import { merge } from "theme-ui";
import { runtimeConfig } from "../tools/runtimeConfig";
import defaultTheme, { DefaultTheme } from "./default";

// TODO: #6214 - Remove this and move the configuration to server

const axisTheme = merge(defaultTheme, {
  name: "axis",
  colors: {
    ...runtimeConfig.styles?.colors,
  },
  layout: {
    // removes floating (fixed) logos
    formsWrap: {
      "&:before": undefined,
      "&:after": undefined,
    },
  },
}) as DefaultTheme;

export default axisTheme;
