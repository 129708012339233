import { ErrorMessage, Field, FieldProps } from "formik";
import React, { PropsWithChildren, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Label, SxStyleProp, Text, Textarea } from "theme-ui";
import { useFieldError } from "./useFieldError";

type Props = PropsWithChildren<{
  name: string;
  placeholder?: string;
  required?: boolean;
  sx?: SxStyleProp;
  textareaSx?: SxStyleProp;
  label?: string;
  processOnEnterPressed?: boolean;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
}>;

type TextAreaProps = Props & FieldProps;

function TextareaField({
  form,
  meta,
  field,
  children,
  placeholder,
  required,
  sx,
  textareaSx,
  label,
  processOnEnterPressed,
  name,
  onChange,
}: TextAreaProps): ReactElement {
  const { t } = useTranslation();

  const { errorInputSx, fieldRef } = useFieldError<HTMLTextAreaElement>({
    meta,
    name,
  });

  const onKeyUp: React.KeyboardEventHandler = (e) => {
    if (e.key.toLowerCase() === "enter" && processOnEnterPressed) {
      // TODO: refactor modals / forms around ConfirmatinButtons
      // state of confirmation button must reflect form state
      // currently both states are independent

      const processingButton = document.getElementById("processing-button");
      processingButton ? processingButton.click() : form.submitForm();
    }
  };

  return (
    <Label sx={sx}>
      {label && (
        <Text sx={{ fontWeight: 500, fontSize: [4], mb: [1] }}>
          {label} {required ? `(${t("form.required")})` : ""}
        </Text>
      )}
      <Textarea
        placeholder={placeholder || t(`form.placeholder.${name}`)}
        onKeyUp={onKeyUp}
        sx={{ ...textareaSx, ...errorInputSx }}
        ref={fieldRef}
        {...field}
        {...meta}
        onChange={onChange ?? field.onChange}
      />
      <ErrorMessage
        name={name}
        component={({ children: kids }) => (
          <Alert variant="primary">{kids}</Alert>
        )}
      />
      {children}
    </Label>
  );
}

function FormTextAreaField(props: Props): ReactElement {
  return (
    <Field name={props.name}>
      {(fieldProps: FieldProps) => <TextareaField {...props} {...fieldProps} />}
    </Field>
  );
}

export default FormTextAreaField;
