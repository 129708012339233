import { canvasObjectIds } from "../../../../const";

export enum ObjectsCRUDActionType {
  ADD_CANVAS_OBJECT = "ADD_CANVAS_OBJECT",
  COPY_CANVAS_OBJECT = "COPY_CANVAS_OBJECT",
  CUT_CANVAS_OBJECT = "CUT_CANVAS_OBJECT",
  DUPLICATE_CANVAS_OBJECT = "DUPLICATE_CANVAS_OBJECT",
  REMOVE_SELECTED_OBJECT = "REMOVE_SELECTED_OBJECT",
  REMOVE_OBJECT_BY_UUID = "REMOVE_OBJECT_BY_UUID",
  SET_OBJECT_PROPS = "SET_OBJECT_PROPS",
}

export type AddNewObject = {
  object: ObjectConstructorConfig;
  config?: {
    pointer?: fabric.Position;
    preservePosition?: boolean;
  };
};

export const addCanvasObjectAction = (payload: AddNewObject) =>
  ({
    type: ObjectsCRUDActionType.ADD_CANVAS_OBJECT,
    payload,
  } as const);

export const copyAction = () =>
  ({
    type: ObjectsCRUDActionType.COPY_CANVAS_OBJECT,
    payload: undefined,
  } as const);

export const cutAction = () =>
  ({
    type: ObjectsCRUDActionType.CUT_CANVAS_OBJECT,
    payload: undefined,
  } as const);

export const duplicateCanvasObjectAction = () =>
  ({
    type: ObjectsCRUDActionType.DUPLICATE_CANVAS_OBJECT,
    payload: undefined,
  } as const);

export const removeCanvasObjectAction = () =>
  ({
    type: ObjectsCRUDActionType.REMOVE_SELECTED_OBJECT,
    payload: undefined,
  } as const);

type RemoveObjectByUuid = {
  uuid: string;
  reversible?: boolean;
};

export const removeObjectByUuidAction = (payload: RemoveObjectByUuid) =>
  ({
    type: ObjectsCRUDActionType.REMOVE_OBJECT_BY_UUID,
    payload,
  } as const);

type SetObjectPropsPayload = {
  objectProps: ContextProps;
  updateAllOfType?: canvasObjectIds;
};

export const setCanvasObjectPropsAction = (payload: SetObjectPropsPayload) =>
  ({
    type: ObjectsCRUDActionType.SET_OBJECT_PROPS,
    payload,
  } as const);

export type AddNewObjectAction = ReturnType<typeof addCanvasObjectAction>;
export type RemoveObjectByUuidAction = ReturnType<
  typeof removeObjectByUuidAction
>;
export type SetObjectPropsAction = ReturnType<
  typeof setCanvasObjectPropsAction
>;

export type ObjectsCRUDAction =
  | AddNewObjectAction
  | ReturnType<typeof copyAction>
  | ReturnType<typeof cutAction>
  | ReturnType<typeof duplicateCanvasObjectAction>
  | ReturnType<typeof removeCanvasObjectAction>
  | RemoveObjectByUuidAction;
