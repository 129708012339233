import type { UserPresenceAutoEnable } from "./userPresence.reducer";

export enum UserPresenceActionType {
  SET_USER_PRESENCE_OPTIONS = "SET_USER_PRESENCE_OPTIONS",
  USER_PRESENCE_MOUSE_MOVED = "USER_PRESENCE_MOUSE_MOVED",
  PAUSE_USER_PRESENCE = "PAUSE_USER_PRESENCE",
  RESUME_USER_PRESENCE = "RESUME_USER_PRESENCE",
  ADD_USER_PRESENCE_USERNAME = "ADD_USER_PRESENCE_USERNAME",
  REMOVE_USER_PRESENCE_USERNAME = "REMOVE_USER_PRESENCE_USERNAME",
  USER_PRESENCE_REQUESTED = "USER_PRESENCE_REQUESTED",
  USER_PRESENCE_REVOKED = "USER_PRESENCE_REVOKED",
  SEND_USER_PRESENCE_REQUESTS = "SEND_USER_PRESENCE_REQUESTS",
  SET_WEBRTC_CONNECTING = "SET_WEBRTC_CONNECTING",
}

export type SetUserPresenceOptionsPayload = UserPresenceAutoEnable;

type AddRemoveUserPresenceUserPayload = {
  connectionId: string;
  UserName: string;
};

type SetUserPresenceRequestPayload = {
  connectionId: string;
};

type SetUserPresenceRequestsPayload = {
  activated: string[];
  deactivated: string[];
};

type SetWebRTCConnectingPayload = {
  connectingIds: string[];
};

type UserPresenceMouseMovedPayload = {
  pointers: CapturedPointer[];
  UserName: string;
};

export const setUserPresenceOptionsAction = (
  payload: SetUserPresenceOptionsPayload
) =>
  ({
    type: UserPresenceActionType.SET_USER_PRESENCE_OPTIONS,
    payload,
  } as const);

export const userPresenceMouseMovedAction = (
  payload: UserPresenceMouseMovedPayload
) =>
  ({
    type: UserPresenceActionType.USER_PRESENCE_MOUSE_MOVED,
    payload,
  } as const);

export const pauseUserPresenceAction = () =>
  ({
    type: UserPresenceActionType.PAUSE_USER_PRESENCE,
    payload: undefined,
  } as const);

export const resumeUserPresenceAction = () =>
  ({
    type: UserPresenceActionType.RESUME_USER_PRESENCE,
    payload: undefined,
  } as const);

export const addUserPresenceUsernameAction = (
  payload: AddRemoveUserPresenceUserPayload
) =>
  ({
    type: UserPresenceActionType.ADD_USER_PRESENCE_USERNAME,
    payload,
  } as const);

export const removeUserPresenceUsernameAction = (
  payload: AddRemoveUserPresenceUserPayload
) =>
  ({
    type: UserPresenceActionType.REMOVE_USER_PRESENCE_USERNAME,
    payload,
  } as const);

export const userPresenceRequestedAction = (
  payload: SetUserPresenceRequestPayload
) =>
  ({
    type: UserPresenceActionType.USER_PRESENCE_REQUESTED,
    payload,
  } as const);

export const userPresenceRevokedAction = (
  payload: SetUserPresenceRequestPayload
) =>
  ({
    type: UserPresenceActionType.USER_PRESENCE_REVOKED,
    payload,
  } as const);

export const sendUserPresenceRequestsAction = (
  payload: SetUserPresenceRequestsPayload
) =>
  ({
    type: UserPresenceActionType.SEND_USER_PRESENCE_REQUESTS,
    payload,
  } as const);

export const setWebRTCConnectingAction = (
  payload: SetWebRTCConnectingPayload
) =>
  ({
    type: UserPresenceActionType.SET_WEBRTC_CONNECTING,
    payload,
  } as const);

export type SetUserPresenceOptionsAction = ReturnType<
  typeof setUserPresenceOptionsAction
>;
export type UserPresenceMouseMovedAction = ReturnType<
  typeof userPresenceMouseMovedAction
>;
export type AddUserPresenceInfoAction = ReturnType<
  typeof addUserPresenceUsernameAction
>;
export type RemoveUserPresenceUsernameAction = ReturnType<
  typeof removeUserPresenceUsernameAction
>;
export type UserPresenceRequestedAction = ReturnType<
  typeof userPresenceRequestedAction
>;
export type SendUserPresenceRequestsAction = ReturnType<
  typeof sendUserPresenceRequestsAction
>;

export type UserPresenceAction =
  | SetUserPresenceOptionsAction
  | UserPresenceMouseMovedAction
  | ReturnType<typeof pauseUserPresenceAction>
  | ReturnType<typeof resumeUserPresenceAction>
  | AddUserPresenceInfoAction
  | RemoveUserPresenceUsernameAction
  | ReturnType<typeof userPresenceRequestedAction>
  | ReturnType<typeof userPresenceRevokedAction>
  | SendUserPresenceRequestsAction
  | ReturnType<typeof setWebRTCConnectingAction>;
