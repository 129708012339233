import { all, Effect, getContext, select, takeEvery } from "redux-saga/effects";
import { selectObjects } from "../../../studio/components/patches/extends-selection/selection.utils";
import {
  isInGroup,
  separateObjectsAndConnections,
} from "../../../studio/utils/fabricObjects";
import {
  SelectAllObjectsAction,
  SelectionActionType,
  SelectReadOnlyObjectAction,
  SetSelectionModeAction,
  ToggleSelectLockedAction,
} from "./selection.actions";
import { selectLockedSelection } from "./selection.reducer";

function* setSelectionMode(action: SetSelectionModeAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const { payload } = action;

  canvas.setSelectionMode(payload);
}

function* selectAllObjects({ payload }: SelectAllObjectsAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const selectLocked = selectLockedSelection(yield select());

  const { isKeyboardSelect } = payload;
  canvas.discardActiveObject({ isSilent: true });
  const { objects } = separateObjectsAndConnections(canvas.getObjects());
  const objectsToSelect = objects.filter(
    (o) =>
      o.selectable &&
      !isInGroup(o) &&
      !o.isReserved() &&
      (!o.isLocked() || (o.isLocked() && selectLocked))
  );

  selectObjects(canvas, objectsToSelect, { isKeyboardSelect });
  canvas.requestRenderAll();
}

function* deSelectAllObjects() {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  canvas.discardActiveObject();
  canvas.requestRenderAll();
}

const setLocked = (isUnlocked: boolean, canvas: fabric.CollaboardCanvas) => {
  if (isUnlocked) {
    canvas.getObjects().forEach((o) => {
      if (o.reservationUserName) {
        // don't unlock object if it is reserved by another user
        return;
      }

      o.set({
        evented: true,
        selectable: o.initialSelectable,
      });
    });
  } else {
    canvas.getObjects().forEach((o) => {
      o.locked &&
        o.set({
          evented: false,
          selectable: false,
        });
    });
    const obj = canvas.getActiveObject();
    if (obj && obj.isLocked()) {
      canvas.discardActiveObject();
    }
  }
};

function* toggleSelectLocked(action: ToggleSelectLockedAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  const { payload: selectLocked } = action;
  setLocked(selectLocked, canvas);
  canvas.isSelectLockedObjectsEnabled = selectLocked;
  canvas.requestRenderAll();
}

function* selectReadOnlyObjectAction({ payload }: SelectReadOnlyObjectAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  canvas.discardReadOnlyActiveObject([payload]);
  canvas.setActiveObject(payload);
}

export function* selectionSaga(): Generator<Effect> {
  yield all([
    takeEvery(SelectionActionType.SET_SELECTION_MODE, setSelectionMode),
  ]);
  yield all([
    takeEvery(SelectionActionType.TOGGLE_SELECT_LOCKED, toggleSelectLocked),
  ]);
  yield all([
    takeEvery(SelectionActionType.SELECT_ALL_OBJECTS, selectAllObjects),
  ]);
  yield all([
    takeEvery(SelectionActionType.DESELECT_ALL_OBJECTS, deSelectAllObjects),
  ]);
  yield all([
    takeEvery(
      SelectionActionType.SELECT_READ_ONLY_OBJECT,
      selectReadOnlyObjectAction
    ),
  ]);
}
