import { TOptions } from "i18next";
import Tooltip from "rc-tooltip";
import React, { ReactElement, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, ThemeUIStyleObject } from "theme-ui";

export type TooltipPlacement =
  | "top"
  | "topLeft"
  | "topRight"
  | "bottom"
  | "bottomLeft"
  | "bottomRight"
  | "left"
  | "leftTop"
  | "leftBottom"
  | "right"
  | "rightTop"
  | "rightBottom";

type OverlayProps = {
  header?: string;
  description?: string;
  descriptionComponent?: ReactElement;
  noTranslate?: boolean;
  shortcut?: string;
  translationParams?: string | TOptions;
};

type CBTooltipProps = OverlayProps & {
  // rc-tooltip just demands string
  placement: TooltipPlacement;
  forceHide?: boolean;
  children: ReactElement; // forced by rc-tooltip (ReactNode won't work, hence PropsWithChildren won't too)
  maxWidth?: number;
};

function Overlay({
  header,
  description,
  descriptionComponent,
  noTranslate,
  shortcut,
  translationParams,
}: OverlayProps): ReactElement {
  const { t } = useTranslation();
  return (
    <Box>
      {header && <Box sx={headerStyle}>{t(header, translationParams)}</Box>}
      {description && (
        <Box sx={descriptionStyle}>
          {noTranslate ? description : t(description, translationParams)}
        </Box>
      )}
      {descriptionComponent}
      {shortcut && <Box>{t("tooltip.shortcut", { shortcut })}</Box>}
    </Box>
  );
}

const headerStyle: ThemeUIStyleObject = { fontWeight: "bold" };
const descriptionStyle: ThemeUIStyleObject = {
  fontStyle: "italic",
  color: "var(--inputPlaceholder)",
};

function CollaboardTooltip({
  placement,
  children,
  forceHide,
  maxWidth = 6,
  ...overlayProps
}: CBTooltipProps): ReactElement {
  const [isVisible, setVisible] = useState(false);
  const [isActive, setActive] = useState(false);

  const onClick = useCallback(() => {
    setVisible(false);
    setActive(!isActive);
  }, [isActive]);

  const onMouseLeave = useCallback(() => setActive(false), []);

  const onVisibleChange = useCallback(
    (visible) => setVisible(visible && !forceHide),
    [forceHide]
  );

  return (
    <Box onMouseLeave={onMouseLeave} onClick={onClick}>
      <Tooltip
        trigger="hover"
        mouseEnterDelay={0.5}
        mouseLeaveDelay={0.2}
        onVisibleChange={onVisibleChange}
        visible={isVisible && !isActive && !forceHide}
        placement={placement}
        destroyTooltipOnHide={destroyTooltipOnHide}
        overlay={<Overlay {...overlayProps} />}
        overlayStyle={{ maxWidth: `calc(var(--gridTile) * ${maxWidth})` }}
      >
        <div>{children}</div>
      </Tooltip>
    </Box>
  );
}

const destroyTooltipOnHide = { keepParent: false };

export default CollaboardTooltip;
