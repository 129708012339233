import React, { ReactElement, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { getExternalLoginUrl } from "../../../api";
import { popupWindowClass, routes } from "../../../const";
import { InternalError } from "../../../errors/InternalError";
import { errorToast } from "../../../tools/errorToast";
import { LogCategory, onErrorToLog } from "../../../tools/telemetry";
import { useErrorHandler } from "../../shared/hooks/useErrorHandler";
import FullPageSpinner from "../../shared/spinners/FullPageSpinner";
import { isRunningInMSTeams } from "../../../tools/msTeams";
import { stringifyError } from "../../../tools/errors";
import useBodyClass from "../../shared/dom/useBodyClass";

function MSTeamsAuth(): ReactElement {
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();
  const { fallbackErrorHandler } = useErrorHandler();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  // The default min body width is 720px but this page opens in a popup window
  // which is smaller so we have to add a class which removes the min body width
  useBodyClass(popupWindowClass);

  const startExternalAuthentication = useCallback(
    async (authProvider: string, tenant: string | undefined) => {
      try {
        const url = await getExternalLoginUrl(authProvider, tenant);
        window.location.assign(url);
      } catch (error) {
        const microsoftTeams = await import("@microsoft/teams-js").catch(
          (error: unknown) => {
            fallbackErrorHandler(
              error,
              "Failed to load library - MS Teams",
              LogCategory.auth
            );
          }
        );
        microsoftTeams?.authentication.notifyFailure(stringifyError(error));
      }
    },
    [fallbackErrorHandler]
  );

  useEffect(() => {
    if (!isRunningInMSTeams()) {
      history.replace(routes.authenticate);
      return;
    }

    const authProvider = queryParams.get("provider") ?? undefined;
    const tenant = queryParams.get("tenant") ?? undefined;

    if (!authProvider) {
      errorToast(t("clientError.failedToPerformThirdPartyAuth"));
      onErrorToLog(
        new InternalError("Provider missing from querystring - MS Teams"),
        LogCategory.auth
      );
      history.replace(routes.authenticate);
      return;
    }

    startExternalAuthentication(authProvider, tenant);
  }, [history, queryParams, startExternalAuthentication, t]);

  return <FullPageSpinner>{t("MSTeamsAuth.redirecting")}</FullPageSpinner>;
}

export default MSTeamsAuth;
