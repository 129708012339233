import theme from "../bgw";
import twrConfiguration from "../twr/configuration";

// NOTE: other than logo / Tagline and colors, it's the same as TWR

const configuration: CustomerConfiguration = {
  ...twrConfiguration,
  faviconName: "favicon-bgw.png",
  theme,
  loginTroubleHref: "mailto:1777-ServiceDesk@bgw-online.de",
};

export default configuration;
