import type { TileStatus } from "../api/signalR/message.types";
import { SetUserPresenceOptionsPayload } from "../reduxStore/canvas/userPresence/userPresence.actions";
import { UISizes } from "../types/enum";
import LocalStorage, { SessionStorage } from "./localStorage";

/**
 * These stores are imported into const/index.ts
 *
 * BEWARE of circular dependencies.
 */

export const hideWelcomeVideoStorage = new LocalStorage<boolean>(
  "hide-welcome-video"
);
export const welcomeVideoClosedStorage = new SessionStorage<boolean>(
  "welcome-video-closed"
);

export const defaultHelpSettings = { shiftScaling: true };
export const helpSettings = new LocalStorage<HelpSettings>("help-settings");

export const uiSizeStorage = new LocalStorage<UISizes>("settings-1-ui-size");

export const hideRequestFreeTrialStorage = new SessionStorage<boolean>(
  "hide-request-free-trial"
);

// Namespaced storage (user-specific value will be used in key)
export const floatingToolbarStorage = new LocalStorage<boolean>(
  "settings-1-floating-toolbar",
  {
    user: true,
  }
);

export const activatePenOnTouchStorage = new LocalStorage<boolean>(
  "settings-1-activate-pen-on-touch",
  {
    user: true,
  }
);

export const windowBorderOverlayStorage = new LocalStorage<boolean>(
  "settings-1-window-border-overlay",
  {
    user: true,
  }
);

export const defaultStickyNoteSettingsStorage = new LocalStorage<ContextProps>(
  "settings-note",
  {
    user: true,
  }
);

export const defaultTextSettingsStorage = new LocalStorage<ContextProps>(
  "settings-text",
  {
    user: true,
  }
);
export const defaultConnectorSettingsStorage = new LocalStorage<ContextProps>(
  "settings-connection",
  {
    user: true,
  }
);

export const clickableUrlsStorage = new LocalStorage<boolean>(
  "settings-1-clickable-urls",
  {
    user: true,
  }
);

export const embedTransparencyStorage = new LocalStorage<boolean>(
  "settings-1-embed-transparency",
  {
    user: true,
  }
);

export const minimapOpenStorage = new LocalStorage<boolean>(
  "settings-1-minimap-open",
  {
    user: true,
  }
);

export const objectGradientsStorage = new LocalStorage<boolean>(
  "settings-1-object-gradients",
  {
    user: true,
  }
);

export const wheelInputAsTouchpadStorage = new LocalStorage<boolean>(
  "settings-1-wheel-input-as-touchpad",
  {
    user: true,
  }
);

// Namespaced storage (project-specific value will be used in key)
export const viewportTransformStorage = new LocalStorage<TransformMatrix>(
  "viewport-transform",
  {
    // Note: `project: true` implies `user: true`
    project: true,
  }
);

export const previouslyAddedObjectStorage = new LocalStorage<
  Omit<TileStatus, "ZIndex">
>("previously-added-object", {
  project: true,
});

export const presentingStorage = new SessionStorage<boolean>("presenting", {
  project: true,
});

export const userPresenceStorage = new LocalStorage<SetUserPresenceOptionsPayload>(
  "user-presence-1",
  {
    project: true,
  }
);
