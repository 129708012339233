import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function CompanyRole(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M26.75,21.89c-.66-.47-1.67-.22-2.29-1.05,0,0-.24-.26.29-.91a3.22,3.22,0,0,0,.44-2.45,1.69,1.69,0,0,0-3.3,0,3.18,3.18,0,0,0,.44,2.45c.52.65.29.91.29.91-.63.83-1.64.58-2.29,1.05a1.74,1.74,0,0,0-.5,1.53h7.41A1.75,1.75,0,0,0,26.75,21.89Z" />
      <path d="M27.78,12.14H13a2.24,2.24,0,0,0-2.24,2.24V24.84A2.24,2.24,0,0,0,13,27.09H27.78A2.24,2.24,0,0,0,30,24.84V14.38A2.24,2.24,0,0,0,27.78,12.14Zm1.06,2.24V24.84a1.06,1.06,0,0,1-1.06,1.07H13a1.07,1.07,0,0,1-1.07-1.07V14.38A1.06,1.06,0,0,1,13,13.32H27.78A1.05,1.05,0,0,1,28.84,14.38Z" />
      <path d="M18.08,23.25H17.4a.34.34,0,0,1-.34-.34.35.35,0,0,1,.34-.34h.68a.34.34,0,0,1,.34.34A.34.34,0,0,1,18.08,23.25Z" />
      <path d="M18.08,20.56H14.27a.34.34,0,1,1,0-.67h3.81a.34.34,0,1,1,0,.67Z" />
      <path d="M18.08,17.43H14.27a.34.34,0,0,1,0-.68h3.81a.34.34,0,1,1,0,.68Z" />
    </Icon>
  );
}

export default CompanyRole;
