import React, { ReactElement } from "react";
import { Flex, ThemeUICSSObject } from "theme-ui";

type Props = PropsWithRequiredChildren<{
  sx?: ThemeUICSSObject;
}>;

function ModalFooter({ children, sx }: Props): ReactElement | null {
  if (!children) {
    return null;
  }

  return (
    <Flex
      as="footer"
      sx={{
        flex: 1,
        order: 3,
        justifyContent: "flex-end",
        padding: "1rem 1.5rem",
        button: {
          flex: "unset",
          minWidth: [0, "220px"],
          ml: [4],
          ":first-of-type": {
            ml: [0],
          },
        },
        ...sx,
      }}
    >
      {children}
    </Flex>
  );
}

export default ModalFooter;
