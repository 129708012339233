export enum ActiveMediaActionType {
  TOGGLE_PLAY = "TOGGLE_PLAY",
  TOGGLE_MUTE = "TOGGLE_MUTE",
  SKIP_POSITION = "SKIP_POSITION",
  TOGGLE_FULLSCREEN = "TOGGLE_FULLSCREEN",
  CLOSE_FULLSCREEN_PLAYER = "CLOSE_FULLSCREEN_PLAYER",
  REWIND = "REWIND",
}

export const togglePlayAction = () =>
  ({
    type: ActiveMediaActionType.TOGGLE_PLAY,
    payload: undefined,
  } as const);

export const toggleMuteAction = () =>
  ({
    type: ActiveMediaActionType.TOGGLE_MUTE,
    payload: undefined,
  } as const);

type SkipPosition = {
  forward: boolean;
};

export const skipPositionAction = (payload: SkipPosition) =>
  ({
    type: ActiveMediaActionType.SKIP_POSITION,
    payload,
  } as const);

export const toggleFullScreenAction = () =>
  ({
    type: ActiveMediaActionType.TOGGLE_FULLSCREEN,
    payload: undefined,
  } as const);

export const closeFullScreenPlayerAction = () =>
  ({
    type: ActiveMediaActionType.CLOSE_FULLSCREEN_PLAYER,
    payload: undefined,
  } as const);

type Rewind = {
  percentage: number;
};

export const rewindAction = (payload: Rewind) =>
  ({
    type: ActiveMediaActionType.REWIND,
    payload,
  } as const);

export type SkipPositionAction = ReturnType<typeof skipPositionAction>;
export type RewindAction = ReturnType<typeof rewindAction>;

export type ActiveMediaAction =
  | ReturnType<typeof togglePlayAction>
  | ReturnType<typeof toggleMuteAction>
  | SkipPositionAction
  | ReturnType<typeof toggleFullScreenAction>
  | ReturnType<typeof closeFullScreenPlayerAction>
  | RewindAction;
