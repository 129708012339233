import { isStaticFeatureActive, staticFeatureFlags } from "../../tools/flags";
import LocalStorage, {
  InMemoryStorage,
  SessionStorage,
} from "../../tools/localStorage";
import { AuthUserType } from "./auth.actions";

export type StoredAuthenticatingDetails = {
  authProvider: string;
  isRegistration: boolean;
  redirect?: string;
  tenant?: string;
};

export type StoredAuthenticatedDetails = Omit<
  StoredAuthenticatingDetails,
  "redirect"
> & {
  token: import("./auth.storage").AuthToken;
  userType: AuthUserType;
};

/**
 * Token storage
 *
 * @NOTE These stores are in a separate file so that they can be mocked in tests.
 */
const storeUserAuthInMemory = isStaticFeatureActive(
  staticFeatureFlags.IN_MEMORY_AUTH_TOKEN
);

/**
 * The shape of the token has changed slightly so we need to use a different
 * name. This will result in users being prompted to log in again when they
 * load the new version of the app.
 */
const authStorageKey = "auth-v2";

export const userAuthStorage = storeUserAuthInMemory
  ? new InMemoryStorage<StoredAuthenticatedDetails>(authStorageKey)
  : new LocalStorage<StoredAuthenticatedDetails>(authStorageKey);

export const guestAuthStorage = new SessionStorage<StoredAuthenticatedDetails>(
  authStorageKey
);

/**
 * Authenticating storage.
 *
 * This is used to persist certain values over an external authentication
 * redirect, for example Login via Google.
 */

export const authenticatingStorage = new SessionStorage<StoredAuthenticatingDetails>(
  "authenticating"
);
