export const featuresFree: Array<Feature> = [
  {
    labelKey: "drawing",
    inCard: true,
    order: 100,
  },
  {
    labelKey: "objects",
    inCard: true,
    order: 101,
  },
  {
    labelKey: "connectors",
    inCard: true,
    order: 102,
  },
  {
    labelKey: "userPresence",
    inCard: true,
    order: 103,
  },
];
