import React, { ReactElement } from "react";
import { Icon } from "../../icons/Icon";

function Logo({ colorized, size, ...rest }: LogoProps): ReactElement {
  return (
    <Icon
      alignMiddle
      noShadow
      viewBox="0 0 61 40"
      fill={colorized ? "var(--primary)" : "var(--textColor)"}
      height="40"
      width="40"
      {...rest}
    >
      <circle cx="6.8" cy="33.28" r="6.8" />
      <polygon points="20.97,0 20.97,6.22 54.78,6.22 54.78,40.03 61,40.03 61,0 " />
      <polygon points="27.17,11.43 20.95,11.43 20.95,40.06 49.58,40.06 49.58,33.84 27.17,33.84 " />
    </Icon>
  );
}
export default Logo;
