import { colorThemes, leadColors, ParticipationType } from "../const";
import { ApiPermission } from "../types/enum";

export const unknownUserColor = "#555555";

export const getUserFullName = ({
  FirstName,
  LastName,
  UserName,
}: ProjectUserIdentity): string =>
  FirstName || LastName
    ? `${(FirstName || "").trim()} ${(LastName || "").trim()}`.trim()
    : UserName;

/**
 * Find the info for the project user, falling back to unknown user if not found, e.g. Guests.
 */
export const findUnknownUserInfo = (
  users: ProjectOnlineUserInfo[],
  userName: string
): ProjectOnlineUserInfo => {
  return (
    users.find((u) => u.UserName === userName) || generateUnknownUser(userName)
  );
};

export const generateUnknownIdentity = (
  UserName: string
): ProjectUserIdentity => {
  return {
    FirstName: UserName,
    IsGuest: true,
    LastName: "",
    PhotoUrl: null,
    UserId: 0,
    UserName,
  };
};

export const generateUnknownUser = (
  UserName: string
): ProjectOnlineUserInfo => {
  const identity: ProjectUserIdentity = generateUnknownIdentity(UserName);
  const AvatarUrl = generateAvatars(identity);

  const unknownUser: ProjectOnlineUserInfo = {
    ...identity,

    // ProjectUserInfo
    Avatar: toCanvasAvatars(AvatarUrl),
    AvatarUrl,
    FullName: getUserFullName(identity),
    IsGuest: true,
    IsMyself: false,
    ProjectColor: unknownUserColor,

    // ProjectParticipantInfo
    IsProjectOwner: false,
    Permission: ApiPermission.readPermission,
    ParticipationType: ParticipationType.direct,

    // ProjectOnlineUserInfo
    IsOnline: true,
  };

  return unknownUser;
};

export const generateAvatars = (user: ProjectUserIdentity): AvatarImageUrls =>
  Object.values(colorThemes).reduce((acc, theme) => {
    const { PhotoUrl } = user;
    const hasAvatar = PhotoUrl && PhotoUrl.startsWith("http");
    acc[theme] = hasAvatar ? (PhotoUrl as string) : generateAvatar(user, theme);
    return acc;
  }, {} as AvatarImageUrls);

export const toCanvasAvatars = (
  AvatarUrl: AvatarImageUrls
): AvatarImageElements =>
  Object.values(colorThemes).reduce((acc, theme) => {
    acc[theme] = toCanvasAvatar(AvatarUrl[theme]);
    return acc;
  }, {} as AvatarImageElements);

export const generateAvatar = (
  user: ProjectUserIdentity,
  theme: colorThemes
): string => {
  const size = 128;
  const initials = user.IsGuest
    ? createGuestInitials(user)
    : createUserInitials(user);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (ctx) {
    canvas.width = canvas.height = size;
    ctx.font = Math.round(canvas.width / 2) + "px Arial";
    ctx.textAlign = "center";
    ctx.fillStyle = leadColors[theme];
    ctx.fillText(initials, size / 2, size / 1.5);
  }

  return canvas.toDataURL();
};

export const toCanvasAvatar = (photoUrl: string): HTMLImageElement => {
  const avatar = new Image();
  avatar.src = photoUrl;
  return avatar;
};

const firstLetter = (text?: string | null): string =>
  (text || "").trim().charAt(0).toUpperCase();

const createGuestInitials = ({
  FirstName = "",
}: ProjectUserIdentity): string => {
  const [name, lastName] = FirstName.split(" ");
  const isDigitsOnlyLastName = !!parseInt(lastName, 10);
  const suffix = isDigitsOnlyLastName
    ? lastName.substring(0, 2)
    : firstLetter(lastName);
  const prefix = firstLetter(name);

  return `${prefix}${suffix}`.trim() || "G";
};

type NameInfo = {
  FirstName?: string | null;
  LastName?: string | null;
  UserName?: string;
};

export const createUserInitials = ({
  FirstName,
  LastName,
  UserName,
}: NameInfo): string => {
  const name = firstLetter(FirstName);
  const surName = firstLetter(LastName);
  const email = firstLetter(UserName);

  return FirstName || LastName ? `${name}${surName}` : email || email;
};
