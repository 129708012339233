import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, ThemeUIStyleObject } from "theme-ui";
import {
  authenticateExternal,
  getExternalLoginUrl,
  registerExternalUser,
} from "../../../api";
import { errorCodeIds } from "../../../api/errorCodes";
import { routes } from "../../../const";
import { InternalError } from "../../../errors/InternalError";
import {
  AuthUserType,
  clearAuthenticatingAction,
  setAuthenticatedAction,
  setAuthenticatingAction,
} from "../../../reduxStore/auth/auth.actions";
import { AuthenticatingDetails } from "../../../reduxStore/auth/auth.reducer";
import { isAPIError } from "../../../tools/errors";
import { errorToast } from "../../../tools/errorToast";
import {
  clearMSTeamsAuthCode,
  getMSTeamsAuthCode,
  isRunningInMSTeams,
} from "../../../tools/msTeams";
import { LogCategory, onErrorToLog } from "../../../tools/telemetry";
import { ApiAuthenticationMode } from "../../../types/enum";
import { useErrorHandler } from "../../shared/hooks/useErrorHandler";
import { useSimpleModal } from "../../shared/modals/useSimpleModal";
import { useAuthenticatingStatus } from "../useAuthenticatingStatus";
import { useLoggedInStatus } from "../useLoggedInStatus";
import {
  isAlreadyRegisteredResponse,
  isCompleteRegistrationResponse,
  isValidatedTokenResponse,
} from "./auth.utils";
import ExternalAuthButton from "./ExternalAuthButton";
import ValidateTFAModalTemplate from "./ValidateTFAModalTemplate";

type Props = {
  authProviders: AuthProvider[];
  externalProviderKey?: string;
  redirectAfterLogin?: string;
  tenantFromConfig?: string;
  /**
   * This component is also used for registration, however this option only
   * affects the styling and labels.
   *
   * The registration flow is actually influenced by the AuthenticatingDetails.
   */
  isRegistration: boolean;
  onUnlockLogin: () => void;
  onLockLogin: () => void;
};

/**
 * @TODO #7118 - The implementation of this would be a lot cleaner if
 * external providers redirect back to a different route, specifically for
 * authenticating with an external auth code.
 *
 * @TODO #7118 - 2FA should be disabled for external logins in most cases,
 * however in rare cases the server may be configured to enable 2FA on the
 * collaboard side. We have no way of knowing when this happens. Dimos said
 * that he would change the endpoint so that it only returns the 2FA
 * authentication mode value when the server is configured such. Check this.
 */
function ExternalLogin({
  authProviders,
  externalProviderKey,
  redirectAfterLogin,
  tenantFromConfig,
  isRegistration,
  onUnlockLogin,
  onLockLogin,
}: Props): ReactElement {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { handleApiError, fallbackErrorHandler } = useErrorHandler();

  const [unvalidatedToken, setUnvalidatedToken] = useState<
    ApiAuthenticate | undefined
  >(undefined);
  const [inProgress, setInProgress] = useState(false);
  const [handledCode, setHandledCode] = useState(false);

  const validate2FAModal = useSimpleModal();

  const { logout } = useLoggedInStatus();
  const { authenticatingDetails, isInitialized } = useAuthenticatingStatus();
  const detailsRef = useRef(authenticatingDetails);

  /**
   * Handle situation where details are missing from store.
   *
   * This can happen if there is a problem with the user's storage or if
   * someone copies an external redirect link into another browser.
   */
  const missingAuthenticatingDetails = useCallback(() => {
    setUnvalidatedToken(undefined);
    setInProgress(false);
    onUnlockLogin();
    logout();
    history.replace(routes.authenticate);
  }, [onUnlockLogin, logout, history]);

  /**
   * User has been authenticated by the nominated provider.
   */
  const authenticatedWithProvider = useCallback(
    (
      response: ApiValidatedAuthenticate | ApiCompleteUserRegistration,
      details: AuthenticatingDetails
    ) => {
      const { authProvider, isRegistration, redirect, tenant } = details;

      dispatch(
        setAuthenticatedAction({
          authProvider,
          isRegistration,
          redirect,
          tenant,
          token: response,
          userType: AuthUserType.User,
        })
      );
    },
    [dispatch]
  );

  const redirectToCompleteRegistration = useCallback(
    (user: RegistrationUser, token: string) => {
      const state: CompleteRegistrationLocationState = {
        authToken: token,
        user,
      };

      history.replace(routes.registerComplete, state);
    },
    [history]
  );

  /**
   * The user is trying to login.
   */
  const handleExternalAuthLogin = useCallback(
    async (externalProviderKey: string, details: AuthenticatingDetails) => {
      const { authProvider, tenant } = details;

      try {
        const response = await authenticateExternal(
          authProvider,
          externalProviderKey,
          tenant
        );

        const { AuthenticationMode } = response;

        /**
         * The server only supports 2FA for external logins when the `TFAEnabled`
         * config value (server-side) is true. We don't have access to that
         * value in the front end so we must always check the token shape and
         * AuthenticationMode values.
         *
         * When `TFAEnabled` === false the server should send us a validated
         * token and the AuthenticationMode for 'password' mode, allowing us
         * to skip the 2FA validation step.
         *
         * This is only applicable to the `/AuthenticateExternal` and
         * `RegisterExternal` endpoints.
         */
        if (
          AuthenticationMode === ApiAuthenticationMode.TFA ||
          !isValidatedTokenResponse(response)
        ) {
          setUnvalidatedToken(response);
          validate2FAModal.open();
          return;
        }

        authenticatedWithProvider(response, details);
      } catch (error: unknown) {
        if (isAPIError(error)) {
          const { details: errorDetails, response } = error;
          const { errorCode } = errorDetails;

          if (errorCode === errorCodeIds.UserNotAcceptedTermsOfService) {
            if (
              response?.data &&
              isCompleteRegistrationResponse(response.data)
            ) {
              const { User, AuthorizationToken } = response.data;
              redirectToCompleteRegistration(User, AuthorizationToken);
              return;
            }
          }

          if (errorCode === errorCodeIds.TokenNotValid) {
            handleApiError(error);
            dispatch(clearAuthenticatingAction());
            history.replace(routes.authenticate);
            return;
          }
        }

        fallbackErrorHandler(
          error,
          "Unable to login via external provider",
          LogCategory.auth
        );
      }
    },
    [
      authenticatedWithProvider,
      validate2FAModal,
      fallbackErrorHandler,
      redirectToCompleteRegistration,
      handleApiError,
      dispatch,
      history,
    ]
  );

  /**
   * The user is trying to register.
   */
  const handleExternalAuthRegistration = useCallback(
    async (externalProviderKey: string, details: AuthenticatingDetails) => {
      const { authProvider, tenant } = details;
      try {
        const response = await registerExternalUser(
          authProvider,
          externalProviderKey,
          tenant
        );

        const { AuthorizationToken, User } = response;
        const { TermsOfServiceAccepted } = User;

        if (!TermsOfServiceAccepted) {
          redirectToCompleteRegistration(User, AuthorizationToken);
          return;
        }

        /**
         * If a user follows the registration flow with an already-registered
         * account we can just log them in. No need to tell them off.
         *
         * This can also happen because some OnPrem customers are configured
         * to auto-accept ToS for their users.
         */
        if (isAlreadyRegisteredResponse(response)) {
          const { AuthenticationMode } = response;

          /**
           * The server only supports 2FA for external logins when the `TFAEnabled`
           * config value (server-side) is true. We don't have access to that
           * value in the front end so we must always check the token shape and
           * AuthenticationMode values.
           *
           * When `TFAEnabled` === false the server should send us a validated
           * token and the AuthenticationMode for 'password' mode, allowing us
           * to skip the 2FA validation step.
           *
           * This is only applicable to the `/AuthenticateExternal` and
           * `RegisterExternal` endpoints.
           */
          if (
            AuthenticationMode === ApiAuthenticationMode.TFA ||
            !isValidatedTokenResponse(response)
          ) {
            // Because we are handling a registration flow as a login we need
            // to update the authenticating details
            dispatch(
              setAuthenticatingAction({
                ...details,
                // Treat this as a normal login
                isRegistration: false,
              })
            );
            setUnvalidatedToken(response);
            validate2FAModal.open();
            return;
          }

          authenticatedWithProvider(response, {
            ...details,
            // Treat this as a normal login
            isRegistration: false,
          });
          return;
        }

        history.replace(routes.authenticate);
      } catch (error: unknown) {
        if (isAPIError(error)) {
          const { details: errorDetails, response } = error;
          const { errorCode } = errorDetails;

          if (errorCode === errorCodeIds.UserNotAcceptedTermsOfService) {
            if (
              response?.data &&
              isCompleteRegistrationResponse(response.data)
            ) {
              const { User, AuthorizationToken } = response.data;
              redirectToCompleteRegistration(User, AuthorizationToken);
              return;
            }
          }

          if (errorCode === errorCodeIds.TokenNotValid) {
            handleApiError(error);
            dispatch(clearAuthenticatingAction());
            history.replace(routes.authenticate);
            return;
          }
        }

        fallbackErrorHandler(
          error,
          "Unable to register via external provider",
          LogCategory.auth
        );
      }
    },
    [
      authenticatedWithProvider,
      dispatch,
      handleApiError,
      fallbackErrorHandler,
      history,
      redirectToCompleteRegistration,
      validate2FAModal,
    ]
  );

  const handle2FAValidation = useCallback(
    (response: ApiValidatedAuthenticate) => {
      if (!authenticatingDetails) {
        missingAuthenticatingDetails();
        return;
      }

      authenticatedWithProvider(response, authenticatingDetails);
    },
    [
      authenticatedWithProvider,
      authenticatingDetails,
      missingAuthenticatingDetails,
    ]
  );

  const handle2FAError = useCallback(
    (error: unknown) =>
      fallbackErrorHandler(error, "2FA verification error", LogCategory.auth),
    [fallbackErrorHandler]
  );

  /**
   * Complete an external login.
   */
  const handleExternalAuthRedirect = useCallback(
    async (externalProviderKey: string) => {
      if (!handledCode) {
        setHandledCode(true);

        if (!detailsRef.current) {
          missingAuthenticatingDetails();
          return;
        }

        setInProgress(true);

        const { isRegistration } = detailsRef.current;

        if (isRegistration) {
          await handleExternalAuthRegistration(
            externalProviderKey,
            detailsRef.current
          );
          return;
        }

        await handleExternalAuthLogin(externalProviderKey, detailsRef.current);
      }
    },
    [
      handleExternalAuthLogin,
      handleExternalAuthRegistration,
      handledCode,
      missingAuthenticatingDetails,
    ]
  );

  /**
   * Handle external auth redirect if necessary.
   */
  useEffect(() => {
    if (externalProviderKey && isInitialized) {
      handleExternalAuthRedirect(externalProviderKey);
    }
  }, [
    handleExternalAuthRedirect,
    externalProviderKey,
    inProgress,
    unvalidatedToken,
    isInitialized,
    authenticatingDetails,
    missingAuthenticatingDetails,
  ]);

  /**
   * If no external auth redirect is detected inform the parent.
   */
  useEffect(() => {
    if (!externalProviderKey) {
      onUnlockLogin();
    }
  }, [externalProviderKey, onUnlockLogin]);

  /**
   * Make authenticatingDetails available within the synchronous MSTeams flow.
   */
  useEffect(() => {
    detailsRef.current = authenticatingDetails;
  }, [authenticatingDetails]);

  /**
   * Authentication flow for normal web app.
   */
  const startExternalLoginWeb = useCallback(
    async (authProvider: string, tenantFromConfig: string | undefined) => {
      try {
        const url = await getExternalLoginUrl(authProvider, tenantFromConfig);
        window.location.assign(url);
      } catch (error: unknown) {
        fallbackErrorHandler(
          error,
          "Unable to get external login url for web",
          LogCategory.auth
        );
        onUnlockLogin();
      }
    },
    [fallbackErrorHandler, onUnlockLogin]
  );

  const handleMSTeamsSuccess = useCallback(
    (externalProviderKey: string | undefined) => {
      if (externalProviderKey) {
        handleExternalAuthRedirect(externalProviderKey);
        return;
      }

      errorToast(t("clientError.failedToPerformThirdPartyAuth"));

      onErrorToLog(
        new InternalError("Missing key or auth details - MS Teams"),
        LogCategory.auth
      );

      // Clean up
      clearMSTeamsAuthCode();
      setInProgress(false);
      onUnlockLogin();
      logout();
    },
    [handleExternalAuthRedirect, logout, onUnlockLogin, t]
  );

  /**
   * @NOTE This is also called if the user closes the popup window before
   * completing the authentication process.
   */
  const handleMSTeamsFailure = useCallback(
    (reason: string | undefined) => {
      const code = getMSTeamsAuthCode();
      // A success has been reported as a failure (MS Teams lib bug)
      if (code) {
        handleMSTeamsSuccess(code);
        clearMSTeamsAuthCode();
        return;
      }

      if (reason !== "CancelledByUser") {
        errorToast(t("clientError.failedToPerformThirdPartyAuth"));

        onErrorToLog(
          new InternalError(
            reason ? reason : "Unable to authenticate - MS Teams"
          ),
          LogCategory.auth
        );
      }

      // Clean up
      clearMSTeamsAuthCode();
      setInProgress(false);
      onUnlockLogin();
      logout();
    },
    [handleMSTeamsSuccess, logout, onUnlockLogin, t]
  );

  /**
   * Special authentication flow for when the app is running inside MS Teams.
   *
   * https://docs.microsoft.com/en-us/microsoftteams/platform/tabs/how-to/authentication/auth-flow-tab
   */
  const startExternalLoginMSTeams = useCallback(
    async (authProvider: string, tenantFromConfig: string | undefined) => {
      // Reset workaround storage when starting a new flow
      clearMSTeamsAuthCode();

      try {
        const microsoftTeams = await import("@microsoft/teams-js");

        const routeToOpen = `${window.location.origin}${
          routes.MSTeamsAuth
        }?provider=${authProvider}&tenant=${tenantFromConfig ?? ""}`;

        microsoftTeams.authentication.authenticate({
          url: routeToOpen,
          width: 460,
          height: 650,
          successCallback: handleMSTeamsSuccess,
          failureCallback: handleMSTeamsFailure,
        });
      } catch (error: unknown) {
        fallbackErrorHandler(
          error,
          "Unable to get external login url for web",
          LogCategory.auth
        );

        // Clean up
        clearMSTeamsAuthCode();
        setInProgress(false);
        onUnlockLogin();
        logout();
      }
    },
    [
      handleMSTeamsSuccess,
      handleMSTeamsFailure,
      fallbackErrorHandler,
      onUnlockLogin,
      logout,
    ]
  );

  /**
   * Clicking on an external provider button starts an async process.
   * To ensure that the user cannot start multiple external processes we
   * need to block interaction by showing the spinner.
   */
  const onStartExternalLogin = useCallback(
    async (authProvider: string) => {
      onLockLogin();

      dispatch(
        setAuthenticatingAction({
          authProvider,
          isRegistration,
          redirect: redirectAfterLogin,
          tenant: tenantFromConfig,
        })
      );

      if (isRunningInMSTeams()) {
        return startExternalLoginMSTeams(authProvider, tenantFromConfig);
      }

      return startExternalLoginWeb(authProvider, tenantFromConfig);
    },
    [
      dispatch,
      isRegistration,
      onLockLogin,
      redirectAfterLogin,
      startExternalLoginMSTeams,
      startExternalLoginWeb,
      tenantFromConfig,
    ]
  );

  return (
    <>
      <Grid sx={isRegistration ? registrationGridStyle : loginGridStyle}>
        {authProviders.map(({ name, displayName }) => (
          <ExternalAuthButton
            key={name}
            name={name}
            displayName={displayName}
            isRegistration={isRegistration}
            onClick={onStartExternalLogin}
          />
        ))}
      </Grid>
      {unvalidatedToken && (
        <ValidateTFAModalTemplate
          modalProps={validate2FAModal}
          onSuccess={handle2FAValidation}
          onError={handle2FAError}
          UnvalidatedToken={unvalidatedToken}
        />
      )}
    </>
  );
}

export default ExternalLogin;

const loginGridStyle: ThemeUIStyleObject = {
  gridGap: [4],
  width: "100%",
};

const registrationGridStyle: ThemeUIStyleObject = {
  ...loginGridStyle,
  gridTemplateColumns: "1fr 1fr",
};
