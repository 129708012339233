import React, { ReactElement } from "react";
import { Flex } from "theme-ui";
import { useTranslation } from "react-i18next";
import RadioButton from "../../../shared/forms/RadioButton";
import { TeamFilter } from "./useTeam";

type Props = {
  currentFilter: TeamFilter;
  filters: TeamFilter[];
  onFilterChange: (newFilter: TeamFilter) => void;
};

function Filters({
  filters,
  onFilterChange,
  currentFilter,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <Flex>
      {filters.map((filter) => (
        <RadioButton
          key={filter}
          name="mode"
          value="date"
          checked={currentFilter === filter}
          defaultChecked={currentFilter === filter}
          label={t(`team.filter.${filter}`)}
          onChange={() => onFilterChange(filter)}
        />
      ))}
    </Flex>
  );
}

export default Filters;
