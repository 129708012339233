import { ErrorMessage, useField } from "formik";
import React, { PropsWithChildren, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Box, Flex, Input, ThemeUIStyleObject, Text } from "theme-ui";
import { getAppName } from "../../../../tools/customer";

type Props = PropsWithChildren<{
  name: string;
  labelFirst?: boolean;
  sx?: ThemeUIStyleObject;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}>;

function ToggleField({
  labelFirst,
  children,
  sx,
  name,
  disabled,
  onChange,
}: Props): ReactElement {
  const [field, helpers] = useField({ name });
  const { t } = useTranslation();

  const label = children
    ? ""
    : t(`form.label.${name}`, { appName: getAppName() });

  return (
    <>
      <Flex
        as="label"
        sx={{ alignItems: "center", opacity: disabled ? 0.5 : 1, ...sx }}
      >
        {labelFirst && (
          <Text variant="default" sx={{ mr: [5] }}>
            {children || label}
          </Text>
        )}

        <Input
          variant="toggle"
          type="checkbox"
          defaultChecked={field.value}
          {...field}
          {...helpers}
          onChange={onChange ?? field.onChange}
          disabled={disabled}
        />

        {!labelFirst && (
          <Text variant="default" sx={{ flex: 1, ml: [5] }}>
            {children || label}
          </Text>
        )}
      </Flex>

      {helpers.error === name && (
        <Box sx={{ width: "100%", mt: "-10px", marginBottom: "10px" }}>
          <ErrorMessage
            name={name}
            component={({ children: kids }) => (
              <Alert variant="primary">{kids}</Alert>
            )}
          />
        </Box>
      )}
    </>
  );
}

export default ToggleField;
