import { useQuery } from "react-query";
import { getProducts } from "../../../../api";

export const useProducts = (productTypeId) => {
  const {
    data: { Results: products },
  } = useQuery({
    queryKey: "GetProducts",
    queryFn: () => getProducts(),
    config: {
      initialData: { Results: [] },
      initialStale: true,
    },
  });

  const getProductDetails = () =>
    products.find(({ ID }) => ID === productTypeId) ?? {
      MinNumberOfUsers: null,
    };

  return {
    productDetails: getProductDetails(),
  };
};
