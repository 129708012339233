import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function TemplateBlank(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M26.67,24.2V11.69A.67.67,0,0,0,26,11H22.72V9.71H17.45V11h-3.3a.66.66,0,0,0-.65.66V24.2H12.18v1.32H13.5v.66a.66.66,0,0,0,.65.66h3l-2.82,2.82.93.94L19,26.84h.38v3.95h1.32V26.84h.39l3.76,3.76.93-.94L23,26.84h3a.67.67,0,0,0,.66-.66v-.66H28V24.2Zm-1.32,0H14.81V12.35H25.35Z" />
    </Icon>
  );
}
export default TemplateBlank;
