import React, { ReactElement } from "react";
import { Box, ThemeUIStyleObject } from "theme-ui";
import { useTranslation } from "react-i18next";

import { colorThemes } from "../../../../const";
import Avatar from "../../../users/Avatar";

function IconWithBar({ children }: PropsWithRequiredChildren<unknown>) {
  return (
    <Box
      sx={{
        position: "relative",
        mb: "calc(var(--gridTile) / 4)",
      }}
    >
      <Box
        sx={{
          background: "var(--toolBackgroundColor2)",
          height: "calc(100% + var(--gridTile) / 4)",
          left: "50%",
          position: "absolute",
          top: 0,
          width: 1,
        }}
      />
      <Box sx={{ position: "relative" }}>{children}</Box>
    </Box>
  );
}

type Props = {
  boldUserName?: boolean;
  avatarBar?: boolean;
  enlargeAvatar?: boolean;
  noEmail?: boolean;
  user: ProjectUserInfo;
  theme?: colorThemes;
};

function UserCard({
  boldUserName,
  avatarBar,
  enlargeAvatar,
  noEmail,
  user,
  theme = colorThemes.light,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    user && (
      <Box sx={userCardStyle}>
        {!avatarBar ? (
          <Avatar {...user} enlarge={enlargeAvatar} theme={theme} />
        ) : (
          <IconWithBar>
            <Avatar {...user} enlarge={enlargeAvatar} theme={theme} />
          </IconWithBar>
        )}
        <Box sx={wrapperStyle}>
          <Box sx={{ fontWeight: boldUserName ? "bold" : "normal" }}>
            <span>
              {user.FirstName} {user.LastName} {user.IsMyself && t("users.you")}
            </span>
          </Box>
          {!noEmail && !user.IsGuest && (
            <Box sx={userEmailStyle}>{user.UserName}</Box>
          )}
        </Box>
      </Box>
    )
  );
}

const userCardStyle: ThemeUIStyleObject = {
  alignItems: "center",
  display: "flex",
};

const wrapperStyle: ThemeUIStyleObject = {
  marginLeft: "calc(var(--gridTile) / 2)",
};

const userEmailStyle: ThemeUIStyleObject = {
  color: "#aaa",
  fontSize: "0.85em",
};

export default UserCard;
