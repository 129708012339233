import React, { ReactElement, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useErrorHandler } from "../../shared/hooks/useErrorHandler";
import { LogCategory } from "../../../tools/telemetry";

import FullPageSpinner from "../../shared/spinners/FullPageSpinner";
import { parseQueryUrl } from "../../../tools/url";
import useBodyClass from "../../shared/dom/useBodyClass";
import { popupWindowClass } from "../../../const";
import { storeMSTeamsAuthCode } from "../../../tools/msTeams";

function MSTeamsAuthEnd(): ReactElement {
  const { search } = useLocation();
  const { t } = useTranslation();
  const { fallbackErrorHandler } = useErrorHandler();

  // The default min body width is 720px but this page opens in a popup window
  // which is smaller so we have to add a class which removes the min body width
  useBodyClass(popupWindowClass);

  useEffect(() => {
    const queryParams = parseQueryUrl<TeamsEndUrlQuery>(search);

    const checkParams = async () => {
      try {
        const microsoftTeams = await import("@microsoft/teams-js");

        if (queryParams.error) {
          // Authentication/authorization failed
          microsoftTeams.authentication.notifyFailure(queryParams.error);
        } else if (queryParams.code) {
          // Store session cookie in case this success is reported as failure (lib bug)
          storeMSTeamsAuthCode(queryParams.code);
          // Success: return token information to the tab
          microsoftTeams.authentication.notifySuccess(queryParams.code);
        } else {
          // Unexpected condition: hash does not contain error or access_token parameter
          microsoftTeams.authentication.notifyFailure("UnexpectedFailure");
        }
      } catch (error) {
        fallbackErrorHandler(
          error,
          "Failed to complete auth - MS Teams",
          LogCategory.auth
        );
      }
    };

    checkParams();
  }, [fallbackErrorHandler, search]);

  return <FullPageSpinner>{t("MSTeamsAuth.finalizing")}</FullPageSpinner>;
}

export default MSTeamsAuthEnd;
