import { fabric } from "fabric";
import { stickyNoteRectWidth, stickyNoteSquareWidth } from "../../const";
import { generateIds } from "../../tools";
import "./stickyNote/index";

export const createStickyNotes = (
  options: Omit<StickyNoteObjectConstructorConfig, "type">
): fabric.StickyNote[] => {
  const { configPerNote = [], ...commonOptions } = options;
  const ids = options.uuid ? [options.uuid] : generateIds(options.quantity);
  const width =
    options.width || options.shape === "square"
      ? stickyNoteSquareWidth
      : stickyNoteRectWidth;

  return ids.map(
    (uuid, i) =>
      new fabric.StickyNote("", {
        width,
        uuid,
        ...commonOptions,
        ...configPerNote[i],
      })
  );
};
