import { Form, Formik, FormikConfig } from "formik";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { object, string } from "yup";

import { useSelector } from "react-redux";
import { addSubscriptionDevice } from "../../../../../api";
import Display from "../../../../../icons/Display";
import Team from "../../../../../icons/Team";
import { selectSubscriptionDetails } from "../../../../../reduxStore/subscriptionDetails/subscriptionDetails.reducer";
import InputField from "../../../../shared/forms/fields/InputField";
import Modal from "../../../../shared/modals";
import ConfirmationButtons from "../../../../shared/modals/ConfirmationButtons";
import ModalHeader from "../../../../shared/modals/ModalHeader";
import { ModalStateProps } from "../../../../shared/modals/useConfirmModal";
import { withModalTemplate } from "../../../../shared/modals/withModalTemplate";
import LicenseItem from "../License/LicenseItem";

type Props = {
  onAddDevice(): void;
  refetchDevices(): void;
  modalProps: ModalStateProps;
};

type FormProps = {
  code: string;
  name: string;
};

function AddDeviceModal({
  onAddDevice,
  refetchDevices,
  modalProps,
}: Props): ReactElement {
  const { t } = useTranslation();
  const {
    subscription,
    summary: { deviceCount, maxDevices },
  } = useSelector(selectSubscriptionDetails);
  const subscriptionID = subscription?.ID || -1;

  const formikProps: FormikConfig<FormProps> = {
    validationSchema: object().shape({
      code: string().required(t("clientError.required")),
      name: string(),
    }),
    initialValues: {
      code: "",
      name: "",
    },
    onSubmit: ({ code, name }, actions) => {
      addSubscriptionDevice({
        subscriptionID,
        code,
        name,
        active: true,
      })
        .then(() => {
          toast(t("team.deviceAdded"));
          refetchDevices();
          onAddDevice();
        })
        .finally(() => {
          actions.setSubmitting(false);
          modalProps.onClose();
        });
    },
  };

  return (
    <Formik {...formikProps}>
      {({ submitForm, isValid }) => (
        <Form>
          <Modal
            header={
              <ModalHeader title={t("team.addNewDevice")} Icon={Display} />
            }
            {...modalProps}
            actionButtons={
              <ConfirmationButtons
                confirmationLabel={t("team.addNewDeviceButton")}
                onConfirm={submitForm}
                confirmationDisabled={!isValid}
                onClose={modalProps.onClose}
              />
            }
          >
            <LicenseItem
              label={t("team.devicesAvailable")}
              count={deviceCount}
              max={maxDevices}
              icon={Team}
            />

            <InputField
              required
              type="text"
              name="code"
              placeholder={t("team.deviceCodePlaceholder")}
            />
            <InputField
              type="text"
              name="name"
              placeholder={t("team.deviceNamePlaceholder")}
            />
          </Modal>
        </Form>
      )}
    </Formik>
  );
}

export default withModalTemplate(AddDeviceModal);
