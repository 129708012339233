import React, { ReactElement } from "react";
import { Icon } from "../../icons/Icon";

function Logo({ colorized, size, ...rest }: LogoProps): ReactElement {
  return (
    <Icon noShadow alignMiddle {...rest}>
      <path d="M39.677 9.475a9.205 9.205 0 1 1-18.41 0 9.205 9.205 0 0 1 18.41 0z" />
      <path d="M18.683 30.461a9.197 9.197 0 0 1-9.197 9.197c-5.08 0-9.198-4.117-9.198-9.197 0-5.079 4.119-9.196 9.198-9.196a9.197 9.197 0 0 1 9.197 9.196z" />
      <path
        fill={colorized ? "var(--primary)" : undefined}
        d="M37.065 23.478c-.838-.754-2.59-1.88-5.68-1.992-.217-.008-.612-.047-.745-.032-.27.019-.296.009-.57.009-6.4 0-11.586-5.186-11.586-11.586 0-.391.07-.955.065-1.159.03-2.573-1.11-4.828-1.75-5.507-1.636-1.82-4.19-2.94-6.903-2.94C4.59.271.289 4.572.289 9.877c0 2.719 1.1 5.189 2.949 6.916.893.747 2.623 1.79 5.517 1.851.045.001.083.003.123.002.338-.008.67-.042 1.018-.046 6.324-.063 11.45 5.127 11.45 11.452 0 .191.007.351-.002.539.019.116.015.291.018.486.044 3.014 1.079 4.778 1.813 5.656a9.566 9.566 0 0 0 6.895 2.926 9.607 9.607 0 0 0 9.607-9.607c0-2.547-1.002-5.03-2.612-6.574z"
        {...rest}
      />
    </Icon>
  );
}

export default Logo;
