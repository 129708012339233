import { Form, Formik, FormikConfig } from "formik";
import React, { ReactElement, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { Button, Flex, Text, ThemeUIStyleObject } from "theme-ui";
import { object, string } from "yup";
import { authenticateUser, sendUserOTPToken } from "../../../api";
import OneTimePassword from "../../../icons/OneTimePassword";
import { LogCategory } from "../../../tools/telemetry";
import { maxOtpCodeLength } from "../../../validationSchemas/fieldRestrictions";
import InputField from "../../shared/forms/fields/InputField";
import { useErrorHandler } from "../../shared/hooks/useErrorHandler";
import Modal from "../../shared/modals";
import ConfirmationButtons from "../../shared/modals/ConfirmationButtons";
import { TemplateProps } from "../../shared/modals/Modal";
import ModalHeader from "../../shared/modals/ModalHeader";
import { withModalTemplate } from "../../shared/modals/withModalTemplate";
import { useAuthenticatingStatus } from "../useAuthenticatingStatus";

type Props = {
  onError: (error: unknown) => void;
  onSuccess: (response: ApiValidatedAuthenticate | ApiAuthenticate) => void;
  UserName: string;
};

type OTPFormValues = {
  UserName: string;
  OTPCode: string;
};

function OTPModalTemplate({
  modalProps,
  onError,
  onSuccess,
  UserName,
}: TemplateProps<Props>): ReactElement {
  const { t } = useTranslation();
  const [sendOTPToken, { isLoading: isSendingOTPToken }] = useMutation(
    sendUserOTPToken
  );
  const { fallbackErrorHandler } = useErrorHandler();
  const { clearAuthenticatingStatus } = useAuthenticatingStatus();

  const onSubmit = useCallback(
    async ({ UserName, OTPCode }: OTPFormValues) => {
      try {
        const response = await authenticateUser(
          UserName,
          // Add leading zeros that will be dropped by number input
          String(OTPCode).padStart(maxOtpCodeLength, "0")
        );
        onSuccess(response);
      } catch (error) {
        onError(error);
      }
    },
    [onError, onSuccess]
  );

  /**
   * If the user dismisses this modal we need to clear the auth state.
   */
  const onClose = useCallback(() => {
    // This actually closes the modal, despite the name
    modalProps.onClose();
    clearAuthenticatingStatus();
  }, [modalProps, clearAuthenticatingStatus]);

  const formikProps: FormikConfig<OTPFormValues> = {
    initialValues: {
      UserName,
      OTPCode: "",
    },
    onSubmit,
    validationSchema: object().shape({
      UserName: string().required(t("clientError.required")),
      OTPCode: string()
        .max(
          maxOtpCodeLength,
          t("clientError.tooLongMax", { max: maxOtpCodeLength })
        )
        .required(t("clientError.required")),
    }),
    /**
     * Disable validateOnBlur so that validation messages don't appear (causing
     * a layout shift) when the user clicks on the close modal button or
     * send OTP code button.
     */
    validateOnBlur: false,
  };

  const onSendClick = useCallback(async () => {
    try {
      await sendOTPToken({ User: UserName });
      toast(t("dialog.otp.OTPCodeSent", { email: UserName }));
    } catch (error) {
      fallbackErrorHandler(error, "Unable to send OTP code", LogCategory.auth);
    }
  }, [UserName, fallbackErrorHandler, sendOTPToken, t]);

  return (
    <Formik {...formikProps}>
      {({ isValid, handleBlur, handleChange, submitForm }) => (
        <Modal
          {...modalProps}
          onClose={onClose}
          header={
            <ModalHeader
              title={t("dialog.otp.header")}
              Icon={OneTimePassword}
            />
          }
          closeOnDimmerClick={false}
          actionButtons={
            <ConfirmationButtons
              onClose={modalProps.onClose}
              confirmationLabel={t("dialog.otp.verifyButtonLabel")}
              confirmationDisabled={!isValid}
              onConfirm={submitForm}
              closeOnConfirm={false}
            />
          }
        >
          <Form>
            <Text sx={textStyle}>
              {t("dialog.otp.message", { email: UserName })}
            </Text>

            <InputField
              name="OTPCode"
              /**
               * @NOTE We use a number input to trigger the numeric keyboard on
               * touch devices, but that means leading zeros will be dropped
               * from the resulting value (as it is a number not a string), so
               * we need to add them back in when we use the value.
               */
              type="number"
              maxLength={maxOtpCodeLength}
              sx={inputStyle}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t("dialog.otp.inputPlaceholder")}
            />
          </Form>
          <Flex sx={sendContainerStyle}>
            <Button
              type="button"
              sx={sendButtonStyle}
              variant="content"
              disabled={isSendingOTPToken}
              onClick={onSendClick}
            >
              {t("dialog.otp.otpEmailButtonLabel")}
            </Button>
          </Flex>
        </Modal>
      )}
    </Formik>
  );
}

export default withModalTemplate(OTPModalTemplate);

const inputStyle: ThemeUIStyleObject = {
  mb: [2],
  "input[type=number]": {
    "::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
    },
  },
};
const textStyle: ThemeUIStyleObject = { mb: [4] };
const sendContainerStyle: ThemeUIStyleObject = { justifyContent: "flex-end" };
const sendButtonStyle: ThemeUIStyleObject = { fontSize: [3], mr: [1] };
