import { combineReducers } from "redux";
import { canvasAppReducer } from "./app/app.reducer";
import { CanvasAction, CanvasActionType } from "./canvas.actions";
import { chatReducer } from "./chat/chat.reducer";
import { drawingReducer } from "./drawing/drawing.reducer";
import { historyReducer } from "./history/history.reducer";
import { meetingReducer } from "./meeting/meeting.reducer";
import { canvasObjectsReducer } from "./objects/objects.reducer";
import { presentationReducer } from "./presentation/presentation.reducer";
import { canvasProjectReducer } from "./project/project.reducer";
import { selectionReducer } from "./selection/selection.reducer";
import { canvasSettingsReducer } from "./settings/settings.reducer";
import { timedSessionReducer } from "./timedSession/timedSession.reducer";
import { userPresenceReducer } from "./userPresence/userPresence.reducer";
import { userSettingsReducer } from "./userSettings/userSettings.reducer";
import { votingReducer } from "./voting/voting.reducer";

const combinedReducers = combineReducers<ApplicationCanvasState>({
  app: canvasAppReducer,
  chat: chatReducer,
  drawing: drawingReducer,
  history: historyReducer,
  meeting: meetingReducer,
  objects: canvasObjectsReducer,
  presentation: presentationReducer,
  project: canvasProjectReducer,
  selection: selectionReducer,
  settings: canvasSettingsReducer,
  timedSession: timedSessionReducer,
  userPresence: userPresenceReducer,
  userSettings: userSettingsReducer,
  voting: votingReducer,
});

export function canvasReducer(
  state: ApplicationCanvasState | undefined,
  action: CanvasAction
): ApplicationCanvasState {
  if (action.type === CanvasActionType.ON_CANVAS_EXIT) {
    // Reset the canvas state to the default values
    return combinedReducers(undefined, action);
  }

  return combinedReducers(state, action);
}
