import { useQuery } from "react-query";
import { add, formatISO } from "date-fns";

import { getPromoCode, getSubscription } from "../../../api";
import { subscriptionStatuses } from "../payment/enums";

export type RedemptionModalProps = {
  code: string;
  onConfirm: () => void;
  onClose: () => void;
  redeem: (arg0: string, arg2: number | null) => Promise<void>;
};

type Returns = {
  handleConfirm: () => Promise<void>;
  shouldUpgrade: boolean;
  current?: ApiManageableSubscription | null;
  newSubscriptionDetails?: {
    NumberOfUsers: number;
    NumberOfDevices: number;
    ActiveTo: string;
    ProductId: ApiProductIds;
  } | null;
  isLoading: boolean;
  isError: boolean;
};

const endOfTheWorld = "9999-12-31T23:59:59.997";

const getDetails = (
  current?: ApiManageableSubscription | null,
  promoCode?: ApiPromoCode
) => {
  if (!promoCode) {
    return {
      shouldUpgrade: false,
      newSubscriptionDetails: null,
    };
  }

  const shouldUpgrade =
    current?.Subscription?.Status === subscriptionStatuses.active;

  const base =
    current && shouldUpgrade
      ? {
          ...current.Subscription,
          ActiveTo: new Date(current.Subscription.ActiveTo),
        }
      : {
          NumberOfUsers: 0,
          NumberOfDevices: 0,
          ActiveTo: new Date(),
        };

  return {
    shouldUpgrade,
    newSubscriptionDetails: {
      ProductId: promoCode.ProductId,
      NumberOfUsers: base.NumberOfUsers + promoCode.NumberOfUsers,
      NumberOfDevices: base.NumberOfDevices + (promoCode.NumberOfDevices || 0),
      ActiveTo:
        promoCode.NumberOfFreeMonths > 1000 ||
        base.ActiveTo.getFullYear() > 9000
          ? endOfTheWorld
          : formatISO(
              add(base.ActiveTo, { months: promoCode.NumberOfFreeMonths })
            ),
    },
  };
};

export const useRedemptionDetailsModalTemplate = ({
  code,
  onConfirm,
  redeem,
}: RedemptionModalProps): Returns => {
  const {
    data: current,
    isLoading: isLoadingSubscription,
    isError: isErrorSubscription,
  } = useQuery<ApiManageableSubscription | null, Error>({
    queryKey: ["GetManageableSubscriptions"],
    queryFn: () => getSubscription(),
  });

  const {
    data: promoCode,
    isLoading: isLoadingPromoCode,
    isError: isErrorPromoCode,
  } = useQuery<ApiPromoCode, Error>({
    queryKey: ["getPromoCode", code],
    queryFn: () => getPromoCode({ PromoCode: code }),
    config: { retry: false },
  });

  const isLoading = isLoadingSubscription || isLoadingPromoCode;

  const isError = isErrorSubscription || isErrorPromoCode;

  const { newSubscriptionDetails, shouldUpgrade } = getDetails(
    current,
    promoCode
  );

  const handleConfirm = async () => {
    await redeem(
      code,
      shouldUpgrade && current ? current.Subscription.ID : null
    );
    onConfirm();
  };

  return {
    handleConfirm,
    shouldUpgrade,
    current,
    newSubscriptionDetails,
    isLoading,
    isError,
  };
};
