import React, { ReactElement } from "react";
import { Box, Image, ThemeUIStyleObject } from "theme-ui";
import { colorThemes } from "../../const";
import noAvatar from "./noAvatar.png";

export type AvatarProps = {
  theme?: colorThemes;
  enlarge?: boolean;
  AvatarUrl?: string | null | AvatarImageUrls;
  IsOnline?: boolean;
  ProjectColor?: string;
  placeholder?: ReactElement | string;
  sx?: ThemeUIStyleObject;
};

const { dark } = colorThemes;

function Avatar({
  AvatarUrl,
  theme = colorThemes.light,
  enlarge,
  IsOnline,
  ProjectColor,
  placeholder,
  sx,
}: AvatarProps): ReactElement {
  return AvatarUrl ? (
    <Image
      alt="Avatar"
      src={
        typeof AvatarUrl === "string"
          ? AvatarUrl
          : AvatarUrl[IsOnline ? theme : dark]
      }
      onError={({ currentTarget }) => (currentTarget.src = noAvatar)}
      variant={enlarge ? "avatar.enlarge" : "avatar"}
      sx={{
        borderColor: IsOnline ? ProjectColor : "var(--iconColor)",
        backgroundColor: IsOnline ? "var(--iconColor)" : ProjectColor,
        ...sx,
      }}
    />
  ) : (
    // placeholder circle when no image url is provided
    <Box
      sx={{
        ...placeholderStyle,
        height: enlarge ? "calc(var(--gridTile) * 1.4)" : "var(--gridTile)",
        width: enlarge ? "calc(var(--gridTile) * 1.4)" : "var(--gridTile)",
      }}
    >
      {placeholder ?? ""}
    </Box>
  );
}

const placeholderStyle: ThemeUIStyleObject = {
  bg: "black",
  color: "background",
  borderRadius: " 50%",
  flex: "0 0 auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "2px solid black",
  "> svg": {
    fill: "white",
  },
};

export default Avatar;
