import { isPromise } from "../../../tools/utils";
import { isCollection, isGroup } from "../../utils/fabricObjects";

/**
 * Applies the group transform to the objects and resets it for the group.
 * Then update objects' and group bounds and coords.
 *
 * The focus is on applying the transform to the objects so that their props become as if they were
 * ungrouped.
 *
 * @TODO Not sure if the group update part is still necessary. This was created much before the rest
 * of the functions in this file, so its use cases can probably be updated to use the other more-specific
 * alternatives.
 *
 * @param {boolean} skipParentGroup Temporary remove the group property so
 * that during the transform fabric's `calcTransformMatrix` won't reapply
 * the matrix of the parent group, e.g. an activeSelection with groups.
 * This means that you must take care of applying the parent's transform to the group
 * beforehand.
 */
export const applyTransformAndUpdate = (
  group: fabric.Group,
  skipParentGroup?: boolean
): void => {
  const parentGroup = group.group;
  skipParentGroup && (group.group = undefined);
  applyTransform(group);
  updateGroupFromAbsoluteCoords(group);
  skipParentGroup && (group.group = parentGroup);
};

/**
 * Applies the group transform to the objects as if they were ungrouped.
 *
 * @NOTE This will also delete `.group` property from the objects. Be careful to ensure that it's
 * restored afterwards if the objects are still grouped.
 *
 * Behind the scenes, this multiplies group's transform matrix to object's own transform matrix. Notably, the
 * matrix's `translateX` and `translateY` values are affected by top, left, width and height. That's
 * because top and left refer to the origin of the group or object - typically originX: 'left', originY: 'top' -
 * but they are normalized to originX: 'center', originY: 'center', which needs width and height to
 * work correctly. See fabric's `calcTransformMatrix` and `getCenterPoint` methods.
 *
 * @NOTE This needs the group to have correct width and height properties.
 */
export const applyTransform = (group: fabric.Group): void => {
  group._restoreObjectsState();
  fabric.util.resetObjectTransform(group);
};

/**
 * Cheaply destroy a group. This is much more performant than doing `group.removeWithUpdate(obj)`
 * for each child object.
 *
 * @NOTE Children transform props are correctly reset. You don't have to worry about that.
 *
 * @NOTE Get a reference of the children with `group.getObjects()` before calling this function if
 * you need it afterwards
 */
export const applyTransformAndDestroy = (group: fabric.Group): void => {
  // First reset all children transforms and delete `.group` property. This internally calls `_restoreObjectsState`
  group.destroy();
  // Then wipe out the objects
  group._objects = [];
};

/**
 * Update group and children coords. This is "update" part that fabric internally
 * does in `addWithUpdate` or `removeWithUpdate`.
 *
 * @NOTE READ ALL THE NOTES BEFORE USING THIS FUNCTION OTHERWISE YOU'RE GONNA BE FIRED 🔫
 *
 * @NOTE Use this function to cheaply first add/remove all the objects in the group using `group.add(obj)` or
 * `group.remove(obj)`. Then call `updateGroup` to update the final coords in a single go. This is
 * much more performant than doing `group.addWithUpdate(obj)` or `group.removeWithUpdate(obj)` for each children.
 *
 * @NOTE If you just need to destroy the group, use `applyTransformAndDestroy(group)` as you don't care
 * about the group's final coords.
 */
export const updateGroupFromAbsoluteCoords = (group: fabric.Group): void => {
  /**
   * @NOTE This updates the group bounds by checking the objects coords but it assumes that their
   * `.top/.left` values are absolute, e.g. after a `group.add(obj)` operation.
   *
   * @NOTE This means that you have to ensure that all group objects have absolute coords, as if they were
   * all ungrouped.
   *
   * Specifically this updates the group's width, height, top and left.
   */
  group._calcBounds();
  /**
   * This will update the object coords wrt to the group center. Objects coords are not absolute anymore after.
   *
   * @NOTE It will also restore their `.group` property
   */
  group._updateObjectsCoords();
  // This will update group's coords based on the values after the previous `_calcBounds()`
  group.setCoords();
  // This is the only simple instruction :)
  group.dirty = true;
};

/**
 * Update the group coords after some of the children have changed their properties, i.e. position, scale
 * or angle.
 *
 * @NOTE This expects the objects to have `.top/.left` values relative to the group center.
 */
export const updateGroupAfterUpdate = (group: fabric.Group): void => {
  /**
   * Ensure that the group has a correct width and height as it affects the matrix calculations. This
   * method works with both absolute and relative object coordinates.
   *
   * Read `applyTransform`'s notes for more information about the matrix calculations,
   */
  group._calcBounds(true);

  // Reset children transform as required by `updateGroupAfterAddOrRemove`
  applyTransform(group);

  // Then update group's bounds and coords.
  updateGroupFromAbsoluteCoords(group);
};

export const getParentCollection = (
  object: fabric.Object
): fabric.Group | fabric.Stack | undefined => {
  const group = object.group || getParentGroup(object);

  return group && isCollection(group) ? group : undefined;
};

/**
 * Strictly returns the parent group only if it's an user-created group, not stacks
 * or fabric's group
 */
export const getParentGroup = (
  object: fabric.Object
): fabric.Group | undefined => {
  const group = object.group || object.__stashedGroupRef;

  return group && isGroup(group) ? group : undefined;
};

/**
 * @NOTE You typically don't need to use this function. Try to make the logic work with `getParentGroup`
 * or `isInGroup`, instead of specifically checking if the object is in subselection.
 */
export const isInSubselection = (object: fabric.Object): boolean => {
  return !!object.__stashedGroupRef;
};

export function runWithoutSubselectionMode(
  canvas: fabric.CollaboardCanvas,
  callback: (props: {
    subselectedGroup: fabric.Group | undefined;
    isSubselectionMode: boolean;
    affectedObjects: fabric.Object[];
  }) => Promise<void>,
  target?: fabric.Object | fabric.Object[]
): Promise<void>;
export function runWithoutSubselectionMode(
  canvas: fabric.CollaboardCanvas,
  callback: (props: {
    subselectedGroup: fabric.Group | undefined;
    isSubselectionMode: boolean;
    affectedObjects: fabric.Object[];
  }) => void,
  target?: fabric.Object | fabric.Object[]
): void;

export function runWithoutSubselectionMode(
  canvas: fabric.CollaboardCanvas,
  callback: (props: {
    subselectedGroup: fabric.Group | undefined;
    isSubselectionMode: boolean;
    affectedObjects: fabric.Object[];
  }) => void | Promise<void>,
  target?: fabric.Object | fabric.Object[]
): void | Promise<void> {
  const subselectedGroup = canvas.__subselectedGroupRef;
  const affectedTarget = !target
    ? []
    : Array.isArray(target)
    ? [...target]
    : [target];

  subselectedGroup && subselectedGroup.groupAgainOnSubselectionModeEnd(true);
  const affectedObjects = subselectedGroup
    ? [subselectedGroup, ...subselectedGroup.getObjects()]
    : affectedTarget;

  const returned = callback({
    subselectedGroup,
    isSubselectionMode: !!subselectedGroup,
    affectedObjects,
  });

  if (!subselectedGroup) {
    return undefined;
  }

  if (isPromise(returned)) {
    return returned.then(() => {
      subselectedGroup.temporarilyUngroup();
      canvas.__subselectedGroupRef = subselectedGroup;
    });
  } else {
    subselectedGroup.temporarilyUngroup();
    canvas.__subselectedGroupRef = subselectedGroup;
    return undefined;
  }
}

/**
 * Disable stashing groups (for snapping and chat creation) during the callback execution to avoid
 * issues caused by objects temporarily being ungrouped and in the canvas, e.g. #7228.
 */
export function runWithoutStashedGroups(
  canvas: fabric.CollaboardCanvas,
  callback: () => Promise<void>
): Promise<void>;
export function runWithoutStashedGroups(
  canvas: fabric.CollaboardCanvas,
  callback: () => void
): void;

export function runWithoutStashedGroups(
  canvas: fabric.CollaboardCanvas,
  callback: () => void | Promise<void>
): void | Promise<void> {
  const stashedGroups = canvas.stashedGroups;

  stashedGroups && canvas.recreateGroups();
  canvas.disableStashedGroups = true;

  const returned = callback();

  if (isPromise(returned)) {
    return returned.then(() => {
      stashedGroups && canvas.stashGroups(stashedGroups);
      canvas.disableStashedGroups = false;
    });
  } else {
    stashedGroups && canvas.stashGroups(stashedGroups);
    canvas.disableStashedGroups = false;
    return undefined;
  }
}
