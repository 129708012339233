import { useEffect, useState, useCallback, useMemo } from "react";
import { useQuery } from "react-query";
import { filter } from "ramda";

import { billingTypes } from "../../licensing/payment/enums";
import { calculatePercent } from "../../licensing/payment/helpers";
import { getPrice } from "../../../api";
import { priceQueryStaleTime } from "./consts";
import { objectMap } from "../../../tools/utils";

export const fetchPrice = (
  key,
  {
    productId,
    billingType,
    currency,
    promoCode = "",
    numberOfDevices = 0,
    numberOfUsers = 1,
  }
) =>
  getPrice({
    productId,
    billingType,
    currency: currency.code,
    promoCode,
    numberOfDevices,
    numberOfUsers,
  });

const usePriceQuery = ({
  productId,
  billingType,
  currency,
  enabled = true,
}) => {
  const { data } = useQuery({
    queryKey: ["GetPrice", { productId, billingType, currency }],
    queryFn: fetchPrice,
    config: {
      staleTime: priceQueryStaleTime,
      enabled,
    },
  });

  return data?.Price?.AmountPerUser;
};

export const useTotalPriceQuery = ({
  billingType,
  currencyCode,
  userCount = 1,
  promoCodeDisabled,
  promoCode,
  queryKey,
  productId,
  MinNumberOfUsers = 1,
}) => {
  const { data, isSuccess, isError, error: totalError } = useQuery({
    queryKey: [
      queryKey,
      {
        productId,
        billingType,
        currency: { code: currencyCode },
        numberOfUsers: userCount,
        promoCodeDisabled,
      },
    ],
    queryFn: (key, args) => fetchPrice(key, { ...args, promoCode }),
    config: {
      enabled:
        Boolean(currencyCode) &&
        (!promoCode || promoCodeDisabled) &&
        userCount &&
        userCount >= MinNumberOfUsers,
      staleTime: priceQueryStaleTime,
      retry: false,
    },
  });

  return {
    total: data?.TotalAmount,
    numberOfFreeMonths:
      (isSuccess && !totalError && data?.Promo?.NumberOfFreeMonths) || null,
    isSuccess,
    isError: isError || userCount < MinNumberOfUsers,
    totalError,
  };
};

export const usePrices = (currency, plans) => {
  const [savings, setSavings] = useState({});

  const [
    individualAnnuallyParams,
    individualMonthlyParams,
    teamAnnuallyParams,
    teamMonthlyParams,
    businessAnnuallyParams,
    businessMonthlyParams,
  ] = Object.keys(plans)
    // disregard free and enterprise plans
    .filter((key) => plans[key].hasPrice)
    // for each plan generate params for both billing types
    .flatMap((key) => {
      const common = {
        currency,
        productId: plans[key].productTypeId,
        enabled: Boolean(plans[key]),
      };

      return [
        { ...common, billingType: billingTypes.annually },
        { ...common, billingType: billingTypes.monthly },
      ];
    });

  const individualAnnually = usePriceQuery(individualAnnuallyParams);
  const individualMonthly = usePriceQuery(individualMonthlyParams);

  const teamAnnually = usePriceQuery(teamAnnuallyParams);
  const teamMonthly = usePriceQuery(teamMonthlyParams);

  const businessAnnually = usePriceQuery(businessAnnuallyParams);
  const businessMonthly = usePriceQuery(businessMonthlyParams);

  const prices = useMemo(
    () => ({
      individual: {
        annually: individualAnnually,
        monthly: individualMonthly,
      },
      team: {
        annually: teamAnnually,
        monthly: teamMonthly,
      },
      business: {
        annually: businessAnnually,
        monthly: businessMonthly,
      },
    }),
    [
      businessAnnually,
      businessMonthly,
      individualAnnually,
      individualMonthly,
      teamAnnually,
      teamMonthly,
    ]
  );

  const pricesFetchedSuccessfully = Object.entries(prices).every(
    // set to true only when monthly and annual prices are fetched for each available plan
    ([key, price]) => !plans[key] || (price.monthly && price.annually)
  );

  const getSavingsObject = useCallback(() => {
    const paidPlans = filter((plan) => plan.hasPrice, plans);

    return objectMap(paidPlans, (plan, key) =>
      calculatePercent(prices[key].monthly, prices[key].annually / 12)
    );
  }, [plans, prices]);

  useEffect(() => {
    if (pricesFetchedSuccessfully) {
      setSavings(getSavingsObject());
    }
  }, [pricesFetchedSuccessfully, currency, getSavingsObject]);

  return {
    savings,
    pricesFetchedSuccessfully,
    prices,
  };
};
