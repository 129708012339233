import {
  all,
  Effect,
  getContext,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import { selectObjects } from "../../../../studio/components/patches/extends-selection/selection.utils";
import { activateEditMode } from "../../../../studio/utils/fabricObjects";
import { createCanvasObjects } from "../../../../studio/utils/objectConverter";
import { stringifyError } from "../../../../tools/errors";
import { errorToast } from "../../../../tools/errorToast";
import { selectIsViewOnlyMode } from "../../app/app.reducer";
import { addedAction } from "../../history/history.entry.actions";
import {
  AddNewObjectAction,
  ObjectsCRUDActionType,
  RemoveObjectByUuidAction,
  SetObjectPropsAction,
} from "./crud.actions";
import { updateAllObjects, updateSingleObject } from "./crud.saga.utils";

function* addCanvasObject({ payload }: AddNewObjectAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  const { object, config } = payload;
  const { pointer, preservePosition } = config || {};

  try {
    const objects: fabric.Object[] = yield createCanvasObjects(object);

    const randomShift =
      pointer || objects.length > 1
        ? new fabric.Point(0, 0)
        : new fabric.Point(
            Math.floor(Math.random() * 200) - 100,
            Math.floor(Math.random() * 200) - 100
          );

    !preservePosition &&
      objects.forEach((object, idx) => {
        const newObjectShift = canvas.getNewObjectShift(
          idx,
          objects.length,
          object.width,
          object.height,
          true
        );

        const position = canvas
          .toCanvasPoint(pointer)
          .add(newObjectShift)
          .add(randomShift);

        canvas._centerObject(object, position);
      });

    yield canvas.fxAdd(...objects);
    canvas.nestInGroups(objects); // create lightweight dependency (attach to parent)

    if (objects.length) {
      yield put(addedAction(objects));
    }

    if (objects.length === 1) {
      canvas.setActiveObject(objects[0], { isSilent: true });

      activateEditMode(canvas.getObjects(), objects[0]);
      return;
    }

    selectObjects(canvas, objects, { isSilent: true });
  } catch (err) {
    errorToast(stringifyError(err));
  }
}

function* copyObject() {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  canvas.copyObject();
}

function* duplicateCanvasObject() {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  canvas.duplicateObject();
  canvas.discardActiveObject();
}

function* cutObject() {
  // TODO: generally works, but cutting images / docs is problematic
  // for now "cut objects" feature is not demanded. Uncomment and fix later on.
  // canvas.copyObject();
  // canvas.removeSelectedObjects();
  yield null;
}

function* removeSelectedObject() {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  canvas.removeSelectedObjects();
}

function* removeObjectByUuid({ payload }: RemoveObjectByUuidAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  const { uuid, reversible } = payload;
  const object = canvas.getObjectByUUID(uuid);
  if (object) {
    canvas.removeObjects([object], reversible);
  }
}

function* setObjectProps(action: SetObjectPropsAction) {
  if (selectIsViewOnlyMode(yield select())) {
    return;
  }

  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const { objectProps, updateAllOfType } = action.payload;

  if (updateAllOfType) {
    updateAllObjects(canvas, objectProps, updateAllOfType);
  } else {
    updateSingleObject(canvas, objectProps);
  }
}

export function* objectsCRUDSaga(): Generator<Effect> {
  yield all([
    takeEvery(ObjectsCRUDActionType.ADD_CANVAS_OBJECT, addCanvasObject),
    takeEvery(ObjectsCRUDActionType.COPY_CANVAS_OBJECT, copyObject),
    takeEvery(ObjectsCRUDActionType.CUT_CANVAS_OBJECT, cutObject),
    takeEvery(
      ObjectsCRUDActionType.DUPLICATE_CANVAS_OBJECT,
      duplicateCanvasObject
    ),
    takeEvery(
      ObjectsCRUDActionType.REMOVE_SELECTED_OBJECT,
      removeSelectedObject
    ),
    takeEvery(ObjectsCRUDActionType.REMOVE_OBJECT_BY_UUID, removeObjectByUuid),
    takeEvery(ObjectsCRUDActionType.SET_OBJECT_PROPS, setObjectProps),
  ]);
}
