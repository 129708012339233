import { useCallback } from "react";
import { isError } from "react-query";
import { useDispatch } from "react-redux";

import { getSubscription } from "../../api";
import { stringifyError } from "../../tools/errors";
import { errorToast } from "../../tools/errorToast";
import { getSubscriptionDone } from "./subscriptionDetails.actions";

type UseSubscriptionResult = {
  getSubscription: () => void;
};

export const useSubscription = (): UseSubscriptionResult => {
  const dispatch = useDispatch();

  return {
    getSubscription: useCallback(() => {
      return getSubscription()
        .then((subscription) => dispatch(getSubscriptionDone(subscription)))
        .catch((error) =>
          errorToast(isError(error) ? error : stringifyError(error))
        );
    }, [dispatch]),
  };
};
