import Schools from "../../../icons/Schools";
import NPO from "../../../icons/NPO";
import { allPlans, contactPageUrl } from "../../../const";
import { getFeatures } from "./features";
import { getCustomerConfiguration } from "../../../tools/customer";
import { ApiLanguage } from "../../../types/enum";

const { visiblePlans } = getCustomerConfiguration();
const isPlanVisible = (planName: PlanNames) =>
  visiblePlans ? visiblePlans.includes(planName) : true;

export const initialPlans: Record<PlanNames, Plan> = {
  free: {
    nameKey: "plans.free",
    infoKey: "plans.freeInfo",
    licenseKey: "plans.freeLicense",
    priceDetailsKey: "plans.freePriceDetails",
    productTypeId: allPlans.free.productTypeId,
    name: allPlans.free.name,
    priceAnnually: 0,
    maxNumberOfUsers: 1,
    inGrid: true,
    isVisible: isPlanVisible("free"),
    features: getFeatures("free"),
    hasPrice: false,
  },
  individual: {
    nameKey: "plans.individual",
    infoKey: "plans.individualInfo",
    licenseKey: "plans.individualLicense",
    priceDetailsKey: "plans.userMonthAnnually",
    productTypeId: allPlans.individual.productTypeId,
    name: allPlans.individual.name,
    maxNumberOfUsers: 1,
    inGrid: true,
    isVisible: isPlanVisible("individual"),
    features: getFeatures("individual"),
    hasPrice: true,
  },
  team: {
    nameKey: "plans.team",
    infoKey: "plans.teamInfo",
    licenseKey: "plans.teamLicense",
    priceDetailsKey: "plans.userMonthAnnually",
    productTypeId: allPlans.team.productTypeId,
    name: allPlans.team.name,
    maxNumberOfUsers: null,
    inGrid: true,
    isVisible: isPlanVisible("team"),
    features: getFeatures("team"),
    hasPrice: true,
  },
  business: {
    nameKey: "plans.business",
    infoKey: "plans.businessInfo",
    licenseKey: "plans.businessLicense",
    priceDetailsKey: "plans.userMonthAnnually",
    productTypeId: allPlans.business.productTypeId,
    name: allPlans.business.name,
    maxNumberOfUsers: null,
    inGrid: true,
    isVisible: isPlanVisible("business"),
    features: getFeatures("business"),
    hasPrice: true,
  },
  enterprise: {
    nameKey: "plans.enterprise",
    infoKey: "plans.enterpriseInfo",
    licenseKey: "plans.enterpriseLicense",
    priceDetailsKey: null,
    productTypeId: allPlans.enterprise.productTypeId,
    name: allPlans.enterprise.name,
    priceAnnually: null,
    maxNumberOfUsers: null,
    inGrid: false,
    isVisible: isPlanVisible("enterprise"),
    features: getFeatures("enterprise"),
    hasPrice: false,
  },
};

export const others: OtherPlan[] = [
  {
    nameKey: "plans.others.schools",
    infoKey: "plans.others.schoolsInfo",
    Icon: Schools,
    getLink: (language: string): string =>
      `https://www.collaboard.app/${
        language === ApiLanguage.german ? "de/" : ""
      }collaboard-for-education`,
  },
  {
    nameKey: "plans.others.npo",
    infoKey: "plans.others.npoInfo",
    Icon: NPO,
    getLink: (): string => contactPageUrl,
  },
];

export const getFreeTrialLink = (language: string): string =>
  `https://www.collaboard.app/${
    language === ApiLanguage.german ? "de/" : ""
  }request-your-collaboard-trial`;

export const priceQueryStaleTime = 10 * 60 * 1000; // 10 min
