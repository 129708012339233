import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Flex } from "theme-ui";
import { useUISettings } from "../../reduxStore/uiSettings";

function Disclaimer(): ReactElement | null {
  const { t } = useTranslation();
  const { getTranslation } = useUISettings();
  const disclaimerText = t(getTranslation("app.disclaimer"));
  return disclaimerText ? (
    <Flex
      sx={{
        mx: [4],
        my: [2],
        p: [1],
        backgroundColor: "disclaimerBg",
        color: "disclaimerText",
        fontSize: 2,
        fontWeight: "bold",
      }}
    >
      {disclaimerText}
    </Flex>
  ) : null;
}

export default Disclaimer;
