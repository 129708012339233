import { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link as Anchor } from "react-router-dom";
import {
  Flex,
  Link,
  LinkProps as AnchorProps,
  SxStyleProp,
  Text,
} from "theme-ui";
import { routes } from "../../../../../const";
import Lock from "../../../../../icons/Lock";
import { selectAssertedUserProfile } from "../../../../../reduxStore/auth/auth.reducer";
import {
  generateAvatars,
  generateUnknownIdentity,
} from "../../../../../tools/avatarGenerator";
import Avatar from "../../../../users/Avatar";
import { patchUserIdentity } from "../../../../users/users.utils";
import { byDateFlex } from "../gridFlex";
import ProjectActions from "../ProjectActions";
import EditProjectDescriptionField from "./EditProjectDescriptionField";
import ProjectDescriptionField from "./ProjectDescriptionField";
import ProjectTagList from "./ProjectTagList";

type Props = {
  projectWithMeta: ApiProject;
  isExpanded: boolean;
  isEditMode: boolean;
  isLocked: boolean;
  onExpand: () => void;
  onProjectEdited: (newProject: Project) => void;
  activateEditMode: (projectId: string | null) => void;
};

type LinkProps = AnchorProps &
  PropsWithRequiredChildren<{
    project: Project;
  }>;

function ProjectLink({ project, children, ...rest }: LinkProps): ReactElement {
  const { ProjectId } = project;
  const projectRoute = `${routes.collaboard}/${ProjectId}`;

  const anchorProps = { to: projectRoute };

  return (
    <Link
      as={Anchor}
      sx={{ display: "flex", textDecoration: "none", width: "100%" }}
      {...anchorProps}
      {...rest}
    >
      {children}
    </Link>
  );
}

const textStyle: SxStyleProp = {
  textAlign: ["left", null, "center"],
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  fontWeight: 300,
};

function ProjectResultByDate({
  projectWithMeta,
  onExpand,
  onProjectEdited,
  activateEditMode,
  isExpanded,
  isEditMode,
  isLocked,
}: Props): ReactElement {
  const { Project, Owner, IsLicensed } = projectWithMeta;
  const { Description, ProjectId } = Project;
  const { t } = useTranslation();
  const userProfile = useSelector(selectAssertedUserProfile);

  const ownerIdentity = useMemo(
    () =>
      Owner
        ? patchUserIdentity(Owner)
        : generateUnknownIdentity(Project.CreatedByUser || t("users.unknown")),
    [Owner, Project, t]
  );
  const ownerAvatar = useMemo(() => generateAvatars(ownerIdentity), [
    ownerIdentity,
  ]);

  return (
    <Flex
      id={`row-project-id-${ProjectId}`}
      sx={{
        backgroundColor: isExpanded ? "accent" : "transparent",
        borderColor: isExpanded ? "accent" : "var(--modalLine)",
        borderBottom: "1px solid",
        position: "relative",
        "> div": {
          px: [4],
          "&, > a": {
            overflow: "hidden",
            alignItems: "center",
          },
          "> div": {
            ...(IsLicensed ? {} : { color: "muted" }),
          },
        },
      }}
    >
      <Flex
        sx={{
          flex: byDateFlex.description,
          flexWrap: "wrap",
          fontWeight: isExpanded ? 700 : 300,
          input: {
            p: [4],
          },
        }}
      >
        <Flex sx={{ mx: [-4], width: "calc(100% + 2rem) !important" }}>
          <ProjectDescriptionField projectWithMeta={projectWithMeta}>
            <EditProjectDescriptionField
              project={projectWithMeta}
              onProjectEdited={onProjectEdited}
              activateEditMode={activateEditMode}
              isEditMode={isEditMode}
              isExpanded={isExpanded}
            >
              <ProjectLink
                project={Project}
                aria-label={Description || t("project.untitled")}
              >
                <Flex
                  sx={{
                    alignItems: "center",
                  }}
                >
                  <Text
                    sx={{
                      p: [4],
                      pr: "var(--gridTile)",
                      ...textStyle,
                      color: IsLicensed ? undefined : "muted",
                    }}
                  >
                    {Project.Description || t("project.untitled")}
                  </Text>
                </Flex>
              </ProjectLink>
            </EditProjectDescriptionField>
          </ProjectDescriptionField>
        </Flex>
      </Flex>
      <Flex sx={{ flex: byDateFlex.owner, py: [1, null, 3] }}>
        <ProjectLink project={Project}>
          <Avatar AvatarUrl={ownerAvatar} ProjectColor="white" />
          <Text
            sx={{
              ...textStyle,
              textAlign: "left",
              marginLeft: 4,
            }}
          >
            {`${ownerIdentity.FirstName} ${ownerIdentity.LastName}`}
          </Text>
        </ProjectLink>
      </Flex>
      <Flex sx={{ flex: byDateFlex.tags }}>
        {projectWithMeta.Tags.length ? (
          <ProjectTagList project={projectWithMeta} sx={textStyle} />
        ) : null}
      </Flex>
      <Flex
        sx={{
          flex: byDateFlex.actions,
          padding: [1, null, 3],
          justifyContent: "space-between",
        }}
      >
        <ProjectActions
          projectWithMeta={projectWithMeta}
          isExpanded={isExpanded}
          onExpand={onExpand}
          isProjectOwner={userProfile?.UserName === Owner?.UserName}
        />
      </Flex>
      {isLocked ? (
        <Flex
          variant="layout.projectThumbnail.overlay"
          sx={{
            cursor: "not-allowed",
          }}
        >
          <Flex
            variant="layout.projectThumbnail.overlayLabel"
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Lock noShadow /> {t("app.projectLocked")}
          </Flex>
        </Flex>
      ) : null}
    </Flex>
  );
}

export default ProjectResultByDate;
