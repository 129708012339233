import imageCompression from "browser-image-compression";
import i18n from "i18next";
import mime from "mime";
import PQueue from "p-queue";
import { ExternalResourceError } from "../errors/externalResourceError";
import { setSVGFileDimensions } from "./files/svg";
import {
  developmentFlags,
  isDevFlagActive,
  isStaticFeatureActive,
  staticFeatureFlags,
} from "./flags";
import { runtimeConfig } from "./runtimeConfig";

/**
 * TODO: split this file in appropriate sub-files in files/ and update the imports
 */

type FetchImageConfig = {
  isOnScreen?: boolean;
  bypassQueue?: boolean;
  waitDecode?: boolean; // decode fails for big dataUrl for internal browser reasons
};

const disableCorsAnywhere =
  !runtimeConfig.corsSafeUrl ||
  isStaticFeatureActive(staticFeatureFlags.DISABLE_CORS_ANYWHERE);

const disableImageQueueThrottling = isDevFlagActive(
  developmentFlags.DISABLE_IMAGE_QUEUE_THROTTLING
);
const disableTimeouts = isDevFlagActive(developmentFlags.DISABLE_TIMEOUTS);

const fetchImageQueue = new PQueue({
  concurrency: disableImageQueueThrottling ? Infinity : 2,
  timeout: disableTimeouts ? undefined : 60_000,
  throwOnTimeout: true, // Prevents timed out requests from resolving as `undefined`
});

export const clearFetchImageQueue = (): void => {
  // Note, this simply clears the queue - it doesn't abort requests
  fetchImageQueue.clear();
};

export const corsSafeUrl = (url: string): string => {
  if (!runtimeConfig.corsSafeUrl) {
    // TODO: Localize error message
    throw new Error("CORS Safe URL not defined");
  }
  return `${runtimeConfig.corsSafeUrl}/${url}`;
};

export const readAndCompressImage = async (
  image: File,
  maxSizeKB: number
): Promise<string> => {
  const file =
    image.size <= 1024 * maxSizeKB
      ? image
      : await imageCompression(image, {
          maxSizeMB: maxSizeKB / 1024,
          maxWidthOrHeight: 256,
        });

  const dataUrl = await readFileAsDataUrl(file);

  return removeDataURLDeclaration(dataUrl);
};

export const isEncodableImageExt = (fileName: string): boolean => {
  return /\.(?:tiff?)|(?:bmp)|(?:exif)$/i.test(fileName);
};

export const isEncodableImageType = (fileType: string): boolean => {
  const encodableTypes = [
    "image/tiff",
    "image/tif",
    "image/bmp",
    "image/bitmap",
  ];

  return encodableTypes.includes(fileType.toLowerCase());
};

/**
 * Determine if the image requires encoding before use (i.e. it is not web safe)
 */
export const requiresConversionToPng = (
  fileName: string,
  fileType: string
): boolean => {
  return isEncodableImageExt(fileName) || isEncodableImageType(fileType);
};

export const fetchImage = async (
  url: string,
  config?: FetchImageConfig
): Promise<HTMLImageElement> => {
  const isDataUrl = url.startsWith("data:");
  const fileName = getFileNameFromUrl(url);
  const fileType = isDataUrl ? getDataUrlParts(url).fileType : "";
  const isSVG = /\.(?:svg)$/i.test(fileName);

  const requiresEncoding = requiresConversionToPng(fileName, fileType);

  if (requiresEncoding) {
    const dataUrl = await fetchImageAsPngBase64(url);
    return loadImagePromise(dataUrl, config);
  }

  const image = await loadImagePromise(url, config).catch((err) => {
    // Sometimes the load of an SVG can fail because of an incorrect
    // Content-Type response from the server. Retry as Base64.
    if (isSVG) {
      return fetchImageAsSvgBase64(url, fileName, config);
    }
    return Promise.reject(err);
  });

  // The SVG doesn't have dimensions so we need to reload it as Base64.
  if (isSVG && (image.width === 0 || image.height === 0)) {
    return fetchImageAsSvgBase64(url, fileName, config);
  }

  return image;
};

export const lookupFileType = async (url: string): Promise<string> => {
  const response = await fetch(url, {
    method: "HEAD",
    /**
     * Some sites redirect cross-domain requests to a different file, e.g. an
     * error page instead of the image. Ensure that if this happens the request
     * fails.
     */
    redirect: "error",
  });

  if (!response.ok) {
    // TODO: Localize error message
    return Promise.reject(
      new ExternalResourceError("Unable to lookup file", url)
    );
  }

  const type = response.headers.get("content-type");

  if (!type) {
    // TODO: Localize error message
    return Promise.reject(
      new ExternalResourceError("Unspecified file type", url)
    );
  }

  return type;
};

/**
 * Attempt to load image File from image config.
 */
export const fetchImageAsFile = async (
  fileName: string,
  fileType: string,
  src: string,
  srcSet: string
): Promise<File> => {
  return urlToFile(src, fileName)
    .catch((err) => {
      if (disableCorsAnywhere) {
        return Promise.reject(err);
      }
      const corsBypassUrl = corsSafeUrl(src);
      return urlToFile(corsBypassUrl, fileName, {
        // When a request goes through the CORS proxy redirects are not
        // detected and the website may return an unexpected file type
        getTypeFromHeader: true,
      }).then((file) => {
        if (file.type !== fileType) {
          return Promise.reject(
            new ExternalResourceError(
              "clientError.unexpectedFileType",
              corsBypassUrl
            )
          );
        }
        return file;
      });
    })
    .catch(() => urlToFile(srcSet, fileName));
};

export const fetchFileAsImageElement = async (
  file: File,
  config?: FetchImageConfig
): Promise<HTMLImageElement> => {
  const dataUrl = await readFileAsDataUrl(file);
  return fetchImage(dataUrl, config);
};

export const loadImagePromise = (
  url: string,
  config: FetchImageConfig = {}
): Promise<HTMLImageElement> => {
  const { isOnScreen, bypassQueue, waitDecode = true } = config;
  const promise = new Promise<HTMLImageElement>((resolve, reject) => {
    const img = document.createElement("img");
    img.onload = async () => {
      try {
        if (waitDecode) {
          /**
           * Ensure the image is decoded before we add it to the DOM.
           *
           * See https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/decode
           *
           * @NOTE It appears that decoding can fail sporadically when the
           * browser has to decode multiple images at the same time, such as
           * when loading a project. Immediately retrying the decode seems to
           * be sufficient to prevent this issue.
           *
           * See #6606 and #7547
           */
          await img.decode().catch(() => img.decode());
        }
        resolve(img);
      } catch {
        reject(
          new ExternalResourceError(
            i18n.t("clientError.cannotDecodeImage"),
            url
          )
        );
      } finally {
        img.onload = null;
        img.onerror = null;
      }
    };
    img.onerror = () => {
      reject(
        new ExternalResourceError(i18n.t("clientError.cannotLoadImage"), url)
      );
      img.onload = null;
      img.onerror = null;
    };
    img.crossOrigin = "anonymous";
    img.src = url;
  });

  // Suppress uncaught rejection (error is handled up the chain)
  promise.catch(() => null);

  if (bypassQueue) {
    return promise;
  }

  return fetchImageQueue.add(() => promise, {
    priority: isOnScreen ? 1 : -1,
  });
};

/**
 * Convert an ArrayBuffer into a PNG encoded ArrayBuffer.
 *
 * @NOTE Using this method results in two fairly heavy image processing
 * libraries being lazy loaded.
 *
 * @NOTE The convertion is very expensive and blocks the CPU.
 *
 * @param arrayBuffer ArrayBuffer to encode
 * @returns PNG encoded ArrayBuffer
 */
const convertArrayBufferToPng = async (
  arrayBuffer: ArrayBuffer
): Promise<ArrayBuffer> => {
  const imageDecode = await import("image-decode");
  const imageEncode = await import("image-encode");

  const decoded = imageDecode.default(arrayBuffer);

  if (!decoded) {
    return Promise.reject(new Error("clientError.cannotDecodeImage"));
  }

  const { data, width, height } = decoded;

  return imageEncode.default(data, undefined, {
    format: "png",
    width,
    height,
  });
};

/**
 * Convert a File to a PNG File
 *
 * @param file Image file for conversion
 * @returns PNG File
 */
export const convertFileToPngFile = async (file: File): Promise<File> => {
  const buffer = await file.arrayBuffer();
  const pngBuffer = await convertArrayBufferToPng(buffer);
  return new File([pngBuffer], file.name, {
    type: "image/png",
  });
};

/**
 * Fetch an image from a URL and convert it to a base64 encoded PNG
 *
 * @param url URL for image resource
 * @returns Base 64 encoded PNG string
 */
const fetchImageAsPngBase64 = async (url: string): Promise<string> => {
  const pngBuffer = await fetchImageAsPngBuffer(url);
  const base64String = Buffer.from(pngBuffer).toString("base64");
  return `data:image/png;base64,${base64String}`;
};

/**
 * Load an image from a URL and convert it to a PNG ArrayBuffer
 *
 * Supports: png, gif, jpg, bmp, and tiff
 *
 * @param url URL for image resource
 * @returns ArrayBuffer containing PNG data
 */
const fetchImageAsPngBuffer = async (url: string): Promise<ArrayBuffer> => {
  const response = await fetch(url, {
    /**
     * Some sites redirect cross-domain requests to a different file, e.g. an
     * error page instead of the image. Ensure that if this happens the request
     * fails.
     */
    redirect: "error",
  });

  if (!response.ok) {
    // TODO: Localize error message
    return Promise.reject(
      new ExternalResourceError("Unable to fetch image as PNG buffer", url)
    );
  }

  const buffer = await response.arrayBuffer();

  return convertArrayBufferToPng(buffer);
};

type UrlToFileConfig = {
  getTypeFromHeader?: boolean;
};

/**
 * Read a URL or data URL (base64) into a File object.
 * @param url URL of resource
 * @param fileName Intended file name
 * @returns File
 */
export const urlToFile = async (
  url: string,
  fileName: string,
  config: UrlToFileConfig = {}
): Promise<File> => {
  const { getTypeFromHeader } = config;
  const response = await fetch(url, {
    /**
     * Some sites redirect cross-domain requests to a different file, e.g. an
     * error page instead of the image. Ensure that if this happens the request
     * fails.
     */
    redirect: "error",
  });

  if (!response.ok) {
    // TODO: Localize error message
    return Promise.reject(
      new ExternalResourceError("Unable to convert url to File", url)
    );
  }

  const mimeType =
    // If the file doesn't have an extension this will return null
    (!getTypeFromHeader && mime.getType(fileName)) ||
    // In which case we use the request content-type, which will be unreliable
    response.headers.get("content-type") ||
    // Finally, we fallback to the default
    "application/octet-stream";

  const buffer = await response.arrayBuffer();

  return new File([buffer], fileName, {
    type: mimeType,
  });
};

export const getDataUrlParts = (
  dataUrl: string
): { fileType: string; content: string | undefined } => {
  const [prefix, content] = dataUrl.split(";base64,");
  const [, fileType] = prefix.split("data:");

  return { fileType, content };
};

export const getFilenameParts = (
  filename: string
): { name: string; extension: string | null } => {
  const separator = filename.lastIndexOf(".");

  if (separator === -1) {
    return { name: filename, extension: null };
  }

  const name = filename.slice(0, separator);
  const extension = filename.slice(separator + 1);

  return { name, extension };
};

export const getFilenameOnly = (filename: string): string => {
  const { name } = getFilenameParts(filename);
  return name;
};

/**
 * Extract file extension from file name.
 *
 * NOTE: Will prefix the extension with a `.`
 *
 * @param filename Name of file
 * @returns Extension
 */
export const getFileExtension = (filename: string): string => {
  const { extension } = getFilenameParts(filename);
  return extension ? `.${extension}` : "";
};

export const getFileTypeIdentifier = (file: File): string => {
  const { type, name } = file;
  // Chrome on MacOS reports the type of certain files as an empty string
  const fileTypeIdentifier = type || getFileExtension(name);

  return fileTypeIdentifier;
};

/**
 * Attempt to infer file extension from the type.
 *
 * NOTE: Will prefix the extension with a `.`

* @returns Extension
 */
export const inferFileExtension = (fileType: string): string => {
  const extension = mime.getExtension(fileType);
  if (!extension) {
    // TODO: Localize error message
    throw new Error("Unable to determine file extension");
  }
  return `.${extension}`;
};

/**
 * Extract file name from a URL.
 * @param url URL to extract file name from
 * @returns File name
 */
export const getFileNameFromUrl = (url = ""): string => {
  const { pathname, search } = new URL(url, window.location.href);

  // TODO (?): move getFileNameFromUrl to storage-client lib
  const mftFileName = getFileNameFromMftUrl(search);
  return mftFileName ?? getLastPathPart(pathname);
};

const getLastPathPart = (path: string) =>
  path.substring(path.lastIndexOf("/") + 1);

// MFT puts fileName in `fileAndPath` url param, e.g.:
// .../StreamFile?fileAndPath=/var/mft/_cbfiles_/053/FILENAME.svg
const getFileNameFromMftUrl = (searchQuery: string): string | undefined => {
  const fileAndPath = new URLSearchParams(searchQuery).get("fileAndPath");
  return fileAndPath ? getLastPathPart(fileAndPath) : undefined;
};

/**
 * Replace reserved characters with a hyphen when creating a filename
 */
export const escapeFileName = (fileName: string): string => {
  return fileName.replace(/[/\\?%*:|"<>]/g, "-");
};

/**
 * Attempt to load an SVG file as a Base64 data url.
 *
 * This is useful when the server gives us an incorrect Content-Type value.
 */
const fetchImageAsSvgBase64 = async (
  url: string,
  fileName: string,
  config?: FetchImageConfig
): Promise<HTMLImageElement> => {
  const file = await urlToFile(url, fileName);
  const fixedFile = await setSVGFileDimensions(file);
  return fetchFileAsImageElement(fixedFile, config);
};

/**
 * Read a File instance as a data URL
 */
export const readFileAsDataUrl = (file: File | Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    const onReject = () => reject(new Error("Cannot read file as DataURL"));

    reader.onload = () =>
      reader.result ? resolve(reader.result.toString()) : onReject();
    reader.onabort = onReject;
    reader.onerror = (e) => reject(e);
    reader.readAsDataURL(file);
  });

export const removeDataURLDeclaration = (dataUrl: string): string => {
  const { content } = getDataUrlParts(dataUrl);

  return content || "";
};

/**
 * Convert byte data as a data URL.
 */
export const convertByteDataToDataUrl = async (
  data: Uint8Array
): Promise<string> => {
  const dataUrl = await readFileAsDataUrl(new Blob([data]));
  return removeDataURLDeclaration(dataUrl);
};
