import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function OrderUp(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M26.46,12a1,1,0,0,0-.21.1Z" />
      <path d="M30.5,15.26l-3-3,0,0-.07-.06h0a1.06,1.06,0,0,0-.33-.18,1,1,0,0,0-.29,0l-.19,0-.11,0-.21.1-.1.07h0l-.07.06,0,.05-3,3a1,1,0,1,0,1.36,1.36l1.41-1.41v8a1,1,0,0,0,1.93,0V15.21l1.41,1.41a1,1,0,1,0,1.36-1.36Z" />
      <path d="M23.71,27h-12a1,1,0,1,0,0,1.93H23.71a1,1,0,0,0,0-1.93Z" />
      <path d="M21.3,22.19H11.66a1,1,0,1,0,0,1.93H21.3a1,1,0,0,0,0-1.93Z" />
      <path d="M18.89,17.41H11.66a1,1,0,1,0,0,1.93h7.23a1,1,0,0,0,0-1.93Z" />
      <path d="M16.48,12.63H11.66a1,1,0,1,0,0,1.93h4.82a1,1,0,0,0,0-1.93Z" />
    </Icon>
  );
}
export default OrderUp;
