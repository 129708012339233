import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  allPlans,
  maxProjectCountInFreePlan,
  PlanName,
  ProductTypeIDs,
} from "../../const";
import { selectSubscriptionDetails } from "../../reduxStore/subscriptionDetails/subscriptionDetails.reducer";
import { useUISettings } from "../../reduxStore/uiSettings";
import { dynamicFeatureFlags } from "../../tools/flags";
import { isRunningInMSTeams } from "../../tools/msTeams";
import { parseQueryUrl } from "../../tools/url";
import { ApiSubscriptionUserPermission } from "../../types/enum";
import { subscriptionStatuses } from "./payment/enums";
import { useLicenseInfo } from "./useLicenseInfo";

export type UsePlanDetailsState = {
  isPlanAdminOfGivenPlan: (productTypeId: InternalProductIds) => boolean;
  getActivePlanName: () => PlanName | null;
  isMultiUserPlanAdmin: boolean;
  hasAccessToPlansPage: boolean;
  isOnNoLimitPlan: boolean;
  isOnLifeTimePlan: boolean;
  isAllowedToCreateProject: boolean;
  showCreateNewProject: boolean;
  isOnFreePlan: boolean;
  hasAccessToTeamPage: boolean;
  isPlanAdmin: boolean;
  isMultiUserPlanMember: boolean;
};

export const usePlanDetails = (): UsePlanDetailsState => {
  const { subscription, summary } = useSelector(selectSubscriptionDetails);
  const { isLicenseActive, numberOfOwnedProjects } = useLicenseInfo();
  const history = useHistory<void>();
  const { isDynamicFeatureActive } = useUISettings();

  const isPlanAdminOfGivenPlan = (productTypeId: InternalProductIds) =>
    subscription?.ProductId === productTypeId;

  const isOnNoLimitPlan = !isDynamicFeatureActive(
    dynamicFeatureFlags.LICENSING
  );

  // isLicenseActive is compared against undefined when the license isn't loaded
  // Default to free plan as that is the most restrictive
  // TODO: duplicated in useActivePlan
  const isOnFreePlan = Boolean(
    !isOnNoLimitPlan &&
      (typeof isLicenseActive !== "boolean" || !isLicenseActive)
  );

  const role = summary.permission ?? ApiSubscriptionUserPermission.none;

  // stopped subscription can be renewed and user is still an admin of a stopped subscription
  const isSubscriptionOngoing =
    subscription &&
    Number.isInteger(subscription.ProductId) &&
    [subscriptionStatuses.active, subscriptionStatuses.stopped].includes(
      subscription.Status
    );

  const isPlanAdmin =
    isOnFreePlan ||
    (isSubscriptionOngoing && role >= ApiSubscriptionUserPermission.owner) || // #7046
    false;

  const isMultiUserPlanAdmin = Boolean(
    isLicenseActive &&
      isSubscriptionOngoing &&
      !isPlanAdminOfGivenPlan(ProductTypeIDs.individual)
  );

  const isMultiUserPlanMember = Boolean(
    isLicenseActive && !isSubscriptionOngoing
  );

  // Only show the Create new project button when the numberOfOwnedProjects is no longer undefined
  const showCreateNewProject =
    !isOnFreePlan ||
    (typeof numberOfOwnedProjects === "number" &&
      typeof maxProjectCountInFreePlan === "number");

  const isAllowedToCreateProject = Boolean(
    !isOnFreePlan ||
      (Number.isInteger(numberOfOwnedProjects) &&
        (numberOfOwnedProjects as number) < maxProjectCountInFreePlan)
  );

  const hasAccessToTeamPage = Boolean(
    isMultiUserPlanAdmin &&
      isDynamicFeatureActive(dynamicFeatureFlags.TEAM_PAGE)
  );

  const isOnLifeTimePlan = subscription?.ActiveTo?.startsWith("9999") || false;

  const { payment, session_id, order } = parseQueryUrl<PlansUrlQuery>(
    history.location.search
  );
  const isCompletingOrder = payment && session_id && order;

  const hasAccessToPlansPage = Boolean(
    (isPlanAdmin || isCompletingOrder) &&
      isDynamicFeatureActive(dynamicFeatureFlags.LICENSING) &&
      isDynamicFeatureActive(dynamicFeatureFlags.PLANS_PAGE) &&
      !isOnLifeTimePlan &&
      !isRunningInMSTeams()
  );

  const getActivePlanName = () => {
    if (isOnNoLimitPlan) {
      return allPlans.noLimit.name;
    }

    if (isOnFreePlan) {
      return allPlans.free.name;
    }

    if (isPlanAdmin) {
      return (
        Object.values(allPlans).find((plan) =>
          isPlanAdminOfGivenPlan(plan.productTypeId)
        )?.name ?? null
      );
    }

    return null;
  };

  return {
    isPlanAdminOfGivenPlan,
    getActivePlanName,
    isOnNoLimitPlan,
    isOnFreePlan,
    isOnLifeTimePlan,
    isMultiUserPlanAdmin,
    isPlanAdmin,
    isMultiUserPlanMember,
    showCreateNewProject,
    isAllowedToCreateProject,
    hasAccessToTeamPage,
    hasAccessToPlansPage,
  };
};
