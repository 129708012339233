import React, { ReactElement } from "react";
import { Flex, Button, SxStyleProp } from "theme-ui";
import CollaboardTooltip from "../../../tooltip/CollaboardTooltip";

import Close from "../../../../../icons/Close";
import { MultiInputFieldItem } from "./useMultiInputField";

export type ItemTooltipProps<T> = { item: MultiInputFieldItem<T> };

type Props<T> = {
  item: MultiInputFieldItem<T>;
  onRemove: React.MouseEventHandler<HTMLButtonElement>;
  isSelected?: boolean;
  sx?: SxStyleProp;
  Tooltip?: (props: ItemTooltipProps<T>) => ReactElement;
};

function MultiInputItem<T extends unknown>({
  item,
  onRemove,
  isSelected,
  sx,
  Tooltip,
}: Props<T>): ReactElement {
  return (
    <CollaboardTooltip
      descriptionComponent={Tooltip && <Tooltip item={item} />}
      forceHide={!Tooltip}
      placement="rightTop"
      maxWidth={8}
    >
      <Flex
        sx={{
          py: [2],
          pl: [4],
          pr: [2],
          mr: [2],
          mb: [2],
          backgroundColor: item.isValid
            ? "var(--multiInputItemBackground)"
            : "#fcc",
          border: "2px solid",
          borderColor: isSelected
            ? "primary"
            : item.isValid
            ? "var(--multiInputItemBackground)"
            : "#faa",
          color: "var(--textColor)",
          alignItems: "center",
          height: "38px",
          ...sx,
        }}
      >
        {item.value}
        <Button
          type="button"
          variant="content"
          aria-label="MultiInputItemButton"
          sx={{
            ml: [4],
            "&:hover": { svg: { fill: "warning" } },
            svg: { width: "20px", height: "20px", fill: "var(--textColor)" },
          }}
          onClick={onRemove}
        >
          <Close noShadow />
        </Button>
      </Flex>
    </CollaboardTooltip>
  );
}

export default MultiInputItem;
