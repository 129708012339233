import React, { ReactElement } from "react";
import { Flex } from "theme-ui";
import { useCustomerComponents } from "../../licensing/useCustomerComponents";

type Props = {
  size?: string;
};

function TagLine({ size }: Props): ReactElement {
  const { Tagline: TaglineContent } = useCustomerComponents();

  return (
    <Flex
      sx={{
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        "> svg": {
          height: `${size || "calc(var(--staticGridTile) * 1.5)"}`,
        },
      }}
    >
      <TaglineContent />
    </Flex>
  );
}

export default TagLine;
