import React, { ReactElement } from "react";
import { Flex, ThemeUIStyleObject } from "theme-ui";
import { sharingMinPermission } from "../../../../const";
import { useUISettings } from "../../../../reduxStore/uiSettings";
import { dynamicFeatureConfig } from "../../../../tools/flags";
import {
  ApiPermission,
  ApiResourcePermission,
  ApiResourceType,
} from "../../../../types/enum";
import { useLoggedInStatus } from "../../../authentication/useLoggedInStatus";
import { usePermissions } from "../../../permissions/usePermissions";
import { useSpacePermission } from "../../../spaces/useSpaces";
import DeleteProjectButton from "./buttons/DeleteProjectButton";
import DuplicateProjectButton from "./buttons/DuplicateProjectButton";
import ExpandButton from "./buttons/ExpandButton";
import LeaveProjectButton from "./buttons/LeaveProjectButton";
import ShareProjectButton from "./buttons/ShareProjectButton";

type Props = {
  isExpanded?: boolean;
  onExpand?: () => void;
  isProjectOwner?: boolean;
  projectWithMeta: ApiProject;
};

function ProjectActions({
  isProjectOwner = false,
  isExpanded = false,
  onExpand,
  projectWithMeta,
}: Props): ReactElement {
  const { userProfile } = useLoggedInStatus();
  const { getDynamicFeatureConfig } = useUISettings();
  const { hasPermission } = usePermissions();

  const {
    Project: { ProjectId, SpaceId },
    IsLicensed,
  } = projectWithMeta;

  const minSharingPermission =
    getDynamicFeatureConfig<ApiPermission>(
      dynamicFeatureConfig.SHARING_MIN_PERMISSION
    ) ?? sharingMinPermission;

  const resourceType = SpaceId
    ? ApiResourceType.SpaceProjects
    : ApiResourceType.Project;
  const spacePermission = useSpacePermission(SpaceId);
  const projectPermission = projectWithMeta.Permission;

  const hasMinSharingPermission =
    projectWithMeta.Permission >= minSharingPermission;
  const canAddParticipants = hasPermission({
    type: ApiResourceType.ProjectParticipants,
    value: String(ProjectId),
    permission: ApiResourcePermission.Create,
    spacePermission,
    projectPermission,
  });

  const hasMinDuplicatePermission =
    IsLicensed && projectWithMeta.Permission > ApiPermission.readPermission;
  const canDuplicate = hasPermission({
    type: resourceType,
    value: String(ProjectId),
    permission: ApiResourcePermission.Copy,
    spacePermission,
    projectPermission,
  });

  const canDelete = hasPermission({
    type: resourceType,
    value: String(ProjectId),
    permission: ApiResourcePermission.Delete,
    spacePermission,
    projectPermission,
    defaultPermission: isProjectOwner,
  });

  return (
    <Flex sx={containerStyle}>
      <ShareProjectButton
        projectId={ProjectId}
        disabled={
          !IsLicensed || !hasMinSharingPermission || !canAddParticipants
        }
        disabledLabelKey={
          !IsLicensed
            ? "apiError.InvalidLicense"
            : "apiError.UserNotAuthorizedToPerformRequestedAction"
        }
        firstName={userProfile?.FirstName}
        isExpanded={isExpanded}
      />

      <DuplicateProjectButton
        projectId={ProjectId}
        disabled={!hasMinDuplicatePermission || !canDuplicate}
        disabledLabelKey={
          !IsLicensed
            ? "apiError.InvalidLicense"
            : "apiError.UserNotAuthorizedToPerformRequestedAction"
        }
        isExpanded={isExpanded}
      />

      {canDelete ? (
        <DeleteProjectButton
          projectId={ProjectId}
          disabled={false}
          disabledLabelKey={
            !IsLicensed
              ? "apiError.InvalidLicense"
              : "apiError.UserNotAuthorizedToPerformRequestedAction"
          }
          isExpanded={isExpanded}
        />
      ) : (
        <LeaveProjectButton
          projectId={ProjectId}
          disabled={false}
          isExpanded={isExpanded}
        />
      )}

      {onExpand && <ExpandButton isExpanded={isExpanded} onExpand={onExpand} />}
    </Flex>
  );
}

const containerStyle: ThemeUIStyleObject = {
  alignItems: "center",
  width: "100%",
  justifyContent: "space-between",
};

export default ProjectActions;
