import React, { ReactElement } from "react";

import UserDetails from "./UserDetails";
import UserProjects from "./UserProjects";

type Props = {
  User: ProjectApiUserInfo;
  isExpanded: boolean;
};

function ProjectResultByUserExtended({
  User,
  isExpanded,
}: Props): ReactElement {
  return (
    <>
      <UserDetails User={User} />
      <UserProjects User={User} isExpanded={isExpanded} />
    </>
  );
}
export default ProjectResultByUserExtended;
