export const isMoveTransform = (
  transform: fabric.ObjectTransform,
  target: fabric.Object
): boolean => {
  const newLeft = transform.ex - transform.offsetX;
  const newTop = transform.ey - transform.offsetY;
  const moveX = Math.round(newLeft - target.left) !== 0;
  const moveY = Math.round(newTop - target.top) !== 0;

  return transform.action === "drag" && (moveX || moveY);
};

const isPointerEvent = (event: Event): event is PointerEvent => {
  return event instanceof PointerEvent;
};

export const isTouchPointerEvent = (event: Event): boolean => {
  return isPointerEvent(event) && event.pointerType === "touch";
};
