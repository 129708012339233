import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import ScanQR from "../../../../icons/ScanQR";
import QRScanner from "./QRInvite/QRScanner";
import Modal from "../../../shared/modals";
import { TemplateProps } from "../../../shared/modals/Modal";
import ModalHeader from "../../../shared/modals/ModalHeader";
import { withModalTemplate } from "../../../shared/modals/withModalTemplate";

function QRScannerModalTemplate({ modalProps }: TemplateProps): ReactElement {
  const { t } = useTranslation();

  return (
    <Modal
      header={<ModalHeader title={t("dialog.scanQRCode")} Icon={ScanQR} />}
      {...modalProps}
    >
      <QRScanner />
    </Modal>
  );
}

export default withModalTemplate(QRScannerModalTemplate);
