import { loadStripe } from "@stripe/stripe-js/pure";
import { isDynamicFeatureActive } from "../reduxStore/uiSettings";
import { errorToast } from "./errorToast";
import { dynamicFeatureFlags, isProduction } from "./flags";
import { runtimeConfig } from "./runtimeConfig";

const fetchStripe = async () => {
  const enableLicensing = isDynamicFeatureActive(dynamicFeatureFlags.LICENSING);
  const shouldLoadStripe =
    runtimeConfig.stripePublishableKey &&
    enableLicensing &&
    isDynamicFeatureActive(dynamicFeatureFlags.PLANS_PAGE);

  if (!shouldLoadStripe) {
    return undefined;
  }

  loadStripe.setLoadParameters({
    advancedFraudSignals: isProduction() && enableLicensing,
  });

  return loadStripe(runtimeConfig.stripePublishableKey);
};

export const goToStripeCheckout = async (sessionId: string): Promise<void> => {
  const stripe = await fetchStripe();

  if (!stripe) {
    errorToast("dialog.payment.failedConnectionToStripe");
    return;
  }

  const { error } = await stripe.redirectToCheckout({
    sessionId,
  });

  if (error) {
    errorToast("dialog.payment.failedConnectionToStripe");
  }
};
