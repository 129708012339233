import React, { ReactElement } from "react";
import { Button } from "theme-ui";
import Share from "../../../../../icons/Share";
import CollaboardTooltip from "../../../../shared/tooltip/CollaboardTooltip";
import ShareModalTemplate from "../../../../canvas/share/ShareModal";
import { useSimpleModal } from "../../../../shared/modals/useSimpleModal";

type Props = {
  projectId: string;
  firstName?: string;
  disabled: boolean;
  disabledLabelKey: string;
  isExpanded?: boolean;
};

function ShareProjectButton({
  projectId,
  firstName = "",
  disabled,
  disabledLabelKey,
  isExpanded = false,
}: Props): ReactElement {
  const shareModalProps = useSimpleModal();

  const onClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    shareModalProps.open();
  };

  return (
    <>
      <CollaboardTooltip
        header="tooltip.header.shareProject"
        description={
          disabled ? disabledLabelKey : "tooltip.description.shareProjectInfo"
        }
        placement="topLeft"
      >
        <Button
          variant={
            isExpanded ? "contentProjectListExpanded" : "contentProjectList"
          }
          id="project-share"
          disabled={disabled}
          onClick={onClick}
        >
          <Share noShadow />
        </Button>
      </CollaboardTooltip>
      <ShareModalTemplate
        modalProps={shareModalProps}
        firstName={firstName}
        projectId={projectId}
      />
    </>
  );
}

export default ShareProjectButton;
