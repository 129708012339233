import { TileStatus } from "../../api/signalR/message.types";
import {
  canvasObjectIds,
  initialObjectHeight,
  initialObjectWidth,
} from "../../const";
import { InternalError } from "../../errors/InternalError";
import { CanvasMode } from "../../reduxStore/canvas/app/app.reducer";
import { LogCategory, onErrorToLog } from "../../tools/telemetry";
import {
  getParentGroup,
  isInSubselection,
} from "../components/group/group.utils";
import { getObjectConnections } from "./connections";

/*=============================================
=             FLATTENING UTILS                =
=============================================*/

const {
  activeSelection,
  alignmentLine,
  chat,
  connector,
  embed,
  group,
  inkPath,
  line,
  shapeInnerTextBox,
  stack,
  urlArea,
} = canvasObjectIds;

const innerObjectTypes = [shapeInnerTextBox];

export const isInnerObject = (object: fabric.Object): boolean => {
  return object.type && innerObjectTypes.includes(object.type);
};

const topLevelObjectTypes = Object.values(canvasObjectIds)
  .filter((t) => !innerObjectTypes.includes(t))
  .reduce<Map<canvasObjectIds, boolean>>((map, type) => {
    map.set(type, true);

    return map;
  }, new Map()); // Use Map to check in constant time O(1)

/**
 * Flatten the object tree without unpacking internals of objects
 * @param {Array<fabric.Object>} objects List of objects
 * @returns {Array<fabric.Object>} The original list of objects, but flattened
 */
export const flattenObjectTree = (
  objects: fabric.Object[]
): fabric.Object[] => {
  return flattenObjects(objects).filter(
    // Don't unpack the internals of objects, only the top level objects
    (object) => object.type && topLevelObjectTypes.has(object.type)
  );
};

/**
 * Flatten user-created groups
 */
export const flattenGroups = (objects: fabric.Object[]): fabric.Object[] => {
  return flattenObjectTree(objects).filter((obj) => !hasInnerObjects(obj));
};

/**
 * Flatten every nested object
 */
export const flattenObjects = (objects: fabric.Object[]): fabric.Object[] => {
  const result: fabric.Object[] = [];

  objects.forEach((object) => {
    result.push(
      object,
      ...(hasInnerObjects(object) || isTransformer(object)
        ? flattenObjects(object.getObjects())
        : [])
    );
  });

  return result;
};

/**
 * Flatten children without including top-level objects or parents
 */
export const flattenChildren = (objects: fabric.Object[]): fabric.Object[] => {
  return objects.flatMap((o) => (isCollection(o) ? o.getObjects() : []));
};

/*=============================================
=            BOOLEAN GUARDS UTILS             =
=============================================*/

const zIndexUnaffectedTypes = [
  shapeInnerTextBox,
  activeSelection,
  alignmentLine,
  chat,
  connector,
  line,
  urlArea,
];

export const attachmentTypes = [canvasObjectIds.chat];

const autoEditableObjectTypes = [
  canvasObjectIds.stickyNote,
  canvasObjectIds.text,
];

const editableObjectTypes = [
  ...autoEditableObjectTypes,
  canvasObjectIds.shapeInnerTextBox,
];

const groupTypes = [group, stack];

const nonAnimatedTypes = [...zIndexUnaffectedTypes, inkPath, embed];

const nonConnectableTypes = [...zIndexUnaffectedTypes, inkPath];

const nonVisibleWhenVotingTypes = [...zIndexUnaffectedTypes, inkPath];

// Visible but not votable types. No need to include nonVisibleTypesWhenVoting
const nonVotableTypes = [...zIndexUnaffectedTypes, shapeInnerTextBox];

/**
 * NOTE: The inclusion of `group` within this list means that objects within
 * nested groups will not support auto-alignment.
 */
export const nonSnappableTypes = [...zIndexUnaffectedTypes, inkPath, group];

export const includesConnectors = (objects: fabric.Object[]): boolean => {
  return objects.some(isConnector);
};

export const isActiveSelection = (
  object: fabric.Object
): object is fabric.ActiveSelection => {
  return object instanceof fabric.ActiveSelection || isTransformer(object);
};

export const isNotAnimated = (object: fabric.Object): boolean => {
  return (
    !!object.parentId ||
    (isCollection(object) && !object.getObjects().length) ||
    nonAnimatedTypes.includes(object.type)
  );
};

export const isAttachment = (object: fabric.Object): object is fabric.Chat => {
  return !!object.type && attachmentTypes.includes(object.type);
};

export const isAutoEditable = (
  object: fabric.Object
): object is fabric.StickyNote | fabric.FreeFormText =>
  !!object.type && autoEditableObjectTypes.includes(object.type);

export const isAutoSelectable = (object: fabric.Object): boolean =>
  !isInCollection(object) && !zIndexUnaffectedTypes.includes(object.type);

export const isCollection = (
  object: fabric.Object
): object is fabric.Group | fabric.Stack =>
  !!object.type && groupTypes.includes(object.type);

export const isEditable = (object: fabric.Object): object is ICustomEditing => {
  return !!object.type && editableObjectTypes.includes(object.type);
};

/**
 * Check if an object is in a collection.
 * @NOTE it's unsafe to only use `object.group` as it may be an ActiveSelection
 */
export const isInCollection = (object: fabric.Object): boolean =>
  !!object.group && isCollection(object.group);

/**
 * Stricter than `isInCollection` as it checks if the group was stashed as well.
 * @NOTE You should ensure `object.group` is defined nevertheless if using it as this could return
 * true because of `object.__stashedGroupRef`
 */
export const isInGroup = (object: fabric.Object): boolean => {
  const parentCollection = getParentGroup(object);
  return !!parentCollection;
};

export const isInActiveSelection = (object: fabric.Object): boolean =>
  !!object.group && isActiveSelection(object.group);

export const isCanvas = (
  object: unknown
): object is fabric.CollaboardCanvas => {
  return object instanceof fabric.CollaboardCanvas;
};

export const isConnectable = (object: fabric.Object): boolean =>
  !!object.type && !nonConnectableTypes.includes(object.type);

export const isConnectedToStack = (
  connection: fabric.CollaboardConnector
): boolean => {
  const { origin, destination } = connection.getEnds();
  return [origin?.group, destination?.group].some(
    (anchorParent) => anchorParent && isStack(anchorParent)
  );
};

export const isConnector = (
  object: fabric.Object
): object is fabric.CollaboardConnector => {
  return object instanceof fabric.CollaboardConnector;
};

export const isConnectorSelection = (object: fabric.Object): boolean => {
  return (
    isConnector(object) ||
    (isActiveSelection(object) && includesConnectors(object.getObjects()))
  );
};

export const isDocument = (
  object: fabric.Object
): object is fabric.CollaboardDocument => {
  return object instanceof fabric.CollaboardDocument;
};

export const isPDFDocument = (
  object: fabric.Object
): object is fabric.PDFDocument => {
  return object instanceof fabric.PDFDocument;
};

export const isEmbed = (
  object: fabric.Object
): object is fabric.CollaboardEmbed => {
  return object instanceof fabric.CollaboardEmbed;
};

export const isFreeFormText = (
  object: fabric.Object
): object is fabric.FreeFormText => {
  return object instanceof fabric.FreeFormText;
};

export const isGroup = (object: fabric.Object): object is fabric.Group => {
  return (
    // Check type value because other classes use fabric.Group as their base
    object instanceof fabric.Group && object.type === group
  );
};

export const isInkPath = (
  object: fabric.Object
): object is fabric.CollaboardInkPath => {
  return object instanceof fabric.CollaboardInkPath;
};

export const isInkPathSelection = (
  object: fabric.Object
): object is fabric.Group => {
  return isInkPath(object) || isInkPathCollection(object);
};

export const isInkPathCollection = (
  object: fabric.Object
): object is fabric.Group =>
  hasInnerObjects(object) && object.getObjects().some(isInkPath);

export const isMediaObject = (
  object: fabric.Object
): object is fabric.CollaboardMedia => {
  return !!object.isMedia;
};

export const isLinkable = (object: fabric.Object): boolean =>
  !!object.type && !zIndexUnaffectedTypes.includes(object.type);

export const isRecognizedTileType = (type: string): type is canvasObjectIds => {
  return type in canvasObjectIds;
};

export const isRemoteObject = (
  object: fabric.Object
): object is fabric.CollaboardStorageObject => {
  return object instanceof fabric.CollaboardImage;
};

export const isStickyNote = (
  object: fabric.Object
): object is fabric.StickyNote => {
  return object instanceof fabric.StickyNote;
};

export const isShape = (
  object: fabric.Object
): object is fabric.CollaboardShape => {
  return object instanceof fabric.CollaboardShape;
};

export const isShapeText = (
  object: fabric.Object
): object is fabric.ShapeInnerTextBox => {
  return object instanceof fabric.ShapeInnerTextBox;
};

export const isStack = (object: fabric.Object): object is fabric.Stack =>
  object.type === stack;

export const isTransformer = (
  object: fabric.Object
): object is fabric.Transformer => {
  return object instanceof fabric.Transformer;
};

export const isUrlArea = (object: fabric.Object): object is fabric.UrlArea => {
  return object instanceof fabric.UrlArea;
};

export const isVisibleOnMinimap = (object: fabric.Object): boolean => {
  return !!object.type && !zIndexUnaffectedTypes.includes(object.type);
};

export const isVisibleWhenVoting = (object: fabric.Object): boolean =>
  !!object.type && !nonVisibleWhenVotingTypes.includes(object.type);

export const isVotableObject = (object: fabric.Object): boolean =>
  isVisibleWhenVoting(object) &&
  !nonVotableTypes.includes(object.type) &&
  !object.isLocked();

// same implementation as `isRemoteObject`, but with a different meaning
// consider merging both functions
export const hasAsyncPreview = (
  object: fabric.Object
): object is fabric.CollaboardImage => {
  return object instanceof fabric.CollaboardImage;
};

export const hasInnerObjects = (
  object: fabric.Object
): object is CollectionOrSelection =>
  isCollection(object) || isActiveSelection(object);

/*=============================================
=           Z-INDEX UTILS                     =
=============================================*/

/**
 * Get the lowest object in the stack (i.e. by zIndex)
 */
export const getLowestObject = (
  objects: fabric.Object[]
): fabric.Object | undefined => {
  const zIndexable = objects.filter(isZIndexable);

  return zIndexable.length
    ? zIndexable.reduce(minByZindex, zIndexable[0])
    : undefined;
};

const alreadyLoggedObjects: string[] = [];

/**
 * Insert the object next to the given one
 *
 * @NOTE This function mutates the array by reference and if called for an array of objects
 * they will end up in the inverse of their original order
 */
export const insertAdjacentToObj = ({
  objects,
  object,
  nextToObj,
  position,
}: {
  objects: fabric.Object[];
  object: fabric.Object;
  nextToObj: fabric.Object;
  position: "before" | "after";
}): void => {
  const index = objects.indexOf(nextToObj);

  if (index !== -1) {
    const finalIndex = position === "after" ? index + 1 : index;
    objects.splice(finalIndex, 0, object);
  } else {
    // Avoid flooding the logs as this function is used in `_chooseObjectsToRender`, which is called for each render
    const isAlreadyLogged = alreadyLoggedObjects.includes(object.uuid);

    if (!isAlreadyLogged) {
      alreadyLoggedObjects.push(object.uuid);
      onErrorToLog(
        new InternalError("Could not insert adjacent object"),
        LogCategory.internal,
        {
          object: `${object.type} ${object.uuid}`,
          objects: Array.from(object.canvas?._objectsByUUID.keys() ?? []),
          nextToObj: `${nextToObj.type} ${nextToObj.uuid}`,
        }
      );
    }
  }
};

export const isZIndexable = (object: fabric.Object): boolean => {
  return !zIndexUnaffectedTypes.includes(object.type);
};

export const isTopLevelZIndexable = (object: fabric.Object): boolean => {
  return isZIndexable(object) && !isInGroup(object);
};

export const minByZindex = (
  lowestObject: fabric.Object,
  object: fabric.Object
): fabric.Object => {
  return lowestObject.zIndex > object.zIndex ? object : lowestObject;
};

/*=============================================
=            GROUP-BY UTILS                   =
=============================================*/

export const groupByTileId = <T extends TileStatus>(
  accu: Record<string, T>,
  tile: T
): Record<string, T> => {
  accu[tile.TileId] = tile;
  return accu;
};

export const groupByUuid = <T extends fabric.Object>(
  acc: Record<string, T | undefined>,
  object: T
): Record<string, T | undefined> => {
  acc[object.uuid] = object;
  return acc;
};

export const groupByRenderingCategory = (canvas: fabric.CollaboardCanvas) => (
  acc: Record<RenderingCategory, fabric.Object[]>,
  object: fabric.Object
): Record<RenderingCategory, fabric.Object[]> => {
  const objectCategory = getRenderingCategory(canvas, object);
  acc[objectCategory].push(object);
  return acc;
};

/*=============================================
=            SEPARATE UTILS                   =
=============================================*/

type SeparatedObjects = {
  included: fabric.Object[];
  excluded: fabric.Object[];
};

type SeparatedObjectsAndConnections = {
  objects: fabric.Object[];
  connections: fabric.CollaboardConnector[];
};

/**
 * Separate canvas objects into connections and normal objects (e.g. shapes,
 * sticky notes).
 *
 * This is required because connections are handled differently by the API.
 *
 * @param {Array<fabric.Object>} items List of objects
 * @returns {object} Objects and connections
 */
// TODO: connectors are going to become "line" objects soon. Most likely this function will have to be removed
export const separateObjectsAndConnections = (
  items: fabric.Object[]
): SeparatedObjectsAndConnections => {
  const results = items.reduce<SeparatedObjectsAndConnections>(
    (acc, object) => {
      isConnector(object)
        ? acc.connections.push(object)
        : acc.objects.push(object);
      return acc;
    },
    {
      objects: [],
      connections: [],
    }
  );

  // add internal connections of groups, stacks etc.
  results.connections.push(...getObjectConnections(results.objects));
  return results;
};

/**
 * Separate objects by type into two lists; included and excluded.
 *
 * @param {Array<fabric.Object>} objects List of objects to separate
 * @param {Array<string>} typesToExclude List of object types to put in `excluded` list
 * @returns {Object} Object containing `included` and `excluded` lists
 */
export const separateObjectsByType = (
  objects: fabric.Object[],
  typesToExclude: string[] = []
): SeparatedObjects => {
  return objects.reduce<SeparatedObjects>(
    (result, object) => {
      const isExcluded = !!object.type && typesToExclude.includes(object.type);

      isExcluded ? result.excluded.push(object) : result.included.push(object);

      return result;
    },
    {
      included: [],
      excluded: [],
    }
  );
};

/*=============================================
=                 MISC UTILS                  =
=============================================*/

/**
 * Activate the edit mode if an object is editable (text, sticky note).
 *
 * @param {Array<fabric.Object>} allObjects List of all objects on the canvas
 * @param {fabric.Object} object Object to activate the edit mode
 */
export const activateEditMode = (
  allObjects: fabric.Object[],
  object: fabric.Object
): void => {
  if (isAutoEditable(object)) {
    allObjects.forEach((o) => {
      if (o instanceof fabric.IText && o.isEditingMode()) {
        o.exitEditing();
      }
    });

    isStickyNote(object) &&
      object.isAutoFontSize &&
      object.setMaxAutoFontSize();
    object.enterEditing && object.enterEditing();
  }
};

export const calcInitialScale = (width: number, height: number): number =>
  Math.min(initialObjectWidth / width, initialObjectHeight / height);

export const calcTransformState = (
  object: fabric.Object
): fabric.TransformState => {
  const matrix = object.calcTransformMatrix();
  const {
    translateX,
    translateY,
    ...otherTransforms
  } = fabric.util.qrDecompose(matrix);

  return {
    ...otherTransforms,
    top: translateY,
    left: translateX,
  };
};

/**
 * Still return the parent group or shape as locked when working on the subitem
 * or shape text
 */
export const getLockableParent = (o: fabric.Object): fabric.Object => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return isInGroup(o) ? getParentGroup(o)! : isShapeText(o) ? o.shape : o;
};

/**
 * Search the object list for an object with a matching property / value pair.
 *
 * Note, this method traverses the entire tree below the given objects and will
 * return the FIRST item that matches the key pair.
 *
 * @param {Array<fabric.Object>} objects List of objects
 * @param {string} property Property to check
 * @param {any} value Value to search for
 * @returns {fabric.Object | null} Matching object
 */
export const getObjectByProperty = <
  O extends fabric.Object,
  P extends keyof O = keyof O
>(
  objects: fabric.Object[],
  property: P,
  value: O[P]
): O | undefined =>
  flattenObjectTree(objects).find(
    (object) => (object as O)[property] === value
  ) as O | undefined;

export type RenderingCategory =
  | "hovered"
  | "selected"
  | "active"
  | "zIndexUnaffected"
  | "attachments"
  | "stashed"
  | "urlAreas"
  | "idle"
  | "connections";

export const getRenderingCategory = (
  canvas: fabric.CollaboardCanvas,
  object: fabric.Object
): RenderingCategory => {
  const {
    _hoveredObject,
    _linkTargetUuid,
    mode,
    preserveObjectStacking,
  } = canvas;
  const isSelectObjectMode = mode === CanvasMode.SELECT_OBJECT;
  const isVotingMode = mode === CanvasMode.VOTING;
  const isHoverMode = isSelectObjectMode || isVotingMode;
  const activeObjects = canvas.getActiveObjects();

  if (isHoverMode && object === _hoveredObject) {
    return "hovered";
  }

  if (
    !preserveObjectStacking &&
    activeObjects.includes(object) &&
    !object.isReadOnly() // #6589
  ) {
    return "active";
  }

  if (object.uuid === _linkTargetUuid) {
    return "selected";
  }

  // These checks must be before `zIndexUnaffectedTypes` as objects can belong to
  // that group as well

  if (isAttachment(object)) {
    return "attachments";
  }

  if (isConnector(object)) {
    return "connections";
  }

  if (isUrlArea(object)) {
    return "urlAreas";
  }

  if (isInSubselection(object)) {
    return "stashed";
  }

  if (!isZIndexable(object)) {
    return "zIndexUnaffected";
  }

  return "idle";
};

export const getUuids = (objects: fabric.Object[]): string[] =>
  objects.map((o) => o.uuid);

export const getNestedUuids = (
  objects: fabric.Object[]
): Array<string | string[]> =>
  objects.map((o) =>
    isCollection(o) ? [o.uuid, getNestedUuids(o.getObjects())] : o.uuid
  ) as Array<string | string[]>;

export const getTileIds = (tiles: TileStatus[] | undefined): string[] =>
  tiles ? tiles.map((o) => o.TileId) : [];

export const setDirty = (
  target: fabric.Object | fabric.CollaboardCanvas
): void => {
  if (target instanceof fabric.Object) {
    target.dirty = true;
  }

  if (isCanvas(target) || hasInnerObjects(target)) {
    target.getObjects().forEach(setDirty);
  }
};
