import { Form, Formik, FormikConfig } from "formik";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Grid } from "theme-ui";
import { object, string } from "yup";
import { importSubscriptionUsers } from "../../../../../api";
import Profile from "../../../../../icons/Profile";
import Team from "../../../../../icons/Team";
import { selectSubscriptionDetails } from "../../../../../reduxStore/subscriptionDetails/subscriptionDetails.reducer";
import { useUISettings } from "../../../../../reduxStore/uiSettings";
import { dynamicFeatureConfig } from "../../../../../tools/flags";
import { initializePhoneValidation } from "../../../../../tools/validationTools";
import { useLoggedInStatus } from "../../../../authentication/useLoggedInStatus";
import InputDropdownField from "../../../../shared/forms/fields/InputDropdownField";
import InputField from "../../../../shared/forms/fields/InputField";
import Modal from "../../../../shared/modals";
import ConfirmationButtons from "../../../../shared/modals/ConfirmationButtons";
import ModalHeader from "../../../../shared/modals/ModalHeader";
import { ModalStateProps } from "../../../../shared/modals/useConfirmModal";
import { withModalTemplate } from "../../../../shared/modals/withModalTemplate";
import LicenseItem from "../License/LicenseItem";
import { useUserImportErrorHandler } from "./useUserImportErrorHandler";

type Props = {
  modalProps: ModalStateProps;
  onAddUser: () => Promise<void>;
};

function AddUserModal({ modalProps, onAddUser }: Props): ReactElement {
  const { t } = useTranslation();
  const { loggedInViaTenant, userProfile } = useLoggedInStatus();

  const {
    subscription,
    summary: { userCount = 0, maxUsers = 0 },
  } = useSelector(selectSubscriptionDetails);

  const subscriptionID = subscription?.ID || -1;
  const { userImportErrorHandler } = useUserImportErrorHandler();
  const { getLanguages, getDynamicFeatureConfig } = useUISettings();
  const autoLoginFromInvite = getDynamicFeatureConfig<boolean>(
    dynamicFeatureConfig.AUTO_LOGIN_FROM_INVITE
  );
  const { appLanguages } = getLanguages();

  initializePhoneValidation(t);

  const formikProps: FormikConfig<ApiBaseUser> = {
    validationSchema: object().shape({
      UserName: string()
        .email(t("clientError.emailNotValid"))
        .required(t("clientError.required")),
      FirstName: string(),
      LastName: string(),
      CompanyName: string(),
      Language: string(),
      PhoneNumber: string().phone(),
    }),
    initialValues: {
      UserName: "",
      FirstName: "",
      LastName: "",
      CompanyName: "",
      Language: userProfile?.Language,
      PhoneNumber: "",
    },
    onSubmit: (data) =>
      importSubscriptionUsers({
        subscriptionID,
        users: [{ ...data, SendInvitationEmail: true }],
        autoLoginFromInvite,
        tenant: loggedInViaTenant,
      })
        .then(() => {
          modalProps.onClose();
          toast(t("team.importProcessing"));
          onAddUser();
        })
        .catch(userImportErrorHandler),
  };

  return (
    <Formik {...formikProps}>
      {({ isValid, submitForm, dirty }) => (
        <Form>
          <Modal
            {...modalProps}
            header={<ModalHeader title={t("team.addNewUser")} Icon={Profile} />}
            actionButtons={
              <ConfirmationButtons
                confirmationLabel={t("team.addNewUserButton")}
                onConfirm={submitForm}
                confirmationDisabled={!isValid || !dirty}
                onClose={modalProps.onClose}
                closeOnConfirm={false}
              />
            }
          >
            <LicenseItem
              label={t("team.usersAvailable")}
              count={maxUsers - userCount}
              max={maxUsers}
              icon={Team}
              sx={{ padding: 0, margin: "0 0 1rem" }}
            />

            <Grid
              columns={2}
              gap={[2]}
              sx={{
                mt: [3],
                input: { height: "40px", fontSize: [3] },
                fontSize: [3],
                "> *": {
                  alignSelf: "start",
                },
              }}
            >
              <InputField
                required
                type="email"
                name="UserName"
                placeholder={t("team.addUserEmailPlaceholder")}
                label={t("team.addUserEmailLabel")}
              />

              <InputField
                type="text"
                name="FirstName"
                placeholder={t("team.addUserFirstNamePlaceholder")}
                label={t("team.addUserFirstNameLabel")}
              />

              <InputField
                type="text"
                name="LastName"
                placeholder={t("team.addUserLastNamePlaceholder")}
                label={t("team.addUserLastNameLabel")}
              />

              <InputField
                type="text"
                name="CompanyName"
                placeholder={t("team.addUserCompanyPlaceholder")}
                label={t("team.addUserCompanyLabel")}
              />

              <InputDropdownField
                name="Language"
                options={appLanguages}
                placeholder={t("team.addUserLanguagePlaceholder")}
                label={t("team.addUserLanguageLabel")}
                dropdownAboveField
              />

              <InputField
                type="text"
                name="PhoneNumber"
                placeholder={t("team.addUserPhoneNumberPlaceholder")}
                label={t("team.addUserPhoneNumberLabel")}
                processOnEnterPressed
              />
            </Grid>
          </Modal>
        </Form>
      )}
    </Formik>
  );
}

export default withModalTemplate(AddUserModal);
