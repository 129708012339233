import React, { ReactElement } from "react";
import { Redirect } from "react-router-dom";
import { routes } from "../../../const";
import {
  isStaticFeatureActive,
  staticFeatureFlags,
} from "../../../tools/flags";
import RegistrationOptions from "../../authentication/registration/RegistrationOptions";
import Page from "../layout/pages/Page";

const registrationDisabled = isStaticFeatureActive(
  staticFeatureFlags.REGISTRATION_DISABLED
);

function Register(): ReactElement {
  if (registrationDisabled) {
    return <Redirect to={routes.authenticate} />;
  }

  return (
    <Page>
      {" "}
      <RegistrationOptions />{" "}
    </Page>
  );
}

export default Register;
