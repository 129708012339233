import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Text } from "theme-ui";
import { useCustomerComponents } from "../../features/licensing/useCustomerComponents";

function TWRBanner() {
  const { t } = useTranslation();
  const { BannerLogo } = useCustomerComponents();

  return (
    <Box sx={containerStyle}>
      <Flex sx={innerContainerStyle}>
        <BannerLogo />
        <Text sx={textStyle}>{t("twr.loginTopBar")}</Text>
      </Flex>
    </Box>
  );
}

const containerStyle = {
  backgroundColor: "primary",
  width: "100%",
  position: "fixed",
  top: 0,
  left: 0,
};

const innerContainerStyle = {
  justifyContent: "space-between",
  px: "10%",
  py: "10px",
  width: "100%",
};

const textStyle = {
  color: "white",
  fontWeight: 700,
  right: "40px",
};

export default TWRBanner;
