import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { Box, Flex, Heading, Image, Text, ThemeUIStyleObject } from "theme-ui";
import { getAppName } from "../../../tools/customer";
import { Tab, TabButtons } from "../../shared/tab/TabButtons";
import { TabPanel, TabPanels } from "../../shared/tab/TabPanel";
import Tabs from "../../shared/tab/Tabs";
import Avatar from "../../users/Avatar";
import Page from "../layout/pages/Page";
import AcceptProjectInvitationForm from "./AcceptProjectInvitationForm";
import { useLoggedInStatus } from "../../authentication/useLoggedInStatus";
import { useAuthenticatingStatus } from "../../authentication/useAuthenticatingStatus";
import ContainedSpinner from "../../shared/spinners/ContainedSpinner";

type Props = {
  inviteDetails: ApiProjectInvitation;
  loginLink: string;
  token: string;
  tenant?: string;
};

function ProjectInvitationDetails({
  inviteDetails,
  loginLink,
  token,
  tenant,
}: Props): ReactElement {
  const { t } = useTranslation();
  const { isLoggedInAsUser } = useLoggedInStatus();
  const { isLoggingIn } = useAuthenticatingStatus();

  /**
   * @NOTE It is possible for the user to open a project invitation link
   * while logged in as a guest user.
   *
   * Forcing logout in this scenario can cause problems when two tabs are open.
   *
   * It doesn't seem to be problematic to have a guest token lying around, so
   * we can leave it for now and return to it later.
   *
   * @TODO #7118 - Check this.
   */

  return (
    <Page>
      <Box>
        <Heading as="h1" sx={welcomeStyle}>
          {t("invite.title", { appName: getAppName() })}
        </Heading>
        <Flex sx={dividerStyle}>
          <Avatar
            AvatarUrl={inviteDetails.IssuerPhotoUrl}
            ProjectColor="white"
            enlarge
          />
        </Flex>

        <Text sx={headingStyle}>
          {t("invite.heading", { name: inviteDetails.IssuerFirstName })}
        </Text>

        <Text sx={subHeaderStyle}>{t("invite.headingInfo")}</Text>

        <Flex sx={projectInfoStyle}>
          <Text sx={projectDescriptionStyle}>
            {inviteDetails.ProjectDescription}
          </Text>

          <Text sx={lastUpdateStyle}>
            {t("invite.projectLastUpdate", {
              date: new Date(inviteDetails.ProjectLastUpdate),
            })}
          </Text>

          <Text sx={ownerStyle}>
            {t("invite.projectOwner", {
              name: `${inviteDetails.ProjectOwnerFirstName} ${inviteDetails.ProjectOwnerLastName}`,
            })}
          </Text>

          {/* TODO: Use a placeholder image here? */}
          {inviteDetails.ProjectThumbnail && (
            <Image
              src={`data:image/png;base64,${inviteDetails.ProjectThumbnail}`}
              sx={thumbnailStyle}
            />
          )}
        </Flex>

        {isLoggingIn && <ContainedSpinner />}

        {isLoggedInAsUser && (
          <AcceptProjectInvitationForm
            inviteDetails={inviteDetails}
            loginLink={loginLink}
            token={token}
            tenant={tenant}
          />
        )}

        {!isLoggedInAsUser && !isLoggingIn && (
          <Tabs>
            <TabButtons>
              <Tab>{t("invite.acceptAsGuest")}</Tab>
              <Tab>{t("invite.acceptAsUser")}</Tab>
            </TabButtons>
            <TabPanels>
              <TabPanel>
                <AcceptProjectInvitationForm
                  inviteDetails={inviteDetails}
                  loginLink={loginLink}
                  token={token}
                  tenant={tenant}
                />
              </TabPanel>
              <TabPanel>
                <Redirect push to={loginLink} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
      </Box>
    </Page>
  );
}

export default ProjectInvitationDetails;

const welcomeStyle: ThemeUIStyleObject = {
  fontSize: [9],
  textAlign: "center",
};

const dividerStyle: ThemeUIStyleObject = {
  width: "100%",
  position: "relative",
  justifyContent: "center",
  my: [5],
  "&::after": {
    position: "absolute",
    left: 0,
    top: "50%",
    height: "1px",
    content: '""',
    width: "100%",
    display: "block",
    backgroundColor: "disabledDark",
    zIndex: -1,
  },
};

const headingStyle: ThemeUIStyleObject = {
  textAlign: "center",
  fontSize: [5],
  mb: [2],
  fontWeight: 500,
};
const subHeaderStyle: ThemeUIStyleObject = {
  textAlign: "center",
};

const projectInfoStyle: ThemeUIStyleObject = {
  flexDirection: "column",
  alignItems: "center",
  mx: "auto",
  my: [5],
  border: "1px solid",
  borderColor: "muted",
};

const projectDescriptionStyle: ThemeUIStyleObject = {
  fontWeight: 600,
  my: [2],
};

const ownerStyle: ThemeUIStyleObject = {
  color: "disabledTextDark",
};

const lastUpdateStyle: ThemeUIStyleObject = {
  ...ownerStyle,
  mb: [1],
};

const thumbnailStyle: ThemeUIStyleObject = {
  mt: [2],
  display: "block",
};
