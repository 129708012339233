import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearAuthenticatingAction } from "../../reduxStore/auth/auth.actions";
import {
  AuthenticatingDetails,
  isAuthenticatingState,
  isLoggingInState,
  isUninitializedState,
} from "../../reduxStore/auth/auth.reducer";

type UseAuthenticatingStatus = {
  authenticatingDetails: AuthenticatingDetails | undefined;
  isInitialized: boolean;
  isLoggingIn: boolean;
  clearAuthenticatingStatus: () => void;
};

export const useAuthenticatingStatus = (): UseAuthenticatingStatus => {
  const dispatch = useDispatch();

  /**
   * The auth state has been initialized.
   *
   * This is required to allow components to wait for tokens to be read from
   * storage etc.
   */
  const isInitialized = useSelector<ApplicationGlobalState, boolean>(
    ({ auth }) => !isUninitializedState(auth)
  );

  /**
   * A user is being logged in (we are waiting for the API request to complete).
   */
  const isLoggingIn = useSelector<ApplicationGlobalState, boolean>(({ auth }) =>
    isLoggingInState(auth)
  );

  /**
   * Get the details of an in-progress authentication.
   */
  const authenticatingDetails = useSelector<
    ApplicationGlobalState,
    AuthenticatingDetails | undefined
  >(({ auth }) => (isAuthenticatingState(auth) ? auth : undefined));

  /**
   * Clear the authenticating status.
   *
   * For example when the user closes the password or 2FA modal.
   */
  const clearAuthenticatingStatus = useCallback(
    () => dispatch(clearAuthenticatingAction()),
    [dispatch]
  );

  return {
    authenticatingDetails,
    isInitialized,
    isLoggingIn,
    clearAuthenticatingStatus,
  };
};
