import type { HistoryEntry, HistoryEntryAction } from "./history.entry.actions";

export enum HistoryActionType {
  HISTORY_ADD_ENTRY = "HISTORY_ADD_ENTRY",
  HISTORY_CLEAN_HISTORY_STACK = "HISTORY_CLEAN_HISTORY_STACK",
  HISTORY_CLEAR_REDO_STACK = "HISTORY_CLEAR_REDO_STACK",
  HISTORY_CLEAR_UNDO_STACK = "HISTORY_CLEAR_UNDO_STACK",
  HISTORY_NOOP = "HISTORY_NOOP",
  HISTORY_PRUNE_REDO_STACK = "HISTORY_PRUNE_REDO_STACK",
  HISTORY_PRUNE_UNDO_STACK = "HISTORY_PRUNE_UNDO_STACK",
  // Do not update the history state on these two actions, they are meant for sagas that need to run
  // side-effects before the new state is computed
  REDO = "REDO",
  UNDO = "UNDO",
  // Use this action to update the history state
  UPDATE_HISTORY = "UPDATE_HISTORY",
}

export const undoAction = () =>
  ({
    type: HistoryActionType.UNDO,
    payload: undefined,
  } as const);

export const redoAction = () =>
  ({
    type: HistoryActionType.REDO,
    payload: undefined,
  } as const);

export const updateHistoryAction = (
  payload: HistoryActionType.UNDO | HistoryActionType.REDO
) =>
  ({
    type: HistoryActionType.UPDATE_HISTORY,
    payload,
  } as const);

export const cleanHistoryStackAction = () =>
  ({
    type: HistoryActionType.HISTORY_CLEAN_HISTORY_STACK,
    payload: undefined,
  } as const);

export const clearUndoStackAction = () =>
  ({
    type: HistoryActionType.HISTORY_CLEAR_UNDO_STACK,
    payload: undefined,
  } as const);

export const clearRedoStackAction = () =>
  ({
    type: HistoryActionType.HISTORY_CLEAR_REDO_STACK,
    payload: undefined,
  } as const);

export const pruneRedoStackAction = (payload: { pruneToIndex: number }) =>
  ({
    type: HistoryActionType.HISTORY_PRUNE_REDO_STACK,
    payload,
  } as const);

export const pruneUndoStackAction = (payload: { pruneToIndex: number }) =>
  ({
    type: HistoryActionType.HISTORY_PRUNE_UNDO_STACK,
    payload,
  } as const);

export type UndoAction = ReturnType<typeof undoAction>;
export type RedoAction = ReturnType<typeof redoAction>;
export type UpdateHistoryAction = ReturnType<typeof updateHistoryAction>;
export type CleanHistoryStackAction = ReturnType<
  typeof cleanHistoryStackAction
>;
export type ClearUndoStackAction = ReturnType<typeof clearUndoStackAction>;
export type ClearRedoStackAction = ReturnType<typeof clearRedoStackAction>;
export type PruneRedoStackAction = ReturnType<typeof pruneRedoStackAction>;
export type PruneUndoStackAction = ReturnType<typeof pruneUndoStackAction>;

export type HistoryAction =
  | HistoryEntryAction<HistoryEntry>
  | UndoAction
  | RedoAction
  | UpdateHistoryAction
  | CleanHistoryStackAction
  | ClearUndoStackAction
  | ClearRedoStackAction
  | PruneRedoStackAction
  | PruneUndoStackAction;
