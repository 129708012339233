import { isPromise } from "../../../../tools/utils";
import { isAutoSelectable } from "../../../utils/fabricObjects";

/**
 * Run the callback without risking the ActiveSelection transform affecting the objects, e.g. when
 * serializing objects.
 *
 * ActiveSelection is a fabric Group, thus it changes selected position to be relative to the group center.
 */
export function runWithoutSelection(
  canvas: fabric.CollaboardCanvas,
  callback: () => Promise<void>
): Promise<void>;
export function runWithoutSelection(
  canvas: fabric.CollaboardCanvas,
  callback: () => void
): void;

export function runWithoutSelection(
  canvas: fabric.CollaboardCanvas,
  callback: () => void | Promise<void>
): void | Promise<void> {
  canvas.saveSelection();
  const returned = callback();

  if (isPromise(returned)) {
    return returned.then(() => {
      canvas.restoreSelection();
    });
  } else {
    canvas.restoreSelection();
    return undefined;
  }
}

/**
 * Similar to `runWithoutSelection` but only if the some of the `affectedUuids` are currently selected.
 *
 * This is typically to support running signalR-notify.saga in the secondary tabs of the same user,
 * which will always have an ActiveSelection of the `affectedUuids`, whose transform can cause issues.
 * But it doesn't hurt for the other users as well, who should not be able to select the same objects.
 */
export function runWithoutAffectingSelection(
  canvas: fabric.CollaboardCanvas,
  callback: () => Promise<void>,
  affectedUuids: string[]
): Promise<void>;
export function runWithoutAffectingSelection(
  canvas: fabric.CollaboardCanvas,
  callback: () => void,
  affectedUuids: string[]
): void;

export function runWithoutAffectingSelection(
  canvas: fabric.CollaboardCanvas,
  callback: () => void | Promise<void>,
  affectedUuids: string[]
): void | Promise<void> {
  const selectedUuids = canvas.getActiveObjects().map((o) => o.uuid);
  const isAffectingSelection = affectedUuids.some((uuid) =>
    selectedUuids.includes(uuid)
  );

  if (isAffectingSelection) {
    return runWithoutSelection(canvas, callback);
  } else {
    return callback();
  }
}

export const selectObjects = (
  canvas: fabric.CollaboardCanvas,
  objects: fabric.Object[],
  eventPayload?: Event | ObjectSelectionEvent
): void => {
  if (canvas.getActiveObjects().length > 1) {
    canvas.discardActiveObject({ isSilent: true });
  }

  const autoSelectableObjects = objects.filter(isAutoSelectable);

  const objectToSelect =
    autoSelectableObjects.length > 1
      ? new fabric.ActiveSelection(autoSelectableObjects, {
          /** @NOTE It's important to always pass the canvas reference on initialization for correct selection bounds */
          canvas: canvas as fabric.Canvas,
        })
      : autoSelectableObjects[0];

  objectToSelect && canvas.setActiveObject(objectToSelect, eventPayload);
};
