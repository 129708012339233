import { RefetchOptions } from "react-query/types/core/query";
import { useCallback, useEffect, useState } from "react";
import { combineResultsOfInfiniteQuery } from "../../../api/helpers";
import { UseParticipatingProjects } from "../../../api/useRequest";

type UseProjectsMemo = {
  shownProjects: ApiProject[];
  isSwitchingQuery: boolean;
};

type UseProjectListMemo = {
  projects: ApiProject[];
  removeFromList: (projectId: string) => void;
  updateList: (newProject: Project) => void;
  updateSpace: (projectId: string, spaceId: number | null) => void;
  updateTags: (projectId: string, newTags: string[]) => void;
};

// implements a "projects cache":
// when switching bewtween sources, and a new set of projects is fetching
// we still want to display projects from the prevoius source (until the call is pending)

export const useProjectsMemo = (
  currentSource: UseParticipatingProjects
): UseProjectsMemo => {
  const { results, queryKey, isFetching } = currentSource;
  const [shownProjects, setShownProjects] = useState(results);
  const [activeQuery, setActiveQuery] = useState("");
  const isSwitchingQuery = activeQuery !== queryKey;

  useEffect(() => {
    if (!isFetching) {
      setShownProjects(results);
      setActiveQuery(queryKey);
    }
  }, [isFetching, results, queryKey]);

  return { shownProjects, isSwitchingQuery };
};

export const useProjectListMemo = (
  data: PagedResponse<ApiProject>[] | undefined,
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<PagedResponse<ApiProject>[] | undefined>
): UseProjectListMemo => {
  const [projects, setProjects] = useState<ApiProject[]>([]); // local copy, not to re-fetch all projects on edit / removal

  useEffect(() => {
    setProjects(combineResultsOfInfiniteQuery(data));
  }, [data]);

  const updateList = useCallback(
    (newProject: Project) => {
      const modifiedProjects = projects.map((p) => {
        const isEdited = p.Project.ProjectId === newProject.ProjectId;
        p.Project = isEdited ? newProject : p.Project;
        return p;
      });
      setProjects(modifiedProjects);
    },
    [projects]
  );

  const updateSpace = useCallback(
    (projectId: string, spaceId: number | null) => {
      const modifiedProjects = projects.map((p) => {
        const isEdited = p.Project.ProjectId === projectId;
        p.Project = isEdited
          ? {
              ...p.Project,
              SpaceId: spaceId,
            }
          : p.Project;
        return p;
      });
      setProjects(modifiedProjects);
    },
    [projects]
  );

  const updateTags = useCallback(
    (projectId: string, tags: string[]) => {
      const modifiedProjects = projects.map((p) => {
        const isEdited = p.Project.ProjectId === projectId;
        p.Tags = isEdited ? tags : p.Tags;
        return p;
      });
      setProjects(modifiedProjects);
    },
    [projects]
  );

  const removeFromList = useCallback(
    (projectId: string) => {
      const hasReadOnlyProjects = projects.some((p) => !p.IsLicensed);

      if (hasReadOnlyProjects) {
        refetch();
      } else {
        const filteredProjects = projects.filter(
          (p) => p.Project.ProjectId !== projectId
        );
        setProjects(filteredProjects);
      }
    },
    [projects, refetch]
  );

  return { projects, removeFromList, updateSpace, updateTags, updateList };
};
