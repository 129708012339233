import React, { ReactElement } from "react";
import { Flex, Text, ThemeUIStyleObject } from "theme-ui";

type Props = {
  error: Error | string | null;
  children?: ReactElement | null;
};

function NoResults({ error, children = null }: Props): ReactElement {
  return (
    <Flex sx={containerStyle}>
      <Flex sx={innerContainerStyle}>
        {error ? (
          <Text variant="text.error">
            {error instanceof Error ? error.message : error}
          </Text>
        ) : (
          children
        )}
      </Flex>
    </Flex>
  );
}

const containerStyle: ThemeUIStyleObject = {
  borderBottom: "2px solid",
  borderColor: "primary",
  fontSize: 14,
  fontWeight: 300,
  flexWrap: "wrap",
  px: [3],
  "&:hover": {
    bg: "delicate",
  },
};

const innerContainerStyle: ThemeUIStyleObject = {
  flex: [1],
  flexBasis: "100%",
  paddingY: [1, null, 4],
};

export default NoResults;
