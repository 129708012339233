import { all, Effect, getContext, takeEvery } from "redux-saga/effects";
import { updateGroupAfterUpdate } from "../../../../studio/components/group/group.utils";
import { runWithoutSelection } from "../../../../studio/components/patches/extends-selection/selection.utils";
import {
  isActiveSelection,
  isGroup,
} from "../../../../studio/utils/fabricObjects";
import {
  AlignAction,
  AlignActionType,
  AlignDirection,
  DistributeAction,
  DistributeDirection,
} from "./alignment.actions";

type ObjDims = {
  width: number;
  height: number;
};

const distributeObjects = (
  canvas: fabric.CollaboardCanvas,
  group: fabric.Group,
  direction: DistributeDirection
): void => {
  const positionProp =
    direction === DistributeDirection.horizontal ? "left" : "top";
  const dimensionProp =
    direction === DistributeDirection.horizontal ? "width" : "height";

  const objs = group.getObjects();
  const filteredObjects = objs.filter((o) => !o.isLocked());
  const sortedObj = filteredObjects.sort((a, b) => {
    return (
      a.getBoundingRect(false)[positionProp] -
      b.getBoundingRect(false)[positionProp]
    );
  });
  const [firstItem] = sortedObj.slice(0);
  const [lastItem] = sortedObj.slice(-1);

  if (!firstItem || !lastItem) {
    return;
  }

  const objDims: Map<fabric.Object, ObjDims> = sortedObj.reduce(
    (map, object) => {
      const dims = object._getTransformedDimensions();
      const info = { width: dims.x, height: dims.y };

      map.set(object, info);
      return map;
    },
    new Map()
  );
  const lastItemDims = objDims.get(lastItem)!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

  const groupDimensions = group._getTransformedDimensions();
  const groupDim =
    direction === DistributeDirection.horizontal
      ? groupDimensions.x
      : groupDimensions.y;
  const totalDimension = Array.from(objDims.values()).reduce((sum, info) => {
    return sum + info[dimensionProp];
  }, 0);

  // Available gap between objects, can be negative
  const gapSpacing = Math.round(
    (groupDim - totalDimension) / (objs.length - 1)
  );
  // Available absolute space
  const spacing = Math.round(
    (groupDim - lastItemDims[dimensionProp]) / (objs.length - 1)
  );
  const objectsToDistribute = sortedObj.slice(1, sortedObj.length - 1);

  let previousItem = firstItem;

  objectsToDistribute.forEach((item) => {
    const prevItemDim = objDims.get(previousItem)!; // eslint-disable-line @typescript-eslint/no-non-null-assertion

    /**
     * If there's gap between objects then distribute the gap without overlapping
     * objects. Otherwise, we just divide the available space between the objects.
     *
     * Be aware of big objects.
     */
    const position =
      gapSpacing >= 0
        ? previousItem[positionProp] + prevItemDim[dimensionProp] + gapSpacing
        : previousItem[positionProp] + spacing;

    item.set({ [positionProp]: position });
    item.setCoords();
    previousItem = item;
  });
};

const repositionObjectsToRequiredAlignment = (
  activeObj: fabric.Group,
  direction: AlignDirection,
  canvas: fabric.CollaboardCanvas
) => {
  const objs = activeObj.getObjects();
  const transformer = isActiveSelection(activeObj)
    ? new fabric.Transformer(objs, { canvas })
    : activeObj;
  const first = objs[0];

  runWithoutSelection(canvas, () => {
    transformer.beginTransform();

    const { scaleX = 1, scaleY = 1 } = first;
    const _bottom = first.top + first.height * scaleY;
    const _right = first.left + first.width * scaleX;
    const _ch = first.left + first.width * scaleX * 0.5;
    const _cv = first.top + first.height * scaleY * 0.5;

    // recalculate positions
    objs.forEach((o) => {
      if (o.isLocked()) {
        return;
      }

      if (direction === AlignDirection.left) {
        o.left = first.left;
      } else if (direction === AlignDirection.top) {
        o.top = first.top;
      } else if (direction === AlignDirection.bottom) {
        o.top = _bottom - o.height * (o.scaleY || 1);
      } else if (direction === AlignDirection.right) {
        o.left = _right - o.width * (o.scaleX || 1);
      } else if (direction === AlignDirection.centerHorizontal) {
        o.left = _ch - o.width * (o.scaleX || 1) * 0.5;
      } else if (direction === AlignDirection.centerVertical) {
        o.top = _cv - o.height * (o.scaleY || 1) * 0.5;
      }
    });

    isGroup(activeObj) && updateGroupAfterUpdate(activeObj);
    objs.forEach((o) => !o.isLocked() && o.trigger("moved"));

    transformer.commitTransform({ isContextTransform: false });
  });
};

function* align({ payload: direction }: AlignAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const activeObj = canvas.getActiveObject();

  if (activeObj && (isGroup(activeObj) || isActiveSelection(activeObj))) {
    repositionObjectsToRequiredAlignment(activeObj, direction, canvas);

    canvas.requestRenderAll();
  }
}

function* distribute({ payload: direction }: DistributeAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const activeObj = canvas.getActiveObject();

  if (activeObj && (isGroup(activeObj) || isActiveSelection(activeObj))) {
    const objs = activeObj.getObjects();
    const transformer = isActiveSelection(activeObj)
      ? new fabric.Transformer(objs, {
          canvas,
        })
      : activeObj;

    runWithoutSelection(canvas, () => {
      transformer.beginTransform();

      distributeObjects(canvas, activeObj, direction);

      isGroup(activeObj) && updateGroupAfterUpdate(activeObj);
      objs.forEach((o) => !o.isLocked() && o.trigger("moved"));

      transformer.commitTransform({ isContextTransform: false });
    });

    canvas.requestRenderAll();
  }
}

export function* alignmentSaga(): Generator<Effect> {
  yield all([
    takeEvery(AlignActionType.ALIGN, align),
    takeEvery(AlignActionType.DISTRIBUTE, distribute),
  ]);
}
