import { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Flex, Heading } from "theme-ui";
import { updateAuthenticationMode } from "../../../../api";
import { setAuthenticateModeAction } from "../../../../reduxStore/auth/auth.actions";
import { selectAssertedUserProfile } from "../../../../reduxStore/auth/auth.reducer";
import { LogCategory } from "../../../../tools/telemetry";
import { ApiAuthenticationMode } from "../../../../types/enum";
import { useErrorHandler } from "../../../shared/hooks/useErrorHandler";
import { useSimpleModal } from "../../../shared/modals/useSimpleModal";
import useToast from "../../../shared/toasts/useToast";
import OTPInfoModalTemplate from "./OTPInfoModalTemplate";

const authenticationModes = [
  ApiAuthenticationMode.PASSWORD,
  ApiAuthenticationMode.OTP_CODE,
  ApiAuthenticationMode.TFA,
];

function UserAuthenticationMethod(): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { fallbackErrorHandler } = useErrorHandler();
  const profileInfoSavedToast = useToast("users.profileInfoSaved");

  const [OTPData, setOTPData] = useState<SetAuthenticationModeResponse | null>(
    null
  );
  const onClose = useCallback(() => setOTPData(null), []);
  const OTPModalProps = useSimpleModal({ onClose });

  const userProfile = useSelector(selectAssertedUserProfile);

  const updateAuthMode = useCallback(
    async (mode: ApiAuthenticationMode) => {
      try {
        const response = await updateAuthenticationMode(mode);
        setOTPData(response);
        OTPModalProps.open();
        profileInfoSavedToast.show();
        dispatch(setAuthenticateModeAction(mode));
      } catch (error) {
        fallbackErrorHandler(
          error,
          "Failed to update auth mode",
          LogCategory.auth
        );
      }
    },
    [OTPModalProps, dispatch, fallbackErrorHandler, profileInfoSavedToast]
  );

  useEffect(() => {
    return () => {
      profileInfoSavedToast.dismiss();
    };
  }, [profileInfoSavedToast]);

  return (
    <Flex as="section" variant="layout.flexWrap">
      <Heading as="h3" variant="text.h3">
        {t("form.header.authenticationMode")}
      </Heading>
      <Flex sx={{ flex: "1 0 100%" }}>
        {authenticationModes.map((mode) => {
          const isActive = userProfile.AuthenticationMode === mode;

          return (
            <Button
              id={`auth-method-${mode}`}
              key={mode}
              disabled={isActive}
              onClick={() => updateAuthMode(mode)}
              variant={isActive ? "radioButtonActive" : "radioButton"}
            >
              {t(`form.button.authType.${mode}`)}
            </Button>
          );
        })}
      </Flex>
      {OTPData && (
        <OTPInfoModalTemplate
          modalProps={OTPModalProps}
          OTPData={OTPData}
          authenticationMode={userProfile.AuthenticationMode}
        />
      )}
    </Flex>
  );
}

export default UserAuthenticationMethod;
