import { getAppVersion } from "../versionInfo";
import { errorLogStorage } from "../authStorageStores";
import {
  PropertiesToTrack,
  shouldLogErrorMessage,
} from "./applicationInsights";

// Currently we store errors in local storage.
// When telemetry utility is ready, we'll collect and send them
// This interface may be later converted into telemetry utility

export const logErrorToOnPrem = (
  error?: Error,
  category = "other",
  errorInfo: PropertiesToTrack = {}
): void => {
  try {
    if (shouldLogErrorMessage(error?.message ?? "Unkown")) {
      const { projectId, data } = errorInfo;
      const { message, stack } = error || {};
      const { href } = window.location;
      const errorsLog = errorLogStorage.get() ?? {};
      const errors = errorsLog[category] || [];
      errors.length >= 20 && errors.shift();
      errors.push({
        Timestamp: Date.now(),
        TimestampISO: new Date().toISOString(),
        Message: message,
        Stack: stack,
        Href: href,
        ProjectId: projectId,
        Data: data,
        PageVisibilityState: document.visibilityState,
        AppVersion: getAppVersion(),
      });
      errorsLog[category] = errors;
      errorLogStorage.set(errorsLog);
    }
  } catch (error) {
    // Make sure we never trigger any new errors in here
  }
};

export const trackEventToOnPrem = (
  name: string,
  properties?: PropertiesToTrack
): void => {
  // TODO: #3941 Start logging events to own API when this becomes available
};
