import { useEffect } from "react";
import { changeLanguage } from "../../i18n";
import { useSubscription } from "../../reduxStore/subscriptionDetails/subscriptionDetails.hook";
import { useUISettings } from "../../reduxStore/uiSettings";
import { useLoggedInStatus } from "../authentication/useLoggedInStatus";
import { usePermissions } from "../permissions/usePermissions";

type UsePostLogin = {
  isPostLoginActionDone: boolean;
};

/**
 * Perform post-login operations
 *
 * @TODO #7118 - Migrate this hook to Redux middleware (requires changes
 * to `useSubscription` and `useUISettings`)
 */
export const usePostLogin = (): UsePostLogin => {
  const { getSubscription } = useSubscription();
  const { getUserPermissions } = usePermissions();
  const { loadUISettings } = useUISettings();
  const { isLoggedIn, isLoggedInAsUser, userProfile } = useLoggedInStatus();

  const userLanguage = userProfile?.Language;
  const userName = userProfile?.UserName;

  /**
   * Initialize the app for a logged in user or guest
   */
  useEffect(() => {
    if (isLoggedIn) {
      if (isLoggedInAsUser) {
        // Run the requests in parallel
        getSubscription();
        getUserPermissions();
        changeLanguage(userLanguage);
      }

      loadUISettings();
    }
  }, [
    getUserPermissions,
    getSubscription,
    isLoggedIn,
    isLoggedInAsUser,
    loadUISettings,
    userLanguage,
    userName,
  ]);
  return {
    isPostLoginActionDone: isLoggedIn,
  };
};
