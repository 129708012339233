import React, { ReactElement } from "react";
import { BoxProps, Text, ThemeUIStyleObject } from "theme-ui";

type Props = {
  children: string;
} & BoxProps;

// TODO: configurable crop point (begin, center, end)

function CroppedText({ children, ref, ...textProps }: Props): ReactElement {
  return (
    <Text
      {...textProps}
      sx={{
        ...textStyle,
        ...textProps.sx,
      }}
      title={children}
    >
      {children}
    </Text>
  );
}

const textStyle: ThemeUIStyleObject = {
  padding: 2,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

export default CroppedText;
