export enum LayersActionType {
  MOVE_LAYER = "MOVE_LAYER",
  TOGGLE_OBJECT_STACKING = "TOGGLE_OBJECT_STACKING",
}

export enum LayerDirection {
  back = "back",
  front = "front",
  down = "down",
  up = "up",
}

export const moveLayerAction = (payload: LayerDirection) =>
  ({
    type: LayersActionType.MOVE_LAYER,
    payload,
  } as const);

export const toggleObjectStackingAction = (payload: boolean) =>
  ({
    type: LayersActionType.TOGGLE_OBJECT_STACKING,
    payload,
  } as const);

export type MoveLayerAction = ReturnType<typeof moveLayerAction>;
export type EnableObjectStackingAction = ReturnType<
  typeof toggleObjectStackingAction
>;

export type LayersAction = MoveLayerAction | EnableObjectStackingAction;
