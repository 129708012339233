import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Team(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M14.48,21.3A6,6,0,0,0,16,20.85l.05-.06a.94.94,0,0,0-.09-.12c-2-2.47-1.7-6-1.46-7.41,0,0,0-.06,0-.09a3.76,3.76,0,0,0-.81-.11,3.33,3.33,0,0,0-3.33,2.65c-.26,1.59-.18,3.65.88,4.94a2.2,2.2,0,0,1,.69,1.57A8.23,8.23,0,0,1,14.48,21.3Z" />
      <path d="M7.19,24.6c-1.32.94-1,3.09-1,3.09H9.53a6.57,6.57,0,0,1,1-4.21C9.39,24,8.12,23.94,7.19,24.6Z" />
      <path d="M30.49,15.71a3.33,3.33,0,0,0-3.33-2.65,3.75,3.75,0,0,0-.94.14.14.14,0,0,0,0,.06c.23,1.4.56,4.94-1.47,7.41l0,0,.19.25a7.91,7.91,0,0,0,1.27.35,7.94,7.94,0,0,1,2.73,1c-.06-.25-.06-.77.67-1.66C30.67,19.36,30.75,17.3,30.49,15.71Z" />
      <path d="M28,24.05h0a3.29,3.29,0,0,0-.69-.37l-.08,0a5.05,5.05,0,0,0-.69-.22l-.12,0-.64-.14c-.32-.07-.63-.15-.95-.24a4.05,4.05,0,0,1-2.27-1.46s-.55-.62.69-2.14a4.64,4.64,0,0,0,.59-1h0a7.19,7.19,0,0,0,.59-2.92v-.08a10.06,10.06,0,0,0-.14-1.85,4,4,0,0,0-7.83,0c0,.24-.07.48-.09.73h0a7.94,7.94,0,0,0-.05,1v.22a8.09,8.09,0,0,0,.29,2.08v0a5.56,5.56,0,0,0,.39,1h0a4.46,4.46,0,0,0,.49.78c1.25,1.52.69,2.14.69,2.14A4,4,0,0,1,16,23c-.39.12-.77.2-1.15.29l-.49.11-.13,0a4.91,4.91,0,0,0-.71.22l-.08,0a3.55,3.55,0,0,0-.72.38c-1.56,1.11-1.17,3.64-1.17,3.64h17.6A5.52,5.52,0,0,0,29,25.76,2.83,2.83,0,0,0,28,24.05Z" />
      <path d="M33.64,24.6c-1-.7-2.36-.61-3.52-1.2a6.46,6.46,0,0,1,1,4.29h3.48S35,25.54,33.64,24.6Z" />
    </Icon>
  );
}
export default Team;
