import { isNil } from "ramda";
import { isDefined } from "./utils";

export const stringifyQueryUrl = <T extends AnyRouteUrlQuery>(
  queryParams: T | null | undefined,
  config?: ParseOptions
): string => {
  if (!queryParams) {
    return "";
  }

  // remove null values and empty strings + stringify booleans
  const entries = Object.entries(queryParams)
    .map<undefined | [string, string]>(([key, value]) => {
      if (isNil(value) || value === "") {
        return undefined;
      }
      return [key, value.toString()];
    }, {})
    .filter(isDefined);

  const cleanObject = Object.fromEntries(entries);

  const urlQuery = new URLSearchParams(cleanObject).toString();
  const decodedQuery = config?.decode ? decodeURI(urlQuery) : urlQuery;

  return decodedQuery ? `?${decodedQuery}` : "";
};

type ParseOptions = {
  decode?: boolean;
};

export const parseQueryUrl = <T extends AnyRouteUrlQuery>(
  query: string | undefined | null,
  config?: ParseOptions
): T => {
  if (!query) {
    return {} as T;
  }

  // removes leading slashes
  const cleanQuery = query.replace(/^\//, "");
  const rawResults = Object.fromEntries(
    new URLSearchParams(cleanQuery).entries()
  );
  const { decode = true } = config || {};

  // parse boolean flags
  const entries = Object.entries(rawResults).map<[string, string | boolean]>(
    ([key, value]) => {
      const lowerValue = value.toLowerCase();
      const parsedValue =
        lowerValue === "true" ? true : lowerValue === "false" ? false : value;
      const decodedValue = decode
        ? parsedValue
        : encodeURIComponent(parsedValue);
      return [key, decodedValue];
    },
    {}
  );

  return Object.fromEntries(entries) as T;
};
