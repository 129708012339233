import { merge as deepMerge } from "merge-anything";
import { Selector } from "react-redux";
import { Reducer as ReduxReducer } from "redux";
import { createSelector } from "reselect";
import { merge, Theme } from "theme-ui";
import { DefaultTheme } from "../../themes/default";
import { getCustomerConfiguration } from "../../tools/customer";
import { runtimeConfig } from "../../tools/runtimeConfig";
import { UISettingsAction, UISettingsActionType } from "./uiSettings.actions";

const { theme } = getCustomerConfiguration();

export type UISettingsState = {
  logo: LogoConfig | null;
  dynamicFeatures:
    | RuntimeConfigDynamicFeatureFlags
    | RuntimeConfigDynamicFeatureConfig;
  themeOverrides: DeepPartial<DefaultTheme> | null;
  labels: TranslationsByLang;
};

const getInitialUISettingsState = (): UISettingsState => {
  return {
    logo: null,
    dynamicFeatures: {
      ...runtimeConfig.features,
    },
    themeOverrides: null,
    labels: {
      ...runtimeConfig.labels,
    },
  };
};

export const uiSettingsReducer: ReduxReducer<
  UISettingsState,
  UISettingsAction
> = (state = getInitialUISettingsState(), action): UISettingsState => {
  switch (action.type) {
    case UISettingsActionType.LOAD_UI_SETTINGS_DONE: {
      const { payload } = action;
      return {
        logo: payload.Logo?.src ? payload.Logo : state.logo,
        dynamicFeatures: {
          ...state.dynamicFeatures,
          ...payload.Features,
        },
        themeOverrides: payload.Theme,
        labels: deepMerge(state.labels, payload.Labels || {}),
      };
    }
    case UISettingsActionType.RESET_UI_SETTINGS: {
      return getInitialUISettingsState();
    }
    default:
      return state;
  }
};

export const selectTheme: Selector<
  ApplicationGlobalState,
  DefaultTheme
> = createSelector(
  (state: ApplicationGlobalState) => state.uiSettings.themeOverrides,
  (themeOverrides) => {
    return themeOverrides
      ? (merge(theme, themeOverrides as Theme) as DefaultTheme)
      : theme;
  }
);
