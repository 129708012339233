import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function ChevronDown(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <polygon points="13.05 17.13 14.11 16.07 20.48 22.44 26.8 16.13 27.86 17.19 20.48 24.56 13.05 17.13" />
    </Icon>
  );
}
export default ChevronDown;
