import Logger from "./awsCloudWatchService";
import { runtimeConfig } from "../runtimeConfig";

const accessKeyId: string = runtimeConfig.aws?.accessKeyId;
const secretAccessKey: string = runtimeConfig.aws?.accessKey;
const region: string = runtimeConfig.aws?.region;
const errorLogGroupName: string = runtimeConfig.aws?.errorLogGroup;
const eventLogGroupName: string = runtimeConfig.aws?.eventLogGroup;
let errorLogger: Logger | undefined;
let eventLogger: Logger | undefined;

export const startAwsLogger = async (): Promise<void> => {
  if (
    !accessKeyId ||
    !secretAccessKey ||
    !region ||
    !errorLogGroupName ||
    !eventLogGroupName
  ) {
    throw new Error(
      "At least one of the required configuration fields for the AWS logger does not exist or is empty"
    );
  }
  errorLogger = new Logger(
    accessKeyId,
    secretAccessKey,
    region,
    errorLogGroupName
  );
  await errorLogger.install();

  eventLogger = new Logger(
    accessKeyId,
    secretAccessKey,
    region,
    eventLogGroupName
  );
  await eventLogger.install();
};

export const logErrorToAws = (
  error: Error,
  category = "other",
  errorInfo = {}
): void => {
  if (!errorLogger) {
    throw new Error(
      "AWS Error logger: unable to log. Call startAwsLogger prior to start logging errors."
    );
  }
  try {
    errorLogger.logError(error, {
      errorInfo,
      type: category,
    });
  } catch (error) {
    // Make sure we never trigger any new errors in here
  }
};

export const trackEventToAws = (name: string, properties = {}): void => {
  if (!eventLogger) {
    throw new Error(
      "AWS Event logger: unable to log. Call startAwsLogger prior to start tracking events."
    );
  }
  eventLogger.trackEvent(name, {
    type: "Event",
    ...properties,
  });
};
