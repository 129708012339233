import { fabric } from "fabric";
import i18n from "i18next";
import { toast, ToastId } from "react-toastify";
import { call, getContext, put, select } from "redux-saga/effects";
import { TileStatus } from "../../../../api/signalR/message.types";
import { InternalError } from "../../../../errors/InternalError";
import { runWithoutSubselectionMode } from "../../../../studio/components/group/group.utils";
import { selectObjects } from "../../../../studio/components/patches/extends-selection/selection.utils";
import {
  getObjectByProperty,
  isCollection,
  isInCollection,
} from "../../../../studio/utils/fabricObjects";
import {
  deserializeRelation,
  isTileBatchActionObjectInfo,
  tileStatusToBatchActionInfo,
  toBaseObject,
  toContextProps,
  toObjectProps,
} from "../../../../studio/utils/objectConverter";
import { assertUnreachable } from "../../../../tools/assertions";
import { isError } from "../../../../tools/errors";
import { onErrorToLog } from "../../../../tools/telemetry";
import {
  addConnections,
  addObjects,
  deleteObjects,
  moveGroupedObjects,
  ungroupObjects,
  updateGroupZIndexes,
  updateTopLevelZIndexes,
} from "../../helpers/objects";
import { setBackgroundAction } from "../../settings/settings.actions";
import {
  cleanHistoryStackAction,
  HistoryActionType,
  updateHistoryAction,
} from "../history.actions";
import { HistoryEntry, HistoryEntryType } from "../history.entry.actions";
import { selectHistory } from "../history.reducer";

/**
 * @TODO #7133: Undo/Redo of actions which involve several objects being removed
 * from the canvas will result in their zIndex being inherently wrong because of
 * the zIndex reassignment for the remaining objects. Example:
 *
 * A zIndex 0
 * B zIndex 1
 * C zIndex 2
 * D zIndex 3
 * E zIndex 4
 *
 * And you remove B, C, D:
 * A zIndex 0
 * E zIndex 1
 *
 * And you do undo:
 * A zIndex 0
 * B zIndex 1
 * C zIndex 2
 * E zIndex 2
 * D zIndex 3
 *
 * And after sortAndReassign:
 * A zIndex 0
 * B zIndex 1
 * C zIndex 2
 * E zIndex 3
 * D zIndex 4
 *
 * The same happens if you do (group B+C+D + Undo) or do (Unstack B+C+D + Undo + Redo)
 *
 * 🤯
 */

/**
 * Another bug caused by zIndex reassignment. This will currently not restore objects in the correct zIndex. Example:
 *
 * Before UngroupAll:
 * G zIndex 0
 *   A zIndex 0
 *   B zIndex 1
 * C zIndex 1
 * H zIndex 2
 *   D zIndex 0
 *   E zIndex 1
 *
 * After UngroupAll:
 * A zIndex 0
 * B zIndex 1
 * C zIndex 2
 * D zIndex 3
 * E zIndex 4
 *
 * After Undo UngroupAll
 * G zIndex 0
 *   A zIndex 0
 *   B zIndex 1
 * H zIndex 2 <- insertAt adds the group before C
 *   D zIndex 0
 *   E zIndex 1
 * C zIndex 2
 *
 * 🤯
 *
 * Doing `canvas.sortAndReassignRootZIndexes({ skipSave: true })` after each group is re-added works,
 * but synced tabs will still have the wrong order for similar reasons. The only solution would
 * be probably to calculate the zIndex difference between Before Undo vs After Undo. 🤯
 * Or maybe instead of sending the difference, we send a message with the new zIndex for each top-level
 * object in the canvas. In thay way, we are always sure that users will have the same exact zIndex. 🤯 🤯 🤯
 */

/**
 * @TODO #7151: A yet another issue caused by zIndex. When you ungroup a group with 400 objects, we do
 * `canvas.sortAndReassignRootZIndexes()` in signalrMiddleware. This results in sending 400 objects in
 * postTileIndexesChange. We should send this information within postTileBatchAction itself.
 */

let historyToastId: ToastId;

const showUndoRedoToast = (
  type: HistoryActionType.UNDO | HistoryActionType.REDO,
  action: HistoryEntry
) => {
  const { transformationType, objectType } = action;
  let finalActionType = transformationType;
  const translationParams = {
    action: i18n.t(`action.${type}`),
    objectType: objectType && i18n.t(`tool.${objectType}`).toLowerCase(),
    modificationType: undefined,
  };

  if (transformationType === HistoryEntryType.MODIFY) {
    const { modificationType } = action;
    if (modificationType && i18n.exists(`transformation.${modificationType}`)) {
      translationParams.modificationType = i18n.t(
        `transformation.${modificationType}`
      );
    } else {
      // fall back to default translation to avoid "transformation.undefined" in toast
      finalActionType = HistoryEntryType.UPDATE;
    }
  }

  const toastMessage = i18n.t(`action.${finalActionType}`, translationParams);

  if (toast.isActive(historyToastId)) {
    toast.update(historyToastId, {
      render: toastMessage,
    });
  } else {
    historyToastId = toast(toastMessage);
  }
};

function* undoAction(action: HistoryEntry) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const addObjs = addObjects.bind(null, canvas);
  const deleteObjs = deleteObjects.bind(null, canvas);
  const moveGroupedObjs = moveGroupedObjects.bind(null, canvas);
  const ungroupObjs = ungroupObjects.bind(null, canvas);
  const addConns = addConnections.bind(null, canvas);

  switch (action.transformationType) {
    case HistoryEntryType.ADD: {
      const { addedObjects } = action;

      const uuids = addedObjects.map((o) => o.TileId);
      const objectsToRemove = canvas.getObjectsByUUIDs(uuids);

      canvas.remove(...objectsToRemove);
      return;
    }
    case HistoryEntryType.COPY: {
      const { targetObjects } = action;

      const targetUuids = targetObjects.map((o) => o.TileId);
      const clonesToRemove = canvas.getObjectsByUUIDs(targetUuids);

      canvas.remove(...clonesToRemove);
      return;
    }
    case HistoryEntryType.REMOVE: {
      const {
        removedConnections,
        removedObjects,
        removedChildren,
        removedAttachments,
      } = action;

      yield addObjects(canvas, [
        ...removedObjects,
        ...removedChildren,
        ...removedAttachments,
      ]);
      yield addConns(removedConnections);
      return;
    }
    case HistoryEntryType.CONNECT: {
      const { addedConnections } = action;

      const connUuids = addedConnections.map((c) => c.uuid);
      const connectionsToRemove = canvas.getObjectsByUUIDs(connUuids);
      canvas.remove(...connectionsToRemove);

      // filling in missing data for signalR middleware
      addedConnections.forEach((c) => {
        const connection = getObjectByProperty(
          connectionsToRemove,
          "uuid",
          c.uuid
        );
        if (connection instanceof fabric.CollaboardConnector) {
          c.Id = connection.relationId;
        }
      });
      return;
    }
    case HistoryEntryType.MODIFY: {
      const { deltas } = action;

      deltas.origin.length &&
        runWithoutSubselectionMode(canvas, () => {
          deltas.origin.forEach((props) => {
            const object = canvas.getObjectByUUID(props.TileId);
            if (object) {
              const delta = parseDelta(props.Delta);
              if (!delta) {
                return;
              }
              const propsToSet = toObjectProps(delta);
              // Sometimes there are context props in the delta, e.g. fontSize when editing
              const contextPropsToSet = toContextProps(delta);
              const eventProps: ClientModifiedEvent = {
                transform: { action: "undoRedo" },
              };

              // Important to set contextProps before propsToSet as the latter may depend
              // on the former, e.g. Textbox width depends on fontSize
              if (Object.keys(contextPropsToSet).length) {
                object.setContextProps(contextPropsToSet);
              }

              object.set(propsToSet);
              object.setCoords();

              object.trigger("modified", eventProps);
              object.trigger("custom:object:undoRedoModify", propsToSet);

              if (isCollection(object)) {
                object.getObjects().forEach((child) => {
                  child.trigger("modified", eventProps);
                  child.trigger("custom:object:undoRedoModify", propsToSet);
                });
              }
            }
          });
        });
      return;
    }
    case HistoryEntryType.UPDATE: {
      const { deltas } = action;

      deltas.origin.forEach(({ TileId, Delta }) => {
        const object = canvas.getObjectByUUID(TileId);
        if (object) {
          const delta = parseDelta(Delta);
          if (!delta) {
            return;
          }
          const previousObjectProps = toContextProps(delta);
          object.setContextProps(previousObjectProps);
        }
      });
      return;
    }
    case HistoryEntryType.UPDATE_CONNECTION: {
      const { originalConnections } = action;

      originalConnections.forEach((relation) => {
        const connection = canvas.getObjectByUUID(relation.uuid);
        if (connection) {
          const props = deserializeRelation(relation);
          connection.setContextProps(props);
        }
      });
      return;
    }
    case HistoryEntryType.GROUP: {
      const {
        addedGroup,
        topLevelObjectsBeforeGroup,
        childrenByGroupBeforeGroup,
        groupsBeforeGroup,
      } = action;

      const allChildrenBeforeGroup = groupsBeforeGroup.flatMap(({ TileId }) => {
        const data = childrenByGroupBeforeGroup[TileId] ?? [];

        return data.map((tile) => ({
          ...tile,
          ParentId: TileId,
        }));
      });

      yield addObjs(groupsBeforeGroup);
      moveGroupedObjs(
        allChildrenBeforeGroup
          .map(tileStatusToBatchActionInfo)
          .filter(isTileBatchActionObjectInfo),
        "parentChange"
      );
      ungroupObjs(
        topLevelObjectsBeforeGroup
          .map(tileStatusToBatchActionInfo)
          .filter(isTileBatchActionObjectInfo),
        "parentChange"
      );
      yield deleteObjs([addedGroup.TileId]);
      return;
    }
    case HistoryEntryType.UNGROUP: {
      const {
        removedChildren,
        removedConnections,
        groupBeforeUngroup,
        childrenBeforeUngroup,
      } = action;

      /**
       * @NOTE How Undo Ungroup works in case of 2nd last deleted subitem is quite fragile.
       * - First we re-added the group and the removed children
       *   - `addObjects` internally calls `nestInGroups` so the removed children will be already in the group
       *     at the end of the step, but their coordinates will be **wrong**. That's because the group is
       *     updated while the remaining not-removed children are still not grouped in it.
       * - Then we move the remaining not-removed children into the group, but we include the removed children
       *   as well.
       *   - `moveGroupedObjects` internally avoids moving children which are already in the correct group, but
       *     it will set all the children coordinates back to their original relative coordinates. So
       *     finally the group is updated and have correct coordinates because all children are grouped and
       *     have correct relative positions now 🤯.
       *
       * This behaviour is the same in both historyMiddleware and signalrMiddleware
       */
      yield addObjs([groupBeforeUngroup, ...removedChildren]);
      moveGroupedObjs(
        [...childrenBeforeUngroup, ...removedChildren]
          .map(tileStatusToBatchActionInfo)
          .filter(isTileBatchActionObjectInfo),
        "parentChange"
      );

      if (removedConnections.length) {
        yield addConns(removedConnections);
      }

      return;
    }
    case HistoryEntryType.UNGROUP_ALL: {
      const { childrenBeforeUngroup, groupsBeforeUngroup } = action;

      yield addObjs(groupsBeforeUngroup);
      moveGroupedObjs(
        childrenBeforeUngroup
          .map(tileStatusToBatchActionInfo)
          .filter(isTileBatchActionObjectInfo),
        "parentChange"
      );

      return;
    }
    case HistoryEntryType.STACK: {
      const { addedStack } = action;

      const stackToRemove = canvas.getObjectByUUID<fabric.Stack>(
        addedStack.TileId
      );
      stackToRemove && stackToRemove.unpack();
      return;
    }
    case HistoryEntryType.UNSTACK: {
      const { childrenBeforeUnstack, removedStack, removedConnections } =
        action;

      const stackOptions = toObjectProps<fabric.Stack>(removedStack);
      const childrenUuids = childrenBeforeUnstack.map((c) => c.TileId);
      const objectsToStack = canvas.getObjectsByUUIDs(childrenUuids);

      const stack = new fabric.ActiveSelection(objectsToStack, {
        canvas: canvas as fabric.Canvas,
      }).toStack(stackOptions);

      // Restack the objects exactly as they were before
      objectsToStack.forEach((obj) => {
        const props = childrenBeforeUnstack.find((t) => t.TileId === obj.uuid);
        props && obj.set(toBaseObject(props));
      });

      canvas.insertAt(stack, removedStack.ZIndex);

      if (removedConnections.length) {
        yield addConns(removedConnections);
      }

      return;
    }
    case HistoryEntryType.STACK_ROTATE: {
      const { stackUuid, isRotatedLeft } = action;

      const stack = canvas.getObjectByUUID<fabric.Stack>(stackUuid);
      isRotatedLeft ? stack?.rotateRight(true) : stack?.rotateLeft(true);
      return;
    }
    case HistoryEntryType.LOCK: {
      const { oldStatus } = action;

      oldStatus.forEach(({ uuid, isLocked: wasLocked }) => {
        const objectToLock = canvas.getObjectByUUID(uuid);
        objectToLock?.setPinned(wasLocked);
      });
      return;
    }
    case HistoryEntryType.MOVE_LAYER: {
      const { oldState, isSubselect } = action;

      isSubselect
        ? updateGroupZIndexes(canvas, oldState)
        : updateTopLevelZIndexes(canvas, oldState);
      return;
    }
    case HistoryEntryType.CHANGE_BACKGROUND: {
      const { oldBackgroundColor } = action;
      yield put(setBackgroundAction({ BackgroundColor: oldBackgroundColor }));
      return;
    }
    case HistoryEntryType.DUPLICATE:
    case HistoryEntryType.UPLOAD:
    case HistoryEntryType.SELECTION_CHANGED:
      // Not possible to undo
      return;
    default:
      assertUnreachable(action);
      return;
  }
}

function* redoAction(action: HistoryEntry) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  const addObjs = addObjects.bind(null, canvas);
  const deleteObjs = deleteObjects.bind(null, canvas);
  const moveGroupedObjs = moveGroupedObjects.bind(null, canvas);
  const ungroupObjs = ungroupObjects.bind(null, canvas);
  const addConns = addConnections.bind(null, canvas);

  // Selection events are silent here as the final PostLock is done in signalrMiddleware. Just be
  // sure to check that they are consistent.
  const selectEvent: ObjectSelectionEvent = { isSilent: true };
  const selectObjs = selectObjects.bind(null, canvas);

  switch (action.transformationType) {
    case HistoryEntryType.ADD: {
      const { addedObjects } = action;

      yield addObjs(addedObjects);

      return;
    }
    case HistoryEntryType.COPY: {
      const { targetObjects, targetChildren, targetConnections } = action;

      yield addObjs([...targetObjects, ...targetChildren]);
      yield addConns(targetConnections);

      return;
    }
    case HistoryEntryType.REMOVE: {
      const { removedConnections, removedObjects } = action;
      const uuids = removedObjects.map((o) => o.TileId);
      uuids.push(...removedConnections.map((c) => c.uuid));
      const objectsToRemove = canvas.getObjectsByUUIDs(uuids);

      removedConnections.forEach((relation) => {
        const connection = canvas.getObjectByUUID<fabric.CollaboardConnector>(
          relation.uuid
        );
        relation.Id = connection?.relationId ?? 0;
      });

      objectsToRemove.forEach((o) => {
        /** @TODO This actually doesn't work for subitems 🔫 */
        o.group && isInCollection(o) ? o.group.remove(o) : canvas.remove(o);
      });
      return;
    }
    case HistoryEntryType.CONNECT: {
      const { addedConnections } = action;
      yield addConns(addedConnections);
      return;
    }
    case HistoryEntryType.MODIFY: {
      const { deltas } = action;

      deltas.origin.length &&
        runWithoutSubselectionMode(canvas, () => {
          deltas.result.forEach((props) => {
            const object = canvas.getObjectByUUID(props.TileId);
            if (object) {
              const delta = parseDelta(props.Delta);
              if (!delta) {
                return;
              }
              const propsToSet = toObjectProps(delta);
              // Sometimes there are context props in the delta, e.g. fontSize when editing
              const contextPropsToSet = toContextProps(delta);
              const eventProps: ClientModifiedEvent = {
                transform: { action: "undoRedo" },
              };

              // Important to set contextProps before propsToSet as the latter may depend
              // on the former, e.g. Textbox width depends on fontSize
              if (Object.keys(contextPropsToSet).length) {
                object.setContextProps(contextPropsToSet);
              }

              object.set(propsToSet);
              object.setCoords();

              object.trigger("modified", eventProps);
              object.trigger("custom:object:undoRedoModify", propsToSet);

              if (isCollection(object)) {
                object.getObjects().forEach((child) => {
                  child.trigger("modified", eventProps);
                  child.trigger("custom:object:undoRedoModify", propsToSet);
                });
              }
            }
          });
        });
      return;
    }
    case HistoryEntryType.UPDATE: {
      const { deltas } = action;

      deltas.result.forEach(({ TileId, Delta }) => {
        const object = canvas.getObjectByUUID(TileId);
        if (object) {
          const delta = parseDelta(Delta);
          if (!delta) {
            return;
          }
          const previousObjectProps = toContextProps(delta);
          object.setContextProps(previousObjectProps);
        }
      });

      return;
    }
    case HistoryEntryType.UPDATE_CONNECTION: {
      const { resultingConnections } = action;
      resultingConnections.forEach((relation) => {
        const connection = canvas.getObjectByUUID(relation.uuid);
        if (connection) {
          const props = deserializeRelation(relation);
          connection.setContextProps(props);
        }
      });

      return;
    }
    case HistoryEntryType.GROUP: {
      const { addedGroup, allChildrenAfterGroup, groupsBeforeGroup } = action;

      const addedGroupObj: fabric.Object[] = yield addObjs([addedGroup]);
      moveGroupedObjs(
        allChildrenAfterGroup
          .map(tileStatusToBatchActionInfo)
          .filter(isTileBatchActionObjectInfo),
        "parentChange"
      );
      yield deleteObjs(groupsBeforeGroup.map((g) => g.TileId));

      selectObjs(addedGroupObj, selectEvent);

      return;
    }
    case HistoryEntryType.UNGROUP: {
      const { groupBeforeUngroup, objectsAfterUngroup, removedChildren } =
        action;

      ungroupObjs(
        objectsAfterUngroup
          .map(tileStatusToBatchActionInfo)
          .filter(isTileBatchActionObjectInfo),
        "parentChange"
      );
      yield deleteObjs(
        [...removedChildren, groupBeforeUngroup].map((g) => g.TileId)
      );

      const selectedObjs = canvas.getObjectsByUUIDs(
        objectsAfterUngroup.map((o) => o.TileId)
      );
      selectObjs(selectedObjs, selectEvent);

      return;
    }
    case HistoryEntryType.UNGROUP_ALL: {
      const {
        groupsBeforeUngroup,
        objectsAfterUngroup,
        topLevelObjectsBeforeUngroup,
      } = action;

      const groupUuids = groupsBeforeUngroup.map((g) => g.TileId);
      const groups = canvas.getObjectsByUUIDs(groupUuids) as fabric.Group[];

      groups.forEach((g) => g.unpack());

      const selectedTiles = [
        ...objectsAfterUngroup,
        ...topLevelObjectsBeforeUngroup,
      ];
      const selectedObjs = canvas.getObjectsByUUIDs(
        selectedTiles.map((o) => o.TileId)
      );
      selectObjs(selectedObjs, selectEvent);

      return;
    }
    case HistoryEntryType.STACK: {
      const { addedStack, childrenAfterStack } = action;

      const stackOptions = toObjectProps<fabric.Stack>(addedStack);
      const childrenUuids = childrenAfterStack.map((c) => c.TileId);
      const objectsToStack = canvas.getObjectsByUUIDs(childrenUuids);

      const stack = new fabric.ActiveSelection(objectsToStack, {
        canvas: canvas as fabric.Canvas,
      }).toStack(stackOptions);

      canvas.insertAt(stack, stack.zIndex);

      selectObjs([stack], selectEvent);

      return;
    }
    case HistoryEntryType.UNSTACK: {
      const { removedStack, objectsAfterUnstack } = action;

      const stackToRemove = canvas.getObjectByUUID<fabric.Stack>(
        removedStack.TileId
      );
      stackToRemove && stackToRemove.unpack();

      const selectedObjs = canvas.getObjectsByUUIDs(
        objectsAfterUnstack.map((o) => o.TileId)
      );
      selectObjs(selectedObjs, selectEvent);

      return;
    }
    case HistoryEntryType.STACK_ROTATE: {
      const { stackUuid, isRotatedLeft } = action;
      const stack = canvas.getObjectByUUID<fabric.Stack>(stackUuid);
      isRotatedLeft ? stack?.rotateLeft(true) : stack?.rotateRight(true);
      return;
    }
    case HistoryEntryType.LOCK: {
      const { newStatus } = action;

      newStatus.forEach(({ uuid, isLocked }) => {
        const unlockedObject = canvas.getObjectByUUID(uuid);
        unlockedObject && unlockedObject.setPinned(isLocked);
      });
      return;
    }
    case HistoryEntryType.MOVE_LAYER: {
      const { newState, isSubselect } = action;

      isSubselect
        ? updateGroupZIndexes(canvas, newState)
        : updateTopLevelZIndexes(canvas, newState);
      return;
    }
    case HistoryEntryType.CHANGE_BACKGROUND: {
      const { newBackgroundColor } = action;
      yield put(setBackgroundAction({ BackgroundColor: newBackgroundColor }));
      return;
    }
    case HistoryEntryType.DUPLICATE:
    case HistoryEntryType.UPLOAD:
    case HistoryEntryType.SELECTION_CHANGED:
      // Not possible to redo
      return;
    default:
      assertUnreachable(action);
      return;
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* undoSaga() {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const { undo } = selectHistory(yield select());

  const action = undo[undo.length - 1];

  if (!action) {
    return;
  }

  // Ensure the user has nothing selected because it affects positions and groups
  // Also broadcasts PostLock
  canvas.discardActiveObject();

  yield call(undoAction, action);

  showUndoRedoToast(HistoryActionType.UNDO, action);

  canvas.requestRenderAll();

  yield put(updateHistoryAction(HistoryActionType.UNDO));
  yield put(cleanHistoryStackAction());
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* redoSaga() {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const { redo } = selectHistory(yield select());

  const action = redo[0];

  // Ensure the user has nothing selected because it affects positions and groups
  // Also broadcasts PostLock
  canvas.discardActiveObject();

  yield call(redoAction, action);

  showUndoRedoToast(HistoryActionType.REDO, action);

  canvas.requestRenderAll();

  yield put(updateHistoryAction(HistoryActionType.REDO));
  yield put(cleanHistoryStackAction());
}

const parseDelta = (delta: string): Partial<TileStatus> | undefined => {
  try {
    return JSON.parse(delta);
  } catch (error) {
    onErrorToLog(
      isError(error)
        ? error
        : new InternalError(`Unable to parse delta: ${error}`)
    );
  }

  return undefined;
};
