import { IBV } from "@collaboard/protobuf";

/**
 * The name the server uses to send us a message.
 */
export enum NotifyMessageName {
  AdjustPresentationTimerMessage = "NotifyAdjustPresentationTimer",
  BackgroundMessage = "NotifyProjectBackgroundChanged",
  CommentCreatedMessage = "NotifyCommentCreated",
  CommentDeletedMessage = "NotifyCommentDeleted",
  CommentLikeChangedMessage = "NotifyCommentLikeChanged",
  DeleteProjectMessage = "NotifyDeleteProject",
  DeleteTileMessage = "NotifyDelete",
  GenericAvailableMessage = "NotifyUserOfAvailableMessage",
  InitialDataForNewJoinToPresentationMessage = "NotifyUpdateNewPresentationJoiner",
  InitialDataForNewJoinToTimerMessage = "NotifyUpdateNewTimerJoiner",
  LinkCreatedMessage = "NotifyLinkCreated",
  LinkDeletedMessage = "NotifyLinkDeleted",
  LinkUpdatedMessage = "NotifyLinkUpdated",
  LogInProjectMessage = "NotifyLoginProject",
  LogOutProjectMessage = "NotifyLogoutProject",
  LowResThumbnailAvailableMessage = "NotifyLowResThumbnailAvailable",
  NewBigInkMessage = "NotifyNewBigInk",
  NewMessage = "NotifyNew",
  NotifyConnectionStartedMessage = "NotifyConnectionStarted",
  NudgeForAttentionMessage = "NotifyNudgeForAttention",
  PausePresentationTimerMessage = "NotifyPausePresentationTimer",
  PingbackMessage = "NotifyPingbackRequest",
  PinMessage = "NotifyPinningActivity",
  ProjectCopiedMessage = "NotifyProjectCopied",
  ProjectCopyFailedMessage = "NotifyProjectCopyFailed",
  ProjectParticipantMessage = "NotifyProjectParticipantChanged",
  ProjectUnavailableMessage = "NotifyProjectUnavailable",
  ResponsiveImagesAvailableMessage = "NotifyResponsiveImagesAvailable",
  ScreenPosZoomChangedMessage = "NotifyScreenPosZoomChanged",
  SingleThumbnailsMessage = "NotifyThumbnailAvailable",
  StartPresentationTimerMessage = "NotifyStartPresentationTimer",
  StartPresentingMessage = "NotifyStartPresenting",
  StopPresentationTimerMessage = "NotifyStopPresentationTimer",
  StopPresentingMessage = "NotifyStopPresenting",
  SystemMessage = "NotifyServiceError",
  ThumbnailCreationFailedMessage = "NotifyThumbnailsFailed",
  ThumbnailPageChangedMessage = "NotifyThumbnailPageChanged",
  ThumbnailsCreatedMessage = "NotifyThumbnailsCreated",
  ThumbnailsCreatingMessage = "NotifyThumbnailsCreating",
  TileBatchActionMessage = "NotifyTileBatchAction",
  TileGroupMessage = "NotifyTileReorder",
  TileIndexesMessage = "NotifyTileIndexesChanged",
  TilePropertyMessage = "NotifyTilePropertiesChanged",
  TileRelationMessage = "NotifyTileRelationChanged",
  TilesCopiedMessage = "NotifyTilesCopied",
  UploadedMessage = "NotifyUploaded",
  VideoPlayerStartStopCalledMessage = "NotifyVideoPlayerStartStopCalled",
  VotingCastMessage = "NotifyVoteCast",
  VotingRevokedMessage = "NotifyVoteRevoked",
  VotingSessionFinalizedMessage = "NotifyUserFinalizedVotingSession",
  VotingStartedMessage = "NotifyVotingStarted",
  VotingStoppedMessage = "NotifyVotingStopped",
  VotingTimeAdjustedMessage = "NotifyVotingTimeAdjusted",
}

/**
 * The name (endpoint) we must use when sending a message to the server.
 */
export enum PostMessageName {
  AdjustPresentationTimerMessage = "PostAdjustPresentationTimer",
  BackgroundMessage = "PostProjectBackgroundChanged",
  DeleteTileMessage = "PostDelete",
  GenericAvailableMessage = "PostNotifyUserOfAvailableMessage",
  InitialDataForNewJoinToPresentationMessage = "PostUpdateNewJoinedInPresentation",
  InitialDataForNewJoinToTimerMessage = "PostUpdateNewJoinedForTimer",
  LogInProjectMessage = "PostLoginProject",
  LogOutProjectMessage = "PostLogoutProject",
  NewMessage = "PostNew",
  PingbackMessage = "PostPingbackRequest",
  PinMessage = "PostPinningActivity",
  ProjectParticipantMessage = "PostProjectParticipantChanged",
  ScreenPosZoomChangedMessage = "PostScreenPosZoomChanged",
  ThumbnailPageChangedMessage = "PostThumbnailPageChanged",
  TileBatchActionMessage = "PostTileBatchAction",
  TileGroupMessage = "PostTileReorder",
  TileIndexesMessage = "PostTileIndexesChanged",
  TilePropertyMessage = "PostPropertyUpdated",
  TileRelationMessage = "PostTileRelationChanged",
  UploadedMessage = "PostUploaded",
}

/**
 * This is required for the `messageEncoders: Record<>` otherwise the value part
 * will not be inferred by TS.
 *
 * This list should only contain messages that the client can send.
 */
type MessageEncoder =
  | typeof IBV.Collaboard.Protobuf.AdjustPresentationTimerMessage
  | typeof IBV.Collaboard.Protobuf.BackgroundMessage
  | typeof IBV.Collaboard.Protobuf.CommentCreatedMessage
  | typeof IBV.Collaboard.Protobuf.CommentDeletedMessage
  | typeof IBV.Collaboard.Protobuf.CommentLikeChangedMessage
  | typeof IBV.Collaboard.Protobuf.DeleteTileMessage
  | typeof IBV.Collaboard.Protobuf.GenericAvailableMessage
  | typeof IBV.Collaboard.Protobuf.InitialDataForNewJoinToPresentationMessage
  | typeof IBV.Collaboard.Protobuf.InitialDataForNewJoinToTimerMessage
  | typeof IBV.Collaboard.Protobuf.LogInProjectMessage
  | typeof IBV.Collaboard.Protobuf.LogOutProjectMessage
  | typeof IBV.Collaboard.Protobuf.NewMessage
  | typeof IBV.Collaboard.Protobuf.NotifyConnectionStartedMessage
  | typeof IBV.Collaboard.Protobuf.PingbackMessage
  | typeof IBV.Collaboard.Protobuf.PinMessage
  | typeof IBV.Collaboard.Protobuf.ProjectCopiedMessage
  | typeof IBV.Collaboard.Protobuf.ProjectCopyFailedMessage
  | typeof IBV.Collaboard.Protobuf.ProjectParticipantMessage
  | typeof IBV.Collaboard.Protobuf.ScreenPosZoomChangedMessage
  | typeof IBV.Collaboard.Protobuf.SystemMessage
  | typeof IBV.Collaboard.Protobuf.ThumbnailPageChangedMessage
  | typeof IBV.Collaboard.Protobuf.TileBatchActionMessage
  | typeof IBV.Collaboard.Protobuf.TileGroupMessage
  | typeof IBV.Collaboard.Protobuf.TileIndexesMessage
  | typeof IBV.Collaboard.Protobuf.TileLockMessage
  | typeof IBV.Collaboard.Protobuf.TilePropertyMessage
  | typeof IBV.Collaboard.Protobuf.TileRelationMessage
  | typeof IBV.Collaboard.Protobuf.UploadedMessage;

/**
 * Map the send endpoint name to the message shape.
 *
 * This is used to help TS determine which class will be used for each message
 * type.
 *
 * @NOTE we use the interface rather than the class here, e.g. `INewMessage`
 * rather than `NewMessage`.
 */
export type MessageEncoderMap = {
  [PostMessageName.AdjustPresentationTimerMessage]: IBV.Collaboard.Protobuf.IAdjustPresentationTimerMessage;
  [PostMessageName.BackgroundMessage]: IBV.Collaboard.Protobuf.IBackgroundMessage;
  [PostMessageName.DeleteTileMessage]: IBV.Collaboard.Protobuf.IDeleteTileMessage;
  [PostMessageName.GenericAvailableMessage]: IBV.Collaboard.Protobuf.IGenericAvailableMessage;
  [PostMessageName.InitialDataForNewJoinToPresentationMessage]: IBV.Collaboard.Protobuf.IInitialDataForNewJoinToPresentationMessage;
  [PostMessageName.InitialDataForNewJoinToTimerMessage]: IBV.Collaboard.Protobuf.IInitialDataForNewJoinToTimerMessage;
  [PostMessageName.LogInProjectMessage]: IBV.Collaboard.Protobuf.ILogInProjectMessage;
  [PostMessageName.LogOutProjectMessage]: IBV.Collaboard.Protobuf.ILogOutProjectMessage;
  [PostMessageName.NewMessage]: IBV.Collaboard.Protobuf.INewMessage;
  [PostMessageName.PingbackMessage]: IBV.Collaboard.Protobuf.IPingbackMessage;
  [PostMessageName.PinMessage]: IBV.Collaboard.Protobuf.IPinMessage;
  [PostMessageName.ProjectParticipantMessage]: IBV.Collaboard.Protobuf.IProjectParticipantMessage;
  [PostMessageName.ScreenPosZoomChangedMessage]: IBV.Collaboard.Protobuf.IScreenPosZoomChangedMessage;
  [PostMessageName.ThumbnailPageChangedMessage]: IBV.Collaboard.Protobuf.IThumbnailPageChangedMessage;
  [PostMessageName.TileBatchActionMessage]: IBV.Collaboard.Protobuf.ITileBatchActionMessage;
  [PostMessageName.TileGroupMessage]: IBV.Collaboard.Protobuf.ITileGroupMessage;
  [PostMessageName.TileIndexesMessage]: IBV.Collaboard.Protobuf.ITileIndexesMessage;
  [PostMessageName.TilePropertyMessage]: IBV.Collaboard.Protobuf.ITilePropertyMessage;
  [PostMessageName.TileRelationMessage]: IBV.Collaboard.Protobuf.ITileRelationMessage;
  [PostMessageName.UploadedMessage]: IBV.Collaboard.Protobuf.IUploadedMessage;
};

/**
 * Map the send endpoint name to the Protobuf encoder class.
 */
export const messageEncoders: Record<PostMessageName, MessageEncoder> = {
  [PostMessageName.AdjustPresentationTimerMessage]:
    IBV.Collaboard.Protobuf.AdjustPresentationTimerMessage,
  [PostMessageName.BackgroundMessage]:
    IBV.Collaboard.Protobuf.BackgroundMessage,
  [PostMessageName.DeleteTileMessage]:
    IBV.Collaboard.Protobuf.DeleteTileMessage,
  [PostMessageName.GenericAvailableMessage]:
    IBV.Collaboard.Protobuf.GenericAvailableMessage,
  [PostMessageName.InitialDataForNewJoinToPresentationMessage]:
    IBV.Collaboard.Protobuf.InitialDataForNewJoinToPresentationMessage,
  [PostMessageName.InitialDataForNewJoinToTimerMessage]:
    IBV.Collaboard.Protobuf.InitialDataForNewJoinToTimerMessage,
  [PostMessageName.LogInProjectMessage]:
    IBV.Collaboard.Protobuf.LogInProjectMessage,
  [PostMessageName.LogOutProjectMessage]:
    IBV.Collaboard.Protobuf.LogOutProjectMessage,
  [PostMessageName.NewMessage]: IBV.Collaboard.Protobuf.NewMessage,
  [PostMessageName.PingbackMessage]: IBV.Collaboard.Protobuf.PingbackMessage,
  [PostMessageName.PinMessage]: IBV.Collaboard.Protobuf.PinMessage,
  [PostMessageName.ProjectParticipantMessage]:
    IBV.Collaboard.Protobuf.ProjectParticipantMessage,
  [PostMessageName.ScreenPosZoomChangedMessage]:
    IBV.Collaboard.Protobuf.ScreenPosZoomChangedMessage,
  [PostMessageName.ThumbnailPageChangedMessage]:
    IBV.Collaboard.Protobuf.ThumbnailPageChangedMessage,
  [PostMessageName.TileBatchActionMessage]:
    IBV.Collaboard.Protobuf.TileBatchActionMessage,
  [PostMessageName.TileGroupMessage]: IBV.Collaboard.Protobuf.TileGroupMessage,
  [PostMessageName.TileIndexesMessage]:
    IBV.Collaboard.Protobuf.TileIndexesMessage,
  [PostMessageName.TilePropertyMessage]:
    IBV.Collaboard.Protobuf.TilePropertyMessage,
  [PostMessageName.TileRelationMessage]:
    IBV.Collaboard.Protobuf.TileRelationMessage,
  [PostMessageName.UploadedMessage]: IBV.Collaboard.Protobuf.UploadedMessage,
} as const;

/**
 * Map the receive endpoint name to the Protobuf decoded class. This is used
 * to help TS determine which class will be used for each message type.
 */
export type MessageDecoderMap = {
  [NotifyMessageName.AdjustPresentationTimerMessage]: IBV.Collaboard.Protobuf.AdjustPresentationTimerMessage;
  [NotifyMessageName.BackgroundMessage]: IBV.Collaboard.Protobuf.BackgroundMessage;
  [NotifyMessageName.CommentCreatedMessage]: IBV.Collaboard.Protobuf.CommentCreatedMessage;
  [NotifyMessageName.CommentDeletedMessage]: IBV.Collaboard.Protobuf.CommentDeletedMessage;
  [NotifyMessageName.CommentLikeChangedMessage]: IBV.Collaboard.Protobuf.CommentLikeChangedMessage;
  [NotifyMessageName.DeleteProjectMessage]: IBV.Collaboard.Protobuf.DeleteProjectMessage;
  [NotifyMessageName.DeleteTileMessage]: IBV.Collaboard.Protobuf.DeleteTileMessage;
  [NotifyMessageName.GenericAvailableMessage]: IBV.Collaboard.Protobuf.GenericAvailableMessage;
  [NotifyMessageName.InitialDataForNewJoinToPresentationMessage]: IBV.Collaboard.Protobuf.InitialDataForNewJoinToPresentationMessage;
  [NotifyMessageName.InitialDataForNewJoinToTimerMessage]: IBV.Collaboard.Protobuf.InitialDataForNewJoinToTimerMessage;
  [NotifyMessageName.LinkCreatedMessage]: IBV.Collaboard.Protobuf.LinkCreatedMessage;
  [NotifyMessageName.LinkDeletedMessage]: IBV.Collaboard.Protobuf.LinkDeletedMessage;
  [NotifyMessageName.LinkUpdatedMessage]: IBV.Collaboard.Protobuf.LinkUpdatedMessage;
  [NotifyMessageName.LogInProjectMessage]: IBV.Collaboard.Protobuf.LogInProjectMessage;
  [NotifyMessageName.LogOutProjectMessage]: IBV.Collaboard.Protobuf.LogOutProjectMessage;
  [NotifyMessageName.LowResThumbnailAvailableMessage]: IBV.Collaboard.Protobuf.LowResThumbnailAvailableMessage;
  [NotifyMessageName.NewBigInkMessage]: IBV.Collaboard.Protobuf.NewBigInkMessage;
  [NotifyMessageName.NewMessage]: IBV.Collaboard.Protobuf.NewMessage;
  [NotifyMessageName.NotifyConnectionStartedMessage]: IBV.Collaboard.Protobuf.NotifyConnectionStartedMessage;
  [NotifyMessageName.NudgeForAttentionMessage]: IBV.Collaboard.Protobuf.NudgeForAttentionMessage;
  [NotifyMessageName.PausePresentationTimerMessage]: IBV.Collaboard.Protobuf.PausePresentationTimerMessage;
  [NotifyMessageName.PingbackMessage]: IBV.Collaboard.Protobuf.PingbackMessage;
  [NotifyMessageName.PinMessage]: IBV.Collaboard.Protobuf.PinMessage;
  [NotifyMessageName.ProjectCopiedMessage]: IBV.Collaboard.Protobuf.ProjectCopiedMessage;
  [NotifyMessageName.ProjectCopyFailedMessage]: IBV.Collaboard.Protobuf.ProjectCopyFailedMessage;
  [NotifyMessageName.ProjectParticipantMessage]: IBV.Collaboard.Protobuf.ProjectParticipantMessage;
  [NotifyMessageName.ProjectUnavailableMessage]: IBV.Collaboard.Protobuf.ProjectUnavailableMessage;
  [NotifyMessageName.ResponsiveImagesAvailableMessage]: IBV.Collaboard.Protobuf.ResponsiveImagesAvailableMessage;
  [NotifyMessageName.ScreenPosZoomChangedMessage]: IBV.Collaboard.Protobuf.ScreenPosZoomChangedMessage;
  [NotifyMessageName.SingleThumbnailsMessage]: IBV.Collaboard.Protobuf.SingleThumbnailsMessage;
  [NotifyMessageName.StartPresentationTimerMessage]: IBV.Collaboard.Protobuf.StartPresentationTimerMessage;
  [NotifyMessageName.StartPresentingMessage]: IBV.Collaboard.Protobuf.StartStopPresentingMessage; // Shared message
  [NotifyMessageName.StopPresentationTimerMessage]: IBV.Collaboard.Protobuf.StopPresentationTimerMessage;
  [NotifyMessageName.StopPresentingMessage]: IBV.Collaboard.Protobuf.StartStopPresentingMessage; // Shared message
  [NotifyMessageName.SystemMessage]: IBV.Collaboard.Protobuf.SystemMessage;
  [NotifyMessageName.ThumbnailCreationFailedMessage]: IBV.Collaboard.Protobuf.ThumbnailCreationFailedMessage;
  [NotifyMessageName.ThumbnailPageChangedMessage]: IBV.Collaboard.Protobuf.ThumbnailPageChangedMessage;
  [NotifyMessageName.ThumbnailsCreatedMessage]: IBV.Collaboard.Protobuf.ThumbnailsMessage; // Shared message
  [NotifyMessageName.ThumbnailsCreatingMessage]: IBV.Collaboard.Protobuf.ThumbnailsMessage; // Shared message
  [NotifyMessageName.TileBatchActionMessage]: IBV.Collaboard.Protobuf.TileBatchActionMessage;
  [NotifyMessageName.TileGroupMessage]: IBV.Collaboard.Protobuf.TileGroupMessage;
  [NotifyMessageName.TileIndexesMessage]: IBV.Collaboard.Protobuf.TileIndexesMessage;
  [NotifyMessageName.TilePropertyMessage]: IBV.Collaboard.Protobuf.TilePropertyMessage;
  [NotifyMessageName.TileRelationMessage]: IBV.Collaboard.Protobuf.TileRelationMessage;
  [NotifyMessageName.TilesCopiedMessage]: IBV.Collaboard.Protobuf.TilesCopiedMessage;
  [NotifyMessageName.UploadedMessage]: IBV.Collaboard.Protobuf.UploadedMessage;
  [NotifyMessageName.VideoPlayerStartStopCalledMessage]: IBV.Collaboard.Protobuf.VideoPlayerStartStopCalledMessage;
  [NotifyMessageName.VotingCastMessage]: IBV.Collaboard.Protobuf.VotingCastMessage;
  [NotifyMessageName.VotingRevokedMessage]: IBV.Collaboard.Protobuf.VotingRevokedMessage;
  [NotifyMessageName.VotingSessionFinalizedMessage]: IBV.Collaboard.Protobuf.VotingSessionFinalizedMessage;
  [NotifyMessageName.VotingStartedMessage]: IBV.Collaboard.Protobuf.VotingStartedMessage;
  [NotifyMessageName.VotingStoppedMessage]: IBV.Collaboard.Protobuf.VotingStoppedMessage;
  [NotifyMessageName.VotingTimeAdjustedMessage]: IBV.Collaboard.Protobuf.VotingTimeAdjustedMessage;
};

/**
 * Map the receive endpoint name to the Protobuf decoder class.
 */
export const messageDecoders: Record<NotifyMessageName, MessageEncoder> = {
  [NotifyMessageName.AdjustPresentationTimerMessage]:
    IBV.Collaboard.Protobuf.AdjustPresentationTimerMessage,
  [NotifyMessageName.BackgroundMessage]:
    IBV.Collaboard.Protobuf.BackgroundMessage,
  [NotifyMessageName.CommentCreatedMessage]:
    IBV.Collaboard.Protobuf.CommentCreatedMessage,
  [NotifyMessageName.CommentDeletedMessage]:
    IBV.Collaboard.Protobuf.CommentDeletedMessage,
  [NotifyMessageName.CommentLikeChangedMessage]:
    IBV.Collaboard.Protobuf.CommentLikeChangedMessage,
  [NotifyMessageName.DeleteProjectMessage]:
    IBV.Collaboard.Protobuf.DeleteProjectMessage,
  [NotifyMessageName.DeleteTileMessage]:
    IBV.Collaboard.Protobuf.DeleteTileMessage,
  [NotifyMessageName.GenericAvailableMessage]:
    IBV.Collaboard.Protobuf.GenericAvailableMessage,
  [NotifyMessageName.InitialDataForNewJoinToPresentationMessage]:
    IBV.Collaboard.Protobuf.InitialDataForNewJoinToPresentationMessage,
  [NotifyMessageName.InitialDataForNewJoinToTimerMessage]:
    IBV.Collaboard.Protobuf.InitialDataForNewJoinToTimerMessage,
  [NotifyMessageName.LinkCreatedMessage]:
    IBV.Collaboard.Protobuf.LinkCreatedMessage,
  [NotifyMessageName.LinkDeletedMessage]:
    IBV.Collaboard.Protobuf.LinkDeletedMessage,
  [NotifyMessageName.LinkUpdatedMessage]:
    IBV.Collaboard.Protobuf.LinkUpdatedMessage,
  [NotifyMessageName.LogInProjectMessage]:
    IBV.Collaboard.Protobuf.LogInProjectMessage,
  [NotifyMessageName.LogOutProjectMessage]:
    IBV.Collaboard.Protobuf.LogOutProjectMessage,
  [NotifyMessageName.LowResThumbnailAvailableMessage]:
    IBV.Collaboard.Protobuf.LowResThumbnailAvailableMessage,
  [NotifyMessageName.NewBigInkMessage]:
    IBV.Collaboard.Protobuf.NewBigInkMessage,
  [NotifyMessageName.NewMessage]: IBV.Collaboard.Protobuf.NewMessage,
  [NotifyMessageName.NotifyConnectionStartedMessage]:
    IBV.Collaboard.Protobuf.NotifyConnectionStartedMessage,
  [NotifyMessageName.NudgeForAttentionMessage]:
    IBV.Collaboard.Protobuf.NudgeForAttentionMessage,
  [NotifyMessageName.PausePresentationTimerMessage]:
    IBV.Collaboard.Protobuf.PausePresentationTimerMessage,
  [NotifyMessageName.PingbackMessage]: IBV.Collaboard.Protobuf.PingbackMessage,
  [NotifyMessageName.PinMessage]: IBV.Collaboard.Protobuf.PinMessage,
  [NotifyMessageName.ProjectCopiedMessage]:
    IBV.Collaboard.Protobuf.ProjectCopiedMessage,
  [NotifyMessageName.ProjectCopyFailedMessage]:
    IBV.Collaboard.Protobuf.ProjectCopyFailedMessage,
  [NotifyMessageName.ProjectParticipantMessage]:
    IBV.Collaboard.Protobuf.ProjectParticipantMessage,
  [NotifyMessageName.ProjectUnavailableMessage]:
    IBV.Collaboard.Protobuf.ProjectUnavailableMessage,
  [NotifyMessageName.ResponsiveImagesAvailableMessage]:
    IBV.Collaboard.Protobuf.ResponsiveImagesAvailableMessage,
  [NotifyMessageName.ScreenPosZoomChangedMessage]:
    IBV.Collaboard.Protobuf.ScreenPosZoomChangedMessage,
  [NotifyMessageName.SingleThumbnailsMessage]:
    IBV.Collaboard.Protobuf.SingleThumbnailsMessage,
  [NotifyMessageName.StartPresentationTimerMessage]:
    IBV.Collaboard.Protobuf.StartPresentationTimerMessage,
  [NotifyMessageName.StartPresentingMessage]:
    IBV.Collaboard.Protobuf.StartStopPresentingMessage, // Shared message
  [NotifyMessageName.StopPresentationTimerMessage]:
    IBV.Collaboard.Protobuf.StopPresentationTimerMessage,
  [NotifyMessageName.StopPresentingMessage]:
    IBV.Collaboard.Protobuf.StartStopPresentingMessage, // Shared message
  [NotifyMessageName.SystemMessage]: IBV.Collaboard.Protobuf.SystemMessage,
  [NotifyMessageName.ThumbnailCreationFailedMessage]:
    IBV.Collaboard.Protobuf.ThumbnailCreationFailedMessage,
  [NotifyMessageName.ThumbnailPageChangedMessage]:
    IBV.Collaboard.Protobuf.ThumbnailPageChangedMessage,
  [NotifyMessageName.ThumbnailsCreatedMessage]:
    IBV.Collaboard.Protobuf.ThumbnailsMessage, // Shared message
  [NotifyMessageName.ThumbnailsCreatingMessage]:
    IBV.Collaboard.Protobuf.ThumbnailsMessage, // Shared message
  [NotifyMessageName.TileBatchActionMessage]:
    IBV.Collaboard.Protobuf.TileBatchActionMessage,
  [NotifyMessageName.TileGroupMessage]:
    IBV.Collaboard.Protobuf.TileGroupMessage,
  [NotifyMessageName.TileIndexesMessage]:
    IBV.Collaboard.Protobuf.TileIndexesMessage,
  [NotifyMessageName.TilePropertyMessage]:
    IBV.Collaboard.Protobuf.TilePropertyMessage,
  [NotifyMessageName.TileRelationMessage]:
    IBV.Collaboard.Protobuf.TileRelationMessage,
  [NotifyMessageName.TilesCopiedMessage]:
    IBV.Collaboard.Protobuf.TilesCopiedMessage,
  [NotifyMessageName.UploadedMessage]: IBV.Collaboard.Protobuf.UploadedMessage,
  [NotifyMessageName.VideoPlayerStartStopCalledMessage]:
    IBV.Collaboard.Protobuf.VideoPlayerStartStopCalledMessage,
  [NotifyMessageName.VotingCastMessage]:
    IBV.Collaboard.Protobuf.VotingCastMessage,
  [NotifyMessageName.VotingRevokedMessage]:
    IBV.Collaboard.Protobuf.VotingRevokedMessage,
  [NotifyMessageName.VotingSessionFinalizedMessage]:
    IBV.Collaboard.Protobuf.VotingSessionFinalizedMessage,
  [NotifyMessageName.VotingStartedMessage]:
    IBV.Collaboard.Protobuf.VotingStartedMessage,
  [NotifyMessageName.VotingStoppedMessage]:
    IBV.Collaboard.Protobuf.VotingStoppedMessage,
  [NotifyMessageName.VotingTimeAdjustedMessage]:
    IBV.Collaboard.Protobuf.VotingTimeAdjustedMessage,
} as const;
