import React, { useState, useRef, useEffect, ReactElement } from "react";
import { Flex, Box, ThemeUICSSObject } from "theme-ui";
import { useTranslation } from "react-i18next";
import {
  BasicDropdownItem,
  fieldStyles,
} from "../../../shared/forms/dropdowns/BasicDropdown/BasicDropdown";
import ChevronDown from "../../../../icons/ChevronDown";
import BasicDropdownPortal from "../../../shared/forms/dropdowns/BasicDropdown/BasicDropdownPortal";

export type DropdownItem<T extends string | number> = BasicDropdownItem<T> & {
  labelKey: string;
};

type DropDownProps<T extends string | number> = {
  disabled?: boolean;
  sx?: ThemeUICSSObject;
  labelSx?: ThemeUICSSObject;
  optionsSx?: ThemeUICSSObject;
  items: DropdownItem<T>[];
  onChange: (item: DropdownItem<T>) => void;
  alreadySelected?: DropdownItem<T>;
  onBeforeChange?: (item: DropdownItem<T>) => Promise<boolean>;
};

function Dropdown<T extends string | number>({
  disabled = false,
  sx,
  labelSx,
  optionsSx,
  items,
  onChange,
  alreadySelected,
  onBeforeChange,
}: DropDownProps<T>): ReactElement {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState<DropdownItem<T> | undefined>(
    alreadySelected
  );
  const container = useRef(null);

  const toggleOptions = () => !disabled && setIsOpen((state) => !state);

  const onItemClick = (item: DropdownItem<T>) => {
    if (onBeforeChange) {
      onBeforeChange(item).then((isAccepted) => {
        if (isAccepted) {
          setSelected(item);
          onChange && onChange(item);
        }
        setIsOpen(false);
      });
    } else {
      setSelected(item);
      onChange && onChange(item);
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setSelected(alreadySelected);
  }, [alreadySelected, items]);

  const isActive = (item: DropdownItem<T>) => selected === item;
  const selectedValue: DropdownItem<T> | undefined = items.find(isActive);

  return (
    <Box ref={container} sx={{ ...sx, position: "relative" }}>
      <Flex
        sx={{
          ...labelSx,
          cursor: disabled ? "auto" : "pointer",
          height: "100%",
          textTransform: "capitalize",
          "> svg": {
            fill: disabled
              ? "var(--buttonDisabledBackgroundText)"
              : "var(--textColor)",
          },
          ...(disabled ? { color: "var(--buttonDisabledBackgroundText)" } : {}),
        }}
        onClick={toggleOptions}
        role="listbox"
      >
        {selectedValue && t(selectedValue.labelKey)}
        <ChevronDown noShadow />
      </Flex>

      <BasicDropdownPortal
        sx={{
          ...fieldStyles.getOptions(isOpen),
          ...optionsSx,
        }}
        isOpen={isOpen}
        container={container.current}
      >
        {items.map((item) => (
          <Box
            role="button"
            key={item.labelKey}
            onClick={() => !disabled && onItemClick(item)}
            sx={{
              ...fieldStyles.getOption(isActive(item)),
              textTransform: "capitalize",
            }}
          >
            {item && t(item.labelKey)}
          </Box>
        ))}
      </BasicDropdownPortal>
    </Box>
  );
}

export default Dropdown;
