import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Input, Text } from "theme-ui";
import Close from "../../../icons/Close";

function PromoCode({ promoCode, onChange, disabled, clear }) {
  const { t } = useTranslation();

  return (
    <Box>
      <Text sx={{ fontSize: [5], mb: [2] }}>
        {t("dialog.payment.promoCode")}
      </Text>

      <Box sx={{ position: "relative" }}>
        <Input
          disabled={disabled}
          readOnly={disabled}
          value={promoCode}
          onChange={onChange}
          placeholder={t("dialog.payment.promoCodePlaceholder")}
          maxLength={50}
          sx={inputStyle}
        />

        {disabled && (
          <IconButton
            variant="buttons.icon.clearInput"
            type="button"
            onClick={clear}
            sx={buttonStyle}
          >
            <Close noShadow />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}

const inputStyle = {
  flex: "unset",
  height: "40px",
  fontSize: "16px !important",
  "&[disabled]": {
    bg: "disabled",
  },
};

const buttonStyle = {
  svg: {
    fill: "var(--greyTextColor)",
    "&:hover": {
      fill: "var(--textColor)",
    },
  },
};

PromoCode.propTypes = {
  onChange: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  promoCode: PropTypes.string,
  disabled: PropTypes.bool,
};
PromoCode.defaultProps = {
  promoCode: "",
  disabled: false,
};

export default PromoCode;
