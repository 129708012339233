import { BrushType } from "../../../../const";
import { RawPoint } from "../utils/pathSmoothing";

import { BaseInkBrush } from "./BaseInkBrush";

export class HighlighterBrush extends BaseInkBrush {
  /**
   * Disable path fitting algorithm
   */
  protected APPLY_PATH_FITTING = false;

  /**
   * This brush looks better when object caching is disabled.
   */
  public ENABLE_OBJECT_CACHING = false;

  private _brushSize = 1;

  constructor(
    canvas: fabric.CollaboardCanvas,
    options?: fabric.CollaboardBrushOptions
  ) {
    super(BrushType.highlighter, canvas, options);

    this.setStrokeWidth(options?.strokeWidth ?? this.strokeWidth);
  }

  public setStrokeWidth(strokeWidth: number): void {
    super.setStrokeWidth(strokeWidth);
    this._brushSize = Math.ceil(this.strokeWidth * 0.5);
  }

  protected _partialRender(
    ctx: CanvasRenderingContext2D | null = this.canvas.contextTop,
    renderFrom = 0,
    _points?: RawPoint[]
  ): void {
    if (!ctx) {
      return;
    }

    const points: RawPoint[] = _points || this._points;
    const pointsToRender = points.slice(renderFrom);

    ctx.globalAlpha = 0.25;
    ctx.fillStyle = this.liveColor.toLive();

    pointsToRender.forEach((point, i, arr) => {
      if (!point) {
        return;
      }

      const previousPoint = arr[i - 1] || points[renderFrom + i - 1];

      if (previousPoint) {
        this._renderSegment(ctx, point, previousPoint);
        return;
      }

      if (points.length === 1) {
        // This is the start of a path - render a small segment to allow for
        // a click without movement
        this._renderSegment(
          ctx,
          point,
          point.add(new fabric.Point(this._brushSize / 2, 0))
        );

        this._clearCanvasOnNextRender = true;
      }
    });
  }

  private _renderSegment(
    ctx: CanvasRenderingContext2D,
    point: fabric.Point,
    previousPoint: fabric.Point
  ): void {
    ctx.beginPath();
    ctx.moveTo(
      previousPoint.x - this._brushSize,
      previousPoint.y - this._brushSize
    );
    ctx.lineTo(point.x - this._brushSize, point.y - this._brushSize);
    ctx.lineTo(point.x + this._brushSize, point.y + this._brushSize);
    ctx.lineTo(
      previousPoint.x + this._brushSize,
      previousPoint.y + this._brushSize
    );
    ctx.fill();
    ctx.closePath();
  }
}
