import { historyLength } from "../../../const";
import { HistoryAction, HistoryActionType } from "./history.actions";
import { HistoryEntry } from "./history.entry.actions";

export type HistoryState = {
  redo: HistoryEntry[];
  undo: HistoryEntry[];
};

export const getInitialHistoryState = (): HistoryState => {
  return {
    redo: [],
    undo: [],
  };
};

export function historyReducer(
  state = getInitialHistoryState(),
  action: HistoryAction
): HistoryState {
  switch (action.type) {
    case HistoryActionType.HISTORY_ADD_ENTRY: {
      const { undo } = state;

      const newPast = [...undo, action.payload];
      newPast.length > historyLength && newPast.shift();

      return {
        undo: newPast,
        redo: [],
      };
    }
    case HistoryActionType.HISTORY_CLEAR_REDO_STACK: {
      return {
        ...state,
        redo: [],
      };
    }
    case HistoryActionType.HISTORY_CLEAR_UNDO_STACK: {
      return {
        ...state,
        undo: [],
      };
    }
    case HistoryActionType.HISTORY_PRUNE_REDO_STACK: {
      const { pruneToIndex } = action.payload;

      return {
        ...state,
        redo: state.redo.slice(pruneToIndex),
      };
    }
    case HistoryActionType.HISTORY_PRUNE_UNDO_STACK: {
      const { pruneToIndex } = action.payload;

      return {
        ...state,
        undo: state.undo.slice(0, pruneToIndex + 1),
      };
    }
    case HistoryActionType.UPDATE_HISTORY: {
      switch (action.payload) {
        case HistoryActionType.REDO: {
          const { undo, redo } = state;

          const newPast = [...undo];
          const newFuture = [...redo];

          const historyEntry = newFuture.shift();
          historyEntry && newPast.push(historyEntry);

          return {
            undo: newPast,
            redo: newFuture,
          };
        }
        case HistoryActionType.UNDO: {
          const { undo, redo } = state;

          const newPast = [...undo];
          const newFuture = [...redo];

          const historyEntry = newPast.pop();

          historyEntry && newFuture.unshift(historyEntry);

          return {
            undo: newPast,
            redo: newFuture,
          };
        }
        default:
          return state;
      }
    }
    default:
      return state;
  }
}

export const selectHistory = (state: ApplicationGlobalState): HistoryState => {
  return state.canvas.history;
};
