import { Formik } from "formik";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Flex, IconButton, Input, ThemeUIStyleObject } from "theme-ui";
import Close from "../../../../icons/Close";
import useProjectUrlParams from "../useProjectUrlParams";
import RadioButton from "../../../shared/forms/RadioButton";
import { searchModes, searchModeTabs } from "./searchModes";

const { date, owned } = searchModes;

function SearchProjectList(): ReactElement {
  const { t } = useTranslation();
  const { params, setSearchQuery } = useProjectUrlParams();

  return (
    <Formik
      initialValues={params}
      onSubmit={({ query, searchMode }) => setSearchQuery(query, searchMode)}
    >
      {({
        values,
        initialValues,
        handleChange,
        handleSubmit,
        submitForm,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} style={{ marginBottom: 0 }}>
          <Flex sx={containerStyle}>
            <Flex sx={searchModesWrapperStyle}>
              {searchModeTabs.map(({ value, translationKey, flex }) => (
                <RadioButton
                  key={value}
                  name="searchMode"
                  value={value}
                  checked={values.searchMode === value}
                  defaultChecked={initialValues.searchMode === value}
                  label={t(translationKey)}
                  flex={flex}
                  onChange={(e) => {
                    handleChange(e);
                    setTimeout(submitForm);
                  }}
                />
              ))}
            </Flex>

            <Flex sx={{ flex: 2, position: "relative" }}>
              {values.searchMode && [date, owned].includes(values.searchMode) && (
                <>
                  <Input
                    type="text"
                    name="query"
                    placeholder={t("form.placeholder.search")}
                    value={values.query ?? ""}
                    onChange={handleChange}
                    onKeyUp={(e) => {
                      if (e.key.toLowerCase() === "enter") {
                        setTimeout(submitForm);
                        (e.target as HTMLInputElement).blur();
                      }
                    }}
                  />

                  <IconButton
                    variant="buttons.icon.clearInput"
                    type="button"
                    onClick={() => {
                      setFieldValue("query", "");
                      setTimeout(submitForm);
                    }}
                  >
                    <Close noShadow />
                  </IconButton>
                </>
              )}
            </Flex>
          </Flex>
        </form>
      )}
    </Formik>
  );
}

const containerStyle: ThemeUIStyleObject = {
  flex: 1,
  flexDirection: ["column", null, "row"],
};

const searchModesWrapperStyle: ThemeUIStyleObject = {
  flex: 1,
  mr: [3],
  minHeight: "55px", // equalize all children height (to match the tallest one)
};

export default SearchProjectList;
