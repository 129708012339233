import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, ThemeUIStyleObject } from "theme-ui";
import { toFullHex } from "../../../../tools/colors";
import { clamp } from "../../../../tools/utils";

type ValueType = {
  number: number;
  color: string;
  text: string;
};

type InputType = keyof ValueType;

type Props<P extends InputType> = {
  type: P;
  defaultValue: ValueType[P];
  value?: ValueType[P];
  min?: number;
  max?: number;
  customStyle?: ThemeUIStyleObject;
  autoFocus?: boolean;
  disabled?: boolean;
  onChange(value: ValueType[P]): void;
  onBlur?(): void;
};

const inputTypes: { [key in InputType]: "number" | "text" } = {
  number: "number",
  color: "text",
  text: "text",
};

const allowedKeys = ["Enter", "Delete", "Backspace", "ArrowLeft", "ArrowRight"];

const isAllowedNumber = (key: string, value: string): boolean => {
  // prevent entering "0" when the input field is empty OR
  // when the entire input value is selected
  const number = parseInt(key, 10);
  const preventZero =
    number === 0 &&
    (value.length === 0 || getSelection()?.toString() === value);

  return !(Number.isNaN(number) || preventZero);
};

const isKeyAllowed: {
  [key in InputType]: (key: string, value: string) => boolean;
} = {
  number: isAllowedNumber,
  text: () => true,
  color: (key) => /(^[0-9,A-F]*?$)/i.test(key),
};

function ContextInput<P extends InputType>({
  type,
  defaultValue,
  value,
  min,
  max,
  customStyle,
  autoFocus,
  disabled,
  onChange,
  onBlur,
}: Props<P>): ReactElement {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(
    (value ?? defaultValue).toString()
  );
  const inputType = inputTypes[type];
  const isColor = type === "color";

  // handle Ctrl+V
  const filterCharacters = useCallback(
    (text: string) => {
      return isColor ? text.replace(/[^0-9,A-F]/gi, "").substring(0, 6) : text;
    },
    [isColor]
  );

  const handleOnBlur = useCallback(() => {
    if (isColor) {
      const newColor = toFullHex(inputValue);
      const newValue = newColor.replace("#", "");

      setInputValue(newValue);
      onChange(newColor as ValueType[P]);
    } else {
      const newValue =
        inputType === "number"
          ? clamp(
              +inputValue,
              min ?? Number.MIN_SAFE_INTEGER,
              max ?? Number.MAX_SAFE_INTEGER
            )
          : inputValue;

      setInputValue(newValue.toString());
      onChange(newValue as ValueType[P]);
    }
    onBlur?.();
  }, [inputType, inputValue, isColor, max, min, onBlur, onChange]);

  const handleOnChange = useCallback(
    (e) => setInputValue(filterCharacters(e.target.value)),
    [filterCharacters]
  );

  const handleOnFocus = useCallback((e) => e.target.select(), []);

  const handleOnKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const { key, ctrlKey, metaKey } = e;
      const target = e.target as HTMLInputElement;
      const { value } = target;
      const isInputKeyForbidden = !allowedKeys.includes(key);
      const isTypeKeyForbidden = !isKeyAllowed[type](key, value);
      const isCopyPasteEvent = (ctrlKey || metaKey) && ["c", "v"].includes(key);

      (key === "Enter" || key === "Escape") && target.blur();

      !isCopyPasteEvent &&
        isInputKeyForbidden &&
        isTypeKeyForbidden &&
        e.preventDefault();
    },
    [type]
  );

  useEffect(() => {
    if (isColor) {
      const color = defaultValue as string;
      const cleanColor = color.split("#")[1] || "";
      setInputValue(cleanColor);
    } else {
      setInputValue((value ?? defaultValue).toString());
    }
  }, [isColor, defaultValue, value]);

  return (
    <Input
      autoFocus={autoFocus}
      type={inputType}
      value={inputValue}
      disabled={disabled}
      placeholder={isColor ? t("tool.transparent") : ""}
      onChange={handleOnChange}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      onKeyDown={handleOnKeyDown}
      sx={{
        ...inputStyle,
        px: isColor ? [3] : [0],
        textAlign: isColor ? "left" : "center",
        textTransform: "lowercase",
        ...customStyle,
      }}
    />
  );
}

const inputStyle: ThemeUIStyleObject = {
  color: "var(--iconColor)",
  cursor: "pointer",
  flex: "none",
  py: [1],
  border: 0,
  pointerEvents: "auto",
  width: "calc(var(--gridTile) * 2)",
  MozAppearance: "textfield",
  "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
    WebkitAppearance: "none",
  },
  "&::placeholder": { fontSize: 2 },
};

export default ContextInput;
