import React, { ReactElement, useMemo } from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { Grid, ThemeUIStyleObject } from "theme-ui";
import { usePlanDetails } from "../../../licensing/usePlanDetails";
import Anyone from "../../../../icons/Anyone";
import LinkOrQR from "../../../../icons/LinkOrQR";
import RegisteredUser from "../../../../icons/RegisteredUser";
import CollaboardTooltip from "../../../shared/tooltip/CollaboardTooltip";
import BasicDropdownField from "../../../shared/forms/fields/BasicDropdownField";
import { BasicDropdownItem } from "../../../shared/forms/dropdowns/BasicDropdown/BasicDropdown";
import ModalSegment from "../../../shared/modals/ModalSegment";
import { permissions } from "./consts";
import { ShareLinkOrQRModel } from "./types";
import { useUISettings } from "../../../../reduxStore/uiSettings";
import { dynamicFeatureFlags } from "../../../../tools/flags";
import SubscriptionUpgradeRequiredPrompt from "../../../licensing/SubscriptionUpgradeRequired/SubscriptionUpgradeRequiredPrompt";
import { usePermissions } from "../../../permissions/usePermissions";
import { ApiResourcePermission, ApiResourceType } from "../../../../types/enum";

function ShareLinkPermissions(): ReactElement {
  const { values, setFieldValue } = useFormikContext<ShareLinkOrQRModel>();
  const { t } = useTranslation();
  const {
    isMultiUserPlanMember,
    isMultiUserPlanAdmin,
    isOnNoLimitPlan,
  } = usePlanDetails();
  const { isDynamicFeatureActive } = useUISettings();
  const { requiresPermission } = usePermissions();

  const isGuestUsersFeatureActive = !isDynamicFeatureActive(
    dynamicFeatureFlags.GUEST_USERS_DISABLED
  );

  // If there's any RequiredPermission on project participants, then we can assume guests are not allowed
  const anyParticipatePermission = requiresPermission({
    type: ApiResourceType.ProjectParticipants,
    permission: ApiResourcePermission.Participate,
  });
  // TODO: #6804 Manage feature permissions in a single place
  const guestAllowedInLicense =
    isMultiUserPlanMember || isMultiUserPlanAdmin || isOnNoLimitPlan;
  const areGuestsDisabled = !guestAllowedInLicense || anyParticipatePermission;

  const translatedPermissions: BasicDropdownItem<number>[] = useMemo(
    () =>
      permissions.map(({ labelKey, value }) => ({
        label: t(labelKey),
        value,
      })),
    [t]
  );

  return (
    <ModalSegment
      sx={{ width: "100%", mt: [3] }}
      Icon={LinkOrQR}
      variant="primary"
      title={t("dialog.share.shareLinkHeader")}
      subTitle={t("dialog.share.shareLinkSubHeader")}
    >
      <Grid sx={gridStyle}>
        <ModalSegment
          Icon={RegisteredUser}
          variant="secondary"
          title={t("dialog.share.registeredUsers")}
          subTitle={t("dialog.share.registeredUsersInfo")}
        />

        <BasicDropdownField
          placeholder={t("dialog.share.permissionLabel")}
          name="MemberPermission"
          items={translatedPermissions}
          onChange={(item) => {
            if (
              typeof item.value === "number" &&
              item.value < values.GuestPermission
            ) {
              // Enforce "member" permission to be higher than "guest".
              // If it changes in the future, please cover "member trying to accept guest invitation" case.
              // See `isForRegisteredUsersOnly` in `AcceptProjectInvitation.tsx`
              setTimeout(() => {
                setFieldValue("GuestPermission", item.value, true);
              }, 0);
            }
          }}
        />
        {isGuestUsersFeatureActive && (
          <>
            <ModalSegment
              Icon={Anyone}
              variant="secondary"
              title={t("dialog.share.guestUsers")}
              subTitle={t("dialog.share.guestUsersInfo")}
              disabled={areGuestsDisabled}
            >
              {!guestAllowedInLicense ? (
                <SubscriptionUpgradeRequiredPrompt />
              ) : undefined}
            </ModalSegment>

            <CollaboardTooltip
              header="tooltip.header.guestInvitesUnavailable"
              description="tooltip.description.guestInvitesUnavailable"
              placement="bottom"
              forceHide={guestAllowedInLicense}
            >
              <BasicDropdownField
                disabled={areGuestsDisabled}
                placeholder={t("dialog.share.permissionLabel")}
                name="GuestPermission"
                items={translatedPermissions}
                onChange={(item) => {
                  if (
                    typeof item.value === "number" &&
                    item.value > values.MemberPermission
                  ) {
                    // Enforce "member" permission to be higher than "guest".
                    // If it changes in the future, please cover "member trying to accept guest invitation" case.
                    // See `isForRegisteredUsersOnly` in `AcceptProjectInvitation.tsx`
                    setTimeout(() => {
                      setFieldValue("MemberPermission", item.value, true);
                    }, 0);
                  }
                }}
              />
            </CollaboardTooltip>
          </>
        )}
      </Grid>
    </ModalSegment>
  );
}

const gridStyle: ThemeUIStyleObject = {
  gridTemplateColumns: "3fr 1fr",
  alignItems: "center",
  flex: 1,
  "> :nth-of-type(2n)": {
    justifySelf: "end",
  },
};

export default ShareLinkPermissions;
