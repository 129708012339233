import SendToken from "../../features/authentication/passwordReset/SendTokenTWR";
import LoginPage from "./Login";
import Logo from "./Logo";
import Tagline from "./Tagline";
import BannerLogo from "./BannerLogo";

const components: Partial<CustomerComponents> = {
  Logo,
  BannerLogo,
  Tagline,
  LoginPage,
  SendToken,
};

export default components;
