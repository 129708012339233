import React, { ReactElement } from "react";
import { Flex, ThemeUIStyleObject } from "theme-ui";
import Eye from "../../../../../icons/Eye";
import CollaboardTooltip from "../../../../shared/tooltip/CollaboardTooltip";
import ReadOnlyProjectTooltip from "../../projectsNav/ReadOnlyProjectTooltip";

type Props = PropsWithRequiredChildren<{
  projectWithMeta: ApiProject;
}>;

function ProjectDescriptionField({
  projectWithMeta,
  children,
}: Props): ReactElement {
  const { IsLicensed } = projectWithMeta;

  return (
    <Flex sx={containerStyle}>
      {children}

      {!IsLicensed && (
        <Flex sx={{ position: "absolute", right: 0, svg: { fill: "muted" } }}>
          <CollaboardTooltip
            descriptionComponent={<ReadOnlyProjectTooltip />}
            placement="topRight"
          >
            <Eye noShadow scale={1.5} />
          </CollaboardTooltip>
        </Flex>
      )}
    </Flex>
  );
}

const containerStyle: ThemeUIStyleObject = {
  position: "relative",
  mx: [0],
  my: [2],
  a: { textDecoration: "none", alignSelf: "center" },
  alignItems: "center",
  width: "100%",
  justifyContent: "space-between",
  flex: 1,
};

export default ProjectDescriptionField;
