import React, { ReactElement } from "react";
import { Flex, Image, Text, ThemeUIStyleObject } from "theme-ui";
import { useTranslation } from "react-i18next";
import { runtimeConfig } from "../../../tools/runtimeConfig";
import { HostingCountry } from "../../../types/enum";

const { hosting } = runtimeConfig;

export default function HostingHeader(): ReactElement | null {
  const { t } = useTranslation();
  const isValidHosting =
    hosting && Object.values(HostingCountry).includes(hosting);

  const hostingLabel = isValidHosting
    ? t("hosting.hostedIn", { country: t(`hosting.${hosting}`) })
    : "";

  return isValidHosting ? (
    <Flex sx={containerStyle}>
      <Flex sx={flagStyle}>
        <Image
          src={`${process.env.PUBLIC_URL}/images/hostmaps/${hosting}-flag.jpg`}
          alt={hostingLabel}
        />
      </Flex>
      <Text sx={{ fontSize: [2] }}>{hostingLabel}</Text>
    </Flex>
  ) : null;
}

const containerStyle: ThemeUIStyleObject = {
  alignItems: "center",
  gap: [3],
  ml: [6],
};

const flagStyle: ThemeUIStyleObject = {
  alignItems: "center",
  border: "1px solid",
  borderColor: "primary",
  borderRadius: "50%",
  height: "2.5rem",
  justifyContent: "center",
  p: [2],
  width: "2.5rem",
};
