import React, { ReactElement } from "react";
import CurrentColor from "./CurrentColor";
import HueColorPicker from "../../canvas/context/contextToolbox/HueColorPicker";
import {
  colorThemes,
  objectColorPalette,
  objectColorPaletteWithTransparency,
} from "../../../const";

type Props = {
  color: string;
  theme: colorThemes;
  isStaticPalette: boolean;
  allowTransparentColor?: boolean;
  onColorChange: (color: string) => void;
};

function ColorPicker({
  color,
  theme,
  isStaticPalette,
  allowTransparentColor,
  onColorChange,
}: Props): ReactElement {
  return (
    <HueColorPicker
      horizontalLayout
      hideInput
      colors={
        allowTransparentColor
          ? objectColorPaletteWithTransparency
          : objectColorPalette
      }
      theme={theme}
      isStaticPalette={isStaticPalette}
      defaultValue={color}
      onAfterChange={onColorChange}
      reverseComponentsOrder={false}
    >
      <CurrentColor color={color} />
    </HueColorPicker>
  );
}

export default ColorPicker;
