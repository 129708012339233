import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
  Flex,
  Grid,
  Input,
  Text,
  IconButton,
  ThemeUIStyleObject,
  Box,
} from "theme-ui";
import Admin from "../../../icons/Admin";
import Delete from "../../../icons/Delete";

import { createUserInitials } from "../../../tools/avatarGenerator";
import { ApiPermission } from "../../../types/enum";
import Avatar from "../../users/Avatar";
import BasicDropdown, {
  BasicDropdownItem,
} from "../../shared/forms/dropdowns/BasicDropdown/BasicDropdown";
import { spacePermissions } from "../consts";
import CollaboardTooltip from "../../shared/tooltip/CollaboardTooltip";
import { isSpaceAdmin } from "../utils";

type Props = {
  adminVisible: boolean;
  removable: boolean;
  user: SpaceUser;
  onPermissionChange: (permission: BasicDropdownItem<ApiPermission>) => void;
  onAdminToggle: (isAdmin: boolean) => void;
  onDelete: (user: SpaceUser) => void;
};

export default function SpaceUser({
  adminVisible,
  removable,
  user,
  onPermissionChange,
  onAdminToggle,
  onDelete,
}: Props): ReactElement {
  const { t } = useTranslation();
  const { User: userInfo, ProjectPermission, SpacePermission } = user;
  const isAdmin = isSpaceAdmin(SpacePermission);
  const isOwner = SpacePermission === ApiPermission.ownerPermission;

  return (
    <Grid
      data-cy="space-user-list-item"
      variant="layout.listRow"
      sx={rowStyle}
      key={userInfo.UserName}
    >
      <Flex sx={{ alignItems: "center" }}>
        <Avatar
          AvatarUrl={userInfo.PhotoUrl}
          placeholder={createUserInitials(userInfo)}
        />
        <Text data-cy="space-user-full-name" sx={{ pl: [4] }}>
          {userInfo.FirstName} {userInfo.LastName}
        </Text>
      </Flex>
      <Text data-cy="space-user-email">
        {userInfo.UserName}{" "}
        {isOwner && <small>({t("accessPermissions.owner")})</small>}
      </Text>
      {adminVisible ? (
        <>
          <BasicDropdown
            testId="space-user-role-permission"
            value={ProjectPermission}
            ariaLabel="Permission"
            placeholder={t("dialog.share.permissionLabel")}
            items={spacePermissions.map(({ labelKey, value }) => ({
              label: t(labelKey),
              value,
            }))}
            onChange={(permission) =>
              permission.value !== ProjectPermission &&
              onPermissionChange(permission)
            }
          />
          <CollaboardTooltip
            header="spaces.grantAdmin"
            description={removable ? undefined : t("spaces.cannotRemoveUser")}
            placement="bottom"
          >
            <Flex as="label" sx={{ alignItems: "center", cursor: "pointer" }}>
              <Input
                data-cy="space-user-permission"
                disabled={!removable}
                variant="toggle"
                type="checkbox"
                checked={isAdmin}
                onChange={() => onAdminToggle(!isAdmin)}
                sx={disabledInputStyle}
              />
              <Box sx={!removable ? disabledStyle : undefined}>
                <Admin noShadow scale={1.25} disabled={!removable} />
              </Box>
            </Flex>
          </CollaboardTooltip>
          <CollaboardTooltip
            header="spaces.deleteSpaceUser"
            description={removable ? undefined : t("spaces.cannotRemoveUser")}
            placement="bottom"
          >
            <Flex sx={{ alignItems: "center" }}>
              <IconButton
                data-cy="space-delete-user"
                disabled={!removable}
                onClick={() => onDelete(user)}
                sx={{
                  cursor: "pointer",
                  ...disabledInputStyle,
                }}
              >
                <Delete noShadow disabled={!removable} />
              </IconButton>
            </Flex>
          </CollaboardTooltip>
        </>
      ) : null}
    </Grid>
  );
}

const disabledStyle: ThemeUIStyleObject = {
  cursor: "not-allowed",
  opacity: 0.3,
};

const disabledInputStyle: ThemeUIStyleObject = {
  "&[disabled], &:disabled": disabledStyle,
};

const rowStyle: ThemeUIStyleObject = {
  alignItems: "center",
  bg: "delicate",
  gap: "2rem",
  gridTemplateColumns: [
    "1fr 1fr auto auto auto",
    null,
    "minmax(8rem, 20rem) 1fr auto auto auto",
  ],
  height: "auto", // Override listRow
  my: [3],
  px: [3],
  width: "100%",
};
