import React, { PropsWithChildren, ReactElement } from "react";
import { useSelector } from "react-redux";
import { Box, Button, ButtonProps, Flex, Grid } from "theme-ui";
import { colorThemes } from "../../../../const";
import { withDrag } from "../../../../hoc/withDrag";
import { selectCanvasTheme } from "../../../../reduxStore/canvas/settings/settings.reducer";
import { isTransparent } from "../../../../tools/colors";
import { useLiveColor } from "../../../shared/color/useLiveColor";

type SlopeProps = {
  active?: boolean;
};

type WrapperProps = {
  columnsCount: number;
};

type ColorButtonProps = {
  background: string;
  active?: boolean;
  colorCount: number;
  theme: colorThemes;
} & ButtonProps;

type PickerProps = {
  active?: string;
  colors: ColorPalette;
  theme: colorThemes;
  onSelect(color: string): void;
  columnsCount: number;
  horizontalLayout?: boolean;
  isStaticPalette: boolean;
};

export type ToObjectConfig = { color: string };

function ColorWrapper({
  columnsCount,
  children,
}: PropsWithChildren<WrapperProps>) {
  return (
    <Grid
      gap={0}
      sx={{
        backgroundColor: "var(--contextBgColor)",
        gridTemplateColumns: `repeat(${columnsCount}, 1fr)`,
      }}
    >
      {children}
    </Grid>
  );
}

function LiveColorButton({
  background,
  ...props
}: PropsWithChildren<ColorButtonProps>) {
  const useBackground = useLiveColor(background);
  return <ColorButton background={useBackground} {...props} />;
}

function ColorButton({
  background,
  theme,
  active,
  children,
  ...props
}: PropsWithChildren<ColorButtonProps>) {
  const isBackgroundBlack = background === "#000000";
  const isBackgroundWhite = background === "#ffffff";
  const isLightMode = theme === colorThemes.light;

  const borderColorActive =
    (isBackgroundBlack && isLightMode) || (isBackgroundWhite && !isLightMode)
      ? "var(--contextActiveColor_alternative)"
      : "var(--contextActiveColor)";

  return (
    <Button
      style={{ backgroundColor: background }}
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "0",
        position: "relative",
        pointerEvents: "auto",
        outline: "none",
        border: "2px solid",
        minHeight: "var(--gridTile)",
        borderColor: active ? borderColorActive : "transparent",
        "&:hover, &:active, &:focus, &:active:hover": {
          borderColor: borderColorActive,
          outline: "none",
        },
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

function Slope({ active }: SlopeProps): ReactElement {
  return (
    <Flex
      sx={{
        ":after": {
          content: '" "',
          background: active ? "var(--contextActiveColor)" : "#cccccc",
          width: "var(--gridTile)",
          height: "1px",
          translateX: "50%",
          transform: "rotate(-45deg)",
        },
      }}
    />
  );
}

type PickerBaseProps = PickerProps & {
  colorButtonProps?: (color: string) => Partial<ColorButtonProps>;
};

export function ColorPickerBase({
  active,
  colors,
  onSelect,
  columnsCount,
  isStaticPalette,
  horizontalLayout = false,
  theme,
  colorButtonProps,
}: PickerBaseProps): ReactElement {
  return (
    <Box
      sx={{
        flex: "none",
        width: horizontalLayout ? "calc(var(--gridTile) * 4)" : "100%",
      }}
    >
      <ColorWrapper columnsCount={columnsCount}>
        {colors.map((color) => {
          const Component = isStaticPalette ? ColorButton : LiveColorButton;
          return (
            <Component
              key={color}
              {...(colorButtonProps ? colorButtonProps(color) : undefined)}
              aria-label={color}
              onClick={() => onSelect(color)}
              background={color}
              active={color === active}
              theme={theme}
              colorCount={colors.length}
            >
              {isTransparent(color) && <Slope active={color === active} />}
            </Component>
          );
        })}
      </ColorWrapper>
    </Box>
  );
}

const ColorPicker = withDrag<PickerProps, ToObjectConfig>(function ColorPicker(
  pickerProps: PickerProps,
  enableDrag
): ReactElement {
  const theme = useSelector(selectCanvasTheme);

  return (
    <ColorPickerBase
      {...pickerProps}
      theme={theme}
      colorButtonProps={(color) => enableDrag({ color })}
    />
  );
});

export default ColorPicker;
