import { Form, Formik, FormikConfig } from "formik";
import React, { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { boolean, mixed, object, string } from "yup";
import { createProjectInvitationLink } from "../../../../api";
import { useUISettings } from "../../../../reduxStore/uiSettings";
import { dynamicFeatureConfig } from "../../../../tools/flags";
import { ApiPermission } from "../../../../types/enum";
import { useLoggedInStatus } from "../../../authentication/useLoggedInStatus";
import { permissions, shareTypes } from "./consts";
import { formatShareLinkValidity } from "./helpers";
import ShareLink from "./ShareLink";
import ShareLinkButtons from "./ShareLinkButtons";
import ShareLinkOptions from "./ShareLinkOptions";
import ShareLinkPermissions from "./ShareLinkPermissions";
import ShareQRCode from "./ShareQRCode";
import { ShareLinkOrQRModel } from "./types";

export interface InvitationUrl {
  url: string;
  type?: string;
}

interface Props {
  projectId: string;
  invitationUrl: InvitationUrl | null;
  setInvitationUrl: (url: InvitationUrl | null) => void;
}

function ShareLinkOrQR({
  projectId,
  invitationUrl,
  setInvitationUrl,
}: Props): ReactElement {
  const resetInvitationUrl = () => {
    setInvitationUrl(null);
  };
  const { t } = useTranslation();
  const { getDynamicFeatureConfig } = useUISettings();
  const { loggedInViaTenant } = useLoggedInStatus();

  const shareLinkValidity = useMemo(() => {
    const options: ApiShareLinkValidity = getDynamicFeatureConfig<ApiShareLinkValidity>(
      dynamicFeatureConfig.SHARE_LINK_VALIDITY
    ) ?? ["NO_EXPIRY", 60, 240, 720, 1440, 2880, 7200, 14400];
    return formatShareLinkValidity(options, t);
  }, [getDynamicFeatureConfig, t]);

  const shareLinkGuestIdDefault = getDynamicFeatureConfig<boolean>(
    dynamicFeatureConfig.SHARE_LINK_GUEST_ID_DEFAULT
  );

  const formikProps: FormikConfig<ShareLinkOrQRModel> = {
    initialValues: {
      MemberPermission: ApiPermission.noPermission,
      GuestPermission: ApiPermission.noPermission,
      ValidForMinutes: shareLinkValidity[0].value,
      PasswordProtectToggle: false,
      Password: "",
      GuestIdentificationRequired: shareLinkGuestIdDefault ?? true,
      ShareType: "",
    },
    validationSchema: object()
      .shape({
        MemberPermission: mixed().oneOf(permissions.map(({ value }) => value)),
        GuestPermission: mixed().oneOf(permissions.map(({ value }) => value)),
        ValidForMinutes: mixed().oneOf(
          shareLinkValidity.map(({ value }) => value)
        ),
        PasswordProtectToggle: boolean(),
        Password: string().when("PasswordProtectToggle", {
          is: (PasswordProtectToggle: boolean) => PasswordProtectToggle,
          then: string().required(t("clientError.required")),
        }),
        GuestIdentificationRequired: boolean(),
        ShareType: string(),
      })
      .test(
        "member-guest-permissions",
        t("dialog.share.guestPermissionsExceedRegisteredUsers"),
        function (values: ShareLinkOrQRModel) {
          if (values.MemberPermission < values.GuestPermission) {
            return this.createError({
              path: "GuestPermission",
            });
          }

          return true;
        }
      ),

    onSubmit: async ({
      MemberPermission,
      GuestPermission,
      ValidForMinutes,
      PasswordProtectToggle,
      Password,
      GuestIdentificationRequired,
      ShareType,
    }) => {
      const { InvitationUrl } = await createProjectInvitationLink({
        ProjectId: projectId,
        ...(PasswordProtectToggle ? { Password } : { Password: undefined }),
        GuestPermission,
        MemberPermission,
        GuestIdentificationRequired,
        ValidForMinutes,
        tenant: loggedInViaTenant,
      });

      setInvitationUrl({ url: InvitationUrl, type: ShareType });
    },
  };

  return (
    <Formik {...formikProps}>
      {({
        isSubmitting,
        isValid,
        values,
        submitForm,
        setFieldValue,
        dirty,
      }) => {
        const nonePermitted = [
          values.MemberPermission,
          values.GuestPermission,
        ].every((permission) => permission === ApiPermission.noPermission);

        return (
          <Form autoComplete="off">
            {!invitationUrl && (
              <>
                <ShareLinkPermissions />

                <ShareLinkOptions
                  values={values}
                  validityOptions={shareLinkValidity}
                />

                <ShareLinkButtons
                  isSubmitting={isSubmitting}
                  disabled={!isValid || !dirty || nonePermitted}
                  setFieldValue={setFieldValue}
                  submitForm={submitForm}
                  values={values}
                />
              </>
            )}

            {invitationUrl?.type === shareTypes.code && (
              <ShareQRCode
                value={invitationUrl.url}
                onBackClick={resetInvitationUrl}
              />
            )}

            {invitationUrl?.type === shareTypes.link && (
              <ShareLink
                value={invitationUrl.url}
                onBackClick={resetInvitationUrl}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default ShareLinkOrQR;
