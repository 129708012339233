import React, { ReactElement } from "react";
import {
  Box,
  Button as UIButton,
  ButtonProps,
  ThemeUIStyleObject,
} from "theme-ui";

type IconButtonProps = {
  active?: boolean;
  inverted?: boolean;
  forceFill?: boolean;
  separator?: boolean;
  sx?: ThemeUIStyleObject;
};

function Button(props: IconButtonProps & ButtonProps): ReactElement {
  const {
    active,
    inverted,
    separator,
    forceFill,
    sx,
    children,
    ...buttonProps
  } = props;

  return (
    <UIButton
      variant="vanilla"
      sx={{
        ...buttonStyle,
        color:
          active || inverted ? "var(--iconColor__active)" : "var(--iconColor)",
        fill:
          active || inverted ? "var(--iconColor__active)" : "var(--iconColor)",
        background: active
          ? "linear-gradient(45deg, var(--toolBackgroundColor1), var(--toolBackgroundColor2))"
          : "transparent",
        ...sx,
      }}
      {...buttonProps}
    >
      {children}
      <Box
        sx={{
          ...buttonAfterStyle,
          display: separator ? "block" : "none",
        }}
      />
    </UIButton>
  );
}

export const disabledButtonStyle: ThemeUIStyleObject = {
  pointerEvents: "none",
  cursor: "default",
  opacity: 0.3,
  background: "transparent !important",
};

const buttonStyle: ThemeUIStyleObject = {
  alignItems: "center",
  border: "none",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  margin: 0,
  outline: "none",
  overflow: "initial",
  padding: 0,
  pointerEvents: "auto",
  position: "relative",
  "&:hover": {
    background: "var(--buttonIconFocus)",
    outline: "none",
  },
  "&:active": {
    background: "var(--accent)",
    boxShadow: "var(--controlShadow)",
  },
  "&:active:hover": {
    background: "var(--accent)",
  },
  "&:focus": {
    outline: "none",
  },
  "&:disabled": disabledButtonStyle,
  "&:disabled:active": {
    boxShadow: "none",
  },
};

const buttonAfterStyle: ThemeUIStyleObject = {
  width: "1px",
  position: "absolute",
  top: 0,
  bottom: 0,
  right: 0,
  backgroundColor: "var(--iconColor)",
  opacity: 0.3,
};

export default Button;
