import theme from "../axis";
import defaultConfiguration from "../default/configuration";

// NOTE: other than logo, it's the same as default theme

const configuration: CustomerConfiguration = {
  ...defaultConfiguration,
  faviconName: "favicon-axis.png",
  theme,
};

export default configuration;
