/**
 * These flags cannot be changed at runtime. They must be set in the env.js
 */
export enum staticFeatureFlags {
  DISABLE_CORS_ANYWHERE = "disableCorsAnywhere",
  DISABLE_REGISTRATION_RECEIVE_NEWS = "disableRegistrationReceiveNews",
  DISABLE_REGISTRATION_TOS = "disableRegistrationToS",
  EMAIL_LOGIN_DISABLED = "emailLoginDisabled",
  EMBED_FONTS = "embedFonts",
  IN_MEMORY_AUTH_TOKEN = "inMemoryAuthToken",
  LOGIN_PAGE_HELP_BUTTON = "loginPageHelpButton",
  MFT_UPLOAD_AS_BYTE_ARRAY = "mftUploadAsByteArray",
  REGISTRATION_DISABLED = "registrationDisabled",
  RTL_LANGUAGES = "rtlLanguages",
  RUN_FOR_CAPTURE = "runForCapture",
  SUPPRESS_ERRORS_IN_MS_TEAMS = "suppressErrorsInMSTeams",
}

/**
 * These flags can be changed at runtime.
 */
export enum dynamicFeatureFlags {
  AREA_SELECTION = "areaSelection",
  BATCH_USER_IMPORT = "batchUserImport",
  CHAT = "chat",
  CREATE_PROJECT_TOS = "createProjectToS",
  DEVICE_LICENSES = "deviceLicenses",
  DISABLE_USER_PREFERENCES = "disableUserUseCases",
  EMBED = "embed",
  ENABLE_ALL_ACTIVE_PLAN_FEATURES = "enableAllActivePlanFeatures",
  GUEST_USERS_DISABLED = "guestUsersDisabled",
  LICENSING = "licensing",
  LINKS_V2 = "links",
  NO_ECOMMERCE = "noEcommerce",
  PLANS_PAGE = "plansPage",
  PRESENTATION_MODE = "presentationMode",
  SPACES = "spaces",
  TEAM_PAGE = "teamPage",
  USER_SEARCH = "userSearch",
  USERPRESENCE = "userPresence",
  WEBEX = "webex",
  WELCOME_VIDEO = "welcomeVideo",
  ZOOM_MEETING = "zoomMeeting",
}

/**
 * The flags represent settings that can be used by features
 */

export enum dynamicFeatureConfig {
  AUTO_LOGIN_FROM_INVITE = "autoLoginFromInvite",
  CREATE_PROJECT_TOS_LINKS = "createProjectToSLinks",
  DIGITAL_WHITEBOARD_HOMEPAGE_URL = "digitalWhiteboardHomepageUrl",
  /**
   * @NOTE - This flag should only be used when email registration is disabled.
   * It is intended for use with a tenant with a custom login provider.
   */
  DISABLE_REGISTRATION_NAME = "disableRegistrationName",
  ENTERPRISE_LICENSE_REQUEST_URL = "enterpriseLicenseRequestUrl",
  SHARE_LINK_GUEST_ID_DEFAULT = "shareLinkGuestIdDefault",
  SHARE_LINK_VALIDITY = "shareLinkValidity",
  SHARING_MIN_PERMISSION = "sharingMinPermission",
}

/**
 * The flags must only be used to hide in-development features.
 *
 * These flags must be kept separate from the UISettings flags to prevent
 * circular dependencies.
 */
export enum developmentFlags {
  DISABLE_IMAGE_QUEUE_THROTTLING = "disableImageQueueThrottling",
  DISABLE_TIMEOUTS = "disableTimeouts",
  TEST_UTILS = "testUtils",
}
