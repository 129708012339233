import React, { ReactElement } from "react";
import { Box, Flex, Text, ThemeUIStyleObject } from "theme-ui";
import { useTranslation } from "react-i18next";

export type Direction = "row" | "column";

type Props = {
  directions: Direction[];
  sx?: ThemeUIStyleObject;
};

function OrSeparator({ sx, directions }: Props): ReactElement {
  const { t } = useTranslation();

  const lineHeight = directions.map(
    (direction) => ({ row: "1px", column: "100%" }[direction])
  );
  const lineWidth = directions.map(
    (direction) => ({ row: "100%", column: "1px" }[direction])
  );

  const Line = (
    <Box
      sx={{
        flex: 1,
        height: lineHeight,
        width: lineWidth,
        backgroundColor: "muted",
      }}
    />
  );

  return (
    <Flex
      sx={{
        alignItems: "center",
        flexDirection: directions,
        width: "100%",
        ...sx,
      }}
    >
      {Line}
      <Text sx={{ m: [2] }}>{t("form.label.or")}</Text>
      {Line}
    </Flex>
  );
}

export default OrSeparator;
