import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Share(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M25.25,22.28a3.15,3.15,0,0,0-2.45,1.19l-5-2.7a3.07,3.07,0,0,0,.1-.74,3.22,3.22,0,0,0-.08-.71l5-2.72a3.13,3.13,0,1,0-.71-2,3,3,0,0,0,.05.52l-5.09,2.76a3.14,3.14,0,1,0,0,4.26l5.11,2.77a2.63,2.63,0,0,0-.05.49,3.15,3.15,0,1,0,3.14-3.15Z" />
    </Icon>
  );
}
export default Share;
