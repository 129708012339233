import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  resetCanvasModeAction,
  setCanvasModeAction,
} from "../../../reduxStore/canvas/app/app.actions";
import {
  CanvasMode,
  selectIsPickColorMode,
} from "../../../reduxStore/canvas/app/app.reducer";
import { isTransparent } from "../../../tools/colors";
import { errorToast } from "../../../tools/errorToast";
import useCanvasCursor, {
  CursorProps,
  FullCursorProps,
} from "../../canvas/shared/useCanvasCursor";

type UseEyeDropper = {
  isEyeDropperActive: boolean;
  isDisabled: boolean;
  enterEyeDropper(): void;
};

const useEyeDropper = (
  onColorPicked: (color: string) => void, // warning: must be a memo-ized function
  allowTransparentColor?: boolean
): UseEyeDropper => {
  const dispatch = useDispatch();
  const isPickColorMode = useSelector(selectIsPickColorMode);
  const { t } = useTranslation();

  const exitEyeDropper = useCallback(() => {
    dispatch(resetCanvasModeAction());
  }, [dispatch]);

  const isPositionValid = useCallback(
    ({ color }: CursorProps) => allowTransparentColor || !isTransparent(color),
    [allowTransparentColor]
  );

  const toCursor = useCallback(
    ({ escapedColor, isValidPosition }: FullCursorProps) =>
      `url('data:image/svg+xml;utf8,\
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 116 116" width="128" height="128">\
          <rect x="18" y="88" width="20" height="0.5"/>\
          <rect x="27" y="78" width="0.5" height="20"/>\
          <circle cx="68.25" cy="46.75" r="38" fill="${escapedColor}" />\
          <path d="M27.2,107.83a20,20,0,0,0,20-20A20.39,20.39,0,0,0,47,85.35a4.75,4.75,0,0,1,3.84-2.51A40,40,0,1,0,32.09,63.92a4.88,4.88,0,0,1-3.12,4c-.58,0-1.17-.09-1.77-.09a20,20,0,0,0,0,40Zm41-98.54a37.5,37.5,0,1,1-37.5,37.5A37.5,37.5,0,0,1,68.24,9.29Zm-41,61A17.5,17.5,0,1,1,9.7,87.83,17.49,17.49,0,0,1,27.2,70.33Z"/>\
          ${
            isValidPosition
              ? ""
              : '<rect width="40" height="4" transform="rotate(45) translate(62 -16)"/><rect width="4" height="40" transform="rotate(45) translate(80 -35)"/>'
          }\
        </svg>') 30 95, auto`,
    []
  );

  const onPointerUp = useCallback(
    ({ color }: CursorProps) => onColorPicked(color),
    [onColorPicked]
  );

  const onTaintedError = useCallback(() => {
    errorToast(t("clientError.taintedCanvas"));
    dispatch(resetCanvasModeAction());
  }, [dispatch, t]);

  const { isCanvasTainted } = useCanvasCursor({
    isCursorModeActive: isPickColorMode,
    withColorData: true,
    exitCursorMode: exitEyeDropper,
    toCursor,
    isPositionValid,
    onPointerUp,
    onTaintedError,
  });

  const enterEyeDropper = useCallback(() => {
    if (isCanvasTainted) {
      errorToast(t("clientError.taintedCanvas"));
      return;
    }

    dispatch(setCanvasModeAction({ canvasMode: CanvasMode.PICK_COLOR }));
  }, [dispatch, t, isCanvasTainted]);

  return {
    isEyeDropperActive: isPickColorMode,
    isDisabled: isCanvasTainted,
    enterEyeDropper,
  };
};

export default useEyeDropper;
