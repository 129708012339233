import PropTypes from "prop-types";
import React from "react";
import { Input, Text } from "theme-ui";

function LicenseCountInput({ value, onChange, label, placeholder }) {
  return (
    <>
      <Text sx={{ mb: [1], fontSize: [5] }}>{label}</Text>
      <Input
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={5}
        sx={inputStyle}
      />
    </>
  );
}

const inputStyle = {
  height: "40px",
  fontSize: "16px !important",
  mb: [5],
  pl: [4],
};

LicenseCountInput.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};
LicenseCountInput.defaultProps = {
  label: "",
  placeholder: "",
  value: "",
};

export default LicenseCountInput;
