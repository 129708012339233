import React, { ReactElement } from "react";

import LoginTWR from "./LoginTWR";
import PageHeadless from "../../features/routes/layout/pages/PageHeadless";

function Login(): ReactElement {
  return (
    <PageHeadless variant="authenticate">
      <LoginTWR />
    </PageHeadless>
  );
}
export default Login;
