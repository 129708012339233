import React, { ReactElement } from "react";
import { Button } from "theme-ui";

import DuplicateProjectModalTemplate from "./DuplicateProjectModalTemplate";
import CollaboardTooltip from "../../../../shared/tooltip/CollaboardTooltip";

import Duplicate from "../../../../../icons/Duplicate";
import { useSimpleModal } from "../../../../shared/modals/useSimpleModal";

type Props = {
  projectId: string;
  disabled: boolean;
  disabledLabelKey: string;
  isExpanded?: boolean;
};

function DuplicateProjectButton({
  projectId,
  disabled,
  disabledLabelKey,
  isExpanded = false,
}: Props): ReactElement {
  const duplicateProjectModalProps = useSimpleModal();

  const onClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    duplicateProjectModalProps.open();
  };

  return (
    <>
      <CollaboardTooltip
        header="tooltip.header.duplicateProject"
        description={
          disabled
            ? disabledLabelKey
            : "tooltip.description.duplicateProjectInfo"
        }
        placement="topLeft"
      >
        <Button
          variant={
            isExpanded ? "contentProjectListExpanded" : "contentProjectList"
          }
          id="project-duplicate"
          disabled={disabled}
          onClick={onClick}
        >
          <Duplicate noShadow />
        </Button>
      </CollaboardTooltip>
      <DuplicateProjectModalTemplate
        modalProps={duplicateProjectModalProps}
        projectId={projectId}
      />
    </>
  );
}

export default DuplicateProjectButton;
