import { BrushType } from "../../../../const";

export abstract class BaseBrush {
  /**
   * If the brush drawing algorithm uses curves, e.g. `ctx.quadraticCurveTo`,
   * then this flag should be `true` to ensure that the InkPath uses a different
   * viewport intersection algorithm.
   */
  public BRUSH_USES_CURVES = false;

  /**
   * Enable debug mode.
   * Prints points along with path.
   */
  protected ENABLE_DEBUG_MODE = false;

  strokeWidth = 1;

  constructor(
    public brushType: BrushType,
    protected canvas: fabric.CollaboardCanvas,
    options?:
      | fabric.CollaboardBrushOptions
      | fabric.CollaboardEraserBrushOptions
  ) {
    this.setStrokeWidth(options?.strokeWidth ?? this.strokeWidth);
  }

  /**
   * Change the stroke size of the brush.
   * @param {number} strokeWidth Brush stroke size
   */
  public setStrokeWidth(strokeWidth: number): void {
    this.strokeWidth = strokeWidth;
  }

  public abstract onMouseDown(
    pointer: fabric.Position,
    options: fabric.BrushEventOptions
  ): void;

  public abstract onMouseMove(
    pointer: fabric.Position,
    options: fabric.BrushEventOptions
  ): void;

  public abstract onMouseUp(options: fabric.BrushEventOptions): boolean;

  public abstract _render(ctx: CanvasRenderingContext2D | null): void;

  public abstract _setBrushStyles(ctx: CanvasRenderingContext2D | null): void;

  /**
   * Determine if this event was triggered by the original drawing pointer.
   *
   * This is required so that we can ignore additional touches while drawing,
   * for example if the user starts using a second finger. Or a finger while
   * using a stylus.
   *
   * @param {PointerEvent} event Pointer event
   * @returns {boolean}
   */
  public _isDrawingPointerEvent(event: PointerEvent): boolean {
    if (event.pointerId !== this.canvas._drawingPointerId) {
      return false;
    }

    if (!this.canvas._isMainEvent(event)) {
      return false;
    }

    return true;
  }
}
