import { Form, Formik, FormikConfig } from "formik";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Flex } from "theme-ui";
import { array, boolean, number, object, string } from "yup";
import { inviteProjectParticipant } from "../../../../api";
import Envelope from "../../../../icons/Envelope";
import { getAppName } from "../../../../tools/customer";
import { LogCategory } from "../../../../tools/telemetry";
import { ApiPermission } from "../../../../types/enum";
import { useLoggedInStatus } from "../../../authentication/useLoggedInStatus";
import BasicDropdownField from "../../../shared/forms/fields/BasicDropdownField";
import EmailInvitesField from "../../../shared/forms/fields/EmailInvitesField";
import { MultiInputFieldItem } from "../../../shared/forms/fields/MultiInputField/useMultiInputField";
import TextareaField from "../../../shared/forms/fields/TextareaField";
import ProcessingButton from "../../../shared/forms/ProcessingButton";
import ModalFooter from "../../../shared/modals/ModalFooter";
import ModalSegment from "../../../shared/modals/ModalSegment";
import { useTrackEventToLog } from "../../../shared/tracking/useTrackEventToLog";
import { emailPermissions } from "./consts";
import InvitesSentConfirmation from "./InvitesSentConfirmation";

const isStringWithValue = (value: string | undefined): value is string => {
  return !!value && typeof value === "string";
};

type Props = {
  isExpanded: boolean;
  setIsExpanded(flag: boolean): void;
  projectId: string;
};

type InvitationsForm = {
  Emails: MultiInputFieldItem<ApiSearchUser>[];
  Permission: number;
  Notes: string;
};

function EmailInvitations({
  isExpanded,
  setIsExpanded,
  projectId,
}: Props): ReactElement {
  const { trackEvent } = useTrackEventToLog();
  const { t } = useTranslation();
  const [sentInvites, setSentInvites] = useState<string[] | null>(null);
  const { loggedInViaTenant } = useLoggedInStatus();

  const formikProps: FormikConfig<InvitationsForm> = {
    initialValues: {
      Emails: [],
      Permission: ApiPermission.noPermission,
      Notes: "",
    },
    validationSchema: object().shape({
      Emails: array()
        .of(
          object().shape({
            isValid: boolean().oneOf([true]),
          })
        )
        .min(1)
        .required(t("clientError.required")),
      Permission: number().min(1).required(),
      Notes: string(),
    }),
    onSubmit: async (values, actions) => {
      // no `metadata` means user not found. In which case, use field value instead
      const Emails = values.Emails.map(
        (e) => e.metadata?.Email ?? e.value
      ).filter(isStringWithValue);

      trackEvent(LogCategory.project, {
        subcategory: "project-invite-send",
        ...values,
        Emails,
        ShareType: "email",
      });
      await inviteProjectParticipant({
        ProjectId: projectId,
        Users: Emails,
        MemberPermission: values.Permission,
        Notes: values.Notes,
        tenant: loggedInViaTenant,
      });

      actions.resetForm();
      setSentInvites(Emails);
    },
  };

  if (sentInvites?.length) {
    return (
      <InvitesSentConfirmation
        emails={sentInvites}
        onReset={() => setSentInvites(null)}
      />
    );
  }

  return (
    <ModalSegment
      Icon={Envelope}
      variant="primary"
      title={t("dialog.share.invitePeople")}
      subTitle={t("dialog.share.invitePeopleInfo", { appName: getAppName() })}
    >
      <Formik {...formikProps}>
        {({ isSubmitting, isValid, dirty, resetForm }) => (
          <Form>
            <Flex sx={{ flexWrap: "wrap", width: "100%" }}>
              <EmailInvitesField
                name="Emails"
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
              />

              {isExpanded && (
                <Box sx={{ ml: [null, null, 5] }}>
                  <BasicDropdownField
                    placeholder={t("dialog.share.permissionLabel")}
                    name="Permission"
                    items={emailPermissions.map(({ labelKey, value }) => ({
                      label: t(labelKey),
                      value,
                    }))}
                  />
                </Box>
              )}
            </Flex>

            {isExpanded && (
              <>
                <TextareaField
                  placeholder={t("dialog.share.notePlaceholder")}
                  name="Notes"
                  sx={{ mt: [4] }}
                  textareaSx={{ height: "12vh" }}
                />

                <ModalFooter sx={{ pr: [0], pb: [0] }}>
                  <Button
                    variant="buttons.secondary"
                    onClick={() => {
                      resetForm();

                      // prevents race conditions
                      setTimeout(() => {
                        setIsExpanded(false);
                      }, 0);
                    }}
                  >
                    {t("dialog.share.back")}
                  </Button>

                  <ProcessingButton
                    isProcessing={isSubmitting}
                    disabled={!isValid || !dirty}
                    type="submit"
                  >
                    {t("dialog.share.sendInvites")}
                  </ProcessingButton>
                </ModalFooter>
              </>
            )}
          </Form>
        )}
      </Formik>
    </ModalSegment>
  );
}

export default EmailInvitations;
