import theme from "../default";

const configuration: CustomerConfiguration = {
  appName: "Collaboard",
  faviconName: "favicon-eth.png",
  loginTroubleHref: "mailto:helpdesk@arch.ethz.ch",
  loginProviderCustomNames: { ADFS: "ETH Zürich" },
  theme,
};

export default configuration;
