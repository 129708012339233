import React, { ReactElement, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "theme-ui";
import { useLoggedInStatus } from "../../../authentication/useLoggedInStatus";

function LogOutButton(): ReactElement {
  const { t } = useTranslation();
  const { logout } = useLoggedInStatus();

  const onClick = useCallback(() => {
    logout();
  }, [logout]);

  return <Button onClick={onClick}>{t("form.button.logout")}</Button>;
}

export default LogOutButton;
