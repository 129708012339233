import React, { ReactElement, useEffect, useRef } from "react";
import { Flex } from "theme-ui";
import { useField } from "formik";
import EmailAutocompleteDropdown from "../../../canvas/share/ShareModal/EmailAutocompleteDropdown";
import MultiInputField from "./MultiInputField";
import { MultiInputFieldItem } from "./MultiInputField/useMultiInputField";
import UserDetailsTooltip from "../../../canvas/navigation/users/UserDetailsTooltip";
import useUserSearch from "../../../canvas/share/ShareModal/useUserSearch";
import { dynamicFeatureFlags } from "../../../../tools/flags";
import { useUISettings } from "../../../../reduxStore/uiSettings";
import { useTranslation } from "react-i18next";
import { useOutClick } from "../../dom/useOutClick";

type Props = {
  name: string;
  isExpanded: boolean;
  setIsExpanded: (flag: boolean) => void;
};

export default function EmailInvitesField({
  name,
  isExpanded,
  setIsExpanded,
}: Props): ReactElement {
  const [field, , helpers] = useField(name);
  const { t } = useTranslation();
  const { isDynamicFeatureActive } = useUISettings();
  const isUserSearchFeatureActive = isDynamicFeatureActive(
    dynamicFeatureFlags.USER_SEARCH
  );
  const guestUsersDisabled = isDynamicFeatureActive(
    dynamicFeatureFlags.GUEST_USERS_DISABLED
  );

  const autocompleteContainerRef = useRef<HTMLDivElement>(null);

  const {
    users: usersSearchResults,
    searchUsers,
    cancelSearchUsers: hideSearchResults,
    getUserDetails,
  } = useUserSearch();

  // Hide the panel when isExpanded changes or user clicks elsewhere
  useEffect(() => hideSearchResults(), [isExpanded, hideSearchResults]);
  useOutClick(autocompleteContainerRef, hideSearchResults);

  const emailFieldProps = isUserSearchFeatureActive
    ? {
        Tooltip: UserDetailsTooltip,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          const input = e.target.value;
          input ? searchUsers(input) : hideSearchResults();
        },
        validateEntries: (emails: string[]) => {
          hideSearchResults();
          return getUserDetails(emails);
        },
        onAdded: hideSearchResults,
        onRemoved: hideSearchResults,
      }
    : undefined;

  return (
    <>
      <Flex sx={{ flexGrow: 1 }} ref={autocompleteContainerRef}>
        <MultiInputField
          itemType="email"
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          placeholder={t("dialog.share.emailsPlaceholder")}
          name={name}
          sx={{
            ...(isExpanded ? { minHeight: "12vh" } : {}),
            maxHeight: "20vh",
            minWidth: "20rem",
          }}
          validateEntries={
            guestUsersDisabled || isUserSearchFeatureActive
              ? (emails) => {
                  hideSearchResults();
                  return getUserDetails(emails);
                }
              : undefined
          }
          {...emailFieldProps}
        />
      </Flex>

      {isUserSearchFeatureActive && (
        <EmailAutocompleteDropdown
          container={autocompleteContainerRef}
          items={usersSearchResults}
          selectedItems={field.value}
          onItemClick={(user) => {
            const fieldItem: MultiInputFieldItem<ApiSearchUserInfo> = {
              value: user.UserName,
              metadata: user,
              isValid: true,
            };

            helpers.setValue([...field.value, fieldItem]);
            hideSearchResults();
          }}
        />
      )}
    </>
  );
}
