import React, { ReactElement, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "theme-ui";

import { deleteProject } from "../../../../../api";
import { useConfirmModal, Confirmation } from "../../../../shared/modals";
import ModalHeader from "../../../../shared/modals/ModalHeader";
import useToast from "../../../../shared/toasts/useToast";
import { useLicenseInfo } from "../../../../licensing/useLicenseInfo";
import Delete from "../../../../../icons/Delete";
import CollaboardTooltip from "../../../../shared/tooltip/CollaboardTooltip";
import { ProjectsContext } from "../../Projects";

type Props = {
  projectId: string;
  disabled: boolean;
  disabledLabelKey: string;
  isExpanded?: boolean;
};

function DeleteProjectButton({
  projectId,
  disabled,
  disabledLabelKey,
  isExpanded,
}: Props): ReactElement {
  const { t } = useTranslation();
  const deleteProjectModal = useConfirmModal();
  const { removeProjectFromList } = useContext(ProjectsContext).current;
  const projectDeletedToast = useToast("project.deleteSuccessful");
  const { refetch } = useLicenseInfo(false);

  const showDeleteProjectModal = (event: React.MouseEvent) => {
    projectDeletedToast.dismiss();
    deleteProjectModal.open({
      confirmCallback: async () => {
        await deleteProject(projectId);
        await refetch();

        projectDeletedToast.show();
        removeProjectFromList(projectId);
      },
    });

    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <>
      <Confirmation
        {...deleteProjectModal.props}
        variant="warning"
        header={<ModalHeader title={t("dialog.deleteProject")} Icon={Delete} />}
        confirmationLabel={t("dialog.delete")}
      >
        {t("dialog.deleteProjectPrompt")}
      </Confirmation>
      <CollaboardTooltip
        header="tooltip.header.deleteProject"
        description={
          disabled ? disabledLabelKey : "tooltip.description.deleteProject"
        }
        placement="topLeft"
      >
        <Button
          variant={
            isExpanded ? "contentProjectListExpanded" : "contentProjectList"
          }
          id="project-delete"
          onClick={showDeleteProjectModal}
          disabled={disabled}
        >
          <Delete noShadow />
        </Button>
      </CollaboardTooltip>
    </>
  );
}

export default DeleteProjectButton;
