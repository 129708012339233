import React, { ReactElement } from "react";
import { Flex, Text, Box, Button } from "theme-ui";
import { useTranslation } from "react-i18next";

import Delete from "../../../../../icons/Delete";
import Profile from "../../../../../icons/Profile";
import Display from "../../../../../icons/Display";
import Avatar from "../../../../users/Avatar";

type Props = {
  request: ApiSubscriptionUser;
  onRemove(): void;
};

function PendingRequest({ request, onRemove }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <Flex variant="layout.listRow">
      <Box variant="layout.listRowCell" sx={{ flex: "unset" }}>
        <Avatar
          placeholder={
            request.Username ? <Profile noShadow /> : <Display noShadow />
          }
        />
      </Box>

      <Text variant="layout.listRowCell" sx={{ flex: 1 }}>
        {request.Username}
      </Text>

      <Text variant="layout.listRowCell" sx={{ bg: "delicate", flex: 1 }}>
        {request.DateCreated}
      </Text>

      <Text variant="layout.listRowCell" sx={{ flex: 2 }}>
        {t("team.waitingForAnswer")}
      </Text>

      <Text variant="layout.listRowCell" sx={{ flex: "unset" }}>
        <Button variant="buttons.content" onClick={onRemove}>
          <Delete noShadow />
        </Button>
      </Text>
    </Flex>
  );
}

export default PendingRequest;
