import React, { PropsWithChildren, ReactElement } from "react";
import { Button, ButtonProps } from "theme-ui";

import LoadingSpinner from "../../../icons/LoadingSpinner";

type Props = PropsWithChildren<
  {
    isProcessing: boolean;
  } & ButtonProps
>;

function ProcessingButton({
  isProcessing,
  children,
  sx,
  ...rest
}: Props): ReactElement {
  return (
    <Button
      sx={{ ...sx, ...(isProcessing ? { color: "black !important" } : {}) }}
      {...rest}
    >
      {isProcessing && (
        <LoadingSpinner
          sx={{ stroke: "var(--textColor)" }}
          fill="transparent"
          margin="0 5px 0 0"
        />
      )}
      {children}
    </Button>
  );
}

export default ProcessingButton;
