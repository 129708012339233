import React, { PropsWithChildren, ReactElement } from "react";
import { ThemeProvider } from "theme-ui";
import { useUISettings } from "../../reduxStore/uiSettings";

function AppThemeProvider({
  children,
}: PropsWithChildren<unknown>): ReactElement {
  const { getTheme } = useUISettings();
  return <ThemeProvider theme={getTheme()}>{children}</ThemeProvider>;
}

export default AppThemeProvider;
