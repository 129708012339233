import React, { PropsWithChildren, ReactElement } from "react";
import { Container } from "theme-ui";

type Props = PropsWithChildren<unknown> & {
  variant?: string;
};

function PageHeadless({
  children,
  variant = "formsWrap",
}: Props): ReactElement {
  return (
    <Container as="main" variant={variant}>
      {children}
    </Container>
  );
}

export default PageHeadless;
