import { Form, Formik } from "formik";
import React, { ReactElement, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Box } from "theme-ui";
import BasicDropdownField from "../../shared/forms/fields/BasicDropdownField";
import { BasicDropdownItem } from "../../shared/forms/dropdowns/BasicDropdown/BasicDropdown";
import PageAuthorized from "../layout/pages/PageAuthorized";
import ToggleField from "../../shared/forms/fields/ToggleField";
import ActionButtons from "./ActionButtons";
import { useImportPreviewHook } from "./useImportPreviewHook";
import BatchImportResultModalTemplate from "./BatchImportResultModalTemplate";

const COLUMN_WIDTH = 270;

function ImportPreview(): ReactElement {
  const { t } = useTranslation();

  const options: BasicDropdownItem<ImportDropdownValue>[] = useMemo(
    () => [
      {
        label: t("team.batchImport.unassigned"),
        value: "",
      },
      {
        label: t("team.addUserEmailLabel"),
        value: "UserName",
      },
      {
        label: t("team.addUserFirstNameLabel"),
        value: "FirstName",
      },
      {
        label: t("team.addUserLastNameLabel"),
        value: "LastName",
      },
      {
        label: t("team.addUserCompanyLabel"),
        value: "CompanyName",
      },
      {
        label: t("team.addUserPhoneNumberLabel"),
        value: "PhoneNumber",
      },
      {
        label: t("team.addUserActionLabel"),
        value: "Action",
      },
    ],
    [t]
  );

  const {
    formikProps,
    columnCount,
    styleAssignedColumns,
    onAssignmentChange,
    parseImportIntention,
    tableData,
    columnKeys,
    isValidForImport,
    batchImportResultModalProps,
    importStatus,
  } = useImportPreviewHook();

  return (
    <PageAuthorized>
      <Formik {...formikProps}>
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <Box
              sx={{
                backgroundColor: "delicate",
                px: [4],
                py: [4],
                mx: [1],
                mb: [6],
              }}
            >
              <Trans i18nKey="team.batchImport.previewIntro">
                <strong />
                <p />
              </Trans>
            </Box>

            <ToggleField
              name="isFirstRowHeader"
              labelFirst
              sx={{ mb: [6], ml: [4] }}
            />

            <Box
              sx={{
                width: "100%",
                overflowX: "auto",
                table: {
                  width: `${columnCount * COLUMN_WIDTH}px`,
                  columnWidth: `${COLUMN_WIDTH}px`,
                  tableLayout: "fixed",
                  borderCollapse: "collapse",
                  tbody: {
                    mt: [6],
                  },
                  "tbody tr": {
                    borderTop: "2px solid #ccc",
                    "&:first-of-type": {
                      borderTop: "none",
                    },
                  },
                  "tbody td": {
                    ...styleAssignedColumns(values, "tbody"),
                  },
                  "thead td": {
                    p: [0],
                    backgroundColor: "#cfcfcf",
                    ...styleAssignedColumns(values, "thead"),
                  },
                  "thead th": {
                    div: {
                      textAlign: "left",
                      borderLeft: "2px solid black",
                      pl: [4],
                      pr: [5],
                      py: [2],
                    },
                    "&:first-of-type div": {
                      borderLeft: 0,
                    },
                  },
                  "td, th div": {
                    transition: "all 0.1s ease-in",
                    width: `${COLUMN_WIDTH}px`,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    px: [4],
                    py: [4],
                  },
                  th: {
                    py: [3],
                    px: [0],
                  },
                },
              }}
            >
              <table>
                <thead>
                  {values.isFirstRowHeader && (
                    <tr>
                      {tableData[0].data.map((cell, idx) => (
                        <th key={idx}>
                          <Box>{cell}</Box>
                        </th>
                      ))}
                    </tr>
                  )}

                  <tr>
                    {columnKeys.map((e) => (
                      <td key={e}>
                        <BasicDropdownField
                          key={e}
                          placeholder={t("team.batchImport.assignColumn")}
                          name={`columns.column${e}`}
                          items={options}
                          selectSx={{
                            justifyContent: "space-between",
                            color: "background",
                            svg: { fill: "background" },
                            pl: [4],
                            pr: [0],
                          }}
                          optionsSx={{ width: `${COLUMN_WIDTH}px` }}
                          onChange={(item) => {
                            onAssignmentChange(item, values, setFieldValue);
                          }}
                        />
                      </td>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {tableData
                    .slice(values.isFirstRowHeader ? 1 : 0)
                    .map((row) => (
                      <tr key={row.id}>
                        {row.data.map((cell, index) => (
                          <td title={`${cell}`} key={index}>
                            {cell}
                          </td>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </Box>

            {isValidForImport(values) && (
              <Box
                sx={{
                  backgroundColor: "delicate",
                  width: "100%",
                  p: [4],
                  mt: [4],
                }}
              >
                {t(
                  "team.batchImport.importIntention",
                  parseImportIntention(values)
                )}
              </Box>
            )}

            <ActionButtons
              values={values}
              isSubmitting={isSubmitting}
              isValidForImport={isValidForImport}
            />
          </Form>
        )}
      </Formik>
      <BatchImportResultModalTemplate
        modalProps={batchImportResultModalProps}
        importStatus={importStatus}
      />
    </PageAuthorized>
  );
}

export default ImportPreview;
