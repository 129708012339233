import {
  all,
  call,
  Effect,
  getContext,
  select,
  takeEvery,
} from "redux-saga/effects";
import { updateLastCommentView } from "../../../api";
import { selectProjectMyself } from "../project/project.reducer";
import {
  ChatActionType,
  DecrementUnreadMessagesCountAction,
  IncrementUnreadMessagesCountAction,
  SetUnreadChatMessageCountAction,
  UpdateUnreadChatMessageCountAction,
} from "./chat.actions";
import { selectActiveChat } from "./chat.reducer";

function* toggleChatWindow() {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  canvas.activeChatUuid = selectActiveChat(yield select())?.uuid;
  canvas.requestRenderAll();
}

function* setUnreadChatMessageCount({
  payload,
}: SetUnreadChatMessageCountAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const { chatStatuses } = payload;
  chatStatuses.forEach((chatStatus) => {
    const chat = canvas.getObjectByUUID<fabric.Chat>(chatStatus.TileId);
    chat?.set({
      unreadCommentsCount: chatStatus.UnreadCommentsCount,
      lastReadCommentId: chatStatus.LastViewedCommentId,
    });
  });

  canvas.requestRenderAll();
}

function* updateUnreadChatMessageCount({
  payload,
}: UpdateUnreadChatMessageCountAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const { chatStatus } = payload;

  const {
    chatUuid,
    lastReadCommentId: newLastReadCommentId,
    unreadCommentsCount,
  } = chatStatus;
  const chat = canvas.getObjectByUUID<fabric.Chat>(chatUuid);

  if (chat) {
    const { lastReadCommentId } = chat;
    const isLastReadCommentChanged =
      !lastReadCommentId || lastReadCommentId < newLastReadCommentId;

    if (isLastReadCommentChanged) {
      yield call(async () => {
        await updateLastCommentView(newLastReadCommentId);
      });

      chat?.set({
        lastReadCommentId: newLastReadCommentId,
        unreadCommentsCount,
      });
    }
  }

  canvas.requestRenderAll();
}

function* incrementUnreadChatMessageCount({
  payload,
}: IncrementUnreadMessagesCountAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const { chatComment } = payload;
  const { TileId, CreatedBy } = chatComment;
  const myself = selectProjectMyself(yield select());
  const chat = canvas.getObjectByUUID<fabric.Chat>(TileId);

  if (chat && CreatedBy !== myself?.UserId) {
    const { unreadCommentsCount } = chat;
    chat.set({ unreadCommentsCount: unreadCommentsCount + 1 });
    canvas.requestRenderAll();
  }
}

function* decrementUnreadChatMessageCount({
  payload,
}: DecrementUnreadMessagesCountAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const { chatComment } = payload;
  const { TileId, CommentId } = chatComment;
  const chat = canvas.getObjectByUUID<fabric.Chat>(TileId);
  const lastReadCommentId = chat?.lastReadCommentId;
  const isUnreadCommentsCountChanged =
    !lastReadCommentId || CommentId > lastReadCommentId;

  if (chat && isUnreadCommentsCountChanged) {
    const { unreadCommentsCount } = chat;
    chat.set({ unreadCommentsCount: Math.max(unreadCommentsCount - 1, 0) });
    canvas.requestRenderAll();
  }
}

export function* chatSaga(): Generator<Effect> {
  yield all([
    takeEvery(ChatActionType.CLOSE_CHAT, toggleChatWindow),
    takeEvery(ChatActionType.OPEN_CHAT, toggleChatWindow),
    takeEvery(
      ChatActionType.SET_UNREAD_CHAT_MESSAGE_COUNT,
      setUnreadChatMessageCount
    ),
    takeEvery(
      ChatActionType.UPDATE_UNREAD_CHAT_MESSAGE_COUNT,
      updateUnreadChatMessageCount
    ),
    takeEvery(
      ChatActionType.INCREMENT_UNREAD_CHAT_MESSAGE_COUNT,
      incrementUnreadChatMessageCount
    ),
    takeEvery(
      ChatActionType.DECREMENT_UNREAD_CHAT_MESSAGE_COUNT,
      decrementUnreadChatMessageCount
    ),
  ]);
}
