import React, { ReactElement, useMemo } from "react";
import { Flex, Input, Label, ThemeUIStyleObject } from "theme-ui";
import { v4 as uuid } from "uuid";

type RadioProps = {
  name: string;
  value: string;
  checked: boolean;
  defaultChecked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

type Props = RadioProps & {
  label: string;
  flex?: number;
};

type OffscreenRadioProps = RadioProps & {
  id: string;
};

function OffscreenRadio({
  name,
  value,
  checked,
  defaultChecked,
  onChange,

  id,
}: OffscreenRadioProps): ReactElement {
  return (
    <Input
      sx={offscreenRadioStyle}
      type="radio"
      id={id}
      name={name}
      value={value}
      checked={checked}
      defaultChecked={defaultChecked}
      onChange={onChange}
    />
  );
}

function RadioButton({
  name,
  value,
  checked,
  defaultChecked,
  onChange,

  label,
  flex,
}: Props): ReactElement {
  const inputId = useMemo(uuid, []);

  return (
    <Flex sx={wrapperStyle} style={{ flex }}>
      <OffscreenRadio
        name={name}
        value={value}
        checked={checked}
        defaultChecked={defaultChecked}
        id={inputId}
        onChange={onChange}
      />
      <Label htmlFor={inputId} variant="layout.tabSwitcher">
        <span>{label}</span>
      </Label>
    </Flex>
  );
}

const wrapperStyle: ThemeUIStyleObject = {
  position: "relative",
  overflow: "hidden",
  flexDirection: "column",
};

const offscreenRadioStyle: ThemeUIStyleObject = {
  position: "absolute",
  left: "-9999px",
};

export default RadioButton;
