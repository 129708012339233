export enum ObjectLinksActionType {
  GO_TO_QUICK_LINK = "GO_TO_QUICK_LINK",
  SET_OBJECT_LINKS = "SET_OBJECT_LINKS",
}

export const goToQuickLink = (payload: QuickLink) =>
  ({
    type: ObjectLinksActionType.GO_TO_QUICK_LINK,
    payload,
  } as const);

export const setObjectLinksAction = (payload: ApiObjectLink[]) =>
  ({
    type: ObjectLinksActionType.SET_OBJECT_LINKS,
    payload,
  } as const);

export type GoToQuickLinkAction = ReturnType<typeof goToQuickLink>;
export type SetObjectLinksAction = ReturnType<typeof setObjectLinksAction>;

export type ObjectLinksAction = GoToQuickLinkAction | SetObjectLinksAction;
