import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function CancelSubscription(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M22.38,13.91l-1.15-1.72-.63-.93h0a1.41,1.41,0,0,0-2.81,0h0l-.63.93-1.16,1.72-.33.49,3.55,0,3.56,0Zm-3.22-3.13a.5.5,0,0,1,.5.5.5.5,0,0,1-1,0A.5.5,0,0,1,19.16,10.78Z" />
      <rect x="15.39" y="16.49" width="4.83" height="1.41" rx="0.66" />
      <path d="M23,19.84a.65.65,0,0,0-.64-.53H16.05a.67.67,0,0,0-.66.66v.09a.67.67,0,0,0,.66.66h5A5.62,5.62,0,0,1,23,19.84Z" />
      <path d="M19.66,22.13H16.05a.67.67,0,0,0-.66.66v.09a.67.67,0,0,0,.66.66h2.89A5.86,5.86,0,0,1,19.66,22.13Z" />
      <path d="M18.67,26.1H14.23V13.91h.63L16,12.19H14.3a1.6,1.6,0,0,0-1.6,1.6V26.22a1.59,1.59,0,0,0,1.6,1.6h4.87A5.8,5.8,0,0,1,18.67,26.1Z" />
      <path d="M24.09,13.91V19.7l.25,0a6.15,6.15,0,0,1,1.29.15v-6a1.6,1.6,0,0,0-1.6-1.6H22.31l1.16,1.72Z" />
      <path d="M24.34,20.55a4.85,4.85,0,1,0,4.84,4.85A4.85,4.85,0,0,0,24.34,20.55ZM20.88,25.4a3.47,3.47,0,0,1,5.37-2.89L21.46,27.3A3.46,3.46,0,0,1,20.88,25.4Zm3.46,3.46a3.44,3.44,0,0,1-1.9-.58l4.78-4.79a3.39,3.39,0,0,1,.58,1.91A3.47,3.47,0,0,1,24.34,28.86Z" />
    </Icon>
  );
}
export default CancelSubscription;
