import React, { useState, useRef, ReactElement, useCallback } from "react";
import { Flex, Box, ThemeUIStyleObject } from "theme-ui";
import BasicDropdownPortal from "./BasicDropdownPortal";
import ChevronDown from "../../../../../icons/ChevronDown";
import { useOutClick } from "../../../dom/useOutClick";
import { dropdownOptionZIndex } from "../../../../../const";

export interface BasicDropdownItem<T extends string | number> {
  value: T;
  label?: string;
}

export interface BasicDropdownProps<T extends string | number> {
  items: BasicDropdownItem<T>[];
  value: T;
  ariaLabel: string;
  placeholder?: string;
  disabled?: boolean;
  onChange: (item: BasicDropdownItem<T>) => void;
  sx?: ThemeUIStyleObject;
  selectSx?: ThemeUIStyleObject;
  optionsSx?: ThemeUIStyleObject;
  optionSx?: ThemeUIStyleObject;
  testId?: string;
}

function BasicDropdown<T extends string | number>({
  items,
  value,
  ariaLabel,
  placeholder,
  disabled,
  sx,
  onChange,
  selectSx,
  optionsSx,
  optionSx,
  testId,
}: BasicDropdownProps<T>): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const container = useRef<HTMLDivElement>(null);

  const toggleOptions = () => {
    if (disabled) {
      return;
    }

    setIsOpen((state) => !state);
  };

  const onItemClick = (item: BasicDropdownItem<T>) => {
    onChange(item);
    setIsOpen(false);
  };

  useOutClick(
    container,
    useCallback(() => setIsOpen(false), [])
  );

  const isActive = (item: BasicDropdownItem<T>) => value === item.value;

  const selectedItem = items.find(isActive);
  const selectedLabel = selectedItem
    ? selectedItem.label || selectedItem.value
    : placeholder;

  return (
    <Box ref={container} sx={{ ...sx, position: "relative" }}>
      <Flex
        data-cy={testId}
        sx={{ ...fieldStyles.getSelect(disabled), ...selectSx }}
        onClick={toggleOptions}
        aria-label={ariaLabel}
        role="listbox"
      >
        {selectedLabel}
        <ChevronDown noShadow />
      </Flex>

      <BasicDropdownPortal
        sx={{ ...fieldStyles.getOptions(isOpen), ...optionsSx }}
        isOpen={isOpen}
        container={container.current}
      >
        {items.map((item) => (
          <Box
            data-cy={item.value}
            role="button"
            key={item.value ?? "placeholder"}
            onClick={() => onItemClick(item)}
            sx={{ ...fieldStyles.getOption(isActive(item)), ...optionSx }}
          >
            {item.label || item.value}
          </Box>
        ))}
      </BasicDropdownPortal>
    </Box>
  );
}

export const fieldStyles = {
  getSelect: (disabled?: boolean): ThemeUIStyleObject => ({
    pl: [3],
    py: [2],
    cursor: disabled ? "default" : "pointer",
    svg: {
      ml: [4],
      fill: disabled ? "silver" : "var(--textColor)",
      transform: "scale(0.9)",
      height: "2em",
    },
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 6px center",
    fontWeight: 600,
    color: disabled ? "muted" : "var(--textColor)",
    alignItems: "center",
  }),

  getOptions: (isOpen: boolean): ThemeUIStyleObject => ({
    transition: "opacity 0.15s ease-in-out",
    visibility: isOpen ? "visible" : "hidden",
    opacity: isOpen ? 1 : 0,
    position: "fixed",
    minWidth: 200,
    maxHeight: 400,
    overflow: "auto",
    zIndex: dropdownOptionZIndex,
    boxShadow: "var(--controlShadow)",
  }),

  getOption: (isActive: boolean): ThemeUIStyleObject => ({
    pl: [3],
    pr: [4],
    py: [2],
    backgroundColor: "var(--basicDropdownBackground)",
    border: "none",
    cursor: "pointer",
    whiteSpace: "nowrap",
    color: isActive ? "primary" : "var(--textColor)",
    overflow: "hidden",
    transition: "all 0.15s ease-in-out",
    "&:hover": {
      backgroundColor: "primary",
      color: isActive ? "white" : "text",
    },
  }),
};

export default BasicDropdown;
