import React, { ReactElement } from "react";
import { Flex, Text, Box, Button } from "theme-ui";

import Display from "../../../../../icons/Display";
import Delete from "../../../../../icons/Delete";

type Props = {
  device: ApiSubscriptionDevice;
  onRemove(): void;
};

function Device({ device, onRemove }: Props): ReactElement {
  return (
    <Flex variant="layout.listRow">
      <Box
        variant="layout.listRowCell"
        style={{
          flex: "unset",
          padding: "0 0.5rem",
        }}
      >
        <Box
          sx={{
            bg: "primary",
            borderRadius: " 50%",
            height: "40px",
            width: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            svg: {
              height: "30px",
              width: "30px",
              "&:hover": {
                fill: "var(--textColor)",
              },
            },
          }}
        >
          <Display noShadow />
        </Box>
      </Box>
      <Text variant="layout.listRowCell">Device name</Text>
      <Text variant="layout.listRowCell" sx={{ bg: "delicate" }}>
        Device ID: {device.ID}
      </Text>
      <Text
        variant="layout.listRowCell"
        sx={{ display: ["none", "none", "flex"] }}
      >
        {device.DateCreated}
      </Text>
      <Text
        variant="layout.listRowCell"
        sx={{ bg: "delicate", display: ["none", "none", "flex"] }}
      >
        last activity
      </Text>
      <Text
        variant="layout.listRowCell"
        style={{
          flex: "unset",
          padding: "0 0.5rem",
        }}
      >
        <Button variant="buttons.content" onClick={onRemove}>
          <Delete noShadow />
        </Button>
      </Text>
    </Flex>
  );
}

export default Device;
