import LocalStorage, { SessionStorage } from "./localStorage";

interface ErrorToLog {
  Timestamp: number;
  TimestampISO: string;
  Message?: string;
  Stack?: string;
  Href: string;
  User?: string;
  ProjectId?: string;
  PageVisibilityState?: string;
  Data: unknown;
  AppVersion?: string;
}
interface ErrorsLog {
  [category: string]: ErrorToLog[];
}

export const errorLogStorage = new LocalStorage<ErrorsLog>("errors-log");

/**
 * Webex
 */

export const webexAuthTokenStorage = new LocalStorage<WebexAuth>(
  "webex-auth-token"
);
export const webexAccessCodeStorage = new SessionStorage<string>(
  "webex-access-code"
);

/**
 * Do not import this directly if you want to detect MS Teams. Instead use
 * the `isRunningInMSTeams` method in `tools/msTeams.ts`.
 */
export const MSTeamsStorage = new SessionStorage<boolean>("msteams");

// Catch users who are already relying on the old local storage value
if (localStorage.getItem("msteams")) {
  MSTeamsStorage.set(true);
  localStorage.removeItem("msteams");
}
