import { useEffect } from "react";
import { useParticipatingSpaces } from "../../api/useRequest";
import { ApiPermission } from "../../types/enum";
import { findSpacePermission } from "./utils";

type UseSpaces = {
  spaces: Space[];
  isLoading: boolean;
  refetch: () => void;
};

export const useSpaces = (): UseSpaces => {
  const { spaces, isLoading, refetch } = useParticipatingSpaces();

  return {
    spaces,
    isLoading,
    refetch,
  };
};

/**
 * This hook allows to know the space permission of the current user
 *
 * @NOTE It doesn't use `useSpaces` hook to avoid requesting the spaces if no `spaceId` is passed, e.g.
 * when the user is a guest.
 */
export const useSpacePermission = (
  spaceId: number | null
): ApiPermission | undefined => {
  const runQuery = !!spaceId;
  const { spaces, refetch } = useParticipatingSpaces(runQuery);

  useEffect(() => {
    if (spaceId) {
      refetch();
    }
  }, [spaceId, refetch]);

  if (!spaceId) {
    return undefined;
  }

  return findSpacePermission(spaces, spaceId);
};
