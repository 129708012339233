import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import CancelSubscription from "../../../../icons/CancelSubscription";
import { Confirmation } from "../../../shared/modals";
import { TemplateProps } from "../../../shared/modals/Modal";
import ModalHeader from "../../../shared/modals/ModalHeader";

type Props = {
  onConfirm(): void;
  activeTo: string;
};

function CancelSubscriptionModalTemplate({
  onConfirm,
  activeTo,
  modalProps,
}: TemplateProps<Props>): ReactElement {
  const { t } = useTranslation();

  return (
    <Confirmation
      header={
        <ModalHeader
          title={t("dialog.payment.cancelSubscription.header")}
          Icon={CancelSubscription}
        />
      }
      confirmationLabel={t("dialog.payment.cancelSubscription.confirm")}
      onConfirm={onConfirm}
      cancelLabel={t("dialog.payment.cancelSubscription.closeModal")}
      variant="warning"
      {...modalProps}
    >
      {t("dialog.payment.cancelSubscription.text", {
        date: new Date(activeTo),
      })}
    </Confirmation>
  );
}

export default CancelSubscriptionModalTemplate;
