import SimplePeer, { SignalData } from "simple-peer";

import { WebRTCError } from "../errors/webRtcError";
import { isError, stringifyError } from "../tools/errors";
import { runtimeConfig } from "../tools/runtimeConfig";
import { LogCategory, onErrorToLog, trackEventToLog } from "../tools/telemetry";
import { logWebRTCErrorToConsole, logWebRTCInfoToConsole } from "./logging";
import {
  WebRTCConnection,
  WebRTCDataMessage,
  WebRTCEvent,
  WebRTCEventToPayload,
  WebRTCMessageHandler,
  WebRTCMessageHandlerMap,
} from "./types";

const { webRTC } = runtimeConfig;

export class WebRTCManager {
  private hasWebRTCSupport = SimplePeer.WEBRTC_SUPPORT;
  private connectionAttemptLimit = 3; // Considering 30s timeout for attempt, attempts are aborted after 90s
  private connectionAttemptTimeout = 30 * 1000; // 30s based on Chrome's timeout https://groups.google.com/g/discuss-webrtc/c/575KmOHSXus

  private connections: Map<string, WebRTCConnection>;
  private connectionAttemptCount: Map<string, number>;
  private connectingIds: Set<string>;
  private connectionUsernames: Map<string, string>;
  private connectionTimeouts: Map<string, number>;

  private messageHandlers: Map<
    WebRTCEvent,
    Set<WebRTCMessageHandlerMap[WebRTCEvent]>
  >;

  constructor(private myUsername: string, private myConnectionId: string) {
    this.connections = new Map();
    this.connectionAttemptCount = new Map();
    this.connectingIds = new Set();
    this.connectionUsernames = new Map();
    this.connectionTimeouts = new Map();
    this.messageHandlers = new Map();

    if (this.hasWebRTCSupport) {
      logWebRTCInfoToConsole(
        `Created WebRTCManager for ${this.myConnectionId} (${this.myUsername})`
      );
    } else {
      logWebRTCInfoToConsole(
        `Client ${this.myConnectionId} (${this.myUsername}) does not support WebRTC`
      );
      trackEventToLog(LogCategory.webRTC, {
        subcategory: "unsupported-webRTC",
        connectionId: this.myConnectionId,
        user: this.myUsername,
      });
    }
  }

  /**
   * Change my connection ID.
   *
   * This is necessary because losing -> restoring connection to SignalR results
   * in the user getting a new connection ID.
   */
  public setMyConnectionId(connectionId: string): void {
    const currentConnectionId = this.myConnectionId;

    this.myConnectionId = connectionId;

    logWebRTCInfoToConsole(
      `Changed my connection ID from ${currentConnectionId} to ${this.myConnectionId}`
    );
  }

  /**
   * Destroy the manager and kill all connections.
   */
  public destroy(): void {
    if (!this.hasWebRTCSupport) {
      return;
    }

    logWebRTCInfoToConsole(
      `Destroying WebRTCManager for ${this.myConnectionId} (${this.myUsername})`
    );

    this.disconnectAll();

    this.messageHandlers.clear();
    this.connectingIds.clear();
    this.connectionTimeouts.clear();
  }

  /**
   * Connect to a connection ID as the initiator.
   */
  public connectTo(connectionId: string): void {
    if (!this.hasWebRTCSupport) {
      return;
    }

    this.createOrGetConnection(connectionId, { initiator: true });
  }

  /**
   * Disconnect a single connection.
   */
  public disconnectFrom(connectionId: string): void {
    if (!this.hasWebRTCSupport) {
      return;
    }

    logWebRTCInfoToConsole(`Disconnecting from ${connectionId}`);

    this.destroyConnection(connectionId);
    this.destroyConnectionTimeout(connectionId);
    this.connectionAttemptCount.delete(connectionId);
  }

  /**
   * Establish a peer-to-peer connection using signalling messages.
   */
  public establishConnection(
    connectionId: string,
    signalData: string | SignalData
  ): void {
    if (!this.hasWebRTCSupport) {
      return;
    }

    logWebRTCInfoToConsole(`Signal received from ${connectionId}`, signalData);

    /**
     * If we start receiving signalling messages from a connection ID that we
     * do not recognize then we need to create a connection as the recipient.
     */
    const connection = this.createOrGetConnection(connectionId, {
      initiator: false,
    });

    connection?.signal(signalData);
  }

  /**
   * Attach a message handler.
   */
  public on<T extends WebRTCEvent>(
    type: T,
    messageHandler: WebRTCMessageHandlerMap[T]
  ): void {
    if (!this.hasWebRTCSupport) {
      return;
    }

    const typeHandlers = this.messageHandlers.get(type);

    if (!typeHandlers) {
      this.messageHandlers.set(type, new Set());
    }

    this.messageHandlers.get(type)?.add(messageHandler);
  }

  /**
   * Detach a message listener.
   */
  public off<T extends WebRTCEvent>(
    type: T,
    messageHandler: WebRTCMessageHandlerMap[T]
  ): void {
    if (!this.hasWebRTCSupport) {
      return;
    }

    this.messageHandlers.get(type)?.delete(messageHandler);
  }

  /**
   * Send a message over the WebRTC data channel.
   */
  public sendTo<T extends WebRTCEvent>(
    connectionIds: string[],
    type: T,
    message: WebRTCEventToPayload[T]
  ): void {
    if (!this.hasWebRTCSupport) {
      return;
    }

    const payload = this.formatMessage(type, message);

    if (payload) {
      connectionIds.forEach((connectionId) => {
        logWebRTCInfoToConsole(`Sending ${type} message to ${connectionId}`);
        this.sendMessageTo(connectionId, payload);
      });
    }
  }

  /**
   * Broadcast a message over the WebRTC data channel.
   */
  public broadcast<T extends WebRTCEvent>(
    type: T,
    message: WebRTCEventToPayload[T]
  ): void {
    if (!this.hasWebRTCSupport) {
      return;
    }

    const payload = this.formatMessage(type, message);
    const connectionIds = Array.from(this.connections.keys());

    if (payload && connectionIds.length) {
      logWebRTCInfoToConsole(
        `Broadcasting ${type} message to ${connectionIds.length}`,
        payload
      );
      connectionIds.forEach((connectionId) =>
        this.sendMessageTo(connectionId, payload)
      );
    }
  }

  /**
   * Send message to a single connection.
   */
  private sendMessageTo(connectionId: string, payload: string): void {
    // Don't send messages to myself
    if (connectionId === this.myConnectionId) {
      return;
    }

    const connection = this.connections.get(connectionId);

    if (connection) {
      /**
       * Note, using `.write` rather than `.send` means the messages will be
       * buffered by SimplePeer if the connection is not fully open.
       */
      connection.write(payload);
      logWebRTCInfoToConsole(`Sent message to ${connectionId}`, payload);
    }
  }

  /**
   * Destroy a connection.
   *
   * This will trigger an "error" event (if an error is passed), followed by
   * a "close" event.
   */
  private destroyConnection(connectionId: string, error?: Error): void {
    const connection = this.connections.get(connectionId);

    if (connection) {
      logWebRTCInfoToConsole(`Destroying connection to ${connectionId}`);

      // Workaround for: https://github.com/feross/simple-peer/issues/848
      connection._channel?.close();
      connection.destroy(error);
    }
  }

  /**
   * Disconnect all connections.
   */
  private disconnectAll(): void {
    this.connections.forEach((_, connectionId) =>
      this.disconnectFrom(connectionId)
    );
  }

  /**
   * Create a connection object for unknown peers.
   */
  private createOrGetConnection(
    connectionId: string,
    config: { initiator: boolean }
  ): WebRTCConnection | undefined {
    // Do not attempt to connect to myself
    if (connectionId === this.myConnectionId) {
      return undefined;
    }

    // Do not attempt to connect to a connection ID that has failed too many times
    if (!this.allowConnectionAttempt(connectionId)) {
      logWebRTCInfoToConsole(
        `Attempted to connect to dead connection ${connectionId}`
      );
      return undefined;
    }

    const existingConnection = this.connections.get(connectionId);

    if (existingConnection) {
      return existingConnection;
    }

    const connection = new SimplePeer({
      // Note, only one end of the connection can be the initiator
      initiator: config.initiator,
      channelConfig: {
        ordered: true,
      },
      config: {
        iceServers: [
          {
            urls: [`stun:${webRTC.stunTurnUrl}`, `turn:${webRTC.stunTurnUrl}`],
            username: webRTC.username,
            credential: webRTC.credential,
          },
        ],
        iceTransportPolicy: webRTC.iceTransportPolicy ?? "all",
      },
      /**
       * #6296 - disable trickle ICE to get a single 'signal' event.
       *
       * This prevents the signalling messages from overflowing SignalR queue
       * and triggering the 'syncing...' toast when starting a presentation
       * with multiple peers. But it also means it takes longer to establish
       * a WebRTC connection.
       *
       * TODO: Allow signalling messages to bypass the SignalR queue monitoring.
       */
      trickle: false,
    }) as WebRTCConnection; // Add properties missing from SimplePeer type

    connection.on("signal", (data) => this.handleSignal(connectionId, data));
    connection.on("connect", () => this.handleConnect(connectionId));
    connection.on("data", (data) => this.handleData(connectionId, data));
    connection.on("close", () => this.handleClose(connectionId));

    // The initiator is responsible for reconnecting
    if (config.initiator) {
      connection.on("error", (error) =>
        this.handleErrorAndReconnect(connectionId, error)
      );
    } else {
      connection.on("error", (error) => this.handleError(connectionId, error));
    }

    this.connections.set(connectionId, connection);

    logWebRTCInfoToConsole(`Connecting to ${connectionId}`, config);

    this.connectingIds.add(connectionId);
    this.notifyHandlers(
      WebRTCEvent.Connecting,
      { connectingIds: Array.from(this.connectingIds) },
      connectionId
    );

    this.startConnectionTimeout(connectionId);
    this.incrementConnectionAttempts(connectionId);

    return connection;
  }

  /**
   * Handle connection connected.
   */
  private handleConnect(connectionId: string): void {
    logWebRTCInfoToConsole(`Connected to ${connectionId}`);

    this.connectingIds.delete(connectionId);
    this.notifyHandlers(
      WebRTCEvent.Connecting,
      { connectingIds: Array.from(this.connectingIds) },
      connectionId
    );
    this.resetConnectionAttempts(connectionId);
    this.destroyConnectionTimeout(connectionId);

    // Automatically send our username when we're connected
    this.sendTo([connectionId], WebRTCEvent.Connected, {
      UserName: this.myUsername,
    });
  }

  /**
   * Handle signalling data (offers) created by SimplePeer.
   */
  private handleSignal(connectionId: string, data: SignalData): void {
    this.notifyHandlers(WebRTCEvent.Signal, data, connectionId);

    logWebRTCInfoToConsole(`Signal generated for ${connectionId}`, data);
  }

  /**
   * Handle incoming data messages on data channel.
   */
  private handleData(connectionId: string, data: Uint8Array): void {
    const message = this.parseMessage(data);

    if (message) {
      if (this.isConnectedMessage(message)) {
        this.connectionUsernames.set(connectionId, message.payload.UserName);
      }

      this.notifyHandlers(message.type, message.payload, connectionId);

      logWebRTCInfoToConsole(
        `Message ${message.type} received from ${connectionId}`,
        message
      );
    }
  }

  /**
   * Handle closed or destroyed connection.
   *
   * This will happen if a fatal error occurs too.
   */
  private handleClose(connectionId: string): void {
    const UserName = this.connectionUsernames.get(connectionId) ?? "";

    this.notifyHandlers(WebRTCEvent.Disconnected, { UserName }, connectionId);

    this.connectingIds.delete(connectionId);
    this.connections.delete(connectionId);
    this.connectionUsernames.delete(connectionId);
    this.destroyConnectionTimeout(connectionId);

    this.notifyHandlers(
      WebRTCEvent.Connecting,
      { connectingIds: Array.from(this.connectingIds) },
      connectionId
    );

    logWebRTCInfoToConsole(`Closed connection to ${connectionId}`);
  }

  /**
   * Handle errors and attempt to reconnect.
   */
  private handleErrorAndReconnect(connectionId: string, error: Error): void {
    this.handleError(connectionId, error);

    if (this.allowConnectionAttempt(connectionId)) {
      /**
       * SimplePeer calls "close" immediately after "error", but as it is
       * event driven we need to wait a tick.
       */
      setTimeout(() => {
        logWebRTCInfoToConsole(`Attempting to reconnect to ${connectionId}`, {
          attempt: this.getConnectionAttempts(connectionId),
        });

        this.connectTo(connectionId);
      });
    } else {
      logWebRTCInfoToConsole(`Failed to reconnect to ${connectionId}`);
    }
  }

  /**
   * Handle errors.
   *
   * All errors are fatal and require a new connection to be established.
   */
  private handleError(connectionId: string, error: Error): void {
    this.destroyConnectionTimeout(connectionId);
    this.connectingIds.delete(connectionId);

    this.notifyHandlers(
      WebRTCEvent.Connecting,
      { connectingIds: Array.from(this.connectingIds) },
      connectionId
    );

    trackEventToLog(LogCategory.webRTC, {
      subcategory: "WebRTC-connection-error",
      error: stringifyError(error),
    });

    logWebRTCErrorToConsole(`Error on connection to ${connectionId}`, error);
  }

  /**
   * Send message to handlers.
   */
  private notifyHandlers<T extends WebRTCEvent>(
    event: T,
    payload: WebRTCEventToPayload[T],
    connectionId: string
  ) {
    const UserName = this.connectionUsernames.get(connectionId) ?? "";

    this.messageHandlers.get(event)?.forEach((messageHandler) => {
      (messageHandler as WebRTCMessageHandler<T>)(
        payload,
        connectionId,
        UserName
      );
    });
  }

  /**
   * Get the number of times we've attempted to connect to this connection ID.
   */
  private getConnectionAttempts(connectionId: string): number {
    return this.connectionAttemptCount.get(connectionId) ?? 0;
  }

  /**
   * Reset the connection attempt count.
   */
  private resetConnectionAttempts(connectionId: string): void {
    this.connectionAttemptCount.set(connectionId, 0);
  }

  /**
   * Increment the number of times we've attempted to connection to this
   * connection ID.
   */
  private incrementConnectionAttempts(connectionId: string): void {
    this.connectionAttemptCount.set(
      connectionId,
      this.getConnectionAttempts(connectionId) + 1
    );
  }

  /**
   * Check if we should continue to attempt to connect to this connection ID.
   */
  private allowConnectionAttempt(connectionId: string): boolean {
    return (
      this.getConnectionAttempts(connectionId) < this.connectionAttemptLimit
    );
  }

  /**
   * Start a timeout to limit connection attempts.
   */
  private startConnectionTimeout(connectionId: string): void {
    this.destroyConnectionTimeout(connectionId);

    const timeout = window.setTimeout(() => {
      if (!this.connectingIds.has(connectionId)) {
        return;
      }

      logWebRTCInfoToConsole(`Timeout reconnecting to ${connectionId}`);

      this.destroyConnection(
        connectionId,
        new WebRTCError("Connection timeout")
      );
    }, this.connectionAttemptTimeout);

    this.connectionTimeouts.set(connectionId, timeout);
  }

  /**
   * Clean up connection timeout.
   */
  private destroyConnectionTimeout(connectionId: string): void {
    clearTimeout(this.connectionTimeouts.get(connectionId));
    this.connectionTimeouts.delete(connectionId);
  }

  /**
   * Format message for data channel.
   */
  private formatMessage(
    type: WebRTCEvent,
    payload: unknown
  ): string | undefined {
    try {
      return JSON.stringify({
        type,
        payload,
      });
    } catch (error) {
      isError(error) && onErrorToLog(error, LogCategory.webRTC);
      return undefined;
    }
  }

  /**
   * Parse message from data channel.
   */
  private parseMessage(
    payload: Uint8Array
  ): WebRTCDataMessage<WebRTCEvent> | undefined {
    try {
      const decoded = new TextDecoder("utf-8").decode(payload);
      return JSON.parse(decoded);
    } catch (error) {
      isError(error) && onErrorToLog(error, LogCategory.webRTC);
      return undefined;
    }
  }

  private isConnectedMessage(
    message: WebRTCDataMessage<WebRTCEvent>
  ): message is WebRTCDataMessage<WebRTCEvent.Connected> {
    return message.type === WebRTCEvent.Connected;
  }
}
