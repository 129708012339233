import { selectCanvasBackgroundColor } from "../../../reduxStore/canvas/settings/settings.reducer";
import { reduxSingleton } from "../../../reduxStore/redux.singleton";
import { isDarkColor, toDarkModeColor } from "../../../tools/colors";

export class LiveColor {
  darkModeColor: string;
  base: string;

  constructor(baseColor: string) {
    this.base = baseColor;
    this.darkModeColor = toDarkModeColor(this.base);
  }

  /**
   * This method is called by fabric internally to determine the actual color
   */
  toLive(): string {
    const { darkModeColor, base } = this;

    const backgroundColor = selectCanvasBackgroundColor(
      reduxSingleton.getStore().getState()
    );
    const isDarkBackground = isDarkColor(backgroundColor);

    return isDarkBackground ? darkModeColor : base;
  }

  /**
   * Sometimes fabric uses directly a color expecting a string, e.g. to render
   * the text blinking cursor.
   */
  toString(): string {
    return this.toLive();
  }
}
