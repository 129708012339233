import i18n from "i18next";
import { uniq } from "ramda";
import type {
  GroupedTile,
  TileBatchActionObjectInfo,
  TileIndex,
  TileRelation,
  TileStatus,
} from "../../../api/signalR/message.types";
import { updateGroupAfterUpdate } from "../../../studio/components/group/group.utils";
import {
  flattenObjectTree,
  hasInnerObjects,
  isAutoSelectable,
  isCollection,
  isInCollection,
  isInGroup,
  isNotAnimated,
  isStack,
} from "../../../studio/utils/fabricObjects";
import {
  isDefinedTileId,
  rehydrateConnectors,
  toContextProps,
  toFullObjects,
  toObjectProps,
} from "../../../studio/utils/objectConverter";
import { objectsInGroupsByIds } from "../../../tools";
import { errorToast } from "../../../tools/errorToast";
import { byZIndex } from "../../../tools/sorters";
import { isDefined, splitBy, unique } from "../../../tools/utils";

type AddObjectsConfig = {
  nestInGroups: boolean;
  noAnimation: boolean;
};

export const addObjects = async (
  canvas: fabric.CollaboardCanvas,
  tiles: TileStatus[],
  config: AddObjectsConfig = {
    noAnimation: false,
    nestInGroups: true,
  }
): Promise<fabric.Object[]> => {
  const { objects, errors, errorMessageList } = await toFullObjects(tiles);

  const sortedObjects = [...objects].sort(byZIndex);

  // #4947: Avoid the animation for objects that have a parentId as they have
  // relative coordinates. Avoid also for empty groups which don't have the children
  // yet, which will be added to the group in `nestInGroups`.
  // Lastly, don't animate adding lots of objects.
  const [notAnimatedAdd, animatedAdd] =
    sortedObjects.length < 10 && !config.noAnimation
      ? splitBy(sortedObjects, isNotAnimated)
      : [sortedObjects, []];

  await canvas.fxAdd(...animatedAdd);

  notAnimatedAdd.forEach((o) =>
    isDefined(o.zIndex) ? canvas.insertAt(o, o.zIndex) : canvas.add(o)
  );

  // When undoing Remove of a group or Ungroup because of deleted 2nd last subitem, both the group
  // and the children are re-added to the canvas in the same batch
  config.nestInGroups && canvas.nestInGroups(sortedObjects);

  errors.length &&
    errorToast(
      i18n.t("clientError.cannotCreateObjects", {
        count: errors.length,
        errors: errorMessageList,
      })
    );

  return sortedObjects;
};

export const updateObject = (
  object: fabric.Object,
  tile: TileStatus,
  action: ModifyAction
): void => {
  const isUpdateFromServer = action === "serverUpdate";
  const objectProps = toObjectProps(tile);
  const contextProps = toContextProps(tile);
  // Beware - `set()` can have a performance overhead when changing properties that affect dimensions
  object.set(objectProps);
  object.setContextProps(contextProps, {
    skipGroup: true,
    isUpdateFromServer,
  });
  isUpdateFromServer && object.onUpdateFromServer(objectProps, contextProps);
  object.setCoords(!!object.group);
  object.trigger("modified", { transform: { action } });
  if (isCollection(object)) {
    flattenObjectTree(object.getObjects()).forEach((child) => {
      child.setCoords();
      child.trigger("modified", { transform: { action } });
    });
  }
};

export const deleteObjects = async (
  canvas: fabric.CollaboardCanvas,
  tileIds: string[]
): Promise<void> => {
  // find objects, and their children if groups
  const objects = canvas.getObjectsByUUIDs(tileIds);
  const children = objects.flatMap((o) =>
    hasInnerObjects(o) ? o.getObjects() : []
  );

  // and find objects only inside groups (subselection mode)
  const childrenInGroups = objectsInGroupsByIds(canvas.getObjects(), tileIds);
  const allObjectsToRemove = [...children, ...objects];

  canvas.discardReadOnlyActiveObject(allObjectsToRemove);
  await canvas.fxRemove(...allObjectsToRemove);

  canvas.unreserveObjects(allObjectsToRemove);

  childrenInGroups.forEach((child) => {
    const { group } = child;

    if (group) {
      group.removeWithUpdate(child);
      canvas._onObjectRemoved(child);
    }
  });
};

const batchTileToProps = (
  tile: TileBatchActionObjectInfo
): Partial<fabric.Object> => {
  return {
    top: tile.Y,
    left: tile.X,
    zIndex: tile.Z,
    angle: tile.Angle,
  };
};

/**
 * Move objects into a group.
 * @NOTE If the object is already is a group, the group is destroyed completely for performance
 * reasons as we don't currently have use cases where only some objects change group.
 */
export const moveGroupedObjects = (
  canvas: fabric.CollaboardCanvas,
  tiles: TileBatchActionObjectInfo[],
  action: ModifyAction
): void => {
  const groupsUuids = uniq(tiles.map((t) => t.ParentId).filter(isDefined));
  const groups = canvas.getObjectsByUUIDs(groupsUuids).filter(isCollection);

  groups.forEach((group) => {
    const childrenUuids = tiles
      .filter((tile) => tile.ParentId === group.uuid)
      .map((tile) => tile.Id);
    // Objects could already be in the group because of `canvas.nestInGroups` in `addObjects`
    // This happens when undoing Remove of a group or Ungroup because of deleted 2nd last subitem
    const children = canvas
      .getObjectsByUUIDs(childrenUuids)
      .filter((o) => !isInGroup(o) || o.group?.uuid !== group.uuid);

    const eventProps: ClientModifiedEvent = {
      transform: { action },
    };

    canvas.discardReadOnlyActiveObject(children);
    canvas.unreserveObjects(children);

    children.forEach((object) => {
      if (object.group && isInCollection(object)) {
        // Unpack so that any object which won't be grouped will be on the canvas, e.g. `topLevelObjectsBeforeGroup` when Undo Group
        object.group.unpack({ isSilent: action === "serverUpdate" });
      }

      canvas.removeSilently(object);

      object.isGrouped = true;
      object.parentId = group.uuid;
    });

    group.add(...children);

    // Apply children coordinates, which must be relative to the parent and not absolute wrt to the canvas
    // Do so for each group children, even those which were already in the group
    group.forEachObject((o) => {
      const tile = tiles.find((t) => t.Id === o.uuid);
      tile && o.set(batchTileToProps(tile));
    });

    // We don't need to reassign any zIndex, just to make sure that that the objects are sorted
    group._objects = group.getObjects().sort(byZIndex);

    // Updating group's bounds and coords is important for stacks as the group has a different size
    // wrt before grouping. For groups it's the same as before, but it doesn't hurt to be updated as well.
    updateGroupAfterUpdate(group);

    // Trigger "modified" for each group children, even those which were already in the group
    group.forEachObject((object) => object.trigger("modified", eventProps));
  });
};

/**
 * Ungroup children from their parent group.
 *
 * @NOTE This should technically just remove ungrouped objects from groups and put them
 * in the canvas, leaving remaining objects in the group. But in practise, all the use cases of
 * `postTileBatchAction({ ungrouped })` result in completely unpacked groups.
 */
export const ungroupObjects = (
  canvas: fabric.CollaboardCanvas,
  tiles: TileBatchActionObjectInfo[],
  action: ModifyAction
): void => {
  const objectUuids = tiles.map((t) => t.Id);
  const objects = canvas.getObjectsByUUIDs(objectUuids);
  // Some objects may already be ungrouped because of `moveGroupedObjects` above, e.g. UNDO UNGROUP_ALL
  const [stillGroupedObjects, alreadyUngroupedObjects] = splitBy(
    objects,
    isInCollection
  );
  const groups: fabric.Group[] = unique(
    stillGroupedObjects.map((o) => o.group as fabric.Group)
  );
  const eventProps: ClientModifiedEvent = {
    transform: { action },
  };

  const updatedChild = (child: fabric.Object) => {
    const tile = tiles.find((t) => t.Id === child.uuid);

    if (!tile) {
      return;
    }

    child.set(batchTileToProps(tile));
    // After a REMOVE action, remained objects can have the same zIndex that
    // the restored object had because of the zIndex reassignment. So we need
    // to actively move the restored object before any object with the same zIndex.
    canvas.moveTo(child, tile.Z);
    child.trigger("modified", eventProps);
  };

  groups.forEach((group) => {
    const groupObjects = group.getObjects();

    // This will internally also remove the group from the UUID map and the later
    // NotifyDelete will be noop for the group UUID
    group.unpack({ isSilent: action === "serverUpdate" });

    // Loop through objects in the reverse order to preserve top-most objects
    // when doing `moveTo` in case of children with the same `props.zIndex`
    // e.g. objectsAfterUnstack
    groupObjects.slice().reverse().forEach(updatedChild);
  });

  alreadyUngroupedObjects.slice().reverse().forEach(updatedChild);

  canvas.unreserveObjects(groups);
};

export const updateObjectsReservation = (
  canvas: fabric.CollaboardCanvas,
  tileIds: string[],
  user: ProjectOnlineUserInfo
): void => {
  const objectsToLock = canvas
    .getObjectsByUUIDs(tileIds)
    .filter(isAutoSelectable);

  // The user has multiple tabs and is receiving his own reservation
  if (user.IsMyself) {
    canvas.initMySelection(objectsToLock);
  } else {
    objectsToLock.length
      ? canvas.reserveObjects(objectsToLock, user)
      : canvas.clearReservation(user.UserName);
  }
};

export const updateTopLevelZIndexes = (
  canvas: fabric.CollaboardCanvas,
  tiles: TileIndex[],
  action?: ModifyAction
): void => {
  tiles.forEach(({ TileId, ZIndex }) => {
    const object = canvas.getObjectByUUID(TileId);
    if (object) {
      object.set({ zIndex: ZIndex });
      action && object.trigger("modified", { transform: { action } });
    }
  });

  canvas.sortAndReassignRootZIndexes({ isSilent: true });
};

export const updateGroupZIndexes = (
  canvas: fabric.CollaboardCanvas,
  children: GroupedTile[],
  action?: ModifyAction
): void => {
  const parentId = unique(
    children
      .map((t) =>
        isDefinedTileId(t.ParentTileId) ? t.ParentTileId : undefined
      )
      .filter(isDefined)
  );
  const group = parentId[0]
    ? canvas.getObjectByUUID<fabric.Group>(parentId[0])
    : undefined;

  if (!group) {
    return;
  }

  group.objectCaching = false;

  children.forEach(({ TileId, ZIndex: zIndex }) => {
    const object = canvas.getObjectByUUID(TileId);
    if (object) {
      object.set({ zIndex });
      action && object.trigger("modified", { transform: { action } });
    }
  });

  group.sortAndReassignZIndexes({ isSilent: true });

  group.dirty = true;
  group.objectCaching = true;

  isStack(group) && group.updateIframes();
};

export const addConnections = (
  canvas: fabric.CollaboardCanvas,
  relations: TileRelation[]
): Promise<void> =>
  rehydrateConnectors(relations, canvas).then((connections) => {
    canvas.add(...connections);
  });

export const changeBlobStatus = (
  tileId: string,
  azureBlobStatus: number,
  canvas: fabric.CollaboardCanvas
): void => {
  const object = canvas.getObjectByUUID<fabric.CollaboardImage>(tileId);
  object?.setBlobStatus(azureBlobStatus);
};
