import { Form, Formik, FormikConfig } from "formik";
import React, {
  CSSProperties,
  ReactElement,
  useCallback,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ThemeUIStyleObject } from "theme-ui";
import { object, string } from "yup";
import { getUserOptions } from "../../../api";
import { errorCodeIds } from "../../../api/errorCodes";
import { routes } from "../../../const";
import {
  AuthUserType,
  setAuthenticatedAction,
  setAuthenticatingAction,
} from "../../../reduxStore/auth/auth.actions";
import { assertUnreachable } from "../../../tools/assertions";
import { getAppName } from "../../../tools/customer";
import { isAPIError } from "../../../tools/errors";
import { ApiAuthenticationMode } from "../../../types/enum";
import FormInputField from "../../shared/forms/fields/InputField";
import ProcessingButton from "../../shared/forms/ProcessingButton";
import { useSimpleModal } from "../../shared/modals/useSimpleModal";
import EmailVerificationModalTemplate from "./EmailVerificationModalTemplate";
import OTPModalTemplate from "./OTPModalTemplate";
import PasswordModalTemplate from "./PasswordModalTemplate";
import ValidateTFAModalTemplate from "./ValidateTFAModalTemplate";
import { useErrorHandler } from "../../shared/hooks/useErrorHandler";
import { LogCategory, onErrorToLog } from "../../../tools/telemetry";
import {
  isCompleteRegistrationResponse,
  isValidatedTokenResponse,
} from "./auth.utils";
import { AuthenticatingDetails } from "../../../reduxStore/auth/auth.reducer";
import { useAuthenticatingStatus } from "../useAuthenticatingStatus";
import { InternalError } from "../../../errors/InternalError";

type EmailLoginFormValues = {
  UserName: string;
};

type Props = {
  redirectAfterLogin?: string;
  tenant?: string;
  UserName?: string;
  onSetUserName: (UserName: string) => void;
};

function EmailLogin({
  redirectAfterLogin,
  tenant,
  UserName,
  onSetUserName,
}: Props): ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { fallbackErrorHandler } = useErrorHandler();
  const { authenticatingDetails } = useAuthenticatingStatus();

  const [loadingMode, setLoadingMode] = useState(false);
  const [unvalidatedToken, setUnvalidatedToken] = useState<ApiAuthenticate>();
  const [authMode, setAuthMode] = useState<ApiAuthenticationMode>();

  const emailVerificationModal = useSimpleModal();
  const otpModal = useSimpleModal();
  const passwordModal = useSimpleModal();
  const validate2FAModal = useSimpleModal();

  const redirectToCompleteRegistration = useCallback(
    (user: RegistrationUser, token: string) => {
      const state: CompleteRegistrationLocationState = {
        authToken: token,
        user,
      };

      history.replace(routes.registerComplete, state);
    },
    [history]
  );

  /**
   * Handle an error from an authentication modal
   */
  const handleAuthModalError = useCallback(
    async (error: unknown) => {
      if (isAPIError(error)) {
        const { details, response } = error;
        const { errorCode } = details;

        if (errorCode === errorCodeIds.UserNotVerified) {
          emailVerificationModal.open();
          otpModal.closeOnNextTick();
          passwordModal.closeOnNextTick();
          return;
        }

        if (errorCode === errorCodeIds.UserNotAcceptedTermsOfService) {
          if (response?.data && isCompleteRegistrationResponse(response.data)) {
            const { User, AuthorizationToken } = response.data;
            redirectToCompleteRegistration(User, AuthorizationToken);
            return;
          }
        }
      }

      fallbackErrorHandler(error, "Error in auth modal", LogCategory.auth);
    },
    [
      emailVerificationModal,
      fallbackErrorHandler,
      otpModal,
      passwordModal,
      redirectToCompleteRegistration,
    ]
  );

  /**
   * User has successfully validated their credentials against the API.
   */
  const authenticatedWithCredentials = useCallback(
    (response: ApiValidatedAuthenticate) => {
      if (!authenticatingDetails) {
        onErrorToLog(
          new InternalError("Missing authenticating details"),
          LogCategory.auth
        );
        return;
      }

      dispatch(
        setAuthenticatedAction({
          ...authenticatingDetails,
          token: response,
          userType: AuthUserType.User,
        })
      );

      /** @TODO #7118 handle stored team invite */

      passwordModal.closeOnNextTick();
      otpModal.closeOnNextTick();
      validate2FAModal.closeOnNextTick();
    },
    [authenticatingDetails, dispatch, otpModal, passwordModal, validate2FAModal]
  );

  /**
   * Credentials (password or OTP) have been successfully sent to the server.
   */
  const onCredentialsSuccess = useCallback(
    (response: ApiAuthenticate | ApiValidatedAuthenticate) => {
      const { AuthenticationMode } = response;

      if (
        AuthenticationMode === ApiAuthenticationMode.TFA ||
        !isValidatedTokenResponse(response)
      ) {
        setUnvalidatedToken(response);
        validate2FAModal.open();
        passwordModal.closeOnNextTick();
        otpModal.closeOnNextTick();
        return;
      }

      authenticatedWithCredentials(response);
    },
    [authenticatedWithCredentials, otpModal, passwordModal, validate2FAModal]
  );

  /**
   * Open the credential modal based on the user's auth mode.
   */
  const openAuthModeCredentialsModal = useCallback(
    (authMode: ApiAuthenticationMode) => {
      switch (authMode) {
        case ApiAuthenticationMode.PASSWORD:
        case ApiAuthenticationMode.TFA: {
          passwordModal.open();
          break;
        }
        case ApiAuthenticationMode.OTP_CODE: {
          otpModal.open();
          break;
        }
        case ApiAuthenticationMode.GUEST: {
          // noop
          break;
        }
        default: {
          assertUnreachable(authMode);
        }
      }
    },
    [otpModal, passwordModal]
  );

  /**
   * Handle an email verification code submission.
   */
  const onEmailVerifySuccess = useCallback(() => {
    emailVerificationModal.closeOnNextTick();
    toast(t("form.header.verificationSuccessful", { appName: getAppName() }));
    authMode && openAuthModeCredentialsModal(authMode);
  }, [authMode, emailVerificationModal, openAuthModeCredentialsModal, t]);

  const formikConfig: FormikConfig<EmailLoginFormValues> = {
    initialValues: {
      UserName: "",
    },
    onSubmit: useCallback(
      async ({ UserName }: EmailLoginFormValues): Promise<void> => {
        onSetUserName(UserName);
        setLoadingMode(true);
        setUnvalidatedToken(undefined);

        try {
          const { AuthenticationMode, Tenant } = await getUserOptions(UserName);

          /**
           * If the API returns a `Tenant` value that means this user's email
           * address is associated to a tenant and the user must be forced to
           * login via the tenant's authentication options.
           */
          if (Tenant) {
            /**
             * @TODO #7118
             * - Check if we need tenantAutoSelect or redirect here
             * - Notify the user what's just happened
             */
            history.replace(`${routes.authenticate}/${Tenant}`);
            return;
          }

          const state: AuthenticatingDetails = {
            authProvider: "email",
            isRegistration: false,
            redirect: redirectAfterLogin,
            tenant,
          };
          dispatch(setAuthenticatingAction(state));

          setAuthMode(AuthenticationMode);
          openAuthModeCredentialsModal(AuthenticationMode);
        } catch (error) {
          fallbackErrorHandler(
            error,
            "Failed to get user options",
            LogCategory.auth
          );
        } finally {
          setLoadingMode(false);
        }
      },
      [
        dispatch,
        fallbackErrorHandler,
        history,
        onSetUserName,
        openAuthModeCredentialsModal,
        redirectAfterLogin,
        tenant,
      ]
    ),
    validationSchema: object().shape({
      UserName: string()
        .email(t("clientError.emailNotValid"))
        .required(t("clientError.required")),
    }),
    /**
     * Run the validation on mount to ensure that the `isValid` value is
     * correct, otherwise the value can be `true` even when the `initialValues`
     * are not valid. This results in the submit button being enabled when
     * really it should be disabled.
     */
    validateOnMount: true,
  };

  return (
    <>
      <Formik {...formikConfig}>
        {({ handleBlur, handleChange, isSubmitting, isValid, values }) => (
          <Form style={emailFormStyle}>
            <FormInputField
              type="email"
              name="UserName"
              autoComplete="email"
              onChange={handleChange}
              onBlur={(e) => {
                handleBlur(e);
                // Update UserName immediately so that the Activate link has access
                onSetUserName(isValid ? values.UserName : "");
              }}
              readOnly={loadingMode}
            />
            <ProcessingButton
              type="submit"
              sx={emailButtonStyle}
              disabled={!isValid || isSubmitting}
              isProcessing={loadingMode}
            >
              {t("form.button.login")}
            </ProcessingButton>
          </Form>
        )}
      </Formik>
      {UserName && (
        <>
          <PasswordModalTemplate
            UserName={UserName}
            modalProps={passwordModal}
            onSuccess={onCredentialsSuccess}
            onError={handleAuthModalError}
          />
          <OTPModalTemplate
            UserName={UserName}
            modalProps={otpModal}
            onSuccess={onCredentialsSuccess}
            onError={handleAuthModalError}
          />
          <EmailVerificationModalTemplate
            UserName={UserName}
            modalProps={emailVerificationModal}
            onSuccess={onEmailVerifySuccess}
            onError={handleAuthModalError}
          />
        </>
      )}
      {unvalidatedToken && (
        <ValidateTFAModalTemplate
          UserName={UserName}
          modalProps={validate2FAModal}
          onSuccess={authenticatedWithCredentials}
          onError={handleAuthModalError}
          UnvalidatedToken={unvalidatedToken}
        />
      )}
    </>
  );
}

export default EmailLogin;

const emailButtonStyle: ThemeUIStyleObject = {
  width: "100%",
  height: "3.75rem",
  flex: "initial",
};

const emailFormStyle: CSSProperties = {
  marginBottom: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};
