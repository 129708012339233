import i18next from "i18next";
import React, { ReactElement, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { Flex, Heading, Text, ThemeUIStyleObject } from "theme-ui";
import { completeUserRegistration, updateUserProfile } from "../../../api";
import { routes } from "../../../const";
import {
  AuthUserType,
  setAuthenticatedAction,
} from "../../../reduxStore/auth/auth.actions";
import { useUISettings } from "../../../reduxStore/uiSettings";
import { getAppName } from "../../../tools/customer";
import { LogCategory } from "../../../tools/telemetry";
import { ApiLanguage } from "../../../types/enum";
import RegistrationForm from "../../authentication/registration/RegistrationForm";
import { useAuthenticatingStatus } from "../../authentication/useAuthenticatingStatus";
import { ProfileFormValues } from "../../authentication/useAuthentication";
import { useErrorHandler } from "../../shared/hooks/useErrorHandler";
import Page from "../layout/pages/Page";

function CompleteRegistration(): ReactElement {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { state } = useLocation<
    CompleteRegistrationLocationState | undefined
  >();
  const { fallbackErrorHandler } = useErrorHandler();
  const { loadUISettings } = useUISettings();
  const {
    authenticatingDetails,
    clearAuthenticatingStatus,
  } = useAuthenticatingStatus();

  const onSubmit = useCallback(
    async (values: ProfileFormValues): Promise<void> => {
      if (!state || !authenticatingDetails) {
        history.replace(routes.authenticate);
        return;
      }

      try {
        const response = await completeUserRegistration(
          values,
          state.authToken
        );

        await updateUserProfile(
          {
            CompanyName: values.CompanyName,
            ReceiveNews: values.ReceiveNews,
          },
          state.authToken
        );

        dispatch(
          setAuthenticatedAction({
            ...authenticatingDetails,
            // Treat this as a normal login
            isRegistration: false,
            redirect: authenticatingDetails.redirect ?? routes.projects,
            token: response,
            userType: AuthUserType.User,
          })
        );
      } catch (error: unknown) {
        fallbackErrorHandler(
          error,
          "Failed to complete registration",
          LogCategory.auth
        );
      }
    },
    [state, authenticatingDetails, history, dispatch, fallbackErrorHandler]
  );

  const authenticatingWithTenant = authenticatingDetails?.tenant;

  useEffect(() => {
    if (authenticatingWithTenant) {
      // Load tenant UISettings to know if registration name is editable
      loadUISettings(authenticatingWithTenant);
    }
  }, [authenticatingWithTenant, loadUISettings]);

  /**
   * Clean up the state if the user navigates away from this page.
   */
  useEffect(() => {
    return () => {
      clearAuthenticatingStatus();
    };
  }, [clearAuthenticatingStatus]);

  if (!state || !authenticatingDetails) {
    return <Redirect to={routes.authenticate} />;
  }

  const initialValues: ProfileFormValues = {
    UserName: state.user.UserName ?? "",
    FirstName: state.user.FirstName ?? "",
    LastName: state.user.LastName ?? "",
    Language: state.user.Language ?? (i18next.resolvedLanguage as ApiLanguage),
    PhoneNumber: state.user.PhoneNumber ?? "",
    ReceiveNews: false, // Not available from API
    TermsOfServiceAccepted: state.user.TermsOfServiceAccepted || false,
  };

  return (
    <Page>
      <Heading as="legend" sx={headingStyle}>
        {t("form.header.completeExternalUserRegistration")}
      </Heading>

      <Flex sx={externalRegistrationStyle}>
        <Text sx={{ fontSize: [5], fontWeight: 200 }}>
          {t("form.header.completeExternalUserRegistrationSubheader1", {
            appName: getAppName(),
          })}
        </Text>
        <Text sx={{ fontSize: [5], fontWeight: 200, mb: [5] }}>
          {t("form.header.completeExternalUserRegistrationSubheader2")}
        </Text>
      </Flex>
      <RegistrationForm<ProfileFormValues>
        initialValues={initialValues}
        onSubmit={onSubmit}
        hasInvitationData={false}
        isCompleteRegistration={true}
      />
    </Page>
  );
}

export default CompleteRegistration;

const headingStyle: ThemeUIStyleObject = { width: ["100%"] };
const externalRegistrationStyle: ThemeUIStyleObject = {
  flexDirection: "column",
};
