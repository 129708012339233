import { ReactElement, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { Flex, Text, ThemeUIStyleObject } from "theme-ui";
import { getProjectInvitationDetails } from "../../../api";
import { fixInvalidToken } from "../../../api/helpers";
import { projectPermissions, routes } from "../../../const";
import { useUISettings } from "../../../reduxStore/uiSettings";
import { isDefaultCustomer } from "../../../tools/customer";
import { dynamicFeatureFlags } from "../../../tools/flags";
import { useAuthenticatingStatus } from "../../authentication/useAuthenticatingStatus";
import { useLoggedInStatus } from "../../authentication/useLoggedInStatus";
import { useErrorHandler } from "../../shared/hooks/useErrorHandler";
import FullPageSpinner from "../../shared/spinners/FullPageSpinner";
import ProjectInvitationDetails from "./ProjectInvitationDetails";
import { useInvite } from "./useInvite";

function AcceptProjectInvitation(): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const [inviteDetails, setInviteDetails] = useState<
    ApiProjectInvitation | undefined
  >(undefined);
  const { isDynamicFeatureActive } = useUISettings();
  const guestUsersDisabled = isDynamicFeatureActive(
    dynamicFeatureFlags.GUEST_USERS_DISABLED
  );
  const { isLoggedOut, isLoggedInAsUser } = useLoggedInStatus();
  const { isLoggingIn } = useAuthenticatingStatus();
  const { acceptInvitationAsGuest, acceptInvitationAsUser } = useInvite();
  const { redirectToErrorPage } = useErrorHandler();

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const token = queryParams.get("token") ?? undefined;
  const tenant = queryParams.get("tenant") ?? undefined;

  const normalizedToken = useMemo(() => fixInvalidToken(token), [token]);

  useEffect(() => {
    const getInviteDetails = async (token: string) => {
      try {
        const details = await getProjectInvitationDetails(token);
        setInviteDetails(details);
      } catch (e: unknown) {
        redirectToErrorPage(e);
      }
    };

    if (normalizedToken) {
      getInviteDetails(normalizedToken);
    } else {
      history.replace(routes.authenticate);
    }
  }, [history, normalizedToken, redirectToErrorPage]);

  const isUserOnlyInvite =
    inviteDetails?.GuestPermission === projectPermissions.noPermission ||
    guestUsersDisabled;

  const inviteAutoAcceptable =
    /**
     * It is important that we wait for the details to be available rather
     * than using the optional operator (?) here because it can give us
     * hard-to-notice false positives when `inviteDetails === undefined`.
     */
    inviteDetails &&
    !inviteDetails.IsPasswordProtected &&
    ((isUserOnlyInvite && isLoggedInAsUser) ||
      !inviteDetails.IsGuestIdentificationRequired);

  const authRoute = `${routes.authenticate}/${tenant ?? ""}`;
  const loginLink = `${authRoute}?redirect=${encodeURIComponent(
    pathname + "?token=" + normalizedToken
  )}`;

  /**
   * Auto-accept invitation if possible.
   */
  useEffect(() => {
    if (inviteAutoAcceptable && normalizedToken) {
      if (isLoggedInAsUser) {
        acceptInvitationAsUser({
          Token: normalizedToken,
          Password: null,
        });
      } else if (isLoggedOut) {
        acceptInvitationAsGuest({
          Token: normalizedToken,
          Password: null,
          Name: "",
          Email: "",
          ReceiveNews: false,
        });
      }
    }
  }, [
    acceptInvitationAsGuest,
    acceptInvitationAsUser,
    inviteAutoAcceptable,
    isLoggedInAsUser,
    isLoggedOut,
    isUserOnlyInvite,
    normalizedToken,
  ]);

  if (!normalizedToken) {
    return <Redirect to={routes.authenticate} />;
  }

  if (!inviteDetails || inviteAutoAcceptable || isLoggingIn) {
    return <FullPageSpinner />;
  }

  if (isUserOnlyInvite && !isLoggedInAsUser) {
    return (
      <Flex sx={wrapperStyle}>
        <Flex sx={contentStyle}>
          <Text sx={headingStyle}>{t("invite.noGuestsAllowed")}</Text>
          <Link to={loginLink} sx={buttonStyle}>
            {t("form.button.login")}
          </Link>
        </Flex>
      </Flex>
    );
  }

  return (
    <ProjectInvitationDetails
      inviteDetails={inviteDetails}
      loginLink={loginLink}
      token={normalizedToken}
      tenant={tenant}
    />
  );
}

export default AcceptProjectInvitation;

const wrapperStyle: ThemeUIStyleObject = {
  backgroundColor: "primary",
  height: "100vh",
  width: "100vw",
  alignItems: "center",
  justifyContent: "center",
  ...(isDefaultCustomer()
    ? {
        backgroundImage: "url('/cb-logo-bg.svg')",
        backgroundPosition: "0 20%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "calc(var(--gridTile) * 10)",
      }
    : {}),
};

const contentStyle: ThemeUIStyleObject = {
  flexDirection: "column",
  textAlign: "center",
  alignItems: "center",
  width: "400px",
};

const headingStyle: ThemeUIStyleObject = {
  mb: [4],
};

/** @TODO #7118 - Do we need all these styles? */
const buttonStyle: ThemeUIStyleObject = {
  backgroundColor: "delicate",
  color: "text",
  p: [4],
  fontSize: [4],
  fontWeight: 700,
  textDecoration: "none",
  my: [2],
  "&:hover": {
    backgroundColor: "secondary",
  },
  width: "calc(var(--gridTile) * 10)",
  "&:visited": {
    color: "text",
  },
};
