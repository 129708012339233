export const byDateFlex = {
  description: [4, null, 4],
  owner: [3, null, 3],
  tags: [4, null, 4],
  actions: [3, null, 2],
};

export const byUserFlex = {
  owner: [1, null, 3],
  email: [1, null, 4],
  registration: [1, null, 3],
  lastActivity: [1, null, 3],
  actions: [0.2, null, 0.6],
};
