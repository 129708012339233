import { ErrorMessage, useField } from "formik";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import {
  Alert,
  Box,
  Flex,
  IconButton,
  Input,
  Label,
  SxStyleProp,
  Text,
  ThemeUIStyleObject,
} from "theme-ui";
import PasswordHidden from "../../../../icons/PasswordHidden";
import PasswordVisible from "../../../../icons/PasswordVisible";
import {
  maxPasswordLength,
  minPasswordLength,
} from "../../../../validationSchemas/fieldRestrictions";
import {
  passwordHasLength,
  passwordHasLowercaseChar,
  passwordHasNoWhitespace,
  passwordHasNumber,
  passwordHasSpecialChar,
  passwordHasUppercaseChar,
  passwordSpecialChars,
} from "../../../../validationSchemas/password";
import { useFieldError } from "./useFieldError";

type Props = React.PropsWithChildren<{
  info?: string;
  inputWrapperSx?: SxStyleProp;
  label?: string;
  name: string;
  noValidation?: boolean;
  placeholder?: string;
  required?: boolean;
  sx?: SxStyleProp;
  variant?: string;
  autoComplete?: string;
}>;

function PasswordField({
  children,
  placeholder,
  noValidation = false,
  variant = "label",
  required = true,
  label,
  info,
  inputWrapperSx,
  sx,
  name,
  autoComplete,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [isPasswordVisible, setPasswordVisibility] = useState(false);

  const [field, meta] = useField<string>({ name });
  const { value } = field;
  const { touched, error } = meta;
  const { errorInputSx, fieldRef } = useFieldError({ meta, name });

  const [checkLength, { data: isValidLength }] = useMutation((str: string) =>
    passwordHasLength.isValid(str)
  );
  const [
    checkLowercaseChar,
    { data: hasLowercaseChar },
  ] = useMutation((str: string) => passwordHasLowercaseChar.isValid(str));
  const [
    checkUppercaseChar,
    { data: hasUppercaseChar },
  ] = useMutation((str: string) => passwordHasUppercaseChar.isValid(str));
  const [
    checkNoWhitespace,
    { data: hasNoWhitespace },
  ] = useMutation((str: string) => passwordHasNoWhitespace.isValid(str));
  const [
    checkSpecialChar,
    { data: hasSpecialChar },
  ] = useMutation((str: string) => passwordHasSpecialChar.isValid(str));
  const [checkNumber, { data: hasNumber }] = useMutation((str: string) =>
    passwordHasNumber.isValid(str)
  );

  useEffect(() => {
    if (!noValidation) {
      checkLength(value);
      checkLowercaseChar(value);
      checkUppercaseChar(value);
      checkSpecialChar(value);
      checkNumber(value);
      checkNoWhitespace(value);
    }
    // MdB Not sure if disabling this is correct but the code seems to be working
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const togglePasswordVisibility = (e: React.MouseEvent) => {
    setPasswordVisibility(!isPasswordVisible);
    e.preventDefault();
  };

  const getColor = useCallback(
    (isValid) => {
      if (isValid) {
        return "primary";
      }

      return touched ? "warning" : "muted";
    },
    [touched]
  );

  return (
    <>
      <Label
        variant={required ? `${variant}.required` : `${variant}`}
        sx={sx}
        aria-label={name}
      >
        {(label || info) && (
          <Box sx={{ mb: [1] }}>
            {label && (
              <Text sx={{ fontWeight: 600, mb: [1] }}>
                {label} {required ? `(${t("form.required")})` : ""}
              </Text>
            )}
            {info && <Text sx={{ mb: [1] }}>{info}</Text>}
          </Box>
        )}

        <Box sx={{ position: "relative", width: "100%", ...inputWrapperSx }}>
          <Input
            type={isPasswordVisible ? "text" : "password"}
            placeholder={placeholder || t(`form.placeholder.${name}`)}
            sx={{
              ...passwordInputStyle,
              ...errorInputSx,
            }}
            ref={fieldRef}
            {...field}
            {...meta}
            autoComplete={autoComplete}
            minLength={noValidation ? undefined : minPasswordLength}
            maxLength={noValidation ? undefined : maxPasswordLength}
          />
          <IconButton
            onClick={togglePasswordVisibility}
            variant="buttons.icon.togglePasswordVisibility"
          >
            {isPasswordVisible ? (
              <PasswordVisible noShadow />
            ) : (
              <PasswordHidden noShadow />
            )}
          </IconButton>
        </Box>

        {noValidation && (
          <ErrorMessage
            name={name}
            component={({ children: kids }) => (
              <Alert variant="primary" sx={{ flex: 1 }}>
                {kids}
              </Alert>
            )}
          />
        )}

        {!noValidation && error && touched && (
          <Alert variant="primary" sx={{ flex: 1 }}>
            {t("clientError.passwordInvalid")}
          </Alert>
        )}

        {children}
      </Label>

      {!noValidation && (
        <Flex
          sx={{
            mb: [3],
            width: ["100%"],
            flexDirection: ["column", "row", "row", "column", "row"],
          }}
        >
          <Box as="ul" variant="layout.passwordValidationParamsColumn">
            <Text
              as="li"
              sx={{
                color: getColor(isValidLength),
              }}
            >
              {t("clientError.passwordInvalidLength", {
                max: maxPasswordLength,
                min: minPasswordLength,
              })}
            </Text>
            <Text
              as="li"
              sx={{
                color: getColor(hasLowercaseChar),
              }}
              title="a - z"
            >
              {t("clientError.passwordInvalidLowercase")}
            </Text>
            <Text
              as="li"
              sx={{
                color: getColor(hasUppercaseChar),
              }}
              title="A - Z"
            >
              {t("clientError.passwordInvalidUppercase")}
            </Text>
          </Box>
          <Box as="ul" variant="layout.passwordValidationParamsColumn">
            <Text
              as="li"
              sx={{
                color: getColor(hasNumber),
              }}
              title="0 - 9"
            >
              {t("clientError.passwordInvalidNumber")}
            </Text>
            <Text
              as="li"
              sx={{
                color: getColor(hasSpecialChar),
              }}
              title={passwordSpecialChars}
            >
              {t("clientError.passwordInvalidSpecialChar")}
            </Text>
            <Text
              as="li"
              sx={{
                // Don't show 'valid' color until the user has entered a value
                color: touched || value ? getColor(hasNoWhitespace) : "muted",
              }}
              title={t("clientError.passwordInvalidWhitespace")}
            >
              {t("clientError.passwordInvalidWhitespace")}
            </Text>
          </Box>
        </Flex>
      )}
    </>
  );
}

const passwordInputStyle: ThemeUIStyleObject = {
  "&::-ms-reveal, &::-ms-clear": {
    display: "none",
  },
};

export default PasswordField;
