import React, { ReactElement, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { verifyUser } from "../../../api";
import { routes } from "../../../const";
import { getAppName } from "../../../tools/customer";
import { LogCategory } from "../../../tools/telemetry";
import VerifyEmail from "../../authentication/registration/VerifyEmail";
import { useErrorHandler } from "../../shared/hooks/useErrorHandler";
import Page from "../layout/pages/Page";

type DecodedToken = {
  User: string;
  Code: string;
};

function VerifyEmailRoute(): ReactElement {
  const history = useHistory();
  const { t } = useTranslation();
  const { fallbackErrorHandler } = useErrorHandler();

  const { search } = useLocation();
  const decodedToken = useMemo(() => {
    const queryParams = new URLSearchParams(search);
    const token = queryParams.get("token") ?? undefined;

    if (!token) {
      return undefined;
    }

    try {
      return JSON.parse(atob(token)) as DecodedToken;
    } catch {
      return undefined;
    }
  }, [search]);

  useEffect(() => {
    const verifyFromToken = async (token: DecodedToken) => {
      try {
        await verifyUser(token.User, token.Code);
        toast(
          t("form.header.verificationSuccessful", { appName: getAppName() })
        );
        history.push(routes.authenticate);
      } catch (error: unknown) {
        fallbackErrorHandler(error, "Failed to verify user", LogCategory.auth);
      }
    };

    if (decodedToken) {
      verifyFromToken(decodedToken);
    }
  }, [decodedToken, fallbackErrorHandler, history, t]);

  return (
    <Page>
      <VerifyEmail />
    </Page>
  );
}

export default VerifyEmailRoute;
