import { fabric } from "fabric";

fabric.util.object.extend(fabric.StickyNote.prototype, {
  _renderShallowStickyNote(
    this: fabric.StickyNote,
    ctx: CanvasRenderingContext2D
  ) {
    if (!this.canvas) {
      return;
    }

    const { x: w, y: h } = this._getNonTransformedDimensions();
    const x = -w / 2;
    const y = -h / 2;
    const x2 = x + w;
    const y2 = y + h;
    const { fillColor } = this;

    ctx.save();

    ctx.beginPath();
    ctx.moveTo(x, y);
    // Rough edges without rounded corners
    ctx.lineTo(x2, y);
    ctx.lineTo(x2, y + h);
    ctx.lineTo(x, y2);
    ctx.lineTo(x, y);

    // Disable gradient
    ctx.fillStyle = fillColor;

    ctx.fill();
    ctx.restore();
  },

  _renderFullStickyNote(
    this: fabric.StickyNote,
    ctx: CanvasRenderingContext2D
  ) {
    if (!this.canvas || !this.canvas.viewportTransform) {
      return;
    }

    const {
      enableObjectGradients,
      isViewOnlyMode,
      viewportTransform,
    } = this.canvas;
    const { x: w, y: h } = this._getNonTransformedDimensions();
    const [zoomX] = viewportTransform;
    const x = -w / 2;
    const y = -h / 2;
    const x2 = x + w;
    const y2 = y + h;
    const cornerRadius = 5;
    const { gradient, fillColor } = this;

    ctx.save();

    ctx.shadowColor = isViewOnlyMode ? "" : "rgba(0,0,0,0.15)";
    ctx.shadowBlur = 6;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 6 * zoomX;
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x2, y);
    ctx.lineTo(x2, y + h - cornerRadius);
    ctx.quadraticCurveTo(x2, y2, x2 - cornerRadius, y2);
    ctx.lineTo(x + cornerRadius, y2);
    ctx.quadraticCurveTo(x, y2, x, y2 - cornerRadius);
    ctx.lineTo(x, y);
    ctx.fillStyle = enableObjectGradients && gradient ? gradient : fillColor;
    ctx.fill();
    ctx.restore();
  },
});
