import React, { ReactElement } from "react";

function Tagline(): ReactElement {
  return (
    <svg viewBox="0 0 1000 400">
      <g transform="translate(-500, 520) scale(0.1, -0.1)">
        <path
          d="M9025 4886 c-70 -30 -115 -115 -100 -188 18 -87 85 -139 180 -139 87
0 153 49 175 131 20 75 -25 166 -97 196 -41 17 -119 17 -158 0z"
        />
        <path
          d="M6075 4855 c-271 -50 -471 -217 -551 -461 -27 -82 -29 -100 -29 -239
0 -169 13 -233 71 -350 69 -141 187 -240 354 -297 92 -31 100 -32 265 -33 149
0 180 3 252 24 90 25 220 87 236 112 7 11 -9 48 -63 147 -40 73 -76 131 -79
129 -139 -74 -183 -90 -271 -95 -75 -4 -101 -1 -162 19 -61 19 -82 32 -129 79
-78 77 -102 142 -103 275 0 132 24 197 103 276 118 117 317 142 502 62 33 -14
61 -24 63 -22 2 2 36 66 75 142 l73 137 -54 24 c-175 78 -378 104 -553 71z"
        />
        <path d="M7910 4170 l0 -680 165 0 165 0 0 680 0 680 -165 0 -165 0 0 -680z" />
        <path d="M8410 4170 l1 -680 164 0 165 0 0 680 0 680 -165 0 -165 0 0 -680z" />

        <path
          d="M7122 4424 c-126 -34 -245 -121 -305 -225 -42 -72 -69 -191 -63 -276
20 -273 228 -463 508 -463 292 0 508 211 508 498 0 86 -34 200 -79 266 -69
100 -190 180 -309 205 -72 15 -196 13 -260 -5z m228 -276 c26 -14 57 -41 75
-66 28 -41 30 -50 30 -130 0 -74 -4 -93 -24 -125 -92 -148 -309 -117 -357 52
-56 191 110 352 276 269z"
        />

        <path
          d="M9901 4419 c-29 -12 -70 -38 -91 -59 l-38 -38 -7 44 -6 44 -160 0
-159 0 0 -460 0 -460 164 0 165 0 3 283 c3 247 5 286 21 314 44 81 167 86 213
10 17 -29 19 -57 22 -319 l3 -288 160 0 160 0 -3 353 c-3 336 -4 354 -25 407
-30 74 -75 125 -137 157 -72 37 -206 43 -285 12z"
        />

        <path
          d="M10752 4425 c-105 -30 -167 -73 -208 -147 -26 -47 -29 -62 -29 -138
1 -99 20 -145 84 -203 53 -48 75 -58 213 -101 121 -38 148 -54 148 -86 0 -53
-131 -67 -275 -30 -43 11 -79 20 -81 20 -1 0 -26 -43 -55 -96 -58 -108 -59
-100 31 -135 169 -64 422 -64 548 0 106 54 152 130 152 252 0 161 -88 250
-307 309 -131 35 -171 72 -124 114 36 32 199 18 304 -26 26 -12 50 -18 53 -15
8 8 35 210 30 224 -3 6 -42 24 -88 39 -104 33 -309 43 -396 19z"
        />

        <path d="M8940 3950 l0 -460 165 0 164 0 1 460 0 460 -165 0 -165 0 0 -460z" />

        <path
          d="M5891 3098 c-5 -13 -124 -317 -265 -676 -141 -359 -256 -655 -256
-657 0 -3 80 -5 178 -5 l177 0 51 153 50 152 249 0 248 0 52 -150 53 -150 190
-3 190 -2 -52 132 c-28 73 -148 379 -266 680 l-215 548 -188 0 c-185 0 -188 0
-196 -22z m263 -525 l75 -223 -154 0 -153 0 75 226 c41 124 76 224 78 222 2
-2 37 -103 79 -225z"
        />
        <path
          d="M7168 2691 c-227 -73 -358 -298 -320 -548 30 -191 140 -318 330 -381
67 -22 95 -25 217 -26 117 -1 153 3 218 22 87 25 157 57 157 72 0 5 -20 53
-45 106 -41 86 -48 96 -68 91 -12 -3 -49 -13 -82 -22 -81 -21 -217 -22 -286
-1 -66 19 -133 65 -154 106 l-16 30 324 0 325 0 6 31 c3 17 6 58 6 90 0 217
-120 383 -315 435 -73 20 -228 17 -297 -5z m242 -220 c41 -21 86 -82 95 -128
l7 -33 -196 0 -195 0 8 28 c29 101 93 152 191 152 35 0 68 -7 90 -19z"
        />
        <path
          d="M8973 2681 c-136 -47 -223 -125 -282 -252 -67 -141 -57 -326 23 -464
30 -50 116 -133 177 -168 74 -44 198 -71 294 -64 141 10 236 49 327 135 100
94 148 207 148 351 0 293 -208 491 -515 491 -73 0 -105 -6 -172 -29z m255
-257 c48 -20 90 -60 109 -104 20 -49 18 -151 -6 -204 -63 -144 -279 -146 -352
-5 -24 48 -28 159 -6 210 41 97 157 144 255 103z"
        />
        <path
          d="M10015 2696 c-109 -29 -168 -70 -210 -147 -26 -46 -30 -64 -30 -129
0 -170 79 -252 306 -315 52 -15 104 -33 117 -41 31 -20 27 -64 -8 -81 -37 -19
-144 -16 -239 7 -47 11 -88 17 -92 13 -4 -5 -28 -47 -54 -95 l-46 -87 23 -15
c38 -24 138 -54 227 -67 164 -23 344 7 433 74 118 88 136 288 38 400 -48 54
-111 88 -225 122 -49 14 -103 31 -118 37 -54 21 -60 83 -9 94 15 3 33 7 40 10
25 7 166 -18 231 -41 36 -13 67 -22 69 -20 2 2 11 52 19 111 18 126 21 121
-87 155 -84 27 -309 36 -385 15z"
        />
        <path
          d="M11134 2690 c-28 -11 -69 -36 -91 -55 -41 -35 -54 -42 -52 -27 1 4 1
24 0 45 l-1 37 -150 0 -150 0 0 -665 0 -665 160 0 160 0 0 234 0 235 51 -35
c152 -102 346 -78 484 60 157 157 189 458 69 662 -35 60 -115 131 -182 162
-79 36 -222 42 -298 12z m124 -284 c36 -19 78 -65 91 -99 18 -47 13 -143 -9
-186 -53 -106 -188 -132 -278 -55 -98 84 -85 270 23 331 47 27 131 31 173 9z"
        />
        <path
          d="M12083 2691 c-135 -49 -226 -152 -274 -310 -19 -63 -21 -89 -17 -190
7 -158 46 -258 133 -345 137 -137 375 -153 509 -34 l46 40 0 -46 0 -46 155 0
155 0 -2 463 -3 462 -150 0 -150 0 -3 -48 -3 -48 -50 45 c-27 24 -70 51 -96
60 -64 22 -184 20 -250 -3z m296 -286 c92 -47 127 -181 76 -288 -40 -85 -157
-121 -245 -75 -133 70 -130 305 5 364 40 18 129 17 164 -1z"
        />
        <path
          d="M13291 2694 c-248 -67 -393 -299 -352 -565 23 -145 113 -279 226
-337 111 -56 277 -75 410 -48 79 16 185 58 185 73 0 4 -25 58 -56 120 -55 107
-58 111 -82 102 -13 -5 -57 -12 -96 -16 -167 -14 -267 62 -267 203 0 91 46
155 134 184 51 16 161 14 222 -5 27 -8 50 -13 51 -12 0 1 10 62 21 135 23 153
28 140 -73 167 -74 19 -250 19 -323 -1z"
        />
        <path
          d="M14163 2695 c-209 -57 -343 -239 -343 -467 0 -178 75 -323 210 -410
152 -97 434 -114 632 -37 100 38 99 34 43 150 -42 84 -53 100 -70 96 -11 -3
-47 -12 -80 -21 -34 -9 -99 -16 -150 -17 -140 0 -232 36 -283 111 l-23 35 325
3 325 2 7 46 c7 50 -1 159 -17 219 -52 194 -211 306 -432 304 -51 0 -116 -7
-144 -14z m227 -225 c40 -20 73 -59 84 -97 3 -13 9 -32 12 -43 5 -20 1 -20
-190 -20 -108 0 -196 2 -196 4 0 2 7 23 15 47 18 51 57 96 100 115 45 20 129
17 175 -6z"
        />
        <path
          d="M8395 2688 c-46 -17 -84 -47 -116 -91 l-29 -40 0 67 0 67 -157 -3
-158 -3 -3 -462 -2 -463 164 0 165 0 3 253 c3 247 3 253 28 297 36 65 94 93
175 86 33 -3 69 -8 81 -12 20 -5 21 0 32 143 7 95 7 152 1 158 -14 14 -149 16
-184 3z"
        />
      </g>
    </svg>
  );
}
export default Tagline;
