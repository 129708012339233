import React, { ReactElement } from "react";
import { Link as Anchor } from "react-router-dom";
import { Box, Flex, Link, SxStyleProp, ThemeUIStyleObject } from "theme-ui";
import { routes } from "../../../../const";

type Props = {
  projectId: string;
  thumbnail: string;
  customStyles?: SxStyleProp;
  boxCustomStyes?: SxStyleProp;
};

function Thumbnail({
  projectId,
  thumbnail,
  customStyles,
  boxCustomStyes,
}: Props): ReactElement {
  const projectRoute = `${routes.collaboard}/${projectId}`;

  const anchorProps = { to: projectRoute };
  return (
    <Flex
      sx={{
        ...containerStyle,
        ...customStyles,
      }}
    >
      <Box
        sx={{
          ...boxStyle,
          ...boxCustomStyes,
        }}
      >
        <Link
          as={Anchor}
          {...anchorProps}
          sx={linkStyle}
          style={{
            backgroundImage: `url(data:image/png;base64,${thumbnail})`,
          }}
        ></Link>
      </Box>
    </Flex>
  );
}

const containerStyle: ThemeUIStyleObject = {
  flexDirection: "row",
  width: "100%",
  flex: [null, null, null, 1],
  height: "300px",
};

const boxStyle: ThemeUIStyleObject = {
  backgroundImage: "linear-gradient(45deg, #fff, rgba(255, 255, 255, 0))",
  border: "1px solid",
  borderColor: "muted",
  width: "100%",
  position: "relative",
};

const linkStyle: ThemeUIStyleObject = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "50% 50%",
};

export default Thumbnail;
