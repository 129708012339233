import {
  allPlans,
  maxFileSizePerProductName,
  PlanName,
  ProductTypeIDs,
} from "../../const";
import { useUISettings } from "../../reduxStore/uiSettings";
import { dynamicFeatureFlags } from "../../tools/flags";
import { useLicenseInfo } from "./useLicenseInfo";

export const noLimitMaximumFileSize =
  maxFileSizePerProductName[allPlans.noLimit.name];

type UseActivePlanResult = {
  isOnFreePlan: boolean;
  hasFacilitatorMode: boolean;
  maxFileSize: number;
};

const getLicensePlanName = (
  productId: number | undefined,
  isLicenseActive: boolean | undefined,
  isLicenseDataFetched: boolean,
  isOnNoLimitPlan: boolean
): PlanName | null => {
  if (!isLicenseDataFetched) {
    return null;
  }

  if (isOnNoLimitPlan) {
    return allPlans.noLimit.name;
  }

  if (!isLicenseActive) {
    return allPlans.free.name;
  }

  const planName =
    Object.values(allPlans).find((plan) => plan.productTypeId === productId)
      ?.name ?? allPlans.free.name;

  return planName;
};

export const useActivePlan = (): UseActivePlanResult => {
  const {
    productId,
    isLicenseActive,
    isSuccess: isLicenseDataFetched,
  } = useLicenseInfo();
  const { isDynamicFeatureActive } = useUISettings();

  const enableAllActivePlanFeatures = isDynamicFeatureActive(
    dynamicFeatureFlags.ENABLE_ALL_ACTIVE_PLAN_FEATURES
  );
  const isOnNoLimitPlan = !isDynamicFeatureActive(
    dynamicFeatureFlags.LICENSING
  );

  // isLicenseActive is compared against undefined when the license isn't loaded
  // Default to free plan as that is the most restrictive
  // TODO: duplicated in usePlanDetails
  const isOnFreePlan = Boolean(
    !isOnNoLimitPlan &&
      (typeof isLicenseActive !== "boolean" || !isLicenseActive)
  );

  const activePlanName = getLicensePlanName(
    productId,
    isLicenseActive,
    isLicenseDataFetched,
    isOnNoLimitPlan
  );

  const isFacilitatorPlan =
    isOnNoLimitPlan || (productId && productId >= ProductTypeIDs.business);

  const hasFacilitatorMode = isFacilitatorPlan || enableAllActivePlanFeatures;

  const maxFileSize =
    maxFileSizePerProductName[activePlanName || allPlans.free.name] ||
    maxFileSizePerProductName["free"];

  return { isOnFreePlan, hasFacilitatorMode, maxFileSize };
};
