type DetectOSResult = {
  mac: boolean;
  windows: boolean;
  linux: boolean;
  iOS: boolean;
  android: boolean;
};

/**
 * Attempt to detect which OS the user is running.
 *
 * @param {window.Navigator} navigator Navigator object from browser
 * @returns {object} Object containing booleans for major platforms
 */
export const detectOS = (navigator: Navigator): DetectOSResult => {
  const { platform, userAgent } = navigator;

  const macOSPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
  const iOSPlatforms = [
    "iPhone",
    "iPad",
    "iPod",
    "iPhone Simulator",
    "iPad Simulator",
    "iPod Simulator",
  ];

  const testAndroid = /Android/i;
  const testLinux = /Linux/i;

  const isAndroid = testAndroid.test(platform) || testAndroid.test(userAgent);
  const isLinux = testLinux.test(platform) || testLinux.test(userAgent);

  return {
    // Desktop
    mac: macOSPlatforms.includes(platform),
    windows: windowsPlatforms.includes(platform),
    linux: isLinux && !isAndroid,
    // Mobile
    iOS: iOSPlatforms.includes(platform),
    android: isAndroid,
  };
};

export const detectedOS = detectOS(window.navigator);

export const isInUWP = (): boolean =>
  /MSAppHost/i.test(window.navigator.userAgent);

export const isInElectron = (): boolean =>
  /Electron/i.test(window.navigator.userAgent);

export const isInAndroid = (): boolean =>
  /Android/i.test(window.navigator.userAgent);

export const isInIOS = (): boolean => {
  const { userAgent } = window.navigator;
  return (
    /iPhone/i.test(userAgent) ||
    /iPad/i.test(userAgent) ||
    /iPod/i.test(userAgent)
  );
};

export const applicationType = (): string => {
  if (isInUWP()) {
    return "uwp";
  } else if (isInElectron()) {
    return "electron";
  } else if (isInAndroid()) {
    return "android";
  } else if (isInIOS()) {
    return "ios";
  } else {
    return "web";
  }
};
