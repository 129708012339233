import { Form, Formik, FormikConfig } from "formik";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Box, Flex, Text } from "theme-ui";
import { array, boolean, number, object, string } from "yup";
import { inviteSpaceParticipant } from "../../../api";
import { errorCodeIds } from "../../../api/errorCodes";
import { routes } from "../../../const";
import Envelope from "../../../icons/Envelope";
import { getAppName } from "../../../tools/customer";
import { isAPIError, isError } from "../../../tools/errors";
import { errorToast } from "../../../tools/errorToast";
import { LogCategory } from "../../../tools/telemetry";
import { stringifyQueryUrl } from "../../../tools/url";
import { ApiPermission } from "../../../types/enum";
import BasicDropdownField from "../../shared/forms/fields/BasicDropdownField";
import EmailInvitesField from "../../shared/forms/fields/EmailInvitesField";
import { MultiInputFieldItem } from "../../shared/forms/fields/MultiInputField/useMultiInputField";
import TextareaField from "../../shared/forms/fields/TextareaField";
import Modal from "../../shared/modals";
import ConfirmationButtons from "../../shared/modals/ConfirmationButtons";
import { useTrackEventToLog } from "../../shared/tracking/useTrackEventToLog";
import { spacePermissions } from "../consts";

const isStringWithValue = (value: string | undefined): value is string => {
  return !!value && typeof value === "string";
};

type Props = {
  spaceId: number;
  setIsExpanded: (expanded: boolean) => void;
  onClose: () => void;
  onInvitesSent: (isPartial: boolean) => void;
};

type InvitationsForm = {
  Users: MultiInputFieldItem<ApiSearchUserInfo>[];
  Permission: ApiPermission;
  Notes: string;
};

type InvitationAPIError = {
  LastInvitedUser: string;
  ErrorCode: errorCodeIds;
};

function InviteToSpaceModalTemplate({
  spaceId,
  setIsExpanded,
  onClose,
  onInvitesSent,
}: Props): ReactElement {
  const { trackEvent } = useTrackEventToLog();
  const { t } = useTranslation();

  const formikProps: FormikConfig<InvitationsForm> = {
    initialValues: {
      Users: [],
      Permission: ApiPermission.readPermission,
      Notes: "",
    },
    validationSchema: object().shape({
      Users: array()
        .of(
          object().shape({
            isValid: boolean().oneOf([true]),
          })
        )
        .min(1)
        .required(t("clientError.required")),
      Permission: number().required(), // Cannot have invalid values
      Notes: string(),
    }),
    onSubmit: async (values, actions) => {
      // no `metadata` means user not found. In which case, use field value instead
      const Emails = values.Users.map(
        (e) => e.metadata?.Email ?? e.value
      ).filter(isStringWithValue);

      const invitationQs = stringifyQueryUrl<SpaceInvitationUrlQuery>({
        spaceId,
      });

      trackEvent(LogCategory.space, {
        subcategory: "space-invite-send",
        ...values,
        Users: Emails,
      });
      try {
        await inviteSpaceParticipant({
          SpaceId: spaceId,
          Users: Emails,
          ProjectPermission: values.Permission,
          SpacePermission: ApiPermission.readPermission,
          InvitationUrl: `${window.origin}${routes.spaces}${invitationQs}`,
          Notes: values.Notes,
        });

        actions.resetForm();
        onInvitesSent(false);
        onClose();
      } catch (error) {
        if (!isError(error)) {
          errorToast(t("clientError.unknownError"));
          return;
        }

        if (!isAPIError<InvitationAPIError>(error)) {
          errorToast(error.message);
          return;
        }

        const { LastInvitedUser, ErrorCode } = error.response?.data ?? {};

        if (ErrorCode === errorCodeIds.ErrorSendingMessage) {
          // Although the email invite failed, the users are still added successfully
          // to the space and they don't strictly need the email to access the space
          onInvitesSent(false);
          errorToast(error.details.errorMessage);
          return;
        }

        if (LastInvitedUser === undefined) {
          errorToast(error.details.errorMessage);
          return;
        }

        const lastValidIndex = Emails.findIndex(
          (user) => user === LastInvitedUser
        );
        const validEmails =
          lastValidIndex !== -1 ? Emails.slice(0, lastValidIndex + 1) : [];
        const invalidUsers =
          lastValidIndex !== -1
            ? values.Users.slice(lastValidIndex + 1)
            : values.Users;

        if (invalidUsers.length) {
          // The error always refers to the first invalid user
          invalidUsers[0] = { ...invalidUsers[0], isValid: false };
          actions.setFieldValue("Users", invalidUsers);
        }

        if (validEmails.length) {
          toast(
            t("dialog.share.invitesSentSuccessfullyTo", {
              count: validEmails.length,
              users: validEmails.join(", "),
            })
          );
          onInvitesSent(true);
        }

        errorToast(error.details.errorMessage);
      }
    },
  };

  return (
    <Formik {...formikProps}>
      {({ isValid, submitForm, dirty }) => (
        <Modal
          header={
            <>
              <Envelope />
              <span>{t("dialog.share.invitePeople")}</span>
            </>
          }
          actionButtons={
            <ConfirmationButtons
              onConfirm={submitForm}
              onClose={onClose}
              confirmationLabel={t("dialog.share.sendInvites")}
              confirmationDisabled={!isValid || !dirty}
              closeOnConfirm={false}
            />
          }
          isOpen
          onClose={onClose}
        >
          <Text sx={{ mb: [4] }}>
            {t("dialog.share.invitePremiumPeopleInfo", {
              appName: getAppName(),
            })}
          </Text>
          <Form>
            <Flex sx={{ flexWrap: "wrap", width: "100%" }}>
              <EmailInvitesField
                data-cy="space-user-email-invite"
                name="Users"
                isExpanded
                setIsExpanded={setIsExpanded}
              />
              <Box sx={{ ml: [null, null, 5] }}>
                <BasicDropdownField
                  placeholder={t("dialog.share.permissionLabel")}
                  name="Permission"
                  items={spacePermissions.map(({ labelKey, value }) => ({
                    label: t(labelKey),
                    value,
                  }))}
                  onChange={() => null}
                />
              </Box>
            </Flex>

            <TextareaField
              placeholder={t("dialog.share.notePlaceholder")}
              name="Notes"
              sx={{ mt: [4] }}
              textareaSx={{ height: "12vh" }}
            />
          </Form>
        </Modal>
      )}
    </Formik>
  );
}

export default InviteToSpaceModalTemplate;
