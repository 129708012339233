import { fabric } from "fabric";
import {
  canvasObjectIds,
  defaultShapeContext,
  fontSizes,
  maxShapeFontSize,
} from "../../../const";

import { isString } from "../../../tools/text";
import { getDimensionPropsToSet } from "../../utils/dimensionsProps";
import { calcTransformState } from "../../utils/fabricObjects";
import { LiveColor } from "../color/liveColor";
import "../object/collaboardObjectCustom.clickable_urls_mixin";
import "../object/collaboardObjectCustom.editing_mixin";

(function () {
  fabric.ShapeInnerTextBox = fabric.util.createClass(
    fabric.Textbox,
    fabric.CustomEditing,
    fabric.CollaboardClickableURLs, // TODO: #6554 (LINKS_V2) remove
    {
      type: canvasObjectIds.shapeInnerTextBox,
      fontFamily: defaultShapeContext.fontFamily,
      fontSize: defaultShapeContext.fontSize,
      maxFontSize: maxShapeFontSize,
      fontStyle: "normal",
      fontWeight: "normal",
      underline: false,
      // Fabric supports: "left", "center", "right", "justify", "justify-left", "justify-center" or "justify-right"
      // API supports: "none", "left", "center", "right"
      textAlign: defaultShapeContext.textAlign,
      textPlacement: defaultShapeContext.textPlacement,

      stroke: "transparent",

      scaleX: 1,
      scaleY: 1,
      // don't change originX/originY
      // https://ibvsolutions.visualstudio.com/CollaBoardWeb/_wiki/wikis/CollaBoardWeb.wiki/364/Coordinate-system
      originX: "left",
      originY: "top",
      objectCaching: false,
      lockUniScaling: false,

      splitByGrapheme: true,

      padding: 15,
      internalPadding: 0,

      hasBorders: false,
      hasControls: false,
      hasRotatingPoint: false,
      allowAttachments: false,

      /**
       * Solid geometric objects should use `false`, while intricate objects with
       * lots of transparency (such as Text) should use `true`.
       */
      perPixelTargetFind: true,

      initialize(
        this: fabric.ShapeInnerTextBox,
        text: string,
        options: ShapeInnerTextConstructorConfig = {}
      ) {
        if (!options.fontSize && options.shape) {
          // Scale default fontSize based on the shape scale
          const { scaleX = 1, scaleY = 1 } = options.shape;
          const scale = Math.min(scaleX, scaleY);

          options.fontSize = Math.round(fontSizes[5] * scale);
        }

        this.callSuper("initialize", text, {
          ...options,
          fill: new LiveColor("#000000"),
          _textBeforeEdit: options.text || text,
        });

        this.initializeCollaboardObject();

        this.initializeClickableURLs && this.initializeClickableURLs(); // TODO: #6554 (LINKS_V2) remove
      },

      positionByParent(this: fabric.ShapeInnerTextBox, parent: fabric.Object) {
        const { height, scaleY = 1, internalPadding } = this.shape;

        /**
         * If it's not editing, the text is inside a fabric.Group so we position
         * relatively to the center of the group. Otherwise we apply the group's
         * translate transform to the standalone text object.
         */
        if (this.group === parent) {
          const offsetByPlacement: Record<TextPlacement, number> = {
            top: -height / 2 + internalPadding,
            center: 0,
            none: 0,
            bottom: height / 2 - internalPadding,
          };
          const topOffset = this.textPlacement
            ? offsetByPlacement[this.textPlacement]
            : 0;
          const position = new fabric.Point(0, topOffset);

          this.setPositionByOrigin(
            position,
            "center",
            this.textPlacement || "center"
          );
        } else {
          const offsetByPlacement: Record<TextPlacement, number> = {
            top: (-height / 2 + internalPadding) * (scaleY || 1),
            center: 0,
            none: 0,
            bottom: (height / 2 - internalPadding) * (scaleY || 1),
          };
          const topOffset = this.textPlacement
            ? offsetByPlacement[this.textPlacement]
            : 0;

          const transform = calcTransformState(parent);
          const position = new fabric.Point(
            transform.left,
            transform.top + topOffset
          );

          this.setPositionByOrigin(
            position,
            "center",
            this.textPlacement || "center"
          );
        }
        this.setCoords();
      },

      onInput(this: fabric.ShapeInnerTextBox, e: fabric.IEvent) {
        fabric.CustomEditing.onInput.call(this, e);
        this.positionByParent(this.shape);
      },

      /**
       * @NOTE this method can be called by other sources than the shape, e.g.
       * by CustomEditing when zooming
       */
      exitEditing(this: fabric.ShapeInnerTextBox) {
        if (!this.isEditing || !this.shape.canvas) {
          return;
        }

        const { canvas } = this.shape;
        canvas.removeSilently(this);
        // Restore the canvas property so that CustomEditing can do `performSync`
        this.canvas = canvas;

        /**
         * Restore text's initial tranform and properties changed by `_restoreObjectState`
         * during `enterEditMode`
         */
        this._originalTransform && this.set(this._originalTransform);
        this.group = this.shape;
        this.setCoords();
        delete this._originalTransform;

        this.shape.addSilently(this);

        // CustomEditing is a mixin, so it's not in the prototype as it's overriden
        // by this method itself
        fabric.CustomEditing.exitEditing.call(this);

        this.shape.exitEditMode();
        this.trigger("custom:transform:end");

        // if shape is part of group then select group, if shape is alone then select shape
        // TODO find better way to deal with this
        const isParentPartOfGroup = this.shape.isPartOfGroup();
        const groupToActivate = isParentPartOfGroup
          ? this.shape.group
          : this.shape;

        groupToActivate &&
          canvas.setActiveObject(groupToActivate, { isSilent: true });
        if (groupToActivate?.__subSelectedObject) {
          groupToActivate.activateSubselectionMode();
        }
      },

      setContextProps(
        this: fabric.ShapeInnerTextBox,
        {
          // Pull out the properties that require special handling
          fillColor = this.fillColor,
          textColor = isString(this.fill) ? this.fill : this.fill?.base,
          // _dimensionAffectingProps
          fontFamily,
          fontSize,
          fontStyle,
          fontWeight,
          textAlign,
          width,
          ...rest
        }: ContextProps,
        config?: SetContextPropsConfig
      ) {
        const dimensionPropsToSet = getDimensionPropsToSet(
          this,
          {
            fontFamily,
            fontSize,
            fontStyle,
            fontWeight,
            textAlign,
            width,
          },
          config
        );

        /**
         * Group changes into a single `set()` call because it does additional
         * work when changing properties that affect dimensions.
         */
        const propsToSet = {
          ...dimensionPropsToSet,
          ...rest,
          fill: textColor ? new LiveColor(textColor) : this.fill,
        };

        this.set(propsToSet);

        // "modified" event already dispatched in the shape
      },

      /**
       * Properties of the object. Used by both React and the API.
       *
       * Some properties are exposed with two different names because React and
       * the API differ in their naming conventions.
       */
      getContextProps(this: fabric.ShapeInnerTextBox): ContextProps {
        return {
          textColor:
            this.fill instanceof LiveColor
              ? this.fill.base
              : this.fill || "#000000",
          fontFamily: this.fontFamily,
          fontSize: this.fontSize,
          fontWeight: this.fontWeight as FontWeight,
          fontStyle: this.fontStyle as FontStyle,
          underline: this.underline,
          textAlign: this.textAlign,
          textPlacement: this.textPlacement,
          maxFontSize: this.maxFontSize,
          isAutoFontSize: false,
        };
      },

      beginTransform(
        this: fabric.ShapeInnerTextBox,
        config?: BeginTransformConfig
      ) {
        this.shape.beginTransform(config);
      },

      applyTransform(
        this: fabric.ShapeInnerTextBox,
        value: ContextProps,
        config?: SetContextPropsConfig
      ) {
        this.shape.applyTransform(value, config);
      },

      commitTransform(
        this: fabric.ShapeInnerTextBox,
        config?: CommitTransformConfig
      ) {
        this.shape.commitTransform(config);
      },

      cancelTransform(this: fabric.ShapeInnerTextBox) {
        this.shape.cancelTransform();
      },

      /* This method also exists in stickyNote.editing.js file
       so consider updating it also there when changing it in this file.
      */
      _processLine(
        this: fabric.ShapeInnerTextBox,
        text: string,
        lineIndex: number,
        desiredWidth: number
      ) {
        let currentLine = "";
        const lines = [];

        for (let i = 0; i < text.length; i += 1) {
          const char = text.charAt(i);
          const newLine = currentLine.concat(char);
          const box = this._getGraphemeBox(newLine, lineIndex, 0);
          if (box.kernedWidth >= desiredWidth) {
            const index = currentLine.lastIndexOf(" ");

            if (index > 0) {
              // divide on white space
              const part = currentLine.slice(0, index + 1);
              lines.push([...part]);
              // second part add a current line in new line, it contains now wrapped word
              currentLine = currentLine.slice(index + 1).concat(char);
            } else {
              // otherwise wrap line
              lines.push([...currentLine]);
              currentLine = char;
            }
          } else {
            currentLine = newLine;
          }
        }

        lines.push([...currentLine]);

        return { lines };
      },

      /* This method also exists in stickyNote.editing.js file
       so consider updating it there when changing it in this file.
      */
      _wrapLine(
        this: fabric.ShapeInnerTextBox,
        line = "",
        lineIndex: number,
        _desiredWidth: number,
        _reservedSpace: number
      ) {
        const desiredWidth = _desiredWidth - 2 * this.internalPadding;
        const { lines } = this._processLine(line, lineIndex, desiredWidth);
        return lines;
      },
    }
  );
})();

export const createShapeInnerTextBox = (
  options: ShapeInnerTextConstructorConfig
): fabric.ShapeInnerTextBox => {
  options = options || {};
  options.fill = options.fill || options.textColor;
  options.text = options.text || "";

  return new fabric.ShapeInnerTextBox(options.text, options);
};
