import React, { ReactElement } from "react";
import { Box } from "theme-ui";

const size = 32;

function Logo(props: LogoProps): ReactElement {
  return (
    <Box
      sx={{
        width: 0,
        height: 0,
        py: `${size / 2}px`,
        px: `${size * 3}px`,
        backgroundSize: "cover",
        background: "url(/logo-eth.svg)",
        ".dark &": {
          background: "url(/logo-eth-white.svg)",
        },
      }}
      {...props}
    />
  );
}
export default Logo;
