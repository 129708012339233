import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Text } from "theme-ui";
import { routes } from "../../../../const";
import Eye from "../../../../icons/Eye";
import Lock from "../../../../icons/Lock";
import ProjectThumbnailLink from "./ProjectThumbnailLink";
import ReadOnlyProjectTooltip from "./ReadOnlyProjectTooltip";

type Props = {
  project: ApiProject;
  index: number;
};

function ProjectThumbnail({ project, index }: Props): ReactElement {
  const { t } = useTranslation();
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const { IsLicensed, Project } = project;
  const freeProjectStyle = {
    background: "white",
    border: "1px solid",
    borderColor: "delicate",
  };

  const {
    CreatedByUser,
    Description,
    LastUpdate,
    IsLocked,
    ProjectId,
    Thumbnail,
  } = Project;

  const lastUpdate = t("app.lastUpdate", {
    date: new Date(LastUpdate),
  });

  const owner = t("app.owner", { owner: CreatedByUser });

  const projectRoute = `${routes.collaboard}/${ProjectId}`;

  function ProjectInfo(): ReactElement {
    return (
      <>
        <Text
          variant="layout.projectThumbnail.title"
          className="project-thumbnail-link__title"
          title={Description}
        >
          {Description}
        </Text>
        <Text variant="layout.projectThumbnail.meta" title={lastUpdate}>
          {lastUpdate}
        </Text>
        <Text variant="layout.projectThumbnail.meta" title={owner}>
          {owner}
        </Text>
        <Flex
          sx={{
            flex: "1 1 auto",
            marginTop: "auto",
            backgroundImage: `url(data:image/png;base64,${Thumbnail})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50% 50%",
          }}
        />
        {/* <ProjectThumbnailContextMenu project={ProjectId} /> */}
      </>
    );
  }

  function LockedProject(): ReactElement {
    return (
      <Box
        key={ProjectId}
        variant="layout.projectThumbnailLocked"
        id={`project-tile-${index}`}
        className="project-thumbnail"
      >
        <Box variant="layout.projectThumbnail.wrapper">
          <Flex variant="layout.projectThumbnail.content">
            <Flex variant="layout.projectThumbnail.overlay">
              <Box
                variant="layout.projectThumbnail.overlayLabel"
                title={t("app.projectLocked")}
              >
                <Lock noShadow />
                <div>{t("app.projectLocked")}</div>
              </Box>
            </Flex>
            <ProjectInfo />
          </Flex>
        </Box>
      </Box>
    );
  }

  function UnlockedProject(): ReactElement {
    return (
      <ProjectThumbnailLink
        index={index}
        key={ProjectId}
        href={projectRoute}
        customStyles={IsLicensed ? undefined : freeProjectStyle}
      >
        {!IsLicensed && (
          <Flex
            variant={`layout.projectThumbnail.${
              isOverlayVisible ? "overlayHover" : "overlay"
            }`}
          >
            {isOverlayVisible && <ReadOnlyProjectTooltip />}

            <Flex
              onClick={(e) => {
                e.preventDefault();
                setOverlayVisible(!isOverlayVisible);
              }}
              sx={{ position: "absolute", bottom: 0, right: 0, p: [2] }}
            >
              <Eye noShadow scale={1.5} />
            </Flex>
          </Flex>
        )}
        <ProjectInfo />
      </ProjectThumbnailLink>
    );
  }

  return IsLocked ? <LockedProject /> : <UnlockedProject />;
}
export default ProjectThumbnail;
