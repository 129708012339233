import React, { ReactElement, useCallback, useState } from "react";
import { Button } from "theme-ui";
import { useTranslation } from "react-i18next";
import { errorToast } from "../../../tools/errorToast";
import ProcessingButton from "../forms/ProcessingButton";
import useHotkeys from "../dom/useHotkeys";
import { isString } from "../../../tools/text";

type Props = {
  confirmationLabel?: string;
  cancelLabel?: string;
  onConfirm?: () => void;
  hideConfirmationButton?: boolean;
  confirmationDisabled?: boolean;
  hideCancelButton?: boolean;
  onCancel?: () => void;
  onClose: () => void;
  closeOnConfirm?: boolean;
  confirmOnEnterKeyPressed?: boolean;
  variant?: "warning" | "success";
  autoFocusConfirmButton?: boolean;
};

function ConfirmationButtons({
  onCancel,
  onClose,
  variant = "success",
  onConfirm,
  closeOnConfirm = true,
  hideConfirmationButton,
  confirmationDisabled,
  hideCancelButton,
  confirmationLabel,
  cancelLabel,
  confirmOnEnterKeyPressed = true,
  autoFocusConfirmButton,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = useState(false);
  const isButtonDisabled = confirmationDisabled || isProcessing;

  // TODO: refactor
  // state of confirmation button must reflect form state
  // currently both states are independent
  // update InputField and TextareaField afterwards
  const onConfirmClick = useCallback(async () => {
    setIsProcessing(true);
    try {
      await onConfirm?.();

      if (closeOnConfirm) {
        onClose();
      } else {
        setIsProcessing(false);
      }
    } catch (error) {
      const e = error as undefined | string | ApiError;
      const fallbackMessage = t("dialog.confirmationError");
      const message = e
        ? isString(e)
          ? e
          : e.message ?? e.errorMessage ?? fallbackMessage
        : fallbackMessage;

      setIsProcessing(false);
      errorToast(message);
    }
  }, [closeOnConfirm, onClose, onConfirm, t]);

  const onCancelClick = useCallback(() => {
    onCancel?.();
    onClose();
  }, [onCancel, onClose]);

  useHotkeys("enter", onConfirmClick, [onConfirmClick], {
    enableForModal: true,
    disabled:
      isButtonDisabled ||
      hideConfirmationButton ||
      !confirmOnEnterKeyPressed ||
      !onConfirm,
  });

  return (
    <>
      {!hideCancelButton && (
        <Button variant="cancel" onClick={onCancelClick}>
          {cancelLabel || t("form.button.cancel")}
        </Button>
      )}

      {!hideConfirmationButton && (
        <ProcessingButton
          variant={variant === "success" ? "primary" : "warning"}
          onClick={onConfirmClick}
          disabled={isButtonDisabled}
          isProcessing={isProcessing}
          id="processing-button"
          autoFocus={autoFocusConfirmButton}
        >
          {confirmationLabel}
        </ProcessingButton>
      )}
    </>
  );
}

export default ConfirmationButtons;
