import { all, Effect } from "redux-saga/effects";
import { activeMediaSaga } from "./activeMedia/activeMedia.saga";
import { alignmentSaga } from "./alignment/alignment.saga";
import { objectsCRUDSaga } from "./crud/crud.saga";
import { dropSaga } from "./drop/drop.saga";
import { editingSaga } from "./editing/editing.saga";
import { groupingSaga } from "./grouping/grouping.saga";
import { layersSaga } from "./layers/layers.saga";
import { linksSaga } from "./links/links.saga";
import { lockSaga } from "./lock/lock.saga";
import { movementSaga } from "./movement/movement.saga";
import { objectSettingsSaga } from "./settings/settings.saga";

export function* objectsSaga(): Generator<Effect> {
  yield all([
    activeMediaSaga(),
    alignmentSaga(),
    editingSaga(),
    objectsCRUDSaga(),
    dropSaga(),
    groupingSaga(),
    layersSaga(),
    linksSaga(),
    lockSaga(),
    movementSaga(),
    objectSettingsSaga(),
  ]);
}
