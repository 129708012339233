/**
 * Read text content of a File
 */
export const readFileContents = (file: File | Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    const onReject = () => reject(new Error("Cannot read file as UTF-8"));

    reader.onload = () =>
      reader.result ? resolve(reader.result.toString()) : onReject();
    reader.onabort = onReject;
    reader.onerror = (e) => reject(e);
    reader.readAsText(file, "UTF-8");
  });
