import React, { ReactElement } from "react";
import { Box, Button, Heading, ThemeUIStyleObject } from "theme-ui";
import { useTranslation } from "react-i18next";
import Plus from "../../../../../icons/Plus";
import LoadingSpinner from "../../../../../icons/LoadingSpinner";
import { useConfirmModal } from "../../../../shared/modals";
import EmptyListPlaceholder from "../../../../shared/layout/EmptyListPlaceholder";
import AddDeviceModal from "./AddDeviceModal";
import Device from "./Device";
import { UseInfiniteQueryResult } from "../../../../../api/useRequest";
import InfiniteScroll from "react-infinite-scroller";
import Centered from "../../../../shared/layout/Centered";
import RequestErrorPlaceholder from "../RequestErrorPlaceholder";
import { rowHeight } from "../const";

type Props = {
  onAddDevice(): void;
  onRemoveDevice(user: ApiSubscriptionDevice): void;
  query: UseInfiniteQueryResult & {
    data: ApiSubscriptionDevice[];
  };
};

function Devices({ onAddDevice, onRemoveDevice, query }: Props): ReactElement {
  const { t } = useTranslation();
  const addDeviceModal = useConfirmModal();
  const {
    canFetchMore,
    fetchMore,
    data,
    isDataInitialized,
    error,
    isFetching,
    isFetchingMore,
    refetch,
  } = query;

  return (
    <>
      <AddDeviceModal
        modalProps={addDeviceModal.props}
        onAddDevice={onAddDevice}
        refetchDevices={refetch}
      />
      <Heading variant="layout.darkBar" sx={{ mt: [5] }}>
        <Box>{t("team.devices")}</Box>
        <Button
          variant="buttons.circleIcon"
          onClick={() => addDeviceModal.open()}
        >
          <Plus />
          <Box>{t("team.addNewDevice")}</Box>
        </Button>
      </Heading>

      {!isDataInitialized && <LoadingSpinner />}
      {isDataInitialized && data.length === 0 && (
        <EmptyListPlaceholder sx={{ height: rowHeight }} />
      )}
      {error && <RequestErrorPlaceholder />}

      {!!data.length && (
        <Box sx={scrollWrapperStyle}>
          <InfiniteScroll
            pageStart={1}
            loadMore={fetchMore}
            hasMore={canFetchMore && !isFetching && !isFetchingMore}
            loader={
              <Centered key="subscription-devices-infinite-scroll">
                <LoadingSpinner />
              </Centered>
            }
          >
            {data.map((device) => (
              <Device
                key={device.ID}
                device={device}
                onRemove={() => onRemoveDevice(device)}
              />
            ))}
          </InfiniteScroll>
        </Box>
      )}
    </>
  );
}

const scrollWrapperStyle: ThemeUIStyleObject = {
  height: "300px",
  overflowY: "auto",
};

export default Devices;
