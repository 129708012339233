import React, { ReactElement } from "react";
import { Trans } from "react-i18next";
import { Box, ThemeUIStyleObject } from "theme-ui";
import { getTranslation } from "../../../reduxStore/uiSettings";
import { getCustomerConfiguration } from "../../../tools/customer";
import { runtimeConfig } from "../../../tools/runtimeConfig";

function LoginFooter(): ReactElement {
  // Inside component so we can test different values
  const {
    loginTroubleHref: customerLoginTroubleHref,
  } = getCustomerConfiguration();

  const loginTroubleHref =
    runtimeConfig.loginTroubleHref || customerLoginTroubleHref;

  return (
    <Box sx={boxStyle}>
      <Trans defaults={getTranslation("form.button.troubles")}>
        {/* eslint-disable jsx-a11y/anchor-has-content */}
        <a
          href={loginTroubleHref}
          target="_blank"
          rel="noreferrer"
          data-testid="login-troubles-link"
        />
      </Trans>
    </Box>
  );
}

export default LoginFooter;

const boxStyle: ThemeUIStyleObject = {
  mt: [5, null, 0],
};
