import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Grid } from "theme-ui";
import { Currency } from "../useCurrencies";
import LoadingSpinner from "../../../../icons/LoadingSpinner";
import BillingSwitch from "../../../licensing/payment/BillingSwitch";
import FreeMonths from "../../../licensing/payment/FreeMonths";
import LicenseCountInput from "../../../licensing/payment/LicenseCountInput";
import PaymentMethod from "../../../licensing/payment/PaymentMethod";
import PromoCode from "../../../licensing/payment/PromoCode";
import Total from "../../../licensing/payment/Total";
import UpdateTotal from "../../../licensing/payment/UpdateTotal";
import { TemplateProps } from "../../../shared/modals/Modal";
import { LicenseOrderModal, LicenseOrderModalTop } from "../LicenseOrderModal";
import { useTeamLicenseOrder } from "./useTeamLicenseOrder";

type Props = {
  currency: Currency;
  savings: string;
  productTypeID: number;
};

function TeamLicenseOrderModalTemplate({
  currency,
  savings,
  productTypeID,
  modalProps,
}: TemplateProps<Props>): ReactElement {
  const { t } = useTranslation();

  const {
    total,
    numberOfFreeMonths,
    totalError,
    promoCode,
    billingType,
    arePricesLoaded,
    onBillingChange,
    onPromoCodeChange,
    goToCheckout,
    updateTotal,
    userCount,
    onUserCountChange,
    promoCodeDisabled,
    clearPromoCode,
  } = useTeamLicenseOrder(currency.code, productTypeID);

  return (
    <LicenseOrderModal
      goToCheckout={goToCheckout}
      modalProps={modalProps}
      totalError={totalError}
    >
      <LicenseOrderModalTop>
        <BillingSwitch
          productTypeID={productTypeID}
          billingType={billingType}
          onBillingChange={onBillingChange}
          savings={savings}
        />
        <Total
          billingType={billingType}
          arePricesLoaded={arePricesLoaded}
          currency={currency}
          total={total}
          error={totalError}
        />
      </LicenseOrderModalTop>

      {userCount === null ? (
        <Flex sx={{ height: "78px", alignItems: "center" }}>
          <LoadingSpinner />
        </Flex>
      ) : (
        <LicenseCountInput
          onChange={onUserCountChange}
          value={userCount}
          label={t("dialog.payment.membershipLicenses")}
          placeholder={t("dialog.payment.membershipLicensesPlaceholder")}
        />
      )}

      <Grid columns={2} gap={0}>
        <PaymentMethod />

        <PromoCode
          onChange={onPromoCodeChange}
          promoCode={promoCode}
          disabled={promoCodeDisabled}
          clear={clearPromoCode}
        />
      </Grid>

      <UpdateTotal
        disabled={promoCodeDisabled || !promoCode}
        updateTotal={updateTotal}
      />

      <FreeMonths count={numberOfFreeMonths} />
    </LicenseOrderModal>
  );
}

export default TeamLicenseOrderModalTemplate;
