import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Duplicate(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M15,26.59a1.85,1.85,0,0,1-1.85-1.86v-9h-.79a1.86,1.86,0,0,0-1.86,1.86v9.94a1.86,1.86,0,0,0,1.86,1.86H23.54a1.86,1.86,0,0,0,1.86-1.86v-.89Z" />
      <path d="M27.56,11.56H16.34a1.86,1.86,0,0,0-1.86,1.86v9.94a1.86,1.86,0,0,0,1.86,1.86H27.56a1.86,1.86,0,0,0,1.86-1.86V13.42A1.86,1.86,0,0,0,27.56,11.56ZM26,19.36H22.76v3.26h-2V19.36H17.49v-2h3.26V14.09h2v3.26H26Z" />
    </Icon>
  );
}
export default Duplicate;
