import { runtimeConfig } from "../../tools/runtimeConfig";
import theme from "../collins";

const configuration: CustomerConfiguration = {
  appName: "Collins Digital Whiteboard",
  faviconName: "favicon-collins.ico",
  loginTroubleHref: runtimeConfig.loginTroubleHref || runtimeConfig.helpPageUrl,
  loginProviderCustomNames: { SAML: "MANAGE MY BOARDS" },
  loginProviderCustomIcons: { SAML: "/btn-Collins.svg" },
  displayProviderNamesOnly: true,
  visiblePlans: ["free", "enterprise"],
  compactPlansPage: true,
  fullFeaturesGrid: true,
  theme,
};

export default configuration;
