import { SignalRAction, SignalRActionType } from "./signalR.actions";

export enum SignalRStatus {
  /** The hub connection is disconnected. */
  Disconnected = "Disconnected",
  /** The hub connection is connecting. */
  Connecting = "Connecting",
  /** The hub connection is connected. */
  Connected = "Connected",
  /** The hub connection is disconnecting. */
  Disconnecting = "Disconnecting",
  /** The hub connection is reconnecting. */
  Reconnecting = "Reconnecting",
  /** The hub connection failed to connect. */
  Failed = "Failed",
  /** The browser is offline and therefore SignalR cannot connect */
  Offline = "Offline",
}

export type SignalRState = {
  connectionId: string | undefined;
  /**
   * @NOTE SignalR is connected after a user / guest 'logs in'. They are not
   * necessarily on the canvas.
   */
  status: SignalRStatus;
  /**
   * @NOTE This property is frequently updated, thus do not select the whole state
   * to avoid unnecessary renders
   */
  isQueueBusy: boolean;
  error: Error | undefined;
};

const getInitialSignalRState = (): SignalRState => {
  return {
    connectionId: undefined,
    status: SignalRStatus.Disconnected,
    isQueueBusy: false,
    error: undefined,
  };
};

export function signalRReducer(
  state = getInitialSignalRState(),
  action: SignalRAction
): SignalRState {
  switch (action.type) {
    case SignalRActionType.SET_SIGNALR_STATUS: {
      return {
        ...state,
        status: action.payload.status ?? state.status,
        isQueueBusy: action.payload.isQueueBusy ?? state.isQueueBusy,
      };
    }
    case SignalRActionType.SET_SIGNALR_CONNECTED: {
      return {
        ...state,
        status: action.payload.status,
        connectionId: action.payload.connectionId,
      };
    }
    case SignalRActionType.SET_SIGNALR_FAILED: {
      return {
        ...state,
        status: SignalRStatus.Failed,
        error: action.payload.error,
      };
    }
    default:
      return state;
  }
}

export const selectSignalRConnected = (
  state: ApplicationGlobalState
): boolean => {
  return state.signalR.status === SignalRStatus.Connected;
};

export const selectSignalROffline = (
  state: ApplicationGlobalState
): boolean => {
  return state.signalR.status === SignalRStatus.Offline;
};

export const selectSignalRFailed = (state: ApplicationGlobalState): boolean => {
  return state.signalR.status === SignalRStatus.Failed;
};

export const selectSignalRError = (
  state: ApplicationGlobalState
): Error | undefined => {
  return state.signalR.error;
};

export const selectSignalRConnectionId = (
  state: ApplicationGlobalState
): string | undefined => {
  return state.signalR.connectionId;
};

export const selectSignalRBusy = (state: ApplicationGlobalState): boolean => {
  return state.signalR.isQueueBusy;
};
