export class CollaboardError extends Error {
  public name = "CollaboardError";

  constructor(message: string) {
    super(message);

    // Maintains proper stack trace for where our error was thrown (V8 only)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CollaboardError);
    }
  }
}
