import React, { useRef, useEffect, useLayoutEffect, ReactElement } from "react";
import { isTransparent } from "../../../tools/colors";

type Size = {
  width: number;
  height: number;
};

type Props = {
  color: string;
};

function CurrentColor({ color }: Props): ReactElement {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const contextRef = useRef<CanvasRenderingContext2D | null>(null);

  useLayoutEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    contextRef.current = canvasRef.current.getContext("2d");
  }, []);

  const drawTransparentBG = (
    ctx: CanvasRenderingContext2D,
    { width, height }: Size
  ) => {
    const gap = 50;
    ctx.strokeStyle = "black";
    ctx.lineWidth = 1;
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, width, height);
    for (let i = 0; i < 20; i++) {
      ctx.beginPath();
      ctx.moveTo(-600 + i * gap, 150);
      ctx.lineTo(i * gap, 0);
      ctx.stroke();
    }
  };

  useEffect(() => {
    const ctx = contextRef.current;
    const canvas = canvasRef.current;
    if (ctx && canvas && color) {
      if (!isTransparent(color)) {
        ctx.fillStyle = color;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
      } else {
        drawTransparentBG(ctx, canvas);
      }
    }
  }, [color]);

  return <canvas ref={canvasRef} style={{ height: "100%", width: "100%" }} />;
}

export default CurrentColor;
