import { BrushType } from "../../../../const";
import { BaseImageBrush } from "./BaseImageBrush";

export class BrushImageBrush extends BaseImageBrush {
  /**
   * Disable brush image rotation for this brush as it makes no difference.
   */
  protected ENABLE_BRUSH_ROTATION = false;

  constructor(
    canvas: fabric.CollaboardCanvas,
    options?: fabric.CollaboardBrushOptions
  ) {
    super("/brush-brush.png", BrushType.brush, canvas, options);
  }

  /**
   * Modify the pointer pressure.
   */
  protected _modifyPressure(pressure: number): number {
    return 0.02;
  }
}
