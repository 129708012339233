import PropTypes from "prop-types";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button, Flex, Link, Text } from "theme-ui";
import TemplateBlank from "../../../../../icons/TemplateBlank";
import TemplateFull from "../../../../../icons/TemplateFull";
import { useUISettings } from "../../../../../reduxStore/uiSettings";
import {
  dynamicFeatureConfig,
  dynamicFeatureFlags,
} from "../../../../../tools/flags";
import { runtimeConfig } from "../../../../../tools/runtimeConfig";
import { ApiLanguage } from "../../../../../types/enum";
import CheckboxField from "../../../../shared/forms/fields/CheckboxField";
import InputField from "../../../../shared/forms/fields/InputField";

function TypeSelection({ types, setType, type }) {
  const { t, i18n } = useTranslation();
  const {
    getTranslation,
    isDynamicFeatureActive,
    getDynamicFeatureConfig,
  } = useUISettings();

  const mustAcceptToS = isDynamicFeatureActive(
    dynamicFeatureFlags.CREATE_PROJECT_TOS
  );

  const tosLinks =
    getDynamicFeatureConfig(dynamicFeatureConfig.CREATE_PROJECT_TOS_LINKS) ??
    {};

  const tosLinksByLanguage =
    tosLinks[i18n.resolvedLanguage] ?? tosLinks[ApiLanguage.english] ?? [];

  const buttons = [
    {
      type: types.blank,
      label: "dialog.blankProject",
      Icon: <TemplateBlank noShadow />,
    },
    {
      type: types.template,
      label: "dialog.fromTemplate",
      Icon: <TemplateFull noShadow />,
    },
  ];

  const tosSecondLineKey = "form.label.CreateProjectToSSecondLine";
  const tosSecondLine = getTranslation(tosSecondLineKey);
  const hasTosSecondLine = tosSecondLineKey !== tosSecondLine;

  return (
    <>
      <Text>{t("dialog.newProjectTypeSelectionHeading")}</Text>
      <InputField
        name="Description"
        maxLength={100}
        placeholder={t("dialog.projectName")}
        sx={{ input: { borderColor: "var(--textColor)", mt: [5] } }}
        processOnEnterPressed
      />
      <Flex sx={{ height: "150px" }}>
        {buttons.map((button) => (
          <Button
            onClick={() => {
              setType(button.type);
            }}
            key={button.type}
            disabled={type === button.type}
            variant={
              type === button.type
                ? "buttons.radioButtonActive"
                : "buttons.radioButton"
            }
          >
            <Flex sx={innerButtonStyle}>
              <span>{button.Icon}</span>
              <span>{t(button.label)}</span>
            </Flex>
          </Button>
        ))}
      </Flex>
      {mustAcceptToS && (
        <CheckboxField
          name="CreateProjectToS"
          sx={{ mt: [4], mb: [0], span: { display: "block", mb: [2] } }}
        >
          <span>
            <Trans defaults={getTranslation("form.label.CreateProjectToS")}>
              {tosLinksByLanguage.map((link, i) => (
                <Link key={i} href={link} target="_blank" />
              ))}
            </Trans>
          </span>
          {hasTosSecondLine && (
            <span>
              <Trans defaults={tosSecondLine}>
                <Link href={runtimeConfig.tosUrl} target="_blank" />
              </Trans>
            </span>
          )}
        </CheckboxField>
      )}
    </>
  );
}

const innerButtonStyle = {
  flexDirection: "column",
  svg: { width: "60px", height: "60px" },
};

TypeSelection.propTypes = {
  setType: PropTypes.func.isRequired,
  type: PropTypes.number.isRequired,
  types: PropTypes.objectOf(PropTypes.number).isRequired,
};
TypeSelection.defaultProps = {};

export default TypeSelection;
