import componentsCollins from "../../themes/collins/components";
import componentsDefault from "../../themes/default/components";
import componentsETH from "../../themes/eth/components";
import componentsProwise from "../../themes/prowise/components";
import componentsTWR from "../../themes/twr/components";
import componentsAxis from "../../themes/axis/components";
import componentsBGW from "../../themes/bgw/components";
import componentsBKKGS from "../../themes/bkkgs/components";
import componentsVP from "../../themes/vp/components";
import { getCustomer } from "../../tools/customer";

export const useCustomerComponents = (): CustomerComponents => {
  return {
    default: componentsDefault,
    eth: { ...componentsDefault, ...componentsETH },
    twr: { ...componentsDefault, ...componentsTWR },
    collins: { ...componentsDefault, ...componentsCollins },
    prowise: { ...componentsDefault, ...componentsProwise },
    vp: { ...componentsDefault, ...componentsVP },
    axis: { ...componentsDefault, ...componentsAxis },
    bgw: { ...componentsDefault, ...componentsBGW },
    bkkgs: { ...componentsDefault, ...componentsBKKGS },
  }[getCustomer()];
};
