/**
 * @NOTE Use this file for object actions which don't have a better place.
 */

import { Direction } from "../../../../types/enum";

export enum MovementActionType {
  MOVE = "MOVE",
}

export type Move = {
  event: KeyboardEvent;
  direction: Direction;
};

export const moveAction = (payload: Move) =>
  ({
    type: MovementActionType.MOVE,
    payload,
  } as const);

export type MoveAction = ReturnType<typeof moveAction>;

export type MovementAction = MoveAction;
