import { compareTwoStrings } from "string-similarity";
import { string } from "yup";

const requiredEmailSchema = string().email().required();

export const prepareUsersData = (
  values: BatchImportTableValues,
  userData: IndexedSpreadsheetData
): {
  toAdd: ApiUsersImport[];
  toRemove: ApiUsersImport[];
  invalid: ApiUsersImport[];
} => {
  const { columns, isFirstRowHeader } = values;

  // convert { column1: "UserName", column2: "FirstName", ... }
  // to [[ 0, "UserName" ], [ 1, "FirstName" ], ...]
  const columnTuples: [number, ImportDropdownValue][] = Object.entries(
    columns
  ).map(([key, val]) => [Number(key.replace("column", "")) - 1, val]);

  const mappedData = (userData ?? [])
    // remove first row if it contains header data instead of user data
    .slice(isFirstRowHeader ? 1 : 0)
    // using columnTuples convert user array from spreadsheet [ "user@ibv.ch", "John", "Smith", … ]
    // into object expected by the api - like { UserName: "user@ibv.ch", FirstName: "John", LastName: "Smith", … }
    .map(({ data }) => {
      const userArray = columnTuples
        .map(([key, fieldName]) => {
          if (fieldName === "Action") {
            const value = String(data[key] ?? "ADD")
              .trim()
              .toUpperCase();
            return [fieldName, value];
          }

          return [fieldName, data[key]];
        })
        .filter(([, value]) => Boolean(value));

      return Object.fromEntries(userArray);
    });

  return mappedData.reduce(
    (result, user) => {
      if (requiredEmailSchema.isValidSync(user.UserName)) {
        const action = user.Action;

        // Don't send the action to the API
        delete user.Action;

        if (action === "REMOVE") {
          result.toRemove.push(user);
        } else {
          user.SendInvitationEmail = true;
          result.toAdd.push(user);
        }
      } else {
        result.invalid.push(user);
      }
      return result;
    },
    {
      toAdd: [],
      toRemove: [],
      invalid: [],
    }
  );
};

const SIMILARITY_THRESHOLD = 0.6;
// we cannot fetch them dynamically via t method of i18next as translations for only one language are stored in memory
const EMAIL_TRANSLATIONS = [
  "Email",
  "Eメール",
  "Correo electrónico",
  "البريد الإلكتروني",
];

export const guessIfFirstRowIsHeader = (
  row: (string | number | null)[]
): boolean => {
  // check all the cells in first row and compare them to "email" word in all supported languages
  const containsEmail = row.some(
    (cell) =>
      typeof cell === "string" &&
      EMAIL_TRANSLATIONS.reduce((accu, curr) => {
        const similarity = compareTwoStrings(
          cell.toLowerCase(),
          curr.toLowerCase()
        );

        return similarity > accu ? similarity : accu;
      }, 0) > SIMILARITY_THRESHOLD
  );

  // make sure there is no valid email address in any of the cells in the first row
  const doesNotPassEmailValidation = row.every(
    (cell) => !cell || !string().email().isValidSync(cell)
  );

  return containsEmail && doesNotPassEmailValidation;
};
