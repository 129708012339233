import { Reducer as ReduxReducer } from "redux";
import { AuthAction, AuthActionType } from "../auth/auth.actions";
import {
  UserPermissionsAction,
  UserPermissionsActionType,
} from "./userPermissions.actions";

export type UserPermissionsState = {
  assignedPermissions: ApiAssignedPermissions[];
  requiredPermissions: ApiRequiredPermission[];
};

const getInitialUserPermissionsState = (): UserPermissionsState => {
  return {
    assignedPermissions: [],
    requiredPermissions: [],
  };
};

export const userPermissionsReducer: ReduxReducer<
  UserPermissionsState,
  UserPermissionsAction | AuthAction
> = (
  state = getInitialUserPermissionsState(),
  action
): UserPermissionsState => {
  switch (action.type) {
    case UserPermissionsActionType.GET_USER_PERMISSIONS_DONE: {
      const { payload } = action;
      return {
        assignedPermissions: payload.AssignedPermissions,
        requiredPermissions: payload.RequiredPermissions,
      };
    }
    case AuthActionType.LOGGED_OUT:
      return getInitialUserPermissionsState();
    default:
      return state;
  }
};
