import { fabric } from "fabric";
import { canvasObjectIds } from "../../../const";
import { ApiShapeId } from "../../../shapes/shapeId";
import { shapesAnchorPoints } from "../../../shapes/shapesAnchorPoints";
import { assertUnreachable } from "../../../tools/assertions";
import { runtimeConfig } from "../../../tools/runtimeConfig";
import { Anchor, AnchorAlgorithm } from "../../../types/enum";
import {
  calcTransformState,
  isFreeFormText,
  isShape,
} from "../../utils/fabricObjects";

// Local constants - not shared with other files
const anchorCircleConfig = {
  strokeWidth: 1,
  inner: {
    id: "INNER",
    edgeRadius: 2,
    centralRadius: 3,
  },
  outer: {
    id: "OUTER",
    edgeRadius: 5,
    centralRadius: 8,
  },
};

const { useCacheForConnectionAnchor = true } = runtimeConfig;

export const anchorAlgorithmByType: Partial<
  Record<canvasObjectIds, AnchorAlgorithm>
> = {
  [canvasObjectIds.stickyNote]: AnchorAlgorithm.useDefaults,
  [canvasObjectIds.image]: AnchorAlgorithm.useDefaults,
  [canvasObjectIds.video]: AnchorAlgorithm.useDefaults,
  [canvasObjectIds.youtube]: AnchorAlgorithm.useDefaults,
  [canvasObjectIds.document]: AnchorAlgorithm.useDefaults,
  [canvasObjectIds.pdfDocument]: AnchorAlgorithm.useDefaults,
  [canvasObjectIds.wordDocument]: AnchorAlgorithm.useDefaults,
  [canvasObjectIds.powerPointDocument]: AnchorAlgorithm.useDefaults,
  [canvasObjectIds.excelDocument]: AnchorAlgorithm.useDefaults,
  [canvasObjectIds.shape]: useCacheForConnectionAnchor
    ? AnchorAlgorithm.preComputed
    : AnchorAlgorithm.linear,
  [canvasObjectIds.text]: AnchorAlgorithm.useDefaults,
  [canvasObjectIds.group]: AnchorAlgorithm.useDefaults,
  [canvasObjectIds.stack]: AnchorAlgorithm.useDefaults,
};

type AnchorPositions = {
  top: fabric.Point;
  right: fabric.Point;
  bottom: fabric.Point;
  left: fabric.Point;
  auto?: fabric.Point;
  center?: fabric.Point;
  width?: number;
  height?: number;
};

class CollaboardObjectConnections {
  _anchorAlgorithm: AnchorAlgorithm;
  _dropZoneAnchors: Record<Anchor, fabric.Point> | null;
  _dropZoneAnchorPositions: AnchorPositions | null;
  _dropZoneCreated: boolean;
  _connections: fabric.CollaboardConnector[];
  _visibleAnchors: Set<Anchor>;

  object: fabric.Object;

  /**
   * This class is available on each connectable fabric.Object and it manages
   * the following:
   *
   *  - Drop zones for connections
   *  - Moving connections when the object moves
   *
   * NOTE: Removal clean up has been removed because it prevents the object from
   * working when it is restored from the history stack.
   * Potential for memory leaks. Beware.
   */
  constructor(object: fabric.Object) {
    this.object = object;

    this._connections = [];

    this._dropZoneCreated = false;
    this._visibleAnchors = new Set();

    this._dropZoneAnchors = null;

    // These are the unscaled positions
    this._dropZoneAnchorPositions = null;

    // Determine which algorithm should be used for anchor
    this._anchorAlgorithm =
      anchorAlgorithmByType[this.object.type] ?? AnchorAlgorithm.useDefaults;

    this._attachEventListeners();
    this._overrideRender();
  }

  /**
   * Override object's render method to draw the anchor points.
   *
   * @TODO It would be better to render anchor points the same as custom controls,
   * by statically overriding fabric.Object.prototype.drawObject instead of
   * dynamically overriding the render method. But that would require relative
   * anchor positions.
   *
   * At least, we currently make the override more deterministic at initialization.
   */
  _overrideRender(): void {
    const originalRender = this.object.render;

    // Use a named function for clearer debugging
    this.object.render = function connectionsOverrideRender(
      ctx: CanvasRenderingContext2D
    ) {
      originalRender.call(this, ctx);

      if (this.connections) {
        this.connections.drawVisibleAnchors();
      }
    };
  }

  attachConnection(connector: fabric.CollaboardConnector): void {
    this._createDropZoneAnchors();
    const index = this._connections.findIndex((item) => item === connector);
    // Only add a connection if it isn't already listed
    if (index === -1) {
      this._connections.push(connector);
    }
  }

  /**
   * Detach a connection from this object.
   * This is called when a connector is manually deleted or if the object at the
   * other end of the connection is deleted.
   *
   * NOTE: this is never actually used because nothing is ever cleaned up,
   * thanks to the undo / redo implementation. Keeping it here for when undo /
   * redo starts to bite!
   *
   */
  detachConnection(connector: fabric.CollaboardConnector): void {
    const index = this._connections.findIndex((item) => item === connector);
    if (index > -1) {
      this._connections.splice(index, 1);
    }
  }

  getConnections(): fabric.CollaboardConnector[] {
    return this._connections;
  }

  /**
   * Show destination drop zone. This is where a user can complete a connection.
   */
  showDestinationDropZone(): void {
    this._createDropZoneAnchors();
    this._dropZoneAnchors &&
      Object.keys(this._dropZoneAnchors).forEach((anchor) =>
        this._visibleAnchors.add(anchor as Anchor)
      );
  }

  /**
   * Show origin drop zone. This is not interactive and is just for information.
   */
  showOriginDropZone(anchor: Anchor): void {
    this._createDropZoneAnchors();
    this._visibleAnchors.add(anchor);
  }

  hideDropZone(): void {
    this._visibleAnchors.clear();
  }

  /**
   * Find and return the nearest anchor point to the mouse pointer.
   */
  findNearestAnchorPoint(
    pointer: fabric.Position
  ): { position: fabric.Position; anchor: Anchor } {
    if (!this._dropZoneAnchors) {
      this._createDropZoneAnchors();
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const anchorPoints = Object.keys(this._dropZoneAnchors!).map((anchor) => {
      const position = this.getAnchorPosition(anchor as Anchor);
      const x = (position.x - pointer.x) ** 2;
      const y = (position.y - pointer.y) ** 2;
      const distance = x + y;

      return {
        anchor: anchor as Anchor,
        position: {
          x: position.x,
          y: position.y,
        },
        distance,
      };
    });
    const nearestPoint = anchorPoints.reduce((result, anchorPoint) => {
      return anchorPoint.distance < result.distance ? anchorPoint : result;
    }, anchorPoints[0]);

    return {
      position: nearestPoint.position,
      anchor: nearestPoint.anchor,
    };
  }

  getAnchorPosition(anchor: Anchor): fabric.Point {
    this._createDropZoneAnchors();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this._dropZoneAnchors![anchor];
  }

  /**
   * Return the coordinates of all four edges of the object.
   */
  getEdges(): Record<Anchor, PathCoords> {
    const transform = calcTransformState(this.object);

    const width = this.object.width * transform.scaleX;
    const height = this.object.height * transform.scaleY;

    const xCenter = transform.left;
    const yCenter = transform.top;

    const halfWidth = width / 2;
    const halfHeight = height / 2;

    const center = new fabric.Point(xCenter, yCenter);

    const tl = center.add(new fabric.Point(-halfWidth, -halfHeight));
    const tr = center.add(new fabric.Point(halfWidth, -halfHeight));
    const bl = center.add(new fabric.Point(-halfWidth, halfHeight));
    const br = center.add(new fabric.Point(halfWidth, halfHeight));

    return {
      top: {
        x1: tl.x,
        y1: tl.y,
        x2: tr.x,
        y2: tr.y,
      },
      right: {
        x1: tr.x,
        y1: tr.y,
        x2: br.x,
        y2: br.y,
      },
      bottom: {
        x1: bl.x,
        y1: bl.y,
        x2: br.x,
        y2: br.y,
      },
      left: {
        x1: tl.x,
        y1: tl.y,
        x2: bl.x,
        y2: bl.y,
      },
    } as Record<Anchor, PathCoords>;
  }

  /**
   * Remove all connections.
   * This is called when this object is deleted.
   */
  removeConnections(): void {
    const numberOfConnections = this._connections.length;

    if (this.object.connectionsPersisted) {
      return;
    }

    // Loop backwards because the array length will be changing
    for (let i = numberOfConnections - 1; i >= 0; i -= 1) {
      this.object.canvas?.remove(this._connections[i]);
    }
  }

  _attachEventListeners(): void {
    const eventHandlerBindings: fabric.EventHandlers = {
      changed: this._onObjectChangingSize, // this a text change event from fabric.IText
      modified: this._onObjectModified,
      moving: this._onObjectTransform,
      moved: this._onObjectMoved,
      removed: this._onObjectRemoved,
      rotating: this._onObjectTransform,
      scaling: this._onObjectModified,
      rotated: this._onObjectModified,
    };

    this.object.on(eventHandlerBindings);
  }

  _onObjectRemoved = (): void => {
    // Connections are able to exist in the history stack as simple objects
    // So we can remove them to remove event listeners etc
    this.removeConnections();
    // We can't however remove event listeners from the object itself though
    // because we currently store that actual live object in the history stack.
    // TODO: Refactor other objects so we can store the flat representation.
  };

  /**
   * Object is moving or rotating. Anchors will be moved as children of the drop
   * zone.
   */
  _onObjectTransform = (): void => {
    if (this._connections.length || this._dropZoneCreated) {
      this._repositionDropZoneAnchors();
      this._repositionConnections();
      // No need to update the bounding box as we move
    }
  };

  /**
   * Object is changing size (text changes).
   */
  _onObjectChangingSize = (): void => {
    if (this._connections.length || this._dropZoneCreated) {
      // Object has changed dimensions so anchors need to be recalculated first
      this._computeInitialAnchorPositions();
      this._repositionDropZoneAnchors();
      this._repositionConnections();
    }
  };

  /**
   * Object is changing size (scaling) or has changed (modified).
   */
  _onObjectModified = (event: ClientModifiedEvent): void => {
    if (this._connections.length || this._dropZoneCreated) {
      // Text could have changed dimensions because of changed text so anchors need to be recalculated first
      // Likewise when a subselected item has been modified
      if (
        isFreeFormText(this.object) ||
        event.transform?.action === "subselectionChange"
      ) {
        this._computeInitialAnchorPositions();
      }
      this._repositionDropZoneAnchors();
      this._repositionConnections();
      this._updateConnectionBoundingBox();
    }
  };

  _onObjectMoved = (): void => {
    if (this._connections.length || this._dropZoneCreated) {
      this._repositionDropZoneAnchors();
      this._repositionConnections();
      this._updateConnectionBoundingBox();
    }
  };

  /**
   * This is called when the parent object is moved. Connection position needs
   * to change.
   */
  _repositionConnections(): void {
    this._connections.forEach((connector) => {
      connector.reposition();
    });
  }

  /**
   * This is called when the parent object has moved, which affects the path.
   * The bounding box of the connection needs to be recalculated.
   */
  _updateConnectionBoundingBox(): void {
    this._connections.forEach((connector) => {
      connector.updateBoundingBox();
    });
  }

  /**
   * Create drop zone anchors for new connections.
   */
  _createDropZoneAnchors(): void {
    if (this._dropZoneCreated) {
      return;
    }

    const { angle } = this.object;

    if (angle) {
      // Ensure object is at zero rotation so that the anchor position algorithm works
      this.object.set({ angle: 0 });
    }

    const {
      left,
      right,
      top,
      bottom,
      center,
    } = this._computeInitialAnchorPositions();

    this._dropZoneAnchors = {
      top: new fabric.Point(top.x, top.y),
      right: new fabric.Point(right.x, right.y),
      bottom: new fabric.Point(bottom.x, bottom.y),
      left: new fabric.Point(left.x, left.y),
      auto: new fabric.Point(center.x, center.y),
    };

    // this._drawDebugInfo(this._dropZoneAnchors);

    this._dropZoneCreated = true;

    // Reset the angle, if necessary
    if (angle) {
      this.object.set({ angle });
      this._repositionDropZoneAnchors();
    }
  }

  /**
   * Compute the offset required for grouped anchors.
   *
   * Works for objects that are not grouped, objects grouped to a single level
   * and objects grouped within multiple levels.
   */
  _computeGroupOffset(): fabric.Point {
    const { group } = this.object;

    if (!group) {
      return new fabric.Point(0, 0);
    }

    const { left, top } = calcTransformState(group);

    return new fabric.Point(left, top);
  }

  _drawDropZoneAnchorPoint(anchor: Anchor): void {
    const { canvas } = this.object;
    const { viewportTransform, colorScheme } = canvas;
    const vptScale = viewportTransform[0];
    const { leadColor } = colorScheme;
    const isCentralAnchor = anchor === "auto";

    // Position 0, 0 represents the center of the group when working with the ctx
    const ctx = canvas.getContext();

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const anchorPosition = this._dropZoneAnchors![anchor];
    const groupOffset = this._computeGroupOffset();
    const position = anchorPosition.subtract(groupOffset);

    // scale the anchor points based on the canvas zoom
    const strokeWidth = anchorCircleConfig.strokeWidth / vptScale;
    const circleRadius = {
      [anchorCircleConfig.inner.id]: {
        edgeRadius: anchorCircleConfig.inner.edgeRadius / vptScale,
        centralRadius: anchorCircleConfig.inner.centralRadius / vptScale,
      },
      [anchorCircleConfig.outer.id]: {
        edgeRadius: anchorCircleConfig.outer.edgeRadius / vptScale,
        centralRadius: anchorCircleConfig.outer.centralRadius / vptScale,
      },
    };

    const { edgeRadius, centralRadius } = circleRadius[
      anchorCircleConfig.inner.id
    ];

    ctx.save();

    // set styles
    ctx.fillStyle = leadColor;
    ctx.strokeStyle = leadColor;
    ctx.lineWidth = strokeWidth;

    // draw inner circle
    ctx.beginPath();
    ctx.arc(
      position.x,
      position.y,
      isCentralAnchor ? centralRadius : edgeRadius,
      0,
      2 * Math.PI,
      false
    );
    ctx.fill();
    ctx.stroke();

    const {
      edgeRadius: outerEdgeRadius,
      centralRadius: outerCentralRadius,
    } = circleRadius[anchorCircleConfig.outer.id];

    // draw outer circle
    ctx.beginPath();
    ctx.arc(
      position.x,
      position.y,
      isCentralAnchor ? outerCentralRadius : outerEdgeRadius,
      0,
      2 * Math.PI,
      false
    );
    ctx.fillStyle = "transparent";
    ctx.stroke();

    ctx.restore();
  }

  drawVisibleAnchors(): void {
    this._visibleAnchors.forEach((anchor) => {
      this._drawDropZoneAnchorPoint(anchor);
    });
  }

  /**
   * Reposition the anchors by scaling their positions and adding
   * the translate values. Prevents the need to recalculate
   * `_findClosestNonTransparentPoint`.
   */
  _repositionDropZoneAnchors(): void {
    const transform = calcTransformState(this.object);
    const { angle, left, top, scaleX, scaleY } = transform;

    const rotationOrigin = new fabric.Point(left, top);
    const angleRadians = fabric.util.degreesToRadians(angle);

    this._dropZoneAnchors &&
      Object.keys(this._dropZoneAnchors).forEach((anchorID) => {
        if (!this._dropZoneAnchors || !this._dropZoneAnchorPositions) {
          return;
        }

        const anchor = anchorID as Anchor;

        const point = this._dropZoneAnchorPositions[anchor];
        if (anchor === "auto") {
          this._dropZoneAnchors[anchor].setXY(left, top);
          return;
        }

        point &&
          this._dropZoneAnchors[anchor].setXY(
            point.x * scaleX + left,
            point.y * scaleY + top
          );

        if (angle) {
          const rotatedPoint = fabric.util.rotatePoint(
            this._dropZoneAnchors[anchor],
            rotationOrigin,
            angleRadians
          );

          this._dropZoneAnchors[anchor].setXY(rotatedPoint.x, rotatedPoint.y);
        }
      });
  }

  static _getPrecomputedAnchorPoint(
    key: ApiShapeId
  ): Record<Anchor, fabric.Point> | undefined {
    const corners = [Anchor.left, Anchor.right, Anchor.top, Anchor.bottom];
    const coords = shapesAnchorPoints[key];
    if (!coords || !coords.length) {
      return undefined;
    }
    return corners.reduce((acc, cornerKey, idx) => {
      const [x, y] = coords.slice(idx * 2, idx * 2 + 2);
      acc[cornerKey] = new fabric.Point(x, y);
      return acc;
    }, {} as Record<Anchor, fabric.Point>);
  }

  _drawDebugInfo(
    anchorPositions: Omit<Record<Anchor, fabric.Point>, "auto">
  ): void {
    const rect = (pos: fabric.Point, color: string) =>
      new fabric.Rect({
        type: canvasObjectIds.alignmentLine, // To avoid being considered as zIndexable
        left: pos.x - 3,
        top: pos.y - 3,
        width: 5,
        height: 5,
        fill: color,
      });

    const anchors = [
      rect(anchorPositions.top, "red"),
      rect(anchorPositions.bottom, "red"),
      rect(anchorPositions.left, "red"),
      rect(anchorPositions.right, "red"),
    ];

    this.object.canvas.add(...anchors);
  }

  /**
   * Compute the anchor positions before they have been added to the canvas or
   * grouped.
   */
  _computeInitialAnchorPositions(): AnchorPositions & { center: fabric.Point } {
    const matrix = this.object.calcTransformMatrix();
    const invertedMatrix = fabric.util.invertTransform(matrix);
    const transform = fabric.util.qrDecompose(matrix);

    const width = this.object.width * transform.scaleX;
    const height = this.object.height * transform.scaleY;

    const xCenter = transform.translateX;
    const yCenter = transform.translateY;

    const center = new fabric.Point(xCenter, yCenter);

    // Attempt to use preComputed algorithm
    if (this._anchorAlgorithm === AnchorAlgorithm.preComputed) {
      if (!isShape(this.object)) {
        return assertUnreachable(
          this.object as never,
          "Cannot use preComputed algorithm for not shape objects"
        );
      }

      const { shape } = this.object;

      const untransformed = CollaboardObjectConnections._getPrecomputedAnchorPoint(
        shape.key
      );

      if (untransformed) {
        const scaleMatrix = [
          this.object.svgScale,
          0,
          0,
          this.object.svgScale,
          0,
          0,
        ];

        const scaled = {
          top: fabric.util.transformPoint(untransformed.top, scaleMatrix),
          right: fabric.util.transformPoint(untransformed.right, scaleMatrix),
          bottom: fabric.util.transformPoint(untransformed.bottom, scaleMatrix),
          left: fabric.util.transformPoint(untransformed.left, scaleMatrix),
        };

        this._dropZoneAnchorPositions = scaled;
        return {
          top: fabric.util.transformPoint(scaled.top, matrix),
          right: fabric.util.transformPoint(scaled.right, matrix),
          bottom: fabric.util.transformPoint(scaled.bottom, matrix),
          left: fabric.util.transformPoint(scaled.left, matrix),
          auto: center,
          center,
          width,
          height,
        };
      }
    }

    // Otherwise compute the anchor positions at runtime
    // This scenario is essential for dynamic objects such as ink paths
    const defaultAnchorPositions = {
      top: new fabric.Point(xCenter, yCenter - height / 2),
      right: new fabric.Point(xCenter + width / 2, yCenter),
      bottom: new fabric.Point(xCenter, yCenter + height / 2),
      left: new fabric.Point(xCenter - width / 2, yCenter),
      center,
    };

    const { top, right, bottom, left } = this._computeBestAnchorPositions(
      defaultAnchorPositions
    );

    // Store the unscaled positions for later use
    this._dropZoneAnchorPositions = {
      top: fabric.util.transformPoint(top, invertedMatrix),
      right: fabric.util.transformPoint(right, invertedMatrix),
      bottom: fabric.util.transformPoint(bottom, invertedMatrix),
      left: fabric.util.transformPoint(left, invertedMatrix),
      auto: center,
    };

    return {
      left,
      right,
      top,
      bottom,
      auto: center,
      center,
      width,
      height,
    };
  }

  /**
   * Based on the default positions compute the 'best' positions for the anchors.
   * Best means the closest non transparent point to the default anchor position.
   */
  _computeBestAnchorPositions(
    anchorPositions: AnchorPositions
  ): AnchorPositions {
    if (this._anchorAlgorithm === AnchorAlgorithm.useDefaults) {
      return anchorPositions;
    }

    const {
      top,
      right,
      bottom,
      left,
      center = new fabric.Point(0, 0),
    } = anchorPositions;

    const fn =
      this._anchorAlgorithm === AnchorAlgorithm.binarySearch
        ? this._findClosestNonTransparentPointBinarySearch
        : this._findClosestNonTransparentPointLinear;

    return {
      top: fn.call(this, top, center),
      right: fn.call(this, right, center),
      bottom: fn.call(this, bottom, center),
      left: fn.call(this, left, center),
    };
  }

  /**
   * BINARY SEARCH ALGORITHM
   *
   * Find the closest point between the start position and end position where
   * the object s NOT transparent. Optimized with binary search.
   */
  private _findClosestNonTransparentPointBinarySearch(
    p0: fabric.Point,
    p1: fabric.Point
  ): fabric.Point {
    const { canvas } = this.object;
    const obj = this.object;

    const { targetFindTolerance } = canvas;
    canvas.targetFindTolerance = 0;

    const vpt = canvas.viewportTransform;

    const wasCaching = obj.objectCaching;
    obj.objectCaching = true;
    canvas.skipOffscreen = false; // expensive, used only here to find correct anchor points on offscreen objects

    let start = 0; // begining line segment p0 -> p1
    let end = 1; // end of line segment p0 -> p1

    let midPoint = p0;
    let pt = 0; // previous value parameter t

    const eps = 0.005;

    const childObjects = isShape(this.object) ? this.object.getObjects() : [];

    let testObjects = [];

    while (start <= end) {
      const t = (start + end) / 2; // current parameter t (middle)

      if (Math.abs(pt - t) < eps) {
        break;
      }

      midPoint = p0.lerp(p1, t);

      const dx = (vpt[4] / vpt[0] + midPoint.x) * vpt[0];
      const dy = (vpt[5] / vpt[3] + midPoint.y) * vpt[3];

      // const isTransparent = canvas.isTargetTransparent(obj, dx, dy);

      testObjects =
        childObjects && childObjects.length ? childObjects : [this.object];
      const isNotTransparent = !this._isAnyObjectNotTransparent(
        testObjects,
        dx,
        dy
      );

      if (isNotTransparent) {
        start = t;
      } else {
        end = t;
      }

      pt = t;
    }

    canvas.targetFindTolerance = targetFindTolerance;

    obj.objectCaching = wasCaching;
    canvas.skipOffscreen = true;

    return new fabric.Point(midPoint.x + 1, midPoint.y + 1);
  }

  /**
   * LINEAR ALGORITHM
   *
   * Find the closest point between the start position and end position where
   * the object s NOT transparent.
   *
   * Beware - this is an expensive algorithm. Use sparingly.
   */
  private _findClosestNonTransparentPointLinear(
    startPosition: fabric.Point,
    endPosition: fabric.Point
  ): fabric.Point {
    const vpt = this.object.canvas.viewportTransform;
    const step = 1; // Move in three pixels at a time

    const result = {
      ...startPosition,
    };

    const deltaX = endPosition.x - startPosition.x;
    const deltaY = endPosition.y - startPosition.y;

    const steps = Math.round(
      Math.max(Math.abs(deltaX), Math.abs(deltaY)) / step
    );

    const { targetFindTolerance } = this.object.canvas;

    this.object.canvas.targetFindTolerance = 0;

    const childObjects = isShape(this.object) ? this.object.getObjects() : [];

    // Gradually move the points towards the center until they hit the object
    for (let i = 0; i < steps; i += 1) {
      const moveX = (deltaX / steps) * i;
      const moveY = (deltaY / steps) * i;

      const x = startPosition.x + moveX;
      const y = startPosition.y + moveY;

      const dx = (vpt[4] / vpt[0] + x) * vpt[0];
      const dy = (vpt[5] / vpt[3] + y) * vpt[3];

      // Test all objects to find the first point that is NOT transparent
      const testObjects =
        childObjects && childObjects.length ? childObjects : [this.object];
      const isNotTransparent = this._isAnyObjectNotTransparent(
        testObjects,
        dx,
        dy
      );

      if (isNotTransparent) {
        result.x = x;
        result.y = y;
        break;
      }
    }

    this.object.canvas.targetFindTolerance = targetFindTolerance;

    return result as fabric.Point;
  }

  /**
   * Check if any object is NOT transparent at this location.
   */
  private _isAnyObjectNotTransparent(
    objects: fabric.Object[],
    x: number,
    y: number
  ): boolean {
    return !!objects.find((object) => {
      return !object.canvas.isTargetTransparent(object, x, y);
    });
  }
}

export default CollaboardObjectConnections;
