import React, { ReactElement } from "react";
import { Flex, Box, BoxOwnProps, ThemeUIStyleObject } from "theme-ui";
import { NavLink, useLocation, NavLinkProps } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { routes } from "../../../const";
import Projects from "../../../icons/Projects";
import Profile from "../../../icons/Profile";
import Team from "../../../icons/Team";
import Plans from "../../../icons/Plans";
import Spaces from "../../../icons/Spaces";
import { usePlanDetails } from "../../licensing/usePlanDetails";
import { useUISettings } from "../../../reduxStore/uiSettings";
import { dynamicFeatureFlags } from "../../../tools/flags";
import { useIsPremium } from "../../licensing/useIsPremium";

const { projects, spaces, user, plans, team } = routes;

const NavLinkBox = Box as (props: BoxOwnProps & NavLinkProps) => ReactElement;

function MainNav(): ReactElement {
  const location = useLocation();
  const { t } = useTranslation();
  const { hasAccessToTeamPage, hasAccessToPlansPage } = usePlanDetails();
  const { isDynamicFeatureActive } = useUISettings();
  const isPremium = useIsPremium();

  const isSpacesEnabled =
    isDynamicFeatureActive(dynamicFeatureFlags.SPACES) && isPremium;

  return (
    <Flex as="nav" sx={{ gap: "0.5rem", py: [6] }}>
      <NavLinkBox
        as={NavLink}
        exact
        location={location}
        to={projects}
        sx={navStyle}
      >
        <Projects noShadow />
        {t(`routes.${projects}`)}
      </NavLinkBox>

      {isSpacesEnabled && (
        <NavLinkBox
          as={NavLink}
          exact
          location={location}
          to={spaces}
          sx={navStyle}
        >
          <Spaces noShadow />
          {t(`routes.${spaces}`)}
        </NavLinkBox>
      )}

      <NavLinkBox
        as={NavLink}
        exact
        location={location}
        to={user}
        sx={navStyle}
      >
        <Profile noShadow />
        {t(`routes.${user}`)}
      </NavLinkBox>

      {Boolean(hasAccessToPlansPage) && (
        <NavLinkBox
          as={NavLink}
          exact
          location={location}
          to={plans}
          sx={navStyle}
        >
          <Plans noShadow />
          {t(`routes.${plans}`)}
        </NavLinkBox>
      )}

      {hasAccessToTeamPage && (
        <NavLinkBox
          as={NavLink}
          exact
          location={location}
          to={team}
          sx={navStyle}
        >
          <Team noShadow />
          {t(`routes.${team}`)}
        </NavLinkBox>
      )}
    </Flex>
  );
}

const navStyle: ThemeUIStyleObject = {
  borderTopColor: "muted",
  borderTopStyle: "solid",
  borderTopWidth: "0.375em",
  color: "muted",
  display: "flex",
  flex: 1,
  fontWeight: 500,
  justifyContent: "center",
  marginTop: "3rem",
  marginBottom: "0.25rem",
  position: "relative",
  pt: [1],
  px: [2],
  py: [2],
  textDecoration: "none",
  transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

  "&:hover": {
    borderTopColor: "primary",
    color: "primary",
    "> svg": {
      fill: "primary",
    },
  },
  "&:visited": {
    borderTopColor: "muted",
    color: "muted",
  },
  "&:visited.active": {
    borderTopColor: "primary",
    color: "primary",
  },
  "&:visited:hover": {
    borderTopColor: "primary",
    color: "primary",
    ">svg": {
      fill: "primary",
    },
  },
  "&.active": {
    borderTopColor: "primary",
    color: "primary",
    ">svg": {
      fill: "primary",
    },
  },
  "> svg": {
    bottom: "100%",
    fill: "muted",
    position: "absolute",
    mb: [1],
    transition: "fill 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
};

export default MainNav;
