import React, { ReactElement } from "react";
import { Box, Flex, Grid, Text } from "theme-ui";
import { Formik, FormikConfig } from "formik";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import { updatedDiff } from "deep-object-diff";
import { isEmpty } from "ramda";
import InputField from "../../shared/forms/fields/InputField";
import ColorHex from "../../shared/color/ColorHex";
import ColorPicker from "../../shared/color/ColorPicker";
import { colorThemes } from "../../../const";
import { maxSpaceDescriptionLength } from "../../../validationSchemas/fieldRestrictions";
import SpaceFavourite from "./SpaceFavourite";

type Props = {
  space: Space;
  onInfoChange: (values: SpaceInfoValues) => void;
  onFavouriteChange: () => void;
};

export type SpaceInfoValues = {
  Name: string;
  Color: string;
};

export default function SpaceInfo({
  space,
  onInfoChange,
  onFavouriteChange,
}: Props): ReactElement {
  const { Space: spaceInfo, IsFavorite } = space;
  const { t } = useTranslation();

  const infoFormikProps: FormikConfig<SpaceInfoValues> = {
    initialValues: {
      Name: spaceInfo.Name,
      Color: spaceInfo.Color,
    },
    onSubmit: (values) => {
      const difference = updatedDiff(values, spaceInfo);
      const hasChanged = !isEmpty(difference);

      hasChanged && onInfoChange(values);
    },
    validationSchema: object().shape({
      Name: string()
        .trim()
        .max(
          maxSpaceDescriptionLength,
          t("clientError.tooLongMax", { max: maxSpaceDescriptionLength })
        )
        .required(t("clientError.required")),
      Color: string(), // Cannot have invalid hex values
    }),
    enableReinitialize: true,
  };

  return (
    <Formik {...infoFormikProps}>
      {({ values, setFieldValue, submitForm }) => {
        const onColorChange = (color: string) => {
          if (color === values.Color) {
            return;
          }

          setFieldValue("Color", color);
          submitForm();
        };

        return (
          <Grid
            columns={["repeat(auto-fit, minmax(24rem, 1fr))", "1fr 2fr"]}
            gap={[6]}
          >
            <Box>
              <Text sx={{ mb: [3] }}>{t("spaces.spaceName")}</Text>
              <InputField
                name="Name"
                placeholder={t("dialog.spaceName")}
                testId="space-edit-name"
                onBlur={submitForm}
              />

              <SpaceFavourite
                isFavourite={IsFavorite}
                onFavouriteChange={onFavouriteChange}
              />
            </Box>
            <Box>
              <Text sx={{ mb: [3] }}>{t("spaces.spaceColor")}</Text>
              <Flex
                sx={{
                  width: "100%",
                  flexWrap: "wrap",
                  gap: [4],
                }}
              >
                <Box sx={{ flexGrow: 1, minWidth: "24rem" }}>
                  <ColorPicker
                    color={values.Color}
                    theme={colorThemes.light}
                    isStaticPalette
                    onColorChange={onColorChange}
                  />
                </Box>
                <Box sx={{ flexShrink: 0, minWidth: "16rem" }}>
                  <Box>
                    <ColorHex
                      color={values.Color}
                      hideEyeDropper
                      vertical
                      testId="space-edit-color-hex"
                      onColorChange={onColorChange}
                    />
                  </Box>
                  <Flex sx={{ justifyContent: "center", mt: [3] }}>
                    <Text sx={{ color: "muted" }}>
                      {t("sidebars.shape.insertHex")}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Grid>
        );
      }}
    </Formik>
  );
}
