import { Form, Formik, FormikConfig } from "formik";
import React, { ReactElement, useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Box, Button, Flex, Text, ThemeUIStyleObject } from "theme-ui";
import { boolean, object, string } from "yup";
import { getAppName } from "../../../tools/customer";
import {
  isStaticFeatureActive,
  staticFeatureFlags,
} from "../../../tools/flags";
import { LogCategory } from "../../../tools/telemetry";
import { passwordSchema } from "../../../validationSchemas/password";
import EmailVerificationModalTemplate from "../../authentication/login/EmailVerificationModalTemplate";
import { useAuthentication } from "../../authentication/useAuthentication";
import { useLoggedInStatus } from "../../authentication/useLoggedInStatus";
import InputField from "../../shared/forms/fields/InputField";
import PasswordField from "../../shared/forms/fields/PasswordField";
import ToggleField from "../../shared/forms/fields/ToggleField";
import ProcessingButton from "../../shared/forms/ProcessingButton";
import { useErrorHandler } from "../../shared/hooks/useErrorHandler";
import { AcceptInvitationAsGuest, useInvite } from "./useInvite";

type FormValues = AcceptInvitationAsGuest & {
  GuestIdRequired: boolean;
  ProjectPasswordRequired: boolean;
  RegisterAsUser: boolean;
  RegisterWithPassword: string;
};

type RegisterFormValues = FormValues & {
  UserName: string;
  RegisterWithPassword: string;
  FirstName: string;
  TermsOfServiceAccepted: boolean;
};

type Props = {
  inviteDetails: ApiProjectInvitation;
  loginLink: string;
  token: string;
  tenant?: string;
};

const allowRegistration = !isStaticFeatureActive(
  staticFeatureFlags.REGISTRATION_DISABLED
);

const isToSDisabled = isStaticFeatureActive(
  staticFeatureFlags.DISABLE_REGISTRATION_TOS
);

function AcceptProjectInvitationForm({
  inviteDetails,
  loginLink,
  token,
  tenant,
}: Props): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();
  const { acceptInvitationAsGuest, acceptInvitationAsUser } = useInvite();
  const { isLoggedInAsUser, userProfile, logout } = useLoggedInStatus();
  const { fallbackErrorHandler } = useErrorHandler();
  const { autoLogin, register, emailVerificationModal } = useAuthentication();

  const [formValues, setFormValues] = useState<RegisterFormValues | undefined>(
    undefined
  );
  const [UserName, setUserName] = useState<string | undefined>(undefined);
  const [Password, setPassword] = useState<string | undefined>(undefined);

  const onSubmit = useCallback(
    async (values: FormValues) => {
      if (isRegisterFormValues(values)) {
        setFormValues(values);
        setUserName(values.Email);
        setPassword(values.RegisterWithPassword);

        try {
          await register({
            values: {
              UserName: values.Email,
              Password: values.RegisterWithPassword,
              FirstName: values.Name,
              TermsOfServiceAccepted: true,
            },
            bypassEmailVerification: false,
          });
        } catch (error) {
          fallbackErrorHandler(
            error,
            "Failed to register user from project invite",
            LogCategory.auth
          );
        }
        return;
      }

      if (isLoggedInAsUser) {
        await acceptInvitationAsUser(values);
        return;
      }

      await acceptInvitationAsGuest(values);
    },
    [
      acceptInvitationAsGuest,
      acceptInvitationAsUser,
      fallbackErrorHandler,
      isLoggedInAsUser,
      register,
    ]
  );

  const onEmailVerifySuccess = useCallback(async () => {
    if (UserName && Password) {
      try {
        await autoLogin({ UserName, Password, Tenant: tenant });
        formValues && (await acceptInvitationAsUser(formValues));
      } catch (error) {
        fallbackErrorHandler(
          error,
          "Failed to auto-login user from project invite",
          LogCategory.auth
        );
        // If auto-login fails take the user to the login link which will
        // redirect them back to this page
        history.push(loginLink);
      }
      emailVerificationModal.closeOnNextTick();
    }
  }, [
    UserName,
    Password,
    emailVerificationModal,
    autoLogin,
    tenant,
    formValues,
    acceptInvitationAsUser,
    fallbackErrorHandler,
    history,
    loginLink,
  ]);

  const onEmailVerifyError = useCallback(
    (error: unknown) =>
      fallbackErrorHandler(
        error,
        "Failed to verify email from invite registration",
        LogCategory.auth
      ),
    [fallbackErrorHandler]
  );

  const GuestIdRequired =
    !isLoggedInAsUser && inviteDetails.IsGuestIdentificationRequired;

  const formikProps: FormikConfig<FormValues> = {
    initialValues: {
      Token: token,
      Password: "",
      Name: "",
      Email: "",
      GuestIdRequired,
      ProjectPasswordRequired: inviteDetails.IsPasswordProtected,
      ReceiveNews: false,
      RegisterAsUser: false,
      RegisterWithPassword: "",
    },
    onSubmit,
    validationSchema: object().shape({
      Token: string().required(t("clientError.required")),
      Password: string().when("ProjectPasswordRequired", {
        is: true,
        then: (schema) => schema.min(1).required(t("clientError.required")),
      }),
      Name: string()
        .trim()
        .when(["GuestIdRequired"], {
          is: true,
          then: (schema) => schema.required(t("clientError.required")),
        }),
      Email: string()
        .email(t("clientError.emailNotValid"))
        .when(["RegisterAsUser", "ReceiveNews"], {
          is: (RegisterAsUser: boolean, ReceiveNews: boolean) =>
            RegisterAsUser || ReceiveNews,
          then: (schema) => schema.required(t("clientError.required")),
        }),
      GuestIdRequired: boolean(),
      ProjectPasswordRequired: boolean(),
      ReceiveNews: boolean(),
      RegisterAsUser: boolean(),
      RegisterWithPassword: string().when("RegisterAsUser", {
        is: true,
        then: () => passwordSchema.required(t("clientError.required")),
      }),
    }),
    validateOnMount: true,
  };

  return (
    <>
      <Formik {...formikProps}>
        {({ handleBlur, handleChange, isSubmitting, isValid, values }) => (
          <Flex as={Form} sx={formStyle}>
            {isLoggedInAsUser && userProfile && (
              <Box sx={loggedInBoxStyle}>
                <Text variant="default" sx={loggedInAsLabelStyle}>
                  {t("form.text.loggedInAs")}:
                </Text>
                <Flex sx={loggedInSplitStyle}>
                  <Box sx={usernameStyle}>
                    <Text variant="ellipsis" data-testid="logged-in-as">
                      {userProfile.UserName}
                    </Text>
                  </Box>
                  <Box>
                    <Button type="button" variant="cancel" onClick={logout}>
                      {t("form.button.logout")}
                    </Button>
                  </Box>
                </Flex>
              </Box>
            )}

            {GuestIdRequired && (
              <Flex sx={tabsStyle}>
                <InputField
                  name="Name"
                  required
                  autoComplete="given-name"
                  label={t("form.label.GuestName")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <InputField
                  name="Email"
                  autoComplete="email username"
                  label={t("form.label.GuestEmail")}
                  required={values.RegisterAsUser || values.ReceiveNews}
                />

                <ToggleField name="ReceiveNews" sx={toggleStyle} />

                {allowRegistration && (
                  <>
                    <ToggleField name="RegisterAsUser" sx={toggleStyle} />

                    {values.RegisterAsUser && (
                      <Box sx={passwordToggleStyle}>
                        <PasswordField
                          name="RegisterWithPassword"
                          autoComplete="new-password"
                          label={t("form.label.RegistrationPassword", {
                            appName: getAppName(),
                          })}
                          info={t("form.label.RegistrationInfo", {
                            appName: getAppName(),
                          })}
                          sx={{ display: "block" }}
                        />
                      </Box>
                    )}
                  </>
                )}
              </Flex>
            )}

            {values.ProjectPasswordRequired && (
              <PasswordField
                sx={passwordFieldStyle}
                name="Password"
                noValidation
                autoComplete="off"
                label={t("form.label.ProjectPassword")}
                info={t("form.label.ProjectPasswordInfo")}
              />
            )}

            <ProcessingButton
              isProcessing={isSubmitting}
              type="submit"
              disabled={!isValid || isSubmitting}
              sx={submitButtonStyle}
            >
              {t("invite.viewProject")}
            </ProcessingButton>

            {!isToSDisabled && (
              <Text sx={tosStyle}>
                <Trans
                  i18nKey="invite.terms"
                  values={{ appName: getAppName() }}
                >
                  {/* eslint-disable jsx-a11y/anchor-has-content */}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.collaboard.app/terms"
                  />
                </Trans>
              </Text>
            )}
          </Flex>
        )}
      </Formik>
      {UserName && (
        <EmailVerificationModalTemplate
          UserName={UserName}
          modalProps={emailVerificationModal}
          onSuccess={onEmailVerifySuccess}
          onError={onEmailVerifyError}
        />
      )}
    </>
  );
}

export default AcceptProjectInvitationForm;

const formStyle: ThemeUIStyleObject = {
  flexDirection: "column",
};

const tabsStyle: ThemeUIStyleObject = {
  flexDirection: "column",
  width: "100%",
  mt: [5],
};

const toggleStyle: ThemeUIStyleObject = {
  mb: [3],
};

const passwordToggleStyle: ThemeUIStyleObject = {
  backgroundColor: "var(--modalBox)",
  mt: [2],
  mb: [4],
  p: [4],
};

const passwordFieldStyle: ThemeUIStyleObject = {
  display: "block",
  mt: [4],
};

const submitButtonStyle: ThemeUIStyleObject = {
  mt: [4],
};

const tosStyle: ThemeUIStyleObject = {
  mb: [4],
  mt: [2],
};

const loggedInBoxStyle: ThemeUIStyleObject = {
  mb: [3],
  width: "100%",
};

const loggedInAsLabelStyle: ThemeUIStyleObject = {
  fontWeight: 600,
  fontSize: [4],
  mb: [1],
};

const loggedInSplitStyle: ThemeUIStyleObject = {
  alignItems: "center",
  justifyContent: "space-between",
};

const usernameStyle: ThemeUIStyleObject = { flex: 1 };

const isRegisterFormValues = (
  values: FormValues | RegisterFormValues
): values is RegisterFormValues => {
  return !!values.RegisterAsUser && !!values.RegisterWithPassword;
};
