import { BrushImageBrush } from "./BrushImageBrush";
import { EraserBrush } from "./EraserBrush";
import { HighlighterBrush } from "./HighlighterBrush";
import { NibBrush } from "./NibBrush";
import { PenBrush } from "./PenBrush";
import { PencilImageBrush } from "./PencilImageBrush";

import { BaseInkBrush } from "./BaseInkBrush";
import { BaseImageBrush } from "./BaseImageBrush";

import { BrushType } from "../../../../const";
import { BaseBrush } from "./BaseBrush";

export type InkBrush =
  | BrushImageBrush
  | HighlighterBrush
  | NibBrush
  | PenBrush
  | PencilImageBrush;

export type ImageInkBrush = BrushImageBrush | PencilImageBrush;

export type InkBrushOrEraser = InkBrush | EraserBrush;

export function createNewBrush(
  canvas: fabric.CollaboardCanvas,
  brushType: BrushType,
  options: fabric.CollaboardBrushOptions
): InkBrush;

export function createNewBrush(
  canvas: fabric.CollaboardCanvas,
  brushType: BrushType,
  options: fabric.CollaboardEraserBrushOptions
): EraserBrush;

export function createNewBrush(
  canvas: fabric.CollaboardCanvas,
  brushType: BrushType,
  options: fabric.CollaboardBrushOptions | fabric.CollaboardEraserBrushOptions
): InkBrush | EraserBrush {
  switch (brushType) {
    case BrushType.rubber: {
      return new EraserBrush(
        canvas,
        options as fabric.CollaboardEraserBrushOptions
      );
    }
    case BrushType.nib: {
      return new NibBrush(canvas, options as fabric.CollaboardBrushOptions);
    }
    case BrushType.pencil: {
      return new PencilImageBrush(
        canvas,
        options as fabric.CollaboardBrushOptions
      );
    }
    case BrushType.brush: {
      return new BrushImageBrush(
        canvas,
        options as fabric.CollaboardBrushOptions
      );
    }
    case BrushType.highlighter: {
      return new HighlighterBrush(
        canvas,
        options as fabric.CollaboardBrushOptions
      );
    }
    default:
    case BrushType.pen: {
      return new PenBrush(canvas, options as fabric.CollaboardBrushOptions);
    }
  }
}

export const isImageInkBrush = (
  brush: InkBrushOrEraser
): brush is ImageInkBrush => {
  return brush instanceof BaseImageBrush;
};

export const isInkBrush = (
  brush: InkBrushOrEraser | fabric.PencilBrush | null
): brush is InkBrush => {
  return brush instanceof BaseInkBrush;
};

export const isBaseBrush = (
  brush: BaseBrush | fabric.PencilBrush | null
): brush is BaseBrush => {
  return brush instanceof BaseBrush;
};

export const isEraserBrush = (
  brush: InkBrushOrEraser | fabric.PencilBrush | null
): brush is EraserBrush => {
  return brush instanceof EraserBrush;
};
