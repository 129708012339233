import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function EmailConfirmation(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M34.91,12.79h-14c0-.38-.08-.76-.14-1.12a5,5,0,0,0-5-4,5,5,0,0,0-5,4c-.4,2.4-.26,5.51,1.34,7.47S13,21.9,13,21.9C11,24.43,8,23.68,6,25.1s-1.51,4.67-1.51,4.67h22.6a6.9,6.9,0,0,0-.26-2.88h8.11a.89.89,0,0,0,.8-.94V13.73A.89.89,0,0,0,34.91,12.79ZM21,14.2h12.6l-7,7.29-5.65-5.86A13.55,13.55,0,0,0,21,14.2ZM34.51,25.48H26a3.16,3.16,0,0,0-.44-.38c-2-1.42-5.07-.67-7-3.2l0-.08V20.73a4.93,4.93,0,0,1,.94-1.59,6.22,6.22,0,0,0,1.05-2L26.12,23a.52.52,0,0,0,.79,0l7.6-7.87Z" />
    </Icon>
  );
}
export default EmailConfirmation;
