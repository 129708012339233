// Get your polyfills from https://github.com/es-shims
import flatMap from "array.prototype.flatmap";
import fromEntries from "object.fromentries";
import promiseFinally from "promise.prototype.finally";

/**
 * According to the es-shim API interface calling `.shim()` will only install
 * the polyfill globally if the function is not available globally. Therefore
 * we don't need to check it manually ourselves.
 *
 * https://github.com/es-shims/api
 */
fromEntries.shim();
promiseFinally.shim();
flatMap.shim();
