import type {
  InitialDataForNewJoinToPresentationMessage,
  NudgeForAttentionMessage,
  ScreenPosZoomChangedMessage,
  StartPresentingMessage,
  StopPresentingMessage,
  ThumbnailPageChangedMessage,
  VideoPlayerStartStopCalledMessage,
} from "../../../api/signalR/message.types";

export enum PresentationViewerActionType {
  PRESENTATION_STARTED = "PRESENTATION_STARTED",
  PRESENTATION_STOPPED = "PRESENTATION_STOPPED",
  ON_NUDGE_FOR_ATTENTION = "ON_NUDGE_FOR_ATTENTION",
  PAN_TO_ATTENTION_POINT = "PAN_TO_ATTENTION_POINT",
  PRESENTATION_VIEWPORT_CHANGE = "PRESENTATION_VIEWPORT_CHANGE",
  ON_JOINED_PRESENTATION = "ON_JOINED_PRESENTATION",
  ON_MEDIA_CONTROL = "ON_MEDIA_CONTROL",
  ON_DOCUMENT_CONTROL = "ON_DOCUMENT_CONTROL",
}

export type PresentationStartedPayload = {
  presentation: StartPresentingMessage;
  isPresenter: boolean;
};

export const presentationStartedAction = (
  payload: PresentationStartedPayload
) =>
  ({
    type: PresentationViewerActionType.PRESENTATION_STARTED,
    payload,
  } as const);

type PresentationStoppedPayload = {
  presentation: StopPresentingMessage;
};

export const presentationStoppedAction = (
  payload: PresentationStoppedPayload
) =>
  ({
    type: PresentationViewerActionType.PRESENTATION_STOPPED,
    payload,
  } as const);

export type CanvasDimensions = { width: number; height: number };

type OnNudgeForAttentionPayload = {
  presentation: NudgeForAttentionMessage;
  myCanvas: CanvasDimensions;
};

export const onNudgeForAttentionAction = (
  payload: OnNudgeForAttentionPayload
) =>
  ({
    type: PresentationViewerActionType.ON_NUDGE_FOR_ATTENTION,
    payload,
  } as const);

type PresentationViewportChangePayload = {
  presentation: ScreenPosZoomChangedMessage;
  myCanvas: CanvasDimensions;
};

export const presentationViewportChangeAction = (
  payload: PresentationViewportChangePayload
) =>
  ({
    type: PresentationViewerActionType.PRESENTATION_VIEWPORT_CHANGE,
    payload,
  } as const);

type OnJoinedPresentationPayload = {
  presentation: InitialDataForNewJoinToPresentationMessage;
  isPresenter: boolean;
  myCanvas: CanvasDimensions;
};

export const onJoinedPresentationAction = (
  payload: OnJoinedPresentationPayload
) =>
  ({
    type: PresentationViewerActionType.ON_JOINED_PRESENTATION,
    payload,
  } as const);

export const onMediaControlAction = (
  payload: VideoPlayerStartStopCalledMessage
) =>
  ({
    type: PresentationViewerActionType.ON_MEDIA_CONTROL,
    payload,
  } as const);

export const onDocumentControlAction = (payload: ThumbnailPageChangedMessage) =>
  ({
    type: PresentationViewerActionType.ON_DOCUMENT_CONTROL,
    payload,
  } as const);

export type PresentationStartedAction = ReturnType<
  typeof presentationStartedAction
>;
export type PresentationStoppedAction = ReturnType<
  typeof presentationStoppedAction
>;
export type OnNudgeForAttentionAction = ReturnType<
  typeof onNudgeForAttentionAction
>;
export type PresentationViewportChangeAction = ReturnType<
  typeof presentationViewportChangeAction
>;
export type OnJoinedPresentationAction = ReturnType<
  typeof onJoinedPresentationAction
>;
export type OnMediaControlAction = ReturnType<typeof onMediaControlAction>;
export type OnDocumentControlAction = ReturnType<
  typeof onDocumentControlAction
>;

export type PresentationViewerAction =
  | PresentationStartedAction
  | PresentationStoppedAction
  | OnNudgeForAttentionAction
  | PresentationViewportChangeAction
  | OnJoinedPresentationAction
  | OnMediaControlAction
  | OnDocumentControlAction;
