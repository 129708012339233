import { useTranslation } from "react-i18next";
import { prop, sortBy } from "ramda";
import { getCustomerConfiguration } from "../../../../tools/customer";

const { fullFeaturesGrid } = getCustomerConfiguration();

export const usePlanFeatures = ({ data }) => {
  const { t } = useTranslation();
  const isFreePlan = data.name === "free";
  const featuresToDisplay = fullFeaturesGrid
    ? data.features.all
    : data.features.own;

  const valueFeaturesToDisplay =
    fullFeaturesGrid && !isFreePlan
      ? data.features.values
      : data.features.values.filter(({ inCard }) => inCard);

  const features = [
    ...featuresToDisplay
      .filter(({ inCard }) => inCard)
      .map((feature) => ({
        order: feature.order,
        displayValue: t(`plans.features.${feature.labelKey}`),
      })),

    ...valueFeaturesToDisplay.map((feature) => ({
      order: feature.order,
      displayValue: t(
        `plans.features.${feature.labelKey}${
          feature.value[0] === null ? "Unlimited" : "Card"
        }`,
        {
          number: feature.value[0],
        }
      ),
    })),
  ];

  const sortedFeatures = sortBy(prop("order"), features);

  return { features: sortedFeatures };
};
