import React, { ReactElement } from "react";
import { Flex } from "theme-ui";
import { useTranslation } from "react-i18next";
import { byUserFlex } from "../gridFlex";

function ProjectListHeader(): ReactElement {
  const { t } = useTranslation();

  return (
    <Flex
      sx={{
        borderTop: "5px solid",
        backgroundColor: "black",
        color: "white",
        fontSize: 14,
        fontWeight: 600,
        flexWrap: "wrap",
        "> div": {
          p: [4],
          lineHeight: "var(--gridTile)",
          textAlign: ["left", null, "center"],
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
          alignItems: "center",
        },
      }}
    >
      <Flex sx={{ flex: byUserFlex.owner }}>{t("project.owner")}</Flex>

      <Flex
        sx={{
          flex: byUserFlex.email,
          textAlign: "left",
        }}
      >
        {t("project.email")}
      </Flex>

      <Flex sx={{ flex: byUserFlex.registration }}>
        {t("project.registrationDate")}
      </Flex>

      <Flex sx={{ flex: byUserFlex.lastActivity }}>
        {t("project.lastActivity")}
      </Flex>
      <Flex sx={{ flex: byUserFlex.actions }} />
    </Flex>
  );
}

export default ProjectListHeader;
