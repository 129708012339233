export const isFulfilled = <T>(
  result: PromiseSettledResult<T>
): result is PromiseFulfilledResult<T> => {
  return result.status === "fulfilled";
};

export const getFulfilledValues = <T>(
  results: PromiseSettledResult<T>[]
): T[] => results.filter(isFulfilled).map((result) => result.value);

export const wait = (timeout: number): Promise<void> =>
  new Promise((res) => setTimeout(res, timeout));

export const delay = <T>(
  callback: () => T | Promise<T>,
  timeout = 100
): Promise<T> => wait(timeout).then(callback);

type ExponentialConfig = {
  onRetry?: (worker: number) => void;
  shouldRetry?: (error: Error) => boolean;
  attemptLimit?: number;
};

/**
 * @param {Function} promise
 * @param {Object} options
 * @param {Function=} options.shouldRetry
 * @param {Function=} options.onRetry
 * @param {number=} options.attemptLimit
 */
export const exponential = <T>(
  promise: () => Promise<T>,
  { onRetry, shouldRetry, attemptLimit = 10 }: ExponentialConfig = {}
): Promise<T> =>
  new Promise((resolve, reject) => {
    let attemptCount = 0;

    const retry = (delay = 0): void => {
      const worker = window.setTimeout(() => {
        attemptCount += 1;

        promise()
          .then(resolve)
          .catch((error: Error) =>
            attemptCount >= attemptLimit || (shouldRetry && !shouldRetry(error))
              ? reject(error)
              : retry(delay ? delay * 2 : 1000)
          );
      }, delay);
      onRetry && onRetry(worker);
    };

    retry();
  });

export const sequentially = async <T, P>(
  toPromise: (element: T) => Promise<P>,
  elements: T[]
): Promise<P[]> => {
  const results: P[] = [];
  await elements.reduce(async (sequence, element) => {
    await sequence;
    results.push(await toPromise(element));
  }, Promise.resolve());

  return results;
};
