import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getUISettings } from "../../api";
import { ApiLanguageLabel } from "../../const";
import { languages } from "../../i18n";
import { DefaultTheme } from "../../themes/default";
import { isError, stringifyError } from "../../tools/errors";
import { errorToast } from "../../tools/errorToast";
import { ApiLanguage } from "../../types/enum";
import { getTranslationValue } from "./translation";
import {
  loadUISettingsDoneAction,
  resetUISettingsAction,
} from "./uiSettings.actions";
import { selectTheme, UISettingsState } from "./uiSettings.reducer";

export type AppLanguage = {
  value: ApiLanguage;
  label: ValuesOf<typeof ApiLanguageLabel>;
};

type UseUISettingsResult = {
  getLogoConfig: () => LogoConfig | null;
  getTheme: () => DefaultTheme;
  getTranslation: (key: string) => string;
  getLanguages: () => {
    appLanguages: AppLanguage[];
    supportedLanguages: ApiLanguage[];
  };
  getDynamicFeatureConfig: <T = unknown>(
    key: keyof RuntimeConfigDynamicFeatureConfig
  ) => T | undefined;
  isDynamicFeatureActive: (
    flag: keyof RuntimeConfigDynamicFeatureFlags
  ) => boolean;
  loadUISettings: (tenant?: string) => Promise<void>;
  resetUISettings: () => void;
};

export const useUISettings = (): UseUISettingsResult => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector<ApplicationGlobalState, UISettingsState>(
    (store) => store.uiSettings
  );
  const { dynamicFeatures, labels, logo } = state;
  const theme = useSelector(selectTheme);
  const { resolvedLanguage } = i18n;

  const getLogoConfig = useCallback(() => {
    return logo;
  }, [logo]);

  const getTheme = useCallback(() => {
    return theme;
  }, [theme]);

  const getTranslation = useCallback(
    (key: string) => {
      return getTranslationValue(key, labels, resolvedLanguage as ApiLanguage);
    },
    [labels, resolvedLanguage]
  );

  const getLanguages = useCallback(() => {
    return {
      supportedLanguages: languages,
      appLanguages: languages.map((languageKey) => ({
        label: ApiLanguageLabel[languageKey],
        value: languageKey,
      })),
    };
  }, []);

  const getDynamicFeatureConfig = useCallback(
    <T = unknown>(flag: keyof RuntimeConfigDynamicFeatureConfig): T => {
      // TODO: Move config flags to a separate key
      return ((dynamicFeatures as RuntimeConfigDynamicFeatureConfig)[
        flag
      ] as unknown) as T;
    },
    [dynamicFeatures]
  );

  const isDynamicFeatureActive = useCallback(
    (flag: keyof RuntimeConfigDynamicFeatureFlags) => {
      return !!(dynamicFeatures as RuntimeConfigDynamicFeatureFlags)[flag];
    },
    [dynamicFeatures]
  );

  const loadUISettings = useCallback(
    async (tenant?: string) => {
      try {
        const settings = await getUISettings(tenant);
        dispatch(loadUISettingsDoneAction(settings));
      } catch (err) {
        errorToast(isError(err) ? err : stringifyError(err));
      }
    },
    [dispatch]
  );

  const resetUISettings = useCallback(() => {
    dispatch(resetUISettingsAction());
  }, [dispatch]);

  return {
    getDynamicFeatureConfig,
    getLogoConfig,
    getLanguages,
    getTheme,
    getTranslation,
    isDynamicFeatureActive,
    loadUISettings,
    resetUISettings,
  };
};
