import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function RemoveAvatar(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M24.38,23.46c-1-.7-2.52-.33-3.47-1.59,0,0-.35-.39.44-1.36A4.87,4.87,0,0,0,22,16.8a2.56,2.56,0,0,0-5,0,4.86,4.86,0,0,0,.66,3.71c.8,1,.44,1.36.44,1.36-.95,1.26-2.47.89-3.46,1.59a2.65,2.65,0,0,0-.75,2.32H25.13A2.65,2.65,0,0,0,24.38,23.46Z" />
      <polygon points="29.34 9.06 27.57 10.83 25.8 9.06 25.09 9.77 26.86 11.54 25.09 13.31 25.8 14.02 27.57 12.25 29.34 14.02 30.05 13.31 28.28 11.54 30.05 9.77 29.34 9.06" />
      <path d="M10.22,16.26a.65.65,0,0,0,.65-.65V12.34h3.27a.65.65,0,1,0,0-1.3H10.22a.65.65,0,0,0-.65.65v3.92A.65.65,0,0,0,10.22,16.26Z" />
      <path d="M14.14,29.33H10.87V26.07a.65.65,0,1,0-1.3,0V30a.65.65,0,0,0,.65.65h3.92a.65.65,0,0,0,.65-.65A.66.66,0,0,0,14.14,29.33Z" />
      <path d="M28.52,25.41a.66.66,0,0,0-.65.66v3.26H24.6a.67.67,0,0,0-.66.66.66.66,0,0,0,.66.65h3.92a.65.65,0,0,0,.65-.65V26.07A.66.66,0,0,0,28.52,25.41Z" />
      <path d="M27.48,17.2a5.57,5.57,0,1,1,5.58-5.57A5.58,5.58,0,0,1,27.48,17.2Zm0-10.14a4.57,4.57,0,1,0,4.58,4.57A4.58,4.58,0,0,0,27.48,7.06Z" />
    </Icon>
  );
}
export default RemoveAvatar;
