import { uniqBy } from "ramda";
import SimplePeer from "simple-peer";
import { NIL as emptyUuid, v4 as uuid } from "uuid";
import { postNewBigInk } from "..";
import { blobStates, canvasObjectIds } from "../../const";
import { InternalError } from "../../errors/InternalError";
import { SignalRError } from "../../errors/signalRError";
import { TimedSessionState } from "../../reduxStore/canvas/timedSession/timedSession.reducer";
import {
  objectTypes,
  tileStatusToBatchActionInfo,
  tileTypes,
} from "../../studio/utils/objectConverter";
import { chunkItemsByByteSizes } from "../../tools";
import { isError, stringifyError } from "../../tools/errors";
import { runtimeConfig } from "../../tools/runtimeConfig";
import {
  LogCategory,
  onErrorToLog,
  trackEventToLog,
} from "../../tools/telemetry";
import { isDefined, splitBy } from "../../tools/utils";
import {
  ApiPermission,
  BatchTileType,
  RelationChangeAction,
} from "../../types/enum";
import { signalRWebRTCSignalMessageType } from "../../webrtc";
import type {
  DeleteTileMessage,
  GroupedTile,
  InkTileContent,
  PinMessage,
  PinnedTile,
  PostNewMessage,
  PostTileBatchActionMessage,
  PostTilePropertyMessage,
  ScreenCoordinates,
  TileBatchActionItemInfo,
  TileGroupMessage,
  TileIdentity,
  TileIndex,
  TileIndexesMessage,
  TilePropertyDelta,
  TileRelation,
  TileStatus,
  TileStatusWithProjectId,
} from "./message.types";
import { PostMessageName } from "./protobuf.codecs";
import {
  InvokeMessageMap,
  SignalRProtobufClient,
} from "./SignalRProtobufClient";

export type PostTileBatchActionPayload = {
  addedTiles?: TileStatus[];
  restoredTiles?: TileStatus[];
  restoredRelations?: TileRelation[];
  groupedTiles?: TileStatus[];
  ungroupedTiles?: TileStatus[];
  deletedTiles?: TileStatus[];
  lockedTiles?: TileIdentity[];
};

/**
 * This wrapper handles the shaping and chunking of messages.
 */
export class SignalRMessaging extends SignalRProtobufClient {
  public syncIdToRelationIdMap = new Map<string, string>();

  /**
   * Inform the server that a tile has been deleted.
   *
   * @chunked
   */
  public postDeleteTile(tileIds: string[]): Promise<void[]> {
    const messagePerTile: DeleteTileMessage[] = tileIds.map((tileId) => {
      return {
        TileIds: [tileId],
      };
    });

    const messageType = PostMessageName.DeleteTileMessage;
    const chunkedMessages = this.chunkMessagePayload(
      messageType,
      messagePerTile,
      tileIds
    );

    return this.invokeChunkedMessage(
      messageType,
      chunkedMessages.map((chunk) => {
        return {
          message: {
            TileIds: chunk,
          },
        };
      })
    );
  }

  /**
   * Log the user into a project.
   *
   * If you do not specify a projectId the current projectId will be read from
   * the redux state.
   *
   * @atomic - do not chunk
   */
  public postLoginProject(projectId?: number): Promise<void> {
    return this.invokeMessage(
      PostMessageName.LogInProjectMessage,
      projectId
        ? {
            Base: {
              ProjectId: projectId,
            },
          }
        : {}
    );
  }

  /**
   * Log the user out of a project.
   *
   * @atomic - do not chunk
   */
  public postLogoutProject(): Promise<void> {
    return this.invokeMessage(PostMessageName.LogOutProjectMessage, {});
  }

  /**
   * Create new tiles.
   *
   * @chunked
   */
  public async postNew(tiles: TileStatus[]): Promise<void> {
    if (!tiles.length) {
      return;
    }

    if (!this.projectId) {
      throw new SignalRError("Project ID not set");
    }

    const tilesWithProjectId: TileStatusWithProjectId[] = tiles.map((tile) => {
      return {
        ...tile,
        ProjectId: String(this.projectId),
      };
    });

    const [largeInkPaths, otherTiles] = splitBy(
      tilesWithProjectId,
      isTileLargeInkPath
    );

    const messagePerTile: PostNewMessage[] = otherTiles.map((tile) => {
      return {
        Tiles: [tile],
      };
    });

    const messageType = PostMessageName.NewMessage;
    const chunkedMessages = this.chunkMessagePayload(
      messageType,
      messagePerTile,
      otherTiles
    );

    const chunkedRequest = this.invokeChunkedMessage(
      messageType,
      chunkedMessages.map((chunk) => {
        return {
          message: {
            Tiles: chunk,
          },
          config: {
            // Delay is required because server processes these asynchronously
            resolveDelay: Math.max(400, chunk.length * 60),
          },
        };
      })
    );

    const largeInkRequests = largeInkPaths.map((inkPath) => {
      return postNewBigInk(String(this.projectId), inkPath);
    });

    this.trackTilesEvent("tile-create", tiles);

    await Promise.all([chunkedRequest, ...largeInkRequests]);
  }

  /**
   * Send SimplePeer (WebRTC) signalling data to another user.
   *
   * @atomic - do not chunk
   */
  public postNotifyUserOfAvailableMessage(
    SenderConnectionId: string,
    RecipientConnectionId: string,
    signalData: SimplePeer.SignalData
  ): Promise<void> {
    return this.invokeMessage(PostMessageName.GenericAvailableMessage, {
      SenderConnectionId,
      RecipientConnectionId,
      MessageType: signalRWebRTCSignalMessageType,
      Payload: JSON.stringify(signalData),
    });
  }

  /**
   * Send pingback request.
   *
   * @TODO - Confirm if this is necessary.
   *
   * @atomic - do not chunk
   */
  public postPingbackRequest(): Promise<void> {
    return this.invokeMessage(PostMessageName.PingbackMessage, {});
  }

  /**
   * Inform server of pinning changes.
   *
   * @chunked
   */
  public postPinningActivity(pinnedTiles: PinnedTile[]): Promise<void[]> {
    const messagePerPin: PinMessage[] = pinnedTiles.map((pinnedTile) => {
      return {
        PinnedTiles: [pinnedTile],
      };
    });

    const messageType = PostMessageName.PinMessage;
    const chunkedMessages = this.chunkMessagePayload(
      messageType,
      messagePerPin,
      pinnedTiles
    );

    return this.invokeChunkedMessage(
      messageType,
      chunkedMessages.map((chunk) => {
        return {
          message: {
            PinnedTiles: chunk,
          },
        };
      })
    );
  }

  /**
   * Change the background color of the project.
   *
   * @atomic - do not chunk
   */
  public postProjectBackgroundChanged(BackgroundColor: string): Promise<void> {
    return this.invokeMessage(PostMessageName.BackgroundMessage, {
      BackgroundColor,
    });
  }

  /**
   * Change the permissions of a project participant.
   *
   * @atomic - do not chunk
   */
  public postProjectParticipantChanged(
    ProjectId: number,
    ParticipantUserName: string,
    Permission: ApiPermission
  ): Promise<void> {
    return this.invokeMessage(PostMessageName.ProjectParticipantMessage, {
      Base: {
        ProjectId,
      },
      ParticipantUserName,
      Permission,
    });
  }

  /**
   * Inform presentation viewers that the presenter has changed their viewport
   * or zoom level.
   *
   * @atomic - do not chunk
   */
  public postScreenPosZoomChanged(
    ZoomLevel: number,
    ScreenCoordinates: ScreenCoordinates
  ): Promise<void> {
    return this.invokeMessage(PostMessageName.ScreenPosZoomChangedMessage, {
      ScreenCoordinates: {
        X: ScreenCoordinates.X,
        Y: ScreenCoordinates.Y,
        Width: ScreenCoordinates.Width,
        Height: ScreenCoordinates.Height,
      },
      ZoomLevel,
    });
  }

  /**
   * Inform presentation viewers that the presenter has changed the page of
   * a thumbnail.
   *
   * @atomic - do not chunk
   */
  public postThumbnailPageChanged(
    TileId: string,
    NewPageNumber: number
  ): Promise<void> {
    return this.invokeMessage(PostMessageName.ThumbnailPageChangedMessage, {
      TileId,
      NewPageNumber,
    });
  }

  /**
   * Send a message to a update tiles in a transaction / batch.
   *
   * @atomic - do not chunk
   */
  public postTileBatchAction(
    type: string,
    tiles: PostTileBatchActionPayload
  ): Promise<void> {
    const {
      addedTiles = [],
      restoredTiles = [],
      restoredRelations = [],
      groupedTiles = [],
      ungroupedTiles = [],
      deletedTiles = [],
      lockedTiles = [],
    } = tiles;

    // First add the tiles which contain more properties to the batch tiles
    const uniqueGroupingTiles = uniqBy((tile) => tile.TileId, [
      ...addedTiles,
      ...groupedTiles,
      ...ungroupedTiles,
    ]);

    const indexByTileId: Map<string, number> = new Map();
    const batchTiles: TileBatchActionItemInfo[] = uniqueGroupingTiles.map(
      (tile, index) => {
        !indexByTileId.has(tile.TileId) &&
          indexByTileId.set(tile.TileId, index);

        return tileStatusToBatchActionInfo(tile);
      }
    );

    // Then add simple identity tiles if not already present in the batch tiles
    const uniqueIdentityTiles = uniqBy((tile) => tile.TileId, [
      ...restoredTiles,
      ...deletedTiles,
      ...lockedTiles,
    ]);

    uniqueIdentityTiles.forEach((tile) => {
      if (indexByTileId.has(tile.TileId)) {
        return;
      }

      const batchTile: TileBatchActionItemInfo = {
        Id: tile.TileId,
        Type: BatchTileType.Identity,
      };

      indexByTileId.set(tile.TileId, batchTiles.length);
      batchTiles.push(batchTile);
    });

    const tilesToIndex = (tiles: TileIdentity[]) =>
      tiles.map((tile) => indexByTileId.get(tile.TileId)).filter(isDefined);

    const message: PostTileBatchActionMessage = {
      Type: type,
      Added: tilesToIndex(addedTiles),
      Restored: tilesToIndex(restoredTiles),
      RestoredRelationIds: restoredRelations
        .map((relation) => relation.Id)
        .filter(isDefined),
      Grouped: tilesToIndex(groupedTiles),
      Ungrouped: tilesToIndex(ungroupedTiles),
      Deleted: tilesToIndex(deletedTiles),
      Locked: tilesToIndex(lockedTiles),
      Tiles: batchTiles,
    };

    /**
     * The resolve delay is used to allow the server some time to process the
     * request before we send any further actions.
     *
     * The delay happens AFTER the message is sent, so it will pause following
     * messages slightly.
     *
     * @TODO #7526 - The queuing of work should be handled on the server so we
     * don't need to implement these arbitrary delays on the client side.
     */
    const resolveDelay = Math.min(Math.max(400, batchTiles.length * 60), 5000);

    return this.invokeMessage(PostMessageName.TileBatchActionMessage, message, {
      resolveDelay,
    });
  }

  /**
   * Inform the server that the tile zIndexes have changed.
   *
   * @chunked
   */
  public postTileIndexesChanged(tiles: TileIndex[]): Promise<void[]> {
    const messagePerTile: TileIndexesMessage[] = tiles.map((tile) => {
      return {
        TileIndexes: [tile],
      };
    });

    const messageType = PostMessageName.TileIndexesMessage;
    const chunkedMessages = this.chunkMessagePayload(
      messageType,
      messagePerTile,
      tiles
    );

    return this.invokeChunkedMessage(
      messageType,
      chunkedMessages.map((chunk) => {
        return {
          message: {
            TileIndexes: chunk,
          },
        };
      })
    );
  }

  /**
   * Inform the server that the tile properties have changed.
   *
   * @chunked
   */
  public async postTilePropertyUpdated(
    deltas: TilePropertyDelta[]
  ): Promise<void[]> {
    if (!deltas.length) {
      onErrorToLog(
        new InternalError("Attempted to call PostPropertyUpdated with no data"),
        LogCategory.history
      );
      return Promise.resolve([]);
    }

    const messagePerTile: PostTilePropertyMessage[] = deltas.map(
      (tileDelta) => {
        return {
          Deltas: [tileDelta],
        };
      }
    );

    const messageType = PostMessageName.TilePropertyMessage;
    const chunkedMessages = this.chunkMessagePayload(
      messageType,
      messagePerTile,
      deltas
    );

    return this.invokeChunkedMessage(
      messageType,
      chunkedMessages.map((chunk) => {
        return {
          message: {
            Deltas: chunk,
          },
        };
      })
    );
  }

  /**
   * Inform the server that relations have been created, modified or deleted.
   *
   * @NOTE In order assign new relations their RelationId we need to use
   * SyncId to identify the relation from the followup
   * `NotifyTileRelationChanged` message.
   *
   * @atomic - do not chunk
   */
  public postTileRelationChanged(
    relations: TileRelation[],
    action: RelationChangeAction
  ): Promise<void[]> {
    if (!relations.length) {
      onErrorToLog(
        new InternalError(
          "Attempted to call PostTileRelationChanged with no data"
        ),
        LogCategory.history
      );
      return Promise.resolve([]);
    }

    const isAdding = action === RelationChangeAction.Add;
    const isDeleting = action === RelationChangeAction.Delete;

    return Promise.all(
      relations.map((relation) => {
        const SyncId = uuid();

        if (isAdding) {
          this.syncIdToRelationIdMap.set(SyncId, relation.uuid);

          if (runtimeConfig.logSignalRMessages) {
            trackEventToLog(LogCategory.signalR, {
              subcategory: "connection-create",
            });
          }
        }

        if (isDeleting && runtimeConfig.logSignalRMessages) {
          trackEventToLog(LogCategory.signalR, {
            subcategory: "connection-delete",
          });
        }

        return this.invokeMessage(PostMessageName.TileRelationMessage, {
          Relation: {
            uuid: relation.uuid,
            Id: relation.Id,
            TileId: relation.TileId,
            RelatedTileId: relation.RelatedTileId,
            Style: relation.Style,
            AnchorOrigin: relation.AnchorOrigin,
            AnchorDestination: relation.AnchorDestination,
            SymbolOrigin: relation.SymbolOrigin,
            SymbolDestination: relation.SymbolDestination,
            Color: relation.Color,
            Thickness: relation.Thickness,
            Line: relation.Line,
            SyncId,
          },
          RelationDeleted: isDeleting,
        });
      })
    );
  }

  /**
   * Inform the server that the tile ordering within a container has changed.
   *
   * This is used for changing the stacking order within groups and stacks.
   *
   * @chunked
   */
  public postTileReorder(tiles: GroupedTile[]): Promise<void[]> {
    const messagePerTile: TileGroupMessage[] = tiles.map((tile) => {
      return {
        GroupedTiles: [tile],
      };
    });

    const messageType = PostMessageName.TileGroupMessage;
    const chunkedMessages = this.chunkMessagePayload(
      messageType,
      messagePerTile,
      tiles
    );

    return this.invokeChunkedMessage(
      messageType,
      chunkedMessages.map((chunk) => {
        return {
          message: {
            GroupedTiles: chunk,
          },
        };
      })
    );
  }

  /**
   * Inform a newly-joined user that a presentation is running.
   *
   * @atomic - do not chunk
   */
  public postUpdateNewJoinedForPresentation(info: {
    NewJoinerConnectionId: string;
    Presenter: string;
    ScreenCoordinates: ScreenCoordinates;
    ZoomLevel: number;
  }): Promise<void> {
    const {
      NewJoinerConnectionId,
      Presenter,
      ScreenCoordinates,
      ZoomLevel,
    } = info;
    return this.invokeMessage(
      PostMessageName.InitialDataForNewJoinToPresentationMessage,
      {
        NewJoinerConnectionId,
        Presenter,
        ScreenCoordinates,
        ZoomLevel,
      }
    );
  }

  /**
   * Inform a newly-joined user that a timedSession is running.
   *
   * @atomic - do not chunk
   */
  public postUpdateNewJoinedForTimer(
    NewJoinerConnectionId: string,
    timedSession: TimedSessionState
  ): Promise<void> {
    const {
      host,
      timedSessionStart,
      timedSessionPausedAt,
      timedSessionLengthInMinutes,
    } = timedSession;

    return this.invokeMessage(
      PostMessageName.InitialDataForNewJoinToTimerMessage,
      {
        NewJoinerConnectionId,
        Presenter: host,
        InitialDurationTimer: timedSessionLengthInMinutes,
        TimerStartedAt: timedSessionStart / 1000,
        TimerPausedAt: timedSessionPausedAt / 1000,
      }
    );
  }

  /**
   * Inform the server that an upload to storage has been completed successfully.
   *
   * @atomic - do not chunk
   */
  public postUploaded(
    TileId: string,
    TypeTile: number, // TODO - enum
    FileName: string
  ): Promise<void> {
    return this.invokeMessage(PostMessageName.UploadedMessage, {
      TileId,
      TypeTile,
      AzureBlobStatus: blobStates.onAzure,
      FileName,
    });
  }

  /**
   * Calculate the tile overflow given the number of tiles included in a batch.
   */
  public calculateTileBatchActionOverflow(
    tileCounts: { [key in keyof PostTileBatchActionPayload]: number }
  ): number {
    const {
      addedTiles = 0,
      restoredTiles = 0,
      restoredRelations = 0,
      groupedTiles = 0,
      ungroupedTiles = 0,
      deletedTiles = 0,
      lockedTiles = 0,
    } = tileCounts;

    const {
      baseSize,
      bytesPerTile: bytesPerGroupingTile,
    } = this.calculateTileBatchActionBytes({
      Grouped: [0],
      Tiles: [
        tileStatusToBatchActionInfo({
          AzureBlobStatus: 0,
          BackgroundColor: "",
          Height: 0,
          IsPinned: false,
          LockedUser: null,
          OldTileId: "",
          OriginalFileName: null,
          ParentId: "",
          PositionX: 0,
          PositionY: 0,
          RawTileContent: undefined,
          Relations: [],
          Rotation: 0,
          ScaleX: 0,
          ScaleY: 0,
          TileContent: undefined,
          TileId: emptyUuid,
          TypeTile: objectTypes.group ?? 0,
          Width: 0,
          ZIndex: 0,
        }),
      ],
    });

    const {
      bytesPerTile: bytesPerIdentityTile,
    } = this.calculateTileBatchActionBytes({
      Locked: [0],
      Tiles: [
        {
          Id: emptyUuid,
          Type: BatchTileType.Identity,
        },
      ],
    });

    const {
      bytesPerTile: bytesPerTileRelation,
    } = this.calculateTileBatchActionBytes({
      RestoredRelationIds: [0],
      Tiles: [],
    });

    const totalTileCount =
      addedTiles +
      groupedTiles +
      ungroupedTiles +
      restoredTiles +
      deletedTiles +
      lockedTiles +
      restoredRelations;

    const groupingTilesSize =
      (addedTiles + groupedTiles + ungroupedTiles) * bytesPerGroupingTile;
    const identityTilesSize =
      (restoredTiles + deletedTiles + lockedTiles) * bytesPerIdentityTile;
    const tileRelationsSize = restoredRelations * bytesPerTileRelation;

    const totalTileSize =
      groupingTilesSize + identityTilesSize + tileRelationsSize;
    const bytesRequired = baseSize + totalTileSize;
    const bytesRemaining = this.maxMessageSizeInBytes - bytesRequired;

    return bytesRemaining > 0
      ? 0
      : Math.round(Math.abs(bytesRemaining) / totalTileCount);
  }

  /**
   * Measure how many tiles can be included in a single selection message.
   *
   * Rounded down to the nearest 100.
   */
  public calculateMaxSelectionLimit(): number {
    const { baseSize, bytesPerTile } = this.calculateTileBatchActionBytes({
      Locked: [0],
      Tiles: [
        {
          Id: emptyUuid,
          Type: BatchTileType.Identity,
        },
      ],
    });

    const selectionLimit = Math.max(
      0,
      (this.maxMessageSizeInBytes - baseSize) / bytesPerTile
    );

    return Math.floor(selectionLimit / 100) * 100;
  }

  /**
   * Calculate the number of bytes required for a given payload.
   */
  private calculateTileBatchActionBytes(
    message: Partial<PostTileBatchActionMessage>
  ) {
    const emptyMessageWrapper: PostTileBatchActionMessage = {
      Type: "XXXXXXXXXXXXXXXXXXXX",
      Added: [],
      Restored: [],
      RestoredRelationIds: [],
      Grouped: [],
      Ungrouped: [],
      Deleted: [],
      Locked: [],
      Tiles: [],
    };

    // Calculate the size of the wrapper, without any tiles
    const {
      baseSize,
      itemSizes: emptyMessageWrapperSizes,
    } = this.calculateMessageBytesPerItem(
      PostMessageName.TileBatchActionMessage,
      [emptyMessageWrapper],
      // We don't know the ProjectId at this point so use the largest number allowed
      Number.MAX_SAFE_INTEGER
    );

    // Now check it with a single tile
    const { itemSizes } = this.calculateMessageBytesPerItem(
      PostMessageName.TileBatchActionMessage,
      [
        {
          ...emptyMessageWrapper,
          ...message,
        },
      ]
    );

    const bytesPerTile = itemSizes[0] - emptyMessageWrapperSizes[0];

    return {
      baseSize,
      bytesPerTile,
    };
  }

  /**
   * Split a message into chunks to ensure the message size does not exceed the
   * configured max size limit for SignalR messages. SignalR default = 32Kb.
   *
   * @NOTE - Do not use this with atomic messages.
   */
  private chunkMessagePayload<T extends PostMessageName, K>(
    messageType: T,
    messagePerItem: InvokeMessageMap[T][],
    allItems: K[]
  ): K[][] {
    try {
      const { baseSize, itemSizes } = this.calculateMessageBytesPerItem(
        messageType,
        messagePerItem
      );

      return chunkItemsByByteSizes(
        allItems,
        itemSizes,
        baseSize,
        this.maxMessageSizeInBytes
      );
    } catch (error) {
      onErrorToLog(
        isError(error)
          ? error
          : new SignalRError(
              `Failed to chunk message: ${stringifyError(error)}`
            )
      );
    }
    return [];
  }

  /**
   * Encode a chunkable-message and calculate the size per item.
   *
   * @NOTE There is a performance cost to this because encoding can take a
   * little time (~10ms), however there is no (easy) way to determine the size
   * of an encoded message without actually encoding it. Also once encoded it is
   * not possible to join / concat the results.
   */
  private calculateMessageBytesPerItem<T extends PostMessageName>(
    messageType: T,
    messagePerItem: InvokeMessageMap[T][],
    projectId?: number
  ): { baseSize: number; itemSizes: number[] } {
    const baseMessageSize = this.getBaseMessageSize(projectId);

    return {
      baseSize: baseMessageSize,
      itemSizes: messagePerItem.map((message) => {
        const messageSize = this.encodeMessageWithProtobuf(
          messageType,
          message,
          {
            projectId,
          }
        ).byteLength;
        return messageSize - baseMessageSize;
      }),
    };
  }

  private trackTilesEvent(name: string, tiles: TileStatus[]): void {
    trackEventToLog(LogCategory.signalR, {
      subcategory: name,
      tileIds: tiles.map((t) => t.TileId),
    });
  }
}

const isTileLargeInkPath = (tile: TileStatus): boolean => {
  if (!tile.TypeTile || tileTypes[tile.TypeTile] !== canvasObjectIds.inkPath) {
    return false;
  }

  const { InkStroke = [] } = (tile.TileContent ??
    {}) as InkTileContent; /** @TODO #7322 - do this with a type guard? */

  const {
    maxSignalRMessageSizeInKB = 0,
    bigInkLowerBoundary = 0,
    bigInkUpperBoundary = 0,
  } = runtimeConfig;

  // most inks are relatively small
  if (InkStroke.length <= bigInkLowerBoundary) {
    return false;
  }

  // relatively long drawing ink > (depends on browser chrome has higher density)
  // but in general 15sec of drawing is good enough to reach big ink
  if (InkStroke.length > bigInkUpperBoundary) {
    return true;
  }

  // if no of points is between - we need to check exact size
  // for safety reason use 90% space and keep 10% space for envelope
  return (
    JSON.stringify(InkStroke).length > maxSignalRMessageSizeInKB * 0.9 * 1024
  );
};
