import { useSelector } from "react-redux";
import { colorThemes } from "../../../const";
import { selectCanvasTheme } from "../../../reduxStore/canvas/settings/settings.reducer";
import { toDarkModeColor } from "../../../tools/colors";

const liveColorCache = new Map<string, string>();

export const useLiveColor = (
  color: string,
  isStaticPalette?: boolean
): string => {
  const theme = useSelector(selectCanvasTheme);
  if (isStaticPalette || theme === colorThemes.light) {
    return color;
  }

  const cachedDarkModeColor = liveColorCache.get(color);
  if (cachedDarkModeColor) {
    return cachedDarkModeColor;
  }

  const darkModeColor = toDarkModeColor(color);
  liveColorCache.set(color, darkModeColor);

  return darkModeColor;
};
