import type {
  AdjustPresentationTimerMessage,
  InitialDataForNewJoinToTimerMessage,
  PausePresentationTimerMessage,
  StartPresentationTimerMessage,
} from "../../../api/signalR/message.types";

export enum TimedSessionActionType {
  ON_JOINED_TIMED_SESSION = "ON_JOINED_TIMED_SESSION",
  TIMED_SESSION_DURATION_CHANGED = "TIMED_SESSION_DURATION_CHANGED",
  TIMED_SESSION_PAUSE_TOGGLED = "TIMED_SESSION_PAUSE_TOGGLED",
  TIMED_SESSION_STARTED = "TIMED_SESSION_STARTED",
  TIMED_SESSION_STOPPED = "TIMED_SESSION_STOPPED",
}

export type TimedSessionStartedPayload = {
  session: StartPresentationTimerMessage;
  isHost: boolean;
  startTime: number;
};

export const timedSessionStartedAction = (
  payload: TimedSessionStartedPayload
) =>
  ({
    type: TimedSessionActionType.TIMED_SESSION_STARTED,
    payload,
  } as const);

export type TimedSessionPauseToggledPayload = {
  session: PausePresentationTimerMessage;
  pausedTime: number;
};

export const timedSessionPauseToggledAction = (
  payload: TimedSessionPauseToggledPayload
) =>
  ({
    type: TimedSessionActionType.TIMED_SESSION_PAUSE_TOGGLED,
    payload,
  } as const);

export const timedSessionDurationChangedAction = (
  payload: AdjustPresentationTimerMessage
) =>
  ({
    type: TimedSessionActionType.TIMED_SESSION_DURATION_CHANGED,
    payload,
  } as const);

type OnJoinedTimedSessionPayload = {
  session: InitialDataForNewJoinToTimerMessage;
  isHost: boolean;
};

export const onJoinedTimedSessionAction = (
  payload: OnJoinedTimedSessionPayload
) =>
  ({
    type: TimedSessionActionType.ON_JOINED_TIMED_SESSION,
    payload,
  } as const);

type TimedSessionStoppedPayload = {
  host: string;
};

export const timedSessionStoppedAction = (
  payload: TimedSessionStoppedPayload
) =>
  ({
    type: TimedSessionActionType.TIMED_SESSION_STOPPED,
    payload,
  } as const);

export type TimedSessionStartedAction = ReturnType<
  typeof timedSessionStartedAction
>;
export type TimedSessionPauseToggledAction = ReturnType<
  typeof timedSessionPauseToggledAction
>;
export type TimedSessionDurationChangedAction = ReturnType<
  typeof timedSessionDurationChangedAction
>;
export type OnJoinedTimedSessionAction = ReturnType<
  typeof onJoinedTimedSessionAction
>;
export type TimedSessionStoppedAction = ReturnType<
  typeof timedSessionStoppedAction
>;

export type TimedSessionAction =
  | TimedSessionStartedAction
  | TimedSessionPauseToggledAction
  | TimedSessionDurationChangedAction
  | OnJoinedTimedSessionAction
  | TimedSessionStoppedAction;
