import React, { ReactElement } from "react";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Box, Button, SxStyleProp, Text, ThemeUIStyleObject } from "theme-ui";
import PulseContainer from "../../canvas/navigation/sidebar-panel/image/PulseContainer";

type Props = {
  inputProps: DropzoneInputProps;
  rootProps: DropzoneRootProps;
  isDragActive: boolean;
  acceptedNames: readonly string[];
  icon: (props: IconProps) => ReactElement;
  isBoardUpload?: boolean;
  sx?: SxStyleProp;
};

function DropZone({
  inputProps,
  rootProps,
  isDragActive,
  acceptedNames,
  icon: Icon,
  isBoardUpload = true,
  sx = {},
}: Props): ReactElement {
  const { t } = useTranslation();

  const acceptedResourceNames = acceptedNames
    .map((n) => n.toUpperCase())
    .join(", ");

  // this is a hack to allow upload the same file in a row via input element
  // https://stackoverflow.com/questions/4109276/how-to-detect-input-type-file-change-for-the-same-file
  const onInputClick = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    const element = event.currentTarget;
    element.value = "";
  };

  return (
    <Box
      {...rootProps}
      sx={{
        ...containerStyle,
        borderColor: isDragActive ? "var(--accent)" : "transparent",
        ...sx,
      }}
    >
      <Box>
        <Button sx={uploadButtonStyle}>
          <label
            className="dropzone__upload-label"
            htmlFor="sidebar-image-upload-image"
          >
            {t("sidebars.label.browseFile")}
          </label>
        </Button>
        <input
          id="sidebar-image-upload-image"
          {...inputProps}
          onClick={onInputClick}
        />
      </Box>
      <Box sx={descriptionBoxStyle}>
        <Text sx={textStyle}>{t("sidebars.label.or")}</Text>
        <PulseContainer isDragActive={isDragActive}>
          <Icon noShadow />
        </PulseContainer>
        <Text sx={textStyle}>
          {t(isBoardUpload ? "sidebars.label.dragInto" : "dropZone.dragInto", {
            names: acceptedResourceNames,
          })}
        </Text>
      </Box>
    </Box>
  );
}

const containerStyle: ThemeUIStyleObject = {
  width: "100%",
  backgroundColor: "#f1f1f1",
  display: "flex",
  flexDirection: "column",
  border: "3px solid transparent",
};

const uploadButtonStyle: ThemeUIStyleObject = {
  width: "60%",
  margin: "auto",
  marginTop: "60px",
  fontWeight: 600,
  fontSize: "calc(var(--staticGridTile) * 0.5)",
  padding: 0,
  lineHeight: "calc(var(--staticGridTile) * 1.25)",
  height: "calc(var(--staticGridTile) * 1.25)",
  ".dropzone__upload-label": {
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
};

const descriptionBoxStyle: ThemeUIStyleObject = {
  margin: "auto",
  flexDirection: "column",
  textAlign: "center",
  marginTop: "30px",
  marginBottom: "60px",
};

const textStyle: ThemeUIStyleObject = {
  fontWeight: 400,
  fontSize: "calc(var(--staticGridTile) * 0.5)",
  color: "black",
};

export default DropZone;
