import { FormikConfig, FormikValues } from "formik";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { importSubscriptionUsers, removeSubscriptionUser } from "../../../api";
import { errorCodeIds } from "../../../api/errorCodes";
import { selectSubscriptionDetails } from "../../../reduxStore/subscriptionDetails/subscriptionDetails.reducer";
import { useUISettings } from "../../../reduxStore/uiSettings";
import { errorToast } from "../../../tools/errorToast";
import { dynamicFeatureConfig } from "../../../tools/flags";
import { useLoggedInStatus } from "../../authentication/useLoggedInStatus";
import { BasicDropdownItem } from "../../shared/forms/dropdowns/BasicDropdown/BasicDropdown";
import { ModalProps, useSimpleModal } from "../../shared/modals/useSimpleModal";
import { guessIfFirstRowIsHeader, prepareUsersData } from "./helpers";

type UseImportPreviewHookResult = {
  formikProps: FormikConfig<BatchImportTableValues>;
  tableData: SpreadsheetRow[];
  columnCount: number;
  styleAssignedColumns: (
    items: FormikValues,
    usage: "tbody" | "thead"
  ) => { [p: string]: { backgroundColor: string } };
  columnKeys: number[];
  isValidForImport: (values: FormikValues) => boolean;
  parseImportIntention: (
    values: BatchImportTableValues
  ) => { add: number; remove: number; invalid: number };
  onAssignmentChange: (
    item: BasicDropdownItem<string>,
    values: FormikValues,
    setFieldValue: (
      field: string,
      value: unknown,
      shouldValidate?: boolean
    ) => void
  ) => void;
  batchImportResultModalProps: ModalProps;
  importStatus: ImportStatus;
};

// TODO: any ideas how to make it sensible? Isn't param of CollaboardApiError type ?
const isImportApiResponse = (
  response: unknown
): response is ImportApiResponse => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return "ErrorCode" in (response as any);
};

const stringToHeader: ColumnsSignature = {
  "first name": "FirstName",
  "last name": "LastName",
  "phone number": "PhoneNumber",
  email: "UserName",
  company: "CompanyName",
  action: "Action",
};

const initColumns = (firstRow: SpreadsheetRow) => {
  return firstRow.data.reduce<ColumnsSignature>((acc, cellValue, i) => {
    const cellKey = cellValue && cellValue.toString().toLowerCase();
    const header = cellKey && stringToHeader[cellKey];
    if (header) {
      acc[`column${i + 1}`] = header;
    }
    return acc;
  }, {});
};

const initialImportStatus: ImportStatus = {
  importing: false,
  requestCount: 0,
  totalRequests: 0,
  complete: false,
  addedCount: 0,
  removedCount: 0,
  invalidCount: 0,
  existingCount: 0,
  maxUsersReached: false,
};

export const useImportPreviewHook = (): UseImportPreviewHookResult => {
  const { t } = useTranslation();
  const location = useLocation<BatchImportHistoryLocationState>();
  const history = useHistory<void>();
  const { spreadsheetData = [] } = location.state ?? {};
  const { subscription } = useSelector(selectSubscriptionDetails);
  const [importStatus, setImportStatus] = useState(initialImportStatus);
  const batchImportResultModalProps = useSimpleModal();
  const { getDynamicFeatureConfig } = useUISettings();
  const autoLoginFromInvite = getDynamicFeatureConfig<boolean>(
    dynamicFeatureConfig.AUTO_LOGIN_FROM_INVITE
  );
  const { loggedInViaTenant } = useLoggedInStatus();

  if (!spreadsheetData?.length) {
    history.push("/");
  }

  const userData = useMemo(
    () =>
      // removes empty rows
      spreadsheetData?.filter((row) => row.data.some((cell) => cell !== "")) ??
      [],
    [spreadsheetData]
  );

  const tableData = userData.slice(0, 10);

  const columnCount = tableData.reduce(
    (accu, curr) => (curr.data.length > accu ? curr.data.length : accu),
    0
  );

  const columnKeys = Array.from(Array(columnCount).keys()).map((x) => x + 1);

  const columnsSignature = useMemo(() => initColumns(userData[0]), [userData]);

  const formikProps: FormikConfig<BatchImportTableValues> = {
    initialValues: {
      isFirstRowHeader: guessIfFirstRowIsHeader(tableData[0]?.data ?? [null]),
      columns: columnsSignature,
    },
    onSubmit: async (values) => {
      const subscriptionID = subscription?.ID;

      if (!subscriptionID) {
        errorToast(t("team.batchImport.noSubscriptionError"));
        return Promise.resolve();
      }

      setImportStatus(initialImportStatus);

      const { toAdd, toRemove, invalid } = prepareUsersData(values, userData);

      if (!toAdd.length && !toRemove.length && !invalid.length) {
        errorToast(t("team.batchImport.noDataError"));
        return Promise.resolve();
      }

      batchImportResultModalProps.open();

      const addChunkSize = 100;
      const numberOfAddChunks = Math.ceil(toAdd.length / addChunkSize);
      const totalRequests = numberOfAddChunks + toRemove.length;

      setImportStatus(
        (prevState): ImportStatus => ({
          ...prevState,
          importing: true,
          totalRequests,
          invalidCount: invalid.length,
        })
      );

      /**
       * ADD USERS
       */

      for (let chunk = 0; chunk < numberOfAddChunks; chunk++) {
        const chunkStart = chunk * addChunkSize;
        const chunkEnd = chunkStart + addChunkSize;
        const usersInChunk = toAdd.slice(chunkStart, chunkEnd);

        let result: ImportApiResponse;

        try {
          result = await importSubscriptionUsers({
            subscriptionID,
            users: usersInChunk,
            autoLoginFromInvite,
            tenant: loggedInViaTenant,
          });
        } catch (error) {
          if (isImportApiResponse(error)) {
            result = error;
          } else {
            // Unexpected / unknown response from server - update progress
            setImportStatus((prevState) => ({
              ...prevState,
              requestCount: prevState.requestCount + 1,
            }));
            continue;
          }
        }

        setImportStatus((prevState) => ({
          ...prevState,
          requestCount: prevState.requestCount + 1,
          addedCount: prevState.addedCount + result.ItemsAdded ?? 0,
          invalidCount: prevState.invalidCount + result.ItemsError ?? 0,
          existingCount: prevState.existingCount + result.ItemsExisting ?? 0,
          maxUsersReached:
            result.ErrorCode === errorCodeIds.MaxNumberOfUsersExceeded
              ? true
              : prevState.maxUsersReached,
        }));
      }

      /**
       * REMOVE USERS
       */

      const removeRequests = toRemove.map((user) => {
        return removeSubscriptionUser(
          {
            subscriptionID,
            Username: user.UserName,
          },
          {
            preventDefaultErrorHandling: true,
          }
        )
          .then(() => {
            setImportStatus((prevState) => ({
              ...prevState,
              removedCount: prevState.removedCount + 1,
              requestCount: prevState.requestCount + 1,
            }));
          })
          .catch(() => {
            setImportStatus((prevState) => ({
              ...prevState,
              invalidCount: prevState.invalidCount + 1,
              requestCount: prevState.requestCount + 1,
            }));
          });
      });

      await Promise.all(removeRequests);

      setImportStatus((prevState) => ({
        ...prevState,
        importing: false,
        complete: true,
      }));

      return Promise.resolve();
    },
  };

  const styleAssignedColumns = (
    items: FormikValues,
    usage: "tbody" | "thead"
  ) => {
    const columnCSSTuples = Object.keys(items.columns).map((key) => {
      const columnNumber = key.replace("column", "");

      return [
        `&:nth-of-type(${columnNumber})`,
        {
          backgroundColor: usage === "tbody" ? "#f5f5f5" : "black",
        },
      ];
    });

    return Object.fromEntries(columnCSSTuples);
  };

  const isValidForImport = (values: FormikValues) => {
    const isEmailFieldAssigned = Boolean(
      Object.values(values.columns).find((val) => val === "UserName")
    );

    const isTableNotEmpty = Boolean(tableData.length);

    return isTableNotEmpty && isEmailFieldAssigned;
  };

  const onAssignmentChange = (
    item: BasicDropdownItem<string>,
    values: FormikValues,
    setFieldValue: (
      field: string,
      value: unknown,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const alreadyExist =
      Object.entries(values.columns).find(
        ([, val]) => typeof item.value !== "undefined" && val === item.value
      ) ?? false;

    if (alreadyExist) {
      setFieldValue(`columns.${alreadyExist[0]}`, undefined);
    }
  };

  const parseImportIntention = (
    values: BatchImportTableValues
  ): { add: number; remove: number; invalid: number } => {
    const { toAdd, toRemove, invalid } = prepareUsersData(values, userData);
    return {
      add: toAdd.length,
      remove: toRemove.length,
      invalid: invalid.length,
    };
  };

  return {
    formikProps,
    columnCount,
    styleAssignedColumns,
    onAssignmentChange,
    isValidForImport,
    parseImportIntention,
    tableData,
    columnKeys,
    batchImportResultModalProps,
    importStatus,
  };
};
