import { ProductTypeIDs } from "../../const";
import { useLicenseInfo } from "./useLicenseInfo";

/**
 * The license is more then individual for features like Webex, Zoom, spaces etc.
 *
 * @NOTE this function is on a separate module so that we can test it by mocking
 * only the internal `useLicenseInfo` hook
 */
export const useIsPremium = (): boolean => {
  const { productId, isLicenseActive } = useLicenseInfo();

  return (
    !!isLicenseActive && !!productId && productId > ProductTypeIDs.individual
  );
};
