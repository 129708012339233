import type { BackgroundMessage } from "../../../api/signalR/message.types";
import { colorThemes } from "../../../const";

export enum CanvasSettingsActionType {
  SET_BACKGROUND_COLOR = "SET_BACKGROUND_COLOR",
  SET_THEME = "SET_THEME",
  TOGGLE_GRID = "TOGGLE_GRID",

  SET_VIEWPORT_TRANSFORM = "SET_VIEWPORT_TRANSFORM",

  ORIENTATION_CHANGE = "ORIENTATION_CHANGE",
  WINDOW_RESIZE_THROTTLED = "WINDOW_RESIZE_THROTTLED",
  WINDOW_RESIZE_DEBOUNCED = "WINDOW_RESIZE_DEBOUNCED",

  SET_ZOOM_LEVEL = "SET_ZOOM_LEVEL",
  ZOOM_OUT_TO_NEXT_STEP = "ZOOM_OUT_TO_NEXT_STEP",
  ZOOM_IN_TO_NEXT_STEP = "ZOOM_IN_TO_NEXT_STEP",
  ZOOM_TO_ACTIVE_OBJECT = "ZOOM_TO_ACTIVE_OBJECT",
  ZOOM_CENTER = "ZOOM_CENTER",
}

export const setBackgroundAction = (payload: BackgroundMessage) =>
  ({
    type: CanvasSettingsActionType.SET_BACKGROUND_COLOR,
    payload,
  } as const);

export const setThemeAction = (payload: {
  theme: colorThemes;
  setByBackgroundChange: boolean;
}) =>
  ({
    type: CanvasSettingsActionType.SET_THEME,
    payload,
  } as const);

export const toggleGridAction = () =>
  ({
    type: CanvasSettingsActionType.TOGGLE_GRID,
    payload: undefined,
  } as const);

export const setViewportTransformAction = (payload: TransformMatrix) =>
  ({
    type: CanvasSettingsActionType.SET_VIEWPORT_TRANSFORM,
    payload,
  } as const);

type WindowSizePayload = { width: number; height: number };

export const orientationChangeAction = (payload: WindowSizePayload) =>
  ({
    type: CanvasSettingsActionType.ORIENTATION_CHANGE,
    payload,
  } as const);

export const windowResizeThrottledAction = (payload: WindowSizePayload) =>
  ({
    type: CanvasSettingsActionType.WINDOW_RESIZE_THROTTLED,
    payload,
  } as const);

/** @TODO #7104: check if both actions can be replaced with a single `windowResizedAction` when migrating presentation state to Redux */
export const windowResizeDebouncedAction = (payload: WindowSizePayload) =>
  ({
    type: CanvasSettingsActionType.WINDOW_RESIZE_DEBOUNCED,
    payload,
  } as const);

export type SetZoomLevel = {
  zoomLevel: number;
  x?: number;
  y?: number;
  animate?: boolean;
};

export const setZoomLevelAction = (payload: SetZoomLevel) =>
  ({
    type: CanvasSettingsActionType.SET_ZOOM_LEVEL,
    payload,
  } as const);

export const zoomOutAction = () =>
  ({
    type: CanvasSettingsActionType.ZOOM_OUT_TO_NEXT_STEP,
    payload: undefined,
  } as const);

export const zoomInAction = () =>
  ({
    type: CanvasSettingsActionType.ZOOM_IN_TO_NEXT_STEP,
    payload: undefined,
  } as const);

export const zoomToActiveObjectAction = () =>
  ({
    type: CanvasSettingsActionType.ZOOM_TO_ACTIVE_OBJECT,
    payload: undefined,
  } as const);

export const scaleToFitAction = () =>
  ({
    type: CanvasSettingsActionType.ZOOM_CENTER,
    payload: undefined,
  } as const);

export type SetBackgroundColorAction = ReturnType<typeof setBackgroundAction>;
export type SetThemeAction = ReturnType<typeof setThemeAction>;

export type SetViewportTransformAction = ReturnType<
  typeof setViewportTransformAction
>;

export type SetZoomLevelAction = ReturnType<typeof setZoomLevelAction>;
export type ZoomInAction = ReturnType<typeof zoomInAction>;
export type ZoomOutAction = ReturnType<typeof zoomOutAction>;

export type CanvasSettingsAction =
  | SetBackgroundColorAction
  | SetThemeAction
  | ReturnType<typeof toggleGridAction>
  | SetViewportTransformAction
  | ReturnType<typeof orientationChangeAction>
  | ReturnType<typeof windowResizeThrottledAction>
  | ReturnType<typeof windowResizeDebouncedAction>
  | SetZoomLevelAction
  | ZoomInAction
  | ZoomOutAction
  | ReturnType<typeof zoomToActiveObjectAction>
  | ReturnType<typeof scaleToFitAction>;
