import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Close(props: IconProps): ReactElement {
  return (
    <Icon viewBox="0 0 22 22" {...props}>
      <polygon points="18.26 4.39 17.56 3.68 10.97 10.26 4.39 3.68 3.69 4.39 10.27 10.97 3.69 17.55 4.39 18.26 10.97 11.67 17.56 18.26 18.26 17.55 11.68 10.97 18.26 4.39" />
    </Icon>
  );
}
export default Close;
