import { unique } from "../../../../tools/utils";
import {
  HistoryNotifyAction,
  NotifyActionType,
} from "../../history/signalR-notify/signalR-notify.actions";
import { ObjectLinksAction, ObjectLinksActionType } from "./links.actions";

export type ObjectLinksState = {
  sourceTileIds: string[];
};

const getInitialObjectLinksState = (): ObjectLinksState => {
  return {
    sourceTileIds: [],
  };
};

export function objectLinksReducer(
  state = getInitialObjectLinksState(),
  action: ObjectLinksAction | HistoryNotifyAction
): ObjectLinksState {
  switch (action.type) {
    case ObjectLinksActionType.SET_OBJECT_LINKS: {
      return {
        ...state,
        sourceTileIds: unique(action.payload.map((link) => link.SourceTileId)),
      };
    }
    case NotifyActionType.ON_NOTIFY_OBJECT_LINK_CREATED: {
      const { SourceTileId } = action.payload;
      return {
        ...state,
        sourceTileIds: unique([...state.sourceTileIds, SourceTileId]),
      };
    }
    case NotifyActionType.ON_NOTIFY_OBJECT_LINK_DELETED: {
      const { SourceTileId } = action.payload;
      return {
        ...state,
        sourceTileIds: state.sourceTileIds.filter(
          (uuid) => uuid !== SourceTileId
        ),
      };
    }
    default:
      return state;
  }
}

export const selectLinkSourceTileIds = (
  state: ApplicationGlobalState
): string[] => {
  return state.canvas.objects.links.sourceTileIds;
};
