export enum MeetingActionType {
  SET_WEBEX_JOIN_AS_GUEST = "SET_WEBEX_JOIN_AS_GUEST",
  SET_WEBEX_SPACE_ID = "SET_WEBEX_SPACE_ID",
  SET_ZOOM_MEETING_URL = "SET_ZOOM_MEETING_URL",
  TOGGLE_WEBEX_WINDOW = "TOGGLE_WEBEX_WINDOW",
  TOGGLE_ZOOM_MEETING_WINDOW = "TOGGLE_ZOOM_MEETING_WINDOW",
}

export const setZoomMeetingUrlAction = (payload: string) =>
  ({
    type: MeetingActionType.SET_ZOOM_MEETING_URL,
    payload,
  } as const);

export const toggleZoomMeetingWindowAction = (payload: boolean) =>
  ({
    type: MeetingActionType.TOGGLE_ZOOM_MEETING_WINDOW,
    payload,
  } as const);

export const toggleWebexWindowAction = (payload: boolean) =>
  ({
    type: MeetingActionType.TOGGLE_WEBEX_WINDOW,
    payload,
  } as const);

export const setWebexSpaceIdAction = (payload: string) =>
  ({
    type: MeetingActionType.SET_WEBEX_SPACE_ID,
    payload,
  } as const);

export const setWebexJoinAsGuestAction = (payload: boolean) =>
  ({
    type: MeetingActionType.SET_WEBEX_JOIN_AS_GUEST,
    payload,
  } as const);

export type MeetingAction =
  | ReturnType<typeof setZoomMeetingUrlAction>
  | ReturnType<typeof toggleZoomMeetingWindowAction>
  | ReturnType<typeof toggleWebexWindowAction>
  | ReturnType<typeof setWebexSpaceIdAction>
  | ReturnType<typeof setWebexJoinAsGuestAction>;
