import { fabric } from "fabric";
import i18n from "i18next";

import {
  canvasObjectIds,
  maxStickyFontSize,
  stickyNoteFontSizes,
} from "../../../const";
import { factoryStickyNoteState } from "../../../reduxStore/canvas/objects/settings/settings.reducer";
import { updateHsl } from "../../../tools/colors";

import { errorToast } from "../../../tools/errorToast";
import { isString } from "../../../tools/text";
import { shallowCleanObject } from "../../../tools/utils";
import {
  getDimensionProps,
  getDimensionPropsToSet,
} from "../../utils/dimensionsProps";
import "../object/collaboardObjectCustom.clickable_urls_mixin";
import "../object/collaboardObjectCustom.editing_mixin";
import "../object/collaboardObjectCustomEvents";

fabric.StickyNote = fabric.util.createClass(
  fabric.Textbox,
  fabric.CustomEditing,
  fabric.CollaboardClickableURLs, // TODO: #6554 (LINKS_V2) remove
  {
    type: canvasObjectIds.stickyNote,
    shallowRenderWidth: 50,

    fontFamily: factoryStickyNoteState.fontFamily,
    fontSize: stickyNoteFontSizes[2],
    maxFontSize: maxStickyFontSize,
    hasRotatingPoint: false,
    hasBorders: true,

    fill: "#000000",
    fillColor: factoryStickyNoteState.fillColor,

    // dummy padding just to have sticky note background bigger then text edit area
    padding: 10, // padding from edge of control to border
    internalPadding: 10, // padding from border to first char of text

    maxHeight: 180,
    bottomPadding4Shadow: 20,

    lockUniScaling: true,
    // don't change originX/originY
    // https://ibvsolutions.visualstudio.com/CollaBoardWeb/_wiki/wikis/CollaBoardWeb.wiki/364/Coordinate-system
    originX: "left",
    originY: "top",

    objectCaching: false,

    textAlign: factoryStickyNoteState.textAlign, // "left", "center", "right", "justify", "justify-left", "justify-center" or "justify-right"
    textPlacement: factoryStickyNoteState.textPlacement,

    isAutoFontSize: factoryStickyNoteState.isAutoFontSize,

    // This must be true to prevent #6141
    splitByGrapheme: true,

    initialize(
      this: fabric.StickyNote,
      text: string,
      options: Partial<StickyNoteObjectConstructorConfig> = {}
    ) {
      const opts: Partial<StickyNoteObjectConstructorConfig> = {
        ...options,
        lineHeight: 1.05,
        _textBeforeEdit: options.text || text,
        text: options.text || text || "",
      };

      this.isAutoFontSize = options.fontSize === 0;

      this.callSuper("initialize", opts.text, opts);

      this.initializeCollaboardObject();
      this.initializeClickableURLs && this.initializeClickableURLs(); // TODO: #6554 (LINKS_V2) remove

      this.on("deselected", () => {
        this.isEditing && this.exitEditing();
      });

      this.on(
        "custom:object:undoRedoModify",
        this._updateAutoFontSize.bind(this)
      );
    },

    setContextProps(
      this: fabric.StickyNote,
      {
        // Pull out the properties that require special handling
        fillColor = this.fillColor,
        textColor = isString(this.fill) ? this.fill : this.fill?.base,
        isAutoFontSize = this.isAutoFontSize,
        // _dimensionAffectingProps
        fontFamily,
        fontSize,
        fontStyle,
        fontWeight,
        textAlign,
        textPlacement = this.textPlacement,
        width,
        ...rest
      }: ContextProps,
      config: SetContextPropsConfig = {}
    ) {
      const { isInitialization, isUpdateFromServer } = config;

      const textColorIsChanging = textColor !== this.fill;
      const fillColorIsChanging = fillColor !== this.fillColor;

      (fillColorIsChanging || isInitialization) &&
        this.updateGradient(fillColor);

      const currentDimensionProps = getDimensionProps(this);
      const dimensionPropsToSet = getDimensionPropsToSet(
        this,
        {
          fontFamily,
          fontSize,
          fontStyle,
          fontWeight,
          textAlign,
          textPlacement,
          width,
        },
        config
      );

      const otherProperties: Partial<fabric.StickyNote> = {
        isAutoFontSize: isAutoFontSize || fontSize === 0,
        fill: textColorIsChanging || isInitialization ? textColor : undefined,
      };

      /**
       * Group changes into a single `set()` call because it does additional
       * work when changing properties that affect dimensions.
       */
      const propsToSet: Partial<fabric.StickyNote> = {
        ...dimensionPropsToSet,
        ...rest,
        ...shallowCleanObject(otherProperties),
      };

      this.set(propsToSet);

      /**
       * For performance reasons we only calculate the max font size when
       * strictly necessary.
       *
       * The max font size is not set on initialization, instead it is computed
       * when the object is selected. This saves some work when loading a
       * project.
       *
       * @NOTE See `shouldRenderFromCache` as well.
       *
       * @TODO #7413: Remove this obscure hack
       */
      this.requireMaxFontSizeCalculation = !!Object.keys(dimensionPropsToSet)
        .length;

      if (!isInitialization && this.requireMaxFontSizeCalculation) {
        this.isAutoFontSize
          ? this.setMaxAutoFontSize()
          : this.setMaxManualFontSize();
      }

      if (!this.isAutoFontSize) {
        // Do not use set() - it will trigger internal Fabric processing
        this.fontSize = Math.min(this.fontSize, this.maxFontSize);
      }

      this.dirty = true;

      /**
       * Reset the values if the requested change results in the text
       * overflowing from the sticky note and inform the user with a toast.
       *
       * @NOTE - If another user made these changes we do NOT reset the values
       * because it means that there must be a discrepancy between how the
       * two different browsers / platforms render the font. In this case the
       * recipient user will see the text overflowing.
       *
       * @NOTE - This happens regardless of auto font size because when there is
       * a large amount of text in the note the possible font sizes are limited
       * and the effectiveness of auto font sizing is reduced, yet changing the
       * font family can easily cause an overflow.
       */
      if (!isInitialization && !isUpdateFromServer && this.isTextOverflow()) {
        /**
         * Group changes into a single `set()` call because it does additional
         * work when changing properties that affect dimensions.
         */
        this.set({
          ...currentDimensionProps,
          isAutoFontSize,
        });

        /**
         * Attempt to determine which value change triggered the overflow and
         * inform the user.
         *
         * @NOTE - Font sizes are pre-computed to prevent the user from
         * selecting sizes that won't fit but other options, such as font
         * family, don't have this calculation yet have a significant impact on
         * the number of lines and size of text so we need to catch those cases
         * too.
         */
        if (dimensionPropsToSet.fontFamily) {
          errorToast(i18n.t("propsReject.fontFamily"));
        } else if (dimensionPropsToSet.fontSize) {
          errorToast(i18n.t("propsReject.fontSize"));
        } else if (dimensionPropsToSet.fontWeight) {
          errorToast(i18n.t("propsReject.fontWeight"));
        } else if (dimensionPropsToSet.width) {
          errorToast(i18n.t("propsReject.width"));
        }
      }

      const event: ClientModifiedEvent = {
        transform: { action: "ctxPropsChange" },
      };
      this.trigger("modified", event);
    },

    onUpdateFromServer(this: fabric.StickyNote, props: Partial<fabric.Text>) {
      this._updateAutoFontSize(props);
    },

    _updateAutoFontSize(this: fabric.StickyNote, props: Partial<fabric.Text>) {
      const textIsChanged = !!props.text;
      const widthIsChanged = !!props.width;

      if (this.isAutoFontSize && (textIsChanged || widthIsChanged)) {
        this.setMaxAutoFontSize();
      }
    },

    supportsAutoFontSize() {
      return true;
    },

    hasEditableText() {
      return true;
    },

    hasEditableFillColor() {
      return true;
    },

    getContextProps(): ContextProps {
      return {
        fillColor: this.fillColor,
        textColor: this.fill,
        fontFamily: this.fontFamily,
        fontSize: this.fontSize,
        fontWeight: this.fontWeight,
        fontStyle: this.fontStyle,
        underline: this.underline,
        textAlign: this.textAlign,
        textPlacement: this.textPlacement,
        maxFontSize: this.maxFontSize,
        isAutoFontSize: this.isAutoFontSize,
      };
    },

    updateGradient(this: fabric.StickyNote, color: string) {
      const { width: gW } = this; // gradient "width" or "spread"
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) {
        return;
      }
      const saturatedFill = updateHsl(color, {
        sDelta: 0.1,
        lDelta: -0.15,
      });
      const gradient = ctx.createLinearGradient(-gW / 4, -gW / 4, gW, gW);
      gradient.addColorStop(0, saturatedFill);
      gradient.addColorStop(0.5, color);
      gradient.addColorStop(1, saturatedFill);

      this.fillColor = color;
      this.gradient = gradient;
    },

    hasShadow() {
      return true;
    },

    /**
     * @override
     */
    calcTextHeight(this: fabric.StickyNote): number {
      return this.maxHeight + this.bottomPadding4Shadow;
    },

    /**
     * @override
     */
    _render(this: fabric.StickyNote, ctx: CanvasRenderingContext2D) {
      const { x: width } = this._calculateCurrentDimensions();
      const isTooSmall =
        this.shallowRenderWidth && width < this.shallowRenderWidth;

      if (this.canvas?.interactive && isTooSmall && !this.__cb_isAnimating) {
        this._renderShallowStickyNote(ctx);
        // Disable text rendering
      } else {
        this._renderFullStickyNote(ctx);
        this.callSuper("_render", ctx);
      }
    },
  }
);
