import React, { ReactElement } from "react";
import { Box, ThemeUIStyleObject } from "theme-ui";

type Props = {
  hex: string;
};

export default function ColorSquare({ hex }: Props): ReactElement {
  return (
    <Box
      sx={squareStyle}
      style={{
        backgroundColor: hex,
      }}
    ></Box>
  );
}

const squareStyle: ThemeUIStyleObject = {
  alignItems: "center",
  border: "2px solid",
  height: "var(--gridTile)",
  width: "var(--gridTile)",
  padding: "0",
};
