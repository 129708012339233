import { canvasObjectIds } from "../../../../const";
import { runWithoutSelection } from "../../../../studio/components/patches/extends-selection/selection.utils";
import { flattenObjectTree } from "../../../../studio/utils/fabricObjects";

export const updateSingleObject = (
  canvas: fabric.CollaboardCanvas,
  objectProps: ContextProps
): void => {
  const target = canvas.getActiveObject();
  if (target) {
    target.fullTransform(objectProps);
  }
};

export const updateAllObjects = (
  canvas: fabric.CollaboardCanvas,
  objectProps: ContextProps,
  updateAllOfType: canvasObjectIds
): void => {
  const availableObjects = canvas.getObjects().filter((obj) => !obj.locked);
  let objectsToUpdate = flattenObjectTree(availableObjects).filter(
    (object) => object.type === updateAllOfType
  );

  if (updateAllOfType === canvasObjectIds.connector) {
    // filter out connections that are part of the locked group
    const allConnections = objectsToUpdate as fabric.CollaboardConnector[];
    objectsToUpdate = allConnections.filter((connector) => {
      const {
        _settings: { origin, destination },
      } = connector;

      return !origin?.group?.locked || !destination?.group?.locked;
    });
  }
  if (!objectsToUpdate.length) {
    return;
  }

  runWithoutSelection(canvas, () => {
    const transformer = new fabric.Transformer(objectsToUpdate, { canvas });

    transformer.beginTransform({ reversible: false });
    objectsToUpdate.forEach((object) => {
      object.setContextProps(objectProps);
    });

    transformer.commitTransform();
  });
  canvas.requestRenderAll();
};
