import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "theme-ui";
import Share from "../../../../icons/Share";
import Modal from "../../../shared/modals";
import { TemplateProps } from "../../../shared/modals/Modal";
import ModalHeader from "../../../shared/modals/ModalHeader";
import { withModalTemplate } from "../../../shared/modals/withModalTemplate";
import EmailInvitations from "./EmailInvitations";
import ShareLinkOrQR, { InvitationUrl } from "./ShareLinkOrQR";

type Props = {
  firstName: string;
  projectId: string;
};

function ShareModalTemplate({
  firstName,
  projectId,
  modalProps,
}: TemplateProps<Props>): ReactElement {
  const { t } = useTranslation();

  const [invitationUrl, setInvitationUrl] = useState<InvitationUrl | null>(
    null
  );
  const [isEmailInvitationsExpanded, setIsEmailInvitationsExpanded] = useState(
    false
  );

  return (
    <Modal
      {...modalProps}
      disableAutoFocus
      header={
        <ModalHeader
          Icon={Share}
          title={t("dialog.share.header")}
          subTitle={t("dialog.share.intro", { firstName })}
        />
      }
    >
      <Box sx={{ mb: "-24px" }}>
        {!invitationUrl && (
          <EmailInvitations
            projectId={projectId}
            isExpanded={isEmailInvitationsExpanded}
            setIsExpanded={setIsEmailInvitationsExpanded}
          />
        )}

        {!isEmailInvitationsExpanded && (
          <ShareLinkOrQR
            projectId={projectId}
            invitationUrl={invitationUrl}
            setInvitationUrl={setInvitationUrl}
          />
        )}
      </Box>
    </Modal>
  );
}

export default withModalTemplate(ShareModalTemplate);
