import { Formik, FormikConfig } from "formik";
import React, { ReactElement, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Grid, Text, ThemeUIStyleObject } from "theme-ui";
import {
  addProjectTag,
  removeProjectTag,
  updateProjectSpace,
} from "../../../../../api";
import { projectPermissions } from "../../../../../const";
import Spaces from "../../../../../icons/Spaces";
import { noop } from "../../../../../tools/utils";
import { ProjectsContext } from "../../Projects";
import DropDown from "../../../../shared/forms/dropdowns/DropDown";
import { Option } from "../../../../shared/forms/options/OptionsListItem";
import { Confirmation, useConfirmModal } from "../../../../shared/modals";
import ModalHeader from "../../../../shared/modals/ModalHeader";
import MultiInputField from "../../../../shared/forms/fields/MultiInputField";
import { MultiInputFieldItem } from "../../../../shared/forms/fields/MultiInputField/useMultiInputField";
import { useSpaces } from "../../../../spaces/useSpaces";
import { isSpaceAdmin } from "../../../../spaces/utils";
import Thumbnail from "../Thumbnail";
import ProjectTagList from "./ProjectTagList";
import { usePermissions } from "../../../../permissions/usePermissions";
import {
  ApiResourcePermission,
  ApiResourceType,
} from "../../../../../types/enum";

type Props = {
  project: ApiProject;
  lastOpened: string;
};

type TagsForm = {
  Tags: MultiInputFieldItem<undefined>[];
};

const noSpaceId = "NO_SPACE";

function ProjectDetails({ project, lastOpened }: Props): ReactElement {
  const { t } = useTranslation();
  const [isTagsFieldExpanded, setTagsFieldExpanded] = useState(false);
  const { updateSpace, updateTags } = useContext(ProjectsContext).current;
  const { Project, Permission, Tags } = project;
  const { spaces } = useSpaces();
  const { hasPermission } = usePermissions();
  const {
    CreationDate: creationDate,
    LastUpdate: lastUpdate,
    Thumbnail: thumbnail,
    ProjectId: projectId,
  } = Project;
  const isProjectOwner = Permission === projectPermissions.ownerPermission;

  const formikProps: FormikConfig<TagsForm> = {
    initialValues: { Tags: Tags.map((t) => ({ value: t, isValid: true })) },
    onSubmit: noop,
  };

  const changeSpaceModal = useConfirmModal();

  const activeSpace = spaces.find(
    (space) => space.Space.SpaceId === Project.SpaceId
  );
  const inaccessibleSpace = Project.SpaceId && !activeSpace;

  const allSpaceOptions: Option[] = useMemo(() => {
    if (inaccessibleSpace) {
      return [];
    }

    const spaceOptions = spaces
      .filter((space) =>
        hasPermission({
          type: ApiResourceType.SpaceProjects,
          value: String(projectId),
          permission: ApiResourcePermission.Update,
          spacePermission: space.SpacePermission,
          projectPermission: Permission,
          defaultPermission: isSpaceAdmin(space.SpacePermission),
        })
      )
      .map((space) => ({
        key: String(space.Space.SpaceId),
        label: <Box title={space.Space.Name}>{space.Space.Name}</Box>,
      }));

    if (
      hasPermission({
        type: ApiResourceType.SpaceProjects,
        value: String(projectId),
        permission: ApiResourcePermission.Update,
        spacePermission: activeSpace?.SpacePermission,
        projectPermission: Permission,
        defaultPermission:
          (activeSpace && isSpaceAdmin(activeSpace.SpacePermission)) ||
          isProjectOwner,
      })
    ) {
      spaceOptions.unshift({ key: noSpaceId, label: t("project.noSpace") });
    }

    return spaceOptions;
  }, [
    t,
    activeSpace,
    spaces,
    isProjectOwner,
    inaccessibleSpace,
    hasPermission,
    Permission,
    projectId,
  ]);

  const onSpaceChange = (key: string) => {
    changeSpaceModal.open({
      confirmCallback: async () => {
        const spaceId = key === noSpaceId ? null : Number(key);

        await updateProjectSpace(projectId, spaceId);
        updateSpace(projectId, spaceId);
      },
    });
  };

  return (
    <Flex sx={containerStyle}>
      <Grid sx={{ gridTemplateColumns: "1fr 1fr", width: "100%" }}>
        <Thumbnail projectId={projectId} thumbnail={thumbnail} />
        <Flex sx={innerContainerStyle}>
          <Grid
            columns={["repeat(auto-fit, minmax(20rem, 1fr))", null, "1fr 1fr"]}
            gap={[5]}
          >
            <Grid sx={{ gridTemplateColumns: "max-content 1fr" }}>
              <Text sx={dtStyle}>{t("app.creationDateV2")}</Text>
              <Text sx={ddStyle}>
                {t("app.fullDateTime", { date: new Date(creationDate) })}
              </Text>
              <Text sx={dtStyle}>{t("app.lastUpdateTitle")}</Text>
              <Text sx={ddStyle}>
                {t("app.fullDateTime", { date: new Date(lastUpdate) })}
              </Text>
              <Text sx={dtStyle}>{t("app.lastOpenedTitle")}</Text>
              <Text sx={ddStyle}>
                {t("app.fullDateTime", { date: new Date(lastOpened) })}
              </Text>
            </Grid>
            <Box>
              <Text sx={dtStyle}>{t("project.space")}</Text>
              {allSpaceOptions.length ? (
                <DropDown
                  current={
                    activeSpace ? String(activeSpace.Space.SpaceId) : noSpaceId
                  }
                  options={allSpaceOptions}
                  placeholder={t("project.noSpace")}
                  boxed
                  itemSx={() => ({ fontSize: "initial" })}
                  selectedLabelSx={{ fontSize: "initial" }}
                  onSelect={onSpaceChange}
                />
              ) : (
                <Text sx={ddStyle}>
                  {project.SpaceName ? project.SpaceName : t("project.noSpace")}
                </Text>
              )}
            </Box>
          </Grid>
          <Flex sx={{ flexDirection: "column", width: "100%" }}>
            <Text sx={{ fontWeight: "bold", mt: [4], mb: [2] }}>
              {t("project.tags")}
            </Text>
            {isProjectOwner && isTagsFieldExpanded ? (
              <Formik {...formikProps}>
                {/* MultiInputField requires wrapping it with Formik, although the form is never submitted. TODO: consider refactoring later */}
                {() => (
                  <MultiInputField<undefined>
                    name="Tags"
                    itemType="string"
                    dismissOnBlur
                    isExpanded={isTagsFieldExpanded}
                    setIsExpanded={setTagsFieldExpanded}
                    sx={{ minHeight: "calc(var(--gridTile) * 4)" }}
                    itemSx={{ backgroundColor: "disabled" }}
                    placeholder={t("project.tagsPlaceholder")}
                    itemMaxLength={25}
                    onAdded={async (addedItems, allItems) => {
                      const createNewTags = addedItems.map((tag) =>
                        addProjectTag(Project, tag.value)
                      );
                      await Promise.all(createNewTags);
                      updateTags(
                        projectId,
                        allItems.map((i) => i.value)
                      );
                    }}
                    onRemoved={async (removedItems, allItems) => {
                      const removeTags = removedItems.map((tag) =>
                        removeProjectTag(Project, tag.value)
                      );
                      await Promise.all(removeTags);
                      updateTags(
                        projectId,
                        allItems.map((i) => i.value)
                      );
                    }}
                  />
                )}
              </Formik>
            ) : (
              <ProjectTagList
                asField={isProjectOwner}
                project={project}
                onDoubleClick={() => setTagsFieldExpanded(true)}
              />
            )}
          </Flex>
        </Flex>
      </Grid>

      <Confirmation
        {...changeSpaceModal.props}
        variant="success"
        header={<ModalHeader title={t("project.space")} Icon={Spaces} />}
        confirmationLabel={t("dialog.changeSpace")}
      >
        {t("dialog.changeSpacePrompt")}
      </Confirmation>
    </Flex>
  );
}

const containerStyle: ThemeUIStyleObject = {
  flex: 1,
  py: [2],
  my: [3],
  bg: "#eee",
  padding: 10,
  height: "400",
};

const innerContainerStyle: ThemeUIStyleObject = {
  flexDirection: "column",
  justifyContent: "space-between",
  width: "100%",
  flex: [null, null, null, 1],
  my: [4, null, null, 0],
};

const dtStyle: ThemeUIStyleObject = { fontWeight: 500, mb: [2] };
const ddStyle: ThemeUIStyleObject = { fontWeight: 300, mb: [2] };

export default ProjectDetails;
