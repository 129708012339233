import { CanvasAppAction, CanvasAppActionType } from "./app.actions";

export enum BoardModalType {
  SHARE = "SHARE",
  OBJECT_LINK = "OBJECT_LINK",
  WHEEL_INPUT_MODE = "WHEEL_INPUT_MODE",
}

export enum CanvasMode {
  ADD_CHAT = "ADD_CHAT",
  DRAW = "DRAW",
  PAN = "PAN",
  PICK_COLOR = "PICK_COLOR",
  SELECT_OBJECT = "SELECT_OBJECT",
  VOTING = "VOTING",
}

export enum CanvasLeftSidePanel {
  DOCS = "DOCS",
  IMAGES = "IMAGES",
  MEDIA = "MEDIA",
  SHAPES = "SHAPES",
  USERS = "USERS",
}

export enum CanvasTool {
  comment = "comment",
  doc = "doc",
  embed = "embed",
  image = "collaboardImage",
  media = "media",
  more = "more",
  note = "note",
  select = "select",
  shape = "collaboardShape",
  template = "template",
  text = "text",
}

export enum CanvasRightSidePanel {
  /** @TODO #6559: NOTIFICATIONS = "NOTIFICATIONS", */
  SETTINGS = "SETTINGS",
}

export type AppState = {
  activeContextOptionPopup:
    | string /** @TODO #7104 - Convert to enum */
    | undefined;
  activeTool: CanvasTool | undefined;
  canvasMode: CanvasMode;
  isTransforming: boolean;
  openLeftSidePanel: CanvasLeftSidePanel | undefined;
  openRightSidePanel: CanvasRightSidePanel | undefined;
  openModal: BoardModalType | undefined;
};

export const defaultCanvasMode = CanvasMode.PAN;

const defaultState: AppState = {
  activeContextOptionPopup: undefined,
  activeTool: undefined,
  canvasMode: defaultCanvasMode,
  isTransforming: false,
  openLeftSidePanel: undefined,
  openRightSidePanel: undefined,
  openModal: undefined,
};

export function canvasAppReducer(
  state = defaultState,
  action: CanvasAppAction
): AppState {
  switch (action.type) {
    case CanvasAppActionType.SET_ACTIVE_CONTEXT_OPTION_POPUP: {
      return {
        ...state,
        activeContextOptionPopup: action.payload.contextOptionPopup,
      };
    }
    case CanvasAppActionType.SET_ACTIVE_TOOL: {
      return {
        ...state,
        activeTool: action.payload.tool,
      };
    }
    case CanvasAppActionType.SET_IS_TRANSFORMING: {
      return {
        ...state,
        isTransforming: action.payload.isTransforming,
      };
    }
    case CanvasAppActionType.SET_LEFT_SIDE_PANEL: {
      return {
        ...state,
        openLeftSidePanel: action.payload.sidePanel,
        openRightSidePanel: undefined,
      };
    }
    case CanvasAppActionType.SET_RIGHT_SIDE_PANEL: {
      return {
        ...state,
        openLeftSidePanel: undefined,
        openRightSidePanel: action.payload.sidePanel,
      };
    }
    case CanvasAppActionType.TOGGLE_LEFT_SIDE_PANEL: {
      const { sidePanel } = action.payload;
      return {
        ...state,
        openLeftSidePanel:
          state.openLeftSidePanel === sidePanel ? undefined : sidePanel,
        openRightSidePanel: undefined,
      };
    }
    case CanvasAppActionType.SET_CANVAS_MODE: {
      return {
        ...state,
        activeContextOptionPopup: undefined,
        activeTool: undefined,
        openLeftSidePanel: undefined,
        openRightSidePanel: undefined,
        canvasMode: action.payload.canvasMode,
      };
    }
    case CanvasAppActionType.OPEN_MODAL: {
      return {
        ...state,
        openModal: action.payload.modal,
      };
    }
    case CanvasAppActionType.CLOSE_MODAL: {
      return {
        ...state,
        openModal: undefined,
      };
    }
    default:
      return state;
  }
}

export const selectActiveContextOptionPopup = (
  state: ApplicationGlobalState
): string | undefined => {
  return state.canvas.app.activeContextOptionPopup;
};

export const selectActiveTool = (
  state: ApplicationGlobalState
): CanvasTool | undefined => {
  return state.canvas.app.activeTool;
};

export const selectOpenLeftSidePanel = (
  state: ApplicationGlobalState
): CanvasLeftSidePanel | undefined => {
  return state.canvas.app.openLeftSidePanel ?? undefined;
};

export const selectOpenRightSidePanel = (
  state: ApplicationGlobalState
): CanvasRightSidePanel | undefined => {
  return state.canvas.app.openRightSidePanel ?? undefined;
};

export const selectIsTransforming = (
  state: ApplicationGlobalState
): boolean => {
  return state.canvas.app.isTransforming;
};

export const selectCanvasMode = (state: ApplicationGlobalState): CanvasMode => {
  return state.canvas.app.canvasMode;
};

export const selectIsPickColorMode = (
  state: ApplicationGlobalState
): boolean => {
  return state.canvas.app.canvasMode === CanvasMode.PICK_COLOR;
};

export const selectIsDrawingMode = (state: ApplicationGlobalState): boolean => {
  return state.canvas.app.canvasMode === CanvasMode.DRAW;
};

export const selectIsAddChatMode = (state: ApplicationGlobalState): boolean => {
  return state.canvas.app.canvasMode === CanvasMode.ADD_CHAT;
};

export const selectIsSelectObjectMode = (
  state: ApplicationGlobalState
): boolean => {
  return state.canvas.app.canvasMode === CanvasMode.SELECT_OBJECT;
};

export const selectIsVotingMode = (state: ApplicationGlobalState): boolean => {
  return state.canvas.app.canvasMode === CanvasMode.VOTING;
};

export const selectOpenModal = (
  state: ApplicationGlobalState
): BoardModalType | undefined => {
  return state.canvas.app.openModal;
};

/**
 * Catch all for special modes that require UI elements, such as toolbox, to
 * be temporarily disabled.
 */
export const selectIsViewOnlyMode = (
  state: ApplicationGlobalState
): boolean => {
  return (
    selectIsAddChatMode(state) ||
    selectIsSelectObjectMode(state) ||
    selectIsVotingMode(state)
  );
};
