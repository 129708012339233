import React, { ReactElement } from "react";
import { Grid } from "theme-ui";
import { ProductTypeIDs } from "../../../../const";
import { Currency } from "../useCurrencies";
import BillingSwitch from "../../../licensing/payment/BillingSwitch";
import FreeMonths from "../../../licensing/payment/FreeMonths";
import PaymentMethod from "../../../licensing/payment/PaymentMethod";
import PromoCode from "../../../licensing/payment/PromoCode";
import Total from "../../../licensing/payment/Total";
import UpdateTotal from "../../../licensing/payment/UpdateTotal";
import { TemplateProps } from "../../../shared/modals/Modal";
import { LicenseOrderModal, LicenseOrderModalTop } from "../LicenseOrderModal";
import { useIndividualLicenseOrder } from "./useIndividualLicenseOrder";

type Props = {
  currency: Currency;
  savings: string;
};

function IndividualLicenseOrderModalTemplate({
  currency,
  savings,
  modalProps,
}: TemplateProps<Props>): ReactElement {
  const {
    total,
    numberOfFreeMonths,
    totalError,
    promoCode,
    promoCodeDisabled,
    billingType,
    arePricesLoaded,
    onBillingChange,
    onPromoCodeChange,
    clearPromoCode,
    goToCheckout,
    updateTotal,
  } = useIndividualLicenseOrder(currency.code, modalProps.isOpen);

  return (
    <LicenseOrderModal
      goToCheckout={goToCheckout}
      modalProps={modalProps}
      totalError={totalError}
    >
      <LicenseOrderModalTop>
        <BillingSwitch
          productTypeID={ProductTypeIDs.individual}
          billingType={billingType}
          onBillingChange={onBillingChange}
          savings={savings}
        />

        <Total
          billingType={billingType}
          arePricesLoaded={arePricesLoaded}
          currency={currency}
          total={total}
          error={totalError}
        />
      </LicenseOrderModalTop>

      <Grid columns={2} gap={0}>
        <PaymentMethod />

        <PromoCode
          onChange={onPromoCodeChange}
          promoCode={promoCode}
          disabled={promoCodeDisabled}
          clear={clearPromoCode}
        />
      </Grid>

      <UpdateTotal
        disabled={promoCodeDisabled || !promoCode}
        updateTotal={updateTotal}
      />

      <FreeMonths count={numberOfFreeMonths} />
    </LicenseOrderModal>
  );
}

export default IndividualLicenseOrderModalTemplate;
