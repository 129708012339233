import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { routes } from "../../../../../const";

export const useQRScanner = () => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState(null);
  const [cameraAccess, setCameraAccess] = useState(null);
  const [cameraCount, setCameraCount] = useState(null);
  const [cameraStream, setCameraStream] = useState(null);
  const [facingMode, setFacingMode] = useState("rear");
  const timeoutRef = useRef();

  useEffect(() => {
    let stream;

    const countVideoDevices = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const count = devices.filter((device) => device.kind === "videoinput")
        .length;

      setCameraCount(count);
    };

    const requestVideoStream = async () => {
      try {
        stream = await navigator.mediaDevices.getUserMedia({
          video: {
            // Match constraints from react-qr-scanner library
            width: { min: 360, ideal: 1280, max: 1920 },
            height: { min: 240, ideal: 720, max: 1080 },
          },
        });
      } catch (e) {
        // User has blocked camera access
        setCameraAccess(false);
        return;
      }

      setCameraAccess(true);
      setCameraStream(stream);
    };

    const getVideo = async () => {
      /**
       * Safari requires that we first request explicit camera access before
       * `videoinput` devices will be listed by `enumerateDevices`
       */
      await requestVideoStream();
      await countVideoDevices();
    };

    getVideo();

    return () => {
      clearTimeout(timeoutRef.current);

      // Kill the stream to prevent UA showing video icon
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
          stream.removeTrack(track);
        });
      }
    };
  }, []);

  const setTemporaryErrorMessage = (message) => {
    setErrorMessage(message);

    timeoutRef.current = setTimeout(() => {
      setErrorMessage(null);
    }, 3000);
  };

  const onScan = (result) => {
    if (!result) {
      return;
    }

    const inviteLinkPrefix = `${window.origin}${routes.acceptProjectInvitation}`;
    const url = result.text;

    if (url.startsWith(inviteLinkPrefix)) {
      clearTimeout(timeoutRef.current);
      window.location.href = url;
      return;
    }

    setTemporaryErrorMessage(t("dialog.scanQRInvalidLink"));
  };

  const onError = (err) => {
    setErrorMessage(
      err.name === "NotAllowedError"
        ? t("dialog.scanQRNoCameraAccess")
        : err.message
    );
  };

  return {
    onScan,
    onError,
    errorMessage,
    cameraAccess,
    cameraCount,
    cameraStream,
    facingMode,
    setFacingMode,
  };
};
