import i18n from "i18next";
import { isIOS, isSafari, isIOS13 } from "react-device-detect";

import collaboard from "../../../tools/collaboard";

const errorCodes = {
  1: "requestAborted",
  2: "networkError",
  3: "cannotDecode",
  4: "resourceUnsuitable",
  MEDIA_ERR_ABORTED: "requestAborted",
  MEDIA_ERR_NETWORK: "networkError",
  MEDIA_ERR_DECODE: "cannotDecode",
  MEDIA_ERR_SRC_NOT_SUPPORTED: "resourceUnsuitable",
};

export const createVideoElementForUrl = (
  url: string,
  fileType: string
): Promise<HTMLVideoElement> =>
  new Promise((resolve, reject) => {
    if (!collaboard.canvas) {
      throw new Error("No canvas");
    }

    const videoInitialTime = 0.001;
    const videoElement = collaboard.canvas.createTemporaryDomElement("video");

    const sourceElement = document.createElement("source");
    videoElement.appendChild(sourceElement);

    /**
     * Safari doesn't support webm and won't even load it, so `onerror` is never
     * triggered. Instead we manually reject the load here so the UI is updated.
     */
    if (
      videoElement.canPlayType(fileType) === "" ||
      (isSafari && fileType === "video/webm")
    ) {
      reject(i18n.t("clientError.resourceUnsuitable"));
      return;
    }

    videoElement.onloadeddata = (): void => {
      if (!videoElement.videoHeight) {
        reject(i18n.t("clientError.cannotLoadVideo"));
        return;
      }
      videoElement.width = videoElement.videoWidth;
      videoElement.height = videoElement.videoHeight;
      if (videoElement.duration === Number.POSITIVE_INFINITY) {
        // #3519 - some videos have "Infinity" duration
        // In order to get duration, we need to "rewind" video to the end, then back to start
        videoElement.currentTime = Number.MAX_SAFE_INTEGER;
        videoElement.addEventListener("durationchange", () => {
          videoElement.currentTime = videoInitialTime;
          resolve(videoElement);
        });
      } else {
        resolve(videoElement);
      }
    };

    videoElement.onerror = (): void => {
      if (videoElement.error) {
        const { code, message } = videoElement.error;
        const errorKey = errorCodes[code as keyof typeof errorCodes];
        const translatedError = errorKey && i18n.t(`clientError.${errorKey}`);

        return reject(translatedError || message);
      }
      return reject();
    };

    videoElement.controls = true;
    videoElement.crossOrigin = "Anonymous";
    // This is required to stop mobile devices opening the video fullscreen
    videoElement.playsInline = true;

    if (isIOS || isIOS13) {
      /**
       * Video won't actually autoplay because it is not muted but this makes
       * iOS download enough data to trigger the `loadeddata` event and resolve
       * the promise.
       */
      videoElement.muted = false;
      videoElement.autoplay = true;
    }

    /**
     * Safari won't play a video if it is quicktime and it doesn't have the
     * source type set.
     *
     * This should only happen for Safari because it can break other browsers.
     * For example a `video/quicktime` won't play on Chrome if the type is set.
     */
    if (isSafari && fileType === "video/quicktime") {
      sourceElement.type = fileType;
      // Setting the src on the <source> element means the 'error' event handlers
      // won't fire so only do this here when we must specify the `type`.
      sourceElement.src = url;
    } else {
      /**
       * Setting the URL triggers the download. This must happen after event
       * handlers have been bound.
       */
      videoElement.src = url;
    }

    /**
     * Set video position to 1ms to trigger the download of the first frame,
     * which is used as the object thumbnail.
     */
    videoElement.currentTime = videoInitialTime;
  });
