import { all, Effect, getContext, takeEvery } from "redux-saga/effects";
import { moveStep } from "../../../../const";
import { runWithoutSubselectionMode } from "../../../../studio/components/group/group.utils";
import { runWithoutSelection } from "../../../../studio/components/patches/extends-selection/selection.utils";
import {
  includesConnectors,
  isActiveSelection,
  isConnector,
  isGroup,
} from "../../../../studio/utils/fabricObjects";
import { Direction } from "../../../../types/enum";
import { MoveAction, MovementActionType } from "./movement.actions";

const { down, left, right, up } = Direction;

const moveVectors = {
  [down]: new fabric.Point(0, -moveStep),
  [left]: new fabric.Point(moveStep, 0),
  [right]: new fabric.Point(-moveStep, 0),
  [up]: new fabric.Point(0, moveStep),
};

const translateObject: {
  [key in Direction]: (object: fabric.Object) => void;
} = {
  down: (o) => o.set({ top: o.top + moveStep }),
  left: (o) => o.set({ left: o.left - moveStep }),
  right: (o) => o.set({ left: o.left + moveStep }),
  up: (o) => o.set({ top: o.top - moveStep }),
};

let tempTransformer: fabric.Transformer | null = null;

function* moveCanvasObject({ payload }: MoveAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const activeObject = canvas.getActiveObject();
  const activeMedia = canvas.getLastActiveMedia();
  const { direction, event } = payload;
  const { type: eventType, repeat } = event;

  if (!activeObject) {
    if (activeMedia) {
      activeMedia.updateVolume(direction === up ? 0.1 : -0.1);
      return;
    } else {
      canvas.relativePan(moveVectors[direction]);
      canvas.requestRenderAll();
      return;
    }
  } else if (
    activeObject.isLocked() ||
    canvas.getActiveObjects().some((object) => object.isLocked()) ||
    isConnector(activeObject) ||
    (isActiveSelection(activeObject) &&
      includesConnectors(activeObject.getObjects()))
  ) {
    return;
  }

  const modifiedEvent: ClientModifiedEvent = {
    transform: { action: eventType === "keyup" ? "dragEnd" : "drag" },
  };

  if (eventType === "keydown") {
    if (!repeat) {
      // Very similar to alignMiddleware's logic, but here activeObject can be a single common object as well
      const transformTarget = isActiveSelection(activeObject)
        ? activeObject.getObjects()
        : [activeObject];
      tempTransformer = new fabric.Transformer(transformTarget, { canvas });

      runWithoutSelection(canvas, () => {
        runWithoutSubselectionMode(canvas, () => {
          tempTransformer?.beginTransform();
        });
      });
    }

    const movedObjects = tempTransformer?.getObjects() || [];

    // Because of `runWithoutSelectionTransform`, the `activeObject` variable can reference an ActiveSelection
    // which doesn't exist anymore so we directly apply the translate to the unselected objects
    runWithoutSelection(canvas, () => {
      runWithoutSubselectionMode(canvas, () => {
        movedObjects.forEach((obj) => translateObject[direction](obj));
      });
    });

    movedObjects.forEach((obj) => {
      obj.trigger("modified", modifiedEvent);

      // Exclude for stacks
      if (isGroup(obj)) {
        obj.getObjects().forEach((obj) => {
          obj.trigger("modified", modifiedEvent);
        });
      }
    });
  }

  if (eventType === "keyup") {
    activeObject.setCoords();
    activeObject.trigger("modified", modifiedEvent);

    runWithoutSelection(canvas, () => {
      runWithoutSubselectionMode(canvas, () => {
        tempTransformer?.commitTransform({ isContextTransform: false });
      });
    });
  }

  canvas.requestRenderAll();
}

export function* movementSaga(): Generator<Effect> {
  yield all([takeEvery(MovementActionType.MOVE, moveCanvasObject)]);
}
