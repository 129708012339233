import { Form, Formik, FormikConfig } from "formik";
import React, { ReactElement, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Flex, Text, ThemeUIStyleObject } from "theme-ui";
import { object, string } from "yup";
import { sendUserOTPToken, validateUser2FA } from "../../../api";
import { routes } from "../../../const";
import TwoFactorAuth from "../../../icons/TwoFactorAuth";
import { LogCategory } from "../../../tools/telemetry";
import { maxOtpCodeLength } from "../../../validationSchemas/fieldRestrictions";
import InputField from "../../shared/forms/fields/InputField";
import { useErrorHandler } from "../../shared/hooks/useErrorHandler";
import Modal from "../../shared/modals";
import ConfirmationButtons from "../../shared/modals/ConfirmationButtons";
import { TemplateProps } from "../../shared/modals/Modal";
import ModalHeader from "../../shared/modals/ModalHeader";
import { withModalTemplate } from "../../shared/modals/withModalTemplate";
import { useAuthenticatingStatus } from "../useAuthenticatingStatus";

type Props = {
  UserName?: string;
  onError: (error: unknown) => void;
  onSuccess: (response: ApiValidatedAuthenticate) => void;
  UnvalidatedToken: ApiAuthenticate;
};

type ValidateTFAFormValues = {
  TFACode: string;
  UnvalidatedToken: ApiAuthenticate;
};

function ValidateTFAModalTemplate({
  UserName,
  modalProps,
  onError,
  onSuccess,
  UnvalidatedToken,
}: TemplateProps<Props>): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();
  const { fallbackErrorHandler } = useErrorHandler();
  const { clearAuthenticatingStatus } = useAuthenticatingStatus();
  const [isSendingOTP, setSendingOTP] = useState(false);

  const onSubmit = useCallback(
    async ({ TFACode, UnvalidatedToken }: ValidateTFAFormValues) => {
      try {
        const response = await validateUser2FA(
          // Add leading zeros that will be dropped by number input
          String(TFACode).padStart(maxOtpCodeLength, "0"),
          UnvalidatedToken
        );
        onSuccess(response);
      } catch (error: unknown) {
        onError(error);
      }
    },
    [onError, onSuccess]
  );

  /**
   * If the user dismisses this modal we need to clear the auth state.
   */
  const onClose = useCallback(() => {
    // This actually closes the modal, despite the name
    modalProps.onClose();
    clearAuthenticatingStatus();
    // Because this modal is also used in the external login flow we redirect
    // to remove any ?code=token from the url
    history.replace(routes.authenticate);
  }, [modalProps, history, clearAuthenticatingStatus]);

  const formikProps: FormikConfig<ValidateTFAFormValues> = {
    initialValues: {
      UnvalidatedToken,
      TFACode: "",
    },
    onSubmit,
    validationSchema: object().shape({
      TFACode: string()
        .max(
          maxOtpCodeLength,
          t("clientError.tooLongMax", { max: maxOtpCodeLength })
        )
        .required(t("clientError.required")),
    }),
    /**
     * Disable validateOnBlur so that validation messages don't appear (causing
     * a layout shift) when the user clicks on the close modal button or
     * send OTP code button.
     */
    validateOnBlur: false,
  };

  const onSendClick = useCallback(async () => {
    setSendingOTP(true);
    try {
      await sendUserOTPToken({
        token: UnvalidatedToken.AuthorizationToken,
        User: UserName,
      });
      toast(t("dialog.tfa.resent"));
    } catch (error) {
      fallbackErrorHandler(error, "Unable to send 2FA code", LogCategory.auth);
    }
    setSendingOTP(false);
  }, [UnvalidatedToken, UserName, fallbackErrorHandler, t]);

  return (
    <Formik {...formikProps}>
      {({ isValid, handleBlur, handleChange, submitForm }) => (
        <Modal
          {...modalProps}
          onClose={onClose}
          header={
            <ModalHeader title={t("dialog.tfa.header")} Icon={TwoFactorAuth} />
          }
          closeOnDimmerClick={false}
          actionButtons={
            <ConfirmationButtons
              onClose={modalProps.onClose}
              confirmationLabel={t("dialog.tfa.verifyButtonLabel")}
              confirmationDisabled={!isValid}
              onConfirm={submitForm}
              closeOnConfirm={false}
            />
          }
        >
          <Form>
            <Text sx={textStyle}>{t("dialog.tfa.message")}</Text>

            <InputField
              name="TFACode"
              /**
               * @NOTE We use a number input to trigger the numeric keyboard on
               * touch devices, but that means leading zeros will be dropped
               * from the resulting value (as it is a number not a string), so
               * we need to add them back in when we use the value.
               */
              type="number"
              maxLength={maxOtpCodeLength}
              sx={inputStyle}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t("dialog.tfa.inputPlaceholder")}
            />
          </Form>

          <Flex sx={sendContainerStyle}>
            <Button
              type="button"
              sx={sendButtonStyle}
              variant="content"
              disabled={isSendingOTP}
              onClick={onSendClick}
            >
              {t("dialog.tfa.resendButtonLabel")}
            </Button>
          </Flex>
        </Modal>
      )}
    </Formik>
  );
}

export default withModalTemplate(ValidateTFAModalTemplate);

const inputStyle: ThemeUIStyleObject = {
  mb: [2],
  "input[type=number]": {
    "::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
    },
  },
};
const textStyle: ThemeUIStyleObject = { mb: [4] };
const sendContainerStyle: ThemeUIStyleObject = { justifyContent: "flex-end" };
const sendButtonStyle: ThemeUIStyleObject = { fontSize: [3], mr: [1] };
