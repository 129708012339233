import React, { ReactElement } from "react";
import ModalSegment, { ModalSegmentProps } from "./ModalSegment";

type Props = Omit<ModalSegmentProps, "variant">;

function ModalHeader(props: Props): ReactElement {
  return <ModalSegment variant="header" {...props} />;
}

export default ModalHeader;
