import { Form, Formik, FormikConfig } from "formik";
import React, { ReactElement, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Text, ThemeUIStyleObject } from "theme-ui";
import { object, string } from "yup";
import Password from "../../../icons/Password";
import InputField from "../../shared/forms/fields/InputField";
import Modal from "../../shared/modals";
import ConfirmationButtons from "../../shared/modals/ConfirmationButtons";
import { TemplateProps } from "../../shared/modals/Modal";
import ModalHeader from "../../shared/modals/ModalHeader";
import { withModalTemplate } from "../../shared/modals/withModalTemplate";
import { authenticateUser } from "../../../api";
import { useAuthenticatingStatus } from "../useAuthenticatingStatus";

type PasswordFormValues = {
  UserName: string;
  Password: string;
};

type Props = {
  onError: (error: unknown) => void;
  onSuccess: (response: ApiValidatedAuthenticate | ApiAuthenticate) => void;
  UserName: string;
};

function PasswordModalTemplate({
  modalProps,
  onError,
  onSuccess,
  UserName,
}: TemplateProps<Props>): ReactElement {
  const { t } = useTranslation();
  const { clearAuthenticatingStatus } = useAuthenticatingStatus();

  const onSubmit = useCallback(
    async ({ UserName, Password }: PasswordFormValues) => {
      try {
        const response = await authenticateUser(UserName, Password);
        onSuccess(response);
      } catch (error) {
        onError(error);
      }
    },
    [onError, onSuccess]
  );

  /**
   * If the user dismisses this modal we need to clear the auth state.
   */
  const onClose = useCallback(() => {
    // This actually closes the modal, despite the name
    modalProps.onClose();
    clearAuthenticatingStatus();
  }, [modalProps, clearAuthenticatingStatus]);

  const formikProps: FormikConfig<PasswordFormValues> = {
    initialValues: {
      UserName,
      Password: "",
    },
    onSubmit,
    validationSchema: object().shape({
      UserName: string().required(t("clientError.required")),
      Password: string().required(t("clientError.required")),
    }),
    /**
     * Disable validateOnBlur so that validation messages don't appear (causing
     * a layout shift) when the user clicks on the close modal button.
     */
    validateOnBlur: false,
  };

  return (
    <Formik {...formikProps}>
      {({ isValid, handleBlur, handleChange, submitForm }) => (
        <>
          <Modal
            {...modalProps}
            onClose={onClose}
            header={
              <ModalHeader
                title={t("dialog.password.header")}
                Icon={Password}
              />
            }
            closeOnDimmerClick={false}
            actionButtons={
              <ConfirmationButtons
                onClose={modalProps.onClose}
                confirmationLabel={t("dialog.password.logInButtonLabel")}
                confirmationDisabled={!isValid}
                onConfirm={submitForm}
                closeOnConfirm={false}
              />
            }
          >
            <Form>
              <Text sx={textStyle}>{UserName}</Text>

              {/* Hidden input for accessibility - https://goo.gl/9p2vKq */}
              <InputField
                name="UserName"
                autoComplete="username email"
                sx={hiddenInput}
              />

              <InputField
                name="Password"
                type="password"
                sx={inputStyle}
                autoComplete="current-password"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t("dialog.password.inputPlaceholder")}
              />

              {/**
               * Allow user to submit form with Enter key - required because
               * there is more than one input
               */}
              <input type="submit" hidden />
            </Form>
          </Modal>
        </>
      )}
    </Formik>
  );
}

export default withModalTemplate(PasswordModalTemplate);

const inputStyle: ThemeUIStyleObject = { mb: [2] };

const textStyle: ThemeUIStyleObject = { mb: [4] };

const hiddenInput: ThemeUIStyleObject = { display: "none" };
