import React, { ReactElement, useEffect } from "react";
import { FieldHookConfig } from "formik";
import { Flex, Input, ThemeUIStyleObject } from "theme-ui";

import MultiInputItem, { ItemTooltipProps } from "./MultiInputItem";
import {
  useMultiInputField,
  MultiInputType,
  MultiInputFieldItem,
} from "./useMultiInputField";

type Props<T> = {
  itemType: MultiInputType;
  itemMaxLength?: number;
  sx?: ThemeUIStyleObject;
  itemSx?: ThemeUIStyleObject;
  placeholder?: string;
  isExpanded: boolean;
  dismissOnBlur?: boolean;
  setIsExpanded(flag: boolean): void;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onAdded?(
    newItems: MultiInputFieldItem<T>[],
    allItems: MultiInputFieldItem<T>[]
  ): void;
  onRemoved?(
    removedItems: MultiInputFieldItem<T>[],
    allItems: MultiInputFieldItem<T>[]
  ): void;
  validateEntries?(entries: string[]): Promise<MultiInputFieldItem<T>[]>;
  Tooltip?: (props: ItemTooltipProps<T>) => ReactElement;
} & FieldHookConfig<MultiInputFieldItem<T>[]>;

function MultiInputField<T extends unknown>({
  itemType,
  itemMaxLength,
  sx,
  itemSx,
  placeholder = "",
  dismissOnBlur,
  isExpanded,
  setIsExpanded,
  onChange,
  onAdded,
  onRemoved,
  Tooltip,
  validateEntries,
  ...props
}: Props<T>): ReactElement {
  const {
    items,
    isAllSelected,
    inputFieldRef,
    inputFieldValue,
    onRemoveItem,
    onInputChange,
    onInputKeyDown,
    onFieldBlur,
    onPaste,
  } = useMultiInputField({
    itemType,
    props,
    setIsExpanded,
    isExpanded,
    onAdded,
    onRemoved,
    validateEntries,
  });

  useEffect(() => {
    if (!isExpanded && inputFieldRef.current) {
      inputFieldRef.current.blur();
    }
  }, [isExpanded, inputFieldRef]);

  return (
    <Flex
      sx={{
        ...containerStyle,
        borderColor: isExpanded ? "primary" : "var(--inputBorder)",
        outline: isExpanded ? "1px solid" : 0,
        ...sx,
      }}
      onClick={() => inputFieldRef.current?.focus()}
      onBlur={(e) => {
        const target = e.relatedTarget as HTMLButtonElement;
        const ariaLabel = target?.getAttribute?.("aria-label");
        onFieldBlur();
        dismissOnBlur &&
          ariaLabel !== "MultiInputItemButton" &&
          setIsExpanded(false);
      }}
    >
      {items.map((item) => (
        <MultiInputItem
          key={item.value}
          item={item}
          isSelected={isAllSelected}
          onRemove={(e) => onRemoveItem(item, e)}
          sx={itemSx}
          Tooltip={Tooltip}
        />
      ))}

      <Input
        variant="inputMulti"
        ref={inputFieldRef}
        value={inputFieldValue}
        sx={{
          width: items.length ? `${4 + inputFieldValue.length}ch` : "100%",
        }}
        onFocus={(e) => {
          const isFieldFocused = e.currentTarget === inputFieldRef.current;
          isFieldFocused && setIsExpanded(true);
        }}
        onChange={(e) => {
          onInputChange(e);
          onChange?.(e);
        }}
        onKeyDown={onInputKeyDown}
        onPaste={onPaste}
        placeholder={!items.length ? placeholder : ""}
        maxLength={itemMaxLength}
      />
    </Flex>
  );
}

const containerStyle: ThemeUIStyleObject = {
  border: "1px solid",
  outlineColor: "primary",
  transition: "all 0.15s ease-in-out",
  py: [2],
  px: [2],
  width: "100%",
  flexWrap: "wrap",
  cursor: "text",
  maxHeight: "300px",
  overflowY: "auto",
  fontSize: [4],
  alignContent: "flex-start",
};

export default MultiInputField;
