import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { Box, Button, Flex, Heading, ThemeUIStyleObject } from "theme-ui";
import { UseInfiniteQueryResult } from "../../../../../api/useRequest";
import LoadingSpinner from "../../../../../icons/LoadingSpinner";
import Plus from "../../../../../icons/Plus";
import UploadDocument from "../../../../../icons/UploadDocument";
import { useUISettings } from "../../../../../reduxStore/uiSettings";
import { dynamicFeatureFlags } from "../../../../../tools/flags";
import Centered from "../../../../shared/layout/Centered";
import EmptyListPlaceholder from "../../../../shared/layout/EmptyListPlaceholder";
import { useConfirmModal } from "../../../../shared/modals";
import { useSimpleModal } from "../../../../shared/modals/useSimpleModal";
import { rowHeight } from "../const";
import RequestErrorPlaceholder from "../RequestErrorPlaceholder";
import AddUserModal from "./AddUserModal";
import BatchImportModalTemplate from "./BatchImportModalTemplate";
import User from "./User";

type Props = {
  onRemoveUser(user: ApiSubscriptionUser, isActive: boolean): void;
  onAddUser(): Promise<void>;
  query: UseInfiniteQueryResult & {
    data: ApiSubscriptionUser[];
  };
};

function Users({ onAddUser, onRemoveUser, query }: Props): ReactElement {
  const addUserModal = useConfirmModal();
  const batchImportModalProps = useSimpleModal();
  const { t } = useTranslation();
  const { isDynamicFeatureActive } = useUISettings();
  const {
    canFetchMore,
    fetchMore,
    data,
    isDataInitialized,
    error,
    isFetching,
    isFetchingMore,
  } = query;

  return (
    <>
      <AddUserModal modalProps={addUserModal.props} onAddUser={onAddUser} />

      <Heading variant="layout.darkBar" sx={{ mt: [5] }}>
        <Box>{t("team.users")}</Box>

        <Flex>
          {isDynamicFeatureActive(dynamicFeatureFlags.BATCH_USER_IMPORT) && (
            <>
              <BatchImportModalTemplate modalProps={batchImportModalProps} />

              <Button
                variant="buttons.circleIcon"
                onClick={batchImportModalProps.open}
              >
                <UploadDocument />
                <Box>{t("team.uploadFile")}</Box>
              </Button>
            </>
          )}
          <Button
            id="user-add-new"
            variant="buttons.circleIcon"
            onClick={() => addUserModal.open()}
          >
            <Plus />
            <Box>{t("team.addNewUser")}</Box>
          </Button>
        </Flex>
      </Heading>

      {isDataInitialized && data.length === 0 && (
        <EmptyListPlaceholder sx={{ height: rowHeight }} />
      )}
      {error && <RequestErrorPlaceholder />}

      {!!data.length && (
        <Box sx={scrollWrapperStyle}>
          <InfiniteScroll
            pageStart={1}
            useWindow={false}
            loadMore={fetchMore}
            hasMore={canFetchMore && !isFetching && !isFetchingMore}
            loader={
              <Centered key="subscription-users-infinite-scroll">
                <LoadingSpinner />
              </Centered>
            }
          >
            {data.map((user) => (
              <User
                key={user.Username}
                user={user}
                onRemove={() => onRemoveUser(user, true)}
              />
            ))}
          </InfiniteScroll>
        </Box>
      )}
    </>
  );
}

const scrollWrapperStyle: ThemeUIStyleObject = {
  height: "300px",
  overflowY: "auto",
};

export default Users;
