import { BrushType } from "../../../const";
import collaBoard from "../../../tools/collaboard";

import { createNewBrush, isImageInkBrush } from "./brushes";
import { createInkPath } from "./inkPath";

type CreateBrushAndInkPathOptions = Partial<fabric.IRectOptions> & {
  points: fabric.CollaboardPoint[];
  color: string;
  brushType: BrushType;
  strokeWidth: number;
};

export const createBrushAndInkPath = async (
  options: CreateBrushAndInkPathOptions
): Promise<fabric.CollaboardInkPath> => {
  const { canvas } = collaBoard;

  if (!canvas) {
    throw new Error("Cannot create ink path object: missing canvas");
  }

  const { points, brushType, strokeWidth, color, ...fabricOptions } = options;

  if (!points || !brushType) {
    // Data missing from API response or unknown brush type
    throw new Error(
      "Cannot create ink path object: unknown brush type or missing points"
    );
  }

  const brush = createNewBrush(canvas, brushType, {
    strokeWidth,
    color,
  });

  if (isImageInkBrush(brush)) {
    await brush.loadBrushImage();
  }

  const inkPath = createInkPath({
    ...fabricOptions,
    brush,
    points,
    color,
    contextProps: {
      strokeWidth,
    },
  });

  return inkPath;
};
