import { Form, Formik, FormikConfig } from "formik";
import React, { ReactElement, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Button, Flex, Heading, Text, ThemeUIStyleObject } from "theme-ui";
import { object, string } from "yup";
import { resetPassword, sendResetPasswordEmail } from "../../../api";
import { routes } from "../../../const";
import { getAppName } from "../../../tools/customer";
import { LogCategory } from "../../../tools/telemetry";
import { passwordSchema } from "../../../validationSchemas/password";
import InputField from "../../shared/forms/fields/InputField";
import PasswordField from "../../shared/forms/fields/PasswordField";
import { useErrorHandler } from "../../shared/hooks/useErrorHandler";

type DecodedToken = {
  User?: string;
  Code?: string;
};

type Props = {
  User?: string;
};

function PasswordReset({ User }: Props): ReactElement {
  const { t } = useTranslation();
  const history = useHistory<void>();
  const { fallbackErrorHandler } = useErrorHandler();
  const { search } = useLocation();
  const decodedToken = useMemo(() => {
    const queryParams = new URLSearchParams(search);
    const token = queryParams.get("token") ?? undefined;

    if (!token) {
      return undefined;
    }

    try {
      return JSON.parse(atob(token)) as DecodedToken;
    } catch {
      return undefined;
    }
  }, [search]);

  const formikProps: FormikConfig<ResetPassword> = {
    initialValues: {
      Password: "",
      PasswordResetToken: decodedToken?.Code ?? "",
      User: decodedToken?.User ?? User ?? "",
    },
    onSubmit: async (values) => {
      try {
        await resetPassword(values);
        toast(t("form.message.passwordReset"));
        history.push(routes.authenticate);
      } catch (error) {
        fallbackErrorHandler(
          error,
          "Unable to reset password",
          LogCategory.auth
        );
      }
    },
    validationSchema: object().shape({
      User: string()
        .email(t("clientError.emailNotValid"))
        .required(t("clientError.required")),
      Password: passwordSchema.required(t("clientError.required")),
      PasswordResetToken: string().required(t("clientError.required")),
    }),
    validateOnMount: true,
  };

  const resend = useCallback(async () => {
    try {
      if (!formikProps.initialValues.User) {
        return;
      }

      await sendResetPasswordEmail({ User: formikProps.initialValues.User });
      toast(t("form.message.tokenSent", { appName: getAppName() }));
    } catch (error) {
      fallbackErrorHandler(
        error,
        "Failed to send password reset",
        LogCategory.auth
      );
    }
  }, [formikProps.initialValues.User, fallbackErrorHandler, t]);

  return (
    <Formik {...formikProps}>
      {({ handleSubmit, isSubmitting, isValid, initialValues }) => (
        <Form onSubmit={handleSubmit}>
          <Flex as="fieldset">
            <Heading as="legend">{t("form.header.resetPassword")}</Heading>
            <InputField
              name="User"
              label={t("form.placeholder.User")}
              disabled={Boolean(initialValues.User)}
            />
            <InputField
              name="PasswordResetToken"
              label={t("form.placeholder.PasswordResetToken")}
              autoComplete="off"
              disabled={Boolean(initialValues.PasswordResetToken)}
            />
            <PasswordField
              name="Password"
              label={t("form.placeholder.newPassword")}
              autoComplete="new-password"
              sx={{ display: "block" }}
            />
          </Flex>
          <Flex sx={buttonsBarStyle}>
            <Box sx={buttonStyle}>
              <Text mb={1}>
                <Link to={routes.passwordReset} onClick={resend}>
                  {t("form.button.resendToken")}
                </Link>
              </Text>
            </Box>
            <Button
              type="submit"
              sx={buttonStyle}
              disabled={!isValid || isSubmitting}
            >
              {t("form.button.save")}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}

const buttonsBarStyle: ThemeUIStyleObject = {
  alignItems: "center",
  flex: "1 0 100%",
  flexWrap: "wrap",
  justifyContent: "space-between",
  my: [3],
};

const buttonStyle: ThemeUIStyleObject = { flex: 1 };

export default PasswordReset;
