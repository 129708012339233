import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Button, Text } from "theme-ui";

import Bell from "../../icons/Bell";
import Modal from "../shared/modals";
import ModalHeader from "../shared/modals/ModalHeader";
import { getAppName } from "../../tools/customer";

type Props = {
  isOpen: boolean;
};

function NewVersionModal({ isOpen }: Props): ReactElement {
  const { t } = useTranslation();
  const onClose = () => {
    window.location.reload();
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      header={
        <ModalHeader
          Icon={Bell}
          title={t("dialog.newVersionAvailableHeader")}
        />
      }
      actionButtons={
        <Button onClick={onClose}>{t("form.button.refresh")}</Button>
      }
    >
      <Text variant="default">
        {t("dialog.newVersionAvailable", {
          appName: getAppName(),
        })}
      </Text>
    </Modal>
  );
}

export default NewVersionModal;
