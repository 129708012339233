import React, { ReactElement, useEffect } from "react";
import { ToastOptions } from "react-toastify";
import {
  openModalAction,
  setRightSidePanelAction,
} from "../../../reduxStore/canvas/app/app.actions";
import {
  BoardModalType,
  CanvasRightSidePanel,
} from "../../../reduxStore/canvas/app/app.reducer";
import { reduxDispatch } from "../../../reduxStore/redux.dispatch";
import collaboard from "../../../tools/collaboard";
import {
  defaultHelpSettings,
  helpSettings,
} from "../../../tools/localStorageStores";
import TouchpadToast from "../../canvas/navigation/input-mode/TouchpadToast";
import HelpNotice from "./HelpNotice";
import ToastWithLink from "./ToastWithLink";
import { CollaboardToast } from "./useToast";

/**
 * TODO #7037:
 * - refactor this to use conventional Redux state instead of canvas events
 * - Merge with CanvasModeNotice so that
 *   - Notices stack up
 *   - We can reuse the same CanvasModeNotice UI for Help notices (?)
 */

type UseCanvasToastConfig = {
  canvas?: fabric.CollaboardCanvas;
};

export enum CanvasToast {
  setTouchpadMode = "setTouchpadMode",
  touchpadModeDetected = "touchpadModeDetected",
  onThemeChangedByBackground = "onThemeChangedByBackground",
  helpEvent = "helpEvent",
}

export enum CanvasToastContent {
  touchpadModeEnabled = "touchpadModeEnabled",
  touchpadModeDisabled = "touchpadModeDisabled",
  darkThemeEnabled = "darkThemeEnabled",
  lightThemeEnabled = "lightThemeEnabled",
}

const useCanvasToast = ({ canvas }: UseCanvasToastConfig): void => {
  useEffect(() => {
    if (!canvas) {
      return undefined;
    }

    canvas.on("custom:canvas:toast:show", ({ toast }) => {
      canvasToasts[toast].show();
    });

    canvas.on("custom:canvas:toast:update", ({ toast, content }) => {
      canvasToasts[toast].update(canvasToastContent[content]);
    });

    canvas.on("custom:canvas:toast:dismiss", ({ toast }) => {
      canvasToasts[toast].dismiss();
    });

    return () => {
      canvas.off("custom:canvas:toast:dismiss");
      canvas.off("custom:canvas:toast:show");
      canvas.off("custom:canvas:toast:update");

      // Ensure canvas toasts are dismissed when user leaves the canvas
      Object.values(canvasToasts).forEach((toast) => toast.dismiss());
    };
  }, [canvas]);
};

export default useCanvasToast;

type CanvasToastContents = {
  [key in CanvasToastContent]: ReactElement | string;
};

/**
 * If the toast content can be changed at runtime specify the content here.
 *
 * This is required when you have a toast for a toggleable feature and you don't
 * want "{feature} enabled" and "{feature} disabled" toasts to be visible at
 * the same time in the toast stack.
 */
const canvasToastContent: CanvasToastContents = {
  [CanvasToastContent.touchpadModeEnabled]: React.createElement(TouchpadToast, {
    labelKey: "dialog.wheelInputMode.enableToast",
  }),
  [CanvasToastContent.touchpadModeDisabled]: React.createElement(
    TouchpadToast,
    {
      labelKey: "dialog.wheelInputMode.disableToast",
    }
  ),
  [CanvasToastContent.darkThemeEnabled]: React.createElement(ToastWithLink, {
    labelKey: "dialog.themeSetByBackground.darkTheme",
    linkKey: "dialog.openSettings",
  }),
  [CanvasToastContent.lightThemeEnabled]: React.createElement(ToastWithLink, {
    labelKey: "dialog.themeSetByBackground.lightTheme",
    linkKey: "dialog.openSettings",
  }),
};

const bottomToastConfig: ToastOptions = {
  autoClose: 5000,
  draggable: false,
  position: "bottom-center",
};

type CanvasToastTypes = {
  [key in CanvasToast]: CollaboardToast;
};

/**
 * Define the list of canvas toasts that can be controlled with this hook.
 */
const canvasToasts: CanvasToastTypes = {
  [CanvasToast.setTouchpadMode]: new CollaboardToast(
    canvasToastContent[CanvasToastContent.touchpadModeEnabled],
    bottomToastConfig
  ),
  [CanvasToast.touchpadModeDetected]: new CollaboardToast(
    React.createElement(TouchpadToast, {
      labelKey: "dialog.wheelInputMode.detectedToast",
      linkKey: "dialog.wheelInputMode.detectedToastLink",
    }),
    {
      ...bottomToastConfig,
      onClick: () => {
        reduxDispatch(
          openModalAction({ modal: BoardModalType.WHEEL_INPUT_MODE })
        );
      },
    }
  ),
  [CanvasToast.onThemeChangedByBackground]: new CollaboardToast(
    canvasToastContent[CanvasToastContent.darkThemeEnabled],
    {
      ...bottomToastConfig,
      onClick: () => {
        reduxDispatch(
          setRightSidePanelAction({ sidePanel: CanvasRightSidePanel.SETTINGS })
        );
      },
    }
  ),

  [CanvasToast.helpEvent]: new CollaboardToast(
    React.createElement(HelpNotice, {
      i18nKey: "dialog.help.scaling",
      hideOnClick: true,
      onDismiss: () => {
        // TODO #7037: use Redux or canvas state
        const { canvas } = collaboard;
        const currentSettings = canvas?.helpSettings || defaultHelpSettings;
        const updatedSettings = {
          ...currentSettings,
          shiftScaling: false,
        };

        helpSettings.set(updatedSettings);
        canvas && (canvas.helpSettings = updatedSettings);
        canvasToasts[CanvasToast.helpEvent].dismiss();
      },
    }),
    bottomToastConfig
  ),
};
