import { fabric } from "fabric";
import mime from "mime";

import {
  getFileNameFromUrl,
  getFileExtension,
  inferFileExtension,
  lookupFileType,
  getFilenameOnly,
  requiresConversionToPng,
} from "../../tools/files";
import { loadPlaceholderImage } from "../../tools/placeholders";
import { createVideoElementForUrl } from "./video/video.element";

import imagePlaceholder from "../../resources/imagePlaceholder.png";
import videoPlaceholder from "../../resources/emptyPng.png";
import documentPlaceholder from "../../resources/documentPlaceholder.png";

interface FileDetails {
  fileName: string;
  fileType: string;
  fileExtension: string;
}

interface FileDetailsWithFile extends FileDetails {
  file: File;
}

interface FileDetailsWithConversion extends FileDetails {
  fileRequiresConversion: boolean;
}

type DocumentConstructorConfig =
  | StorageObjectConstructorConfig
  | StorageObjectFromFileConstructorConfig;

/**
 * Create a fabric.CollaboardDocument instance.
 */
export const createDocuments = async <T extends fabric.CollaboardDocument>(
  config: DocumentConstructorConfig,
  ObjectConstructor: new (
    thumbnail: HTMLImageElement,
    config: DocumentConstructorConfig
  ) => T
): Promise<T> => {
  const thumbnail = await loadPlaceholderImage(documentPlaceholder);

  if (isFileConfig(config)) {
    const { file, fileName, fileExtension, fileType } = getFileDetailsFromFile(
      config.file
    );

    return new ObjectConstructor(thumbnail, {
      ...config,
      file,
      fileExtension,
      fileName,
      fileType,
      isPlaceholder: true,
    });
  }

  return new ObjectConstructor(thumbnail, config);
};

export const createPDFDocuments = (
  config: DocumentConstructorConfig
): Promise<fabric.PDFDocument> => createDocuments(config, fabric.PDFDocument);
export const createWordDocuments = (
  config: DocumentConstructorConfig
): Promise<fabric.WordDocument> => createDocuments(config, fabric.WordDocument);
export const createExcelDocuments = (
  config: DocumentConstructorConfig
): Promise<fabric.ExcelDocument> =>
  createDocuments(config, fabric.ExcelDocument);
export const createPowerPointDocuments = (
  config: DocumentConstructorConfig
): Promise<fabric.PowerPointDocument> =>
  createDocuments(config, fabric.PowerPointDocument);

/**
 * Create a fabric.CollaboardImage instance.
 */
export const createImages = async (
  config:
    | StorageObjectConstructorConfig
    | StorageObjectFromFileConstructorConfig
    | StorageObjectFromImageConstructorConfig
): Promise<fabric.CollaboardImage> => {
  const thumbnail = await loadPlaceholderImage(imagePlaceholder);

  if (isFileConfig(config)) {
    return createImageFromFile(thumbnail, config);
  } else if (isImageConfig(config)) {
    return createImageFromImageConfig(thumbnail, config);
  }

  return createImage(thumbnail, config);
};

/**
 * Create a fabric.CollaboardVideo instance.
 */
export const createVideos = async (
  config:
    | StorageObjectConstructorConfig
    | StorageObjectFromFileConstructorConfig
): Promise<fabric.CollaboardVideo> => {
  if (isFileConfig(config)) {
    const { file, fileName, fileType, fileExtension } = getFileDetailsFromFile(
      config.file
    );

    const fileObjectURL = URL.createObjectURL(file); // TODO: This needs to be revoked

    const videoElement = await createVideoElementForUrl(
      fileObjectURL,
      fileType
    );

    return new fabric.CollaboardVideo(videoElement, {
      ...config,
      file,
      fileObjectURL,
      fileExtension,
      fileName,
      fileType,
    });
  }

  return new fabric.CollaboardVideo(document.createElement("video"), config);
};

/**
 * Create a fabric.YoutubeVideo instance.
 */
export const createYoutubeVideo = async (
  config: StorageObjectConstructorConfig
): Promise<fabric.YoutubeVideo> => {
  const thumbnail = await loadPlaceholderImage(videoPlaceholder);
  return new fabric.YoutubeVideo(thumbnail, config);
};

/**
 * Create an image instance from database (image is already uploaded)
 *
 * @private
 */
const createImage = async (
  thumbnail: HTMLImageElement,
  config: StorageObjectConstructorConfig
): Promise<fabric.CollaboardImage> => {
  return new fabric.CollaboardImage(thumbnail, {
    ...config,
    isPlaceholder: true,
  });
};

/**
 * Create an image instance from an image config.
 *
 * This is used when an image is created from the 'Search on web' panel.
 *
 * @private
 */
const createImageFromImageConfig = async (
  thumbnail: HTMLImageElement,
  config: StorageObjectFromImageConstructorConfig
): Promise<fabric.CollaboardImage> => {
  const { image } = config;
  const { src, encodingFormat } = image;

  const fallbackFileType = encodingFormat
    ? `image/${encodingFormat}`
    : undefined;

  const details = await getFileDetailsFromUrl(src, fallbackFileType).catch(
    () => {
      return Promise.reject(new Error("clientError.cannotLoadImage"));
    }
  );

  const {
    fileName,
    fileExtension,
    fileType,
    fileRequiresConversion,
  } = getConvertedImageFileDetails(details);

  return new fabric.CollaboardImage(thumbnail, {
    ...config,
    fileRequiresConversion,
    fileExtension,
    fileName,
    fileType,
    isPlaceholder: true,
  });
};

/**
 * Create an image instance from a File.
 *
 * This is used when an image file is uploaded from the user's file system.
 *
 * @private
 */
const createImageFromFile = async (
  thumbnail: HTMLImageElement,
  config: StorageObjectFromFileConstructorConfig
): Promise<fabric.CollaboardImage> => {
  const details = getFileDetailsFromFile(config.file);
  const {
    fileName,
    fileExtension,
    fileType,
    fileRequiresConversion,
  } = getConvertedImageFileDetails(details);
  const { file } = details;

  return new fabric.CollaboardImage(thumbnail, {
    ...config,
    file,
    fileRequiresConversion,
    fileExtension,
    fileName,
    fileType,
    isPlaceholder: true,
  });
};

/**
 * Extract file details, e.g. name, type and extension, from a URL.
 *
 * @private
 */
const getFileDetailsFromUrl = async (
  url: string,
  fallbackFileType?: string
): Promise<FileDetails> => {
  let fileName = getFileNameFromUrl(url);
  let fileExtension = getFileExtension(fileName);
  let fileType = mime.getType(fileName);

  if (!fileExtension || !fileType) {
    try {
      fileType = await lookupFileType(url);
    } catch (err) {
      if (fallbackFileType) {
        fileType = fallbackFileType;
      } else {
        return Promise.reject(err);
      }
    }
    fileExtension = inferFileExtension(fileType);
    fileName = `${fileName}${fileExtension}`;
  }

  return {
    fileName,
    fileType,
    fileExtension,
  };
};

/**
 * Extract file details, e.g. name, type and extension, from a File.
 *
 * @private
 */
const getFileDetailsFromFile = (file: File): FileDetailsWithFile => {
  const fileType = file.type;
  let fileName = file.name;
  let fileExtension = getFileExtension(fileName);

  if (fileExtension) {
    return {
      file,
      fileName,
      fileType,
      fileExtension,
    };
  }

  fileExtension = inferFileExtension(fileType);
  fileName = `${fileName}${fileExtension}`;

  return {
    file: new File([file], fileName, {
      type: fileType,
    }),
    fileName,
    fileType,
    fileExtension,
  };
};

/**
 * Get the details for an image file that requires conversion before uploading.
 *
 * Some images, such as TIFFs, need to be converted to PNGs before they are
 * uploaded. As a result their file name, type and extension need to be changed.
 *
 * The conversion itself is a slow process and it won't happen until the
 * point of upload, however the API requires the correct file name at the point
 * of object creation.
 *
 * @private
 */
const getConvertedImageFileDetails = (
  details: FileDetails
): FileDetailsWithConversion => {
  const fileRequiresConversion = requiresConversionToPng(
    details.fileName,
    details.fileType
  );

  if (!fileRequiresConversion) {
    return {
      ...details,
      fileRequiresConversion,
    };
  }

  const { fileName } = details;

  return {
    fileName: `${getFilenameOnly(fileName)}.png`,
    fileExtension: ".png",
    fileType: "image/png",
    fileRequiresConversion,
  };
};

/**
 * Type guards
 */
const isFileConfig = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: any
): config is StorageObjectFromFileConstructorConfig => {
  return config.file instanceof File;
};

const isImageConfig = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: any
): config is StorageObjectFromImageConstructorConfig => {
  return config.image && config.image.src;
};
