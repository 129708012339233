import { useCallback, useEffect, useState } from "react";
import { useField } from "formik";
import { isIOS, isMobile } from "react-device-detect";

import {
  findCountryBy,
  formatPhoneNumber,
  getCountry,
} from "../../../countries/countries";
import allCountries from "../../../countries/allCountries";

const initialCountry = findCountryBy("iso2", "intl") || allCountries[0];

export const usePhoneInputField = ({
  defaultCountry,
  props,
  disabled,
  inputLabel,
  phoneInput,
}) => {
  const [field, meta, helpers] = useField(props);
  const { name } = field;
  const { initialValue } = meta;
  const { setValue, setTouched } = helpers;
  const [shownCountry, setShownCountry] = useState(initialCountry);
  const [cachedCountry, setCachedCountry] = useState();
  const [phoneNumber, setPhoneNumber] = useState(initialValue || "");
  const [countriesList, toggleCountriesList] = useState(false);

  useEffect(() => {
    setShownCountry(
      cachedCountry || getCountry(phoneNumber, defaultCountry) || initialCountry
    );
  }, [phoneNumber, defaultCountry, cachedCountry]);

  // hide dropdown on click outside
  useEffect(() => {
    const clickOutside = (e) =>
      inputLabel.current &&
      !inputLabel.current.contains(e.target) &&
      toggleCountriesList(false);

    document.addEventListener("mousedown", clickOutside);
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
    // Note: inputLabel is a React.useRef()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // toggle dropdown with list of countries
  const handleToggleList = useCallback(
    () =>
      !disabled &&
      !isMobile &&
      !isIOS &&
      toggleCountriesList((state) => !state),
    [disabled]
  );

  // handle field value change
  const onFieldChange = async (e) => {
    const newValue = e.target.value.trim();

    if (!newValue.replace(/\+/g, "").length) {
      setCachedCountry(undefined);
      setPhoneNumber("");
      setValue("");

      return;
    }

    if (!/^[\d ()+-]+$/.test(newValue)) {
      return;
    }

    const formatted = await formatPhoneNumber(newValue, shownCountry.iso2);

    toggleCountriesList(false);
    !cachedCountry &&
      setShownCountry(getCountry(formatted, defaultCountry) || shownCountry);
    setPhoneNumber(formatted);
    setTouched(true);
    setValue(formatted);
  };

  // handle country selection on dropdown list
  const handleSelect = useCallback(
    (countryCode) => (e) => {
      const selectedCountry = findCountryBy(
        "iso2",
        countryCode || e.target.value
      );
      toggleCountriesList(false);
      setCachedCountry(selectedCountry);
      setPhoneNumber(selectedCountry.dialCode);
      phoneInput.current.focus();
    },
    // Note: phoneInput is a React.useRef()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    name,
    country: shownCountry,
    phoneNumber,
    countriesList,
    onFieldChange,
    handleSelect,
    handleToggleList,
  };
};
