import { isNil } from "ramda";
import { InvalidLocalStorageNamespaceError } from "../errors/invalidLocalStorageNamespaceError";
import { InternalError } from "../errors/InternalError";
import { isError, stringifyError } from "./errors";
import MemoryStorage, { MemoryStorageObject } from "./MemoryStorage";

function stringify(value: unknown): string {
  // Simple try / catch scoped in function to help V8 optimization
  try {
    return JSON.stringify(value);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return value as string;
  }
}

function parse(value: string): unknown {
  // Simple try / catch scoped in function to help V8 optimization
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
}

type RequiredNamespaces = { project?: boolean; user?: boolean };

export default class LocalStorage<T> {
  protected storage = window.localStorage;

  private userNamespace: string | number | null | undefined = null;
  private projectNamespace: string | number | null | undefined = null;
  private _requiredNamespaces: RequiredNamespaces;
  // Not private because it's used in tests
  _key = "";

  constructor(
    private itemKey: string,
    requiredNamespaces: RequiredNamespaces = { project: false, user: false }
  ) {
    this._requiredNamespaces = requiredNamespaces;
    this._setKey();
  }

  /**
   * Read value from local storage.
   */
  get = (): T | undefined => {
    const isValid = this._validateNamespaces();

    if (!isValid) {
      return undefined;
    }

    /**
     * Browsers set quotas for storage and eventually storage can become full,
     * in which case it will throw an exception. Something like
     * `QuotaExceededError`.
     *
     * According to StackOverflow comments it seems that this can happen when
     * you read or write from storage, so that's why we catch errors here too.
     */
    try {
      const value = this.storage.getItem(this._key);

      if (value === "undefined" || typeof value === "undefined") {
        return undefined;
      }

      return parse(value as string) as T;
    } catch (error) {
      /**
       * Telemetry references LocalStorage in its import stack so we need to
       * load it dynamically for the app to compile properly.
       */
      import("./telemetry")
        .then(({ LogCategory, onErrorToLog }) => {
          onErrorToLog(
            isError(error)
              ? error
              : new InternalError(
                  `Failed to read from local storage: ${stringifyError(error)}`
                ),
            LogCategory.other
          );

          window.location.replace(`${process.env.PUBLIC_URL}/no-storage.html`);
        })
        .catch();
    }

    return undefined;
  };

  /**
   * Store value in local storage.
   *
   * @param {*} value Value to store. Will be stringfied.
   */
  set = (value: T): void => {
    const isValid = this._validateNamespaces();

    if (!isValid) {
      return;
    }

    /**
     * Browsers set quotas for storage and eventually storage can become full,
     * in which case it will throw an exception. Something like
     * `QuotaExceededError` or `DOMException`.
     *
     * @TODO - If we catch a `QuotaExceededError` we could, in theory, clear
     * the storage ourselves and attempt to set the value again.
     */
    try {
      this.storage.setItem(this._key, stringify(value));
    } catch (error) {
      /**
       * Telemetry references LocalStorage in its import stack so we need to
       * load it dynamically for the app to compile properly.
       */
      import("./telemetry")
        .then(({ LogCategory, onErrorToLog }) => {
          onErrorToLog(
            isError(error)
              ? error
              : new InternalError(
                  `Failed to write to local storage: ${stringifyError(error)}`
                ),
            LogCategory.other
          );

          window.location.replace(`${process.env.PUBLIC_URL}/no-storage.html`);
        })
        .catch();
    }
  };

  /**
   * Remove value from local storage.
   */
  clear = (): void => this.storage.removeItem(this._key);

  /**
   * Set a namespace on the key to scope the value to a specific user.
   *
   * @param {string} username Username to use in namespace.
   */
  setUserNamespace = (
    username: string | number | null | undefined = null
  ): void => {
    this.userNamespace = username;
    this._setKey();
  };

  /**
   * Set a namespace on the key to scope the value to a specific project.
   *
   * @param {object} project Project ID and Key.
   */
  setProjectNamespace = (
    projectId: string | number | null | undefined = null
  ): void => {
    this.projectNamespace = projectId;
    this._setKey();
  };

  /**
   * Set the storage key.
   * @private
   */
  private _setKey(): void {
    const segments: (string | number | null | undefined)[] = [this.itemKey];

    if (!isNil(this.userNamespace)) {
      segments.push(this.userNamespace);
    }

    if (!isNil(this.projectNamespace)) {
      segments.push(this.projectNamespace);
    }

    this._key = segments.join("-");
  }

  /**
   * Check that the necessary namespaces have been set before allowing read or
   * write to storage. This is intended to prevent weird behaviour.
   *
   * If we're sure that everything is ok we can remove this.
   *
   * @private
   */
  private _validateNamespaces(): boolean {
    // Cannot use `onErrorToLog` as the on-premise logger uses the localStorage, resulting in circular
    // dependency. So we resort to console.error, which will track the error in PROD.
    /* eslint-disable no-console */

    const { project, user } = this._requiredNamespaces;
    if (
      project &&
      (isNil(this.projectNamespace) || isNil(this.userNamespace))
    ) {
      console.error(
        new InvalidLocalStorageNamespaceError(
          `Missing project or user namespace for storage ${this.itemKey}`
        ),
        {
          requiredNamespaces: this._requiredNamespaces,
          projectNamespace: this.projectNamespace,
          userNamespace: this.userNamespace,
        }
      );
      return false;
    } else if (user && isNil(this.userNamespace)) {
      console.error(
        new InvalidLocalStorageNamespaceError(
          `Missing user namespace for storage ${this.itemKey}`
        ),
        {
          requiredNamespaces: this._requiredNamespaces,
          projectNamespace: this.projectNamespace,
          userNamespace: this.userNamespace,
        }
      );
      return false;
    }

    return true;
  }
}

export class SessionStorage<T> extends LocalStorage<T> {
  protected storage = window.sessionStorage;
  static clearAll = (): void => {
    sessionStorage.clear();
  };
}

const unloadStorage = new SessionStorage<MemoryStorageObject>("unload-storage");
const memoryStorage = new MemoryStorage(unloadStorage);

export class InMemoryStorage<T> extends LocalStorage<T> {
  protected storage = memoryStorage;
}
