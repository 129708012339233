import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserPermissions } from "../../api";
import { getUserPermissionsDone } from "../../reduxStore/userPermissions/userPermissions.actions";
import { UserPermissionsState } from "../../reduxStore/userPermissions/userPermissions.reducer";
import { assertUnreachable } from "../../tools/assertions";
import { splitBy } from "../../tools/utils";
import {
  ApiPermission,
  ApiResourcePermission,
  ApiResourcePermissionMode,
  ApiResourcePermissionScope,
  ApiResourceType,
} from "../../types/enum";

export type PermissionRequest = {
  type: ApiResourceType;
  value: string | null;
  permission: ApiResourcePermission;
  // Used to validate RequiredPermissions with Scope
  spacePermission?: ApiPermission;
  projectPermission?: ApiPermission;
  // Used when there is no explicit RequiredPermission so we fallback to default rules
  defaultPermission?: boolean;
};

type UsePermissions = {
  getUserPermissions: () => void;
  requiresPermission: (request: Omit<PermissionRequest, "value">) => boolean;
  hasPermission: (request: PermissionRequest) => boolean;
};

const hasScopePermission = (
  Scope: ApiResourcePermissionScope,
  {
    spacePermission,
    projectPermission,
  }: { spacePermission?: ApiPermission; projectPermission?: ApiPermission }
): boolean => {
  switch (Scope) {
    case ApiResourcePermissionScope.ProjectOwner:
      return (
        !!projectPermission &&
        projectPermission === ApiPermission.ownerPermission
      );
    case ApiResourcePermissionScope.ProjectParticipant:
      return (
        !!projectPermission && projectPermission >= ApiPermission.readPermission
      );
    case ApiResourcePermissionScope.SpaceManager:
      return (
        !!spacePermission &&
        spacePermission >= ApiPermission.readWritePermission
      );
    case ApiResourcePermissionScope.SpaceParticipant:
      return (
        !!spacePermission && spacePermission >= ApiPermission.readPermission
      );
    default:
      assertUnreachable(Scope);
      return false;
  }
};

const evaluateAssignedPermissions = (
  assignedPermissions: ApiAssignedPermissions[],
  request: PermissionRequest
) => {
  const matchingPermissions = assignedPermissions.filter(
    (p) =>
      p.ResourceType === request.type && p.Permission === request.permission
  );
  const [allowedPermissions, deniedPermissions] = splitBy(
    matchingPermissions,
    (p) => p.PermissionMode === ApiResourcePermissionMode.Allowed
  );

  const isDeniedForAnyInstance = deniedPermissions.some(
    (p) => p.ResourceValue === null
  );
  const isDeniedForThisInstance = deniedPermissions.some(
    (p) => p.ResourceValue === request.value
  );

  const isAllowedForAnyInstance = allowedPermissions.some(
    (p) => p.ResourceValue === null
  );
  const isAllowedForThisInstance = allowedPermissions.some(
    (p) => p.ResourceValue === request.value
  );

  return {
    allowedPermissions,
    deniedPermissions,
    isDeniedForAnyInstance,
    isDeniedForThisInstance,
    isAllowedForAnyInstance,
    isAllowedForThisInstance,
  };
};

export const evaluateHasPermission = (
  assignedPermissions: ApiAssignedPermissions[],
  requiredPermissions: ApiRequiredPermission[],
  request: PermissionRequest
): boolean => {
  const requirements = requiredPermissions.filter(
    (p) =>
      p.ResourceType === request.type && p.Permission === request.permission
  );

  if (!requirements.length) {
    // The permission doesn't need to be checked so we fallback to Collaboard's default rules
    return request.defaultPermission ?? true;
  }

  const [requirementsWithScope, requirementsWoScope] = splitBy(
    requirements,
    (requirement) => !!requirement.Scope
  );

  // First check that every RequiredPermission with Scope is satisfied
  const scopesOkay = requirementsWithScope.every((requirement) => {
    if (!requirement.Scope) {
      return true;
    }

    if (
      !hasScopePermission(requirement.Scope, {
        projectPermission: request.projectPermission,
        spacePermission: request.spacePermission,
      })
    ) {
      return false;
    }

    // Even if the user has the Scope permission, we still check if there are some Denied AssignedPermission,
    // which in case takes the precedence
    const {
      isDeniedForAnyInstance,
      isDeniedForThisInstance,
    } = evaluateAssignedPermissions(assignedPermissions, request);

    return !(isDeniedForAnyInstance || isDeniedForThisInstance);
  });

  if (!scopesOkay) {
    return false;
  }

  // Then check remaining requirements without Scope. If none, then all requirements are met
  if (!requirementsWoScope.length) {
    return true;
  }

  const {
    allowedPermissions,
    isDeniedForAnyInstance,
    isDeniedForThisInstance,
    isAllowedForThisInstance,
    isAllowedForAnyInstance,
  } = evaluateAssignedPermissions(assignedPermissions, request);

  if (isDeniedForThisInstance) {
    return false;
  }

  if (isDeniedForAnyInstance) {
    // Check if there's a specific Allow permission for this instance
    return (
      allowedPermissions.some((p) => p.ResourceValue === request.value) || false
    );
  }

  return isAllowedForAnyInstance || isAllowedForThisInstance;
};

export const usePermissions = (): UsePermissions => {
  const { assignedPermissions, requiredPermissions } = useSelector<
    ApplicationGlobalState,
    UserPermissionsState
  >((state) => state.userPermissions);
  const dispatch = useDispatch();

  const requiresPermission = useCallback(
    (request: Omit<PermissionRequest, "value">): boolean => {
      return !!requiredPermissions.find(
        (p) =>
          p.ResourceType === request.type && p.Permission === request.permission
      );
    },
    [requiredPermissions]
  );

  /**
   * @NOTE Guest users will always have the permission request falling back to the `defaultPermission`
   * as the `/GetUserPermissions` API is not called for them and the default state is `requiredPermissions: []`.
   * This is fine as the default permission should not allow guest users to do anything like editing
   * project participants or inviting other users.
   */
  const hasPermission = useCallback(
    (request: PermissionRequest): boolean =>
      evaluateHasPermission(assignedPermissions, requiredPermissions, request),
    [assignedPermissions, requiredPermissions]
  );

  return {
    getUserPermissions: useCallback(() => {
      return getUserPermissions().then((userPermissions) =>
        dispatch(getUserPermissionsDone(userPermissions))
      );
    }, [dispatch]),
    requiresPermission,
    hasPermission,
  };
};
