import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Anyone(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M26.41,21.2c-1.43-1-3.64-.48-5-2.3,0,0-.51-.57.64-2s1.24-3.64.95-5.36a3.7,3.7,0,0,0-7.21,0c-.29,1.72-.19,4,1,5.36s.64,2,.64,2c-1.37,1.82-3.57,1.28-5,2.3s-1.09,3.36-1.09,3.36H27.5S27.85,22.22,26.41,21.2Z" />
      <path d="M28,31.61a3.78,3.78,0,0,0-3.76-3.78h-3a4,4,0,0,1,.38.34,4.88,4.88,0,0,1,.91,1.26h1.68a2.19,2.19,0,0,1,1.53.64,2.17,2.17,0,0,1-1.54,3.69H20.91a2.14,2.14,0,0,1-1.53-.63,2.2,2.2,0,0,1-.48-2.34,1,1,0,0,0-.71-.28h-.88a3.53,3.53,0,0,0-.16,1.08,3.75,3.75,0,0,0,3.75,3.77h3.3A3.76,3.76,0,0,0,28,31.61Z" />
      <path d="M17.49,35a4.86,4.86,0,0,1-.91-1.29h-1.7a2.13,2.13,0,0,1-1.52-.64,2.1,2.1,0,0,1-.63-1.53,2.15,2.15,0,0,1,2.16-2.16h3.3a2.15,2.15,0,0,1,1.53.64,2.18,2.18,0,0,1,.63,1.53,2.25,2.25,0,0,1-.16.81,1.06,1.06,0,0,0,.72.27h.88A3.94,3.94,0,0,0,22,31.59a3.76,3.76,0,0,0-3.76-3.77H14.9a3.77,3.77,0,0,0,0,7.53h3L17.49,35Z" />
    </Icon>
  );
}
export default Anyone;
