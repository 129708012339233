import React, { ReactElement } from "react";
import { useCustomerComponents } from "../../licensing/useCustomerComponents";

function Authenticate(): ReactElement {
  const { LoginPage } = useCustomerComponents();

  return <LoginPage />;
}

export default Authenticate;
