import React, {
  LazyExoticComponent,
  ReactElement,
  useCallback,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";
import { routes } from "../../const";
import { clearRedirectAfterLoginAction } from "../../reduxStore/auth/auth.actions";
import { useUISettings } from "../../reduxStore/uiSettings";
import { dynamicFeatureFlags } from "../../tools/flags";
import {
  hideWelcomeVideoStorage,
  welcomeVideoClosedStorage,
} from "../../tools/localStorageStores";
import WelcomeVideo from "../app/WelcomeVideo";
import { useAuthenticatingStatus } from "../authentication/useAuthenticatingStatus";
import { useLoggedInStatus } from "../authentication/useLoggedInStatus";
import SignalRStatus from "../shared/network/SignalRStatus";
import { useNetworkStatus } from "../shared/network/useNetworkStatus";
import FullPageSpinner from "../shared/spinners/FullPageSpinner";
import Title from "./Title";

type PageTitleProps = {
  path: string;
};

type RestrictedRouteProps = RouteProps &
  PageTitleProps & {
    component: LazyExoticComponent<() => ReactElement> | (() => ReactElement);
  };

type PrivateRouteProps = RestrictedRouteProps & {
  guestAccessible?: boolean;
  isPostLoginActionDone: boolean;
  tenant: string | undefined;
};

export function PrivateRoute({
  component: Component,
  guestAccessible = false,
  isPostLoginActionDone,
  tenant: loggedInWithTenant,
  ...rest
}: PrivateRouteProps): ReactElement {
  const {
    isLoggedIn,
    isLoggedInAsGuest,
    isLoggedInAsUser,
    loggedInViaTenant,
  } = useLoggedInStatus();
  const { isInitialized, isLoggingIn } = useAuthenticatingStatus();

  const isLoading =
    !isInitialized || isLoggingIn || (isLoggedIn && !isPostLoginActionDone);
  const isAllowed = isLoggedInAsUser || (isLoggedInAsGuest && guestAccessible);

  const { isDynamicFeatureActive } = useUISettings();

  useNetworkStatus();

  const isWelcomeVideoFeatureActive = isDynamicFeatureActive(
    dynamicFeatureFlags.WELCOME_VIDEO
  );

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoading) {
          return <FullPageSpinner />;
        }

        if (isAllowed) {
          const showWelcomeVideo =
            isWelcomeVideoFeatureActive &&
            !welcomeVideoClosedStorage.get() &&
            !hideWelcomeVideoStorage.get();

          return (
            <>
              <PageTitle path={rest.path} />
              <Component {...props} />
              {showWelcomeVideo && <WelcomeVideo />}
              <SignalRStatus />
            </>
          );
        }

        return (
          <Redirect
            to={{
              pathname: `${routes.authenticate}/${loggedInViaTenant ?? ""}`,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}

/**
 * The AuthRoute is not accessible to logged in users.
 */
export function AuthRoute({
  component: Component,
  ...rest
}: RestrictedRouteProps): ReactElement {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isLoggedInAsUser, redirectAfterLogin } = useLoggedInStatus();
  const { isInitialized, isLoggingIn } = useAuthenticatingStatus();

  const isLoading = !isInitialized || isLoggingIn;

  const getRedirect = useCallback(() => {
    if (!redirectAfterLogin) {
      // The default redirect
      return { pathname: routes.projects };
    }

    const { pathname, search } = new URL(
      redirectAfterLogin,
      window.location.origin
    );

    return { pathname, search };
  }, [redirectAfterLogin]);

  const redirectParams = getRedirect();

  useEffect(() => {
    if (isLoggedInAsUser && redirectAfterLogin) {
      dispatch(clearRedirectAfterLoginAction());
    }
  }, [dispatch, isLoggedInAsUser, redirectAfterLogin]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoading) {
          return <FullPageSpinner />;
        }

        if (isLoggedInAsUser) {
          return (
            <Redirect
              to={{
                ...redirectParams,
                state: { from: props.location },
              }}
            />
          );
        }

        return (
          <>
            <PageTitle path={rest.path} />
            {/**
             * Re-render the route component if the pathname changed. Needed for paths like `/authenticate`
             * and `/authenticate/:tenant` where the same component is used, but has different appearance.
             */}
            <Component {...props} key={location.pathname} />
          </>
        );
      }}
    />
  );
}

function PageTitle({ path }: PageTitleProps) {
  const { t } = useTranslation();
  const cleanPath = path.split("/")[1];

  return <Title title={t(`routes./${cleanPath}`)} />;
}
