import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function SelectColor(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M19.46,8.78A11.55,11.55,0,1,0,31,20.33,11.55,11.55,0,0,0,19.46,8.78Zm10.5,11h-3.6a6.89,6.89,0,0,0-1.66-4l2.55-2.55A10.46,10.46,0,0,1,30,19.81Zm-10-10a10.45,10.45,0,0,1,6.55,2.72L24,15.08a6.91,6.91,0,0,0-4-1.66Zm-1,0v3.6a6.89,6.89,0,0,0-4,1.66L12.4,12.54A10.45,10.45,0,0,1,19,9.82Zm-7.28,3.44,2.55,2.55a6.9,6.9,0,0,0-1.67,4H9A10.47,10.47,0,0,1,11.67,13.26ZM9,20.84h3.6a6.9,6.9,0,0,0,1.67,4l-2.55,2.55A10.47,10.47,0,0,1,9,20.84Zm10,10a10.5,10.5,0,0,1-6.55-2.71l2.54-2.55a6.89,6.89,0,0,0,4,1.66Zm-5.39-10.5a5.9,5.9,0,1,1,5.9,5.9A5.9,5.9,0,0,1,13.56,20.33ZM20,30.83v-3.6a6.91,6.91,0,0,0,4-1.66l2.54,2.55A10.5,10.5,0,0,1,20,30.83Zm7.28-3.44L24.7,24.84a6.89,6.89,0,0,0,1.66-4H30A10.46,10.46,0,0,1,27.25,27.39Z" />
    </Icon>
  );
}
export default SelectColor;
