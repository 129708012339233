import i18n from "i18next";
import { isNil, pickAll } from "ramda";
import { setConsole } from "react-query";
import { NIL as emptyUuid, v4 as uuid, validate as isUuid } from "uuid";
import { quickLinkShortcutsCodes } from "../const";
import { CollaboardError } from "../errors/collaboardError";
import {
  hasInnerObjects,
  isGroup,
  isInCollection,
} from "../studio/utils/fabricObjects";
import { getCustomerConfiguration } from "./customer";
import { isError } from "./errors";
import { isDevModeEnabled, isProduction } from "./flags";
import { LogCategory, onErrorToLog, startLoggingErrors } from "./telemetry";
import {
  isObject,
  noop,
  updateRuntimeConfigIfRunningForCapture,
} from "./utils";

// https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
// https://stackoverflow.com/questions/16541676/what-are-best-practices-for-detecting-pixel-ratio-density
export const getDevicePixelRatio = (): number => {
  let mediaQuery;
  const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
  if (window.devicePixelRatio !== undefined && !isFirefox) {
    return window.devicePixelRatio;
  }
  if (window.matchMedia) {
    mediaQuery =
      "(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-resolution: 1.5dppx)";
    if (window.matchMedia(mediaQuery).matches) {
      return 1.5;
    }
    mediaQuery =
      "(-webkit-min-device-pixel-ratio: 2), (min--moz-device-pixel-ratio: 2), (-o-min-device-pixel-ratio: 2/1), (min-resolution: 2dppx)";
    if (window.matchMedia(mediaQuery).matches) {
      return 2;
    }
    mediaQuery =
      "(-webkit-min-device-pixel-ratio: 0.75), (min--moz-device-pixel-ratio: 0.75), (-o-min-device-pixel-ratio: 3/4), (min-resolution: 0.75dppx)";
    if (window.matchMedia(mediaQuery).matches) {
      return 0.7;
    }
  }
  return 1;
};

export const generateIds = (quantity: number): string[] =>
  Array(quantity)
    .fill(null)
    .map(() => uuid());

export const groupBy = <
  Element,
  Value extends string | number | symbol,
  ValueAsKey extends Value | "null" | "undefined",
  Group extends Record<ValueAsKey, Element[]>
>(
  elements: Element[],
  valueGetter: (element: Element) => Value | null | undefined
): Group => {
  return elements.reduce((rv, element) => {
    const value = valueGetter(element) as ValueAsKey;
    const existingArray = rv[value];

    if (existingArray) {
      existingArray.push(element);
    } else {
      rv[value] = [element] as Group[ValueAsKey];
    }

    return rv;
  }, {} as Group);
};

export const toArray = <T>(object: T | T[] | null | undefined): T[] => {
  return isNil(object) ? [] : Array.isArray(object) ? object : [object];
};

const textStyles = {
  bold: 1,
  italic: 2,
  underline: 4,
};

export const decodeStyle = (style?: number): Partial<TextProps> =>
  isNil(style)
    ? {}
    : {
        fontWeight: style & textStyles.bold ? "bold" : "normal",
        fontStyle: style & textStyles.italic ? "italic" : "normal",
        underline: !!(style & textStyles.underline),
      };

export const encodeStyle = ({
  fontWeight,
  fontStyle,
  underline,
}: fabric.Text | fabric.StickyNote): number =>
  0 |
  (fontWeight === "bold" ? textStyles.bold : 0) |
  (fontStyle === "italic" ? textStyles.italic : 0) |
  (underline ? textStyles.underline : 0);

export const decodeProjectHash = (
  ProjectId: string,
  hash: string
): ProjectHash => {
  const id = hash.substring(1);
  if (id) {
    const numericId = +id;
    const isValidUuid = isUuid(id) && id !== emptyUuid;

    return {
      quickLink: numericId ? { Id: numericId, ProjectId } : undefined,
      objectUuid: isValidUuid ? id : undefined,
    };
  }

  return {};
};

export const getAbsolutePagePath = (): string => {
  const { origin, pathname } = window.location;
  return `${origin}${pathname}`;
};

const disableConsoleLogs = (): void => {
  Object.keys(console).forEach((key) => {
    // eslint-disable-next-line no-console
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (typeof (console as any)[key] === "function") {
      // Using named function so it is clear the method has been replaced
      // eslint-disable-next-line no-console
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (console as any)[key] = noop;
    }
  });

  // Disable react-query logs, otherwise console.error will be logged to telemetry
  setConsole({
    log: noop,
    warn: noop,
    error: noop,
  });
};

export const catchErrorsOnTopLevel = (): void => {
  if (window.isCatchingTopLevelErrors) {
    return;
  }

  window.addEventListener("unhandledrejection", (event) => {
    onErrorToLog(
      isError(event.reason)
        ? event.reason
        : new CollaboardError(`${event.reason || "Unknown rejected Promise"}`),
      LogCategory.unhandled,
      {
        subcategory: "unhandled-rejections",
      }
    );
    return event.preventDefault();
  });

  // Avoid overriding Application Insight's `onerror` handler
  if (!window.onerror) {
    window.onerror = (message, file, line, col, error) => {
      const errorToLog = error ? error : new CollaboardError(`${message}`);

      onErrorToLog(errorToLog, LogCategory.unhandled, {
        subcategory: "unhandled-errors",
        file,
        line,
        col,
      });

      return true;
    };
  }

  // Make sure we trace console.error() messages
  // eslint-disable-next-line no-console
  console.error = (...args: string[]) => {
    // Serialize objects to avoid being logged as [object Object]
    const message = args
      .map((arg) =>
        isObject(arg) && !isError(arg) ? JSON.stringify(arg, null, 2) : arg
      )
      .join("\r\n");
    onErrorToLog(new Error(message), LogCategory.internal, {
      subcategory: "console-error",
    });
  };

  window.isCatchingTopLevelErrors = true;
};

export const initializeNamesAndLabels = (): void => {
  const definition = getCustomerConfiguration();

  const link = document.querySelector<HTMLLinkElement>("#favicon");
  if (link && link.href) {
    link.href = link.href.replace("favicon.ico", definition.faviconName);
    const head = document.getElementsByTagName("head")[0];
    if (head) {
      head.appendChild(link);
    }
  }

  document.title = definition.appName;
};

/** *********************************************************** */

/** *********************************************************** */

export const initializeEnv = async (): Promise<void> => {
  // Is the app running for screen capturing purposes?
  updateRuntimeConfigIfRunningForCapture();

  /** @NOTE Needs to be before catchErrorsOnTopLevel as it sets window.onerror */
  await startLoggingErrors();

  if (isProduction()) {
    disableConsoleLogs();
  }

  if (!isDevModeEnabled()) {
    /** @NOTE Needs to be after disableConsoleLogs() because this also sets console.error */
    catchErrorsOnTopLevel();
  }

  initializeNamesAndLabels();
};

export const printPropsDebug = (
  target: fabric.Object,
  keys: string[],
  level = 0
): void => {
  const _target = Array.isArray(target) ? target : [target];
  _target.forEach((t: fabric.Object) => {
    const props = pickAll<fabric.Object, fabric.Object>(keys, t);
    const { type, uuid: _uuid } = t;
    // eslint-disable-next-line no-console
    console.log(`__props__ (${level})`, type, _uuid.slice(0, 6), { ...props });
    if (hasInnerObjects(t)) {
      t.getObjects().forEach((o) => printPropsDebug(o, keys, level + 1));
    }
  });
};

export const objectsInGroupsByIds = (
  objects: fabric.Object[],
  ids: string[]
): Array<fabric.Object> => {
  const results: Array<fabric.Object> = [];
  objects.forEach((o) => {
    if (isGroup(o)) {
      const objs = objectsInGroupsByIds(o.getObjects(), ids);
      results.push(...objs);
    } else if (isInCollection(o) && o.uuid && ids.includes(o.uuid)) {
      results.push(o);
    }
  });

  return results;
};

/** *********************************************************** */

/**
 * Calculate the byte size of a string.
 *
 * Supports Unicode / emojis 😀
 */
export const getStringSizeInBytes = (value: string): number =>
  new Blob([value]).size;

/**
 * Chunk items based on their byte sizes.
 *
 * @NOTE If a single array item is larger than the `maxByteSizePerChunk` the
 * item will have its own chunk, but there won't be an error.
 */
export const chunkItemsByByteSizes = <T>(
  items: T[],
  itemByteSizes: number[],
  baseByteSize: number,
  maxBytesPerChunk: number
): T[][] => {
  if (items.length !== itemByteSizes.length) {
    throw new Error("Mismatch between items and item byte sizes");
  }

  let currentChunkSizeInBytes = baseByteSize;

  return items.reduce<T[][]>(
    (result, item, index) => {
      const itemSizeInBytes = itemByteSizes[index];

      if (currentChunkSizeInBytes + itemSizeInBytes > maxBytesPerChunk) {
        result.push([]);
        currentChunkSizeInBytes = baseByteSize;
      }

      const currentChunk = result[result.length - 1];
      currentChunk.push(item);

      currentChunkSizeInBytes += itemSizeInBytes;

      return result;
    },
    [[]]
  );
};

/** *********************************************************** */

/**
 * method rounds to 2 decimal points
 * @param {number} value number to be rounded
 * @param {number} [x] Number of decimal places (0 - 100), default 2
 * @returns {number} number rounded in a way:
 * 11.11333 -> 11.11
 * 11.12567 -> 11.13
 * 11.1 -> 11.1
 * 11.003 -> 11
 * 11.00 -> 11
 */
export const roundToXdecimal = (number = 0, x = 2): number => {
  const dp = Math.min(Math.max(Math.floor(x), 0), 100);
  const result = +parseFloat(String(number)).toFixed(dp);

  if (Number.isNaN(result)) {
    throw new Error(`Cannot round number: ${number}`);
  }

  return result;
};

/**
 * method returns translated quicklink shortcuts list
 */
export const translatedQuicklinksShortcuts = (): string[] => {
  const translatedShortcuts = quickLinkShortcutsCodes.map(
    (key) => `Ctrl+Shift+${i18n.t(`shortcuts.${key}`)}`
  );
  return translatedShortcuts;
};

// From https://github.com/JohannesKlauss/react-hotkeys-hook/blob/master/src/useHotkeys.ts#L4
type AvailableTags = "INPUT" | "TEXTAREA" | "SELECT";

export const inputTags: AvailableTags[] = ["INPUT", "TEXTAREA", "SELECT"];

export const isInput = (element: EventTarget | null): boolean =>
  !!element &&
  inputTags.includes((element as HTMLElement).nodeName as AvailableTags);
