import type {
  DeleteTileMessage,
  LinkCreatedMessage,
  LinkDeletedMessage,
  LinkUpdatedMessage,
  LowResThumbnailAvailableMessage,
  NewBigInkMessage,
  NotifyNewMessage,
  NotifyTileBatchActionMessage,
  NotifyTilePropertyMessage,
  NotifyTileRelationMessage,
  PinMessage,
  ResponsiveImagesAvailableMessage,
  SingleThumbnailsMessage,
  ThumbnailCreationFailedMessage,
  ThumbnailsCreatedMessage,
  ThumbnailsCreatingMessage,
  TileGroupMessage,
  TileIndexesMessage,
  TilesCopiedMessage,
  UploadedMessage,
} from "../../../../api/signalR/message.types";

export enum NotifyActionType {
  ON_NOTIFY_DELETE = "ON_NOTIFY_DELETE",
  ON_NOTIFY_LOW_RES_THUMBNAIL_AVAILABLE = "ON_NOTIFY_LOW_RES_THUMBNAIL_AVAILABLE",
  ON_NOTIFY_NEW = "ON_NOTIFY_NEW",
  ON_NOTIFY_NEW_BIG_INK = "ON_NOTIFY_NEW_BIG_INK",
  ON_NOTIFY_OBJECT_LINK_CREATED = "ON_NOTIFY_OBJECT_LINK_CREATED",
  ON_NOTIFY_OBJECT_LINK_DELETED = "ON_NOTIFY_OBJECT_LINK_DELETED",
  ON_NOTIFY_PINNING = "ON_NOTIFY_PINNING",
  ON_NOTIFY_RESPONSIVE_IMAGES_AVAILABLE = "ON_NOTIFY_RESPONSIVE_IMAGES_AVAILABLE",
  ON_NOTIFY_THUMBNAIL_AVAILABLE = "ON_NOTIFY_THUMBNAIL_AVAILABLE",
  ON_NOTIFY_THUMBNAILS_CREATED = "ON_NOTIFY_THUMBNAILS_CREATED",
  ON_NOTIFY_THUMBNAILS_CREATING = "ON_NOTIFY_THUMBNAILS_CREATING",
  ON_NOTIFY_THUMBNAILS_FAILED = "ON_NOTIFY_THUMBNAILS_FAILED",
  ON_NOTIFY_TILE_BATCH = "ON_NOTIFY_TILE_BATCH",
  ON_NOTIFY_TILE_INDEXES_CHANGE = "ON_NOTIFY_TILE_INDEXES_CHANGE",
  ON_NOTIFY_TILE_PROPERTIES_CHANGED = "ON_NOTIFY_TILE_PROPERTIES_CHANGED",
  ON_NOTIFY_TILE_RELATION_CHANGE = "ON_NOTIFY_TILE_RELATION_CHANGE",
  ON_NOTIFY_TILE_REORDER = "ON_NOTIFY_TILE_REORDER",
  ON_NOTIFY_TILES_COPIED = "ON_NOTIFY_TILES_COPIED",
  ON_NOTIFY_UPLOADED = "ON_NOTIFY_UPLOADED",
}

export const onNotifyNewAction = (payload: NotifyNewMessage) =>
  ({
    type: NotifyActionType.ON_NOTIFY_NEW,
    payload,
  } as const);

export const onNotifyTilesCopiedAction = (payload: TilesCopiedMessage) =>
  ({
    type: NotifyActionType.ON_NOTIFY_TILES_COPIED,
    payload,
  } as const);

export const onNotifyNewBigInkAction = (payload: NewBigInkMessage) =>
  ({
    type: NotifyActionType.ON_NOTIFY_NEW_BIG_INK,
    payload,
  } as const);

export const onNotifyUploadedAction = (payload: UploadedMessage) =>
  ({
    type: NotifyActionType.ON_NOTIFY_UPLOADED,
    payload,
  } as const);

export const onNotifyThumbnailsCreatingAction = (
  payload: ThumbnailsCreatingMessage
) =>
  ({
    type: NotifyActionType.ON_NOTIFY_THUMBNAILS_CREATING,
    payload,
  } as const);

export const onNotifyThumbnailsFailedAction = (
  payload: ThumbnailCreationFailedMessage
) =>
  ({
    type: NotifyActionType.ON_NOTIFY_THUMBNAILS_FAILED,
    payload,
  } as const);

export const onNotifyLowResThumbnailAvailableAction = (
  payload: LowResThumbnailAvailableMessage
) =>
  ({
    type: NotifyActionType.ON_NOTIFY_LOW_RES_THUMBNAIL_AVAILABLE,
    payload,
  } as const);

export const onNotifyThumbnailAvailableAction = (
  payload: SingleThumbnailsMessage
) =>
  ({
    type: NotifyActionType.ON_NOTIFY_THUMBNAIL_AVAILABLE,
    payload,
  } as const);

export const onNotifyThumbnailsCreatedAction = (
  payload: ThumbnailsCreatedMessage
) =>
  ({
    type: NotifyActionType.ON_NOTIFY_THUMBNAILS_CREATED,
    payload,
  } as const);

export const onNotifyResponsiveImagesAvailableAction = (
  payload: ResponsiveImagesAvailableMessage
) =>
  ({
    type: NotifyActionType.ON_NOTIFY_RESPONSIVE_IMAGES_AVAILABLE,
    payload,
  } as const);

export const onNotifyDeleteAction = (payload: DeleteTileMessage) =>
  ({
    type: NotifyActionType.ON_NOTIFY_DELETE,
    payload,
  } as const);

export const onNotifyPinningActivityAction = (payload: PinMessage) =>
  ({
    type: NotifyActionType.ON_NOTIFY_PINNING,
    payload,
  } as const);

export const onNotifyTilePropertiesChangedAction = (
  payload: NotifyTilePropertyMessage
) =>
  ({
    type: NotifyActionType.ON_NOTIFY_TILE_PROPERTIES_CHANGED,
    payload,
  } as const);

export const onNotifyTileBatchAction = (
  payload: NotifyTileBatchActionMessage
) =>
  ({
    type: NotifyActionType.ON_NOTIFY_TILE_BATCH,
    payload,
  } as const);

export const onNotifyTileReorderAction = (payload: TileGroupMessage) =>
  ({
    type: NotifyActionType.ON_NOTIFY_TILE_REORDER,
    payload,
  } as const);

export const onNotifyTileIndexesChangeAction = (payload: TileIndexesMessage) =>
  ({
    type: NotifyActionType.ON_NOTIFY_TILE_INDEXES_CHANGE,
    payload,
  } as const);

export const onNotifyTileRelationChangedAction = (
  payload: NotifyTileRelationMessage
) =>
  ({
    type: NotifyActionType.ON_NOTIFY_TILE_RELATION_CHANGE,
    payload,
  } as const);

export const onNotifyLinkCreatedAction = (
  payload: LinkCreatedMessage | LinkUpdatedMessage
) =>
  ({
    type: NotifyActionType.ON_NOTIFY_OBJECT_LINK_CREATED,
    payload,
  } as const);

export const onNotifyLinkDeletedAction = (payload: LinkDeletedMessage) =>
  ({
    type: NotifyActionType.ON_NOTIFY_OBJECT_LINK_DELETED,
    payload,
  } as const);

export type OnNotifyNewAction = ReturnType<typeof onNotifyNewAction>;
export type OnNotifyTilesCopiedAction = ReturnType<
  typeof onNotifyTilesCopiedAction
>;
export type OnNotifyNewBigInkAction = ReturnType<
  typeof onNotifyNewBigInkAction
>;
export type OnNotifyUploadedAction = ReturnType<typeof onNotifyUploadedAction>;
export type OnNotifyThumbnailsCreatingAction = ReturnType<
  typeof onNotifyThumbnailsCreatingAction
>;
export type OnNotifyThumbnailsFailedAction = ReturnType<
  typeof onNotifyThumbnailsFailedAction
>;
export type OnNotifyLowResThumbnailAvailableAction = ReturnType<
  typeof onNotifyLowResThumbnailAvailableAction
>;
export type OnNotifyThumbnailAvailableAction = ReturnType<
  typeof onNotifyThumbnailAvailableAction
>;
export type OnNotifyThumbnailsCreatedAction = ReturnType<
  typeof onNotifyThumbnailsCreatedAction
>;
export type OnNotifyResponsiveImagesAvailableAction = ReturnType<
  typeof onNotifyResponsiveImagesAvailableAction
>;
export type OnNotifyDeleteAction = ReturnType<typeof onNotifyDeleteAction>;
export type OnNotifyPinningActivityAction = ReturnType<
  typeof onNotifyPinningActivityAction
>;
export type OnNotifyTilePropertiesChangedAction = ReturnType<
  typeof onNotifyTilePropertiesChangedAction
>;
export type OnNotifyTileBatchAction = ReturnType<
  typeof onNotifyTileBatchAction
>;
export type OnNotifyTileReorderAction = ReturnType<
  typeof onNotifyTileReorderAction
>;
export type OnNotifyTileIndexesChangeAction = ReturnType<
  typeof onNotifyTileIndexesChangeAction
>;
export type OnNotifyTileRelationChangedAction = ReturnType<
  typeof onNotifyTileRelationChangedAction
>;
export type OnNotifyLinkCreatedAction = ReturnType<
  typeof onNotifyLinkCreatedAction
>;
export type OnNotifyLinkDeletedAction = ReturnType<
  typeof onNotifyLinkDeletedAction
>;

export type HistoryNotifyAction =
  | OnNotifyNewAction
  | OnNotifyTilesCopiedAction
  | OnNotifyNewBigInkAction
  | OnNotifyUploadedAction
  | OnNotifyThumbnailsCreatingAction
  | OnNotifyThumbnailsFailedAction
  | OnNotifyLowResThumbnailAvailableAction
  | OnNotifyThumbnailAvailableAction
  | OnNotifyThumbnailsCreatedAction
  | OnNotifyResponsiveImagesAvailableAction
  | OnNotifyDeleteAction
  | OnNotifyPinningActivityAction
  | OnNotifyTilePropertiesChangedAction
  | OnNotifyTileBatchAction
  | OnNotifyTileReorderAction
  | OnNotifyTileIndexesChangeAction
  | OnNotifyTileRelationChangedAction
  | OnNotifyLinkCreatedAction
  | OnNotifyLinkDeletedAction;
