/**
 * The values in this file should be precomputed. We have a method that
 * should generate the required values but it has been broken for a long time
 * and needs fixing. See `preCalculateAnchorPointsForShapes`
 *
 * Approach:
 *  - Loop through shapes
 *  - Add each to canvas
 *  - Calculate the anchor positions (using existing methods)
 *  - Format them into JSON / JS
 *  - Write file to repo / replace values in this file with output.
 */

import { ApiShapeId } from "./shapeId";

export const shapesAnchorPoints: Record<ApiShapeId, number[]> = {
  "1": [-113.64, 0, 158.5, 0, 0, -93.99, 0, 88],
  "2": [-38, 0, 40, 0, 0, -21.88, 0, 27.85],
  "3": [-64.2, 0, 90.28, 0, 0, -9.96, 0, 128.5],
  "4": [-32.28, 0, 30.26, 0, 0, -16.78, 0, 8.89],
  "5": [-15, 0, 13, 0, 0, -6.89, 0, 12.79],
  "6": [-47, 0, 49, 0, 0, -18.1, 0, 5.03],
  "7": [-10, 0, 8, 0, 0, -1.04, 0, 6.23],
  "8": [-10, 0, 15, 0, 0, -5, 0, 13.01],
  "9": [-48.74, 0, 37.8, 0, 0, -23.82, 0, 10.92],
  "10": [-20.86, 0, 46.68, 0, 0, -2, 0, 48],
  "11": [-12.26, 0, 13.29, 0, 0, -16, 0, 16],
  "12": [-146.07, 0, 239.12, 0, 0, -161.02, 0, 248.03],
  "13": [-50, 0, 50, 0, 0, -16.04, 0, 16.04],
  "14": [-38.65, 0, 39.64, 0, 0, -45, 0, 22],
  "15": [-13, 0, 13, 0, 0, -13, 0, 13],
  "16": [-48.38, 0, 48.39, 0, 0, -10.88, 0, 10.88],
  "17": [-42.05, 0, 42.05, 0, 0, -48.56, 0, 48.56],
  "18": [-37, 0, 36, 0, 0, -27.08, 0, 36.1],
  "19": [-276, 0, 275, 0, 0, -282.5, 0, 282.5],
  "20": [-1.5, 0, 1.5, 0, 0, -0.12, 0, 0.13],
  "21": [-37.22, 0, 37.22, 0, 0, -37.22, 0, 37.22],
  "22": [-37.22, 0, 37.22, 0, 0, -37.22, 0, 37.22],
  "23": [-11, 0, 10, 0, 0, -13, 0, 9],
  "24": [-20, 0, 19, 0, 0, -32.9, 0, 32.9],
  "25": [-26.07, 0, 26.08, 0, 0, -26.07, 0, 26.08],
  "26": [-26.07, 0, 26.08, 0, 0, -26.07, 0, 26.08],
  "27": [-45, 0, 45, 0, 0, -45, 0, 45],
  "28": [-22, 0, 22, 0, 0, -22, 0, 22],
  "29": [-40.17, 0, 40.17, 0, 0, -40.17, 0, 40.17],
  "30": [-32, 0, 32, 0, 0, -32, 0, 32],
  "31": [-26.07, 0, 26.08, 0, 0, -26.07, 0, 26.08],
  "32": [-39.2, 0, 38.2, 0, 0, -40.03, 0, 46.04],
  "33": [-411.99, 0, 412, 0, 0, -411.75, 0, 90.94],
  "34": [-26.07, 0, 26.08, 0, 0, -26.07, 0, 26.08],
  "35": [-26.07, 0, 26.08, 0, 0, -26.07, 0, 26.08],
  "36": [-26.07, 0, 26.08, 0, 0, -26.07, 0, 26.08],
  "37": [-24.68, 0, 32.58, 0, 0, -6.96, 0, 14.91],
  "38": [-19.99, 0, 20.99, 0, 0, -20.56, 0, 19.58],
  "39": [-63.43, 0, 63.44, 0, 0, -16.7, 0, 15.72],
  "40": [-14.84, 0, 8.9, 0, 0, -23.45, 0, 23.45],
  "41": [-24.02, 0, 24.03, 0, 0, -24.02, 0, 24.03],
  "42": [-23.97, 0, 23.98, 0, 0, -14.98, 0, 14.98],
  "43": [-33, 0, 32, 0, 0, -27.34, 0, 33.41],
  "44": [-42.26, 0, 10.06, 0, 0, -10.01, 0, 38.04],
  "45": [-1.98, 0, 2.96, 0, 0, -40.8, 0, 26.87],
  "46": [-35.33, 0, 34.32, 0, 0, -45, 0, 41],
  "47": [-8, 0, 45, 0, 0, -40.69, 0, 19.85],
  "48": [-23, 0, 31, 0, 0, -19.72, 0, 25.64],
  "49": [-23, 0, 20, 0, 0, -4.89, 0, 21.5],
  "50": [-22, 0, 21, 0, 0, -24.99, 0, 24.99],
  "51": [-25, 0, 25, 0, 0, -16, 0, 18],
  "52": [-21, 0, 21, 0, 0, -23, 0, 23],
  "53": [-38, 0, 45, 0, 0, -45, 0, 45],
  "54": [-15.47, 0, 9.28, 0, 0, -21, 0, 25],
  "55": [-25, 0, 22, 0, 0, -10, 0, 10],
  "56": [-23.91, 0, 23.92, 0, 0, -11, 0, 25],
  "57": [-18, 0, 17, 0, 0, -25, 0, 25],
  "58": [-25, 0, 25, 0, 0, -7.6, 0, 7.61],
  "59": [-14.12, 0, 14.12, 0, 0, -24.54, 0, 24.55],
  "60": [-25, 0, 25, 0, 0, -23.08, 0, 24.09],
  "61": [-45, 0, 45, 0, 0, -45, 0, 45],
  "62": [-13.32, 0, 16.4, 0, 0, -24.05, 0, 24.05],
  "63": [-24.6, 0, 24.6, 0, 0, -32, 0, 32],
  "64": [-15.17, 0, 14.16, 0, 0, -47.43, 0, 47.44],
  "65": [-24, 0, 23, 0, 0, -14.01, 0, 8],
  "66": [-12.39, 0, 12.39, 0, 0, -12.39, 0, 12.39],
  "67": [-8.5, 0, 8.5, 0, 0, -8.5, 0, 8.5],
  "68": [-29, 0, 28, 0, 0, -15.02, 0, 37.06],
  "69": [-19, 0, 19, 0, 0, -24, 0, 18],
  "70": [-19, 0, 17, 0, 0, -19.16, 0, 7.06],
  "71": [-18, 0, 18, 0, 0, -24, 0, 24],
  "72": [-42, 0, 42, 0, 0, -33, 0, 33],
  "73": [-8.93, 0, 9.93, 0, 0, -6.2, 0, 12.41],
  "74": [-8.66, 0, 10.59, 0, 0, -12.66, 0, 12.66],
  "75": [-14.75, 0, 14.75, 0, 0, -8.94, 0, 4.96],
  "76": [-10.77, 0, 10.77, 0, 0, -13.02, 0, 5.01],
  "77": [-9.84, 0, 9.84, 0, 0, -13.02, 0, 13.03],
  "78": [-5.03, 0, 13.07, 0, 0, -10.75, 0, 10.75],
  "79": [-9.91, 0, 9.91, 0, 0, -13.01, 0, 13.01],
  "80": [-10.59, 0, 9.63, 0, 0, -12.62, 0, 12.62],
  "81": [-4.86, 0, 4.86, 0, 0, -11.64, 0, 8.73],
  "82": [-12.99, 0, 5, 0, 0, -10.73, 0, 10.73],
  "83": [-10.75, 0, 10.75, 0, 0, -12, 0, 13.01],
  "84": [-10.64, 0, 12.57, 0, 0, -8.97, 0, 9.96],
  "85": [-34.58, 0, 34.58, 0, 0, -47.5, 0, 47.5],
  "86": [-37.22, 0, 36.21, 0, 0, -47.5, 0, 47.5],
  "87": [-275.6, 0, 307.55, 0, 0, -243.63, 0, 277.58],
  "88": [-18, 0, 18, 0, 0, -47.5, 0, 47.5],
  "89": [-27.82, 0, 42.72, 0, 0, -45.52, 0, 45.52],
  "90": [-47.45, 0, 47.46, 0, 0, -30.3, 0, 30.3],
  "91": [-36.57, 0, 36.57, 0, 0, -47.4, 0, 5.04],
  "92": [-30.13, 0, 30.13, 0, 0, -47.5, 0, 47.5],
  "93": [-31.04, 0, 22.03, 0, 0, -47.5, 0, 47.5],
  "94": [-42.6, 0, 30.71, 0, 0, -45.52, 0, 47.5],
  "95": [-45, 0, 45, 0, 0, -32.16, 0, 29.15],
  "96": [-9.08, 0, 8.07, 0, 0, -12.12, 0, 47.47],
  "97": [-41, 0, 40, 0, 0, -38.25, 0, 38.25],
  "98": [-45, 0, 45, 0, 0, -28.59, 0, 34.5],
  "99": [-3, 0, 2, 0, 0, -45, 0, 45],
  "100": [-15.97, 0, 15.98, 0, 0, -13.52, 0, 13.53],
  "101": [-45, 0, 44, 0, 0, -45, 0, 45],
  "102": [-16, 0, 16, 0, 0, -15.97, 0, 15.97],
  "103": [-45, 0, 45, 0, 0, -21.28, 0, 21.28],
  "104": [-30.13, 0, 30.13, 0, 0, -28.99, 0, 44.98],
  "105": [-43.27, 0, 44.28, 0, 0, -14.88, 0, 46.61],
  "106": [-45, 0, 39, 0, 0, -35.78, 0, 38.76],
  "107": [-44.69, 0, 44.69, 0, 0, -45, 0, 45],
  "108": [-45, 0, 45, 0, 0, -40.23, 0, 40.23],
  "109": [-31, 0, 31, 0, 0, -24.9, 0, 24.9],
  "110": [-17, 0, 19, 0, 0, -24, 0, 24],
  "111": [-12.98, 0, 13.98, 0, 0, -34.33, 0, 47.45],
  "112": [-6.92, 0, 7.9, 0, 0, -7.9, 0, 6.92],
  "113": [-21, 0, 20, 0, 0, -42, 0, 21],
  "114": [-16, 0, 10, 0, 0, -24, 0, 24],
  "115": [-21, 0, 21, 0, 0, -23.5, 0, 23.5],
  "116": [-141.83, 0, 141.84, 0, 0, -190.96, 0, 190.97],
  "117": [-22, 0, 10, 0, 0, -24, 0, 24],
  "118": [-13, 0, 13, 0, 0, -13, 0, 13],
  "119": [-34, 0, 42, 0, 0, -42, 0, 42],
  "120": [-18.14, 0, 16.12, 0, 0, -16.32, 0, 16.32],
  "121": [-14, 0, 13, 0, 0, -5.98, 0, 11.96],
  "122": [-9, 0, 10, 0, 0, -14.11, 0, 40.31],
  "123": [-41.69, 0, 6.95, 0, 0, -11.03, 0, 11.03],
  "124": [-4, 0, 13, 0, 0, -9.01, 0, 15.02],
  "125": [-86.83, 0, 86.83, 0, 0, -127.15, 0, 127.15],
  "126": [-19, 0, 18, 0, 0, -45, 0, 31],
  "127": [-31.68, 0, 31.68, 0, 0, -41.52, 0, 41.53],
  "128": [-44, 0, 43, 0, 0, -45.2, 0, 31.14],
  "129": [-45, 0, 45, 0, 0, -45, 0, 45],
  "130": [-16.19, 0, 15.18, 0, 0, -39, 0, 39],
  "131": [-30.22, 0, 30.22, 0, 0, -33.49, 0, 33.49],
  "132": [-29.03, 0, 28.02, 0, 0, -39.9, 0, 39.9],
  "133": [-6.07, 0, 15.16, 0, 0, -18.7, 0, 6.89],
  "134": [-24, 0, 24, 0, 0, -24, 0, 24],
  "135": [-24, 0, 24, 0, 0, -24, 0, 24],
  "136": [-29, 0, 28, 0, 0, -15.02, 0, 37.06],
  "137": [-31.33, 0, 31.33, 0, 0, -31.33, 0, 31.33],
  "138": [-5.97, 0, 23.89, 0, 0, -24, 0, 22],
  "139": [-21, 0, 22, 0, 0, -22, 0, 22],
  "140": [-11.05, 0, 14.07, 0, 0, -6.13, 0, 11.24],
  "141": [-19, 0, 21, 0, 0, -14, 0, 24],
  "142": [-24, 0, 24, 0, 0, -24, 0, 24],
  "143": [-11, 0, 10, 0, 0, -23.99, 0, 23.99],
  "144": [-19, 0, 24, 0, 0, -7, 0, 13],
  "145": [-22, 0, 7, 0, 0, -10, 0, 15],
  "146": [-154, 0, 154, 0, 0, -86.5, 0, 58.66],
  "147": [-24, 0, 23, 0, 0, -10.41, 0, 7.29],
  "148": [-24, 0, 23, 0, 0, -4.96, 0, 5.95],
  "149": [-24, 0, 23, 0, 0, -17, 0, 17],
  "150": [-31.31, 0, 31.31, 0, 0, -45, 0, 45],
  "151": [-64.27, 0, 64.27, 0, 0, -31.05, 0, 26.04],
  "152": [-22.16, 0, 17.12, 0, 0, -14, 0, 13],
  "153": [-14.13, 0, 21.19, 0, 0, -13.11, 0, 22.19],
  "154": [-19, 0, 21, 0, 0, -11.2, 0, 16.3],
  "155": [-96.22, 0, 96.22, 0, 0, -142.69, 0, 122.74],
  "156": [-24, 0, 24, 0, 0, -16, 0, 14],
  "157": [-42.3, 0, 40.28, 0, 0, -23.21, 0, 23.22],
  "158": [-38.23, 0, 38.23, 0, 0, -40.02, 0, 40.03],
  "159": [-44.78, 0, 34.83, 0, 0, -39.49, 0, 37.46],
  "160": [-39.04, 0, 38.04, 0, 0, -42.31, 0, 41.3],
  "161": [-37.02, 0, 36.02, 0, 0, -43.43, 0, 32.32],
  "162": [-36.07, 0, 31.06, 0, 0, -28.7, 0, 11.87],
  "163": [-42.86, 0, 42.87, 0, 0, -37.08, 0, 24.05],
  "164": [-41.16, 0, 32.13, 0, 0, -40.17, 0, 27.12],
  "165": [-44.26, 0, 44.26, 0, 0, -44.26, 0, 44.26],
  "166": [-13.05, 0, 28.12, 0, 0, -32.38, 0, 42.5],
  "167": [-32.8, 0, 31.81, 0, 0, -40.86, 0, 28.9],
  "168": [-40.99, 0, 22.99, 0, 0, -44.02, 0, 22.01],
  "169": [-14, 0, 14, 0, 0, -9, 0, 8],
  "170": [-8, 0, 7, 0, 0, -12, 0, 12],
  "171": [-13, 0, 12, 0, 0, -10, 0, 10],
  "172": [-14, 0, 13, 0, 0, -13, 0, 13],
  "173": [-14, 0, 13, 0, 0, -13, 0, 13],
  "174": [-8, 0, 7, 0, 0, -10, 0, 11],
  "175": [-11, 0, 11, 0, 0, -11, 0, 11],
  "176": [-8, 0, 7, 0, 0, -13, 0, 13],
  "177": [-13, 0, 12, 0, 0, -12, 0, 12],
  "178": [-12, 0, 11, 0, 0, -16, 0, 16],
  "179": [-16, 0, 15, 0, 0, -7, 0, 4],
  "180": [-8, 0, 7, 0, 0, -13, 0, 13],
  "181": [-15, 0, 15, 0, 0, -1, 0, 10],
  "182": [-6, 0, 5, 0, 0, -15, 0, 15],
  "183": [-16, 0, 14, 0, 0, -13, 0, 14],
  "184": [-7, 0, 5, 0, 0, -11, 0, 12],
  "185": [-14, 0, 13, 0, 0, -11, 0, 10],
  "186": [-14, 0, 12, 0, 0, -14, 0, 15],
  "187": [-4, 0, 2, 0, 0, -9, 0, 5],
  "188": [-13, 0, 14, 0, 0, -8, 0, 7],
  "189": [-10, 0, 8, 0, 0, -14, 0, 15],
  "190": [-7, 0, 5, 0, 0, -14, 0, 13],
  "191": [-6, 0, 5, 0, 0, -15, 0, 15],
  "192": [-11, 0, 7, 0, 0, -8, 0, 10],
  "193": [-12, 0, 11, 0, 0, -8, 0, 8],
  "194": [-14, 0, 13, 0, 0, -14, 0, 15],
  "195": [-16, 0, 15, 0, 0, -15, 0, 15],
  "196": [-13, 0, 12, 0, 0, -13, 0, 13],
  "197": [-16, 0, 15, 0, 0, -16, 0, 15],
  "198": [-16, 0, 15, 0, 0, -15, 0, 15],
  "199": [-13, 0, 12, 0, 0, -8, 0, 8],
  "200": [-14, 0, 13, 0, 0, -13, 0, 13],
  "201": [-11, 0, 10, 0, 0, -13, 0, 14],
  "202": [-8, 0, 4, 0, 0, -14, 0, 5],
  "203": [-15, 0, 15, 0, 0, -16, 0, 15],
  "204": [-12, 0, 11, 0, 0, -12, 0, 10],
  "205": [-7, 0, 7, 0, 0, -14, 0, 14],
  "206": [-6, 0, 12, 0, 0, -8, 0, 3],
  "207": [-11, 0, 8, 0, 0, -12, 0, 8],
  "208": [-5, 0, 10, 0, 0, -12, 0, 1],
  "209": [-5, 0, 7, 0, 0, -5, 0, 7],
  "210": [-3, 0, 6, 0, 0, -4, 0, 4],
  "211": [-7, 0, 6, 0, 0, -7, 0, 6],
  "212": [-12, 0, 9, 0, 0, -9, 0, 11],
  "213": [-6, 0, 7, 0, 0, -5, 0, 8],
  "214": [-6, 0, 2, 0, 0, -3, 0, 5],
  "215": [-13, 0, 20, 0, 0, -20, 0, 13],
  "216": [-4, 0, 3, 0, 0, -4, 0, 3],
  "217": [-13, 0, 13, 0, 0, -8, 0, 8],
  "218": [-14, 0, 12, 0, 0, -8, 0, 8],
  "219": [-11, 0, 10, 0, 0, -13, 0, 13],
  "220": [-16, 0, 15, 0, 0, -16, 0, 15],
  "221": [-16, 0, 15, 0, 0, -15, 0, 15],
  "222": [-14, 0, 14, 0, 0, -14, 0, 14],
  "223": [-9, 0, 8, 0, 0, -11, 0, 11],
  "224": [-14, 0, 13, 0, 0, -13, 0, 13],
  "225": [-8, 0, 7, 0, 0, -14, 0, 13],
  "226": [-10, 0, 11, 0, 0, -9, 0, 8],
  "227": [-14, 0, 13, 0, 0, -12, 0, 12],
  "228": [-15, 0, 14, 0, 0, -9, 0, 9],
  "1000": [-50, 0, 50, 0, 0, -0.5, 0, 0.5],
};

/**
 * Use this to compute the difference between the newly calculated anchor points and the old ones.
 * For some reason, it seems that `canvas.preCalculateAnchorPointsForShapes()` is not correctly calculating
 * some points (about 5 shapes of 229). If you try to create a shape in the application and use
 * `AnchorAlgorithm.linear` to compute the anchor points on-the-fly, it works perfectly.
 * So there may be some slight difference between the automated method and when manually adding a shape.
 *
 * Manually check if the new anchor points are acceptable and pick the values that you prefer between
 * old-precomputed, new-precomputed, new-computed-on-the-fly.
 */
// const calculateDifference = () => {
//   Object.entries(newShapesAnchorPoints).forEach(([shapeId, points]) => {
//     const oldPoints = shapesAnchorPoints[Number(shapeId) as ApiShapeId];

//     if (!oldPoints) {
//       return;
//     }

//     const hasChanged = zip(points, oldPoints).some(([p1, p2]) => {
//       const absoluteDiff = Math.abs(p1 - p2);
//       const diffRate = absoluteDiff / p2;

//       // New anchor point difference is 30% than the old one and greater than 5px
//       return diffRate > 0.3 && absoluteDiff > 5;
//     });

//     if (hasChanged) {
//       // eslint-disable-next-line no-console
//       console.log(
//         `Detected different anchor point for shape ${shapeId}:
//         ${points.map((p) => String(p).padEnd(6))}
//         ${oldPoints.map((p) => String(p).padEnd(6))}`
//       );
//     }
//   });
// };
