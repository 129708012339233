import React, { ReactElement } from "react";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Text } from "theme-ui";
import { maxProjectCountInFreePlan, routes } from "../../../../const";

function ReadOnlyProjectTooltip(): ReactElement {
  const history = useHistory<void>();

  return (
    <Text sx={{ cursor: "default" }}>
      <Trans
        i18nKey={"app.readOnlyProject"}
        values={{ limit: maxProjectCountInFreePlan }}
      >
        <Text
          variant="links.projectThumbnailLink.updateLink"
          onClick={(e) => {
            e.preventDefault();
            history.push(routes.plans);
          }}
        />
      </Trans>
    </Text>
  );
}

export default ReadOnlyProjectTooltip;
