import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function LinkOrQR(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M28.66,8a3.77,3.77,0,0,0-3.75-3.77h-3a3.9,3.9,0,0,1,.38.33,5.06,5.06,0,0,1,.9,1.27h1.69a2.16,2.16,0,1,1,0,4.32H21.61a2.17,2.17,0,0,1-2.16-2.17,2.05,2.05,0,0,1,.16-.81,1.11,1.11,0,0,0-.72-.27H18a3.81,3.81,0,0,0-.16,1.08A3.76,3.76,0,0,0,21.6,11.7h3.29A3.75,3.75,0,0,0,28.66,8Z" />
      <path d="M18.2,11.38a5,5,0,0,1-.92-1.28H15.59a2.15,2.15,0,0,1-2.16-2.16A2.17,2.17,0,0,1,15.6,5.77h3.29a2.14,2.14,0,0,1,1.53.63,2.16,2.16,0,0,1,.47,2.34,1.07,1.07,0,0,0,.72.28h.87A3.87,3.87,0,0,0,22.65,8a3.76,3.76,0,0,0-3.76-3.77H15.6a3.76,3.76,0,0,0,0,7.52h3l-.35-.32Z" />
      <path d="M13.23,19.63v4.64h4.63V19.63Zm-1.54-1.54H19.4v7.72H11.69Z" />
      <rect x="14.77" y="21.18" width="1.54" height="1.54" />
      <rect x="14.77" y="30.44" width="1.54" height="1.54" />
      <rect x="20.95" y="27.35" width="3.09" height="1.54" />
      <rect x="20.95" y="30.44" width="1.54" height="4.63" />
      <rect x="24.04" y="33.53" width="1.54" height="1.54" />
      <rect x="25.58" y="27.35" width="1.54" height="1.54" />
      <rect x="24.04" y="21.18" width="1.54" height="1.54" />
      <path d="M22.49,19.63v4.64h4.63V19.63ZM21,18.09h7.72v7.72H21Z" />
      <path d="M13.23,28.9v4.63h4.63V28.9Zm-1.54-1.55H19.4v7.72H11.69Z" />
      <polygon points="24.04 31.98 24.04 30.44 27.12 30.44 27.12 31.98 24.04 31.98" />
      <polygon points="28.67 35.07 27.12 35.07 27.12 27.35 28.67 27.35 28.67 35.07" />
    </Icon>
  );
}
export default LinkOrQR;
