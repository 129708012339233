import React, { ReactElement, useContext, useEffect, useMemo } from "react";
import { Flex } from "theme-ui";
import { useProjectParticipants } from "../../../../../api/useRequest";
import LoadingSpinner from "../../../../../icons/LoadingSpinner";
import collaBoard from "../../../../../tools/collaboard";
import NoResults from "../../../../shared/layout/NoResults";
import { ProjectsContext } from "../../Projects";
import ProjectCollaborators from "./ProjectCollaborators";
import ProjectDetails from "./ProjectDetails";
import { PermissionChangeProps } from "./UserCard";

type Props = {
  projectWithMeta: ApiProject;
};

const participantsConfig = { registeredOnly: true }; // extracted const, prevents infinite loop (useCallback deps)

function ProjectResultByDateExtended({ projectWithMeta }: Props): ReactElement {
  const { Project: project, LastAccessDate: lastAccessDate } = projectWithMeta;
  const { refreshProjects } = useContext(ProjectsContext).current;

  const {
    users,
    participantsLoaded,
    getProjectParticipants,
    participantsError,
  } = useProjectParticipants(project.ProjectId, participantsConfig);

  useEffect(() => {
    getProjectParticipants();
  }, [getProjectParticipants]);

  const onOwnerChange = () => {
    refreshProjects();
    getProjectParticipants();
  };

  const onPermissionChange = async (
    props: PermissionChangeProps
  ): Promise<void> => {
    const { permission, user } = props;

    const { signalRClient } = collaBoard;

    await signalRClient.postProjectParticipantChanged(
      Number(project.ProjectId),
      user.UserName,
      permission
    );
    getProjectParticipants();
  };

  const myself = useMemo(() => users.find(({ IsMyself }) => IsMyself), [users]);

  return (
    <Flex sx={{ alignItems: "flex-start" }}>
      <Flex
        sx={{
          flex: 1,
          flexDirection: "column",
          borderBottom: "2px solid",
          borderBottomColor: "accent",
        }}
      >
        <ProjectDetails project={projectWithMeta} lastOpened={lastAccessDate} />
        {participantsError ? (
          <NoResults error={participantsError} />
        ) : participantsLoaded ? (
          <ProjectCollaborators
            myself={myself || null}
            users={users}
            project={projectWithMeta}
            onPermissionChange={onPermissionChange}
            onOwnerChange={onOwnerChange}
          />
        ) : (
          <LoadingSpinner />
        )}
      </Flex>
    </Flex>
  );
}

export default ProjectResultByDateExtended;
