import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Heading } from "theme-ui";
import { getAppName } from "../../tools/customer";

function Tagline(): ReactElement {
  const { t } = useTranslation();

  return (
    <Heading
      as="h2"
      sx={{
        fontSize: [3, 4, 5],
        fontWeight: 600,
        marginTop: "-3px",
      }}
    >
      {t("app.name", { appName: getAppName() })}
    </Heading>
  );
}

export default Tagline;
