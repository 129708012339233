import { v4 as uuid } from "uuid";
import "./freeFormText";
import "./freeFormText.legacy";

export const createTextBox = (options: FreeFormConfig): fabric.FreeFormText => {
  options = options || {};

  options.uuid = options.uuid || uuid();
  options.fill = options.fill || "#000000";
  options.text = options.text || "text";

  return new fabric.FreeFormText(options.text, options);
};
