import React from "react";
import { useTranslation } from "react-i18next";
import { Flex, Text, Box } from "theme-ui";

function ComparisonTableLabels({ labels }) {
  const { t } = useTranslation();

  return (
    <>
      <Text
        sx={{
          bg: "delicate",
          fontWeight: 700,
          px: [0],
          fontSize: "1.2rem",
          left: 0,
          position: "sticky",
        }}
      >
        {t("plans.features.features")}
      </Text>

      {labels.map(({ labelKey }) => (
        <Flex
          key={labelKey}
          sx={{
            justifyContent: "flex-start",
            textAlign: "left",
            alignItems: "left",
            bg: "background",
            pl: [0],
            left: 0,
            position: "sticky",
          }}
        >
          {t(`plans.features.${labelKey}`)}
        </Flex>
      ))}

      {/* empty cell in footer*/}
      <Box />
    </>
  );
}

export default ComparisonTableLabels;
