import { Global } from "@emotion/core";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import React, { lazy, ReactElement, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { QueryParamProvider } from "use-query-params";
import { routes } from "./const";
import AppErrorBoundary from "./features/app/ErrorBoundaries/AppErrorBoundary";
import NewVersionModal from "./features/app/NewVersionModal";
import { useNewVersionCheck } from "./features/app/useNewVersionCheck";
import { usePostLogin } from "./features/app/usePostLogin";
import { useLoggedInStatus } from "./features/authentication/useLoggedInStatus";
import AcceptProjectInvitation from "./features/routes/acceptProjectInvitation/AcceptProjectInvitation";
import Authenticate from "./features/routes/authentication/Authenticate";
import CompleteRegistration from "./features/routes/authentication/CompleteRegistration";
import MSTeamsAuthEnd from "./features/routes/authentication/MSTeamsAuthEnd";
import MSTeamsAuth from "./features/routes/authentication/MSTeamsAuth";
import Register from "./features/routes/authentication/Register";
import ResetPassword from "./features/routes/authentication/ResetPassword";
import VerifyEmailRoute from "./features/routes/authentication/VerifyEmailRoute";
import WebexAuth from "./features/routes/authentication/WebexAuth";
import AppSumoLanding from "./features/routes/codeRedemption/AppSumoLanding";
import WacomLanding from "./features/routes/codeRedemption/WacomLanding";
import ErrorPage from "./features/routes/ErrorPage";
import ImportPreview from "./features/routes/importPreview";
import Plans from "./features/routes/plans";
import Projects from "./features/routes/projects";
import { AuthRoute, PrivateRoute } from "./features/routes/RestrictedRoutes";
import Team from "./features/routes/team/Team";
import AcceptTeamInvitation from "./features/routes/teamInvitation/AcceptTeamInvitation";
import RejectTeamInvitation from "./features/routes/teamInvitation/RejectTeamInvitation";
import User from "./features/routes/user/User";
import Spaces from "./features/spaces/Spaces";
import { initAuthAction } from "./reduxStore/auth/auth.actions";
import { useUISettings } from "./reduxStore/uiSettings";
import "./styles/animation.css";
import "./styles/fonts-slider.css";
import globalStyles from "./styles/globalStyles";
import "./styles/index.css";
import "./styles/rc-tooltip-overrides.css";
import "./styles/reset.css";
import "./styles/toastify.css";
import { dynamicFeatureFlags } from "./tools/flags";

const ViewBoard = lazy(() => import("./features/routes/ViewBoard"));

const projectRoute = `${routes.collaboard}/:ProjectId`;
const projectRouteWithKey = `${routes.collaboard}/:ProjectId/:ProjectKey`;

function App(): ReactElement {
  const dispatch = useDispatch();
  const { isNewVersionAvailable } = useNewVersionCheck();
  const { isDynamicFeatureActive } = useUISettings();
  const tenantRef = useRef<string | undefined>(undefined);

  /**
   * The license is not checked here because getting the license info is async, so the user is always
   * redirected back to /projects instead of already landing in /spaces as the Router thinks there isn't
   * a matching route.
   * Since the server enforces the license with Spaces API, even if the user opens /spaces without a license,
   * there is no harm.
   *
   * @TODO #7321: Move permission related-stuff to a step in that authentication flow so that the app is not
   * rendered until stuff like subscription, license and user permissions are loaded. Then the rest of
   * the app can just read the values from the Redux state.
   */
  const isSpacesEnabled = isDynamicFeatureActive(dynamicFeatureFlags.SPACES);

  const { isPostLoginActionDone } = usePostLogin();
  const { loggedInViaTenant } = useLoggedInStatus();

  useEffect(() => {
    dispatch(initAuthAction());
  }, [dispatch]);

  useEffect(() => {
    tenantRef.current = loggedInViaTenant;
  }, [loggedInViaTenant]);

  const tenant = tenantRef.current;

  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <AppErrorBoundary>
          <Global styles={globalStyles} />
          <NewVersionModal isOpen={isNewVersionAvailable} />
          <ToastContainer
            position={toast.POSITION.TOP_CENTER}
            hideProgressBar
            closeButton={false}
            autoClose={15000}
          />
          <div id="modal-root" />
          <div id="dropdown-root" />
          <div id="draggable-toolbar-root" />
          <Switch>
            <AuthRoute
              path={`${routes.authenticate}/:tenant?`}
              component={Authenticate}
            />
            <AuthRoute path={routes.MSTeamsAuth} component={MSTeamsAuth} />
            <AuthRoute
              path={routes.MSTeamsAuthEnd}
              component={MSTeamsAuthEnd}
            />
            <AuthRoute
              path={`${routes.register}/:tenant?`}
              component={Register}
            />
            <AuthRoute
              path={routes.registerComplete}
              component={CompleteRegistration}
            />
            <AuthRoute path={routes.verifyEmail} component={VerifyEmailRoute} />
            <AuthRoute path={routes.passwordReset} component={ResetPassword} />
            <Redirect from={projectRouteWithKey} to={projectRoute} />
            <PrivateRoute
              exact
              path={projectRoute}
              component={ViewBoard}
              guestAccessible
              tenant={tenant}
              isPostLoginActionDone={isPostLoginActionDone}
            />
            <PrivateRoute
              path={routes.user}
              component={User}
              tenant={tenant}
              isPostLoginActionDone={isPostLoginActionDone}
            />
            <PrivateRoute
              path={routes.webexAuth}
              component={WebexAuth}
              tenant={tenant}
              isPostLoginActionDone={isPostLoginActionDone}
            />
            <PrivateRoute
              path={routes.projects}
              component={Projects}
              tenant={tenant}
              isPostLoginActionDone={isPostLoginActionDone}
            />
            {isSpacesEnabled && (
              <PrivateRoute
                path={routes.spaces}
                component={Spaces}
                tenant={tenant}
                isPostLoginActionDone={isPostLoginActionDone}
              />
            )}
            <PrivateRoute
              path={routes.plans}
              component={Plans}
              tenant={tenant}
              isPostLoginActionDone={isPostLoginActionDone}
            />
            <PrivateRoute
              path={routes.team}
              component={Team}
              tenant={tenant}
              isPostLoginActionDone={isPostLoginActionDone}
            />
            <PrivateRoute
              path={routes.importPreview}
              component={ImportPreview}
              tenant={tenant}
              isPostLoginActionDone={isPostLoginActionDone}
            />
            <Route path={`${routes.acceptTeamInvitation}`}>
              {<AcceptTeamInvitation />}
            </Route>
            <Route path={`${routes.rejectTeamInvitation}`}>
              {<RejectTeamInvitation />}
            </Route>
            <Route path={`${routes.AppSumoLanding}/:code?`}>
              <AppSumoLanding />
            </Route>
            <Route path={`${routes.WacomLanding}/:code?`}>
              <WacomLanding />
            </Route>
            <Route path={routes.acceptProjectInvitation}>
              <AcceptProjectInvitation />
            </Route>
            <Route path={routes.error}>
              <ErrorPage />
            </Route>
            <Route>
              <Redirect to={routes.projects} />
            </Route>
          </Switch>
        </AppErrorBoundary>
      </QueryParamProvider>
    </Router>
  );
}

export default App;
