import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getUserByEmail, searchUsers } from "../../../../api";
import { selectAssertedUserProfile } from "../../../../reduxStore/auth/auth.reducer";
import { useUISettings } from "../../../../reduxStore/uiSettings";
import debounce from "../../../../tools/debounce";
import { dynamicFeatureFlags } from "../../../../tools/flags";
import { isDefined } from "../../../../tools/utils";
import { MultiInputFieldItem } from "../../../shared/forms/fields/MultiInputField/useMultiInputField";
import { combineUserInfo, patchUserIdentity } from "../../../users/users.utils";

type UseUserSearch = {
  users: ApiSearchUserInfo[];
  searchUsers(input: string): Promise<void>;
  getUserDetails(
    entries: string[]
  ): Promise<MultiInputFieldItem<ApiSearchUserInfo>[]>;
  cancelSearchUsers(): void;
};

export default (): UseUserSearch => {
  const [usersSearchResults, setUsersSearchResults] = useState<
    ApiSearchUserInfo[]
  >([]);
  const { isDynamicFeatureActive } = useUISettings();

  const areGuestsAllowed = !isDynamicFeatureActive(
    dynamicFeatureFlags.GUEST_USERS_DISABLED
  );

  const userProfile = useSelector(selectAssertedUserProfile);

  const getUserDetails = useCallback(
    async (entries: string[]) => {
      const responses = await Promise.all(entries.map(getUserByEmail));
      const users = responses
        .map((r) => r.Results?.[0])
        .filter(isDefined)
        .map((user, index) =>
          combineUserInfo({
            user: patchUserIdentity(user),
            userProfile,
            index,
          })
        );
      return entries.map<MultiInputFieldItem<ApiSearchUserInfo>>((email) => {
        const user = users.find((u) => u?.Email === email);
        return {
          value: email,
          metadata: user,
          isValid: !!user || areGuestsAllowed,
        };
      });
    },
    [areGuestsAllowed, userProfile]
  );

  const clearSearchResults = useCallback(() => {
    setUsersSearchResults([]);
  }, []);

  const debouncedSearchUsers = useMemo(
    () =>
      debounce(async (input: string) => {
        const users = await searchUsers(input);
        clearSearchResults(); // hides dropdown for a while, fixing glitched position
        setUsersSearchResults(
          users.Results?.map((user, index) =>
            combineUserInfo({
              user: patchUserIdentity(user),
              userProfile,
              index,
            })
          ).filter((user) => !!user.Email) ?? []
        );
      }, 250),
    [clearSearchResults, userProfile]
  );

  const cancelSearchUsers = useCallback(() => {
    debouncedSearchUsers.cancel();
    clearSearchResults();
  }, [debouncedSearchUsers, clearSearchResults]);

  return {
    users: usersSearchResults,
    searchUsers: debouncedSearchUsers,
    cancelSearchUsers,
    getUserDetails,
  };
};
