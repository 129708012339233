import { runtimeConfig } from "./runtimeConfig";
import configurationDefault from "../themes/default/configuration";
import configurationTWR from "../themes/twr/configuration";
import configurationAxis from "../themes/axis/configuration";
import configurationBGW from "../themes/bgw/configuration";
import configurationCollins from "../themes/collins/configuration";
import configurationBKKGS from "../themes/bkkgs/configuration";
import configurationETH from "../themes/eth/configuration";
import configurationVP from "../themes/vp/configuration";
import configurationProwise from "../themes/prowise/configuration";

export const customers = {
  default: "default",
  twr: "twr",
  axis: "axis",
  bgw: "bgw",
  collins: "collins",
  bkkgs: "bkkgs",
  eth: "eth",
  prowise: "prowise",
  vp: "vp",
} as const;

export type Customer = ValuesOf<typeof customers>;

const customerConfiguration = {
  default: configurationDefault,
  twr: configurationTWR,
  axis: configurationAxis,
  bgw: configurationBGW,
  collins: configurationCollins,
  bkkgs: configurationBKKGS,
  eth: configurationETH,
  prowise: configurationProwise,
  vp: configurationVP,
} as const;

export const getCustomer = (): Customer =>
  customers[runtimeConfig.customer] ?? customers.default;

export const getCustomerConfiguration = (): CustomerConfiguration =>
  customerConfiguration[getCustomer()];

export const isDefaultCustomer = (): boolean =>
  customers[runtimeConfig.customer] === customers.default;

export const getAppName = (): string => {
  const { appName } = getCustomerConfiguration();
  return appName;
};
