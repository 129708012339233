import { Form, Formik } from "formik";
import { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Box, Flex, Input } from "theme-ui";
import {
  removeSubscriptionDevice,
  removeSubscriptionUser,
} from "../../../../api";
import {
  useSubscriptionDevices,
  useSubscriptionUsers,
} from "../../../../api/useRequest";
import { SubscriptionUserStatuses } from "../../../../const";
import Display from "../../../../icons/Display";
import RemoveUser from "../../../../icons/RemoveUser";
import { useSubscription } from "../../../../reduxStore/subscriptionDetails/subscriptionDetails.hook";
import { selectSubscriptionDetails } from "../../../../reduxStore/subscriptionDetails/subscriptionDetails.reducer";
import { useUISettings } from "../../../../reduxStore/uiSettings";
import { dynamicFeatureFlags } from "../../../../tools/flags";
import { wait } from "../../../../tools/promises";
import { Confirmation, useConfirmModal } from "../../../shared/modals";
import ModalHeader from "../../../shared/modals/ModalHeader";
import ContainedSpinner from "../../../shared/spinners/ContainedSpinner";
import Devices from "./Devices/Devices";
import Filters from "./Filters";
import PendingRequests from "./PendingRequests/PendingRequests";
import Users from "./Users/Users";
import { TeamFilter, useTeam } from "./useTeam";

function Team(): ReactElement {
  const { t } = useTranslation();
  const { isDynamicFeatureActive } = useUISettings();
  const { getSubscription } = useSubscription();
  const [loading, setLoading] = useState(false);

  const deviceLicensesEnabled = isDynamicFeatureActive(
    dynamicFeatureFlags.DEVICE_LICENSES
  );

  const { subscription } = useSelector(selectSubscriptionDetails);
  const subscriptionID = subscription?.ID || -1;
  const removeUserModal = useConfirmModal<ApiSubscriptionUser>();
  const removeDeviceModal = useConfirmModal<ApiSubscriptionDevice>();
  const { currentFilter, filters, onFilterChange, query, setQuery } = useTeam();

  const allUsersInfiniteQuery = useSubscriptionUsers(
    SubscriptionUserStatuses.active,
    subscriptionID,
    query
  );

  const pendingUsersInfiniteQuery = useSubscriptionUsers(
    SubscriptionUserStatuses.pending,
    subscriptionID,
    query
  );

  const devicesInfiniteQuery = useSubscriptionDevices(true, subscriptionID);

  /**
   * @NOTE - Data sent to ImportSubscriptionUsers gets added to a background
   * task for processing; as a result the new data will not be available
   * immediately. Therefore we have a small delay which will typically allow
   * enough time for the data to be available, ensuring that the user counts
   * are updated when a user is added.
   */
  const onAddUser = async () => {
    setLoading(true);
    await wait(3000);
    getSubscription();
    pendingUsersInfiniteQuery.refetch();
    setLoading(false);
  };

  const onRemoveUser = (user: ApiSubscriptionUser, isActive: boolean) => {
    removeUserModal.open({
      data: user,
      confirmCallback: () => {
        /**
         * There is no delay required when removing a user.
         */
        removeSubscriptionUser({
          subscriptionID,
          Username: user.Username,
        }).then(() => {
          isActive
            ? allUsersInfiniteQuery.refetch()
            : pendingUsersInfiniteQuery.refetch();
          getSubscription();
          removeUserModal.close();
        });
      },
    });
  };

  const onAddDevice = () => {
    getSubscription();
  };

  const onRemoveDevice = (device: ApiSubscriptionDevice) => {
    removeDeviceModal.open({
      data: device,
      confirmCallback: () => {
        removeSubscriptionDevice({
          subscriptionID,
          DeviceCode: device.DeviceCode,
        }).then(() => {
          devicesInfiniteQuery.refetch();
          getSubscription();
          removeDeviceModal.close();
        });
      },
    });
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Flex
        sx={{
          mt: [5],
          flexWrap: "wrap",
          justifyContent: "flexStart",
          "> *": {
            width: ["100%", "100%", "50%"],
          },
        }}
      >
        <Filters
          filters={filters}
          onFilterChange={onFilterChange}
          currentFilter={currentFilter}
        />

        <Box sx={{ mt: ["15px", "15px", 0] }}>
          <Formik initialValues={{}} onSubmit={allUsersInfiniteQuery.refetch}>
            <Form sx={{ margin: "0" }}>
              <Input
                onChange={setQuery}
                placeholder={t("form.placeholder.search")}
              />
            </Form>
          </Formik>
        </Box>
      </Flex>

      {[TeamFilter.all, TeamFilter.pending, TeamFilter.users].includes(
        currentFilter
      ) && (
        <Confirmation
          {...removeUserModal.props}
          confirmationLabel={t("dialog.remove")}
          variant="warning"
          header={
            <ModalHeader title={t("dialog.removeUser")} Icon={RemoveUser} />
          }
        >
          {t("team.removeUserDescription", {
            name: removeUserModal.props.data?.Username,
          })}
        </Confirmation>
      )}

      {[TeamFilter.all, TeamFilter.users].includes(currentFilter) && (
        <Users
          onAddUser={onAddUser}
          onRemoveUser={onRemoveUser}
          query={allUsersInfiniteQuery}
        />
      )}

      {[TeamFilter.all, TeamFilter.devices].includes(currentFilter) &&
        deviceLicensesEnabled && (
          <>
            <Confirmation
              {...removeDeviceModal.props}
              confirmationLabel={t("dialog.remove")}
              variant="warning"
              header={
                <ModalHeader title={t("dialog.removeDevice")} Icon={Display} />
              }
            >
              {t("team.removeDeviceDescription", {
                name: removeDeviceModal.props.data?.ID,
              })}
            </Confirmation>
            <Devices
              onAddDevice={onAddDevice}
              onRemoveDevice={onRemoveDevice}
              query={devicesInfiniteQuery}
            />
          </>
        )}

      {[TeamFilter.all, TeamFilter.pending].includes(currentFilter) && (
        <PendingRequests
          onRemoveUser={onRemoveUser}
          query={pendingUsersInfiniteQuery}
        />
      )}

      {loading && <ContainedSpinner />}
    </Box>
  );
}

export default Team;
