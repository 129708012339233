import { fabric } from "fabric";
import { equals } from "ramda";
import { v4 as uuid } from "uuid";

import {
  BrushType,
  canvasObjectIds,
  minObjectHeight,
  minObjectWidth,
} from "../../../../const";

import { zoomCacheMixin } from "../../object/collaboardObjectCustom.zoom_cache_mixin";
import { computeVisibleSegments } from "../utils/viewportIntersection";

fabric.CollaboardInkPath = fabric.util.createClass(fabric.Rect, {
  /**
   * Fabric options
   */
  fill: null, // Do not set the fill value on InkPaths
  strokeWidth: 0, // do not set this prop in this class (fabric adds some logic around it causing glitches)
  hasRotatingPoint: false,
  lockUniScaling: true,
  originX: "left",
  originY: "top",
  padding: 10,

  type: canvasObjectIds.inkPath,
  perPixelTargetFind: true,

  initialize(
    this: fabric.CollaboardInkPath,
    options: fabric.CollaboardInkPathOptions
  ) {
    const { brush, points, color, uuid: objectId, ...fabricOptions } = options;

    this._points = points;
    this._brush = brush;
    this._visibleSegments = [];

    const opts = {
      ...fabricOptions,
      objectCaching: brush.ENABLE_OBJECT_CACHING,
      uuid: objectId ?? uuid(),
      color,
    };

    // Must happen before initialize otherwise the positions get weird
    this._calculatePositionAndSize();
    this._points = this._offsetPointsByCenter();

    // Start with the full list of points.
    // Will be overwritten if objectCaching is disabled
    this._visibleSegments = [this._points];

    this.callSuper("initialize", opts);

    this.initializeCollaboardObject();
    this.updateMinSize(minObjectWidth, minObjectHeight);
  },

  /**
   * Determine if the object requires its own cache.
   *
   * @override
   */
  needsItsOwnCache(this: fabric.CollaboardInkPath): boolean {
    return false;
  },

  /**
   * Override the cache rendering to ensure that the ink path is never drawn
   * at too low a zoom level. This prevents very blocky ink paths appearing
   * when you start at a low zoom level (1%) and zoom in.
   *
   * @override
   */
  renderCache(
    this: fabric.CollaboardInkPath,
    options?: { forClipping: boolean }
  ) {
    if (!this.canvas) {
      this.callSuper("renderCache", options);
      return;
    }

    const [zoomX, , , zoomY] = this.canvas.viewportTransform;
    this.canvas.viewportTransform[0] = Math.max(zoomX, 1);
    this.canvas.viewportTransform[3] = Math.max(zoomY, 1);

    this.callSuper("renderCache", options);

    this.canvas.viewportTransform[0] = zoomX;
    this.canvas.viewportTransform[3] = zoomY;
  },

  /**
   * Check if any part of the ink path is within the canvas bounds.
   *
   * @override
   * @param {boolean} calculate Use coordinates of current position instead of .aCoords
   * @returns {boolean}
   */
  isOnScreen(this: fabric.CollaboardInkPath, calculate?: boolean): boolean {
    const isOnScreen = this.callSuper("isOnScreen", calculate) as boolean;

    if (!this.objectCaching) {
      if (isOnScreen) {
        this.recalculateVisibleSegments();
      } else {
        this._setVisibleSegments([]);
      }
    }

    return isOnScreen;
  },

  /**
   * Recalculate the visible segments of the ink path.
   */
  recalculateVisibleSegments(this: fabric.CollaboardInkPath): void {
    const { vptCoords } = this.canvas || {};
    if (this._shouldRecalculateVisibleSegments() && vptCoords) {
      const transform = this.calcTransformMatrix();
      const visibleSegments = computeVisibleSegments(
        this._points,
        transform,
        vptCoords,
        this.getBrushStrokeWidth(),
        this._brush.BRUSH_USES_CURVES
      );
      this._setVisibleSegments(visibleSegments);
    }
  },

  /**
   * Get the brush type from the brush instance.
   *
   * @returns {BrushType} Brush type (ID used by API)
   */
  getBrushType(this: fabric.CollaboardInkPath): BrushType {
    return this._brush.brushType;
  },

  /**
   * Get the brush stroke size from the brush instance.
   *
   * @returns {number} Brush stroke size
   */
  getBrushStrokeWidth(this: fabric.CollaboardInkPath): number {
    return this._brush.strokeWidth ?? this.strokeWidth;
  },

  /**
   * Get the brush color from the brush instance.
   *
   * @returns {string} Brush color
   */
  getBrushColor(this: fabric.CollaboardInkPath): string {
    return this._brush.color ?? this.color;
  },

  /**
   * Reveal the context properties. Called by React.
   */
  getContextProps(this: fabric.CollaboardInkPath): ContextProps {
    return {
      fillColor: this.getBrushColor(),
      strokeWidth: this.getBrushStrokeWidth(),
    };
  },

  /**
   * Modify the context properties. Called by React.
   */
  setContextProps(this: fabric.CollaboardInkPath, props: ContextProps) {
    const { fillColor, strokeWidth: newStrokeWidth } = props;
    // Update the underlying brush
    if (fillColor) {
      this._brush.setColor(fillColor);
      this.dirty = true;
    }

    newStrokeWidth && this._brush.setStrokeWidth(newStrokeWidth);

    const strokeWidth = this.getBrushStrokeWidth();

    // Ensure the cached brushes are redrawn
    this.dirty = true;

    // Nudge the object dimensions instead of recalculating
    this.width = this.rawWidth + strokeWidth;
    this.height = this.rawHeight + strokeWidth;
    newStrokeWidth && this.setCoords(); // #6577 recalculate bb

    const event: ClientModifiedEvent = {
      transform: { action: "ctxPropsChange" },
    };
    this.trigger("modified", event);
  },

  supportsDarkMode() {
    return true;
  },

  hasEditableFillColor() {
    return true;
  },

  /**
   * Calculate and set the dimensions of the ink path using the points.
   *
   * @private
   */
  _calculatePositionAndSize(this: fabric.CollaboardInkPath) {
    const aX: number[] = [];
    const aY: number[] = [];
    this._points.forEach((p: fabric.Point) => {
      aX.push(p.x);
      aY.push(p.y);
    });

    const onlyWidthHeight = false;

    // Call internal private Fabric method to calculate the object bounds
    // and set the position
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (fabric.Group.prototype as any)._getBounds.call(
      this,
      aX,
      aY,
      onlyWidthHeight
    );

    this.rawWidth = this.width;
    this.rawHeight = this.height;
    const strokeWidth = this.getBrushStrokeWidth();

    this.top -= strokeWidth / 2;
    this.left -= strokeWidth / 2;
    this.width += strokeWidth;
    this.height += strokeWidth;
  },

  /**
   * Offset the points by the ink path's center point.
   *
   * @private
   */
  _offsetPointsByCenter(this: fabric.CollaboardInkPath) {
    const center = this.getCenterPoint();

    return this._points.map((point) => {
      const newPoint = point.subtract(center) as fabric.CollaboardPoint;
      newPoint.pressure = point.pressure;
      return newPoint;
    });
  },

  /**
   * Set the visible segments.
   *
   * @private
   * @param visibleSegments Visible segments to store
   */
  _setVisibleSegments(
    this: fabric.CollaboardInkPath,
    visibleSegments: fabric.CollaboardPoint[][]
  ): void {
    this._visibleSegments = visibleSegments;
    this._storeViewportPosition();
  },

  /**
   * Determine if the viewport intersection indexes should be recalculated.
   *
   * @private
   * @returns {boolean}
   */
  _shouldRecalculateVisibleSegments(this: fabric.CollaboardInkPath): boolean {
    if (this.objectCaching || !this.canvas) {
      return false;
    }

    const vptCoords = this.canvas.vptCoords;
    const canvasZoom = this.canvas.getZoom();
    const transform = this.calcTransformMatrix();

    const zoomHasChanged = !equals(canvasZoom, this.__prevCanvasZoom);
    const vptHasChanged = !equals(vptCoords, this.__prevVptCoords);
    const transformHasChanged = !equals(transform, this.__prevTransform);

    return zoomHasChanged || vptHasChanged || transformHasChanged;
  },

  /**
   * Store the properties that we use to invalidate the visible segments
   * cache.
   *
   * @private
   */
  _storeViewportPosition(this: fabric.CollaboardInkPath): void {
    if (!this.canvas) {
      return;
    }

    this.__prevCanvasZoom = this.canvas.getZoom();
    this.__prevVptCoords = this.canvas.vptCoords;
    this.__prevTransform = this.calcTransformMatrix();
  },

  /**
   * Render the ink path using the brush.
   *
   * @private
   * @override
   * @param {CanvasRenderingContext2D} ctx Canvas context
   */
  _render(this: fabric.CollaboardInkPath, ctx: CanvasRenderingContext2D) {
    if (this._brush) {
      this._visibleSegments.forEach((segment: fabric.CollaboardPoint[]) => {
        this._brush.fullRender(ctx, segment);
      });
    }

    this._renderPaintInOrder(ctx);
  },
});

fabric.util.object.extend(
  fabric.CollaboardInkPath.prototype,
  zoomCacheMixin(fabric.CollaboardInkPath.prototype)
);
