/* eslint-disable no-console */
import { runtimeConfig } from "../tools/runtimeConfig";

export enum WebRTCLogLevel {
  none = 0,
  error = 1,
  info = 2,
}

const config = runtimeConfig.webRTC ?? {};

export const logWebRTCErrorToConsole = (
  header: string,
  message?: unknown
): void => {
  if (config.logging >= WebRTCLogLevel.error) {
    console.groupCollapsed(`%c${header}`, "color: red");
    if (message) {
      console.log(message);
    }
    console.groupEnd();
  }
};

export const logWebRTCInfoToConsole = (
  header: string,
  message?: unknown
): void => {
  if (config.logging >= WebRTCLogLevel.info) {
    console.groupCollapsed(header);
    if (message) {
      console.log(message);
    }
    console.groupEnd();
  }
};
