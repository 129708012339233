import { all, Effect, getContext, takeEvery } from "redux-saga/effects";
import {
  GoToQuickLinkAction,
  ObjectLinksActionType,
  SetObjectLinksAction,
} from "./links.actions";

function* goToQuickLink({ payload }: GoToQuickLinkAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  canvas.goToQuickLink(payload);
}

function* setObjectLinks({ payload }: SetObjectLinksAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  payload.forEach((link) => {
    const object = canvas.getObjectByUUID(link.SourceTileId);
    if (object) {
      object.objectLink = link;
    }
  });
}

export function* linksSaga(): Generator<Effect> {
  yield all([
    takeEvery(ObjectLinksActionType.GO_TO_QUICK_LINK, goToQuickLink),
    takeEvery(ObjectLinksActionType.SET_OBJECT_LINKS, setObjectLinks),
  ]);
}
