import React, { ReactElement } from "react";
import { Box, Image, Text, ThemeUIStyleObject } from "theme-ui";
import { useTranslation } from "react-i18next";
import { runtimeConfig } from "../../../tools/runtimeConfig";
import { HostingCountry } from "../../../types/enum";

const { hosting } = runtimeConfig;

export default function HostingMap(): ReactElement | null {
  const { t } = useTranslation();
  const isValidHosting =
    hosting && Object.values(HostingCountry).includes(hosting);

  const hostingLabel = isValidHosting
    ? t("hosting.hostedIn", { country: t(`hosting.${hosting}`) })
    : "";

  return isValidHosting ? (
    <Box sx={boxStyle}>
      <Text sx={labelStyle}>
        <Image
          src={`${process.env.PUBLIC_URL}/images/hostmaps/${hosting}-flag.jpg`}
          alt={hostingLabel}
          sx={{ mr: [3] }}
        />
        {hostingLabel}
      </Text>
      <Image
        src={`${process.env.PUBLIC_URL}/images/hostmaps/${hosting}-map.jpg`}
        alt={hostingLabel}
      />
    </Box>
  ) : null;
}

const boxStyle: ThemeUIStyleObject = {
  position: "relative",
  // Prevent label appearing outside container on slow-to-load devices
  overflow: "hidden",
};

const labelStyle: ThemeUIStyleObject = {
  fontSize: [3],
  position: "absolute",
  left: [1],
  bottom: "50%",
  mb: ".25rem",
  transform: "translateY(-100%)",
};
