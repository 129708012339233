import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function ApplyEdit(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M21.47,16.43a.68.68,0,0,0-.28-.18.78.78,0,0,0-.22,0,.77.77,0,0,0-.5.2.67.67,0,0,0-.21.5.75.75,0,0,0,0,.26.79.79,0,0,0,.16.23l2.15,2.12H15.09a.69.69,0,1,0,0,1.38h7.53L20.47,23a.69.69,0,0,0,0,1,.7.7,0,0,0,.49.2.72.72,0,0,0,.49-.19l3.34-3.3a.67.67,0,0,0,0-1Z" />
      <path d="M19.7,10.55a9.78,9.78,0,1,0,9.78,9.78A9.79,9.79,0,0,0,19.7,10.55Zm0,18a8.2,8.2,0,1,1,8.19-8.19A8.21,8.21,0,0,1,19.7,28.52Z" />
    </Icon>
  );
}

export default ApplyEdit;
