import { Selector } from "react-redux";
import { Reducer as ReduxReducer } from "redux";
import { createSelector } from "reselect";
import { CanvasAction, CanvasActionType } from "../canvas.actions";
import {
  UserPresenceAction,
  UserPresenceActionType,
} from "./userPresence.actions";

type UserPresenceInfo = {
  connectionId: string;
  enabled: boolean;
  UserName: string;
};

export enum AutoEnableMode {
  ALL = "ALL",
  USERNAMES = "USERNAMES",
}

export type UserPresenceAutoEnable =
  | { mode: AutoEnableMode.ALL }
  | { mode: AutoEnableMode.USERNAMES; userNames: string[] };

export const isAutoEnabledForAll = (
  autoEnable: UserPresenceAutoEnable
): autoEnable is { mode: AutoEnableMode.ALL } => {
  return autoEnable.mode === AutoEnableMode.ALL;
};

export type UserPresenceState = {
  connectingIds: string[];
  active: boolean;
  autoEnable: UserPresenceAutoEnable;
  presenceRequestedBy: string[];
  users: {
    [connectionId: string]: UserPresenceInfo;
  };
};

const getInitialUserPresenceState = (): UserPresenceState => {
  return {
    connectingIds: [],
    active: false,
    autoEnable: { mode: AutoEnableMode.USERNAMES, userNames: [] },
    presenceRequestedBy: [],
    users: {},
  };
};

export const userPresenceReducer: ReduxReducer<
  UserPresenceState,
  UserPresenceAction | CanvasAction
> = (state = getInitialUserPresenceState(), action): UserPresenceState => {
  /** @TODO #7104: Need to write tests for this reducer */

  switch (action.type) {
    case UserPresenceActionType.SET_WEBRTC_CONNECTING: {
      const { payload } = action;

      return {
        ...state,
        connectingIds: payload.connectingIds,
      };
    }
    case UserPresenceActionType.SET_USER_PRESENCE_OPTIONS: {
      const { payload } = action;
      // Check state rather than payload in case it contains stale values
      const users = Object.fromEntries(
        Object.entries(state.users).map(([connectionId, user]) => {
          const updatedUser: UserPresenceInfo = {
            ...user,
            enabled:
              isAutoEnabledForAll(payload) ||
              payload.userNames.includes(user.UserName),
          };

          return [connectionId, updatedUser];
        })
      );
      const hasEnabledUsers = Object.values(users).some((user) => user.enabled);
      return {
        ...state,
        active: isAutoEnabledForAll(payload) || hasEnabledUsers,
        autoEnable: payload,
        users,
      };
    }
    case UserPresenceActionType.PAUSE_USER_PRESENCE: {
      return {
        ...state,
        active: false,
      };
    }
    case UserPresenceActionType.RESUME_USER_PRESENCE: {
      const hasEnabledUsers = Object.values(state.users).some(
        (user) => user.enabled
      );
      return {
        ...state,
        active: isAutoEnabledForAll(state.autoEnable) || hasEnabledUsers,
      };
    }
    case UserPresenceActionType.USER_PRESENCE_REQUESTED: {
      const { payload } = action;
      const { connectionId } = payload;

      if (state.presenceRequestedBy.includes(connectionId)) {
        return state;
      }

      return {
        ...state,
        presenceRequestedBy: [...state.presenceRequestedBy, connectionId],
      };
    }
    case UserPresenceActionType.USER_PRESENCE_REVOKED: {
      const { payload } = action;
      const { connectionId } = payload;

      if (!state.presenceRequestedBy.includes(connectionId)) {
        return state;
      }

      return {
        ...state,
        presenceRequestedBy: state.presenceRequestedBy.filter(
          (id) => id !== connectionId
        ),
      };
    }
    case UserPresenceActionType.ADD_USER_PRESENCE_USERNAME: {
      const { payload } = action;
      const { connectionId, UserName } = payload;

      const users = {
        ...state.users,
        [connectionId]: {
          connectionId,
          enabled:
            isAutoEnabledForAll(state.autoEnable) ||
            state.autoEnable.userNames.includes(UserName),
          UserName,
        },
      };
      const hasEnabledUsers = Object.values(users).some((user) => user.enabled);

      return {
        ...state,
        active: isAutoEnabledForAll(state.autoEnable) || hasEnabledUsers,
        users,
      };
    }
    case UserPresenceActionType.REMOVE_USER_PRESENCE_USERNAME: {
      const { payload } = action;
      const { connectionId } = payload;

      // Avoid returning a new reference if the connection was already removed,
      // which can happen with Disconnect + Close
      const active = Object.values(state.users).find(
        (user) => user.connectionId === connectionId
      );

      if (!active) {
        return state;
      }

      const { [connectionId]: removedUser, ...users } = state.users;
      const hasEnabledUsers = Object.values(users).some((user) => user.enabled);

      return {
        ...state,
        active: hasEnabledUsers,
        presenceRequestedBy: state.presenceRequestedBy.filter(
          (id) => id !== connectionId
        ),
        users,
      };
    }
    case CanvasActionType.ON_CANVAS_EXIT: {
      return getInitialUserPresenceState();
    }
    default:
      return state;
  }
};

/**
 * @NOTE Use this selector only in sagas or when you need lots of userPresence state properties, since
 * it can easily cause a re-render. Prefer the more granular selectors.
 */
export const selectUserPresenceState = (
  state: ApplicationGlobalState
): UserPresenceState => {
  return state.canvas.userPresence;
};

export const selectConnectingIds = (
  state: ApplicationGlobalState
): string[] => {
  return state.canvas.userPresence.connectingIds;
};

export const selectIsUserPresenceActive = (
  state: ApplicationGlobalState
): boolean => {
  return state.canvas.userPresence.active;
};

export const selectPresencesInfo: Selector<
  ApplicationGlobalState,
  UserPresenceInfo[]
> = createSelector(
  (state: ApplicationGlobalState) => state.canvas.userPresence.users,
  (presencesInfo) => Object.values(presencesInfo)
);

export const selectUserPresenceAutoEnable = (
  state: ApplicationGlobalState
): UserPresenceAutoEnable => {
  return state.canvas.userPresence.autoEnable;
};

export const selectPresenceRequestedBy = (
  state: ApplicationGlobalState
): string[] => {
  return state.canvas.userPresence.presenceRequestedBy;
};
