import {
  all,
  call,
  delay,
  Effect,
  getContext,
  select,
  takeEvery,
} from "redux-saga/effects";
import { copyTiles } from "../../../api";
import { SignalRClient } from "../../../api/signalR";
import { canvasObjectIds } from "../../../const";
import { toSerializedCopyObject } from "../../../studio/components/patches/extends-duplicate-objects/duplicate.utils";
import { getTileIds } from "../../../studio/utils/fabricObjects";
import {
  toDuplicateTile,
  toGroupedTile,
} from "../../../studio/utils/objectConverter";
import { assertUnreachable } from "../../../tools/assertions";
import { RelationChangeAction } from "../../../types/enum";
import { selectProjectId } from "../project/project.reducer";
import { HistoryActionType } from "./history.actions";
import {
  HistoryEntry,
  HistoryEntryAction,
  HistoryEntryType,
} from "./history.entry.actions";
import { selectHistory } from "./history.reducer";

const signalrRErrorHandler = () => {
  // Noop. SignalR errors are already handled and logged in `signalRClient.handleServiceError`
  // but we still reject/throw on error so that subsequent operations like `sortAndReassignRootZIndexes`
  // are not run. And we catch the error to avoid unhandled rejections and logging errors twice in PROD.
  /**
   * @TODO We should check if the error is from ServiceError, otherwise it might be a saga error, which
   * needs to be handled in that case.
   */
};

function* _notifyServer(action: HistoryEntry, isRedo: boolean) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const signalRClient: SignalRClient = yield getContext("signalRClient");
  const projectId = selectProjectId(yield select());

  switch (action.transformationType) {
    case HistoryEntryType.ADD: {
      const { addedObjects } = action;

      if (isRedo) {
        signalRClient.postTileBatchAction("RedoAdd", {
          restoredTiles: addedObjects,
        });
      } else {
        yield signalRClient.postNew(addedObjects);
        yield signalRClient.postTileBatchAction("Lock", {
          lockedTiles: addedObjects,
        });
      }

      canvas.sortAndReassignRootZIndexes();
      return;
    }
    case HistoryEntryType.DUPLICATE: {
      const { objects, sourceProjectId } = action;
      const tiles = objects.map(toDuplicateTile);

      canvas.addToPendingCopies(objects);

      try {
        yield copyTiles(tiles, projectId, sourceProjectId);
      } catch {
        canvas.removeFromPendingCopies(objects);
      }
      return;
    }
    case HistoryEntryType.COPY: {
      const { targetObjects, targetChildren, targetConnections } = action;

      if (isRedo) {
        yield signalRClient.postTileBatchAction("RedoCopy", {
          restoredTiles: [...targetObjects, ...targetChildren],
          restoredRelations: targetConnections,
        });
        canvas.sortAndReassignRootZIndexes();
      } else {
        // Action dispatched when a Duplicate or paste copy has completed
        const pendingCopies = canvas
          .getObjectsByUUIDs(targetObjects.map((o) => o.TileId))
          .map(toSerializedCopyObject);
        canvas.removeFromPendingCopies(pendingCopies);
      }
      return;
    }
    case HistoryEntryType.UPLOAD: {
      const { uploadedObject } = action;
      yield signalRClient.postUploaded(
        uploadedObject.TileId,
        uploadedObject.TypeTile,
        uploadedObject.OriginalFileName ?? ""
      );
      return;
    }
    case HistoryEntryType.REMOVE: {
      const {
        removedConnections,
        removedObjects,
        removedChildren,
        removedAttachments,
        objectType,
      } = action;

      if (objectType === canvasObjectIds.connector) {
        yield signalRClient.postTileRelationChanged(
          removedConnections,
          RelationChangeAction.Delete
        );
      } else if (removedObjects.length) {
        // Remove the children before the group tiles on the server
        const allRemovedObjects = [
          ...removedChildren,
          ...removedObjects,
          ...removedAttachments,
        ];
        yield signalRClient.postDeleteTile(getTileIds(allRemovedObjects));
      }
      canvas.sortAndReassignRootZIndexes();
      return;
    }
    case HistoryEntryType.CONNECT: {
      const { addedConnections } = action;
      yield signalRClient.postTileRelationChanged(
        addedConnections,
        RelationChangeAction.Add
      );
      return;
    }
    case HistoryEntryType.MODIFY:
    case HistoryEntryType.UPDATE: {
      const { deltas } = action;
      if (deltas.origin.length) {
        yield signalRClient.postTilePropertyUpdated(deltas.result);
      }

      return;
    }
    case HistoryEntryType.UPDATE_CONNECTION: {
      const { resultingConnections } = action;
      yield signalRClient.postTileRelationChanged(
        resultingConnections,
        RelationChangeAction.Modify
      );
      return;
    }
    case HistoryEntryType.SELECTION_CHANGED: {
      const { selectedUuids } = action;
      yield signalRClient.postTileBatchAction("Lock", {
        lockedTiles: selectedUuids.map((TileId) => ({ TileId })),
      });
      return;
    }
    case HistoryEntryType.GROUP: {
      const { addedGroup, allChildrenAfterGroup, groupsBeforeGroup } = action;

      const addedTiles = [addedGroup];

      yield signalRClient.postTileBatchAction("Group", {
        addedTiles: isRedo ? [] : addedTiles,
        restoredTiles: isRedo ? addedTiles : [],
        groupedTiles: allChildrenAfterGroup,
        deletedTiles: groupsBeforeGroup,
        lockedTiles: addedTiles,
      });
      canvas.sortAndReassignRootZIndexes();
      return;
    }
    case HistoryEntryType.UNGROUP: {
      const {
        groupBeforeUngroup,
        objectsAfterUngroup,
        removedChildren,
      } = action;

      yield signalRClient.postTileBatchAction("Ungroup", {
        ungroupedTiles: objectsAfterUngroup,
        // The server will automatically delete related connections
        deletedTiles: [...removedChildren, groupBeforeUngroup],
        lockedTiles: objectsAfterUngroup,
      });
      canvas.sortAndReassignRootZIndexes();
      return;
    }
    case HistoryEntryType.UNGROUP_ALL: {
      const {
        objectsAfterUngroup,
        groupsBeforeUngroup,
        topLevelObjectsBeforeUngroup,
      } = action;

      yield signalRClient.postTileBatchAction("UngroupAll", {
        ungroupedTiles: objectsAfterUngroup,
        deletedTiles: groupsBeforeUngroup,
        lockedTiles: [...objectsAfterUngroup, ...topLevelObjectsBeforeUngroup],
      });
      canvas.sortAndReassignRootZIndexes();
      return;
    }
    case HistoryEntryType.STACK: {
      const { addedStack, childrenAfterStack } = action;

      const addedTiles = [addedStack];

      yield signalRClient.postTileBatchAction("Stack", {
        addedTiles: isRedo ? [] : addedTiles,
        restoredTiles: isRedo ? addedTiles : [],
        groupedTiles: childrenAfterStack,
        lockedTiles: addedTiles,
      });
      canvas.sortAndReassignRootZIndexes();
      return;
    }
    case HistoryEntryType.UNSTACK: {
      const { removedStack, objectsAfterUnstack, objectsBeforeStack } = action;
      const ungrouped = objectsBeforeStack
        ? objectsBeforeStack
        : objectsAfterUnstack;

      yield signalRClient.postTileBatchAction("Unstack", {
        ungroupedTiles: ungrouped,
        deletedTiles: [removedStack],
        lockedTiles: ungrouped,
      });
      canvas.sortAndReassignRootZIndexes();
      return;
    }
    case HistoryEntryType.STACK_ROTATE: {
      const { rotationState } = action;
      yield signalRClient.postTileReorder(rotationState);
      return;
    }
    case HistoryEntryType.LOCK: {
      const { newStatus } = action;
      yield signalRClient.postPinningActivity(
        newStatus.map((status) => {
          return {
            TileId: status.uuid,
            IsPinned: status.isLocked,
          };
        })
      );
      return;
    }
    case HistoryEntryType.MOVE_LAYER: {
      const { newState, isSubselect } = action;

      if (isSubselect) {
        yield signalRClient.postTileReorder(newState);
      } else {
        yield signalRClient.postTileIndexesChanged(newState);
      }

      canvas.sortAndReassignRootZIndexes();
      return;
    }
    case HistoryEntryType.CHANGE_BACKGROUND: {
      const { newBackgroundColor } = action;
      yield signalRClient.postProjectBackgroundChanged(newBackgroundColor);
      return;
    }
    default:
      assertUnreachable(action);
      return;
  }
}

/**
 * Send the correct SignalR messages to sync the undo to the other users.
 *
 * @NOTE Objects are not reserved after Undo/Redo, as the selection is always discarded in historyMiddleware
 */
function* _undoNotifyServer(action: HistoryEntry) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const signalRClient: SignalRClient = yield getContext("signalRClient");

  /**
   * Delay for some time before sending UNDO message, to avoid race condition with the MODIFY
   * message in case of sync + UNDO happening next to each other.
   *
   * This is especially true in the case of text sync + Undo #7264
   */
  yield delay(400);

  switch (action.transformationType) {
    case HistoryEntryType.ADD: {
      const { addedObjects } = action;

      if (addedObjects.length) {
        yield signalRClient.postDeleteTile(getTileIds(addedObjects));
      }
      canvas.sortAndReassignRootZIndexes();
      return;
    }
    case HistoryEntryType.COPY: {
      const { targetObjects, targetChildren } = action;

      // Remove the children before the group tiles on the server
      const allRemovedObjects = [...targetChildren, ...targetObjects];

      if (allRemovedObjects.length) {
        yield signalRClient.postDeleteTile(getTileIds(allRemovedObjects));
      }
      return;
    }
    case HistoryEntryType.REMOVE: {
      const {
        removedConnections,
        removedObjects,
        removedChildren,
        removedAttachments,
      } = action;

      const objectsToRestore = [
        ...removedChildren,
        ...removedObjects,
        ...removedAttachments,
      ];

      yield signalRClient.postTileBatchAction("UndoRemoval", {
        restoredTiles: objectsToRestore,
        restoredRelations: removedConnections,
        lockedTiles: [],
      });
      canvas.sortAndReassignRootZIndexes();
      return;
    }
    case HistoryEntryType.CONNECT: {
      const { addedConnections } = action;
      yield signalRClient.postTileRelationChanged(
        addedConnections,
        RelationChangeAction.Delete
      );
      return;
    }
    case HistoryEntryType.MODIFY:
    case HistoryEntryType.UPDATE: {
      const { deltas } = action;
      if (deltas.origin.length) {
        yield signalRClient.postTilePropertyUpdated(deltas.origin);
      }
      return;
    }
    case HistoryEntryType.UPDATE_CONNECTION: {
      const { originalConnections } = action;
      yield signalRClient.postTileRelationChanged(
        originalConnections,
        RelationChangeAction.Modify
      );
      return;
    }
    case HistoryEntryType.GROUP: {
      const {
        addedGroup,
        topLevelObjectsBeforeGroup,
        childrenByGroupBeforeGroup,
        groupsBeforeGroup,
      } = action;

      const allChildrenBeforeGroup = groupsBeforeGroup.flatMap(({ TileId }) => {
        const data = childrenByGroupBeforeGroup[TileId] ?? [];

        return data.map((tile) => ({
          ...tile,
          ParentId: TileId,
        }));
      });

      yield signalRClient.postTileBatchAction("UndoGroup", {
        restoredTiles: groupsBeforeGroup,
        // Revert grouped objects by "grouping" them into their previous groups
        groupedTiles: allChildrenBeforeGroup,
        // Ungroup top-level objects
        ungroupedTiles: topLevelObjectsBeforeGroup,
        deletedTiles: [addedGroup],
        lockedTiles: [],
      });
      canvas.sortAndReassignRootZIndexes();
      return;
    }
    case HistoryEntryType.UNGROUP: {
      const {
        removedChildren,
        removedConnections,
        groupBeforeUngroup,
        childrenBeforeUngroup,
      } = action;

      /**
       * @NOTE How Undo Ungroup works in case of 2nd last deleted subitem is quite fragile.
       * - First we re-added the group and the removed children
       *   - `addObjects` internally calls `nestInGroups` so the removed children will be already in the group
       *     at the end of the step, but their coordinates will be **wrong**. That's because the group is
       *     updated while the remaining not-removed children are still not grouped in it.
       * - Then we move the remaining not-removed children into the group, but we include the removed children
       *   as well.
       *   - `moveGroupedObjects` internally avoids moving children which are already in the correct group, but
       *     it will set all the children coordinates back to their original relative coordinates. So
       *     finally the group is updated and have correct coordinates because all children are grouped and
       *     have correct relative positions now 🤯.
       *
       * This behaviour is the same in both historyMiddleware and signalrMiddleware
       */
      yield signalRClient.postTileBatchAction("UndoUngroup", {
        restoredTiles: [groupBeforeUngroup, ...removedChildren],
        restoredRelations: removedConnections,
        groupedTiles: [...childrenBeforeUngroup, ...removedChildren],
        lockedTiles: [],
      });
      canvas.sortAndReassignRootZIndexes();
      return;
    }
    case HistoryEntryType.UNGROUP_ALL: {
      const { childrenBeforeUngroup, groupsBeforeUngroup } = action;

      yield signalRClient.postTileBatchAction("UndoUngroupAll", {
        restoredTiles: groupsBeforeUngroup,
        groupedTiles: childrenBeforeUngroup,
        lockedTiles: [],
      });
      canvas.sortAndReassignRootZIndexes();
      return;
    }
    case HistoryEntryType.STACK: {
      const { addedStack, objectsBeforeStack } = action;

      yield signalRClient.postTileBatchAction("UndoStack", {
        ungroupedTiles: objectsBeforeStack,
        deletedTiles: [addedStack],
        lockedTiles: [],
      });
      canvas.sortAndReassignRootZIndexes();
      return;
    }
    case HistoryEntryType.UNSTACK: {
      const {
        removedStack,
        removedConnections,
        childrenBeforeUnstack,
      } = action;

      yield signalRClient.postTileBatchAction("UndoUnstack", {
        restoredTiles: [removedStack],
        restoredRelations: removedConnections,
        groupedTiles: childrenBeforeUnstack,
        lockedTiles: [],
      });
      canvas.sortAndReassignRootZIndexes();
      return;
    }
    case HistoryEntryType.STACK_ROTATE: {
      const { stackUuid } = action;
      const stackObject = canvas.getObjectByUUID<fabric.Stack>(stackUuid);

      if (!stackObject) {
        return;
      }

      const rotationState = stackObject.getObjects().map(toGroupedTile);
      yield signalRClient.postTileReorder(rotationState);
      return;
    }
    case HistoryEntryType.LOCK: {
      const { oldStatus } = action;
      yield signalRClient.postPinningActivity(
        oldStatus.map((status) => {
          return {
            TileId: status.uuid,
            IsPinned: status.isLocked,
          };
        })
      );
      return;
    }
    case HistoryEntryType.MOVE_LAYER: {
      const { oldState, isSubselect } = action;
      if (isSubselect) {
        yield signalRClient.postTileReorder(oldState);
      } else {
        yield signalRClient.postTileIndexesChanged(oldState);
      }

      canvas.sortAndReassignRootZIndexes();
      return;
    }
    case HistoryEntryType.CHANGE_BACKGROUND: {
      const { oldBackgroundColor } = action;
      yield signalRClient.postProjectBackgroundChanged(oldBackgroundColor);
      return;
    }
    case HistoryEntryType.DUPLICATE:
    case HistoryEntryType.UPLOAD:
    case HistoryEntryType.SELECTION_CHANGED:
      // Not possible to redo
      return;
    default:
      assertUnreachable(action);
      return;
  }
}

function* undoNotifyServer() {
  const { undo } = selectHistory(yield select());
  const action = undo[undo.length - 1];

  if (action) {
    try {
      yield call(_undoNotifyServer, action);
    } catch {
      signalrRErrorHandler();
    }
  }
}

function* redoNotifyServer() {
  const { redo } = selectHistory(yield select());
  const action = redo[0];
  if (action) {
    try {
      yield call(_notifyServer, action, true);
    } catch {
      signalrRErrorHandler();
    }
  }
}

function* notifyServer(action: HistoryEntryAction<HistoryEntry>) {
  try {
    yield call(_notifyServer, action.payload, false);
  } catch {
    signalrRErrorHandler();
  }
}

export function* signalRHistorySaga(): Generator<Effect> {
  yield all([
    takeEvery(
      [HistoryActionType.HISTORY_NOOP, HistoryActionType.HISTORY_ADD_ENTRY],
      notifyServer
    ),
    takeEvery(HistoryActionType.UNDO, undoNotifyServer),
    takeEvery(HistoryActionType.REDO, redoNotifyServer),
  ]);
}
