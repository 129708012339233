import { merge } from "theme-ui";

import { runtimeConfig } from "../tools/runtimeConfig";
import { DefaultTheme } from "./default";
import twrTheme from "./twr";

// TODO: #6214 - Remove this and move the configuration to server

const bgwTheme = merge(twrTheme, {
  name: "bgw",
  colors: {
    accent: "#004994",
    active: "#004994",
    danger: "#0084ff",
    primary: "#003f80",
    disabled: "#d3d3d3",
    disabledDark: "#666666",
    accentAlternate: "#00356b",
    accentSecondary: "#3880c9",
    accentSecondaryAlternate: "#225385",
    accentSecondaryDimmed: "#f6d5e9",
    disclaimerBg: "#004994",
    ...runtimeConfig.styles?.colors,
  },
  layout: {
    fullLogo: {
      alignItems: "normal !important", // !important so it applies to both "shared/Logo" and "LoginTWR" components
    },
  },
}) as DefaultTheme;

export default bgwTheme;
