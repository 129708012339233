import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function DownArrow(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <polygon points="19.86,22.56 15.74,18.43 16.44,17.72 19.86,21.14 23.25,17.75 23.96,18.46 	" />
    </Icon>
  );
}
export default DownArrow;
