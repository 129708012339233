import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { CollaboardApiError } from "../../../../../errors/collaboardApiError";

import { errorToast } from "../../../../../tools/errorToast";

type ImportError = ApiBaseResponse & {
  ItemsAdded: number;
  ItemsError: number;
  ItemsExisting: number;
};

type useUserImportErrorHandlerResult = {
  userImportErrorHandler: (err: CollaboardApiError<ImportError>) => void;
};
export const useUserImportErrorHandler = (): useUserImportErrorHandlerResult => {
  const { t } = useTranslation();

  const userImportErrorHandler = (err: CollaboardApiError<ImportError>) => {
    if (!err.response) {
      return;
    }

    const { ItemsAdded, ItemsError, ItemsExisting } = err.response.data;
    const translationData = {
      added: ItemsAdded,
      invalid: ItemsError,
      existing: ItemsExisting,
    };

    if (ItemsAdded === 0) {
      errorToast(
        t("team.batchImport.importError", translationData),
        {},
        t("team.batchImport.importErrorHeader")
      );
    } else {
      toast(t("team.batchImport.partialSuccess", translationData));
    }
  };

  return { userImportErrorHandler };
};
