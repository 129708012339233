import React, { ReactElement } from "react";
import { Trans } from "react-i18next";
import { ThemeUIStyleObject, Flex, Link } from "theme-ui";
import TagLine from "../../features/routes/layout/TagLine";
import { useUISettings } from "../../reduxStore/uiSettings";
import { dynamicFeatureConfig } from "../../tools/flags";
import Logo from "./Logo";

function PlansPageFooter(): ReactElement {
  const { getTranslation, getDynamicFeatureConfig } = useUISettings();
  const digitalWhiteboardHomepageUrl =
    getDynamicFeatureConfig<string>(
      dynamicFeatureConfig.DIGITAL_WHITEBOARD_HOMEPAGE_URL
    ) ?? "";

  return (
    <Flex sx={footerStyle}>
      <Flex>
        <Logo size="50px" />
        <TagLine size="50px" />
      </Flex>

      <Flex sx={logoStyle}>
        <Trans defaults={getTranslation("app.additionalResources")}>
          <Link
            sx={footerContentStyle}
            href={digitalWhiteboardHomepageUrl}
            target="_blank"
          />
        </Trans>
      </Flex>
    </Flex>
  );
}

const footerStyle: ThemeUIStyleObject = {
  border: "2px solid black",
  p: [5],
  my: [5],
  justifyContent: "space-between",
};

const logoStyle: ThemeUIStyleObject = {
  flexFlow: "column",
  fontWeight: "bold",
};

const footerContentStyle: ThemeUIStyleObject = {
  color: "var(--accent) !important", // TODO: although there is nothing that overrides this color, it doesn't work without `!important`
};

export default PlansPageFooter;
