import React, { ReactElement } from "react";
import { ThemeUIStyleObject } from "theme-ui";
import { Icon } from "./Icon";

type Props = IconProps & { sx?: ThemeUIStyleObject };

function LoadingSpinner(props: Props): ReactElement {
  const { fill, sx, ...otherProps } = props;

  return (
    <Icon
      role="status"
      aria-label="Loading spinner"
      sx={{
        animation: "chrome-loading 1.5s linear infinite",
        fill: fill ? `${fill} !important` : "none !important",
        filter: "none",
        stroke: "#ccc",
        ...sx,
      }}
      {...otherProps}
    >
      <circle strokeWidth="4" r="16" cx="20" cy="20" />
    </Icon>
  );
}
export default LoadingSpinner;
