import { BrushType } from "../../../../const";
import { BaseImageBrush } from "./BaseImageBrush";
export class PencilImageBrush extends BaseImageBrush {
  /**
   * Define the spacing between painted instances of the brush image.
   *
   * This controls how heavy the brush path looks because it affects the amount
   * of overlap.
   */
  protected BRUSH_IMAGE_SPACING = 3;

  constructor(
    canvas: fabric.CollaboardCanvas,
    options?: fabric.CollaboardBrushOptions
  ) {
    super("/brush-pencil.png", BrushType.pencil, canvas, options);
  }

  /**
   * Modify the pointer pressure.
   */
  protected _modifyPressure(pressure: number): number {
    return this._isStylusPointer ? pressure + 0.05 : 0.15;
  }
}
