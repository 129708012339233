import { google, IBV } from "@collaboard/protobuf";
import { Struct, struct } from "pb-util";
import { blobStates } from "../../const";
import { assertUnreachable } from "../../tools/assertions";
import { isString } from "../../tools/text";
import { isDefined, isNumber } from "../../tools/utils";
import { BatchTileType } from "../../types/enum";
import { errorCodeIds } from "../errorCodes";
import {
  IncomingMessage,
  IncomingMessageMap,
  OutgoingMessage,
  OutgoingMessageMap,
} from "./message.serializer.types";
import type {
  AdjustPresentationTimerMessage,
  BackgroundMessage,
  BaseMessage,
  CommentCreatedMessage,
  CommentDeletedMessage,
  CommentLikeChangedMessage,
  DeleteProjectMessage,
  DeleteTileMessage,
  GenericAvailableMessage,
  GroupedTile,
  InitialDataForNewJoinToPresentationMessage,
  InitialDataForNewJoinToTimerMessage,
  LinkCreatedMessage,
  LinkDeletedMessage,
  LinkUpdatedMessage,
  LowResThumbnailAvailableMessage,
  NewBigInkMessage,
  NotifyConnectionStartedMessage,
  NotifyLogInProjectMessage,
  NotifyLogOutProjectMessage,
  NotifyNewMessage,
  NotifyPingbackMessage,
  NotifyTileBatchActionMessage,
  NotifyTilePropertyMessage,
  NotifyTileRelationMessage,
  NudgeForAttentionMessage,
  PausePresentationTimerMessage,
  PinMessage,
  ProjectCopiedMessage,
  ProjectCopyFailedMessage,
  ProjectParticipantMessage,
  ProjectUnavailableMessage,
  ResizedImageTileContent,
  ResponsiveImagesAvailableMessage,
  ScreenCoordinates,
  ScreenPosZoomChangedMessage,
  SingleThumbnailsMessage,
  StartPresentationTimerMessage,
  StartPresentingMessage,
  StopPresentationTimerMessage,
  StopPresentingMessage,
  SystemMessage,
  ThumbnailCreationFailedMessage,
  ThumbnailPageChangedMessage,
  ThumbnailsCreatedMessage,
  ThumbnailsCreatingMessage,
  TileBatchActionItemInfo,
  TileContent,
  TileGroupMessage,
  TileIndex,
  TileIndexesMessage,
  TileRelation,
  TilesCopiedMessage,
  TileStatus,
  UploadedMessage,
  VideoPlayerStartStopCalledMessage,
  VotingCastMessage,
  VotingRevokedMessage,
  VotingSessionFinalizedMessage,
  VotingStartedMessage,
  VotingStoppedMessage,
  VotingTimeAdjustedMessage,
} from "./message.types";
import { NotifyMessageName, PostMessageName } from "./protobuf.codecs";

/**
 * Deserialize a "protobuf" message into an "app" message.
 *
 * @NOTE It is important to note that ALL message properties from protobuf are
 * optional - according to the TS definitions and the protocol (proto3) - but
 * in reality these properties will always be sent by the server. However to
 * satisfy TS we always provide a fallback value to ensure the type is correct
 * and make it easier to use around the code base.
 *
 * See https://github.com/protobufjs/protobuf.js/issues/1171#issuecomment-528255164
 *
 * @NOTE The type assertions on the returned response values are required
 * because TS isn't able to infer the return type correctly.
 *
 * E.g. `return response as IncomingMessageMap[T["name"]];`
 *
 * See https://stackoverflow.com/a/58673995
 */
export const deserializeIncomingMessage = <T extends IncomingMessage>(
  incomingMessage: T
): IncomingMessageMap[T["name"]] => {
  const { name, message } = incomingMessage;
  switch (name) {
    case NotifyMessageName.AdjustPresentationTimerMessage: {
      const response: AdjustPresentationTimerMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        MinutesToAdd: message.MinutesToAdd ?? 0,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.BackgroundMessage: {
      const response: BackgroundMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        BackgroundColor: message.BackgroundColor ?? "",
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.CommentCreatedMessage: {
      const response: CommentCreatedMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        CommentId: message.CommentId ?? 0,
        TileId: fromGuid(message.TileId) ?? "",
        Text: message.Text ?? "",
        CreatedBy: message.CreatedBy ?? 0,
        CreationDate: fromTimestamp(message.CreationDate),
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.CommentDeletedMessage: {
      const response: CommentDeletedMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        CommentId: message.CommentId ?? 0,
        TileId: fromGuid(message.TileId) ?? "",
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.CommentLikeChangedMessage: {
      const response: CommentLikeChangedMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        CommentId: message.CommentId ?? 0,
        TileId: fromGuid(message.TileId) ?? "",
        UserId: message.UserId ?? 0,
        LikeCount: message.LikeCount ?? 0,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.DeleteProjectMessage: {
      const response: DeleteProjectMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.DeleteTileMessage: {
      const response: DeleteTileMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        TileIds: (message.TileIds ?? []).map(fromGuid).filter(isDefined),
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.GenericAvailableMessage: {
      const response: GenericAvailableMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        SenderConnectionId: message.SenderConnectionId ?? "",
        RecipientConnectionId: message.RecipientConnectionId ?? "",
        MessageType: message.MessageType ?? "",
        Payload: message.Payload ?? "",
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.InitialDataForNewJoinToTimerMessage: {
      const response: InitialDataForNewJoinToTimerMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        NewJoinerConnectionId: fromGuid(message.NewJoinerConnectionId) ?? "",
        Presenter: fromStringValue(message.Presenter) ?? "",
        InitialDurationTimer: message.InitialDurationTimer ?? 0,
        TimerStartedAt: message.TimerStartedAt ?? 0,
        TimerPausedAt: message.TimerPausedAt ?? 0,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.InitialDataForNewJoinToPresentationMessage: {
      const response: InitialDataForNewJoinToPresentationMessage &
        BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        NewJoinerConnectionId: fromGuid(message.NewJoinerConnectionId) ?? "",
        Presenter: fromStringValue(message.Presenter) ?? "",
        ScreenCoordinates: fromScreenCoordinates(message.ScreenCoordinates),
        ZoomLevel: message.ZoomLevel ?? 0,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.LinkCreatedMessage: {
      const response: LinkCreatedMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        LinkId: message.LinkId ?? 0,
        SourceTileId: fromGuid(message.SourceTileId) ?? "",
        LinkType: message.LinkType ?? 0,
        TargetTileId: fromGuid(message.TargetTileId) ?? "",
        TargetUrl: fromStringValue(message.TargetUrl) ?? "",
        QuickLinkId: message.QuickLinkId ?? 0,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.LinkDeletedMessage: {
      const response: LinkDeletedMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        LinkId: message.LinkId ?? 0,
        SourceTileId: fromGuid(message.SourceTileId) ?? "",
        LinkType: message.LinkType ?? 0,
        TargetTileId: fromGuid(message.TargetTileId) ?? "",
        TargetUrl: fromStringValue(message.TargetUrl) ?? "",
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.LinkUpdatedMessage: {
      const response: LinkUpdatedMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        LinkId: message.LinkId ?? 0,
        SourceTileId: fromGuid(message.SourceTileId) ?? "",
        LinkType: message.LinkType ?? 0,
        TargetTileId: fromGuid(message.TargetTileId) ?? "",
        TargetUrl: fromStringValue(message.TargetUrl) ?? "",
        QuickLinkId: message.QuickLinkId ?? 0,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.LogInProjectMessage: {
      const response: NotifyLogInProjectMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        AuthUser: {
          UserId: message.AuthUser?.UserId ?? 0,
          ConnectionId: message.AuthUser?.ConnectionId ?? "",
          UserName: message.AuthUser?.UserName ?? "",
          FirstName: fromStringValue(message.AuthUser?.FirstName) ?? "",
          LastName: fromStringValue(message.AuthUser?.LastName) ?? "",
          PhotoUrl: fromStringValue(message.AuthUser?.PhotoUrl) ?? "",
          IsGuest: !!message.AuthUser?.IsGuest,
          DateCreated: fromTimestamp(
            message.AuthUser?.DateCreated
          ).toISOString(),
          DateLastActivity: message.AuthUser?.DateLastActivity
            ? fromTimestamp(message.AuthUser?.DateLastActivity).toISOString()
            : null,
          LastUpdate: fromTimestamp(message.AuthUser?.LastUpdate).toISOString(),
          IsPresenting: !!message.AuthUser?.IsPresenting,
          HasTimer: !!message.AuthUser?.HasTimer,
          HasVoting: !!message.AuthUser?.HasVoting,
          IsDisconnected: !!message.AuthUser?.IsDisconnected,
        },
        IsPresenting: !!message.IsPresenting,
        HasTimer: !!message.HasTimer,
        HasVoting: !!message.HasVoting,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.LogOutProjectMessage: {
      const response: NotifyLogOutProjectMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        ConnectionId: message.ConnectionId ?? "",
        UserName: message.UserName ?? "",
        IsPresenting: !!message.IsPresenting,
        LockedTiles: (message.LockedTiles ?? [])
          .map(fromGuid)
          .filter(isDefined),
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.LowResThumbnailAvailableMessage: {
      const response: LowResThumbnailAvailableMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        ParentTileId: fromGuid(message.ParentTileId) ?? "",
        ImageData: message.ImageData ?? null,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.NewBigInkMessage: {
      const response: NewBigInkMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        TileId: fromGuid(message.TileId) ?? "",
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.NewMessage: {
      const response: NotifyNewMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        Tiles: (message.Tiles ?? []).map((tileStatus) => {
          const tile: TileStatus = {
            AzureBlobStatus: tileStatus.AzureBlobStatus ?? 0,
            BackgroundColor: fromStringValue(tileStatus.BackgroundColor) ?? "",
            Height: tileStatus.Height ?? 0,
            IsPinned: !!tileStatus.IsPinned,
            LockedUser: fromStringValue(tileStatus.LockedUser),
            OldTileId: fromGuid(tileStatus.OldTileId) ?? "",
            OriginalFileName: fromStringValue(tileStatus.OriginalFileName),
            ParentId: fromGuid(tileStatus.ParentId) ?? "",
            PositionX: tileStatus.PositionX ?? 0,
            PositionY: tileStatus.PositionY ?? 0,
            RawTileContent:
              fromStringValue(tileStatus.RawTileContent) ?? undefined,
            Relations: (tileStatus.Relations ?? []).map(
              fromProtobufTileRelation
            ),
            Rotation: tileStatus.Rotation ?? 0,
            ScaleX: tileStatus.ScaleX ?? 0,
            ScaleY: tileStatus.ScaleY ?? 0,
            TileContent: tileStatus.TileContent?.Data
              ? ((struct.decode(
                  tileStatus.TileContent.Data as Struct
                ) as unknown) as TileContent)
              : undefined,
            TileId: fromGuid(tileStatus.TileId) ?? "",
            TypeTile: tileStatus.TypeTile ?? 0,
            Width: tileStatus.Width ?? 0,
            ZIndex: tileStatus.ZIndex ?? 0,
          };
          return tile;
        }),
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.NotifyConnectionStartedMessage: {
      const response: NotifyConnectionStartedMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        ConnectionId: message.ConnectionId ?? "",
        HubId: fromGuid(message.HubId),
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.NudgeForAttentionMessage: {
      const response: NudgeForAttentionMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        Presenter: fromStringValue(message.Presenter) ?? "",
        ScreenCoordinates: fromScreenCoordinates(message.ScreenCoordinates),
        ZoomLevel: message.ZoomLevel ?? 0,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.PausePresentationTimerMessage: {
      const response: PausePresentationTimerMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        IsPaused: !!message.IsPaused,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.PingbackMessage: {
      const response: NotifyPingbackMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        ConnectionId: message.ConnectionId ?? "",
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.PinMessage: {
      const response: PinMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        PinnedTiles: (message.PinnedTiles ?? []).map((pinnedTile) => {
          return {
            TileId: fromGuid(pinnedTile.Key) ?? "",
            IsPinned: !!pinnedTile.Value,
          };
        }),
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.ProjectCopiedMessage: {
      const response: ProjectCopiedMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        ContainerUri: fromStringValue(message.ContainerUri) ?? "",
        ClientConnectionId: fromStringValue(message.ClientConnectionId) ?? "",
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.ProjectCopyFailedMessage: {
      const response: ProjectCopyFailedMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        ClientConnectionId: fromStringValue(message.ClientConnectionId) ?? "",
        ErrorCode: message.ErrorCode ?? errorCodeIds.GenericError,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.ProjectParticipantMessage: {
      const response: ProjectParticipantMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        ParticipantUserName: message.ParticipantUserName ?? "",
        Permission: message.Permission ?? 0,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.ProjectUnavailableMessage: {
      const response: ProjectUnavailableMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        UnavailableReason: message.UnavailableReason ?? 0,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.ResponsiveImagesAvailableMessage: {
      const response: ResponsiveImagesAvailableMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        ImageTileId: fromGuid(message.ImageTileId) ?? "",
        ImageContent: message.ImageContent?.Data
          ? ((struct.decode(
              message.ImageContent.Data as Struct
            ) as unknown) as ResizedImageTileContent)
          : undefined,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.ScreenPosZoomChangedMessage: {
      const response: ScreenPosZoomChangedMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        ScreenCoordinates: fromScreenCoordinates(message.ScreenCoordinates),
        ZoomLevel: message.ZoomLevel ?? 0,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.SingleThumbnailsMessage: {
      const response: SingleThumbnailsMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        ParentTileId: fromGuid(message.ParentTileId) ?? "",
        OriginalFileName: message.OriginalFileName ?? "",
        NumberOfThumbnails: message.NumberOfThumbnails ?? 0,
        SequenceNumber: message.SequenceNumber ?? 0,
        ThumbnailId: fromGuid(message.ThumbnailId) ?? "",
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.StartPresentationTimerMessage: {
      const response: StartPresentationTimerMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        InitialDuration: message.InitialDuration ?? 0,
        Presenter: fromStringValue(message.Presenter) ?? "",
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.StartPresentingMessage: {
      const response: StartPresentingMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        Presenter: message.Presenter ?? "",
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.StopPresentationTimerMessage: {
      const response: StopPresentationTimerMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.StopPresentingMessage: {
      const response: StopPresentingMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        Presenter: message.Presenter ?? "",
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.SystemMessage: {
      const response: SystemMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        MessageToClient: message.MessageToClient ?? "",
        ErrorCode: message.ErrorCode ?? errorCodeIds.GenericError,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.ThumbnailCreationFailedMessage: {
      const response: ThumbnailCreationFailedMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        ParentTileId: fromGuid(message.ParentTileId) ?? "",
        OriginalFileName: fromStringValue(message.OriginalFileName) ?? "",
        ErrorMessage: message.ErrorMessage ?? "",
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.ThumbnailPageChangedMessage: {
      const response: ThumbnailPageChangedMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        TileId: fromGuid(message.TileId) ?? "",
        NewPageNumber: message.NewPageNumber ?? 0,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.ThumbnailsCreatedMessage: {
      const response: ThumbnailsCreatedMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        ParentTileId: fromGuid(message.ParentTileId) ?? "",
        OriginalFileName: fromStringValue(message.OriginalFileName) ?? "",
        NumberOfThumbnails: message.NumberOfThumbnails ?? 0,
        FirstThumbnailId: fromGuid(message.FirstThumbnailId) ?? "",
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.ThumbnailsCreatingMessage: {
      const response: ThumbnailsCreatingMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        ParentTileId: fromGuid(message.ParentTileId) ?? "",
        OriginalFileName: fromStringValue(message.OriginalFileName) ?? "",
        NumberOfThumbnails: message.NumberOfThumbnails ?? 0,
        FirstThumbnailId: fromGuid(message.FirstThumbnailId) ?? "",
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.TileBatchActionMessage: {
      const response: NotifyTileBatchActionMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        User: fromStringValue(message.User) ?? "",
        Type: message.Type ?? "",
        Added: message.Added ?? [],
        Grouped: message.Grouped ?? [],
        Ungrouped: message.Ungrouped ?? [],
        Deleted: message.Deleted ?? [],
        Restored: message.Restored ?? [],
        Locked: message.Locked ?? [],
        RestoredRelationIds: message.RestoredRelationIds ?? [],
        Tiles: (message.Tiles ?? []).map((tile) => {
          const batchTile: TileBatchActionItemInfo = {
            Id: fromGuid(tile.Id) ?? "",
            Type: tile.Type ?? 0,
            X: tile.X ?? undefined,
            Y: tile.Y ?? undefined,
            Z: tile.Z ?? undefined,
            W: tile.W ?? undefined,
            H: tile.H ?? undefined,
            Angle: fromDoubleValue(tile.Angle) ?? undefined,
            ParentId: fromGuid(tile.ParentId) ?? undefined,
          };
          return batchTile;
        }),
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.TileGroupMessage: {
      const response: TileGroupMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        GroupedTiles: (message.GroupedTiles ?? []).map((groupedTile) => {
          const tile: GroupedTile = {
            ParentTileId: fromGuid(groupedTile.ParentTileId) ?? "",
            TileId: fromGuid(groupedTile.TileId) ?? "",
            PositionX: groupedTile.PositionX ?? 0,
            PositionY: groupedTile.PositionY ?? 0,
            Height: groupedTile.Height ?? 0,
            Width: groupedTile.Width ?? 0,
            ZIndex: groupedTile.ZIndex ?? 0,
            ScaleX: groupedTile.ScaleX ?? 0,
            ScaleY: groupedTile.ScaleY ?? 0,
            Rotation: groupedTile.Rotation ?? 0,
          };
          return tile;
        }),
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.TileIndexesMessage: {
      const response: TileIndexesMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        TileIndexes: (message.TileIndexes ?? []).map((tileIndex) => {
          const tile: TileIndex = {
            TileId: fromGuid(tileIndex.TileId) ?? "",
            ZIndex: tileIndex.ZIndex ?? 0,
          };
          return tile;
        }),
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.TilePropertyMessage: {
      const response: NotifyTilePropertyMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        Deltas: (message.Deltas ?? []).map((delta) => {
          return {
            TileId: fromGuid(delta.TileId) ?? "",
            Delta: delta.Delta ?? "",
          };
        }),
        HasSucceeded: !!message.HasSucceeded,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.TileRelationMessage: {
      const response: NotifyTileRelationMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        Relation: fromProtobufTileRelation(message.Relation),
        RelationDeleted: !!message.RelationDeleted,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.TilesCopiedMessage: {
      const response: TilesCopiedMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        Token: fromGuid(message.Token) ?? "",
        CopiedTimestamp: fromTimestamp(message.CopiedTimeStamp),
        CopiedTilesCount: message.CopiedTilesCount ?? 0,
        CopiedRelationsCount: message.CopiedRelationsCount ?? 0,
        CopiedLinksCount: message.CopiedLinksCount ?? 0,
        CopyTrigger: message.CopyTrigger ?? 0,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.UploadedMessage: {
      const response: UploadedMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        TileId: fromGuid(message.TileId) ?? "",
        TypeTile: message.TypeTile ?? 0,
        AzureBlobStatus: message.AzureBlobStatus ?? 0,
        FileName: message.FileName ?? "",
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.VideoPlayerStartStopCalledMessage: {
      const response: VideoPlayerStartStopCalledMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        TileIdVideoContent: fromGuid(message.TileIdVideoContent) ?? "",
        VideoPlayerCommand: message.VideoPlayerCommand ?? 0,
        Presenter: fromStringValue(message.Presenter) ?? "",
        TimeStamp: message.TimeStamp ?? 0,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.VotingCastMessage: {
      const response: VotingCastMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        TileId: fromGuid(message.TileId) ?? "",
        SessionId: message.SessionId ?? 0,
        UserName: message.UserName ?? "",
        VotesLeft: message.VotesLeft ?? 0,
        NumberOfVotes: message.NumberOfVotes ?? 0,
        NumberOfVotersForTile: message.NumberOfVotersForTile ?? 0,
        TotalVotesForTile: message.TotalVotesForTile ?? 0,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.VotingRevokedMessage: {
      const response: VotingRevokedMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        TileId: fromGuid(message.TileId) ?? "",
        SessionId: message.SessionId ?? 0,
        UserName: message.UserName ?? "",
        VotesLeft: message.VotesLeft ?? 0,
        NumberOfVotersForTile: message.NumberOfVotersForTile ?? 0,
        TotalVotesForTile: message.TotalVotesForTile ?? 0,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.VotingSessionFinalizedMessage: {
      const response: VotingSessionFinalizedMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        SessionId: message.SessionId ?? 0,
        UserName: message.UserName ?? "",
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.VotingStartedMessage: {
      const response: VotingStartedMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        SessionId: message.SessionId ?? 0,
        VotingType: message.VotingType ?? 0,
        NumberOfVotesPerUser: message.NumberOfVotesPerUser ?? 0,
        DurationInSeconds: message.DurationInSeconds ?? 0,
        Owner: message.Owner ?? "",
        IsInvited: !!message.IsInvited,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.VotingStoppedMessage: {
      const response: VotingStoppedMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        SessionId: message.SessionId ?? 0,
        IsInvited: !!message.IsInvited,
      };
      return response as IncomingMessageMap[T["name"]];
    }
    case NotifyMessageName.VotingTimeAdjustedMessage: {
      const response: VotingTimeAdjustedMessage & BaseMessage = {
        ...extractIncomingBaseMessage(incomingMessage),
        SessionId: message.SessionId ?? 0,
        TotalDurationInSeconds: message.TotalDurationInSeconds ?? 0,
      };
      return response as IncomingMessageMap[T["name"]];
    }
  }

  return assertUnreachable(name, `Missing deserializer for ${name}`);
};

/**
 * Serialize an "app" message into a "protobuf" message.
 */
export const serializeOutgoingMessage = <T extends OutgoingMessage>(
  outgoingMessage: T,
  Base: IBV.Collaboard.Protobuf.IBaseMessage
): OutgoingMessageMap[T["name"]] => {
  const { name, message } = outgoingMessage;
  switch (name) {
    case PostMessageName.AdjustPresentationTimerMessage: {
      const response: IBV.Collaboard.Protobuf.IAdjustPresentationTimerMessage = {
        Base,
        MinutesToAdd: message.MinutesToAdd,
      };
      return response;
    }
    case PostMessageName.BackgroundMessage: {
      const response: IBV.Collaboard.Protobuf.IBackgroundMessage = {
        Base,
        BackgroundColor: message.BackgroundColor,
      };
      return response;
    }
    case PostMessageName.DeleteTileMessage: {
      const response: IBV.Collaboard.Protobuf.IDeleteTileMessage = {
        Base,
        TileIds: message.TileIds.map(toGuid).filter(isDefined),
      };
      return response;
    }
    case PostMessageName.GenericAvailableMessage: {
      const response: IBV.Collaboard.Protobuf.IGenericAvailableMessage = {
        Base,
        MessageType: message.MessageType,
        Payload: message.Payload,
        RecipientConnectionId: message.RecipientConnectionId,
        SenderConnectionId: message.SenderConnectionId,
      };
      return response;
    }
    case PostMessageName.InitialDataForNewJoinToPresentationMessage: {
      const response: IBV.Collaboard.Protobuf.IInitialDataForNewJoinToPresentationMessage = {
        Base,
        NewJoinerConnectionId: toStringValue(message.NewJoinerConnectionId),
        Presenter: toStringValue(message.Presenter),
        ScreenCoordinates: message.ScreenCoordinates,
        ZoomLevel: message.ZoomLevel,
      };
      return response;
    }
    case PostMessageName.InitialDataForNewJoinToTimerMessage: {
      const response: IBV.Collaboard.Protobuf.IInitialDataForNewJoinToTimerMessage = {
        Base,
        InitialDurationTimer: message.InitialDurationTimer,
        NewJoinerConnectionId: toStringValue(message.NewJoinerConnectionId),
        Presenter: toStringValue(message.Presenter),
        TimerPausedAt: message.TimerPausedAt,
        TimerStartedAt: message.TimerStartedAt,
      };
      return response;
    }
    case PostMessageName.LogInProjectMessage: {
      const response: IBV.Collaboard.Protobuf.ILogInProjectMessage = {
        Base: {
          ...Base,
          ProjectId: message.ProjectId ?? Base.ProjectId,
        },
      };
      return response;
    }
    case PostMessageName.LogOutProjectMessage: {
      const response: IBV.Collaboard.Protobuf.ILogOutProjectMessage = {
        Base,
      };
      return response;
    }
    case PostMessageName.NewMessage: {
      const response: IBV.Collaboard.Protobuf.INewMessage = {
        Base,
        Tiles: message.Tiles.map((tileStatus) => {
          return {
            AzureBlobStatus:
              tileStatus.AzureBlobStatus ?? blobStates.notOnAzure,
            BackgroundColor: toStringValue(tileStatus.BackgroundColor),
            Height: tileStatus.Height,
            IsPinned: tileStatus.IsPinned,
            LockedUser: toStringValue(tileStatus.LockedUser),
            OldTileId: toGuid(tileStatus.OldTileId),
            OriginalFileName: toStringValue(tileStatus.OriginalFileName),
            ParentId: toGuid(tileStatus.ParentId),
            PositionX: tileStatus.PositionX,
            PositionY: tileStatus.PositionY,
            ProjectId: tileStatus.ProjectId,
            Rotation: tileStatus.Rotation,
            ScaleX: tileStatus.ScaleX,
            ScaleY: tileStatus.ScaleY,
            TileContent: tileStatus.TileContent
              ? {
                  Data: struct.encode(tileStatus.TileContent),
                }
              : undefined,
            TileId: toGuid(tileStatus.TileId),
            TypeTile: tileStatus.TypeTile,
            Width: tileStatus.Width,
            ZIndex: tileStatus.ZIndex,
          };
        }),
      };
      return response;
    }
    case PostMessageName.PingbackMessage: {
      const response: IBV.Collaboard.Protobuf.IPingbackMessage = {
        Base,
      };
      return response;
    }
    case PostMessageName.PinMessage: {
      const response: IBV.Collaboard.Protobuf.IPinMessage = {
        Base,
        PinnedTiles: message.PinnedTiles.map((pinnedTile) => {
          return {
            Key: toGuid(pinnedTile.TileId),
            Value: pinnedTile.IsPinned,
          };
        }),
      };
      return response;
    }
    case PostMessageName.ProjectParticipantMessage: {
      const response: IBV.Collaboard.Protobuf.IProjectParticipantMessage = {
        Base,
        ParticipantUserName: message.ParticipantUserName,
        Permission: message.Permission,
      };
      return response;
    }
    case PostMessageName.ScreenPosZoomChangedMessage: {
      const response: IBV.Collaboard.Protobuf.IScreenPosZoomChangedMessage = {
        Base,
        ScreenCoordinates: message.ScreenCoordinates,
        ZoomLevel: message.ZoomLevel,
      };
      return response;
    }
    case PostMessageName.ThumbnailPageChangedMessage: {
      const response: IBV.Collaboard.Protobuf.IThumbnailPageChangedMessage = {
        Base,
        TileId: toGuid(message.TileId),
        NewPageNumber: message.NewPageNumber,
      };
      return response;
    }
    case PostMessageName.TileBatchActionMessage: {
      const response: IBV.Collaboard.Protobuf.ITileBatchActionMessage = {
        Base,
        Added: message.Added,
        Deleted: message.Deleted,
        Grouped: message.Grouped,
        Locked: message.Locked,
        Restored: message.Restored,
        RestoredRelationIds: message.RestoredRelationIds,
        Tiles: message.Tiles.map((tile) => {
          if (tile.Type === BatchTileType.Identity) {
            return {
              Id: toGuid(tile.Id),
              Type: tile.Type,
            };
          } else if (tile.Type === BatchTileType.Object) {
            return {
              Id: toGuid(tile.Id),
              Type: tile.Type,
              X: tile.X,
              Y: tile.Y,
              Z: tile.Z,
              Angle: toDoubleValue(tile.Angle ?? null),
              ParentId: toGuid(tile.ParentId ?? null),
            };
          }
          return {
            Id: toGuid(tile.Id),
            Type: tile.Type,
            X: tile.X,
            Y: tile.Y,
            Z: tile.Z,
            W: tile.W,
            H: tile.H,
          };
        }),
        Type: message.Type,
        Ungrouped: message.Ungrouped,
      };
      return response;
    }
    case PostMessageName.TileGroupMessage: {
      const response: IBV.Collaboard.Protobuf.ITileGroupMessage = {
        Base,
        GroupedTiles: message.GroupedTiles.map((tile) => {
          return {
            Height: tile.Height,
            ParentTileId: toGuid(tile.ParentTileId ?? null),
            PositionX: tile.PositionX,
            PositionY: tile.PositionY,
            Rotation: tile.Rotation,
            ScaleX: tile.ScaleX,
            ScaleY: tile.ScaleY,
            TileId: toGuid(tile.TileId),
            Width: tile.Width,
            ZIndex: tile.ZIndex,
          };
        }),
      };
      return response;
    }
    case PostMessageName.TileIndexesMessage: {
      const response: IBV.Collaboard.Protobuf.ITileIndexesMessage = {
        Base,
        TileIndexes: message.TileIndexes.map((tile) => {
          return {
            TileId: toGuid(tile.TileId),
            ZIndex: tile.ZIndex,
          };
        }),
      };
      return response;
    }
    case PostMessageName.TilePropertyMessage: {
      const response: IBV.Collaboard.Protobuf.ITilePropertyMessage = {
        Base,
        Deltas: message.Deltas.map((delta) => {
          return {
            Delta: delta.Delta,
            TileId: toGuid(delta.TileId),
          };
        }),
      };
      return response;
    }
    case PostMessageName.TileRelationMessage: {
      const response: IBV.Collaboard.Protobuf.ITileRelationMessage = {
        Base,
        Relation: toProtobufTileRelation(message.Relation),
        RelationDeleted: message.RelationDeleted,
      };
      return response;
    }
    case PostMessageName.UploadedMessage: {
      const response: IBV.Collaboard.Protobuf.IUploadedMessage = {
        Base,
        AzureBlobStatus: message.AzureBlobStatus,
        FileName: message.FileName,
        TileId: toGuid(message.TileId),
        TypeTile: message.TypeTile,
      };
      return response;
    }
  }

  return assertUnreachable(name, `Missing serializer for ${name}`);
};

const extractIncomingBaseMessage = ({
  message,
}: IncomingMessage): BaseMessage => {
  return {
    Base: {
      ProjectId: Number(message.Base?.ProjectId ?? 0),
      UniqueDeviceId: fromGuid(message.Base?.UniqueDeviceId) ?? "",
    },
  };
};

const toGuid = (value: string | null): IBV.Collaboard.Protobuf.IGuid | null => {
  return isString(value) ? { value } : null;
};

const fromGuid = (
  guid?: IBV.Collaboard.Protobuf.IGuid | null
): string | null => {
  return guid?.value ?? null;
};

/**
 * This protobuf type is used when the value is nullable.
 */
const toStringValue = (
  value: string | null
): google.protobuf.IStringValue | null => {
  return isString(value) ? { value } : null;
};

/**
 * This protobuf type is used when the value is nullable.
 */
const fromStringValue = (
  value?: google.protobuf.IStringValue | null
): string | null => {
  return value?.value ?? null;
};

/**
 * This protobuf type is used when the value is nullable.
 */
const toDoubleValue = (
  value: number | null
): google.protobuf.IDoubleValue | null => {
  return isNumber(value) ? { value } : null;
};

/**
 * This protobuf type is used when the value is nullable.
 */
const fromDoubleValue = (
  value?: google.protobuf.IDoubleValue | null
): number | null => {
  return value?.value ?? null;
};

const fromScreenCoordinates = (
  value?: IBV.Collaboard.Protobuf.IScreenCoordinates | null
): ScreenCoordinates => {
  return {
    Height: value?.Height ?? 0,
    Width: value?.Width ?? 0,
    X: value?.X ?? 0,
    Y: value?.Y ?? 0,
  };
};

const fromTimestamp = (value?: google.protobuf.ITimestamp | null): Date => {
  // Exclude Long type (only used by Node)
  const seconds = value?.seconds
    ? isNumber(value.seconds)
      ? value.seconds
      : 0
    : 0;
  const nanos = value?.nanos ?? 0;

  const secondsAsMs = seconds * 1000;
  const nanosAsMs = Math.floor(nanos / 1_000_000);

  return new Date(secondsAsMs + nanosAsMs);
};

const fromProtobufTileRelation = (
  relation?: IBV.Collaboard.Protobuf.ITileRelation | null
): TileRelation => {
  return {
    uuid: "",
    AnchorDestination: relation?.AnchorDestination ?? 0,
    AnchorOrigin: relation?.AnchorOrigin ?? 0,
    Color: relation?.Color ?? "",
    Id: relation?.Id ?? 0,
    Line: relation?.Line ?? 0,
    RelatedTileId: fromGuid(relation?.RelatedTileId) ?? "",
    Style: relation?.Style ?? 0,
    SymbolDestination: relation?.SymbolDestination ?? 0,
    SymbolOrigin: relation?.SymbolOrigin ?? 0,
    SyncId: fromGuid(relation?.SyncId) ?? "",
    Thickness: relation?.Thickness ?? 0,
    TileId: fromGuid(relation?.TileId) ?? "",
  };
};

const toProtobufTileRelation = (
  relation: TileRelation
): IBV.Collaboard.Protobuf.ITileRelation => {
  return {
    AnchorDestination: relation.AnchorDestination,
    AnchorOrigin: relation.AnchorOrigin,
    Color: relation.Color,
    Id: relation.Id, // When creating a relation the Id is not set
    Line: relation.Line,
    RelatedTileId: toGuid(relation.RelatedTileId),
    Style: relation.Style,
    SymbolDestination: relation.SymbolDestination,
    SymbolOrigin: relation.SymbolOrigin,
    SyncId: toGuid(relation.SyncId),
    Thickness: relation.Thickness,
    TileId: toGuid(relation.TileId),
  };
};
