import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Grid, SxProps, Text, ThemeUIStyleObject } from "theme-ui";
import Calendar from "../../../icons/Calendar";
import Person from "../../../icons/Person";
import Plans from "../../../icons/Plans";
import { getPlanInfoKeyById } from "../payment/helpers";

type Props = {
  header: string;
  data: {
    NumberOfUsers: number;
    ActiveTo: string;
    ProductId: ApiProductIds;
  };
  sx?: SxProps;
};

function SubscriptionDetailsBox({
  header,
  data,
  sx = {},
}: Props): ReactElement {
  const { t } = useTranslation();

  const licenseTranslationKey = getPlanInfoKeyById(data.ProductId);

  return (
    <>
      <Text sx={{ fontSize: [4], fontWeight: 500 }}>{header}</Text>

      <Grid
        sx={{
          ...sx,
          gridStyle,
        }}
      >
        <Flex className="subscription-details-box__col">
          <Plans noShadow />
          {licenseTranslationKey && (
            <Text sx={{ ml: [2] }}>{t(licenseTranslationKey)}</Text>
          )}
        </Flex>

        <Flex
          className="subscription-details-box__col"
          // Person icon is not following the same layout as other icons and needs some manual adjustments
          sx={{ svg: { height: "16px !important", width: "16px !important" } }}
        >
          <Person noShadow />
          <Text sx={{ ml: [2] }}>
            {t("dialog.codeRedemptionDetails.users")}: {data.NumberOfUsers}
          </Text>
        </Flex>

        <Flex className="subscription-details-box__col">
          <Calendar noShadow />
          <Text>
            {t("dialog.codeRedemptionDetails.activeTo")}:{" "}
            {t("dialog.codeRedemptionDetails.date", {
              date: new Date(data.ActiveTo),
            })}
          </Text>
        </Flex>
      </Grid>
    </>
  );
}

const gridStyle: ThemeUIStyleObject = {
  fontWeight: 700,
  gridTemplateColumns: "repeat(3, 1fr)",
  ".subscription-details-box__col": {
    svg: { height: "36px", width: "36px" },
    alignItems: "center",
  },
};

export default SubscriptionDetailsBox;
