import { fabric } from "fabric";
import { initialObjectHeight, initialObjectWidth } from "../../../const";

/**
 * This mixin allows use of the cache when zooming, which otherwise would
 * trigger a cache invalidation in fabric.
 */
export function zoomCacheMixin<T extends fabric.Object>(
  FabricPrototype: T
): Partial<fabric.Object> {
  const originalShouldCache = FabricPrototype.shouldCache;
  const originalIsCacheDirty = FabricPrototype.isCacheDirty;

  return {
    /**
     * This method is called by `render()` and also `isTargetTransparent`,
     * which means it is called VERY frequently (mousemove events trigger
     * transparency checks) so must be performant.
     *
     * @override
     */
    shouldCache(this: fabric.Object): boolean {
      if (!this.canvas) {
        return originalShouldCache.call(this);
      }

      // Avoid blurred strokes when zooming in an object
      const { x: width, y: height } = this._calculateCurrentDimensions();
      const isTooBig =
        width > initialObjectWidth * 5 || height > initialObjectHeight * 5;

      this.ownCaching =
        (this.canvas.isZoomInProgress() &&
          !isTooBig &&
          !shouldZoomDeltaInvalidateCache(this.canvas)) ||
        originalShouldCache.call(this);

      return this.ownCaching;
    },

    /**
     * This method is called by `renderCache()` to determine if an object's
     * cache needs to be updated.
     *
     * `shouldCache()` (above) determines whether or not `renderCache()` is
     * called.
     *
     * @override
     */
    isCacheDirty(this: fabric.Object, skipCanvas: boolean): boolean {
      if (!this.canvas) {
        return originalIsCacheDirty.call(this, skipCanvas);
      }

      return this.canvas.isZoomInProgress()
        ? !!this.dirty
        : originalIsCacheDirty.call(this, skipCanvas);
    },
  };
}

/**
 * Avoid rendering with the cache if the zoom delta is big, otherwise we are using
 * a cache where the object size difference is too big. This typically results
 * in blurred objects when zooming in and not crisp text when zooming out
 */
export const shouldZoomDeltaInvalidateCache = (
  canvas: fabric.CollaboardCanvas
): boolean => {
  const currentZoom = canvas.getZoom();
  const zoomDelta = Math.abs(currentZoom - canvas.__prevCanvasZoom);
  return zoomDelta > 1;
};
