import { ApiSubscriptionUserPermission } from "../../types/enum";
import { AuthAction, AuthActionType } from "../auth/auth.actions";
import {
  SubscriptionDetailsAction,
  SubscriptionDetailsActionType,
} from "./subscriptionDetails.actions";

export type SubscriptionDetailsState = {
  product: ApiProduct | null;
  subscription: ApiSubscription | null;
  summary: {
    userCount?: number;
    deviceCount?: number;
    maxUsers?: number;
    maxDevices?: number;
    nextBillingDate?: string | null;
    permission?: ApiSubscriptionUserPermission;
  };
};

const getInitialSubscriptionDetailsState = (): SubscriptionDetailsState => {
  return {
    subscription: null,
    product: null,
    summary: {},
  };
};

export function subscriptionDetailsReducer(
  state = getInitialSubscriptionDetailsState(),
  action: SubscriptionDetailsAction | AuthAction
): SubscriptionDetailsState {
  switch (action.type) {
    case SubscriptionDetailsActionType.GET_SUBSCRIPTION_DONE: {
      const { payload } = action;
      return payload
        ? {
            subscription: payload.Subscription,
            product: payload.Product,
            summary: {
              userCount: payload.CurrentNumberOfUsers,
              deviceCount: payload.CurrentNumberOfDevices,
              maxUsers: payload.Subscription?.NumberOfUsers,
              maxDevices: payload.Subscription?.NumberOfDevices,
              nextBillingDate: payload.NextBillingDate ?? null,
              permission: payload.Permission,
            },
          }
        : {
            subscription: null,
            product: null,
            summary: {},
          };
    }
    case AuthActionType.LOGGED_OUT:
      return getInitialSubscriptionDetailsState();
    default:
      return state;
  }
}

export const selectSubscriptionDetails = (
  state: ApplicationGlobalState
): SubscriptionDetailsState => {
  return state.subscriptionDetails;
};
