import { merge } from "theme-ui";

import { runtimeConfig } from "../tools/runtimeConfig";
import defaultTheme, { DefaultTheme } from "./default";

// TODO: #6214 - Remove this and move the configuration to server

const collinsTheme = merge(defaultTheme, {
  name: "collins",
  colors: {
    accent: "#e4551f",
    active: "#e4551f",
    primary: "#e4551f",
    accentAlternate: "#c44f21",
    accentDimmed: "#ffc4b3",
    accentSecondary: "#fb7c57",
    accentSecondaryAlternate: "#e9592f",
    accentSecondaryDimmed: "#ffe4dc",
    disclaimerBg: "#f2a900",
    disclaimerText: "#000000",
    ...runtimeConfig.styles?.colors,
  },
  layout: {
    // removes floating (fixed) logos
    formsWrap: {
      "&:before": undefined,
      "&:after": undefined,
    },
  },
}) as DefaultTheme;

export default collinsTheme;
