import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Lock(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M24.61,17.05H22.8V14.79a2.63,2.63,0,1,0-5.25,0V17H15.74V14.79a4.6,4.6,0,0,1,4.44-4.74,4.6,4.6,0,0,1,4.43,4.74Z" />
      <path d="M24.6,18.33h-9a1.54,1.54,0,0,0-1.49,1.59v8.54a1.54,1.54,0,0,0,1.49,1.59h9a1.54,1.54,0,0,0,1.5-1.59V19.92A1.54,1.54,0,0,0,24.6,18.33ZM21,26.56H19l.51-2.71a1.36,1.36,0,0,1-.72-1.21,1.26,1.26,0,1,1,2.51,0,1.36,1.36,0,0,1-.72,1.21Z" />
    </Icon>
  );
}
export default Lock;
