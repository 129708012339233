import { ThemeUIStyleObject } from "theme-ui";

const optionsDivider: ThemeUIStyleObject = {
  position: "absolute",
  left: "0.5px",
  top: "12.5%",
  height: "75%",
  content: '" "',
  borderLeft: "1px solid",
  borderColor: "var(--toolbarBorder)",
};

const noOptionsDivider: ThemeUIStyleObject = {
  borderLeft: "none",
};

const buttons: Record<string, ThemeUIStyleObject> = {
  // buttons in the board
  canvasTool: {
    alignItems: "center",
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
    display: "flex",
    margin: 0,
    padding: 0,
    pointerEvents: "auto",
    position: "relative",
    "&::after": {
      border: "2px solid transparent",
      content: '" "',
      display: "block",
      height: "100%",
      position: "absolute",
      width: "100%",
    },
    "&:active, &:active:hover": {
      background: "none",
      backgroundColor: "accent",
      color: "text",
      fill: "text",
      "&:after": {
        borderColor: "transparent",
      },
    },
    "&:disabled": {
      pointerEvents: "none",
      cursor: "default",
      "> svg": {
        fill: "var(--iconColor__disabled)",
      },
    },
    "&:focus": {
      outline: "none",
    },
  },
  canvasOptionListItem: {
    borderRadius: 0,
    fontFamily: "body",
    fontSize: "var(--dynamicFontSize)",
    fontWeight: "300",
    height: "var(--gridTile)",
    overflow: "hidden",
    position: "relative",
    textAlign: "left",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
    "&::after": {
      border: "2px solid transparent",
      content: '" "',
      display: "block",
      left: 0,
      height: "100%",
      pointerEvents: "none",
      position: "absolute",
      top: 0,
      width: "100%",
    },
    "&:hover": {
      "&:after": {
        bg: "transparent",
      },
    },
    "&:active, &:active:hover": {
      background: "none",
      backgroundColor: "accent",
      color: "text",
      fill: "text",
      "&:after": {
        borderColor: "transparent",
      },
    },
  },
  canvasOptionTool: {
    alignItems: "center",
    border: "none",
    borderRadius: 0,
    display: "flex",
    margin: 0,
    outline: "none",
    padding: 0,
    pointerEvents: "auto",
    position: "relative",
    justifyContent: "space-between",
    minWidth: "auto",
    "&::after": {
      border: "2px solid transparent",
      content: '" "',
      display: "block",
      height: "100%",
      position: "absolute",
      width: "100%",
    },
    "> label": {
      minWidth: "calc(var(--gridTile) * 2)",
      maxWidth: "calc(var(--gridTile) * 3)",
    },
    "> svg": {
      filter: "none",
    },
    "&:hover": {
      "&:after": {
        bg: "transparent",
        border: "2px solid var(--contextActiveColor)",
      },
    },
    "&:active, &:active:hover": {
      background: "none",
      backgroundColor: "accent",
      color: "text",
      fill: "text",
      "&:after": {
        borderColor: "transparent",
      },
    },
    "&:disabled": {
      pointerEvents: "none",
    },
  },
  optionButton: {
    position: "relative",
    flexDirection: "column",
    "&:after": optionsDivider,
    "&:first-of-type:after": noOptionsDivider, // hide the first options divider
  },
  optionButtonNoDivider: {
    variant: "buttons.optionButton",
    "&:after": noOptionsDivider,
  },
  optionButtonBoldDivider: {
    variant: "buttons.optionButton",
    "&:after": {
      ...optionsDivider,
      borderColor: "var(--toolbarDivider)",
    },
  },
  optionButtonSelected: {
    variant: "buttons.optionButton",
    "&:after": noOptionsDivider, // hide divider of active option
    "& + div:after": noOptionsDivider, // hide divider next to active option
  },
  canvasToolbar: {
    alignItems: "center",
    borderRadius: 0,
    border: "none",
    cursor: "pointer",
    display: "flex",
    flex: 1,
    margin: 0,
    outline: "none",
    padding: 0,
    pointerEvents: "auto",
    position: "relative",
    width: "calc(var(--gridTile) * 3)",
    "&:disabled": {
      pointerEvents: "none",
      cursor: "default",
    },
    "> svg": {
      height: "var(--gridTile)",
      position: "relative",
      width: "var(--gridTile)",
    },
  },
  usersPanelButton: {
    cursor: "pointer",
    height: "auto",
    pointerEvents: "auto",
    width: "auto",
    padding: 0,
    borderRadius: "50%",
    boxShadow: "var(--controlShadow)",
    "> svg": {
      fill: "var(--iconColor)",
    },
  },
  switchButton: {
    fontSize: [1],
    borderRadius: 0,
    border: "1px solid var(--backgroundColor)",
    borderRightWidth: 0,
    bg: "transparent",
    color: "var(--backgroundColor)",
    appearance: "none",
    py: [1],
    lineHeight: "16px",
    flex: 1,
    "&:last-child": {
      borderRightWidth: "1px",
    },
    "&:hover": {
      color: "var(--textColor)",
      backgroundColor: "var(--backgroundColor)",
    },
    "&:active": {
      borderColor: "accent",
      backgroundColor: "accent",
      color: "text",
    },
  },

  // buttons in react app
  contentProjectList: {
    pointerEvents: "auto",

    backgroundColor: "transparent",
    cursor: "pointer",
    flex: "unset",
    padding: 0,
    margin: 0,
    border: 0,

    "&[disabled]": {
      pointerEvents: "none", // mouseLeave is not triggered without this line (tooltip doesn't vanish)
      "> svg": {
        fill: "var(--buttonDisabledBackgroundText)",
      },
    },

    "&:hover, &:active:hover": {
      svg: { fill: "primary" },
    },
  },

  contentProjectListExpanded: {
    variant: "buttons.contentProjectList",
    "&:hover, &:active:hover": {
      svg: { fill: "white" },
    },
  },

  vanilla: {
    borderRadius: 0,
  },
  primary: {
    backgroundColor: "accent",
    borderColor: "accent",
    overflow: "hidden",
    borderRadius: 0,
    borderStyle: "solid",
    borderWidth: "1px",
    color: "black",
    cursor: "pointer",
    fontFamily: "Segoe UI - Semibold",
    fontSize: [4],
    fontWeight: "600",
    textDecoration: "none",
    textAlign: "center",
    px: [4],
    py: [2],
    transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    alignItems: "center",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    lineHeight: [1.2],
    minHeight: [40],
    outline: "none",
    "&:hover": {
      bg: "accentAlternate",
      borderColor: "accentAlternate",
      svg: {
        fill: "text",
      },
    },
    "&:focus": {
      outline: "none",
      borderColor: "accentAlternate",
    },
    "&:focus:hover": {
      bg: "accentAlternate",
    },
    "&:active, &:active:hover": {
      backgroundColor: "accentDimmed",
      color: "black",
      borderColor: "accent",
    },
    "&[disabled], &[disabled]:active:hover": {
      cursor: "default",
      backgroundColor: "var(--buttonDisabledBackground)",
      borderColor: "var(--buttonDisabledBackground)",
      color: "var(--buttonDisabledBackgroundText)",
    },
    "&:visited": {
      color: "black",
    },
    svg: {
      width: 20,
      height: 20,
      fill: "primary",
    },
  },
  secondary: {
    variant: "buttons.primary",
    backgroundColor: "accentSecondary",
    borderColor: "accentSecondary",
    "&:hover": {
      bg: "accentSecondaryAlternate",
      borderColor: "accentSecondaryAlternate",
      svg: {
        fill: "text",
      },
    },
    "&:focus": {
      outline: "none",
      borderColor: "accentSecondaryAlternate",
    },
    "&:focus:hover": {
      bg: "accentSecondaryAlternate",
    },
    "&:active, &:active:hover": {
      backgroundColor: "accentSecondaryDimmed",
      color: "black",
      borderColor: "accentSecondary",
    },
    "&[disabled], &[disabled]:active:hover": {
      cursor: "default",
      backgroundColor: "var(--buttonSecondaryDisabledBackground)",
      borderColor: "var(--buttonSecondaryDisabledBackground)",
      color: "var(--buttonSecondaryDisabledBackgroundText)",
    },
  },
  warning: {
    backgroundColor: "warning",
    borderColor: "warning",
    overflow: "hidden",
    borderRadius: 0,
    borderStyle: "solid",
    borderWidth: "1px",
    color: "black",
    cursor: "pointer",
    fontFamily: "Segoe UI - Semibold",
    fontSize: [4],
    fontWeight: "600",
    px: [4],
    py: [2],
    transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    alignItems: "center",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    lineHeight: [1.2],
    minHeight: [40],
    outline: "none",
    "&:hover": {
      bg: "warningAlternate",
      borderColor: "warningAlternate",
    },
    "&:focus": {
      outline: "none",
      borderColor: "warningAlternate",
    },
    "&:focus:hover": {
      bg: "warningAlternate",
    },
    "&:active, &:active:hover": {
      backgroundColor: "transparent",
      borderColor: "warning",
      color: "warning",
    },
    "&[disabled], &[disabled]:active:hover": {
      backgroundColor: "muted",
      borderColor: "muted",
      color: "var(--textColor)",
    },
    svg: {
      width: 20,
      height: 20,
      fill: "primary",
    },
  },
  cancel: {
    backgroundColor: "secondary",
    borderColor: "secondary",
    overflow: "hidden",
    borderRadius: 0,
    borderStyle: "solid",
    borderWidth: "1px",
    color: "var(--greyTextColor)",
    cursor: "pointer",
    fontFamily: "Segoe UI - Semibold",
    fontSize: 4,
    fontWeight: "600",
    px: [5],
    py: "11px",
    transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "&:hover": {
      bg: "muted",
      borderColor: "muted",
    },
    "&:focus": {
      outline: "none",
      borderColor: "muted",
    },
    "&:focus:hover": {
      bg: "muted",
    },
    "&:active": {
      backgroundColor: "transparent",
      borderColor: "muted",
    },
    "&:active:hover": {
      backgroundColor: "transparent",
      borderColor: "muted",
    },
  },
  inverted: {
    variant: "buttons.primary",
    fontWeight: 400,
    backgroundColor: "background",
    color: "primary",
    "&:hover": {
      color: "background",
      backgroundColor: "accentAlternate",
      borderColor: "background",
    },
  },
  dropdownItem: {
    alignItems: "center",
    bg: "transparent",
    borderRadius: "0",
    color: "text",
    cursor: "pointer",
    display: "flex",
    fontSize: [4, 5],
    fontWeight: "300",
    px: [3],
    py: [4],
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      bg: "secondary",
    },
    "&:active": {
      bg: "accent",
    },
    "&:hover:active": {
      bg: "accent",
    },
    "> img ": {
      mr: [3],
    },
  },
  profile: {
    variant: "buttons.primary",
    flex: "1 0 100%",
    fontSize: [4, 5],
    mb: [2],
    ml: [0, 1],
  },
  icon: {
    action: {
      background: "transparent",
      borderRadius: 0,
      color: "inherit",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 0,
      overflow: "hidden",
      outline: "none",
      // Prevent sticky hover on tablets
      "@media (hover: hover)": {
        transition: "background 0.15s",
        "&:hover": {
          backgroundColor: "accent",
        },
      },
      "&:active:hover": {
        backgroundColor: "accentSecondary",
        ".dark &": {
          backgroundColor: "accentAlternate",
        },
      },
      "&:disabled, &[disabled]": {
        "&, .dark &": {
          backgroundColor: "transparent",
          color: "disabledDark",
          cursor: "default",
          svg: {
            fill: "disabledDark",
          },
        },
      },
    },
    togglePasswordVisibility: {
      height: "100%",
      outline: "none",
      position: "absolute",
      width: "auto",
      right: [2],
      top: 0,
      fill: "muted",
      cursor: "pointer",
      "&:focus": { outline: "none", fill: "accentAlternate" },
      "&:hover": { outline: "none" },
    },
    clearInput: {
      position: "absolute",
      backgroundColor: "transparent",
      right: [2],
      top: 0,
      height: "100%",
      fill: "muted",
      cursor: "pointer",
      "&:focus, &:hover": { outline: "none", fill: "accentAlternate" },
      "> svg": {
        height: "1rem",
        width: "1rem",
        fill: "var(--inputPlaceholder)",
      },
    },
  },
  social: {
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "flex-start",
    bg: "background",
    borderColor: "primary",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: 0,
    color: "text",
    display: "flex",
    flex: 1,
    height: "3.75rem",
    "&:hover": {
      bg: "accent",
      borderColor: "accent",
      color: "background",
    },
    "> img": {
      mr: [4],
    },
  },
  content: {
    cursor: "pointer",
    color: "var(--textColor)",
    bg: "transparent",
    padding: 0,
    margin: 0,
    border: 0,
    "&:hover": {
      svg: {
        fill: "primary",
      },
    },
    "&:active:hover": {
      svg: {
        fill: "accent",
      },
    },
  },
  toggleWrapper: {
    bg: "transparent",
    color: "var(--textColor)",
    cursor: "pointer",
    display: "block",
    m: [0],
    p: [2],
    width: "calc(var(--gridTile) * 2)",
    "&:hover": {
      svg: {
        fill: "var(--textColor)",
      },
    },
    "&:active:hover": {
      svg: {
        fill: "var(--textColor)",
      },
    },
  },
  link: {
    variant: "buttons.content",
    color: "text",
    cursor: "pointer",
    display: "inline-block",
    textDecoration: "underline",
  },
  linkWithIcon: {
    variant: "buttons.link",
    display: "flex",
    alignItems: "center",
    transition: "all 0.15s",
    svg: {
      transition: "fill 0.15s",
    },
    "&[disabled]": {
      color: "disabled",
      cursor: "default",
      svg: {
        cursor: "default",
        fill: "disabled",
      },
      "&:hover": {
        svg: { fill: "disabled" },
      },
    },
  },
  canvasLinkWithIcon: {
    variant: "buttons.linkWithIcon",
    svg: {
      fill: "var(--iconColor)",
    },
    "&:hover": {
      svg: { fill: "var(--buttonDisabledBackgroundText)" },
    },
    "&[disabled]": {
      cursor: "default",
      svg: {
        cursor: "default",
        fill: "var(--iconColor__disabled)",
      },
    },
  },
  circleIcon: {
    bg: "transparent",
    fontSize: "1rem",
    display: "flex",
    alignItems: "center",
    marginLeft: "1em",
    cursor: "pointer",
    "> svg": {
      bg: "delicate",
      borderRadius: "50%",
      marginRight: "0.5em",
    },
    "> div": {
      display: ["none", "block"],
    },
  },
  confirmation: {
    backgroundColor: "background",
    color: "text",
    cursor: "pointer",
    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    border: "1px solid var(--confirmationColor)",
    borderRadius: "0",
    flex: 1,
    fontSize: [2],
    height: "100%",
    padding: 0,
    margin: 0,
    outline: "none",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    userSelect: "none",
    "&:active": {
      bg: "var(--confirmationColor)",
      color: "background",
      borderColor: "background",
    },
    "&:focus": {
      outline: "none",
    },
    "&:focus:hover": {
      bg: "var(--confirmationColor)",
      borderColor: "background",
      color: "background",
    },
    "&:hover": {
      bg: "secondary",
    },
    "&:disabled": {
      bg: "muted",
      borderColor: "muted",
      color: "secondary",
    },
  },
  modalHeaderIconButton: {
    bg: "transparent",
    cursor: "pointer",
    padding: 0,
    alignSelf: "flex-start",
    marginLeft: [2],
    svg: {
      width: "22px",
      height: "22px",
    },
  },
  radioButtonBase: {
    transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: 0,
    flex: 1,
    borderColor: "primary",
    borderWidth: 1,
    borderStyle: "solid",
    minHeight: [40],
    cursor: "pointer",
    "& + &": {
      borderLeft: 0,
    },
    "&:disabled, &[disabled]": {
      cursor: "default",
    },
  },
  radioButton: {
    variant: "buttons.radioButtonBase",
    backgroundColor: "var(--buttonToggleBackground__off)",
    borderColor: "var(--buttonToggleBackground__on)",
    color: "var(--buttonToggleColor__off)",
    fontSize: [4],
    "&:hover": {
      bg: "var(--buttonToggleBackground__hovered)",
      color: "var(--buttonToggleColor__hovered)",
    },
    svg: {
      fill: "var(--buttonToggleColor__off)",
      transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
  },
  radioButtonActive: {
    variant: "buttons.radioButton",
    cursor: "default",
    backgroundColor: "var(--buttonToggleBackground__on)",
    borderColor: "var(--buttonToggleBackground__on)",
    color: "var(--buttonToggleColor__on)",
    "&:hover": {
      bg: "var(--buttonToggleBackground__on)",
    },
    svg: {
      fill: "var(--buttonToggleColor__on)",
      transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
  },
  sidePanel: {
    radioButton: {
      variant: "buttons.radioButton",
      fontSize: "calc(var(--staticGridTile) * 0.375)",
      height: "var(--staticGridTile)",
    },
    radioButtonActive: {
      variant: "buttons.radioButtonActive",
      fontSize: "calc(var(--staticGridTile) * 0.375)",
      height: "var(--staticGridTile)",
    },
    confirmation: {
      variant: "buttons.confirmation",
      fontSize: "calc(var(--staticGridTile) * 0.375)",
    },
  },
  toolbarRedock: {
    pointerEvents: "auto",
    bg: "var(--iconColor)",
    fill: "var(--iconColor__active)",
    padding: 0,
    borderRadius: 0,
    cursor: "pointer",
    width: "calc(var(--gridTile) / 3)",
    overflow: "hidden",
    svg: {
      display: "block",
      ml: "-100%",
    },
    "&:hover": {
      bg: "var(--accent)",
      fill: "var(--iconColor)",
    },
  },
  toolbarRedockMuted: {
    variant: "buttons.toolbarRedock",
    bg: "secondary",
    fill: "muted",
    "&:hover": {
      bg: "var(--accent)",
      fill: "var(--iconColor)",
    },
  },
  toolbarRedockMutedOver: {
    variant: "buttons.toolbarRedockMuted",
    animation: "background-swing-animation 0.75s ease infinite",
    background: "linear-gradient(90deg, var(--accent), #fff, var(--accent))",
    backgroundSize: "400% 400%",
    fill: "var(--iconColor)",
  },
  toolbarUndock: {
    variant: "buttons.toolbarRedock",
    "&:hover": {
      bg: "var(--accent)",
      fill: "var(--iconColor)",
      cursor: "move",
    },
  },
  draggableWindowIcon: {
    bg: "transparent",
    padding: 0,
    position: "absolute",
    border: 0,
  },
  draggableWindowBottomControl: {
    variant: "buttons.draggableWindowIcon",
    position: "relative",
    cursor: "pointer",
  },
  draggableWindowResize: {
    variant: "buttons.draggableWindowIcon",
    svg: {
      width: "40px",
      height: "40px",
      verticalAlign: "top",
      fill: "var(--iconColor)",
    },
  },
};

export default buttons;
