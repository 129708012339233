import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Heart(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M29.93,13.91a4.94,4.94,0,0,0-4.6-2.9,5.11,5.11,0,0,0-4.3,2.9c-.2.4-.6,1.4-.6,1.4a6.87,6.87,0,0,0-.6-1.3,4.82,4.82,0,0,0-5.2-2.9,4.72,4.72,0,0,0-2.5,1.1c-2.3,2-2.2,4.8,0,7.8s8.2,11,8.2,11,6.3-8,8.5-11C30.63,17.81,30.83,15.91,29.93,13.91Z" />
    </Icon>
  );
}
export default Heart;
