import { BrushType } from "../../../const";

export enum DrawingActionType {
  SET_DRAWING_BRUSH_TYPE = "SET_DRAWING_BRUSH_TYPE",
  SET_DRAWING_COLOR = "SET_DRAWING_COLOR",
  SET_DRAWING_STROKE_WIDTH = "SET_DRAWING_STROKE_WIDTH",
  SET_DRAWING_SUBMENU = "SET_DRAWING_SUBMENU",
}

export const setDrawingBrushTypeAction = (payload: BrushType) =>
  ({
    type: DrawingActionType.SET_DRAWING_BRUSH_TYPE,
    payload,
  } as const);

export const setDrawingColorAction = (fillColor: string) =>
  ({
    type: DrawingActionType.SET_DRAWING_COLOR,
    payload: fillColor,
  } as const);

export const setDrawingStrokeWidthAction = (strokeWidth: number) =>
  ({
    type: DrawingActionType.SET_DRAWING_STROKE_WIDTH,
    payload: strokeWidth,
  } as const);

export const setDrawingSubmenuAction = (id: string | null) =>
  ({
    type: DrawingActionType.SET_DRAWING_SUBMENU,
    payload: id,
  } as const);

export type DrawingAction =
  | ReturnType<typeof setDrawingBrushTypeAction>
  | ReturnType<typeof setDrawingColorAction>
  | ReturnType<typeof setDrawingStrokeWidthAction>
  | ReturnType<typeof setDrawingSubmenuAction>;
