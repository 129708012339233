import React, { ReactElement } from "react";
import { Helmet } from "react-helmet";

import { getAppName } from "../../tools/customer";

type Props = {
  title: string;
};

function Title({ title }: Props): ReactElement {
  return (
    <Helmet>
      <title>{title ? `${title} - ${getAppName()}` : getAppName()}</title>
    </Helmet>
  );
}

export default Title;
