import { Form, Formik, FormikConfig } from "formik";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import { Flex, Image, Text, ThemeUIStyleObject } from "theme-ui";
import { boolean, object, string } from "yup";
import { routes } from "../../../const";
import { getAppName } from "../../../tools/customer";
import {
  isStaticFeatureActive,
  staticFeatureFlags,
} from "../../../tools/flags";
import { LogCategory } from "../../../tools/telemetry";
import { stringifyQueryUrl } from "../../../tools/url";
import { mergeSchemas } from "../../../validationSchemas/helpers";
import EmailVerificationModalTemplate from "../../authentication/login/EmailVerificationModalTemplate";
import {
  RegistrationFormValues,
  useAuthentication,
} from "../../authentication/useAuthentication";
import { useLoggedInStatus } from "../../authentication/useLoggedInStatus";
import RedemptionDetailsModalTemplate from "../../licensing/RedemptionDetailsModalTemplate";
import { useRedemptionDetails } from "../../licensing/RedemptionDetailsModalTemplate/useRedemptionDetails";
import CheckboxField from "../../shared/forms/fields/CheckboxField";
import InputField from "../../shared/forms/fields/InputField";
import PasswordField from "../../shared/forms/fields/PasswordField";
import ProcessingButton from "../../shared/forms/ProcessingButton";
import { useErrorHandler } from "../../shared/hooks/useErrorHandler";
import Page from "../layout/pages/Page";

const isToSDisabled = isStaticFeatureActive(
  staticFeatureFlags.DISABLE_REGISTRATION_TOS
);

type RegisterWithCodeParams = RegistrationFormValues & {
  Code: string;
};

type Props = {
  name: string;
  label: string;
  logoSx?: ThemeUIStyleObject;
};

function CodeRedemptionLanding({ name, label, logoSx }: Props): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    isLoggedOut,
    isLoggedInAsUser,
    isLoggedInAsGuest,
    userProfile,
    logout,
  } = useLoggedInStatus();
  const { code: codeFromUrl } = useParams<CodeRedemptionRouteParams>();
  const {
    redemptionDetailsModalProps,
    redemptionDetailsData,
    displayRedemptionDetailsModal,
  } = useRedemptionDetails(label);

  const [RegisteredUserName, setRegisteredUserName] = useState<
    string | undefined
  >(undefined);
  const [Password, setPassword] = useState<string | undefined>(undefined);

  const {
    profileSchema,
    credentialsSchema,
    emailVerificationModal,
    register,
    autoLogin,
  } = useAuthentication();
  const { fallbackErrorHandler } = useErrorHandler();

  const queryString = stringifyQueryUrl<AnyRouteUrlQuery>({
    redirect: `${name}/${codeFromUrl}`,
  });

  const UserName = isLoggedInAsUser ? userProfile?.UserName : undefined;

  const codeSchema = object().shape({
    UserName: string().required(t("clientError.required")),
    Code: string().required(t("clientError.required")),
    TermsOfServiceAccepted: boolean()
      .required(t("clientError.required"))
      .oneOf([true], t("clientError.mustAcceptTermsOfService")),
  });

  useEffect(() => {
    if (isLoggedInAsGuest) {
      logout();
    }
  }, [isLoggedInAsGuest, logout]);

  const validationSchema = useMemo(() => {
    if (isLoggedInAsUser) {
      return codeSchema;
    }
    return mergeSchemas(profileSchema, credentialsSchema, codeSchema);
  }, [codeSchema, credentialsSchema, isLoggedInAsUser, profileSchema]);

  const formikProps: FormikConfig<RegisterWithCodeParams> = {
    initialValues: {
      UserName: UserName ?? "",
      Password: "",
      FirstName: "",
      LastName: "",
      TermsOfServiceAccepted: isToSDisabled || false,
      Code: codeFromUrl,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (isLoggedInAsUser) {
        displayRedemptionDetailsModal(values);
        return;
      }

      // Store values for auto-login
      setRegisteredUserName(values.UserName);
      setPassword(values.Password);

      try {
        await register({ values, bypassEmailVerification: false });
      } catch (error) {
        fallbackErrorHandler(
          error,
          "Failed to register from code redemption",
          LogCategory.auth
        );
      }
    },
    validationSchema,
    validateOnMount: true,
  };

  /**
   * Log the newly registered user in.
   *
   * This will simply update their logged in status and the form will change
   * allowing them to redeem the offer. We don't need to send the code anywhere
   * until they are logged in.
   */
  const onEmailVerifySuccess = useCallback(async () => {
    if (RegisteredUserName && Password) {
      try {
        await autoLogin({ UserName: RegisteredUserName, Password });
      } catch (error) {
        fallbackErrorHandler(
          error,
          "Failed to auto-login from code redemption",
          LogCategory.auth
        );
        history.push(`${routes.authenticate}${queryString}`);
      }
      emailVerificationModal.closeOnNextTick();
    }
  }, [
    Password,
    RegisteredUserName,
    autoLogin,
    emailVerificationModal,
    fallbackErrorHandler,
    history,
    queryString,
  ]);

  const onEmailVerifyError = useCallback(
    (error: unknown) =>
      fallbackErrorHandler(error, "Failed to verify email", LogCategory.auth),
    [fallbackErrorHandler]
  );

  return (
    <Page>
      <Image
        src={`/${name}-logo.svg`}
        alt={label}
        sx={{ width: "100%", ...logoSx }}
      />

      <Formik {...formikProps}>
        {({ isSubmitting, isValid, values }) => {
          return (
            <>
              {isLoggedOut && (
                <Text sx={{ mb: [4] }}>
                  <Trans
                    i18nKey={`form.text.haveAccount${label}`}
                    values={{ appName: getAppName() }}
                  >
                    <Link to={`${routes.authenticate}${queryString}`} />
                  </Trans>
                </Text>
              )}

              <Form>
                <Flex as="fieldset">
                  <InputField
                    required={isLoggedOut}
                    name="UserName"
                    readOnly={isLoggedInAsUser}
                    disabled={isLoggedInAsUser}
                  />
                  {isLoggedOut && (
                    <>
                      <PasswordField
                        name="Password"
                        autoComplete="new-password"
                        sx={{ display: "block" }}
                      />
                      <InputField
                        required
                        name="FirstName"
                        autoComplete="given-name"
                      />
                      <InputField
                        required
                        name="LastName"
                        autoComplete="family-name"
                      />
                    </>
                  )}

                  <Text sx={{ mb: [2] }}>
                    {t(`form.label.${label}Code`, {
                      appName: getAppName(),
                    })}
                  </Text>
                  <InputField
                    required
                    name="Code"
                    placeholder={t(`form.placeholder.${label}Code`, {
                      appName: getAppName(),
                    })}
                  />

                  {!isToSDisabled && (
                    <CheckboxField
                      required
                      name="TermsOfServiceAccepted"
                      sx={{ mt: [2] }}
                    >
                      <Trans
                        i18nKey="form.label.TermsOfServiceAccepted"
                        values={{ appName: getAppName() }}
                      >
                        {/* eslint-disable jsx-a11y/anchor-has-content */}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.collaboard.app/terms"
                        />
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.collaboard.app/privacy"
                        />
                      </Trans>
                    </CheckboxField>
                  )}
                </Flex>

                <Flex
                  sx={{
                    alignItems: "center",
                    flex: "1 0 100%",
                    flexWrap: "wrap",
                    justifyContent: "flex-end",
                    my: [3],
                  }}
                >
                  <ProcessingButton
                    isProcessing={isSubmitting}
                    type="submit"
                    sx={{ width: "40%", flex: "unset" }}
                    disabled={!isValid || isSubmitting}
                  >
                    {t("form.button.redeem")}
                  </ProcessingButton>
                </Flex>
              </Form>
            </>
          );
        }}
      </Formik>

      {RegisteredUserName && (
        <EmailVerificationModalTemplate
          UserName={RegisteredUserName}
          modalProps={emailVerificationModal}
          onSuccess={onEmailVerifySuccess}
          onError={onEmailVerifyError}
        />
      )}
      {redemptionDetailsData && (
        <RedemptionDetailsModalTemplate
          modalProps={redemptionDetailsModalProps}
          {...redemptionDetailsData}
        />
      )}
    </Page>
  );
}

export default CodeRedemptionLanding;
