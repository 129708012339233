import React, { ReactElement } from "react";
import { Flex, ThemeUIStyleObject } from "theme-ui";
import LoadingSpinner from "../../../icons/LoadingSpinner";

function ContainedSpinner({
  children,
  size = 50,
}: {
  children?: ReactElement;
  size?: number;
}): ReactElement {
  return (
    <Flex
      data-testid="contained-spinner"
      sx={{
        ...containerStyle,
        svg: {
          height: `${size}px`,
          width: `${size}px`,
        },
      }}
    >
      <LoadingSpinner />
      {children && <div>{children}</div>}
    </Flex>
  );
}

const containerStyle: ThemeUIStyleObject = {
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  height: "100%",
  width: "100%",
  top: 0,
  left: 0,
  backgroundColor: "rgba(255,255,255,0.75)",
  zIndex: 2,
};

export default ContainedSpinner;
