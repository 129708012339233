import React, { ReactElement, Suspense } from "react";
import { DndProvider } from "react-dnd";
// TODO: #6733 Add touch support
import { HTML5Backend } from "react-dnd-html5-backend";
import { ReactQueryConfigProvider } from "react-query";
import { Provider } from "react-redux";
import AppThemeProvider from "./features/app/AppThemeProvider";
import FullPageSpinner from "./features/shared/spinners/FullPageSpinner";
import { initializeLocales } from "./i18n";
import "./polyfills";
import { reduxStore } from "./reduxStore";
import AppRoutes from "./Routes";
import { initializeEnv } from "./tools";
import { detectAndInitializeMSTeams } from "./tools/msTeams";
import { runtimeConfig } from "./tools/runtimeConfig";
import { getVersionInfo } from "./tools/versionInfo";

// Validate webRTC config to throw immediately if it's wrong
const { webRTC } = runtimeConfig;
if (!webRTC.stunTurnUrl || !webRTC.username || !webRTC.credential) {
  throw new Error("Invalid WebRTC configuration in runtimeConfig");
}

initializeEnv(); // do some stuff depends on dev/prod env or screen capture
initializeLocales();
window.version = getVersionInfo();

detectAndInitializeMSTeams();

const queryConfig = {
  queries: {
    refetchOnWindowFocus: false,
  },
};

function CollaBoardApp(): ReactElement {
  return (
    <Suspense fallback={<FullPageSpinner />}>
      {/**
       * It seems there are two warnings with StrictMode:
       * - UNSAFE_componentWillMount, which from a search in node_modules seems to be caused by rc-slider
       * - findDOMNode, by Toast
       *
       * @NOTE Be aware that StrictMode has two important behaviours **in DEV mode**:
       * - Expected pure functions like render, useState(fn) and useReducer are ran twice: https://reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects
       * - Effects are destroyed and created on mount: https://reactjs.org/docs/strict-mode.html#ensuring-reusable-state
       */}
      <React.StrictMode>
        <Provider store={reduxStore}>
          <AppThemeProvider>
            <ReactQueryConfigProvider config={queryConfig}>
              <DndProvider backend={HTML5Backend}>
                <AppRoutes />
              </DndProvider>
            </ReactQueryConfigProvider>
          </AppThemeProvider>
        </Provider>
      </React.StrictMode>
    </Suspense>
  );
}

export default CollaBoardApp;
