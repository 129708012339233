import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { InternalError } from "../../../errors/InternalError";
import { isAPIError, isError, stringifyError } from "../../../tools/errors";
import { onErrorToLog, LogCategory } from "../../../tools/telemetry";
import { errorToast } from "../../../tools/errorToast";
import { CollaboardApiError } from "../../../errors/collaboardApiError";
import { useHistory } from "react-router-dom";
import { routes } from "../../../const";
import { normalizeToCloneable } from "../../../tools/utils";

type UseErrorHandler = {
  handleApiError: (error: CollaboardApiError) => void;
  fallbackErrorHandler: (
    error: unknown,
    logMessage: string,
    logCategory: LogCategory
  ) => void;
  logUnknownError: (
    error: unknown,
    logMessage: string,
    logCategory: LogCategory
  ) => void;
  redirectToErrorPage: (error: unknown) => void;
};

export const useErrorHandler = (): UseErrorHandler => {
  const { t } = useTranslation();
  const history = useHistory();

  const redirectToErrorPage = useCallback(
    (error: unknown) => {
      if (isAPIError(error)) {
        const { details } = error;
        const { errorCode } = details;
        history.replace(`${routes.error}?code=${errorCode}`);
        return;
      }

      if (isError(error)) {
        const state: ErrorHistoryLocationState = {
          error: normalizeToCloneable(error),
        };
        history.replace(routes.error, state);
        return;
      }

      history.replace(routes.error);
    },
    [history]
  );

  const handleApiError = useCallback(
    (error: CollaboardApiError) => {
      const { details } = error;
      const { isHTTPError, statusText, reasonPhrase, errorMessage } = details;
      const errorText = isHTTPError ? reasonPhrase ?? statusText : errorMessage;
      errorToast(errorText ?? t("clientError.unknownError"));
    },
    [t]
  );

  const logUnknownError = useCallback(
    (error: unknown, logMessage: string, logCategory: LogCategory) => {
      onErrorToLog(
        isError(error)
          ? error
          : new InternalError(`${logMessage}: ${stringifyError(error)}`),
        logCategory
      );
    },
    []
  );

  /**
   * Handle an unknown error.
   *
   * If the error is an API error with an ErrorCode then the user will be
   * shown the appropriate error toast message.
   *
   * If the error is not recognized the error will be logged and the user
   * will not see anything.
   *
   * It is the responsibility of the caller to handle the resulting state.
   */
  const fallbackErrorHandler = useCallback(
    (error: unknown, logMessage: string, logCategory: LogCategory) => {
      if (isAPIError(error)) {
        handleApiError(error);
        return;
      }

      errorToast(t("clientError.caughtError", { error: logMessage }));
      logUnknownError(error, logMessage, logCategory);
    },
    [handleApiError, logUnknownError, t]
  );

  return {
    handleApiError,
    fallbackErrorHandler,
    logUnknownError,
    redirectToErrorPage,
  };
};
