import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Leave(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M29.34,11.13a.76.76,0,0,1,.76.76v16a.76.76,0,0,1-1.52,0v-16a.77.77,0,0,1,.76-.76Z" />
      <path d="M23.45,18.84H11.29a.76.76,0,0,0-.77.74.77.77,0,0,0,.77.75H23.45l-3.78,3.72a.72.72,0,0,0,0,1,.77.77,0,0,0,1.06,0l5.08-5a.74.74,0,0,0,0-1.05l-5.08-5a.77.77,0,0,0-.3-.19,1,1,0,0,0-.24,0,.74.74,0,0,0-.55.22.72.72,0,0,0-.23.53.67.67,0,0,0,.06.28.64.64,0,0,0,.18.25Z" />
    </Icon>
  );
}
export default Leave;
