import { fabric } from "fabric";
import { canvasObjectIds } from "../../../const";
import { urlRegex } from "../../../tools/regex";
import { calcTransformState, isShapeText } from "../../utils/fabricObjects";

// TODO: #6554 (LINKS_V2) remove

/**
 * Create custom class based on the fabric.Rect.
 *
 * The most important thing about it is to override the
 * isNotVisible method to force the fabric to render the Rect
 * even if it's opacity is equal to 0.
 */
fabric.UrlArea = fabric.util.createClass(fabric.Rect, {
  type: canvasObjectIds.urlArea,

  initialSelectable: false,
  selectable: false,
  hasControls: false,
  hasBorders: false,
  lockMovementX: true,
  lockMovementY: true,
  lockRotation: true,
  lockScalingX: true,
  lockScalingY: true,
  lockUniScaling: true,
  lockSkewingX: true,
  lockSkewingY: true,
  lockScalingFlip: true,
  padding: 0,
  strokeWidth: 0,
  stroke: "",
  opacity: 0,
  hoverCursor: "pointer",
  allowAttachments: false,

  isNotVisible() {
    return this.width === 0 && this.height === 0 && this.strokeWidth === 0;
  },
});

fabric.CollaboardClickableURLs = {
  urls: [],
  clickableAreas: [],
  timeout: null,

  /**
   * Initialize clickable URLs and attach the event handlers.
   */
  initializeClickableURLs() {
    const eventsHost = isShapeText(this) ? this.shape : this;

    eventsHost.on("modified", this._recalculateAreas.bind(this));
    eventsHost.on("deselected", (event) => {
      // object was removed
      if (!event.e) {
        return;
      }

      this._recalculateAreas();
    });

    eventsHost.on("selected", this._removeAreas.bind(this));
    eventsHost.on("removed", this._removeAreas.bind(this));

    eventsHost.on("added", () => {
      if (this.canvas?.getContext()) {
        this._initClickableAreas();
      }

      this._attachCanvasListeners();
    });
  },

  /**
   * Attach the canvas custom events listeners.
   * @private
   */
  _attachCanvasListeners() {
    this.canvas.on("custom:canvas:set:urls:clickable", ({ urlsClickable }) => {
      this._toggleAreas(urlsClickable);
      urlsClickable && this._recalculateAreas();
    });
  },

  /**
   * Initialize clickable areas.
   * @private
   */
  _initClickableAreas() {
    // Only calculate dimensions if they haven't already been computed
    !this.__lineHeights && this.initDimensions();
    this._calculateUrlsPosition();
    this._createClickableAreas();
    this._updateAreasAngle();
  },

  /**
   * Recalculate the position, the size and
   * the angle of the clickable areas.
   * @private
   */
  _recalculateAreas() {
    if (!this.canvas?.urlsClickable) {
      return;
    }

    this.clickableAreas.length && this.canvas.remove(...this.clickableAreas);
    this.urls = [];
    this._initClickableAreas();
  },

  /**
   * Calculate the positions of the URLs inside of the object.
   *
   * It goes through every line and checks if the line contains some URLs.
   * If so, it calculates the position and the dimensions of the URL based
   * on the __charBounds and __lineHeights and saves it as an object in the
   * this.urls array.
   * @private
   */
  _calculateUrlsPosition() {
    const {
      textLines,
      _unwrappedTextLines,
      __charBounds,
      __lineHeights,
      __lineWidths,
    } = this;
    /** Sticky notes have custom offsets @see `stickyNote.editing.ts` */
    const leftOffset = this._getLeftOffset() + this.width / 2;
    const topOffset = this._getTopOffset() + this.height / 2;

    const urlsPositions = [];
    const unwrappedTextLines = _unwrappedTextLines.map((line) => line.join(""));
    let wrappedUrl = "";
    let wrappedUrlPart = "";
    let wrappedLinesCount = 0;
    let currentUrlIndex = 0;
    let currentFullUrl = "";
    let charCount = 0;
    let isLastUrlPart = false;
    let prevUnwrappedLine = "";

    textLines.forEach((line, i) => {
      charCount += line.length;
      const unwrappedLine = unwrappedTextLines[i - wrappedLinesCount];

      if (unwrappedLine.length > charCount) {
        wrappedLinesCount += 1;
      } else {
        charCount = 0;
        wrappedUrlPart = "";
      }

      // Reset these variables when a new unwrappedLine is encountered instead
      // of when the next one will be, as in the above check. These variables
      // are still needed for the current iteration, so they cannot be reset in
      // the above check.
      if (unwrappedLine !== prevUnwrappedLine) {
        currentUrlIndex = 0;
        currentFullUrl = "";
        prevUnwrappedLine = unwrappedLine;
      }

      const isUnwrappedLine = unwrappedLine.length > line.length;
      let urls = line.match(urlRegex) || [];

      // if a line doesn't match an URL reg, run additional regex
      // on the unwrapped line and if the whole line contains an URL
      // trim it to the length of the line and use in the further calculations
      if (isUnwrappedLine && !urls.length && !wrappedUrl) {
        urls = unwrappedLine.match(urlRegex) || [];

        if (urls.length) {
          urls = [urls[0].substring(0, line.length)];
        }
      }

      // treat wrappedUrl string as a URL, because it's a part of the
      // URL wrapped to the next line
      wrappedUrl && urls.unshift(wrappedUrl);

      if (!urls.length) {
        return;
      }

      if (isUnwrappedLine) {
        const fullUrls = unwrappedLine.match(urlRegex);

        // take the url from the unwrapped line if it exists
        currentFullUrl = fullUrls?.[currentUrlIndex] || "";
      }

      const charBounds = __charBounds[i];
      const lineHeight = __lineHeights[i];
      const lineWidth = __lineWidths[i];

      /**
       * @NOTE It seems that these values are not defined on project load and only when the user selects
       * the object, they are defined and the UrlAreas are actually created. This may have something
       * to do with lazy loading the fonts.
       * Since Clickable URLs are a legacy feature, we can ignore this bug.
       */
      if (!charBounds || !lineHeight || !lineWidth) {
        return;
      }

      const lineOffset = this._getLineLeftOffset(i);

      urls.forEach((url, index) => {
        // in case there are two or more same URLs in a one line
        const lastDuplicatedUrl =
          index > 0 &&
          [...urlsPositions].reverse().find((el) => el.url === url);

        const firstCharIndex = line.indexOf(
          url,
          lastDuplicatedUrl && lastDuplicatedUrl.lastCharIndex + 1
        );

        const lastCharIndex = firstCharIndex + url.length;

        if (firstCharIndex === -1) {
          return;
        }

        urlsPositions.push({
          url: (index === 0 && currentFullUrl) || url,
          lastCharIndex,
          left: charBounds[firstCharIndex]?.left + leftOffset + lineOffset,
          top: i * lineHeight + topOffset,
          width:
            charBounds[lastCharIndex]?.left - charBounds[firstCharIndex]?.left,
          height:
            (charBounds[firstCharIndex]?.height +
              charBounds[lastCharIndex]?.height) /
            2,
        });

        if (isLastUrlPart) {
          wrappedUrl = "";
        }
      });

      // if there is a tempFullUrl (which is URL wrapped to the next line)
      // take it's wrapped part from the next line and treat as a URL
      if (currentFullUrl) {
        if (isLastUrlPart) {
          isLastUrlPart = false;
          currentUrlIndex += 1;
          return;
        }

        if (!wrappedUrlPart) {
          wrappedUrlPart = line;
        }

        const remainingUrlPart = currentFullUrl.length - wrappedUrlPart.length;

        if (remainingUrlPart <= 0) {
          // Only a smaller part of the text is url, e.g. "http://google.com foo"
          currentUrlIndex += 1;
          return;
        }

        isLastUrlPart = remainingUrlPart <= textLines[i + 1]?.length;

        wrappedUrl = isLastUrlPart
          ? textLines[i + 1].substring(0, remainingUrlPart)
          : textLines[i + 1];
        wrappedUrlPart += wrappedUrl;
      }
    });

    this.urls = urlsPositions;
  },

  /**
   * Create the clickable areas, which are basically the fabric.Rect objects
   * (UrlArea extends the fabric.Rect). They are fabric.Rects, because it
   * helps a lot with things like listening for events, rotating and changing the cursor.
   * @private
   */
  _createClickableAreas() {
    this.canvas.remove(...this.clickableAreas);

    this.clickableAreas = this.urls.map((urlObj) => {
      const transform = calcTransformState(this);
      const center = new fabric.Point(transform.left, transform.top);
      const translatedCenter = this.translateToCenterPoint(
        center,
        "center",
        "center"
      );
      const { x, y } = this.translateToOriginPoint(
        translatedCenter,
        this.originX,
        this.originY
      );

      /**
       * @Note `urlObj.left` and `urlObj.top` should also be normalized wrt the origin but it seems
       * that repeating the above lines for `new Point(urlObj.left, urlObj.top)` is not enough.
       */
      const calculatedLeft = x + urlObj.left * transform.scaleY;
      const calculatedTop = y + urlObj.top * transform.scaleX;

      const area = new fabric.UrlArea({
        left: calculatedLeft,
        top: calculatedTop,
        width: urlObj.width,
        height: urlObj.height,
        evented: this.canvas?.urlsClickable,
        scaleX: transform.scaleX,
        scaleY: transform.scaleY,
        parentId: this.uuid,
        url: urlObj.url,
        originX: isShapeText(this) ? "center" : this.originX,
        originY: isShapeText(this) ? "center" : this.originY,
      });

      this._attachAreaEvents(area);

      return area;
    });

    const activeObject = this.canvas.getActiveObject();
    const isNotSelected = isShapeText(this)
      ? activeObject !== this && activeObject !== this.shape
      : activeObject !== this;

    isNotSelected && this._renderAreas();
  },

  /**
   * Attach events to the clickable area.
   * @param {fabric.UrlArea} area Clickable area
   * @private
   */
  _attachAreaEvents(area) {
    area.on("mousedown", (event) => {
      event.e.stopImmediatePropagation();
      area.set({ selectable: false });
    });

    area.on("mouseup", (event) => {
      event.e.stopImmediatePropagation();
      window.open(area.url, "_blank");
    });

    area.on("mouseover", () => area.set({ selectable: false }));
  },

  /**
   * Update the clickable area's angle and the position.
   *
   * The rotatePoint util is required to get the new position of the
   * clickable area when the main object is rotated, because the area
   * needs to be rotated around the top-left point of the main object.
   * @private
   */
  _updateAreasAngle() {
    const { angle } = this;

    this.clickableAreas.forEach((area) => {
      const { left, top } = area;

      const rotatedPoint = fabric.util.rotatePoint(
        new fabric.Point(left, top),
        new fabric.Point(this.left, this.top),
        fabric.util.degreesToRadians(angle)
      );

      area.set({
        selectable: false,
        top: rotatedPoint.y,
        left: rotatedPoint.x,
        angle,
      });

      area.setCoords();
    });

    this.canvas.requestRenderAll();
  },

  /**
   * Remove clickable areas from the canvas.
   * @private
   */
  _removeAreas() {
    clearTimeout(this.timeout);

    if (this.canvas) {
      this.clickableAreas.length && this.canvas.remove(...this.clickableAreas);
      this.canvas.requestRenderAll();
    }
  },

  /**
   * Render clickable areas by adding them to the canvas.
   * @private
   */
  _renderAreas() {
    if (this.clickableAreas.length && this.canvas) {
      this.canvas.add(...this.clickableAreas);

      this.canvas.requestRenderAll();
    }
  },

  /**
   * Toggle the clickable areas.
   *
   * @param {bool} urlsClickable If the url can be clicked.
   * @private
   */
  _toggleAreas(urlsClickable) {
    this.clickableAreas.forEach((area) => {
      area.set({
        evented: urlsClickable,
        selectable: false,
      });
    });
  },

  /**
   * Override the setLockMode method to enable clickable areas
   * each time lock mode is changed. It is required because URLs
   * need to be clickable even when an object is locked.
   * @param {boolean} enableLocking
   */
  setLockMode(enableLocking) {
    this.callSuper("setLockMode", enableLocking);
    this._recalculateAreas();
  },
};
