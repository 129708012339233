import { string } from "yup";

import { maxPasswordLength, minPasswordLength } from "./fieldRestrictions";
import { mergeSchemas } from "./helpers";

export const passwordHasLength = string()
  .min(minPasswordLength)
  .max(maxPasswordLength);
export const passwordHasLowercaseChar = string().matches(/[a-z]/);
export const passwordHasUppercaseChar = string().matches(/[A-Z]/);
export const passwordHasNumber = string().matches(/[0-9]/);
export const passwordHasNoWhitespace = string()
  .ensure()
  .test({
    name: "noWhitespace",
    test: (value) => {
      return !/\s/.test(value);
    },
  });

export const passwordSpecialChars =
  "! @ # $ % ^ & * ( ) _ + = [ ] { } ; : < > | . \\ / ? , - ` ' \" ~";

export const passwordHasSpecialChar = string()
  .ensure()
  // Must have at least one of these special characters:
  // ! @ # $ % ^ & * ( ) _ + = [ ] { } ; : < > | . / ? , - ` ' " ~
  .matches(/[!@#$%^&*()_+=[\]{};:<>|\\./?,\-`'"~]/);

const passwordWhitelist = string()
  .ensure()
  .test({
    name: "whitelist",
    test: (value) => {
      /**
       * Check for anything that isn't whitelisted.
       *
       * Note, the whitelist also includes letters from other languages using
       * `/p{L}` and `/p{M}` and the `/u` unicode flag.
       *
       * See:
       * https://dev.to/tillsanders/let-s-stop-using-a-za-z-4a0m
       * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Unicode_Property_Escapes
       */
      return !/[^a-zA-Z0-9\p{L}\p{M}!@#$%^&*()_+=[\]{};:<>|\\./?,\-`'"~]/u.test(
        value
      );
    },
  });

export const passwordSchema = mergeSchemas(
  passwordHasLength,
  passwordHasNumber,
  passwordHasLowercaseChar,
  passwordHasUppercaseChar,
  passwordHasSpecialChar,
  passwordHasNoWhitespace,
  passwordWhitelist
);
