import { combineReducers } from "redux";
import { MeetingAction, MeetingActionType } from "./meeting.actions";

type WebexMeetingState = {
  isMinimized: boolean;
  spaceId: string;
  joinAsGuest: boolean;
};

type ZoomMeetingState = {
  url: string;
  isMinimized: boolean;
};

export type MeetingState = {
  webex: WebexMeetingState;
  zoom: ZoomMeetingState;
};

export const getInitialMeetingState = (): MeetingState => {
  return {
    webex: {
      isMinimized: false,
      spaceId: "",
      joinAsGuest: false,
    },
    zoom: {
      url: "",
      isMinimized: false,
    },
  };
};

function zoomMeetingReducer(
  state = getInitialMeetingState().zoom,
  action: MeetingAction
): ZoomMeetingState {
  switch (action.type) {
    case MeetingActionType.SET_ZOOM_MEETING_URL: {
      return {
        ...state,
        url: action.payload,
      };
    }
    case MeetingActionType.TOGGLE_ZOOM_MEETING_WINDOW: {
      return {
        ...state,
        isMinimized: action.payload,
      };
    }
    default:
      return state;
  }
}

function webexMeetingReducer(
  state = getInitialMeetingState().webex,
  action: MeetingAction
): WebexMeetingState {
  switch (action.type) {
    case MeetingActionType.SET_WEBEX_SPACE_ID: {
      return {
        ...state,
        spaceId: action.payload,
      };
    }
    case MeetingActionType.SET_WEBEX_JOIN_AS_GUEST: {
      return {
        ...state,
        joinAsGuest: action.payload,
      };
    }
    case MeetingActionType.TOGGLE_WEBEX_WINDOW: {
      return {
        ...state,
        isMinimized: action.payload,
      };
    }
    default:
      return state;
  }
}

export const meetingReducer = combineReducers<MeetingState>({
  webex: webexMeetingReducer,
  zoom: zoomMeetingReducer,
});

export const selectWebexMeeting = (
  state: ApplicationGlobalState
): WebexMeetingState => {
  return state.canvas.meeting.webex;
};

export const selectZoomMeeting = (
  state: ApplicationGlobalState
): ZoomMeetingState => {
  return state.canvas.meeting.zoom;
};
