import React, { PropsWithChildren, ReactElement } from "react";
import { Grid, ThemeUIStyleObject } from "theme-ui";

function LicenseOrderModalTop({
  children,
}: PropsWithChildren<unknown>): ReactElement {
  return <Grid sx={gridStyle}>{children}</Grid>;
}

const gridStyle: ThemeUIStyleObject = {
  mb: [5],
  gridTemplateColumns: "1fr 1fr",
  gridGap: 0,
  border: "1px solid",
  borderColor: "var(--inputBorder)",
  minHeight: "150px",
};

export default LicenseOrderModalTop;
