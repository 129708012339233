import {
  all,
  call,
  Effect,
  getContext,
  select,
  takeEvery,
} from "redux-saga/effects";
import { SignalRClient } from "../../../api/signalR";
import { CollaboardToast } from "../../../features/shared/toasts/useToast";
import { selectAssertedUserProfile } from "../../auth/auth.reducer";
import {
  selectProjectOnlineUsers,
  selectProjectUserFirstName,
} from "../project/project.reducer";
import {
  NotifyProjectActionType,
  SetUserOnlineAction,
} from "../project/signalR-project/signalR-project.actions";
import { signalRTimedSessionSaga } from "./signalR-timedSession.saga";
import {
  TimedSessionActionType,
  TimedSessionPauseToggledAction,
  TimedSessionStoppedAction,
} from "./timedSession.action";
import { selectTimedSession } from "./timedSession.reducer";

const sessionToast = new CollaboardToast("");

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* showSessionToast(host: string, messageKey: string) {
  const userProfile = selectAssertedUserProfile(yield select());

  if (userProfile.UserName !== host) {
    sessionToast.update(messageKey, {
      name: selectProjectUserFirstName(host)(yield select()),
    });
  }
}

function* onTimedSessionStarted() {
  const timedSession = selectTimedSession(yield select());
  yield call(showSessionToast, timedSession.host, "timedSession.started");
}

function* onTimedSessionStopped({ payload }: TimedSessionStoppedAction) {
  yield call(showSessionToast, payload.host, "timedSession.stopped");
}

function* onTimedSessionPauseToggled({
  payload: {
    session: { IsPaused },
  },
}: TimedSessionPauseToggledAction) {
  const timedSession = selectTimedSession(yield select());

  yield call(
    showSessionToast,
    timedSession.host,
    IsPaused ? "timedSession.paused" : "timedSession.resumed"
  );
}

function* notifyUserJoined({ payload }: SetUserOnlineAction) {
  const signalRClient: SignalRClient = yield getContext("signalRClient");

  const userProfile = selectAssertedUserProfile(yield select());
  const onlineUsers = selectProjectOnlineUsers(yield select());
  const { AuthUser } = payload;

  const timedSession = selectTimedSession(yield select());
  const { isRunning, isHost, host } = timedSession;

  if (isRunning) {
    // Viewer joins - host sends the signal
    // Host joins - single viewer sends the signal
    const hostJoined = host === AuthUser?.UserName;
    const firstViewer = onlineUsers.find((u) => u.UserName !== host);
    const isMyselfFirstViewer = firstViewer?.UserName === userProfile.UserName;
    const shouldSendUpdate = isHost || (hostJoined && isMyselfFirstViewer);

    if (shouldSendUpdate) {
      const connectionId = AuthUser?.ConnectionId;

      if (connectionId) {
        yield signalRClient.postUpdateNewJoinedForTimer(
          connectionId,
          timedSession
        );
      }
    }
  }
}

export function* timedSessionSaga(): Generator<Effect> {
  yield all([
    signalRTimedSessionSaga(),
    takeEvery(
      TimedSessionActionType.TIMED_SESSION_PAUSE_TOGGLED,
      onTimedSessionPauseToggled
    ),
    takeEvery(
      TimedSessionActionType.TIMED_SESSION_STARTED,
      onTimedSessionStarted
    ),
    takeEvery(
      TimedSessionActionType.TIMED_SESSION_STOPPED,
      onTimedSessionStopped
    ),
    takeEvery(NotifyProjectActionType.SET_USER_ONLINE, notifyUserJoined),
  ]);
}
