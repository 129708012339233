import { ReactElement } from "react";
import useEyeDropper from "./useEyeDropper";

type Props = {
  onColorPicked: (color: string) => void; // warning: must be a memo-ized function
  allowTransparentColor?: boolean;
  children: (context: {
    isEyeDropperActive: boolean;
    isDisabled: boolean;
    enterEyeDropper: () => void;
  }) => ReactElement;
};

/**
 * Render prop component to allow conditional use of the useEyeDropper hook
 */
function EyeDropper({
  onColorPicked,
  allowTransparentColor,
  children,
}: Props): ReactElement {
  const { isEyeDropperActive, isDisabled, enterEyeDropper } = useEyeDropper(
    onColorPicked,
    allowTransparentColor
  );

  return children({
    isEyeDropperActive,
    isDisabled,
    enterEyeDropper,
  });
}

export default EyeDropper;
