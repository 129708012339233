import React, { ReactElement } from "react";
import { Flex, Heading, ThemeUIStyleObject } from "theme-ui";

type Props = {
  count?: number;
  label: string;
  max?: number;
  icon(props: IconProps): ReactElement;
  sx?: ThemeUIStyleObject;
};

function LicenseItem({
  count = 0,
  max = 0,
  icon: Icon,
  label,
  sx,
}: Props): ReactElement {
  return (
    <Flex variant="licenseItem" sx={{ flexWrap: ["wrap", "nowrap"], ...sx }}>
      <Flex>
        <Icon scale={1.2} noShadow />
        <Heading
          sx={{
            fontSize: "1rem",
            color: "var(--textColor)",
            ml: [3],
            display: ["none", "block"],
          }}
        >
          {label}
        </Heading>
      </Flex>
      <Flex
        id="licenses-available"
        sx={{
          fontSize: ["1.8rem", "2.3rem"],
          fontWeight: 200,
        }}
      >
        <strong>{count}</strong> / <span>{max}</span>
      </Flex>
    </Flex>
  );
}

export default LicenseItem;
