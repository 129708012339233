import type {
  VotingCastMessage,
  VotingRevokedMessage,
  VotingSessionFinalizedMessage,
  VotingStartedMessage,
  VotingStoppedMessage,
  VotingTimeAdjustedMessage,
} from "../../../api/signalR/message.types";

export enum VotingActionType {
  CAST_VOTE = "CAST_VOTE",
  CREATE_VOTING_SUMMARY_OBJECT = "CREATE_VOTING_SUMMARY_OBJECT",
  FINISH_VOTING = "FINISH_VOTING",
  ON_PARTICIPANT_FINISHED_VOTING = "ON_PARTICIPANT_FINISHED_VOTING",
  ON_VOTE_CAST = "ON_VOTE_CAST",
  ON_VOTING_ERROR = "ON_VOTING_ERROR",
  ON_VOTING_SESSION_DURATION_CHANGE = "ON_VOTING_SESSION_DURATION_CHANGE",
  ON_VOTING_SESSION_RESUMED = "ON_VOTING_SESSION_RESUMED",
  ON_VOTING_SESSION_STARTED = "ON_VOTING_SESSION_STARTED",
  ON_VOTING_SESSION_STOPPED = "ON_VOTING_SESSION_STOPPED",
  RESET_VOTE = "RESET_VOTE",
  SET_VOTES_PER_OBJECT = "SET_VOTES_PER_OBJECT",
}

export const castVoteAction = (payload: { uuid: string; vote: number }) =>
  ({
    type: VotingActionType.CAST_VOTE,
    payload,
  } as const);

export type CastVoteAction = ReturnType<typeof castVoteAction>;

export const resetVoteAction = (payload: { uuid: string }) =>
  ({
    type: VotingActionType.RESET_VOTE,
    payload,
  } as const);

export type ResetVoteAction = ReturnType<typeof resetVoteAction>;

export const finishVotingAction = () =>
  ({
    type: VotingActionType.FINISH_VOTING,
    payload: undefined,
  } as const);

type FinishVotingAction = ReturnType<typeof finishVotingAction>;

export const createVotingSummaryObjectAction = (payload: {
  summary: ApiVotingOverview;
}) =>
  ({
    type: VotingActionType.CREATE_VOTING_SUMMARY_OBJECT,
    payload,
  } as const);

export type CreateVotingSummaryObjectAction = ReturnType<
  typeof createVotingSummaryObjectAction
>;

export type OnVotingSessionStartedPayload = {
  isOwner: boolean;
  session: VotingStartedMessage;
  sessionStartTime: number;
};

export const onVotingSessionStartedAction = (
  payload: OnVotingSessionStartedPayload
) =>
  ({
    type: VotingActionType.ON_VOTING_SESSION_STARTED,
    payload,
  } as const);

export type OnVotingSessionStartedAction = ReturnType<
  typeof onVotingSessionStartedAction
>;

export type OnVotingSessionResumedPayload = {
  isOwner: boolean;
  session: ApiGetMyVotes;
};

export const onVotingSessionResumedAction = (
  payload: OnVotingSessionResumedPayload
) =>
  ({
    type: VotingActionType.ON_VOTING_SESSION_RESUMED,
    payload,
  } as const);

export type OnVotingSessionResumedAction = ReturnType<
  typeof onVotingSessionResumedAction
>;

export const onVotingSessionStoppedAction = (payload: {
  session: VotingStoppedMessage;
}) =>
  ({
    type: VotingActionType.ON_VOTING_SESSION_STOPPED,
    payload,
  } as const);

type OnVotingSessionStoppedAction = ReturnType<
  typeof onVotingSessionStoppedAction
>;

export const onVoteCastAction = (payload: {
  isMyVote: boolean;
  vote: VotingCastMessage | VotingRevokedMessage;
}) =>
  ({
    type: VotingActionType.ON_VOTE_CAST,
    payload,
  } as const);

type OnVoteCastAction = ReturnType<typeof onVoteCastAction>;

export const onParticipantFinishedVotingAction = (payload: {
  isMyself: boolean;
  session: VotingSessionFinalizedMessage;
}) =>
  ({
    type: VotingActionType.ON_PARTICIPANT_FINISHED_VOTING,
    payload,
  } as const);

type OnParticipantFinishedVotingAction = ReturnType<
  typeof onParticipantFinishedVotingAction
>;

export const onVotingSessionDurationChangedAction = (payload: {
  session: VotingTimeAdjustedMessage;
}) =>
  ({
    type: VotingActionType.ON_VOTING_SESSION_DURATION_CHANGE,
    payload,
  } as const);

type OnVotingSessionDurationChangedAction = ReturnType<
  typeof onVotingSessionDurationChangedAction
>;

export const setVotesPerObjectAction = (payload: {
  sessionId: number;
  votes: ApiUserVotesSummary[];
}) =>
  ({
    type: VotingActionType.SET_VOTES_PER_OBJECT,
    payload,
  } as const);

type SetVotesPerObjectAction = ReturnType<typeof setVotesPerObjectAction>;

export const onVotingErrorAction = (payload: { error: unknown }) =>
  ({
    type: VotingActionType.ON_VOTING_ERROR,
    payload,
  } as const);

export type OnVotingErrorAction = ReturnType<typeof onVotingErrorAction>;

export type VotingAction =
  | CastVoteAction
  | CreateVotingSummaryObjectAction
  | FinishVotingAction
  | OnParticipantFinishedVotingAction
  | OnVoteCastAction
  | OnVotingErrorAction
  | OnVotingSessionDurationChangedAction
  | OnVotingSessionResumedAction
  | OnVotingSessionStartedAction
  | OnVotingSessionStoppedAction
  | ResetVoteAction
  | SetVotesPerObjectAction;
