import { unnest } from "ramda";

import bird from "./animals/Bird.svg";
import butterflies from "./animals/Butterflies.svg";
import cat from "./animals/Cat.svg";
import dog from "./animals/Dog.svg";
import elephant from "./animals/Elephant.svg";
import lion from "./animals/Lion.svg";
import lizard from "./animals/Lizard.svg";
import monkey from "./animals/Monkey.svg";
import pig from "./animals/Pig.svg";
import rabbit from "./animals/Rabbit.svg";
import snake from "./animals/Snake.svg";
import squirrel from "./animals/Squirrel.svg";

import arrow from "./basic/Arrow.svg";
import chat from "./basic/Chat.svg";
import circle from "./basic/Circle.svg";
import doubleArrow from "./basic/DoubleArrow.svg";
import heart from "./basic/Heart.svg";
import hexagon from "./basic/Hexagon.svg";
import line from "./basic/Line.svg";
import longLine from "./basic/LongLine.svg";
import pentagon from "./basic/Pentagon.svg";
import square from "./basic/Square.svg";
import squareRounded from "./basic/SquareRounded.svg";
import star from "./basic/Star.svg";
import triangle from "./basic/Triangle.svg";

import allDevices from "./devices/AllDevices.svg";
import ipod from "./devices/Ipod.svg";
import laptop from "./devices/Laptop.svg";
import mobileDevices from "./devices/MobileDevices.svg";
import monitor from "./devices/Monitor.svg";
import mouseDevice from "./devices/Mouse.svg";
import printer from "./devices/Printer.svg";
import smartphone from "./devices/Smartphone.svg";
import surface from "./devices/Surface.svg";
import tablet from "./devices/Tablet.svg";
import vr from "./devices/VR.svg";
import watch from "./devices/Watch.svg";

import angry from "./emoticons/Angry.svg";
import blink from "./emoticons/Blink.svg";
import cool from "./emoticons/Cool.svg";
import doubtful from "./emoticons/Doubtful.svg";
import injured from "./emoticons/Injured.svg";
import laugh from "./emoticons/Laugh.svg";
import love from "./emoticons/Love.svg";
import poop from "./emoticons/Poop.svg";
import sad from "./emoticons/Sad.svg";
import shock from "./emoticons/Shock.svg";
import smile from "./emoticons/Smile.svg";
import tongue from "./emoticons/Tongue.svg";

import berries from "./floral/Berries.svg";
import bug from "./floral/Bug.svg";
import calligraphicElement from "./floral/CalligraphicElement.svg";
import cereusPeruvianus from "./floral/CereusPeruvianus.svg";
import flower from "./floral/Flower.svg";
import fourPetalsFlower from "./floral/FourPetalsFlower.svg";
import grass from "./floral/Grass.svg";
import leaf from "./floral/Leaf.svg";
import rake from "./floral/Rake.svg";
import tree from "./floral/Tree.svg";
import wateringCan from "./floral/WateringCan.svg";
import wheelbarrow from "./floral/Wheelbarrow.svg";

import burgerDrink from "./food/BurgerDrink.svg";
import cake from "./food/Cake.svg";
import cheese from "./food/Cheese.svg";
import chef from "./food/Chef.svg";
import cutlery from "./food/Cutlery.svg";
import drink from "./food/Drink.svg";
import fishSkeleton from "./food/FishSkeleton.svg";
import fruitBowl from "./food/FruitBowl.svg";
import iceCream from "./food/IceCream.svg";
import loaf from "./food/Loaf.svg";
import pizza from "./food/Pizza.svg";
import tea from "./food/Tea.svg";

import boardGame from "./game/BoardGame.svg";
import bowling from "./game/Bowling.svg";
import cardGame from "./game/CardGame.svg";
import chess from "./game/Chess.svg";
import controller from "./game/Controller.svg";
import dart from "./game/Dart.svg";
import dice from "./game/Dice.svg";
import football from "./game/Football.svg";
import ghost from "./game/Ghost.svg";
import gun from "./game/Gun.svg";
import nintendo from "./game/Nintendo.svg";
import tank from "./game/Tank.svg";

import handclap from "./hands/Handclap.svg";
import handcouple from "./hands/Handcouple.svg";
import handdouble from "./hands/Handdouble.svg";
import handdown from "./hands/Handdown.svg";
import handko from "./hands/Handko.svg";
import handleft from "./hands/Handleft.svg";
import handok from "./hands/Handok.svg";
import handopen from "./hands/Handopen.svg";
import handpray from "./hands/Handpray.svg";
import handright from "./hands/Handright.svg";
import handup from "./hands/Handup.svg";
import handwrite from "./hands/Handwrite.svg";

import bank from "./money/Bank.svg";
import cash from "./money/Cash.svg";
import creditCards from "./money/CreditCards.svg";
import dollar from "./money/Dollar.svg";
import euro from "./money/Euro.svg";
import money1 from "./money/Money1.svg";
import money2 from "./money/Money2.svg";
import money3 from "./money/Money3.svg";
import pound from "./money/Pound.svg";
import profit from "./money/Profit.svg";
import savingPiggy from "./money/SavingPiggy.svg";
import yen from "./money/Yen.svg";

import briefcase from "./office/Briefcase.svg";
import folder from "./office/Folder.svg";
import hierarchy from "./office/Hierarchy.svg";
import landline from "./office/Landline.svg";
import loop from "./office/Loop.svg";
import mail from "./office/Mail.svg";
import megaphone from "./office/Megaphone.svg";
import mouse from "./office/Mouse.svg";
import office from "./office/Office.svg";
import officeFolder from "./office/OfficeFolder.svg";
import pieChart from "./office/PieChart.svg";
import statistic from "./office/Statistic.svg";

import discussion from "./people/Discussion.svg";
import man from "./people/Man.svg";
import people from "./people/People.svg";
import portrait from "./people/Portrait.svg";
import portraits from "./people/Portraits.svg";
import raisedHand from "./people/RaisedHand.svg";
import running from "./people/Running.svg";
import speechBubbles from "./people/SpeechBubbles.svg";
import twoMen from "./people/TwoMen.svg";
import wheelchair from "./people/Wheelchair.svg";
import woman from "./people/Woman.svg";
import womanAndMan from "./people/WomanAndMan.svg";

// BPMN Symbols https://www.edrawsoft.com/bpmn-symbols.html
import bpmnBulb from "./process/BPMNBulb.svg";
import bpmnCompensation from "./process/BPMNCompensation.svg";
import bpmnCompensation2 from "./process/BPMNCompensation2.svg";
import bpmnComplexGateway from "./process/BPMNComplexGateway.svg";
import bpmnData from "./process/BPMNData.svg";
import bpmnError from "./process/BPMNError.svg";
import bpmnEvent from "./process/BPMNEvent.svg";
import bpmnExclusiveGateway from "./process/BPMNExclusiveGateway.svg";
import bpmnGateway from "./process/BPMNGateway.svg";
import bpmnInclusiveGateway from "./process/BPMNInclusiveGateway.svg";
import bpmnInputData from "./process/BPMNInputData.svg";
import bpmnLink from "./process/BPMNLink.svg";
import bpmnLoop from "./process/BPMNLoop.svg";
import bpmnParallelEventGateway from "./process/BPMNParallelEventGateway.svg";
import bpmnParallelMI from "./process/BPMNParallelMI.svg";
import bpmnProcess from "./process/BPMNProcess.svg";
import bpmnReceiveTask from "./process/BPMNReceiveTask.svg";
import bpmnScriptTask from "./process/BPMNScriptTask.svg";
import bpmnSendTask from "./process/BPMNSendTask.svg";
import bpmnSequentialMI from "./process/BPMNSequentialMI.svg";
import bpmnServiceTask from "./process/BPMNServiceTask.svg";
import bpmnStorage from "./process/BPMNStorage.svg";
import bpmnTask from "./process/BPMNTask.svg";
import bpmnTimer from "./process/BPMNTimer.svg";

import abacus from "./science/Abacus.svg";
import atoms from "./science/Atoms.svg";
import chemical from "./science/Chemical.svg";
import dna from "./science/DNA.svg";
import drawingCompass from "./science/DrawingCompass.svg";
import education from "./science/Education.svg";
import magnet from "./science/Magnet.svg";
import math from "./science/Math.svg";
import microscope from "./science/Microscope.svg";
import radioactive from "./science/Radioactive.svg";
import triangularRuler from "./science/TriangularRuler.svg";
import world from "./science/World.svg";

import biohazard from "./sign/Biohazard.svg";
import branch from "./sign/Branch.svg";
import check from "./sign/Check.svg";
import cross from "./sign/Cross.svg";
import danger from "./sign/Danger.svg";
import halloween from "./sign/Halloween.svg";
import highVoltage from "./sign/HighVoltage.svg";
import recycle from "./sign/Recycle.svg";
import stop from "./sign/Stop.svg";
import trafficSign from "./sign/TrafficSign.svg";
import wifi from "./sign/Wifi.svg";
import wip from "./sign/WIP.svg";

import baseball from "./sport/Baseball.svg";
import baseball2 from "./sport/Baseball2.svg";
import basketball from "./sport/Basketball.svg";
import football1 from "./sport/Football.svg";
import football2 from "./sport/Football2.svg";
import golf from "./sport/Golf.svg";
import rugby from "./sport/Rugby.svg";
import swim from "./sport/Swim.svg";
import tennis from "./sport/Tennis.svg";
import tennisBall from "./sport/TennisBall.svg";
import trophy from "./sport/Trophy.svg";
import volleyball from "./sport/Volleyball.svg";

import bucket from "./tools/Bucket.svg";
import calipers from "./tools/Calipers.svg";
import compass from "./tools/Compass.svg";
import hammer from "./tools/Hammer.svg";
import marker from "./tools/Marker.svg";
import pen from "./tools/Pen.svg";
import pencil from "./tools/Pencil.svg";
import pliers from "./tools/Pliers.svg";
import saw from "./tools/Saw.svg";
import screwdriver from "./tools/Screwdriver.svg";
import setsquare from "./tools/Setsquare.svg";
import wrench from "./tools/Wrench.svg";

import airplane from "./transport/Airplane.svg";
import bus from "./transport/Bus.svg";
import car from "./transport/Car.svg";
import cycle from "./transport/Cycle.svg";
import helicopter from "./transport/Helicopter.svg";
import hotAirBalloon from "./transport/HotAirBalloon.svg";
import motorcycle from "./transport/Motorcycle.svg";
import motorizedScooter from "./transport/MotorizedScooter.svg";
import rocket from "./transport/Rocket.svg";
import ship from "./transport/Ship.svg";
import train from "./transport/Train.svg";
import van from "./transport/Van.svg";

import { ApiShapeId } from "./shapeId";
import cloud from "./weather/Cloud.svg";
import fogDay from "./weather/FogDay.svg";
import heavyRainDay from "./weather/HeavyRainDay.svg";
import heavySnow from "./weather/HeavySnow.svg";
import highWindSpeeds from "./weather/HighWindSpeeds.svg";
import hotThermometer from "./weather/HotThermometer.svg";
import mist from "./weather/Mist.svg";
import moonWithStars from "./weather/MoonWithStars.svg";
import sunny from "./weather/Sunny.svg";
import sunnyIntervals from "./weather/SunnyIntervals.svg";
import thermometerFrost from "./weather/ThermometerFrost.svg";
import thunderstorm from "./weather/Thunderstorm.svg";

export const shapeCollections = [
  "animals",
  "basic",
  "devices",
  "emoticons",
  "floral",
  "food",
  "game",
  "hands",
  "money",
  "office",
  "people",
  "process",
  "processExtended",
  "science",
  "sign",
  "sport",
  "tools",
  "transport",
  "weather",
];

export const animalIcons: Shape[] = [
  { key: ApiShapeId.bird, path: bird },
  { key: ApiShapeId.butterflies, path: butterflies },
  { key: ApiShapeId.cat, path: cat },
  { key: ApiShapeId.dog, path: dog },
  { key: ApiShapeId.elephant, path: elephant },
  { key: ApiShapeId.lion, path: lion },
  { key: ApiShapeId.lizard, path: lizard },
  { key: ApiShapeId.monkey, path: monkey },
  { key: ApiShapeId.pig, path: pig },
  { key: ApiShapeId.rabbit, path: rabbit },
  { key: ApiShapeId.snake, path: snake },
  { key: ApiShapeId.squirrel, path: squirrel },
];

export const basicIcons: Shape[] = [
  { key: ApiShapeId.circle, path: circle },
  { key: ApiShapeId.triangle, path: triangle },
  { key: ApiShapeId.square, path: square },
  { key: ApiShapeId.squareRounded, path: squareRounded },
  { key: ApiShapeId.star, path: star },
  { key: ApiShapeId.heart, path: heart },
  { key: ApiShapeId.pentagon, path: pentagon },
  { key: ApiShapeId.hexagon, path: hexagon },
  { key: ApiShapeId.chat, path: chat },
  { key: ApiShapeId.arrow, path: arrow },
  { key: ApiShapeId.doubleArrow, path: doubleArrow },
  { key: ApiShapeId.line, path: line, hidden: true },
  // replaces old "Line" shape, with back-compatibility
  {
    key: ApiShapeId.longLine,
    path: longLine,
    menuIcon: line,
    customProps: {
      minWidth: 10,
      minHeight: 10,
    },
  },
];

export const deviceIcons: Shape[] = [
  { key: ApiShapeId.allDevices, path: allDevices },
  { key: ApiShapeId.ipod, path: ipod },
  { key: ApiShapeId.laptop, path: laptop },
  { key: ApiShapeId.mobileDevices, path: mobileDevices },
  { key: ApiShapeId.monitor, path: monitor },
  { key: ApiShapeId.mouseDevice, path: mouseDevice },
  { key: ApiShapeId.printer, path: printer },
  { key: ApiShapeId.smartphone, path: smartphone },
  { key: ApiShapeId.surface, path: surface },
  { key: ApiShapeId.tablet, path: tablet },
  { key: ApiShapeId.vr, path: vr },
  { key: ApiShapeId.watch, path: watch },
];

export const emojiIcons: Shape[] = [
  { key: ApiShapeId.love, path: love },
  { key: ApiShapeId.tongue, path: tongue },
  { key: ApiShapeId.smile, path: smile },
  { key: ApiShapeId.blink, path: blink },
  { key: ApiShapeId.sad, path: sad },
  { key: ApiShapeId.angry, path: angry },
  { key: ApiShapeId.shock, path: shock },
  { key: ApiShapeId.laugh, path: laugh },
  { key: ApiShapeId.doubtful, path: doubtful },
  { key: ApiShapeId.injured, path: injured },
  { key: ApiShapeId.cool, path: cool },
  { key: ApiShapeId.poop, path: poop },
];

export const floralIcons: Shape[] = [
  { key: ApiShapeId.bug, path: bug },
  { key: ApiShapeId.fourPetalsFlower, path: fourPetalsFlower },
  { key: ApiShapeId.cereusPeruvianus, path: cereusPeruvianus },
  { key: ApiShapeId.flower, path: flower },
  { key: ApiShapeId.rake, path: rake },
  { key: ApiShapeId.leaf, path: leaf },
  { key: ApiShapeId.calligraphicElement, path: calligraphicElement },
  { key: ApiShapeId.berries, path: berries },
  { key: ApiShapeId.grass, path: grass },
  { key: ApiShapeId.tree, path: tree },
  { key: ApiShapeId.wateringCan, path: wateringCan },
  { key: ApiShapeId.wheelbarrow, path: wheelbarrow },
];

export const foodIcons: Shape[] = [
  { key: ApiShapeId.burgerDrink, path: burgerDrink },
  { key: ApiShapeId.cake, path: cake },
  { key: ApiShapeId.cheese, path: cheese },
  { key: ApiShapeId.chef, path: chef },
  { key: ApiShapeId.cutlery, path: cutlery },
  { key: ApiShapeId.drink, path: drink },
  { key: ApiShapeId.fishSkeleton, path: fishSkeleton },
  { key: ApiShapeId.fruitBowl, path: fruitBowl },
  { key: ApiShapeId.iceCream, path: iceCream },
  { key: ApiShapeId.loaf, path: loaf },
  { key: ApiShapeId.pizza, path: pizza },
  { key: ApiShapeId.tea, path: tea },
];

export const gameIcons: Shape[] = [
  { key: ApiShapeId.boardGame, path: boardGame },
  { key: ApiShapeId.bowling, path: bowling },
  { key: ApiShapeId.cardGame, path: cardGame },
  { key: ApiShapeId.chess, path: chess },
  { key: ApiShapeId.controller, path: controller },
  { key: ApiShapeId.dart, path: dart },
  { key: ApiShapeId.dice, path: dice },
  { key: ApiShapeId.football, path: football },
  { key: ApiShapeId.ghost, path: ghost },
  { key: ApiShapeId.gun, path: gun },
  { key: ApiShapeId.nintendo, path: nintendo },
  { key: ApiShapeId.tank, path: tank },
];

export const handsIcons: Shape[] = [
  { key: ApiShapeId.handclap, path: handclap },
  { key: ApiShapeId.handcouple, path: handcouple },
  { key: ApiShapeId.handdouble, path: handdouble },
  { key: ApiShapeId.handdown, path: handdown },
  { key: ApiShapeId.handko, path: handko },
  { key: ApiShapeId.handleft, path: handleft },
  { key: ApiShapeId.handok, path: handok },
  { key: ApiShapeId.handopen, path: handopen },
  { key: ApiShapeId.handpray, path: handpray },
  { key: ApiShapeId.handright, path: handright },
  { key: ApiShapeId.handup, path: handup },
  { key: ApiShapeId.handwrite, path: handwrite },
];

export const moneyIconsIcons: Shape[] = [
  { key: ApiShapeId.bank, path: bank },
  { key: ApiShapeId.cash, path: cash },
  { key: ApiShapeId.creditCards, path: creditCards },
  { key: ApiShapeId.dollar, path: dollar },
  { key: ApiShapeId.euro, path: euro },
  { key: ApiShapeId.money1, path: money1 },
  { key: ApiShapeId.money2, path: money2 },
  { key: ApiShapeId.money3, path: money3 },
  { key: ApiShapeId.pound, path: pound },
  { key: ApiShapeId.profit, path: profit },
  { key: ApiShapeId.savingPiggy, path: savingPiggy },
  { key: ApiShapeId.yen, path: yen },
];

export const officeIcons: Shape[] = [
  { key: ApiShapeId.briefcase, path: briefcase },
  { key: ApiShapeId.folder, path: folder },
  { key: ApiShapeId.hierarchy, path: hierarchy },
  { key: ApiShapeId.landline, path: landline },
  { key: ApiShapeId.loop, path: loop },
  { key: ApiShapeId.mail, path: mail },
  { key: ApiShapeId.megaphone, path: megaphone },
  { key: ApiShapeId.mouse, path: mouse },
  { key: ApiShapeId.office, path: office },
  { key: ApiShapeId.officeFolder, path: officeFolder },
  { key: ApiShapeId.pieChart, path: pieChart },
  { key: ApiShapeId.statistic, path: statistic },
];

export const peopleIcons: Shape[] = [
  { key: ApiShapeId.discussion, path: discussion },
  { key: ApiShapeId.man, path: man },
  { key: ApiShapeId.people, path: people },
  { key: ApiShapeId.portrait, path: portrait },
  { key: ApiShapeId.portraits, path: portraits },
  { key: ApiShapeId.raisedHand, path: raisedHand },
  { key: ApiShapeId.running, path: running },
  { key: ApiShapeId.speechBubbles, path: speechBubbles },
  { key: ApiShapeId.twoMen, path: twoMen },
  { key: ApiShapeId.wheelchair, path: wheelchair },
  { key: ApiShapeId.woman, path: woman },
  { key: ApiShapeId.womanAndMan, path: womanAndMan },
];

export const processIcons: Shape[] = [
  { key: ApiShapeId.bpmnGateway, path: bpmnGateway },
  { key: ApiShapeId.bpmnInclusiveGateway, path: bpmnInclusiveGateway },
  { key: ApiShapeId.bpmnExclusiveGateway, path: bpmnExclusiveGateway },
  { key: ApiShapeId.bpmnParallelEventGateway, path: bpmnParallelEventGateway },
  { key: ApiShapeId.bpmnComplexGateway, path: bpmnComplexGateway },
  { key: ApiShapeId.bpmnEvent, path: bpmnEvent },
  { key: ApiShapeId.bpmnTask, path: bpmnTask },
  { key: ApiShapeId.bpmnSendTask, path: bpmnSendTask },
  { key: ApiShapeId.bpmnReceiveTask, path: bpmnReceiveTask },
  { key: ApiShapeId.bpmnInputData, path: bpmnInputData },
  { key: ApiShapeId.bpmnData, path: bpmnData },
  { key: ApiShapeId.bpmnProcess, path: bpmnProcess },
];

export const processExtendedIcons: Shape[] = [
  { key: ApiShapeId.bpmnServiceTask, path: bpmnServiceTask },
  { key: ApiShapeId.bpmnTimer, path: bpmnTimer },
  { key: ApiShapeId.bpmnLoop, path: bpmnLoop },
  { key: ApiShapeId.bpmnBulb, path: bpmnBulb },
  { key: ApiShapeId.bpmnError, path: bpmnError },
  { key: ApiShapeId.bpmnSequentialMI, path: bpmnSequentialMI },
  { key: ApiShapeId.bpmnStorage, path: bpmnStorage },
  { key: ApiShapeId.bpmnScriptTask, path: bpmnScriptTask },
  { key: ApiShapeId.bpmnParallelMI, path: bpmnParallelMI },
  { key: ApiShapeId.bpmnCompensation, path: bpmnCompensation },
  { key: ApiShapeId.bpmnCompensation2, path: bpmnCompensation2 },
  { key: ApiShapeId.bpmnLink, path: bpmnLink },
];

export const scienceIcons: Shape[] = [
  { key: ApiShapeId.abacus, path: abacus },
  { key: ApiShapeId.atoms, path: atoms },
  { key: ApiShapeId.chemical, path: chemical },
  { key: ApiShapeId.dna, path: dna },
  { key: ApiShapeId.drawingCompass, path: drawingCompass },
  { key: ApiShapeId.education, path: education },
  { key: ApiShapeId.magnet, path: magnet },
  { key: ApiShapeId.math, path: math },
  { key: ApiShapeId.microscope, path: microscope },
  { key: ApiShapeId.radioactive, path: radioactive },
  { key: ApiShapeId.triangularRuler, path: triangularRuler },
  { key: ApiShapeId.world, path: world },
];

export const signIcons: Shape[] = [
  { key: ApiShapeId.biohazard, path: biohazard },
  { key: ApiShapeId.branch, path: branch },
  { key: ApiShapeId.check, path: check },
  { key: ApiShapeId.cross, path: cross },
  { key: ApiShapeId.danger, path: danger },
  { key: ApiShapeId.halloween, path: halloween },
  { key: ApiShapeId.highVoltage, path: highVoltage },
  { key: ApiShapeId.recycle, path: recycle },
  { key: ApiShapeId.stop, path: stop },
  { key: ApiShapeId.trafficSign, path: trafficSign },
  { key: ApiShapeId.wifi, path: wifi },
  { key: ApiShapeId.wip, path: wip },
];

export const sportIcons: Shape[] = [
  { key: ApiShapeId.baseball, path: baseball },
  { key: ApiShapeId.baseball2, path: baseball2 },
  { key: ApiShapeId.basketball, path: basketball },
  { key: ApiShapeId.football1, path: football1 },
  { key: ApiShapeId.football2, path: football2 },
  { key: ApiShapeId.golf, path: golf },
  { key: ApiShapeId.rugby, path: rugby },
  { key: ApiShapeId.swim, path: swim },
  { key: ApiShapeId.tennis, path: tennis },
  { key: ApiShapeId.tennisBall, path: tennisBall },
  { key: ApiShapeId.trophy, path: trophy },
  { key: ApiShapeId.volleyball, path: volleyball },
];

export const toolIcons: Shape[] = [
  { key: ApiShapeId.bucket, path: bucket },
  { key: ApiShapeId.calipers, path: calipers },
  { key: ApiShapeId.compass, path: compass },
  { key: ApiShapeId.hammer, path: hammer },
  { key: ApiShapeId.marker, path: marker },
  { key: ApiShapeId.pen, path: pen },
  { key: ApiShapeId.pencil, path: pencil },
  { key: ApiShapeId.pliers, path: pliers },
  { key: ApiShapeId.saw, path: saw },
  { key: ApiShapeId.screwdriver, path: screwdriver },
  { key: ApiShapeId.setsquare, path: setsquare },
  { key: ApiShapeId.wrench, path: wrench },
];

export const transportIcons: Shape[] = [
  { key: ApiShapeId.airplane, path: airplane },
  { key: ApiShapeId.bus, path: bus },
  { key: ApiShapeId.car, path: car },
  { key: ApiShapeId.cycle, path: cycle },
  { key: ApiShapeId.helicopter, path: helicopter },
  { key: ApiShapeId.hotAirBalloon, path: hotAirBalloon },
  { key: ApiShapeId.motorcycle, path: motorcycle },
  { key: ApiShapeId.motorizedScooter, path: motorizedScooter },
  { key: ApiShapeId.rocket, path: rocket },
  { key: ApiShapeId.ship, path: ship },
  { key: ApiShapeId.train, path: train },
  { key: ApiShapeId.van, path: van },
];

export const weatherIcons: Shape[] = [
  { key: ApiShapeId.cloud, path: cloud },
  { key: ApiShapeId.fogDay, path: fogDay },
  { key: ApiShapeId.heavyRainDay, path: heavyRainDay },
  { key: ApiShapeId.heavySnow, path: heavySnow },
  { key: ApiShapeId.highWindSpeeds, path: highWindSpeeds },
  { key: ApiShapeId.hotThermometer, path: hotThermometer },
  { key: ApiShapeId.mist, path: mist },
  { key: ApiShapeId.moonWithStars, path: moonWithStars },
  { key: ApiShapeId.sunny, path: sunny },
  { key: ApiShapeId.sunnyIntervals, path: sunnyIntervals },
  { key: ApiShapeId.thermometerFrost, path: thermometerFrost },
  { key: ApiShapeId.thunderstorm, path: thunderstorm },
];

export const allShapes = unnest([
  animalIcons,
  basicIcons,
  deviceIcons,
  emojiIcons,
  floralIcons,
  foodIcons,
  gameIcons,
  handsIcons,
  moneyIconsIcons,
  officeIcons,
  peopleIcons,
  processIcons,
  processExtendedIcons,
  scienceIcons,
  signIcons,
  sportIcons,
  toolIcons,
  transportIcons,
  weatherIcons,
]).reduce<AllShapes>((acc, shape) => {
  acc[shape.key] = shape;
  return acc;
}, {});

export const shapes: Record<string, Array<Shape>> = {
  animals: animalIcons,
  basic: basicIcons,
  devices: deviceIcons,
  emoticons: emojiIcons,
  floral: floralIcons,
  food: foodIcons,
  game: gameIcons,
  money: moneyIconsIcons,
  office: officeIcons,
  people: peopleIcons,
  process: processIcons,
  processExtended: processExtendedIcons,
  science: scienceIcons,
  hands: handsIcons,
  sign: signIcons,
  sport: sportIcons,
  tools: toolIcons,
  transport: transportIcons,
  weather: weatherIcons,
} as const;
