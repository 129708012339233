import React, { PropsWithChildren, ReactElement } from "react";
import { Link } from "react-router-dom";
import { Box, Flex, ThemeUIStyleObject } from "theme-ui";
import { useUISettings } from "../../../../reduxStore/uiSettings";
import { useCustomerComponents } from "../../../licensing/useCustomerComponents";
import LogoImage from "./LogoImage";

type Props = PropsWithChildren<{
  withDisclaimer?: boolean;
  size?: string;
  sx?: ThemeUIStyleObject;
}>;

function Logo({ withDisclaimer, children, size, sx }: Props): ReactElement {
  const { getLogoConfig } = useUISettings();

  const logoConfig = getLogoConfig();
  const { Logo, Disclaimer } = useCustomerComponents();

  return (
    <Flex sx={{ flexDirection: "column" }}>
      <Flex variant="layout.fullLogo" sx={sx}>
        <Link to="/" aria-label="logoLink">
          <Box
            px={[2, 3]}
            py={1}
            sx={{
              pointerEvents: "auto",
              position: "relative",
            }}
          >
            {logoConfig ? (
              <LogoImage {...logoConfig} />
            ) : (
              <Logo colorized size={size} />
            )}
          </Box>
        </Link>
        {
          /**
           * TODO: Figure out how to add flexible TagLine support to UISettings
           *
           * Until then we simply disable the TagLine (children) if a logo
           * config is available.
           */
          !logoConfig && children
        }
      </Flex>
      {withDisclaimer && Disclaimer && <Disclaimer />}
    </Flex>
  );
}
export default Logo;
