import { fabric } from "fabric";
import i18n from "i18next";
import { clone, isNil } from "ramda";
import { v4 as uuid } from "uuid";
import { canvasObjectIds } from "../../../const";
import { signIcons } from "../../../shapes";
import { ApiShapeId } from "../../../shapes/shapeId";
import { roundToXdecimal } from "../../../tools";
import { isTransparent } from "../../../tools/colors";
import { ShapeVersion } from "../../../types/enum";
import { isMoveTransform } from "../../utils/event";
import {
  calcInitialScale,
  calcTransformState,
  isGroup,
  isInActiveSelection,
  isShapeText,
} from "../../utils/fabricObjects";
import { LiveColor } from "../color/liveColor";
import { zoomCacheMixin } from "../object/collaboardObjectCustom.zoom_cache_mixin";
import {
  parseShapeSettings,
  preventFullTransparency,
  shapeReviver,
} from "./shape.utils";
import { createShapeInnerTextBox } from "./shapeInnerTextBox";

(() => {
  if (fabric.CollaboardShape) {
    return;
  }

  /**
   * Shape (from SVG)
   */
  fabric.CollaboardShape = fabric.util.createClass(fabric.Group, {
    // NOTE: The value of type **must** match the name of the class.
    type: canvasObjectIds.shape,
    version: ShapeVersion.ShapeUnscaledText,

    shallowRenderWidth: 50,

    allowAttachments: true,
    padding: 10,
    internalPadding: 10,
    lockUniScaling: false,
    lockScalingFlip: true,
    hasRotatingPoint: false,
    // don't change originX/originY
    // https://ibvsolutions.visualstudio.com/CollaBoardWeb/_wiki/wikis/CollaBoardWeb.wiki/364/Coordinate-system
    originX: "left",
    originY: "top",
    // This setting means the stroke is recalculated while scaling.
    // It looks nicer but has a performance impact
    objectCaching: false,

    /**
     * This is enabled on Group (to support selection within group), but as we
     * don't need that feature here and shapes extend Group we must disable it.
     */
    subTargetCheck: false,

    /**
     * Solid geometric objects should use `false`, while intricate objects with
     * lots of transparency (such as Text) should use `true`.
     */
    perPixelTargetFind: true,

    /**
     * #6609: use strokeUniform so that the bounding box takes shape's strokeWidth
     * into account
     */
    strokeUniform: true,

    /**
     * Create a single object (`fabric.Group`) given a list of elements.
     *
     * @param {Array<fabric.Object>} shapeElements List of elements created from
     * SVG
     * @param {object} options Fabric options
     * @returns {fabric.Group} The shape object
     */
    initialize(
      this: fabric.CollaboardShape,
      shapeElements: fabric.Path[],
      options: ShapeConfig & {
        svgScale?: number;
      }
    ) {
      /**
       * Store the styling options in a settings object, because we don't want
       * to set the fill / stroke of the Group, just the underlying objects.
       */
      this._settings = parseShapeSettings(options.contextProps);

      const isShapeOtherThanLine = this._isShapeOtherThanLine(options.shape);
      const { width = 0, height = 0, svgScale = 1 } = options;

      if (shapeElements.length > 1) {
        /**
         * If the SVG has multiple paths, we need to group them so that scaling
         * happens using the group center as origin
         */
        const group = fabric.util.groupSVGElements([...shapeElements], {
          width,
          height,
        });
        group.scale(svgScale);
        isGroup(group) && group.destroy();
      } else {
        const object = shapeElements[0];
        object.set({
          scaleX: svgScale,
          scaleY: svgScale,
          originX: "center",
          originY: "center",
          left: width / 2,
          top: height / 2,
        });
        object.setCoords();
      }

      shapeElements.forEach((e) => {
        // Disable cache to make the stroke sharp. They also render faster than cache
        // images as shapes have a small number of paths
        e.objectCaching = false;
      });

      this.callSuper("initialize", shapeElements, {
        uuid: uuid(),
        // Set the group's center point from the SVG dimension just as `groupSVGElements` does
        centerPoint: {
          x: width / 2,
          y: height / 2,
        },
        ...options,
      });

      if (this.shape.isBroken) {
        this.width = this.viewBoxWidth;
        this.height = this.viewBoxHeight;
      }

      if (isShapeOtherThanLine && options._textContent?.text) {
        this.createTextObject(options._textContent);
      }

      if (isShapeOtherThanLine) {
        this.on("custom:object:undoRedoModify", (props) => {
          props._textContent && this.updateText(props._textContent);
          this._textObject && this.setTextObjectScale();
        });

        this.on("scaling", () => {
          this._textObject && this.setTextObjectScale();
        });

        this.on("mouseup", (e: fabric.IEvent) => {
          const {
            __lastSelected,
            _textObject,
            canvas,
            shallowRenderWidth,
          } = this;
          const { x: width } = this._calculateCurrentDimensions();
          if (width < shallowRenderWidth || !canvas || this.isReadOnly()) {
            return;
          }

          const transform = canvas._currentTransform;

          if (
            transform &&
            (transform.action.startsWith("scale") ||
              transform.action === "rotate" ||
              isMoveTransform(transform, this))
          ) {
            return;
          }

          if (__lastSelected && !canvas.isViewOnlyMode) {
            if (!_textObject) {
              this.createTextObject(options._textContent || {});
            }

            delete this.__lastSelected;
            this.enterEditMode(e);
          } else {
            this.__lastSelected = this === canvas._activeObject;
          }
        });
      }

      // Don't need to call it as it's done by the underlying fabric.Group
      // this.initializeCollaboardObject();
    },

    createTextObject(
      this: fabric.CollaboardShape,
      textContent: ShapeInnerTextConstructorConfig
    ) {
      const isNewShape = !this.needsMigration() && !textContent.text;

      isNewShape && this.beginTransform({ reversible: false });

      this._textObject = createShapeInnerTextBox({
        ...textContent,
        shape: this,
      });

      this.setTextObjectScale();
      this.add(this._textObject);

      // Send deltas in the initial text properties, e.g. initial fontSize based
      // on shape scale. CustomEditing syncs cannot detect them.
      isNewShape && this.commitTransform();
    },

    updateText(
      this: fabric.CollaboardShape,
      textContent: ShapeInnerTextConstructorConfig
    ) {
      if (isNil(textContent?.text)) {
        return;
      }

      if (!this._textObject) {
        this.createTextObject(textContent);
      }

      if (this._textObject) {
        this._textObject.set({ text: textContent.text });
        this._textObject.positionByParent(this);
      }
    },

    onDeselect(this: fabric.CollaboardShape) {
      if (this.isEditingMode()) {
        this._textObject?.exitEditing();
        this.exitEditMode();
      }

      delete this.__lastSelected;
    },

    setTextObjectScale(this: fabric.CollaboardShape) {
      if (!this._textObject) {
        return;
      }

      this.version === ShapeVersion.ShapeUnscaledText
        ? this._unscaleText()
        : this._scaleTextWithShape();
    },

    /**
     * Each shape version has its own way to manipulate the text scale. This
     * method returns the logical expected value
     */
    // eslint-disable-next-line consistent-return
    getTextScale(
      this: fabric.CollaboardShape
    ): { scaleX: number; scaleY: number } {
      switch (this.version) {
        case ShapeVersion.Shape:
          return { scaleX: this.scaleX || 1, scaleY: this.scaleY || 1 };
        case ShapeVersion.ShapeUnscaledText:
          return { scaleX: 1, scaleY: 1 };
      }
    },

    _unscaleText(this: fabric.CollaboardShape) {
      // Include scale from parent group
      const { scaleX, scaleY } = calcTransformState(this);

      // Invert the group scale so that text appears as initial
      const invertedScaleX = 1 / scaleX;
      const invertedScaleY = 1 / scaleY;

      this._textObject?.set({
        width: this.width * scaleX - this.internalPadding * 2,
        scaleX: invertedScaleX,
        scaleY: invertedScaleY,
      });

      this._textObject?.positionByParent(this);
    },

    /**
     * Legacy behaviour, kept to correctly render legacy shapes before they are
     * selected and migrated
     *
     */
    _scaleTextWithShape(this: fabric.CollaboardShape) {
      const { scaleX = 1, scaleY = 1 } = this;

      // Always scale by the smallest dimension
      const minBaseScale = Math.min(scaleX, scaleY);

      // Invert the group scale so that text appears as initial
      const invertedScaleX = 1 / scaleX;
      const invertedScaleY = 1 / scaleY;

      // Uniformly apply the base scale to the initial text so that it's
      // not stretched. The final value will always be 1 for one of the two
      // so that text is scaled based on the group scale as usual.
      const updatedScaleX = minBaseScale * invertedScaleX;
      const updatedScaleY = minBaseScale * invertedScaleY;

      // New width difference that the text has now available
      const widthOffset = (this.width * scaleX) / minBaseScale - this.width;

      this._textObject?.set({
        width: this.width + widthOffset,
        scaleX: updatedScaleX,
        scaleY: updatedScaleY,
      });

      this._textObject?.positionByParent(this);
    },

    enterEditMode(
      this: fabric.CollaboardShape,
      { target }: { target?: fabric.Object }
    ) {
      const isSubselectingEvent = target !== this;
      if (
        this._textObject &&
        !isInActiveSelection(this) &&
        !this.isLocked() &&
        !isSubselectingEvent &&
        this.canvas
      ) {
        const { canvas } = this;

        this._textObject._originalTransform = fabric.util.saveObjectTransform(
          this._textObject
        );
        /**
         * We apply the transform ourselves and remove the textObject silently,
         * instead of calling `this.removeWithUpdate()` as it would apply the
         * transform to every object and reset the group transform, e.g. scaling
         * value. Although it's visually equivalent, we want to maintain the
         * group's transform values, as they are used as reference for the shape's transform.
         */
        this._restoreObjectState(this._textObject);
        this.removeSilently(this._textObject);

        canvas.addSilently(this._textObject);

        canvas.requestRenderAll();
        if (!this.isPartOfGroup()) {
          canvas.setActiveObject(this._textObject, { isSilent: true });
        }

        this._textObject.positionByParent(this);

        // we need disable it to not allow user select and move shape while shape is in edit mode
        this.selectable = false;
        this.evented = false;

        // start editing mode
        this._textObject.enterEditing();
      }
    },

    exitEditMode(this: fabric.CollaboardShape) {
      this._textObject?.positionByParent(this);
      this.selectable = true;
      this.evented = true;
    },

    // Added just for consistency with postZoom
    _preZoom(this: fabric.CollaboardShape) {
      this._textObject?._preZoom?.();
    },

    /**
     * When the shape is in editing mode, the shapeInnerTextBox is the selected object and on which
     * preZoom is called, but after the zoom the selected object is the shape. We have therefore to
     * propagate the call to the shapeInnerTextBox.
     */
    _postZoom(this: fabric.CollaboardShape) {
      this._textObject?._postZoom?.();
    },

    isEditingMode(this: fabric.CollaboardShape) {
      return this._textObject && this._textObject.isEditingMode();
    },

    isPartOfGroup(this: fabric.CollaboardShape): boolean {
      return !!this.group;
    },

    set(
      this: fabric.CollaboardShape,
      key: Partial<fabric.CollaboardShape>,
      value: unknown
    ) {
      // Ensure width, height and scale are adjusted when there is a server update
      // with legacy values
      if (
        typeof key === "object" &&
        key.width &&
        key.height &&
        key.scaleX &&
        key.scaleY
      ) {
        const props = adjustLegacyScale(key, {
          width: this.svgWidth,
          height: this.svgHeight,
        });
        return this.callSuper("set", props, value);
      } else {
        return this.callSuper("set", key, value);
      }
    },

    /**
     * Modify the context properties. Called by React.
     */
    setContextProps(
      this: fabric.CollaboardShape,
      {
        fillColor = this._settings.fillColor,
        strokeColor = this._settings.strokeColor,
        strokeWidth = this._settings.strokeWidth,
        ...rest
      }: ContextProps,
      config?: SetContextPropsConfig
    ) {
      const {
        fill: safeFill,
        stroke: safeStroke,
      } = this._preventFullTransparency(fillColor, strokeColor);
      const liveFill = new LiveColor(safeFill);
      const liveStroke = new LiveColor(safeStroke);

      this._settings.fillColor = safeFill;
      this._settings.strokeColor = safeStroke;
      this._settings.strokeWidth = strokeWidth;

      // #6609: apply strokeWidth to the group as well to have correct bounding box
      this.strokeWidth = strokeWidth;

      // Apply values to child objects of group, i.e. the parts of the shape
      this.forEachObject((child) => {
        !isShapeText(child) &&
          child.set({
            strokeWidth,
            fill: liveFill,
            stroke: liveStroke,
          });
      });

      // update inner text object,
      if (this._textObject) {
        this._textObject.setContextProps(rest, config);
        this._textObject.positionByParent(this);
      }

      const event: ClientModifiedEvent = {
        transform: { action: "ctxPropsChange" },
      };
      this.trigger("modified", event);
    },

    /**
     * Reveal the context properties. Called by React.
     */
    getContextProps(this: fabric.CollaboardShape): ContextProps {
      return {
        ...this._settings,
        ...(this._textObject?.getContextProps() || {}),
      };
    },

    onUpdateFromServer(
      this: fabric.CollaboardShape,
      { _textContent }: Partial<fabric.CollaboardShape>
    ) {
      _textContent && this.updateText(_textContent);
      this._textObject && this.setTextObjectScale();
    },

    supportsTransparency() {
      return true;
    },

    supportsDarkMode() {
      return true;
    },

    supportsAutoFontSize() {
      return false;
    },

    hasEditableStroke() {
      return true;
    },

    hasEditableText(this: fabric.CollaboardShape) {
      return !!this._textObject;
    },

    hasEditableFillColor() {
      return true;
    },

    /**
     * Ensure the shape can never be fully transparent (i.e. invisible)
     */
    _preventFullTransparency(
      this: fabric.CollaboardShape,
      newFill: string,
      newStroke: string
    ) {
      const { fillColor, strokeColor } = this._settings;
      return preventFullTransparency(
        newFill,
        newStroke,
        fillColor,
        strokeColor
      );
    },

    _isShapeOtherThanLine(this: fabric.CollaboardShape, shapeInfo: Shape) {
      return ![ApiShapeId.longLine, ApiShapeId.line].includes(shapeInfo?.key);
    },

    drawObject(this: fabric.CollaboardShape, ctx: CanvasRenderingContext2D) {
      const { x: width } = this._calculateCurrentDimensions();

      if (this.canvas?.interactive && width < this.shallowRenderWidth) {
        this.getObjects()
          .filter((obj) => !isShapeText(obj))
          .forEach((obj) => obj.render(ctx));

        this._drawClipPath(ctx);
      } else {
        this.callSuper("drawObject", ctx);
      }
    },
  });

  fabric.util.object.extend(
    fabric.CollaboardShape.prototype,
    zoomCacheMixin(fabric.CollaboardShape.prototype)
  );
})();

const shapeRequestByPath = new Map();

/**
 * Create a shape from a local (same origin) SVG URL.
 */
export const createShape = (
  settings: ShapeConfig,
  skipScaling = false
): Promise<fabric.CollaboardShape> => {
  const {
    shape: { path: pathToSVG, customProps, isBroken, key } = {},
  } = settings;

  // If we have been unable to identify the correct SVG path to use, stop here
  if (isBroken) {
    return Promise.resolve(createFallbackShape(settings));
  }

  if (!pathToSVG) {
    return Promise.reject(i18n.t("clientError.cannotLoadShape", { key }));
  }

  const toCollaboardShape = ([shapeElements, options]: [
    fabric.Path[],
    ShapeConstructorConfig
  ]) => {
    if (!shapeElements) {
      // Avoid rendering nothing if the SVG file could not load
      return createFallbackShape(settings);
    }

    // Clone shapeElements because fabric.Group does mutations by reference
    const elements = clone(shapeElements);

    if (skipScaling) {
      return new fabric.CollaboardShape(elements, {
        ...(customProps as Partial<fabric.CollaboardShape>),
        ...options,
        ...settings,
      });
    }

    const { width = 0, height = 0 } = options;
    const svgScale = calcInitialScale(width, height);
    const svgOptions = {
      ...options,
      width: width * svgScale,
      height: height * svgScale,
      svgWidth: width,
      svgHeight: height,
      svgScale,
    };

    const finalSettings = adjustLegacyScale(settings, options);

    return new fabric.CollaboardShape(elements, {
      ...(customProps as Partial<fabric.CollaboardShape>),
      ...svgOptions,
      ...finalSettings,
    });
  };

  const cachedRequest = shapeRequestByPath.get(pathToSVG);

  if (cachedRequest) {
    return cachedRequest.then(toCollaboardShape);
  }

  const request = new Promise<[fabric.Path[], ShapeConstructorConfig]>(
    (resolve) => {
      fabric.loadSVGFromURL(
        pathToSVG,
        (shapeElements, options) => {
          resolve([shapeElements as fabric.Path[], options]);
        },
        shapeReviver(settings.contextProps || {})
      );
    }
  );
  shapeRequestByPath.set(pathToSVG, request);

  return request.then(toCollaboardShape);
};

/**
 * This is to support legacy shape tiles, where width/height were unscaled,
 * whereas the scale was increased by the initial svgScale
 */
export const adjustLegacyScale = (
  settings: Partial<fabric.CollaboardShape>,
  options: { width?: number; height?: number }
): Partial<fabric.CollaboardShape> => {
  if (
    settings.width &&
    options.width &&
    settings.height &&
    options.height &&
    Math.round(settings.width) === Math.round(options.width) &&
    Math.round(settings.height) === Math.round(options.height) &&
    settings.scaleX &&
    settings.scaleY
  ) {
    const tileScale = calcInitialScale(settings.width, settings.height);

    return {
      ...settings,
      width: roundToXdecimal(settings.width * tileScale),
      height: roundToXdecimal(settings.height * tileScale),
      scaleX: roundToXdecimal(settings.scaleX / tileScale),
      scaleY: roundToXdecimal(settings.scaleY / tileScale),
      isLegacyScale: true,
    };
  }

  return settings;
};

const createFallbackShape = (settings: ShapeConfig) => {
  const { shape, fillColor } = settings;
  return createShape({
    ...settings,
    shape: {
      ...shape,
      ...signIcons.find((s) => s.key === ApiShapeId.danger),
      isBroken: false,
    },
    contextProps: {
      ...settings.contextProps,
      textColor: fillColor && isTransparent(fillColor) ? undefined : fillColor,
    },
    _textContent: {
      text: i18n.t("clientError.cannotLoadShape", { key: shape?.key }),
    },
  });
};
