import { useState } from "react";
import { queryCache } from "react-query";
import { useTranslation } from "react-i18next";

import errorCodes from "../../../../api/errorCodes";
import { ProductTypeIDs } from "../../../../const";
import { billingTypes } from "../../../licensing/payment/enums";
import { useTotalPriceQuery } from "../usePrices";
import { useOrderCreation } from "../useOrderCreation";

const GET_TOTAL_QUERY_KEY = "GetPriceIndividual";

export const useIndividualLicenseOrder = (currencyCode, isOpen) => {
  const { t } = useTranslation();
  const [promoCode, setPromoCode] = useState("");
  const [billingType, setBilling] = useState(billingTypes.annually);
  const [promoCodeDisabled, setPromoCodeDisabled] = useState(false);

  const { goToCheckout } = useOrderCreation({
    productId: ProductTypeIDs.individual,
    billingType,
    currencyCode,
    promoCode,
    numberOfDevices: 0,
    numberOfUsers: 1,
  });

  const onPromoCodeChange = (e) => {
    setPromoCode(e.target.value.trim());
  };

  const onBillingChange = (value) => {
    setBilling(value);
  };

  const {
    total,
    numberOfFreeMonths,
    totalError,
    isSuccess,
    isError,
  } = useTotalPriceQuery({
    billingType,
    currencyCode,
    promoCodeDisabled,
    promoCode,
    isOpen,
    queryKey: GET_TOTAL_QUERY_KEY,
    productId: ProductTypeIDs.individual,
  });

  const clearPromoCode = async () => {
    await queryCache.invalidateQueries(GET_TOTAL_QUERY_KEY);
    setPromoCodeDisabled(false);
    setPromoCode("");
  };

  const getTotalError = () =>
    totalError?.ErrorCode && t(`apiError.${errorCodes[totalError?.ErrorCode]}`);

  return {
    total,
    numberOfFreeMonths,
    totalError: getTotalError(),
    promoCode,
    promoCodeDisabled,
    billingType,
    arePricesLoaded: isSuccess || isError,
    onBillingChange,
    onPromoCodeChange,
    clearPromoCode,
    goToCheckout,
    updateTotal: async () => {
      await queryCache.invalidateQueries(GET_TOTAL_QUERY_KEY);
      setPromoCodeDisabled(true);
    },
  };
};
