import {
  TabPanel as ReachTabPanel,
  TabPanels as ReachTabPanels,
  useTabsContext,
} from "@reach/tabs";
import React, { PropsWithChildren, ReactElement } from "react";
import { Flex, ThemeUIStyleObject } from "theme-ui";

export function TabPanels(
  props: PropsWithChildren<unknown>
): ReactElement | null {
  const { selectedIndex } = useTabsContext();
  const { children } = props;

  return (
    <ReachTabPanels as={Flex} sx={panelsStyle} {...props}>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child as ReactElement, {
          isSelected: selectedIndex === index,
        })
      )}
    </ReachTabPanels>
  );
}

const panelsStyle: ThemeUIStyleObject = {
  flex: 1,
};

export function TabPanel(
  props: PropsWithChildren<{ isSelected?: boolean }>
): ReactElement | null {
  const { isSelected, children } = props;

  return (
    <ReachTabPanel as={Flex} sx={activePanelStyle}>
      {isSelected ? children : null}
    </ReachTabPanel>
  );
}

const activePanelStyle: ThemeUIStyleObject = {
  flex: 1,

  "&[hidden]": {
    display: "none",
  },
};
