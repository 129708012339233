import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function QRCode(props: IconProps): ReactElement {
  return (
    <Icon viewBox="0 0 51.7 51.7" {...props}>
      <path d="M14.25,0H1.38A1.38,1.38,0,0,0,0,1.38V14.25a1.37,1.37,0,0,0,1.38,1.37H14.25a1.37,1.37,0,0,0,1.37-1.37V1.38A1.37,1.37,0,0,0,14.25,0Zm-.62,12.25a1.38,1.38,0,0,1-1.38,1.38H3.37A1.37,1.37,0,0,1,2,12.25V3.37A1.37,1.37,0,0,1,3.37,2h8.88a1.37,1.37,0,0,1,1.38,1.37Z" />
      <rect x="6.43" y="6.43" width="2.76" height="2.76" rx="0.22" />
      <rect x="17.92" y="7.05" width="2.76" height="2.76" rx="0.22" />
      <rect x="21.14" y="3.6" width="2.76" height="2.76" rx="0.22" />
      <rect x="27.8" y="3.6" width="2.76" height="2.76" rx="0.22" />
      <rect x="24.58" y="7.05" width="2.76" height="2.76" rx="0.22" />
      <rect x="24.58" y="0.15" width="2.76" height="2.76" rx="0.22" />
      <rect x="31.25" y="7.05" width="2.76" height="2.76" rx="0.22" />
      <rect
        x="31.25"
        y="41.89"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(65.25 86.54) rotate(180)"
      />
      <rect
        x="28.03"
        y="45.34"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(58.82 93.43) rotate(180)"
      />
      <rect
        x="21.37"
        y="45.34"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(45.49 93.43) rotate(180)"
      />
      <rect
        x="24.58"
        y="41.89"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(51.92 86.54) rotate(180)"
      />
      <rect
        x="24.58"
        y="48.78"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(51.92 100.33) rotate(180)"
      />
      <rect
        x="17.92"
        y="41.89"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(38.6 86.54) rotate(180)"
      />
      <rect
        x="42.01"
        y="17.81"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(62.57 -24.2) rotate(90)"
      />
      <rect
        x="45.45"
        y="21.02"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(69.23 -24.43) rotate(90)"
      />
      <rect
        x="45.45"
        y="27.69"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(75.9 -17.77) rotate(90)"
      />
      <rect
        x="42.01"
        y="24.47"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(69.23 -17.54) rotate(90)"
      />
      <rect
        x="48.9"
        y="24.47"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(76.13 -24.43) rotate(90)"
      />
      <rect
        x="42.01"
        y="31.13"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(75.9 -10.88) rotate(90)"
      />
      <rect
        x="7.16"
        y="31.13"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(-23.97 41.05) rotate(-90)"
      />
      <rect
        x="3.71"
        y="27.92"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(-24.2 34.39) rotate(-90)"
      />
      <rect
        x="3.71"
        y="21.25"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(-17.54 27.72) rotate(-90)"
      />
      <rect
        x="7.16"
        y="24.47"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(-17.31 34.39) rotate(-90)"
      />
      <rect
        x="0.27"
        y="24.47"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(-24.2 27.49) rotate(-90)"
      />
      <rect
        x="7.16"
        y="17.81"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(-10.65 27.72) rotate(-90)"
      />
      <rect
        x="31.51"
        y="31.13"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(0.38 65.4) rotate(-90)"
      />
      <rect
        x="28.07"
        y="27.92"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(0.15 58.74) rotate(-90)"
      />
      <rect
        x="28.07"
        y="21.25"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(6.82 52.08) rotate(-90)"
      />
      <rect
        x="31.51"
        y="24.47"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(7.05 58.74) rotate(-90)"
      />
      <rect
        x="31.51"
        y="17.81"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(13.71 52.08) rotate(-90)"
      />
      <rect
        x="17.6"
        y="17.81"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(38.16 0.21) rotate(90)"
      />
      <rect
        x="21.04"
        y="21.02"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(44.82 -0.02) rotate(90)"
      />
      <rect
        x="21.04"
        y="27.69"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(51.48 6.64) rotate(90)"
      />
      <rect
        x="17.6"
        y="24.47"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(44.82 6.87) rotate(90)"
      />
      <rect
        x="17.6"
        y="31.13"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(51.48 13.54) rotate(90)"
      />
      <rect
        x="24.51"
        y="31.13"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(-6.62 58.4) rotate(-90)"
      />
      <rect
        x="24.51"
        y="24.47"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(0.04 51.74) rotate(-90)"
      />
      <rect
        x="24.51"
        y="17.81"
        width="2.76"
        height="2.76"
        rx="0.22"
        transform="translate(6.71 45.08) rotate(-90)"
      />
      <path d="M50.32,0H37.45a1.38,1.38,0,0,0-1.38,1.38V14.25a1.37,1.37,0,0,0,1.38,1.37H50.32a1.37,1.37,0,0,0,1.38-1.37V1.38A1.38,1.38,0,0,0,50.32,0ZM49.7,12.25a1.38,1.38,0,0,1-1.38,1.38H39.45a1.38,1.38,0,0,1-1.38-1.38V3.37A1.37,1.37,0,0,1,39.45,2h8.87A1.37,1.37,0,0,1,49.7,3.37Z" />
      <rect x="42.5" y="6.43" width="2.76" height="2.76" rx="0.22" />
      <rect x="6.43" y="42.58" width="2.76" height="2.76" rx="0.22" />
      <rect x="42.5" y="42.58" width="2.76" height="2.76" rx="0.22" />
      <path d="M14.25,36.07H1.38A1.38,1.38,0,0,0,0,37.45V50.32A1.38,1.38,0,0,0,1.38,51.7H14.25a1.37,1.37,0,0,0,1.37-1.38V37.45A1.37,1.37,0,0,0,14.25,36.07Zm-.62,12.25a1.38,1.38,0,0,1-1.38,1.38H3.37A1.37,1.37,0,0,1,2,48.32V39.45a1.37,1.37,0,0,1,1.37-1.38h8.88a1.38,1.38,0,0,1,1.38,1.38Z" />
      <path d="M50.32,36.07H37.45a1.38,1.38,0,0,0-1.38,1.38V50.32a1.38,1.38,0,0,0,1.38,1.38H50.32a1.38,1.38,0,0,0,1.38-1.38V37.45A1.38,1.38,0,0,0,50.32,36.07ZM49.7,48.32a1.38,1.38,0,0,1-1.38,1.38H39.45a1.38,1.38,0,0,1-1.38-1.38V39.45a1.37,1.37,0,0,1,1.38-1.38h8.87a1.38,1.38,0,0,1,1.38,1.38Z" />
    </Icon>
  );
}
export default QRCode;
