export const urlRegex = /https?:\/\/(www\.)?[-a-z0-9@:%._+~#=]{1,256}\.[a-z0-9()]{1,6}\b([-a-z0-9()@:%_+.~#?&//=!;]*)/gim;

// NOTE: react-mentions markup format is @[display](id)
export const chatMentionRegex = /@\[(.+?)\]\((.+?)\)/gm;

export const iframeRegex = /<iframe\s.*?src=("|')(https?:\/\/(www\.)?[-a-z0-9@:%._+~#=]{1,256}\.[a-z0-9()]{1,6}\b([-a-z0-9()@:%_+.~#?&//=!;]*))("|').*?>\s*<\/iframe>/gim;

export const htmlRegex = /<\/?[a-z][\s\S]*>/i;

export const userNameRegex = /^\s*(\p{L}{1,}([.,] |[-']| )?)+\p{L}*\.?\s*$/iu;
