import { Form, Formik } from "formik";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Box, Button, Flex, Heading, Text, ThemeUIStyleObject } from "theme-ui";
import { routes } from "../../../const";
import {
  isStaticFeatureActive,
  staticFeatureFlags,
} from "../../../tools/flags";
import InputField from "../../shared/forms/fields/InputField";
import { useSendToken } from "./useSendToken";

const registrationDisabled = isStaticFeatureActive(
  staticFeatureFlags.REGISTRATION_DISABLED
);

function SendToken({ onSendToken }: SendTokenProps): ReactElement {
  const { t } = useTranslation();
  const { search } = useLocation();

  const { formikProps } = useSendToken({ onSendToken });

  return (
    <Formik {...formikProps}>
      {({ isSubmitting, isValid }) => (
        <Form>
          <Flex as="fieldset">
            <Heading as="legend">{t("form.header.resetPassword")}</Heading>
            <InputField name="User" autoComplete="email username" />
          </Flex>
          <Flex sx={buttonsBarStyle}>
            <Box sx={buttonStyle}>
              <Text mb={1}>
                <Link
                  to={{
                    pathname: routes.authenticate,
                    search,
                  }}
                >
                  {t("form.button.login")}
                </Link>
              </Text>
              {!registrationDisabled && (
                <Text mb={1}>
                  <Link
                    to={{
                      pathname: routes.register,
                      search,
                    }}
                  >
                    {t("form.button.register")}
                  </Link>
                </Text>
              )}
            </Box>
            <Button
              type="submit"
              sx={buttonStyle}
              disabled={!isValid || isSubmitting}
            >
              {t("form.button.sendToken")}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}

const buttonsBarStyle: ThemeUIStyleObject = {
  flex: "1 0 100%",
  justifyContent: "space-between",
  mb: [3],
};

const buttonStyle: ThemeUIStyleObject = { flex: 1 };

export default SendToken;
