import { Form, Formik, FormikConfig } from "formik";
import React, { ReactElement, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Flex, Heading, ThemeUIStyleObject } from "theme-ui";
import { updateUserAndProfile } from "../../../../api";
import { updateUserProfileAction } from "../../../../reduxStore/auth/auth.actions";
import { LogCategory } from "../../../../tools/telemetry";
import { ApiUseCase } from "../../../../types/enum";
import { useLoggedInStatus } from "../../../authentication/useLoggedInStatus";
import CheckboxField from "../../../shared/forms/fields/CheckboxField";
import { useErrorHandler } from "../../../shared/hooks/useErrorHandler";
import useToast from "../../../shared/toasts/useToast";

type UseCaseKey = keyof typeof ApiUseCase;

type UseCaseFormValues = {
  [key in UseCaseKey]?: boolean;
};

function UserUseCases(): ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { fallbackErrorHandler } = useErrorHandler();
  const profileInfoSavedToast = useToast("users.profileInfoSaved");

  const { userProfile } = useLoggedInStatus();

  const formikProps: FormikConfig<UseCaseFormValues> = {
    initialValues: reduceUseCaseFormValues(userProfile?.UseCases ?? []),
    enableReinitialize: true,
    onSubmit: useCallback(
      async (values, actions) => {
        try {
          const payload = { UseCases: getUseCaseValues(values) };
          const user = await updateUserAndProfile(payload);
          dispatch(updateUserProfileAction(user));
          profileInfoSavedToast.show();
        } catch (error) {
          fallbackErrorHandler(
            error,
            "Failed to save user preferences",
            LogCategory.api
          );
        } finally {
          actions.setSubmitting(false);
        }
      },
      [dispatch, fallbackErrorHandler, profileInfoSavedToast]
    ),
  };

  return (
    <Formik {...formikProps}>
      {({ isSubmitting }) => (
        <Form id="user-info-preferences">
          <Flex variant="layout.flexWrap">
            <Heading as="h3" variant="text.h3">
              {t("app.personalUseCases")}
            </Heading>
            {useCaseKeys.map((useCase) => (
              <CheckboxField
                name={useCase}
                key={useCase}
                labelVariant="profile3"
              />
            ))}
          </Flex>

          {/* Notifications: Temporary commented out - until backend support is ready */}
          {/* <Flex as="fieldset">
            <Heading as="h3" variant="text.h3">
              {t(`app.notifications`)}
            </Heading>
            <CheckboxField name="WhenShared" />
            <CheckboxField name="AccessRequest" />
            <CheckboxField name="WhenAddedToProject" />
            <CheckboxField name="WhenInviteesSignUp" />
          </Flex>
          <Flex as="fieldset">
            <Heading as="h3" variant="text.h3">
              {t(`app.otherNotifications`)}
            </Heading>
            <CheckboxField name="FeaturedContent" />
            <CheckboxField name="ProductUpdates" />
          </Flex> */}
          <Flex variant="layout.submitButtonContainer" sx={submitButtonStyle}>
            <Button type="submit" variant="profile" disabled={isSubmitting}>
              {t("form.button.save")}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}

export default UserUseCases;

const submitButtonStyle: ThemeUIStyleObject = {
  height: 62,
  padding: "0!important",
};

// ApiUseCase is a numeric enum so we need to filter out the runtime reverse map
const useCases = Object.entries(ApiUseCase).filter(([key]) =>
  isNaN(Number(key))
) as [UseCaseKey, ApiUseCase][];

const useCaseKeys = useCases.map(([key]) => key);

const reduceUseCaseFormValues = (useCases: ApiUseCase[]): UseCaseFormValues => {
  return useCases.reduce((result, useCase) => {
    const key = getUseCaseKey(useCase);
    if (key) {
      result[key] = true;
    }
    return result;
  }, {} as UseCaseFormValues);
};

const getUseCaseValues = (formMap: UseCaseFormValues): ApiUseCase[] => {
  return Object.entries(formMap)
    .filter(([, value]) => !!value)
    .map(([key]) => ApiUseCase[key as UseCaseKey]);
};

const getUseCaseKey = (valueToFind: ApiUseCase): UseCaseKey | undefined => {
  const useCase = useCases.find(([, value]) => value === valueToFind);
  return useCase?.[0];
};
