import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Grid, Text, ThemeUIStyleObject } from "theme-ui";
import Checkmark from "../../../../icons/Checkmark";
import { getCustomerConfiguration } from "../../../../tools/customer";
import { usePlanFeatures } from "./usePlanFeatures";

const { compactPlansPage } = getCustomerConfiguration();

type Props = {
  data: Plan;
  horizontal?: boolean;
  sx: ThemeUIStyleObject;
};

function PlanFeatures({ sx, data, horizontal }: Props): ReactElement {
  const { t } = useTranslation();

  const { features } = usePlanFeatures({ data });

  return (
    <Box
      sx={{
        ...sx,
        ...containerStyle,
        borderTop: horizontal ? "1px solid" : "none",
        p: horizontal ? "32px 0" : "32px 10px",
      }}
    >
      {!compactPlansPage && (
        <Text sx={{ fontWeight: 700, height: "1.3rem", pl: [5], mb: [4] }}>
          {t(`plans.features.extends.${data.features.extends || "none"}`)}
        </Text>
      )}

      <Grid
        sx={{
          gridTemplateColumns: `repeat(${horizontal ? 3 : 1}, 1fr)`,
          gridRowGap: "1rem",
        }}
      >
        {features.map((feature) => (
          <Flex
            key={`${data.name}-${feature.order}-${feature.displayValue}`}
            sx={{
              alignItems: "center",
              svg: { height: "24px", width: "24px", fill: "primary", mr: [1] },
              p: horizontal ? "0 10px" : 0,
            }}
          >
            <Checkmark noShadow />
            <Text>{feature.displayValue}</Text>
          </Flex>
        ))}
      </Grid>
    </Box>
  );
}

const containerStyle = {
  bg: "background",
  color: "text",
  border: "1px solid",
  borderColor: "primary",
};

export default PlanFeatures;
