import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function OneTimePassword(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M7.36,15a1.17,1.17,0,0,1,1.18-1.17,1.15,1.15,0,0,1,1,1.17v1l.91-.52a1,1,0,0,1,1.43.39,1,1,0,0,1-.39,1.44l-.78.52.78.52A1.09,1.09,0,0,1,11.92,20a1,1,0,0,1-1.43.39l-.91-.52v.91a1.15,1.15,0,0,1-1,1.17,1.17,1.17,0,0,1-1.18-1.17v-.91l-.78.52A1.25,1.25,0,0,1,5,19.83a1,1,0,0,1,.39-1.43l.91-.52-.91-.52A1.05,1.05,0,0,1,5,15.92a1.09,1.09,0,0,1,1.56-.39l.78.52Z" />
      <path d="M18.82,15a1.06,1.06,0,0,1,1-1.17A1.17,1.17,0,0,1,21,15v1l.78-.52a1.11,1.11,0,0,1,1.56.39A1.05,1.05,0,0,1,23,17.36l-.91.52.91.52A1.2,1.2,0,0,1,23.38,20a1.24,1.24,0,0,1-1.56.39L21,19.83v.91a1.17,1.17,0,0,1-1.18,1.17,1.06,1.06,0,0,1-1-1.17v-.91l-.91.52a1.09,1.09,0,0,1-1.43-.52,1,1,0,0,1,.39-1.43l.78-.52-.78-.52a1,1,0,0,1-.39-1.44,1,1,0,0,1,1.43-.39l.91.52Z" />
      <path d="M30.15,15a1.11,1.11,0,1,1,2.22,0v1l.91-.52a1,1,0,0,1,1.43.39,1.05,1.05,0,0,1-.39,1.44l-.91.52.91.52A1.2,1.2,0,0,1,34.71,20a1,1,0,0,1-1.43.39l-.91-.52v.91a1.11,1.11,0,1,1-2.22,0v-.91l-.91.52a1.09,1.09,0,0,1-1.43-.52,1,1,0,0,1,.39-1.43l.91-.52-.91-.52a1.05,1.05,0,0,1-.39-1.44,1,1,0,0,1,1.43-.39l.91.52Z" />
      <path d="M6.06,26.21a1.15,1.15,0,0,1-1.17-1A1.17,1.17,0,0,1,6.06,24h5a1.15,1.15,0,0,1,1,1.17,1.12,1.12,0,0,1-1,1Z" />
      <path d="M17.39,26.21a1,1,0,0,1-1-1,1.06,1.06,0,0,1,1-1.17h4.95a1.17,1.17,0,0,1,1.17,1.17,1.15,1.15,0,0,1-1.17,1Z" />
      <path d="M28.72,26.21a1.12,1.12,0,0,1-1-1,1.15,1.15,0,0,1,1-1.17H33.8a1.15,1.15,0,0,1,1,1.17,1.12,1.12,0,0,1-1,1Z" />
    </Icon>
  );
}
export default OneTimePassword;
