import { Selector } from "react-redux";
import { combineReducers } from "redux";
import { createSelector } from "reselect";
import { ParticipationType } from "../../../const";
import { hasAtLeastWritePermission } from "../../../features/permissions/utils.permissions";
import { combineParticipantInfo } from "../../../features/users/users.utils";
import { assertNotNull } from "../../../tools/assertions";
import { byOnlineStatus } from "../../../tools/sorters";
import {
  ApiPermission,
  CanvasExportFormat,
  ProjectUnavailableReason,
  UIProjectUnavailableReason,
} from "../../../types/enum";
import { selectAssertedUserProfile } from "../../auth/auth.reducer";
import { ProjectAction, ProjectActionType } from "./project.actions";
import {
  NotifyProjectAction,
  NotifyProjectActionType,
} from "./signalR-project/signalR-project.actions";

export type ProjectState = {
  apiInfo: ApiGetProject | null;
  status: ProjectStatusState;
  // Use Map to preserve key order, which is important for the assigned ProjectColor
  // Also the Map is keyed by the UserName, which is unique, but UserId is safer. But we typically
  // want to know the user info by UserName
  usersByUserName: Map<string, ProjectUserState>;
};

export enum ProjectStatus {
  ADDING_TEMPLATE = "ADDING_TEMPLATE",
  EXPORTING = "EXPORTING",
  LOADING = "LOADING",
  READY = "READY",
  ERROR = "ERROR",
  HURRY_UP = "HURRY_UP",
  INACTIVE = "INACTIVE",
  SYNCING = "SYNCING",
  UNAVAILABLE = "UNAVAILABLE",
  UNINITIALIZED = "UNINITIALIZED",
}

export type ProjectStatusState =
  | { status: ProjectStatus.ADDING_TEMPLATE }
  | { status: ProjectStatus.EXPORTING; format: CanvasExportFormat }
  | {
      status: ProjectStatus.LOADING;
      loadingPercentage: number | null;
    }
  | { status: ProjectStatus.READY }
  | { status: ProjectStatus.HURRY_UP }
  | { status: ProjectStatus.INACTIVE }
  | { status: ProjectStatus.SYNCING }
  | { status: ProjectStatus.ERROR; error: Cloneable<Error> }
  | { status: ProjectStatus.UNAVAILABLE; reason: ProjectUnavailableReason }
  | { status: ProjectStatus.UNINITIALIZED };

type ProjectUserState = {
  apiInfo: ProjectUserIdentity | null; // null when NotifyProjectParticipantChanged arrives before NotifyLoginProject
  projectPermission: ProjectUserPermission;
  onlineInfo: ApiOnlineUser | null;
};

export const getInitialProjectState = (): ProjectState => {
  return {
    apiInfo: null,
    status: {
      status: ProjectStatus.UNINITIALIZED,
    },
    usersByUserName: new Map(),
  };
};

function apiInfoReducer(
  state = getInitialProjectState().apiInfo,
  action: ProjectAction
): ApiGetProject | null {
  switch (action.type) {
    case ProjectActionType.SETUP_PROJECT: {
      return action.payload.project;
    }
    default:
      return state;
  }
}

function statusReducer(
  state = getInitialProjectState().status,
  action: ProjectAction
): ProjectStatusState {
  switch (action.type) {
    case ProjectActionType.INCREMENTAL_UPDATE_PROJECT: {
      return {
        status: ProjectStatus.LOADING,
        loadingPercentage: action.payload.percentage,
      };
    }
    case ProjectActionType.INCREMENTAL_COMPLETE_PROJECT: {
      // Avoid setting the project as loading if it was not, however we also can't set it as READY
      // directly as we have to wait for SET_PARTICIPANTS.
      return state.status === ProjectStatus.LOADING
        ? {
            status: ProjectStatus.LOADING,
            loadingPercentage: action.payload.percentage,
          }
        : state;
    }
    case ProjectActionType.RESET_PROJECT_STATUS: {
      return {
        status: ProjectStatus.READY,
      };
    }
    case ProjectActionType.PROJECT_DELETED: {
      return {
        status: ProjectStatus.UNAVAILABLE,
        reason: UIProjectUnavailableReason.deleted,
      };
    }
    case ProjectActionType.PROJECT_UNAVAILABLE: {
      return {
        status: ProjectStatus.UNAVAILABLE,
        reason: action.payload.UnavailableReason,
      };
    }
    case ProjectActionType.SET_PROJECT_STATUS: {
      return action.payload;
    }
    default:
      return state;
  }
}

export function usersByUserNameReducer(
  state = getInitialProjectState().usersByUserName,
  action: ProjectAction | NotifyProjectAction
): Map<string, ProjectUserState> {
  switch (action.type) {
    case ProjectActionType.SET_PARTICIPANTS: {
      const { users, onlineUsers } = action.payload;
      const usersMap = new Map(
        users.map((user) => {
          const UserName = user.User.UserName;
          const onlineInfo = onlineUsers.find(
            (onlineUser) => onlineUser.UserName === UserName
          );

          // SET_USER_ONLINE (i.e. NotifyLoginProject) and SET_PARTICIPANTS (i.e. GetAuthOnlineUsersByProject)
          // can arrive in different order depending on the network condition and the case.
          // Sometimes the SET_PARTICIPANTS payload may not contain the `onlineInfo` of the guest user
          // because the `PostLoginProject` message is not arrived yet and the server thinks the guest
          // is offline, e.g. it was removed from the project or it's the first time accepting.
          // So in those cases, we reuse the information from SET_USER_ONLINE if it arrived before SET_PARTICIPANTS,
          // otherwise it will be filled by the SET_USER_ONLINE reducer.
          const existingOnlineInfo = state.get(UserName)?.onlineInfo;

          const entry: ProjectUserState = {
            apiInfo: user.User,
            projectPermission: {
              Permission: user.Permission,
              ParticipationType: user.ParticipationType,
            },
            onlineInfo: onlineInfo || existingOnlineInfo || null,
          };

          return [UserName, entry];
        })
      );

      return usersMap;
    }
    case NotifyProjectActionType.SET_USER_OFFLINE: {
      const { UserName } = action.payload;

      const entry = state.get(UserName);

      if (!entry) {
        return state;
      }

      const newMap = new Map(state);
      return newMap.set(UserName, { ...entry, onlineInfo: null });
    }
    case NotifyProjectActionType.SET_USER_ONLINE: {
      const newMap = new Map(state);
      const { AuthUser } = action.payload;

      if (!AuthUser?.UserName) {
        return state;
      }

      const { UserName } = AuthUser;

      const existingEntry = newMap.get(UserName);

      if (existingEntry) {
        return newMap.set(UserName, {
          ...existingEntry,
          // Set the apiInfo if still not defined, which can happen when there's a new user
          // and NotifyProjectParticipantChanged arrives before NotifyLoginProject
          apiInfo: existingEntry.apiInfo || AuthUser,
          onlineInfo: AuthUser,
        });
      }

      const entry: ProjectUserState = {
        apiInfo: AuthUser,
        projectPermission: {
          // We don't know the project permission info yet, which will arrive with NotifyProjectParticipantChanged and result in UPDATE_PERMISSION action
          Permission: ApiPermission.readPermission,
          ParticipationType: ParticipationType.direct,
        },
        onlineInfo: AuthUser,
      };

      return newMap.set(UserName, entry);
    }
    case NotifyProjectActionType.UPDATE_PERMISSION: {
      const newMap = new Map(state);
      const { ParticipantUserName, Permission } = action.payload;

      if (Permission === ApiPermission.noPermission) {
        newMap.delete(ParticipantUserName);
        return newMap;
      }

      if (Permission === ApiPermission.ownerPermission) {
        // Change existing owner to have readWritePermission
        const existingOwner = Array.from(newMap.values()).find(
          (entry) =>
            entry.projectPermission.Permission === ApiPermission.ownerPermission
        );
        existingOwner &&
          existingOwner.apiInfo &&
          newMap.set(existingOwner.apiInfo?.UserName, {
            ...existingOwner,
            projectPermission: {
              Permission: ApiPermission.readWritePermission,
              ParticipationType: ParticipationType.direct,
            },
          });
      }

      const existingEntry = state.get(ParticipantUserName);

      if (existingEntry) {
        return newMap.set(ParticipantUserName, {
          ...existingEntry,
          projectPermission: {
            Permission,
            ParticipationType: ParticipationType.direct,
          },
        });
      }

      // The NotifyLoginProject message has not arrived yet, so we just save the permission
      const entry: ProjectUserState = {
        apiInfo: null,
        projectPermission: {
          Permission,
          ParticipationType: ParticipationType.direct,
        },
        onlineInfo: null,
      };

      return newMap.set(ParticipantUserName, entry);
    }
    default:
      return state;
  }
}

export const canvasProjectReducer = combineReducers<ProjectState>({
  apiInfo: apiInfoReducer,
  status: statusReducer,
  usersByUserName: usersByUserNameReducer,
});

/*----------  SELECTORS  ----------*/

export const selectProjectInfo = (
  state: ApplicationGlobalState
): ApiGetProject | null => {
  return state.canvas.project.apiInfo;
};

/**
 * @NOTE Use with caution, only when you're sure that the project info is already loaded
 */
export const selectAssertedProjectInfo = (
  state: ApplicationGlobalState
): ApiGetProject => {
  return assertNotNull(state.canvas.project.apiInfo, "projectInfo");
};

export const selectProjectId = (state: ApplicationGlobalState): string => {
  /**
   * @TODO #7104: The projectId is required by many React components but it's not available on-mount so we
   * resort to getting it from the URL for the time being. In the old Context, the state was initialised
   * with the projectId, but with Redux we can't do it and projectId requires at least one render cycle
   * before being available in the Redux state.
   *
   * @TODO Refactor React components and hooks to receive the projectId as parameter instead of reading
   * it from Redux if they need the value immediately.
   */
  const projectInfo = selectProjectInfo(state);
  const projectId = window.location.pathname
    .split("/")
    .find((part) => /\d+/.test(part));

  return projectInfo ? projectInfo.Project.ProjectId : projectId || "0"; // "" is returned for tests
};

export const selectHasWritePermissions = (
  state: ApplicationGlobalState
): boolean => {
  const myself = selectProjectMyself(state);
  return myself ? hasAtLeastWritePermission(myself.Permission) : false;
};

export const selectProjectStatus = (
  state: ApplicationGlobalState
): ProjectStatusState => {
  return state.canvas.project.status;
};

export const selectIsProjectLoaded = (
  state: ApplicationGlobalState
): boolean => {
  return state.canvas.project.status.status === ProjectStatus.READY;
};

const isDefinedUser = (
  user: ProjectUserState
): user is WithRequiredKeys<ProjectUserState, "apiInfo"> => {
  return !!user.apiInfo;
};

const isOnlineUser = (
  user: ProjectUserState
): user is WithRequiredKeys<ProjectUserState, "onlineInfo"> => {
  return !!user.onlineInfo;
};

const selectUsersMap = (state: ApplicationGlobalState) =>
  state.canvas.project.usersByUserName;

export const selectProjectUsers: Selector<
  ApplicationGlobalState,
  ProjectOnlineUserInfo[]
> = createSelector(
  (state: ApplicationGlobalState) => state.canvas.project.apiInfo,
  selectUsersMap,
  selectAssertedUserProfile,
  (apiInfo, usersByUserName, userProfile) => {
    const isProjectLicensed = apiInfo?.IsLicensed;
    const users = Array.from(usersByUserName.values())
      .filter(isDefinedUser)
      // Remove offline guests from the Users list
      .filter((user) => (user.apiInfo.IsGuest ? isOnlineUser(user) : true))
      // Sort by UserName so that the ProjectColor is more consistent
      .sort((userA, userB) =>
        userA.apiInfo.UserName.localeCompare(userB.apiInfo.UserName)
      )
      .map((user, index) => {
        const participantInfo = combineParticipantInfo({
          user: user.apiInfo,
          projectPermission: user.projectPermission,
          userProfile,
          index,
        });
        const userState: ProjectOnlineUserInfo = {
          ...participantInfo,
          IsOnline: participantInfo.IsMyself || !!user.onlineInfo,
          Permission: isProjectLicensed
            ? user.projectPermission.Permission
            : ApiPermission.readPermission,
        };

        return userState;
      })
      .sort(byOnlineStatus);

    return users;
  }
);

export const selectProjectUserFirstName: (
  userName: string
) => Selector<ApplicationGlobalState, string> = (userName: string) =>
  createSelector(selectProjectUsers, (projectUsers) => {
    const user = projectUsers.find((u) => u.UserName === userName);
    return user?.FirstName ?? userName;
  });

export const selectProjectUsersById: Selector<
  ApplicationGlobalState,
  Map<number, ProjectOnlineUserInfo>
> = createSelector(selectProjectUsers, (users) => {
  return new Map(users.map((user) => [user.UserId, user]));
});

export const selectProjectOnlineUsers: Selector<
  ApplicationGlobalState,
  ApiOnlineUser[]
> = createSelector(selectUsersMap, (usersByUserName) => {
  return Array.from(usersByUserName.values())
    .filter(isOnlineUser)
    .map((user) => user.onlineInfo);
});

export const selectProjectMyself: Selector<
  ApplicationGlobalState,
  ProjectOnlineUserInfo | undefined
> = createSelector(selectProjectUsers, (users) => {
  return users.find((user) => user.IsMyself);
});

export const selectProjectOwner: Selector<
  ApplicationGlobalState,
  ProjectOnlineUserInfo | undefined
> = createSelector(selectProjectUsers, (users) => {
  return users.find(
    (user) => user.Permission === ApiPermission.ownerPermission
  );
});
