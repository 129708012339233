export enum UserPermissionsActionType {
  GET_USER_PERMISSIONS_DONE = "GET_USER_PERMISSIONS_DONE",
}

export const getUserPermissionsDone = (permissions: ApiUserPermissions) =>
  ({
    type: UserPermissionsActionType.GET_USER_PERMISSIONS_DONE,
    payload: permissions,
  } as const);

export type UserPermissionsAction = ReturnType<typeof getUserPermissionsDone>;
