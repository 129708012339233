import { SessionStorage } from "./localStorage";
import { unload } from "./utils";

export type MemoryStorageObject = { [key: string]: string };

/**
 * An in-memory implementation of Storage. Values are persisted for the current browser session by
 * saving them before refreshing the page and clearing them immediately after a new load, to avoid
 * saving any sensitive information for longer than necessary.
 */
export default class MemoryStorage implements Storage {
  private storage: MemoryStorageObject;
  length = 0;

  constructor(unloadStorage: SessionStorage<MemoryStorageObject>) {
    this.storage = unloadStorage.get() ?? {};
    this.updateLength();
    unloadStorage.clear();
    unload(() => unloadStorage.set(this.storage));
  }

  private updateLength(): void {
    this.length = Object.keys(this.storage).length;
  }

  clear(): void {
    this.storage = {};
    this.length = 0;
  }

  setItem(key: string, value: string): void {
    this.storage[key] = value;
    this.updateLength();
  }

  getItem(key: string): string | null {
    const hasKey = Object.prototype.hasOwnProperty.call(this.storage, key);
    return hasKey ? this.storage[key] : null;
  }

  removeItem(key: string): void {
    delete this.storage[key];
    this.updateLength();
  }

  key(index: number): string | null {
    const key = Object.keys(this.storage)[index];
    return key || null;
  }
}
