export const featuresBusiness: Array<Feature> = [
  {
    labelKey: "webexIntegration",
    inCard: true,
    order: 600,
  },
  {
    labelKey: "zoom",
    inCard: true,
    order: 601,
  },
  {
    labelKey: "presentationMode",
    inCard: true,
    order: 602,
    soon: true,
  },
  {
    labelKey: "facilitatorRole",
    inCard: true,
    order: 603,
    soon: true,
  },
  {
    labelKey: "voting",
    inCard: true,
    order: 604,
  },
  {
    labelKey: "quickLinks",
    inCard: true,
    order: 605,
  },
  {
    labelKey: "customTemplates",
    inCard: true,
    order: 606,
    soon: true,
  },
  {
    labelKey: "swissHosting",
    inCard: true,
    order: 607,
  },
];
