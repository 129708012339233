import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  acceptProjectInvitation,
  acceptProjectInvitationAsGuest,
} from "../../../api";
import { errorCodeIds } from "../../../api/errorCodes";
import { routes } from "../../../const";
import {
  AuthUserType,
  setAuthenticatedAction,
} from "../../../reduxStore/auth/auth.actions";
import { LogCategory } from "../../../tools/telemetry";
import { isAPIError } from "../../../tools/errors";
import { errorToast } from "../../../tools/errorToast";
import { useErrorHandler } from "../../shared/hooks/useErrorHandler";

export type AcceptInvitationAsUser = {
  Token: string;
  Password: string | null;
};

export type AcceptInvitationAsGuest = AcceptInvitationAsUser & {
  Name: string;
  Email: string;
  ReceiveNews: boolean;
};

type UseInvite = {
  acceptInvitationAsGuest: (config: AcceptInvitationAsGuest) => Promise<void>;
  acceptInvitationAsUser: (config: AcceptInvitationAsUser) => Promise<void>;
};

export const useInvite = (): UseInvite => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { fallbackErrorHandler } = useErrorHandler();

  const acceptInvitationAsUser = useCallback(
    async ({ Token, Password }: AcceptInvitationAsUser) => {
      try {
        const response = await acceptProjectInvitation({
          Token,
          Password,
        }).catch((error: unknown) => {
          if (isAPIError(error)) {
            const { details, response } = error;
            const { errorCode } = details;
            // Silently ignore this error and treat this as a successful request
            if (errorCode === errorCodeIds.UserIsParticipantInProject) {
              return response?.data as ApiAcceptProjectInvitation;
            }
          }
          throw error;
        });
        const { Project } = response;
        const { ProjectId } = Project;
        const projectRoute = `${routes.collaboard}/${ProjectId}`;
        history.replace(projectRoute);
      } catch (error) {
        fallbackErrorHandler(
          error,
          "Failed to accept invite as user",
          LogCategory.auth
        );

        history.replace(routes.authenticate);
      }
    },
    [history, fallbackErrorHandler]
  );

  const acceptInvitationAsGuest = useCallback(
    async (values: AcceptInvitationAsGuest) => {
      try {
        const response = await acceptProjectInvitationAsGuest(values);
        const { Project, Authentication } = response;

        dispatch(
          setAuthenticatedAction({
            authProvider: "invite",
            isRegistration: false,
            token: Authentication,
            userType: AuthUserType.Guest,
          })
        );

        const { ProjectId } = Project;
        const projectRoute = `${routes.collaboard}/${ProjectId}`;
        history.replace(projectRoute);
      } catch (error: unknown) {
        if (isAPIError(error)) {
          const { details } = error;
          const { errorCode } = details;

          if (errorCode === errorCodeIds.PasswordNotValid) {
            errorToast(t("invite.invalidProjectPassword"));
            return;
          }
        }

        fallbackErrorHandler(
          error,
          "Failed to accept invite as guest",
          LogCategory.auth
        );

        history.replace(routes.authenticate);
      }
    },
    [dispatch, fallbackErrorHandler, history, t]
  );

  return {
    acceptInvitationAsGuest,
    acceptInvitationAsUser,
  };
};
