import { useCallback, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getCustomLoginProviders } from "../../api";
import { routes } from "../../const";
import { useUISettings } from "../../reduxStore/uiSettings";
import { isStaticFeatureActive, staticFeatureFlags } from "../../tools/flags";
import { isRunningInMSTeams } from "../../tools/msTeams";
import { runtimeConfig } from "../../tools/runtimeConfig";
import { LogCategory } from "../../tools/telemetry";
import { useErrorHandler } from "../shared/hooks/useErrorHandler";

type UseAuthConfig = {
  getAuthConfig: () => Promise<void>;
  isLoadingConfig: boolean;
  authConfig: AuthConfig | undefined;
};

type AuthConfig = {
  allowEmailLogin: boolean;
  allowRegistration: boolean;
  externalAuthProviders: AuthProvider[];
  tenant?: string;
};

const defaultExternalAuthProviders = ["Google", "Microsoft", "Apple"];

export const useAuthConfig = (): UseAuthConfig => {
  const history = useHistory();
  const { search } = useLocation();
  const { tenant: tenantFromUrl } = useParams<AuthenticationRouteParams>();
  const { loadUISettings } = useUISettings();
  const { fallbackErrorHandler } = useErrorHandler();

  const [authConfig, setAuthConfig] = useState<AuthConfig | undefined>(
    undefined
  );
  const [isLoadingConfig, setIsLoadingConfig] = useState(false);

  // These values are defined inside the hook so they can be changed in tests
  const { authProviders = defaultExternalAuthProviders } = runtimeConfig;
  const registrationDisabled = isStaticFeatureActive(
    staticFeatureFlags.REGISTRATION_DISABLED
  );
  const emailLoginDisabled = isStaticFeatureActive(
    staticFeatureFlags.EMAIL_LOGIN_DISABLED
  );

  /**
   * Get the external auth providers for this instance.
   */
  const getExternalAuthProviders = useCallback(async (): Promise<
    AuthProvider[]
  > => {
    if (tenantFromUrl) {
      return getCustomLoginProviders({
        tenant: tenantFromUrl,
        isInTeams: isRunningInMSTeams(),
      });
    }

    return authProviders.map((name) => ({
      name,
      displayName: name,
    }));
  }, [authProviders, tenantFromUrl]);

  /**
   * Get the configuration for the login page.
   */
  const getAuthConfig = useCallback(async (): Promise<void> => {
    setIsLoadingConfig(true);

    try {
      const externalAuthProviders = await getExternalAuthProviders();
      const isValidTenant = !!tenantFromUrl && !!externalAuthProviders.length;
      const isInvalidTenant = !!tenantFromUrl && !externalAuthProviders.length;

      if (isInvalidTenant) {
        history.replace(`${routes.authenticate}${search}`);
        setIsLoadingConfig(false);
        return;
      }

      if (isValidTenant) {
        await loadUISettings(tenantFromUrl);
      }

      setAuthConfig({
        allowEmailLogin: !emailLoginDisabled && !isValidTenant,
        allowRegistration: !registrationDisabled && !isValidTenant,
        externalAuthProviders,
        tenant: isValidTenant ? tenantFromUrl : undefined,
      });
    } catch (error) {
      fallbackErrorHandler(
        error,
        "Unable to load auth config",
        LogCategory.auth
      );
    }

    setIsLoadingConfig(false);
  }, [
    emailLoginDisabled,
    getExternalAuthProviders,
    fallbackErrorHandler,
    history,
    loadUISettings,
    registrationDisabled,
    search,
    tenantFromUrl,
  ]);

  return {
    getAuthConfig,
    isLoadingConfig,
    authConfig,
  };
};
