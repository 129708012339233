import {
  colorThemes,
  defaultProjectTheme,
  zoomInitialLevel,
} from "../../../const";
import { ProjectAction, ProjectActionType } from "../project/project.actions";
import {
  CanvasSettingsAction,
  CanvasSettingsActionType,
} from "./settings.actions";

export type CanvasSettingsState = {
  backgroundColor: string;
  theme: colorThemes;
  grid: boolean;
  zoomLevel: number;
  width: number;
  height: number;
};

export const getInitialCanvasSettingsState = (): CanvasSettingsState => {
  return {
    backgroundColor: defaultProjectTheme.backgroundColor,
    theme: defaultProjectTheme.theme,
    grid: false,

    zoomLevel: zoomInitialLevel,
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

export function canvasSettingsReducer(
  state = getInitialCanvasSettingsState(),
  action: CanvasSettingsAction | ProjectAction
): CanvasSettingsState {
  switch (action.type) {
    case CanvasSettingsActionType.SET_BACKGROUND_COLOR: {
      return {
        ...state,
        backgroundColor: action.payload.BackgroundColor,
      };
    }
    case CanvasSettingsActionType.SET_THEME: {
      return {
        ...state,
        theme: action.payload.theme,
      };
    }
    case CanvasSettingsActionType.SET_VIEWPORT_TRANSFORM: {
      const viewportTransform = action.payload;

      return {
        ...state,
        zoomLevel: viewportTransform[0],
      };
    }
    case ProjectActionType.SETUP_PROJECT: {
      const { viewportTransform } = action.payload;

      return viewportTransform
        ? {
            ...state,
            zoomLevel: viewportTransform[0],
          }
        : state;
    }
    case CanvasSettingsActionType.TOGGLE_GRID: {
      return {
        ...state,
        grid: !state.grid,
      };
    }
    case CanvasSettingsActionType.WINDOW_RESIZE_DEBOUNCED:
    case CanvasSettingsActionType.WINDOW_RESIZE_THROTTLED: {
      const { width, height } = action.payload;

      return {
        ...state,
        /** @NOTE This is ok as long as the canvas fills the window */
        width,
        height,
      };
    }
    case CanvasSettingsActionType.SET_ZOOM_LEVEL: {
      const { zoomLevel } = action.payload;

      return {
        ...state,
        zoomLevel,
      };
    }
    default:
      return state;
  }
}

/**
 * @NOTE Avoid using this selector in React components since the state can easily have a new reference
 * when zooming is changed, thus causing a new render. Prefer the more granular selectors below.
 * It's okay to use this selector in sagas.
 */
export const selectCanvasSettings = (
  state: ApplicationGlobalState
): CanvasSettingsState => {
  return state.canvas.settings;
};

export const selectCanvasBackgroundColor = (
  state: ApplicationGlobalState
): string => {
  return state.canvas.settings.backgroundColor;
};

export const selectCanvasTheme = (
  state: ApplicationGlobalState
): colorThemes => {
  return state.canvas.settings.theme;
};

export const selectCanvasGrid = (state: ApplicationGlobalState): boolean => {
  return state.canvas.settings.grid;
};

export const selectCanvasZoomLevel = (
  state: ApplicationGlobalState
): number => {
  return state.canvas.settings.zoomLevel;
};
