export enum SubscriptionDetailsActionType {
  GET_SUBSCRIPTION_DONE = "GET_SUBSCRIPTION_DONE",
}

export const getSubscriptionDone = (
  subscription: ApiManageableSubscription | null
) =>
  ({
    type: SubscriptionDetailsActionType.GET_SUBSCRIPTION_DONE,
    payload: subscription,
  } as const);

export type SubscriptionDetailsAction = ReturnType<typeof getSubscriptionDone>;
