import { fabric } from "fabric";

import { BrushType, defaultPointerPressure } from "../../../../const";
import { LineIntersection } from "../utils/lineIntersection";
import { RawPoint } from "../utils/pathSmoothing";

import { BaseInkBrush } from "./BaseInkBrush";

export class NibBrush extends BaseInkBrush {
  private prevPa: fabric.Point | undefined;
  private prevPb: fabric.Point | undefined;

  constructor(
    canvas: fabric.CollaboardCanvas,
    options?: fabric.CollaboardBrushOptions
  ) {
    super(BrushType.nib, canvas, options);

    this.prevPa = undefined;
    this.prevPb = undefined;
  }

  protected _reset(): void {
    super._reset();
    this.prevPa = undefined;
    this.prevPb = undefined;
  }

  protected _partialRender(
    ctx: CanvasRenderingContext2D | null = this.canvas.contextTop,
    renderFrom = 0,
    _points?: RawPoint[]
  ): void {
    if (!ctx) {
      return;
    }

    const points = _points || this._points;
    const length = points.length;

    // Calculate mid point for previous batch
    const ultimatePoint = points[renderFrom - 1];
    const penultimatePoint = points[renderFrom - 2];

    let previousMidPoint: RawPoint =
      ultimatePoint && penultimatePoint
        ? (ultimatePoint.midPointFrom(
            penultimatePoint
          ) as fabric.CollaboardPoint)
        : null;

    // Draw a tiny stroke around each path to remove the cracks
    const liveColor = this.liveColor.toLive();
    ctx.strokeStyle = liveColor;
    ctx.lineWidth = 0.5;

    // Render this batch
    for (let i = renderFrom; i < length; i++) {
      const point = points[i];
      const previousPoint = (points[i - 1] || point) as fabric.CollaboardPoint;

      if (!point) {
        previousMidPoint = null;
        continue;
      }

      const midPoint = point.midPointFrom(
        previousPoint
      ) as fabric.CollaboardPoint;

      const p1 = previousMidPoint || previousPoint;
      const p2 = midPoint;
      const c = previousPoint;

      const thickness =
        (point.pressure ?? defaultPointerPressure) *
        (this.strokeWidth + this.strokeWidth * 0.5);
      const brushHeight = thickness / 2;
      const brushWidth = brushHeight * 0.2;

      if (renderFrom + i === 0) {
        // First point
        const v = p2.subtract(p1) as fabric.CollaboardPoint;
        const n = v.normalize(brushHeight).perpendicular();

        const p1a = p1.add(n);
        const p2a = p2.add(n);
        const p2b = p2.subtract(n);

        if (points.length === 1) {
          // Should only be drawn if InkPath has one point or we are in
          // drawing mode
          ctx.beginPath();
          ctx.moveTo(p1a.x - brushWidth, p1a.y - brushHeight);
          ctx.lineTo(p1a.x + brushWidth, p1a.y - brushHeight);
          ctx.lineTo(p1a.x + brushWidth, p1a.y + brushHeight);
          ctx.lineTo(p1a.x - brushWidth, p1a.y + brushHeight);
          ctx.closePath();
          ctx.fillStyle = liveColor;
          ctx.fill();
          ctx.stroke();

          this._clearCanvasOnNextRender = true;
        }

        this.prevPa = p2a;
        this.prevPb = p2b;
      } else if (this.prevPa && this.prevPb) {
        // algorithm here described: http://brunoimbrizi.com/unbox/2015/03/offset-curve/
        const v1 = c.subtract(p1) as fabric.CollaboardPoint;
        const v2 = p2.subtract(c) as fabric.CollaboardPoint;
        const n1 = v1.normalize(brushHeight).perpendicular();
        const n2 = v2.normalize(brushHeight).perpendicular();

        const p1a = p1.add(n1) as fabric.CollaboardPoint;
        const p1b = p1.subtract(n1) as fabric.CollaboardPoint;
        const p2a = p2.add(n2) as fabric.CollaboardPoint;
        const p2b = p2.subtract(n2) as fabric.CollaboardPoint;
        const p2aIsChanged = false;
        const p2bIsChanged = false;

        const c1a = c.add(n1) as fabric.CollaboardPoint;
        const c1b = c.subtract(n1) as fabric.CollaboardPoint;
        const c2a = c.add(n2) as fabric.CollaboardPoint;
        const c2b = c.subtract(n2) as fabric.CollaboardPoint;

        const line1a = new LineIntersection(p1a, c1a);
        const line1b = new LineIntersection(p1b, c1b);
        const line2a = new LineIntersection(p2a, c2a);
        const line2b = new LineIntersection(p2b, c2b);

        let ca = null;
        let cb = null;
        let qa = new fabric.Point(0, 0) as fabric.CollaboardPoint;
        let qb = new fabric.Point(0, 0) as fabric.CollaboardPoint;
        let q1a = null;
        let q2a = null;
        let q1b = null;
        let q2b = null;

        const split = v1.angleBetween(v2) > Math.PI / 2;

        if (!split) {
          ca = line1a.intersectsLine(line2a);
          cb = line1b.intersectsLine(line2b);
        } else {
          const t = this._getNearestPoint(p1, c, p2);
          const pt = this._getPointInQuadraticCurve(t, p1, c, p2);

          const t1 = p1.multiply(1 - t).add(c.multiply(t));
          const t2 = c.multiply(1 - t).add(p2.multiply(t));

          const vt = t2.subtract(t1) as fabric.CollaboardPoint;
          const vt1 = vt.normalize(brushHeight).perpendicular();
          qa = pt.add(vt1) as fabric.CollaboardPoint;
          qb = pt.subtract(vt1) as fabric.CollaboardPoint;

          const lineqa = new LineIntersection(
            qa,
            qa.add(vt1.perpendicular()) as fabric.CollaboardPoint
          );
          const lineqb = new LineIntersection(
            qb,
            qb.add(vt1.perpendicular()) as fabric.CollaboardPoint
          );

          q1a = line1a.intersectsLine(lineqa);
          q2a = line2a.intersectsLine(lineqa);
          q1b = line1b.intersectsLine(lineqb);
          q2b = line2b.intersectsLine(lineqb);
        }

        if (
          (!split && (!ca || !cb)) ||
          (split && (!q1a || !q2a || !q1b || !q2b))
        ) {
          if (!split) {
            ctx.beginPath();
            ctx.moveTo(p2a.x, p2a.y);
            ctx.lineTo(p1a.x, p1a.y);
            ctx.lineTo(p1b.x, p1b.y);
            ctx.lineTo(p2b.x, p2b.y);
            ctx.lineTo(p2a.x, p2a.y);
            ctx.closePath();
            ctx.fillStyle = liveColor;
            ctx.fill();
            ctx.stroke();
          }
        } else if (!split) {
          if (p2aIsChanged || p2bIsChanged) {
            // p2 is changed due to intersection between the previous segment, so I draw a simple line
            ctx.lineTo(p2a.x, p2a.y);
          } else if (ca) {
            ctx.quadraticCurveTo(ca.x, ca.y, p2a.x, p2a.y);
            if (this.ENABLE_DEBUG_MODE) {
              this._renderDebugPoint(ctx, ca.x, ca.y, 50, "orange");
            }
          }

          // connect p2 a and b points
          ctx.lineTo(p2b.x, p2b.y);

          // offset curve b
          if (p2aIsChanged || p2bIsChanged) {
            ctx.lineTo(this.prevPb.x, this.prevPb.y);
          } else if (cb) {
            ctx.quadraticCurveTo(cb.x, cb.y, this.prevPb.x, this.prevPb.y);
            if (this.ENABLE_DEBUG_MODE) {
              this._renderDebugPoint(ctx, cb.x, cb.y, 50, "blue");
            }
          }

          // close the quad
          ctx.lineTo(this.prevPa.x, this.prevPa.y);
          ctx.closePath();

          ctx.fillStyle = liveColor;
          ctx.fill();
          ctx.stroke();

          ctx.moveTo(p2a.x, p2a.y);
        } else {
          // offset curve a
          if (q1a) {
            ctx.quadraticCurveTo(q1a.x, q1a.y, qa.x, qa.y);
            if (this.ENABLE_DEBUG_MODE) {
              this._renderDebugPoint(ctx, q1a.x, q1a.y, 50, "green");
            }
          }
          ctx.lineTo(qb.x, qb.y);
          if (q1b) {
            ctx.quadraticCurveTo(q1b.x, q1b.y, this.prevPb.x, this.prevPb.y);
            if (this.ENABLE_DEBUG_MODE) {
              this._renderDebugPoint(ctx, q1b.x, q1b.y, 50, "purple");
            }
          }
          ctx.lineTo(this.prevPa.x, this.prevPa.y);
          ctx.closePath();
          ctx.fillStyle = liveColor;
          ctx.fill();
          ctx.stroke();
          // offset curve b
          ctx.moveTo(qa.x, qa.y);
          if (q2a) {
            ctx.quadraticCurveTo(q2a.x, q2a.y, p2a.x, p2a.y);
            if (this.ENABLE_DEBUG_MODE) {
              this._renderDebugPoint(ctx, q2a.x, q2a.y, 50, "aqua");
            }
          }
          ctx.lineTo(p2b.x, p2b.y);
          q2b && ctx.quadraticCurveTo(q2b.x, q2b.y, qb.x, qb.y);
          if (q2b) {
            ctx.quadraticCurveTo(q2b.x, q2b.y, qb.x, qb.y);
            if (this.ENABLE_DEBUG_MODE) {
              this._renderDebugPoint(ctx, q2b.x, q2b.y, 50, "olive");
            }
          }
          ctx.lineTo(qa.x, qa.y);
          ctx.closePath();
          ctx.fillStyle = liveColor;
          ctx.fill();
          ctx.stroke();
          ctx.moveTo(p2a.x, p2a.y);
        }

        this.prevPa = p2a;
        this.prevPb = p2b;
      }

      previousMidPoint = midPoint;
    }
  }

  private _getPointInQuadraticCurve(
    t: number,
    p1: fabric.Point,
    pc: fabric.Point,
    p2: fabric.Point
  ) {
    const x = (1 - t) * (1 - t) * p1.x + 2 * (1 - t) * t * pc.x + t * t * p2.x;
    const y = (1 - t) * (1 - t) * p1.y + 2 * (1 - t) * t * pc.y + t * t * p2.y;

    return new fabric.Point(x, y);
  }

  private _getNearestPoint(
    p1: fabric.Point,
    pc: fabric.Point,
    p2: fabric.Point
  ): number {
    const cbrt = (x: number) => {
      const sign = x === 0 ? 0 : x > 0 ? 1 : -1;
      return sign * Math.pow(Math.abs(x), 1 / 3);
    };

    const v0 = pc.subtract(p1) as fabric.CollaboardPoint;
    const v1 = p2.subtract(pc) as fabric.CollaboardPoint;

    const a = (v1.subtract(v0) as fabric.CollaboardPoint).dot(v1.subtract(v0));
    const b = 3 * (v1.dot(v0) - v0.dot(v0));
    const c = 3 * v0.dot(v0) - v1.dot(v0);
    const d = -1 * v0.dot(v0);

    const p = -b / (3 * a);
    const q = p * p * p + (b * c - 3 * a * d) / (6 * a * a);
    const r = c / (3 * a);

    const s = Math.sqrt(q * q + Math.pow(r - p * p, 3));
    return cbrt(q + s) + cbrt(q - s) + p;
  }
}
