import { useEffect } from "react";

export const useOutClick = (
  elementRef: React.RefObject<HTMLElement>,
  onHide: () => void
): void => {
  useEffect(() => {
    const handleOutClick = (event: MouseEvent): void => {
      if (elementRef && !elementRef.current?.contains(event.target as Node)) {
        onHide();
      }
    };
    document.addEventListener("click", handleOutClick);

    return () => {
      document.removeEventListener("click", handleOutClick);
    };
  }, [elementRef, onHide]);
};
