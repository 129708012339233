/**
 * Find the first element that is scrollable.
 * Check itself and parent elements.
 *
 * If a more comprehensive solution is required in future please take a look at
 * https://github.com/jquery/jquery-ui/blob/74f8a0ac952f6f45f773312292baef1c26d81300/ui/scroll-parent.js
 *
 * @param {HTMLElement} element Starting element to check. Will walk up the DOM
 * tree from this point.
 * @returns {HTMLElement | null} First scrollable element or `null` if none are
 * found.
 */
export const findClosestScrollableAncestor = (
  element: HTMLElement | null
): HTMLElement | null => {
  if (!element) {
    return null;
  }

  const overflowRegex = /(auto|scroll)/;
  const overflowProps: ("overflow" | "overflowX" | "overflowY")[] = [
    "overflow",
    "overflowX",
    "overflowY",
  ];
  const styles = getComputedStyle(element);

  const isScrollable = overflowProps.some((prop) =>
    overflowRegex.test(styles[prop])
  );

  const isVisible = styles.display !== "none";

  if (isVisible && isScrollable) {
    return element;
  } else {
    return findClosestScrollableAncestor(element.parentElement);
  }
};

/**
 * Find all scrollable ancestors.
 *
 * @param {HTMLElement} element Starting element to check. Will walk up the DOM
 * tree from this point.
 * @returns {Array<HTMLElement>} List of scrollable ancestors
 */
export const findScrollableAncestors = (
  element: HTMLElement | null
): HTMLElement[] => {
  const results = [(document as unknown) as HTMLElement];
  if (!element) {
    return results;
  }
  let scrollable = findClosestScrollableAncestor(element);

  while (scrollable) {
    results.push(scrollable);
    scrollable = scrollable.parentElement
      ? findClosestScrollableAncestor(scrollable.parentElement)
      : null;
  }

  return results;
};
