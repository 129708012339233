import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function CreditCard(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M29.49,11.34h-19a1.83,1.83,0,0,0-1.83,1.84V26.82a1.83,1.83,0,0,0,1.83,1.84h19a1.83,1.83,0,0,0,1.83-1.84h0V13.18A1.83,1.83,0,0,0,29.49,11.34Zm-19,1.14h19a.7.7,0,0,1,.7.7v1.87H9.81V13.18a.7.7,0,0,1,.7-.7Zm19,15h-19a.7.7,0,0,1-.7-.7V18.49H30.19v8.33a.7.7,0,0,1-.7.7Zm-9.24-6.23H11.92a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.51h8.33a.5.5,0,0,1,.5.51A.5.5,0,0,1,20.25,21.29Zm-4.16,2.26H11.92a.5.5,0,1,1,0-1h4.17a.5.5,0,0,1,0,1Z" />
    </Icon>
  );
}
export default CreditCard;
