import {
  Tab as ReachTab,
  TabList as ReachTabList,
  useTabsContext,
} from "@reach/tabs";
import React, { PropsWithChildren, ReactElement } from "react";
import { Button, Flex, ThemeUIStyleObject } from "theme-ui";

type TabsVariant = "bold" | "regular" | "light";

type TabButtonsProps = {
  variant?: TabsVariant;
  sx?: ThemeUIStyleObject;
};

export function TabButtons(
  props: PropsWithChildren<TabButtonsProps>
): ReactElement {
  const { selectedIndex } = useTabsContext();
  const { sx, variant = "regular", children } = props;

  return (
    <ReachTabList
      as={Flex}
      sx={{
        background: "none",
        ...sx,
      }}
      variant={`tabs.${variant}`}
    >
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child as ReactElement, {
          isSelected: selectedIndex === index,
        })
      )}
    </ReachTabList>
  );
}

export function Tab(
  props: PropsWithChildren<{ isSelected?: boolean }>
): ReactElement {
  const { isSelected, children } = props;

  return (
    <ReachTab
      as={Button}
      variant={isSelected ? "radioButtonActive" : "radioButton"}
      sx={{
        fontSize: "calc(var(--staticGridTile) * 0.5)",
        lineHeight: "calc(var(--staticGridTile) * 0.5)",
        minHeight: "calc(var(--staticGridTile) * 1.25)",
      }}
    >
      {children}
    </ReachTab>
  );
}
