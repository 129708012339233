import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function ScanQR(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M11.17,15.35a.67.67,0,0,0,.66-.66V11.42h3.26a.65.65,0,1,0,0-1.3H11.17a.65.65,0,0,0-.65.65v3.92A.66.66,0,0,0,11.17,15.35Z" />
      <path d="M15.09,28.42H11.83V25.15a.66.66,0,0,0-.66-.65.65.65,0,0,0-.65.65v3.92a.65.65,0,0,0,.65.65h3.92a.65.65,0,1,0,0-1.3Z" />
      <path d="M29.47,24.5a.65.65,0,0,0-.65.65v3.27H25.55a.65.65,0,1,0,0,1.3h3.92a.65.65,0,0,0,.65-.65V25.15A.65.65,0,0,0,29.47,24.5Z" />
      <path d="M29.47,10.12H25.55a.65.65,0,0,0,0,1.3h3.27v3.27a.65.65,0,1,0,1.3,0V10.77A.65.65,0,0,0,29.47,10.12Z" />
      <path d="M14.44,26.46H26.2a.67.67,0,0,0,.66-.66V21.88h1.3a.66.66,0,0,0,.66-.65.67.67,0,0,0-.66-.66H12.48a.66.66,0,0,0-.65.66.65.65,0,0,0,.65.65h1.31V25.8A.66.66,0,0,0,14.44,26.46Zm11.11-1.31H15.09V21.88H25.55Z" />
      <path d="M26.2,13.39H14.44a.65.65,0,0,0-.65.65v4.57a.65.65,0,1,0,1.3,0V14.69H25.55v3.92a.66.66,0,0,0,.65.66.67.67,0,0,0,.66-.66V14A.66.66,0,0,0,26.2,13.39Z" />
    </Icon>
  );
}
export default ScanQR;
