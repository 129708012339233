import type { TFunction } from "i18next";
import { DropdownItem } from "../../../routes/projects/projectList/DropDown";

const convertMinutesToLabel = (
  minutes: number,
  translate: TFunction
): string => {
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(minutes / (60 * 24));

  if (days === 0) {
    return translate("time.short.hours", { time: hours });
  }

  return translate("time.short.days", { time: days });
};

export const formatShareLinkValidity = (
  options: ApiShareLinkValidity,
  translate: TFunction
): DropdownItem<number>[] => {
  return options.map((value) => {
    if (value === "NO_EXPIRY") {
      return {
        labelKey: "dialog.share.indefinitely",
        value: 10000 * 24 * 60,
      };
    }

    const label = convertMinutesToLabel(value, translate);

    return {
      labelKey: "",
      label,
      value,
    };
  });
};
