import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Box, Button, Flex, ThemeUIStyleObject } from "theme-ui";
import { routes } from "../../../const";
import { InternalError } from "../../../errors/InternalError";
import { errorToast } from "../../../tools/errorToast";
import { LogCategory, onErrorToLog } from "../../../tools/telemetry";
import { useErrorHandler } from "../../shared/hooks/useErrorHandler";
import OrSeparator, { Direction } from "../../shared/layout/OrSeparator";
import { useSimpleModal } from "../../shared/modals/useSimpleModal";
import ContainedSpinner from "../../shared/spinners/ContainedSpinner";
import { useAuthConfig } from "../useAuthConfig";
import { useAuthenticatingStatus } from "../useAuthenticatingStatus";
import EmailLogin from "./EmailLogin";
import EmailVerificationModalTemplate from "./EmailVerificationModalTemplate";
import ExternalLogin from "./ExternalLogin";

function LoginOptions(): ReactElement {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { isInitialized } = useAuthenticatingStatus();
  const { getAuthConfig, isLoadingConfig, authConfig } = useAuthConfig();
  const { fallbackErrorHandler } = useErrorHandler();

  const [UserName, setUserName] = useState<string | undefined>(undefined);
  const [isLoginLocked, setIsLoginLocked] = useState(true);

  const emailVerificationModal = useSimpleModal();

  useEffect(() => {
    if (!isInitialized) {
      onErrorToLog(
        new InternalError(
          "LoginOptions component requires initialized auth state"
        )
      );
    }
  }, [isInitialized]);

  /**
   * Check if the query string looks like an external authentication redirect.
   */
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const externalProviderKey = queryParams.get("code") ?? undefined;
  const redirectAfterLogin = queryParams.get("redirect") ?? undefined;

  /**
   * Wait for ExternalLogin component to check if it needs to handle
   * an external login redirect. If no redirect is detected then login will be
   * unlocked.
   */
  const onUnlockLogin = useCallback(() => {
    setIsLoginLocked(false);
  }, []);

  const onLockLogin = useCallback(() => {
    setIsLoginLocked(true);
  }, []);

  const onEmailVerifySuccess = useCallback(() => {
    // Ensure async task is completed before closing modal
    setTimeout(() => {
      emailVerificationModal.onClose();
    });
  }, [emailVerificationModal]);

  const onEmailVerifyError = useCallback(
    (error: unknown) =>
      fallbackErrorHandler(error, "Unable to verify email", LogCategory.auth),
    [fallbackErrorHandler]
  );

  const openActivateEmailModal = useCallback(() => {
    if (UserName) {
      emailVerificationModal.open();
    } else {
      errorToast(t("clientError.invalidEmail"));
    }
  }, [UserName, emailVerificationModal, t]);

  /**
   * Load the initial login configuration.
   */
  useEffect(() => {
    if (!isLoginLocked && !isLoadingConfig && !authConfig) {
      getAuthConfig();
    }
  }, [getAuthConfig, isLoginLocked, authConfig, isLoadingConfig]);

  const hasExternalAuthProviders = !!authConfig?.externalAuthProviders.length;

  return (
    <Box sx={boxStyle} data-testid="login-options">
      <Flex sx={loginOptionsStyle}>
        <Flex sx={hasExternalAuthProviders ? loginPanelStyle : undefined}>
          {/**
           * ExternalLogin also handles processing of the redirect from the
           * external auth provider, therefore it must be present in the DOM.
           *
           * If you need to hide it use CSS.
           */}
          <ExternalLogin
            authProviders={authConfig?.externalAuthProviders ?? []}
            externalProviderKey={externalProviderKey}
            redirectAfterLogin={redirectAfterLogin}
            tenantFromConfig={authConfig?.tenant}
            isRegistration={false}
            onUnlockLogin={onUnlockLogin}
            onLockLogin={onLockLogin}
          />
        </Flex>
        {authConfig?.allowEmailLogin && (
          <>
            {hasExternalAuthProviders && (
              <Flex sx={orStyle} data-testid="or-separator">
                <OrSeparator directions={orDirections} />
              </Flex>
            )}
            <Flex sx={loginPanelStyle}>
              <EmailLogin
                UserName={UserName}
                redirectAfterLogin={redirectAfterLogin}
                tenant={authConfig.tenant}
                onSetUserName={setUserName}
              />
            </Flex>
          </>
        )}
      </Flex>
      <Box
        sx={
          authConfig?.allowEmailLogin && hasExternalAuthProviders
            ? linksContainerStyle
            : partialLinksContainerStyle
        }
      >
        {authConfig?.allowEmailLogin && (
          <Box sx={linkStyle}>
            <Trans i18nKey="form.button.resetPassword">
              <Link
                to={{
                  pathname: routes.passwordReset,
                  search,
                }}
                data-testid="reset-password"
              />
            </Trans>
          </Box>
        )}
        {authConfig?.allowRegistration && (
          <Box sx={linkStyle}>
            <Trans i18nKey="form.button.activate">
              <Button
                variant="link"
                onClick={openActivateEmailModal}
                data-testid="activate"
              />
            </Trans>
          </Box>
        )}
      </Box>
      {UserName && (
        <EmailVerificationModalTemplate
          UserName={UserName}
          modalProps={emailVerificationModal}
          onSuccess={onEmailVerifySuccess}
          onError={onEmailVerifyError}
        />
      )}
      {(isLoadingConfig || isLoginLocked) && <ContainedSpinner />}
    </Box>
  );
}

export default LoginOptions;

const boxStyle: ThemeUIStyleObject = {
  width: "100%",
  mb: [3],
};

const linksContainerStyle: ThemeUIStyleObject = {
  my: [3],
  textAlign: "right",
};

const partialLinksContainerStyle: ThemeUIStyleObject = {
  ...linksContainerStyle,
  textAlign: "left",
};

const linkStyle: ThemeUIStyleObject = {
  mb: [1],
};

const loginOptionsStyle: ThemeUIStyleObject = {
  width: "100%",
  flexDirection: ["column", "column", "row"],
  mt: [6, null, 8],
  mb: [5],
};

const loginPanelStyle: ThemeUIStyleObject = {
  flex: 3,
  alignItems: "center",
  justifyContent: "center",
  maxWidth: [null, null, "50%"],
};

const orStyle: ThemeUIStyleObject = {
  flex: 1,
  my: [4, null, 0],
};

const orDirections: Direction[] = ["row", "row", "column"];
