import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function NPO(props: IconProps): ReactElement {
  return (
    <Icon viewBox="0 0 80 80" {...props}>
      <path d="M58.7,23.17a3.16,3.16,0,0,0-3.48-3.12,3.19,3.19,0,0,0-2.83,3.22l.07,12.86a.66.66,0,0,1-.66.67h-.41a.66.66,0,0,1-.67-.66l-.11-20.4a3.57,3.57,0,1,0-7.14,0l.11,20.4a.66.66,0,1,1-1.32,0l-.14-24.81a3.58,3.58,0,0,0-3.59-3.55h0A3.58,3.58,0,0,0,35,11.41l.14,24.81a.67.67,0,0,1-.66.67h0a.67.67,0,0,1-.67-.66l-.11-20.57a3.59,3.59,0,0,0-3.59-3.55h0a3.59,3.59,0,0,0-3.55,3.59l.17,32-4.8-2.59a1.69,1.69,0,0,1-1-.85l-2.7-5.69a3.57,3.57,0,1,0-6.45,3.06l3.57,7.53a3.11,3.11,0,0,0,.92,1.47S27.81,62.73,29.7,64l.66.68c-.15-.17-.65-.68-.65-.68h0a3.87,3.87,0,0,1,1.55,3.09v5.16H53.2V67.76a3.75,3.75,0,0,1,1.91-3.28c2.25-1.27,3.78-5.2,3.76-8Zm-8,28.94-8.49,8.41-8.37-8.44a5.37,5.37,0,0,1,7.63-7.55l.81.82.87-.86a5.36,5.36,0,1,1,7.55,7.62Z" />
    </Icon>
  );
}
export default NPO;
