import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { Text } from "theme-ui";
import { routes } from "../../../const";
import {
  selectSignalRConnected,
  selectSignalRError,
  selectSignalRFailed,
  selectSignalROffline,
} from "../../../reduxStore/signalR/signalR.reducer";
import PageCover from "../../routes/layout/pages/PageCover";
import Centered from "../layout/Centered";
import FullPageSpinner from "../spinners/FullPageSpinner";

function SignalRStatus(): ReactElement | null {
  const { t } = useTranslation();
  const isOffline = useSelector(selectSignalROffline);
  const isSignalRConnected = useSelector(selectSignalRConnected);
  const hasSignalRFailed = useSelector(selectSignalRFailed);
  const signalRError = useSelector(selectSignalRError);

  if (hasSignalRFailed) {
    const state: ErrorHistoryLocationState = {
      error: signalRError,
    };

    return (
      <Redirect
        to={{
          pathname: routes.error,
          state,
        }}
      />
    );
  }

  return isSignalRConnected ? null : (
    <PageCover data-testid="page-cover" disableMouseWheel>
      {/**
       * @TODO add an icon here to indicate interaction has been blocked /
       * connection is offline.
       */}
      {isOffline ? null : (
        <Centered>
          <FullPageSpinner>
            <Text>{t("connecting")}</Text>
          </FullPageSpinner>
        </Centered>
      )}
    </PageCover>
  );
}

export default SignalRStatus;
