import { zoomMax, zoomMaxSteps, zoomMin } from "../const";
import { clamp } from "./utils";

const calcZoomValue = (step: number): number => {
  const _min = Math.log(zoomMin);
  const _max = Math.log(zoomMax);
  const value = _min + ((_max - _min) * step) / (zoomMaxSteps - 1);

  const zoom = +`${Math.round(+`${Math.E ** value}e+3`)}e-3`;

  return clamp(zoom, zoomMin, zoomMax);
};

const zoomSteps = Array(zoomMaxSteps)
  .fill(null)
  .map((val, index) => calcZoomValue(index));

/**
 * Calculate the next zoom step.
 */
export const nextZoomStep = (currentZoomLevel: number): number => {
  return (
    zoomSteps.find((level) => level > currentZoomLevel) ||
    zoomSteps[zoomSteps.length - 1]
  );
};

/**
 * Calculate the previous zoom step.
 */
export const previousZoomStep = (currentZoomLevel: number): number => {
  const nextStepIndex = zoomSteps.indexOf(nextZoomStep(currentZoomLevel));

  // if current zoom level is one of zoomSteps step then go -2 if is in between go -1
  const offset =
    currentZoomLevel !== zoomMax && zoomSteps.includes(currentZoomLevel)
      ? 2
      : 1;
  const zoomLevel = zoomSteps[nextStepIndex - offset] || currentZoomLevel;

  return zoomLevel;
};

export const calculateZoomPercentageValue = (zoomLevel: number): number =>
  Math.round(zoomLevel * 100);
