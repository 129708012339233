import { createBrushAndInkPath } from "../";
import { BrushType, penDevices } from "../../../../const";
import { addedAction } from "../../../../reduxStore/canvas/history/history.entry.actions";
import { reduxDispatch } from "../../../../reduxStore/redux.dispatch";
import { detectedOS } from "../../../../tools/detectOS";
import { LiveColor } from "../../color/liveColor";
import { selectObjects } from "../../patches/extends-selection/selection.utils";
import {
  extractDistinctPaths,
  fitLastPointWithChaikin,
  RawPoint,
} from "../utils/pathSmoothing";
import { BaseBrush } from "./BaseBrush";

const { windows: isWindows } = detectedOS;

const minPressure = 0.01;
const maxPressure = 1;

export abstract class BaseInkBrush extends BaseBrush {
  public BRUSH_USES_CURVES = true;

  /**
   * The decimate threshold controls how close together points are allowed to
   * be. At a zoom level of 100% the decimate threshold equates to pixels.
   */
  protected DECIMATE_THRESHOLD = 3;

  /**
   * When this value is `true` the points array will be modified as new points
   * are recorded to add additional points, fitted to a curve, using the
   * Chaikin algorithm.
   */
  protected APPLY_PATH_FITTING = true;

  /**
   * When this value is `true` the context will be cleared before each render.
   * This is required because the curve-fitting algorithm needs to redraw
   * segments after sufficient points become available.
   */
  protected CLEAR_CANVAS_WHILE_DRAWING = false;

  /**
   * How long in ms do we wait before finalizing the drawing and converting it
   * to an object on the canvas. Converting to an object is expensive (~20ms)
   * so it can block the thread and cause problems if the user is writing
   * quickly with a stylus.
   */
  protected FINALIZE_AFTER_DELAY = 250 as const;

  /**
   * Enable Fabric's objectCaching for this brush?
   * Some brushes render better at extreme zoom levels if they are redrawn.
   */
  public ENABLE_OBJECT_CACHING = true;

  color = "#000000";
  protected liveColor: LiveColor;

  private _renderQueue = 0;
  private _finalizeTimeout = 0;
  private _renderedToIndex = 0;

  protected _points: RawPoint[] = [];
  protected _isStylusPointer = false;
  protected _clearCanvasOnNextRender = false;

  constructor(
    public brushType: BrushType,
    protected canvas: fabric.CollaboardCanvas,
    options?: fabric.CollaboardBrushOptions
  ) {
    super(brushType, canvas, options);

    this._points = [];

    this.liveColor = new LiveColor(this.color);
    this.setColor(options?.color ?? this.color);
  }

  /**
   * Change the color of the brush.
   * @param {string} color Brush color
   */
  public setColor(color: string): void {
    this.color = color;
    this.liveColor = new LiveColor(color);
  }

  public onMouseDown(
    pointer: fabric.Position,
    options: fabric.BrushEventOptions
  ): void {
    const isDrawingPointer = this._isDrawingPointerEvent(options.e);

    if (!isDrawingPointer) {
      return;
    }

    this._isStylusPointer = this._isStylus(options);
    this._capturePointer(pointer, options);
    this._renderWhileDrawing();
  }

  public onMouseMove(
    pointer: fabric.Position,
    options: fabric.BrushEventOptions
  ): void {
    const isDrawingPointer = this._isDrawingPointerEvent(options.e);

    if (!isDrawingPointer) {
      return;
    }

    const wasPointAdded = this._capturePointer(pointer, options);
    wasPointAdded && this.APPLY_PATH_FITTING && this._applyPathFitting();
    wasPointAdded && this._renderWhileDrawing();
  }

  public onMouseUp(options: fabric.BrushEventOptions): boolean {
    const isDrawingPointer = this._isDrawingPointerEvent(options.e);

    if (!isDrawingPointer) {
      return this.canvas._isCurrentlyDrawing;
    }

    this._addBreakPoint();
    this._queueFinalization();

    return false; // Used as `_isCurrentlyDrawing` value
  }

  /**
   * Render the full brush path.
   *
   * This is called when an existing ink path is painted on to the canvas.
   */
  public fullRender(
    ctx: CanvasRenderingContext2D,
    points: fabric.CollaboardPoint[]
  ): void {
    if (!ctx) {
      return;
    }

    ctx.save();
    this._setBrushStyles(ctx);

    this._partialRender(ctx, 0, points);

    if (this.ENABLE_DEBUG_MODE) {
      this._renderRawPoints(ctx, points);
    }

    ctx.restore();
  }

  /**
   * Method called by Fabric.
   *
   * @param {CanvasRenderingContext2D} [ctx] Canvas context
   */
  public _render(ctx: CanvasRenderingContext2D | null): void {
    if (ctx) {
      this._partialRender(ctx);
    }
  }

  /**
   * Sets brush styles (called by Fabric).
   */
  public _setBrushStyles(ctx: CanvasRenderingContext2D | null): void {
    ctx = ctx || this.canvas.contextTop;
    if (ctx) {
      ctx.globalAlpha = 1;
      ctx.strokeStyle = this.liveColor.toLive();
      ctx.lineWidth = this.strokeWidth;
      ctx.miterLimit = 10;
      ctx.lineJoin = "round";
      ctx.lineCap = "round";
    }
  }

  protected abstract _partialRender(
    ctx: CanvasRenderingContext2D | null,
    renderFrom?: number,
    _points?: RawPoint[]
  ): void;

  /**
   * Render while the user is drawing.
   *
   * This must be highly performant.
   */
  private _renderWhileDrawing(): void {
    // If we need to render then prevent finalization
    clearTimeout(this._finalizeTimeout);
    fabric.util.cancelAnimFrame(this._renderQueue);

    this._renderQueue = fabric.util.requestAnimFrame(() => {
      const ctx = this.canvas.contextTop;

      if (!ctx) {
        return;
      }

      const numberOfPointsToRender =
        this._points.length - this._renderedToIndex;

      if (this.CLEAR_CANVAS_WHILE_DRAWING || this._clearCanvasOnNextRender) {
        this.canvas.clearContext(ctx);
        this._clearCanvasOnNextRender = false;
      }

      this._saveAndTransform(ctx);
      this._setBrushStyles(ctx);

      this._partialRender(ctx, this._renderedToIndex);

      if (this.ENABLE_DEBUG_MODE) {
        this._renderRawPoints(ctx);
      }

      ctx.restore();

      this._renderedToIndex += numberOfPointsToRender;
    });
  }

  /**
   * Once the user has finished drawing we convert the path into a real object.
   */
  public async finalize(): Promise<void> {
    const { color, strokeWidth, brushType } = this;
    const paths = extractDistinctPaths(this._points);

    this._reset();

    const inkPaths = await Promise.all(
      paths.map((points) => {
        return createBrushAndInkPath({
          color,
          strokeWidth,
          brushType,
          points,
        });
      })
    );

    const inkPathsToAdd = inkPaths.filter(
      (path) => !!path
    ) as fabric.CollaboardInkPath[];

    if (inkPathsToAdd.length) {
      this.canvas.add(...inkPathsToAdd);

      // Dispatch action per ink path so the user can undo each line separately
      inkPathsToAdd.forEach((inkPath) => reduxDispatch(addedAction([inkPath])));

      // Only the last ink path will be locked by the server
      selectObjects(this.canvas, inkPathsToAdd.slice(-1));
      this.canvas.requestRenderAll();
    }

    const ctx = this.canvas.contextTop;
    if (ctx) {
      ctx.closePath();
      this.canvas.clearContext(ctx);
    }
  }

  /**
   * Finalize the path and convert it into an object on the main canvas.
   *
   * Generating a UUID is expensive (approx 20ms+) so we delay this work
   * until the user has paused interacting for some time. Otherwise, when
   * writing quickly with a stylus, it is possible to do a
   * `mouseup` -> `mousedown` action in less than 20ms. If the thread is
   * currently generating a UUID at this point then the `mousedown` event
   * and some `mousemove` events can be missed, resulting in an incomplete
   * path.
   */
  private _queueFinalization(): void {
    clearTimeout(this._finalizeTimeout);
    this._finalizeTimeout = window.setTimeout(async () => {
      await this.finalize();
    }, this.FINALIZE_AFTER_DELAY);
  }

  /**
   * Capture the pointer position and pressure
   */
  private _capturePointer(
    pointer: fabric.Position,
    options: fabric.BrushEventOptions
  ): boolean {
    const pressure = this._getPressure(options);

    const point = new fabric.Point(
      pointer.x,
      pointer.y
    ) as fabric.CollaboardPoint;

    const modifiedPressure = this._modifyPressure(pressure);

    const clampedPressure = Math.min(
      maxPressure,
      Math.max(minPressure, modifiedPressure)
    );

    point.pressure = clampedPressure;

    return this._addPoint(point);
  }

  /**
   * Add a new point to the points array if it is suitable.
   */
  private _addPoint(point: fabric.CollaboardPoint): boolean {
    const previousPoint = this._points[this._points.length - 1];
    // If previous point was null that means we're at the start of a new path
    // so we always keep this point
    if (!previousPoint) {
      this._points.push(point);
      return true;
    }

    // Don't keep points that are the same as previous points
    if (point.eq(previousPoint)) {
      return false;
    }

    // Calculate point distance including zoom
    // TODO: Take into account the thickness of the brush?
    const distance = Math.pow(
      this.DECIMATE_THRESHOLD / this.canvas.getZoom(),
      2
    );

    const distanceFromPrevious =
      Math.pow(previousPoint.x - point.x, 2) +
      Math.pow(previousPoint.y - point.y, 2);

    if (distanceFromPrevious >= distance) {
      this._points.push(point);
      return true;
    }

    return false;
  }

  /**
   * Fit the last (latest) points with the Chaikin curve fitting algorithm.
   */
  private _applyPathFitting(): void {
    this._points = fitLastPointWithChaikin(this._points);
  }

  /**
   * To improve performance drawing mode isn't deactivated immediately on
   * mouseup. As such we need to indicate when a mouseup occurs.
   */
  private _addBreakPoint(): void {
    this._points.push(null);
  }

  /**
   * Reset brush.
   */
  protected _reset(): void {
    this._points = [];
    this._renderedToIndex = 0;
  }

  /**
   * Determine if user is using a stylus (e.g. Apple Pencil)
   */
  private _isStylus(options: fabric.BrushEventOptions): boolean {
    const { e } = options;
    return penDevices.includes(e.pointerType);
  }

  /**
   * Get pressure from event, between 0.01 and 1.
   */
  private _getPressure(options: fabric.BrushEventOptions): number {
    // By default mouse pressure is 0.5
    const {
      e: { pressure = 0.5 },
    } = options;
    const normalizedPressure = isWindows ? pressure / 0.7 : pressure;

    return Math.min(maxPressure, Math.max(minPressure, normalizedPressure));
  }

  /**
   * Modify the pointer pressure.
   *
   * By default no modification is made, but specific brushes can override this.
   */
  protected _modifyPressure(pressure: number): number {
    return pressure;
  }

  /**
   * Sets the transformation on given context
   */
  private _saveAndTransform(ctx: CanvasRenderingContext2D): void {
    ctx.save();
    ctx.transform(...this.canvas.viewportTransform);
  }

  /**
   * Debug method - draws raw points on canvas
   */
  private _renderRawPoints(
    ctx: CanvasRenderingContext2D,
    _points?: RawPoint[]
  ): void {
    const points: RawPoint[] = _points || this._points;

    points.forEach((point) => {
      if (point) {
        this._renderDebugPoint(ctx, point.x, point.y);
      }
    });
  }

  /**
   * Debug method - draws curve control point on canvas
   */
  protected _renderDebugPoint(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    size = 100,
    color = "#f00"
  ): void {
    ctx.save();
    ctx.lineWidth = 20;
    ctx.strokeStyle = color;
    ctx.strokeRect(x - size / 2, y - size / 2, size, size);
    ctx.restore();
  }
}
