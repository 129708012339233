import { CanvasAppAction } from "./app/app.actions";
import { ChatAction } from "./chat/chat.actions";
import { DrawingAction } from "./drawing/drawing.actions";
import { HistoryAction } from "./history/history.actions";
import { MeetingAction } from "./meeting/meeting.actions";
import { CanvasObjectsAction } from "./objects/objects.actions";
import { PresentationPresenterAction } from "./presentation/presentation.presenter.actions";
import { PresentationViewerAction } from "./presentation/presentation.viewer.actions";
import { ProjectAction } from "./project/project.actions";
import { SelectionAction } from "./selection/selection.actions";
import { CanvasSettingsAction } from "./settings/settings.actions";
import { TimedSessionAction } from "./timedSession/timedSession.action";
import { VotingAction } from "./voting/voting.actions";

export enum CanvasActionType {
  ON_CANVAS_ENTER = "ON_CANVAS_ENTER",
  ON_CANVAS_EXIT = "ON_CANVAS_EXIT",
}

export const onCanvasEnter = (payload: { projectId: string }) =>
  ({
    type: CanvasActionType.ON_CANVAS_ENTER,
    payload,
  } as const);

export const onCanvasExit = () =>
  ({
    type: CanvasActionType.ON_CANVAS_EXIT,
    payload: undefined,
  } as const);

export type CanvasEnterAction = ReturnType<typeof onCanvasEnter>;

export type CanvasAction = CanvasEnterAction | ReturnType<typeof onCanvasExit>;

export type ApplicationCanvasAction =
  | CanvasAction
  | CanvasAppAction
  | ChatAction
  | DrawingAction
  | HistoryAction
  | MeetingAction
  | CanvasObjectsAction
  | PresentationPresenterAction
  | PresentationViewerAction
  | ProjectAction
  | SelectionAction
  | CanvasSettingsAction
  | TimedSessionAction
  | VotingAction;
