import LocalStorage from "../../../tools/localStorage";
import {
  SelectableUserSettings,
  ToggleableUserSettings,
} from "./userSettings.reducer";

export enum UserSettingsActionType {
  RESET_USER_SETTINGS = "RESET_USER_SETTINGS",
  SET_USER_SETTINGS = "SET_USER_SETTINGS",
  TOGGLE_USER_SETTING = "TOGGLE_USER_SETTING",
}

export const resetUserSettingsAction = () =>
  ({
    type: UserSettingsActionType.RESET_USER_SETTINGS,
    payload: undefined,
  } as const);

export enum UserSettingOrigin {
  defaultValue = "defaultValue",
  uiSettings = "uiSettings",
  /**
   * The app has temporarily set the value, either by detection or as a side
   * effect (e.g. hiding the minimap during a presentation).
   *
   * Value will NOT be stored in local storage.
   */
  setByApp = "setByApp",
  /**
   * The user has explicitly set their preference.
   *
   * Value will be stored in local storage.
   */
  setByUser = "setByUser",
}

type UserSetting<T, V = ValuesOf<T>> = {
  key: keyof T;
  storage: LocalStorage<V>;
  defaultValue: V;
};

type SelectableValues = ValuesOf<SelectableUserSettings>;
type ToggleableValues = ValuesOf<ToggleableUserSettings>;

export type SelectableUserSetting = UserSetting<
  SelectableUserSettings,
  SelectableValues
>;

export type ToggleableUserSetting = UserSetting<ToggleableUserSettings>;

export const isSelectableUserSetting = (
  setting: SelectableUserSetting | ToggleableUserSetting
): setting is SelectableUserSetting => {
  return typeof setting.defaultValue !== "boolean";
};

export type SetUserSettingPayload = {
  setting: SelectableUserSetting | ToggleableUserSetting;
  value: SelectableValues | ToggleableValues;
  origin: UserSettingOrigin;
};

export const setUserSettingAction = (
  payload: SetUserSettingPayload
): SetUserSettingsAction => setUserSettingsAction([payload]);

export const setUserSettingsAction = (payload: SetUserSettingPayload[]) =>
  ({
    type: UserSettingsActionType.SET_USER_SETTINGS,
    payload,
  } as const);

export type ToggleUserSettingPayload = {
  setting: ToggleableUserSetting;
  origin: UserSettingOrigin;
};

export const toggleUserSettingAction = (payload: ToggleUserSettingPayload) =>
  ({
    type: UserSettingsActionType.TOGGLE_USER_SETTING,
    payload,
  } as const);

export type SetUserSettingsAction = ReturnType<typeof setUserSettingsAction>;
export type ToggleUserSettingAction = ReturnType<
  typeof toggleUserSettingAction
>;

export type UserSettingsActions =
  | ReturnType<typeof resetUserSettingsAction>
  | SetUserSettingsAction
  | ToggleUserSettingAction;
