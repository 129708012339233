import React, { ReactElement, ElementType } from "react";
import { Box, Text, ThemeUICSSObject } from "theme-ui";

type Props = {
  text: string;
  as?: ElementType;
  isLineVisible?: boolean;
  extSx?: ThemeUICSSObject;
  textSx?: ThemeUICSSObject;
};

function LineSeparator({
  text,
  as = "h3",
  isLineVisible = true,
  extSx = {},
  textSx = {},
}: Props): ReactElement {
  return (
    <Box as={as} sx={{ display: "flex", alignItems: "center", ...{ extSx } }}>
      <Text sx={{ pr: [2], ...textSx }}>{text}</Text>
      {isLineVisible && (
        <Box sx={{ flex: 1, height: "1px", backgroundColor: "muted" }} />
      )}
    </Box>
  );
}

export default LineSeparator;
