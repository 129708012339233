import { FormikConfig } from "formik";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { object, string } from "yup";
import { SendResetPasswordEmail, sendResetPasswordEmail } from "../../../api";
import { getAppName } from "../../../tools/customer";
import { LogCategory } from "../../../tools/telemetry";
import { useErrorHandler } from "../../shared/hooks/useErrorHandler";

type UseSendTokenConfig = {
  onSendToken: (values: SendResetPasswordEmail) => void;
};

type UseSendToken = {
  formikProps: FormikConfig<SendResetPasswordEmail>;
};

export const useSendToken = ({
  onSendToken,
}: UseSendTokenConfig): UseSendToken => {
  const { t } = useTranslation();

  const { fallbackErrorHandler } = useErrorHandler();

  const formikProps: FormikConfig<SendResetPasswordEmail> = {
    initialValues: { User: "" },
    onSubmit: async (values) => {
      try {
        await sendResetPasswordEmail(values);
        toast(t("form.message.tokenSent", { appName: getAppName() }));
        onSendToken(values);
      } catch (error) {
        fallbackErrorHandler(
          error,
          "Failed to send password reset",
          LogCategory.auth
        );
      }
    },
    validationSchema: object().shape({
      User: string()
        .email(t("clientError.emailNotValid"))
        .required(t("clientError.required")),
    }),
    validateOnMount: true,
  };

  return {
    formikProps,
  };
};
