import { isNil } from "ramda";
import {
  all,
  Effect,
  getContext,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import {
  activatePenOnTouchSetting,
  clickableUrlsSetting,
  embedTransparencySetting,
  floatingToolbarSetting,
  minimapOpenSetting,
  objectGradientsSetting,
  uiSizeSetting,
  wheelInputAsTouchpadSetting,
  windowBorderOverlaySetting,
} from ".";
import { uiSizeMultiplier } from "../../../const";
import { UISettingsState } from "../../uiSettings/uiSettings.reducer";
import {
  isSelectableUserSetting,
  SelectableUserSetting,
  SetUserSettingPayload,
  SetUserSettingsAction,
  setUserSettingsAction,
  ToggleableUserSetting,
  ToggleUserSettingAction,
  ToggleUserSettingPayload,
  UserSettingOrigin,
  UserSettingsActionType,
} from "./userSettings.actions";
import { UserSettingsState } from "./userSettings.reducer";

const getSetUserSettingPayload = (
  setting: SelectableUserSetting | ToggleableUserSetting,
  uiSettings: UISettingsState
): SetUserSettingPayload => {
  const { value, origin } = getInitialValueAndOrigin(setting, uiSettings);
  return {
    setting,
    value,
    origin,
  };
};

const getInitialValueAndOrigin = (
  setting: SelectableUserSetting | ToggleableUserSetting,
  uiSettings: UISettingsState
) => {
  const { dynamicFeatures } = uiSettings;
  const {
    defaultSettingsState,
  } = dynamicFeatures as RuntimeConfigDynamicFeatureConfig;
  const dynamicConfigValue = defaultSettingsState?.[setting.key];
  const { storage, defaultValue } = setting;

  const valueFromStorage = storage.get();

  if (!isNil(valueFromStorage)) {
    return {
      value: valueFromStorage,
      origin: UserSettingOrigin.setByUser,
    };
  }

  if (!isNil(dynamicConfigValue)) {
    return {
      value: dynamicConfigValue,
      origin: UserSettingOrigin.uiSettings,
    };
  }

  return {
    value: defaultValue,
    origin: UserSettingOrigin.defaultValue,
  };
};

function* loadUserSettings() {
  const state: ApplicationGlobalState = yield select();
  const { uiSettings } = state;

  const action = setUserSettingsAction([
    // Toggleable
    getSetUserSettingPayload(activatePenOnTouchSetting, uiSettings),
    getSetUserSettingPayload(clickableUrlsSetting, uiSettings),
    getSetUserSettingPayload(embedTransparencySetting, uiSettings),
    getSetUserSettingPayload(floatingToolbarSetting, uiSettings),
    getSetUserSettingPayload(minimapOpenSetting, uiSettings),
    getSetUserSettingPayload(objectGradientsSetting, uiSettings),
    getSetUserSettingPayload(windowBorderOverlaySetting, uiSettings),
    getSetUserSettingPayload(wheelInputAsTouchpadSetting, uiSettings),
    // Selectable
    getSetUserSettingPayload(uiSizeSetting, uiSettings),
  ]);

  yield put(action);
}

function* setUserSettings({ payload }: SetUserSettingsAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const state: ApplicationGlobalState = yield select();
  const {
    canvas: { userSettings },
  } = state;

  payload.forEach((p) => changeUserSetting(p, userSettings, canvas));
}

function* toggleUserSetting({ payload }: ToggleUserSettingAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const state: ApplicationGlobalState = yield select();
  const {
    canvas: { userSettings },
  } = state;

  changeUserSetting(payload, userSettings, canvas);
}

function changeUserSetting(
  { setting, origin }: ToggleUserSettingPayload | SetUserSettingPayload,
  userSettings: UserSettingsState,
  canvas: fabric.CollaboardCanvas
) {
  const {
    activatePenOnTouch,
    clickableUrls,
    embedTransparency,
    objectGradients,
    uiSize,
    wheelInputAsTouchpad,
  } = userSettings;

  switch (setting) {
    case activatePenOnTouchSetting: {
      canvas.activatePenOnTouch = activatePenOnTouch.value;
      break;
    }
    case clickableUrlsSetting: {
      canvas.trigger("custom:canvas:set:urls:clickable", {
        urlsClickable: clickableUrls.value,
      });
      canvas.requestRenderAll();
      break;
    }
    case embedTransparencySetting: {
      canvas.embedTransparency = embedTransparency.value;
      canvas.trigger("custom:canvas:embed:transparency");
      break;
    }
    case objectGradientsSetting: {
      canvas.enableObjectGradients = objectGradients.value;
      canvas.requestRenderAll();
      break;
    }
    case uiSizeSetting: {
      document.documentElement.style.setProperty(
        "--uiScale",
        `${uiSizeMultiplier[uiSize.value]}`
      );
      break;
    }
    case wheelInputAsTouchpadSetting: {
      if (
        canvas &&
        wheelInputAsTouchpad.origin === UserSettingOrigin.setByUser
      ) {
        wheelInputAsTouchpad.value
          ? canvas.setWheelInputAsTouchpad()
          : canvas.setWheelInputAsMouse();
      }
      break;
    }
    default:
      break;
  }

  if (origin === UserSettingOrigin.setByUser) {
    if (isSelectableUserSetting(setting)) {
      setting.storage.set(userSettings[setting.key].value);
    } else {
      setting.storage.set(userSettings[setting.key].value);
    }
  }
}

export function* userSettingsSaga(): Generator<Effect> {
  yield all([
    // userSettingsSaga is spawned only on canvas enter. The user is surely logged in and the canvas
    // is initialized, so we don't need to wait for any action to load the initial user settings.
    loadUserSettings(),
    takeEvery(UserSettingsActionType.SET_USER_SETTINGS, setUserSettings),
    takeEvery(UserSettingsActionType.TOGGLE_USER_SETTING, toggleUserSetting),
  ]);
}
