import { MediaAction } from "../../../types/enum";

export enum PresentationPresenterActionType {
  CONTROL_MEDIA = "CONTROL_MEDIA",
  CONTROL_DOCUMENT = "CONTROL_DOCUMENT",
  START_PRESENTING = "START_PRESENTING",
  RESUME_PRESENTING = "RESUME_PRESENTING",
  PRESENTING_IN_OTHER_WINDOW = "PRESENTING_IN_OTHER_WINDOW",
  STOP_PRESENTING = "STOP_PRESENTING",
}

type ControlMediaPaylaod = {
  object: fabric.Object;
  action: MediaAction;
  currentTime?: number;
};

export const controlMediaAction = (payload: ControlMediaPaylaod) =>
  ({
    type: PresentationPresenterActionType.CONTROL_MEDIA,
    payload,
  } as const);

export type ControlDocumentPayload = { object: fabric.Object; page: number };

export const controlDocumentAction = (payload: ControlDocumentPayload) =>
  ({
    type: PresentationPresenterActionType.CONTROL_DOCUMENT,
    payload,
  } as const);

export const startPresentingAction = () =>
  ({
    type: PresentationPresenterActionType.START_PRESENTING,
    payload: undefined,
  } as const);

export const resumePresentingAction = () =>
  ({
    type: PresentationPresenterActionType.RESUME_PRESENTING,
    payload: undefined,
  } as const);

export const presentingInOtherWindowAction = () =>
  ({
    type: PresentationPresenterActionType.PRESENTING_IN_OTHER_WINDOW,
    payload: undefined,
  } as const);

export const stopPresentingAction = () =>
  ({
    type: PresentationPresenterActionType.STOP_PRESENTING,
    payload: undefined,
  } as const);

export type ControlMediaAction = ReturnType<typeof controlMediaAction>;
export type ControlDocumentAction = ReturnType<typeof controlDocumentAction>;
export type StartPresentingAction = ReturnType<typeof startPresentingAction>;
export type ResumePresentingAction = ReturnType<typeof resumePresentingAction>;
export type PresentingInOtherWindowAction = ReturnType<
  typeof presentingInOtherWindowAction
>;
export type StopPresentingAction = ReturnType<typeof stopPresentingAction>;

export type PresentationPresenterAction =
  | ControlMediaAction
  | ControlDocumentAction
  | StartPresentingAction
  | ResumePresentingAction
  | PresentingInOtherWindowAction
  | StopPresentingAction;
