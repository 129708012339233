import { useEffect, useState } from "react";

export default (className: string): void => {
  const [previousClass, setPreviousClass] = useState(className);
  useEffect(() => {
    const { classList } = document.body;
    classList.remove(previousClass);
    classList.add(className);
    setPreviousClass(className);

    return () => {
      classList.remove(className);
    };
  }, [className, previousClass]);
};
