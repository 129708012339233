import { useState, useCallback, useMemo } from "react";

export type ModalProps = {
  isOpen: boolean;
  open: () => void;
  onClose: () => void;
  close: () => void;
  closeOnNextTick: () => void;
};

export const useSimpleModal = ({
  onClose: onCloseCallback,
}: { onClose?: () => void } = {}): ModalProps => {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => {
    setIsOpen(false);
    onCloseCallback && onCloseCallback();
  }, [onCloseCallback]);

  /**
   * If a modal is closed before an internal Promise has resolved, for example
   * an `async onSubmit` handler, React will warn us about a memory leak.
   *
   * This method allows you to avoid that error by closing the modal in the next
   * tick, i.e. after the Promise has resolved.
   */
  const closeOnNextTick = useCallback(() => setTimeout(close), [close]);

  return useMemo(
    () => ({
      isOpen,
      open,
      // Called `onClose` so that the object can be used for <ModalTemplate modalProps={modalProps} />
      onClose: close,
      close,
      closeOnNextTick,
    }),
    [isOpen, open, close, closeOnNextTick]
  );
};
