import React, { ReactElement } from "react";
import HostingMap from "../../features/authentication/login/HostingMap";
import LoginFooter from "../../features/authentication/login/LoginFooter";
import LoginHeaders from "../../features/authentication/login/LoginHeaders";
import LoginOptions from "../../features/authentication/login/LoginOptions";
import Page from "../../features/routes/layout/pages/Page";

function Login(): ReactElement {
  return (
    <Page variant="authenticate">
      <LoginHeaders withHeader />
      <LoginOptions />
      <LoginFooter />
      <HostingMap />
    </Page>
  );
}

export default Login;
