import { fabric } from "fabric";

type CreateEmbedObjectOptions = Partial<fabric.IRectOptions> & {
  embeddedContent: string;
};

export const createEmbedObject = ({
  embeddedContent,
  ...rest
}: CreateEmbedObjectOptions): fabric.CollaboardEmbed => {
  return new fabric.CollaboardEmbed(embeddedContent, rest);
};
