import { WebRTCEvent } from "./types";
import { WebRTCManager } from "./webRTCManager";

export const webRTCMouseConfig = {
  messageInterval: 400, // The higher, the less WebRTC messages are sent. The lower, the less delay between clients
  pointerBufferSize: 15, // How many pointers we use from a message to animate the movement
  pointerFps: 24, // The higher, the smoother playback. The lower, the WebRTC messages are smaller
};

export const signalRWebRTCSignalMessageType = "WEB-RTC-SIGNAL-MESSAGE";

export { WebRTCEvent, WebRTCManager };
