import { FieldMetaProps } from "formik";
import { RefObject, useRef } from "react";
import { SxStyleProp } from "theme-ui";
import { useFocusOnError } from "./useFocusOnError";

type Config = {
  meta: FieldMetaProps<boolean | string>;
  name: string;
};

type UseFieldError<T extends HTMLElement> = {
  errorInputSx: SxStyleProp;
  errorCheckboxSx: SxStyleProp;
  fieldRef: RefObject<T>;
};

export const useFieldError = <T extends HTMLElement = HTMLInputElement>({
  meta,
  name,
}: Config): UseFieldError<T> => {
  const fieldRef = useRef(null);
  useFocusOnError({ fieldRef, name });

  const isErrorDisplayed = Boolean(meta.error && meta.touched);

  const errorInputSx = {
    borderColor: isErrorDisplayed ? "warning" : "var(--inputBorder)",
    outlineColor: isErrorDisplayed ? "warning" : "transparent",
    "&:focus": {
      borderColor: isErrorDisplayed ? "warning" : "primary",
      outlineColor: isErrorDisplayed ? "warning" : "primary",
    },
  };

  const errorCheckboxSx = {
    "> div > svg": {
      borderRadius: 0,
      color: isErrorDisplayed ? "warning" : "primary",
    },
  };

  return { errorInputSx, errorCheckboxSx, fieldRef };
};
