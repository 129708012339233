import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from "redux";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
import {
  Config,
  createStateSyncMiddleware,
  initMessageListener,
  withReduxStateSync,
} from "redux-state-sync";
import collaboard from "../tools/collaboard";
import { isRunningInMSTeams } from "../tools/msTeams";
import { AuthActionType } from "./auth/auth.actions";
import { authReducer } from "./auth/auth.reducer";
import { authSaga } from "./auth/auth.saga";
import { canvasReducer } from "./canvas/canvas.reducer";
import { canvasSaga } from "./canvas/canvas.saga";
import { reduxSingleton } from "./redux.singleton";
import { selectorContext } from "./redux.utils";
import { signalRReducer } from "./signalR/signalR.reducer";
import { signalRSaga } from "./signalR/signalR.saga";
import { subscriptionDetailsReducer } from "./subscriptionDetails/subscriptionDetails.reducer";
import { uiSettingsReducer } from "./uiSettings/uiSettings.reducer";
import { userPermissionsReducer } from "./userPermissions/userPermissions.reducer";

const rootReducer = combineReducers<ApplicationGlobalState>({
  auth: authReducer,
  canvas: canvasReducer,
  signalR: signalRReducer,
  subscriptionDetails: subscriptionDetailsReducer,
  uiSettings: uiSettingsReducer,
  userPermissions: userPermissionsReducer,
});

const tabSyncConfig: Config = {
  channel: "collaboard_redux_channel",
  whitelist: isRunningInMSTeams()
    ? []
    : [AuthActionType.SYNC_LOGGED_IN, AuthActionType.SYNC_LOGGED_OUT],
};

function* rootSaga() {
  yield all([authSaga(), canvasSaga(), signalRSaga()]);
}
const sagaMiddleware = createSagaMiddleware({
  /**
   * Add canvas and SignalR references to redux saga's context so that they can be retrieved inside
   * a saga without using a direct import and can be easily mocked in tests. It should also help
   * with reducing the risk of circular-imports since we avoid direct imports.
   *
   * @NOTE This requires a bit of extra care so that the references are defined by the time they will
   * be needed in a saga.
   */
  context: collaboard,
});

// Just like with redux-saga, will set the references so that they can be retrieved inside a selector
selectorContext.setContext(collaboard);

const middlewares = [
  createStateSyncMiddleware(tabSyncConfig),

  /**
   * Put saga middleware as last because the other middlewares run after the new state is computed so
   * this is going to actually run as first.
   * @TODO #7104: once the above middlewares and canvasMiddleware are migrated to sagas, we can probably
   * restore a more natural order
   */
  sagaMiddleware,
];
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      serialize: {
        // Enable serializers for "undefined", Map, Set etc.
        options: true,
      },
    })
  : compose;

export const reduxStore = createStore(
  (withReduxStateSync(
    rootReducer
    /**
     * Casting is required to revert store typing to the correct type, ensuring
     * that autocomplete and type checking are available when the store is
     * accessed elsewhere.
     */
  ) as unknown) as typeof rootReducer,
  composeEnhancer(applyMiddleware(...middlewares))
);

initMessageListener((reduxStore as unknown) as Store<unknown, AnyAction>);
sagaMiddleware.run(rootSaga);

reduxSingleton.setStore(reduxStore);
window.reduxStore = reduxStore;
