import React from "react";
import { useTranslation } from "react-i18next";
import { Text } from "theme-ui";

type BatchImportResultCompleteProps = {
  maxUsersReached: boolean;
  added: number;
  removed: number;
  invalid: number;
  existing: number;
};

function BatchImportResultComplete({
  maxUsersReached,
  added,
  removed,
  invalid,
  existing,
}: BatchImportResultCompleteProps): React.ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <Text sx={{ mb: [3] }}>{t("dialog.batchUserImport.result.text")}</Text>

      {((added === 0 && removed === 0) || maxUsersReached) && (
        <Text variant="error" sx={{ mb: [3] }}>
          {t("dialog.batchUserImport.result.error")}
        </Text>
      )}

      <Text sx={{ mb: [3] }}>
        {t(
          invalid === 0 && existing === 0
            ? "dialog.batchUserImport.result.totalSuccess"
            : "dialog.batchUserImport.result.partialSuccess",
          {
            added,
            removed,
            invalid,
            existing,
          }
        )}
      </Text>

      {maxUsersReached && (
        <>
          <Text variant="error">{t("apiError.MaxNumberOfUsersExceeded")}</Text>
        </>
      )}
    </>
  );
}

export default BatchImportResultComplete;
