import { fabric } from "fabric";
import i18n from "i18next";
import { canvasObjectIds } from "../../../const";
import { VotingSessionType } from "../../../reduxStore/canvas/voting/voting.reducer";
import { basicIcons, handsIcons, signIcons } from "../../../shapes";
import { ApiShapeId } from "../../../shapes/shapeId";
import { getCustomerConfiguration } from "../../../tools/customer";
import { escapeFileName, urlToFile } from "../../../tools/files";
import { wordwrap } from "../../../tools/text";
import { isDefined } from "../../../tools/utils";
import { isRemoteObject } from "../../utils/fabricObjects";
import { createImages } from "../createStorageObjects";
import { createTextBox } from "../freeFormText";
import { applyTransformAndUpdate } from "../group/group.utils";
import { createShape } from "../shape/shape";

const padding = 15;
const fontSize = 30;
const { theme } = getCustomerConfiguration();
const iconColor = theme.colors.accent;

type SummaryObjectConfig = {
  votingSession: ApiVotingOverview;
  thumbnailPromise: Promise<string | void>;
  groupPosition: fabric.Point;
  toSummaryGroup(summaryObjects: fabric.Object[]): Promise<fabric.Group>;
};

type HorizontalCenterConfig = {
  noPadding?: boolean;
};

export const createVotingSessionSummaryObjects = async ({
  votingSession,
  thumbnailPromise,
  groupPosition,
  toSummaryGroup,
}: SummaryObjectConfig): Promise<{
  summaryGroup: fabric.Group;
  summaryObjects: fabric.Object[];
}> => {
  const { DateCreated, Description } = votingSession;
  const { VotingType, MostVotedCount } = votingSession;
  const votersCount = 0; // TODO: #5861: Missing from the `votingSession` payload
  const objects = await Promise.all([
    createThumbnail(thumbnailPromise, Description), // goes first to minimize signals race-condition probability (when API resolves PostUploaded before PostNew)
    createStroke(),
    createTypeIconBackground(),
    VotingType === VotingSessionType.Vote
      ? createThumbUpIcon()
      : createStarIcon(),
    createDescription(Description),
    createDateLabel(),
    createDate(DateCreated),
    createVotesCount(MostVotedCount),
    createVotesLabel(VotingType, votersCount),
  ]);

  const summaryObjects = objects.flat().filter(isDefined);
  const summaryGroup = await toSummaryGroup(summaryObjects);
  await onPreviewLoaded(summaryObjects);

  const [
    thumbnail,
    stroke,
    typeCircle,
    typeIcon,
    description,
    dateLabel,
    date,
    votesCount,
    votesLabel,
  ] = objects;

  align.centerOf(typeCircle).vertically();
  align.centerOf(typeCircle).horizontally();
  stroke.set({ top: 0 });

  center(...typeIcon).horizontally({ noPadding: true });
  align.centerOf(...typeIcon).vertically();

  if (VotingType === VotingSessionType.Vote) {
    const [sleeve, hand] = typeIcon;
    align.bottomOf(sleeve).toTheBottomOf(hand);
    // bring hand and sleeve icons slightly closer
    shift(hand).left(40);
    shift(sleeve).right(10);
  }

  put(description).below(typeCircle);
  put(dateLabel, date).below(description);
  center(description).horizontally();
  center(dateLabel, date).horizontally();

  const summaryObjectWidth = Math.max(
    getWidth(dateLabel) + padding + getWidth(date),
    getWidth(description)
  );

  if (thumbnail) {
    scale(thumbnail).toMatch(summaryObjectWidth);
    center(thumbnail).horizontally();
    put(thumbnail).below(date);
    put(votesCount).below(thumbnail);
  } else {
    put(votesCount).below(date);
  }

  put(votesLabel).below(votesCount);
  center(votesCount).horizontally();
  center(votesLabel).horizontally();

  const summaryObjectHeight = votesLabel.top + getHeight(votesLabel);

  scale(stroke).widthTo(summaryObjectWidth);
  scale(stroke).heightTo(summaryObjectHeight);
  center(stroke).horizontally();
  applyTransformAndUpdate(summaryGroup); // re-calculate group dimensions
  put(summaryGroup).at(groupPosition);
  shift(summaryGroup).left(summaryGroup.width / 2);

  return {
    summaryGroup,
    summaryObjects,
  };
};

const onPreviewLoaded = (flatSummaryObjects: fabric.Object[]) => {
  const thumbnails = flatSummaryObjects.filter(isRemoteObject);
  const thumbnailPromises = thumbnails.map((o) => o.onPreviewLoaded);
  return Promise.all(thumbnailPromises);
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const emptyShape = signIcons.find((s) => s.key === ApiShapeId.danger)!;

const createTypeIconBackground = () => {
  return createShape({
    shape: basicIcons.find((s) => s.key === ApiShapeId.circle) || emptyShape,
    scaleX: 0.5,
    scaleY: 0.5,
    contextProps: {
      fillColor: "#333333",
      strokeColor: "transparent",
    },
  });
};

const createThumbUpIcon = (): Promise<fabric.CollaboardShape[]> => {
  return Promise.all([
    createShape({
      shape: basicIcons.find((s) => s.key === ApiShapeId.square) || emptyShape,

      scaleX: 0.065,
      scaleY: 0.14,
      contextProps: {
        fillColor: iconColor,
        strokeColor: "transparent",
      },
    }),
    createShape({
      shape: handsIcons.find((s) => s.key === ApiShapeId.handok) || emptyShape,
      scaleX: 0.25,
      scaleY: 0.25,
      contextProps: {
        fillColor: iconColor,
        strokeColor: "transparent",
      },
    }),
  ]);
};

const createStarIcon = () => {
  return Promise.all([
    createShape({
      shape: basicIcons.find((s) => s.key === ApiShapeId.star) || emptyShape,
      scaleX: 0.25,
      scaleY: 0.25,
      contextProps: {
        fillColor: iconColor,
        strokeColor: "transparent",
      },
    }),
  ]);
};

const createDescription = (description: string) => {
  return createTextBox({
    text: wrapText(description),
    contextProps: {
      fontSize,
      fontWeight: "bold",
      textAlign: "center",
    },
  });
};

const createDateLabel = () => {
  return createTextBox({
    text: i18n.t("dateLabel"),
    contextProps: {
      fontSize,
      fontWeight: "bold",
    },
  });
};

const createDate = (dateString: string): fabric.FreeFormText => {
  return createTextBox({
    text: i18n.t("app.fullDateTime", { date: new Date(dateString) }),
    contextProps: {
      fontSize,
    },
  });
};

const createThumbnail = async (
  thumbnailPromise: Promise<string | void>,
  description: string
) => {
  const thumbnailExt = "png";
  const thumbnailFileType = `image/${thumbnailExt}`;
  const thumbnailFileName = escapeFileName(`${description}.${thumbnailExt}`);
  const image = await thumbnailPromise;
  return image
    ? await urlToFile(image, thumbnailFileName).then((file) =>
        createImages({
          type: canvasObjectIds.image,
          fileName: thumbnailFileName,
          fileExtension: thumbnailExt,
          fileType: thumbnailFileType,
          file,
        })
      )
    : undefined;
};

const createVotesCount = (count: number) => {
  return createTextBox({
    text: `${count}`,
    contextProps: {
      fontSize: fontSize * 2,
      fontWeight: "bold",
    },
  });
};

const createVotesLabel = (type: VotingSessionType, votersCount: number) => {
  return createTextBox({
    text: i18n.t(
      type === VotingSessionType.Vote
        ? "votingSession.mostVoted"
        : "votingSession.basedOnVotes",
      { count: votersCount }
    ),
    contextProps: {
      fontSize,
      textColor: "#777777",
    },
  });
};

const createStroke = () => {
  return createShape({
    shape: basicIcons.find((s) => s.key === ApiShapeId.square) || emptyShape,
    contextProps: {
      fillColor: "transparent",
      strokeColor: "#000000",
    },
  });
};

const wrapText = (text: string): string => {
  const wordWrapper = wordwrap(0, 25, { mode: "hard" });
  return wordWrapper(text);
};

const center = (...objects: fabric.Object[]) => {
  return {
    horizontally: (config?: HorizontalCenterConfig) => {
      const totalWidth = objects.reduce(
        (width, o) => width + getWidth(o),
        config?.noPadding ? 0 : (objects.length - 1) * padding
      );

      objects.reduce<fabric.Object | undefined>(
        (previousObject, object) =>
          object.set({
            left: previousObject
              ? previousObject.left + getWidth(previousObject) + padding
              : -totalWidth / 2,
          }),
        undefined
      );
    },
  };
};

const align = {
  centerOf: (...objects: fabric.Object[]) => ({
    vertically: () => {
      objects.forEach((object) =>
        object.set({
          top: -getHeight(object) / 2,
        })
      );
    },
    horizontally: () => {
      objects.forEach((object) =>
        object.set({
          left: -getWidth(object) / 2,
        })
      );
    },
  }),
  bottomOf: (...objects: fabric.Object[]) => ({
    toTheBottomOf: (source: fabric.Object) => {
      objects.forEach((destination) =>
        destination.set({
          top: source.top + getHeight(source) - getHeight(destination),
        })
      );
    },
  }),
};

const put = (...objects: fabric.Object[]) => {
  return {
    below: (topObject: fabric.Object) => {
      const bottomY = topObject.top + getHeight(topObject);

      objects.forEach((o) =>
        o.set({
          top: bottomY + padding,
        })
      );
    },
    at: (position: fabric.Point) => {
      objects.forEach((o) =>
        o.set({
          top: position.y - o.height / 2,
          left: position.x - o.width / 2,
        })
      );
    },
  };
};

const scale = (object: fabric.Object) => {
  return {
    widthTo: (width: number) => {
      const newObjectWidth = width + padding * 4;

      object.set({ scaleX: newObjectWidth / object.width });
    },
    heightTo: (height: number) => {
      const newObjectHeight = height + padding * 2;
      object.set({ scaleY: newObjectHeight / object.height });
    },
    toMatch: (width: number) => {
      const oldScaleX = object.scaleX || 1;
      const oldScaleY = object.scaleY || 1;
      const newScaleX = width / object.width;
      const changeFactor = newScaleX / oldScaleX;
      const newScaleY = oldScaleY * changeFactor;

      object.set({ scaleX: newScaleX, scaleY: newScaleY });
    },
  };
};

const shift = (object: fabric.Object) => {
  const shiftLeft = (x: number) => {
    object.set({
      left: object.left - x * (object.scaleX || 1),
    });
  };

  return {
    left: shiftLeft,
    right: (x: number) => shiftLeft(-x),
  };
};

const getWidth = (object: fabric.Object) => object.width * (object.scaleX || 1);
const getHeight = (object: fabric.Object) =>
  object.height * (object.scaleY || 1);
