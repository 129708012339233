import { ChangeEventHandler, useCallback, useMemo, useState } from "react";
import { useUISettings } from "../../../../reduxStore/uiSettings";
import { dynamicFeatureFlags } from "../../../../tools/flags";
import debounce from "../../../../tools/debounce";

export enum TeamFilter {
  all = "all",
  users = "users",
  devices = "devices",
  pending = "pending",
}

const filtersWithNoDevices = [
  TeamFilter.all,
  TeamFilter.users,
  TeamFilter.pending,
];

const allFilters = [...filtersWithNoDevices, TeamFilter.devices];

type UseTeam = {
  filters: TeamFilter[];
  currentFilter: TeamFilter;
  onFilterChange(newFilter: TeamFilter): void;
  query: string;
  setQuery: ChangeEventHandler;
};

export const useTeam = (): UseTeam => {
  const [currentFilter, setFilter] = useState(TeamFilter.all);
  const [query, setQuery] = useState("");
  const { isDynamicFeatureActive } = useUISettings();

  const setQueryDebounced = useMemo(
    () => debounce((value: string) => setQuery(value), 250),
    []
  );

  const onQueryChange = useCallback(
    (e) => {
      const value = e.target.value.trim();
      setQueryDebounced(value);
    },
    [setQueryDebounced]
  );

  return {
    filters: isDynamicFeatureActive(dynamicFeatureFlags.DEVICE_LICENSES)
      ? allFilters
      : filtersWithNoDevices,
    currentFilter,
    onFilterChange: setFilter,
    query,
    setQuery: onQueryChange,
  };
};
