import { isEmpty } from "ramda";
import React, { ReactElement, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { useCoParticipatingUsers } from "../../../../api/useRequest";
import LoadingSpinner from "../../../../icons/LoadingSpinner";
import NoResults from "../../../shared/layout/NoResults";
import { ProjectsContext } from "../Projects";
import {
  ProjectResultByUser,
  ProjectResultByUserExtended,
} from "./ProjectResultByUser";
import ProjectListHeader from "./ProjectResultByUser/ProjectListHeader";

function UserListResult(): ReactElement {
  const [expandedResultId, expandByResultId] = useState<false | string>(false);
  const { t } = useTranslation();
  const { isProjectListLoading, projectListError } = useContext(
    ProjectsContext
  ).current;

  const {
    users,
    isLoading,
    fetchMore,
    canFetchMore,
    isFetching,
    isFetchingMore,
    error,
  } = useCoParticipatingUsers();

  if (isLoading || isProjectListLoading) {
    return <LoadingSpinner />;
  }

  if (isEmpty(users) || error || projectListError) {
    return (
      <NoResults error={error || projectListError}>
        {t("clientError.noCoParticipants")}
      </NoResults>
    );
  }

  return (
    <>
      <ProjectListHeader />
      <InfiniteScroll
        pageStart={1}
        loadMore={() => fetchMore()}
        hasMore={canFetchMore && !isFetching && !isFetchingMore}
        loader={<LoadingSpinner key="users-infinite-scroll" />}
      >
        {users.map((User) => {
          const id = User.UserName;
          const isExpanded = expandedResultId === id;
          const onExpand = () => expandByResultId(!isExpanded && id);

          return (
            <React.Fragment key={id}>
              <ProjectResultByUser
                User={User}
                isExpanded={isExpanded}
                onExpand={onExpand}
              />
              {isExpanded && (
                <ProjectResultByUserExtended
                  User={User}
                  isExpanded={isExpanded}
                />
              )}
            </React.Fragment>
          );
        })}
      </InfiniteScroll>
    </>
  );
}

export default UserListResult;
