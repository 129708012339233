import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Confirmation from "../../../../shared/modals/Confirmation";
import { maxProjectCountInFreePlan, routes } from "../../../../../const";
import ModalHeader from "../../../../shared/modals/ModalHeader";
import LimitReached from "../../../../../icons/LimitReached";
import { getAppName } from "../../../../../tools/customer";
import { TemplateProps } from "../../../../shared/modals/Modal";
import { runtimeConfig } from "../../../../../tools/runtimeConfig";
import { useUISettings } from "../../../../../reduxStore/uiSettings";
import { isRunningInMSTeams } from "../../../../../tools/msTeams";

const { limitReachedUpgradeUrl } = runtimeConfig;

function FreeProjectsLimitModalTemplate({
  modalProps,
}: TemplateProps): React.ReactElement {
  const history = useHistory<void>();
  const { t } = useTranslation();
  const { getTranslation } = useUISettings();

  const isInTeams = isRunningInMSTeams();

  return (
    <Confirmation
      hideConfirmationButton={isInTeams}
      confirmationLabel={t("plans.limitReachedConfirmationLabel")}
      header={
        <ModalHeader
          title={t("plans.limitReachedHeader")}
          Icon={LimitReached}
        />
      }
      onConfirm={() => {
        if (limitReachedUpgradeUrl) {
          window.open(limitReachedUpgradeUrl, "_blank");
        } else {
          history.push(routes.plans);
        }
      }}
      {...modalProps}
    >
      {t(
        getTranslation(
          isInTeams
            ? "plans.limitReachedInTeamsMessage"
            : "plans.limitReachedMessage"
        ),
        { limit: maxProjectCountInFreePlan, appName: getAppName() }
      )}
    </Confirmation>
  );
}

export default FreeProjectsLimitModalTemplate;
