import React, { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Flex, ThemeUIStyleObject } from "theme-ui";
import Company from "../../../../icons/Company";
import CompanyRoleIcon from "../../../../icons/CompanyRole";
import Envelope from "../../../../icons/Envelope";
import { selectAssertedUserProfile } from "../../../../reduxStore/auth/auth.reducer";
import { ItemTooltipProps } from "../../../shared/forms/fields/MultiInputField/MultiInputItem";
import { combineUserInfo, patchUserIdentity } from "../../../users/users.utils";
import UserCard from "./UserCard";

type PatchedApiSearchUser = ApiSearchUser & ProjectUserIdentity;

function UserDetailsTooltip({
  item,
}: ItemTooltipProps<ApiSearchUserInfo>): ReactElement {
  const { t } = useTranslation();
  const { metadata } = item;
  const userProfile = useSelector(selectAssertedUserProfile);

  const userInfo = useMemo(() => {
    if (!metadata) {
      return null;
    }

    const user = patchUserIdentity(metadata);
    return combineUserInfo<PatchedApiSearchUser>({
      user,
      userProfile,
      index: 0,
    });
  }, [metadata, userProfile]);

  return (
    <Flex
      sx={{
        ...containerStyle,
        fontSize: userInfo ? 4 : 3,
      }}
    >
      {userInfo ? (
        <>
          <UserCard user={userInfo} noEmail />
          <Flex sx={userDetailsRowStyle}>
            <Envelope noShadow />
            <span>{item.value}</span>
          </Flex>
          <Flex
            sx={{
              ...userDetailsRowStyle,
              ...(userInfo.Company ? undefined : noDataStyle),
            }}
          >
            <Company noShadow />
            <span>{userInfo.Company || t("form.placeholder.CompanyName")}</span>
          </Flex>
          <Flex
            sx={{
              ...userDetailsRowStyle,
              ...(userInfo.CompanyRole ? undefined : noDataStyle),
            }}
          >
            <CompanyRoleIcon noShadow />
            <span>
              {userInfo.CompanyRole || t("form.placeholder.CompanyRoleGeneric")}
            </span>
          </Flex>
        </>
      ) : (
        t("clientError.userDoesntExist")
      )}
    </Flex>
  );
}

const containerStyle: ThemeUIStyleObject = {
  flexDirection: "column",
  px: [4],
  py: [2],
  gap: 2,
  img: {
    boxShadow: "none !important",
  },
  span: {
    wordBreak: "break-word",
  },
};

const userDetailsRowStyle: ThemeUIStyleObject = {
  gap: 4,
  alignItems: "center",
};

const noDataStyle: ThemeUIStyleObject = {
  color: "muted",
  svg: {
    fill: "muted",
  },
};

export default UserDetailsTooltip;
