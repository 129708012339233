import { useCallback } from "react";
import {
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import { searchModes } from "./projectList/searchModes";
import { ApiProjectsOrderParameter } from "../../../types/enum";

type UseProjectUrlParams = {
  params: {
    searchMode: string;
    query: string;
    orderBy: string;
    ascending: boolean;
    tag: string;
    spaceId: number | null;
  };
  orderProjectsBy(parameterName: ApiProjectsOrderParameter): void;
  resetProjectsOrder(): void;
  filterByTag(tag?: string): void;
  clearTag(): void;
  filterBySpaceId(spaceId: number | null): void;
  setSearchQuery(query: string, searchMode: string): void;
};

const projectsPageParamConfig = {
  searchMode: withDefault(StringParam, searchModes.date),
  query: withDefault(StringParam, ""),
  orderBy: withDefault(StringParam, ApiProjectsOrderParameter.lastAccess),
  ascending: withDefault(BooleanParam, false),
  tag: withDefault(StringParam, ""),
  spaceId: withDefault(NumberParam, null),
};

export default (): UseProjectUrlParams => {
  const [params, setParams] = useQueryParams(projectsPageParamConfig);
  const { orderBy, ascending, tag } = params;

  const orderProjectsBy = useCallback(
    (parameterName: ApiProjectsOrderParameter) => {
      setParams({
        orderBy: parameterName,
        ascending: orderBy !== parameterName || !ascending,
      });
    },
    [setParams, orderBy, ascending]
  );

  const resetProjectsOrder = useCallback(() => {
    setParams({
      orderBy: undefined,
      ascending: undefined,
    });
  }, [setParams]);

  const filterByTag = useCallback(
    (newTag: string) => {
      setParams({
        tag: newTag === tag ? undefined : newTag,
      });
    },
    [setParams, tag]
  );

  const clearTag = useCallback(() => {
    setParams({
      tag: undefined,
    });
  }, [setParams]);

  const filterBySpaceId = useCallback(
    (spaceId: number) => {
      setParams({
        spaceId,
      });
    },
    [setParams]
  );

  const setSearchQuery = useCallback(
    (query: string, searchMode: string) => setParams({ query, searchMode }),
    [setParams]
  );

  return {
    params,
    orderProjectsBy,
    resetProjectsOrder,
    filterByTag,
    clearTag,
    filterBySpaceId,
    setSearchQuery,
  };
};
