import React, { PropsWithChildren, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Heading, Text } from "theme-ui";
import { useLoggedInStatus } from "../../../authentication/useLoggedInStatus";

function UserProfileDetails({
  children,
}: PropsWithChildren<unknown>): ReactElement {
  const { t } = useTranslation();
  const { userProfile } = useLoggedInStatus();

  return (
    <Flex variant="layout.userProfile">
      <Heading as="h2" variant="text.h2">
        {userProfile?.FirstName}
      </Heading>
      <Heading as="h2" variant="text.h2">
        {userProfile?.LastName}
      </Heading>
      <Heading as="h4" variant="text.h4">
        {userProfile?.UserName}
      </Heading>
      <Text sx={textStyle}>
        {/**
         * @TODO Extract real registered date from `userProfile` once it is
         * available from API. `RegisteredDate` is just a placeholder, it
         * doesn't exist yet.
         */}
        {userProfile?.RegisteredDate &&
          t("users.registeredOn", {
            date: new Date(userProfile.RegisteredDate),
          })}
      </Text>
      {children}
    </Flex>
  );
}

const textStyle = {
  color: "muted",
  fontSize: 14,
  flex: 1,
  marginTop: "1em",
  marginBottom: "1em",
};

export default UserProfileDetails;
