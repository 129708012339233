import i18n, { TFunction } from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import { getAppVersion } from "../tools/versionInfo";
import {
  isDevModeEnabled,
  isStaticFeatureActive,
  staticFeatureFlags,
} from "../tools/flags";
import { ApiLanguage } from "../types/enum";
import { ICUWithRegionSupport } from "./icuWithRegionSupport";

/**
 * Supported languages.
 *
 * Use this as the single-source-of-truth.
 */
export const languages = isStaticFeatureActive(staticFeatureFlags.RTL_LANGUAGES)
  ? Object.values(ApiLanguage)
  : // Exclude RTL languages
    Object.values(ApiLanguage).filter(
      (language) => language !== ApiLanguage.arabic
    );

/**
 * Initialize i18next.
 *
 * Must only happen once.
 */
export const initializeLocales = (): Promise<TFunction> =>
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(
      new ICUWithRegionSupport({
        formats: {
          date: {
            mediumDate: {
              year: "numeric",
              month: "long",
              day: "numeric",
            },
            fullDate: {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            },
            fullDateTime: {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "numeric",
              minute: "numeric",
              second: "numeric",
              hour12: false,
            },
          },
        },
      })
    )
    .init({
      fallbackLng: "en",
      load: "languageOnly",
      nonExplicitSupportedLngs: true,
      supportedLngs: languages,
      debug: isDevModeEnabled(),
      backend: {
        queryStringParams: {
          v: getAppVersion(),
        },
      },
      interpolation: {
        escapeValue: false, // React already handles XSS escaping
      },
    });

/**
 * Change the language, but only if it is different from the current language.
 *
 * If only the region is changing then the language won't be changed. This is
 * important to maintain the correct date formatting for the user's locale.
 *
 * For example if the language is currently `en-GB` then choosing `en` will not
 * change anything.
 */
export const changeLanguage = (language?: string | ApiLanguage): void => {
  if (language) {
    const browserLanguage = detectBrowserLanguage();
    const isBrowserLanguage = !!browserLanguage?.startsWith(language);
    const isChangingLanguage = !i18n.language.startsWith(language);

    if (isBrowserLanguage) {
      browserLanguage !== i18n.language && i18n.changeLanguage(browserLanguage);
    } else if (isChangingLanguage) {
      i18n.changeLanguage(language);
    }
  }
};

/**
 * Detect the browser's configured language.
 *
 * @private
 */
const detectBrowserLanguage = (): string | undefined => {
  const languages = (i18n.services
    .languageDetector as LanguageDetector).detect(["navigator"]);

  return Array.isArray(languages) ? languages[0] : languages;
};
