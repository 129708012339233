import { ActionCreator, AnyAction } from "redux";
import { EventChannel } from "redux-saga";
import {
  call,
  cancelled,
  Effect,
  getContext,
  put,
  select,
  take,
} from "redux-saga/effects";
import { SignalRClient } from "../../../../api/signalR";
import { NotifyMessageName } from "../../../../api/signalR/protobuf.codecs";
import { OnMessageType } from "../../../../api/signalR/SignalRProtobufClient";
import collaboard from "../../../../tools/collaboard";
import {
  signalRWebRTCSignalMessageType,
  WebRTCManager,
} from "../../../../webrtc";
import { selectAssertedUserProfile } from "../../../auth/auth.reducer";
import { signalRNotifyChannel } from "../../../redux.utils";
import { setBackgroundAction } from "../../settings/settings.actions";
import {
  onProjectDeletedAction,
  onProjectUnavailableAction,
} from "../project.actions";
import {
  NotifyProjectAction,
  NotifyProjectActionType,
  onNotifyPingbackRequestAction,
  OnNotifyUserAvailableAction,
  onNotifyUserAvailableAction,
  SetUserOfflineAction,
  setUserOfflineAction,
  SetUserOnlineAction,
  setUserOnlineAction,
  updatePermissionAction,
} from "./signalR-project.actions";

function* notifyUserJoined({ payload }: SetUserOnlineAction) {
  const webRTCManager: WebRTCManager | undefined = yield getContext(
    "webRTCManager"
  );
  const userProfile = selectAssertedUserProfile(yield select());

  const { AuthUser } = payload;

  if (!AuthUser?.ConnectionId || !AuthUser.UserName) {
    return;
  }

  /**
   * @NOTE
   * This is the only point that an initiator connection can be created. We basically establish a
   * connection to the other user when they join the project and thus a NotifyLoginProject is received
   * from SignalR.
   *
   * It is created even if THIS user has not enabled UserPresence. This is
   * necessary because another user may have enabled it and we need to decide
   * which client will be the initiator.
   */

  /**
   * @NOTE
   * A NotifyLoginProject is received for myself as well, but the webRTCManager is not created yet
   * so we need to avoid connecting to ourselves. On the other hand, it seems that when two users are
   * joining the project at the same time, one will only receive a NotifyLoginProject for himself, the
   * other will receive both NotifyLoginProject messages and the second one arrives "fortunately" after
   * webRTCManager is already initialized.
   *
   * @TODO #7486: ensure that WebRTC is correctly istantiated on time and we don't risk to loose the
   * NotifyLoginProject messages from other users.
   */
  AuthUser.UserName !== userProfile.UserName &&
    webRTCManager &&
    webRTCManager.connectTo(AuthUser.ConnectionId);
}

function* notifyUserLeft({ payload }: SetUserOfflineAction) {
  const webRTCManager: WebRTCManager | undefined = yield getContext(
    "webRTCManager"
  );
  webRTCManager?.disconnectFrom(payload.ConnectionId);
}

function* notifyUserAvailable({ payload }: OnNotifyUserAvailableAction) {
  const webRTCManager: WebRTCManager | undefined = yield getContext(
    "webRTCManager"
  );
  const { MessageType, SenderConnectionId, Payload } = payload;

  if (MessageType !== signalRWebRTCSignalMessageType) {
    return;
  }

  webRTCManager?.establishConnection(SenderConnectionId, Payload);
}

function* notifyPingbackRequest() {
  const signalRClient: SignalRClient = yield getContext("signalRClient");

  signalRClient.postPingbackRequest();
}

const onNotifySagas: {
  [key in NotifyProjectActionType]?: (
    payload: ActionInUnionWithType<NotifyProjectAction, key>
  ) => Generator<Effect>;
} = {
  ON_NOTIFY_PINGBACK: notifyPingbackRequest,
  ON_NOTIFY_USER_AVAILABLE: notifyUserAvailable,
  SET_USER_OFFLINE: notifyUserLeft,
  SET_USER_ONLINE: notifyUserJoined,
};

const eventToAction: { [key in OnMessageType]?: ActionCreator<AnyAction> } = {
  [NotifyMessageName.DeleteProjectMessage]: onProjectDeletedAction,
  [NotifyMessageName.LogInProjectMessage]: setUserOnlineAction,
  [NotifyMessageName.LogOutProjectMessage]: setUserOfflineAction,
  [NotifyMessageName.PingbackMessage]: onNotifyPingbackRequestAction,
  [NotifyMessageName.BackgroundMessage]: setBackgroundAction,
  [NotifyMessageName.ProjectParticipantMessage]: updatePermissionAction,
  [NotifyMessageName.ProjectUnavailableMessage]: onProjectUnavailableAction,
  [NotifyMessageName.GenericAvailableMessage]: onNotifyUserAvailableAction,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* signalRProjectSaga() {
  const notifyChannel: EventChannel<NotifyProjectAction> = yield call(
    signalRNotifyChannel,
    collaboard.signalRClient,
    eventToAction
  );

  try {
    while (true) {
      const action: NotifyProjectAction = yield take(notifyChannel);

      // Always dispatch the action from the SignalR event as it may needed by other middlewares/reducers
      yield put(action);

      const handler = onNotifySagas[action.type] as (
        action: NotifyProjectAction
      ) => Generator<Effect>;

      // If there's a handler, we run the handler saga sequentially. If new SignalR messages arrive meanwhile,
      // they will be buffered inside the `notifyChannel`
      if (handler) {
        yield call(handler, action);
      }
    }
  } finally {
    const isCancelled: boolean = yield cancelled(); // Cancelled by exiting the project

    if (isCancelled) {
      // Close the EventChannel so that the unsubscribe function is called and listeners removed
      notifyChannel.close();
    }
  }
}
