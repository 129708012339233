import React, { ReactElement, useMemo, useState } from "react";
import { Text } from "theme-ui";
import { useTranslation } from "react-i18next";
import { ApiSubscriptionUserPermission } from "../../../../../types/enum";
import { updateSubscriptionUser } from "../../../../../api";
import BasicDropdown from "../../../../shared/forms/dropdowns/BasicDropdown/BasicDropdown";

type Props = {
  user: ApiSubscriptionUser;
  disabled: boolean;
};

const managableRoles = [
  ApiSubscriptionUserPermission.manager,
  ApiSubscriptionUserPermission.viewer,
];

function RoleDropdown({ user, disabled }: Props): ReactElement {
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);
  const [role, setRole] = useState(user.Permission);
  const manageblePermissions = useMemo(
    () =>
      managableRoles.map((role) => ({
        value: role,
        label: t(`roles.${role}`),
      })),
    [t]
  );

  return disabled ? (
    <Text sx={{ color: "muted", pl: [2] }}>{t(`roles.${role}`)}</Text>
  ) : (
    <BasicDropdown
      ariaLabel="role-dropdown"
      items={manageblePermissions}
      value={role}
      disabled={isSubmitting}
      sx={{ width: "100%" }}
      selectSx={{ justifyContent: "space-between" }}
      onChange={async (option) => {
        setSubmitting(true);

        try {
          const newRole = option.value;

          await updateSubscriptionUser({
            permission: newRole,
            user,
          });

          setRole(newRole);
        } catch {
          // default handlers
        }

        setSubmitting(false);
      }}
    />
  );
}

export default RoleDropdown;
