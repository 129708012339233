import React, { ReactElement, useEffect, useRef } from "react";
import { Box, ThemeUIStyleObject, BoxProps } from "theme-ui";
import { pageCoverZIndex } from "../../../../const";

type Props = BoxProps & {
  disableMouseWheel?: boolean;
};

export default function PageCover({
  children,
  disableMouseWheel = false,
  sx,
  ...rest
}: Props): ReactElement {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const blockWheel: EventListener = (event) => {
      event.preventDefault();
    };

    const element = ref.current;

    disableMouseWheel &&
      element?.addEventListener("wheel", blockWheel, { passive: false });

    return () => {
      disableMouseWheel && element?.removeEventListener("wheel", blockWheel);
    };
  }, [disableMouseWheel]);

  return (
    <Box
      ref={ref}
      sx={{
        ...pageCoverStyle,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}

const pageCoverStyle: ThemeUIStyleObject = {
  bg: "pageCover", // pageCoverDark is available but only used inside canvas modals
  bottom: 0,
  left: 0,
  pointerEvents: "all",
  position: "absolute",
  right: 0,
  top: 0,
  zIndex: pageCoverZIndex,
};
