import i18n from "i18next";
import { toast } from "react-toastify";
import { all, Effect, getContext, put, takeEvery } from "redux-saga/effects";
import { initialObjectWidth, OneMB } from "../../../../const";
import { selectObjects } from "../../../../studio/components/patches/extends-selection/selection.utils";
import {
  isDocument,
  isRemoteObject,
} from "../../../../studio/utils/fabricObjects";
import { createCanvasObjects } from "../../../../studio/utils/objectConverter";
import { groupBy } from "../../../../tools";
import { errorToast } from "../../../../tools/errorToast";
import { getFulfilledValues } from "../../../../tools/promises";
import { addedAction } from "../../history/history.entry.actions";
import { DropAction, DropActionType } from "./drop.actions";

// Use initialObjectWidth (200px) instead of scaled width (175px) for documents to reserve
// more space in order to decrease overlapped area in case of horizontal documents.
// See #5988.
const shiftDocumentPreviews = async (
  canvas: fabric.CollaboardCanvas,
  objects: fabric.Object[],
  pointerPosition: fabric.Point
): Promise<void> => {
  const animationPromises = objects.map((o, idx) => {
    const width =
      o instanceof fabric.CollaboardDocument
        ? initialObjectWidth
        : o.getScaledWidth();

    const newObjectShift = canvas.getNewObjectShift(
      idx,
      objects.length,
      width,
      o.getScaledHeight()
    );

    const { x, y } = pointerPosition.add(newObjectShift);

    return new Promise((resolve) => {
      o.animate(
        { left: x, top: y },
        {
          duration: canvas.FX_DURATION,
          onChange: canvas.requestRenderAll.bind(canvas),
          easing: fabric.util.ease.easeOutBack,
          onComplete: resolve,
        }
      );
    });
  });

  await Promise.all(animationPromises);
};

const addToCanvas = async (
  canvas: fabric.CollaboardCanvas,
  objects: fabric.Object[],
  pointerPosition: fabric.Point
) => {
  if (!objects.length) {
    return;
  }

  await canvas.fxAdd(...objects);
  objects.forEach((o) => o.temporaryDisable());

  const previewPromises = objects
    .filter(isRemoteObject)
    // In case of documents we need to wait until server generates the preview
    // which is problematic for two reasons:
    // 1. It may take a few moments until preview is generated while a user can already move that
    //    object around the canvas
    // 2. It requires sending the PostNew signal first (dispatch(addedAction(objects)) bit)
    //    and then another signal would be required to update positions after preview is loaded.
    .filter((object) => !isDocument(object))
    .map((object) => object.onPreviewLoaded);

  await Promise.allSettled(previewPromises);

  await shiftDocumentPreviews(canvas, objects, pointerPosition);

  objects.forEach((o) => o.reEnable());
};

function* drop({ payload }: DropAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  const { acceptedObjects, rejectedObjects, pointer } = payload;
  const pointerPosition = canvas.toCanvasPoint(pointer);
  const createCanvasObjectsPromises = acceptedObjects.map((config, idx) => {
    const newObjectShift = canvas.getNewObjectShift(
      idx,
      acceptedObjects.length
    );

    return createCanvasObjects({
      top: pointerPosition.y + newObjectShift.y,
      left: pointerPosition.x + newObjectShift.x,
      ...config,
    }).catch((e) => {
      errorToast(e);
      return [] as fabric.Object[];
    });
  });

  const acceptedWarnings = new Set(
    acceptedObjects.filter((o) => o.warning).map((o) => o.warning)
  );
  acceptedWarnings.forEach((warning) => {
    const msg = i18n.t(`file.${warning}`);

    toast(msg);
  });

  if (rejectedObjects.length) {
    const rejectionReasons = groupBy(rejectedObjects, (o) => o.rejectionReason);
    Object.entries(rejectionReasons).forEach(([reason, fArray]) => {
      const maxSize = (fArray.length && fArray[0].maxSize) || 0;
      const msg = i18n.t(`file.${reason}`, {
        count: fArray.length,
        names: fArray.map(({ file }) => `\n- ${file.name}`).join(""),
        maxSize: ~~(maxSize / OneMB),
      });

      errorToast(msg);
    });
  }

  const results: PromiseSettledResult<
    fabric.Object[]
  >[] = yield Promise.allSettled(createCanvasObjectsPromises);
  const objects = getFulfilledValues(results).flat();

  yield addToCanvas(canvas, objects, pointerPosition);
  yield put(addedAction(objects));

  selectObjects(canvas, objects, { isSilent: true });
}

export function* dropSaga(): Generator<Effect> {
  yield all([takeEvery(DropActionType.DROP, drop)]);
}
