export const spreadsheetToJSON = (
  file: File
): Promise<IndexedSpreadsheetData> => {
  return new Promise((res, rej) => {
    import("xlsx")
      .then((XLSX) => {
        const reader = new FileReader();
        const rABS = Boolean(reader.readAsBinaryString);

        reader.onabort = rej;
        reader.onerror = rej;

        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target?.result;
          const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });

          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];

          /* Convert array of arrays */
          const data: SpreadsheetData = XLSX.utils.sheet_to_json(ws, {
            header: 1,
            defval: "",
          });

          const result: IndexedSpreadsheetData = data.map((row, index) => ({
            id: index,
            data: row,
          }));
          res(result);
        };

        if (rABS) {
          reader.readAsBinaryString(file);
        } else {
          reader.readAsArrayBuffer(file);
        }
      })
      .catch(rej);
  });
};
