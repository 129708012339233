import QRCode from "qrcode.react";
import { ComponentProps, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Text } from "theme-ui";

import OneTimePassword from "../../../../icons/OneTimePassword";
import { ApiAuthenticationMode } from "../../../../types/enum";
import Modal from "../../../shared/modals";
import { TemplateProps } from "../../../shared/modals/Modal";
import ModalHeader from "../../../shared/modals/ModalHeader";
import { withModalTemplate } from "../../../shared/modals/withModalTemplate";

type ModalProps = ComponentProps<typeof Modal>;

interface Props {
  modalProps?: Omit<ModalProps, "header">;
  OTPData: SetAuthenticationModeResponse;
  authenticationMode: ApiAuthenticationMode;
}

function OTPInfoModalTemplate({
  OTPData: { OTPSecurityKey, OTPUrl },
  modalProps,
  authenticationMode,
}: TemplateProps<Props>): ReactElement | null {
  const { t } = useTranslation();

  if (!OTPSecurityKey || !OTPUrl) {
    return null;
  }

  const isTFA = authenticationMode === ApiAuthenticationMode.TFA;

  return (
    <Modal
      header={
        <ModalHeader
          title={t(`form.message.${isTFA ? "tfaHeader" : "otpHeader"}`)}
          Icon={OneTimePassword}
          subTitle={t(`form.message.${isTFA ? "tfaHint" : "otpHint"}`)}
        />
      }
      {...modalProps}
    >
      <Flex sx={{ alignItems: "center", flexDirection: "column" }}>
        <Text as="p" sx={{ mb: [4] }}>
          <strong>{t("form.message.tfaCodeHint")}</strong>
        </Text>
        <Text as="p" sx={{ mb: [4] }}>
          {OTPSecurityKey}
        </Text>
        <Text as="p" sx={{ mb: [4] }}>
          <strong>{t("form.message.tfaQRHint")}:</strong>
        </Text>
        <Box>
          <QRCode value={OTPUrl} />
        </Box>
      </Flex>
    </Modal>
  );
}

export default withModalTemplate(OTPInfoModalTemplate);
