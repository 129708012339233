import React, { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Flex } from "theme-ui";
import { selectAssertedUserProfileInfo } from "../../../reduxStore/auth/auth.reducer";
import { useUISettings } from "../../../reduxStore/uiSettings";
import {
  dynamicFeatureFlags,
  isStaticFeatureActive,
  staticFeatureFlags,
} from "../../../tools/flags";
import { LogCategory } from "../../../tools/telemetry";
import UserProfileDetails from "../../canvas/navigation/users/UserProfileDetails";
import { useTrackEventToLog } from "../../shared/tracking/useTrackEventToLog";
import PageAuthorized from "../layout/pages/PageAuthorized";
import AvatarEditButton from "./avatar/AvatarEditButton";
import AvatarEditor from "./avatar/AvatarEditor";
import AvatarRemoveButton from "./avatar/AvatarRemoveButton";
import UserAuthenticationMethod from "./preferences/UserAuthenticationMethod";
import UserUseCases from "./preferences/UserUseCases";
import UserProfile from "./UserProfile";

const emailLoginDisabled = isStaticFeatureActive(
  staticFeatureFlags.EMAIL_LOGIN_DISABLED
);

function User(): ReactElement {
  const { trackEvent } = useTrackEventToLog();
  const [isAvatarEditMode, setAvatarEditMode] = useState(false);
  const { isDynamicFeatureActive } = useUISettings();
  const userProfile = useSelector(selectAssertedUserProfileInfo);

  const toggleEditMode = () => setAvatarEditMode(!isAvatarEditMode);
  const exitEditMode = () => setAvatarEditMode(false);

  useEffect(() => {
    trackEvent(LogCategory.navigation, {
      subcategory: "page-user",
    });
  }, [trackEvent]);

  return (
    <PageAuthorized>
      <Flex variant="layout.userData">
        <AvatarEditor
          isAvatarEditMode={isAvatarEditMode}
          onEditSuccess={exitEditMode}
        />
        <UserProfileDetails>
          {(userProfile?.AvatarUrl || isAvatarEditMode) && (
            <AvatarEditButton
              isAvatarEditMode={isAvatarEditMode}
              onAvatarClick={toggleEditMode}
            />
          )}
          <AvatarRemoveButton onRemoveAvatar={exitEditMode} />
        </UserProfileDetails>
      </Flex>
      <UserProfile />
      {!emailLoginDisabled && <UserAuthenticationMethod />}
      {!isDynamicFeatureActive(
        dynamicFeatureFlags.DISABLE_USER_PREFERENCES
      ) && <UserUseCases />}
    </PageAuthorized>
  );
}

export default User;
