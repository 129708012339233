import React, { ReactElement } from "react";
import i18n from "i18next";
import { isNil } from "ramda";
import { useTranslation } from "react-i18next";
import { Box, Flex, Text, ThemeUIStyleObject } from "theme-ui";
import { maxProjectCountInFreePlan } from "../../../../const";
import { Currency } from "../useCurrencies";
import { useUISettings } from "../../../../reduxStore/uiSettings";
import { dynamicFeatureFlags } from "../../../../tools/flags";

function getMonthlyPriceBilledAnnually(priceAnnually: number): string {
  const priceMonthly = priceAnnually / 12;

  const value = priceMonthly % 1 === 0 ? priceMonthly : priceMonthly.toFixed(2);
  return value.toLocaleString(i18n.language);
}

type Props = {
  data: Plan;
  activePlan: ActivePlan;
  isActive: boolean;
  currency: Currency;
  sx: ThemeUIStyleObject;
};

function PlanBase({
  isActive,
  activePlan,
  currency,
  data,
  sx,
}: Props): ReactElement {
  const { t } = useTranslation();
  const { isDynamicFeatureActive } = useUISettings();

  const isEcommerceFeatureActive = !isDynamicFeatureActive(
    dynamicFeatureFlags.NO_ECOMMERCE
  );

  return (
    <Flex
      sx={{
        ...sx,
        ...containerStyle,
        span: {
          color: isActive ? "background" : "var(--textColor)",
        },
      }}
    >
      <Box>
        <Box sx={{ mb: [4] }}>
          <Text
            sx={{
              ...planNameStyle,
              color: isActive ? "background" : "primary",
            }}
            aria-label="planName"
          >
            {t(data.nameKey)}
          </Text>
          {isEcommerceFeatureActive && (
            <>
              {((isActive && activePlan.amount) ||
                data.priceAnnually !== null) && (
                <Box sx={{ fontSize: "46px", marginBottom: [2] }}>
                  <Text
                    as="span"
                    sx={{ fontSize: "24px", marginRight: [1] }}
                    aria-label="currency"
                  >
                    {(isActive && activePlan.currency?.symbol) ||
                      currency.symbol}
                  </Text>

                  <Text
                    as="span"
                    sx={{ fontWeight: "bold" }}
                    aria-label="amount"
                  >
                    {(isActive &&
                      activePlan.amount?.toLocaleString(i18n.language)) ||
                      (!isNil(data.priceAnnually) &&
                        getMonthlyPriceBilledAnnually(data.priceAnnually))}
                  </Text>
                </Box>
              )}

              {!isActive && (
                <Text sx={{ fontSize: [3] }} aria-label="billingDetails">
                  {data.priceDetailsKey &&
                    t(data.priceDetailsKey, {
                      number: maxProjectCountInFreePlan,
                    })}
                </Text>
              )}
            </>
          )}
        </Box>
      </Box>
    </Flex>
  );
}

const containerStyle: ThemeUIStyleObject = {
  flex: 1,
  flexDirection: "column",
  textAlign: "center",
  justifyContent: "space-between",
  border: "1px solid",
  borderBottom: "none",
  borderColor: "primary",
};

const planNameStyle: ThemeUIStyleObject = {
  fontSize: "3rem",
  fontWeight: 600,
  margin: "10px 0",
};

export default PlanBase;
