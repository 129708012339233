import { Task } from "redux-saga";
import {
  all,
  call,
  cancel,
  Effect,
  fork,
  getContext,
  put,
  select,
  take,
  takeEvery,
} from "redux-saga/effects";
import { isPresentationViewerClass, SelectionMode } from "../../../const";
import { CollaboardToast } from "../../../features/shared/toasts/useToast";
import { isMediaObject } from "../../../studio/utils/fabricObjects";
import { MediaAction } from "../../../types/enum";
import { resetCanvasModeAction } from "../app/app.actions";
import { selectProjectUserFirstName } from "../project/project.reducer";
import { CanvasSettingsActionType } from "../settings/settings.actions";
import { minimapOpenSetting } from "../userSettings";
import {
  setUserSettingAction,
  UserSettingOrigin,
} from "../userSettings/userSettings.actions";
import { selectPresentationState } from "./presentation.reducer";
import {
  OnDocumentControlAction,
  OnMediaControlAction,
  OnNudgeForAttentionAction,
  PresentationStartedAction,
  PresentationStoppedAction,
  PresentationViewerActionType,
} from "./presentation.viewer.actions";

const { start, stop, pause } = MediaAction;

const sessionToast = new CollaboardToast("");

const setMediaSessionHandler = (
  handler: ((details: MediaSessionActionDetails) => void) | null
): void => {
  if (navigator.mediaSession) {
    navigator.mediaSession.setActionHandler("play", handler);
    navigator.mediaSession.setActionHandler("pause", handler);
    navigator.mediaSession.setActionHandler("seekbackward", handler);
    navigator.mediaSession.setActionHandler("seekforward", handler);
    navigator.mediaSession.setActionHandler("previoustrack", handler);
    navigator.mediaSession.setActionHandler("nexttrack", handler);
  }
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const disableMediaSession = () => setMediaSessionHandler(function () {});
const enableMediaSession = () => {
  // Restore default media session handlers
  // https://developer.mozilla.org/en-US/docs/Web/API/MediaSession/setActionHandler#description
  setMediaSessionHandler(null);
};

function* onPresentationStarted({ payload }: PresentationStartedAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  yield put(resetCanvasModeAction());
  yield put(
    setUserSettingAction({
      setting: minimapOpenSetting,
      origin: UserSettingOrigin.setByApp,
      value: false,
    })
  );

  canvas.forEachObject((o) => isMediaObject(o) && o._closePlayer());
}

function* onViewerPresentationStarted({ payload }: PresentationStartedAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const {
    presentation: { Presenter },
  } = payload;
  const { classList } = document.body;

  classList.add(isPresentationViewerClass);
  sessionToast.update("presentation.started", {
    name: selectProjectUserFirstName(Presenter)(yield select()),
  });
  disableMediaSession();
  canvas.clearPresenterViewportMask();
  canvas.clearUserMousePositions();
  canvas.isPresentationMode = true;
  // Clear any targets from an in-progress drag
  canvas.targets = [];
  // Ensure multi-selection is disabled
  canvas.setSelectionMode(SelectionMode.standard);
  // Ensure drawing mode is deactivated
  canvas.trigger("custom:drawing-mode:deactivate");

  canvas.disableMultiSelectionFlag();
  canvas.updateSkipTargetFind();
  canvas.discardActiveObject();
  canvas.fullScreenOff();
}

function* onPresentationStopped({ payload }: PresentationStoppedAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const {
    presentation: { Presenter },
  } = payload;
  const { classList } = document.body;

  classList.remove(isPresentationViewerClass);
  sessionToast.update("presentation.stopped", {
    name: selectProjectUserFirstName(Presenter)(yield select()),
  });
  enableMediaSession();
  canvas.clearPresenterViewportMask();
  canvas.clearUserMousePositions();
  canvas.isPresentationMode = false;
  canvas.enableMultiSelectionFlag();
  canvas.updateSkipTargetFind();
  canvas.requestRenderAll();
}

function* onPresentationViewportChange() {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const {
    presenterViewport,
    presenterZoomLevel,
    scaledZoomLevel,
    offsetX,
    offsetY,
    scaledWidth,
    scaledHeight,
  } = selectPresentationState(yield select());
  if (
    typeof presenterZoomLevel === "undefined" ||
    typeof presenterViewport === "undefined"
  ) {
    // Note: These can be undefined if a user comes into a presentation that was already started
    //       Happend also with an F5 reload action
    return;
  }

  const focalPoint = new fabric.Point(
    (presenterViewport.x / presenterZoomLevel) * scaledZoomLevel + offsetX,
    (presenterViewport.y / presenterZoomLevel) * scaledZoomLevel + offsetY
  );

  yield canvas.panToPoint(focalPoint, scaledZoomLevel);

  canvas.setPresenterViewportMask(offsetX, offsetY, scaledWidth, scaledHeight);

  canvas.requestRenderAll();
}

function* onMediaControl({ payload }: OnMediaControlAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const {
    VideoPlayerCommand: action,
    TileIdVideoContent,
    TimeStamp: currentTime,
  } = payload;

  const mediaObject = canvas.getObjectByUUID<fabric.CollaboardMedia>(
    TileIdVideoContent
  );

  if (mediaObject) {
    switch (action) {
      case start: {
        mediaObject.isPlayerActive
          ? mediaObject.play(currentTime)
          : mediaObject._openPlayer(currentTime);
        return;
      }
      case stop:
        mediaObject._closePlayer();
        return;
      case pause:
        mediaObject.pause();
        return;
      // #4483: We don't want to FORCE viewers to enter full screen
      // TODO: come up with a better alternative (e.g. display toast / prompt)
      // case fullScreenOn:
      //   mediaObject.fullScreenOn();
      //   return;
      // case fullScreenOff:
      //   canvas.fullScreenOff();
      //   return;
    }
  }
}

function* onDocumentControl({ payload }: OnDocumentControlAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const { TileId, NewPageNumber } = payload;

  const document = canvas.getObjectByUUID<fabric.CollaboardDocument>(TileId);
  document?.updateThumbnail(NewPageNumber);
}

function* spawnViewerSagas(): Generator<Effect> {
  yield all([
    takeEvery(
      PresentationViewerActionType.PRESENTATION_VIEWPORT_CHANGE,
      onPresentationViewportChange
    ),
    takeEvery(
      CanvasSettingsActionType.ORIENTATION_CHANGE,
      onPresentationViewportChange
    ),
    takeEvery(
      CanvasSettingsActionType.WINDOW_RESIZE_THROTTLED,
      onPresentationViewportChange
    ),
    takeEvery(
      PresentationViewerActionType.ON_JOINED_PRESENTATION,
      onPresentationViewportChange
    ),
    takeEvery(PresentationViewerActionType.ON_MEDIA_CONTROL, onMediaControl),
    takeEvery(
      PresentationViewerActionType.ON_DOCUMENT_CONTROL,
      onDocumentControl
    ),
  ]);
}

function* onNudgeForAttention({ payload }: OnNudgeForAttentionAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const {
    presenterViewport,
    presenterZoomLevel,
    scaledZoomLevel,
    offsetX,
    offsetY,
  } = selectPresentationState(yield select());
  const {
    presentation: { Presenter },
  } = payload;

  sessionToast.update("presentation.nudged", {
    name: selectProjectUserFirstName(Presenter)(yield select()),
  });

  if (canvas && presenterViewport && presenterZoomLevel) {
    const focalPoint = new fabric.Point(
      (presenterViewport.x / presenterZoomLevel) * scaledZoomLevel + offsetX,
      (presenterViewport.y / presenterZoomLevel) * scaledZoomLevel + offsetY
    );

    yield canvas.panToPoint(focalPoint, scaledZoomLevel);
  }
}

function* presentationStartedSaga() {
  while (true) {
    const startedAction: PresentationStartedAction = yield take(
      PresentationViewerActionType.PRESENTATION_STARTED
    );

    // Saga for both viewer and presenter
    yield call(onPresentationStarted, startedAction);

    if (startedAction.payload.isPresenter) {
      break;
    }

    yield call(onViewerPresentationStarted, startedAction);

    // Spawn sagas in the background when starting the presentation
    const spawnedSagasTask = (yield fork(spawnViewerSagas)) as Task;

    // Cancel sagas when the presentation is stopped
    const stoppedAction: PresentationStoppedAction = yield take(
      PresentationViewerActionType.PRESENTATION_STOPPED
    );
    yield call(onPresentationStopped, stoppedAction);
    yield cancel(spawnedSagasTask);
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* presentationViewerSaga() {
  yield all([
    presentationStartedSaga(),
    takeEvery(
      PresentationViewerActionType.ON_NUDGE_FOR_ATTENTION,
      onNudgeForAttention
    ),
  ]);
}
