import { applicationType } from "../detectOS";
import { isDevModeEnabled, isOnAws, isOnPremise } from "../flags";
import {
  logErrorToAppInsights,
  PropertiesToTrack,
  startAppInsights,
  trackEventToAppInsights,
} from "./applicationInsights";
import {
  logErrorToAws,
  startAwsLogger,
  trackEventToAws,
} from "./awsErrorLogger";
import { logErrorToOnPrem, trackEventToOnPrem } from "./onPremErrorLogger";

export enum LogCategory {
  api = "api",
  auth = "auth",
  history = "history",
  internal = "internal",
  interaction = "interaction",
  navigation = "navigation",
  network = "network",
  project = "project",
  space = "space",
  react = "react",
  externalResource = "externalResource",
  signalR = "signalR",
  storage = "storage",
  unhandled = "unhandled",
  version = "version.json",
  webRTC = "WebRTC",

  other = "other",
}

const categoriesAlreadyTrackedByAppInsights = [LogCategory.unhandled];

export const onErrorToLog = (
  error: Error,
  category: LogCategory = LogCategory.other,
  properties: PropertiesToTrack = {}
): void => {
  //todo: implement mechanism to choose between envs instead of the isOn functions
  if (isOnAws()) {
    logErrorToAws(error, category, properties);
  } else if (isOnPremise()) {
    logErrorToOnPrem(error, category, properties);
  } else if (categoriesAlreadyTrackedByAppInsights.includes(category)) {
    // No need to do anything as these are tracked by @microsoft/applicationinsights-web
  } else {
    logErrorToAppInsights(error, category, properties);
  }

  // If `isCatchingTopLevelErrors = true` console.error is overriden to track using `onErrorToLog` internally,
  // resulting in recursive stack overflow
  if (isDevModeEnabled() && !window.isCatchingTopLevelErrors) {
    // eslint-disable-next-line no-console
    console.error(error);
    // eslint-disable-next-line no-console
    console.error(category, properties);
  }
};

export const trackEventToLog = (
  name: string,
  properties?: PropertiesToTrack
): void => {
  if (isOnAws()) {
    trackEventToAws(name, properties);
  } else if (isOnPremise()) {
    trackEventToOnPrem(name, properties);
  } else {
    trackEventToAppInsights(name, properties);
  }
};

export const startLoggingErrors = async (): Promise<void> => {
  //todo: implement mechanism to choose between envs instead of the isOn functions
  if (isOnAws()) {
    await startAwsLogger();
  } else if (isOnPremise()) {
    // TODO: #3941 Start logging errors to own API when this becomes available
  } else {
    // Does nothing when there's no app insights instrumentation key
    await startAppInsights();
  }

  const type = applicationType();
  trackEventToLog(LogCategory.other, {
    subcategory: "start-application",
    type,
  });
};
