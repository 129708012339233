import SendToken from "../../features/authentication/passwordReset/SendToken";
import LoginPage from "./Login";
import Logo from "./Logo";
import Tagline from "./Tagline";

const components: CustomerComponents = {
  Logo,
  Tagline,
  LoginPage,
  SendToken,
};

export default components;
