import React, { forwardRef, ReactElement } from "react";
import { Button, ThemeUIStyleObject } from "theme-ui";

export type Option = {
  key: string;
  label: string | ReactElement;
  disabled?: boolean;
};

type Props = {
  option: Option;
  active: boolean;
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  inverted?: boolean;
  boxed?: boolean;
  sx?: ThemeUIStyleObject;
};

const OptionsListItem = forwardRef<HTMLButtonElement, Props>(
  function OptionsListItem(
    { option, active, disabled, onClick, inverted, boxed, sx = {} }: Props,
    ref
  ): ReactElement {
    const border = inverted
      ? "var(--contextActiveColor_alternative)"
      : "var(--contextActiveColor)";
    const activeBg = inverted ? "var(--iconColor__active)" : "var(--iconColor)";
    const inactiveColor = inverted
      ? "var(--iconColor__active)"
      : "var(--iconColor)";
    const activeColor = inverted
      ? "var(--iconColor)"
      : "var(--iconColor__active)";
    const color = disabled
      ? "var(--iconColor__disabled)"
      : active
      ? activeColor
      : inactiveColor;

    return (
      <Button
        ref={ref}
        disabled={disabled}
        onClick={onClick}
        sx={{
          background: active ? activeBg : "transparent",
          color,
          fontSize: boxed ? "calc(var(--gridTile) / 3)" : "auto",
          minWidth: "5rem",
          ...sx,
          "&:hover": {
            "&::after": {
              borderColor: active ? "transparent" : border,
            },
          },
        }}
        type="button"
        variant="canvasOptionListItem"
      >
        {option.label}
      </Button>
    );
  }
);

export default OptionsListItem;
