import { AnyAction } from "redux";
import { all, Effect, getContext, takeEvery } from "redux-saga/effects";
import { blobStates } from "../../../const";
import { isRemoteObject } from "../../../studio/utils/fabricObjects";
import { errorToast } from "../../../tools/errorToast";
import { HistoryActionType } from "./history.actions";
import {
  HistoryAdd,
  HistoryEntryAction,
  HistoryEntryType,
} from "./history.entry.actions";
import { cleanHistoryStackSaga } from "./sagas/history-cleanup.saga";
import { redoSaga, undoSaga } from "./sagas/undo-redo.saga";
import { signalRHistorySaga } from "./signalR-history.saga";
import { signalRHistoryNotifySaga } from "./signalR-notify/signalR-notify.saga";

const isHistoryAddAction = (
  action: AnyAction
): action is HistoryEntryAction<HistoryAdd> => {
  return (
    (action.type === HistoryActionType.HISTORY_NOOP ||
      action.type === HistoryActionType.HISTORY_ADD_ENTRY) &&
    action.payload.transformationType === HistoryEntryType.ADD
  );
};

function* addedObject({ payload }: HistoryEntryAction<HistoryAdd>) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const { addedObjects } = payload;
  const uuids = addedObjects.map((o) => o.TileId);

  canvas.saveLastAddedObject(addedObjects);

  // when a new object is added to the canvas (not when loading an app)
  // we need to wait until the fxAdd animation is complete before starting an upload
  canvas
    .getObjectsByUUIDs(uuids)
    .filter(isRemoteObject)
    .forEach((o) => {
      if (o.azureBlobStatus === blobStates.notOnAzure) {
        o.upload()
          .then(() => {
            const historyTile = addedObjects.find(
              (tile) => tile.TileId === o.uuid
            );

            /**
             * Update the AzureBlobStatus in the history tile so that on Redo we know it's on Azure and can thus
             * display the preview.
             *
             * @TODO Find a way to do this without a mutation by reference. It seems also that `previousUuid`
             * is not used anymore.
             */
            if (historyTile) {
              historyTile.AzureBlobStatus = o.azureBlobStatus;
            }
          })
          .catch((err) => void errorToast(err));
      } else if (o.previousUuid && !o.preventUpload) {
        o.restore().catch((err) => void errorToast(err));
      }
    });
}

export function* historySaga(): Generator<Effect> {
  yield all([
    /**
     * These sagas run in a precise order:
     *
     * - UNDO/REDO action is dispatched. These two sagas run in parallel:
     *   1. undo/redoSagas apply the undo/redo actions on the canvas
     *   2. signalRHistorySaga reads the action from history and sends the messages
     *
     * - UPDATE_HISTORY is dispatched and a new history state is computed in the reducer
     * - HISTORY_CLEAN_HISTORY_STACK is dispatched and cleanHistoryStackSaga validates the history stack based on the final canvas state
     *
     * In synced users the two sagas ran sequentially:
     * 1. signalRHistoryNotifySaga applies the notified action on the canvas
     * 2. HISTORY_CLEAN_HISTORY_STACK is dispatched and cleanHistoryStackSaga validates the history stack based on the final canvas state
     *
     * We are currently manually dispatching the cleanHistoryStackAction when undo/redoSaga and
     * signalRHistoryNotifySaga async handlers have completed.
     */

    // Ran BEFORE the state update
    takeEvery(HistoryActionType.UNDO, undoSaga),
    takeEvery(HistoryActionType.REDO, redoSaga),
    signalRHistorySaga(),

    // Independent from state update
    signalRHistoryNotifySaga(),

    // Ran AFTER the state update or `signalRHistoryNotifySaga`
    takeEvery(isHistoryAddAction, addedObject),
    takeEvery(
      HistoryActionType.HISTORY_CLEAN_HISTORY_STACK,
      cleanHistoryStackSaga
    ),
  ]);
}
