import i18n from "i18next";
import ICU from "i18next-icu";

/**
 * Wrap the `i18next-icu` package to pass the active language + region down to
 * the Internationalization API.
 *
 * By default `i18next-icu` only uses the language part of a locale code, e.g.
 * `en` rather than `en-GB`. This means we always end up with US date formatting.
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl
 *
 * See #7050
 */
export class ICUWithRegionSupport extends ICU {
  parse(
    res: string,
    options: unknown,
    _: string,
    ns: string,
    key: string,
    info: unknown
  ): string {
    // The typings for `i18next-icu` do not include the `parse` method
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return super.parse(res, options, i18n.language, ns, key, info);
  }
}
