import { fabric } from "fabric";

export type ViewportIntersectionIndex = {
  entry: number;
  exit: number;
};

export type ViewportBounds = {
  tl: fabric.CollaboardPoint;
  tr: fabric.CollaboardPoint;
  bl: fabric.CollaboardPoint;
  br: fabric.CollaboardPoint;
  bounds: fabric.CollaboardPoint[];
};

type PointSectorId = -1 | 0 | 1;
type PointSector = [PointSectorId, PointSectorId];

/**
 * How many additional points should BEFORE and AFTER each visible segment?
 *
 * The Catmull-Rom curve-fitting algorithm requires four points to fit a
 * curve. In order to draw the curve from `point1` to `point2` we also need
 * `point0` and `point3` (i.e. one more before `point1` and two more after
 * `point1`).
 *
 * `point0` -> `point1` -> `point2` -> `point3`
 *
 * The buffer ensures we have plenty of points outside the viewport in order
 * to draw the visible curves correctly.
 *
 */
const buffer = 5;

/**
 * Compute visible segments.
 *
 *@NOTE This is run twice on load because of the thumbnail update.
 *
 * @param {fabric.CollaboardPoint[]} points List of points to process
 * @param {TransformMatrix} objectTransformMatrix Transform matrix of the InkPath object
 * @param {fabric.BoundingCoords} vptCoords Coordinates of the viewport
 * @param {number} strokeWidth Stroke size of brush
 * @param {boolean} [pathUsesCurves] Does the brush use curves for rendering?
 * @returns {fabric.CollaboardPoint[][]} List of visible point arrays
 */
export const computeVisibleSegments = (
  points: fabric.CollaboardPoint[],
  objectTransformMatrix: TransformMatrix,
  vptCoords: fabric.BoundingCoords,
  strokeWidth: number,
  pathUsesCurves = false
): fabric.CollaboardPoint[][] => {
  const indexes = computeViewportIntersectionIndexes(
    points,
    objectTransformMatrix,
    vptCoords,
    strokeWidth,
    pathUsesCurves
  );

  return indexes
    .filter((index) => {
      return index.exit + 1 > index.entry;
    })
    .map((index) => {
      return points.slice(index.entry, index.exit + 1);
    });
};

/**
 * Compute the viewport intersection indexes (i.e. the entry and exit points of
 * each visible segment).
 *
 * @param {fabric.CollaboardPoint[]} points List of points to process
 * @param {TransformMatrix} objectTransformMatrix Transform matrix of the InkPath object
 * @param {fabric.BoundingCoords} vptCoords Coordinates of the viewport
 * @returns {ViewportIntersectionIndex[]}
 */
const computeViewportIntersectionIndexes = (
  points: fabric.CollaboardPoint[],
  objectTransformMatrix: TransformMatrix,
  vptCoords: fabric.BoundingCoords,
  strokeWidth: number,
  pathUsesCurves: boolean
): ViewportIntersectionIndex[] => {
  const { tl, br } = vptCoords;
  const bounds = Object.values(vptCoords);

  const maxIndex = points.length - 1;
  let isSegmentOnScreen = false;

  return points.reduce(
    (
      result: ViewportIntersectionIndex[],
      rawPoint: fabric.CollaboardPoint,
      i: number,
      arr: fabric.CollaboardPoint[]
    ) => {
      const previousRawPoint = arr[i - 1];

      const point = fabric.util.transformPoint(
        rawPoint,
        objectTransformMatrix
      ) as fabric.CollaboardPoint;

      const isPointVisible = isPointVisibleWithinViewport(
        point,
        strokeWidth,
        tl,
        br
      );

      if (isPointVisible && !isSegmentOnScreen) {
        // Path was not on screen, but now it is - this is an ENTRY
        setEntryPoint(result, i, maxIndex);
        isSegmentOnScreen = true;
      } else if (!isPointVisible && isSegmentOnScreen) {
        // Path was on screen, but now it is not - this is an EXIT
        setExitPoint(result, i, maxIndex);
        isSegmentOnScreen = false;
      } else if (previousRawPoint && !isSegmentOnScreen && !isPointVisible) {
        /**
         * Path was not on screen and this point is also not on screen.
         *
         * Perform an additional, more expensive, check.
         */
        const previousPoint = fabric.util.transformPoint(
          previousRawPoint,
          objectTransformMatrix
        ) as fabric.CollaboardPoint;

        const pointSector = computePointSector(point, tl, br);
        const previousPointSector = computePointSector(previousPoint, tl, br);

        // If the path uses curves we must use a simpler check because the
        // intersection algorithm can give false negatives.
        if (pathUsesCurves) {
          if (isDifferentSector(pointSector, previousPointSector)) {
            // Path entered and exited between two points
            setEntryPoint(result, i - 1, maxIndex);
            setExitPoint(result, i, maxIndex);
          }
          return result;
        }

        // This check is only suitable for brushes that draw straight lines
        // between points.
        const checkIntersection = isIntersectionPossible(
          pointSector,
          previousPointSector
        );

        if (checkIntersection) {
          // If it is possible, check for sure
          const intersects = pointsIntersectViewport(
            point,
            previousPoint,
            bounds,
            strokeWidth
          );

          if (intersects) {
            // Path entered and exited between two points
            setEntryPoint(result, i - 1, maxIndex);
            setExitPoint(result, i, maxIndex);
          }
        }
      }

      return result;
    },
    []
  );
};

/**
 * Helper method to add an entry point to the result.
 *
 * @private
 * @param {ViewportIntersectionIndex[]} result In-progress viewport intersection result
 * @param {number} i Index of current point
 * @param {number} maxIndex Maximum index value
 */
const setEntryPoint = (
  result: ViewportIntersectionIndex[],
  i: number,
  maxIndex: number
) => {
  const previousResult = result[result.length - 1];
  const entryIndex = Math.max(i - 1 - buffer, 0);
  if (previousResult && previousResult.exit >= entryIndex) {
    // If the points overlap then modify the previous entry instead
    // of adding another one
    result[result.length - 1].exit = maxIndex;
  } else {
    result.push({
      entry: entryIndex,
      exit: maxIndex,
    });
  }
};

/**
 * Helper method to add an exit point to the result.
 *
 * @private
 * @param {ViewportIntersectionIndex[]} result In-progress viewport intersection result
 * @param {number} i Index of current point
 * @param {number} maxIndex Maximum index value
 */
const setExitPoint = (
  result: ViewportIntersectionIndex[],
  i: number,
  maxIndex: number
) => {
  const exitIndex = Math.min(i + buffer, maxIndex);
  result[result.length - 1].exit = exitIndex;
};

/**
 * Check if the line between two points intersects the viewport.
 *
 * @private
 * @param {fabric.CollaboardPoint} point1 Point 1
 * @param {fabric.CollaboardPoint} point2 Point 2
 * @param {fabric.CollaboardPoint[]} bounds Viewport bounds as a list
 * @returns {boolean}
 */
const pointsIntersectViewport = (
  point1: fabric.CollaboardPoint,
  point2: fabric.CollaboardPoint,
  bounds: fabric.Position[],
  strokeWidth: number
): boolean => {
  const path = createConvexPolygon(point1, point2, strokeWidth);
  // @types def for fabric isn't quite right, so we cast it to our own custom type
  const Intersection = (fabric.Intersection as unknown) as fabric.CollaboardIntersection;
  const intersectionResult = Intersection.intersectPolygonPolygon(path, bounds);

  return intersectionResult.status === "Intersection";
};

/**
 * Create a convex polygon using the points and the strokeWidth.
 *
 * @param {fabric.CollaboardPoint} point1 Point 1
 * @param {fabric.CollaboardPoint} point2 Point 2
 * @param {number} strokeWidth Width of the ink path
 * @returns {fabric.CollaboardPoint[]}
 */
const createConvexPolygon = (
  point1: fabric.CollaboardPoint,
  point2: fabric.CollaboardPoint,
  strokeWidth: number
) => {
  const vector = point2.subtract(point1) as fabric.CollaboardPoint;
  const perpendicular = vector.perpendicular();
  const magnitude = perpendicular.magnitude();
  const normalized = perpendicular.divide(magnitude).multiply(strokeWidth);

  const p0 = point1.add(normalized);
  const p1 = point1.subtract(normalized);
  const p2 = point2.add(normalized);
  const p3 = point2.subtract(normalized);

  const midPoint = p0.midPointFrom(p1);

  const path = [p0, p1, p2, p3];

  // Ensure points are in correct order to draw convex polygon
  path.sort((a, b) => {
    const a0 = Math.atan2(midPoint.y - a.y, midPoint.x - a.x);
    const b0 = Math.atan2(midPoint.y - b.y, midPoint.x - b.x);
    if (a0 < b0) {
      return -1;
    }
    if (a0 > b0) {
      return 1;
    }
    return 0;
  });

  return path;
};

/**
 * Compute which viewport sector the point resides in.
 *
 * Sector [0, 0] is the viewport, i.e. on screen. Everything else is offscreen.
 *
 *   [-1, -1]  |  [0, -1]  |  [1, -1]
 * ------------------------------------
 *   [-1,  0]  |  [0,  0]  |  [1,  0]
 * ------------------------------------
 *   [-1,  1]  |  [0,  1]  |  [1,  1]
 *
 * @private
 * @param {fabric.CollaboardPoint} point Point to check
 * @param {fabric.Position} tl Coordinates of the top left corner of the viewport
 * @param {fabric.Position} br Coordinates of the bottom right corner of the viewport
 * @returns {PointSector} Array representing the point sector
 */
const computePointSector = (
  point: fabric.CollaboardPoint,
  tl: fabric.Position,
  br: fabric.Position
): PointSector => {
  let x: PointSectorId = 0;
  let y: PointSectorId = 0;

  if (point.x < tl.x) {
    x = -1;
  } else if (point.x > br.x) {
    x = 1;
  }

  if (point.y < tl.y) {
    y = -1;
  } else if (point.y > br.y) {
    y = 1;
  }

  return [x, y];
};

/**
 * Check if the points are from different sectors.
 *
 * @private
 * @param {PointSector} sector1 Point sector 1
 * @param {PointSector} sector2 Point sector 2
 * @returns {boolean}
 */
const isDifferentSector = (
  sector1: PointSector,
  sector2: PointSector
): boolean => {
  const [x1, y1] = sector1;
  const [x2, y2] = sector2;

  if (x1 !== x2 || y1 !== y2) {
    return true;
  }

  return false;
};

/**
 * Using the point sectors determine if it is possible for a straight line to
 * intersect the viewport.
 *
 * NOTE: This algorithm only works with straight lines, meaning that a curved
 * line (e.g. a quadratic curve) may report a false negative.
 *
 * TODO: Improve this algorithm to work with curves.
 *
 * @private
 * @param {PointSector} sector1 Point sector 1
 * @param {PointSector} sector2 Point sector 2
 * @returns {boolean}
 */
const isIntersectionPossible = (
  sector1: PointSector,
  sector2: PointSector
): boolean => {
  const [x1, y1] = sector1;
  const [x2, y2] = sector2;

  // Points have changed sectors on both axes
  if (x1 !== x2 && y1 !== y2) {
    return true;
  }

  // Points have crossed the viewport horizontally
  if (x1 !== x2 && y1 === 0 && y2 === 0) {
    return true;
  }

  // Points have crossed the viewport vertically
  if (y1 !== y2 && x1 === 0 && x2 === 0) {
    return true;
  }

  return false;
};

/**
 * Detect if a point plus its strokeWidth is within the viewport.
 *
 * @private
 * @param {fabric.CollaboardPoint} point Point to check
 * @param {number} strokeWidth Stroke size of brush
 * @param {fabric.Position} tl Coords of top left corner of viewport
 * @param {fabric.Position} br Coords of bottom right corner of viewport
 * @returns {boolean}
 */
const isPointVisibleWithinViewport = (
  point: fabric.CollaboardPoint,
  strokeWidth: number,
  tl: fabric.Position,
  br: fabric.Position
): boolean => {
  const brushOffset = strokeWidth / 2;
  const p0 = point.scalarSubtract(brushOffset);
  const p1 = point.scalarAdd(brushOffset);

  if (p1.x >= tl.x && p0.x <= br.x && p1.y >= tl.y && p0.y <= br.y) {
    return true;
  }

  return false;
};
