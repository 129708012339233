import { ApiPermission } from "../../types/enum";
import { DropdownItem } from "../routes/projects/projectList/DropDown";

export const spacePermissions: DropdownItem<ApiPermission>[] = [
  {
    labelKey: "accessPermissions.viewer",
    value: ApiPermission.readPermission,
  },
  {
    labelKey: "accessPermissions.editor",
    value: ApiPermission.readWritePermission,
  },
  {
    labelKey: "accessPermissions.facilitator",
    value: ApiPermission.facilitatorPermission,
  },
];
