import React, { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Box, Heading, Text, ThemeUIStyleObject } from "theme-ui";
import { routes } from "../../../const";
import { getAppName } from "../../../tools/customer";
import {
  isStaticFeatureActive,
  staticFeatureFlags,
} from "../../../tools/flags";

const registrationDisabled = isStaticFeatureActive(
  staticFeatureFlags.REGISTRATION_DISABLED
);

type Props = {
  withHeader?: boolean;
};

function LoginHeaders({ withHeader }: Props): ReactElement {
  const { t } = useTranslation();
  const { search } = useLocation();

  return (
    <>
      {withHeader && (
        <Box sx={appnameStyle}>
          <Heading as="legend">
            {t("form.header.login", { appName: getAppName() })}
          </Heading>
        </Box>
      )}

      {!registrationDisabled && (
        <Text sx={registerStyle}>
          <Trans i18nKey="form.header.loginSubheader">
            <Link
              to={{
                pathname: routes.register,
                search,
              }}
            />
          </Trans>
        </Text>
      )}
    </>
  );
}

export default LoginHeaders;

const appnameStyle: ThemeUIStyleObject = { width: "100%" };

const registerStyle: ThemeUIStyleObject = {
  fontSize: [5],
  fontWeight: 200,
};
