import { runtimeConfig } from "../runtimeConfig";

export {
  developmentFlags,
  staticFeatureFlags,
  dynamicFeatureFlags,
  dynamicFeatureConfig,
} from "./flags";

/**
 * Is the app running in development mode?
 *
 * @returns {boolean}
 */
export const isDevModeEnabled = (): boolean => {
  const { env } = runtimeConfig;
  return !!env && !["production", "staging"].includes(env);
};

/**
 * Is the app running in production mode?
 *
 * @returns {boolean}
 */
export const isProduction = (): boolean => {
  const { env } = runtimeConfig;
  return env === "production";
};

/**
 * Is the app running on premises?
 *
 * @returns {boolean}
 */
export const isOnPremise = (): boolean => {
  const { isOnPremise: toggle, onPremiseMftApiUrl } = runtimeConfig;
  return toggle && !!onPremiseMftApiUrl;
};

/**
 * Is the app running on AWS?
 *
 * @returns {boolean}
 */
export const isOnAws = (): boolean => {
  const { isOnAws } = runtimeConfig;
  return isOnAws;
};

/**
 * Check if a development flag is active.
 *
 * This value will not change at runtime.
 *
 * @param flag Development flag
 * @returns {boolean}
 */
export const isDevFlagActive = (
  flag: keyof RuntimeConfigDevelopmentFlags
): boolean => {
  // TODO: Move dev flags to a separate key
  return runtimeConfig.features ? !!runtimeConfig.features[flag] : false;
};

/**
 * Check if a static feature flag is active.
 *
 * This value will not change at runtime.
 *
 * @param flag Feature flag
 * @returns {boolean}
 */
export const isStaticFeatureActive = (
  flag: keyof RuntimeConfigStaticFeatureFlags
): boolean => {
  // TODO: Move static flags to a separate key
  return runtimeConfig.features ? !!runtimeConfig.features[flag] : false;
};
