import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Plus(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <polygon points="27.02 19.47 20.79 19.47 20.79 13.24 19.25 13.24 19.25 19.47 13.02 19.47 13.02 21.01 19.25 21.01 19.25 27.24 20.79 27.24 20.79 21.01 27.02 21.01 27.02 19.47" />
    </Icon>
  );
}
export default Plus;
