import React, { ReactElement, RefObject, useMemo } from "react";
import { Box } from "theme-ui";
import { fieldStyles } from "../../../shared/forms/dropdowns/BasicDropdown/BasicDropdown";
import BasicDropdownPortal from "../../../shared/forms/dropdowns/BasicDropdown/BasicDropdownPortal";
import { MultiInputFieldItem } from "../../../shared/forms/fields/MultiInputField/useMultiInputField";

type Props = {
  container: RefObject<HTMLElement>;
  items: ApiSearchUserInfo[];
  selectedItems: MultiInputFieldItem<ApiSearchUserInfo>[];
  onItemClick(user: ApiSearchUserInfo): void;
};

function EmailAutocompleteDropdown({
  container,
  items,
  selectedItems,
  onItemClick,
}: Props): ReactElement {
  const selectedUserNames = useMemo(
    () => selectedItems.map((i) => i.value.toLowerCase()),
    [selectedItems]
  );

  const filteredItems = useMemo(
    () =>
      items
        .filter(
          (user) =>
            !selectedUserNames.includes(user.UserName.toLocaleLowerCase())
        )
        .slice(0, 10),
    [items, selectedUserNames]
  );

  const isOpen = !!filteredItems.length;

  return (
    <BasicDropdownPortal
      sx={fieldStyles.getOptions(isOpen)}
      isOpen={isOpen}
      container={container.current}
    >
      {filteredItems.map((user) => {
        const { Company, CompanyRole, Email, FullName } = user;
        const companyData = `${Company || ""} ${CompanyRole || ""}`.trim();

        return (
          <Box
            role="button"
            key={Email}
            sx={fieldStyles.getOption(false)}
            style={{ width: container.current?.clientWidth }}
            onClick={() => onItemClick(user)}
          >
            <strong>{FullName}</strong> ({Email}
            {companyData ? ` - ${companyData}` : ""})
          </Box>
        );
      })}
    </BasicDropdownPortal>
  );
}

export default EmailAutocompleteDropdown;
