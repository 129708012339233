import React, { ReactElement } from "react";
import { Box, Flex, ThemeUIStyleObject } from "theme-ui";
import LoadingSpinner from "../../../icons/LoadingSpinner";
import Centered from "../layout/Centered";

function FullPageSpinner({
  children,
}: {
  children?: ReactElement;
}): ReactElement {
  return (
    <Flex sx={containerStyle} data-testid="full-page-spinner">
      <Flex sx={innerContainerStyle}>
        <Centered>
          <LoadingSpinner />
        </Centered>
        {children && <Box sx={childBoxStyle}>{children}</Box>}
      </Flex>
    </Flex>
  );
}

const childBoxStyle: ThemeUIStyleObject = {
  mt: [3],
};

const containerStyle: ThemeUIStyleObject = {
  height: "100vh",
  alignItems: "center",
};

const innerContainerStyle: ThemeUIStyleObject = {
  justifyContent: "center",
  flexDirection: "column",
};

export default FullPageSpinner;
