import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Person(props: IconProps): ReactElement {
  return (
    <Icon viewBox="0 0 10.871 10.566" {...props}>
      <path
        d="M10.127,8.336c-1-0.69-2.43-0.32-3.35-1.54c0,0-0.34-0.38,0.43-1.32c0.683-1.06,0.913-2.349,0.64-3.58
	C7.524,0.565,6.183-0.253,4.852,0.071C3.95,0.29,3.246,0.994,3.027,1.896c-0.279,1.231-0.048,2.522,0.64,3.58
	c0.77,0.94,0.43,1.32,0.43,1.32c-0.92,1.22-2.39,0.85-3.35,1.54s-0.72,2.23-0.72,2.23h10.82
	C10.847,10.566,11.077,9.016,10.127,8.336z"
      />
    </Icon>
  );
}
export default Person;
