import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthUserType, logoutAction } from "../../reduxStore/auth/auth.actions";
import {
  isLoggedInState,
  isLoggedOutState,
  selectUserProfile,
} from "../../reduxStore/auth/auth.reducer";
import { Roles } from "../../types/enum";

type UseLoggedInStatus = {
  isLoggedOut: boolean;
  isLoggedIn: boolean;
  isLoggedInAsGuest: boolean;
  isLoggedInAsUser: boolean;
  loggedInViaTenant: string | undefined;
  redirectAfterLogin: string | undefined;
  userProfile: UserProfile | undefined;
  userRoles: Roles[];
  logout: () => void;
};

export const useLoggedInStatus = (): UseLoggedInStatus => {
  const dispatch = useDispatch();

  /**
   * User is 'logged out'.
   *
   * @NOTE `isLoggedOut` and `isLoggedIn` can both be `false` at the same time,
   * (for example while fetching the user profile or initializing auth) but they
   * can't both be `true` at the same time. Don't attempt to infer one from
   * the other - always use the values exposed here.
   */
  const isLoggedOut = useSelector<ApplicationGlobalState, boolean>(({ auth }) =>
    isLoggedOutState(auth)
  );

  /**
   * A user is 'logged in'.
   *
   * This means their token has been used to successfully load their user
   * profile from the API.
   *
   * @NOTE `isLoggedOut` and `isLoggedIn` can both be `false` at the same time,
   * (for example while fetching the user profile or initializing auth) but they
   * can't both be `true` at the same time. Don't attempt to infer one from
   * the other - always use the values exposed here.
   */
  const isLoggedIn = useSelector<ApplicationGlobalState, boolean>(({ auth }) =>
    isLoggedInState(auth)
  );

  /**
   * This means that the user has logged in with a registered account.
   */
  const isLoggedInAsUser = useSelector<ApplicationGlobalState, boolean>(
    ({ auth }) => isLoggedInState(auth) && auth.userType === AuthUserType.User
  );

  /**
   * This means that the user has logged in as a guest.
   */
  const isLoggedInAsGuest = useSelector<ApplicationGlobalState, boolean>(
    ({ auth }) => isLoggedInState(auth) && auth.userType === AuthUserType.Guest
  );

  /**
   * The tenant value the user used when logging in, e.g. from `/authenticate/:tenant`
   */
  const loggedInViaTenant = useSelector<
    ApplicationGlobalState,
    string | undefined
  >(({ auth }) => (isLoggedInState(auth) ? auth.tenant : undefined));

  /**
   * The profile of the user loaded from the API.
   */
  const userProfile = useSelector<
    ApplicationGlobalState,
    UserProfile | undefined
  >((state) => selectUserProfile(state));

  /**
   * The user's roles as defined in their JWT token.
   */
  const userRoles = useSelector<ApplicationGlobalState, Roles[]>(({ auth }) =>
    isLoggedInState(auth) ? auth.roles : []
  );

  /**
   * Redirect after a log in.
   */
  const redirectAfterLogin = useSelector<
    ApplicationGlobalState,
    string | undefined
  >(({ auth }) => (isLoggedInState(auth) ? auth.redirect : undefined));

  /**
   * Logout.
   *
   * Always use this method to perform a logout.
   *
   * Redirection is handled by AuthRoute / PrivateRoute components.
   */
  const logout = useCallback(() => {
    dispatch(logoutAction());
  }, [dispatch]);

  return {
    isLoggedOut,
    isLoggedIn,
    isLoggedInAsGuest,
    isLoggedInAsUser,
    loggedInViaTenant,
    redirectAfterLogin,
    userProfile,
    userRoles,
    logout,
  };
};
