import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Touchpad(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M14.48,15.43a.56.56,0,0,0,.41.19.53.53,0,0,0,.36-.13.54.54,0,0,0,.06-.77A3,3,0,0,1,14.6,13a3,3,0,0,1,2.74-3.27.57.57,0,0,0,.45-.31,3,3,0,0,1,.53-.82,3.65,3.65,0,0,1,5.14-.41,3.65,3.65,0,0,1,.41,5.15.55.55,0,0,0,.06.77.54.54,0,0,0,.77-.06,4.75,4.75,0,0,0-7.22-6.16,4.3,4.3,0,0,0-.55.79,4.1,4.1,0,0,0-3.42,4.41A4,4,0,0,0,14.48,15.43Z" />
      <path d="M28.14,33.4a6.52,6.52,0,0,1-1.15-3,21.28,21.28,0,0,0,1.19-4.31v-4.8a1.4,1.4,0,0,0-1.32-1.43h0a.43.43,0,0,0-.43.42h0v1.1a.34.34,0,0,1-.32.34.32.32,0,0,1-.34-.31h0v-2a1.52,1.52,0,0,0-1.44-1.53h-.09a.86.86,0,0,0-.86.88v1.16a.33.33,0,0,1-.32.34.31.31,0,0,1-.33-.32h0V10a1.51,1.51,0,0,0-1.43-1.52h-.17A1.52,1.52,0,0,0,19.69,10v9.34a.32.32,0,0,1-.31.34.32.32,0,0,1-.34-.31h0V11.48a.88.88,0,0,0-.85-.88h0a1.87,1.87,0,0,0-1.84,1.89V23.11h0a19,19,0,0,0-1.82-2.69A2.06,2.06,0,0,0,12,19.84a1.13,1.13,0,0,0-.65.7,1.17,1.17,0,0,0,.09,1c.35.59.77,1.55,1.26,2.65,1.12,2.52,2.39,5.38,3.76,6.27a28.58,28.58,0,0,1,3.44,3.35.34.34,0,0,0,.25.11h7.66a.33.33,0,0,0,.29-.5Z" />
    </Icon>
  );
}
export default Touchpad;
