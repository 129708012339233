import React, { ReactElement } from "react";
import { Image } from "theme-ui";

function Logo({ colorized }: LogoProps): ReactElement {
  return (
    <Image
      sx={{ height: "40px", filter: colorized ? "none" : "brightness(0%)" }}
      src="/logo-digital-workspace.svg"
    />
  );
}
export default Logo;
