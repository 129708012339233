export enum GroupingActionType {
  TOGGLE_GROUP = "TOGGLE_GROUP",
  TOGGLE_STACK = "TOGGLE_STACK",
  UNGROUP_ALL = "UNGROUP_ALL",
}

export const toggleGroupAction = () =>
  ({
    type: GroupingActionType.TOGGLE_GROUP,
    payload: undefined,
  } as const);

export const toggleStackAction = () =>
  ({
    type: GroupingActionType.TOGGLE_STACK,
    payload: undefined,
  } as const);

export const toggleUngroupAllAction = () =>
  ({
    type: GroupingActionType.UNGROUP_ALL,
    payload: undefined,
  } as const);

export type GroupingAction =
  | ReturnType<typeof toggleGroupAction>
  | ReturnType<typeof toggleStackAction>
  | ReturnType<typeof toggleUngroupAllAction>;
