/**
 * Function to be used with useInfiniteQuery from ReactQuery
 * returns number of the next page OR false if there are not more items to fetch
 */

import { pluck, unnest } from "ramda";
import { requestCancelled } from ".";
import { isDefined } from "../tools/utils";

export const getFetchMore = <V>(
  lastGroup: PagedResponse<V>,
  allGroups: PagedResponse<V>[]
): number | false => {
  const allResultsCount = allGroups.reduce(
    (accu, curr) => accu + (curr.Results?.length ?? 0),
    0
  );

  const totalCount = lastGroup.TotalRows || allGroups[0].TotalRows;
  const isCurrentPageEmpty = !lastGroup.Results?.length;
  const areItemsLeft = !isCurrentPageEmpty && allResultsCount < totalCount;

  return areItemsLeft ? allGroups.length + 1 : false;
};

export const combineResultsOfInfiniteQuery = <V>(
  data: PagedResponse<V>[] | undefined | null
): V[] => {
  const getResults = pluck("Results");
  return unnest(getResults(data ?? []).filter(isDefined));
};

export function fixInvalidToken(token: string): string;
export function fixInvalidToken(token?: string): string | undefined;
export function fixInvalidToken(token: string[]): string[];
export function fixInvalidToken(
  token: string | string[] | undefined
): string | string[] | undefined {
  if (!token) {
    return token;
  }

  if (Array.isArray(token)) {
    return token.map((t) => fixInvalidToken(t));
  }

  // double `decodeURIComponent` pass, to convert `%252B` to `+`, instead of `%2B`
  return decodeURIComponent(decodeURIComponent(token)).replace(/ /g, "+");
}

export const getAllPages = async <Value, Response extends PagedResponse<Value>>(
  getSinglePage: (pageNumber: number) => Promise<Response>
): Promise<Response> => {
  const allPages: Value[] = [];

  let currentPage = 0;
  let totalResultsCount = 0;
  let firstPage: Response = {} as Response;
  let hasMorePages = true;

  do {
    currentPage++;
    try {
      const results = await getSinglePage(currentPage);
      const { TotalRows, Results } = results;
      firstPage = currentPage === 1 ? results : firstPage;
      totalResultsCount = TotalRows || totalResultsCount;
      Results && allPages.push(...Results);
      hasMorePages =
        Boolean(Results?.length) && totalResultsCount > allPages.length;
    } catch (error) {
      /**
       * If a request fails the do/while loop is exited and the rejection is
       * passed up to the caller. If the caller is react-query the request will
       * be retried 3 times by default. We don't want this to happen if the
       * request was cancelled intentionally - which happens when there is no
       * auth token or we were unable to renew the auth token.
       *
       * In this case we exit the loop and treat the response as successful to
       * prevent retries (we don't know if our caller is react-query).
       */
      if (error === requestCancelled) {
        hasMorePages = false;
        break;
      }

      // Rethrow the error when it is unknown
      throw error;
    }
  } while (hasMorePages);

  return {
    ...firstPage,
    Results: allPages,
  };
};
