import React, { ReactElement } from "react";
import { Box } from "theme-ui";

const size = 25;

function Logo(props: LogoProps): ReactElement {
  return (
    <Box
      sx={{
        width: 0,
        height: 0,
        p: `${size}px`,
        backgroundSize: "cover",
        background: "url(/logo-vp.svg)",
        ".dark &": {
          background: "url(/logo-vp-white.svg)",
        },
      }}
      {...props}
    />
  );
}
export default Logo;
