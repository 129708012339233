import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Flex, Text } from "theme-ui";
import Delete from "../../../../../icons/Delete";
import Placeholder from "../../../../../icons/Placeholder";
import { createUserInitials } from "../../../../../tools/avatarGenerator";
import { ApiSubscriptionUserPermission } from "../../../../../types/enum";
import { useLoggedInStatus } from "../../../../authentication/useLoggedInStatus";
import Avatar from "../../../../users/Avatar";
import RoleDropdown from "./RoleDropdown";

const getDisplayName = ({
  FirstName,
  LastName,
  Username,
}: ApiSubscriptionUser) =>
  FirstName || LastName
    ? `${FirstName} ${LastName}`.trim()
    : Username.split("@")[0].replace(".", " ");

type Props = {
  user: ApiSubscriptionUser;
  onRemove(): void;
};

function User({ user, onRemove }: Props): ReactElement {
  const { t } = useTranslation();
  const { userProfile } = useLoggedInStatus();

  const { Username, Permission } = user;
  const isLoggedUser = userProfile?.UserName === Username;
  const isSubscriptionOwner =
    Permission === ApiSubscriptionUserPermission.owner;
  const isManagable = !isSubscriptionOwner && !isLoggedUser;

  return (
    <Flex variant="layout.listRow">
      <Box variant="layout.listRowCell" sx={{ flex: "unset" }}>
        <Avatar
          AvatarUrl={user.PhotoUrl}
          placeholder={createUserInitials({
            FirstName: user.FirstName,
            LastName: user.LastName,
            UserName: user.Username,
          })}
        />
      </Box>
      <Text variant="layout.listRowCell">{getDisplayName(user)}</Text>
      <Text variant="layout.listRowCell" sx={{ bg: "delicate" }}>
        {user.Username}
      </Text>
      <Text
        variant="layout.listRowCell"
        sx={{ display: ["none", "none", "flex"] }}
      >
        {t("app.creationDate", { date: new Date(user.DateCreated) })}
      </Text>

      <Text
        variant="layout.listRowCell"
        sx={{ bg: "delicate", display: ["none", "none", "flex"] }}
      >
        {user.LastLoginDate &&
          t("users.lastLoginDate", { date: new Date(user.LastLoginDate) })}
      </Text>

      <Box variant="layout.listRowCell">
        <RoleDropdown user={user} disabled={!isManagable} />
      </Box>

      <Text variant="layout.listRowCell" sx={{ bg: "delicate", flex: "unset" }}>
        {isManagable ? (
          <Button id="user-remove" variant="buttons.content" onClick={onRemove}>
            <Delete noShadow />
          </Button>
        ) : (
          <Placeholder />
        )}
      </Text>
    </Flex>
  );
}

export default User;
