import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function OK(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M20.1,10.19C14.883,10.19 10.59,14.483 10.59,19.7C10.59,24.917 14.883,29.21 20.1,29.21C25.317,29.21 29.61,24.917 29.61,19.7C29.61,14.483 25.317,10.19 20.1,10.19ZM18.25,24.28L14,20L15.12,18.88L18.29,22.05L25.46,14.86L26.55,16L18.25,24.28Z" />
    </Icon>
  );
}
export default OK;
