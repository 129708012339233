/**
 * Check if this looks like validated token response.
 *
 * This means either the user has logged in without 2FA enabled or they have
 * logged in and then validated the token against the 2FA endpoint.
 */
export const isValidatedTokenResponse = (
  response:
    | ApiAuthenticate
    | ApiValidatedAuthenticate
    | ApiRegisterUser
    | ApiCompleteUserRegistration
): response is ApiValidatedAuthenticate => {
  return "RefreshToken" in response && !!response.RefreshToken;
};

export const isCompleteRegistrationResponse = (
  data: ApiBaseResponse | undefined
): data is ApiCompleteUserRegistration => {
  if (!data) {
    return false;
  }

  return "User" in data && "AuthorizationToken" in data;
};

export const isAlreadyRegisteredResponse = (
  data: ApiBaseResponse | undefined
): data is ApiCompleteUserRegistration => {
  if (!data) {
    return false;
  }

  return isCompleteRegistrationResponse(data) && isValidatedTokenResponse(data);
};
