import React, { PropsWithChildren, ReactElement } from "react";
import { Text } from "theme-ui";

import Modal from "./Modal";
import ConfirmationButtons from "./ConfirmationButtons";
import { noop } from "../../../tools/utils";

export type ConfirmationProps = PropsWithChildren<{
  header: string | ReactElement;
  hideConfirmationButton?: boolean;
  confirmationLabel: string;
  onConfirm(): void;
  isOpen?: boolean;
  cancelLabel?: string;
  confirmationDisabled?: boolean;
  onClose?(): void;
  variant?: "warning" | "success";
  closeOnDimmerClick?: boolean;
  closeOnEscape?: boolean;
  closeOnConfirm?: boolean;
  confirmOnEnterKeyPressed?: boolean;
  autoFocusConfirmButton?: boolean;
  hideCancelButton?: boolean;
}>;

function Confirmation({
  confirmationLabel,
  hideConfirmationButton,
  onConfirm,
  cancelLabel = "",
  confirmationDisabled = false,
  closeOnConfirm = true,
  closeOnDimmerClick = true,
  onClose = noop,
  variant = "success",
  confirmOnEnterKeyPressed = true,
  autoFocusConfirmButton = false,
  hideCancelButton = false,
  children,
  ...rest
}: ConfirmationProps): ReactElement {
  return (
    <Modal
      onClose={onClose}
      actionButtons={
        <ConfirmationButtons
          onClose={onClose}
          variant={variant}
          onConfirm={onConfirm}
          hideConfirmationButton={hideConfirmationButton}
          confirmationDisabled={confirmationDisabled}
          confirmationLabel={confirmationLabel}
          cancelLabel={cancelLabel}
          closeOnConfirm={closeOnConfirm}
          confirmOnEnterKeyPressed={confirmOnEnterKeyPressed}
          autoFocusConfirmButton={autoFocusConfirmButton}
          hideCancelButton={hideCancelButton}
        />
      }
      closeOnDimmerClick={closeOnDimmerClick}
      {...rest}
    >
      <Text
        py={4}
        sx={{
          color: "var(--textColor)",
          svg: {
            fill: "var(--textColor)",
          },
        }}
      >
        {children}
      </Text>
    </Modal>
  );
}

export default Confirmation;
