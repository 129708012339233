import React, { ReactElement, useCallback, useState } from "react";
import { Box, Flex, IconButton, ThemeUIStyleObject } from "theme-ui";
import useHotkeys from "../../../shared/dom/useHotkeys";
import Avatar, { AvatarProps } from "../../../users/Avatar";
import LogOutButton from "./LogOutButton";

function UserMenu(props: AvatarProps): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => setIsOpen((state) => !state), []);

  useHotkeys("esc", toggle, [toggle], {
    disabled: !isOpen,
  });

  return (
    <Box sx={{ position: "relative" }}>
      <IconButton
        id="user-menu"
        aria-label="Toggle Menu"
        onClick={toggle}
        sx={buttonStyle}
      >
        <Avatar enlarge {...props} IsOnline />
      </IconButton>

      {isOpen && (
        <Flex sx={dropdownStyle}>
          <LogOutButton />
        </Flex>
      )}
    </Box>
  );
}

const buttonStyle: ThemeUIStyleObject = {
  cursor: "pointer",
  height: "auto",
  pl: [0],
  pointerEvents: "auto",
  width: "auto",
  "&:focus": {
    outline: "none",
  },
};

const dropdownStyle: ThemeUIStyleObject = {
  bg: "primary",
  borderColor: "primary",
  borderStyle: "solid",
  borderWidth: "2px",
  boxShadow: "var(--controlShadow)",
  flexDirection: "column",
  m: [1],
  minWidth: "10rem",
  mt: [2],
  outline: "none",
  pointerEvents: "auto",
  position: "absolute",
  right: 0,
  top: "100%",
  "&:focus": {
    outline: "none",
  },
  "&:before": {
    borderBottomColor: "primary",
    borderBottomStyle: "solid",
    borderBottomWidth: "calc(var(--gridTile) / 2)",
    borderLeftColor: "transparent",
    borderLeftStyle: "solid",
    borderLeftWidth: "calc(var(--gridTile) / 2)",
    bottom: "100%",
    content: '""',
    display: "block",
    height: 0,
    position: "absolute",
    right: "calc(var(--gridTile) / 4)",
    width: 0,
  },
};

export default UserMenu;
