import { MutableRefObject, useCallback, useMemo } from "react";
import { useHotkeys, Options } from "react-hotkeys-hook";
import type { HotkeysEvent } from "hotkeys-js";
import { inputTags, isInput } from "../../../tools";

type HotkeyConfig = {
  enableForModal?: boolean;
  enableForInputs?: boolean;
  disabled?: boolean;
  allowRepeat?: boolean;
  keyUp?: boolean;
};

export default (
  keys: string,
  callback: (e: KeyboardEvent, hotkeysEvent: HotkeysEvent) => void,
  deps: unknown[],
  config?: HotkeyConfig
): MutableRefObject<Element | null> => {
  const filter = useCallback(
    (e: KeyboardEvent) => {
      const isModalOpen = !!document.querySelector(".modal-overlay");
      const isInputFocused = isInput(e.srcElement);
      const isModalPrevented = isModalOpen && !config?.enableForModal;
      const isRepeatPrevented = e.repeat && !config?.allowRepeat; // only execute callback once - do not repeat if user keeps pressing down the key
      const isKeyAllowed = !isModalPrevented && !isRepeatPrevented;

      return isInputFocused || isKeyAllowed;
    },
    [config?.allowRepeat, config?.enableForModal]
  );

  const useHotkeysConfig = useMemo<Options>(
    () => ({
      keyup: config?.keyUp ?? false,
      keydown: true,
      enabled: !config?.disabled ?? true,
      enableOnTags: config?.enableForInputs ? inputTags : [],
      filter,
    }),
    [config?.disabled, config?.enableForInputs, config?.keyUp, filter]
  );

  return useHotkeys(keys, callback, useHotkeysConfig, deps);
};
