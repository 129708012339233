import React, { PropsWithChildren, ReactElement } from "react";
import { Container, Flex } from "theme-ui";

import Logo from "../logo/Logo";
import TagLine from "../TagLine";
import HelpButton from "../../../shared/help/HelpButton";
import {
  isStaticFeatureActive,
  staticFeatureFlags,
} from "../../../../tools/flags";
import HostingHeader from "../../../authentication/login/HostingHeader";

const showLoginPageHelpButton = isStaticFeatureActive(
  staticFeatureFlags.LOGIN_PAGE_HELP_BUTTON
);

type Props = PropsWithChildren<unknown> & {
  variant?: string;
};

function Page({ children, variant = "formsWrap" }: Props): ReactElement {
  return (
    <>
      <Container as="header" variant="header">
        <Flex as="nav" variant="layout.navbar">
          <Logo withDisclaimer>
            <TagLine />

            <HostingHeader />
          </Logo>
          {showLoginPageHelpButton && <HelpButton />}
        </Flex>
      </Container>
      <Container as="main" variant={variant}>
        {children}
      </Container>
    </>
  );
}

export default Page;
