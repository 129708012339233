// First item of the tuple is actual value. `false` means "this feature is not in the plan", `null` means "unlimited".
// Second item if set to `true` means "take a translation key with the 'Table' suffix"
type valueTuple = [boolean | number | null, boolean?];

interface ValueFeatureAll {
  labelKey: string;
  order: number;
  inCard: {
    free: boolean;
    individual: boolean;
    team: boolean;
    business: boolean;
    enterprise: boolean;
  };
  value: {
    free: valueTuple;
    individual: valueTuple;
    team: valueTuple;
    business: valueTuple;
    enterprise: valueTuple;
  };
}

export const valueFeatures: Array<ValueFeatureAll> = [
  {
    labelKey: "numberOfProjects",
    order: 1,
    inCard: {
      free: true,
      individual: true,
      team: true,
      business: true,
      enterprise: true,
    },
    value: {
      free: [3],
      individual: [null],
      team: [null],
      business: [null],
      enterprise: [null],
    },
  },
  {
    labelKey: "participantsPerBoard",
    order: 2,
    inCard: {
      free: true,
      individual: true,
      team: true,
      business: true,
      enterprise: true,
    },
    value: {
      free: [5],
      individual: [10],
      team: [50],
      business: [null],
      enterprise: [null],
    },
  },
  {
    labelKey: "uploadDocuments",
    order: 200,
    inCard: {
      free: true,
      individual: false,
      team: false,
      business: false,
      enterprise: false,
    },
    value: {
      free: [15, true],
      individual: [60, true],
      team: [120, true],
      business: [200, true],
      enterprise: [null],
    },
  },
  {
    labelKey: "picturesVideos",
    order: 201,
    inCard: {
      free: true,
      individual: false,
      team: false,
      business: false,
      enterprise: false,
    },
    value: {
      free: [20, true],
      individual: [60, true],
      team: [120, true],
      business: [200, true],
      enterprise: [null],
    },
  },
  {
    labelKey: "guestUsers",
    order: 400,
    inCard: {
      free: false,
      individual: false,
      team: true,
      business: true,
      enterprise: false,
    },
    value: {
      free: [false],
      individual: [false],
      team: [20],
      business: [null],
      enterprise: [null],
    },
  },
];
