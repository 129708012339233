export enum ApiShapeId {
  bird = 1,
  butterflies = 2,
  cat = 3,
  dog = 4,
  elephant = 5,
  lion = 6,
  lizard = 7,
  monkey = 8,
  pig = 9,
  rabbit = 10,
  snake = 11,
  squirrel = 12,
  arrow = 13,
  chat = 14,
  circle = 15,
  doubleArrow = 16,
  hexagon = 17,
  heart = 18,
  pentagon = 19,
  line = 20,
  square = 21,
  squareRounded = 22,
  star = 23,
  triangle = 24,
  angry = 25,
  blink = 26,
  cool = 27,
  doubtful = 28,
  injured = 29,
  laugh = 30,
  love = 31,
  poop = 32,
  sad = 33,
  shock = 34,
  smile = 35,
  tongue = 36,
  berries = 37,
  bug = 38,
  calligraphicElement = 39,
  cereusPeruvianus = 40,
  flower = 41,
  fourPetalsFlower = 42,
  grass = 43,
  leaf = 44,
  rake = 45,
  tree = 46,
  wateringCan = 47,
  wheelbarrow = 48,
  burgerDrink = 49,
  cake = 50,
  cheese = 51,
  chef = 52,
  cutlery = 53,
  drink = 54,
  fishSkeleton = 55,
  fruitBowl = 56,
  iceCream = 57,
  loaf = 58,
  pizza = 59,
  tea = 60,
  boardGame = 61,
  bowling = 62,
  cardGame = 63,
  chess = 64,
  controller = 65,
  dart = 66,
  dice = 67,
  football = 68,
  ghost = 69,
  gun = 70,
  nintendo = 71,
  tank = 72,
  handclap = 73,
  handcouple = 74,
  handdouble = 75,
  handdown = 76,
  handko = 77,
  handleft = 78,
  handok = 79,
  handopen = 80,
  handpray = 81,
  handright = 82,
  handup = 83,
  handwrite = 84,
  bank = 85,
  cash = 86,
  creditCards = 87,
  dollar = 88,
  euro = 89,
  money1 = 90,
  money2 = 91,
  money3 = 92,
  pound = 93,
  profit = 94,
  savingPiggy = 95,
  yen = 96,
  briefcase = 97,
  folder = 98,
  hierarchy = 99,
  landline = 100,
  loop = 101,
  mail = 102,
  megaphone = 103,
  mouse = 104,
  office = 105,
  officeFolder = 106,
  pieChart = 107,
  statistic = 108,
  abacus = 109,
  atoms = 110,
  chemical = 111,
  dna = 112,
  drawingCompass = 113,
  education = 114,
  magnet = 115,
  math = 116,
  microscope = 117,
  radioactive = 118,
  triangularRuler = 119,
  world = 120,
  biohazard = 121,
  branch = 122,
  check = 123,
  cross = 124,
  danger = 125,
  halloween = 126,
  highVoltage = 127,
  recycle = 128,
  stop = 129,
  trafficSign = 130,
  wifi = 131,
  wip = 132,
  baseball = 133,
  baseball2 = 134,
  basketball = 135,
  football1 = 136,
  football2 = 137,
  golf = 138,
  rugby = 139,
  swim = 140,
  tennis = 141,
  tennisBall = 142,
  trophy = 143,
  volleyball = 144,
  airplane = 145,
  bus = 146,
  car = 147,
  cycle = 148,
  helicopter = 149,
  hotAirBalloon = 150,
  motorcycle = 151,
  motorizedScooter = 152,
  rocket = 153,
  ship = 154,
  train = 155,
  van = 156,
  cloud = 157,
  fogDay = 158,
  heavyRainDay = 159,
  heavySnow = 160,
  highWindSpeeds = 161,
  hotThermometer = 162,
  mist = 163,
  moonWithStars = 164,
  sunny = 165,
  sunnyIntervals = 166,
  thermometerFrost = 167,
  thunderstorm = 168,
  allDevices = 169,
  ipod = 170,
  laptop = 171,
  mobileDevices = 172,
  monitor = 173,
  mouseDevice = 174,
  printer = 175,
  smartphone = 176,
  surface = 177,
  tablet = 178,
  vr = 179,
  watch = 180,
  discussion = 181,
  man = 182,
  people = 183,
  portrait = 184,
  portraits = 185,
  raisedHand = 186,
  running = 187,
  speechBubbles = 188,
  twoMen = 189,
  wheelchair = 190,
  woman = 191,
  womanAndMan = 192,
  bpmnLink = 193,
  bpmnBulb = 194,
  bpmnInclusiveGateway = 195,
  bpmnTimer = 196,
  bpmnExclusiveGateway = 197,
  bpmnComplexGateway = 198,
  bpmnReceiveTask = 199,
  bpmnSendTask = 200,
  bpmnInputData = 201,
  bpmnError = 202,
  bpmnParallelEventGateway = 203,
  bpmnServiceTask = 204,
  bucket = 205,
  calipers = 206,
  compass = 207,
  hammer = 208,
  marker = 209,
  pen = 210,
  pencil = 211,
  pliers = 212,
  saw = 213,
  screwdriver = 214,
  setsquare = 215,
  wrench = 216,
  bpmnCompensation = 217,
  bpmnCompensation2 = 218,
  bpmnData = 219,
  bpmnEvent = 220,
  bpmnGateway = 221,
  bpmnLoop = 222,
  bpmnParallelMI = 223,
  bpmnProcess = 224,
  bpmnScriptTask = 225,
  bpmnSequentialMI = 226,
  bpmnStorage = 227,
  bpmnTask = 228,

  longLine = 1000,
}
