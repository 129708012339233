import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Text, Input } from "theme-ui";
import Heart from "../../../icons/Heart";

type Props = {
  isFavourite: boolean;
  onFavouriteChange: () => void;
};

export default function SpaceFavourite({
  isFavourite,
  onFavouriteChange,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <Flex as="label" sx={{ alignItems: "center" }}>
      <Heart noShadow />

      <Text variant="default" sx={{ mr: [5] }}>
        {t("spaces.makeItPreferred")}
      </Text>

      <Input
        variant="toggle"
        type="checkbox"
        checked={isFavourite}
        data-cy="space-preferred-checkbox"
        onChange={onFavouriteChange}
      />
    </Flex>
  );
}
