import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Heading } from "theme-ui";

import { useSelector } from "react-redux";
import Display from "../../../../../icons/Display";
import Team from "../../../../../icons/Team";
import { selectSubscriptionDetails } from "../../../../../reduxStore/subscriptionDetails/subscriptionDetails.reducer";
import { useUISettings } from "../../../../../reduxStore/uiSettings";
import { dynamicFeatureFlags } from "../../../../../tools/flags";
import LicenseItem from "./LicenseItem";

function LicenseSummary(): ReactElement {
  const { t } = useTranslation();
  const {
    summary: { userCount = 0, maxUsers = 0, deviceCount = 0, maxDevices = 0 },
  } = useSelector(selectSubscriptionDetails);
  const { isDynamicFeatureActive } = useUISettings();

  return (
    <>
      <Heading variant="layout.headerBar">{t("team.licenseSummary")}</Heading>
      <Flex variant="licenseBox">
        <LicenseItem
          label={t("team.usersAvailable")}
          count={maxUsers - userCount}
          max={maxUsers}
          icon={Team}
        />

        {isDynamicFeatureActive(dynamicFeatureFlags.DEVICE_LICENSES) && (
          <LicenseItem
            label={t("team.devicesAvailable")}
            count={maxDevices - deviceCount}
            max={maxDevices}
            icon={Display}
          />
        )}
      </Flex>
    </>
  );
}

export default LicenseSummary;
