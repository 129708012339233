import React from "react";
import { Flex, Text } from "theme-ui";

import PropTypes from "prop-types";

function WrappingBox({ label, dark, sx, children }) {
  return (
    <Flex sx={{ flexDirection: "column", ...sx }}>
      {label && <Text sx={{ mb: [1], fontSize: [5] }}>{label}</Text>}
      <Flex
        sx={{
          border: "1px solid",
          borderColor: "var(--inputBorder)",
          backgroundColor: dark ? "delicate" : "transparent",
          color: "var(--textColor)",
          padding: [4],
          flex: 1,
        }}
      >
        {children}
      </Flex>
    </Flex>
  );
}

WrappingBox.propTypes = {
  dark: PropTypes.bool,
  label: PropTypes.string,
};
WrappingBox.defaultProps = {
  dark: false,
  label: "",
};

export default WrappingBox;
