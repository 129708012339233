export const billingTypes = {
  monthly: 1,
  annually: 2,
  lifetime: 3,
};

export const orderStatuses = {
  new: 1,
  completed: 2,
  cancelled: 3,
};

export const subscriptionStatuses = {
  new: 1,
  active: 2,
  inactive: 3,
  stopped: 4,
};

export const paymentUrls = {
  successUrl: `https://${window.location.host}/plans?payment=success&order={ORDER_ID}`,
  cancelUrl: `https://${window.location.host}/plans?payment=cancel&order={ORDER_ID}`,
};
