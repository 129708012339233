import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastOptions } from "react-toastify";
import {
  ProjectStatus,
  selectProjectStatus,
} from "../../../reduxStore/canvas/project/project.reducer";
import { LogCategory } from "../../../tools/telemetry";
import { getVersion } from "../../app/useNewVersionCheck";
import useToast from "../toasts/useToast";
import { useTrackEventToLog } from "../tracking/useTrackEventToLog";

const toastConfig: ToastOptions = {
  autoClose: false,
  type: "error",
};

export function useNetworkStatus(): void {
  const { trackEvent } = useTrackEventToLog();
  const projectStatus = useSelector(selectProjectStatus);

  const disabled = projectStatus.status === ProjectStatus.LOADING;

  const offlineToast = useToast("clientError.networkOffline", {
    ...toastConfig,
    closeOnClick: false,
  });
  const slowToast = useToast("clientError.networkSlow", toastConfig);

  const onNetworkChange = useCallback(() => {
    const isOnline = window.navigator.onLine;

    if (!isOnline) {
      slowToast.isActive() && slowToast.dismiss();
      offlineToast.show();
    } else {
      offlineToast.isActive() && offlineToast.dismiss();
    }
  }, [slowToast, offlineToast]);

  const checkConnectionSpeed = useCallback(async () => {
    if (disabled) {
      return;
    }

    const rttEstimate = await estimateNetworkRTT();

    // RTT above 500ms is considered slow for MacOS Network Link Conditioner
    // Firefox GPRS emulation has latency 500+ms https://developer.mozilla.org/en-US/docs/Tools/Network_Monitor/Throttling#throttling
    if (rttEstimate > 500) {
      offlineToast.isActive() && offlineToast.dismiss();
      slowToast.show();
      trackEvent(LogCategory.network, {
        subcategory: "connection-slow",
        rttEstimate,
        ...getNetworkInformation(),
      });
    } else {
      slowToast.isActive() && slowToast.dismiss();
    }
  }, [slowToast, disabled, offlineToast, trackEvent]);

  useEffect(() => {
    window.addEventListener("offline", onNetworkChange);
    window.addEventListener("online", onNetworkChange);

    return () => {
      window.removeEventListener("offline", onNetworkChange);
      window.removeEventListener("online", onNetworkChange);
    };
  }, [onNetworkChange]);

  useEffect(() => {
    let intervalId: number | null = null;

    if (navigator.connection) {
      // Immediately check the connection speed only if Network API is supported, as the fallback
      // makes an HTTP request to version.json, which may be slow because of other concurrent server requests.
      // So it's better to delay the initial check in that case.
      checkConnectionSpeed();

      navigator.connection.addEventListener("change", checkConnectionSpeed);
    } else {
      intervalId = window.setInterval(checkConnectionSpeed, 10_000); // SignalR ConnectionSlow timeout
    }

    return () => {
      navigator.connection &&
        navigator.connection.removeEventListener(
          "change",
          checkConnectionSpeed
        );
      intervalId && clearInterval(intervalId);
    };
  }, [checkConnectionSpeed]);

  /**
   * TODO: ideally we should be able to use SignalR ConnectionSlow detection, but
   * it doesn't seem to be supported currently on the TS SDK.
   *
   * https://docs.microsoft.com/en-us/aspnet/signalr/overview/guide-to-the-api/handling-connection-lifetime-events
   * https://github.com/dotnet/aspnetcore/blob/main/src/SignalR/clients/ts/signalr/src/HubConnection.ts#L17
   */
}

const estimateNetworkRTT = async (): Promise<number> => {
  // Currently supported only on Chrome and Chromium Edge
  // https://developer.mozilla.org/en-US/docs/Web/API/NetworkInformation
  if (navigator.connection && navigator.connection.rtt) {
    const rttEstimate = navigator.connection.rtt;

    return rttEstimate;
  } else {
    const startTime = new Date().getTime();

    try {
      // The server is not available during a deployment so this request fails
      await getVersion();
      const endTime = new Date().getTime();
      const duration = endTime - startTime;

      return duration;
    } catch {
      return -1;
    }
  }
};

const getNetworkInformation = (): Partial<NetworkInformation> => {
  if (!navigator.connection) {
    return {};
  }

  // These seem to be the actual properties available in Chrome/Edge.
  // They are also read-only and `...navigator.connection` doesn't copy them
  return {
    effectiveType: navigator.connection.effectiveType,
    rtt: navigator.connection.rtt,
    downlink: navigator.connection.downlink,
  };
};
