import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik, FormikConfig } from "formik";
import { object, string } from "yup";

import { Confirmation } from "../../../shared/modals";
import ModalHeader from "../../../shared/modals/ModalHeader";
import InputField from "../../../shared/forms/fields/InputField";
import { useRedemptionDetails } from "../../../licensing/RedemptionDetailsModalTemplate/useRedemptionDetails";
import Redeem from "../../../../icons/Redeem";
import { TemplateProps } from "../../../shared/modals/Modal";
import RedemptionDetailsModalTemplate from "../../../licensing/RedemptionDetailsModalTemplate";

type RedeemForm = {
  Code: string;
};

function RedeemModalTemplate({ modalProps }: TemplateProps): ReactElement {
  const { t } = useTranslation();

  const {
    redemptionDetailsModalProps,
    redemptionDetailsData,
    displayRedemptionDetailsModal,
  } = useRedemptionDetails("Wacom");

  const formikProps: FormikConfig<RedeemForm> = {
    initialValues: {
      Code: "",
    },
    onSubmit: async (values) => {
      await displayRedemptionDetailsModal(values);
    },
    validationSchema: object().shape({
      Code: string().required(t("clientError.required")),
    }),
    validateOnBlur: false,
  };

  return (
    <>
      <Formik {...formikProps}>
        {({ isValid, dirty, handleSubmit }) => (
          <Form>
            {/*todo: provide types for modal-related stuff */}
            <Confirmation
              {...modalProps}
              header={
                <ModalHeader
                  title={t("plans.licenseCodeRedeemHeader")}
                  subTitle={t("plans.licenseCodeRedeemSubHeader")}
                  Icon={Redeem}
                />
              }
              confirmationLabel={t("form.button.redeem")}
              closeOnConfirm={false}
              autoFocusConfirmButton
              onConfirm={handleSubmit}
              confirmationDisabled={!isValid || !dirty}
            >
              <InputField
                required
                name="Code"
                placeholder={t("plans.licenseCodePlaceholder")}
              />
            </Confirmation>
          </Form>
        )}
      </Formik>

      {redemptionDetailsData && (
        <RedemptionDetailsModalTemplate
          modalProps={redemptionDetailsModalProps}
          {...redemptionDetailsData}
        />
      )}
    </>
  );
}

export default RedeemModalTemplate;
