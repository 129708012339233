import React, { useCallback, useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Box } from "theme-ui";
import PropTypes from "prop-types";

import throttle from "../../../../../tools/throttle";
import { findScrollableAncestors } from "../../../../../tools/scrollableAncestors";

const BasicDropdownPortal = ({ children, container, isOpen, sx }) => {
  const dropdownRoot = document.getElementById("dropdown-root");

  const [dropdownPosition, setDropdownPosition] = useState({});
  const dropdown = useRef(null);

  const computeDropdownPosition = useCallback(() => {
    const {
      top,
      left,
      height: containerHeight,
      width: containerWidth,
    } = container.getBoundingClientRect();
    const {
      height: dropdownHeight,
      width: dropdownWidth,
    } = dropdown.current.getBoundingClientRect();
    const { innerHeight, innerWidth } = window;
    const padding = 10;

    const dropdownLeft =
      left + dropdownWidth + padding > innerWidth
        ? left + containerWidth - dropdownWidth
        : left;

    const dropdownTop =
      top + containerHeight + dropdownHeight + padding > innerHeight
        ? top - dropdownHeight
        : top + containerHeight;

    return {
      top: dropdownTop,
      left: dropdownLeft,
    };
  }, [container]);

  const updateDropdownPosition = useCallback(() => {
    if (container && isOpen) {
      setDropdownPosition(computeDropdownPosition());
    }
  }, [container, isOpen, setDropdownPosition, computeDropdownPosition]);

  useEffect(() => {
    const scrollableAncestors = findScrollableAncestors(container);
    const throttledUpdate = throttle(() => {
      updateDropdownPosition();
    }, 10);

    updateDropdownPosition();

    window.addEventListener("resize", throttledUpdate, { passive: true });
    scrollableAncestors.forEach((element) => {
      element.addEventListener("scroll", throttledUpdate, { passive: true });
    });

    return () => {
      window.removeEventListener("resize", throttledUpdate);
      scrollableAncestors.forEach((element) => {
        element.removeEventListener("scroll", throttledUpdate);
      });
    };
  }, [container, updateDropdownPosition]);

  const component = (
    <Box
      ref={dropdown}
      sx={{
        ...sx,
        ...dropdownPosition,
      }}
    >
      {children}
    </Box>
  );

  return ReactDOM.createPortal(component, dropdownRoot);
};

BasicDropdownPortal.propTypes = {
  children: PropTypes.node,
  container: PropTypes.object,
  isOpen: PropTypes.bool,
  sx: PropTypes.object,
};
BasicDropdownPortal.defaultProps = {
  children: [],
  container: null,
  isOpen: false,
  sx: {},
};

export default BasicDropdownPortal;
