import React, { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Flex, Image, Link as ThemeLink, Text, useThemeUI } from "theme-ui";
import errorCodes, { errorCodeIds } from "../../api/errorCodes";
import { routes } from "../../const";
import Ghost from "../../icons/Ghost";
import { isDefaultCustomer } from "../../tools/customer";
import {
  isAPIErrorObject,
  isSignalRError,
  isWSAbnormalClosure,
} from "../../tools/errors";
import { parseQueryUrl } from "../../tools/url";
import { ApiLanguage } from "../../types/enum";
import { useLoggedInStatus } from "../authentication/useLoggedInStatus";

export const getNetworkHelpLink = (language: string): string =>
  `https://help.collaboard.app/${
    language === ApiLanguage.german
      ? "de/verbindungsprobleme"
      : "do-you-have-connection-issues"
  }`;

type ErrorDetails = {
  errorCode: errorCodeIds;
  errorType: string;
  errorMessage: string;
};

const useErrorDetails = (
  errorFromState: ErrorHistoryLocationState["error"],
  errorCodeFromQs: number | undefined
): ErrorDetails => {
  const { t } = useTranslation();
  const defaultErrorCode = errorCodeIds.GenericError;
  const defaultErrorType = errorCodes[defaultErrorCode];
  const defaultErrorMessage = t(`apiError.${defaultErrorType}`);

  if (errorFromState) {
    if (isAPIErrorObject(errorFromState)) {
      const { details, response } = errorFromState;

      return {
        errorCode: details.errorCode,
        errorType: details.errorType,
        errorMessage:
          details.errorMessage ||
          t(`apiError.${details.errorType}`, response?.data),
      };
    }

    // Error
    return {
      errorCode: defaultErrorCode,
      errorType: defaultErrorType,
      errorMessage: errorFromState.message || defaultErrorMessage,
    };
  }

  if (errorCodeFromQs) {
    const errorCode = errorCodeFromQs;
    const errorType = errorCodes[errorCode];
    return {
      errorCode,
      errorType,
      errorMessage: t(`apiError.${errorType}`),
    };
  }

  return {
    errorCode: defaultErrorCode,
    errorType: defaultErrorType,
    errorMessage: defaultErrorMessage,
  };
};

const isNetworkIssue = (
  errorFromState: ErrorHistoryLocationState["error"],
  errorCodeFromQs: number | undefined
): boolean => {
  if (errorFromState) {
    if (isAPIErrorObject(errorFromState)) {
      const { details } = errorFromState;

      return details.errorCode === errorCodeIds.NetworkError;
    }

    const { message } = errorFromState;

    const isSignalRTransportError =
      isSignalRError(errorFromState) && /transport/i.test(message);
    const isWebsocketAbnormalError = isWSAbnormalClosure(errorFromState);
    const hasSignalRFailed =
      isSignalRError(errorFromState) && /websocketFailed/.test(message);

    return (
      isSignalRTransportError || isWebsocketAbnormalError || hasSignalRFailed
    );
  }

  if (errorCodeFromQs) {
    return errorCodeFromQs === errorCodeIds.NetworkError;
  }

  return false;
};

function ErrorPage(): ReactElement {
  const history = useHistory();
  const location = useLocation<ErrorHistoryLocationState>();
  const { t, i18n } = useTranslation();
  const { theme } = useThemeUI();

  const { isLoggedIn, logout } = useLoggedInStatus();

  const { code } = parseQueryUrl<ErrorUrlQuery>(location.search);
  const { error: errorFromState } = location.state || {};
  const errorCodeFromQs = code ? parseInt(code) : undefined;

  const { errorCode, errorMessage } = useErrorDetails(
    errorFromState,
    errorCodeFromQs
  );

  const isNetwork = isNetworkIssue(errorFromState, errorCodeFromQs);
  const isMaintenance = errorCode === errorCodeIds.Maintenance;

  const buttonStyles = {
    display: "inline-block",
    backgroundColor: theme.colors?.delicate,
    color: theme.colors?.text,
    p: [4],
    fontSize: [4],
    fontWeight: 700,
    textDecoration: "none",
    my: [2],
    "&:hover": {
      backgroundColor: theme.colors?.secondary,
    },
    width: "calc(var(--gridTile) * 10)",
    "&:visited": {
      color: theme.colors?.text,
    },
  };
  const inverseButtonStyles = {
    ...buttonStyles,
    backgroundColor: theme.colors?.text,
    color: theme.colors?.delicate,
    "&:hover": {
      backgroundColor: undefined,
      text: theme.colors?.primary,
    },
    "&:visited": {
      color: theme.colors?.primary,
    },
  };

  const handleLogin: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.preventDefault();

    logout();
    history.replace(routes.authenticate);
  };

  return (
    <Flex
      sx={{
        backgroundColor: theme.colors?.primary,
        height: "100vh",
        width: "100vw",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: isDefaultCustomer()
          ? "url('/cb-logo-bg.svg')"
          : undefined,
        backgroundPosition: "0 20%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "calc(var(--gridTile) * 10)",
      }}
    >
      <Flex
        sx={{
          flexDirection: "column",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <Image
          as={Ghost}
          sx={{
            width: "calc(var(--gridTile) * 3)",
            height: "auto",
            filter: "none",
          }}
        />
        <Text
          sx={{
            fontSize: "calc(var(--gridTile) * 3)",
            fontWeight: "200",
            marginBottom: "0.3em",
          }}
        >
          {t("clientError.oops")}
        </Text>
        <Text
          as="h2"
          sx={{
            fontWeight: "200",
            color: "var(--textColor)",
            marginBottom: "1rem",
          }}
        >
          {isNetwork ? (
            <Trans i18nKey="clientError.networkIssues" />
          ) : i18n.exists(errorMessage) ? (
            t(errorMessage)
          ) : (
            errorMessage
          )}
        </Text>

        {!isMaintenance && isNetwork && (
          <ThemeLink
            href={getNetworkHelpLink(i18n.resolvedLanguage)}
            target="_blank"
            rel="nofollow noreferrer"
            sx={inverseButtonStyles}
          >
            {t("form.button.getNetworkHelp")}
          </ThemeLink>
        )}

        {!isMaintenance && isLoggedIn && (
          <>
            <Link to={routes.user} component={ThemeLink} sx={buttonStyles}>
              {t("form.button.backToProfile")}
            </Link>
            <Link to={routes.projects} component={ThemeLink} sx={buttonStyles}>
              {t("form.button.backToProjects")}
            </Link>
          </>
        )}

        {!isMaintenance && !isLoggedIn && (
          <ThemeLink
            href={routes.authenticate}
            onClick={handleLogin}
            sx={buttonStyles}
          >
            {t("form.button.login")}
          </ThemeLink>
        )}

        {isMaintenance && (
          <Link
            to={routes.authenticate}
            component={ThemeLink}
            sx={buttonStyles}
          >
            {t("form.button.backToMain")}
          </Link>
        )}
      </Flex>
    </Flex>
  );
}

export default ErrorPage;
