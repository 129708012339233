import axios from "axios";

import { runtimeConfig } from "../tools/runtimeConfig";

const { youtubeApiKey } = runtimeConfig;

const youtubeApiBaseUrl = "https://www.googleapis.com/youtube/v3/";

export type YouTubeVideoData = {
  duration?: string;
  height: number;
  id: string;
  isLive: boolean;
  isUpcoming: boolean;
  src: string;
  title: string;
  width: number;
};

type YouTubeVideoDetails = {
  id: string;
  title: string;
  duration: string;
};

type YouTubeVideoResults = {
  [videoId: string]: YouTubeVideoData;
};

type YouTubeVideo = gapi.client.youtube.SearchResult & {
  id: {
    videoId: string;
  };
};

export const toVideoData = (video: YouTubeVideo): YouTubeVideoData => {
  const videoId = video.id.videoId;
  const snippet = video.snippet;
  const src = `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`;
  return {
    src,
    height: 360,
    width: 480,
    id: videoId,
    title: snippet?.title || "",
    isLive: snippet?.liveBroadcastContent === "live",
    isUpcoming: snippet?.liveBroadcastContent === "upcoming",
  };
};

const toVideoDetails = ({
  id,
  snippet,
  contentDetails,
}: DeepRequired<gapi.client.youtube.Video>): YouTubeVideoDetails => {
  const { duration } = contentDetails;
  const { title } = snippet;
  return {
    id,
    title,
    duration: parseDuration(duration),
  };
};

let cancelToken = axios.CancelToken.source();

const _searchVideos = async (
  searchTerm: string
): Promise<YouTubeVideoResults> => {
  try {
    const response = await axios.get<
      DeepRequired<gapi.client.youtube.SearchListResponse>
    >(
      `${youtubeApiBaseUrl}search?key=${youtubeApiKey}&type=video&q=${searchTerm}&part=snippet&maxResults=20`,
      {
        cancelToken: cancelToken.token,
      }
    );

    return response.data.items.reduce<YouTubeVideoResults>((acc, rawVideo) => {
      const video = toVideoData(rawVideo);
      acc[video.id] = video;
      return acc;
    }, {});
  } catch (error) {
    if (axios.isCancel(error)) {
      return {};
    }
    return Promise.reject(error);
  }
};

export const getVideoDetails = async (
  videoIds: string[] = []
): Promise<YouTubeVideoDetails[]> => {
  const ids = videoIds.join(",");

  try {
    const response = await axios.get<
      DeepRequired<gapi.client.youtube.VideoListResponse>
    >(
      `${youtubeApiBaseUrl}videos?key=${youtubeApiKey}&id=${ids}&part=contentDetails,snippet`,
      {
        cancelToken: cancelToken.token,
      }
    );

    return response.data.items.map(toVideoDetails) ?? [];
  } catch (error) {
    if (axios.isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};

export const searchVideos = async (
  searchTerm: string
): Promise<YouTubeVideoData[]> => {
  cancelToken.cancel();
  cancelToken = axios.CancelToken.source();

  const videos = await _searchVideos(searchTerm);
  const videoIds = Object.keys(videos);
  const videoDetails = await getVideoDetails(videoIds);

  return videoDetails.map((video) => ({
    ...video,
    ...videos[video.id],
  }));
};

/**
 * https://unpkg.com/youtube-duration-format@0.2.0/index.js
 */
const parseDuration = (PT: string): string => {
  const output = [];
  let durationInSec = 0;
  const matches = PT.match(
    /P(?:(\d*)Y)?(?:(\d*)M)?(?:(\d*)W)?(?:(\d*)D)?T?(?:(\d*)H)?(?:(\d*)M)?(?:(\d*)S)?/i
  );

  if (!matches) {
    return "0:0";
  }

  const parts = [
    {
      // years
      pos: 1,
      multiplier: 86400 * 365,
    },
    {
      // months
      pos: 2,
      multiplier: 86400 * 30,
    },
    {
      // weeks
      pos: 3,
      multiplier: 604800,
    },
    {
      // days
      pos: 4,
      multiplier: 86400,
    },
    {
      // hours
      pos: 5,
      multiplier: 3600,
    },
    {
      // minutes
      pos: 6,
      multiplier: 60,
    },
    {
      // seconds
      pos: 7,
      multiplier: 1,
    },
  ];

  for (let i = 0; i < parts.length; i++) {
    if (typeof matches[parts[i].pos] != "undefined") {
      durationInSec += parseInt(matches[parts[i].pos]) * parts[i].multiplier;
    }
  }

  // Hours extraction
  if (durationInSec > 3599) {
    output.push(parseInt(String(durationInSec / 3600)));
    durationInSec %= 3600;
  }
  // Minutes extraction with leading zero
  output.push(("0" + parseInt(String(durationInSec / 60))).slice(-2));
  // Seconds extraction with leading zero
  output.push(("0" + (durationInSec % 60)).slice(-2));

  return output.join(":");
};
