import { isNil } from "ramda";
import React, { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Link,
  Text,
  ThemeUIStyleObject,
} from "theme-ui";
import { useParticipatingProjects } from "../../../../api/useRequest";
import {
  contactPageUrl,
  maxProjectCountInFreePlan,
  ProductTypeIDs,
} from "../../../../const";
import CancelSubscription from "../../../../icons/CancelSubscription";
import { selectSubscriptionDetails } from "../../../../reduxStore/subscriptionDetails/subscriptionDetails.reducer";
import { useUISettings } from "../../../../reduxStore/uiSettings";
import { getCustomerConfiguration } from "../../../../tools/customer";
import {
  dynamicFeatureConfig,
  dynamicFeatureFlags,
} from "../../../../tools/flags";
import { subscriptionStatuses } from "../../../licensing/payment/enums";
import { getFreeTrialLink } from "../consts";

const { compactPlansPage } = getCustomerConfiguration();

const LinkButton = Button as (
  props: ButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
) => ReactElement;

const getAvailableProjectCount = (projectCount: number): number => {
  if (projectCount > maxProjectCountInFreePlan) {
    return 0;
  }

  return maxProjectCountInFreePlan - projectCount;
};

const getBottomInfoStyle = (isActive: boolean): ThemeUIStyleObject => ({
  borderTop: "1px solid",
  borderColor: "background",
  py: [4],
  "&:first-of-type": {
    border: "none",
  },
  fontWeight: 500,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: isActive ? "background" : "var(--textColor)",
});

export type PlanDetailsActions = {
  openIndividualLicenseOrderModal?: () => void; // Doesn't seem to be used
  openTeamLicenseOrderModal?: () => void; // Doesn't seem to be used
  openCancelSubscriptionModal: () => void;
  onResumeSubscription: () => void;
  onBuy?: (productTypeId: ProductTypeIDs) => void;
};

type Props = {
  data: Plan;
  activePlan: ActivePlan;
  plans: Record<PlanNames, Plan>;
  isActive: boolean;
  horizontal?: boolean;
  actions: PlanDetailsActions;
  sx: ThemeUIStyleObject;
};

function PlanDetails({
  sx,
  data,
  isActive,
  horizontal,
  actions: { openCancelSubscriptionModal, onResumeSubscription, onBuy },
  activePlan,
  plans,
}: Props): ReactElement | null {
  const {
    subscription,
    summary: { userCount, maxUsers, nextBillingDate } = {},
  } = useSelector(selectSubscriptionDetails);
  const {
    isDynamicFeatureActive,
    getTranslation,
    getDynamicFeatureConfig,
  } = useUISettings();

  const enterpriseLicenseRequestUrl =
    getDynamicFeatureConfig<string>(
      dynamicFeatureConfig.ENTERPRISE_LICENSE_REQUEST_URL
    ) ?? "";

  const isEcommerceFeatureActive = !isDynamicFeatureActive(
    dynamicFeatureFlags.NO_ECOMMERCE
  );

  const bottomInfoStyle = getBottomInfoStyle(isActive);

  const { t, i18n } = useTranslation();
  const isFree = data.productTypeId === null;
  const isTeam = data.productTypeId === ProductTypeIDs.team;

  const { results: projects } = useParticipatingProjects({
    enabled: true,
    spaceId: null,
  });

  return (
    <Flex
      sx={{
        ...sx,
        ...containerStyle,
        py: compactPlansPage ? [0] : undefined,
        borderBottom: horizontal ? "1px solid" : "none",
      }}
    >
      {isActive && !isFree && (
        <Flex sx={{ flexDirection: "column" }}>
          {isActive && isTeam && (
            <Text sx={bottomInfoStyle} aria-label="availableMemberSlots">
              {t("plans.membershipLicenses")}: {userCount} / {maxUsers}
            </Text>
          )}

          {subscription?.ActiveTo && (
            <Text sx={bottomInfoStyle} aria-label="expirationDate">
              {t("plans.expirationDate", {
                date: new Date(subscription.ActiveTo),
              })}
            </Text>
          )}

          {nextBillingDate &&
            subscription?.Status === subscriptionStatuses.active && (
              <Text
                sx={{ ...bottomInfoStyle, py: [1] }}
                aria-label="nextBillingDate"
              >
                {t("plans.nextBillingDate", {
                  date: new Date(nextBillingDate),
                })}
                <Button
                  id={`plan-cancel-${data.productTypeId}`}
                  onClick={openCancelSubscriptionModal}
                  variant="buttons.content"
                  title={t("plans.cancelSubscription")}
                  sx={cancelButtonStyle}
                >
                  <CancelSubscription noShadow />
                </Button>
              </Text>
            )}

          {subscription?.Status === subscriptionStatuses.stopped && (
            <Button
              id={`plan-resume-${data.productTypeId}`}
              onClick={onResumeSubscription}
              variant="buttons.inverted"
              aria-label="resumeBilling"
            >
              {t("plans.resumeBilling")}
            </Button>
          )}
        </Flex>
      )}

      {isActive && isFree && (
        <Flex sx={{ flexDirection: "column" }} id="available-projects-wrapper">
          <Text sx={bottomInfoStyle} aria-label="availableProjects">
            {t("plans.availableProjectsCount")}:{" "}
            {getAvailableProjectCount(projects?.length)}/
            {maxProjectCountInFreePlan}
          </Text>
        </Flex>
      )}

      {!isActive &&
        activePlan.name === plans.free.name &&
        data.hasPrice &&
        isEcommerceFeatureActive && (
          <>
            <Button
              sx={ctaButtonStyle}
              onClick={() => {
                onBuy &&
                  !isNil(data.productTypeId) &&
                  onBuy(data.productTypeId);
              }}
              id={`plan-buy-${data.productTypeId}`}
            >
              {t("plans.buy", { planName: t(data.nameKey) })}
            </Button>
            {data.productTypeId === ProductTypeIDs.business ? (
              <LinkButton
                as="a"
                href={getFreeTrialLink(i18n.resolvedLanguage)}
                target="_blank"
                rel="nofollow noreferrer"
                variant="link"
                sx={{ mt: [4] }}
              >
                {t("plans.freeTrial")}
              </LinkButton>
            ) : (
              <p></p> // Empty line to align texts vertically between plans
            )}
          </>
        )}

      {!isActive && data.name === plans.enterprise.name && !compactPlansPage && (
        <LinkButton
          as="a"
          href={contactPageUrl}
          target="_blank"
          rel="nofollow noreferrer"
          sx={ctaButtonStyle}
        >
          {t("plans.contactUsShort")}
        </LinkButton>
      )}

      {!isActive && (
        <Text sx={infoStyle} aria-label="planInfo">
          <Trans defaults={getTranslation(data.infoKey)}>
            <Link href={enterpriseLicenseRequestUrl} target="_blank" />
          </Trans>
        </Text>
      )}

      <Box
        sx={{
          mt: [4],
          borderBottom: horizontal ? "none" : "1px solid",
          borderColor: "primary",
        }}
      />
    </Flex>
  );
}

const containerStyle: ThemeUIStyleObject = {
  flexDirection: "column",
  borderRight: "1px solid",
  borderLeft: "1px solid",
  borderColor: "primary",
};

const ctaButtonStyle: ThemeUIStyleObject = { flex: 0, fontSize: "20px" };

const cancelButtonStyle: ThemeUIStyleObject = {
  svg: {
    fill: "background",
    "&:hover": {
      fill: "yellow",
    },
  },
};

const infoStyle: ThemeUIStyleObject = {
  flex: 1,
  margin: "0.25rem",
  textAlign: "center",
  mt: [5],
  my: compactPlansPage ? [6] : undefined,
  fontWeight: compactPlansPage ? "bold" : undefined,
};

export default PlanDetails;
