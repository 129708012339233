import { all, Effect } from "redux-saga/effects";
import { presentationPresenterSaga } from "./presentation.presenter.saga";
import { presentationViewerSaga } from "./presentation.viewer.saga";
import { signalRPresentationSaga } from "./signalR-presentation.saga";

export function* presentationSaga(): Generator<Effect> {
  yield all([
    presentationPresenterSaga(),
    presentationViewerSaga(),
    signalRPresentationSaga(),
  ]);
}
