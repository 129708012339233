import { fabric } from "fabric";
import i18n from "i18next";
import { toast } from "react-toastify";
import { errorToast } from "../../../tools/errorToast";
import { runtimeConfig } from "../../../tools/runtimeConfig";
import {
  isFreeFormText,
  isShapeText,
  isStickyNote,
} from "../../utils/fabricObjects";

(() => {
  const originalOnInput = fabric.IText.prototype.onInput;

  fabric.util.object.extend(fabric.Object.prototype, {
    isEditingMode() {
      return this.isEditing || false;
    },
  });

  fabric.CustomEditing = {
    disposingHookForSyncChangesStop: false,
    syncInterval: 2000,
    previousText: "",
    textLimit: 10000,

    /**
     * Handles input (typing / pasting) into sticky note.
     *
     * Modified to add support for auto font size logic.
     *
     * @override
     */
    onInput(this: ICustomEditing, e) {
      const {
        hiddenTextarea,
        selectionStart,
        selectionEnd,
        fromPaste,
        text: currentText = "",
        fontSize: currentFontSize,
        maxFontSize: currentMaxFontSize,
        canvas,
      } = this;

      if (!hiddenTextarea || !canvas) {
        return;
      }

      if (!canvas?.contextTop && !canvas?.contextContainer) {
        /**
         * Bug: #4547 - Cannot read property 'clearRect' of null
         * Sometimes this event handler will be called when there is no context
         * and clearContext() results in a runtime error.
         * This appears to be the case when the user leaves the project.
         */
        return;
      }

      // Apply the changes to the text area
      this.handleBorderOnUserActivity();
      originalOnInput.call(this, e);
      this.styles = {};

      if (isStickyNote(this)) {
        if (this.isAutoFontSize) {
          this.setMaxAutoFontSize();
          // Reflow text after changing font size
          originalOnInput.call(this, e);
        } else {
          // Only re-calculate the max font size
          this.setMaxManualFontSize();
        }
      }

      if (this.isTextOverflow()) {
        const handleManualOverflow = () => {
          /**
           * @NOTE if any object would try to calculate precise availableChars
           * based on fontSize, this calculation should be moved to a point
           * where `text, fontSize, __lineHeights, __lineWidths` are accurate.
           */
          const {
            desiredChars,
            availableChars,
          } = this.calculateTextOverflowProps(this.text || "");

          // Do not use set() - it will trigger internal Fabric processing
          this.text = currentText;
          this.fontSize = currentFontSize;
          this.maxFontSize = currentMaxFontSize;
          this.dirty = true;

          hiddenTextarea.value = currentText;
          if (
            typeof selectionStart === "number" &&
            typeof selectionEnd === "number"
          ) {
            hiddenTextarea.setSelectionRange(selectionStart, selectionEnd);
          }
          this.selectionStart = selectionStart;
          this.selectionEnd = selectionEnd;

          if (fromPaste) {
            const message =
              desiredChars > availableChars
                ? i18n.t("propsReject.availableChars", { availableChars })
                : i18n.t("propsReject.paste");

            errorToast(message);
          }
        };

        if (isStickyNote(this) && !this.isAutoFontSize) {
          this.isAutoFontSize = true;
          this.setMaxAutoFontSize();
          // Reflow text after changing font size
          originalOnInput.call(this, e);

          // Check again for text overflow with new auto font size
          if (this.isTextOverflow()) {
            handleManualOverflow();
            this.setMaxManualFontSize();
          } else {
            toast(i18n.t("propsReject.autoFontSize"));
          }
        } else {
          handleManualOverflow();
        }
      }

      this.queueSync();
    },

    handleBorderOnUserActivity(this: ICustomEditing) {
      this.trigger("custom:transform:begin");

      if (isShapeText(this)) {
        return;
      }

      const { timerForEditableObjectBorder = 0 } = runtimeConfig;
      if (timerForEditableObjectBorder === 0) {
        return;
      }

      this.onInputTimerId && clearTimeout(this.onInputTimerId);
      this.onInputTimerId = window.setTimeout(() => {
        // this timer can still be called on previous active object
        // important especially in  transition between sticky|text object -> shape text
        if (this.canvas?._activeObject?.uuid !== this.uuid) {
          return;
        }
        this.trigger("custom:transform:end");
      }, timerForEditableObjectBorder);
    },

    exitEditing(this: ICustomEditing, isSilent?: boolean) {
      if (!this.isEditing) {
        return;
      }

      this.callSuper("exitEditing");

      // An exit sync is triggered by the "text:editing:exited" event.
      // No need to trigger one here.
      this.cancelQueuedSync();

      if (!isSilent) {
        this.trigger("custom:transform:end");
      } else {
        // By default Fabric shows the controls when zooming. Disable this.
        this.hasControls = false;
      }

      this.canvas?.setThemeCursors(this.canvas?.theme);

      //  delete the freeFormText if contains no text or only whitespaces
      if (isFreeFormText(this) && !this.text?.trim()) {
        this.canvas?.removeSelectedObjects();
      }
    },

    enterEditing(this: ICustomEditing, event: fabric.ModifiedEvent) {
      const { isEditing, canvas } = this;
      if (
        this.isLocked() ||
        isEditing ||
        !canvas ||
        canvas.isViewOnlyMode ||
        this.isReadOnly()
      ) {
        return;
      }
      this.callSuper("enterEditing", event);

      // Prepare for sync
      this.previousText = this.text;
      this.beginTransform();

      if (this.lastSelection) {
        this.setSelectionEnd(this.lastSelection.end);
        this.setSelectionStart(this.lastSelection.start);
        delete this.lastSelection;
      } else {
        this.selectAll();
      }
      this.handleBorderOnUserActivity();
      canvas.setThemeCursors(canvas.theme);
    },

    queueSync(this: ICustomEditing) {
      this.cancelQueuedSync();

      if (!this.disposingHookForSyncChangesStop) {
        this.canvas?.disposingHook.then(() => this.cancelQueuedSync());
        this.disposingHookForSyncChangesStop = true;
      }

      this.queuedSyncTimeout = window.setTimeout(
        () => this.performSync(),
        this.syncInterval
      );
    },

    cancelQueuedSync(this: ICustomEditing) {
      clearTimeout(this.queuedSyncTimeout);
    },

    performSync(this: ICustomEditing) {
      if (this.previousText === this.text) {
        return;
      }

      if (this.canvas) {
        this.commitTransform({ isContextTransform: false });
        this.beginTransform();
      } else {
        this.cancelTransform();
      }

      this.previousText = this.text;
    },

    /**
     * Custom pre-zoom handler. Called when a zoom action, e.g. mouse wheel, starts.
     */
    _preZoom(this: ICustomEditing) {
      this.wasEditing = this.isEditingMode();
      if (this.wasEditing) {
        this.saveEditing();
      }
    },

    /**
     * Custom post-zoom handler. Called when a zoom action, e.g. mouse wheel, finishes.
     */
    _postZoom(this: ICustomEditing) {
      if (this.wasEditing && !this.canvas?.isViewOnlyMode) {
        this.restoreEditing();
      }
    },

    saveEditing(this: ICustomEditing) {
      this.wasEditing = true;
      this.prevSelectionStart = this.selectionStart;
      this.prevSelectionEnd = this.selectionEnd;
      this.exitEditing(true);
    },

    restoreEditing(this: ICustomEditing) {
      // Sticky notes and freeFormText can just enter editing, whereas shapes need to call `enterEditMode`
      // so that the shapeInnerTextBox is correctly positioned and selected
      isShapeText(this)
        ? this.shape.enterEditMode({ target: this.shape })
        : this.enterEditing();
      this.wasEditing = false;

      if (
        this.hiddenTextarea &&
        this.prevSelectionStart &&
        this.prevSelectionEnd
      ) {
        this.selectionStart = this.prevSelectionStart;
        this.selectionEnd = this.prevSelectionEnd;
        this._updateTextarea();
      }

      delete this.prevSelectionStart;
      delete this.prevSelectionEnd;
    },

    /**
     * Prevent typing events from reaching the canvas.
     * This is important for keys like DELETE which will delete the object.
     * @param {KeyboardEvent} e Event
     */
    onKeyDown(this: ICustomEditing, e) {
      this.callSuper("onKeyDown", e);
      e.stopPropagation();
    },

    isTextOverflow(this: ICustomEditing) {
      const { length } = this.text || "";
      return length > this.textLimit;
    },

    calculateTextOverflowProps(this: ICustomEditing, text: string) {
      const { length } = text;

      return {
        desiredChars: length,
        availableChars: this.textLimit,
      };
    },
  } as ICustomEditing;
})();
