import {
  all,
  call,
  Effect,
  getContext,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { v4 as uuid } from "uuid";
import {
  sendFinishVotingSession,
  sendVoteCast,
  sendVoteReset,
} from "../../../api";
import { errorCodeIds } from "../../../api/errorCodes";
import { createVotingSessionSummaryObjects } from "../../../studio/components/votingSessionSummary/votingSessionSummary.init";
import {
  isMediaObject,
  isVisibleWhenVoting,
} from "../../../studio/utils/fabricObjects";
import { isAPIError } from "../../../tools/errors";
import { deepFreeze } from "../../../tools/utils";
import { monitorSaga } from "../../redux.utils";
import { resetCanvasModeAction, setCanvasModeAction } from "../app/app.actions";
import { CanvasMode, selectIsVotingMode } from "../app/app.reducer";
import { addedAction } from "../history/history.entry.actions";
import { selectProjectId } from "../project/project.reducer";
import { showSessionToast } from "../timedSession/timedSession.saga";
import { signalRVotingSaga } from "./signalR-voting.saga";
import {
  CastVoteAction,
  CreateVotingSummaryObjectAction,
  finishVotingAction,
  onVotingErrorAction,
  OnVotingErrorAction,
  OnVotingSessionResumedAction,
  OnVotingSessionStartedAction,
  onVotingSessionStoppedAction,
  resetVoteAction,
  ResetVoteAction,
  VotingActionType,
} from "./voting.actions";
import { selectVotingSession, VotingState } from "./voting.reducer";

function* updateObjectsState() {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const isVoting = selectIsVotingMode(yield select());

  if (isVoting) {
    canvas.saveObjectsOrder();
  }

  // Start from the most forwards objects, so that locked objects maintain their
  // relative order when they are sent backward during voting
  canvas
    .getObjects()
    .reverse()
    .forEach((obj) => {
      // Hide objects whose type cannot be voted, locked ones are kept visible
      if (!isVisibleWhenVoting(obj)) {
        obj.visible = !isVoting;
      } else {
        if (obj.isLocked() && isVoting) {
          // Bring locked objects to back
          canvas.sendToBack(obj);
        }
      }

      if (isMediaObject(obj)) {
        obj._closePlayer();
      }
    });

  if (!isVoting) {
    canvas.restoreObjectsOrder();
  }
}

function* updateCanvasState(votingSession: VotingState) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  canvas.votingSessionState = deepFreeze(votingSession); // TODO: #5927 - remove (single source of truth)

  canvas.enableMultiSelectionFlag();
  canvas.updateSkipTargetFind();

  canvas.requestRenderAll();
}

function* onVotingSessionStarted({
  payload,
}: OnVotingSessionStartedAction | OnVotingSessionResumedAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const { session } = payload;

  canvas.trigger("custom:drawing-mode:deactivate");
  canvas.discardActiveObject();

  yield call(updateObjectsState);

  if (session.Owner) {
    yield call(showSessionToast, session.Owner, "votingSession.started");
  }

  yield put(setCanvasModeAction({ canvasMode: CanvasMode.VOTING }));
}

function* onVotingSessionStopped() {
  const state: ApplicationGlobalState = yield select();
  const { ownerUserName } = selectVotingSession(state);

  yield take(VotingActionType.ON_VOTING_SESSION_STOPPED);

  if (ownerUserName) {
    yield call(showSessionToast, ownerUserName, "votingSession.stopped");
  }

  yield call(updateObjectsState);

  yield put(resetCanvasModeAction());
}

function* castVote({ payload }: CastVoteAction) {
  const { uuid, vote } = payload;
  const state: ApplicationGlobalState = yield select();
  const projectId = selectProjectId(state);
  const { sessionId } = selectVotingSession(state);

  if (!sessionId) {
    return;
  }

  if (vote <= 0) {
    yield put(resetVoteAction({ uuid }));
    return;
  }

  try {
    yield call(sendVoteCast, projectId, sessionId, uuid, vote);
  } catch (error: unknown) {
    yield put(onVotingErrorAction({ error }));
  }
}

function* resetVote({ payload }: ResetVoteAction) {
  const { uuid } = payload;
  const state: ApplicationGlobalState = yield select();
  const projectId = selectProjectId(state);
  const { sessionId } = selectVotingSession(state);

  if (!sessionId) {
    return;
  }

  try {
    yield call(sendVoteReset, projectId, sessionId, uuid);
  } catch (error: unknown) {
    yield put(onVotingErrorAction({ error }));
  }
}

function* finishVoting() {
  const state: ApplicationGlobalState = yield select();
  const projectId = selectProjectId(state);
  const { sessionId } = selectVotingSession(state);

  if (!sessionId) {
    return;
  }

  try {
    yield call(sendFinishVotingSession, projectId, sessionId);
  } catch (error: unknown) {
    yield put(onVotingErrorAction({ error }));
  }
}

function* onVotingError({ payload }: OnVotingErrorAction) {
  const { error } = payload;
  if (isAPIError(error)) {
    const state: ApplicationGlobalState = yield select();
    const { sessionId } = selectVotingSession(state);

    switch (error.details.errorCode) {
      case errorCodeIds.ProjectNotInVotingMode: {
        if (sessionId) {
          yield put(
            onVotingSessionStoppedAction({
              session: {
                SessionId: sessionId,
                IsInvited: false,
              },
            })
          );
        }
        break;
      }
      case errorCodeIds.UserAlreadyStoppedVotingSession:
      case errorCodeIds.UserNotInVoteSession: {
        yield put(finishVotingAction());
        break;
      }
    }
  }
  /** @TODO - add more logging here? */
}

function* createVotingSummaryObject({
  payload,
}: CreateVotingSummaryObjectAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const { summary } = payload;
  const { MostVotedTileId } = summary;
  const { viewportTransform, width } = canvas;
  const [zoom] = viewportTransform;
  const hiResThumbnailPromise = canvas.toThumbnail([MostVotedTileId], {
    size: 1024,
    withDefaultAspectRatio: true,
  });

  // add summary object to the left of the modal
  const groupPosition = canvas
    .getVpCenter()
    .add(new fabric.Point(width / zoom / 3, 0));

  // - add objects to the canvas first, so the thumbnail upload is triggered
  // - then group these objects
  // - then correct position / scale of the objects

  const { summaryGroup, summaryObjects } = yield call(
    createVotingSessionSummaryObjects,
    {
      votingSession: summary,
      thumbnailPromise: hiResThumbnailPromise,
      groupPosition,
      toSummaryGroup: async (summaryObjects) => {
        canvas.add(...summaryObjects);
        const group = new fabric.ActiveSelection(summaryObjects, {
          canvas: canvas as fabric.Canvas,
        }).toGroup({
          uuid: uuid(),
          top: groupPosition.y,
          left: groupPosition.x,
        });

        canvas.add(group);
        canvas.setActiveObject(group, { isSilent: true });

        return group;
      },
    }
  );

  yield put(addedAction([summaryGroup, ...summaryObjects]));

  canvas.requestRenderAll();
}

export function* votingSaga(): Generator<Effect> {
  yield all([
    signalRVotingSaga(),
    onVotingSessionStopped(),
    takeEvery(VotingActionType.CAST_VOTE, castVote),
    takeEvery(VotingActionType.RESET_VOTE, resetVote),
    takeEvery(VotingActionType.FINISH_VOTING, finishVoting),
    takeEvery(
      VotingActionType.CREATE_VOTING_SUMMARY_OBJECT,
      createVotingSummaryObject
    ),
    takeLatest(
      VotingActionType.ON_VOTING_SESSION_STARTED,
      onVotingSessionStarted
    ),
    takeLatest(
      VotingActionType.ON_VOTING_SESSION_RESUMED,
      onVotingSessionStarted
    ),
    takeLatest(
      [
        VotingActionType.ON_VOTING_SESSION_STARTED,
        VotingActionType.ON_VOTING_SESSION_RESUMED,
      ],
      onVotingSessionStopped
    ),
    takeEvery(VotingActionType.ON_VOTING_ERROR, onVotingError),
    monitorSaga(
      selectVotingSession,
      updateCanvasState,
      Object.values(VotingActionType)
    ),
  ]);
}
