import React, { ReactElement } from "react";

function Tagline(props: TaglineProps): ReactElement {
  return (
    <svg viewBox="0 0 83.220001 33.299999" {...props}>
      <g transform="translate(-65.62,-7.22)">
        <g>
          <path d="m 65.93,7.39 h 9.85 l 0.24,5.48 H 75.45 C 75.08,9.65 74.1,8.04 72.52,8.04 72.2,8.04 71.98,8.11 71.87,8.24 71.76,8.37 71.7,8.58 71.7,8.88 v 11.59 c 0,0.24 0.06,0.41 0.17,0.52 0.11,0.11 0.3,0.17 0.57,0.17 h 1.22 v 0.74 h -5.64 v -0.74 h 1.39 c 0.19,0 0.33,-0.06 0.43,-0.17 0.1,-0.11 0.15,-0.26 0.15,-0.44 V 8.79 C 69.99,8.54 69.93,8.35 69.8,8.23 69.67,8.1 69.47,8.04 69.19,8.04 c -0.79,0 -1.45,0.45 -1.99,1.34 -0.44,0.74 -0.75,1.9 -0.94,3.49 h -0.6 z" />
          <path d="m 82.61,17.36 h -5.5 c 0.03,1.4 0.25,2.39 0.66,2.96 0.47,0.65 1.07,0.97 1.8,0.97 1.07,0 1.93,-0.73 2.58,-2.19 l 0.53,0.27 c -0.8,1.86 -1.95,2.78 -3.46,2.78 -1.03,0 -1.89,-0.44 -2.6,-1.33 -0.71,-0.89 -1.05,-2.03 -1.05,-3.41 0,-1.49 0.35,-2.69 1.04,-3.61 0.69,-0.92 1.54,-1.38 2.56,-1.38 0.74,0 1.39,0.23 1.93,0.69 0.54,0.46 0.95,1.11 1.22,1.97 0.18,0.6 0.28,1.36 0.29,2.28 z m -5.5,-0.7 h 3.33 c 0.2,0 0.35,-0.08 0.46,-0.24 0.11,-0.16 0.16,-0.43 0.16,-0.82 0,-0.72 -0.19,-1.33 -0.56,-1.83 -0.38,-0.5 -0.82,-0.75 -1.32,-0.75 -0.57,0 -1.05,0.3 -1.45,0.91 -0.4,0.6 -0.6,1.52 -0.62,2.73 z" />
          <path d="m 91.92,20.88 0.29,0.55 c -0.4,0.49 -0.84,0.73 -1.33,0.73 -0.37,0 -0.68,-0.12 -0.94,-0.36 -0.25,-0.24 -0.48,-0.67 -0.67,-1.27 -0.52,0.65 -0.98,1.09 -1.37,1.31 -0.39,0.22 -0.87,0.33 -1.42,0.33 -0.79,0 -1.39,-0.2 -1.8,-0.61 -0.41,-0.4 -0.62,-0.92 -0.62,-1.56 0,-0.95 0.47,-1.75 1.41,-2.38 0.94,-0.63 2.19,-1.05 3.74,-1.26 v -1.39 c 0,-0.5 -0.17,-0.92 -0.5,-1.27 -0.33,-0.35 -0.76,-0.53 -1.27,-0.53 -0.48,0 -0.89,0.12 -1.23,0.35 -0.25,0.18 -0.37,0.35 -0.37,0.52 0,0.1 0.07,0.25 0.2,0.46 0.16,0.26 0.24,0.49 0.24,0.7 0,0.29 -0.08,0.53 -0.24,0.72 -0.16,0.19 -0.37,0.28 -0.62,0.28 -0.27,0 -0.5,-0.11 -0.68,-0.33 -0.18,-0.22 -0.28,-0.51 -0.28,-0.85 0,-0.65 0.3,-1.24 0.92,-1.77 0.61,-0.54 1.41,-0.8 2.4,-0.8 0.91,0 1.61,0.23 2.09,0.71 0.48,0.48 0.72,1.06 0.72,1.73 v 5.02 c 0,0.44 0.06,0.77 0.18,0.98 0.12,0.22 0.28,0.32 0.47,0.32 0.22,-0.02 0.45,-0.12 0.68,-0.33 z M 89.23,17 c -1.39,0.23 -2.37,0.6 -2.95,1.12 -0.43,0.39 -0.65,0.95 -0.65,1.67 0,0.5 0.11,0.9 0.34,1.18 0.22,0.28 0.52,0.43 0.88,0.43 0.64,0 1.2,-0.29 1.68,-0.88 0.48,-0.59 0.71,-1.32 0.71,-2.2 V 17 Z" />
          <path d="m 93.62,12.7 2.2,-0.17 c 0.12,0.68 0.2,1.33 0.24,1.95 0.42,-0.72 0.83,-1.22 1.25,-1.51 0.42,-0.29 0.87,-0.44 1.37,-0.44 0.53,0 0.97,0.17 1.31,0.5 0.35,0.33 0.61,0.87 0.8,1.62 0.37,-0.71 0.77,-1.24 1.22,-1.59 0.45,-0.35 0.94,-0.53 1.47,-0.53 0.7,0 1.22,0.25 1.57,0.75 0.45,0.65 0.67,1.5 0.67,2.53 v 4.56 c 0,0.27 0.06,0.46 0.17,0.56 0.15,0.15 0.38,0.23 0.68,0.23 h 0.39 v 0.74 h -3.86 v -0.74 h 0.39 c 0.33,0 0.56,-0.06 0.69,-0.2 0.13,-0.13 0.2,-0.33 0.2,-0.59 V 15.8 c 0,-0.68 -0.14,-1.21 -0.42,-1.58 -0.28,-0.37 -0.64,-0.56 -1.1,-0.56 -0.34,0 -0.65,0.11 -0.93,0.33 -0.28,0.22 -0.5,0.53 -0.66,0.94 -0.23,0.58 -0.35,1.08 -0.35,1.48 v 3.96 c 0,0.27 0.06,0.47 0.18,0.59 0.12,0.13 0.31,0.19 0.56,0.19 h 0.53 v 0.74 h -3.92 v -0.74 h 0.44 c 0.28,0 0.49,-0.06 0.62,-0.2 0.14,-0.13 0.21,-0.3 0.21,-0.5 v -4.23 c 0,-0.9 -0.13,-1.55 -0.4,-1.95 -0.26,-0.4 -0.62,-0.61 -1.07,-0.61 -0.58,0 -1.03,0.24 -1.34,0.71 -0.44,0.67 -0.66,1.44 -0.66,2.33 v 3.67 c 0,0.28 0.05,0.47 0.16,0.58 0.15,0.14 0.38,0.21 0.69,0.21 h 0.39 v 0.74 h -3.87 v -0.74 h 0.58 c 0.22,0 0.39,-0.06 0.5,-0.2 0.12,-0.13 0.18,-0.3 0.18,-0.5 v -6.02 c 0,-0.33 -0.07,-0.58 -0.21,-0.75 -0.14,-0.16 -0.43,-0.27 -0.85,-0.31 V 12.7 Z" />
          <path d="m 107.97,12.7 h 3.37 v 0.68 h -0.44 c -0.22,0 -0.37,0.04 -0.47,0.12 -0.09,0.08 -0.14,0.19 -0.14,0.33 0,0.14 0.03,0.3 0.08,0.46 l 1.73,5.19 1.5,-4.93 c -0.21,-0.53 -0.39,-0.85 -0.55,-0.98 -0.16,-0.13 -0.44,-0.19 -0.84,-0.19 V 12.7 h 3.71 v 0.68 h -0.31 c -0.29,0 -0.48,0.04 -0.59,0.13 -0.11,0.09 -0.16,0.21 -0.16,0.37 0,0.12 0.03,0.25 0.07,0.41 l 1.75,5.19 1.38,-4.62 c 0.09,-0.31 0.14,-0.57 0.14,-0.78 0,-0.21 -0.07,-0.38 -0.21,-0.5 -0.14,-0.12 -0.41,-0.19 -0.8,-0.21 V 12.7 h 2.99 v 0.68 c -0.37,0.07 -0.65,0.21 -0.85,0.42 -0.19,0.21 -0.35,0.52 -0.48,0.94 l -2.23,7.42 h -0.48 l -2.16,-6.42 -1.93,6.42 h -0.47 l -2.76,-8.12 c -0.09,-0.25 -0.19,-0.43 -0.3,-0.51 -0.11,-0.08 -0.29,-0.14 -0.56,-0.15 V 12.7 Z" />
          <path d="m 124.77,12.43 c 1.03,0 1.9,0.45 2.6,1.34 0.7,0.89 1.05,2.07 1.05,3.52 0,1.01 -0.16,1.86 -0.47,2.57 -0.32,0.71 -0.76,1.27 -1.35,1.68 -0.58,0.41 -1.21,0.62 -1.88,0.62 -1,0 -1.84,-0.45 -2.53,-1.35 -0.69,-0.9 -1.04,-2.07 -1.04,-3.51 0,-1.44 0.35,-2.62 1.04,-3.52 0.71,-0.9 1.57,-1.35 2.58,-1.35 z m 0,0.69 c -0.61,0 -1.12,0.33 -1.51,0.99 -0.39,0.66 -0.59,1.71 -0.59,3.14 0,1.46 0.2,2.52 0.59,3.2 0.39,0.67 0.9,1.01 1.51,1.01 0.64,0 1.16,-0.34 1.55,-1.01 0.4,-0.67 0.59,-1.77 0.59,-3.3 0,-1.42 -0.2,-2.44 -0.59,-3.08 -0.37,-0.63 -0.89,-0.95 -1.55,-0.95 z" />
          <path d="m 129.43,12.7 2.76,-0.17 v 3.71 c 0.29,-1.22 0.65,-2.15 1.07,-2.77 0.42,-0.63 0.93,-0.94 1.54,-0.94 0.42,0 0.75,0.15 1,0.46 0.25,0.3 0.37,0.71 0.37,1.22 0,0.41 -0.08,0.73 -0.25,0.96 -0.17,0.23 -0.38,0.34 -0.63,0.34 -0.25,0 -0.44,-0.1 -0.59,-0.29 -0.15,-0.2 -0.22,-0.46 -0.22,-0.79 0,-0.22 0.04,-0.42 0.13,-0.61 0.08,-0.17 0.12,-0.27 0.12,-0.3 0,-0.1 -0.05,-0.15 -0.16,-0.15 -0.22,0 -0.48,0.16 -0.76,0.47 -0.39,0.44 -0.7,1.04 -0.91,1.8 -0.33,1.19 -0.49,2.14 -0.49,2.85 v 1.97 c 0,0.22 0.07,0.39 0.2,0.51 0.13,0.12 0.36,0.18 0.67,0.18 h 0.98 v 0.74 h -4.77 v -0.74 h 0.76 c 0.26,0 0.45,-0.06 0.58,-0.18 0.13,-0.12 0.2,-0.27 0.2,-0.44 v -5.85 c 0,-0.44 -0.08,-0.77 -0.24,-0.98 -0.16,-0.21 -0.39,-0.31 -0.68,-0.31 h -0.67 V 12.7 Z" />
          <path d="m 136.87,7.39 2.94,-0.17 v 10.03 l 2.5,-2.6 c 0.24,-0.26 0.36,-0.48 0.36,-0.67 0,-0.16 -0.07,-0.29 -0.21,-0.38 -0.21,-0.13 -0.56,-0.2 -1.04,-0.21 V 12.7 h 4.09 v 0.69 c -0.81,0.05 -1.43,0.31 -1.85,0.76 l -1.77,1.87 2.87,4.71 c 0.17,0.28 0.48,0.42 0.91,0.42 h 0.43 v 0.74 h -4.2 v -0.74 h 0.42 c 0.31,0 0.52,-0.03 0.63,-0.1 0.11,-0.07 0.16,-0.14 0.16,-0.23 0,-0.09 -0.03,-0.18 -0.08,-0.27 l -2.09,-3.5 -1.12,1.18 v 2.32 c 0,0.19 0.05,0.33 0.16,0.42 0.15,0.12 0.35,0.19 0.62,0.19 h 0.53 v 0.74 h -4.12 v -0.74 h 0.66 c 0.27,0 0.48,-0.07 0.61,-0.21 0.13,-0.14 0.2,-0.32 0.2,-0.55 V 9.06 c 0,-0.29 -0.08,-0.51 -0.24,-0.65 -0.23,-0.2 -0.54,-0.29 -0.93,-0.29 h -0.42 V 7.39 Z" />
          <path d="m 65.62,25.75 h 6.27 c 1.21,0 2.16,0.33 2.83,0.99 0.67,0.66 1,1.53 1,2.6 0,0.64 -0.13,1.21 -0.4,1.71 -0.27,0.5 -0.65,0.9 -1.15,1.2 -0.5,0.3 -1.29,0.51 -2.36,0.64 0.78,0.25 1.32,0.5 1.61,0.75 0.29,0.25 0.53,0.56 0.71,0.95 0.18,0.39 0.38,1.18 0.61,2.38 0.17,0.86 0.34,1.44 0.53,1.75 0.14,0.24 0.3,0.36 0.46,0.36 0.17,0 0.33,-0.15 0.47,-0.45 0.14,-0.3 0.23,-0.82 0.27,-1.57 h 0.56 c -0.1,2.22 -0.79,3.33 -2.06,3.33 -0.4,0 -0.75,-0.12 -1.04,-0.36 -0.29,-0.24 -0.51,-0.6 -0.66,-1.07 -0.11,-0.35 -0.24,-1.28 -0.39,-2.77 -0.08,-0.87 -0.21,-1.49 -0.39,-1.85 -0.18,-0.37 -0.46,-0.66 -0.83,-0.88 -0.38,-0.22 -0.83,-0.33 -1.35,-0.33 h -0.99 v 5.69 c 0,0.24 0.05,0.41 0.16,0.51 0.13,0.12 0.34,0.19 0.62,0.19 h 1.16 v 0.74 h -5.63 v -0.74 h 1.19 c 0.26,0 0.46,-0.06 0.58,-0.19 0.12,-0.12 0.18,-0.29 0.18,-0.51 V 27.23 c 0,-0.25 -0.06,-0.43 -0.19,-0.56 -0.13,-0.12 -0.32,-0.19 -0.57,-0.19 h -1.19 v -0.73 z m 3.7,6.66 h 1.44 c 0.96,0 1.72,-0.22 2.26,-0.65 0.54,-0.43 0.82,-1.21 0.82,-2.31 0,-0.76 -0.1,-1.37 -0.31,-1.81 -0.21,-0.44 -0.47,-0.75 -0.81,-0.91 -0.33,-0.16 -0.99,-0.24 -1.97,-0.24 -0.65,0 -1.04,0.05 -1.2,0.16 -0.15,0.11 -0.23,0.29 -0.23,0.55 z" />
          <path d="m 85.08,35.71 h -5.5 c 0.03,1.4 0.25,2.39 0.66,2.96 0.47,0.65 1.07,0.97 1.8,0.97 1.07,0 1.93,-0.73 2.58,-2.19 l 0.53,0.27 c -0.8,1.86 -1.95,2.78 -3.46,2.78 -1.03,0 -1.89,-0.44 -2.6,-1.33 -0.71,-0.89 -1.05,-2.03 -1.05,-3.41 0,-1.49 0.35,-2.69 1.04,-3.61 0.69,-0.92 1.54,-1.38 2.56,-1.38 0.74,0 1.39,0.23 1.93,0.69 0.54,0.46 0.95,1.11 1.22,1.97 0.18,0.61 0.28,1.37 0.29,2.28 z m -5.5,-0.69 h 3.33 c 0.2,0 0.35,-0.08 0.46,-0.24 0.11,-0.16 0.16,-0.43 0.16,-0.82 0,-0.72 -0.19,-1.33 -0.56,-1.83 -0.38,-0.5 -0.82,-0.76 -1.32,-0.76 -0.57,0 -1.05,0.3 -1.45,0.91 -0.4,0.61 -0.6,1.52 -0.62,2.74 z" />
          <path d="m 85.94,31.06 h 3.78 v 0.67 c -0.5,0.01 -0.82,0.07 -0.96,0.16 -0.14,0.09 -0.21,0.23 -0.21,0.41 0,0.14 0.03,0.29 0.08,0.43 l 2.04,5.42 1.85,-5.26 c 0.03,-0.09 0.04,-0.18 0.04,-0.27 0,-0.48 -0.39,-0.77 -1.18,-0.89 v -0.67 h 3.18 v 0.67 c -0.32,0.04 -0.58,0.14 -0.77,0.31 -0.19,0.17 -0.34,0.4 -0.44,0.69 l -2.82,7.79 h -0.44 l -3.02,-8.01 c -0.11,-0.29 -0.24,-0.49 -0.4,-0.6 -0.16,-0.12 -0.4,-0.18 -0.73,-0.18 z" />
          <path d="m 98.92,30.78 c 1.03,0 1.9,0.45 2.6,1.34 0.7,0.89 1.05,2.07 1.05,3.52 0,1.01 -0.16,1.87 -0.47,2.57 -0.32,0.71 -0.76,1.27 -1.35,1.68 -0.58,0.41 -1.21,0.62 -1.88,0.62 -1,0 -1.84,-0.45 -2.53,-1.35 -0.69,-0.9 -1.04,-2.07 -1.04,-3.51 0,-1.44 0.35,-2.62 1.04,-3.52 0.71,-0.9 1.56,-1.35 2.58,-1.35 z m 0,0.7 c -0.61,0 -1.12,0.33 -1.51,1 -0.39,0.66 -0.59,1.71 -0.59,3.14 0,1.46 0.2,2.52 0.59,3.2 0.39,0.67 0.9,1.01 1.51,1.01 0.64,0 1.16,-0.34 1.55,-1.01 0.4,-0.67 0.59,-1.77 0.59,-3.3 0,-1.42 -0.2,-2.44 -0.59,-3.08 -0.38,-0.64 -0.9,-0.96 -1.55,-0.96 z" />
          <path d="m 106.6,25.58 v 13.31 c 0,0.18 0.05,0.32 0.14,0.42 0.14,0.13 0.3,0.2 0.5,0.2 h 0.81 v 0.74 h -4.36 v -0.74 h 0.77 c 0.25,0 0.44,-0.07 0.56,-0.22 0.12,-0.14 0.18,-0.35 0.18,-0.61 V 27.51 c 0,-0.33 -0.07,-0.57 -0.22,-0.72 -0.2,-0.21 -0.45,-0.31 -0.75,-0.31 h -0.69 v -0.74 z" />
          <path d="m 111.62,30.89 v 6.09 c 0,0.74 0.16,1.32 0.47,1.72 0.31,0.41 0.72,0.61 1.22,0.61 0.61,0 1.13,-0.29 1.56,-0.89 0.43,-0.59 0.64,-1.33 0.64,-2.22 v -3.44 c 0,-0.31 -0.09,-0.56 -0.28,-0.73 -0.19,-0.17 -0.55,-0.27 -1.09,-0.29 v -0.69 l 2.7,-0.17 v 7.54 c 0,0.35 0.08,0.61 0.25,0.8 0.17,0.19 0.39,0.28 0.69,0.28 h 0.48 v 0.66 l -2.58,0.07 -0.16,-1.8 c -0.46,0.65 -0.92,1.13 -1.38,1.46 -0.46,0.33 -0.93,0.49 -1.41,0.49 -0.69,0 -1.28,-0.28 -1.75,-0.83 -0.47,-0.55 -0.71,-1.48 -0.71,-2.79 v -3.99 c 0,-0.35 -0.12,-0.62 -0.35,-0.8 -0.16,-0.13 -0.5,-0.2 -1.01,-0.21 v -0.69 z" />
          <path d="m 121.27,27.31 h 0.55 v 3.75 h 2.49 v 0.9 h -2.49 v 5.89 c 0,0.51 0.1,0.9 0.3,1.17 0.2,0.27 0.46,0.41 0.77,0.41 0.37,0 0.69,-0.21 0.95,-0.62 0.26,-0.41 0.42,-1.09 0.46,-2.04 h 0.55 c -0.03,1.22 -0.27,2.13 -0.7,2.72 -0.43,0.59 -0.99,0.89 -1.68,0.89 -0.63,0 -1.13,-0.21 -1.49,-0.62 -0.36,-0.42 -0.54,-0.96 -0.54,-1.63 v -6.17 h -1.34 v -0.69 c 0.61,-0.12 1.07,-0.43 1.39,-0.91 0.49,-0.72 0.74,-1.74 0.78,-3.05 z" />
          <path d="m 125.64,31.06 3.06,-0.11 v 7.9 c 0,0.22 0.04,0.37 0.13,0.46 0.12,0.13 0.28,0.2 0.48,0.2 h 0.87 v 0.74 h -4.37 v -0.74 h 0.81 c 0.24,0 0.42,-0.07 0.53,-0.21 0.11,-0.14 0.17,-0.35 0.17,-0.61 V 32.9 c 0,-0.37 -0.09,-0.64 -0.26,-0.83 -0.17,-0.19 -0.43,-0.28 -0.78,-0.28 h -0.63 v -0.73 z m 2.13,-5.48 c 0.25,0 0.46,0.11 0.65,0.33 0.19,0.22 0.28,0.48 0.28,0.78 0,0.29 -0.09,0.55 -0.27,0.76 -0.18,0.21 -0.4,0.32 -0.65,0.32 -0.25,0 -0.47,-0.11 -0.65,-0.33 -0.18,-0.22 -0.28,-0.47 -0.28,-0.77 0,-0.3 0.09,-0.55 0.28,-0.77 0.18,-0.21 0.4,-0.32 0.64,-0.32 z" />
          <path d="m 134.7,30.78 c 1.03,0 1.9,0.45 2.6,1.34 0.7,0.89 1.05,2.07 1.05,3.52 0,1.01 -0.16,1.87 -0.47,2.57 -0.32,0.71 -0.76,1.27 -1.35,1.68 -0.58,0.41 -1.21,0.62 -1.88,0.62 -1,0 -1.84,-0.45 -2.53,-1.35 -0.69,-0.9 -1.04,-2.07 -1.04,-3.51 0,-1.44 0.35,-2.62 1.04,-3.52 0.71,-0.9 1.56,-1.35 2.58,-1.35 z m 0,0.7 c -0.61,0 -1.12,0.33 -1.51,1 -0.39,0.66 -0.59,1.71 -0.59,3.14 0,1.46 0.2,2.52 0.59,3.2 0.39,0.67 0.9,1.01 1.51,1.01 0.64,0 1.16,-0.34 1.55,-1.01 0.4,-0.67 0.59,-1.77 0.59,-3.3 0,-1.42 -0.2,-2.44 -0.59,-3.08 -0.38,-0.64 -0.9,-0.96 -1.55,-0.96 z" />
          <path d="m 139.47,31.06 2.55,-0.19 c 0.1,0.58 0.18,1.25 0.23,2.02 0.34,-0.67 0.75,-1.17 1.22,-1.5 0.47,-0.33 1,-0.5 1.59,-0.5 0.51,0 0.93,0.12 1.28,0.36 0.34,0.24 0.62,0.62 0.83,1.13 0.21,0.52 0.31,1.09 0.31,1.74 v 4.47 c 0,0.33 0.06,0.57 0.19,0.71 0.13,0.14 0.34,0.21 0.63,0.21 h 0.54 v 0.74 h -4.05 v -0.74 h 0.38 c 0.36,0 0.61,-0.07 0.75,-0.21 0.14,-0.14 0.21,-0.34 0.21,-0.62 v -3.93 c 0,-0.86 -0.11,-1.49 -0.33,-1.9 -0.31,-0.59 -0.77,-0.88 -1.39,-0.88 -0.62,0 -1.13,0.3 -1.55,0.89 -0.41,0.59 -0.62,1.37 -0.62,2.32 v 3.4 c 0,0.33 0.06,0.57 0.19,0.71 0.13,0.14 0.34,0.21 0.64,0.21 h 0.53 v 0.74 h -4.07 V 39.5 h 0.53 c 0.27,0 0.47,-0.07 0.61,-0.2 0.14,-0.13 0.21,-0.29 0.21,-0.49 v -5.93 c 0,-0.39 -0.09,-0.69 -0.27,-0.89 -0.18,-0.2 -0.43,-0.3 -0.77,-0.3 h -0.39 v -0.63 z" />
        </g>
      </g>
    </svg>
  );
}
export default Tagline;
