import { produce } from "immer";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  cancelOrder,
  completeOrder,
  getOrders,
  reactivateOrder,
} from "../../../api";
import { useSubscription } from "../../../reduxStore/subscriptionDetails/subscriptionDetails.hook";
import { selectSubscriptionDetails } from "../../../reduxStore/subscriptionDetails/subscriptionDetails.reducer";
import { parseQueryUrl } from "../../../tools/url";
import { orderStatuses } from "../../licensing/payment/enums";
import { usePlanDetails } from "../../licensing/usePlanDetails";
import { initialPlans, others } from "./consts";
import { useCurrencies } from "./useCurrencies";
import { usePrices } from "./usePrices";

const getActivePlan = (plan, orders, currencies, isOnFreePlan) => {
  if (isOnFreePlan || !orders?.[0]?.OrderLines?.length) {
    return plan;
  }

  const {
    Order: { Currency: currencyCode },
    OrderLines: [{ Amount: amount, BillingTypeId: billingTypeId }],
  } = orders[0];

  const currency = currencies.find(({ code }) => currencyCode === code);

  return {
    ...plan,
    currency,
    amount,
    billingTypeId,
  };
};

/**
 *
 * @param {(data: any) => void} openPaymentSuccessModal
 * @returns
 */
export const usePlans = (openPaymentSuccessModal) => {
  const history = useHistory();
  const { getSubscription } = useSubscription();
  const { subscription } = useSelector(selectSubscriptionDetails);

  const { getActivePlanName, isOnFreePlan } = usePlanDetails();

  const { currencies, areCurrenciesLoading } = useCurrencies();

  const {
    data: { Results: orders },
    refetch: refetchOrders,
    isLoading: areOrdersLoading,
  } = useQuery({
    queryKey: "GetOrders",
    queryFn: () => getOrders(subscription.ID),
    config: {
      initialData: { Results: [] },
      initialStale: true,
      enabled: subscription?.ID,
    },
  });

  const [plans, setPlans] = useState(initialPlans);
  const [currency, setCurrency] = useState(currencies[0]);
  const [pricesUpdated, setPricesUpdated] = useState(false);

  const { pricesFetchedSuccessfully, savings, prices } = usePrices(
    currency,
    plans
  );

  const refreshPlansData = () =>
    Promise.all([getSubscription(), refetchOrders()]);

  const onCurrencyChange = (newValue) => {
    const newCurrency = currencies.find(({ code }) => code === newValue);
    setCurrency(newCurrency);
  };

  const onCancelSubscription = async () => {
    const orderToCancel =
      orders.find(({ Order }) => {
        return (
          Order.Status === orderStatuses.completed &&
          Order.SubscriptionId === subscription?.ID
        );
      })?.Order?.ID ?? null;

    return orderToCancel
      ? cancelOrder(orderToCancel).then(refreshPlansData)
      : Promise.reject();
  };

  const onResumeSubscription = async () => {
    const orderToResume =
      orders.find(({ Order }) => {
        return (
          Order.Status === orderStatuses.cancelled &&
          Order.SubscriptionId === subscription?.ID
        );
      })?.Order?.ID ?? null;

    return orderToResume
      ? reactivateOrder(orderToResume).then(refreshPlansData)
      : Promise.reject();
  };

  // for each plan assign `priceAnnually` based on a corresponding value from prices object
  const updatePlanPrices = useCallback(
    (state) =>
      produce(state, (draft) => {
        Object.keys(plans).forEach((planName) => {
          draft[planName].priceAnnually =
            prices[planName]?.annually ?? draft[planName].priceAnnually;
        });
      }),
    [plans, prices]
  );

  useEffect(() => {
    if (pricesFetchedSuccessfully) {
      setPlans(updatePlanPrices);
      setPricesUpdated(true);
    }
  }, [pricesFetchedSuccessfully, currency, updatePlanPrices]);

  // todo: remove the call to CompleteOrder when the API integrates with Stripe webhook
  useEffect(() => {
    // TODO (TS): <PlansUrlQuery>
    const { payment, session_id: PaymentReference, order } = parseQueryUrl(
      history.location.search
    );

    if (payment === "success") {
      completeOrder({
        OrderID: parseInt(order, 10),
        PaymentReference,
      }).then((result) => {
        const { OrderLines, Order, NextBillingDate } = result;

        const { Amount, BillingTypeId, ProductId } = OrderLines[0] || {};

        // send conversion event to google
        if (process.env.NODE_ENV === "production" && window.gtag) {
          window.gtag("event", "conversion", {
            send_to: "AW-978600067/7O4_CLKGy90BEIOB0dID",
            currency: Order.Currency,
            value: Amount,
          });
        }

        // show dialog box after order is completed
        openPaymentSuccessModal({
          amount: Amount,
          billingTypeId: BillingTypeId,
          currency: Order.Currency,
          productId: ProductId,
          nextBillingDate: NextBillingDate,
        });
      });
    }
    // MdB Not sure if disabling this is correct but the code seems to be working
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return {
    activePlan: getActivePlan(
      plans[getActivePlanName()],
      orders,
      currencies,
      isOnFreePlan
    ),
    currency,
    currencies,
    onCurrencyChange,
    plans,
    others,
    subscription,
    savings,
    onCancelSubscription,
    onResumeSubscription,
    isLoading:
      !pricesFetchedSuccessfully ||
      !pricesUpdated ||
      areCurrenciesLoading ||
      areOrdersLoading,
  };
};
