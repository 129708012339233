import { diff } from "deep-object-diff";
import { fabric } from "fabric";
import i18n from "i18next";
import mime from "mime";
import { chain, isEmpty, isNil, uniq, unnest } from "ramda";
import { NIL as emptyUuid } from "uuid";
import type {
  AnyShapeTextTileContent,
  AnyTextTileContent,
  BaseShapeTileContent,
  ChatTileContent,
  DuplicateTile,
  EmbedTileContent,
  GroupedTile,
  ImageTileContent,
  InkTileContent,
  ShapeTextTileContent,
  ShapeUnscaledTextTileContent,
  TextTileContent,
  ThumbnailTileContent,
  TileBatchActionGroupInfo,
  TileBatchActionItemInfo,
  TileBatchActionObjectInfo,
  TileContent,
  TilePropertyDelta,
  TileRelation,
  TileStatus,
  TileStatusWithOptionalZIndex,
  UnscaledTextTileContent,
  UploadedFileContent,
  YoutubeTileContent,
} from "../../api/signalR/message.types";
import { toVideoData } from "../../api/YouTube";
import {
  ArrowHeadMode,
  ArrowVisibilityMode,
  BrushType,
  canvasObjectIds,
  connectionAnchorIDs,
  connectorLineModes,
  connectorModes,
  defaultPointerPressure,
  defaultShapeContext,
  fontFamilies,
  fontSizes,
} from "../../const";
import { InternalError } from "../../errors/InternalError";
import { allShapes } from "../../shapes";
import {
  decodeStyle,
  encodeStyle,
  roundToXdecimal,
  toArray,
} from "../../tools";
import { assertUnreachable } from "../../tools/assertions";
import { isError, stringifyError } from "../../tools/errors";
import { getFileExtension } from "../../tools/files";
import { getFulfilledValues } from "../../tools/promises";
import { byTileZIndex } from "../../tools/sorters";
import { LogCategory, onErrorToLog } from "../../tools/telemetry";
import {
  clampToSafeInteger,
  isDefined,
  isNumber,
  shallowCleanObject,
} from "../../tools/utils";
import {
  Anchor,
  BatchTileType,
  FreeFormTextVersion,
  ShapeVersion,
  TileContentType,
} from "../../types/enum";
import {
  createExcelDocuments,
  createImages,
  createPDFDocuments,
  createPowerPointDocuments,
  createVideos,
  createWordDocuments,
  createYoutubeVideo,
} from "../components/createStorageObjects";
import { createEmbedObject } from "../components/embed";
import { createTextBox } from "../components/freeFormText";
import { getParentCollection } from "../components/group/group.utils";
import { createBrushAndInkPath } from "../components/ink";
import { SerializedObject } from "../components/patches/extends-duplicate-objects/duplicate.utils";
import { createShape } from "../components/shape/shape";
import { createStickyNotes } from "../components/stickyNote";
import {
  groupByTileId,
  isActiveSelection,
  isCollection,
  isInActiveSelection,
  isRemoteObject,
  isStickyNote,
  isTransformer,
} from "./fabricObjects";

export const tileTypes: { [key in number]: canvasObjectIds } = {
  1: canvasObjectIds.stickyNote,
  2: canvasObjectIds.image,
  3: canvasObjectIds.pdfDocument,
  4: canvasObjectIds.group,
  5: canvasObjectIds.video,
  6: canvasObjectIds.audio,
  8: canvasObjectIds.stack,
  9: canvasObjectIds.youtube,
  11: canvasObjectIds.wordDocument,
  12: canvasObjectIds.excelDocument,
  13: canvasObjectIds.powerPointDocument,
  16: canvasObjectIds.text,
  17: canvasObjectIds.shape,
  19: canvasObjectIds.inkPath,
  20: canvasObjectIds.embed,
  21: canvasObjectIds.chat,
};

export const objectTypes: { [key in canvasObjectIds]?: number } = {
  stickyNote: 1,
  collaboardImage: 2,
  pdfDocument: 3,
  group: 4,
  collaboardVideo: 5,
  collaboardAudio: 6,
  stack: 8,
  youtubeVideo: 9,
  wordDocument: 11,
  excelDocument: 12,
  powerPointDocument: 13,
  freeFormText: 16,
  collaboardShape: 17,
  inkPath: 19,
  embed: 20,
  chat: 21,
};

const inkId2Type: {
  [key in keyof typeof BrushType]: number | undefined;
} = {
  [BrushType.rubber]: undefined,
  [BrushType.pencil]: 0,
  [BrushType.pen]: 1,
  [BrushType.brush]: 2,
  [BrushType.highlighter]: 3,
  [BrushType.nib]: 4,
};

export const inkType2Id: {
  [key in number]: BrushType;
} = {
  0: BrushType.pencil,
  1: BrushType.pen,
  2: BrushType.brush,
  3: BrushType.highlighter,
  4: BrushType.nib,
};

const createGroup = (props: ObjectConstructorConfig) =>
  Promise.resolve(new fabric.Group([], props as fabric.IGroupOptions));
const createStack = (props: ObjectConstructorConfig) =>
  Promise.resolve(new fabric.Stack([], props as fabric.IGroupOptions));
const createChat = (props: ObjectConstructorConfig) =>
  Promise.resolve(
    new fabric.Chat({
      ...props,
      ...props.contextProps,
    } as fabric.IObjectOptions)
  );

export const createCanvasObjects = async (
  props: ObjectConstructorConfig
): Promise<fabric.Object[]> => {
  const constructorFunction:
    | ((
        props: never
      ) => fabric.Object | fabric.Object[] | Promise<fabric.Object>)
    | undefined =
    (props.type === canvasObjectIds.image && createImages) ||
    (props.type === canvasObjectIds.video && createVideos) ||
    (props.type === canvasObjectIds.youtube && createYoutubeVideo) ||
    (props.type === canvasObjectIds.pdfDocument && createPDFDocuments) ||
    (props.type === canvasObjectIds.wordDocument && createWordDocuments) ||
    (props.type === canvasObjectIds.excelDocument && createExcelDocuments) ||
    (props.type === canvasObjectIds.powerPointDocument &&
      createPowerPointDocuments) ||
    (props.type === canvasObjectIds.shape && createShape) ||
    (props.type === canvasObjectIds.stickyNote && createStickyNotes) ||
    (props.type === canvasObjectIds.text && createTextBox) ||
    (props.type === canvasObjectIds.inkPath && createBrushAndInkPath) ||
    (props.type === canvasObjectIds.embed && createEmbedObject) ||
    (props.type === canvasObjectIds.group && createGroup) ||
    (props.type === canvasObjectIds.stack && createStack) ||
    (props.type === canvasObjectIds.chat && createChat) ||
    undefined;

  return constructorFunction
    ? toArray(await constructorFunction(props as never))
    : [];
};

export const isDefinedTileId = (
  id: string | undefined | null
): id is string => {
  return !!id && id !== emptyUuid;
};

export const toDuplicateTile = (o: SerializedObject): DuplicateTile => ({
  TileId: o.uuid,
  OldTileId: o.originUuid,
  Position: {
    X: o.left,
    Y: o.top,
  },
});

export const toTileStatus = (object: fabric.Object): TileStatus => {
  const { uuid, parentId } = object;
  const { fillColor } = object.getContextProps({ skipGroupMerge: true });
  const parentCollection = getParentCollection(object);

  const parentUuid = parentCollection?.uuid || parentId || emptyUuid;
  // Ensure a tile's UUID is never used as its own parent ID. See #6535
  const ParentId = parentUuid === uuid ? emptyUuid : parentUuid;

  return {
    AzureBlobStatus: object.azureBlobStatus,
    BackgroundColor: fillColor || "",
    Height: roundToXdecimal(object.height),
    IsPinned: !!object.isPinned,
    LockedUser: object.reservationUserName ?? null,
    OldTileId: object.originUuid,
    OriginalFileName: isRemoteObject(object) ? object.fileName : null,
    ParentId,
    PositionX: roundToXdecimal(object.left),
    PositionY: roundToXdecimal(object.top),
    RawTileContent: undefined,
    Relations: [],
    Rotation: roundToXdecimal(object.angle),
    ScaleX: roundToXdecimal(object.scaleX),
    ScaleY: roundToXdecimal(object.scaleY),
    TileContent: toTileContent(object),
    TileId: object.uuid,
    TypeTile: objectTypes[object.type] || 0,
    Width: roundToXdecimal(object.width),
    ZIndex: object.zIndex,
  };
};

// Used only for StackRotate and MoveLayer, can otherwise be removed
export const toGroupedTile = (
  object: fabric.Object,
  zIndex: number
): GroupedTile => {
  const parent = getParentCollection(object);

  return {
    ...toTileStatus(object),
    ZIndex: zIndex,
    ParentTileId: parent?.uuid || emptyUuid,
  };
};

export const toDecomposedObjectTile = (object: fabric.Object): TileStatus => {
  // `decompose` removes .group reference so parentZIndex has to be calculated before
  const parent = getParentCollection(object);
  /**
   * Don't assign `parentZIndex + o.zIndex` as children would appear on top of other objects
   * @NOTE A `canvas.sortAndReassignRootZIndexes()` will be needed however to
   * correctly fix the duplicate zIndexes of the decomposed children
   */
  const parentZIndex = parent?.zIndex ?? 0;

  const tile = object.decompose(toTileStatus);
  tile.ZIndex = parentZIndex;

  return tile;
};

export const serializeObjects = (objects: fabric.Object[]): TileStatus[] => {
  // If the object is inside an activeSelection, temporary apply the translate
  // to calculate the absolute coords to save on the server
  return objects.map((obj) =>
    isInActiveSelection(obj) ? obj.decompose(toTileStatus) : toTileStatus(obj)
  );
};

export const textAlignments = ["none", "left", "center", "right"] as const;
export const textPlacements = ["none", "top", "center", "bottom"] as const;

const toTileContent = (object: fabric.Object): TileContent | undefined => {
  const contextProps = object.getContextProps({ skipGroupMerge: true });
  if (object instanceof fabric.StickyNote) {
    return toTextContent(object, contextProps);
  } else if (object instanceof fabric.FreeFormText) {
    return toFreeFormTextContent(object, contextProps);
  } else if (object instanceof fabric.ShapeInnerTextBox) {
    return toShapeTextContent(object, contextProps);
  } else if (object instanceof fabric.CollaboardShape) {
    return toShapeContent(object, contextProps);
  } else if (object instanceof fabric.CollaboardInkPath) {
    return toInkContent(object);
  } else if (object instanceof fabric.YoutubeVideo) {
    return toYoutubeContent(object);
  } else if (object instanceof fabric.CollaboardEmbed) {
    return toEmbedContent(object);
  } else if (object instanceof fabric.Chat) {
    return toChatContent(object);
  }

  return undefined;
};

const toTextContent = (
  object: fabric.Text | fabric.StickyNote,
  { textAlign, textPlacement, fontSize, textColor }: ContextProps
): TextTileContent => {
  return {
    JSonType: TileContentType.TextContent,
    Text: object.text || "",
    FontFamily: object.fontFamily || fontFamilies[0],
    FontSize:
      isStickyNote(object) && object.isAutoFontSize
        ? 0
        : fontSize ?? fontSizes[0],
    FontColor: textColor || "#000000",
    Style: encodeStyle(object),
    TextAlignment: textAlign ? textAlignments.indexOf(textAlign) : 0,
    TextPlacement: textPlacement ? textPlacements.indexOf(textPlacement) : 0,
  };
};

const toShapeTextContent = (
  object: fabric.ShapeInnerTextBox,
  contextProps: ContextProps
): UnscaledTextTileContent => {
  return {
    ...toTextContent(object, contextProps),
    JSonType: TileContentType.UnscaledTextContent,
  };
};

const toFreeFormTextContent = (
  object: fabric.FreeFormText,
  contextProps: ContextProps
  // eslint-disable-next-line consistent-return
): AnyTextTileContent => {
  switch (object.version) {
    case FreeFormTextVersion.FreeFormText:
      return toTextContent(object, contextProps);
    case FreeFormTextVersion.FreeFormUnscaledText:
      return {
        ...toTextContent(object, contextProps),
        JSonType: TileContentType.UnscaledTextContent,
      };
  }
};

const defaultShapeTextContent: TextTileContent = {
  JSonType: TileContentType.TextContent,
  Text: "",
  FontFamily: defaultShapeContext.fontFamily || fontFamilies[0],
  FontSize: defaultShapeContext.fontSize || fontSizes[0],
  FontColor: "#000000",
  Style: 0,
  TextAlignment: defaultShapeContext.textAlign
    ? textAlignments.indexOf(defaultShapeContext.textAlign)
    : 0,
  TextPlacement: defaultShapeContext.textPlacement
    ? textPlacements.indexOf(defaultShapeContext.textPlacement)
    : 0,
};

const defaultShapeUnscaledTextContent: UnscaledTextTileContent = {
  ...defaultShapeTextContent,
  JSonType: TileContentType.UnscaledTextContent,
};

const toShapeContent = (
  object: fabric.CollaboardShape,
  contextProps: ContextProps
  // eslint-disable-next-line consistent-return
): AnyShapeTextTileContent => {
  const { shape, _textObject } = object;
  const { fillColor, strokeColor, strokeWidth } = contextProps;

  const baseContent: BaseShapeTileContent = {
    JSonType: TileContentType.ShapeContent,
    Id: shape.key || 0,
    FillColor: fillColor || "#000000",
    BorderColor: strokeColor || "transparent",
    BorderSize: strokeWidth || 0,
  };

  switch (object.version) {
    case ShapeVersion.Shape:
      return {
        ...baseContent,
        Text: _textObject
          ? toTextContent(_textObject, contextProps)
          : defaultShapeTextContent,
        UnscaledText: null,
      };
    case ShapeVersion.ShapeUnscaledText:
      return {
        ...baseContent,
        Text: null,
        UnscaledText: _textObject
          ? toShapeTextContent(_textObject, contextProps)
          : defaultShapeUnscaledTextContent,
      };
  }
};

const toYoutubeContent = (object: fabric.YoutubeVideo): YoutubeTileContent => {
  return {
    JSonType: TileContentType.YouTubeContent,
    VideoID: object.video.id,
  };
};

const toEmbedContent = (object: fabric.CollaboardEmbed): EmbedTileContent => {
  return {
    JSonType: TileContentType.EmbedContent,
    EmbeddedContent: object.embeddedContent,
  };
};

const toInkContent = (inkPath: fabric.CollaboardInkPath): InkTileContent => {
  return {
    JSonType: TileContentType.InkContent,
    Color: inkPath.getBrushColor(),
    Type: inkId2Type[inkPath.getBrushType()] || 0,
    StrokeSize: inkPath.getBrushStrokeWidth(),
    InkStroke:
      inkPath &&
      (inkPath._points || inkPath.points || []).map((p) => ({
        PositionX: roundToXdecimal(p.x),
        PositionY: roundToXdecimal(p.y),
        Pressure: roundToXdecimal(
          (p as fabric.CollaboardPoint).pressure ?? defaultPointerPressure
        ),
      })),
  };
};

const toChatContent = (chat: fabric.Chat): ChatTileContent => {
  return {
    JSonType: TileContentType.ChatContent,
    CreatedBy: chat.createdBy,
  };
};

const updateDeltaWithMissingFields = (
  delta: Partial<TileStatus>,
  objA: TileStatus,
  objB: TileStatus
): Partial<TileStatus> => {
  const { TileContent } = delta;

  if (TileContent) {
    const TileContentDelta = TileContent as
      | ShapeTextTileContent
      | ShapeUnscaledTextTileContent;
    TileContent.JSonType = objB.TileContent?.JSonType || TileContent.JSonType;

    // #4046 - inner text content object also need to store JSonContent
    if (
      TileContentDelta.JSonType === TileContentType.ShapeContent &&
      (TileContentDelta.Text || TileContentDelta.UnscaledText)
    ) {
      TileContentDelta.Text &&
        (TileContentDelta.Text.JSonType = TileContentType.TextContent);
      TileContentDelta.UnscaledText &&
        (TileContentDelta.UnscaledText.JSonType =
          TileContentType.UnscaledTextContent);
    }
  }

  if (TileContent && !delta.TypeTile) {
    // TypeTile is needed on the delta to correctly distinguish FreeFormText from
    // other texts
    delta.TypeTile = objB.TypeTile;
  }

  return delta;
};

export const toDiffTiles = (
  origin: TileStatus[],
  result: TileStatus[]
): { origin: TilePropertyDelta[]; result: TilePropertyDelta[] } => {
  const resultsUuidMap = result.reduce<Record<string, TileStatus>>(
    groupByTileId,
    {}
  );
  const toDelta = (
    objA: TileStatus,
    objB: TileStatus
  ): TilePropertyDelta | undefined => {
    if (!objA || !objB) {
      return undefined;
    }

    let delta = diff(objA, objB) as Partial<TileStatus>;
    delta = updateDeltaWithMissingFields(delta, objA, objB);

    return isEmpty(delta)
      ? undefined
      : {
          TileId: objA.TileId,
          Delta: JSON.stringify(delta),
        };
  };

  return {
    origin: origin
      .map((tile) => toDelta(resultsUuidMap[tile.TileId], tile))
      .filter(isDefined),
    result: origin
      .map((tile) => toDelta(tile, resultsUuidMap[tile.TileId]))
      .filter(isDefined),
  };
};

/**
 * Use TS inferred type which is more precise and too tedious to write manually, as it knows which
 * fields are defined and which are optional as result of `shallowCleanObject`
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const toBaseObject = (tile: Partial<TileStatusWithOptionalZIndex>) => {
  return shallowCleanObject({
    uuid: tile.TileId,
    type: tile.TypeTile ? tileTypes[tile.TypeTile] : undefined,
    azureBlobStatus: tile.AzureBlobStatus,
    fileName: tile.OriginalFileName,
    originUuid: tile.OldTileId,
    top: isNumber(tile.PositionY)
      ? clampToSafeInteger(tile.PositionY)
      : undefined,
    left: isNumber(tile.PositionX)
      ? clampToSafeInteger(tile.PositionX)
      : undefined,
    height: tile.Height,
    width: tile.Width,
    angle: tile.Rotation,
    scaleX: tile.ScaleX,
    scaleY: tile.ScaleY,
    reservationUserName: tile.LockedUser ?? undefined,
    isPinned: tile.IsPinned,
    parentId: tile.ParentId
      ? tile.ParentId === emptyUuid
        ? null // Don't want removed it even if it's the emptyUuid, e.g. the diff when detaching a chat
        : tile.ParentId
      : undefined,
    zIndex: tile.ZIndex,
    fileExtension: tile.OriginalFileName
      ? getFileExtension(tile.OriginalFileName)
      : undefined,
    fileType: tile.OriginalFileName
      ? mime.getType(tile.OriginalFileName) ?? undefined
      : undefined,
  });
};

const toTextObject = (
  tileContent?: AnyTextTileContent
): Partial<fabric.Textbox> =>
  shallowCleanObject({
    text: tileContent?.Text ?? undefined, // No text if tile.Text is null or undefined
  });

const toFreeFormTextObject = (
  tileContent: AnyTextTileContent
): Partial<fabric.FreeFormText> => {
  const isLegacy = tileContent.JSonType === TileContentType.TextContent;

  return shallowCleanObject({
    ...(toTextObject(tileContent) as Partial<fabric.FreeFormText>),
    version: isLegacy
      ? FreeFormTextVersion.FreeFormText
      : FreeFormTextVersion.FreeFormUnscaledText,
  });
};

const toShapeObject = (
  tileContent: AnyShapeTextTileContent
): Partial<fabric.CollaboardShape> => {
  const isLegacy =
    tileContent.Text &&
    tileContent.Text.JSonType === TileContentType.TextContent;

  return shallowCleanObject({
    shape: tileContent.Id
      ? allShapes[tileContent.Id] || { isBroken: true, key: tileContent.Id }
      : undefined,
    version: isLegacy ? ShapeVersion.Shape : ShapeVersion.ShapeUnscaledText,
    _textContent: tileContent.Text
      ? toTextObject(tileContent.Text)
      : tileContent.UnscaledText
      ? toTextObject(tileContent.UnscaledText)
      : {},
  });
};

const toImageObject = (
  tileContent: ImageTileContent
): Partial<fabric.CollaboardImage> =>
  shallowCleanObject({
    resizedImages: tileContent.ResizedImages,
  });

const toYoutubeObject = (
  tileContent: YoutubeTileContent
): Partial<fabric.YoutubeVideo> =>
  shallowCleanObject({
    video: toVideoData({ id: { videoId: tileContent.VideoID } }),
  });

const toEmbedObject = (
  tileContent: EmbedTileContent
): Partial<fabric.CollaboardEmbed> =>
  shallowCleanObject({
    embeddedContent: tileContent.EmbeddedContent,
  });

const toChatObject = (tileContent: ChatTileContent): Partial<fabric.Chat> =>
  shallowCleanObject({
    createdBy: tileContent.CreatedBy,
  });

const toUploadedObject = (
  tileContent: UploadedFileContent
): Partial<fabric.CollaboardStorageObject> =>
  shallowCleanObject({
    fileName: tileContent.OriginalFileName,
  });

/**
 * Currently we only receive `ThumbnailContent` in the `GetCopiedTilesInfo`
 * response (when copying a document). As it stands this information is parsed
 * but not used within an object on the canvas.
 *
 * Keeping this here in case we find a way for this information to be useful.
 */
const toThumbnailContentObject = (
  tileContent: ThumbnailTileContent
): Partial<fabric.CollaboardImage> =>
  shallowCleanObject({
    resizedImages: tileContent.ResizedImageContent?.ResizedImages || [],
  });

const toInkObject = (
  tileContent: InkTileContent
): Partial<fabric.CollaboardInkPath> =>
  shallowCleanObject({
    color: tileContent.Color,
    brushType: inkType2Id[tileContent.Type],
    strokeWidth: tileContent.StrokeSize,
    points: tileContent.InkStroke
      ? tileContent.InkStroke.map((inkStroke) => {
          const point = new fabric.Point(
            clampToSafeInteger(inkStroke.PositionX),
            clampToSafeInteger(inkStroke.PositionY)
          ) as fabric.CollaboardPoint;
          point.pressure = inkStroke.Pressure ?? defaultPointerPressure;
          return point;
        })
      : [],
  });

const parseTileContent = (tile: {
  RawTileContent?: string | null;
  TileContent?: TileContent;
}): TileContent | undefined => {
  const { RawTileContent, TileContent } = tile;
  try {
    return RawTileContent
      ? (JSON.parse(RawTileContent) as TileContent)
      : TileContent ?? undefined;
  } catch (error) {
    onErrorToLog(
      isError(error)
        ? error
        : new Error(`Unable to parse tile content: ${stringifyError(error)}`)
    );
    return undefined;
  }
};

const toSpecificObject = (
  tile: Partial<TileStatusWithOptionalZIndex>,
  tileContent?: TileContent // tile content can be passed here, not to invoke `JSON.parse` twice
  // eslint-disable-next-line consistent-return
): Partial<fabric.Object> => {
  const content = tileContent || parseTileContent(tile);

  if (!content) {
    return toTextObject();
  }

  const tileType = tile.TypeTile ? tileTypes[tile.TypeTile] : undefined;
  const { JSonType } = content;

  switch (JSonType) {
    case TileContentType.UnscaledTextContent:
    case TileContentType.TextContent: {
      if (tileType === canvasObjectIds.text) {
        return toFreeFormTextObject(content);
      }
      return toTextObject(content);
    }
    case TileContentType.ShapeContent:
      return toShapeObject(content);
    case TileContentType.ImageContent:
      return toImageObject(content);
    case TileContentType.InkContent:
      return toInkObject(content);
    case TileContentType.YouTubeContent:
      return toYoutubeObject(content);
    case TileContentType.EmbedContent:
      return toEmbedObject(content);
    case TileContentType.ChatContent:
      return toChatObject(content);
    case TileContentType.UploadedFileContent:
      return toUploadedObject(content);
    case TileContentType.ThumbnailContent:
      return toThumbnailContentObject(content);
    default:
      return assertUnreachable(
        JSonType,
        i18n.t("clientError.unrecognisedContentType", { JSonType })
      );
  }
};

export const toContextProps = (
  tile: {
    BackgroundColor?: string;
    RawTileContent?: string | null;
    TileContent?: TileContent;
  },
  tileContent?: TileContent
  // eslint-disable-next-line consistent-return
): ContextProps => {
  const { BackgroundColor } = tile;
  const content = tileContent || parseTileContent(tile);

  if (!content) {
    return shallowCleanObject({ fillColor: BackgroundColor });
  }

  const { JSonType } = content;

  switch (JSonType) {
    case TileContentType.UnscaledTextContent:
    case TileContentType.TextContent: {
      return shallowCleanObject({
        fontFamily: content.FontFamily,
        fontSize: content.FontSize,
        // If FontSize is defined, we must always pass isAutoFontSize: true|false
        // otherwise the object will use the current value
        isAutoFontSize: !isNil(content.FontSize)
          ? content.FontSize === 0
          : undefined,
        textColor: content.FontColor,
        textAlign: content.TextAlignment
          ? textAlignments[content.TextAlignment]
          : undefined,
        textPlacement: content.TextPlacement
          ? textPlacements[content.TextPlacement]
          : undefined,
        fillColor: BackgroundColor,
        ...decodeStyle(content.Style),
      });
    }

    case TileContentType.ShapeContent: {
      return shallowCleanObject({
        ...toContextProps({
          RawTileContent: null,
          TileContent: content.Text ?? content.UnscaledText ?? undefined,
        }),
        fillColor: content.FillColor,
        strokeColor: content.BorderColor,
        strokeWidth: content.BorderSize,
      });
    }

    case TileContentType.InkContent: {
      const inkContent = content as Partial<InkTileContent>;
      return shallowCleanObject({
        fillColor: inkContent.Color,
        strokeWidth: inkContent.StrokeSize,
      });
    }

    case TileContentType.ChatContent: {
      return shallowCleanObject({ fillColor: BackgroundColor || undefined });
    }

    // Exhaustive case checks, `default` won't give a TS error if a case is forgotten
    case TileContentType.ImageContent:
    case TileContentType.YouTubeContent:
    case TileContentType.EmbedContent:
    case TileContentType.UploadedFileContent:
    case TileContentType.ThumbnailContent:
      return {};
  }
};

export const toObjectProps = <T extends fabric.Object>(
  tile: Partial<TileStatusWithOptionalZIndex>,
  tileContent?: TileContent
): ObjWithRequiredFields<T> =>
  (({
    ...toBaseObject(tile),
    ...toSpecificObject(tile, tileContent),
  } as unknown) as ObjWithRequiredFields<T>); /** @TODO #7322 - Why do we need this unknown now? */

const toObjectConstructorConfig = (
  tile: TileStatusWithOptionalZIndex
): ObjectConstructorConfig => {
  const content = parseTileContent(tile);
  return {
    ...(toObjectProps(tile, content) as ObjectConstructorConfig),
    contextProps: toContextProps(tile, content),
    contextPropsConfig: { isInitialization: true },
  };
};

type ObjectsConstructionResults = Promise<{
  objects: fabric.Object[];
  errors: (Error | string)[];
  errorMessageList: string;
}>;

export const toFullObjects = (
  tiles: TileStatusWithOptionalZIndex[]
): ObjectsConstructionResults => {
  const errors: (Error | string)[] = [];

  const objectPromises = tiles.sort(byTileZIndex).map(async (tile) => {
    try {
      const object = toObjectConstructorConfig(tile);
      // We must await before returning to allow rejections to be caught here
      return await createCanvasObjects(object);
    } catch (error) {
      // Log the error here one-by-one to send the correct stack trace
      onErrorToLog(
        isError(error)
          ? error
          : new InternalError(`Failed to istantiate object for tile: ${error}`),
        LogCategory.internal,
        {
          tile,
        }
      );

      errors.push(stringifyError(error));
      return Promise.resolve([] as fabric.Object[]);
    }
  });

  return Promise.all(objectPromises).then((objects) => {
    return {
      objects: unnest(objects),
      errors,
      errorMessageList: uniq(
        errors
          .filter(Boolean)
          .map((error) => (error instanceof Error ? error.message : error))
      )
        .map((message) => `\n- ${message}`)
        .join(""),
    };
  });
};

/**
 * Serialize connector state to match the API's concept of TileRelation.
 * // TODO: only allow fabric.CollaboardConnector, ConnectorSerializedState is used for tests
 */
export const toTileRelation = (
  connection: fabric.CollaboardConnector | ConnectorSerializedState
): TileRelation => {
  const config =
    connection instanceof fabric.CollaboardConnector
      ? connection.toObjectState()
      : connection;
  const { enableArrows, arrowStyle, routingStyle, strokeStyle } = config;
  const { uuid, relationId, originId, destinationId } = config;
  const { originAnchor, destinationAnchor, fillColor, strokeWidth } = config;

  const originArrow =
    enableArrows === ArrowVisibilityMode.origin ||
    enableArrows === ArrowVisibilityMode.originDestination
      ? arrowStyle
      : ArrowHeadMode.none;
  const destinationArrow =
    enableArrows === ArrowVisibilityMode.destination ||
    enableArrows === ArrowVisibilityMode.originDestination
      ? arrowStyle
      : ArrowHeadMode.none;

  return {
    Id: relationId ?? 0,
    uuid,
    TileId: originId || emptyUuid,
    RelatedTileId: destinationId || emptyUuid,
    Style: routingStyle || connectorModes.bezier,
    AnchorOrigin: connectionAnchorIDs[originAnchor],
    AnchorDestination: connectionAnchorIDs[destinationAnchor],
    Color: fillColor || "#000000",
    Line: strokeStyle || connectorLineModes.solid,
    Thickness: strokeWidth || 1,
    SymbolOrigin: originArrow,
    SymbolDestination: destinationArrow,
    SyncId: null,
  };
};

/**
 * Deserialize the API's concept of Relation into a fabric.CollaboardConnector
 * config.
 */
export const deserializeRelation = (
  config: TileRelation
): ConnectorSerializedState => {
  const {
    SymbolDestination: destinationArrow,
    SymbolOrigin: originArrow,
  } = config;

  const arrowStyle = originArrow || destinationArrow || ArrowHeadMode.none;
  let enableArrows: number = ArrowVisibilityMode.none;

  if (
    originArrow !== ArrowHeadMode.none &&
    destinationArrow !== ArrowHeadMode.none
  ) {
    enableArrows = ArrowVisibilityMode.originDestination;
  } else if (originArrow !== ArrowHeadMode.none) {
    enableArrows = ArrowVisibilityMode.origin;
  } else if (destinationArrow !== ArrowHeadMode.none) {
    enableArrows = ArrowVisibilityMode.destination;
  }

  const anchorIds = Object.keys(connectionAnchorIDs) as Anchor[];

  return {
    type: canvasObjectIds.connector,
    uuid: config.uuid,
    relationId: config.Id,
    originId: config.TileId,
    destinationId: config.RelatedTileId,
    originAnchor:
      anchorIds.find((key) => {
        return connectionAnchorIDs[key] === config.AnchorOrigin;
      }) || Anchor.top,
    destinationAnchor:
      anchorIds.find((key) => {
        return connectionAnchorIDs[key] === config.AnchorDestination;
      }) || Anchor.top,
    routingStyle: config.Style,
    strokeStyle: config.Line,
    strokeWidth: config.Thickness,
    fillColor: config.Color,
    arrowStyle,
    enableArrows,
  };
};

/**
 * Convert the object configurations into fabric objects. Currently only
 * supports fabric.CollaboardConnectors.
 *
 * @param {Array<object>} objectConfigs List of object configs
 * @param {fabric.CollaboardCanvas} canvas The canvas
 * @returns {Promise<Array<fabric.Object>>} Hydrated objects
 */
export const rehydrateConnectors = async (
  objectConfigs: TileRelation[],
  canvas: fabric.CollaboardCanvas
): Promise<fabric.CollaboardConnector[]> => {
  const existingConnectorIds = canvas
    .getObjects(canvasObjectIds.connector)
    .map((connector) => (connector as fabric.CollaboardConnector).relationId);
  const connectorsResults = await Promise.allSettled(
    objectConfigs
      .map(deserializeRelation)
      .filter(({ relationId }) => {
        // Ensure that we don't attempt to recreate a connector that is already
        // on the canvas. Note the API uses `relationId` for a connector, not UUID.
        return !relationId || !existingConnectorIds.includes(relationId);
      })
      .map((config) => {
        return fabric.CollaboardConnector.fromObjectState(config, canvas);
      })
  );

  return getFulfilledValues(connectorsResults);
};

type ObjectMapper<T> = (object: fabric.Object) => T;
type ObjectIndexedMapper<T> = (object: fabric.Object, id: number) => T;

export const decomposeChildren = <T = Partial<fabric.Object>>(
  object: fabric.ActiveSelection,
  transform?: ObjectMapper<T>
): T[] => object.getObjects().map((o) => o.decompose(transform)) || [];

const flatObjectMapper = <T = Partial<fabric.Object>>(
  object: fabric.Object,
  transform: ObjectMapper<T>
): T[] =>
  isActiveSelection(object)
    ? decomposeChildren(object, transform)
    : isTransformer(object)
    ? object._objects?.map(transform)
    : [transform(object)];

/**
 * Flattens an activeSelection to its children and applies the translate transform
 * to them (not the rotate and scaling transform).
 *
 * TODO: split the implementation into 3 functions which makes clear what it does:
 * 1. Flattening an activeSelection
 * 2. Applying the translate transform
 * 3. Calling the transform fn, tipically to serialize as a tile. This one can
 * actually be done by the caller, instead of within this function.
 */
export const flatten = <T = Partial<fabric.Object>>(
  objects: fabric.Object[],
  transform: ObjectMapper<T>
): T[] => chain((o) => flatObjectMapper(o, transform), objects);

export const flattenChildren = <T = Partial<fabric.Object>>(
  objects: fabric.Object[],
  transform: ObjectIndexedMapper<T>
): T[] =>
  chain(
    (object) => (isCollection(object) && object.getObjects()) || [],
    objects
  ).map(transform);

export const isTileBatchActionGroupInfo = (
  tile: TileBatchActionItemInfo
): tile is TileBatchActionGroupInfo => {
  return tile.Type === BatchTileType.Group || tile.Type === BatchTileType.Stack;
};

export const isTileBatchActionObjectInfo = (
  tile: TileBatchActionItemInfo
): tile is TileBatchActionObjectInfo => {
  return tile.Type === BatchTileType.Object;
};

export const tileBatchActionGroupInfoToTileStatus = (
  batchTile: TileBatchActionGroupInfo
): TileStatus => {
  const TypeTile =
    batchTile.Type === BatchTileType.Group
      ? objectTypes.group
      : objectTypes.stack;
  return {
    AzureBlobStatus: 1,
    BackgroundColor: "",
    Height: batchTile.H,
    IsPinned: false,
    LockedUser: null,
    OldTileId: emptyUuid,
    OriginalFileName: null,
    ParentId: emptyUuid,
    PositionX: batchTile.X,
    PositionY: batchTile.Y,
    RawTileContent: undefined,
    Relations: [],
    Rotation: 0,
    ScaleX: 1,
    ScaleY: 1,
    TileContent: undefined,
    TileId: batchTile.Id,
    TypeTile: TypeTile || 0,
    Width: batchTile.W,
    ZIndex: batchTile.Z,
  };
};

export const tileStatusToBatchActionInfo = (
  tileStatus: TileStatus
): TileBatchActionGroupInfo | TileBatchActionObjectInfo => {
  const isGroupTile = tileStatus.TypeTile === objectTypes.group;
  const isStackTile = tileStatus.TypeTile === objectTypes.stack;

  if (isGroupTile || isStackTile) {
    const groupTile: TileBatchActionGroupInfo = {
      Id: tileStatus.TileId,
      Type: isGroupTile ? BatchTileType.Group : BatchTileType.Stack,
      W: tileStatus.Width,
      H: tileStatus.Height,
      X: tileStatus.PositionX,
      Y: tileStatus.PositionY,
      Z: tileStatus.ZIndex,
    };

    return groupTile;
  }

  const ParentId = isDefinedTileId(tileStatus.ParentId)
    ? tileStatus.ParentId
    : null;
  const objectTile: TileBatchActionObjectInfo = {
    Id: tileStatus.TileId,
    Type: BatchTileType.Object,
    X: tileStatus.PositionX,
    Y: tileStatus.PositionY,
    Z: tileStatus.ZIndex,
    Angle: tileStatus.Rotation,
    ParentId,
  };

  return objectTile;
};
