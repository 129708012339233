import { SelectionMode } from "../../../const";

export enum SelectionActionType {
  SET_SELECTION_MODE = "SET_SELECTION_MODE",
  TOGGLE_SELECT_LOCKED = "TOGGLE_SELECT_LOCKED",
  SELECT_ALL_OBJECTS = "SELECT_ALL_OBJECTS",
  DESELECT_ALL_OBJECTS = "DESELECT_ALL_OBJECTS",
  SELECT_FABRIC_OBJECT = "SELECT_FABRIC_OBJECT",
  SELECT_READ_ONLY_OBJECT = "SELECT_READ_ONLY_OBJECT",
}

export const setSelectionModeAction = (method: SelectionMode) =>
  ({
    type: SelectionActionType.SET_SELECTION_MODE,
    payload: method,
  } as const);

export const toggleSelectLockedAction = (selectLocked: boolean) =>
  ({
    type: SelectionActionType.TOGGLE_SELECT_LOCKED,
    payload: selectLocked,
  } as const);

type SelectAllObjects = {
  isKeyboardSelect: boolean;
};

export const selectAllObjectsAction = (payload: SelectAllObjects) =>
  ({
    type: SelectionActionType.SELECT_ALL_OBJECTS,
    payload,
  } as const);

export const deSelectAllObjectsAction = () =>
  ({
    type: SelectionActionType.DESELECT_ALL_OBJECTS,
    payload: undefined,
  } as const);

export const selectFabricObjectAction = (payload: fabric.Object | undefined) =>
  ({
    type: SelectionActionType.SELECT_FABRIC_OBJECT,
    payload,
  } as const);

export const selectReadOnlyObjectAction = (payload: fabric.Object) =>
  ({
    type: SelectionActionType.SELECT_READ_ONLY_OBJECT,
    payload,
  } as const);

export type SetSelectionModeAction = ReturnType<typeof setSelectionModeAction>;
export type ToggleSelectLockedAction = ReturnType<
  typeof toggleSelectLockedAction
>;
export type SelectAllObjectsAction = ReturnType<typeof selectAllObjectsAction>;
export type SelectReadOnlyObjectAction = ReturnType<
  typeof selectReadOnlyObjectAction
>;

export type SelectionAction =
  | SetSelectionModeAction
  | ToggleSelectLockedAction
  | SelectAllObjectsAction
  | ReturnType<typeof deSelectAllObjectsAction>
  | ReturnType<typeof selectFabricObjectAction>
  | SelectReadOnlyObjectAction;
