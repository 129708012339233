import React, { ReactElement, ReactNode } from "react";
import { Box, Link, Flex, SxStyleProp } from "theme-ui";
import { Link as Anchor } from "react-router-dom";

type Props = {
  children: ReactNode;
  href: string;
  onClick?: (event: React.MouseEvent) => void;
  variant?: string;
  index: number;
  customStyles?: SxStyleProp;
};

function ProjectThumbnailLink({
  children,
  href = "#",
  onClick,
  variant = "projectThumbnailLink",
  index,
  customStyles,
}: Props): ReactElement {
  const onClickWrapper = (event: React.MouseEvent) => {
    if (onClick) {
      event.preventDefault();
      event.stopPropagation();
      onClick(event);
    }
  };

  const anchorProps = { to: href };
  return (
    <Link
      id={`project-tile-${index}`}
      as={Anchor}
      onClick={onClickWrapper}
      className="project-thumbnail"
      variant={variant}
      sx={customStyles}
      {...anchorProps}
    >
      <Box variant={"layout.projectThumbnail.wrapper"}>
        <Flex variant={"layout.projectThumbnail.content"}>{children}</Flex>
      </Box>
    </Link>
  );
}
export default ProjectThumbnailLink;
