import React, { ReactElement } from "react";
import { Flex, ThemeUIStyleObject } from "theme-ui";

type CenteredProps = {
  column?: boolean;
};

function Centered({
  children,
  column,
}: PropsWithRequiredChildren<CenteredProps>): ReactElement {
  return (
    <Flex sx={column ? centeredColumnStyle : centeredRowStyle}>{children}</Flex>
  );
}

export default Centered;

const centeredColumnStyle: ThemeUIStyleObject = {
  alignItems: "center",
  height: "100%",
  justifyContent: "center",
  flexDirection: "column",
};

const centeredRowStyle: ThemeUIStyleObject = {
  ...centeredColumnStyle,
  flexDirection: "row",
};
