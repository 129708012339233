import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Password(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M27,14.37H24.28V11a4.06,4.06,0,0,0-3.91-4.18A4.06,4.06,0,0,0,16.45,11v3.35H13.74V11a6.86,6.86,0,0,1,6.63-7.07A6.86,6.86,0,0,1,27,11Z" />
      <path d="M27,16.29H13.51a2.3,2.3,0,0,0-2.22,2.38V31.4a2.3,2.3,0,0,0,2.22,2.38H27A2.3,2.3,0,0,0,29.2,31.4V18.67A2.3,2.3,0,0,0,27,16.29ZM24,27.75l-2.2,1.57-1.48-2.07L18.82,29.3l-2.17-1.61,1.51-2.05-2.41-.81.86-2.56,2.41.81,0-2.55h0l2.7,0,0,2.55,2.43-.77L25,24.92l-2.43.76Z" />
    </Icon>
  );
}
export default Password;
