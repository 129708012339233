import React, { useEffect, useRef, useState, ReactElement } from "react";
import copyToClipboard from "copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { Box, Button, Flex, Text, Textarea } from "theme-ui";
import OK from "../../../../icons/OK";
import ModalFooter from "../../../shared/modals/ModalFooter";

const DISPLAY_COPIED_LABEL_TIMEOUT = 4_000;

type Props = {
  value: string;
  onBackClick: () => void;
};

function ShareLink({ value, onBackClick }: Props): ReactElement {
  const { t } = useTranslation();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    textareaRef.current?.select();
  }, []);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, DISPLAY_COPIED_LABEL_TIMEOUT);
    }
  }, [isCopied]);

  const onCopyClick = () => {
    copyToClipboard(value);
    setIsCopied(true);
  };

  return (
    <Box sx={{ flex: 1 }}>
      <Flex sx={{ flexDirection: "column", alignItems: "center", mb: [6] }}>
        <Flex sx={{ alignItems: "center", svg: { fill: "primary" } }}>
          <OK noShadow />
          <Text sx={{ fontSize: [5], fontWeight: 500 }}>
            {t("dialog.share.linkGenerated")}
          </Text>
        </Flex>
        <Text sx={{ mb: [4], textAlign: "center", whiteSpace: "pre-line" }}>
          {t("dialog.share.linkGeneratedInfo")}
        </Text>

        <Textarea
          sx={{ height: "150px" }}
          ref={textareaRef}
          value={value}
          autoFocus
          readOnly
        />
      </Flex>

      <ModalFooter sx={{ p: 0 }}>
        <Button variant="buttons.secondary" onClick={onBackClick}>
          {t("dialog.share.back")}
        </Button>

        {isCopied ? (
          <Button variant="content" disabled sx={copiedButtonStyle}>
            <OK noShadow /> {t("dialog.share.linkCopiedLabel")}
          </Button>
        ) : (
          <Button type="button" sx={{ flex: 1 }} onClick={onCopyClick}>
            {t("dialog.share.copyLink")}
          </Button>
        )}
      </ModalFooter>
    </Box>
  );
}

const copiedButtonStyle = {
  cursor: "default",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  svg: { fill: "primary" },
};

export default ShareLink;
