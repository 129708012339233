import { merge } from "theme-ui";

import { runtimeConfig } from "../tools/runtimeConfig";
import defaultTheme, { DefaultTheme } from "./default";

// TODO: #6214 - Remove this and move the configuration to server

const twrTheme = merge(defaultTheme, {
  name: "twr",
  colors: {
    accent: "#bb0872",
    active: "#bb0872",
    danger: "#ff05fc",
    primary: "#ea0a8e",
    disabled: "#d3d3d3",
    disabledDark: "#666666",
    accentAlternate: "#ac0a6a",
    accentSecondary: "#df72b3",
    accentSecondaryAlternate: "#d454a1",
    accentSecondaryDimmed: "#f6d5e9",
    disclaimerBg: "#bb0872",
    ...runtimeConfig.styles?.colors,
  },
  layout: {
    // removes floating (fixed) logos
    formsWrap: {
      "&:before": undefined,
      "&:after": undefined,
    },
  },
  fonts: {
    body: "system-ui, sans-serif",
  },
  links: {
    projectThumbnailLinkLoad: {
      title: {
        color: "#ffffff",
      },
    },
    projectThumbnailLinkNew: {
      title: {
        color: "#ffffff",
      },
      ".project-thumbnail-link__icon": {
        fill: "#ffffff",
      },
    },
  },
}) as DefaultTheme;

export default twrTheme;
