import React, { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, ThemeUIStyleObject } from "theme-ui";
import { ParticipationType } from "../../../../../const";
import { splitBy } from "../../../../../tools/utils";
import LineSeparator from "../LineSeparator";
import UserCard, { PermissionChangeProps } from "./UserCard";

type Props = {
  myself: ProjectApiParticipantInfo | null;
  project: ApiProject;
  users: ProjectApiParticipantInfo[];
  onPermissionChange: (props: PermissionChangeProps) => void;
  onOwnerChange: () => void;
};

function ProjectCollaborators({
  myself,
  users,
  project,
  onPermissionChange,
  onOwnerChange,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [directUsers, spaceUsers] = useMemo(
    () =>
      splitBy(
        users,
        (user) => user.ParticipationType === ParticipationType.direct
      ),
    [users]
  );

  const renderUser = (user: ProjectApiParticipantInfo) => {
    return (
      <Box key={user.UserName} sx={userStyle}>
        <UserCard
          myself={myself}
          user={user}
          project={project}
          onProjectOwnerChange={onOwnerChange}
          onPermissionChange={onPermissionChange}
        />
      </Box>
    );
  };

  return (
    <>
      <Box sx={{ my: [2], flex: 1 }}>
        <LineSeparator
          text={t("users.collaboratorsFromSpace", { count: spaceUsers.length })}
          isLineVisible={false}
          textSx={{ fontWeight: 300, mb: [2] }}
        />
        <Grid sx={gridStyle}>{spaceUsers.map(renderUser)}</Grid>
      </Box>
      <Box sx={{ my: [2], flex: 1 }}>
        <LineSeparator
          text={t("users.collaboratorsFromDirectInvite", {
            count: directUsers.length,
          })}
          isLineVisible={false}
          textSx={{ fontWeight: 300, mb: [2] }}
        />
        <Grid sx={gridStyle}>{directUsers.map(renderUser)}</Grid>
      </Box>
    </>
  );
}

const gridStyle: ThemeUIStyleObject = {
  gridTemplateColumns: "1fr 1fr",
  overflowY: "auto",
  maxHeight: "300px",
  width: "100%",
};

const userStyle: ThemeUIStyleObject = {
  bg: "#eee",
  padding: 1,
  width: "100%",
  fontSize: [10, null, null, 11],
};

export default ProjectCollaborators;
