import type {
  GroupedTile,
  TileIndex,
  TilePropertyDelta,
  TileRelation,
  TileStatus,
} from "../../../api/signalR/message.types";
import { canvasObjectIds } from "../../../const";
import type { SerializedObject } from "../../../studio/components/patches/extends-duplicate-objects/duplicate.utils";
import {
  getLockableParent,
  isCollection,
  isInCollection,
  isTopLevelZIndexable,
  separateObjectsAndConnections,
} from "../../../studio/utils/fabricObjects";
import {
  flatten,
  flattenChildren,
  toDecomposedObjectTile,
  toDiffTiles,
  toGroupedTile,
  toTileRelation,
  toTileStatus,
} from "../../../studio/utils/objectConverter";
import { isDefined, unique } from "../../../tools/utils";
import { HistoryActionType } from "./history.actions";

export enum HistoryEntryType {
  ADD = "ADD",
  CHANGE_BACKGROUND = "CHANGE_BACKGROUND",
  CONNECT = "CONNECT",
  COPY = "COPY",
  DUPLICATE = "DUPLICATE",
  GROUP = "GROUP",
  LOCK = "LOCK",
  MODIFY = "MODIFY",
  MOVE_LAYER = "MOVE_LAYER",
  REMOVE = "REMOVE",
  SELECTION_CHANGED = "SELECTION_CHANGED",
  STACK = "STACK",
  STACK_ROTATE = "STACK_ROTATE",
  UNGROUP = "UNGROUP",
  UNGROUP_ALL = "UNGROUP_ALL",
  UNSTACK = "UNSTACK",
  UPDATE = "UPDATE",
  UPDATE_CONNECTION = "UPDATE_CONNECTION",
  UPLOAD = "UPLOAD",
}

type BackgroundChangePayload = {
  oldBackgroundColor: string;
  newBackgroundColor: string;
};

export type HistoryModifyTransform<TileType> = ConditionalUndo &
  ObjectTransformEvent<TileType>;

export type HistoryLockStatus = {
  uuid: string;
  isLocked: boolean;
};

type ConditionalUndo = {
  affectedUuids?: string[];
  skipTopLevelObjectsCheck?: boolean;
};

type RequiresAffectedUuids = {
  affectedUuids: string[];
};

type HistoryEntryFields = ConditionalUndo & {
  objectType?: canvasObjectIds;
};

export type HistoryEntryAction<T> = {
  type: HistoryActionType.HISTORY_ADD_ENTRY | HistoryActionType.HISTORY_NOOP;
  payload: T;
};

export type HistoryAdd = HistoryEntryFields & {
  transformationType: HistoryEntryType.ADD;
  addedObjects: TileStatus[];
};

export type HistoryDuplicate = HistoryEntryFields & {
  transformationType: HistoryEntryType.DUPLICATE;
  objects: SerializedObject[];
  sourceProjectId?: string;
};

export type HistoryCopy = HistoryEntryFields & {
  transformationType: HistoryEntryType.COPY;
  sourceProjectId: string;
  originalUuids: string[];
  targetConnections: TileRelation[];
  targetObjects: TileStatus[];
  targetChildren: TileStatus[];
};

export type HistoryUploaded = HistoryEntryFields & {
  transformationType: HistoryEntryType.UPLOAD;
  uploadedObject: TileStatus;
};

export type HistoryRemoved = HistoryEntryFields & {
  transformationType: HistoryEntryType.REMOVE;
  removedConnections: TileRelation[];
  removedObjects: TileStatus[];
  removedChildren: TileStatus[];
  removedAttachments: TileStatus[];
};

export type HistoryConnected = HistoryEntryFields &
  RequiresAffectedUuids & {
    transformationType: HistoryEntryType.CONNECT;
    addedConnections: TileRelation[];
  };

export type HistoryModified = HistoryEntryFields & {
  transformationType: HistoryEntryType.MODIFY;
  modificationType: string;
  deltas: { origin: TilePropertyDelta[]; result: TilePropertyDelta[] };
  originalObjects: TileStatus[];
  resultingObjects: TileStatus[];
};

export type HistoryUpdatedContextProps = HistoryEntryFields & {
  transformationType: HistoryEntryType.UPDATE;
  deltas: { origin: TilePropertyDelta[]; result: TilePropertyDelta[] };
};

export type HistoryUpdatedConnectionProps = HistoryEntryFields & {
  transformationType: HistoryEntryType.UPDATE_CONNECTION;
  originalConnections: TileRelation[];
  resultingConnections: TileRelation[];
};

export type HistoryGroup = HistoryEntryFields & {
  transformationType: HistoryEntryType.GROUP;
  addedGroup: TileStatus;
  topLevelObjectsBeforeGroup: TileStatus[];
  groupsBeforeGroup: TileStatus[];
  childrenByGroupBeforeGroup: Record<string, TileStatus[] | undefined>;
  allChildrenAfterGroup: TileStatus[];
};

export type HistoryUnGroup = HistoryEntryFields & {
  transformationType: HistoryEntryType.UNGROUP;
  removedChildren: TileStatus[];
  removedConnections: TileRelation[];

  groupBeforeUngroup: TileStatus;
  childrenBeforeUngroup: TileStatus[];
  objectsAfterUngroup: TileStatus[];
};

export type HistoryUnGroupAll = HistoryEntryFields & {
  transformationType: HistoryEntryType.UNGROUP_ALL;
  groupsBeforeUngroup: TileStatus[];
  childrenBeforeUngroup: TileStatus[];
  objectsAfterUngroup: TileStatus[];
  topLevelObjectsBeforeUngroup: TileStatus[];
};

export type HistoryStack = HistoryEntryFields & {
  transformationType: HistoryEntryType.STACK;
  addedStack: TileStatus;
  objectsBeforeStack: TileStatus[];
  childrenAfterStack: TileStatus[];
};

export type HistoryUnStack = HistoryEntryFields & {
  transformationType: HistoryEntryType.UNSTACK;
  removedStack: TileStatus;
  removedConnections: TileRelation[];
  childrenBeforeUnstack: TileStatus[];
  /**
   * Used in case `objectsBeforeStack` is not available
   */
  objectsAfterUnstack: TileStatus[];
  /**
   * Used to unstack the objects as they were before being stacked, but their previous positions
   * may not be available if the user refreshed the app.
   */
  objectsBeforeStack: TileStatus[] | null;
};

export type HistoryStackRotated = HistoryEntryFields & {
  transformationType: HistoryEntryType.STACK_ROTATE;
  stackUuid: string;
  rotationState: GroupedTile[];
  isRotatedLeft: boolean;
};

export type HistoryLock = HistoryEntryFields &
  RequiresAffectedUuids & {
    transformationType: HistoryEntryType.LOCK;
    newStatus: HistoryLockStatus[];
    oldStatus: HistoryLockStatus[];
  };

export type HistoryMovedLayer = HistoryEntryFields & {
  transformationType: HistoryEntryType.MOVE_LAYER;
  originalObjects: TileStatus[];
} & (
    | {
        oldState: GroupedTile[];
        newState: GroupedTile[];
        isSubselect: true;
      }
    | {
        oldState: TileIndex[];
        newState: TileIndex[];
        isSubselect: false;
      }
  );

export type HistorySelectionChanged = HistoryEntryFields & {
  transformationType: HistoryEntryType.SELECTION_CHANGED;
  selectedUuids: string[];
};

export type HistoryBackground = HistoryEntryFields &
  BackgroundChangePayload & {
    transformationType: HistoryEntryType.CHANGE_BACKGROUND;
  };

export type HistoryDuplicatePayload = {
  objects: SerializedObject[];
  sourceProjectId?: string;
  translate: fabric.Position;
};

export type HistoryCopyPayload = {
  objects: fabric.Object[];
  sourceProjectId: string;
  originalUuids: string[];
};

export type HistoryModifiedPayload<TileType> = {
  object: fabric.Object;
  transform: HistoryModifyTransform<TileType>;
};

export type HistoryGroupPayload = {
  addedGroup: fabric.Group;
  /** Used to undo top-level objects as they contain the objects props BEFORE being grouped */
  topLevelObjectsBeforeGroup: TileStatus[];
  childrenByGroupBeforeGroup: Record<string, TileStatus[] | undefined>;
  groupsBeforeGroup: TileStatus[];
};

export type HistoryUnGroupPayload = {
  removedObjects?: fabric.Object[];
  groupBeforeUngroup: fabric.Group;
  childrenBeforeUngroup?: fabric.Object[];
};

export type HistoryUnGroupAllPayload = {
  groupsBeforeUngroup: fabric.Group[];
  childrenBeforeUngroup: fabric.Object[];
  topLevelObjectsBeforeUngroup: fabric.Object[];
};

export type HistoryStackRotatedPayload = {
  stack: fabric.Stack;
  isRotatedLeft: boolean;
};

export type HistoryLockPayload = {
  status: HistoryLockStatus[];
  types: canvasObjectIds[];
};

export type HistoryMoverLayerPayload = {
  order: fabric.Object[];
  targetType?: canvasObjectIds;
  reversible: boolean;
  isSubselect: boolean;
};

export type HistoryEntry =
  | HistoryAdd
  | HistoryDuplicate
  | HistoryCopy
  | HistoryUploaded
  | HistoryRemoved
  | HistoryConnected
  | HistoryModified
  | HistoryUpdatedContextProps
  | HistoryUpdatedConnectionProps
  | HistoryGroup
  | HistoryUnGroup
  | HistoryUnGroupAll
  | HistoryStack
  | HistoryUnStack
  | HistoryStackRotated
  | HistoryLock
  | HistoryMovedLayer
  | HistorySelectionChanged
  | HistoryBackground;

const getObjectType = (
  objects: fabric.Object[]
): canvasObjectIds | undefined => {
  const types = unique(objects.map((o) => o.type));
  return types.length === 1
    ? types[0]
    : objects.length
    ? canvasObjectIds.activeSelection
    : undefined;
};

export const addedAction = (
  objects: fabric.Object[]
): HistoryEntryAction<HistoryAdd> => ({
  type: HistoryActionType.HISTORY_ADD_ENTRY,
  payload: {
    transformationType: HistoryEntryType.ADD,
    objectType: getObjectType(objects),
    affectedUuids: objects.map((o) => o.uuid),
    addedObjects: flatten(objects, toTileStatus),
  },
});

export const duplicatedAction = ({
  objects,
  sourceProjectId,
  translate,
}: HistoryDuplicatePayload): HistoryEntryAction<HistoryDuplicate> => ({
  type: HistoryActionType.HISTORY_NOOP,
  payload: {
    transformationType: HistoryEntryType.DUPLICATE,
    objects: objects.map((o) => ({
      ...o,
      top: o.top + translate.y,
      left: o.left + translate.x,
    })),
    sourceProjectId,
  },
});

// "duplicated" is triggered synchronously

// "copied" is triggered once server finishes tile duplication

export const copiedAction = ({
  objects: items,
  sourceProjectId,
  originalUuids,
}: HistoryCopyPayload): HistoryEntryAction<HistoryCopy> => {
  const { objects, connections } = separateObjectsAndConnections(items);
  const targetObjects = objects.filter(
    // top level objects only, we don't care about children
    (o) => !isInCollection(o)
  );

  return {
    type: HistoryActionType.HISTORY_ADD_ENTRY,
    payload: {
      transformationType: HistoryEntryType.COPY,
      objectType: getObjectType(objects), // Only objects can be copied
      sourceProjectId,
      originalUuids,
      targetConnections: connections.map(toTileRelation),
      targetObjects: targetObjects.map(toTileStatus),
      targetChildren: targetObjects
        .flatMap((o) => (isCollection(o) ? o.getObjects() : []))
        .map(toTileStatus),
    },
  };
};

export const uploadedAction = (
  object: CollaboardBlobObject | fabric.CollaboardStorageObject
): HistoryEntryAction<HistoryUploaded> => ({
  type: HistoryActionType.HISTORY_NOOP,
  payload: {
    transformationType: HistoryEntryType.UPLOAD,
    uploadedObject: toTileStatus(object),
  },
});

export const removedAction = ({
  objects: items,
  reversible = true,
}: RemovedEvent): HistoryEntryAction<HistoryRemoved> => {
  const { objects, connections } = separateObjectsAndConnections(items);
  return {
    type: reversible
      ? HistoryActionType.HISTORY_ADD_ENTRY
      : HistoryActionType.HISTORY_NOOP,
    payload: {
      transformationType: HistoryEntryType.REMOVE,
      objectType: getObjectType(items),
      affectedUuids: items.map((i) => i.uuid),
      removedConnections: connections.map(toTileRelation),
      removedObjects: flatten(objects, toTileStatus),
      removedChildren: flattenChildren(objects, toTileStatus),
      removedAttachments: objects
        .flatMap((o) => o._getAllAttachments())
        .map(toTileStatus),
    },
  };
};

export const connectedAction = (
  connection: fabric.CollaboardConnector
): HistoryEntryAction<HistoryConnected> => {
  const { origin, destination } = connection._settings;
  const affectedUuids = [origin?.uuid, destination?.uuid].filter(isDefined);
  return {
    type: HistoryActionType.HISTORY_ADD_ENTRY,
    payload: {
      transformationType: HistoryEntryType.CONNECT,
      objectType: origin?.type,
      affectedUuids,
      addedConnections: [toTileRelation(connection)],
    },
  };
};

export const modifiedAction = ({
  object,
  transform,
}: HistoryModifiedPayload<TileStatus>): HistoryEntryAction<HistoryModified> => {
  const {
    action,
    originalObjects,
    resultingObjects,
    skipTopLevelObjectsCheck,
    reversible,
  } = transform;

  const deltas = toDiffTiles(originalObjects, resultingObjects);
  const hasDelta = deltas.result.length > 0;

  return {
    type:
      reversible && hasDelta
        ? HistoryActionType.HISTORY_ADD_ENTRY
        : HistoryActionType.HISTORY_NOOP,
    payload: {
      transformationType: HistoryEntryType.MODIFY,
      objectType: object.type,
      affectedUuids: originalObjects.map((o) => o.TileId),
      modificationType: action,
      deltas,
      originalObjects,
      resultingObjects,
      skipTopLevelObjectsCheck,
    },
  };
};

export const updatedContextPropsAction = ({
  object,
  transform,
}: HistoryModifiedPayload<TileStatus>): HistoryEntryAction<HistoryUpdatedContextProps> => {
  const { originalObjects, resultingObjects, reversible } = transform;
  const deltas = toDiffTiles(originalObjects, resultingObjects);
  const hasDelta = deltas.result.length > 0;

  return {
    type:
      reversible && hasDelta
        ? HistoryActionType.HISTORY_ADD_ENTRY
        : HistoryActionType.HISTORY_NOOP,
    payload: {
      transformationType: HistoryEntryType.UPDATE,
      objectType: object.type,
      affectedUuids: originalObjects.map((o) => o.TileId),
      deltas,
      skipTopLevelObjectsCheck: true,
    },
  };
};

export const updatedConnectionPropsAction = ({
  originalObjects,
  resultingObjects,
  reversible,
}: HistoryModifyTransform<TileRelation>): HistoryEntryAction<HistoryUpdatedConnectionProps> => ({
  type: reversible
    ? HistoryActionType.HISTORY_ADD_ENTRY
    : HistoryActionType.HISTORY_NOOP,
  payload: {
    transformationType: HistoryEntryType.UPDATE_CONNECTION,
    objectType: canvasObjectIds.connector,
    affectedUuids: originalObjects.map((c) => c.uuid),
    originalConnections: originalObjects,
    resultingConnections: resultingObjects,
  },
});

export const groupAction = ({
  addedGroup: groupObject,
  topLevelObjectsBeforeGroup,
  childrenByGroupBeforeGroup,
  groupsBeforeGroup,
}: HistoryGroupPayload): HistoryEntryAction<HistoryGroup> => ({
  type: HistoryActionType.HISTORY_ADD_ENTRY,
  payload: {
    transformationType: HistoryEntryType.GROUP,
    objectType: groupObject.type,
    addedGroup: toTileStatus(groupObject),
    allChildrenAfterGroup: groupObject.getObjects().map(toTileStatus),
    topLevelObjectsBeforeGroup,

    groupsBeforeGroup,
    childrenByGroupBeforeGroup,
  },
});

export const ungroupAction = ({
  groupBeforeUngroup: groupObject,
  childrenBeforeUngroup = groupObject.getObjects(),
  removedObjects = [],
}: HistoryUnGroupPayload): HistoryEntryAction<HistoryUnGroup> => {
  const {
    connections: childrenConnections,
    objects: childrenToRemove,
  } = separateObjectsAndConnections(removedObjects);
  const groupConnections = groupObject.connections.getConnections();
  const connections = [...childrenConnections, ...groupConnections];

  return {
    type: HistoryActionType.HISTORY_ADD_ENTRY,
    payload: {
      transformationType: HistoryEntryType.UNGROUP,
      objectType: groupObject.type,
      removedChildren: childrenToRemove.map(toTileStatus),
      groupBeforeUngroup: toTileStatus(groupObject),
      removedConnections: connections.map(toTileRelation),
      childrenBeforeUngroup: childrenBeforeUngroup.map(toTileStatus),
      objectsAfterUngroup: childrenBeforeUngroup.map(toDecomposedObjectTile),
    },
  };
};

export const ungroupAllAction = ({
  groupsBeforeUngroup,
  childrenBeforeUngroup,
  topLevelObjectsBeforeUngroup,
}: HistoryUnGroupAllPayload): HistoryEntryAction<HistoryUnGroupAll> => ({
  type: HistoryActionType.HISTORY_ADD_ENTRY,
  payload: {
    transformationType: HistoryEntryType.UNGROUP_ALL,
    objectType: canvasObjectIds.group,
    childrenBeforeUngroup: childrenBeforeUngroup.map(toTileStatus),
    topLevelObjectsBeforeUngroup: topLevelObjectsBeforeUngroup.map(
      toTileStatus
    ),
    objectsAfterUngroup: childrenBeforeUngroup.map(toDecomposedObjectTile),
    groupsBeforeUngroup: groupsBeforeUngroup.map(toTileStatus),
  },
});

export const stackAction = (
  stackObject: fabric.Stack
): HistoryEntryAction<HistoryStack> => ({
  type: HistoryActionType.HISTORY_ADD_ENTRY,
  payload: {
    transformationType: HistoryEntryType.STACK,
    objectType: stackObject.type,
    addedStack: toTileStatus(stackObject),
    childrenAfterStack: stackObject.getObjects().map(toTileStatus),
    objectsBeforeStack: stackObject
      .getObjects()
      .map((o) => o._preservedObjectTransform)
      .filter(isDefined),
  },
});

export const unstackAction = (
  stackObject: fabric.Stack
): HistoryEntryAction<HistoryUnStack> => {
  const objects = stackObject.getObjects();
  const childrenBeforeStack = objects
    .map((o) => o._preservedObjectTransform)
    .filter(isDefined);

  const stackConnections = stackObject.connections.getConnections();

  return {
    type: HistoryActionType.HISTORY_ADD_ENTRY,
    payload: {
      transformationType: HistoryEntryType.UNSTACK,
      objectType: stackObject.type,
      removedStack: toTileStatus(stackObject),
      removedConnections: [...stackConnections].map(toTileRelation),
      childrenBeforeUnstack: objects.map(toTileStatus),
      objectsAfterUnstack: objects.map(toDecomposedObjectTile),
      objectsBeforeStack: childrenBeforeStack.length
        ? childrenBeforeStack
        : null,
    },
  };
};

export const stackRotatedAction = ({
  stack,
  isRotatedLeft,
}: HistoryStackRotatedPayload): HistoryEntryAction<HistoryStackRotated> => ({
  type: HistoryActionType.HISTORY_ADD_ENTRY,
  payload: {
    transformationType: HistoryEntryType.STACK_ROTATE,
    objectType: stack.type,
    affectedUuids: [stack.uuid],
    stackUuid: stack.uuid,
    rotationState: stack.getObjects().map(toGroupedTile),
    isRotatedLeft,
  },
});

export const lockedAction = ({
  status,
  types,
}: HistoryLockPayload): HistoryEntryAction<HistoryLock> => ({
  type: HistoryActionType.HISTORY_ADD_ENTRY,
  payload: {
    transformationType: HistoryEntryType.LOCK,
    affectedUuids: status.map(({ uuid }) => uuid),
    objectType: types.length > 1 ? canvasObjectIds.activeSelection : types[0],
    newStatus: status,
    oldStatus: status.map(({ uuid, isLocked }) => ({
      uuid,
      isLocked: !isLocked,
    })),
  },
});

export const movedLayerAction = ({
  order,
  targetType,
  reversible,
  isSubselect,
}: HistoryMoverLayerPayload): HistoryEntryAction<HistoryMovedLayer> => {
  return {
    type: reversible
      ? HistoryActionType.HISTORY_ADD_ENTRY
      : HistoryActionType.HISTORY_NOOP,
    payload: {
      transformationType: HistoryEntryType.MOVE_LAYER,
      objectType: targetType,
      affectedUuids: order.map((o) => o.uuid),
      originalObjects: order.map(toTileStatus),
      ...(isSubselect
        ? {
            oldState: order.map((o) =>
              toGroupedTile(o, o.previousZIndex ?? o.zIndex)
            ),
            newState: order.map((o) => toGroupedTile(o, o.zIndex)),
            isSubselect: true,
          }
        : {
            oldState: order.map(({ uuid, previousZIndex, zIndex }) => ({
              TileId: uuid || "",
              ZIndex: previousZIndex ?? zIndex,
            })),
            newState: order.map(({ uuid, zIndex }) => ({
              TileId: uuid || "",
              ZIndex: zIndex,
            })),
            isSubselect: false,
          }),
      skipTopLevelObjectsCheck: isSubselect,
    },
  };
};

export const selectionChangedAction = (
  items: fabric.Object[]
): HistoryEntryAction<HistorySelectionChanged> => {
  const { objects } = separateObjectsAndConnections(items);
  const topLevelObjects = objects
    .map(getLockableParent)
    .filter((o) => isTopLevelZIndexable(o))
    .filter(isDefined);

  return {
    type: HistoryActionType.HISTORY_NOOP,
    payload: {
      transformationType: HistoryEntryType.SELECTION_CHANGED,
      selectedUuids: topLevelObjects.map((obj) => obj.uuid),
    },
  };
};

export const backgroundColorChangedAction = ({
  oldBackgroundColor,
  newBackgroundColor,
}: BackgroundChangePayload): HistoryEntryAction<HistoryBackground> => ({
  type: HistoryActionType.HISTORY_ADD_ENTRY,
  payload: {
    transformationType: HistoryEntryType.CHANGE_BACKGROUND,
    oldBackgroundColor,
    newBackgroundColor,
  },
});
