import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Redeem(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M11.56,15.5a4.31,4.31,0,1,0,4.06,5.35h3.54v1.5a.59.59,0,0,0,1.18,0v-1.5h2.24v1.06a1,1,0,0,0,2.06,0V18.78h-9A4.32,4.32,0,0,0,11.56,15.5Zm-.18,2.06a2.25,2.25,0,0,1,2.3,2.19v.06a2.25,2.25,0,1,1-4.49.07v-.07A2.24,2.24,0,0,1,11.38,17.56Z" />
      <path d="M12.79,12.3a10,10,0,1,1,0,15,1,1,0,0,0-1.46.09,1,1,0,0,0,.09,1.45,12.11,12.11,0,1,0,0-18.12,1,1,0,0,0-.09,1.46,1,1,0,0,0,1.46.09Z" />
    </Icon>
  );
}
export default Redeem;
