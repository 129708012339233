import React, { ReactElement } from "react";
import { Flex, Text, ThemeUIStyleObject } from "theme-ui";
import EyeDropperIcon from "../../../icons/EyeDropper";
import ContextInput from "../forms/input/ContextInput";
import Button from "../../canvas/Button";
import EyeDropper from "./EyeDropper";

type Props = {
  color: string;
  hideEyeDropper?: boolean;
  vertical?: boolean;
  testId?: string;
  onColorChange: (color: string) => void;
};

function ColorHex({
  color,
  hideEyeDropper,
  onColorChange,
  testId,
}: Props): ReactElement {
  return (
    <Flex sx={containerStyle}>
      <Flex sx={inputWrapperStyle} data-cy={testId}>
        <Text sx={hexStyle}>#</Text>
        <ContextInput
          type="color"
          defaultValue={color}
          onChange={onColorChange}
          customStyle={hexInputStyle}
        />
      </Flex>
      {!hideEyeDropper && (
        <EyeDropper onColorPicked={onColorChange} allowTransparentColor>
          {({ isEyeDropperActive, isDisabled, enterEyeDropper }) => (
            <Button
              active={isEyeDropperActive}
              disabled={isDisabled}
              onClick={enterEyeDropper}
              sx={buttonStyle}
            >
              <EyeDropperIcon noShadow />
            </Button>
          )}
        </EyeDropper>
      )}
    </Flex>
  );
}

const inputWrapperStyle: ThemeUIStyleObject = {
  flex: "1 1",
  alignItems: "center",
  justifyContent: "space-between",
};

const containerStyle: ThemeUIStyleObject = {
  alignItems: "center",
  flex: 1,
};

const hexStyle: ThemeUIStyleObject = {
  fontWeight: 600,
  fontSize: "calc(var(--staticGridTile) * 0.5)",
  pr: [2],
};

const hexInputStyle: ThemeUIStyleObject = {
  border: "1px solid",
  flex: 1,
  fontSize: "calc(var(--staticGridTile) * 0.5) !important",
  py: [3],
  mr: [1],
  width: "auto",
  "&::placeholder": {
    fontSize: "calc(var(--staticGridTile) * 0.375)",
  },
};

const buttonStyle: ThemeUIStyleObject = {
  display: "block",
  svg: { display: "block" },
};

export default ColorHex;
