import React, { ReactElement, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { duplicateProject } from "../../../../../api";
import errorCodes from "../../../../../api/errorCodes";
import { NotifyMessageName } from "../../../../../api/signalR/protobuf.codecs";
import { routes } from "../../../../../const";
import Duplicate from "../../../../../icons/Duplicate";
import { selectSignalRConnectionId } from "../../../../../reduxStore/signalR/signalR.reducer";
import collaboard from "../../../../../tools/collaboard";
import { errorToast } from "../../../../../tools/errorToast";
import { useDeferredPromise } from "../../../../shared/hooks/useDeferredPromise";
import { Confirmation } from "../../../../shared/modals";
import { TemplateProps } from "../../../../shared/modals/Modal";
import ModalHeader from "../../../../shared/modals/ModalHeader";

type Props = {
  projectId: string;
};

function DuplicateProjectModalTemplate({
  projectId,
  modalProps,
}: TemplateProps<Props>): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();
  const signalRConnectionId = useSelector(selectSignalRConnectionId);
  const deferredPromise = useDeferredPromise();

  useEffect(() => {
    const { signalRClient } = collaboard;

    const unsubscribeOnNotifyProjectCopied = signalRClient.onMessage(
      NotifyMessageName.ProjectCopiedMessage,
      ({ Base }) => {
        history.push(`${routes.collaboard}/${Base.ProjectId}`);
        toast(t("project.duplicateSuccessful"));
        deferredPromise.resolver();
      },
      { once: true }
    );

    const unsubscribeOnNotifyProjectCopyFailed = signalRClient.onMessage(
      NotifyMessageName.ProjectCopyFailedMessage,
      ({ ErrorCode }) => {
        errorToast(t(`apiError.${errorCodes[ErrorCode]}`));
        deferredPromise.resolver();
      },
      { once: true }
    );

    return () => {
      unsubscribeOnNotifyProjectCopied();
      unsubscribeOnNotifyProjectCopyFailed();
    };
  }, [history, t, deferredPromise]);

  const onConfirm = useCallback(async () => {
    if (!signalRConnectionId) {
      errorToast(t("clientError.websocketNotConnected"));
      return;
    }

    await duplicateProject(projectId, signalRConnectionId);

    /**
     * The `onConfirm` handler should wait until a message has been received,
     * otherwise the modal will close and the listeners will be removed.
     */
    await deferredPromise.promise;
  }, [deferredPromise.promise, projectId, signalRConnectionId, t]);

  return (
    <Confirmation
      header={
        <ModalHeader title={t("dialog.duplicateProject")} Icon={Duplicate} />
      }
      confirmationLabel={t("dialog.duplicate")}
      onConfirm={onConfirm}
      closeOnConfirm
      {...modalProps}
    >
      {t("dialog.duplicateProjectPrompt")}
    </Confirmation>
  );
}

export default DuplicateProjectModalTemplate;
