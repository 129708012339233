import React, { createContext, ReactElement, useEffect } from "react";
import { toast } from "react-toastify";
import PageAuthorized from "../layout/pages/PageAuthorized";
import ProjectsNav from "./projectsNav/ProjectsNav";
import ProjectList from "./projectList/ProjectList";
import { useProjects } from "./useProjects";
import { noop } from "../../../tools/utils";
import { useTrackEventToLog } from "../../shared/tracking/useTrackEventToLog";
import SpacesNav from "../../spaces/SpacesNav";
import { useUISettings } from "../../../reduxStore/uiSettings";
import { dynamicFeatureFlags } from "../../../tools/flags";
import { useIsPremium } from "../../licensing/useIsPremium";
import useProjectUrlParams from "./useProjectUrlParams";
import { LogCategory } from "../../../tools/telemetry";

export type ProjectsState = {
  Projects: ApiProject[];
  projectListError: Error | string | null;
  isProjectListLoading: boolean;
  query: string;
  hasMore?: boolean;
  refreshProjects: () => void;
  updateProject(project: Project): void;
  removeProjectFromList(projectId: string): void;
  updateSpace: (projectId: string, spaceId: number | null) => void;
  updateTags: (projectId: string, tags: string[]) => void;
  loadMoreProjects: () => void;
};

export type ProjectsContextState = {
  current: ProjectsState;
  all: ProjectsState;
  filtered: ProjectsState;
  owned: ProjectsState;
};

const defaultProjectsState = {
  Projects: [],
  projectListError: null,
  isProjectListLoading: false,
  hasMore: true,
  query: "",
  loadMoreProjects: noop,
  refreshProjects: noop,
  updateProject: noop,
  removeProjectFromList: noop,
  updateSpace: noop,
  updateTags: noop,
};

const defaultContextState = {
  current: defaultProjectsState,
  all: defaultProjectsState,
  filtered: defaultProjectsState,
  owned: defaultProjectsState,
};

export const ProjectsContext = createContext<ProjectsContextState>(
  defaultContextState
);

const allSpaceId = -1;

function Projects(): ReactElement {
  const projects = useProjects();
  const {
    params: { spaceId },
    filterBySpaceId,
  } = useProjectUrlParams();
  const { trackEvent } = useTrackEventToLog();
  const { isDynamicFeatureActive } = useUISettings();
  const isPremium = useIsPremium();

  const isSpacesEnabled =
    isDynamicFeatureActive(dynamicFeatureFlags.SPACES) && isPremium;

  useEffect(() => {
    trackEvent(LogCategory.navigation, {
      subcategory: "page-projects",
    });
  }, [trackEvent]);

  useEffect(() => {
    return () => toast.dismiss();
  }, []);

  const onSpaceChange = (spaceId: number) => {
    filterBySpaceId(spaceId === allSpaceId ? null : spaceId);
  };

  return (
    <PageAuthorized>
      <ProjectsContext.Provider value={projects}>
        {isSpacesEnabled && (
          <SpacesNav
            activeSpaceId={spaceId ?? allSpaceId}
            headSpaceId={allSpaceId}
            isProjectsPage
            onSpaceChange={onSpaceChange}
          />
        )}
        <ProjectsNav activeSpaceId={spaceId} />
        <ProjectList />
      </ProjectsContext.Provider>
    </PageAuthorized>
  );
}

export default Projects;
