import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Delete(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M14.51,28.37H25.13a1.17,1.17,0,0,0,1.16-1.17V15.56L27,14.9V13.84H12.7V14.9l.65.66V27.2A1.17,1.17,0,0,0,14.51,28.37Zm8.28-12.81h1.32v11H22.79Zm-3.64,0h1.31v11H19.15Zm-3.65,0h1.31v11H15.5Z" />
      <path d="M27.87,13.21v-.5a1.12,1.12,0,0,0-1.12-1.12H22v-.25A1.36,1.36,0,0,0,20.61,10H19a1.37,1.37,0,0,0-1.37,1.37v.25H12.89a1.12,1.12,0,0,0-1.12,1.12v.5Zm-9.22-1.87A.38.38,0,0,1,19,11h1.57a.38.38,0,0,1,.38.38v.25H18.65Z" />
    </Icon>
  );
}
export default Delete;
