/**
 * Beware of circular dependencies when adding imports to this file.
 *
 * DO NOT import anything to do with feature flags.
 */
import { DeviceUUID } from "device-uuid";
import type { DropdownItem } from "../features/routes/projects/projectList/DropDown";
import type { Option } from "../features/shared/forms/options/OptionsListItem";
import { range } from "../tools/utils";
import { Anchor, ApiLanguage, ApiPermission, UISizes } from "../types/enum";

const du = new DeviceUUID();
const browser = du.getBrowser(navigator.userAgent);
export const deviceUuid = du.get({ browser });

export enum routes {
  acceptProjectInvitation = "/acceptProjectInvitation",
  acceptTeamInvitation = "/acceptTeamInvitation",
  rejectTeamInvitation = "/rejectTeamInvitation",
  authenticate = "/authenticate",
  collaboard = "/collaboard",
  error = "/error",
  passwordReset = "/passwordReset",
  plans = "/plans",
  projects = "/projects",
  spaces = "/spaces",
  register = "/register",
  registerComplete = "/registerComplete",
  team = "/team",
  importPreview = "/importPreview",
  user = "/user",
  verifyEmail = "/verifyEmail",
  MSTeamsAuth = "/MSTeamsAuth",
  MSTeamsAuthEnd = "/MSTeamsAuthEnd",
  AppSumoLanding = "/appsumo",
  WacomLanding = "/wacom",
  webexAuth = "/webexAuth",
}

// TODO: enum is already available - migrate all uses to enum
export const projectPermissions = {
  noPermission: 0,
  readPermission: 1, // Viewer
  readWritePermission: 2, // Editor
  facilitatorPermission: 20, // Facilitator
  ownerPermission: 255, // Owner
};

export enum ParticipationType {
  direct = 1,
  space = 2,
}

export const projectListNoAccessPermissions: DropdownItem<number> = {
  labelKey: "accessPermissions.noAccess",
  value: projectPermissions.noPermission,
};

export const permissions: DropdownItem<number>[] = [
  {
    labelKey: "accessPermissions.view",
    value: projectPermissions.readPermission,
  },
  {
    labelKey: "accessPermissions.edit",
    value: projectPermissions.readWritePermission,
  },
  projectListNoAccessPermissions,
  {
    labelKey: "accessPermissions.facilitator",
    value: projectPermissions.facilitatorPermission,
  },
  {
    labelKey: "accessPermissions.owner",
    value: projectPermissions.ownerPermission,
  },
];

export const projectListPermissions = permissions.filter(({ value }) =>
  [
    projectPermissions.readPermission,
    projectPermissions.readWritePermission,
    projectPermissions.facilitatorPermission,
    projectPermissions.ownerPermission,
  ].includes(value)
);

export const sharingMinPermission = ApiPermission.readWritePermission;

export enum ProductTypeIDs {
  individual = 1,
  team = 2,
  business = 3,
  enterprise = 4,
}

export enum SubscriptionUserStatuses {
  new = 1,
  pending = 2,
  active = 3,
  inactive = 4,
}

export const allPlans = {
  noLimit: {
    productTypeId: null,
    name: "noLimit",
  },
  free: {
    productTypeId: null,
    name: "free",
  },
  individual: {
    productTypeId: ProductTypeIDs.individual,
    name: "individual",
  },
  team: {
    productTypeId: ProductTypeIDs.team,
    name: "team",
  },
  business: {
    productTypeId: ProductTypeIDs.business,
    name: "business",
  },
  enterprise: {
    productTypeId: ProductTypeIDs.enterprise,
    name: "enterprise",
  },
} as const;

export type PlanName =
  | "free"
  | "noLimit"
  | "individual"
  | "team"
  | "business"
  | "enterprise";

export const OneMB = 1024 * 1024;

export const maxFileSizePerProductName = {
  [allPlans.free.name]: 15 * OneMB,
  [allPlans.individual.name]: 60 * OneMB,
  [allPlans.team.name]: 120 * OneMB,
  [allPlans.business.name]: 200 * OneMB,
  [allPlans.enterprise.name]: 50 * 1024 * OneMB, // 50 GB
  [allPlans.noLimit.name]: 50 * 1024 * OneMB, // 50 GB
} as const;

export const maxProjectCountInFreePlan = 3;

export const activatePenOnTouchDefault = true;

// TODO: camel case enum
export enum canvasObjectIds {
  activeSelection = "activeSelection",
  connector = "connector",
  group = "group",
  inkPath = "inkPath",
  stack = "stack",
  image = "collaboardImage",
  audio = "collaboardAudio",
  video = "collaboardVideo",
  youtube = "youtubeVideo",
  document = "collaboardDocument",
  pdfDocument = "pdfDocument",
  wordDocument = "wordDocument",
  powerPointDocument = "powerPointDocument",
  excelDocument = "excelDocument",
  shape = "collaboardShape",
  stickyNote = "stickyNote",
  text = "freeFormText",
  shapeInnerTextBox = "shapeInnerTextBox",
  urlArea = "urlArea", // TODO: #6554 (LINKS_V2) remove
  line = "line",
  embed = "embed",
  alignmentLine = "alignmentLine",
  chat = "chat",
}

export enum stickyNoteShape {
  square = "square",
  rectangle = "rectangle",
}

export enum SelectionMode {
  areaSelection = "areaSelection",
  multi = "multi",
  standard = "standard",
}

export const applicationMode = {
  pan: "pan",
  draw: "draw",
} as const;

export enum BrushType {
  pencil = "pencil",
  pen = "pen",
  brush = "brush",
  highlighter = "highlighter",
  nib = "nib",
  rubber = "rubber", // TODO: rename eraser when v1 is removed
}

export const brushTypeConfig = {
  [BrushType.pencil]: {
    min: 4,
    max: 100,
    default: 6,
    marks: range(4, 100, 100 / 4),
    brush: "/brush-pencil.png",
    brushType: BrushType.pencil,
  },
  [BrushType.pen]: {
    min: 1,
    max: 100,
    default: 4,
    marks: range(1, 100, 100 / 4),
  },
  [BrushType.brush]: {
    min: 5,
    max: 100,
    default: 35,
    marks: range(5, 100, 100 / 4),
    brush: "/brush-brush.png",
  },
  [BrushType.highlighter]: {
    min: 1,
    max: 100,
    default: 18,
    marks: range(1, 100, 100 / 4),
  },
  [BrushType.nib]: {
    min: 1,
    max: 100,
    default: 12,
    marks: range(1, 100, 100 / 4),
  },
  [BrushType.rubber]: {
    min: 1,
    max: 100,
    default: 12,
    marks: range(1, 100, 100 / 4),
  },
} as const;

export enum colorThemes {
  dark = "dark",
  light = "light",
}

export enum SearchImageSizeFilter {
  large = "Large",
  medium = "Medium",
  small = "Small",
}

export const searchImageSizeFilters = [
  SearchImageSizeFilter.large,
  SearchImageSizeFilter.medium,
  SearchImageSizeFilter.small,
];

export const leadColors: Record<colorThemes, string> = {
  [colorThemes.light]: "#ffffff",
  [colorThemes.dark]: "#000000",
};

// Should provide sufficient contrast (see #6545). Aligned with `--mixedValuesPlaceholder`
export const mixedColor = {
  [colorThemes.light]: "#666666",
  [colorThemes.dark]: "#c6c6c5",
};

const initialColors: ColorPalette = ["#ca1e2b", "#ff512e"];

const commonColors: ColorPalette = [
  "#fce949",
  "#cfd93e",
  "#00aa4a",
  "#00a1d9",
  "#006baf",
  "#952681",
  "#ff117f",
  "#dddddd",
  "#000000",
];

export const objectColorPalette: ColorPalette = [
  ...initialColors,
  "#ffa647",
  ...commonColors,
];

export const objectColorPaletteWithTransparency: ColorPalette = [
  "transparent",
  ...initialColors,
  ...commonColors,
];

export const backgroundColors: Record<colorThemes, ColorPalette> = {
  [colorThemes.light]: [
    "#f72828",
    "#f79616",
    "#ffe133",
    "#25cc54",
    "#029347",
    "#24c1ff",
    "#0595ce",
    "#4460ea",
    "#9a65e5",
    "#ed4cb3",
    "#cbcdce",
    "#ffffff",
  ],
  [colorThemes.dark]: [
    "#b21b2d",
    "#ff3300",
    "#ff9933",
    "#009933",
    "#006633",
    "#0099cc",
    "#006699",
    "#0a0abc",
    "#663399",
    "#dc117f",
    "#666666",
    "#444444",
  ],
};

export const userColors = [
  "#f4f476",
  "#5a9ded",
  "#d85035",
  "#43c4b8",
  "#ce38c7",
  "#92dd57",
  "#d83068",
  "#efd435",
  "#7cd6f2",
  "#583382",
  "#44c156",
  "#aa3c3c",
  "#4763d7",
  "#f28e3d",
  "#262b84",
  "#479b3b",
  "#dd58ad",
  "#4294c6",
  "#843acc",
  "#44a579",
] as const;

export const fontFamilies = [
  "Segoe UI", // "system" font family - needs to be the 1st item in the array
  ...[
    "Roboto:300,400,500",
    "Caveat",
    "Cormorant Garamond",
    "Crimson Text",
    "Dancing Script",
    "Indie Flower",
    "Kaushan Script",
    "Montserrat",
    "Oswald",
    "Prata",
    "Press Start 2P",
    "Shrikhand",
    "Special Elite",
    "Orbitron",
    "Rokkitt",
    "Tourney",
    "Ruda",
    "Reem Kufi",
    "Sora",
    "Grandstander",
    "Newsreader",
    "Grenze Gotisch",
    "Oxanium",
    "Kufam",
  ].sort(),
];

export const fontOptions: Option[] = fontFamilies.map((font) => {
  const fontName = font.split(":")[0]; // For fonts like 'Roboto:300,400,500'

  return {
    key: fontName,
    label: fontName,
  };
});

export const stickyNoteFontSizes = [10, 12, 14, 18, 24, 32, 48, 64];
export const fontSizes = [...stickyNoteFontSizes, 80, 144, 288];

export const zoomInitialLevel = 1;
export const zoomMin = 0.01; // min zoom 1%
export const zoomMax = 4; // max zoom 400%
export const zoomMaxSteps = 70;
export const zoomWheelSpeedLimit = 30;

export const initialObjectWidth = 200;
export const initialObjectHeight = 200;
export const minObjectWidth = initialObjectWidth / 20;
export const minObjectHeight = initialObjectHeight / 20;
export const stickyNoteSquareWidth = initialObjectWidth;
export const stickyNoteRectWidth = 300;
export const historyLength = 32;
export const defaultFontSize = fontSizes[6]; // 48
export const minFontSize = fontSizes[0];
export const maxStickyFontSize =
  stickyNoteFontSizes[stickyNoteFontSizes.length - 1];
export const maxShapeFontSize = 40_000; // Can basically use any fontSize
export const maxFontSize = 40_000; // Can basically use any fontSize
export const minShapeStrokeWidth = 1;
export const maxShapeStrokeWidth = 15;
export const selectionPadding = 30;

export const sizeOptions: Option[] = fontSizes.map((size) => ({
  key: String(size),
  label: String(size),
}));

// Note, there are currently no defaults for text-in-shape in the Settings panel.
// If we ever add that then these values need to be moved to state.
export const defaultShapeContext: ContextProps = {
  fontFamily: fontFamilies[0],
  fontSize: fontSizes[5],
  textAlign: "center",
  textPlacement: "center",
};

export enum blobStates {
  notOnAzure = 1,
  onAzure = 2,
  creatingThumbnails = 5,
  thumbnailsAvailable = 6,
  thumbnailError = 7,
  uploadError = 10,
  deleted = 11,
}

export enum CameraTypes {
  rear = "environment",
  front = "user",
}

export const penDevices = ["pen", "stylus"];

export const defaultProjectTheme = {
  backgroundColor: leadColors[colorThemes.light],
  theme: colorThemes.light,
};

export const googleFontsConfig = {
  google: {
    // Note: Google Fonts doesn't support Segoe UI
    families: fontFamilies,
  },
};
export const customFontsConfig = {
  custom: { families: fontFamilies, urls: ["/fonts/fonts.css"] },
};

export const rejectDocumentExtensions = [".csv"] as const;

export const documentTypes: { [key: string]: canvasObjectIds } = {
  "application/pdf": canvasObjectIds.pdfDocument,
  "application/msword": canvasObjectIds.wordDocument,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    canvasObjectIds.wordDocument,
  "application/vnd.ms-excel": canvasObjectIds.excelDocument,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    canvasObjectIds.excelDocument,
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    canvasObjectIds.powerPointDocument,
  "application/vnd.ms-powerpoint": canvasObjectIds.powerPointDocument,
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow":
    canvasObjectIds.powerPointDocument,
  ".pdf": canvasObjectIds.pdfDocument,
  ".doc": canvasObjectIds.wordDocument,
  ".docx": canvasObjectIds.wordDocument,
  ".xls": canvasObjectIds.excelDocument,
  ".xlsx": canvasObjectIds.excelDocument,
  ".ppt": canvasObjectIds.powerPointDocument,
  ".pptx": canvasObjectIds.powerPointDocument,
};

export const acceptedImageFileTypes = ["jpg", "png", "tiff", "svg"];

export const acceptedImageTypes: string[] = [
  "image/svg+xml",
  "image/jpeg",
  "image/png",
  "image/tiff",
  ".jpg",
  ".jpeg",
  ".png",
  ".tiff",
  ".svg",
];

export const acceptedVideoFileTypes = ["mp4", "mov", "webm"];

export const acceptedVideoTypes = [
  "video/mp4",
  "video/quicktime",
  "video/webm",
  ".mp4",
  ".mov",
  ".webm",
];

export const acceptedDocFileTypes = ["word", "ppt", "excel", "pdf"];

export const acceptedDocTypes = Object.keys(documentTypes);

export const allAcceptedFileTypes = [
  ...acceptedDocTypes,
  ...acceptedImageTypes,
  ...acceptedVideoTypes,
];

export const allAcceptedFileExtensions = allAcceptedFileTypes.filter((type) =>
  type.startsWith(".")
);

export const acceptedUserImportSpreadsheets = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "application/vnd.oasis.opendocument.spreadsheet",
  "text/csv",
  "text/plain",
];

export const maxAvatarSizeKB = 128;

export const connectorModes = {
  // NOTE: The values must match the server's values for `TileRelation.Style`
  bezier: 0,
  direct: 1,
  perpendicular: 2,
} as const;

export enum ArrowVisibilityMode {
  none = 0,
  origin = 1,
  destination = 2,
  originDestination = 3,
}

export const connectionAnchorIDs = {
  // NOTE: The values must match the server's values for `TileRelation.Anchor*`
  [Anchor.top]: 0,
  [Anchor.bottom]: 1,
  [Anchor.left]: 2,
  [Anchor.right]: 3,
  // TODO: auto is not support by the server
  [Anchor.auto]: 4,
};

export const connectionAnchorMapping = {
  mt: Anchor.top,
  mb: Anchor.bottom,
  ml: Anchor.left,
  mr: Anchor.right,
} as const;

export enum ArrowHeadMode {
  // NOTE: The values must match the server's values for `TileRelation.Symbol*`
  none = 0,
  diamond = 1,
  arrow1 = 2,
  arrow2 = 3,
  t = 4,
  square = 5,
  circle = 6,
}

export const connectorLineModes = {
  // NOTE: The values must match the server's values for `TileRelation.Line`
  solid: 0,
  dashed: 1,
  dotted: 2,
} as const;

export const moveStep = 5;

export const canvasZIndex = 100;
export const inactiveEmbedZIndex = canvasZIndex + 1;
export const activeEmbedZIndex = inactiveEmbedZIndex + 1;
export const objectLinkZIndex = activeEmbedZIndex + 1;
export const borderOverlayZIndex = objectLinkZIndex + 1;
export const toolPanelsZindex = borderOverlayZIndex + 1;
export const draggableWindowZIndex = toolPanelsZindex + 1;
export const contextMenuZIndex = draggableWindowZIndex + 1;
export const drawingToolbarZIndex = contextMenuZIndex + 1;
export const chatZIndex = drawingToolbarZIndex + 1;
export const sidebarZIndex = chatZIndex + 1;
export const fileDropOverlayZIndex = sidebarZIndex + 1;
export const settingsPanelZIndex = fileDropOverlayZIndex + 1;
export const zoomWarningZIndex = settingsPanelZIndex + 1;
export const hurryUpOverlayZIndex = zoomWarningZIndex + 1;
export const fullscreenElementZIndex = hurryUpOverlayZIndex + 1;
// NOTE: .Toastify__toast-container zIndex in between
export const permissionsDropdownZIndex = 99999;
export const modalZIndex = 100000; // overlay must be higher then opened dropdowns
export const pageCoverZIndex = 100001;
// NOTE: .rc-tooltip zIndex in between
export const dropdownOptionZIndex = 200000;
// NOTE: all above zIndexes must be below .Toastify

export const canvasThumbnailSize = 400;

export const inactivityTimeout = 2 * 60 * 60 * 1000;

export const companySizes = {
  micro: 1,
  xsmall: 2,
  small: 3,
  medium: 4,
  large: 5,
  xlarge: 6,
  corporation: 7,
  conglomerate: 8,
} as const;

export const companyIndustries = {
  accounting: 1,
  administration: 2,
  advertising: 3,
  banking: 4,
  callCentre: 5,
  communityServices: 6,
  construction: 7,
  consulting: 8,
  architecture: 9,
  education: 10,
  engineering: 11,
  farming: 12,
  government: 13,
  healthcare: 14,
  tourism: 15,
  hr: 16,
  it: 17,
  insurance: 18,
  legal: 19,
  logistics: 20,
  marketing: 21,
  energy: 22,
  realEstate: 23,
  retail: 24,
  sales: 25,
  science: 26,
  sport: 27,
  trade: 28,
} as const;

export const preventCanvasScrollClass = "prevent-scroll";
export const preventModalScrollClass = "prevent-scroll-modal";
export const isPresentationViewerClass = "presentation-viewer";
export const popupWindowClass = "popup-window";

export enum SnapCoord {
  left = "left",
  right = "right",
  middleHorizontal = "middleHorizontal",
  top = "top",
  middleVertical = "middleVertical",
  bottom = "bottom",
}

export enum AlignmentLine {
  alignmentLineX = "_alignmentLineX",
  alignmentLineY = "_alignmentLineY",
}

// minimal width of the object's background in the voting mode
export const minBackgroundWidth = 100;
export const minVideoPlayerWidth = 240;

export const newObjectsPerRow = 5;
export const newObjectOffset = 50;

export const contactPageUrl = "https://www.collaboard.app/contact-us";

// https://developer.mozilla.org/en-US/docs/Web/API/PointerEvent/pressure
export const defaultPointerPressure = 0.5;
// mapping which shortcut should execute which quicklink

export const quickLinkShortcutsCodes = [
  "Digit1",
  "Digit2",
  "Digit3",
  "Digit4",
  "Digit5",
  "Digit6",
  "Digit7",
  "Digit8",
  "Digit9",
  "KeyA",
  "KeyB",
  "KeyC",
  "KeyD",
  "KeyE",
  "KeyF",
  "KeyG",
  "KeyH",
  "KeyI",
  "KeyJ",
  "KeyK",
  "KeyL",
  "KeyM",
  "KeyN",
  "KeyO",
  "KeyP",
  "KeyQ",
  "KeyR",
  "KeyS",
  "KeyT",
  "KeyU",
  "KeyV",
  "KeyW",
  "KeyX",
  "KeyY",
  "KeyZ",
];

export const uiSizeMultiplier = {
  [UISizes.Default]: 1,
  [UISizes.Medium]: 1.1,
  [UISizes.Large]: 1.2,
};

export enum CopyTilesTrigger {
  CopyTiles = 0,
  ApplyProjectTemplate = 1,
}

// These values should not be localized. See #6624
export const ApiLanguageLabel: Record<ApiLanguage, string> = {
  ar: "العربية",
  de: "Deutsch",
  en: "English",
  es: "Español",
  fr: "Français",
  it: "Italiano",
  ja: "日本語",
};

export const objectThumbnailWidth = 128;
