import { DragEvent, ReactElement } from "react";
import { AddNewObject } from "../reduxStore/canvas/objects/crud/crud.actions";

type DragEnableProps = {
  draggable: boolean;
  onDragStart?: (e: DragEvent) => void;
};
type DragEnableFunc<P> = (props?: P) => DragEnableProps;
type BaseComp<P, T> = (
  props: P,
  enableDrag: DragEnableFunc<T>
) => ReactElement<P>;
type WithDragProps<P, T> = P & {
  toDraggedObject?: (objectProps?: T) => ObjectConstructorConfig;
};

/**
 * HOC, adding tiles to canvas on components drop. Usage:
 *
 * const DraggableButton = withDrag((props, enableDrag) => (
 *   <Button {...enableDrag()} />
 * );
 *
 * <DraggableButton toDraggedObject={() => ({ type: "stickyNote" })} />
 *
 * You can also pass additional params to `enableDrag`. They will be handed over to `toDraggedObject` function
 */

export const withDrag = <P, T>(BaseComponent: BaseComp<P, T>) => (
  props: WithDragProps<P, T>
): ReactElement<P> => {
  const { toDraggedObject } = props;
  const enableDrag: DragEnableFunc<T> = (params) => ({
    draggable: !!toDraggedObject,
    onDragStart: toDraggedObject
      ? (e) => {
          e.stopPropagation();
          const serializedObject = JSON.stringify(toDraggedObject(params));
          e.dataTransfer?.setData("canvasObject", serializedObject);
        }
      : undefined,
  });
  return BaseComponent(props, enableDrag);
};

export const extractDroppedObject = (
  e: DragEvent
): AddNewObject | undefined => {
  const serializedObject = e.dataTransfer?.getData("canvasObject");

  return serializedObject
    ? {
        object: JSON.parse(serializedObject) as ObjectConstructorConfig,
        config: {
          pointer: { x: e.pageX, y: e.pageY },
        },
      }
    : undefined;
};
