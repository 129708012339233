import React from "react";

import allCountries from "../../../countries/allCountries";
import { useCountryTranslations } from "../../../countries/useCountryTranslations";

function CountriesSelectMobile({ handleSelect, country, theme }) {
  const { countries, loading, error } = useCountryTranslations();

  if (loading || error) {
    return null;
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-onchange
    <select onChange={handleSelect()}>
      {allCountries.map(
        ({ iso2, dialCode, isAreaCode }) =>
          !isAreaCode && (
            <option
              key={`${iso2}${dialCode}`}
              value={iso2}
              sx={{
                bg: iso2 === country.iso2 && theme.colors.accent,
              }}
            >
              {countries[(iso2 || "").toUpperCase()]}
            </option>
          )
      )}
    </select>
  );
}

export default CountriesSelectMobile;
