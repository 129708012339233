import { projectPermissions } from "../../../../const";
import { DropdownItem } from "../../../routes/projects/projectList/DropDown";

export const permissions: DropdownItem<number>[] = [
  {
    labelKey: "accessPermissions.view",
    value: projectPermissions.readPermission,
  },
  {
    labelKey: "accessPermissions.edit",
    value: projectPermissions.readWritePermission,
  },
  {
    labelKey: "accessPermissions.noAccess",
    value: projectPermissions.noPermission,
  },
];

export const emailPermissions = [permissions[0], permissions[1]];

export const shareTypes = {
  code: "code",
  link: "link",
};
