import type {
  GenericAvailableMessage,
  NotifyLogInProjectMessage,
  NotifyLogOutProjectMessage,
  NotifyPingbackMessage,
  ProjectParticipantMessage,
} from "../../../../api/signalR/message.types";

export enum NotifyProjectActionType {
  ON_NOTIFY_PINGBACK = "ON_NOTIFY_PINGBACK",
  ON_NOTIFY_USER_AVAILABLE = "ON_NOTIFY_USER_AVAILABLE",
  SET_USER_OFFLINE = "SET_USER_OFFLINE",
  SET_USER_ONLINE = "SET_USER_ONLINE",
  UPDATE_PERMISSION = "UPDATE_PERMISSION",
}

export const onNotifyUserAvailableAction = (payload: GenericAvailableMessage) =>
  ({
    type: NotifyProjectActionType.ON_NOTIFY_USER_AVAILABLE,
    payload,
  } as const);

export const onNotifyPingbackRequestAction = (payload: NotifyPingbackMessage) =>
  ({
    type: NotifyProjectActionType.ON_NOTIFY_PINGBACK,
    payload,
  } as const);

export const setUserOfflineAction = (payload: NotifyLogOutProjectMessage) =>
  ({
    type: NotifyProjectActionType.SET_USER_OFFLINE,
    payload,
  } as const);

export const setUserOnlineAction = (payload: NotifyLogInProjectMessage) =>
  ({
    type: NotifyProjectActionType.SET_USER_ONLINE,
    payload,
  } as const);

export const updatePermissionAction = (payload: ProjectParticipantMessage) =>
  ({
    type: NotifyProjectActionType.UPDATE_PERMISSION,
    payload,
  } as const);

export type OnNotifyUserAvailableAction = ReturnType<
  typeof onNotifyUserAvailableAction
>;
export type OnNotifyPingbackRequestAction = ReturnType<
  typeof onNotifyPingbackRequestAction
>;
export type SetUserOfflineAction = ReturnType<typeof setUserOfflineAction>;
export type SetUserOnlineAction = ReturnType<typeof setUserOnlineAction>;
export type UpdatePermissionAction = ReturnType<typeof updatePermissionAction>;

export type NotifyProjectAction =
  | OnNotifyUserAvailableAction
  | OnNotifyPingbackRequestAction
  | SetUserOfflineAction
  | SetUserOnlineAction
  | UpdatePermissionAction;
