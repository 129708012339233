import { updatedDiff } from "deep-object-diff";
import { fabric } from "fabric";

type DimensionsProps = Pick<
  fabric.TextboxWithMixins,
  | "fontFamily"
  | "fontSize"
  | "fontStyle"
  | "fontWeight"
  | "textAlign"
  | "textPlacement"
  | "width"
>;

export const getDimensionProps = (
  object: fabric.TextboxWithMixins
): DimensionsProps => {
  return {
    fontFamily: object.fontFamily,
    fontSize: object.fontSize,
    fontStyle: object.fontStyle,
    fontWeight: object.fontWeight,
    textAlign: object.textAlign,
    textPlacement: object.textPlacement,
    width: object.width,
  };
};

/**
 * Get the dimension props that are actually changing.
 *
 * These properties are special because they affect dimensions or
 * require some additional number crunching. Both of which can be
 * expensive.
 *
 * See: `_dimensionAffectingProps` within Fabric.
 *
 * As a result we only include them in the `set()` call if they are
 * actually changing.
 */
export const getDimensionPropsToSet = (
  object: fabric.TextboxWithMixins,
  props: Partial<DimensionsProps>,
  config: SetContextPropsConfig = {}
): Partial<DimensionsProps> => {
  const { isInitialization } = config;

  const currentDimensionProps = getDimensionProps(object);

  const newDimensionProps: DimensionsProps = {
    fontFamily: props.fontFamily ?? currentDimensionProps.fontFamily,
    fontSize: props.fontSize ?? currentDimensionProps.fontSize,
    fontStyle: props.fontStyle ?? currentDimensionProps.fontStyle,
    fontWeight: props.fontWeight ?? currentDimensionProps.fontWeight,
    textAlign: props.textAlign ?? currentDimensionProps.textAlign,
    textPlacement: props.textPlacement ?? currentDimensionProps.textPlacement,
    width: props.width ?? currentDimensionProps.width,
  };

  const dimensionPropsToSet: Partial<DimensionsProps> = isInitialization
    ? newDimensionProps
    : updatedDiff(currentDimensionProps, newDimensionProps);

  const {
    fontFamily: updatedFontFamily,
    ...dimensionPropsToSetWithoutFontFamily
  } = dimensionPropsToSet;

  // Set the font family, but only if it is changing.
  updatedFontFamily && object.setFontFamily(updatedFontFamily);

  /**
   * @NOTE The applied `fontFamily` may be different from the requested `fontFamily` (e.g. text overflows),
   * so ensure we use the value from `object.fontFamily` in our call to `set()`
   */
  const useFontFamily = updatedFontFamily
    ? { fontFamily: object.fontFamily }
    : undefined;

  return {
    ...dimensionPropsToSetWithoutFontFamily,
    ...useFontFamily,
  };
};
