import { Selector } from "react-redux";
import { Reducer } from "redux";
import { createSelector } from "reselect";
import { combineUserInfo } from "../../features/users/users.utils";
import { assertNotUndefined } from "../../tools/assertions";
import { Roles } from "../../types/enum";
import * as AuthActions from "./auth.actions";
import { AuthActionType, AuthUserType } from "./auth.actions";
import { getRolesFromToken } from "./auth.storage";

export enum AuthStatus {
  UNINITIALIZED = "UNINITIALIZED",
  INITIALIZING = "INITIALIZING",
  LOGGED_OUT = "LOGGED_OUT",
  AUTHENTICATING = "AUTHENTICATING",
  AUTHENTICATED = "AUTHENTICATED",
  LOGGING_IN = "LOGGING_IN",
  LOGGED_IN = "LOGGED_IN",
}

export type AuthenticatingDetails = {
  authProvider: string;
  isRegistration: boolean;
  redirect: string | undefined;
  tenant: string | undefined;
};

export type AuthenticatedDetails = AuthenticatingDetails & {
  roles: Roles[];
  userType: AuthUserType;
};

type UninitializedState = {
  status: AuthStatus.UNINITIALIZED;
};
type InitializingState = {
  status: AuthStatus.INITIALIZING;
};
type LoggedOutState = {
  status: AuthStatus.LOGGED_OUT;
};
type AuthenticatingState = {
  status: AuthStatus.AUTHENTICATING;
} & AuthenticatingDetails;
type AuthenticatedState = {
  status: AuthStatus.AUTHENTICATED;
} & AuthenticatedDetails;
type LoggingInState = {
  status: AuthStatus.LOGGING_IN;
} & AuthenticatedDetails;
type LoggedInState = {
  status: AuthStatus.LOGGED_IN;
} & AuthenticatedDetails & {
    userProfile: UserProfile;
  };

export type AuthState =
  | UninitializedState
  | InitializingState
  | LoggedOutState
  | AuthenticatingState
  | AuthenticatedState
  | LoggingInState
  | LoggedInState;

const defaultState: UninitializedState = {
  status: AuthStatus.UNINITIALIZED,
};

export const authReducer: Reducer<AuthState, AuthActions.AuthAction> = (
  state = defaultState,
  action
): AuthState => {
  if (!isAuthState(state)) {
    return state;
  }

  switch (action.type) {
    case AuthActionType.SET_AUTHENTICATING: {
      const { authProvider, isRegistration, redirect, tenant } = action.payload;
      return {
        status: AuthStatus.AUTHENTICATING,
        authProvider,
        isRegistration,
        redirect,
        tenant,
      };
    }
    case AuthActionType.CLEAR_AUTHENTICATING: {
      return isAuthenticatingState(state)
        ? {
            status: AuthStatus.LOGGED_OUT,
          }
        : state;
    }
    case AuthActionType.SET_AUTHENTICATED: {
      const {
        authProvider,
        isRegistration,
        redirect,
        token,
        tenant,
        userType,
      } = action.payload;
      return {
        status: AuthStatus.AUTHENTICATED,
        authProvider,
        isRegistration,
        redirect,
        roles: getRolesFromToken(token.AuthorizationToken),
        tenant,
        userType,
      };
    }
    case AuthActionType.ASSERT_USER: {
      return isAuthenticatedState(state)
        ? {
            ...state,
            status: AuthStatus.LOGGING_IN,
          }
        : state;
    }
    case AuthActionType.LOGGED_IN: {
      return isLoggingInState(state)
        ? {
            ...state,
            status: AuthStatus.LOGGED_IN,
            /** @TODO #7118 - v1 pushed the Language into the profile here */
            userProfile: action.payload,
          }
        : state;
    }
    case AuthActionType.UPDATE_USER_PROFILE: {
      return isLoggedInState(state)
        ? {
            ...state,
            /** @TODO #7118 - v1 pushed the Language into the profile here */
            userProfile: action.payload,
          }
        : state;
    }
    case AuthActionType.SET_AUTHENTICATION_MODE: {
      return isLoggedInState(state)
        ? {
            ...state,
            userProfile: {
              ...state.userProfile,
              AuthenticationMode: action.payload,
            },
          }
        : state;
    }
    case AuthActionType.CLEAR_REDIRECT_AFTER_LOGIN: {
      return isLoggedInState(state)
        ? {
            ...state,
            redirect: undefined,
          }
        : state;
    }
    case AuthActionType.LOGGED_OUT: {
      return {
        status: AuthStatus.LOGGED_OUT,
      };
    }
    case AuthActionType.INIT_AUTH: {
      return {
        status: AuthStatus.INITIALIZING,
      };
    }
  }

  return state;
};

const isAuthState = (state: AuthState | undefined): state is AuthState => {
  return !!state;
};

export const isUninitializedState = (
  state: AuthState
): state is UninitializedState => {
  return state.status === AuthStatus.UNINITIALIZED;
};

export const isLoggedOutState = (state: AuthState): state is LoggedOutState => {
  return state.status === AuthStatus.LOGGED_OUT;
};

export const isAuthenticatingState = (
  state: AuthState
): state is AuthenticatingState => {
  return state.status === AuthStatus.AUTHENTICATING;
};

export const isAuthenticatedState = (
  state: AuthState
): state is AuthenticatedState => {
  return state.status === AuthStatus.AUTHENTICATED;
};

export const isLoggingInState = (state: AuthState): state is LoggingInState => {
  return state.status === AuthStatus.LOGGING_IN;
};

export const isLoggedInState = (state: AuthState): state is LoggedInState => {
  return state.status === AuthStatus.LOGGED_IN;
};

export const selectUserProfile = ({
  auth,
}: ApplicationGlobalState): UserProfile | undefined => {
  return isLoggedInState(auth) ? auth.userProfile : undefined;
};

/**
 * @NOTE Use this method if it can be called only after the user has already logged in, e.g. in project sagas.
 * It's more unsafe than `selectUserProfile` but more convenient.
 */
export const selectAssertedUserProfile = (
  state: ApplicationGlobalState
): UserProfile => {
  return assertNotUndefined(
    selectUserProfile(state),
    "selectAssertedUserProfile"
  );
};

export const selectAssertedUserProfileInfo: Selector<
  ApplicationGlobalState,
  UserProfileInfo
> = createSelector(selectAssertedUserProfile, (userProfile) =>
  combineUserInfo({ user: userProfile, userProfile, index: 0 })
);
