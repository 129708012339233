import { fabric } from "fabric";
import { FreeFormTextVersion } from "../../../types/enum";

(() => {
  fabric.util.object.extend(fabric.FreeFormText.prototype, {
    needsMigration(this: fabric.FreeFormText) {
      return this.version !== FreeFormTextVersion.FreeFormUnscaledText;
    },

    // eslint-disable-next-line consistent-return
    migrateToLatest(this: fabric.FreeFormText): boolean {
      switch (this.version) {
        case FreeFormTextVersion.FreeFormText: {
          this.setTextSize();

          this.version = FreeFormTextVersion.FreeFormUnscaledText;
          return true;
        }
        case FreeFormTextVersion.FreeFormUnscaledText:
          return false;
      }
    },
  });
})();
