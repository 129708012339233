import { IBV } from "@collaboard/protobuf";
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from "@microsoft/signalr";
import { MessagePackHubProtocol } from "@microsoft/signalr-protocol-msgpack";
import { deviceUuid } from "../../const";
import { InternalError } from "../../errors/InternalError";
import { SignalRError } from "../../errors/signalRError";
import { getOrRefreshAuthToken } from "../../reduxStore/auth/auth.storage";
import { isError, stringifyError } from "../../tools/errors";
import { isStaticFeatureActive, staticFeatureFlags } from "../../tools/flags";
import { wait } from "../../tools/promises";
import { runtimeConfig } from "../../tools/runtimeConfig";
import {
  LogCategory,
  onErrorToLog,
  trackEventToLog,
} from "../../tools/telemetry";
import { OverloadCallback } from "../SignalRQueue";
import { SignalRQueueWithBasicMonitoring } from "../SignalRQueueWithBasicMonitoring";
import {
  deserializeIncomingMessage,
  serializeOutgoingMessage,
} from "./message.serializer";
import { IncomingMessageMap } from "./message.serializer.types";
import type {
  AdjustPresentationTimerMessage,
  BackgroundMessage,
  BaseMessage,
  DeleteTileMessage,
  GenericAvailableMessage,
  InitialDataForNewJoinToPresentationMessage,
  InitialDataForNewJoinToTimerMessage,
  PinMessage,
  PostLogInProjectMessage,
  PostLogOutProjectMessage,
  PostNewMessage,
  PostPingbackMessage,
  PostTileBatchActionMessage,
  PostTilePropertyMessage,
  PostTileRelationMessage,
  ProjectParticipantMessage,
  ScreenPosZoomChangedMessage,
  SystemMessage,
  ThumbnailPageChangedMessage,
  TileGroupMessage,
  TileIndexesMessage,
  UploadedMessage,
} from "./message.types";
import {
  MessageDecoderMap,
  messageDecoders,
  messageEncoders,
  NotifyMessageName,
  PostMessageName,
} from "./protobuf.codecs";

export const noAuthTokenError = "NoAuthToken"; // Exported for tests

type OnMessageListener<T extends NotifyMessageName> = (
  message: IncomingMessageMap[T]
) => Promise<void> | void;

type OnMessageConfig = {
  once?: boolean;
};

export type OnMessageType = Exclude<
  NotifyMessageName,
  NotifyMessageName.SystemMessage
>;

type InvokeConfig = {
  resolveDelay: number;
};

export type InvokeMessageMap = {
  [PostMessageName.AdjustPresentationTimerMessage]: AdjustPresentationTimerMessage;
  [PostMessageName.BackgroundMessage]: BackgroundMessage;
  [PostMessageName.DeleteTileMessage]: DeleteTileMessage;
  [PostMessageName.GenericAvailableMessage]: GenericAvailableMessage;
  [PostMessageName.InitialDataForNewJoinToPresentationMessage]: InitialDataForNewJoinToPresentationMessage;
  [PostMessageName.InitialDataForNewJoinToTimerMessage]: InitialDataForNewJoinToTimerMessage;
  [PostMessageName.LogInProjectMessage]: PostLogInProjectMessage;
  [PostMessageName.LogOutProjectMessage]: PostLogOutProjectMessage;
  [PostMessageName.NewMessage]: PostNewMessage;
  [PostMessageName.PingbackMessage]: PostPingbackMessage;
  [PostMessageName.PinMessage]: PinMessage;
  [PostMessageName.ProjectParticipantMessage]: ProjectParticipantMessage;
  [PostMessageName.ScreenPosZoomChangedMessage]: ScreenPosZoomChangedMessage;
  [PostMessageName.ThumbnailPageChangedMessage]: ThumbnailPageChangedMessage;
  [PostMessageName.TileBatchActionMessage]: PostTileBatchActionMessage;
  [PostMessageName.TileGroupMessage]: TileGroupMessage;
  [PostMessageName.TileIndexesMessage]: TileIndexesMessage;
  [PostMessageName.TilePropertyMessage]: PostTilePropertyMessage;
  [PostMessageName.TileRelationMessage]: PostTileRelationMessage;
  [PostMessageName.UploadedMessage]: UploadedMessage;
};

const isRunningForCaptureMode = isStaticFeatureActive(
  staticFeatureFlags.RUN_FOR_CAPTURE
);

const connectionTimeout = 30_000;

/**
 * This class handles the connection and decoding / encoding of messages.
 */
export class SignalRProtobufClient {
  private connectionId?: string;

  protected maxMessageSizeInBytes =
    runtimeConfig.maxSignalRMessageSizeInKB * 1024;

  private connection: HubConnection;
  private signalQueue: SignalRQueueWithBasicMonitoring;

  private onServiceErrorHandler?: (
    message: SystemMessage
  ) => Promise<void> | void;
  private onServiceErrorLimitHandler?: () => Promise<void> | void;
  private serviceErrorLimit = 5;
  private serviceErrorCount = 0;

  private onCloseHandler?: (error?: Error) => Promise<void> | void;
  private onReconnectedHandler?: (connectionId: string) => Promise<void> | void;
  private onReconnectingHandler?: (error?: Error) => Promise<void> | void;
  private onReconnectionFailedHandler?: (error?: Error) => Promise<void> | void;

  private connectionIdMonitor?: () => void;

  protected projectId?: number;

  private stoppedIntentionally = false;

  constructor() {
    this.signalQueue = new SignalRQueueWithBasicMonitoring({
      /**
       * Throttle the queue so that only one signal promise can be active at
       * a time. This ensures that we won't send another message until the
       * server has responded to any inflight message.
       *
       * @NOTE The server's ack response doesn't mean that the command has been
       * processed, just that it has been received and queued.
       *
       * See #7269
       */
      concurrency: 1,
    });

    // Pause the queue until the connection is established
    this.signalQueue.pause();

    this.connection = this.createConnection();

    this.addMessageHandler(
      NotifyMessageName.SystemMessage,
      this.handleServiceError.bind(this)
    );
  }

  /**
   * Start the connection to SignalR.
   */
  public async start(): Promise<string> {
    this.stoppedIntentionally = false;

    /**
     * The canvas shotter does not require a SignalR connection, so prevent
     * a connection from being started (although allow it to be created)
     */
    if (isRunningForCaptureMode) {
      return "";
    }

    /**
     * ConnectionId sometimes arrives before connection.start resolves.
     */
    const [connectionId] = await Promise.all([
      this.waitForConnectionIdWithTimeout(),
      this.connection.start(),
    ]);

    this.connectionId = connectionId;

    this.monitorConnectionId();

    this.signalQueue.start();

    return connectionId;
  }

  /**
   * Stop the connection to SignalR.
   */
  public async stop(): Promise<void> {
    this.stoppedIntentionally = true;
    this.signalQueue.pause();
    await this.connection?.stop();
    this.connectionIdMonitor?.();
    delete this.connectionId;
    this.serviceErrorCount = 0;
  }

  /**
   * Client has an active connection to SignalR server.
   */
  public isConnected(): boolean {
    return (
      this.connection?.state === HubConnectionState.Connected &&
      !!this.connectionId
    );
  }

  /**
   * Client is connected to a project.
   */
  public isConnectedToProject(): boolean {
    return this.isConnected() && !!this.projectId;
  }

  public getConnectionId(): string | undefined {
    return this.connectionId;
  }

  /**
   * Clear the current project ID.
   */
  public clearProjectId(): void {
    delete this.projectId;
  }

  /**
   * Set the current project ID.
   *
   * @TODO - Make this accept a number only
   */
  public setProjectId(projectId: string | number): void {
    this.projectId = Number(projectId);
  }

  /**
   * Add an `onClose` event listener / callback.
   */
  public onClose(callback: (error?: Error) => Promise<void> | void): void {
    this.onCloseHandler = callback;
  }

  /**
   * Add an `onReconnecting` event listener / callback.
   */
  public onReconnecting(
    callback: (error?: Error) => Promise<void> | void
  ): void {
    this.onReconnectingHandler = callback;
  }

  /**
   * Add an `onReconnected` event listener / callback.
   */
  public onReconnected(
    callback: (connectionId: string) => Promise<void> | void
  ): void {
    this.onReconnectedHandler = callback;
  }

  /**
   * Add an `onReconnectionFailed` event listener / callback.
   */
  public onReconnectionFailed(
    callback: (error?: Error) => Promise<void> | void
  ): void {
    this.onReconnectionFailedHandler = callback;
  }

  /**
   * Add a handler for service errors.
   */
  public onServiceError(
    callback: (message: SystemMessage) => Promise<void> | void
  ): void {
    this.onServiceErrorHandler = callback;
  }

  /**
   * Add a handler for when the service error limit has been reached.
   */
  public onServiceErrorsLimit(callback: () => Promise<void> | void): void {
    this.onServiceErrorLimitHandler = callback;
  }

  /**
   * Add an event listener / callback for when the signal queue is active.
   */
  public onSignalQueueActive(callback: () => Promise<void> | void): void {
    this.signalQueue.on("active", callback);
  }

  /**
   * Add an event listener / callback for when the signal queue is idle.
   */
  public onSignalQueueIdle(callback: () => Promise<void> | void): void {
    this.signalQueue.on("idle", callback);
  }

  /**
   * Add an event listener / callback for when the signal queue is overloaded.
   */
  public onSignalQueueOverloaded(callback: OverloadCallback): void {
    this.signalQueue.setQueueOverloadCallback(callback);
  }

  /**
   * Add a message listener / callback.
   *
   * @NOTE Does not allow you to listen for service errors. Use `onServiceError`
   * instead.
   *
   * @returns An unsubscribe function will be returned, like `useEffect`,
   * allowing you to clean up listeners that are no longer required.
   */
  public onMessage<T extends OnMessageType>(
    messageType: T,
    handler: OnMessageListener<T>,
    config: OnMessageConfig = {}
  ): () => void {
    return this.addMessageHandler(messageType, handler, config);
  }

  /**
   * Remove all connection / queue status callbacks.
   */
  public removeStatusCallbacks(): void {
    const noop = () => null;
    this.connection?.onclose(noop);
    this.connection?.onreconnected(noop);
    this.connection?.onreconnecting(noop);
    this.signalQueue.off("active");
    this.signalQueue.off("idle");
    this.signalQueue.setQueueOverloadCallback(undefined);
    delete this.onServiceErrorLimitHandler;
  }

  /**
   * Add a message handler.
   *
   * Message will be decoded from Protobuf and also reshaped for use within the
   * app.
   *
   * @returns Method for unsubscribing from event
   */
  private addMessageHandler<T extends NotifyMessageName>(
    messageType: T,
    handler: OnMessageListener<T>,
    config: OnMessageConfig = {}
  ): () => void {
    const messageHandler = async (payload: Uint8Array) => {
      const codec = messageDecoders[messageType];

      try {
        const decodedMessage = codec.decode(payload) as MessageDecoderMap[T];

        if (runtimeConfig.logSignalRMessages) {
          // eslint-disable-next-line no-console
          console.log("Protobuf message", messageType, decodedMessage);
        }

        const message = deserializeIncomingMessage({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          name: messageType as any, // TODO - how to narrow this? https://github.com/microsoft/TypeScript/issues/28102
          message: decodedMessage,
        }) as IncomingMessageMap[T];

        await handler(message);

        if (config.once) {
          this.connection.off(messageType, messageHandler);
        }

        if (runtimeConfig.logSignalRMessages) {
          // eslint-disable-next-line no-console
          console.log("Deserialized message", messageType, message);

          trackEventToLog(LogCategory.signalR, {
            subcategory: `SignalR-received-${messageType}`,
          });
        }
      } catch (error) {
        this.handleUnknownError(error);
      }
    };

    this.connection.on(messageType, messageHandler);

    return () => {
      this.connection.off(messageType, messageHandler);
    };
  }
  /**
   * Send the message using SignalR's `invoke` method.
   *
   * The returned promise will resolve once the server's ack response is
   * received.
   *
   * If the invocation fails the promise will still resolve successfully.
   */
  protected async invokeMessage<T extends PostMessageName>(
    messageType: T,
    message: InvokeMessageMap[T] & DeepPartial<BaseMessage>,
    config?: InvokeConfig
  ): Promise<void> {
    return this.signalQueue.add(async () => {
      try {
        if (!this.projectId) {
          throw new SignalRError("Project ID not set");
        }

        const payload = this.encodeMessageWithProtobuf(messageType, message, {
          isInvoking: true,
        });

        if (payload.byteLength > this.maxMessageSizeInBytes) {
          throw new Error(
            `Exceeded the max SignalR message size limit for ${messageType}`
          );
        }

        await this.connection.invoke(messageType, payload);

        if (config?.resolveDelay) {
          /**
           * The server processes signals asynchronously (separately from sending
           * the acknowledgement response) so sometimes we need to use a little
           * delay on the client side to prevent race conditions.
           *
           * @TODO - This should be handled on the server really.
           */
          await wait(config.resolveDelay);
        }

        if (runtimeConfig.logSignalRMessages) {
          trackEventToLog(LogCategory.signalR, {
            subcategory: `SignalR-sent-${messageType}`,
          });
        }
      } catch (error) {
        onErrorToLog(
          isError(error)
            ? error
            : new SignalRError(`Failed to invoke message ${messageType}`)
        );
      }
    });
  }

  protected invokeChunkedMessage<T extends PostMessageName>(
    messageType: T,
    messages: {
      message: InvokeMessageMap[T] & DeepPartial<BaseMessage>;
      config?: InvokeConfig;
    }[]
  ): Promise<void[]> {
    return Promise.all(
      messages.map(({ message, config }) => {
        return this.invokeMessage(messageType, message, config);
      })
    );
  }

  /**
   * Encode message with Protobuf.
   */
  protected encodeMessageWithProtobuf<T extends PostMessageName>(
    messageType: T,
    messagePayload: InvokeMessageMap[T] & DeepPartial<BaseMessage>,
    config?: { isInvoking?: boolean; projectId?: number }
  ): Uint8Array {
    const serializedMessage = serializeOutgoingMessage(
      {
        name: messageType,
        // TODO - how to narrow this? https://github.com/microsoft/TypeScript/issues/28102
        // Use an action fn style approach?
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        message: messagePayload as any,
      },
      this.getBaseMessage(config?.projectId ?? this.projectId)
    );

    if (runtimeConfig.logSignalRMessages && config?.isInvoking) {
      // eslint-disable-next-line no-console
      console.log(messageType, serializedMessage);
    }

    const codec = messageEncoders[messageType];
    const message = codec.create(serializedMessage);

    if (config?.isInvoking) {
      const error = codec.verify(message);

      if (error) {
        throw new SignalRError(`Invalid message: ${error}`);
      }
    }

    return codec.encode(message).finish();
  }

  protected getBaseMessage(
    ProjectId?: number
  ): IBV.Collaboard.Protobuf.IBaseMessage {
    return {
      ProjectId: ProjectId ?? 0,
      UniqueDeviceId: { value: deviceUuid },
    };
  }

  protected getBaseMessageSize(projectId?: number): number {
    const message = IBV.Collaboard.Protobuf.BaseMessage.create(
      this.getBaseMessage(projectId ?? this.projectId)
    );

    return IBV.Collaboard.Protobuf.BaseMessage.encode(message).finish()
      .byteLength;
  }

  private createConnection(): HubConnection {
    const connection = new HubConnectionBuilder()
      .withUrl(`${runtimeConfig.apiUrl}${runtimeConfig.signalRPath}`, {
        transport: HttpTransportType.WebSockets,
        skipNegotiation: true,
        accessTokenFactory: async () => {
          // Prevent the auth token being revoked during a page unload.
          if (isPageUnloading) {
            onErrorToLog(
              new InternalError("Attempted to revoke token during page unload"),
              LogCategory.signalR
            );
            return "";
          }

          const { AuthorizationToken } = await getOrRefreshAuthToken().catch(
            () => {
              throw new SignalRError(noAuthTokenError);
            }
          );

          return AuthorizationToken;
        },
      })
      .withAutomaticReconnect()
      .withHubProtocol(new MessagePackHubProtocol())
      .configureLogging(
        runtimeConfig.signalRLogging ? LogLevel.Trace : LogLevel.None
      )
      .build();

    connection.onreconnecting(async (error?: Error) => {
      this.signalQueue.pause();
      delete this.connectionId;
      this.onReconnectingHandler?.(error);
    });

    connection.onclose((error?: Error) => {
      this.signalQueue.pause();
      delete this.connectionId;

      if (!this.stoppedIntentionally) {
        /**
         * If the connection was not stopped intentionally, then SignalR will
         * attempt to reconnect automatically. Therefore if the connection is
         * ultimately closed that means the reconnection attempts have been
         * exhausted.
         */
        this.onReconnectionFailedHandler?.(error);
      } else {
        this.onCloseHandler?.(error);
      }
    });

    return connection;
  }

  /**
   * Waits for the ConnectionId to be sent by the server when a connection
   * is first started. A timeout is used here to ensure the connection does
   * not hang indefinitely.
   */
  private async waitForConnectionIdWithTimeout(): Promise<string> {
    const connectionId = new Promise<string>((resolve) => {
      this.onMessage(
        NotifyMessageName.NotifyConnectionStartedMessage,
        (message) => {
          resolve(message.ConnectionId);
        },
        { once: true }
      );
    });

    await Promise.race([connectionId, this.createConnectionTimeout()]);

    return connectionId;
  }

  /**
   * Monitor `NotifyConnectionStarted` messages and update the connectionId.
   *
   * A new connectionId is treated as a reconnection.
   */
  private monitorConnectionId() {
    this.connectionIdMonitor = this.onMessage(
      NotifyMessageName.NotifyConnectionStartedMessage,
      (message) => {
        this.connectionId = message.ConnectionId;
        this.onReconnectedHandler?.(this.connectionId);
        this.signalQueue.start();
      }
    );
  }

  protected handleUnknownError(error: unknown): void {
    onErrorToLog(
      isError(error) ? error : new SignalRError("Unknown error"),
      LogCategory.signalR,
      {
        subcategory: "unknown-error",
        error: stringifyError(error),
      }
    );
  }

  private async handleServiceError(message: SystemMessage) {
    this.serviceErrorCount += 1;

    if (this.serviceErrorCount >= this.serviceErrorLimit) {
      try {
        await this.onServiceErrorLimitHandler?.();
      } catch (error) {
        onErrorToLog(
          new SignalRError("onServiceErrorLimitHandler exception"),
          LogCategory.signalR,
          {
            subcategory: "service-error-limit-handler",
            error: stringifyError(error),
          }
        );
      }

      // This also removes status handlers so it must be called last
      await this.stop();
      return;
    }

    this.onServiceErrorHandler?.(message);

    onErrorToLog(new SignalRError("Service error"), LogCategory.signalR, {
      subcategory: "service-error",
      error: {
        ...message,
      },
    });
  }

  /**
   * @NOTE This is in a separate method so that it can be mocked in tests. We
   * also don't use the `wait` helper because it makes the tests more
   * complicated.
   */
  private createConnectionTimeout(): Promise<void> {
    return new Promise((_, reject) => {
      setTimeout(() => {
        reject(new SignalRError("Start connection timed out"));
      }, connectionTimeout);
    });
  }
}

let isPageUnloading = false;

/**
 * Firefox disconnects SignalR in a strange way when the page is
 * unloaded / refreshed which can result in the `accessTokenFactory` method
 * being called _during_ the unload. As a result the user's RefreshToken can
 * be revoked on the server and the user is logged out unexpectedly.
 */
window.addEventListener("pagehide", () => (isPageUnloading = true));
window.addEventListener("unload", () => (isPageUnloading = true));
window.addEventListener("beforeunload", () => (isPageUnloading = true));
