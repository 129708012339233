import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function RemoveUser(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <polygon points="22.29 23.42 20.52 25.19 18.75 23.42 18.04 24.13 19.81 25.9 18.04 27.67 18.75 28.38 20.52 26.61 22.29 28.38 23 27.67 21.23 25.9 23 24.13 22.29 23.42" />
      <path d="M27.46,20.69c-1.44-1-3.64-.48-5-2.3,0,0-.51-.57.64-2s1.25-3.64,1-5.36a3.7,3.7,0,0,0-7.22,0c-.28,1.72-.19,4,1,5.36s.64,2,.64,2c-1.37,1.82-3.58,1.28-5,2.3s-1.09,3.36-1.09,3.36h2.87A5.28,5.28,0,0,0,15,25.6a5.47,5.47,0,0,0,10.94,0,5.28,5.28,0,0,0-.23-1.55h2.87S28.9,21.71,27.46,20.69Zm-7,9.38A4.47,4.47,0,1,1,24.9,25.6,4.48,4.48,0,0,1,20.43,30.07Z" />
    </Icon>
  );
}
export default RemoveUser;
