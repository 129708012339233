import { Form, Formik, FormikConfig } from "formik";
import React, { ReactElement, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button, Flex, ThemeUIStyleObject } from "theme-ui";
import { object, string } from "yup";
import { sendRegistrationVerificationToken, verifyUser } from "../../../api";
import EmailConfirmation from "../../../icons/EmailConfirmation";
import { getAppName } from "../../../tools/customer";
import { LogCategory } from "../../../tools/telemetry";
import { maxOtpCodeLength } from "../../../validationSchemas/fieldRestrictions";
import InputField from "../../shared/forms/fields/InputField";
import { useErrorHandler } from "../../shared/hooks/useErrorHandler";
import { Confirmation } from "../../shared/modals";
import { TemplateProps } from "../../shared/modals/Modal";
import ModalHeader from "../../shared/modals/ModalHeader";
import { withModalTemplate } from "../../shared/modals/withModalTemplate";
import { useAuthenticatingStatus } from "../useAuthenticatingStatus";

type Props = {
  onDismiss?: () => void;
  onError: (error: unknown) => void;
  onSuccess: () => void;
  UserName: string;
};

type EmailVerificationFormValues = {
  UserName: string;
  VerificationCode: string;
};

function EmailVerificationModalTemplate({
  onDismiss,
  onError,
  onSuccess,
  modalProps,
  UserName,
}: TemplateProps<Props>): ReactElement {
  const { t } = useTranslation();
  const { fallbackErrorHandler } = useErrorHandler();
  const { clearAuthenticatingStatus } = useAuthenticatingStatus();

  const [isSending, setSending] = useState(false);

  const onSendClick = useCallback(async () => {
    setSending(true);
    try {
      await sendRegistrationVerificationToken(UserName);
      toast(t("form.message.tokenSent", { appName: getAppName() }));
    } catch (error) {
      fallbackErrorHandler(
        error,
        "Unable to send email verification token",
        LogCategory.auth
      );
    }
    setSending(false);
  }, [UserName, fallbackErrorHandler, t]);

  const onSubmit = useCallback(
    async ({ UserName, VerificationCode }: EmailVerificationFormValues) => {
      try {
        await verifyUser(
          UserName,
          // Add leading zeros that will be dropped by number input
          String(VerificationCode).padStart(maxOtpCodeLength, "0")
        );
        onSuccess();
      } catch (error) {
        onError(error);
      }
    },
    [onError, onSuccess]
  );

  /**
   * If the user dismisses this modal we need to clear the auth state.
   */
  const onClose = useCallback(() => {
    // This actually closes the modal, despite the name
    modalProps.onClose();
    clearAuthenticatingStatus();
    onDismiss?.();
  }, [modalProps, clearAuthenticatingStatus, onDismiss]);

  const formikProps: FormikConfig<EmailVerificationFormValues> = {
    initialValues: {
      UserName,
      VerificationCode: "",
    },
    onSubmit,
    validationSchema: object().shape({
      UserName: string().required(t("clientError.required")),
      VerificationCode: string()
        .max(
          maxOtpCodeLength,
          t("clientError.tooLongMax", { max: maxOtpCodeLength })
        )
        .required(t("clientError.required")),
    }),
    /**
     * Run the validation on mount to ensure that the `isValid` value is
     * correct, otherwise the value can be `true` even when the `initialValues`
     * are not valid. This results in the submit button being enabled when
     * really it should be disabled.
     */
    validateOnMount: true,
  };

  return (
    <Formik {...formikProps}>
      {({ submitForm, handleBlur, handleChange, isValid }) => (
        <Confirmation
          {...modalProps}
          onClose={onClose}
          header={
            <ModalHeader
              title={t("dialog.emailConfirmation.header")}
              Icon={EmailConfirmation}
              subTitle={t("dialog.emailConfirmation.subheader", {
                email: UserName,
              })}
            />
          }
          closeOnDimmerClick={false}
          closeOnEscape={false}
          onConfirm={submitForm}
          confirmationDisabled={!isValid}
          confirmationLabel={t("dialog.emailConfirmation.confirmButtonLabel")}
          hideCancelButton
          closeOnConfirm={false}
        >
          <Form>
            <InputField
              name="VerificationCode"
              /**
               * @NOTE We use a number input to trigger the numeric keyboard on
               * touch devices, but that means leading zeros will be dropped
               * from the resulting value (as it is a number not a string), so
               * we need to add them back in when we use the value.
               */
              type="number"
              maxLength={maxOtpCodeLength}
              sx={inputStyle}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t("dialog.emailConfirmation.inputPlaceholder")}
            />
          </Form>

          <Flex sx={sendContainerStyle}>
            <Button
              type="button"
              sx={sendButtonStyle}
              variant="link"
              disabled={isSending}
              onClick={onSendClick}
            >
              {t("dialog.emailConfirmation.resendCode")}
            </Button>
          </Flex>
        </Confirmation>
      )}
    </Formik>
  );
}

export default withModalTemplate(EmailVerificationModalTemplate);

const inputStyle: ThemeUIStyleObject = {
  mb: [2],
  "input[type=number]": {
    "::-webkit-outer-spin-button, ::-webkit-inner-spin-button": {
      WebkitAppearance: "none",
    },
  },
};
const sendContainerStyle: ThemeUIStyleObject = { justifyContent: "flex-end" };
const sendButtonStyle: ThemeUIStyleObject = { fontSize: [3], mr: [1] };
