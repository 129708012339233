import type {
  ApplicationInsights,
  SeverityLevel,
} from "@microsoft/applicationinsights-web";
import { reduxSingleton } from "../../reduxStore/redux.singleton";
import { wsAbnormalClosureRegex } from "../errors";
import { runtimeConfig } from "../runtimeConfig";
import { getAppVersion } from "../versionInfo";

let appInsights: ApplicationInsights | null = null;
let appSeverityLevel: typeof SeverityLevel | null = null;

const errorMessagesToIgnore = [
  // These two error mean that ResizeObserver was not able to deliver all observations within a single animation frame.
  // Do not log as exception as it is benign. See https://stackoverflow.com/a/50387233/19676
  /ResizeObserver loop limit exceeded/i,
  /ResizeObserver loop completed with undelivered notifications/i,
  // These two are WebSockets close error messages that we can't do anything about. The connection will be recreated if possible
  /WebSocket closed with status code: 1000/i,
  wsAbnormalClosureRegex,

  // This error is from some user password-managing extension. See https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-864140452
  /Object Not Found Matching Id/i,
];

export const shouldLogErrorMessage = (message = ""): boolean => {
  return !errorMessagesToIgnore.find((re) => re.test(message));
};

export const startAppInsights = async (): Promise<void> => {
  const instrumentationKey = runtimeConfig.applicationInsightsKey;
  if (instrumentationKey) {
    const { ApplicationInsights, SeverityLevel } = await import(
      "@microsoft/applicationinsights-web"
    );

    appSeverityLevel = SeverityLevel;

    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        enableUnhandledPromiseRejectionTracking: true,
      },
    });

    appInsights.loadAppInsights();

    appInsights.addTelemetryInitializer((envelope) => {
      if (envelope?.baseType === "ExceptionData") {
        return shouldLogErrorMessage(envelope?.data?.message);
      }

      return true;
    });
  }
};

export interface PropertiesToTrack {
  category?: string;
  subcategory?: string;
  projectId?: string;
  [key: string]: unknown; // TODO: remove and make interface generic
}

interface CustomProperties {
  projectId: string;
  pageUrl: string;
  user: string;
  pageVisibilityState: string;
  [key: string]: unknown;
}

const mapProperties = (
  properties: PropertiesToTrack = {}
): CustomProperties => {
  const { projectId, ...rest } = properties;
  const user =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (reduxSingleton.getStore().getState().auth as any).userProfile?.UserName ??
    "";

  return {
    user,
    /** @TODO Read projectId from Redux as well when context is migrated to Redux */
    projectId: projectId ?? "",
    pageUrl: window.location.toString(),
    pageVisibilityState: document.visibilityState,
    appVersion: getAppVersion(),
    ...rest,
  };
};

export const logErrorToAppInsights = (
  error: Error,
  category: string,
  properties: PropertiesToTrack
): void => {
  if (!appInsights) {
    return;
  }
  if (!error.message) {
    // Avoid "not_specified" logs
    error.message = error.stack?.split("\n")[0] || category;
  }

  const exception = {
    exception: error,
    properties: mapProperties({ ...properties, category }),
    severityLevel: appSeverityLevel?.Error,
  };
  appInsights.trackException(exception);
};

export const trackEventToAppInsights = (
  name: string,
  properties?: PropertiesToTrack
): void => {
  if (appInsights) {
    appInsights.trackEvent({ name }, mapProperties(properties));
  }
};
