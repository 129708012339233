import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { rejectSubscriptionUserInvitation } from "../../../api";
import { fixInvalidToken } from "../../../api/helpers";
import { routes } from "../../../const";
import { LogCategory } from "../../../tools/telemetry";
import { useErrorHandler } from "../../shared/hooks/useErrorHandler";
import FullPageSpinner from "../../shared/spinners/FullPageSpinner";

function RejectTeamInvitation(): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const { fallbackErrorHandler, handleApiError } = useErrorHandler();

  const [rejected, setRejected] = useState(false);

  const queryParams = useMemo(() => {
    const params = new URLSearchParams(search);
    const token = params.get("token") ?? undefined;
    token && params.set("token", fixInvalidToken(token));
    return params;
  }, [search]);

  const token = queryParams.get("token");

  useEffect(() => {
    const rejectInvite = async (token: string) => {
      if (rejected) {
        return;
      }

      setRejected(true);

      try {
        await rejectSubscriptionUserInvitation(token);
        toast(t("team.invite.rejected"));
      } catch (error: unknown) {
        fallbackErrorHandler(
          error,
          "Failed to reject team invite",
          LogCategory.auth
        );
      } finally {
        history.replace(routes.authenticate);
      }
    };

    if (token) {
      rejectInvite(token);
    }
  }, [handleApiError, fallbackErrorHandler, history, rejected, t, token]);

  if (!token) {
    return <Redirect to={routes.authenticate} />;
  }

  return <FullPageSpinner />;
}

export default RejectTeamInvitation;
