import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Plans(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M26.49,10.7H24.32l1.46,2.16h.79V28.21H14.16V12.86H15l1.46-2.16H14.24a2,2,0,0,0-2,2V28.36a2,2,0,0,0,2,2H26.49a2,2,0,0,0,2-2V12.71A2,2,0,0,0,26.49,10.7Z" />
      <path d="M24.42,12.86,23,10.7l-.79-1.18,0,0a1.77,1.77,0,0,0-3.54,0l0,0-.79,1.18-1.46,2.16-.42.62,4.48,0,4.47,0ZM20.37,8.93a.62.62,0,1,1-.63.62A.62.62,0,0,1,20.37,8.93Z" />
      <rect x="15.62" y="16.12" width="6.08" height="1.77" rx="0.83" />
      <rect x="15.62" y="19.66" width="9.63" height="1.77" rx="0.83" />
      <rect x="15.62" y="23.21" width="9.63" height="1.77" rx="0.83" />
    </Icon>
  );
}
export default Plans;
