/**
 * Throttle a function.
 *
 * From underscore.js
 *
 * Returns a function, that, when invoked, will only be triggered at most once
 * during a given window of time. Normally, the throttled function will run
 * as much as it can, without ever going more than once per `wait` duration;
 * but if you'd like to disable the execution on the leading edge, pass
 * `{leading: false}`. To disable execution on the trailing edge, ditto.
 *
 * @param {Function} func Function to throttle.
 * @param {number} wait Number of milliseconds to wait.
 * @param {object} options Options.
 * @param {boolean} options.leading Trigger on the leading edge.
 * @param {boolean} options.trailing Trigger on the leading edge.
 * @returns {EventListener} Function to invoke
 */

type ThrottleOptions = {
  leading?: boolean;
  trailing?: boolean;
};

interface ThrottleFunction<T> extends Function {
  (): T;
  cancel: () => void;
}

function throttle<T, C>(
  func: () => T,
  wait = 50,
  options: ThrottleOptions = {}
): ThrottleFunction<T> {
  let timeout: ReturnType<typeof setTimeout> | null;
  let context: C | null;
  let args: [] | null;
  let result: T;
  let previous = 0;

  const later = function throttleLater() {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args || []);
    if (!timeout) {
      context = null;
      args = null;
    }
  };

  const throttled = function throttled(this: C, ...rest: []) {
    const now = Date.now();
    if (!previous && options.leading === false) {
      previous = now;
    }
    const remaining = wait - (now - previous);
    context = this;
    args = rest;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) {
        context = null;
        args = null;
      }
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };

  throttled.cancel = function cancelThrottle() {
    timeout !== null && clearTimeout(timeout);
    previous = 0;
    timeout = null;
    context = null;
    args = null;
  };

  return throttled;
}

export default throttle;
