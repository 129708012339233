import { VotingAction, VotingActionType } from "./voting.actions";

export enum VotingSessionType {
  Vote = 1,
  Rate = 2,
}

export enum VotingStatus {
  Initiated = 1,
  InProgress = 10,
  Suspended = 20,
  Stopped = 30,
}

export type VotingParticipantStatus = {
  isFinished: boolean;
  votesRemaining: number;
};

export type VotingState = {
  ownerUserName: string | undefined;
  isOwner: boolean;

  isReadOnly: boolean;

  sessionId: number | undefined;
  sessionType: VotingSessionType;
  sessionDurationInMinutes: number;
  sessionStartTime: number;

  participantStatusByUserName: Map<string, VotingParticipantStatus>;
  participantVoteLimit: number;

  myVotesCastByTileId: Map<string, number>;
  myVotesRemaining: number;

  // OWNER ONLY
  voterCountByTileId: Map<string, number>;
  totalVotesCastByTileId: Map<string, number>;
};

const defaultDurationInMinutes = 5;
const defaultVoteLimit = 10;

export const getInitialVotingState = (): VotingState => ({
  ownerUserName: undefined,
  isOwner: false,

  isReadOnly: false,

  sessionId: undefined,
  sessionType: VotingSessionType.Vote,
  sessionDurationInMinutes: defaultDurationInMinutes,
  sessionStartTime: 0,

  participantStatusByUserName: new Map(),
  participantVoteLimit: defaultVoteLimit,

  myVotesCastByTileId: new Map(),
  myVotesRemaining: defaultVoteLimit,

  voterCountByTileId: new Map(),
  totalVotesCastByTileId: new Map(),
});

export function votingReducer(
  state = getInitialVotingState(),
  action: VotingAction
): VotingState {
  switch (action.type) {
    case VotingActionType.ON_VOTING_SESSION_STARTED: {
      const { isOwner, session, sessionStartTime } = action.payload;
      const {
        DurationInSeconds,
        IsInvited,
        NumberOfVotesPerUser,
        Owner,
        SessionId,
        VotingType,
      } = session;

      return {
        ...getInitialVotingState(),
        ownerUserName: Owner,
        isOwner,

        isReadOnly: !IsInvited,

        sessionId: SessionId,
        sessionType: VotingType,
        sessionDurationInMinutes: DurationInSeconds / 60,
        sessionStartTime,

        participantVoteLimit: NumberOfVotesPerUser,
        myVotesRemaining: NumberOfVotesPerUser,
      };
    }
    case VotingActionType.ON_VOTING_SESSION_RESUMED: {
      const { isOwner, session } = action.payload;
      const {
        DateCreatedUTC,
        DurationInSeconds,
        IsInvited,
        NumberOfVotesPerUser,
        Owner,
        Results,
        SessionId,
        UserVotingState,
        VotingType,
      } = session;

      const isUserFinished = UserVotingState !== VotingStatus.InProgress;

      const myVotesCastByTileId = new Map(
        (Results || []).map((myVote) => {
          return [myVote.TileId, myVote.NumberOfVotes || 0];
        })
      );

      const myVoteCastCount = Array.from(myVotesCastByTileId.values()).reduce(
        (result, voteCount) => result + voteCount,
        0
      );

      return {
        ...state,
        ownerUserName: Owner,
        isOwner,

        isReadOnly: !IsInvited || isUserFinished,

        sessionId: SessionId,
        sessionType: VotingType,
        sessionDurationInMinutes: DurationInSeconds / 60,
        sessionStartTime: new Date(`${DateCreatedUTC}Z`).getTime(),

        participantVoteLimit: NumberOfVotesPerUser,
        myVotesRemaining: NumberOfVotesPerUser - myVoteCastCount,

        myVotesCastByTileId,
      };
    }
    case VotingActionType.ON_VOTING_SESSION_STOPPED: {
      const { session } = action.payload;

      if (session.SessionId !== state.sessionId) {
        return state;
      }

      return getInitialVotingState();
    }
    case VotingActionType.ON_VOTE_CAST: {
      const { isMyVote, vote } = action.payload;

      const {
        NumberOfVotersForTile = 0,
        SessionId,
        TileId,
        TotalVotesForTile = 0,
        UserName,
        VotesLeft,
      } = vote;

      if (SessionId !== state.sessionId) {
        return state;
      }

      const { myVotesRemaining, isOwner } = state;

      const myVotesCastByTileId = new Map(state.myVotesCastByTileId);

      if (isMyVote) {
        // NumberOfVotes is only present when casting a vote, otherwise the vote is being revoked
        myVotesCastByTileId.set(
          TileId,
          "NumberOfVotes" in vote ? vote.NumberOfVotes : 0
        );
      }

      const participantStatusByUserName = new Map(
        state.participantStatusByUserName
      );
      participantStatusByUserName.set(UserName, {
        isFinished: false,
        votesRemaining: VotesLeft,
      });

      const voterCountByTileId = new Map(state.voterCountByTileId);
      const totalVotesCastByTileId = new Map(state.totalVotesCastByTileId);

      if (isOwner) {
        voterCountByTileId.set(TileId, NumberOfVotersForTile);
        totalVotesCastByTileId.set(TileId, TotalVotesForTile);
      }

      return {
        ...state,
        participantStatusByUserName,
        myVotesRemaining: isMyVote ? VotesLeft : myVotesRemaining,
        myVotesCastByTileId,
        voterCountByTileId,
        totalVotesCastByTileId,
      };
    }
    case VotingActionType.ON_PARTICIPANT_FINISHED_VOTING: {
      const { isMyself, session } = action.payload;
      const { SessionId, UserName } = session;

      if (SessionId !== state.sessionId) {
        return state;
      }

      const participantStatusByUserName = new Map(
        state.participantStatusByUserName
      );
      const currentParticipantStatus = participantStatusByUserName.get(
        UserName
      );

      participantStatusByUserName.set(UserName, {
        votesRemaining: currentParticipantStatus
          ? currentParticipantStatus.votesRemaining
          : state.participantVoteLimit,
        isFinished: true,
      });

      return {
        ...state,
        isReadOnly: isMyself || state.isReadOnly,
        participantStatusByUserName,
      };
    }
    case VotingActionType.ON_VOTING_SESSION_DURATION_CHANGE: {
      const { session } = action.payload;
      const { SessionId, TotalDurationInSeconds } = session;

      if (SessionId !== state.sessionId) {
        return state;
      }

      return {
        ...state,
        sessionDurationInMinutes: TotalDurationInSeconds / 60,
      };
    }
    case VotingActionType.SET_VOTES_PER_OBJECT: {
      const { sessionId, votes } = action.payload;

      if (sessionId !== state.sessionId) {
        return state;
      }

      const totalVotesCastByTileId = new Map(state.totalVotesCastByTileId);
      const voterCountByTileId = new Map(state.voterCountByTileId);

      votes.forEach((vote) => {
        totalVotesCastByTileId.set(vote.TileId, vote.VoteCount);
        voterCountByTileId.set(vote.TileId, vote.NumberOfVoters);
      });

      return {
        ...state,
        totalVotesCastByTileId,
        voterCountByTileId,
      };
    }
    default:
      return state;
  }
}

export const selectVotingSession = (
  state: ApplicationGlobalState
): VotingState => {
  return state.canvas.voting;
};
