import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Heading, Input, ThemeUIStyleObject } from "theme-ui";
import Delete from "../../../icons/Delete";
import { ApiPermission } from "../../../types/enum";
import EmptyListPlaceholder from "../../shared/layout/EmptyListPlaceholder";
import { BasicDropdownItem } from "../../shared/forms/dropdowns/BasicDropdown/BasicDropdown";
import { Confirmation, useConfirmModal } from "../../shared/modals";
import ModalHeader from "../../shared/modals/ModalHeader";
import SpaceUser from "./SpaceUser";
import InfiniteScroll from "react-infinite-scroller";
import { UseInfiniteQueryResult } from "../../../api/useRequest";
import Centered from "../../shared/layout/Centered";
import LoadingSpinner from "../../../icons/LoadingSpinner";
import ContainedSpinner from "../../shared/spinners/ContainedSpinner";
import Admin from "../../../icons/Admin";
import Profile from "../../../icons/Profile";
import { isSpaceAdmin } from "../utils";

type Props = {
  adminVisible: boolean;
  removeVisible: boolean;
  usersQueryResult: UseInfiniteQueryResult & {
    users: SpaceUser[];
  };
  onQueryChange: (query: string) => void;
  onPermissionChange: (
    user: SpaceUser,
    permission: BasicDropdownItem<ApiPermission>
  ) => void;
  onAdminToggle: (user: SpaceUser, isAdmin: boolean) => void;
  onDelete: (user: SpaceUser) => void;
};

export function SpaceUsers({
  adminVisible,
  removeVisible,
  usersQueryResult,
  onQueryChange,
  onDelete,
  onPermissionChange,
  onAdminToggle,
}: Props): ReactElement {
  const {
    users,
    isLoading,
    fetchMore,
    canFetchMore,
    isFetching,
    isFetchingMore,
  } = usersQueryResult;
  const { t } = useTranslation();
  const toggleAdminModal = useConfirmModal<{ isAdmin: boolean }>();
  const deleteUserModal = useConfirmModal();

  const isMakingAdmin = toggleAdminModal.props.data?.isAdmin;

  return (
    <>
      <Box>
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            mt: [7],
          }}
        >
          <Heading as="h4" variant="text.h4">
            <Profile noShadow margin="0 0 0 -.75rem" />
            {t("spaces.activeUsers")}
          </Heading>
          <Box>
            <Input
              data-cy="space-search-user"
              onChange={(e) => onQueryChange(e.target.value)}
              placeholder={t("form.placeholder.search")}
            />
          </Box>
        </Flex>
        <Box sx={scrollWrapperStyle}>
          {isLoading ? (
            <ContainedSpinner />
          ) : users.length ? (
            <InfiniteScroll
              pageStart={1}
              useWindow={false}
              loadMore={fetchMore}
              hasMore={canFetchMore && !isFetching && !isFetchingMore}
              loader={
                <Centered>
                  <LoadingSpinner />
                </Centered>
              }
            >
              {users.map((user) => {
                const thereAreOtherAdmins = users.some(
                  (u) => isSpaceAdmin(u.SpacePermission) && u !== user
                );
                const isRemovable =
                  removeVisible &&
                  (!isSpaceAdmin(user.SpacePermission) || thereAreOtherAdmins);

                return (
                  <SpaceUser
                    adminVisible={adminVisible}
                    removable={isRemovable}
                    user={user}
                    onPermissionChange={(permission) =>
                      onPermissionChange(user, permission)
                    }
                    onAdminToggle={(isAdmin) =>
                      toggleAdminModal.open({
                        data: { isAdmin },
                        confirmCallback: () => onAdminToggle(user, isAdmin),
                      })
                    }
                    onDelete={(user) =>
                      deleteUserModal.open({
                        confirmCallback: () => onDelete(user),
                      })
                    }
                    key={user.User.UserName}
                  />
                );
              })}
            </InfiniteScroll>
          ) : (
            <EmptyListPlaceholder />
          )}
        </Box>
      </Box>

      <Confirmation
        {...toggleAdminModal.props}
        variant={isMakingAdmin ? "success" : "warning"}
        header={<ModalHeader title={t("dialog.admin")} Icon={Admin} />}
        confirmationLabel={t("dialog.confirm")}
      >
        {isMakingAdmin
          ? t("dialog.grantSpaceAdminUserPrompt")
          : t("dialog.revokeSpaceAdminUserPrompt")}
      </Confirmation>

      <Confirmation
        {...deleteUserModal.props}
        variant="warning"
        header={
          <ModalHeader title={t("spaces.deleteSpaceUser")} Icon={Delete} />
        }
        confirmationLabel={t("dialog.delete")}
      >
        {t("dialog.deleteSpaceUserPrompt")}
      </Confirmation>
    </>
  );
}

const scrollWrapperStyle: ThemeUIStyleObject = {
  minHeight: "5rem",
  maxHeight: "300px",
  overflowX: "hidden",
  overflowY: "auto",
  position: "relative",
};
