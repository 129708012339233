import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Web(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M20,11.9a8,8,0,1,0,8,8A8,8,0,0,0,20,11.9Zm4.58,4.16H23a16.53,16.53,0,0,0-.49-1.61A5.89,5.89,0,0,1,24.61,16.06Zm-8,3.21H14.08a5.85,5.85,0,0,1,.56-1.95h2.18A16.41,16.41,0,0,0,16.61,19.27Zm1.49-1.95H22a16.29,16.29,0,0,1,.23,1.95H17.87A16.29,16.29,0,0,1,18.1,17.32Zm-1.49,3.21a16.36,16.36,0,0,0,.21,2H14.64a5.84,5.84,0,0,1-.56-2Zm1.26,0H22.2a14.39,14.39,0,0,1-.24,2H18.11A14.39,14.39,0,0,1,17.87,20.53Zm5.59,0H26a5.83,5.83,0,0,1-.55,2H23.25A16.36,16.36,0,0,0,23.46,20.53Zm0-1.26a16.41,16.41,0,0,0-.21-1.95h2.18A5.84,5.84,0,0,1,26,19.27ZM20.94,14a15.54,15.54,0,0,1,.74,2.07H18.39A17.33,17.33,0,0,1,19.12,14a5.49,5.49,0,0,1,.91-.07A5.41,5.41,0,0,1,20.94,14Zm-3.36.46a15,15,0,0,0-.49,1.61H15.45A5.93,5.93,0,0,1,17.58,14.45Zm-2.13,9.3h1.64c.16.61.34,1.15.51,1.62A6,6,0,0,1,15.45,23.75Zm3.7,2.07a14.72,14.72,0,0,1-.75-2.07h3.27a14.72,14.72,0,0,1-.75,2.07,6.54,6.54,0,0,1-.89.07A5.14,5.14,0,0,1,19.15,25.82Zm3.32-.45c.17-.47.35-1,.51-1.62h1.63A6.11,6.11,0,0,1,22.47,25.37Z" />
    </Icon>
  );
}
export default Web;
