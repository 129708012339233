import { AxiosRequestConfig, AxiosResponse } from "axios";
import { isNil } from "ramda";
import i18n from "i18next";
import { getReasonPhrase } from "http-status-codes";

import errorCodes, { errorCodeIds } from "../api/errorCodes";
import { CollaboardError } from "./collaboardError";
import { isNetworkError } from "../tools/errors";

export class CollaboardApiError<
  ResponseType extends ApiBaseResponse = ApiBaseResponse
> extends CollaboardError {
  config?: AxiosRequestConfig;
  code?: string;
  isAxiosError = false;
  response?: {
    data: ResponseType;
  };
  request?: AxiosRequestConfig;
  details: ApiErrorDetails;

  constructor(
    errorResponse:
      | SimulatedApiErrorResponse<ResponseType>
      | ApiErrorResponse<ResponseType>
  ) {
    super(errorResponse.message);

    this.name = "CollaboardApiError";

    // This means Axios threw this error
    if (errorResponse instanceof Error) {
      this.stack = errorResponse.stack;
      this.request = errorResponse.request;
      this.code = errorResponse.code;
      this.isAxiosError = errorResponse.isAxiosError;
    }

    const { response, config } = errorResponse;

    if (response) {
      this.response = response;
    }

    if (config) {
      this.config = config;
    }

    this.details = extractErrorDetails<ResponseType>(errorResponse);
  }

  toObject(): CollaboardApiErrorObject<ResponseType> {
    const fallbackResponse = {
      ErrorCode: errorCodeIds.GenericError,
    } as ResponseType;

    return {
      message: this.message,
      response: { data: this.response?.data || fallbackResponse },
      details: this.details,
    };
  }
}

/**
 * Extract error code, type and message from an error.
 *
 * TODO: This needs further work IMO.
 * When we make the change so that the full error is always used the consumer
 * should easily be able to identify from the error contents why the request
 * failed. And we should also provide a single, safe, translated error message
 * so that things like ErrorToast and ErrorPage don't have to make decisions
 * about what property to use.
 *
 * Something like this:
 *
 *   {
 *     apiErrorCode: 10000,  // <-- this is only set if the server gave us an error code
 *     httpErrorCode: 503,  // <-- this is only set if the request failed with a HTTP error
 *     runtimeError: "TypeError", // <-- this is set if it was a JS error
 *     localizedMessage: "Oops, something went wrong"  // <-- this is always set, translated and is what should be shown in the UI
 *   }
 *
 * @private
 */
const extractErrorDetails = <T extends ApiBaseResponse>(
  error: ApiErrorResponse<T> | SimulatedApiErrorResponse<T>
): ApiErrorDetails => {
  const { response } = error;

  const genericErrorCode = errorCodeIds.GenericError;
  const genericErrorType = errorCodes[genericErrorCode];
  const genericErrorMessage = i18n.t(`apiError.${genericErrorType}`);

  if (!response) {
    // Unknown error, could be an internal Axios runtime error or a network error
    const isNetwork = isNetworkError(error);
    const errorCode = isNetwork ? errorCodeIds.NetworkError : genericErrorCode;
    const errorType = errorCodes[errorCode];

    return {
      ErrorCode: errorCode, // TODO: Remove this legacy ErrorCode
      errorCode,
      errorType,
      errorMessage: error.message || genericErrorMessage,
      isClientError: true, // The request did not reach the server
      isHTTPError: false,
      isServerError: false,
    };
  }

  const { status, statusText, data } = response as AxiosResponse<T>;

  // HTTP2 doesn't support statusText, so we need to look it up
  const reasonPhrase = status ? getReasonPhrase(status) : statusText;

  if (isNil(data?.ErrorCode)) {
    // Probably a gateway error. Server is unable to respond with JSON
    return {
      ErrorCode: genericErrorCode, // TODO: Remove this legacy ErrorCode
      errorCode: genericErrorCode,
      errorType: genericErrorType,
      errorMessage: statusText || reasonPhrase || genericErrorMessage,
      reasonPhrase,
      statusCode: status,
      statusText,
      isClientError: false,
      isHTTPError: true, // The server sent an error response status
      isServerError: false,
    };
  }

  const errorCode = data.ErrorCode;
  const errorType = errorCodes[errorCode];
  const errorMessage = i18n.t(`apiError.${errorType}`, data);

  return {
    ErrorCode: errorCode, // TODO: Remove this legacy ErrorCode
    errorCode,
    errorType,
    errorMessage,
    reasonPhrase,
    statusCode: status,
    statusText,
    isClientError: false,
    isHTTPError: false,
    isServerError: true,
  };
};
