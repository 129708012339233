import { featuresFree } from "./featuresFree";
import { featuresIndividual } from "./featuresIndividual";
import { featuresTeam } from "./featuresTeam";
import { featuresBusiness } from "./featuresBusiness";
import { featuresEnterprise } from "./featuresEnterprise";
import { valueFeatures } from "./valueFeatures";

const getValues = (type: PlanNames): Array<ValueFeature> => {
  return valueFeatures.map((feature) => ({
    ...feature,
    inCard: feature.inCard[type],
    value: feature.value[type],
  }));
};

const features: Features = {
  free: {
    extends: null,
    own: featuresFree,
    values: getValues("free"),
  },
  individual: {
    extends: "free",
    own: featuresIndividual,
    values: getValues("individual"),
  },
  team: {
    extends: "individual",
    own: featuresTeam,
    values: getValues("team"),
  },
  business: {
    extends: "team",
    own: featuresBusiness,
    values: getValues("business"),
  },
  enterprise: {
    extends: "business",
    own: featuresEnterprise,
    values: getValues("enterprise"),
  },
};
type FeatureKeys = keyof typeof features;

export const getFeatures = (key: FeatureKeys): PlanFeatures => {
  const plan = features[key];

  if (!plan?.extends) {
    return {
      ...plan,
      all: plan.own,
    };
  }

  const inherited = getFeatures(plan.extends)?.all || [];

  return {
    ...plan,
    all: inherited.concat(plan.own),
  };
};
