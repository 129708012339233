import {
  ArrowHeadMode,
  ArrowVisibilityMode,
  connectorLineModes,
  connectorModes,
  fontFamilies,
  fontSizes,
  objectColorPalette,
} from "../../../../const";
import {
  defaultConnectorSettingsStorage,
  defaultStickyNoteSettingsStorage,
  defaultTextSettingsStorage,
} from "../../../../tools/localStorageStores";
import {
  ObjectSettingsAction,
  ObjectsSettingsActionType,
} from "./settings.actions";

export type ObjectSettingsState = {
  connectors: ContextProps;
  stickyNote: ContextProps;
  text: ContextProps;
};

export const factoryConnectorState = {
  fillColor: "#000000",
  routingStyle: connectorModes.bezier,
  enableArrows: ArrowVisibilityMode.none,
  strokeStyle: connectorLineModes.solid,
  arrowStyle: ArrowHeadMode.arrow1,
  strokeWidth: 1,
} as const;

export const factoryStickyNoteState = {
  textColor: "#000000",
  fontFamily: fontFamilies[0],
  fontSize: 0, // 0 means font is in auto size mode
  isAutoFontSize: true,
  fontWeight: "normal",
  fontStyle: "normal",
  underline: false,
  textAlign: "left",
  textPlacement: "top",
  fillColor: objectColorPalette[0],
} as const;

export const factoryTextState = {
  textColor: "#000000",
  fontFamily: fontFamilies[0],
  fontWeight: "normal",
  fontStyle: "normal",
  underline: false,
  fontSize: fontSizes[6],
  textAlign: "left",
} as const;

export const getDefaultConnectorsSettingsState = (): ObjectSettingsState["connectors"] => {
  return {
    ...factoryConnectorState,
  };
};

export const getDefaultStickyNoteSettingsState = (): ObjectSettingsState["stickyNote"] => {
  return {
    ...factoryStickyNoteState,
  };
};

export const getDefaultTextSettingsState = (): ObjectSettingsState["text"] => {
  return {
    ...factoryTextState,
  };
};

/**
 * @TODO #7104: find some way so that these initial values used in the canvas are read from Redux
 */
export const getDefaultConnectorSettings = (): ObjectSettingsState["connectors"] => {
  return {
    ...getDefaultConnectorsSettingsState(),
    ...defaultConnectorSettingsStorage.get(),
  };
};

export const getDefaultStickyNoteSettings = (): ObjectSettingsState["stickyNote"] => {
  return {
    ...getDefaultStickyNoteSettingsState(),
    ...defaultStickyNoteSettingsStorage.get(),
  };
};

export const getDefaultTextSettings = (): ObjectSettingsState["text"] => {
  return {
    ...getDefaultTextSettingsState(),
    ...defaultTextSettingsStorage.get(),
  };
};

const getDefaultObjectSettingsState = (): ObjectSettingsState => {
  return {
    connectors: getDefaultConnectorsSettingsState(),
    stickyNote: getDefaultStickyNoteSettingsState(),
    text: getDefaultTextSettingsState(),
  };
};

export function objectSettingsReducer(
  state = getDefaultObjectSettingsState(),
  action: ObjectSettingsAction
): ObjectSettingsState {
  switch (action.type) {
    case ObjectsSettingsActionType.SET_CONNECTOR_ATTRIBUTES:
      return {
        ...state,
        connectors: {
          ...state.connectors,
          ...action.payload,
        },
      };
    case ObjectsSettingsActionType.SET_STICKY_NOTE_ATTRIBUTES:
      return {
        ...state,
        stickyNote: {
          ...state.stickyNote,
          ...action.payload,
        },
      };
    case ObjectsSettingsActionType.SET_TEXT_ATTRIBUTES:
      return {
        ...state,
        text: {
          ...state.text,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}

export const selectDefaultObjectSettings = (
  state: ApplicationGlobalState
): ObjectSettingsState => {
  return state.canvas.objects.settings;
};
