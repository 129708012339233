import React, { ReactElement, useMemo } from "react";
import { Box, Flex, Text } from "theme-ui";
import { useTranslation } from "react-i18next";

import BasicDropdownField from "../../../shared/forms/fields/BasicDropdownField";
import ToggleField from "../../../shared/forms/fields/ToggleField";
import PasswordField from "../../../shared/forms/fields/PasswordField";
import { usePlanDetails } from "../../../licensing/usePlanDetails";
import CollaboardTooltip from "../../../shared/tooltip/CollaboardTooltip";
import { useUISettings } from "../../../../reduxStore/uiSettings";
import { dynamicFeatureFlags } from "../../../../tools/flags";
import { BasicDropdownItem } from "../../../shared/forms/dropdowns/BasicDropdown/BasicDropdown";
import { ShareLinkOrQRModel } from "./types";
import SubscriptionUpgradeRequiredPrompt from "../../../licensing/SubscriptionUpgradeRequired/SubscriptionUpgradeRequiredPrompt";
import { DropdownItem } from "../../../routes/projects/projectList/DropDown";
import { ApiResourcePermission, ApiResourceType } from "../../../../types/enum";
import { usePermissions } from "../../../permissions/usePermissions";

type Props = {
  validityOptions: DropdownItem<number>[];
  values: ShareLinkOrQRModel;
};

function ShareLinkOptions({ values, validityOptions }: Props): ReactElement {
  const { t } = useTranslation();
  const {
    isMultiUserPlanMember,
    isMultiUserPlanAdmin,
    isOnNoLimitPlan,
  } = usePlanDetails();
  const { isDynamicFeatureActive } = useUISettings();
  const { requiresPermission } = usePermissions();

  const isGuestUsersFeatureActive = !isDynamicFeatureActive(
    dynamicFeatureFlags.GUEST_USERS_DISABLED
  );

  // If there's any RequiredPermission on project participants, then we can assume guests are not allowed
  const anyParticipatePermission = requiresPermission({
    type: ApiResourceType.ProjectParticipants,
    permission: ApiResourcePermission.Participate,
  });
  // TODO: #6804 Manage feature permissions in a single place
  const guestAllowedInLicense =
    isMultiUserPlanMember || isMultiUserPlanAdmin || isOnNoLimitPlan;
  const areGuestsDisabled = !guestAllowedInLicense || anyParticipatePermission;

  const translatedValidityOptions: BasicDropdownItem<number>[] = useMemo(
    () =>
      validityOptions.map(({ labelKey, label, value }) => ({
        label: label ?? t(labelKey),
        value,
      })),
    [t, validityOptions]
  );

  return (
    <Box sx={containerStyle}>
      <Flex className="share-link-options__col" sx={{ alignItems: "center" }}>
        <Text variant="default" sx={{ mr: [4] }}>
          {t("dialog.share.linkValidFor")}
        </Text>
        <BasicDropdownField
          name="ValidForMinutes"
          items={translatedValidityOptions}
        />
      </Flex>

      <Flex
        className="share-link-options__col"
        sx={{ py: [2], alignItems: "center" }}
      >
        <ToggleField name="PasswordProtectToggle" labelFirst />
        {values.PasswordProtectToggle && (
          <PasswordField
            autoComplete="project-password"
            required={false}
            noValidation
            sx={{ ml: [4], flex: 1, mb: [0] }}
            inputWrapperSx={{ width: "160px" }}
            variant="small"
            name="Password"
            placeholder={t("dialog.share.enterPassword")}
          />
        )}
      </Flex>

      {isGuestUsersFeatureActive && (
        <>
          <Flex className="share-link-options__col" sx={{ py: [2] }}>
            <CollaboardTooltip
              header="tooltip.header.guestInvitesUnavailable"
              description="tooltip.description.guestInvitesUnavailable"
              placement="bottom"
              forceHide={guestAllowedInLicense}
            >
              <ToggleField
                disabled={areGuestsDisabled}
                name="GuestIdentificationRequired"
                labelFirst
              />
            </CollaboardTooltip>
          </Flex>
          <Text
            className="share-link-options__col"
            variant="secondary"
            sx={{
              mt: "-0.5rem",
              color: areGuestsDisabled ? "var(--textColor)" : "muted",
            }}
          >
            {t("dialog.share.registrationRequiredInfo")}
          </Text>
          {!guestAllowedInLicense && <SubscriptionUpgradeRequiredPrompt />}
        </>
      )}
    </Box>
  );
}

const containerStyle = {
  backgroundColor: "var(--modalBox)",
  width: "100%",
  mt: [4],
  py: [4],
  px: "50px",
  ".share-link-options__col": {
    height: "2.5rem",
  },
};

export default ShareLinkOptions;
