import { Form, Formik, FormikConfig } from "formik";
import React, { ReactElement, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button, Flex, Heading } from "theme-ui";
import { object, string } from "yup";
import { routes } from "../../../const";
import { LogCategory } from "../../../tools/telemetry";
import InputField from "../../shared/forms/fields/InputField";
import { useErrorHandler } from "../../shared/hooks/useErrorHandler";
import { useSimpleModal } from "../../shared/modals/useSimpleModal";
import EmailVerificationModalTemplate from "../login/EmailVerificationModalTemplate";

interface FormData {
  UserName: string;
}

function VerifyEmail(): ReactElement {
  const history = useHistory();
  const { t } = useTranslation();
  const { fallbackErrorHandler } = useErrorHandler();

  const modalProps = useSimpleModal();

  const formikProps: FormikConfig<FormData> = {
    initialValues: { UserName: "" },
    onSubmit: () => modalProps.open(),
    validationSchema: object().shape({
      UserName: string()
        .email(t("clientError.emailNotValid"))
        .required(t("clientError.required")),
    }),
    validateOnMount: true,
  };

  const onSuccess = useCallback(() => {
    history.replace(routes.authenticate);
  }, [history]);

  const onError = useCallback(
    (error: unknown) => {
      fallbackErrorHandler(error, "Failed to verify email", LogCategory.auth);
    },
    [fallbackErrorHandler]
  );

  return (
    <Formik {...formikProps}>
      {({ isValid, values }) => (
        <Form>
          <Flex as="fieldset">
            <Heading as="legend">{t("form.header.verify")}</Heading>
            <InputField name="UserName" />
          </Flex>
          <Button type="submit" disabled={!isValid}>
            {t("form.button.verify")}
          </Button>

          <EmailVerificationModalTemplate
            modalProps={modalProps}
            UserName={values.UserName}
            onError={onError}
            onSuccess={onSuccess}
          />
        </Form>
      )}
    </Formik>
  );
}

export default VerifyEmail;
