import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function ThinPlus(props: IconProps): ReactElement {
  return (
    <Icon viewBox="0 0 60.5 60.5" {...props}>
      <polygon points="60.3,29 31.5,29 31.5,0 29,0 29,29 0,29 0,31.5 29,31.5 29,60.5 31.5,60.5 31.5,31.5 60.5,31.5 60.5,29 " />
    </Icon>
  );
}
export default ThinPlus;
