import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Schools(props: IconProps): ReactElement {
  return (
    <Icon viewBox="0 0 80 80" {...props}>
      <path d="M44,63.56a4.67,4.67,0,0,0,2.4-.76L70,34.52l-.09-6s0-2.55-2.25-2.55a2.29,2.29,0,0,0-2.57,2.39v5.54l-21.82,25-7.33-2.12v4.76A66.74,66.74,0,0,0,44,63.56Z" />
      <path d="M16.93,40.09l26.33,7.37c1.51-.45,1.66-.75,1.66-.75L70.65,17.07C72,14.21,70,13.91,70,13.91L39.31,6.84H37.4L12.12,35a18.76,18.76,0,0,0-3.46,9.1,12.29,12.29,0,0,0,4.66,10.76l5,1.52V51.69l-2.15-.62s-3-1.35-2.86-6S16.93,40.09,16.93,40.09Z" />
      <path d="M31.21,49.23l-7.93-2.16a2.94,2.94,0,0,0-3.1,2.81V70.54l7-4.33,7,6.93V52.47A3.63,3.63,0,0,0,31.21,49.23Z" />
    </Icon>
  );
}
export default Schools;
