import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { FormikHelpers } from "formik";

import ProcessingButton from "../../../shared/forms/ProcessingButton";
import ModalFooter from "../../../shared/modals/ModalFooter";
import { shareTypes } from "./consts";
import { useTrackEventToLog } from "../../../shared/tracking/useTrackEventToLog";
import { ShareLinkOrQRModel } from "./types";
import { LogCategory } from "../../../../tools/telemetry";
import { isRunningInMSTeams } from "../../../../tools/msTeams";

type Props = {
  isSubmitting: boolean;
  disabled: boolean;
  setFieldValue: FormikHelpers<ShareLinkOrQRModel>["setFieldValue"];
  submitForm: FormikHelpers<ShareLinkOrQRModel>["submitForm"];
  values: ShareLinkOrQRModel;
};

function ShareLinkButtons({
  isSubmitting,
  disabled,
  setFieldValue,
  submitForm,
  values,
}: Props): ReactElement {
  const { t } = useTranslation();
  const { trackEvent } = useTrackEventToLog();

  /**
   * We need to suppress all QR codes when running in MS Teams, otherwise
   * the app will be rejected during the certification process.
   */
  const disableQRCode = isRunningInMSTeams();

  return (
    <ModalFooter sx={{ pr: [0], pb: [0] }}>
      {!disableQRCode && (
        <ProcessingButton
          isProcessing={isSubmitting && values.ShareType === shareTypes.code}
          variant="buttons.secondary"
          disabled={disabled || isSubmitting}
          type="button"
          onClick={() => {
            trackEvent(LogCategory.project, {
              subcategory: "project-share",
              ...values,
              ShareType: shareTypes.code,
              Password: "", // Don't log the password
            });
            setFieldValue("ShareType", shareTypes.code);
            submitForm();
          }}
        >
          {t("dialog.share.generateQrCode")}
        </ProcessingButton>
      )}

      <ProcessingButton
        isProcessing={isSubmitting && values.ShareType === shareTypes.link}
        type="button"
        disabled={disabled || isSubmitting}
        onClick={() => {
          trackEvent(LogCategory.project, {
            subcategory: "project-share",
            ...values,
            ShareType: shareTypes.link,
            Password: "", // Don't log the password
          });
          setFieldValue("ShareType", shareTypes.link);
          submitForm();
        }}
      >
        {t("dialog.share.generateLink")}
      </ProcessingButton>
    </ModalFooter>
  );
}

export default ShareLinkButtons;
