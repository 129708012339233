import { fabric } from "fabric";

(() => {
  fabric.util.object.extend(fabric.Object.prototype, {
    onBorderHide(this: fabric.Object, e: BorderEvent = {}) {
      this.hasBorders = false;
      this.hasControls = false;

      !e.preventCanvasEvent &&
        this.canvas?.trigger("custom:object:transform:begin");
      this.canvas?.requestRenderAll();
    },

    onBorderShow(this: fabric.Object, e: BorderEvent = {}) {
      this.hasBorders = this.initialHasBorders;
      this.hasControls = this.initialHasControls;

      !e.preventCanvasEvent &&
        this.canvas?.trigger("custom:object:transform:end");
      this.canvas?.requestRenderAll();
    },

    attachObjectCustomEvents(this: fabric.Object) {
      this.on("custom:transform:begin", this.onBorderHide.bind(this));
      this.on("custom:transform:end", this.onBorderShow.bind(this));
    },

    deattachObjectCustomEvents(this: fabric.Object) {
      this.off("custom:transform:begin");
      this.off("custom:transform:end");
    },
  });
})();
