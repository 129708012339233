import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Company(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <polygon points="22.63 10.11 22.61 10.06 13.26 14.3 13.26 29.93 15.04 29.93 15.04 15.45 22.6 12.03 22.6 29.93 26.74 29.93 26.74 14.43 22.63 10.11" />
      <polygon points="16.23 18.72 20.89 17.28 20.89 14.72 16.23 16.88 16.23 18.72" />
      <polygon points="16.23 22.25 20.89 21.72 20.89 19.31 16.23 20.55 16.23 22.25" />
      <polygon points="16.23 25.79 20.89 25.79 20.89 23.63 16.23 24.02 16.23 25.79" />
      <rect x="16.23" y="27.82" width="4.65" height="2.1" />
    </Icon>
  );
}

export default Company;
