import { isTaintedCanvasError } from "./errors";

interface MaximumCanvasSize {
  width: number;
  height: number;
  _cached?: boolean;
}

interface CanvasSizeResult {
  width: number;
  height: number;
  benchmark: number;
}

// Use a safe initial value
// https://stackoverflow.com/questions/6081483/maximum-size-of-a-canvas-element
export const defaultSafeCanvasSize = 4000;

const maxCanvasSize: MaximumCanvasSize = {
  width: defaultSafeCanvasSize,
  height: defaultSafeCanvasSize,
  _cached: false,
};

/**
 * Determine the maximum canvas size that this browser / device supports.
 *
 * This operation is expensive and should only be run once, the first time they
 * experience a failed export.
 */
export const determineMaxCanvasSize = async (): Promise<MaximumCanvasSize> => {
  const { default: canvasSize } = await import("canvas-size");

  if (maxCanvasSize._cached) {
    return maxCanvasSize;
  }

  return canvasSize
    .maxArea({
      usePromise: true,
      useWorker: true,
    })
    .then(
      (result: CanvasSizeResult) => {
        maxCanvasSize.width = result.width;
        maxCanvasSize.height = result.height;
        return result;
      },
      () => {
        // Failed to calculate max size, so use our predetermined safe values
        return maxCanvasSize;
      }
    )
    .finally(() => {
      maxCanvasSize._cached = true;
    });
};

export const getCanvasBlob = (
  canvas: HTMLCanvasElement,
  format: string,
  quality: number
): Promise<Blob> => {
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        blob
          ? resolve(blob)
          : reject(new Error("Could not convert image slice to Blob"));
      },
      format,
      quality
    );
  });
};

export const isCanvasTainted = (ctx: CanvasRenderingContext2D): boolean => {
  try {
    ctx.getImageData(0, 0, 1, 1);
    return false;
  } catch (e) {
    return isTaintedCanvasError(e);
  }
};
