import { ApiPermission } from "../../types/enum";

export const isSpaceAdmin = (permission: ApiPermission): boolean => {
  return permission >= ApiPermission.readWritePermission;
};

export const findSpacePermission = (
  spaces: Space[],
  spaceId: number
): ApiPermission | undefined => {
  return spaces.find((space) => space.Space.SpaceId === spaceId)
    ?.SpacePermission;
};
