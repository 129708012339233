import React, { useState, useEffect } from "react";
import { Grid, Text, Box } from "theme-ui";
import { useTranslation } from "react-i18next";
import { prop, sortBy } from "ramda";
import ComparisonTableColumn from "./ComparisonTableColumn";
import ComparisonTableLabels from "./ComparisonTableLabels";

function ComparisonTable({ data = {}, onBuy }) {
  const { t } = useTranslation();

  const [labels, setLabels] = useState([]);
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    const { all, values } = data.enterprise.features;
    const allFeatures = [...(all || []), ...(values || [])];
    const _labels = sortBy(prop("order"), allFeatures);
    setLabels(_labels);

    const _plans = Object.entries(data).map(([name, plan]) => ({
      name,
      ...plan.features,
    }));
    setPlans(_plans);
  }, [data]);

  return (
    <Box sx={{ display: ["none", "none", "none", "none", "block"] }}>
      <Text
        as="h3"
        sx={{ textAlign: "center", fontSize: [6], fontWeight: 500, mt: [6] }}
      >
        {t("plans.comparisonTableHeader")}:
      </Text>

      <Grid
        sx={{
          gridTemplateColumns: `250px repeat(${plans.length}, 1fr)`,
          gridTemplateRows: `repeat(${labels.length + 2}, 1fr)`,
          gridAutoFlow: "column",
          gridRowGap: 0,
          mt: [5],
          "> *": {
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            svg: { height: "24px", width: "24px", fill: "primary" },
            px: [2],
            py: [3],
          },
        }}
      >
        <ComparisonTableLabels labels={labels} />

        {plans.map((plan) => (
          <ComparisonTableColumn
            plan={plan}
            labels={labels}
            key={plan.name}
            onBuy={onBuy}
          />
        ))}
      </Grid>
    </Box>
  );
}

ComparisonTable.propTypes = {};
ComparisonTable.defaultProps = {};

export default ComparisonTable;
