import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Flex, Input, Text } from "theme-ui";
import { ProductTypeIDs } from "../../../const";
import { getKeyByValue } from "../../../tools/utils";
import { initialPlans } from "../../routes/plans/consts";
import { billingTypes } from "./enums";

function BillingSwitch({
  productTypeID,
  onBillingChange,
  billingType,
  savings,
}) {
  const { t } = useTranslation();

  const planName = getKeyByValue(ProductTypeIDs, productTypeID);

  return (
    <Flex sx={containerStyle}>
      <Box>
        <Text sx={{ fontSize: [5] }}>
          {t(initialPlans[planName].licenseKey)}
        </Text>
        <Text sx={{ color: "disabledDark", fontSize: [3], pt: [2] }}>
          {t(initialPlans[planName].infoKey)}
        </Text>
      </Box>

      <Flex sx={{ fontSize: [3], input: { px: [2] } }}>
        <Text
          sx={{
            mr: [2],
            textShadow:
              billingType === billingTypes.monthly
                ? "0px 0px 1px black"
                : "none",
          }}
        >
          {t("dialog.payment.billedMonthly")}
        </Text>

        <Input
          variant="toggle"
          type="checkbox"
          defaultChecked={billingType === billingTypes.annually}
          onChange={(e) => {
            onBillingChange(
              e.target.checked ? billingTypes.annually : billingTypes.monthly
            );
          }}
        />

        <Box
          sx={{
            ml: [2],
            textShadow:
              billingType === billingTypes.annually
                ? "0px 0px 1px black"
                : "none",
          }}
        >
          <Text>{t("dialog.payment.billedAnnually")}</Text>
          {savings && (
            <Text>
              {t("dialog.payment.save", {
                percent: savings[planName],
              })}
            </Text>
          )}
        </Box>
      </Flex>
    </Flex>
  );
}

const containerStyle = {
  p: [4],
  flexDirection: "column",
  justifyContent: "space-between",
  bg: "delicate",
};

BillingSwitch.propTypes = {
  billingType: PropTypes.number.isRequired,
  productTypeID: PropTypes.number.isRequired,
  onBillingChange: PropTypes.func.isRequired,
  savings: PropTypes.shape({
    business: PropTypes.number,
    individual: PropTypes.number,
    team: PropTypes.number,
  }),
};
BillingSwitch.defaultProps = {
  savings: null,
};

export default BillingSwitch;
