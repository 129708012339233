import React from "react";
import PropTypes from "prop-types";
import { Button, Flex, Text } from "theme-ui";
import { useTranslation } from "react-i18next";

import Update from "../../../icons/Update";

function UpdateTotal({ updateTotal, disabled }) {
  const { t } = useTranslation();
  return (
    <Flex sx={{ justifyContent: "flex-end" }}>
      <Button
        variant="buttons.linkWithIcon"
        onClick={updateTotal}
        disabled={disabled}
      >
        <Update noShadow /> <Text>{t("dialog.payment.updateTotal")}</Text>
      </Button>
    </Flex>
  );
}

UpdateTotal.propTypes = {
  updateTotal: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default UpdateTotal;
