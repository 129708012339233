import React, { ReactElement, useCallback } from "react";
import Help from "../../../icons/Help";
import Button from "../../canvas/Button";
import CollaboardTooltip from "../tooltip/CollaboardTooltip";
import { runtimeConfig } from "../../../tools/runtimeConfig";

const { helpPageUrl } = runtimeConfig;

function HelpButton(): ReactElement {
  const onClick = useCallback(() => window.open(helpPageUrl, "_blank"), []);

  return (
    <CollaboardTooltip
      header="tooltip.header.help"
      description="tooltip.description.help"
      placement="bottomRight"
    >
      <Button id="tbx-help" onClick={onClick}>
        <Help />
      </Button>
    </CollaboardTooltip>
  );
}
export default HelpButton;
