import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Update(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M10.59,20.79l-.3,3.33a.47.47,0,0,0,.68.46l.92-.46a8.93,8.93,0,0,0,16.45-1.54.74.74,0,0,0-.7-.94.75.75,0,0,0-.7.52,7.47,7.47,0,0,1-13.75,1.3l.94-.48a.47.47,0,0,0,0-.82l-2.86-1.73A.48.48,0,0,0,10.59,20.79Z" />
      <path d="M19.8,11.05a8.94,8.94,0,0,0-8.55,6.32.74.74,0,0,0,.7.94.75.75,0,0,0,.7-.52,7.47,7.47,0,0,1,13.75-1.3l-.94.48a.47.47,0,0,0,0,.82l2.84,1.72a.48.48,0,0,0,.72-.36l.3-3.3a.47.47,0,0,0-.68-.46l-.92.46A8.87,8.87,0,0,0,19.8,11.05Z" />
    </Icon>
  );
}
export default Update;
