import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Calendar(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M17.07,14.93a1.09,1.09,0,0,0,1.09-1.09V11.7a1.09,1.09,0,0,0-2.18,0v2.14A1.09,1.09,0,0,0,17.07,14.93Z" />
      <path d="M24.26,14.93a1.09,1.09,0,0,0,1.09-1.09V11.7a1.09,1.09,0,0,0-2.18,0v2.14A1.09,1.09,0,0,0,24.26,14.93Z" />
      <path d="M27,13.13h-.89v.71a1.88,1.88,0,0,1-3.76,0v-.71H19v.71a1.88,1.88,0,1,1-3.76,0v-.71h-.88a2.39,2.39,0,0,0-2.39,2.39V26.09a2.39,2.39,0,0,0,2.39,2.39H27.05a2.4,2.4,0,0,0,2.39-2.39V15.52A2.41,2.41,0,0,0,27,13.13Zm.81,13a.79.79,0,0,1-.79.79H14.31a.79.79,0,0,1-.8-.79V18H27.84Z" />
      <path d="M15.45,21.67h2.2a.33.33,0,0,0,.32-.32V19.94a.33.33,0,0,0-.32-.32h-2.2a.33.33,0,0,0-.32.32v1.41A.33.33,0,0,0,15.45,21.67Z" />
      <path d="M19.57,21.67h2.21a.33.33,0,0,0,.32-.32V19.94a.33.33,0,0,0-.32-.32H19.57a.34.34,0,0,0-.32.32v1.41A.32.32,0,0,0,19.57,21.67Z" />
      <path d="M23.68,21.67h2.2a.33.33,0,0,0,.32-.32V19.94a.33.33,0,0,0-.32-.32h-2.2a.33.33,0,0,0-.32.32v1.41A.33.33,0,0,0,23.68,21.67Z" />
      <path d="M15.45,25.3h2.2A.33.33,0,0,0,18,25V23.57a.33.33,0,0,0-.32-.32h-2.2a.33.33,0,0,0-.32.32V25A.33.33,0,0,0,15.45,25.3Z" />
      <path d="M19.57,25.3h2.21A.33.33,0,0,0,22.1,25V23.57a.33.33,0,0,0-.32-.32H19.57a.34.34,0,0,0-.32.32V25A.32.32,0,0,0,19.57,25.3Z" />
      <path d="M23.68,25.3h2.2A.33.33,0,0,0,26.2,25V23.57a.33.33,0,0,0-.32-.32h-2.2a.33.33,0,0,0-.32.32V25A.33.33,0,0,0,23.68,25.3Z" />
    </Icon>
  );
}
export default Calendar;
