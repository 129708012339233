import React, { ReactElement, useRef } from "react";
import { Box, Button, ThemeUIStyleObject } from "theme-ui";
import DownArrow from "../../../../icons/DownArrow";
import useDropdown from "./useDropdown";
import OptionsList from "../options/OptionsList";
import OptionsListItem, { Option } from "../options/OptionsListItem";

type Props = {
  current?: string;
  placeholder?: ReactElement;
  onSelect: (key: string) => void;
  options: Option[];
  inverted?: boolean;
  boxed?: boolean;
  itemSx?: (option: Option) => ThemeUIStyleObject;
  selectedLabelSx?: ThemeUIStyleObject;
};

function DropDown({
  current,
  placeholder,
  inverted,
  boxed,
  options,
  itemSx,
  selectedLabelSx,
  onSelect,
}: Props): ReactElement {
  const selectedEl = useRef(null);
  const dropEl = useRef(null);
  const [dropOpen, toggleDropOpen] = useDropdown(selectedEl, dropEl);

  const currentOption = current
    ? options.find((o) => o.key === current)
    : undefined;

  return (
    <Box ref={selectedEl} sx={dropdownStyle} onClick={() => toggleDropOpen()}>
      <Button
        variant="vanilla"
        sx={{
          appearance: "none",
          backgroundColor:
            inverted || boxed ? "transparent" : "var(--backgroundColor)",
          border: boxed ? "1px solid var(--buttonToggleColor__off)" : "none",
          color: inverted ? "var(--backgroundColor)" : "var(--textColor)",
          display: "flex",
          fontSize: boxed
            ? "calc(var(--gridTile) / 3)"
            : "calc(var(--gridTile) / 4)",
          justifyContent: "space-between",
          lineHeight: "var(--gridTile)",
          outline: "none",
          padding: 0,
          width: "100%",
          "> svg": {
            fill: inverted ? "var(--backgroundColor)" : "var(--textColor)",
          },
        }}
      >
        <Box
          sx={{
            ...selectionWrapStyle,
            width: boxed
              ? "calc(100% - var(--gridTile))"
              : "calc(var(--gridTile) * 2)",
          }}
        >
          <Box sx={selectedLabelSx}>{currentOption?.label || placeholder}</Box>
        </Box>
        <DownArrow noShadow />
      </Button>
      <OptionsList
        ref={dropEl}
        hidden={!dropOpen}
        inverted={inverted}
        boxed={boxed}
      >
        {options.map((option) => (
          <OptionsListItem
            key={`option-${option.key}`}
            active={current === option.key}
            option={option}
            inverted={inverted}
            boxed={boxed}
            sx={{
              backgroundColor:
                option.key === current ? undefined : "var(--contextBgColor)",
              ...(itemSx ? itemSx(option) : undefined),
            }}
            onClick={() => {
              onSelect(option.key);
              toggleDropOpen(false);
            }}
          />
        ))}
      </OptionsList>
    </Box>
  );
}

const dropdownStyle: ThemeUIStyleObject = {
  alignItems: "center",
  border: 0,
  flex: "none",
  height: "var(--gridTile)",
  padding: 0,
  position: "relative",
  maxWidth: "100%",
  width: "100%",
};

const selectionWrapStyle: ThemeUIStyleObject = {
  overflow: "hidden",
  padding: "0 0 0 calc(var(--gridTile) / 4)",
  textAlign: "left",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

export default DropDown;
