/**
 * Debounce a function.
 *
 * From underscore.js
 *
 * Returns a function, that, as long as it continues to be invoked, will not be
 * triggered. The function will be called after it stops being called for N
 * milliseconds. If `immediate` is `true`, trigger the function on the leading
 * edge, instead of the trailing.
 *
 * @template T extends Function
 * @param {T} func Function to debounce.
 * @param {number} wait Number of milliseconds to wait.
 * @param {boolean} immediate Trigger function on leading edge.
 * @returns {T & { cancel: () => void }} Function to invoke
 */
function debounce(func, wait = 50, immediate = false) {
  let timeout;
  let result;
  let args;
  let context;

  const later = function debounceLater() {
    timeout = null;
    if (args) {
      result = func.apply(context, args);
    }
  };

  const debounced = function debounced() {
    context = this;
    args = arguments;
    if (timeout) {
      clearTimeout(timeout);
    }
    if (immediate) {
      const callNow = !timeout;
      timeout = setTimeout(later, wait);
      if (callNow) {
        result = func.apply(context, args);
      }
    } else {
      timeout = setTimeout(later, wait);
    }

    return result;
  };

  debounced.cancel = function cancelDebounce() {
    clearTimeout(timeout);
    timeout = null;
    context = null;
    args = null;
  };

  return debounced;
}

export default debounce;
