import FocusTrap from "focus-trap-react";
import React, {
  MouseEvent,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
} from "react";
import ReactDOM from "react-dom";
import { Box, Button, Flex, Heading, ThemeUICSSObject } from "theme-ui";
import { preventModalScrollClass } from "../../../const";
import useHotkeys from "../dom/useHotkeys";
import Close from "../../../icons/Close";
import { noop } from "../../../tools/utils";
import ModalFooter from "./ModalFooter";
import styles from "./styles";

// todo: handle multiple instances (so that overlay doesn't multiply)
// todo: show/hide animation
// todo: prevent warning: "Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application."

// Tips:
// Use useModal hook to control the Modal
// There is a complementary component in this directory called Confirmation that wraps Modal providing it with default action buttons
// Modal component is stateless, fully declarative and uses inversion of control pattern to handle action buttons for easier customization

type onCloseFn = () => void;

export type ModalProps = PropsWithChildren<{
  isOpen?: boolean;
  onClose: onCloseFn;
  header: string | ReactElement;
  smallHeader?: boolean;
  actionButtons?: ReactElement | null;
  closeOnEscape?: boolean;
  closeOnDimmerClick?: boolean;
  additionalHeaderElements?: ReactElement | null;
  large?: boolean;
  disableAutoFocus?: boolean;
}>;

export type TemplateProps<P = JSX.IntrinsicAttributes> = P & {
  modalProps: {
    isOpen: boolean;
    onClose(): void;
  };
};

function Modal({
  isOpen,
  onClose = noop,
  children,
  header,
  smallHeader,
  actionButtons,
  closeOnEscape = true,
  closeOnDimmerClick,
  additionalHeaderElements,
  large,
  disableAutoFocus,
}: ModalProps): ReactElement | null {
  const isDrag = useRef(false);
  const isPointerDown = useRef(false);

  useHotkeys("esc", onClose, [onClose], {
    enableForModal: true,
    enableForInputs: true,
    disabled: !closeOnEscape || !isOpen,
  });

  const modalRoot = document.getElementById("modal-root");

  useEffect(() => {
    // Ensure body scrolling is disabled when modal is open
    // Important - this should not overwrite the class used by the canvas
    const { classList } = document.body;
    isOpen
      ? classList.add(preventModalScrollClass)
      : classList.remove(preventModalScrollClass);

    // Remove body scrolling lock when modal is unmounted
    return () => {
      classList.remove(preventModalScrollClass);
    };
  }, [isOpen]);

  const onDimmerPointerDown = useCallback(() => {
    isDrag.current = false;
    isPointerDown.current = true;
  }, []);

  const onDimmerPointerMove = useCallback(() => {
    isDrag.current = isPointerDown.current;
  }, []);

  const onDimmerPointerUp = useCallback(() => {
    isPointerDown.current = false;
  }, []);

  const onDimmerClick = useCallback(() => {
    if (closeOnDimmerClick && !isDrag) {
      onClose();
    }
  }, [closeOnDimmerClick, onClose]);

  const onModalClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    // prevents calling onDimmerClick when clicked within the modal
    e.stopPropagation();
  }, []);

  if (!modalRoot || !isOpen) {
    return null;
  }

  const component = (
    <FocusTrap
      active={!disableAutoFocus}
      focusTrapOptions={{
        allowOutsideClick: () => true,
        fallbackFocus: () => document.body,
      }}
    >
      <Flex
        sx={styles.overlay as ThemeUICSSObject}
        onClick={onDimmerClick}
        onPointerDown={onDimmerPointerDown}
        onPointerMove={onDimmerPointerMove}
        onPointerUp={onDimmerPointerUp}
        className="modal-overlay"
        aria-modal
        role="dialog"
      >
        <Flex
          sx={
            (large ? styles.largeWrapper : styles.wrapper) as ThemeUICSSObject
          }
          onClick={onModalClick}
        >
          {/* content needs to go before header, so that it has priority in taking focus before the Close button */}
          <Box
            sx={{
              ...(styles.content as ThemeUICSSObject),
              ...(smallHeader ? { pt: 0 } : {}),
            }}
          >
            <Box>{children}</Box>
          </Box>
          <Flex as="header" sx={styles.headerWrapper}>
            <Flex
              sx={{
                ...styles.header,
                ...(smallHeader ? { borderBottom: 0 } : {}),
              }}
            >
              <Flex sx={styles.headerContent}>
                <Heading sx={styles.heading}>{header}</Heading>
                {additionalHeaderElements}
              </Flex>

              <Button
                variant="modalHeaderIconButton"
                onClick={onClose}
                name="close-modal"
              >
                <Close />
              </Button>
            </Flex>
          </Flex>
          <ModalFooter>{actionButtons}</ModalFooter>
        </Flex>
      </Flex>
    </FocusTrap>
  );

  return ReactDOM.createPortal(component, modalRoot);
}

export default Modal;
