import React, { PropsWithChildren, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "theme-ui";
import CreditCard from "../../../../icons/CreditCard";
import Modal from "../../../shared/modals";
import ConfirmationButtons from "../../../shared/modals/ConfirmationButtons";
import { TemplateProps } from "../../../shared/modals/Modal";
import ModalHeader from "../../../shared/modals/ModalHeader";
import { withModalTemplate } from "../../../shared/modals/withModalTemplate";

type Props = PropsWithChildren<{
  totalError: Error | null;
  goToCheckout(): void;
}>;

function LicenseOrderModal({
  modalProps,
  totalError,
  goToCheckout,
  children,
}: TemplateProps<Props>): ReactElement {
  const { t } = useTranslation();

  return (
    <Modal
      {...modalProps}
      smallHeader
      header={
        <ModalHeader title={t("dialog.payment.summary")} Icon={CreditCard} />
      }
      actionButtons={
        <ConfirmationButtons
          onClose={modalProps.onClose}
          confirmationLabel={t("dialog.payment.goToCheckout")}
          onConfirm={goToCheckout}
          confirmationDisabled={Boolean(totalError)}
          closeOnConfirm={false}
        />
      }
    >
      <Box>{children}</Box>
    </Modal>
  );
}

export default withModalTemplate(LicenseOrderModal);
