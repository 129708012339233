import jwtDecode, { JwtPayload } from "jwt-decode";
import { refreshToken } from "../../api";
import { errorCodeIds } from "../../api/errorCodes";
import { InternalError } from "../../errors/InternalError";
import { isAPIError, isError, stringifyError } from "../../tools/errors";
import { wait } from "../../tools/promises";
import { LogCategory, onErrorToLog } from "../../tools/telemetry";
import { Roles } from "../../types/enum";
import { reduxDispatch } from "../redux.dispatch";
import {
  AuthUserType,
  logoutAction,
  SetAuthenticatedPayload,
} from "./auth.actions";
import {
  authenticatingStorage,
  guestAuthStorage,
  StoredAuthenticatedDetails,
  StoredAuthenticatingDetails,
  userAuthStorage,
} from "./auth.stores";

export type AuthToken = {
  AuthorizationToken: string;
  AuthorizationTokenExpiry: number;
  RefreshToken: string;
  RefreshTokenExpiry: number;
};

type AuthenticatedDetails = StoredAuthenticatedDetails & {
  roles: Roles[];
};

export const tokenExpiryBuffer = 10_000;

const noAuthDetails = "NoAuthDetails";

/**
 * Get auth token for use in API requests, SignalR, etc.
 *
 * If the token has expired this will attempt to refresh it and revoke the
 * existing RefreshToken.
 *
 * @throws Will reject if the new token has expired (indicating server-side
 * config problem)
 */
export const getOrRefreshAuthToken = async (): Promise<AuthToken> => {
  try {
    const authDetails = getValidAuthDetails();

    if (!authDetails) {
      throw new Error(noAuthDetails);
    }

    const { token } = authDetails;

    if (!isAuthTokenExpired(token)) {
      return token;
    }

    const newToken = await exchangeRefreshTokenForNewToken(authDetails);

    if (newToken) {
      storeAuthenticatedDetails({
        ...authDetails,
        token: newToken,
      });
    }

    // Always read the auth details from storage
    const newAuthDetails = getValidAuthDetails();

    if (!newAuthDetails) {
      throw new Error(noAuthDetails);
    }

    const { token: newTokenFromStorage } = newAuthDetails;

    if (isAuthTokenExpired(newTokenFromStorage)) {
      // Server has given us an expired token - something is wrong
      throw new Error("New auth token expired");
    }

    return newTokenFromStorage;
  } catch (error) {
    // Don't log missing auth details (aka not logged in) as an error
    if (isError(error) && error.message !== noAuthDetails) {
      onErrorToLog(error, LogCategory.auth);
    } else if (!isError(error)) {
      onErrorToLog(
        new InternalError(`Failed to refresh token: ${stringifyError(error)}`),
        LogCategory.auth
      );
    }

    reduxDispatch(logoutAction());

    /**
     * @NOTE - This method is used by other libraries (SignalR and
     * StorageClient) which require a rejected promise to stop the flow of what
     * they're doing.
     */
    return Promise.reject(error);
  }
};

/**
 * Revoke the refresh token on the server.
 */
export const revokeRefreshToken = async (): Promise<void> => {
  try {
    const authDetails = getValidAuthDetails();

    if (!authDetails) {
      return;
    }

    const { token } = authDetails;
    const { RefreshToken } = token;

    if (!RefreshToken) {
      onErrorToLog(
        new InternalError("Attempted to revoke an unvalidated 2FA token"),
        LogCategory.auth
      );
      return;
    }

    await refreshToken(RefreshToken);
  } catch (error) {
    onErrorToLog(
      isError(error)
        ? error
        : new InternalError("Failed to revoke refresh token"),
      LogCategory.auth
    );
  }
};

/**
 * Store auth details in the user's storage.
 *
 * @NOTE This will store the token even if it is already expired. Any subsequent
 * API requests will refresh the token.
 */
export const storeAuthenticatedDetails = (
  payload: SetAuthenticatedPayload
): void => {
  const { authProvider, isRegistration, tenant, token, userType } = payload;
  const { AuthorizationToken, RefreshToken } = token;

  // Only store necessary properties
  const authToken: AuthToken = {
    AuthorizationToken,
    AuthorizationTokenExpiry:
      getTokenExpiryTimestamp(AuthorizationToken) - tokenExpiryBuffer,
    RefreshToken,
    RefreshTokenExpiry:
      getTokenExpiryTimestamp(RefreshToken) - tokenExpiryBuffer,
  };

  const authDetails: StoredAuthenticatedDetails = {
    authProvider,
    isRegistration,
    tenant,
    token: authToken,
    userType,
  };

  if (userType === AuthUserType.User) {
    userAuthStorage.set(authDetails);
  } else if (userType === AuthUserType.Guest) {
    guestAuthStorage.set(authDetails);
  }
};

/**
 * Look for valid auth details from storage.
 */
export const getAuthenticatedDetails = (): AuthenticatedDetails | undefined => {
  try {
    const authDetails = getValidAuthDetails();

    if (!authDetails) {
      return undefined;
    }

    return {
      ...authDetails,
      roles: getRolesFromToken(authDetails.token.AuthorizationToken),
    };
  } catch {
    return undefined;
  }
};

/**
 * Look for authenticating details in storage.
 */
export const getAuthenticatingDetails = ():
  | StoredAuthenticatingDetails
  | undefined => authenticatingStorage.get();

/**
 * Write authenticating details to storage.
 */
export const storeAuthenticatingDetails = (
  payload: StoredAuthenticatingDetails
): void => authenticatingStorage.set(payload);

/**
 * Clear authenticating details from storage.
 */
export const clearAuthenticatingDetails = (): void =>
  authenticatingStorage.clear();

/**
 * Destroy auth token for this user type.
 *
 * @NOTE - It is possible for a user to have multiple GUEST sessions in a single
 * browser. This is possible because the token is stored in SessionStorage
 * which is isolated to a single tab (usually - see exact specification here:
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage)
 *
 * In this case only the token for the current session context will be cleared.
 *
 * It is not possible for a user to have multiple USER sessions (usually)
 * because user tokens are stored in LocalStorage which is shared by tabs. The
 * exception here is when the app is configured to use `InMemoryStorage`.
 */
export const clearAuthStorageByUserType = (userType: AuthUserType): void => {
  userType === AuthUserType.User && userAuthStorage.clear();
  userType === AuthUserType.User && authenticatingStorage.clear();

  userType === AuthUserType.Guest && guestAuthStorage.clear();
};

/**
 * Clear all auth storage.
 *
 * Use this when we don't know the type of user.
 */
export const clearAllAuthStorage = (): void => {
  userAuthStorage.clear();
  authenticatingStorage.clear();
  guestAuthStorage.clear();
};

/**
 * Extract user roles from token.
 */
export const getRolesFromToken = (token: string): Roles[] => {
  try {
    const { roles } = jwtDecode<JwtPayload>(token);
    return (roles ?? []).filter(isValidRole);
  } catch (e: unknown) {
    onErrorToLog(
      isError(e)
        ? e
        : new InternalError(`Failed to extract roles from JWT token: ${e}`)
    );
    return [];
  }
};

/**
 * Only accept known roles.
 */
const isValidRole = (role: Roles): role is Roles =>
  Object.values(Roles).includes(role);

/**
 * Get and validate auth details from storage.
 */
const getValidAuthDetails = (): StoredAuthenticatedDetails | undefined => {
  const authDetails = getAuthDetailsFromStorage();

  if (!authDetails) {
    return undefined;
  }

  const { token } = authDetails;

  if (!token) {
    return undefined;
  }

  if (!isTokenRefreshable(token)) {
    return undefined;
  }

  return authDetails;
};

/**
 * Get the stored auth details.
 *
 * @NOTE - We don't know where to find a token when the app first loads so
 * this looks through the different storage options in priority order.
 *
 * We prioritize the guest storage because its context is more restrictive.
 * This means that if you were to login with a real user in another tab this
 * tab will continue to use the guest token / mode. Any new tabs will use the
 * auth token of the logged in user.
 */
const getAuthDetailsFromStorage = ():
  | StoredAuthenticatedDetails
  | undefined => {
  return guestAuthStorage.get() ?? userAuthStorage.get() ?? undefined;
};

/**
 * Shared promise for refresh token requests
 */
let sharedRefreshTokenPromise:
  | Promise<ApiValidatedAuthenticate | undefined>
  | undefined;

/**
 * Revoke the existing RefreshToken and get a new token.
 *
 * @NOTE Multiple requests will be batched into a single, shared request
 */
const exchangeRefreshTokenForNewToken = async (
  authDetails: StoredAuthenticatedDetails
): Promise<ApiValidatedAuthenticate | undefined> => {
  if (sharedRefreshTokenPromise) {
    return sharedRefreshTokenPromise;
  }

  const { token } = authDetails;
  const { RefreshToken } = token;

  if (!RefreshToken) {
    onErrorToLog(
      new InternalError("Attempted to revoke an unvalidated 2FA token"),
      LogCategory.auth
    );
    return sharedRefreshTokenPromise;
  }

  // Do not await here - we want to share the promise
  sharedRefreshTokenPromise = refreshToken(RefreshToken)
    .catch(async (error: unknown) => {
      if (isAPIError(error)) {
        const { details } = error;
        const { errorCode } = details;

        if (errorCode === errorCodeIds.RefreshTokenNotValid) {
          /**
           * This happens when two tabs try to revoke the token at the same
           * moment. One wins the race and the others get a 2002 error code.
           * See #6829.
           *
           * When this happens a new token will soon be in storage, once the
           * request in the other tab completes. So we simply wait a little and
           * then return `undefined` and the flow will continue with the value
           * from storage.
           *
           * @TODO #7118 - Figure out how to use storage events to determine
           * when the token has actually been updated, rather than this
           * arbitrary delay.
           *
           * https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
           */
          await wait(3000);

          return undefined;
        }
      }

      return Promise.reject(error);
    })
    .finally(() => {
      // Clear the promise so the next attempt makes a new request
      sharedRefreshTokenPromise = undefined;
    });

  return sharedRefreshTokenPromise;
};

/**
 * Check if auth token has expired.
 */
const isAuthTokenExpired = (token: AuthToken): boolean => {
  const { AuthorizationTokenExpiry } = token;

  return AuthorizationTokenExpiry < Date.now();
};

/**
 * Check if token is refreshable.
 */
const isTokenRefreshable = (token: AuthToken): boolean => {
  const { AuthorizationToken, RefreshToken, RefreshTokenExpiry } = token;

  return (
    !!AuthorizationToken && !!RefreshToken && RefreshTokenExpiry > Date.now()
  );
};

const getTokenExpiryTimestamp = (token: string): number => {
  try {
    const { exp = 0 } = jwtDecode<JwtPayload>(token);
    const expiry = exp * 1000;
    return expiry;
  } catch (e: unknown) {
    onErrorToLog(
      isError(e) ? e : new InternalError(`Failed to decode JWT token: ${e}`)
    );
    return 0;
  }
};
