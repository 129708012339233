import React, { ReactElement } from "react";
import { Text, Box, Grid, ThemeUIStyleObject } from "theme-ui";

interface Style {
  iconSize: string;
  title: ThemeUIStyleObject;
  subTitle: ThemeUIStyleObject;
}

interface AllStyles {
  header: Style;
  primary: Style;
  secondary: Style;
}

const allStyles: AllStyles = {
  header: {
    iconSize: "40px",
    title: {
      fontSize: [5],
      color: "var(--textColor)",
    },
    subTitle: {
      fontSize: [4],
      color: "var(--textColor)",
    },
  },
  primary: {
    iconSize: "40px",
    title: {
      fontSize: [5],
      color: "var(--textColor)",
    },
    subTitle: {
      fontSize: [3],
      color: "var(--modalSubTitle)",
    },
  },
  secondary: {
    iconSize: "32px",
    title: {
      fontSize: [4],
      color: "var(--textColor)",
    },
    subTitle: {
      fontSize: [3],
      color: "var(--modalSubTitle)",
    },
  },
};

export interface ModalSegmentProps {
  Icon?: (props: IconProps) => ReactElement;
  title?: string;
  subTitle?: ReactElement;
  variant: keyof typeof allStyles;
  children?: ReactElement;
  disabled?: boolean;
  sx?: ThemeUIStyleObject;
}

function ModalSegment({
  Icon,
  title,
  subTitle,
  variant,
  children,
  disabled,
  sx,
}: ModalSegmentProps): ReactElement {
  const styles = allStyles[variant];
  const opacity = disabled ? 0.4 : 1;

  return (
    <Grid
      sx={{
        gridGap: 0,
        gridTemplateColumns: `${Icon ? styles.iconSize : ""} auto`,
        gridTemplateRows: `${Icon ? styles.iconSize : ""} auto`,
        gridTemplateAreas: `
          ${Icon ? '"icon title"' : '"title"'}
          ". content"
        `,
        ...sx,
      }}
    >
      {Icon && (
        <Box
          sx={{
            gridArea: "icon",
            svg: {
              width: styles.iconSize,
              height: styles.iconSize,
              fill: "var(--textColor)",
              cursor: "default",
              "&:hover": {
                fill: "var(--textColor)",
              },
            },
            opacity,
          }}
        >
          <Icon noShadow />
        </Box>
      )}
      <Text
        variant="default"
        sx={{
          ...styles.title,
          fontWeight: "600",
          gridArea: "title",
          alignSelf: "center",
          opacity,
        }}
      >
        {title}
      </Text>
      <Box sx={{ gridArea: "content" }}>
        <Text variant="default" sx={{ opacity, ...styles.subTitle }}>
          {subTitle}
        </Text>
        {children && <Box sx={{ mt: [4] }}>{children}</Box>}
      </Box>
    </Grid>
  );
}

export default ModalSegment;
