import React, { ReactElement } from "react";
import PlanBase from "./PlanBase";
import PlanDetails, { PlanDetailsActions } from "./PlanDetails";
import PlanFeatures from "./PlanFeatures";
import { Currency } from "../useCurrencies";

type Props = {
  data: Plan;
  currency: Currency;
  activePlan: ActivePlan;
  plans: Record<PlanNames, Plan>;
  actions: PlanDetailsActions;
  horizontal?: boolean;
};

function Plan({
  data,
  currency,
  activePlan,
  plans,
  actions,
  horizontal,
}: Props): ReactElement {
  const isActive = data.productTypeId === activePlan.productTypeId;

  const planKeys = Object.keys(plans);
  const plansCount = planKeys.length;
  const orderBase = planKeys.indexOf(data.name) + 1;
  const order = {
    base: orderBase,
    details: orderBase + plansCount,
    features: orderBase + plansCount * 2,
  };
  const commonStyles = {
    overflow: "hidden",
    bg: isActive ? "primary" : "background",
    color: isActive ? "background" : "text",
    padding: "32px 10px 0 10px",
  };

  return (
    <>
      <PlanBase
        sx={{
          order: order.base,
          ...(horizontal ? { gridArea: "base" } : {}),
          ...commonStyles,
        }}
        isActive={isActive}
        activePlan={activePlan}
        currency={currency}
        data={data}
      />

      <PlanDetails
        sx={{
          order: order.details,
          ...(horizontal ? { gridArea: "details" } : {}),
          ...commonStyles,
        }}
        horizontal={horizontal}
        data={data}
        isActive={isActive}
        plans={plans}
        activePlan={activePlan}
        actions={actions}
      />

      <PlanFeatures
        sx={{
          order: order.features,
          ...(horizontal ? { gridArea: "features" } : {}),
          ...commonStyles,
        }}
        horizontal={horizontal}
        data={data}
      />
    </>
  );
}

export default Plan;
