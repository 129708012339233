import { filter } from "ramda";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Grid,
  Text,
  ThemeUIStyleObject,
} from "theme-ui";
import { ProductTypeIDs } from "../../../const";
import LoadingSpinner from "../../../icons/LoadingSpinner";
import { useUISettings } from "../../../reduxStore/uiSettings";
import { getCustomerConfiguration } from "../../../tools/customer";
import { dynamicFeatureFlags } from "../../../tools/flags";
import { LogCategory } from "../../../tools/telemetry";
import { useCustomerComponents } from "../../licensing/useCustomerComponents";
import { useSimpleModal } from "../../shared/modals/useSimpleModal";
import { useTrackEventToLog } from "../../shared/tracking/useTrackEventToLog";
import PageAuthorized from "../layout/pages/PageAuthorized";
import CancelSubscriptionModalTemplate from "./CancelSubscriptionModalTemplate";
import ComparisonTable from "./ComparisonTable";
import { getFreeTrialLink } from "./consts";
import CurrencySwitcher from "./CurrencySwitcher";
import IndividualLicenseOrderModalTemplate from "./IndividualLicenseOrderModalTemplate";
import PaymentSuccessModalTemplate from "./PaymentSuccessModalTemplate";
import { PaymentSuccessModalProps } from "./PaymentSuccessModalTemplate/PaymentSuccessModalTemplate";
import Plan from "./Plan";
import OtherLicense from "./Plan/OtherLicense";
import RedeemModalTemplate from "./RedeemModalTemplate/RedeemModalTemplate";
import TeamLicenseOrderModalTemplate from "./TeamLicenseOrderModalTemplate";
import { usePlans } from "./usePlans";

const { compactPlansPage } = getCustomerConfiguration();

const LinkButton = Button as (
  props: ButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement>
) => ReactElement;

function Plans(): ReactElement {
  const { t, i18n } = useTranslation();
  const { trackEvent } = useTrackEventToLog();
  const { isDynamicFeatureActive } = useUISettings();
  const { PlansPageFooter } = useCustomerComponents();

  const isEcommerceFeatureActive = !isDynamicFeatureActive(
    dynamicFeatureFlags.NO_ECOMMERCE
  );

  useEffect(() => {
    trackEvent(LogCategory.navigation, {
      subcategory: "page-plan",
    });
  }, [trackEvent]);

  const [
    paymentData,
    setPaymentData,
  ] = useState<PaymentSuccessModalProps | null>(null);
  const onPaymentSuccessClose = useCallback(() => {
    setPaymentData(null);

    // remove the query string from url and from history so user can't access it again using back button
    window.history.replaceState({}, "plans", "/plans");
    window.location.reload();
  }, []);

  const paymentSuccessModalProps = useSimpleModal({
    onClose: onPaymentSuccessClose,
  });

  const {
    activePlan,
    currency,
    currencies,
    onCurrencyChange,
    plans,
    others,
    subscription,
    savings,
    onCancelSubscription,
    onResumeSubscription,
    isLoading,
  } = usePlans((data) => {
    setPaymentData(data);
    paymentSuccessModalProps.open();
  });

  const [productTypeId, setProductTypeId] = useState<ProductTypeIDs | null>(
    null
  );
  const onTeamLicenceModalClose = useCallback(() => setProductTypeId(null), []);
  const individualLicenseOrderModalProps = useSimpleModal();
  const teamLicenseOrderModalProps = useSimpleModal({
    onClose: onTeamLicenceModalClose,
  });
  const cancelSubscriptionModalProps = useSimpleModal();
  const redeemModalProps = useSimpleModal();

  const onBuy = (productTypeId: ProductTypeIDs) => {
    const action =
      productTypeId === ProductTypeIDs.individual
        ? individualLicenseOrderModalProps.open
        : () => {
            setProductTypeId(productTypeId);
            teamLicenseOrderModalProps.open();
          };

    action();
  };

  if (isLoading) {
    return (
      <PageAuthorized>
        <LoadingSpinner />
      </PageAuthorized>
    );
  }

  const plansInGrid = Object.values(
    filter((plan) => plan.isVisible && (compactPlansPage || plan.inGrid), plans)
  );
  const horizontalPlans = Object.values(
    filter((plan) => plan.isVisible && !compactPlansPage && !plan.inGrid, plans)
  );

  return (
    <PageAuthorized>
      {isEcommerceFeatureActive && (
        <Flex sx={{ justifyContent: "space-between" }}>
          <Flex sx={{ alignItems: "center", fontSize: "18px" }}>
            <Trans i18nKey="plans.redeem">
              <Button
                sx={redeemStyle}
                variant="link"
                onClick={redeemModalProps.open}
              />
            </Trans>
          </Flex>

          {/* todo: replace with basic dropdown */}
          <CurrencySwitcher
            currencies={currencies}
            selected={currency}
            onChange={onCurrencyChange}
          />
        </Flex>
      )}
      {activePlan.name === plans.free.name && isEcommerceFeatureActive && (
        <Flex sx={{ fontSize: "18px", mb: [3] }}>
          <Trans i18nKey="plans.freeTrialLine">
            <LinkButton
              as="a"
              href={getFreeTrialLink(i18n.resolvedLanguage)}
              target="_blank"
              rel="nofollow noreferrer"
              variant="link"
              sx={freeTrialStyle}
            >
              {t("plans.freeTrial")}
            </LinkButton>
          </Trans>
        </Flex>
      )}

      <Box sx={inGridBoxStyle}>
        <Grid
          sx={{
            gridTemplateColumns: `repeat(${plansInGrid.length}, minmax(18rem, 1fr))`,
            rowGap: 0,
            columnGap: [3],
          }}
        >
          {plansInGrid.map((plan) => (
            <Plan
              key={plan.name}
              data={plan}
              plans={plans}
              activePlan={activePlan}
              currency={currency}
              actions={{
                onBuy,
                openCancelSubscriptionModal: cancelSubscriptionModalProps.open,
                onResumeSubscription,
              }}
            />
          ))}
        </Grid>
      </Box>

      {horizontalPlans.map((plan) => (
        <Grid
          key={plan.name}
          sx={{
            ...horizontalGridStyle,
            gridTemplateColumns: `repeat(${plansInGrid.length}, 1fr)`,
          }}
        >
          <Plan
            data={plan}
            horizontal
            plans={plans}
            activePlan={activePlan}
            currency={currency}
            actions={{
              openIndividualLicenseOrderModal:
                individualLicenseOrderModalProps.open,
              openTeamLicenseOrderModal: teamLicenseOrderModalProps.open,
              openCancelSubscriptionModal: cancelSubscriptionModalProps.open,
              onResumeSubscription,
            }}
          />
        </Grid>
      ))}

      {!compactPlansPage && (
        <>
          <ComparisonTable data={plans} onBuy={onBuy} />
          <Text variant="standard" sx={othersHeadingStyle}>
            {t("plans.othersHeading")}
          </Text>

          <Grid
            sx={{
              gridAutoFlow: "column",
              gridTemplateColumns: `repeat(${others.length}, 1fr)`,
              gridTemplateRows: "auto auto",
            }}
          >
            {others.map((license) => (
              <OtherLicense key={license.nameKey} license={license} />
            ))}
          </Grid>
        </>
      )}

      {PlansPageFooter && <PlansPageFooter />}

      <IndividualLicenseOrderModalTemplate
        modalProps={individualLicenseOrderModalProps}
        currency={currency}
        savings={savings as string} //  TODO: remove when usePlan is migrated to TS
      />
      {productTypeId && (
        <TeamLicenseOrderModalTemplate
          modalProps={teamLicenseOrderModalProps}
          productTypeID={productTypeId}
          currency={currency}
          savings={savings as string} //  TODO: remove when usePlan is migrated to TS
        />
      )}
      {subscription && (
        <CancelSubscriptionModalTemplate
          modalProps={cancelSubscriptionModalProps}
          onConfirm={onCancelSubscription}
          activeTo={subscription.ActiveTo}
        />
      )}
      {paymentData && (
        <PaymentSuccessModalTemplate
          modalProps={paymentSuccessModalProps}
          {...paymentData}
        />
      )}
      <RedeemModalTemplate modalProps={redeemModalProps} />
    </PageAuthorized>
  );
}

const redeemStyle: ThemeUIStyleObject = {
  ml: [1],
  color: "primary",
  fontWeight: 500,
};

const freeTrialStyle: ThemeUIStyleObject = {
  ml: [1],
  // Can't override <a> styles for some reason, probably related to specificity of custom properties
  color: "var(--accent) !important",
  fontWeight: 500,
};

const inGridBoxStyle: ThemeUIStyleObject = {
  maxWidth: "100%",
  overflowX: "auto",
};

const horizontalGridStyle: ThemeUIStyleObject = {
  mt: [6],
  display: ["none", "none", "none", "none", "grid"],
  gridRowGap: 0,
  gridColumnGap: "8px",
  gridTemplateAreas: `
    "base features features features"
    "details features features features"
  `,
};

const othersHeadingStyle: ThemeUIStyleObject = {
  textAlign: "center",
  fontSize: [5],
  mt: [6],
  mb: [3],
};

export default Plans;
