import { AnySchema, object, string } from "yup";
import { TFunction } from "i18next";
import { initializePhoneValidation } from "../tools/validationTools";
import { userNameRegex } from "../tools/regex";
import { maxUserNameLength } from "./fieldRestrictions";

export const userProfileSchema = (t: TFunction): AnySchema => {
  initializePhoneValidation(t);

  return object().shape({
    FirstName: string()
      .required(t("clientError.required"))
      .matches(userNameRegex, t("clientError.invalidFormat"))
      .max(
        maxUserNameLength,
        t("clientError.tooLongMax", { max: maxUserNameLength })
      ),
    LastName: string()
      .required(t("clientError.required"))
      .matches(userNameRegex, t("clientError.invalidFormat"))
      .max(
        maxUserNameLength,
        t("clientError.tooLongMax", { max: maxUserNameLength })
      ),
    PhoneNumber: string().phone(),
  });
};
