import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useRef,
} from "react";
import { Box, Flex } from "theme-ui";
import { HexColorPicker } from "react-colorful";
import ContextInput from "../../../shared/forms/input/ContextInput";
import EyeDropperIcon from "../../../../icons/EyeDropper";
import { ColorPickerBase } from "../../navigation/toolbox/ColorPicker";

import { isTransparent } from "../../../../tools/colors";
import Button from "../../Button";
import {
  colorThemes,
  objectColorPaletteWithTransparency,
} from "../../../../const";
import EyeDropper from "../../../shared/color/EyeDropper";

type Props = PropsWithChildren<{
  columnsCount?: number;
  defaultValue?: string;
  colors: ColorPalette;
  theme: colorThemes;
  onBeforeChange?(color: string): void;
  onChange?(newColor: string): void;
  onAfterChange?(newColor: string): void;
  horizontalLayout?: boolean;
  reverseComponentsOrder: boolean;
  hideInput?: boolean;
  isStaticPalette: boolean;
  insetColorPickerX?: boolean;
}>;

const fontSize = "calc(var(--gridTile) / 2) !important";
const pointerSize = (multiplier = 1): string =>
  `calc(var(--gridTile) / ${2 / multiplier})`;

function HueColorPicker({
  defaultValue = "#000000",
  colors,
  columnsCount = 4,
  theme,
  onBeforeChange,
  onChange,
  onAfterChange,
  horizontalLayout,
  reverseComponentsOrder,
  hideInput,
  insetColorPickerX = false,
  isStaticPalette,
  children,
}: Props): ReactElement {
  const colorRef = useRef(defaultValue);
  const onMouseDown = useCallback(() => {
    const onMouseUp = () => {
      onAfterChange?.(colorRef.current);
      document.removeEventListener("mouseup", onMouseUp);
    };

    onBeforeChange?.(colorRef.current);
    document.addEventListener("mouseup", onMouseUp);
  }, [onBeforeChange, onAfterChange]);

  const fullUpdate = useCallback(
    (color: string) => {
      onBeforeChange?.(color);
      onChange?.(color);
      onAfterChange?.(color);
    },
    [onBeforeChange, onChange, onAfterChange]
  );

  const allowTransparentColor = colors === objectColorPaletteWithTransparency;

  return (
    <Flex
      sx={{
        flexDirection: horizontalLayout ? "row-reverse" : "column",
        columnGap: [4],
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      {children && (
        <Flex sx={{ width: "calc(var(--gridTile) * 1.5)" }}>{children}</Flex>
      )}

      <Flex
        sx={{
          flexDirection: horizontalLayout
            ? "row-reverse"
            : reverseComponentsOrder
            ? "column-reverse"
            : "column",
          flexGrow: 1,
          columnGap: [4],
        }}
      >
        {!hideInput && (
          <Flex
            sx={{
              alignItems: "center",
              fontSize,
            }}
          >
            <Box
              sx={{
                color: "var(--iconColor__disabled)",
                fontWeight: "bold",
                pl: [3],
              }}
            >
              #
            </Box>

            <ContextInput
              type="color"
              defaultValue={defaultValue}
              onChange={fullUpdate}
              customStyle={{
                fontSize,
                flexGrow: 1,
                px: [1],
              }}
            />
            <EyeDropper
              onColorPicked={fullUpdate}
              allowTransparentColor={allowTransparentColor}
            >
              {({ isEyeDropperActive, isDisabled, enterEyeDropper }) => (
                <Button
                  active={isEyeDropperActive}
                  disabled={isDisabled}
                  onClick={enterEyeDropper}
                  sx={{ ml: ["auto"] }}
                >
                  <EyeDropperIcon noShadow />
                </Button>
              )}
            </EyeDropper>
          </Flex>
        )}

        <Flex
          onMouseDown={onMouseDown}
          sx={{
            mx: insetColorPickerX ? [2] : [0],
            my: [2],
            flexGrow: 1,
            minWidth: "calc(var(--gridTile) * 2)",
            ".react-colorful": {
              display: "flex",
              flexDirection: reverseComponentsOrder
                ? "column-reverse"
                : undefined,
              height: horizontalLayout ? "auto" : pointerSize(5),
              width: "100%",
              pointerEvents: "auto",
              ".react-colorful__saturation, .react-colorful__last-control": {
                borderRadius: 0,
              },
              ".react-colorful__saturation": {
                borderBottomWidth: 0,
              },
              ".react-colorful__pointer": {
                width: pointerSize(),
              },
              ".react-colorful__hue": {
                mb: reverseComponentsOrder ? [2] : undefined,
                mt: reverseComponentsOrder ? undefined : [2],
              },
              ".react-colorful__pointer, .react-colorful__hue": {
                height: pointerSize(),
              },
            },
          }}
        >
          <HexColorPicker
            color={isTransparent(defaultValue) ? "#ffffff" : defaultValue}
            onChange={(color) => {
              colorRef.current = color;
              onChange?.(color);
            }}
          />
        </Flex>

        <ColorPickerBase
          colors={colors}
          theme={theme}
          active={defaultValue}
          onSelect={fullUpdate}
          columnsCount={columnsCount}
          isStaticPalette={isStaticPalette}
          horizontalLayout={horizontalLayout}
        />
      </Flex>
    </Flex>
  );
}

export default HueColorPicker;
