export enum AlignActionType {
  ALIGN = "ALIGN",
  DISTRIBUTE = "DISTRIBUTE",
}

export enum AlignDirection {
  bottom = "bottom",
  left = "left",
  right = "right",
  top = "top",
  centerHorizontal = "centerHorizontal",
  centerVertical = "centerVertical",
}

export const alignAction = (payload: AlignDirection) =>
  ({
    type: AlignActionType.ALIGN,
    payload,
  } as const);

export enum DistributeDirection {
  horizontal = "horizontal",
  vertical = "vertical",
}

export const distributeAction = (payload: DistributeDirection) =>
  ({
    type: AlignActionType.DISTRIBUTE,
    payload,
  } as const);

export type AlignAction = ReturnType<typeof alignAction>;
export type DistributeAction = ReturnType<typeof distributeAction>;

export type AlignmentAction = AlignAction | DistributeAction;
