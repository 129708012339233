import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Profile(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M27.32,24.05c-1.56-1.11-3.95-.52-5.44-2.5,0,0-.56-.62.69-2.14s1.35-3.95,1-5.83a4,4,0,0,0-7.83,0c-.31,1.88-.21,4.3,1,5.83s.69,2.14.69,2.14c-1.49,2-3.88,1.39-5.44,2.5s-1.18,3.64-1.18,3.64h17.6S28.88,25.16,27.32,24.05Z" />
    </Icon>
  );
}
export default Profile;
