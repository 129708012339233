import axios from "axios";
import { SearchImageSizeFilter } from "../const";
import { runtimeConfig } from "../tools/runtimeConfig";

const { bingApiKey } = runtimeConfig;
let cancelToken = axios.CancelToken.source();

export type ImageSearchOptions = {
  count: number;
  size: SearchImageSizeFilter;
};

type BingImageSearchResponse = {
  value: {
    contentUrl: string;
    encodingFormat: string;
    height: number;
    name: string;
    thumbnailUrl: string;
    width: number;
  }[];
};

export type BingImage = {
  height: number;
  name: string;
  src: string;
  srcSet: string;
  width: number;
  fullWidth: number;
  fullHeight: number;
  encodingFormat: string;
};

export interface SearchImages {
  searchTerm: string;
  options: ImageSearchOptions;
}

export const searchImages = async ({
  searchTerm,
  options,
}: SearchImages): Promise<BingImage[]> => {
  try {
    cancelToken.cancel();
    cancelToken = axios.CancelToken.source();
    const { count, size } = options;
    const response = await axios.get<BingImageSearchResponse>(
      `https://api.bing.microsoft.com/v7.0/images/search?q=${encodeURIComponent(
        searchTerm
      )}&count=${count}&size=${size}&imageType=Photo`,
      {
        cancelToken: cancelToken.token,
        headers: {
          "Ocp-Apim-Subscription-Key": bingApiKey,
          Accept: "application/json",
        },
      }
    );

    return response.data.value.map((image) => {
      const {
        contentUrl,
        encodingFormat,
        height,
        name,
        thumbnailUrl,
        width,
      } = image;
      return {
        height,
        name,
        src: contentUrl,
        srcSet: thumbnailUrl,
        width,
        fullWidth: width,
        fullHeight: height,
        encodingFormat,
      };
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};
