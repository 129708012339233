import axios, { AxiosRequestConfig } from "axios";
import { deviceUuid, projectPermissions, routes } from "../const";
import { CollaboardApiError } from "../errors/collaboardApiError";
import {
  ProfileFormValues,
  RegistrationFormValues,
} from "../features/authentication/useAuthentication";
import { getOrRefreshAuthToken } from "../reduxStore/auth/auth.storage";
import { ScreenCoordinates } from "../reduxStore/canvas/presentation/presentation.reducer";
import { VotingSessionType } from "../reduxStore/canvas/voting/voting.reducer";
import { getCustomer } from "../tools/customer";
import { errorToast } from "../tools/errorToast";
import { isRunningInMSTeams } from "../tools/msTeams";
import { runtimeConfig } from "../tools/runtimeConfig";
import { LogCategory, trackEventToLog } from "../tools/telemetry";
import { stringifyQueryUrl } from "../tools/url";
import { utcDate } from "../tools/utils";
import { getAppVersion } from "../tools/versionInfo";
import {
  ApiAuthenticationMode,
  ApiPermission,
  ApiSubscriptionUserPermission,
  MediaAction,
  Roles,
} from "../types/enum";
import { errorCodeIds } from "./errorCodes";
import { getAllPages } from "./helpers";
import type {
  DuplicateTile,
  InkTileStatus,
  TileRelation,
  TileStatus,
} from "./signalR/message.types";

declare module "axios" {
  interface AxiosRequestConfig {
    isGuestRequest?: boolean; // TODO - rename because it isn't related to guests #7118
    token?: string;
    preventDefaultErrorHandling?: boolean | errorCodeIds[];
    shouldGetFullError?: boolean;
  }
}
declare module "jwt-decode" {
  interface JwtPayload {
    tfa?: boolean;
    roles?: Roles[];
  }
}

const MessageTheme = getCustomer(); // TODO: Read this from Redux, not const

const { apiUrl, appName } = runtimeConfig;
const licensingApiUrl = `${apiUrl}/licensing/api/Licensing`;
const authApiUrl = `${apiUrl}/auth/api/Authorization`;
const searchApiUrl = `${apiUrl}/search/api/Search`;

export const Api = axios.create({
  baseURL: `${apiUrl}/api`,
});

const getAppName = () => `${appName}${isRunningInMSTeams() ? "-teams" : ""}`;

export const requestCancelled = "Request cancelled intentionally";

/**
 * Handle an API error.
 *
 * This could be triggered by an ErrorCode returned by the server, a network
 * error or some other unexpected error.
 *
 * @private
 */
const handleApiError = (
  originalError: ApiErrorResponse | SimulatedApiErrorResponse
): Promise<void> => {
  if (axios.isCancel(originalError)) {
    /**
     * Reject silently when a request is cancelled. This only happens
     * intentionally, for example if the user doesn't have a valid auth token
     * or they already have an inflight request to the YouTube API and they've
     * changed their search terms and triggered another search request.
     */
    return Promise.reject(requestCancelled);
  }

  const error = new CollaboardApiError(originalError);
  const {
    errorMessage,
    errorCode,
    isHTTPError,
    statusText,
    reasonPhrase,
  } = error.details;

  const { shouldGetFullError, preventDefaultErrorHandling } =
    error.config || {};

  // TODO: #2851 temporary solution before refactoring axios to be more react-like...
  const triggerRedirectOnErrorCodes = [
    errorCodeIds.Maintenance,
    errorCodeIds.NoAuthTokenProvided,
    errorCodeIds.AuthTokenExpired,
    errorCodeIds.NetworkError,
  ];

  if (
    errorCode &&
    triggerRedirectOnErrorCodes.includes(errorCode) &&
    window.location.pathname !== routes.error
  ) {
    window.forceUnload = true;
    window.location.assign(`${routes.error}?code=${errorCode}`);
  }

  const flagBypassesErrorToast = preventDefaultErrorHandling === true;

  const errorCodeBypassesErrorToast =
    Array.isArray(preventDefaultErrorHandling) &&
    preventDefaultErrorHandling.includes(errorCode) &&
    errorCode;

  if (!flagBypassesErrorToast && !errorCodeBypassesErrorToast) {
    errorToast(errorMessage);
  }

  trackEventToLog(LogCategory.api, error.toObject());

  const useMessage = isHTTPError ? statusText || reasonPhrase : errorMessage;

  // TODO: Deprecate `shouldGetFullError` so we always reject with the full error
  return Promise.reject(shouldGetFullError ? error : useMessage);
};

/**
 *
 * REQUEST INTERCEPTOR
 *
 */

Api.interceptors.request.use(
  async (request): Promise<AxiosRequestConfig> => {
    /**
     * @TODO - Update this to AbortController when we upgrade Axios
     * https://axios-http.com/docs/cancellation
     */
    const cancelSource = axios.CancelToken.source();

    const { isGuestRequest } = request;
    let { token } = request;

    if (!isGuestRequest && !token) {
      try {
        const authToken = await getOrRefreshAuthToken();
        token = authToken.AuthorizationToken;
      } catch {
        cancelSource.cancel();
      }
    }

    if (token) {
      request.headers.common.Authorization = `Bearer ${token}`;
    }

    return {
      ...request,
      data: {
        UniqueDeviceId: deviceUuid,
        AppVer: getAppVersion(),
        ...request.data,
      },
      cancelToken: cancelSource.token,
    };
  }
);

/**
 *
 * RESPONSE INTERCEPTOR
 *
 */
Api.interceptors.response.use(
  async (response) => {
    const { data, config } = response;
    const result = data?.Result ?? data?._result ?? data;

    return result?.ErrorCode
      ? handleApiError({
          message: `API error code ${result.ErrorCode}`,
          response: {
            ...response,
            data: result,
          },
          config,
        })
      : Promise.resolve(result);
  },
  (errorResponse) => handleApiError(errorResponse)
);

/**
 *
 * API ENDPOINT CONFIGURATIONS
 *
 */

export const authenticateUser = (
  username: string,
  password: string
): Promise<ApiAuthenticate> =>
  Api.post<
    ApiAuthenticate | ApiValidatedAuthenticate,
    ApiAuthenticate | ApiValidatedAuthenticate
  >(`${authApiUrl}/Authenticate`, undefined, {
    isGuestRequest: true,
    preventDefaultErrorHandling: true,
    shouldGetFullError: true,
    auth: { username, password },
  });

export const validateUser2FA = (
  OTPCode: string,
  UnvalidatedToken: ApiAuthenticate
): Promise<ApiValidatedAuthenticate> =>
  Api.post<ApiValidatedAuthenticate, ApiValidatedAuthenticate>(
    `${authApiUrl}/ValidateUser2FA`,
    { OTPCode },
    {
      isGuestRequest: true,
      preventDefaultErrorHandling: true,
      shouldGetFullError: true,
      token: UnvalidatedToken.AuthorizationToken,
    }
  );

export const getExternalLoginUrl = (
  provider: string,
  tenant?: string
): Promise<string> =>
  Api.get<string, string>(`${authApiUrl}/GetExternalLoginUrl`, {
    isGuestRequest: true,
    params: { app: getAppName(), provider, tenant },
    shouldGetFullError: true,
  });

export const registerExternalUser = (
  provider: string,
  key: string,
  tenant?: string
): Promise<ApiRegisterUser | ApiCompleteUserRegistration> =>
  Api.post(
    `${authApiUrl}/RegisterExternalUser`,
    {
      App: getAppName(),
      ExternalProvider: provider,
      ExternalProviderKey: key,
      Tenant: tenant,
    },
    {
      isGuestRequest: true,
      shouldGetFullError: true,
      preventDefaultErrorHandling: true,
    }
  );

export const completeUserRegistration = (
  {
    FirstName,
    LastName,
    PhoneNumber,
    Language,
    TermsOfServiceAccepted,
  }: ProfileFormValues,
  token: string
): Promise<ApiCompleteUserRegistration> =>
  Api.post(
    `${authApiUrl}/CompleteUserRegistration`,
    {
      App: getAppName(),
      FirstName,
      LastName,
      PhoneNumber,
      Language,
      TermsOfServiceAccepted,
    },
    {
      isGuestRequest: true,
      shouldGetFullError: true,
      preventDefaultErrorHandling: true,
      token,
    }
  );

export const authenticateExternal = (
  provider: string,
  key: string,
  tenant?: string
): Promise<ApiAuthenticate> =>
  Api.post<ApiAuthenticate, ApiAuthenticate>(
    `${authApiUrl}/AuthenticateExternal`,
    {
      App: getAppName(),
      Tenant: tenant,
      ExternalProvider: provider,
      ExternalProviderKey: key,
    },
    {
      isGuestRequest: true,
      shouldGetFullError: true,
      preventDefaultErrorHandling: true,
    }
  );

export const refreshToken = (
  token: string
): Promise<ApiValidatedAuthenticate> =>
  Api.get<ApiValidatedAuthenticate, ApiValidatedAuthenticate>(
    `${authApiUrl}/RefreshToken`,
    {
      isGuestRequest: true,
      token,
      preventDefaultErrorHandling: true,
      shouldGetFullError: true,
    }
  );

export const assertUser = (): Promise<UserProfile> =>
  Api.post<UserProfile, UserProfile>(
    "/CollaborationHub/AssertUser",
    {},
    {
      shouldGetFullError: true,
    }
  );

export const updateAuthenticationMode = (
  mode: ApiAuthenticationMode
): Promise<SetAuthenticationModeResponse> =>
  Api.post<SetAuthenticationModeResponse, SetAuthenticationModeResponse>(
    `${authApiUrl}/UpdateUserAuthenticationMode`,
    {
      AuthenticationMode: mode,
      AppName: "Collaboard",
    }
  );

export const registerUser = (
  user: RegistrationFormValues,
  SkipVerificationMessage: boolean
): Promise<ApiRegisterUser> =>
  Api.post(
    `${authApiUrl}/RegisterUser`,
    {
      VerificationUrl: `${window.location.origin}${routes.verifyEmail}`,
      SkipVerificationMessage,
      MessageTheme,
      ...user,
    },
    {
      isGuestRequest: true,
      preventDefaultErrorHandling: true,
      shouldGetFullError: true,
    }
  );

export const sendRegistrationVerificationToken = (
  User: string
): Promise<void> =>
  Api.post(
    `${authApiUrl}/SendRegistrationVerificationCode`,
    {
      VerificationUrl: `${window.location.origin}${routes.verifyEmail}`,
      User,
      MessageTheme,
    },
    { isGuestRequest: true }
  );

export const updateUserProfile = (
  data: Partial<RegistrationFormValues>,
  AuthorizationToken: string
): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/UpdateUserProfile", data, {
    token: AuthorizationToken,
    shouldGetFullError: true,
  });

export const verifyUser = (
  email: string,
  token: string
): Promise<ApiBaseResponse> =>
  Api.post(
    `${authApiUrl}/VerifyUser`,
    { User: email, VerificationToken: token },
    {
      isGuestRequest: true,
      shouldGetFullError: true,
      preventDefaultErrorHandling: true,
    }
  );

export const resendVerificationToken = ({
  User,
}: {
  User: string;
}): Promise<{ UserName: string }> =>
  Api.post(
    `${authApiUrl}/SendRegistrationVerificationCode`,
    {
      VerificationUrl: `${window.location.origin}${routes.verifyEmail}`,
      User,
      MessageTheme,
    },
    { isGuestRequest: true }
  ).then(() => ({ UserName: User }));

// If a token is passed, the server will use the user from it. Otherwise the User param must be passed.
export const sendUserOTPToken = ({
  token,
  User,
}: {
  token?: string;
  User?: string;
}): Promise<ApiBaseResponse> =>
  Api.post(
    `${authApiUrl}/SendUserOTPToken`,
    { MessageTheme, User },
    {
      isGuestRequest: true,
      token,
    }
  );

export const updateUserAndProfile = async (
  config: Partial<EditUserData>
): Promise<UserProfile> => {
  const { FirstName, LastName, PhoneNumber, Language, Photo } = config;
  await Api.post(
    `${authApiUrl}/UpdateUser`,
    {
      FirstName,
      LastName,
      PhoneNumber,
      Language,
      Photo,
    },
    {
      shouldGetFullError: true,
      preventDefaultErrorHandling: true,
    }
  );
  return Api.post("/CollaborationHub/UpdateUserProfile", config, {
    shouldGetFullError: true,
    preventDefaultErrorHandling: true,
  });
};

export type ApiUserOptions = ApiBaseResponse & {
  AuthenticationMode: ApiAuthenticationMode;
  Tenant: string | null;
};

export const getUserOptions = async (User: string): Promise<ApiUserOptions> =>
  Api.post<ApiUserOptions, ApiUserOptions>(
    `${authApiUrl}/GetUserOptions`,
    { User },
    { isGuestRequest: true }
  );

export const getUserByEmail = async (
  email: string
): Promise<PagedResponse<ApiSearchUser>> =>
  Api.post<PagedResponse<ApiSearchUser>, PagedResponse<ApiSearchUser>>(
    `${searchApiUrl}/SearchUsers`,
    {
      QueryType: "Contains",
      SearchFields: ["Email"],
      Text: email,
      PageNumber: 1,
      PageSize: 1,
    }
  ).then((response) => {
    response.Results?.forEach((result) => {
      // Ensure consistent casing
      result.UserName = (result as any).Username; // eslint-disable-line @typescript-eslint/no-explicit-any
    });

    return response;
  });

export const searchUsers = async (
  input: string
): Promise<PagedResponse<ApiSearchUser>> =>
  Api.post<PagedResponse<ApiSearchUser>, PagedResponse<ApiSearchUser>>(
    `${searchApiUrl}/SearchUsers`,
    {
      QueryType: "Contains",
      SearchFields: ["Username", "Email", "FullName"],
      Text: input,
      PageNumber: 1,
      PageSize: 20,
    }
  );

export interface SendResetPasswordEmail {
  User: string;
}

export const sendResetPasswordEmail = (
  data: SendResetPasswordEmail
): Promise<ApiBaseResponse> =>
  Api.post(
    `${authApiUrl}/SendUserPasswordResetToken`,
    { PasswordResetUrl: `${window.location}`, MessageTheme, ...data },
    { isGuestRequest: true }
  );

export const resetPassword = (data: ResetPassword): Promise<ApiBaseResponse> =>
  Api.post(`${authApiUrl}/UpdateUserPassword`, data, {
    isGuestRequest: true,
    preventDefaultErrorHandling: true,
    shouldGetFullError: true,
  });

export interface GetCustomLoginProviders {
  tenant: string;
  /** @TODO #7118 This doesn't appear to be used */
  isInTeams?: boolean;
}

export const getCustomLoginProviders = ({
  tenant,
}: GetCustomLoginProviders): Promise<AuthProvider[]> =>
  Api.post<unknown, ApiLoginProvidersResponse>(
    `${authApiUrl}/GetCustomLoginProviders`,
    {
      Tenant: tenant,
      App: getAppName(),
    },
    {
      isGuestRequest: true,
    }
  ).then(({ Items }) =>
    Items.map((i) => ({
      name: i.Provider,
      displayName: i.Name,
    }))
  );

// Project //

export const createProject = (
  description: string,
  spaceId: number | null,
  backgroundColor?: string
): Promise<ApiCreateProject> =>
  Api.post("/CollaborationHub/CreateProject", {
    Description: description,
    SpaceId: spaceId,
    BackgroundColor: backgroundColor,
  });

export const getProject = (ProjectId: string): Promise<ApiGetProject> =>
  Api.post<unknown, ApiGetProject>(
    "/CollaborationHub/GetProject",
    { ProjectId },
    {
      preventDefaultErrorHandling: [errorCodeIds.ProjectIsUpgrading],
      shouldGetFullError: true,
    }
  ).then((response) => {
    /**
     * @TODO #7396 Since the codebase expects a String projectId, we enforce it here although the
     * API actually returns a number and the projectId is indeed a number
     */
    response.Project.ProjectId = String(response.Project.ProjectId);
    // Some projects have no BackgroundColor on the server
    response.Project.BackgroundColor =
      response.Project.BackgroundColor || "#ffffff";
    return response;
  });

export interface ProjectParticipantsConfig {
  registeredOnly?: boolean;
}

export const getUsersByProject = (
  ProjectId: string,
  { registeredOnly }: ProjectParticipantsConfig
): Promise<PagedResponse<ProjectParticipant>> =>
  getAllPages((pageNumber) =>
    Api.post("/CollaborationHub/GetUsersByProject", {
      ProjectId,
      PageSize: 20,
      PageNumber: pageNumber,
      IsGuest: registeredOnly ? false : null,
      WantsCount: pageNumber === 1,
    })
  );

export const getTilesByProjectIdPaged = (
  ProjectId: string,
  PageNumber: number,
  PageSize: number
): Promise<ApiGetTilesByProjectIdPaged> =>
  Api.post(
    "/CollaborationHub/GetTilesByProjectIdPaged",
    {
      ProjectId,
      PageSize,
      PageNumber,
      WantsCount: PageNumber === 1,
    },
    {
      shouldGetFullError: true,
    }
  );

type CopiedTiles = {
  CopiedRelations: TileRelation[];
  CopiedTiles: TileStatus[];
  OriginalTileIds: string[];
  UserName: string;
  SourceProjectId: string;
  ErrorCode: number;
  OperationStartedTime: string;
  TargetProjectId: string;
  Token: string;
  TotalCopiedRelations: number;
  TotalCopiedTiles: number;
  TotalOriginalTileIds: number;
};

export const getCopiedTiles = (
  token: string,
  pageNumber: number
): Promise<CopiedTiles> =>
  Api.post("/CollaborationHub/GetCopiedTilesInfo", {
    Token: token,
    PageSize: 20,
    PageNumber: pageNumber,
    WantsCount: pageNumber === 1,
  });

export type AllCopiedTiles = {
  relations: TileRelation[];
  tiles: TileStatus[];
  originalUuids: string[];
  userName: string;
  sourceProjectId: string;
};

export const getAllPagesForCopiedTiles = async (
  token: string
): Promise<AllCopiedTiles> => {
  let isNextAvailable = true;

  const result: AllCopiedTiles = {
    relations: [],
    tiles: [],
    originalUuids: [],
    userName: "",
    sourceProjectId: "",
  };

  let totalCopiedRelations = 0;
  let totalCopiedTiles = 0;

  let page = 1;

  while (isNextAvailable) {
    const {
      CopiedRelations,
      CopiedTiles,
      OriginalTileIds,
      UserName,
      SourceProjectId,
      TotalCopiedRelations,
      TotalCopiedTiles,
    } = await getCopiedTiles(token, page);

    result.relations = result.relations.concat(CopiedRelations);
    result.tiles = result.tiles.concat(CopiedTiles);
    result.originalUuids = result.originalUuids.concat(OriginalTileIds);
    result.userName = result.userName || UserName;
    result.sourceProjectId = result.sourceProjectId || SourceProjectId;
    totalCopiedRelations = totalCopiedRelations || TotalCopiedRelations;
    totalCopiedTiles = totalCopiedTiles || TotalCopiedTiles;
    page += 1;

    isNextAvailable =
      result.tiles.length < totalCopiedTiles ||
      result.relations.length < totalCopiedRelations;
  }

  return result;
};

interface TilesInfo extends ApiBaseResponse {
  Relations: TileRelation[];
  Tiles: TileStatus[];
}

export const getTilesInfo = (
  projectId: string,
  tileIds: string[]
): Promise<TilesInfo> =>
  Api.post("/CollaborationHub/GetTilesInfo", {
    ProjectId: projectId,
    TileIds: tileIds,
  });

type Thumbnails = {
  Tiles: { Id: string; Index: number }[];
  TotalNumberOfThumbnails: number;
};

export const getThumbnailsForTile = (
  object: fabric.CollaboardImage,
  from: number,
  to: number
): Promise<Thumbnails> =>
  Api.post("/CollaborationHub/GetThumbnailsForTile", {
    TileId: object.uuid,
    Start: from,
    End: to,
  });

interface GetParticipatingProjects {
  pageNumber?: number;
  pageSize?: number;
  filterQuery?: FilterQuery[];
  orderQuery?: OrderQuery;
  permission?: number;
  spaceId: number | null;
}

export const getParticipatingProjects = ({
  pageNumber = 1,
  pageSize = 20,
  filterQuery,
  orderQuery,
  permission,
  spaceId,
}: GetParticipatingProjects): Promise<PagedResponse<ApiProject>> =>
  Api.post<PagedResponse<ApiProject>, PagedResponse<ApiProject>>(
    "/CollaborationHub/GetParticipatingProjecs", // Intentional typo
    {
      PageSize: pageSize,
      PageNumber: pageNumber,
      WantsCount: pageNumber === 1,

      SpaceId: spaceId,

      ...(orderQuery?.property
        ? {
            OrderBy: {
              PropertyName: orderQuery.property,
              Direction: orderQuery.ascending ? 1 : 2,
            },
          }
        : {}),

      ...(filterQuery?.length
        ? {
            Filters: filterQuery.map((f) => ({
              PropertyName: f.key,
              FilterType: f.type,
              Value: f.value,
            })),
          }
        : {}),

      ...(permission
        ? {
            Permission: permission,
          }
        : {}),
    }
  ).then((response) => {
    // If the user doesn't have the permission to View the project, the API will return an object with
    // null properties. We just discard them
    return {
      ...response,
      Results: response.Results
        ? response.Results.filter(
            (project) => project.Permission > ApiPermission.noPermission
          )
        : response.Results,
    };
  });

// TODO: the type ApiProject is wrong, some top-level fields are not returned by the API
export const getCoParticipatingProjects = (
  UserName: string
): Promise<PagedResponse<ApiProject>> =>
  getAllPages((pageNumber) =>
    Api.post<PagedResponse<ApiProject>, PagedResponse<ApiProject>>(
      "/CollaborationHub/GetCoParticipatingProjects",
      {
        User: UserName,
        PageSize: 20,
        PageNumber: pageNumber,
        WantsCount: pageNumber === 1,
      }
    )
  ).then((response) => {
    // If the user doesn't have the permission to View the project, the API will return an object with
    // null properties. We just discard them
    return {
      ...response,
      Results: response.Results
        ? response.Results.filter(
            (project) => project.Permission > ApiPermission.noPermission
          )
        : response.Results,
    };
  });

export const getCoParticipatingUsers = (
  pageNumber = 1
): Promise<PagedResponse<ProjectParticipant>> =>
  Api.post<
    PagedResponse<ProjectParticipant>,
    PagedResponse<ProjectParticipant>
  >("/CollaborationHub/GetCoParticipatingUsers", {
    PageSize: 20,
    PageNumber: pageNumber,
    IsGuest: false,
    WantsCount: pageNumber === 1,
  });

// Space //

export const createSpace = (
  Name: string,
  Color?: string
): Promise<ApiCreateSpace> =>
  Api.post("/CollaborationHub/CreateSpace", {
    Name,
    Color,
  });

export const updateSpace = (
  SpaceId: number,
  Name: string,
  Color: string
): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/UpdateSpace", {
    SpaceId,
    Name,
    Color,
  });

export const deleteSpace = (SpaceId: number): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/DeleteSpace", {
    SpaceId,
  });

export const getParticipatingSpaces = (): Promise<PagedResponse<Space>> =>
  getAllPages((pageNumber) =>
    Api.post<PagedResponse<Space>, PagedResponse<Space>>(
      "/CollaborationHub/GetParticipatingSpaces",
      {
        PageSize: 10,
        PageNumber: pageNumber,
        WantsCount: pageNumber === 1,
      }
    )
  );

export const getUsersBySpace = (
  SpaceId: number,
  pageNumber = 1,
  searchTerm?: string
): Promise<PagedResponse<SpaceUser>> =>
  Api.post<PagedResponse<SpaceUser>, PagedResponse<SpaceUser>>(
    "/CollaborationHub/GetUsersBySpace",
    {
      SpaceId,
      PageSize: 10,
      PageNumber: pageNumber,
      WantsCount: pageNumber === 1,

      Filters: searchTerm
        ? [
            {
              PropertyName: "Username",
              FilterType: "StartsWith",
              Value: searchTerm,
            },
          ]
        : undefined,
    }
  );

interface InviteSpaceParticipant {
  SpaceId: number;
  Users: string[];
  ProjectPermission: ApiPermission;
  SpacePermission: ApiPermission;
  InvitationUrl: string;
  Notes: string;
}

export const inviteSpaceParticipant = ({
  SpaceId,
  Users,
  ProjectPermission,
  SpacePermission,
  InvitationUrl,
  Notes,
}: InviteSpaceParticipant): Promise<ApiBaseResponse> => {
  return Api.post(
    "/CollaborationHub/InviteSpaceParticipant",
    {
      SpaceId,
      Users,
      ProjectPermission,
      SpacePermission,
      Notes,
      InvitationUrl,
      MessageTheme,
    },
    {
      preventDefaultErrorHandling: true,
      shouldGetFullError: true,
    }
  );
};

interface UpdateSpaceParticipant {
  SpaceId: number;
  User: string;
  ProjectPermission: ApiPermission;
  SpacePermission: ApiPermission;
  IsFavorite: boolean;
}

export const updateSpaceParticipant = ({
  SpaceId,
  User,
  ProjectPermission,
  SpacePermission,
  IsFavorite,
}: UpdateSpaceParticipant): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/UpdateSpaceParticipant", {
    SpaceId,
    User,
    ProjectPermission,
    SpacePermission,
    IsFavorite,
  });

export const updateProjectSpace = (
  ProjectId: string,
  SpaceId: number | null
): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/UpdateProjectSpace", {
    ProjectId,
    SpaceId,
  });

export const addProjectTag = (
  project: Project,
  tag: string
): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/AddProjectTag", {
    ProjectId: project.ProjectId,
    Tag: tag,
  });

export const removeProjectTag = (
  project: Project,
  tag: string
): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/DeleteProjectTag", {
    ProjectId: project.ProjectId,
    Tag: tag,
  });

export const getSubscription = (): Promise<ApiManageableSubscription | null> =>
  Api.post<
    PagedResponse<ApiManageableSubscription>,
    PagedResponse<ApiManageableSubscription>
  >(`${licensingApiUrl}/GetManageableSubscriptions`, {
    ProductFamilyCode: "COLLABOARD",
    PageSize: 1,
    PageNumber: 1,
    WantsCount: false,
  }).then(({ Results }) => Results?.[0] ?? null);

export const getLicenseInfo = (): Promise<ApiBaseResponse> =>
  Api.post(
    "/CollaborationHub/GetLicenseInfo",
    {
      ProductFamilyCode: "COLLABOARD",
    },
    { preventDefaultErrorHandling: true }
  );

export const getUserPermissions = (): Promise<ApiUserPermissions> => {
  return Api.get<unknown, ApiUserPermissions>(
    `${authApiUrl}/GetUserPermissions`,
    {
      shouldGetFullError: true,
    }
  ).then((response) => {
    response.RequiredPermissions.forEach((permission) => {
      // Enforce the Scope to be null if not defined, as it can be an empty string, which can lead to subtle bugs
      permission.Scope = permission.Scope || null;
    });

    return response;
  });
};

export const getUISettings = (tenant?: string): Promise<ApiUISettings> => {
  return Api.post(
    "/CollaborationHub/GetUISettings",
    {
      tenant,
    },
    {
      // This endpoint can be used without authentication when requesting styles for a tenant
      isGuestRequest: !!tenant,
      preventDefaultErrorHandling: true,
      shouldGetFullError: true,
    }
  );
};

export const getProducts = (): Promise<PagedResponse<ApiProduct>> =>
  Api.post(`${licensingApiUrl}/GetProducts`, {
    ProductFamilyCode: "COLLABOARD",
  });

interface GetPrice {
  productId: number;
  billingType: number;
  currency: string;
  numberOfUsers: number;
  numberOfDevices: number;
  promoCode: string;
}

export const getPrice = ({
  productId,
  billingType,
  currency,
  numberOfUsers = 1,
  numberOfDevices = 0,
  promoCode,
}: GetPrice): Promise<ApiBaseResponse> =>
  Api.post(
    `${licensingApiUrl}/GetPrice`,
    {
      ProductId: productId,
      BillingType: billingType,
      Currency: currency,
      NumberOfUsers: numberOfUsers,
      NumberOfDevices: numberOfDevices,
      PromoCode: promoCode,
    },
    { shouldGetFullError: true, preventDefaultErrorHandling: true }
  );

interface CreateSubscription {
  productId: number;
  billingType: number;
}

export const createSubscription = ({
  productId,
  billingType,
}: CreateSubscription): Promise<ApiSubscription> =>
  Api.post(`${licensingApiUrl}/CreateSubscription`, {
    ProductId: productId,
    BillingType: billingType,
    IncludeUserAsMember: true,
  });

interface CreateOrder {
  subscriptionId: number;
  currency: string;
  numberOfUsers: number;
  numberOfDevices: number;
  promoCode: string;
  successUrl: string;
  cancelUrl: string;
}

export const createOrder = ({
  subscriptionId,
  currency,
  numberOfUsers = 1,
  numberOfDevices = 0,
  promoCode,
  successUrl,
  cancelUrl,
}: CreateOrder): Promise<ApiCreateOrder> =>
  Api.post(`${licensingApiUrl}/CreateOrder`, {
    SubscriptionID: subscriptionId,
    PaymentMethod: 1, // 1: Stripe, 2: bankDeposit
    Currency: currency,
    NumberOfUsers: numberOfUsers,
    NumberOfDevices: numberOfDevices,
    PromoCode: promoCode,
    SuccessUrl: successUrl,
    CancelUrl: cancelUrl,
  });

interface CreateSubscriptionFromVoucher {
  PromoCode: string;
}

export const createSubscriptionFromVoucher = ({
  PromoCode,
}: CreateSubscriptionFromVoucher): Promise<ApiBaseResponse> =>
  Api.post(
    `${licensingApiUrl}/CreateSubscriptionFromVoucher`,
    { PromoCode, IncludeUserAsMember: true },
    { shouldGetFullError: true }
  );

interface UpgradeSubscriptionFromVoucher {
  PromoCode: string;
  SubscriptionId: number | null;
}

export const upgradeSubscriptionFromVoucher = ({
  PromoCode,
  SubscriptionId,
}: UpgradeSubscriptionFromVoucher): Promise<ApiBaseResponse> =>
  Api.post(
    `${licensingApiUrl}/UpgradeSubscriptionFromVoucher`,
    { PromoCode, SubscriptionId },
    { shouldGetFullError: true }
  );

interface GetPromoCode {
  PromoCode: string;
}

export const getPromoCode = ({
  PromoCode,
}: GetPromoCode): Promise<ApiBaseResponse> =>
  Api.post(`${licensingApiUrl}/GetPromoCode`, { PromoCode });

interface CompleteOrder {
  OrderID: string;
  PaymentReference: string;
}

export const completeOrder = ({
  OrderID,
  PaymentReference,
}: CompleteOrder): Promise<ApiBaseResponse> => {
  return Api.post(`${licensingApiUrl}/CompleteOrder`, {
    OrderID,
    PaymentReference,
    MessageTheme,
  });
};

export const getOrders = (SubscriptionID: number): Promise<ApiBaseResponse> =>
  Api.post(`${licensingApiUrl}/GetOrders`, {
    PageNumber: 1,
    PageSize: 10,
    SubscriptionID,
    WantsCount: false,
  });

export const cancelOrder = (OrderID: string): Promise<ApiBaseResponse> =>
  Api.post(`${licensingApiUrl}/CancelOrder`, { OrderID });

export const reactivateOrder = (OrderID: string): Promise<ApiBaseResponse> =>
  Api.post(`${licensingApiUrl}/ReactivateOrder`, { OrderID });

interface GetSubscriptionUsers {
  subscriptionID: number;
  pageNumber?: number;
  pageSize?: number;
  status: number;
  searchTerm?: string;
}

export const getSubscriptionUsers = ({
  subscriptionID,
  pageNumber = 1,
  status,
  searchTerm,
}: GetSubscriptionUsers): Promise<PagedResponse<ApiSubscriptionUser>> =>
  Api.post(`${licensingApiUrl}/GetSubscriptionUsers`, {
    PageSize: 20,
    PageNumber: pageNumber,
    WantsCount: pageNumber === 1,
    SubscriptionID: subscriptionID,
    IncludeUserProperties: true,
    Status: status,
    Filters: searchTerm
      ? [
          {
            PropertyName: "Username",
            FilterType: "StartsWith",
            Value: searchTerm,
          },
        ]
      : undefined,
  });

interface GetSubscriptionDevices {
  subscriptionID: number;
  pageNumber: number;
  isActive: boolean;
}

export const getSubscriptionDevices = ({
  subscriptionID,
  pageNumber = 1,
  isActive,
}: GetSubscriptionDevices): Promise<PagedResponse<ApiSubscriptionDevice>> =>
  Api.post(`${licensingApiUrl}/GetSubscriptionDevices`, {
    PageSize: 20,
    PageNumber: pageNumber,
    WantsCount: pageNumber === 1,
    SubscriptionID: subscriptionID,
    IsActive: isActive,
  });

interface AddSubscriptionDevice {
  subscriptionID: number;
  code: string;
  name: string;
  active: boolean;
}

export const addSubscriptionDevice = ({
  subscriptionID,
  code,
  name,
  active = true,
}: AddSubscriptionDevice): Promise<ApiBaseResponse> =>
  Api.post(`${licensingApiUrl}/AddSubscriptionDevice`, {
    DeviceCode: code,
    DeviceName: name,
    SubscriptionID: subscriptionID,
    IsActive: active,
  });

interface RemoveSubscriptionUser {
  subscriptionID: number;
  Username: string;
}

export const removeSubscriptionUser = (
  { subscriptionID, Username }: RemoveSubscriptionUser,
  config?: AxiosRequestConfig
): Promise<ApiBaseResponse> =>
  Api.post(
    `${licensingApiUrl}/DeleteSubscriptionUser`,
    {
      Username,
      SubscriptionID: subscriptionID,
    },
    config
  );

interface RemoveSubscriptionDevice {
  subscriptionID: number;
  DeviceCode: string;
}

export const removeSubscriptionDevice = ({
  subscriptionID,
  DeviceCode,
}: RemoveSubscriptionDevice): Promise<ApiBaseResponse> =>
  Api.post(`${licensingApiUrl}/DeleteSubscriptionDevice`, {
    DeviceCode,
    SubscriptionID: subscriptionID,
  });

export interface UpdateSubscriptionUser {
  user: ApiSubscriptionUser;
  permission: ApiSubscriptionUserPermission;
}

export const updateSubscriptionUser = ({
  user,
  permission,
}: UpdateSubscriptionUser): Promise<ApiBaseResponse> =>
  Api.post(`${licensingApiUrl}/UpdateSubscriptionUser`, {
    Username: user.Username,
    SubscriptionID: user.SubscriptionId,
    Status: user.Status,
    Permission: permission,
  });

interface ImportSubscriptionUsers {
  subscriptionID: number;
  users: ApiUsersImport[];
  autoLoginFromInvite?: boolean;
  tenant?: string;
}

export const importSubscriptionUsers = ({
  subscriptionID,
  users,
  autoLoginFromInvite,
  tenant,
}: ImportSubscriptionUsers): Promise<ImportApiResponse> => {
  const urlQuery = stringifyQueryUrl<TeamInvitationUrlQuery>({
    tenant,
    autoLoginFromInvite,
  });
  const acceptUrl = `${window.origin}${routes.acceptTeamInvitation}${urlQuery}`;
  const rejectUrl = `${window.origin}${routes.rejectTeamInvitation}${urlQuery}`;

  return Api.post(
    `${licensingApiUrl}/ImportSubscriptionUsers`,
    {
      SubscriptionID: subscriptionID,
      InvitationAcceptUrl: acceptUrl,
      InvitationRejectUrl: rejectUrl,
      Users: users,
    },
    {
      preventDefaultErrorHandling: [errorCodeIds.MaxNumberOfUsersExceeded],
      shouldGetFullError: true,
    }
  );
};

export const getInvitedSubscriptionUser = (
  token: string,
  loggedUser?: string
): Promise<UserProfile> =>
  Api.post(
    "/CollaborationHub/GetInvitedSubscriptionUser",
    {
      Token: token,
      UserName: loggedUser,
    },
    {
      isGuestRequest: true,
      shouldGetFullError: true,
      preventDefaultErrorHandling: true,
    }
  );

export const acceptSubscriptionUserInvitation = (
  inviteToken: string,
  authToken: string | null = null
): Promise<ApiBaseResponse> =>
  Api.post(
    "CollaborationHub/AcceptSubscriptionUserInvitation",
    {
      Token: inviteToken,
    },
    {
      shouldGetFullError: true,
      token: authToken ?? undefined,
      preventDefaultErrorHandling: true,
    }
  );

export const rejectSubscriptionUserInvitation = (
  token: string
): Promise<ApiBaseResponse> =>
  Api.post(
    "/CollaborationHub/RejectSubscriptionUserInvitation",
    {
      Token: token,
    },
    {
      isGuestRequest: true,
      shouldGetFullError: true,
      preventDefaultErrorHandling: true,
    }
  );

export const updateProjectLastAccess = (
  ProjectId: string
): Promise<ApiBaseResponse> =>
  Api.post(
    "/CollaborationHub/UpdateProjectLastAccess",
    {
      ProjectId,
    },
    { preventDefaultErrorHandling: true }
  );

export const updateProject = (project: Project): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/UpdateProject", {
    CurrentProject: project,
    NotifyToParticipants: true,
  });

export const duplicateProject = (
  ProjectId: string,
  ClientConnectionId: string | null
): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/CopyProject", {
    ProjectId,
    ClientConnectionId,
  });

export const deleteProject = (ProjectId: string): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/DeleteProject", { ProjectId });

export interface ChangeProjectOwner {
  projectId: string;
  newOwner: string;
}

export const changeProjectOwner = ({
  projectId,
  newOwner,
}: ChangeProjectOwner): Promise<void> =>
  Api.post("/CollaborationHub/ChangeProjectOwner", {
    ProjectId: projectId,
    NewOwner: newOwner,
  });

interface InviteProjectParticipant {
  ProjectId: string;
  Users: unknown;
  MemberPermission: number;
  Notes: string;
  tenant?: string;
}

export const inviteProjectParticipant = ({
  ProjectId,
  Users,
  MemberPermission,
  Notes,
  tenant,
}: InviteProjectParticipant): Promise<ApiBaseResponse> => {
  const urlQuery = stringifyQueryUrl<ProjectInvitationUrlQuery>({
    tenant: tenant ? encodeURIComponent(tenant) : undefined,
  });

  return Api.post("/CollaborationHub/InviteProjectParticipant", {
    ProjectId,
    Users,
    MemberPermission,
    Notes,
    MessageTheme,
    GuestPermission: projectPermissions.noPermission,
    ValidForMinutes: 60 * 24 * 7, // 1 week
    InvitationUrl: `${window.origin}${routes.acceptProjectInvitation}${urlQuery}`,
  });
};

interface CreateProjectInvitationLink {
  GuestIdentificationRequired?: boolean;
  GuestPermission: number;
  MemberPermission: number;
  Password?: string;
  ProjectId: string;
  ValidForMinutes?: number;
  tenant?: string;
}

export const createProjectInvitationLink = ({
  ProjectId,
  MemberPermission,
  GuestPermission,
  ValidForMinutes,
  Password,
  GuestIdentificationRequired,
  tenant,
}: CreateProjectInvitationLink): Promise<ApiCreateProjectInvitationLink> => {
  const tenantQs = tenant ? `?tenant=${encodeURIComponent(tenant)}` : "";
  return Api.post("/CollaborationHub/CreateProjectInvitationLink", {
    ProjectId,
    MemberPermission,
    GuestPermission,
    ValidForMinutes,
    Password,
    GuestIdentificationRequired,
    InvitationUrl: `${window.origin}${routes.acceptProjectInvitation}${tenantQs}`,
  });
};

export const getProjectInvitationDetails = (
  Token: string
): Promise<ApiProjectInvitation> =>
  Api.post(
    "/CollaborationHub/GetProjectInvitationDetails",
    { Token },
    {
      isGuestRequest: true,
      preventDefaultErrorHandling: true,
      shouldGetFullError: true,
    }
  );

export interface AcceptProjectInvitation {
  Token: string;
  Password: string | null;
}

export const acceptProjectInvitation = ({
  Token,
  Password,
}: AcceptProjectInvitation): Promise<ApiAcceptProjectInvitation> =>
  Api.post(
    "/CollaborationHub/AcceptProjectInvitation",
    {
      Token,
      // #7058 - Server requires null rather than "" when there is no password
      Password: Password || null,
    },
    {
      shouldGetFullError: true,
      preventDefaultErrorHandling: true,
    }
  );

interface AcceptProjectInvitationAsGuest {
  Token: string;
  Password: string | null;
  Name: string;
  Email: string | null;
  ReceiveNews: boolean;
}

export const acceptProjectInvitationAsGuest = ({
  Token,
  Password,
  Name,
  Email,
  ReceiveNews,
}: AcceptProjectInvitationAsGuest): Promise<ApiAcceptProjectInvitationAsGuest> =>
  Api.post(
    "/CollaborationHub/AcceptProjectInvitationAsGuest",
    {
      Token,
      // #7058 - Server requires null rather than "" when there is no password
      Password: Password || null,
      Name,
      Email,
      ReceiveNews,
    },
    {
      isGuestRequest: true,
      shouldGetFullError: true,
      preventDefaultErrorHandling: true,
    }
  );

export interface ChangeUserPermission {
  projectId: string;
  user: ProjectUserIdentity;
  permission: ApiPermission;
}

export interface UserPermissionChange {
  user: ProjectUserIdentity;
  permission: ApiPermission;
}

export const changeUserPermission = ({
  projectId,
  user,
  permission,
}: ChangeUserPermission): Promise<UserPermissionChange> =>
  Api.post("/CollaborationHub/UpdateProjectParticipant", {
    ProjectId: projectId,
    User: user.UserName,
    Permission: permission,
  }).then(() => ({ user, permission }));

export const leaveProject = (
  projectId: string,
  user: ProjectUserIdentity
): Promise<UserPermissionChange> =>
  changeUserPermission({
    projectId,
    user,
    permission: projectPermissions.noPermission,
  });

export const getOnlineUsersByProject = (
  ProjectId: string
): Promise<{ Users: ApiOnlineUser[] }> =>
  Api.post<{ Users: ApiOnlineUser[] }, { Users: ApiOnlineUser[] }>(
    "/CollaborationHub/GetAuthOnlineUsersByProject",
    { ProjectId }
  ).then((response) => {
    const { Users } = response;

    // Remove duplicate entries for guests, which can happen when a guest reloads the page. The server
    // may still believe that the old connection is still active, while a new one is established.
    // Normal users can have multiple active connections in case of multiple tabs, so we can't exclude them.
    const uniqueUsers = Users.reduce((map, user) => {
      const existingEntry = map.get(user.UserName);
      if (!existingEntry || !user.IsGuest) {
        map.set(user.UserName, user);
        return map;
      }

      const entryLastUpdate = utcDate(existingEntry.LastUpdate);
      const userLastUpdate = utcDate(user.LastUpdate);

      if (userLastUpdate.getTime() > entryLastUpdate.getTime()) {
        map.set(user.UserName, user);
      }

      return map;
    }, new Map<string, ApiOnlineUser>());

    return {
      ...response,
      Users: Array.from(uniqueUsers.values()),
    };
  });

export const updateProjectThumbnail = (
  projectId: string,
  projectThumbnail: unknown
): Promise<ApiBaseResponse> =>
  Api.post(
    "/CollaborationHub/UpdateProjectThumbnail",
    {
      ProjectId: projectId,
      ProjectThumbnail: projectThumbnail,
    },
    { preventDefaultErrorHandling: true }
  );

/**
 * inks which are longer then signalR limit will use this method to send data
 */
export const postNewBigInk = (
  ProjectId: string,
  tile?: TileStatus
): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/PostNewBigInk", {
    ProjectId,
    Tile: {
      ProjectId,
      ...tile,
    },
  });

/**
 * for inks which are larger then signalR limit app is notified with 'NotifyNewBigInk'
 * and request for ink body with requestBigInk method
 */
export const requestBigInk = (
  ProjectId: string,
  TileId: string
): Promise<{ Tile: InkTileStatus }> =>
  Api.post("/CollaborationHub/RequestBigInk", {
    ProjectId,
    TileId,
  });

export const copyTiles = (
  tiles: DuplicateTile[],
  destinationProjectId: string,
  sourceProjectId = destinationProjectId
): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/CopyTiles", {
    Tiles: tiles,
    SourceProject: sourceProjectId,
    DestinationProject: destinationProjectId,
  });

export const getTemplateCategories = (): Promise<
  PagedResponse<TemplateCategory>
> =>
  getAllPages((pageNumber) =>
    Api.post("/CollaborationHub/GetTemplateCategories", {
      LanguageId: 1, // todo: change to a variable when languages are supported
      PageNumber: pageNumber,
      PageSize: 20,
      WantsCount: pageNumber === 1,
    })
  );

export const getTemplateProjects = (): Promise<PagedResponse<ApiTemplate>> =>
  getAllPages((pageNumber) =>
    Api.post("/CollaborationHub/GetTemplateProjects", {
      LanguageId: 1, // todo: change to a variable when languages are supported
      PageSize: 20,
      PageNumber: pageNumber,
      WantsCount: pageNumber === 1,
    })
  );

interface AddTemplateToProject {
  ProjectId: string;
  TemplateId: string;
  XOffSet: number;
  YOffSet: number;
  UserName: string | undefined;
  ConnectionId: string;
}

export const addTemplateToProject = ({
  ProjectId,
  TemplateId,
  XOffSet,
  YOffSet,
  UserName,
  ConnectionId,
}: AddTemplateToProject): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/ApplyProjectTemplate", {
    ProjectId,
    TemplateId,
    XOffSet,
    YOffSet,
    UserName,
    ClientConnectionId: ConnectionId,
  });

export const sendStartPresenting = (
  ProjectId: string
): Promise<ApiBaseResponse> =>
  Api.post(
    "/CollaborationHub/StartPresenting",
    { ProjectId },
    {
      preventDefaultErrorHandling: true,
    }
  );

export const sendStopPresenting = (
  ProjectId: string
): Promise<ApiBaseResponse> =>
  Api.post(
    "/CollaborationHub/StopPresenting",
    { ProjectId },
    {
      preventDefaultErrorHandling: true,
    }
  );

export const sendStartTimedSession = (
  projectId: string,
  InitialDuration: number
): Promise<ApiBaseResponse> =>
  Api.post(
    "/CollaborationHub/StartPresentationTimer",
    {
      ProjectId: projectId,
      InitialDuration,
    },
    {
      preventDefaultErrorHandling: true,
    }
  );

export const sendStopTimedSession = (
  ProjectId: string
): Promise<ApiBaseResponse> =>
  Api.post(
    "/CollaborationHub/StopPresentationTimer",
    { ProjectId },
    {
      preventDefaultErrorHandling: true,
    }
  );

export interface SendTogglePauseTimedSession {
  projectId: string;
  isPaused: boolean;
}

export const sendTogglePauseTimedSession = ({
  projectId,
  isPaused,
}: SendTogglePauseTimedSession): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/PausePresentationTimer", {
    ProjectId: projectId,
    IsPaused: isPaused,
  });

export const sendExtendTimedSession = (
  projectId: string,
  MinutesToAdd: number
): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/AdjustPresentationTimer", {
    ProjectId: projectId,
    MinutesToAdd,
  });

export type StartVotingSessionConfig = {
  Users: string[];
  Description: string;
  Type: VotingSessionType;
  DurationInMinutes: number;
  VotesLimit: number;
  AllMembersForSelectionToggle: boolean;
};

export const sendStartVotingSession = (
  projectId: string,
  config: StartVotingSessionConfig
): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/InitiateVoting", {
    ProjectId: projectId,
    Description: config.Description,
    DurationInSeconds: config.DurationInMinutes * 60,
    VotingType: config.Type,
    NumberOfVotesPerUser: config.VotesLimit,
    InvitedUsers: config.Users,
    InviteAllUsers: config.AllMembersForSelectionToggle,
  });

export const sendStopVotingSession = (
  projectId: string,
  sessionId: number
): Promise<ApiBaseResponse> =>
  Api.post(
    "/CollaborationHub/StopVoting",
    {
      ProjectId: projectId,
      SessionId: sessionId,
    },
    {
      shouldGetFullError: true,
    }
  );

export const sendExtendVotingSession = (
  projectId: string,
  sessionId: number,
  minutesToAdd: number
): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/AdjustVotingTimer", {
    ProjectId: projectId,
    SessionId: sessionId,
    SecondsToAdd: minutesToAdd * 60,
  });

export const sendFinishVotingSession = (
  projectId: string,
  sessionId: number
): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/FinalizeVotingSession", {
    ProjectId: projectId,
    SessionId: sessionId,
  });

export const sendVoteCast = (
  projectId: string,
  sessionId: number,
  uuid: string,
  vote: number
): Promise<ApiBaseResponse> =>
  Api.post(
    "/CollaborationHub/CastVote",
    {
      ProjectId: projectId,
      SessionId: sessionId,
      TileId: uuid,
      VotesCast: vote,
    },
    {
      shouldGetFullError: true,
    }
  );

export const sendVoteReset = (
  projectId: string,
  sessionId: number,
  uuid: string
): Promise<ApiBaseResponse> =>
  Api.post(
    "/CollaborationHub/RevokeVote",
    {
      ProjectId: projectId,
      SessionId: sessionId,
      TileId: uuid,
    },
    {
      shouldGetFullError: true,
    }
  );

export const getMyVotes = (projectId: string): Promise<ApiGetMyVotes> =>
  getAllPages((pageNumber) =>
    Api.post<ApiGetMyVotes, ApiGetMyVotes>(
      "/CollaborationHub/GetMyVotes",
      {
        ProjectId: projectId,
        PageSize: 20,
        PageNumber: pageNumber,
        WantsCount: pageNumber === 1,
      },
      {
        shouldGetFullError: true,
        preventDefaultErrorHandling: [errorCodeIds.NoActiveVotingSession],
      }
    )
  );

export const getVotesPerUser = (
  projectId: string,
  sessionId: number,
  pageNumber: number
): Promise<PagedResponse<VotesPerUser>> =>
  Api.post<PagedResponse<VotesPerUser>, PagedResponse<VotesPerUser>>(
    "/CollaborationHub/GetUserResultsForVotingSession",
    {
      ProjectId: projectId,
      SessionId: sessionId,
      PageSize: 20,
      PageNumber: pageNumber,
      WantsCount: pageNumber === 1,
    }
  );

export const getVotingArchive = (
  projectId: string,
  pageNumber: number
): Promise<PagedResponse<ApiVotingOverview>> =>
  Api.post("/CollaborationHub/GetAllVotingSessions", {
    ProjectId: projectId,
    PageSize: 20,
    PageNumber: pageNumber,
    WantsCount: pageNumber === 1,
  });

export const deleteVotingSession = (
  projectId: string,
  sessionId: number
): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/DeleteVotingSession", {
    ProjectId: projectId,
    SessionId: sessionId,
  });

export const getVotingSessionDetails = (
  projectId: string,
  sessionId: number,
  pageNumber: number
): Promise<ApiGetVotingSessionSummary> =>
  Api.post("/CollaborationHub/GetVotingSessionSummary", {
    ProjectId: projectId,
    SessionId: sessionId,
    PageSize: 20,
    PageNumber: pageNumber,
    WantsCount: pageNumber === 1,
  });

export const notifyNudgeForAttention = (
  projectId: string,
  zoomLevel: number,
  screenCoordinates: ScreenCoordinates,
  connectionIds: string[]
): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/SendAttentionNudge", {
    ProjectId: projectId,
    ConnectionIds: connectionIds,
    ScreenCoordinates: screenCoordinates,
    ZoomLevel: zoomLevel,
  });

export const sendControlMedia = (
  projectId: string,
  tileId: string | undefined,
  mediaAction: MediaAction,
  currentTime?: number
): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/StartStopVideoPlayers", {
    ProjectId: projectId,
    TileIdVideoContent: tileId,
    VideoPlayerCommand: mediaAction,
    TimeStamp: currentTime,
  });

export const addQuickLink = (
  quickLinkDetail: QuickLinkDetail
): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/CreateQuickLink", quickLinkDetail);

export const removeQuickLink = (
  quickLink: QuickLink
): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/DeleteQuickLink", {
    ProjectId: quickLink.ProjectId,
    QuickLinkId: quickLink.Id,
  });

export const updateQuickLinkSequence = (
  quickLink: QuickLink,
  newSequenceNumber: number
): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/UpdateQuickLinkSequence", {
    ProjectId: quickLink.ProjectId,
    Id: quickLink.Id,
    NewSequenceNumber: newSequenceNumber,
  });

export const getQuickLinks = (
  projectId: string,
  page: number,
  searchTerm: string // TODO: search - no API support yet
): Promise<PagedResponse<QuickLink>> =>
  Api.post("/CollaborationHub/GetQuickLinks", {
    ProjectId: projectId,
    WantsCount: page === 1,
    PageSize: 20,
    PageNumber: page,
  });

export const getQuickLink = (
  projectId: string,
  quickLinkIndex: number
): Promise<QuickLink | undefined> =>
  Api.post<PagedResponse<QuickLink>, PagedResponse<QuickLink>>(
    "/CollaborationHub/GetQuickLinks",
    {
      ProjectId: projectId,
      WantsCount: false,
      PageSize: 1,
      PageNumber: quickLinkIndex,
    }
  ).then(({ Results }) => Results?.[0]);

export const getQuickLinkById = (
  quickLink: QuickLinkWithNoDetail,
  preventDefaultErrorHandling?: boolean
): Promise<ApiGetQuickLink> =>
  Api.post<ApiGetQuickLink, ApiGetQuickLink>(
    "/CollaborationHub/GetQuickLinkById",
    {
      ProjectId: quickLink.ProjectId,
      QuickLinkId: quickLink.Id,
    },
    {
      preventDefaultErrorHandling,
    }
  );

export const createObjectLink = (
  projectId: string,
  params: ApiObjectLink
): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/CreateLink", {
    ProjectId: projectId,
    ...params,
  });

export const deleteObjectLink = (
  projectId: string,
  uuid: string
): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/DeleteLinkForSource", {
    ProjectId: projectId,
    SourceTileId: uuid,
  });

export const getObjectLinks = (
  projectId: string
): Promise<PagedResponse<ApiObjectLink>> =>
  getAllPages((pageNumber) =>
    Api.post("/CollaborationHub/GetLinksForProject", {
      ProjectId: projectId,
      PageSize: 50,
      PageNumber: pageNumber,
      WantSCount: pageNumber === 1,
    })
  );

export const getCommentsByTile = (
  projectId: string,
  tileId: string
): Promise<PagedResponse<ApiComment>> =>
  getAllPages((pageNumber) =>
    Api.post("/CollaborationHub/GetCommentsByTile", {
      ProjectId: projectId,
      TileId: tileId,
      PageSize: 20,
      PageNumber: pageNumber,
      WantsCount: pageNumber === 1,
    })
  );

type AddCommentToTile = {
  projectId: string;
  tileId: string;
  text: string;
  mentionedUsers: string[];
  commentLinkUrl: string;
};

export const addCommentToTile = ({
  projectId,
  tileId,
  text,
  mentionedUsers,
  commentLinkUrl,
}: AddCommentToTile): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/AddComment", {
    ProjectId: projectId,
    TileId: tileId,
    Text: text,
    MentionedUsers: mentionedUsers,
    NotificationUrl: mentionedUsers.length ? commentLinkUrl : undefined,
  });

export const deleteComment = (commentId: number): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/DeleteComment", {
    CommentId: commentId,
  });

export const addCommentLike = (commentId: number): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/AddCommentLike", {
    CommentId: commentId,
  });

export const deleteCommentLike = (
  commentId: number
): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/DeleteCommentLike", {
    CommentId: commentId,
  });

export const getLastCommentView = (
  projectId: string
): Promise<PagedResponse<ApiCommentView>> =>
  getAllPages((pageNumber) =>
    Api.post("/CollaborationHub/GetLastCommentView", {
      ProjectId: projectId,
      PageSize: 20,
      PageNumber: pageNumber,
      WantsCount: pageNumber === 1,
    })
  );

export const updateLastCommentView = (
  commentId: number
): Promise<ApiBaseResponse> =>
  Api.post("/CollaborationHub/UpdateLastCommentView", {
    CommentId: commentId,
  });

export const getStorageAccessSignature = (
  projectId: string | number
): Promise<ApiGetStorageAccessSignature> =>
  Api.post<{ ProjectId: string | number }, ApiGetStorageAccessSignature>(
    "/CollaborationHub/GetStorageAccessSignature",
    {
      ProjectId: projectId,
    }
  );

export const generateZoomSignature = async (
  meetingNumber = 0
): Promise<ApiGetGenerateZoomSignature> =>
  Api.post<ApiGetGenerateZoomSignature, ApiGetGenerateZoomSignature>(
    "/CollaborationHub/GenerateZoomSignature",
    {
      MeetingNumber: meetingNumber,
      MeetingRole: 0,
    }
  );

export const generateWebexGuestToken = async (): Promise<ApiGetGenerateWebexGuestToken> =>
  Promise.resolve({
    guestToken:
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJZMmx6WTI5emNHRnlhem92TDNWeWJqcFVSVUZOT25WekxYZGxjM1F0TWw5eUwwOVNSMEZPU1ZwQlZFbFBUaTloTW1FMlpXRmpNeTA0WXpBNExUUTRNakV0WW1NM05TMDVZV1kzT0RjM09UUmxPVFEiLCJleHAiOjE2NTg1NzA1NDQsInN1YiI6Imd1ZXN0LXVzZXItNzM0OSIsIm5hbWUiOiJHdWVzdCA3MzQ5In0.t8jjW-TmgoOI3vK1-NVjTLd1a-WYDoG4EX1cJcok6bc", // TODO: should come from the server
  });
