type WrapFunction = (text: string) => string;

type WrapMode = "soft" | "hard";

interface WrapOptions {
  mode?: WrapMode | undefined;
}

// github.com/substack/node-wordwrap/blob/master/index.js
export const wordwrap = (
  start: number,
  stop: number,
  params: WrapOptions = {}
): WrapFunction => {
  const mode = params.mode || "soft";
  const re = mode === "hard" ? /\b/ : /(\S+\s+)/;

  return function (text) {
    const chunks = text
      .toString()
      .split(re)
      .reduce<string[]>((acc, x) => {
        if (mode === "hard") {
          for (let i = 0; i < x.length; i += stop - start) {
            acc.push(x.slice(i, i + stop - start));
          }
        } else {
          acc.push(x);
        }
        return acc;
      }, []);

    return chunks
      .reduce(
        (lines, rawChunk) => {
          if (rawChunk === "") {
            return lines;
          }

          const chunk = rawChunk.replace(/\t/g, "    ");

          const i = lines.length - 1;
          if (lines[i].length + chunk.length > stop) {
            lines[i] = lines[i].replace(/\s+$/, "");

            chunk
              .split(/\n/)
              .forEach((c) =>
                lines.push(
                  new Array(start + 1).join(" ") + c.replace(/^\s+/, "")
                )
              );
          } else if (chunk.match(/\n/)) {
            const xs = chunk.split(/\n/);
            lines[i] += xs.shift();
            xs.forEach((c) =>
              lines.push(new Array(start + 1).join(" ") + c.replace(/^\s+/, ""))
            );
          } else {
            lines[i] += chunk;
          }

          return lines;
        },
        [new Array(start + 1).join(" ")]
      )
      .join("\n");
  };
};

export const isString = (variable: unknown): variable is string => {
  return typeof variable === "string" || variable instanceof String;
};
