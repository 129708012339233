import {
  all,
  call,
  cancelled,
  getContext,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import { findUnknownUserInfo } from "../../../tools/avatarGenerator";
import { userPresenceStorage } from "../../../tools/localStorageStores";
import { splitBy } from "../../../tools/utils";
import { WebRTCEvent, WebRTCManager } from "../../../webrtc";
import { selectIsViewingPresentation } from "../presentation/presentation.reducer";
import { selectProjectUsers } from "../project/project.reducer";
import {
  AddUserPresenceInfoAction,
  RemoveUserPresenceUsernameAction,
  sendUserPresenceRequestsAction,
  SendUserPresenceRequestsAction,
  SetUserPresenceOptionsAction,
  setUserPresenceOptionsAction,
  SetUserPresenceOptionsPayload,
  UserPresenceActionType,
  UserPresenceMouseMovedAction,
} from "./userPresence.actions";
import {
  isAutoEnabledForAll,
  selectIsUserPresenceActive,
  selectPresencesInfo,
  selectUserPresenceAutoEnable,
} from "./userPresence.reducer";

function* loadUserPresence() {
  const options: SetUserPresenceOptionsPayload | undefined = yield call({
    context: userPresenceStorage,
    fn: userPresenceStorage.get,
  });

  // We can't be sure that LocalStorage contents are in the shape
  // we expect. So play it safe here.
  if (options && options.mode) {
    yield put(setUserPresenceOptionsAction(options));
  }
}

function* setUserPresenceOptions({ payload }: SetUserPresenceOptionsAction) {
  const userPresencesInfo = selectPresencesInfo(yield select());

  userPresenceStorage.set(payload);

  const [enabledUsers, disabledUsers] = splitBy(
    userPresencesInfo,
    (presenceInfo) => presenceInfo.enabled
  );

  yield put(
    sendUserPresenceRequestsAction({
      activated: enabledUsers.map((user) => user.connectionId),
      deactivated: disabledUsers.map((user) => user.connectionId),
    })
  );
}

function* addUserPresenceInfo({ payload }: AddUserPresenceInfoAction) {
  const webRTCManager: WebRTCManager | undefined = yield getContext(
    "webRTCManager"
  );
  const { UserName, connectionId } = payload;
  const autoEnable = selectUserPresenceAutoEnable(yield select());

  if (
    isAutoEnabledForAll(autoEnable) ||
    autoEnable.userNames.includes(UserName)
  ) {
    webRTCManager?.sendTo(
      [connectionId],
      WebRTCEvent.SendPresenceRequest,
      undefined
    );
  }
}

function* removeUserPresenceInfo({
  payload,
}: RemoveUserPresenceUsernameAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const { UserName } = payload;

  canvas.clearUserMousePosition(UserName);
}

function* userPresenceMouseMoved({ payload }: UserPresenceMouseMovedAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const { pointers, UserName } = payload;
  const state: ApplicationGlobalState = yield select();
  const userPresencesInfo = selectPresencesInfo(state);
  const isActive = selectIsUserPresenceActive(state);
  const isViewingPresentation = selectIsViewingPresentation(state);

  if (!isActive && !isViewingPresentation) {
    canvas.clearUserMousePositions();
    return;
  }

  const userPresence = userPresencesInfo.find(
    (presenceInfo) => presenceInfo.UserName === UserName
  );

  if (!userPresence || !userPresence.enabled) {
    canvas.clearUserMousePosition(UserName);
    return;
  }

  const projectUsers = selectProjectUsers(yield select());
  const userState = findUnknownUserInfo(projectUsers, UserName);

  canvas.setUserMousePosition({
    pointers,
    user: userState,
  });
}

function* sendUserPresenceRequests({
  payload,
}: SendUserPresenceRequestsAction) {
  const webRTCManager: WebRTCManager | undefined = yield getContext(
    "webRTCManager"
  );
  const { activated, deactivated } = payload;

  activated.length &&
    webRTCManager?.sendTo(
      activated,
      WebRTCEvent.SendPresenceRequest,
      undefined
    );

  deactivated.length &&
    webRTCManager?.sendTo(
      deactivated,
      WebRTCEvent.RevokePresenceRequest,
      undefined
    );
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* userPresenceSaga() {
  try {
    // The userPresenceSaga is spawned ON_CANVAS_ENTER and cancelled on ON_CANVAS_EXIT
    yield call(loadUserPresence);

    yield all([
      takeEvery(
        UserPresenceActionType.SET_USER_PRESENCE_OPTIONS,
        setUserPresenceOptions
      ),
      takeEvery(
        UserPresenceActionType.ADD_USER_PRESENCE_USERNAME,
        addUserPresenceInfo
      ),
      takeEvery(
        UserPresenceActionType.REMOVE_USER_PRESENCE_USERNAME,
        removeUserPresenceInfo
      ),
      takeEvery(
        UserPresenceActionType.USER_PRESENCE_MOUSE_MOVED,
        userPresenceMouseMoved
      ),
      takeEvery(
        UserPresenceActionType.SEND_USER_PRESENCE_REQUESTS,
        sendUserPresenceRequests
      ),
    ]);
  } finally {
    const isCancelled: boolean = yield cancelled(); // Cancelled by exiting the project

    if (isCancelled) {
      userPresenceStorage.clear();
    }
  }
}
