import { ChatAction, ChatActionType } from "./chat.actions";

export type ActiveChat = {
  uuid: string;
  createdBy: number;
  lastReadCommentId: number | null;
  unreadCommentsCount: number;
  initialCommentId?: string;
};

export type ChatState = {
  activeChat: ActiveChat | undefined;
};

const defaultState: ChatState = {
  activeChat: undefined,
};

export function chatReducer(
  state = defaultState,
  action: ChatAction
): ChatState {
  switch (action.type) {
    case ChatActionType.OPEN_CHAT: {
      return {
        ...state,
        activeChat: action.payload,
      };
    }
    case ChatActionType.CLOSE_CHAT: {
      if (action.payload.uuid !== state.activeChat?.uuid) {
        return state;
      }

      return {
        ...state,
        activeChat: undefined,
      };
    }
    default:
      return state;
  }
}

export const selectActiveChat = (
  state: ApplicationGlobalState
): ActiveChat | undefined => {
  return state.canvas.chat.activeChat;
};
