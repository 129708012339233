import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Envelope(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M12.24,12.77a.89.89,0,0,0-.8.94V25.93a.89.89,0,0,0,.8.94H29a.89.89,0,0,0,.8-.94V13.71a.89.89,0,0,0-.8-.94Zm1.36,1.41H27.69l-7,7.29Zm-1,.88,7.61,7.88a.54.54,0,0,0,.79,0l7.6-7.88v10.4h-16Z" />
    </Icon>
  );
}
export default Envelope;
