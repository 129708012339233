import type {
  CreateLogStreamRequest,
  PutLogEventsRequest,
  PutLogEventsResponse,
  SequenceToken,
  InputLogEvents,
} from "aws-sdk/clients/cloudwatchlogs";

import type { AWSError, Request } from "aws-sdk";

import LocalStorage from "../localStorage";
import { getAppVersion } from "../versionInfo";

// Format message string from Error
export interface ErrorInfo {
  [key: string]: unknown;
}

export interface ClientInterface {
  createLogStream(
    params: CreateLogStreamRequest,
    callback?: (err: AWSError, data: unknown) => void
  ): Request<unknown, AWSError>;
  putLogEvents(
    params: PutLogEventsRequest,
    callback?: (err: AWSError, data: PutLogEventsResponse) => void
  ): Request<PutLogEventsResponse, AWSError>;
}

export default class Logger {
  protected static readonly defaultLogStreamName: string = "defaultstream";

  protected interval = 10000;
  protected muting = false;
  protected enabled = true;

  protected client?: ClientInterface;
  protected storage?: LocalStorage<string>;
  protected sequenceTokenStorage?: LocalStorage<string>;

  protected events: InputLogEvents = [];
  protected intervalId?: NodeJS.Timeout | number;

  /**
   * Constructor.
   *
   * @param accessKeyId     - AWS Access Key ID
   * @param secretAccessKey - AWS Secret Access Key
   * @param region          - AWS Region (e.g. ap-northeast-1)
   * @param logGroupName    - AWS CloudWatch Log Group Name
   */
  constructor(
    protected readonly accessKeyId: string,
    protected readonly secretAccessKey: string,
    protected readonly region: string,
    protected readonly logGroupName: string,
    protected readonly storageKeyHeader = "awsCloudWatch:" + logGroupName
  ) {}

  /**
   * Set interval.
   *
   * @param interval - Interval milliseconds for sending logs
   */
  public setInterval(interval: number): this {
    this.interval = interval;
    return this;
  }

  /**
   * Mute logging in browser console.
   */
  public mute(): this {
    this.muting = true;
    return this;
  }

  /**
   * Resume logging in browser console.
   */
  public unmute(): this {
    this.muting = false;
    return this;
  }

  /**
   * Enable collecting errors and sending to AWS CloudWatch.
   */
  public enable(): this {
    this.enabled = true;
    return this;
  }

  /**
   * Disable collecting errors and sending to AWS CloudWatch.
   */
  public disable(): this {
    this.enabled = false;
    return this;
  }

  /**
   * Bootstrap Logger.
   *
   * @param Ctor
   * @param storage
   */
  public async install(): Promise<void> {
    const { default: CloudWatchLogs } = await import(
      "aws-sdk/clients/cloudwatchlogs"
    );
    this.client = new CloudWatchLogs({
      accessKeyId: this.accessKeyId,
      secretAccessKey: this.secretAccessKey,
      region: this.region,
    });

    this.storage = new LocalStorage(this.storageKeyHeader + ":eventStorage");
    this.sequenceTokenStorage = new LocalStorage(
      this.storageKeyHeader + ":sequenceStorage"
    );

    // Start timer that executes this.onInterval()
    this.intervalId = setInterval(this.onInterval.bind(this), this.interval);
  }

  /**
   * Queue a new error.
   *
   * @param e    - Error object
   * @param info - Extra Error Info (Consider using "type" field)
   */
  public async logError(e: Error, info?: ErrorInfo): Promise<void> {
    if (!e || !this.enabled || !e.message) {
      return;
    }

    const stack = e.stack || "unknown";

    const message = JSON.stringify({
      message: e.message,
      timestamp: new Date().getTime(),
      userAgent: window.navigator.userAgent,
      stack,
      pageVisibilityState: document.visibilityState,
      appVersion: getAppVersion(),
      ...info,
    });

    this.events.push({
      timestamp: new Date().getTime(),
      message,
    });
  }

  /**
   * Queue a new error.
   *
   * @param e    - Error object
   * @param info - Extra Error Info (Consider using "type" field)
   */
  public async trackEvent(e: string, info?: ErrorInfo): Promise<void> {
    if (!e || !this.enabled) {
      return;
    }

    const message = JSON.stringify({
      message: e,
      timestamp: new Date().getTime(),
      userAgent: window.navigator.userAgent,
      appVersion: getAppVersion(),
      ...info,
    });

    this.events.push({
      timestamp: new Date().getTime(),
      message,
    });
  }

  /**
   * Send queued messages.
   */
  public async onInterval(): Promise<void> {
    if (!this.enabled) {
      return;
    }

    // Extract from queue
    const pendingEvents = this.events.splice(0);
    if (!pendingEvents.length) {
      return;
    }

    // Retrieve or newly calculate logStreamName for current user
    const logStreamName = await this.getLogStreamName();
    if (!logStreamName) {
      return;
    }

    // Retrieve previous "nextSequenceToken" from cache
    const sequenceToken = this.getSequenceTokenStorage().get();

    // Build parameters for PutLogEvents endpoint
    //   c.f. https://docs.aws.amazon.com/AmazonCloudWatchLogs/latest/APIReference/API_PutLogEvents.html
    const params: PutLogEventsRequest = {
      logEvents: pendingEvents,
      logGroupName: this.logGroupName,
      logStreamName,
      ...(sequenceToken ? { sequenceToken } : undefined),
    };

    let nextSequenceToken: SequenceToken | undefined = undefined,
      match: RegExpMatchArray | null = null;

    try {
      // Run request to send events and retrieve fresh "nextSequenceToken"
      ({ nextSequenceToken = undefined } = await new Promise(
        (resolve, reject) => {
          this.getClient().putLogEvents(params, (err, data) =>
            err ? reject(err) : resolve(data)
          );
        }
      ));
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const error = e as any;
      // Try to recover from InvalidSequenceTokenException error message
      if (
        !error ||
        error.code !== "InvalidSequenceTokenException" ||
        !(match = error.message.match(
          /The next expected sequenceToken is: (\w+)/
        ))
      ) {
        // Print error to console (for devs) and reset states
        // eslint-disable-next-line no-console
        console.error(error);
        this.refresh();
        return;
      }
    }

    // Recover from InvalidSequenceTokenException error message
    if (match) {
      nextSequenceToken = match[1];
    }
    // Cache fresh "nextSequenceToken"
    if (nextSequenceToken) {
      this.getSequenceTokenStorage().set(nextSequenceToken);
    }
    // Immediately retry after recovery
    if (match) {
      this.events.push(...pendingEvents);
      setTimeout(this.onInterval, 0);
    }
  }

  protected getClient(): ClientInterface {
    if (!this.client) {
      throw new Error(
        "Aws CloudWatch service: call install before logging items."
      );
    }
    return this.client;
  }

  protected getEventStorage(): LocalStorage<string> {
    if (!this.storage) {
      throw new Error(
        "Aws CloudWatch service: call install before logging items."
      );
    }
    return this.storage;
  }

  protected getSequenceTokenStorage(): LocalStorage<string> {
    if (!this.sequenceTokenStorage) {
      throw new Error(
        "Aws CloudWatch service: call install before logging items."
      );
    }
    return this.sequenceTokenStorage;
  }

  protected refresh(): void {
    this.getEventStorage().clear();
    this.getSequenceTokenStorage().clear();
    this.events.splice(0);
  }

  protected async getLogStreamName(): Promise<string | null> {
    const retrieved = this.getEventStorage().get();
    if (retrieved) {
      return retrieved;
    }

    // Build parameters for CreateLogStream endpoint
    //   c.f. https://docs.aws.amazon.com/AmazonCloudWatchLogs/latest/APIReference/API_CreateLogStream.html
    const params = {
      logGroupName: this.logGroupName,
      logStreamName: Logger.defaultLogStreamName, // "defaultstream"
    };

    try {
      // Run request to create a new logStream
      await new Promise((resolve, reject) => {
        this.getClient().createLogStream(params, (err, data) =>
          err ? reject(err) : resolve(data)
        );
      });
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const error = e as any;

      // Try to recover from ResourceAlreadyExistsException error
      if (!error || error.code !== "ResourceAlreadyExistsException") {
        // Print error to  console and reset states
        // eslint-disable-next-line no-console
        console.error(error);
        this.refresh();
        return null;
      }
    }

    // Cache fresh "logStreamName"
    this.getEventStorage().set(params.logStreamName);
    return params.logStreamName;
  }
}
