import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Edit(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M27.13,19.52a.75.75,0,0,0-.74.75v5.25a.78.78,0,0,1-.78.78H14.54a.78.78,0,0,1-.78-.78V14.45a.78.78,0,0,1,.78-.78h5.51a.74.74,0,1,0,0-1.48H14.54a2.26,2.26,0,0,0-2.26,2.26V25.52a2.26,2.26,0,0,0,2.26,2.26H25.61a2.26,2.26,0,0,0,2.26-2.26V20.27A.75.75,0,0,0,27.13,19.52Z" />
      <path d="M17.36,20v2.17a.55.55,0,0,0,.55.55h2.17a.55.55,0,0,0,.38-.16l7.05-7.06a.52.52,0,0,0,0-.76l-2.17-2.17a.52.52,0,0,0-.76,0L17.52,19.6A.55.55,0,0,0,17.36,20ZM25,13.7l1.4,1.4L25.5,16l-1.4-1.4Zm-6.51,6.51,4.88-4.89,1.41,1.41-4.89,4.88h-1.4Z" />
    </Icon>
  );
}
export default Edit;
