import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function UploadDocument(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <polygon points="25.07 15.9 19.78 21.84 22.63 21.85 22.63 29.35 27.5 29.35 27.5 21.87 30.35 21.88 25.07 15.9" />
      <path d="M21,23.54H14.5V22.05h2.82l2.56-2.72H14.5V17.85h6.78L24.91,14h0V11.35H12.35v15H21Zm-6.52-9.9h4.61v1.48H14.5Z" />
    </Icon>
  );
}
export default UploadDocument;
