import { fabric } from "fabric";

import { BrushType } from "../../../../const";
import { loadImagePromise } from "../../../../tools/files";
import { RawPoint } from "../utils/pathSmoothing";

import { BaseInkBrush } from "./BaseInkBrush";

/**
 * Create a list of rotations to use for the brush image. This helps to prevent
 * streaking.
 *
 * The rotations should not change when the canvas is re-rendered, otherwise the
 * path will flicker.
 */
const brushRotationPattern = [
  60,
  240,
  30,
  180,
  120,
  300,
  90,
  210,
  330,
  150,
  270,
].map((d) => fabric.util.degreesToRadians(d));
const brushRotationPatternSize = brushRotationPattern.length;

/**
 * Cache the requests for the brush images so that we don't load the same image
 * multiple times (which can flood the `PQueue` and result in TimeoutErrors)
 */
const brushImageRequestCache: { [src: string]: Promise<HTMLImageElement> } = {};

export abstract class BaseImageBrush extends BaseInkBrush {
  /**
   * Randomly rotate each brush image to prevent streaking in the path.
   */
  protected ENABLE_BRUSH_ROTATION = true;

  /**
   * Define the spacing between painted instances of the brush image.
   *
   * This controls how heavy the brush path looks because it affects the amount
   * of overlap.
   */
  protected BRUSH_IMAGE_SPACING = 2;

  private _image: fabric.Image | null;

  constructor(
    private brushImageSrc: string,
    public brushType: BrushType,
    protected canvas: fabric.CollaboardCanvas,
    options?: fabric.CollaboardBrushOptions
  ) {
    super(brushType, canvas, options);

    this._image = null;
  }

  public setColor(color: string): void {
    super.setColor(color);
    this._applyImageFilter();
  }

  public async loadBrushImage(): Promise<void> {
    const src = this.brushImageSrc;
    const imageRequestPromise =
      brushImageRequestCache[src] ??
      loadImagePromise(src, {
        bypassQueue: true,
      }).catch(() => {
        delete brushImageRequestCache[src];
      });
    brushImageRequestCache[src] = imageRequestPromise;

    const imageElement = await imageRequestPromise;
    this._image = new fabric.Image(imageElement);
    this._image.filters = [];
    this._applyImageFilter();
  }

  private _applyImageFilter(): void {
    if (this._image) {
      this._image.filters = this._image.filters ?? [];
      this._image.filters[0] = new fabric.Image.filters.BlendColor({
        color: this.liveColor.toLive(),
        mode: "multiply",
      });
      this._image.applyFilters();
    }
  }

  public _setBrushStyles(ctx: CanvasRenderingContext2D | null): void {
    super._setBrushStyles(ctx);
    this._applyImageFilter();
  }

  protected _partialRender(
    ctx: CanvasRenderingContext2D | null = this.canvas.contextTop,
    renderFrom = 0,
    _points?: RawPoint[]
  ): void {
    const points: RawPoint[] = _points || this._points;
    const pointsToRender = points.slice(renderFrom);
    const defaultAlpha = 0.1;

    if (!this._image || !ctx) {
      // Brush image is not ready yet or canvas has been destroyed
      return;
    }

    if (points.length === 1 && renderFrom === 0) {
      const singlePoint = points[0] as fabric.CollaboardPoint;
      const x = singlePoint.x - this.strokeWidth / 2;
      const y = singlePoint.y - this.strokeWidth / 2;

      // Single points use a different alpha value
      ctx.globalAlpha = defaultAlpha;
      ctx.drawImage(
        this._image._element,
        x,
        y,
        this.strokeWidth,
        this.strokeWidth
      );

      this._clearCanvasOnNextRender = true;
      return;
    }

    pointsToRender.forEach((point, i, arr) => {
      const realIndex = renderFrom + i;
      const previousPoint = arr[i - 1] || points[realIndex - 1];

      if (!point || !previousPoint || !this._image) {
        return;
      }

      const distance = point.distanceFrom(previousPoint);
      const angle = point.angleBetweenDifferenceVectors(previousPoint);

      const alpha = point.pressure ?? defaultAlpha;
      const clampedDistance = Math.max(distance, 0.1);

      ctx.globalAlpha = alpha;

      /**
       *@NOTE The iOS app uses zoom factor here to control the spacing between
       * images (for performance reasons), however that isn't required because
       * we have a cache. Also it affects the appearance of the path as you zoom
       * in and out (the opacity changes).
       */
      for (
        let i = 0, n = 0;
        i < clampedDistance;
        i += this.BRUSH_IMAGE_SPACING, n += 1
      ) {
        const halfStroke = this.strokeWidth / 2;
        const x = previousPoint.x + Math.sin(angle) * i - halfStroke;
        const y = previousPoint.y + Math.cos(angle) * i - halfStroke;

        const interpolatedIndex = realIndex + n;
        // Only rotate every other point to save some work
        const rotatePoint = this.ENABLE_BRUSH_ROTATION && interpolatedIndex % 2;

        if (rotatePoint) {
          const rotation =
            brushRotationPattern[
              Math.floor((interpolatedIndex % brushRotationPatternSize) / 2)
            ];

          ctx.save();
          ctx.translate(x + halfStroke, y + halfStroke);
          ctx.rotate(rotation);
          ctx.translate(-x - halfStroke, -y - halfStroke);
        }

        ctx.drawImage(
          this._image._element,
          x,
          y,
          this.strokeWidth,
          this.strokeWidth
        );

        if (rotatePoint) {
          ctx.restore();
        }
      }
    });
  }
}
