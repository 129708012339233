import { isEmpty } from "ramda";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import useToast from "../../../shared/toasts/useToast";
import LoadingSpinner from "../../../../icons/LoadingSpinner";
import ContainedSpinner from "../../../shared/spinners/ContainedSpinner";
import { ProjectsContext } from "../Projects";
import NoResults from "../../../shared/layout/NoResults";
import {
  ProjectListHeader,
  ProjectResultByDate,
  ProjectResultByDateExtended,
} from "./ProjectResultByDate";
import { usePermissions } from "../../../permissions/usePermissions";
import { ApiResourcePermission, ApiResourceType } from "../../../../types/enum";
import { useSpaces } from "../../../spaces/useSpaces";
import { findSpacePermission } from "../../../spaces/utils";

function ProjectListResult(): ReactElement {
  const [expandedProjectId, expandProjectId] = useState<string | null>(null);
  const [editedProjectId, setEditedProjectId] = useState<string | null>(null);
  const { t } = useTranslation();
  const projectRenamedToast = useToast("project.renameSuccessful");
  const {
    Projects,
    projectListError,
    isProjectListLoading,
    hasMore,
    updateProject,
    loadMoreProjects,
    query,
  } = useContext(ProjectsContext).current;
  const { spaces } = useSpaces();
  const { hasPermission } = usePermissions();

  useEffect(() => {
    // dismiss toast when entering editing mode
    editedProjectId && projectRenamedToast.dismiss();
  }, [editedProjectId, projectRenamedToast]);

  if (!isProjectListLoading && (projectListError || isEmpty(Projects))) {
    return (
      <NoResults error={projectListError}>
        {query
          ? t("clientError.noMatchingProjects")
          : t("clientError.noProjects")}
      </NoResults>
    );
  }

  const allowedProjects = Projects.filter((project) => {
    const spaceId = project.Project.SpaceId;
    return hasPermission({
      type: spaceId ? ApiResourceType.SpaceProjects : ApiResourceType.Project,
      value: String(project.Project.ProjectId),
      permission: ApiResourcePermission.View,
      spacePermission: spaceId
        ? findSpacePermission(spaces, spaceId)
        : undefined,
      projectPermission: project.Permission,
    });
  });

  return (
    <>
      <ProjectListHeader />
      <InfiniteScroll
        loadMore={() => loadMoreProjects()}
        hasMore={hasMore}
        loader={<LoadingSpinner key="projects-infinite-scroll" />}
        threshold={0}
        style={{ position: "relative", minHeight: "calc(var(--gridTile) * 2)" }}
      >
        {isProjectListLoading && <ContainedSpinner />}
        {allowedProjects.map((projectWithMeta) => {
          const { ProjectId: id, IsLocked } = projectWithMeta.Project;
          const isExpanded = expandedProjectId === id && !IsLocked;
          const isEditMode = editedProjectId === id && !IsLocked;

          const onExpand = () =>
            expandProjectId(expandedProjectId === id ? null : id);

          const onProjectEdited = (newProject: Project) => {
            projectRenamedToast.dismiss();
            setEditedProjectId(null);
            updateProject(newProject);
            projectRenamedToast.show();
          };

          return (
            <React.Fragment key={id}>
              <ProjectResultByDate
                projectWithMeta={projectWithMeta}
                onExpand={onExpand}
                onProjectEdited={onProjectEdited}
                activateEditMode={setEditedProjectId}
                isExpanded={isExpanded}
                isEditMode={isEditMode}
                isLocked={!!IsLocked}
              />
              {isExpanded && (
                <ProjectResultByDateExtended
                  projectWithMeta={projectWithMeta}
                />
              )}
            </React.Fragment>
          );
        })}
      </InfiniteScroll>
    </>
  );
}

export default ProjectListResult;
