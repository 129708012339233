import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Cool(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="m20,9.86487a10.13513,10.13513 0 1 0 10.13513,10.13513a10.13513,10.13513 0 0 0 -10.13513,-10.13513zm0,0.9009a9.23423,9.23423 0 1 1 -9.23423,9.23423a9.23423,9.23423 0 0 1 9.23423,-9.23423zm-7.43243,4.95495l0,0.9009l0.9009,0a3.15315,3.15315 0 0 0 6.3063,0l0.45045,0a3.15315,3.15315 0 0 0 6.3063,0l0.9009,0l0,-0.9009l-14.86485,0zm12.38738,6.49549a0.46622,0.46622 0 0 0 -0.36712,0.22523a5.44144,5.44144 0 0 1 -9.16215,0a0.45045,0.45045 0 0 0 -0.43018,-0.18919a0.47522,0.47522 0 0 0 -0.32432,0.7027a6.33783,6.33783 0 0 0 10.65765,0c0.22523,-0.41892 -0.04054,-0.72522 -0.38063,-0.73198l0.00676,-0.00676z" />
    </Icon>
  );
}

export default Cool;
