import { useEffect, useState } from "react";
import axios from "axios";

import { runtimeConfig } from "../../tools/runtimeConfig";
import { getAppVersion, isVersionLessThan } from "../../tools/versionInfo";
import { exponential } from "../../tools/promises";
import { noop } from "../../tools/utils";
import { LogCategory, onErrorToLog } from "../../tools/telemetry";

const { checkForNewVersionInterval } = runtimeConfig;
const intervalDuration = checkForNewVersionInterval * 60 * 1000;

export const getVersion = (): Promise<{ version: string }> => {
  const url = `/config/version.json?timestamp=${Date.now()}`;

  return axios.get<{ version: string }>(url).then((response) => response.data);
};

/*
 * compares the version of the app retrieved from process.env during initial load with the latest version number stored in config/version.json
 * config/version.json is created during build time via azure-pipelines.yml with no-cache setting set via Web.config
 */
export const useNewVersionCheck = (): { isNewVersionAvailable: boolean } => {
  const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);

  const checkForNewVersion = async () => {
    try {
      // The server is not available during a deployment so this request fails and needs to be retried
      const latest = await exponential(getVersion);

      const isLatestVersionProperlyFetched =
        typeof latest.version === "string" && latest.version.length >= 5;

      if (
        isLatestVersionProperlyFetched &&
        isVersionLessThan(getAppVersion(), latest.version)
      ) {
        setIsNewVersionAvailable(true);
      }
    } catch (error) {
      onErrorToLog(error as Error, LogCategory.version);
    }
  };

  useEffect(() => {
    // setting `checkForNewVersionInterval` to zero disables checks in the environment
    if (intervalDuration === 0) {
      return noop;
    }

    // run a single check on mount
    checkForNewVersion();

    // run checks in interval
    const timer = setInterval(checkForNewVersion, intervalDuration);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return { isNewVersionAvailable };
};
