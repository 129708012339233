import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function TwoFactorAuth(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M20.38,6.74,9.86,10.06V16.5A17.3,17.3,0,0,0,17.2,30.62l3.18,2.23,3.18-2.23A17.23,17.23,0,0,0,30.91,16.5V10.06Zm9.06,9.76a15.69,15.69,0,0,1-6.72,12.89L20.38,31,18,29.39A15.75,15.75,0,0,1,11.33,16.5V11.12l9-2.86,9.06,2.86Z" />
      <path d="M17.31,15.06v1.47H16.17v6.82H24.6V16.53H23.45V15.06a3.07,3.07,0,1,0-6.14,0Zm3.78,6.64H19.67l.25-1.83a.87.87,0,0,1-.41-.73.87.87,0,1,1,1.74,0,.87.87,0,0,1-.41.73Zm-2.5-6.64a1.8,1.8,0,0,1,3.59,0v1.47H18.59V15.06Z" />
    </Icon>
  );
}
export default TwoFactorAuth;
