import { useQuery } from "react-query";
import { getLicenseInfo } from "../../api";
import { errorCodeIds } from "../../api/errorCodes";
import { useLoggedInStatus } from "../authentication/useLoggedInStatus";

type UseLicenseInfoState = {
  isSuccess: boolean;
  isLicenseActive: boolean | undefined;
  numberOfOwnedProjects: number | undefined;
  productId: ApiProductIds | undefined;
  refetch: () => Promise<ApiLicense | undefined>;
};

export const useLicenseInfo = (enableQuery = true): UseLicenseInfoState => {
  const { isLoggedInAsUser } = useLoggedInStatus();

  const { data: licenseInfo, refetch } = useQuery<ApiLicense, Error>({
    queryKey: "GetLicenseInfo",
    queryFn: () => getLicenseInfo(),
    config: {
      initialData: null,
      initialStale: true,
      enabled: enableQuery && isLoggedInAsUser,
    },
  });

  return {
    isSuccess: licenseInfo?.ErrorCode === errorCodeIds.None,
    isLicenseActive: licenseInfo?.IsActive,
    numberOfOwnedProjects: licenseInfo?.NumberOfOwnedProjects,
    productId: licenseInfo?.ProductId,
    refetch,
  };
};
