import LoginPage from "./LoginCollins";
import Disclaimer from "./Disclaimer";
import Logo from "./Logo";
import Tagline from "./Tagline";
import PlansPageFooter from "./PlansPageFooter";

const components: Partial<CustomerComponents> = {
  Logo,
  Tagline,
  LoginPage,
  Disclaimer,
  PlansPageFooter,
};

export default components;
