export const featuresEnterprise: Array<Feature> = [
  {
    labelKey: "onPremiseHosting",
    inCard: true,
    order: 700,
  },
  {
    labelKey: "azureHosting",
    inCard: true,
    order: 701,
  },
  {
    labelKey: "awsHosting",
    inCard: true,
    order: 702,
  },
  {
    labelKey: "anyCloudHosting",
    inCard: true,
    order: 703,
  },
  {
    labelKey: "SSO",
    inCard: true,
    order: 704,
  },
  {
    labelKey: "SLA",
    inCard: true,
    order: 705,
  },
  {
    labelKey: "companyBranding",
    inCard: true,
    order: 706,
  },
  {
    labelKey: "apiUse",
    inCard: true,
    order: 707,
  },
  {
    labelKey: "adminDashboard",
    inCard: true,
    order: 708,
  },
];
