import React, { useEffect, ReactElement } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FullPageSpinner from "../../shared/spinners/FullPageSpinner";
import { routes } from "../../../const";
import { parseQueryUrl } from "../../../tools/url";

function WebexAuth(): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const {
    code: webexAccessCode,
    state: projectId,
  } = parseQueryUrl<WebexAuthUrlQuery>(search);

  useEffect(() => {
    if (webexAccessCode && projectId) {
      const projectState: ViewBoardState = { webexAccessCode };
      history.push(`${routes.collaboard}/${projectId}`, projectState);
    } else {
      history.push(routes.projects);
    }
  }, [webexAccessCode, projectId, history]);

  return <FullPageSpinner>{t("redirecting")}</FullPageSpinner>;
}

export default WebexAuth;
