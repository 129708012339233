import React, { ReactElement, PropsWithChildren } from "react";
import { Flex, ThemeUIStyleObject } from "theme-ui";
import { Tabs as ReachTabs } from "@reach/tabs";
import "@reach/tabs/styles.css";

function Tabs({ children }: PropsWithChildren<unknown>): ReactElement {
  return (
    <ReachTabs as={Flex} sx={tabsWrapperStyle}>
      {children}
    </ReachTabs>
  );
}

const tabsWrapperStyle: ThemeUIStyleObject = {
  flexDirection: "column",
  width: "100%",
  flex: 1,
};

export default Tabs;
