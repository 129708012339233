import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Flex } from "theme-ui";

import { rowHeight } from "./const";

function EmptyListPlaceholder(): ReactElement {
  const { t } = useTranslation();

  return (
    <Flex
      sx={{
        height: rowHeight,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {t("team.requestError")}
    </Flex>
  );
}

export default EmptyListPlaceholder;
