import React, { ReactElement, useEffect } from "react";
import PageAuthorized from "../layout/pages/PageAuthorized";
import LicenseSummary from "./Team/License/LicenseSummary";
import { useTrackEventToLog } from "../../shared/tracking/useTrackEventToLog";
import Team from "./Team/Team";
import { LogCategory } from "../../../tools/telemetry";

function TeamPage(): ReactElement {
  const { trackEvent } = useTrackEventToLog();

  useEffect(() => {
    trackEvent(LogCategory.navigation, {
      subcategory: "page-team",
    });
  }, [trackEvent]);

  return (
    <PageAuthorized>
      <LicenseSummary />
      <Team />
    </PageAuthorized>
  );
}

export default TeamPage;
