import type { TFunction } from "i18next";
import type { CountryCode } from "libphonenumber-js";
import * as yup from "yup";

import { getCountry } from "../features/shared/countries/countries";

export function isAbsent(value: unknown): boolean {
  return value === null || value === undefined;
}

export const initializePhoneValidation = (t: TFunction): void => {
  yup.addMethod(yup.string, "phone", function validatePhoneNumber() {
    return this.test({
      name: "phone",
      exclusive: true,
      message: t("clientError.phoneNumberNotValid"),
      test: async (number) => {
        if (isAbsent(number)) {
          // Custom validation methods must return as valid if the value is
          // absent as the `.required()` rule will catch missing values.
          return true;
        }
        const country = getCountry(number ?? "", "");
        if (!country?.iso2) {
          return false;
        }

        const { parsePhoneNumberFromString } = await import(
          "libphonenumber-js"
        );

        try {
          const phoneNumberObj = parsePhoneNumberFromString(
            number ?? "",
            country.iso2 as CountryCode
          );
          return phoneNumberObj?.isValid() ?? false;
        } catch {
          return false;
        }
      },
    });
  });
};

export function normalizeNullValues<T>(object: T): Partial<T> {
  return JSON.parse(
    JSON.stringify(object, (key, value) => (value === null ? "" : value))
  );
}
