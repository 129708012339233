import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { Box, Heading, Text, ThemeUIStyleObject } from "theme-ui";
import { routes } from "../../../const";
import { getAppName } from "../../../tools/customer";
import EmailRegistration from "./EmailRegistration";
import { useAuthConfig } from "../useAuthConfig";
import OrSeparator, { Direction } from "../../shared/layout/OrSeparator";
import ContainedSpinner from "../../shared/spinners/ContainedSpinner";
import ExternalLogin from "../login/ExternalLogin";

function RegistrationOptions(): ReactElement {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [isRegistrationLocked, setIsRegistrationLocked] = useState(false);

  const { getAuthConfig, isLoadingConfig, authConfig } = useAuthConfig();

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const redirectAfterLogin = queryParams.get("redirect") ?? undefined;

  /**
   * Wait for ExternalLogin component to check if it needs to handle
   * an external login redirect. If no redirect is detected then login will be
   * unlocked.
   */
  const onUnlockLogin = useCallback(() => {
    setIsRegistrationLocked(false);
  }, []);

  const onLockLogin = useCallback(() => {
    setIsRegistrationLocked(true);
  }, []);

  useEffect(() => {
    if (!isRegistrationLocked && !isLoadingConfig && !authConfig) {
      getAuthConfig();
    }
  }, [authConfig, getAuthConfig, isLoadingConfig, isRegistrationLocked]);

  const hasExternalAuthProviders = !!authConfig?.externalAuthProviders.length;

  return (
    <Box data-testid="registration-options">
      <Heading as="legend" sx={headingStyle}>
        {t("form.header.register", { appName: getAppName() })}
      </Heading>
      <Text sx={textStyle}>
        <Trans i18nKey="form.text.haveAccount">
          <Link
            to={{
              pathname: routes.authenticate,
              search,
            }}
          />
        </Trans>
      </Text>
      {hasExternalAuthProviders && (
        <>
          <ExternalLogin
            authProviders={authConfig.externalAuthProviders}
            tenantFromConfig={authConfig.tenant}
            redirectAfterLogin={redirectAfterLogin}
            isRegistration={true}
            onUnlockLogin={onUnlockLogin}
            onLockLogin={onLockLogin}
          />
          <OrSeparator sx={orStyle} directions={orDirections} />
        </>
      )}
      <EmailRegistration tenantFromConfig={authConfig?.tenant} />
      {isLoadingConfig && <ContainedSpinner />}
    </Box>
  );
}

export default RegistrationOptions;

const headingStyle: ThemeUIStyleObject = { width: ["100%"] };
const textStyle: ThemeUIStyleObject = { mt: [4], mb: [5] };
const orStyle: ThemeUIStyleObject = { my: [3] };
const orDirections: Direction[] = ["row"];
