import {
  CanvasSettingsAction,
  CanvasSettingsActionType,
} from "../settings/settings.actions";
import {
  PresentationPresenterAction,
  PresentationPresenterActionType,
} from "./presentation.presenter.actions";
import { computeScaledViewport } from "./presentation.utils";
import {
  PresentationViewerAction,
  PresentationViewerActionType,
} from "./presentation.viewer.actions";

export type ScreenCoordinates = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type PresentationState = {
  isPresenter: boolean;
  active: boolean;

  // OWNER ONLY
  currentTab: boolean;

  // VIEWER ONLY
  offsetX: number;
  offsetY: number;
  presenter: string | undefined;
  /**
   * @NOTE These two properties can be temporarily undefined if a user comes into a presentation that
   * was already started and and ON_JOINED_PRESENTATION is dispatched later than PRESENTATION_STARTED.
   * It happens also with an F5 reload.
   */
  presenterViewport: ScreenCoordinates | undefined;
  presenterZoomLevel: number | undefined;
  scaledHeight: number;
  scaledWidth: number;
  scaledZoomLevel: number;
};

const getInitialPresentationState = (): PresentationState => {
  return {
    isPresenter: false,

    currentTab: false,

    active: false,
    offsetX: 0,
    offsetY: 0,
    presenter: undefined,
    presenterViewport: undefined,
    presenterZoomLevel: undefined,
    scaledHeight: 1,
    scaledWidth: 1,
    scaledZoomLevel: 1,
  };
};

export function presentationReducer(
  state = getInitialPresentationState(),
  action:
    | PresentationPresenterAction
    | PresentationViewerAction
    | CanvasSettingsAction
): PresentationState {
  switch (action.type) {
    /*----------  PRESENTATION PRESENTER  ----------*/
    case PresentationPresenterActionType.PRESENTING_IN_OTHER_WINDOW: {
      return {
        ...state,
        isPresenter: true,
        active: true,
        currentTab: false,
      };
    }
    case PresentationPresenterActionType.START_PRESENTING:
    case PresentationPresenterActionType.RESUME_PRESENTING: {
      return {
        ...state,
        isPresenter: true,
        active: true,
        currentTab: true,
      };
    }
    case PresentationPresenterActionType.STOP_PRESENTING: {
      return {
        ...state,
        isPresenter: false,
        active: false,
        currentTab: false,
      };
    }
    /*----------  PRESENTATION VIEWER  ----------*/
    case PresentationViewerActionType.ON_NUDGE_FOR_ATTENTION: {
      const {
        presentation: { ZoomLevel: zoomLevel, ScreenCoordinates },
        myCanvas,
      } = action.payload;
      const screenCoordinates = {
        x: ScreenCoordinates?.X ?? 0,
        y: ScreenCoordinates?.Y ?? 0,
        width: ScreenCoordinates?.Width ?? 0,
        height: ScreenCoordinates?.Height ?? 0,
      };

      return {
        ...state,
        presenterZoomLevel: zoomLevel,
        presenterViewport: screenCoordinates,
        ...computeScaledViewport(
          { viewport: screenCoordinates, zoomLevel },
          myCanvas
        ),
      };
    }
    case PresentationViewerActionType.PRESENTATION_STARTED: {
      const {
        presentation: { Presenter },
        isPresenter,
      } = action.payload;

      return {
        ...state,
        isPresenter,
        active: true,
        presenter: Presenter,
      };
    }
    case PresentationViewerActionType.PRESENTATION_STOPPED: {
      return getInitialPresentationState();
    }
    case PresentationViewerActionType.PRESENTATION_VIEWPORT_CHANGE: {
      const {
        presentation: { ScreenCoordinates, ZoomLevel: zoomLevel },
        myCanvas,
      } = action.payload;
      const screenCoordinates = {
        x: ScreenCoordinates?.X ?? 0,
        y: ScreenCoordinates?.Y ?? 0,
        width: ScreenCoordinates?.Width ?? 0,
        height: ScreenCoordinates?.Height ?? 0,
      };

      return {
        ...state,
        presenterZoomLevel: zoomLevel,
        presenterViewport: screenCoordinates,
        ...computeScaledViewport(
          { viewport: screenCoordinates, zoomLevel },
          myCanvas
        ),
      };
    }
    case CanvasSettingsActionType.ORIENTATION_CHANGE:
    case CanvasSettingsActionType.WINDOW_RESIZE_THROTTLED: {
      const myCanvas = action.payload;
      const { active, presenterZoomLevel, presenterViewport } = state;

      if (!active) {
        return state;
      }

      if (!presenterZoomLevel || !presenterViewport) {
        return state;
      }

      return {
        ...state,
        presenterZoomLevel,
        presenterViewport,
        ...computeScaledViewport(
          { viewport: presenterViewport, zoomLevel: presenterZoomLevel },
          myCanvas
        ),
      };
    }
    case PresentationViewerActionType.ON_JOINED_PRESENTATION: {
      const {
        presentation: { ZoomLevel, ScreenCoordinates },
        isPresenter,
        myCanvas,
      } = action.payload;
      const screenCoordinates = {
        x: ScreenCoordinates?.X ?? 0,
        y: ScreenCoordinates?.Y ?? 0,
        width: ScreenCoordinates?.Width ?? 0,
        height: ScreenCoordinates?.Height ?? 0,
      };

      if (isPresenter) {
        return state;
      }

      return {
        ...state,
        // active: true,
        presenterZoomLevel: ZoomLevel,
        presenterViewport: screenCoordinates,
        ...computeScaledViewport(
          { viewport: screenCoordinates, zoomLevel: ZoomLevel },
          myCanvas
        ),
      };
    }
    default:
      return state;
  }
}

/**
 * @NOTE This selector is often updated during a presentation, prefer a more granular selector to
 * avoid unnecessary re-renders. It's okay to be used in sagas.
 */
export const selectPresentationState = (
  state: ApplicationGlobalState
): PresentationState => {
  return state.canvas.presentation;
};

export const selectIsPresenting = (state: ApplicationGlobalState): boolean => {
  const { active, isPresenter } = state.canvas.presentation;
  return active && isPresenter;
};

export const selectIsPresentingInCurrentTab = (
  state: ApplicationGlobalState
): boolean => {
  const { active, isPresenter, currentTab } = state.canvas.presentation;
  return active && isPresenter && currentTab;
};

export const selectIsViewingPresentation = (
  state: ApplicationGlobalState
): boolean => {
  return (
    state.canvas.presentation.active && !state.canvas.presentation.isPresenter
  );
};

export const selectIsAnyonePresenting = (
  state: ApplicationGlobalState
): boolean => {
  return state.canvas.presentation.active;
};

export const selectPresenter = (
  state: ApplicationGlobalState
): string | undefined => {
  return state.canvas.presentation.presenter;
};
