import { fabric } from "fabric";
import { all, Effect, getContext, select, takeEvery } from "redux-saga/effects";
import { selectIsViewingPresentation } from "../../presentation/presentation.reducer";
import {
  ActiveMediaAction,
  ActiveMediaActionType,
  RewindAction,
  SkipPositionAction,
} from "./activeMedia.actions";

type Config = {
  preventIfSelected?: boolean;
};

function forLastActiveMediaObject<
  T extends ActiveMediaAction = ActiveMediaAction
>(
  callback: (mediaObject: fabric.CollaboardMedia, action: T) => void,
  config: Config = {}
) {
  return function* handleActiveMediaSaga(action: T) {
    const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
    const { preventIfSelected } = config;
    const isOtherPresenting = selectIsViewingPresentation(yield select());

    // If a given key is responsible for multiple actions, these on selected object take precedence.
    // Example: Up key moves selected object up, or turns up the video.
    // If any object is selected, it will be moved, otherwise last active video will be turned up
    const preventAction = preventIfSelected && canvas.getActiveObject();
    if (isOtherPresenting || preventAction) {
      return;
    }

    const lastActiveMediaObject = canvas.getLastActiveMedia();

    lastActiveMediaObject && callback(lastActiveMediaObject, action);
  };
}

const togglePlay = (mediaObject: fabric.CollaboardMedia) =>
  mediaObject.togglePlay();

const toggleMute = (mediaObject: fabric.CollaboardMedia) =>
  mediaObject.toggleMute();

const skipPosition = (
  mediaObject: fabric.CollaboardMedia,
  { payload: { forward } }: SkipPositionAction
) => mediaObject.rewindBy(forward ? 5 : -5);

const rewind = (
  mediaObject: fabric.CollaboardMedia,
  { payload: { percentage } }: RewindAction
) => mediaObject.rewindToPercentage(percentage);

const toggleFullScreen = (mediaObject: fabric.CollaboardMedia) =>
  mediaObject.toggleFullScreen();

function* closeFullscreenPlayer() {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  canvas.removeTemporaryFullscreenElement();
}

export function* activeMediaSaga(): Generator<Effect> {
  yield all([
    takeEvery(
      ActiveMediaActionType.TOGGLE_PLAY,
      forLastActiveMediaObject(togglePlay)
    ),
    takeEvery(
      ActiveMediaActionType.TOGGLE_MUTE,
      forLastActiveMediaObject(toggleMute)
    ),
    takeEvery(
      ActiveMediaActionType.SKIP_POSITION,
      forLastActiveMediaObject<SkipPositionAction>(skipPosition, {
        preventIfSelected: true,
      })
    ),
    takeEvery(ActiveMediaActionType.REWIND, forLastActiveMediaObject(rewind)),
    takeEvery(
      ActiveMediaActionType.TOGGLE_FULLSCREEN,
      forLastActiveMediaObject(toggleFullScreen)
    ),
    takeEvery(
      ActiveMediaActionType.CLOSE_FULLSCREEN_PLAYER,
      closeFullscreenPlayer
    ),
  ]);
}
