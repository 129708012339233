import { BrushType, canvasObjectIds } from "../../../../const";

import { BaseBrush } from "./BaseBrush";

export class EraserBrush extends BaseBrush {
  constructor(
    canvas: fabric.CollaboardCanvas,
    options?: fabric.CollaboardEraserBrushOptions
  ) {
    super(BrushType.rubber, canvas, options);
  }

  /**
   * Handle pointer down event. Called internally by Fabric.
   * @param pointer {fabric.Position} Pointer position
   * @param options {fabric.BrushEventOptions} Brush event options
   */
  public onMouseDown(
    pointer: fabric.Position,
    options: fabric.BrushEventOptions
  ): void {
    const isDrawingPointer = this._isDrawingPointerEvent(options.e);

    if (!isDrawingPointer) {
      return;
    }

    // Remove on click (without move)
    this._findTargetAndRemove(options);
  }

  /**
   * Handle pointer move event. Called internally by Fabric.
   * @param pointer {fabric.Position} Pointer position
   * @param options {fabric.BrushEventOptions} Brush event options
   */
  public onMouseMove(
    pointer: fabric.Position,
    options: fabric.BrushEventOptions
  ): void {
    const isDrawingPointer = this._isDrawingPointerEvent(options.e);

    if (!isDrawingPointer) {
      return;
    }

    // Remove on draw
    this._findTargetAndRemove(options);
  }

  /**
   * Handle pointer up event. Called internally by Fabric.
   * @param pointer {fabric.Position} Pointer position
   * @param options {fabric.BrushEventOptions} Brush event options
   * @returns {boolean} Use by Fabric as `_isCurrentlyDrawing` value
   */
  public onMouseUp(options: fabric.BrushEventOptions): boolean {
    const isDrawingPointer = this._isDrawingPointerEvent(options.e);

    if (!isDrawingPointer) {
      return this.canvas._isCurrentlyDrawing;
    }

    return false; // Used as `_isCurrentlyDrawing` value
  }

  /**
   * Not required by EraserBrush but called internally by Fabric
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public _setBrushStyles(): void {}

  /**
   * Not required by EraserBrush but called internally by Fabric
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public _render(): void {}

  /**
   * Find an InkPath target and remove it.
   * @private
   * @param options {fabric.BrushEventOptions}
   */
  private _findTargetAndRemove(options: fabric.BrushEventOptions): void {
    const { e } = options;
    const skipGroups = true;
    const existingTargetFindTolerance = this.canvas.targetFindTolerance;
    this.canvas.targetFindTolerance = this.strokeWidth;
    const hoveredTarget = this.canvas.findTarget(e, skipGroups);

    if (
      hoveredTarget?.type === canvasObjectIds.inkPath &&
      !hoveredTarget.__isRemoving
    ) {
      this.canvas.removeObjects([hoveredTarget]);
    }

    this.canvas.targetFindTolerance = existingTargetFindTolerance;
  }
}
