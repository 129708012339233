import React, { ReactElement } from "react";
import { Button } from "theme-ui";
import Leave from "../../../../../icons/Leave";
import CollaboardTooltip from "../../../../shared/tooltip/CollaboardTooltip";
import LeaveProjectModalTemplate from "./LeaveProjectModalTemplate";
import { useSimpleModal } from "../../../../shared/modals/useSimpleModal";

type Props = {
  projectId: string;
  disabled: boolean;
  isExpanded?: boolean;
};

function LeaveProjectButton({
  projectId,
  disabled = false,
  isExpanded = false,
}: Props): ReactElement {
  const leaveProjectModalProps = useSimpleModal();

  const onClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    leaveProjectModalProps.open();
  };

  return (
    <>
      <CollaboardTooltip
        header="tooltip.header.leaveProject"
        description="tooltip.description.leaveProjectInfo"
        placement="topLeft"
      >
        <Button
          variant={
            isExpanded ? "contentProjectListExpanded" : "contentProjectList"
          }
          id="project-leave"
          disabled={disabled}
          onClick={onClick}
        >
          <Leave noShadow />
        </Button>
      </CollaboardTooltip>

      <LeaveProjectModalTemplate
        modalProps={leaveProjectModalProps}
        projectId={projectId}
      />
    </>
  );
}

export default LeaveProjectButton;
