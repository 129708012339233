import { fetchImage } from "./files";

/**
 * Cache placeholder requests so that they can be reused for multiple objects.
 */
const placeholderCache: { [src: string]: Promise<HTMLImageElement> } = {};

export const loadPlaceholderImage = async (
  src: string
): Promise<HTMLImageElement> => {
  const requestPromise =
    placeholderCache[src] ??
    fetchImage(src, {
      bypassQueue: true,
    }).catch(() => {
      delete placeholderCache[src];
    });
  return requestPromise;
};
