import { BrushType } from "../../../../const";
import { computeBezierPoint, RawPoint } from "../utils/pathSmoothing";

import { BaseInkBrush } from "./BaseInkBrush";

export class PenBrush extends BaseInkBrush {
  /**
   * Path fitting is disabled for the PenBrush because it results in the path
   * being 'too precise' and the bezier point algorithm doesn't work as nicely.
   */
  protected APPLY_PATH_FITTING = false;

  /**
   * Configure the FastBaseBrush to clear the canvas on every render.
   * This is required because the curve-fitting algorithm needs to redraw
   * segments after sufficient points become available.
   */
  protected CLEAR_CANVAS_WHILE_DRAWING = true;

  /**
   * This brush looks better when object caching is disabled.
   */
  public ENABLE_OBJECT_CACHING = false;

  constructor(
    canvas: fabric.CollaboardCanvas,
    options?: fabric.CollaboardBrushOptions
  ) {
    super(BrushType.pen, canvas, options);
  }

  protected _partialRender(
    ctx: CanvasRenderingContext2D | null = this.canvas.contextTop,
    renderFrom = 0,
    _points?: RawPoint[]
  ): void {
    if (!ctx) {
      return;
    }

    const points: RawPoint[] = _points || this._points;

    ctx.beginPath();

    points.forEach((point, i) => {
      const previousPoint = points[i - 1];

      if (point) {
        if (!previousPoint) {
          // Start of a path.
          // Move to a slightly different place to ensure a path is drawn
          // (paths aren't drawn when the start and end point are identical)
          if (points.length === 1) {
            ctx.moveTo(point.x - this.strokeWidth / 500, point.y);
          } else {
            ctx.moveTo(point.x, point.y);
          }
        }

        // Check the availability of points
        const p2 = points[i + 1];
        const p3 = points[i + 2];

        // Bezier point algorithm requires four points to work properly
        if (previousPoint && p2 && p3) {
          // Uses Catmull-Rom to Bezier algorithm
          const { cp1x, cp1y, cp2x, cp2y, x, y } = computeBezierPoint(
            points as fabric.CollaboardPoint[],
            i
          );
          ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, x, y);

          if (this.ENABLE_DEBUG_MODE) {
            // Render the control points in debug mode
            this._renderDebugPoint(ctx, cp1x, cp1y, 50, "orange");
            this._renderDebugPoint(ctx, cp2x, cp2y, 50, "blue");
            this._renderDebugPoint(ctx, x, y, 50, "green");
          }
        } else {
          // Just draw a straight line if we don't have enough points
          ctx.lineTo(point.x, point.y);
        }
      }
    });

    ctx.stroke();
  }
}
