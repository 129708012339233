import { Form, FormikProvider, useFormik } from "formik";
import React, { ReactElement, useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button, Flex, ThemeUIStyleObject } from "theme-ui";
import { SchemaOf } from "yup";
import { changeLanguage } from "../../../i18n";
import { useUISettings } from "../../../reduxStore/uiSettings";
import { getAppName } from "../../../tools/customer";
import {
  dynamicFeatureConfig,
  isStaticFeatureActive,
  staticFeatureFlags,
} from "../../../tools/flags";
import { mergeSchemas } from "../../../validationSchemas/helpers";
import CheckboxField from "../../shared/forms/fields/CheckboxField";
import InputDropDownField from "../../shared/forms/fields/InputDropdownField";
import InputField from "../../shared/forms/fields/InputField";
import PasswordField from "../../shared/forms/fields/PasswordField";
import PhoneInputField from "../../shared/forms/fields/PhoneInputField";
import ProcessingButton from "../../shared/forms/ProcessingButton";
import {
  ProfileFormValues,
  RegistrationFormValues,
  useAuthentication,
} from "../useAuthentication";

type Props<T extends ProfileFormValues> = {
  initialValues: T;
  hasInvitationData: boolean;
  onSubmit: (values: T) => Promise<void>;
  onClearInviteData?: () => void;
  isCompleteRegistration: boolean;
};

function RegistrationForm<T extends ProfileFormValues>({
  initialValues,
  hasInvitationData,
  onSubmit,
  onClearInviteData,
  isCompleteRegistration,
}: Props<T>): ReactElement {
  const { t } = useTranslation();
  const { getDynamicFeatureConfig } = useUISettings();
  const {
    profileSchema,
    credentialsSchema,
    languageOptions,
  } = useAuthentication();

  // This is inside the component so it can be changed within tests
  const isToSDisabled = isStaticFeatureActive(
    staticFeatureFlags.DISABLE_REGISTRATION_TOS
  );

  const enableReceiveNews = !isStaticFeatureActive(
    staticFeatureFlags.DISABLE_REGISTRATION_RECEIVE_NEWS
  );

  const disableEditableName =
    (isCompleteRegistration || hasInvitationData) &&
    !!getDynamicFeatureConfig<boolean>(
      dynamicFeatureConfig.DISABLE_REGISTRATION_NAME
    );

  /**
   * The user will only be sent to the complete registration page if the server
   * has returned an error code indicating that they haven't accepted the ToS.
   *
   * Therefore we override the global setting and show the ToS UI in this case.
   *
   * However, if `TermsOfServiceAccepted` is actually already true (despite
   * being redirected to this page) we hide the checkbox to prevent confusion.
   */
  const enableToS =
    !initialValues.TermsOfServiceAccepted &&
    (!isToSDisabled || isCompleteRegistration);

  /** @TODO type this better */
  const validationSchema = useMemo(() => {
    return isCompleteRegistration
      ? profileSchema
      : (mergeSchemas(
          credentialsSchema,
          profileSchema
        ) as SchemaOf<RegistrationFormValues>);
  }, [credentialsSchema, isCompleteRegistration, profileSchema]);

  const formik = useFormik({
    initialValues,
    onSubmit,
    // Ensure that clearing the invite data updates the form values
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
  });

  const {
    errors,
    isSubmitting,
    isValid,
    setFieldValue,
    values,
    setFieldTouched,
    handleSubmit,
  } = formik;

  /**
   * Display a disabled field in case the form is pre-filled with data
   * but the UserName is missing. In this case the user will be shown
   * a validation message so we need the field in order for it to make
   * sense.
   */
  const isDisabled = !isValid || isSubmitting;
  const lockUserName =
    hasInvitationData || isCompleteRegistration || disableEditableName;

  /**
   * FirstName and LastName can be populated by the authentication provider and
   * the values supplied may not be valid according to our validation rules.
   *
   * Therefore we have to check to ensure they are valid and if not we set the
   * fields as touched so that the validation message appears. Otherwise the
   * user will have a disabled submit button but no validation messages
   * explaining what the problem is.
   */
  const invalidFirstName = initialValues.FirstName && errors.FirstName;
  const invalidLastName = initialValues.LastName && errors.LastName;

  // When the username is locked we need to display the message if there is no value
  const invalidUserName = lockUserName && errors.UserName;

  useEffect(() => {
    invalidUserName && setFieldTouched("UserName", true);
    invalidFirstName && setFieldTouched("FirstName", true);
    invalidLastName && setFieldTouched("LastName", true);
  }, [invalidFirstName, invalidLastName, invalidUserName, setFieldTouched]);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        <Flex as="fieldset">
          <InputField
            required
            name="UserName"
            autoComplete="username email"
            // Disable the field in case it's not editable, but still show the input and the validation message
            disabled={lockUserName}
          />

          {!isCompleteRegistration && (
            <PasswordField
              sx={passwordFieldStyle}
              name="Password"
              autoComplete="new-password"
            />
          )}

          {/* Disable the field in case it's not editable, but still show the input and the validation message */}
          <InputField
            required
            name="FirstName"
            autoComplete="given-name"
            disabled={disableEditableName}
          />
          <InputField
            required
            name="LastName"
            autoComplete="family-name"
            disabled={disableEditableName}
          />

          <InputField name="CompanyName" autoComplete="organization" />
          <InputDropDownField
            name="Language"
            options={languageOptions}
            onHandleChange={(value) => {
              changeLanguage(value);
              setFieldValue("Language", value);
            }}
          />
          <PhoneInputField name="PhoneNumber" defaultCountry={values.Country} />
          {enableToS && (
            <CheckboxField
              required
              name="TermsOfServiceAccepted"
              sx={{ mt: 2 }}
            >
              <Trans
                i18nKey="form.label.TermsOfServiceAccepted"
                values={{ appName: getAppName() }}
              >
                {/* eslint-disable jsx-a11y/anchor-has-content */}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.collaboard.app/terms"
                />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.collaboard.app/privacy"
                />
              </Trans>
            </CheckboxField>
          )}
          {enableReceiveNews && <CheckboxField name="ReceiveNews" />}
        </Flex>
        <Flex sx={teamsInviteStyle}>
          {hasInvitationData && onClearInviteData && (
            <Button sx={clearInviteStyle} onClick={onClearInviteData}>
              {t("form.button.clearInviteData")}
            </Button>
          )}
          <ProcessingButton
            isProcessing={isSubmitting}
            type="submit"
            disabled={isDisabled}
            sx={processingButtonStyle}
          >
            {t("form.button.register")}
          </ProcessingButton>
        </Flex>
      </Form>
    </FormikProvider>
  );
}

export default RegistrationForm;

const teamsInviteStyle: ThemeUIStyleObject = {
  alignItems: "center",
  flex: "1 0 100%",
  flexWrap: "wrap",
  justifyContent: "flex-end",
  my: [3],
};

const processingButtonStyle: ThemeUIStyleObject = {
  width: "40%",
  flex: "unset",
};

const passwordFieldStyle: ThemeUIStyleObject = {
  display: "block",
};

const clearInviteStyle: ThemeUIStyleObject = {
  mr: [2],
};
