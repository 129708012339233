import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import {
  DropzoneInputProps,
  DropzoneRootProps,
  FileRejection,
  useDropzone,
} from "react-dropzone";

import { errorToast } from "../../../../../../tools/errorToast";
import { acceptedUserImportSpreadsheets } from "../../../../../../const";
import { spreadsheetToJSON } from "../../../../../../tools/spreadsheetProcessor";

type UseSpreadsheetUploadReturn = {
  inputProps: DropzoneInputProps;
  rootProps: DropzoneRootProps;
  spreadsheetData: IndexedSpreadsheetData;
  fileName: string | null;
  isDragActive: boolean;
  isProcessing: boolean;
};

export const useSpreadsheetUpload = (): UseSpreadsheetUploadReturn => {
  const { t } = useTranslation();
  const [
    spreadsheetData,
    setSpreadsheetData,
  ] = useState<IndexedSpreadsheetData>(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [fileName, setFileName] = useState<string | null>(null);

  const onDropAccepted = useCallback(async (acceptedFiles: File[]) => {
    const [file] = acceptedFiles;
    setIsProcessing(true);
    setSpreadsheetData(await spreadsheetToJSON(file));
    setIsProcessing(false);
    setFileName(file.name);
  }, []);

  const onDropRejected = (rejections: FileRejection[]) => {
    errorToast(
      t(
        `team.batchImport.dropFailedReason.${
          rejections[0].errors?.[0]?.code ?? "unknown"
        }`
      )
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: acceptedUserImportSpreadsheets,
    multiple: false,
    onDropAccepted,
    onDropRejected,
  });

  return {
    inputProps: getInputProps(),
    rootProps: getRootProps(),
    spreadsheetData,
    fileName,
    isDragActive,
    isProcessing,
  };
};
