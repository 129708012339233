import { useEffect } from "react";
import { queryCache } from "react-query";
import { useProjectsMemo } from "./useProjectsMemo";
import {
  useFilteredParticipatingProjects,
  useParticipatingProjects,
} from "../../../api/useRequest";
import { searchModes } from "./projectList/searchModes";
import useProjectUrlParams from "./useProjectUrlParams";
import { ProjectsContextState } from "./Projects";
import { ApiProjectsOrderParameter } from "../../../types/enum";

export const useProjects = (): ProjectsContextState => {
  const { params } = useProjectUrlParams();
  const { searchMode, query, orderBy, ascending, tag, spaceId } = params;

  const filterQuery = [
    {
      key: "Description",
      value: query,
      type: 4,
    },
    {
      key: "Tag",
      value: tag,
      type: 1,
    },
  ];

  const orderQuery = {
    property: orderBy,
    ascending,
  };

  const isDateSearchMode = searchMode === searchModes.date;
  const isDefaultOrder = orderBy === ApiProjectsOrderParameter.lastAccess;
  const areFiltersActive = !!(query || tag || !isDefaultOrder);

  const all = useParticipatingProjects({
    enabled: isDateSearchMode && !areFiltersActive,
    spaceId,
  });

  const filtered = useFilteredParticipatingProjects({
    filterQuery,
    orderQuery,
    enabled: isDateSearchMode && areFiltersActive,
    spaceId,
  });

  const ownedFiltered = useFilteredParticipatingProjects({
    filterQuery,
    orderQuery,
    permission: 255,
    enabled: searchMode === searchModes.owned,
    spaceId,
  });

  useEffect(() => {
    const action = {
      [searchModes.date]: () => {
        query && filtered.refetch();
      },
      [searchModes.owned]: () => {
        query && ownedFiltered.refetch();
      },
    }[searchMode];

    action && action();
    // MdB Not sure if disabling this is correct but the code seems to be working
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, searchMode]);

  const source = areFiltersActive ? filtered : all;
  const { shownProjects, isSwitchingQuery } = useProjectsMemo(source);
  const allSources = [all, filtered, ownedFiltered];

  const removeProjectFromList = (projectId: string) =>
    allSources.forEach((s) => s.removeFromList(projectId));

  const updateProject = (project: Project) =>
    allSources.forEach((s) => s.updateList(project));

  const updateSpace = (projectId: string, spaceId: number | null) =>
    allSources.forEach((s) => s.updateSpace(projectId, spaceId));

  const updateTags = (projectId: string, newTags: string[]) =>
    allSources.forEach((s) => s.updateTags(projectId, newTags));

  const allProps = {
    refreshProjects: () => {
      all.refetch();
      query && filtered.refetch();
      queryCache.invalidateQueries("GetLicenseInfo");
    },
    Projects: all.results,
    projectListError: all.error,
    isProjectListLoading: all.isFetching && !all.isFetchingMore,
    hasMore: all.canFetchMore,
    loadMoreProjects: all.fetchMore,
    updateProject,
    updateSpace,
    updateTags,
    removeProjectFromList,
    query,
  };

  const filteredProps = {
    refreshProjects: () => {
      all.refetch();
      query && filtered.refetch();
      queryCache.invalidateQueries("GetLicenseInfo");
    },
    updateProject,
    updateSpace,
    updateTags,
    removeProjectFromList,
    Projects: shownProjects,
    projectListError: source.error,
    isProjectListLoading: isSwitchingQuery || source.isLoading,
    hasMore: source.canFetchMore,
    loadMoreProjects: source.fetchMore,
    query,
  };

  const ownedFilteredProps = {
    refreshProjects: () => {
      all.refetch();
      ownedFiltered.refetch();
      queryCache.invalidateQueries("GetLicenseInfo");
    },
    Projects: ownedFiltered.results,
    projectListError: ownedFiltered.error,
    isProjectListLoading: ownedFiltered.isLoading,
    hasMore:
      ownedFiltered.canFetchMore &&
      !ownedFiltered.isFetching &&
      !ownedFiltered.isFetchingMore,
    loadMoreProjects: ownedFiltered.fetchMore,
    updateProject,
    updateSpace,
    updateTags,
    removeProjectFromList,
    query,
  };

  const current = {
    [searchModes.date]: filteredProps,
    [searchModes.user]: allProps,
    [searchModes.owned]: ownedFilteredProps,
  }[params.searchMode];

  return {
    current,
    all: allProps,
    filtered: filteredProps,
    owned: ownedFilteredProps,
  };
};
