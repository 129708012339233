import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Ghost(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M38,46.52v-28A18.6,18.6,0,0,0,19.33,0h-.65A18.6,18.6,0,0,0,0,18.49v28a.88.88,0,0,0,0,.17,0,0,0,0,0,0,0A1.32,1.32,0,0,0,0,47a1,1,0,0,0,2,0,5.11,5.11,0,0,1,5-5.21A5.11,5.11,0,0,1,12,47a1,1,0,0,0,2,0,5.11,5.11,0,0,1,5-5.21A5.11,5.11,0,0,1,24,47a1,1,0,0,0,2,0,5,5,0,1,1,10,0,1,1,0,0,0,2,0,1.32,1.32,0,0,0,0-.28,0,0,0,0,0,0,0A.47.47,0,0,0,38,46.52ZM11.36,24.27c-2.53,0-4.58-2.53-4.58-5.63S8.83,13,11.36,13s4.57,2.52,4.57,5.63S13.88,24.27,11.36,24.27Zm15.29,0c-2.52,0-4.57-2.53-4.57-5.63S24.13,13,26.65,13s4.57,2.52,4.57,5.63S29.17,24.27,26.65,24.27Z" />
    </Icon>
  );
}
export default Ghost;
