import { Interpolation } from "@emotion/serialize";
import {
  isPresentationViewerClass,
  popupWindowClass,
  preventCanvasScrollClass,
  preventModalScrollClass,
  uiSizeMultiplier,
} from "../const";
import { DefaultTheme } from "../themes/default";
import { uiSizeStorage } from "../tools/localStorageStores";
import { UISizes } from "../types/enum";

export default (theme: DefaultTheme): Interpolation => {
  const colorVariables = Object.entries(theme.colors).reduce<
    Record<string, string>
  >((result, [name, color]) => {
    result["--" + name] = color;
    return result;
  }, {});
  return {
    ":root": {
      "--uiScale": uiSizeMultiplier[uiSizeStorage.get() ?? UISizes.Default],
      ...colorVariables,
    },
    "*": {
      fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif`,
      WebkitFontSmoothing: "antialiased",
      MozOsxFontSmoothing: "grayscale",
      "&:before": {
        boxSizing: "border-box",
      },
      "&:after": {
        boxSizing: "border-box",
      },
    },
    a: {
      color: theme.colors.text,
      ":active": {
        color: theme.colors.text,
      },
      ":visited": {
        color: theme.colors.text,
      },
    },
    button: {
      outline: "none",
      "&:active:hover:focus": {
        outline: "none",
      },
      "&:hover:focus": {
        outline: "none",
      },
      "&:active": {
        outline: "none",
      },
      "&:visited": {
        outline: "none",
      },
    },
    form: {
      display: "flex",
      flexWrap: "wrap",
      flex: "1 0 100%",
      marginBottom: theme.space[4],
    },
    fieldset: {
      padding: 0,
      border: "none",
      flex: "1 0 100%",
      flexWrap: "wrap",
    },
    legend: {
      fontSize: theme.fontSizes[7],
      paddingBottom: theme.space[4],
      width: ["100%"],
    },
    [`.${preventCanvasScrollClass}`]: {
      overflow: "hidden !important",
      position: "relative",
    },
    [`.${preventModalScrollClass}`]: {
      overflow: "hidden !important",
    },
    // Avoid page flickering due to hidden scrollbar in common pages
    [`.${preventModalScrollClass}:not(.${preventCanvasScrollClass})`]: {
      paddingRight: "16px",
    },
    ".prevent-user-select": {
      userSelect: "none",
      "*:not(input)": {
        userSelect: "none",
      },
    },
    [`body.${popupWindowClass}`]: {
      minWidth: 0,
    },
    [`body.${isPresentationViewerClass} video::-webkit-media-controls`]: {
      display: "none !important",
    },
    ".qr-scanner": {
      height: "auto",
      width: "480px",
      maxWidth: "100%",
      backgroundColor: "#000",
      marginBottom: "0.5em",
      ">video": {
        maxWidth: "100%",
        maxHeight: "300px",
        margin: "0 auto",
      },
    },
    ".overlay-container": {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "> .video-container": {
        width: "100%",
        maxWidth: "800px",
        "> .video-iframe-wrapper": {
          position: "relative",
          paddingBottom: "60%",
          clear: "both",
          "> iframe": {
            position: "absolute",
            width: "100%",
            height: "100%",
          },
        },
      },
    },
  };
};
