import { merge } from "theme-ui";
import { runtimeConfig } from "../tools/runtimeConfig";
import defaultTheme, { DefaultTheme } from "./default";

// TODO: #6214 - Remove this and move the configuration to server

const bkkgsTheme = merge(defaultTheme, {
  name: "bkkgs",
  colors: {
    ...runtimeConfig.styles?.colors,
  },
}) as DefaultTheme;

export default bkkgsTheme;
