import {
  BoardModalType,
  CanvasLeftSidePanel,
  CanvasMode,
  CanvasRightSidePanel,
  CanvasTool,
  defaultCanvasMode,
} from "./app.reducer";

export enum CanvasAppActionType {
  CLOSE_MODAL = "CLOSE_MODAL",
  OPEN_MODAL = "OPEN_MODAL",
  SET_ACTIVE_CONTEXT_OPTION_POPUP = "SET_ACTIVE_CONTEXT_OPTION_POPUP",
  SET_ACTIVE_TOOL = "SET_ACTIVE_TOOL",
  SET_CANVAS_MODE = "SET_CANVAS_MODE",
  SET_IS_TRANSFORMING = "SET_IS_TRANSFORMING",
  SET_LEFT_SIDE_PANEL = "SET_LEFT_SIDE_PANEL",
  SET_RIGHT_SIDE_PANEL = "SET_RIGHT_SIDE_PANEL",
  TOGGLE_LEFT_SIDE_PANEL = "TOGGLE_LEFT_SIDE_PANEL",
}

export const setActiveContextOptionPopupAction = (payload: {
  contextOptionPopup: string | undefined;
}) =>
  ({
    type: CanvasAppActionType.SET_ACTIVE_CONTEXT_OPTION_POPUP,
    payload,
  } as const);

export const resetActiveContextOptionPopupAction = (): SetActiveContextOptionPopupAction =>
  setActiveContextOptionPopupAction({ contextOptionPopup: undefined });

export const setActiveToolAction = (payload: {
  tool: CanvasTool | undefined;
}) =>
  ({
    type: CanvasAppActionType.SET_ACTIVE_TOOL,
    payload,
  } as const);

export const resetActiveToolAction = (): SetActiveToolAction =>
  setActiveToolAction({ tool: undefined });

export const openModalAction = (payload: { modal: BoardModalType }) =>
  ({
    type: CanvasAppActionType.OPEN_MODAL,
    payload,
  } as const);

export const closeModalAction = () =>
  ({
    type: CanvasAppActionType.CLOSE_MODAL,
  } as const);

export const setIsTransformingAction = (payload: { isTransforming: boolean }) =>
  ({
    type: CanvasAppActionType.SET_IS_TRANSFORMING,
    payload,
  } as const);

export const setLeftSidePanelAction = (payload: {
  sidePanel: CanvasLeftSidePanel | undefined;
}) =>
  ({
    type: CanvasAppActionType.SET_LEFT_SIDE_PANEL,
    payload,
  } as const);

export const toggleLeftSidePanelAction = (payload: {
  sidePanel: CanvasLeftSidePanel;
}) =>
  ({
    type: CanvasAppActionType.TOGGLE_LEFT_SIDE_PANEL,
    payload,
  } as const);

export const setRightSidePanelAction = (payload: {
  sidePanel: CanvasRightSidePanel | undefined;
}) =>
  ({
    type: CanvasAppActionType.SET_RIGHT_SIDE_PANEL,
    payload,
  } as const);

type SetCanvasModePayload = {
  canvasMode: Exclude<CanvasMode, CanvasMode.SELECT_OBJECT>;
};

type SelectObjectModePayload = {
  canvasMode: CanvasMode.SELECT_OBJECT;
  sourceTileId: string;
};

export const setCanvasModeAction = (
  payload: SetCanvasModePayload | SelectObjectModePayload
) =>
  ({
    type: CanvasAppActionType.SET_CANVAS_MODE,
    payload,
  } as const);

export const resetCanvasModeAction = (): SetCanvasModeAction =>
  setCanvasModeAction({ canvasMode: defaultCanvasMode });

type SetActiveContextOptionPopupAction = ReturnType<
  typeof setActiveContextOptionPopupAction
>;
type SetActiveToolAction = ReturnType<typeof setActiveToolAction>;
export type SetCanvasModeAction = ReturnType<typeof setCanvasModeAction>;

export type CanvasAppAction =
  | SetActiveContextOptionPopupAction
  | SetActiveToolAction
  | SetCanvasModeAction
  | ReturnType<typeof closeModalAction>
  | ReturnType<typeof openModalAction>
  | ReturnType<typeof setIsTransformingAction>
  | ReturnType<typeof setLeftSidePanelAction>
  | ReturnType<typeof setRightSidePanelAction>
  | ReturnType<typeof toggleLeftSidePanelAction>;
