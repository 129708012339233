import { Selector } from "react-redux";
import { createSelector } from "reselect";
import { canvasObjectIds, SelectionMode } from "../../../const";
import { createContextSelector } from "../../redux.utils";
import {
  ObjectsCRUDAction,
  ObjectsCRUDActionType,
} from "../objects/crud/crud.actions";
import { SelectionAction, SelectionActionType } from "./selection.actions";

export type SelectionState = {
  method: SelectionMode;
  // Can't put the whole fabric.Object in the state as it's a full class instance and would cause issues
  // like Redux devtools, serialization or type inference (e.g. DeepPartial)
  selectedObject:
    | {
        type: string;
        uuid: string | undefined;
      }
    | undefined;
  selectLocked: boolean;
};

const getSelectionInitialState = (): SelectionState => {
  return {
    method: SelectionMode.standard,
    selectLocked: false,
    selectedObject: undefined,
  };
};

export function selectionReducer(
  state = getSelectionInitialState(),
  action: SelectionAction | ObjectsCRUDAction
): SelectionState {
  switch (action.type) {
    case ObjectsCRUDActionType.REMOVE_SELECTED_OBJECT: {
      return {
        ...state,
        selectedObject: undefined,
      };
    }
    case ObjectsCRUDActionType.REMOVE_OBJECT_BY_UUID: {
      if (action.payload.uuid !== state.selectedObject?.uuid) {
        return state;
      }

      return {
        ...state,
        selectedObject: undefined,
      };
    }
    case SelectionActionType.SELECT_FABRIC_OBJECT: {
      return {
        ...state,
        selectedObject: action.payload
          ? {
              type: action.payload.type,
              uuid: action.payload.uuid,
            }
          : undefined,
      };
    }
    case SelectionActionType.SET_SELECTION_MODE: {
      return {
        ...state,
        method: action.payload,
      };
    }
    case SelectionActionType.TOGGLE_SELECT_LOCKED: {
      return {
        ...state,
        selectLocked: action.payload,
      };
    }
    default:
      return state;
  }
}

export const selectSelectionMode = (
  state: ApplicationGlobalState
): SelectionMode => {
  return state.canvas.selection.method;
};

export const selectSelectedObject: Selector<
  ApplicationGlobalState,
  fabric.Object | undefined
> = createContextSelector((context) =>
  createSelector(
    (state: ApplicationGlobalState) => state.canvas.selection.selectedObject,
    (selectedObject) => {
      const canvas = context.getContext("canvas");

      return canvas && selectedObject
        ? selectedObject.type === canvasObjectIds.activeSelection
          ? canvas.getActiveObject()
          : selectedObject.uuid
          ? canvas.getObjectByUUID(selectedObject.uuid)
          : undefined
        : undefined;
    }
  )
);

export const selectLockedSelection = (
  state: ApplicationGlobalState
): boolean => {
  return state.canvas.selection.selectLocked;
};
