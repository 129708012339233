export const searchModes = {
  date: "date",
  user: "user",
  owned: "own",
};

export const searchModeTabs = [
  { value: searchModes.date, translationKey: "form.button.byDate", flex: 2 },
  { value: searchModes.user, translationKey: "form.button.byUser", flex: 2 },
  { value: searchModes.owned, translationKey: "form.button.owned", flex: 3 },
];
