import { all, Effect, getContext, takeEvery } from "redux-saga/effects";
import { getParentGroup } from "../../../../studio/components/group/group.utils";
import {
  hasInnerObjects,
  isActiveSelection,
  isCanvas,
} from "../../../../studio/utils/fabricObjects";
import {
  EnableObjectStackingAction,
  LayersActionType,
  MoveLayerAction,
} from "./layers.actions";

function* moveLayer({ payload: direction }: MoveLayerAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  const target = canvas.getActiveObject();

  if (!target) {
    return;
  }

  const allObjects = hasInnerObjects(target) ? target.getObjects() : [];

  if (isActiveSelection(target)) {
    target._objects = target.getObjects().filter((o) => !o.isLocked());
  }

  const parentGroup = getParentGroup(target);
  const parent = parentGroup ? parentGroup : canvas;

  canvas.preserveObjectStacking = true;

  /**
   * Enabling `isIntersecting` for `bringForward` / `sendBackwards` means that
   * Fabric will move an object in front or behind an intersecting object in
   * a single operation even if there are actually many zIndexes in between.
   *
   * This is more inline with what a user will expect.
   */
  const isIntersecting = true;

  switch (direction) {
    case "up":
      target.bringForward(isIntersecting);
      break;
    case "down":
      target.sendBackwards(isIntersecting);
      break;
    case "front":
      target.bringToFront();
      break;
    case "back":
      target.sendToBack();
      break;
    default:
      break;
  }

  if (parentGroup && !isCanvas(parent) && parent?.__stashedObjects) {
    canvas.reassignZIndexes(parent.__stashedObjects, {
      reversible: true,
      targetType: target.type,
      isSubselect: true,
    });
  } else {
    canvas.reassignRootZIndexes({
      reversible: true,
      targetType: target.type,
    });
  }

  if (hasInnerObjects(target)) {
    target._objects = allObjects;
  }

  canvas.requestRenderAll();
}

function* enableObjectStacking({
  payload: isEnabled,
}: EnableObjectStackingAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  canvas.preserveObjectStacking = isEnabled;
  canvas.requestRenderAll();
}

export function* layersSaga(): Generator<Effect> {
  yield all([
    takeEvery(LayersActionType.MOVE_LAYER, moveLayer),
    takeEvery(LayersActionType.TOGGLE_OBJECT_STACKING, enableObjectStacking),
  ]);
}
