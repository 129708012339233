export enum LockActionType {
  TOGGLE_ITEM_LOCK = "TOGGLE_ITEM_LOCK",
  UNLOCK_ALL = "UNLOCK_ALL",
}

export const toggleItemLockAction = () =>
  ({
    type: LockActionType.TOGGLE_ITEM_LOCK,
    payload: undefined,
  } as const);

export const unlockAllAction = () =>
  ({
    type: LockActionType.UNLOCK_ALL,
    payload: undefined,
  } as const);

export type LockAction =
  | ReturnType<typeof toggleItemLockAction>
  | ReturnType<typeof unlockAllAction>;
