import SimplePeer, { SignalData } from "simple-peer";

export type ConnectingWebRTCMessage = {
  connectingIds: string[];
};

export type MouseMovedWebRTCMessage = {
  pointers: CapturedPointer[];
};

export type PresenceWebRTCMessage = {
  UserName: string;
};

export type WebRTCMessagePayload =
  | ConnectingWebRTCMessage
  | MouseMovedWebRTCMessage
  | PresenceWebRTCMessage;

export enum WebRTCEvent {
  Connected = "Connected",
  Connecting = "Connecting",
  Disconnected = "Disconnected",
  Signal = "Signal",
  SendPresenceRequest = "SendPresenceRequest",
  RevokePresenceRequest = "RevokePresenceRequest",
  MouseMoved = "MouseMoved",
}

export type WebRTCEventToPayload = {
  [WebRTCEvent.Connected]: PresenceWebRTCMessage;
  [WebRTCEvent.Connecting]: ConnectingWebRTCMessage;
  [WebRTCEvent.Disconnected]: PresenceWebRTCMessage;
  [WebRTCEvent.Signal]: SignalData;
  [WebRTCEvent.SendPresenceRequest]: void;
  [WebRTCEvent.RevokePresenceRequest]: void;
  [WebRTCEvent.MouseMoved]: MouseMovedWebRTCMessage;
};

export type WebRTCDataMessage<T extends WebRTCEvent> = {
  type: T;
  payload: WebRTCEventToPayload[T];
};

export type WebRTCMessageHandler<T extends WebRTCEvent> = (
  message: WebRTCEventToPayload[T],
  connectionId: string,
  UserName?: string
) => void;

export type WebRTCMessageHandlerMap = {
  [MessageType in WebRTCEvent]: WebRTCMessageHandler<MessageType>;
};

// Add useful (private) properties missing from SimplePeer types
export type WebRTCConnection = SimplePeer.Instance & {
  _channel?: RTCDataChannel;
  _channelReady: boolean;
};
