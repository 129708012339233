import i18n from "i18next";
import { all, Effect, getContext, put, takeEvery } from "redux-saga/effects";
import { v4 as uuid } from "uuid";
import type { TileStatus } from "../../../../api/signalR/message.types";
import { SignalRError } from "../../../../errors/signalRError";
import { selectObjects } from "../../../../studio/components/patches/extends-selection/selection.utils";
import {
  flattenObjectTree,
  hasInnerObjects,
  isActiveSelection,
  isCollection,
  isGroup,
  isStack,
} from "../../../../studio/utils/fabricObjects";
import {
  serializeObjects,
  toTileStatus,
} from "../../../../studio/utils/objectConverter";
import collaboard from "../../../../tools/collaboard";
import { errorToast } from "../../../../tools/errorToast";
import { LogCategory, onErrorToLog } from "../../../../tools/telemetry";
import { splitBy } from "../../../../tools/utils";
import {
  groupAction,
  stackAction,
  ungroupAction,
  ungroupAllAction,
  unstackAction,
} from "../../history/history.entry.actions";
import { GroupingActionType } from "./grouping.actions";

const validate = (
  activeObject: fabric.Object,
  type: "group" | "stack" | "ungroup"
) => {
  // Forbid stack/group/ungroup when only one top-level object is selected (possible due to shortcuts) #3771
  if (!hasInnerObjects(activeObject)) {
    return false;
  }

  const objects = activeObject.getObjects();

  if (type === "group" && objects.some((object) => object.isLocked())) {
    errorToast(i18n.t("clientError.forbidGroupingWithLocked"));
    return false;
  }

  if (type === "stack" && objects.some((object) => object.isLocked())) {
    errorToast(i18n.t("clientError.forbidStackingWithLocked"));
    return false;
  }

  // ban creating stack from group or nested collection #3097
  if (
    type === "stack" &&
    (isGroup(activeObject) || objects.some(isCollection))
  ) {
    errorToast(i18n.t("clientError.forbidStackingWithCollection"));
    return false;
  }

  // ban creating group from stacks #3097
  if (type === "group" && (isStack(activeObject) || objects.some(isStack))) {
    errorToast(i18n.t("clientError.forbidGroupingWithStack"));
    return false;
  }

  const flatSelection = flattenObjectTree(objects);
  const [allGroups, otherObjects] = splitBy(flatSelection, isGroup);
  const exceeding = collaboard.signalRClient.calculateTileBatchActionOverflow({
    groupedTiles: otherObjects.length,
    deletedTiles: allGroups.length,
  });

  if (exceeding > 0) {
    errorToast(
      i18n.t("clientError.maxBatch", {
        max: flatSelection.length - exceeding - 1,
        selected: flatSelection.length,
      })
    );
    onErrorToLog(
      new SignalRError("Exceeded the max group/stack size limit"),
      LogCategory.signalR,
      {
        subcategory: "post-tile-batch",
        type,
        max: flatSelection.length - exceeding - 1,
        selected: flatSelection.length,
      }
    );
    return false;
  }

  return true;
};

function* toggle(type: "group" | "stack") {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const activeObject = canvas.getActiveObject();
  if (!activeObject) {
    return;
  }

  if (!validate(activeObject, type)) {
    return;
  }

  if (isCollection(activeObject)) {
    yield put(
      activeObject instanceof fabric.Stack
        ? unstackAction(activeObject)
        : ungroupAction({ groupBeforeUngroup: activeObject })
    );
    const activeSelection = activeObject.toActiveSelection();

    canvas.setActiveObject(activeSelection, { isSilent: true });
    canvas.requestRenderAll();
    return;
  }

  if (!isActiveSelection(activeObject)) {
    return;
  }

  const groupedObjs = activeObject.getObjects().filter((o) => !o.isLocked());
  const [prevGroupObjects, prevObjects] = splitBy(groupedObjs, isCollection);

  // Serialize objects before any transform or zIndex mutation after grouping
  const topLevelObjectsBeforeGroup = prevObjects.map((o) =>
    o.decompose(toTileStatus)
  );
  const groupsBeforeGroup = serializeObjects(prevGroupObjects);
  const childrenByGroupBeforeGroup = prevGroupObjects.reduce<
    Record<string, TileStatus[] | undefined>
  >((acc, g) => {
    acc[g.uuid] = g.getObjects().map(toTileStatus);
    return acc;
  }, {});

  // Discard the current selection and reselect only unlocked objects later
  canvas.discardActiveObject({ isSilent: true });

  if (groupedObjs.length === 1) {
    // only one object left in active selection so instead
    // of creating a group/stack just select it
    const object = groupedObjs[0];

    canvas.setActiveObject(object);
    canvas.requestRenderAll();
    return;
  }

  selectObjects(canvas, groupedObjs);

  if (type === "group") {
    const group = activeObject.toGroup({ uuid: uuid() });

    canvas.insertAt(group, group.zIndex);
    canvas.setActiveObject(group, { isSilent: true }); // Automatically reserved in signalrMiddleware

    yield put(
      groupAction({
        addedGroup: group,
        topLevelObjectsBeforeGroup,
        groupsBeforeGroup,
        childrenByGroupBeforeGroup,
      })
    );
  } else {
    const stack = activeObject.toStack({ uuid: uuid() });
    canvas.insertAt(stack, stack.zIndex);
    canvas.setActiveObject(stack, { isSilent: true }); // Automatically reserved in signalrMiddleware

    yield put(stackAction(stack));
  }

  canvas.requestRenderAll();

  // Final sortAndReassignRootZIndexes is called in signalrMiddleware
}

function* toggleUngroupAll() {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  const activeObject = canvas.getActiveObject();
  if (!activeObject || !validate(activeObject, "ungroup")) {
    return;
  }

  const ungroupedObjs = canvas.getActiveObjects().filter((o) => !o.isLocked());

  const flatSelection = flattenObjectTree(ungroupedObjs);
  const [allGroups, otherObjects] = splitBy(flatSelection, isGroup);
  const childrenBeforeUngroup = allGroups.flatMap((g) => g.getObjects());
  const topLevelObjectsBeforeUngroup = otherObjects.filter(
    (o) => !childrenBeforeUngroup.includes(o)
  );

  // Ensure the user has nothing selected because it affects positions and groups
  canvas.discardActiveObject({ isSilent: true });

  yield put(
    ungroupAllAction({
      groupsBeforeUngroup: allGroups,
      childrenBeforeUngroup,
      topLevelObjectsBeforeUngroup,
    })
  );

  allGroups.forEach((g) => g.unpack());

  selectObjects(canvas, otherObjects, { isSilent: true }); // Automatically reserved in signalrMiddleware
  canvas.requestRenderAll();

  // Final sortAndReassignRootZIndexes is called in signalrMiddleware
}

function* toggleGroup() {
  yield toggle("group");
}

function* toggleStack() {
  yield toggle("stack");
}

export function* groupingSaga(): Generator<Effect> {
  yield all([
    takeEvery(GroupingActionType.TOGGLE_GROUP, toggleGroup),
    takeEvery(GroupingActionType.TOGGLE_STACK, toggleStack),
    takeEvery(GroupingActionType.UNGROUP_ALL, toggleUngroupAll),
  ]);
}
