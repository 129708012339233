import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Button, Flex } from "theme-ui";
import { FormikValues } from "formik";

import CollaboardTooltip from "../../shared/tooltip/CollaboardTooltip";
import ProcessingButton from "../../shared/forms/ProcessingButton";
import { useHistory } from "react-router-dom";
import { routes } from "../../../const";

type SubmitButtonProps = {
  isValidForImport: (values: FormikValues) => boolean;
  values: FormikValues;
  isSubmitting: boolean;
};

function ActionButtons({
  isValidForImport,
  values,
  isSubmitting,
}: SubmitButtonProps): ReactElement {
  const { t } = useTranslation();
  const history = useHistory<void>();

  return (
    <Flex
      sx={{
        justifyContent: "flex-end",
        width: "100%",
        mt: [5],
      }}
    >
      <Button
        variant="secondary"
        sx={{ flex: "unset", mr: [2], width: "300px" }}
        type="button"
        role="navigation"
        onClick={() => {
          history.push(routes.team);
        }}
      >
        {t("team.batchImport.backToTeam")}
      </Button>

      <CollaboardTooltip
        header={t("team.batchImport.emailRequiredTooltipHeader")}
        description={t("team.batchImport.emailRequiredTooltipDesc")}
        placement="topRight"
        forceHide={isValidForImport(values)}
      >
        <ProcessingButton
          sx={{ width: "300px" }}
          disabled={!isValidForImport(values)}
          type="submit"
          isProcessing={isSubmitting}
        >
          {t("team.batchImport.importButton")}
        </ProcessingButton>
      </CollaboardTooltip>
    </Flex>
  );
}
export default ActionButtons;
