import { fabric } from "fabric";

import { defaultPointerPressure } from "../../../../const";

export type RawPoint = fabric.CollaboardPoint | null;

export type BezierPoint = {
  cp1x: number;
  cp1y: number;
  cp2x: number;
  cp2y: number;
  x: number;
  y: number;
  pressure?: number;
};

/**
 * Split the points into distinct paths
 * (i.e. one unit of pointerdown + pointermove + pointerup)
 *
 * `null` is used to delimit each distinct path.
 */
export const extractDistinctPaths = (
  points: RawPoint[]
): fabric.CollaboardPoint[][] => {
  return points
    .reduce<fabric.CollaboardPoint[][]>(
      (result, point) => {
        if (point) {
          result[result.length - 1].push(point);
        } else {
          result.push([]);
        }
        return result;
      },
      [[]]
    )
    .filter((pathPoints) => {
      return !!pathPoints.length; // Ignore paths with no points
    });
};

/**
 * Catmull-Rom Spline Algorithm
 * https://gist.github.com/nicholaswmin/c2661eb11cad5671d816
 */
export const computeBezierPoint = (
  points: fabric.CollaboardPoint[],
  index: number
): BezierPoint => {
  // https://en.wikipedia.org/wiki/Centripetal_Catmull%E2%80%93Rom_spline#/media/File:Catmull-Rom_examples_with_parameters..png
  // const alpha = 0.5; // Centripetal
  const alpha = 1; // Chordal

  // The path is drawn between points p1 and p2
  const p0 = index === 0 ? points[0] : points[index - 1];
  const p1 = points[index];
  const p2 = points[index + 1];
  const p3 = points[index + 2];

  if (!p0 || !p1 || !p2 || !p3) {
    throw new Error("Missing required points for algorithm");
  }

  const d1 = Math.sqrt(Math.pow(p0.x - p1.x, 2) + Math.pow(p0.y - p1.y, 2));
  const d2 = Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
  const d3 = Math.sqrt(Math.pow(p2.x - p3.x, 2) + Math.pow(p2.y - p3.y, 2));

  // Catmull-Rom to Cubic Bezier conversion matrix

  // A = 2d1^2a + 3d1^a * d2^a + d3^2a
  // B = 2d3^2a + 3d3^a * d2^a + d2^2a

  // [   0             1            0          0          ]
  // [   -d2^2a /N     A/N          d1^2a /N   0          ]
  // [   0             d3^2a /M     B/M        -d2^2a /M  ]
  // [   0             0            1          0          ]

  const d3powA = Math.pow(d3, alpha);
  const d3pow2A = Math.pow(d3, 2 * alpha);
  const d2powA = Math.pow(d2, alpha);
  const d2pow2A = Math.pow(d2, 2 * alpha);
  const d1powA = Math.pow(d1, alpha);
  const d1pow2A = Math.pow(d1, 2 * alpha);

  const A = 2 * d1pow2A + 3 * d1powA * d2powA + d2pow2A;
  const B = 2 * d3pow2A + 3 * d3powA * d2powA + d2pow2A;
  let N = 3 * d1powA * (d1powA + d2powA);

  if (N > 0) {
    N = 1 / N;
  }

  let M = 3 * d3powA * (d3powA + d2powA);

  if (M > 0) {
    M = 1 / M;
  }

  let bp1 = {
    x: (-d2pow2A * p0.x + A * p1.x + d1pow2A * p2.x) * N,
    y: (-d2pow2A * p0.y + A * p1.y + d1pow2A * p2.y) * N,
  };

  let bp2 = {
    x: (d3pow2A * p1.x + B * p2.x - d2pow2A * p3.x) * M,
    y: (d3pow2A * p1.y + B * p2.y - d2pow2A * p3.y) * M,
  };

  if (bp1.x === 0 && bp1.y === 0) {
    bp1 = p1;
  }
  if (bp2.x === 0 && bp2.y === 0) {
    bp2 = p2;
  }

  return {
    cp1x: bp1.x,
    cp1y: bp1.y,
    cp2x: bp2.x,
    cp2y: bp2.y,
    x: p2.x,
    y: p2.y,
    pressure: p2.pressure,
  };
};

/**
 * Chaikin's algorithm for curves
 *
 * This algorithm can interpolate points, however it doesn't always feel like natural writing
 *
 * http://www.idav.ucdavis.edu/education/CAGDNotes/Chaikins-Algorithm/Chaikins-Algorithm.html
 *
 * @param points
 */
export const fitLastPointWithChaikin = (points: RawPoint[]): RawPoint[] => {
  const copiedPoints = points.slice(0); // Keep it pure
  const length = copiedPoints.length;

  if (length < 2) {
    return copiedPoints;
  }

  const ultimatePoint = copiedPoints[length - 1];
  const penultimatePoint = copiedPoints[length - 2];

  if (!ultimatePoint || !penultimatePoint) {
    return copiedPoints;
  }

  const a = 0.75;
  const b = 0.25;

  // Ensure points have pressure
  ultimatePoint.pressure = ultimatePoint.pressure ?? defaultPointerPressure;
  penultimatePoint.pressure =
    penultimatePoint.pressure ?? defaultPointerPressure;

  const pressureDifference = ultimatePoint.pressure - penultimatePoint.pressure;
  const pressureStep = pressureDifference / 3;

  const Q = new fabric.Point(
    a * penultimatePoint.x + b * ultimatePoint.x,
    a * penultimatePoint.y + b * ultimatePoint.y
  ) as fabric.CollaboardPoint;
  Q.pressure = penultimatePoint.pressure + pressureStep;

  const R = new fabric.Point(
    b * penultimatePoint.x + a * ultimatePoint.x,
    b * penultimatePoint.y + a * ultimatePoint.y
  ) as fabric.CollaboardPoint;
  R.pressure = penultimatePoint.pressure + 2 * pressureStep;

  // Remove the last array item (modifies array in place) - faster than splice
  copiedPoints.pop();
  copiedPoints.push(Q);
  copiedPoints.push(R);
  copiedPoints.push(ultimatePoint);

  return copiedPoints;
};
