import {
  TimedSessionAction,
  TimedSessionActionType,
} from "./timedSession.action";

export type TimedSessionState = {
  host: string;
  isHost: boolean;
  /** @TODO These two boolean should be refactored as status: "RUNNING" | "PAUSED" | "UNINITALIZED" */
  isPaused: boolean;
  isRunning: boolean;
  timedSessionLengthInMinutes: number;
  timedSessionPausedAt: number;
  timedSessionStart: number;
};

const getInitialTimedSessionState = (): TimedSessionState => {
  return {
    isRunning: false,
    isPaused: false,
    isHost: false,
    host: "",
    timedSessionStart: 0,
    timedSessionPausedAt: 0,
    timedSessionLengthInMinutes: 5,
  };
};

export function timedSessionReducer(
  state = getInitialTimedSessionState(),
  action: TimedSessionAction
): TimedSessionState {
  switch (action.type) {
    case TimedSessionActionType.TIMED_SESSION_STARTED: {
      const { session, isHost, startTime } = action.payload;

      return {
        isRunning: true,
        isPaused: false,
        host: session.Presenter,
        isHost,
        timedSessionStart: startTime,
        timedSessionLengthInMinutes: session.InitialDuration,
        timedSessionPausedAt: 0,
      };
    }
    case TimedSessionActionType.TIMED_SESSION_STOPPED: {
      return getInitialTimedSessionState();
    }
    case TimedSessionActionType.TIMED_SESSION_PAUSE_TOGGLED: {
      const { timedSessionStart, timedSessionPausedAt } = state;
      const {
        session: { IsPaused },
        pausedTime,
      } = action.payload;
      const pauseLength = pausedTime - timedSessionPausedAt;

      return {
        ...state,
        isPaused: IsPaused,
        timedSessionPausedAt: IsPaused ? pausedTime : 0,
        timedSessionStart: timedSessionStart + (IsPaused ? 0 : pauseLength),
      };
    }
    case TimedSessionActionType.TIMED_SESSION_DURATION_CHANGED: {
      return {
        ...state,
        timedSessionLengthInMinutes:
          state.timedSessionLengthInMinutes + action.payload.MinutesToAdd,
      };
    }
    case TimedSessionActionType.ON_JOINED_TIMED_SESSION: {
      const { session, isHost } = action.payload;
      const {
        InitialDurationTimer,
        Presenter,
        TimerPausedAt,
        TimerStartedAt,
      } = session;

      return {
        isRunning: true,
        host: Presenter,
        isHost,
        isPaused: TimerPausedAt !== 0,
        timedSessionStart: TimerStartedAt * 1000,
        timedSessionLengthInMinutes: InitialDurationTimer,
        timedSessionPausedAt: TimerPausedAt * 1000,
      };
    }
    default:
      return state;
  }
}

export const selectTimedSession = (
  state: ApplicationGlobalState
): TimedSessionState => {
  return state.canvas.timedSession;
};
