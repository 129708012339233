import { Selector } from "react-redux";
import { ActionCreator, AnyAction } from "redux";
import { buffers, EventChannel, eventChannel } from "redux-saga";
import { ActionPattern, call, select, take } from "redux-saga/effects";
import { SignalRClient } from "../api/signalR";
import { OnMessageType } from "../api/signalR/SignalRProtobufClient";
import { Collaboard } from "../tools/collaboard";

class SelectorContext {
  context: Collaboard | null = null;

  getContext<K extends keyof Collaboard>(name: K): Collaboard[K] {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.context![name];
  }

  setContext(collaboard: Collaboard) {
    this.context = collaboard;
  }
}

export const selectorContext = new SelectorContext();

/**
 * Create a selector which can have a later-instantiated `collaboard` reference without the need
 * for the selector to directly import it, thus keeping the selector pure and without the need for mocks.
 *
 * The idea is inspired from redux-saga's context.
 */
export function createContextSelector<
  T extends Selector<ApplicationGlobalState, unknown>
>(collaboardSelector: (selectorContext: SelectorContext) => T): T {
  return collaboardSelector(selectorContext);
}

/**
 * Return an EventChannel which a saga can subscribe to, just as with normal Redux actions. This acts
 * basically like a BehaviourSubject in RxJS.
 *
 * https://redux-saga.js.org/docs/advanced/Channels/#using-the-eventchannel-factory-to-connect-to-external-events
 */
export function signalRNotifyChannel<T extends AnyAction>(
  signalRClient: SignalRClient,
  eventToAction: { [key in OnMessageType]?: ActionCreator<AnyAction> }
): EventChannel<T> {
  return eventChannel((emit) => {
    const unsubscribers = Object.entries(eventToAction).map(
      ([event, actionCreator]) => {
        const messageType = event as OnMessageType;
        const unsubscribe = signalRClient.onMessage(messageType, (payload) => {
          emit(actionCreator(payload));
        });

        return unsubscribe;
      }
    );

    return () => {
      unsubscribers.forEach((unsubscribe) => {
        unsubscribe();
      });
    };
  }, buffers.expanding());
}

export type SignalRAction<T, P> = {
  type: T;
  payload: P;
};

/**
 * Monitor state changes so that another state, e.g. the canvas, can be updated accordingly.
 *
 * @TODO #5927: unfortunately sagas ran after the React re-render after the Redux state has changed.
 * This means that the canvas properties are synced after the render, thus it may use stale properties
 * which are later updated by the monitor handler.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* monitorSaga<T>(
  selector: Selector<ApplicationGlobalState, T>,
  handler: (state: T) => void | Generator,
  pattern: ActionPattern = "*"
) {
  let prevState: T | null = null;

  while (true) {
    const state = selector(yield select());

    // Run the handler at least once
    if (state !== prevState) {
      yield call(handler, state);
      prevState = state;
    }

    const action: AnyAction = yield take(pattern);

    // Reset after each test case
    if (action.type === "$_RESET_STORE_FOR_TEST_$") {
      prevState = null;
    }
  }
}
