import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Spaces(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <rect x="11.43" y="11.6" width="7.31" height="7.31" rx="0.99" />
      <rect x="20.73" y="11.6" width="7.31" height="7.31" rx="0.99" />
      <rect x="11.43" y="20.78" width="7.31" height="7.31" rx="0.99" />
      <rect x="20.73" y="20.78" width="7.31" height="7.31" rx="0.99" />
    </Icon>
  );
}
export default Spaces;
