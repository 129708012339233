import { ActionCreator } from "redux";
import { EventChannel } from "redux-saga";
import { call, cancelled, put, select, take } from "redux-saga/effects";
import type {
  AdjustPresentationTimerMessage,
  InitialDataForNewJoinToTimerMessage,
  PausePresentationTimerMessage,
  StartPresentationTimerMessage,
  StopPresentationTimerMessage,
} from "../../../api/signalR/message.types";
import { NotifyMessageName } from "../../../api/signalR/protobuf.codecs";
import { assertUnreachable } from "../../../tools/assertions";
import collaboard from "../../../tools/collaboard";
import { selectAssertedUserProfile } from "../../auth/auth.reducer";
import { SignalRAction, signalRNotifyChannel } from "../../redux.utils";
import {
  onJoinedTimedSessionAction,
  timedSessionDurationChangedAction,
  timedSessionPauseToggledAction,
  timedSessionStartedAction,
  timedSessionStoppedAction,
} from "./timedSession.action";
import { selectTimedSession } from "./timedSession.reducer";

type NotifyTimedSessionMessageType =
  | NotifyMessageName.StartPresentationTimerMessage
  | NotifyMessageName.StopPresentationTimerMessage
  | NotifyMessageName.PausePresentationTimerMessage
  | NotifyMessageName.AdjustPresentationTimerMessage
  | NotifyMessageName.InitialDataForNewJoinToTimerMessage;

type MarshalNotifyTimedSession =
  | SignalRAction<
      NotifyMessageName.StartPresentationTimerMessage,
      StartPresentationTimerMessage
    >
  | SignalRAction<
      NotifyMessageName.StopPresentationTimerMessage,
      StopPresentationTimerMessage
    >
  | SignalRAction<
      NotifyMessageName.PausePresentationTimerMessage,
      PausePresentationTimerMessage
    >
  | SignalRAction<
      NotifyMessageName.AdjustPresentationTimerMessage,
      AdjustPresentationTimerMessage
    >
  | SignalRAction<
      NotifyMessageName.InitialDataForNewJoinToTimerMessage,
      InitialDataForNewJoinToTimerMessage
    >;

const marshalActionCreator = <T extends NotifyTimedSessionMessageType>(
  type: T
) => (
  payload: ActionInUnionWithType<MarshalNotifyTimedSession, T>["payload"]
) => {
  return {
    type,
    payload,
  };
};

const eventToAction: Record<
  NotifyTimedSessionMessageType,
  ActionCreator<MarshalNotifyTimedSession>
> = {
  [NotifyMessageName.StartPresentationTimerMessage]: marshalActionCreator(
    NotifyMessageName.StartPresentationTimerMessage
  ),
  [NotifyMessageName.StopPresentationTimerMessage]: marshalActionCreator(
    NotifyMessageName.StopPresentationTimerMessage
  ),
  [NotifyMessageName.PausePresentationTimerMessage]: marshalActionCreator(
    NotifyMessageName.PausePresentationTimerMessage
  ),
  [NotifyMessageName.AdjustPresentationTimerMessage]: marshalActionCreator(
    NotifyMessageName.AdjustPresentationTimerMessage
  ),
  [NotifyMessageName.InitialDataForNewJoinToTimerMessage]: marshalActionCreator(
    NotifyMessageName.InitialDataForNewJoinToTimerMessage
  ),
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* signalRTimedSessionSaga() {
  const notifyChannel: EventChannel<MarshalNotifyTimedSession> = yield call(
    signalRNotifyChannel,
    collaboard.signalRClient,
    eventToAction
  );

  try {
    while (true) {
      const action: MarshalNotifyTimedSession = yield take(notifyChannel);
      const userProfile = selectAssertedUserProfile(yield select());
      const timedSession = selectTimedSession(yield select());

      switch (action.type) {
        case NotifyMessageName.StartPresentationTimerMessage: {
          const { payload } = action;

          yield put(
            timedSessionStartedAction({
              session: payload,
              isHost: payload.Presenter === userProfile.UserName,
              startTime: Date.now(),
            })
          );
          break;
        }
        case NotifyMessageName.StopPresentationTimerMessage: {
          yield put(timedSessionStoppedAction({ host: timedSession.host }));
          break;
        }
        case NotifyMessageName.PausePresentationTimerMessage: {
          const { payload } = action;
          yield put(
            timedSessionPauseToggledAction({
              session: payload,
              pausedTime: Date.now(),
            })
          );
          break;
        }
        case NotifyMessageName.AdjustPresentationTimerMessage: {
          const { payload } = action;
          yield put(timedSessionDurationChangedAction(payload));
          break;
        }
        case NotifyMessageName.InitialDataForNewJoinToTimerMessage: {
          const { payload } = action;

          yield put(
            onJoinedTimedSessionAction({
              session: payload,
              isHost: payload.Presenter === userProfile.UserName,
            })
          );
          break;
        }
        default: {
          assertUnreachable(action);
        }
      }
    }
  } finally {
    const isCancelled: boolean = yield cancelled(); // Cancelled by exiting the project

    if (isCancelled) {
      // Close the EventChannel so that the unsubscribe function is called and listeners removed
      notifyChannel.close();
    }
  }
}
