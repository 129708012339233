import React from "react";
import { Flex, Text, Button } from "theme-ui";
import { useTranslation } from "react-i18next";

import { useLicenseInfo } from "../../licensing/useLicenseInfo";
import { contactPageUrl, ProductTypeIDs } from "../../../const";
import { useComparisonTableColumn } from "./useComparisonTableColumn";
import { initialPlans } from "./consts";
import { useUISettings } from "../../../reduxStore/uiSettings";
import { dynamicFeatureFlags } from "../../../tools/flags";

function ComparisonTableColumn({ plan, labels, onBuy }) {
  const { t } = useTranslation();
  const { values } = useComparisonTableColumn({ plan, labels });
  const { isLicenseActive } = useLicenseInfo();
  const { isDynamicFeatureActive } = useUISettings();

  const isEcommerceFeatureActive = !isDynamicFeatureActive(
    dynamicFeatureFlags.NO_ECOMMERCE
  );

  const renderActionButton = () => {
    if (
      !isEcommerceFeatureActive ||
      plan.name === initialPlans.free.name ||
      isLicenseActive
    ) {
      return null;
    }

    if (plan.name === initialPlans.enterprise.name) {
      return (
        <Button
          as="a"
          href={contactPageUrl}
          target="_blank"
          rel="nofollow noreferrer"
          sx={buttonStyle}
        >
          {t("plans.contactUsShort")}
        </Button>
      );
    }

    return (
      <Button onClick={() => onBuy(ProductTypeIDs[plan.name])} sx={buttonStyle}>
        {t("plans.buy", { planName: t(`plans.${plan.name}`) })}
      </Button>
    );
  };

  return (
    <>
      <Text sx={headerStyle}>{t(`plans.${plan.name}`)}</Text>

      {values.map(({ labelKey, value }) => (
        <Flex key={labelKey} sx={labelStyle}>
          {value || String.fromCharCode(8212)}
        </Flex>
      ))}

      <Flex sx={footerStyle}>{renderActionButton()}</Flex>
    </>
  );
}

const buttonStyle = { py: [4], height: "100%" };
const headerStyle = { bg: "background", fontWeight: 700, fontSize: "1.2rem" };
const labelStyle = { backgroundColor: "delicate" };
const footerStyle = { bg: "background", pt: [3], px: [0] };

export default ComparisonTableColumn;
