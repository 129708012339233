import React, { ReactElement } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Box, Button, ThemeUIStyleObject } from "theme-ui";
import { useHistory } from "react-router-dom";
import { routes } from "../../../const";

type Props = {
  sx?: ThemeUIStyleObject;
};

function SubscriptionUpgradeRequiredPrompt({ sx }: Props): ReactElement {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Box sx={{ fontSize: [3], mt: [4], ...sx }}>
      <Box>
        <Trans i18nKey="dialog.upgradeRequired.prompt" />
      </Box>

      <Button
        sx={{ pt: [2] }}
        variant="link"
        onClick={() => history.push(routes.plans)}
      >
        {t("dialog.upgradeRequired.button")}
      </Button>
    </Box>
  );
}

export default SubscriptionUpgradeRequiredPrompt;
