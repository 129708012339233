import { all, Effect, getContext, select, takeEvery } from "redux-saga/effects";
import { brushTypeConfig } from "../../../const";
import { DrawingActionType } from "./drawing.actions";
import { defaultBrushSize, selectDrawingInkState } from "./drawing.reducer";

function* updateDrawingSettings() {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const inkState = selectDrawingInkState(yield select());

  const { brushType, fillColor, inkSizes } = inkState;
  const strokeWidth =
    inkSizes[brushType] ??
    brushTypeConfig[brushType]?.default ??
    defaultBrushSize;
  const event: DrawingModeConfig = {
    brushType,
    color: fillColor,
    strokeWidth,
  };

  canvas.trigger("custom:drawing-mode:activate", event);
}

export function* drawingSaga(): Generator<Effect> {
  yield all([
    takeEvery(
      [
        DrawingActionType.SET_DRAWING_BRUSH_TYPE,
        DrawingActionType.SET_DRAWING_COLOR,
        DrawingActionType.SET_DRAWING_STROKE_WIDTH,
      ],
      updateDrawingSettings
    ),
  ]);
}
