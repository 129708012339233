import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getCountriesTranslationByLanguage } from "./countries";

type UseCountryTranslations = {
  countries: { [key in string | number]: string };
  loading: boolean;
  error: boolean;
};

export const useCountryTranslations = (): UseCountryTranslations => {
  const { i18n } = useTranslation();
  const [countries, setCountries] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getCountriesTranslationByLanguage(i18n.resolvedLanguage)
      .then((res) => {
        setCountries(res);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [i18n.resolvedLanguage]);

  return { countries, loading: loading || !countries, error };
};
