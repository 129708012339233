import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Display(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M32.67,6.1H21.17V5.25a.81.81,0,1,0-1.62,0V6.1H8A1.27,1.27,0,0,0,6.77,7.37V23.58A1.27,1.27,0,0,0,8,24.85h8.08l-2.93,9.49a.81.81,0,0,0,.53,1,.67.67,0,0,0,.24,0,.83.83,0,0,0,.78-.57l3.08-10h1.73v6.62a.81.81,0,1,0,1.62,0V24.85h1.72l3.09,10a.8.8,0,0,0,.77.57.67.67,0,0,0,.24,0,.8.8,0,0,0,.53-1l-2.93-9.49h8.08a1.27,1.27,0,0,0,1.27-1.27V7.37A1.27,1.27,0,0,0,32.67,6.1Zm-.35,17.13H8.39V7.72H32.32Z" />
    </Icon>
  );
}
export default Display;
