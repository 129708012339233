import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function DropdownArrow(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <polygon points="19.99 30.31 3.63 13.95 4.51 13.06 19.99 28.54 35.47 13.06 36.35 13.95 19.99 30.31" />
    </Icon>
  );
}
export default DropdownArrow;
