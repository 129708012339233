import { all, Effect, getContext, put, takeEvery } from "redux-saga/effects";
import { canvasObjectIds } from "../../../../const";
import { isActiveSelection } from "../../../../studio/utils/fabricObjects";
import {
  HistoryLockStatus,
  lockedAction,
} from "../../history/history.entry.actions";
import { LockActionType, unlockAllAction } from "./lock.actions";

function* toggleItemLock() {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const activeObjects = canvas.getActiveObjects();

  // toggleItemLock can be invoked with a shortcut, therefore this check has to be done
  if (!activeObjects.length) {
    return;
  }

  const status: HistoryLockStatus[] = [];
  const types: canvasObjectIds[] = [];

  const allLocked = activeObjects.every((o) => o.isLocked());
  const someLocked = activeObjects.some((o) => o.isLocked());

  if (!allLocked && someLocked) {
    yield put(unlockAllAction());
    return;
  }

  activeObjects.forEach((obj) => {
    const { uuid, type, locked } = obj;
    const invertedStatus = !locked;

    if (!allLocked && locked) {
      return;
    }

    obj.setPinned(invertedStatus);
    status.push({ uuid, isLocked: invertedStatus });
    types.push(type);
  });

  if (types.length) {
    yield put(
      lockedAction({
        status,
        types,
      })
    );

    // Force the context menu to update
    canvas.saveSelection();
    canvas.restoreSelection();

    canvas.requestRenderAll();
  }
}

function* unlockAll() {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const selection = canvas.getActiveObject();

  if (!selection || !isActiveSelection(selection)) {
    return;
  }

  const lockedObjects = selection.getObjects().filter((o) => o.isLocked());
  const status: HistoryLockStatus[] = [];
  const types: canvasObjectIds[] = [];

  lockedObjects.forEach((obj) => {
    const { uuid, type } = obj;
    obj.setPinned(false);
    status.push({ uuid, isLocked: false });
    types.push(type);
  });

  yield put(
    lockedAction({
      status,
      types,
    })
  );

  // Force the context menu to update
  canvas.saveSelection();
  canvas.restoreSelection();

  canvas.requestRenderAll();
}

export function* lockSaga(): Generator<Effect> {
  yield all([
    takeEvery(LockActionType.TOGGLE_ITEM_LOCK, toggleItemLock),
    takeEvery(LockActionType.UNLOCK_ALL, unlockAll),
  ]);
}
