import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function PasswordHidden(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M20.26,16.89,17,20.14A3.1,3.1,0,0,1,20.26,16.89Zm11.5-7.12L30.31,8.33,8.44,30.2l1.45,1.44ZM23.2,19.83,20,23.08A3.11,3.11,0,0,0,23.2,19.83Zm6-6L27.63,15.4A18.29,18.29,0,0,1,32.79,20a17.32,17.32,0,0,1-5.31,4.74A9.17,9.17,0,0,0,28.87,20a8.45,8.45,0,0,0-1.29-4.54l-1.49,1.49a6.85,6.85,0,0,1,.72,3,6.69,6.69,0,0,1-6.71,6.7,6.84,6.84,0,0,1-3-.72l-2,2a16.91,16.91,0,0,0,5.1.78A17.74,17.74,0,0,0,35.27,20,19.34,19.34,0,0,0,29.18,13.85ZM12.57,24.63A18.44,18.44,0,0,1,7.41,20a17.39,17.39,0,0,1,5.32-4.75A8.79,8.79,0,0,0,11.33,20a8.53,8.53,0,0,0,1.29,4.54l1.5-1.5a6.72,6.72,0,0,1,6-9.75,6.85,6.85,0,0,1,3,.72l2-2a17.22,17.22,0,0,0-5.06-.77A17.73,17.73,0,0,0,5,20a18.34,18.34,0,0,0,6,6.13Z" />
    </Icon>
  );
}
export default PasswordHidden;
