import React, { ReactElement } from "react";
import { Trans } from "react-i18next";
import { Box, Heading, Link, ThemeUIStyleObject } from "theme-ui";
import LoginFooter from "../../features/authentication/login/LoginFooter";
import LoginOptions from "../../features/authentication/login/LoginOptions";
import Logo from "../../features/routes/layout/logo/Logo";
import TagLine from "../../features/routes/layout/TagLine";
import { useUISettings } from "../../reduxStore/uiSettings";
import { getAppName } from "../../tools/customer";
import { runtimeConfig } from "../../tools/runtimeConfig";

function LoginCollins(): ReactElement {
  const { getTranslation } = useUISettings();
  return (
    <>
      <Box sx={leftBoxStyle}>
        <Logo size="20px" sx={logoStyle}>
          <TagLine size="20px" />
        </Logo>

        <span>
          <Trans
            defaults={getTranslation("form.header.login")}
            values={{ appName: getAppName() }}
          >
            <strong />
            <Link href={runtimeConfig.tosUrl} target="_blank" />
          </Trans>
        </span>

        <span>
          <Trans defaults={getTranslation("form.header.loginHeadline")}>
            <Link href={runtimeConfig.authorizationUrl} />
            <Link href={runtimeConfig.dataPrivacyUrl} target="_blank" />
          </Trans>
        </span>
      </Box>

      <Box sx={rightBoxStyle}>
        <Logo sx={logoStyle}>
          <TagLine />
        </Logo>
        <Heading as="legend" sx={legendStyle}>
          {getAppName()}
        </Heading>
        <LoginOptions />
        <LoginFooter />
      </Box>
    </>
  );
}

const leftBoxStyle: ThemeUIStyleObject = {
  width: "100%",
  backgroundColor: "accent",
  color: "#ffffff",
  p: [6],
  mb: [8],
  span: {
    display: "block",
    mb: [4],
  },
};

const logoStyle: ThemeUIStyleObject = { div: { p: [0], m: [0], mb: [2] } };

const rightBoxStyle: ThemeUIStyleObject = {
  width: "100%",
  px: [6, null, 9],
  mb: [4],
};

const legendStyle: ThemeUIStyleObject = {
  textTransform: "uppercase",
  width: "100%",
  py: [5],
  fontWeight: "bold",
};

export default LoginCollins;
