import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { boolean, object, SchemaOf, string } from "yup";
import { authenticateUser, registerUser, updateUserProfile } from "../../api";
import { InternalError } from "../../errors/InternalError";
import {
  AuthUserType,
  setAuthenticatedAction,
} from "../../reduxStore/auth/auth.actions";
import { useUISettings } from "../../reduxStore/uiSettings";
import { AppLanguage } from "../../reduxStore/uiSettings/uiSettings.hook";
import { userNameRegex } from "../../tools/regex";
import { initializePhoneValidation } from "../../tools/validationTools";
import { maxUserNameLength } from "../../validationSchemas/fieldRestrictions";
import { passwordSchema } from "../../validationSchemas/password";
import { ModalProps, useSimpleModal } from "../shared/modals/useSimpleModal";
import { isValidatedTokenResponse } from "./login/auth.utils";

export type UserNameFormValues = {
  UserName: string;
};

export type CredentialFormValues = UserNameFormValues & {
  Password: string;
};

export type ProfileFormValues = UserNameFormValues & {
  FirstName: string;
  LastName?: string;
  CompanyName?: string;
  Country?: string;
  Language?: string;
  PhoneNumber?: string;
  TermsOfServiceAccepted: boolean;
  ReceiveNews?: boolean;
};

export type RegistrationFormValues = CredentialFormValues & ProfileFormValues;

type RegisterConfig = {
  values: RegistrationFormValues;
  bypassEmailVerification: boolean;
};

type AutoLoginConfig = {
  UserName: string;
  Password: string;
  Tenant?: string;
};

type UseAuthentication = {
  register: (config: RegisterConfig) => Promise<ApiRegisterUser>;
  autoLogin: (config: AutoLoginConfig) => Promise<void>;
  credentialsSchema: SchemaOf<CredentialFormValues>;
  profileSchema: SchemaOf<ProfileFormValues>;
  languageOptions: AppLanguage[];
  emailVerificationModal: ModalProps;
};

export const useAuthentication = (): UseAuthentication => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { getLanguages } = useUISettings();
  const { appLanguages, supportedLanguages } = getLanguages();

  useMemo(() => initializePhoneValidation(t), [t]);

  const emailVerificationModal = useSimpleModal();

  const profileSchema = object().shape({
    UserName: string().required(t("clientError.required")),
    FirstName: string()
      .required(t("clientError.required"))
      .matches(userNameRegex, t("clientError.invalidFormat"))
      .max(
        maxUserNameLength,
        t("clientError.tooLongMax", { max: maxUserNameLength })
      ),
    LastName: string()
      .required(t("clientError.required"))
      .matches(userNameRegex, t("clientError.invalidFormat"))
      .max(
        maxUserNameLength,
        t("clientError.tooLongMax", { max: maxUserNameLength })
      ),
    CompanyName: string().optional(),
    Country: string().optional(),
    Language: string()
      .optional()
      .oneOf(supportedLanguages, t("clientError.invalidFormat")),
    PhoneNumber: string().optional().phone(),
    ReceiveNews: boolean(),
    TermsOfServiceAccepted: boolean()
      .required(t("clientError.required"))
      .oneOf([true], t("clientError.mustAcceptTermsOfService")),
  });

  const credentialsSchema = object().shape({
    UserName: string()
      .email(t("clientError.emailNotValid"))
      .required(t("clientError.required")),
    Password: passwordSchema.required(t("clientError.required")),
  });

  /**
   * Register a user
   */
  const register = useCallback(
    async ({ values, bypassEmailVerification }: RegisterConfig) => {
      const response = await registerUser(values, bypassEmailVerification);
      const { AuthorizationToken } = response;

      await updateUserProfile(values, AuthorizationToken);

      if (!bypassEmailVerification) {
        emailVerificationModal.open();
      }

      return response;
    },
    [emailVerificationModal]
  );

  /**
   * Auto-login a user.
   *
   * This is intended to be used directly after a new registration.
   */
  const autoLogin = useCallback(
    async ({ UserName, Password, Tenant }: AutoLoginConfig) => {
      const response = await authenticateUser(UserName, Password);

      /**
       * Unexpected response shape - this suggests that the user has got 2FA
       * enabled, which can only happen if we are auto-logging in an existing
       * user account, rather than a newly-registered one.
       *
       * In this case we throw an error for the caller to handle. Each caller
       * will want to do a slightly different thing in this case.
       */
      if (!isValidatedTokenResponse(response)) {
        throw new InternalError("Attempting to auto-login with 2FA enabled");
      }

      dispatch(
        setAuthenticatedAction({
          authProvider: "email",
          isRegistration: false,
          redirect: undefined,
          tenant: Tenant,
          token: response,
          userType: AuthUserType.User,
        })
      );
    },
    [dispatch]
  );

  return {
    register,
    autoLogin,
    credentialsSchema,
    profileSchema,
    languageOptions: appLanguages,
    emailVerificationModal,
  };
};
