/** @jsxRuntime classic */
/** @jsx jsx */
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Alert,
  Button,
  Flex,
  Input,
  jsx,
  Label,
  Text,
  useThemeUI,
} from "theme-ui";
import { ErrorMessage, FieldHookConfig, useField } from "formik";
import { useTranslation } from "react-i18next";
import { isIPad13, isTablet, isIOS } from "react-device-detect";

import DropdownArrow from "../../../../icons/DropdownArrow";

export type InputDropdownOption<T extends string | number> = {
  label?: string;
  value: T;
};

type Props<T extends string | number> = {
  options: InputDropdownOption<T>[];
  onHandleChange?: (value: T) => void;
  label?: string;
  variant?: string;
  readOnly?: boolean;
  dropdownAboveField?: boolean;
} & FieldHookConfig<T>;

// todo: refactor this
function InputDropDownField<T extends string | number>({
  children,
  disabled,
  options,
  placeholder,
  required,
  sx,
  onHandleChange,
  tabIndex,
  label,
  variant = "label",
  // when set to true it prevents virtual keyboard from showing up but also makes field not focusable in Safari
  readOnly,
  dropdownAboveField,
  ...props
}: Props<T>): React.ReactElement {
  const useHTMLSelect = isIOS && !isTablet; // iPhone, not Android phone
  // Note: An Android phone will not work with the HTML <select/> option
  const supportTabletFocus = isTablet || isIPad13;
  const { theme } = useThemeUI();

  const [field, , helpers] = useField(props);
  const { name, value } = field;
  const { setValue, setTouched } = helpers;

  const selectedOption = useMemo(
    () => options.find((option) => option.value === value),
    [options, value]
  );
  const selectedOptionIndex = selectedOption
    ? options.indexOf(selectedOption)
    : -1;

  const { t } = useTranslation();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [
    highlightedOption,
    setHighlightedOption,
  ] = useState<InputDropdownOption<T> | null>(selectedOption || null);
  const highlightedOptionIndex = highlightedOption
    ? options.indexOf(highlightedOption)
    : -1;

  const inputLabel = useRef<HTMLLabelElement>(null);
  const triggerInput = useRef<HTMLInputElement & { _ignoreNextClick: boolean }>(
    null
  );
  const fieldInput = useRef(null);
  const dropdownList = useRef<HTMLDivElement>(null);
  const selectList = useRef<HTMLSelectElement>(null);

  /**
   * Select an option from the dropdown
   */
  const selectAnOption = (newValue: string | number) => {
    // Keep the type of value consistent
    const selectedValue = (typeof value === "number"
      ? +newValue
      : newValue) as T;
    setValue(selectedValue);
    setTouched(false);
    setDropdownVisible(false);
    triggerInput.current?.focus();
    onHandleChange && onHandleChange(selectedValue);
  };

  /**
   * Handle a click on an option in the styled custom dropdown
   *
   * @param {MouseEvent | PointerEvent} event DOM event
   */
  const handleOptionClick = (event: React.MouseEvent<HTMLElement>) => {
    // Stop event from hitting <label> and firing a click on the trigger input
    event.stopPropagation();
    event.preventDefault();
    const target = event.target as HTMLElement;
    const { key } = target.dataset;
    if (key) {
      selectAnOption(key);
    }
  };

  /**
   * Handle a click event on the trigger field
   */
  const handleTriggerClick = () => {
    if (useHTMLSelect) {
      selectList.current?.focus();
    } else if (!triggerInput.current?._ignoreNextClick) {
      setDropdownVisible((state) => !state);
    }
  };

  /**
   * Handle a focus event on the trigger input.
   */
  const handleTriggerFocus = () => {
    if (!triggerInput.current) {
      return;
    }
    /**
     * Make the field readonly - fools iPad into allowing focus, but hides the
     * cursor. Focus support is required to ensure user can tab to the field.
     */
    triggerInput.current.readOnly = true;
    /**
     * If the focus is triggered by the user clicking then both events will fire.
     * Focus will be fired just before click. Therefore we need to stop the click
     * event from toggling the dropdown visibility, i.e. closing it.
     */
    triggerInput.current._ignoreNextClick = true;
    setDropdownVisible(true);
    setTimeout(() => {
      if (triggerInput.current) {
        // Cancel the flag in the next tick to allow the click event to fire first.
        triggerInput.current._ignoreNextClick = false;
      }
    });
  };

  /**
   * Handle a blur event on the trigger input.
   *
   * TODO: This is a bit hacky. Perhaps the iPad UX could be reconsidered.
   */
  const handleTriggerBlur = () => {
    /**
     * Delay required so this happens _after_ the option click event (which also
     * triggers a blur)
     */
    setTimeout(() => {
      if (triggerInput.current) {
        triggerInput.current.readOnly = false;
        setDropdownVisible(false);
        triggerInput.current.blur();
      }
    });
  };

  /**
   * Handle keyboard controls in the trigger input.
   *
   * TODO: Tidy this method up
   *
   * @param {KeyboardEvent} event DOM event
   */
  const handleTriggerKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    const openDropdownKeys = ["ArrowDown", "ArrowUp", "Enter", " "];
    const closeDropdownKeys = ["Escape", "Tab"];
    const trackedKeys = [...openDropdownKeys, ...closeDropdownKeys];

    if (!trackedKeys.includes(event.key)) {
      return;
    }

    if (!options.length) {
      return;
    }

    if (!dropdownVisible && openDropdownKeys.includes(event.key)) {
      event.preventDefault();
      event.stopPropagation();

      setDropdownVisible(true);
      return;
    }

    if (closeDropdownKeys.includes(event.key)) {
      setDropdownVisible(false);
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      const direction = event.key === "ArrowDown" ? 1 : -1;

      const newOptionIndex = Math.max(
        Math.min(highlightedOptionIndex + direction, options.length - 1),
        0
      );
      const newOption = options[newOptionIndex];
      setHighlightedOption(newOption);
    } else if (event.key === "Enter") {
      selectAnOption(highlightedOption?.value || value);
    }
  };

  /**
   * Ensure temp option is reset when dropdown is toggled
   */
  useEffect(() => {
    setHighlightedOption(selectedOption || null);
  }, [dropdownVisible, selectedOption]);

  /**
   * Scroll the dropdown to the selected option
   */
  useEffect(() => {
    if (!dropdownVisible || !dropdownList.current) {
      return;
    }
    if (dropdownList.current) {
      const highlightChanging = highlightedOptionIndex !== selectedOptionIndex;
      const targetIndex = highlightChanging
        ? highlightedOptionIndex
        : selectedOptionIndex;
      dropdownList.current.children[targetIndex]?.scrollIntoView({
        // Only animate the scroll when user is using keyboard
        behavior: highlightChanging ? "smooth" : "auto",
        block: "nearest",
      });
    }
  }, [
    inputLabel,
    dropdownList,
    highlightedOption,
    value,
    dropdownVisible,
    options,
    selectedOptionIndex,
    highlightedOptionIndex,
  ]);

  /**
   * Close the dropdown when the user clicks anywhere outside the <label>
   */
  useEffect(() => {
    const clickOutside = (e: MouseEvent) => {
      inputLabel.current &&
        !inputLabel.current.contains(e.target as HTMLLabelElement) &&
        setDropdownVisible(false);
    };

    document.addEventListener("mousedown", clickOutside);
    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, []);

  return (
    <Label
      variant={required ? `${variant}.required` : `${variant}`}
      ref={inputLabel}
      sx={sx}
    >
      {label && (
        <Text sx={{ fontWeight: 600, fontSize: [4], mb: [1] }}>
          {label} {required ? `(${t("form.required")})` : ""}
        </Text>
      )}
      <Flex variant="forms.formGroup">
        {
          // Value for server is contained in this field
        }
        <Input ref={fieldInput} {...field} required={required} type="hidden" />
        {
          // Dummy focus field because Safari can't handle focus on a button
        }
        <Input
          readOnly={readOnly || !supportTabletFocus}
          disabled={disabled}
          ref={triggerInput}
          onKeyDown={useHTMLSelect ? () => null : handleTriggerKeyDown}
          onFocus={supportTabletFocus ? handleTriggerFocus : () => null}
          onBlur={supportTabletFocus ? handleTriggerBlur : () => null}
          onClick={handleTriggerClick}
          tabIndex={useHTMLSelect ? -1 : tabIndex}
          variant="forms.input"
          value={value ? selectedOption?.label || value : ""}
          placeholder={placeholder || t(`form.placeholder.${name}`)}
          className="disable-selection-highlight"
          sx={{ pr: [6] }}
        />
        <DropdownArrow
          noShadow
          sx={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            right: 3,
            fill: options.length ? "var(--inputPlaceholder)" : "muted",
            pointerEvents: "none",
          }}
        />
        {!useHTMLSelect && dropdownVisible && (
          <Flex
            variant={
              dropdownAboveField
                ? "layout.dropdownAboveField"
                : "layout.dropdown"
            }
            ref={dropdownList}
            onClick={(event) => handleOptionClick(event)}
          >
            {options.map((option) => (
              <Button
                type="button"
                key={option.value}
                data-key={option.value}
                variant="dropdownItem"
                tabIndex={-1}
                sx={{
                  bg:
                    (option === selectedOption && theme.colors?.primary) ||
                    (option === highlightedOption && theme.colors?.secondary) ||
                    "",
                }}
              >
                {option.label}
              </Button>
            ))}
          </Flex>
        )}
        {useHTMLSelect && (
          <select
            ref={selectList}
            onBlur={(event) => selectAnOption(event.target.value)}
            disabled={disabled}
            tabIndex={tabIndex}
            defaultValue={value}
            sx={{
              position: "absolute",
              left: "-9999px",
              fontSize: 16, // Prevents iPhone zooming on focus
            }}
          >
            <option value={0} />
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        )}
      </Flex>
      <ErrorMessage
        name={name}
        component={({ children: kids }) => (
          <Alert variant="primary">{kids}</Alert>
        )}
      />
      {children}
    </Label>
  );
}

export default InputDropDownField;
