import React, { ReactElement, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useQueryParams, withDefault, NumberParam } from "use-query-params";
import { Box } from "theme-ui";

import PageAuthorized from "../routes/layout/pages/PageAuthorized";
import { useTrackEventToLog } from "../shared/tracking/useTrackEventToLog";
import SpacesNav from "./SpacesNav";
import CreateSpaceModalTemplate from "./CreateSpaceModalTemplate";
import { useSpaces } from "./useSpaces";
import Space from "./space/Space";
import { LogCategory } from "../../tools/telemetry";
import { useSimpleModal } from "../shared/modals/useSimpleModal";

const createSpaceId = -1;

const spacesPageParamConfig = {
  spaceId: withDefault(NumberParam, null),
};

function Spaces(): ReactElement {
  const [{ spaceId: activeSpaceId }, setParams] = useQueryParams(
    spacesPageParamConfig
  );
  const { t } = useTranslation();
  const { spaces, isLoading } = useSpaces();
  const { trackEvent } = useTrackEventToLog();

  useEffect(() => {
    trackEvent(LogCategory.navigation, {
      subcategory: "page-spaces",
    });
  }, [trackEvent]);

  useEffect(() => {
    return () => toast.dismiss();
  }, []);

  const createSpaceModalProps = useSimpleModal();

  const setActiveSpaceId = (spaceId: number | null) => setParams({ spaceId });

  const onSpaceChange = (spaceId: number) => {
    spaceId === createSpaceId
      ? createSpaceModalProps.open()
      : setActiveSpaceId(spaceId);
  };

  const selectedSpace = useMemo(
    () => spaces.find((space) => space.Space.SpaceId === activeSpaceId),
    [spaces, activeSpaceId]
  );

  return (
    <PageAuthorized>
      <SpacesNav
        activeSpaceId={activeSpaceId ?? createSpaceId}
        headSpaceId={createSpaceId}
        onSpaceChange={onSpaceChange}
      />
      {selectedSpace ? (
        <Space space={selectedSpace} onExit={() => setActiveSpaceId(null)} />
      ) : (
        !isLoading && (
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            {spaces.length ? (
              t("spaces.noSelectedSpace")
            ) : (
              <>
                <Box>{t("spaces.noSpaces")}</Box>
                <Box sx={{ mt: [3] }}>{t("spaces.noSpacesCreate")}</Box>
              </>
            )}
          </Box>
        )
      )}

      <CreateSpaceModalTemplate
        modalProps={createSpaceModalProps}
        onCreated={setActiveSpaceId}
      />
    </PageAuthorized>
  );
}

export default Spaces;
