import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Text } from "theme-ui";
import LoadingSpinner from "../../../icons/LoadingSpinner";
import { formatNumber } from "../../../tools/numbers";
import { billingTypes } from "./enums";

function Total({ arePricesLoaded, currency, total, billingType, error }) {
  const { t } = useTranslation();
  const monthly = (total / 12).toFixed(2);

  return (
    <Box sx={{ p: [4] }}>
      <Text sx={{ fontSize: [5] }}>{t("dialog.payment.total")}</Text>

      <Flex sx={containerStyle}>
        {!arePricesLoaded && <LoadingSpinner />}
        {arePricesLoaded && error && <Text sx={{ mt: [5] }}>{error}</Text>}
        {arePricesLoaded && !error && (
          <>
            <Box>
              <Text as="span" sx={{ fontSize: "22px", marginRight: [1] }}>
                {currency.symbol}
              </Text>
              <Text
                as="span"
                sx={{
                  fontSize: total > 99999 ? "32px" : "46px",
                  fontWeight: 500,
                  whiteSpace: "nowrap",
                }}
              >
                {formatNumber(total)}
              </Text>
              <Text
                as="span"
                sx={{
                  fontSize: total > 99999 ? "30px" : "44px",
                  fontWeight: 200,
                }}
              >
                /
                {billingType === billingTypes.monthly
                  ? t("plans.monthShortcut")
                  : t("plans.yearShortcut")}
              </Text>
            </Box>

            {billingType === billingTypes.annually && (
              <Text sx={annuallyStyle}>
                {currency.symbol}
                {formatNumber(monthly)} {t("plans.perMonth")}
              </Text>
            )}
          </>
        )}
      </Flex>
    </Box>
  );
}

const containerStyle = {
  alignItems: "center",
  justifyContent: "center",
  flex: 1,
  flexDirection: "column",
};

const annuallyStyle = {
  color: "disabledDark",
  fontSize: [3],
  fontWeight: 500,
  mt: [1],
  whiteSpace: "nowrap",
};

Total.propTypes = {
  arePricesLoaded: PropTypes.bool.isRequired,
  billingType: PropTypes.number.isRequired,
  currency: PropTypes.shape({
    code: PropTypes.string,
    symbol: PropTypes.string,
  }),
  total: PropTypes.number,
  error: PropTypes.oneOfType([
    PropTypes.shape({ ErrorCode: PropTypes.number }),
    PropTypes.string,
  ]),
};
Total.defaultProps = {
  currency: {},
  total: null,
  error: null,
};

export default Total;
