import React, { PropsWithChildren, ReactElement } from "react";
import {
  Alert,
  Checkbox,
  Label,
  Text,
  Box,
  ThemeUIStyleObject,
} from "theme-ui";
import { ErrorMessage, useField } from "formik";
import { useTranslation } from "react-i18next";
import { getAppName } from "../../../../tools/customer";
import { useFieldError } from "./useFieldError";

type Props = PropsWithChildren<{
  name: string;
  placeholder?: string;
  required?: boolean;
  labelVariant?: string;
  sx?: ThemeUIStyleObject;
}>;

function CheckboxField({
  children,
  placeholder,
  required,
  labelVariant = "checkboxLabel",
  sx,
  ...props
}: Props): ReactElement {
  const { t } = useTranslation();

  const [field, meta] = useField(props);
  const { name } = field;
  const { errorCheckboxSx, fieldRef } = useFieldError<HTMLLabelElement>({
    meta,
    name,
  });

  return (
    <>
      <Label
        variant={required ? `${labelVariant}.required` : `${labelVariant}`}
        sx={{ ...sx, ...errorCheckboxSx }}
        aria-label={name}
        ref={fieldRef}
      >
        <Checkbox
          defaultChecked={field.value}
          {...field}
          {...meta}
          {...props}
        />
        <Text
          sx={{
            flex: 1,
            pt: 1,
            // Prevent text overlapping required asterisk
            pr: required ? 5 : 0,
          }}
        >
          {children || t(`form.label.${name}`, { appName: getAppName() })}
        </Text>
      </Label>

      {meta.error && (
        <Box sx={{ marginTop: "-15px", width: "100%", marginBottom: "10px" }}>
          <ErrorMessage
            name={name}
            component={({ children: kids }) => (
              <Alert variant="primary">{kids}</Alert>
            )}
          />
        </Box>
      )}
    </>
  );
}

export default CheckboxField;
