import { ActionCreator } from "redux";
import { EventChannel } from "redux-saga";
import { call, cancelled, put, select, take } from "redux-saga/effects";
import type {
  VotingCastMessage,
  VotingRevokedMessage,
  VotingSessionFinalizedMessage,
  VotingStartedMessage,
  VotingStoppedMessage,
  VotingTimeAdjustedMessage,
} from "../../../api/signalR/message.types";
import { NotifyMessageName } from "../../../api/signalR/protobuf.codecs";
import { assertUnreachable } from "../../../tools/assertions";
import collaboard from "../../../tools/collaboard";
import { selectUserProfile } from "../../auth/auth.reducer";
import { SignalRAction, signalRNotifyChannel } from "../../redux.utils";
import {
  onParticipantFinishedVotingAction,
  onVoteCastAction,
  onVotingSessionDurationChangedAction,
  onVotingSessionStartedAction,
  onVotingSessionStoppedAction,
} from "./voting.actions";

type NotifyVotingMessageType =
  | NotifyMessageName.VotingStartedMessage
  | NotifyMessageName.VotingStoppedMessage
  | NotifyMessageName.VotingCastMessage
  | NotifyMessageName.VotingRevokedMessage
  | NotifyMessageName.VotingSessionFinalizedMessage
  | NotifyMessageName.VotingTimeAdjustedMessage;

type MarshalNotifyVoting =
  | SignalRAction<NotifyMessageName.VotingStartedMessage, VotingStartedMessage>
  | SignalRAction<NotifyMessageName.VotingStoppedMessage, VotingStoppedMessage>
  | SignalRAction<NotifyMessageName.VotingCastMessage, VotingCastMessage>
  | SignalRAction<NotifyMessageName.VotingRevokedMessage, VotingRevokedMessage>
  | SignalRAction<
      NotifyMessageName.VotingSessionFinalizedMessage,
      VotingSessionFinalizedMessage
    >
  | SignalRAction<
      NotifyMessageName.VotingTimeAdjustedMessage,
      VotingTimeAdjustedMessage
    >;

const marshalActionCreator = <T extends NotifyVotingMessageType>(type: T) => (
  payload: ActionInUnionWithType<MarshalNotifyVoting, T>["payload"]
) => {
  return {
    type,
    payload,
  };
};

const eventToAction: Record<
  NotifyVotingMessageType,
  ActionCreator<MarshalNotifyVoting>
> = {
  [NotifyMessageName.VotingStartedMessage]: marshalActionCreator(
    NotifyMessageName.VotingStartedMessage
  ),
  [NotifyMessageName.VotingStoppedMessage]: marshalActionCreator(
    NotifyMessageName.VotingStoppedMessage
  ),
  [NotifyMessageName.VotingCastMessage]: marshalActionCreator(
    NotifyMessageName.VotingCastMessage
  ),
  [NotifyMessageName.VotingRevokedMessage]: marshalActionCreator(
    NotifyMessageName.VotingRevokedMessage
  ),
  [NotifyMessageName.VotingSessionFinalizedMessage]: marshalActionCreator(
    NotifyMessageName.VotingSessionFinalizedMessage
  ),
  [NotifyMessageName.VotingTimeAdjustedMessage]: marshalActionCreator(
    NotifyMessageName.VotingTimeAdjustedMessage
  ),
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* signalRVotingSaga() {
  const notifyChannel: EventChannel<MarshalNotifyVoting> = yield call(
    signalRNotifyChannel,
    collaboard.signalRClient,
    eventToAction
  );

  try {
    while (true) {
      const message: MarshalNotifyVoting = yield take(notifyChannel);
      const userProfile = selectUserProfile(yield select());

      switch (message.type) {
        case NotifyMessageName.VotingStartedMessage: {
          const { payload } = message;
          yield put(
            onVotingSessionStartedAction({
              isOwner: payload.Owner === userProfile?.UserName,
              session: payload,
              sessionStartTime: Date.now(),
            })
          );
          break;
        }
        case NotifyMessageName.VotingStoppedMessage: {
          const { payload } = message;
          yield put(
            onVotingSessionStoppedAction({
              session: payload,
            })
          );
          break;
        }
        case NotifyMessageName.VotingCastMessage: {
          const { payload } = message;
          yield put(
            onVoteCastAction({
              isMyVote: payload.UserName === userProfile?.UserName,
              vote: payload,
            })
          );
          break;
        }
        case NotifyMessageName.VotingRevokedMessage: {
          const { payload } = message;
          yield put(
            onVoteCastAction({
              isMyVote: payload.UserName === userProfile?.UserName,
              vote: payload,
            })
          );
          break;
        }
        case NotifyMessageName.VotingSessionFinalizedMessage: {
          const { payload } = message;
          yield put(
            onParticipantFinishedVotingAction({
              isMyself: payload.UserName === userProfile?.UserName,
              session: payload,
            })
          );
          break;
        }
        case NotifyMessageName.VotingTimeAdjustedMessage: {
          const { payload } = message;
          yield put(
            onVotingSessionDurationChangedAction({
              session: payload,
            })
          );
          break;
        }
        default: {
          assertUnreachable(message);
        }
      }
    }
  } finally {
    const isCancelled: boolean = yield cancelled(); // Cancelled by exiting the project

    if (isCancelled) {
      // Close the EventChannel so that the unsubscribe function is called and listeners removed
      notifyChannel.close();
    }
  }
}
