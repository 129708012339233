import { all, call, Effect, put, select, takeEvery } from "redux-saga/effects";
import { v4 as uuid } from "uuid";
import { assertUser } from "../../api";
import { InternalError } from "../../errors/InternalError";
import { isStaticFeatureActive, staticFeatureFlags } from "../../tools/flags";
import {
  activatePenOnTouchStorage,
  clickableUrlsStorage,
  defaultConnectorSettingsStorage,
  defaultStickyNoteSettingsStorage,
  defaultTextSettingsStorage,
  embedTransparencyStorage,
  floatingToolbarStorage,
  minimapOpenStorage,
  objectGradientsStorage,
  presentingStorage,
  previouslyAddedObjectStorage,
  uiSizeStorage,
  userPresenceStorage,
  viewportTransformStorage,
  wheelInputAsTouchpadStorage,
  windowBorderOverlayStorage,
} from "../../tools/localStorageStores";
import { LogCategory, onErrorToLog } from "../../tools/telemetry";
import * as AuthActions from "./auth.actions";
import { AuthActionType, AuthUserType } from "./auth.actions";
import { isLoggedInState, isLoggedOutState } from "./auth.reducer";
import {
  clearAllAuthStorage,
  clearAuthenticatingDetails,
  clearAuthStorageByUserType,
  getAuthenticatedDetails,
  getAuthenticatingDetails,
  revokeRefreshToken,
  storeAuthenticatedDetails,
  storeAuthenticatingDetails,
} from "./auth.storage";

const isInMemoryAuthToken = isStaticFeatureActive(
  staticFeatureFlags.IN_MEMORY_AUTH_TOKEN
);

const uniqueTabId = uuid();

function* initAuth() {
  /**
   * This value comes from LocalStorage which can be changed by other
   * tabs, so it is important that we check it first.
   */
  const authenticatedDetails = getAuthenticatedDetails();

  if (authenticatedDetails) {
    yield put(
      AuthActions.setAuthenticatedAction({
        authProvider: authenticatedDetails.authProvider,
        isRegistration: authenticatedDetails.isRegistration,
        tenant: authenticatedDetails.tenant,
        token: authenticatedDetails.token,
        userType: authenticatedDetails.userType,
      })
    );
    return;
  }

  /**
   * This value comes from SessionStorage which is not (usually) shared
   * by other tabs.
   */
  const authenticatingDetails = getAuthenticatingDetails();

  if (authenticatingDetails) {
    yield put(
      AuthActions.setAuthenticatingAction({
        authProvider: authenticatingDetails.authProvider,
        isRegistration: authenticatingDetails.isRegistration,
        redirect: authenticatingDetails.redirect,
        tenant: authenticatingDetails.tenant,
      })
    );
    return;
  }

  yield put(AuthActions.loggedOutAction());
}

function* setAuthenticating(action: AuthActions.SetAuthenticatingAction) {
  yield call(storeAuthenticatingDetails, action.payload);
}

function* clearAuthenticating() {
  yield call(clearAuthenticatingDetails);
}

function* setAuthenticated({ payload }: AuthActions.SetAuthenticatedAction) {
  storeAuthenticatedDetails(payload);
  clearAuthenticatingDetails();

  // Don't assert the user until registration has been completed
  if (!payload.isRegistration) {
    yield put(AuthActions.assertUserAction());
  }
}

function* handleAssertUser() {
  const state: ApplicationGlobalState = yield select();
  /**
   * This should only happen once per login because it updates the
   * database. However it is harmless for the React app.
   */
  const { auth } = state;

  if (isLoggedInState(auth)) {
    onErrorToLog(
      new InternalError("Assert user has already been called"),
      LogCategory.auth
    );
  }

  try {
    const user: UserProfile = yield call(assertUser);
    yield put(AuthActions.loggedInAction(user));
  } catch {
    /**
     * @TODO #7118 - Add retry support for network errors?
     * https://redux-saga.js.org/docs/recipes/index.html#retrying-xhr-calls
     */
    yield put(AuthActions.logoutAction());
  }
}

function* loggedIn() {
  const state: ApplicationGlobalState = yield select();
  const { auth } = state;

  if (isLoggedInState(auth)) {
    const { UserName } = auth.userProfile;

    // TODO: Do this via a manager or static method
    [
      activatePenOnTouchStorage,
      clickableUrlsStorage,
      defaultConnectorSettingsStorage,
      defaultStickyNoteSettingsStorage,
      defaultTextSettingsStorage,
      embedTransparencyStorage,
      floatingToolbarStorage,
      minimapOpenStorage,
      objectGradientsStorage,
      presentingStorage,
      previouslyAddedObjectStorage,
      uiSizeStorage,
      userPresenceStorage,
      viewportTransformStorage,
      wheelInputAsTouchpadStorage,
      windowBorderOverlayStorage,
    ].forEach((storage) => {
      storage.setUserNamespace(UserName);
    });
  }

  /**
   * If a USER has been authenticated the token will be available in a
   * global context (i.e. LocalStorage) so we can inform other tabs.
   *
   * GUESTs store their token in SessionStorage so they won't have access
   * to the token set by this tab. As is the case for InMemoryStorage
   */
  if (
    !isInMemoryAuthToken &&
    isLoggedInState(auth) &&
    auth.userType === AuthUserType.User
  ) {
    yield put(
      AuthActions.syncLoggedInAction({
        uniqueTabId,
      })
    );
  }
}

function* logout() {
  const state: ApplicationGlobalState = yield select();
  const { auth } = state;

  if (isLoggedInState(auth)) {
    yield call(revokeRefreshToken);
    clearAuthStorageByUserType(auth.userType);
  } else {
    clearAllAuthStorage();
  }

  yield put(AuthActions.loggedOutAction());
}

function* loggedOut() {
  /**
   * Let other tabs know that we have logged out.
   *
   * At this point we no longer know what type of user logged out, so
   * we have to leave it up to the recipients to decide how to handle
   * this event.
   */
  if (!isInMemoryAuthToken) {
    yield put(
      AuthActions.syncLoggedOutAction({
        uniqueTabId,
      })
    );
  }
}

function* syncLoggedIn({ payload }: AuthActions.SyncLoggedInAction) {
  const state: ApplicationGlobalState = yield select();

  /**
   * Ignore actions triggered by self.
   *
   * @TODO #7118 - It appears that there is an undocumented `$isSync`
   * boolean included in the action (added by react-state-sync) that
   * we could use instead of our approach. Figure out how to type this
   * and use that instead.
   */
  if (payload.uniqueTabId === uniqueTabId) {
    return;
  }

  /**
   * USER has logged in in another tab. If this tab hasn't yet logged in
   * then re-init the auth status to trigger login.
   */
  const { auth } = state;

  if (isLoggedOutState(auth)) {
    yield put(AuthActions.initAuthAction());
  }
}

function* syncLoggedOut({ payload }: AuthActions.SyncLoggedOutAction) {
  const state: ApplicationGlobalState = yield select();

  /**
   * Ignore actions triggered by self.
   *
   * @TODO #7118 - It appears that there is an undocumented `$isSync`
   * boolean included in the action (added by react-state-sync) that
   * we could use instead of our approach. Figure out how to type this
   * and use that instead.
   */
  if (payload.uniqueTabId === uniqueTabId) {
    return;
  }

  /**
   * USER or GUEST has logged out in another tab.
   */
  const { auth } = state;

  /**
   * If we are logged in as a USER we need to re-init our auth status
   * because our token will probably be gone.
   *
   * @TODO #7118 - Can we do the same for GUESTs? In some cases, such
   * as duplicated tabs, the token will also be gone. We'd probably need
   * to compare tokens or something to prevent it reloading unnecessarily.
   */
  if (isLoggedInState(auth) && auth.userType === AuthUserType.User) {
    yield put(AuthActions.initAuthAction());
  }
}

export function* authSaga(): Generator<Effect> {
  yield all([
    takeEvery(AuthActionType.INIT_AUTH, initAuth),
    takeEvery(AuthActionType.SET_AUTHENTICATING, setAuthenticating),
    takeEvery(AuthActionType.CLEAR_AUTHENTICATING, clearAuthenticating),
    takeEvery(AuthActionType.SET_AUTHENTICATED, setAuthenticated),
    takeEvery(AuthActionType.ASSERT_USER, handleAssertUser),
    takeEvery(AuthActionType.LOGGED_IN, loggedIn),
    takeEvery(AuthActionType.LOGOUT, logout),
    takeEvery(AuthActionType.LOGGED_OUT, loggedOut),
    takeEvery(AuthActionType.SYNC_LOGGED_IN, syncLoggedIn),
    takeEvery(AuthActionType.SYNC_LOGGED_OUT, syncLoggedOut),
  ]);
}
