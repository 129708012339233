import { getContext, put, select } from "redux-saga/effects";
import { canvasObjectIds } from "../../../../const";
import { getParentCollection } from "../../../../studio/components/group/group.utils";
import {
  attachmentTypes,
  getTileIds,
  isInCollection,
  isInGroup,
} from "../../../../studio/utils/fabricObjects";
import { isDefinedTileId } from "../../../../studio/utils/objectConverter";
import { assertUnreachable } from "../../../../tools/assertions";
import { isDefined, unique } from "../../../../tools/utils";
import {
  clearRedoStackAction,
  clearUndoStackAction,
  pruneRedoStackAction,
  pruneUndoStackAction,
} from "../history.actions";
import { HistoryEntry, HistoryEntryType } from "../history.entry.actions";
import { selectHistory } from "../history.reducer";

/**
 * Middleware cleaning history stack from actions that don't cause a meaningful change
 * i.e. if affected objects are removed or grouped by somebody else
 *
 * @NOTE Check in parallel with historyMiddleware to know what's required
 * to correctly undo/redo an action. A good knowledge of signalrMiddleware is also
 * required, as in some case historyMiddleware would work correctly but not signalrMiddleware.
 * E.g. you can still undo Group even if some children has been deleted after being grouped, but
 * signalrMiddleware would send `ungrouped` for objects which don't exist anymore.
 */

const isExpectingParent = (object: fabric.Object): boolean => {
  return isDefinedTileId(object.parentId);
};

const areAllObjectsTopLevel = (objects: fabric.Object[]): boolean => {
  return !objects.some(isInCollection) && !objects.some(isExpectingParent);
};

const areAllObjectsInTheirParents = (
  objects: fabric.Object[],
  expectedParentMap: Map<string, string>
): boolean => {
  return objects.every((object) => {
    const parentId = expectedParentMap.get(object.uuid);
    if (!isDefinedTileId(parentId)) {
      return true;
    }

    const parentCollection = getParentCollection(object);
    return parentCollection && parentCollection.uuid === parentId;
  });
};

const areAllObjectsUnreserved = (objects: fabric.Object[]): boolean => {
  return objects.length > 0 && objects.every((object) => !object.isReserved());
};

const areAllObjectsPresent = (
  objects: fabric.Object[],
  uuids: string[]
): boolean => {
  return objects.length === uuids.length;
};

const canUndo = (action: HistoryEntry, canvas: fabric.CollaboardCanvas) => {
  switch (action.transformationType) {
    case HistoryEntryType.ADD: {
      const allUuids = getTileIds(action.addedObjects);
      const allObjects = canvas.getObjectsByUUIDs(allUuids);

      return (
        areAllObjectsPresent(allObjects, allUuids) &&
        areAllObjectsTopLevel(allObjects)
      );
    }
    case HistoryEntryType.COPY: {
      const allUuids = getTileIds(action.targetObjects);
      const allObjects = canvas.getObjectsByUUIDs(allUuids);

      return (
        areAllObjectsPresent(allObjects, allUuids) &&
        areAllObjectsTopLevel(allObjects)
      );
    }
    case HistoryEntryType.REMOVE: {
      const { removedObjects } = action;

      const allParentUuids = unique(
        removedObjects.map((o) =>
          isDefinedTileId(o.ParentId) ? o.ParentId : null
        )
      ).filter(isDefined);
      const allParents = canvas.getObjectsByUUIDs(allParentUuids);

      return areAllObjectsPresent(allParents, allParentUuids);
    }
    case HistoryEntryType.CONNECT: {
      const allUuids = action.addedConnections.map((c) => c.uuid);
      const allObjects = canvas.getObjectsByUUIDs(allUuids);

      // Checking if the connection still exists is enough as if origin/destination has been removed, so is the connection
      return areAllObjectsPresent(allObjects, allUuids);
    }
    case HistoryEntryType.GROUP: {
      const allUuids = [
        action.addedGroup.TileId,
        ...getTileIds(action.allChildrenAfterGroup),
      ];
      const allObjects = canvas.getObjectsByUUIDs(allUuids);

      // If any group has been grouped it won't be present anymore so no need to check `areAllObjectsTopLevel`
      return areAllObjectsPresent(allObjects, allUuids);
    }
    case HistoryEntryType.UNGROUP: {
      const allUuids = getTileIds(action.objectsAfterUngroup);
      const allObjects = canvas.getObjectsByUUIDs(allUuids);

      return (
        areAllObjectsPresent(allObjects, allUuids) &&
        areAllObjectsTopLevel(allObjects)
      );
    }
    case HistoryEntryType.UNGROUP_ALL: {
      const allUuids = getTileIds(action.objectsAfterUngroup);
      const allObjects = canvas.getObjectsByUUIDs(allUuids);

      return (
        areAllObjectsPresent(allObjects, allUuids) &&
        areAllObjectsTopLevel(allObjects)
      );
    }
    case HistoryEntryType.STACK: {
      // It's not possible to delete the stack children as with groups so we don't need to ensure they still exist
      const allUuids = [action.addedStack.TileId];
      const allObjects = canvas.getObjectsByUUIDs(allUuids);

      // Stacks cannot be grouped or stacked again, so no need to check `areAllObjectsTopLevel`
      return areAllObjectsPresent(allObjects, allUuids);
    }
    case HistoryEntryType.UNSTACK: {
      const allUuids = getTileIds(action.objectsAfterUnstack);
      const allObjects = canvas.getObjectsByUUIDs(allUuids);

      return (
        areAllObjectsPresent(allObjects, allUuids) &&
        areAllObjectsTopLevel(allObjects)
      );
    }
    case HistoryEntryType.MODIFY:
    case HistoryEntryType.MOVE_LAYER: {
      const { affectedUuids, originalObjects } = action;
      const allUuids = affectedUuids || [];
      const allObjects = canvas.getObjectsByUUIDs(allUuids);

      if (action.skipTopLevelObjectsCheck) {
        const expectedParentMap = new Map(
          originalObjects.map((tile) => [tile.TileId, tile.ParentId])
        );
        // Ensure that all objects are still in their original parent
        return (
          areAllObjectsPresent(allObjects, allUuids) &&
          areAllObjectsInTheirParents(allObjects, expectedParentMap)
        );
      }

      return (
        areAllObjectsPresent(allObjects, allUuids) &&
        areAllObjectsTopLevel(allObjects)
      );
    }
    case HistoryEntryType.UPDATE:
    case HistoryEntryType.UPDATE_CONNECTION:
    case HistoryEntryType.STACK_ROTATE:
    case HistoryEntryType.LOCK: {
      const allUuids = action.affectedUuids || [];
      const allObjects = canvas.getObjectsByUUIDs(allUuids);
      return (
        areAllObjectsPresent(allObjects, allUuids) &&
        (action.skipTopLevelObjectsCheck || areAllObjectsTopLevel(allObjects))
      );
    }
    case HistoryEntryType.DUPLICATE:
    case HistoryEntryType.UPLOAD:
    case HistoryEntryType.SELECTION_CHANGED:
      return false;

    case HistoryEntryType.CHANGE_BACKGROUND:
      return true;

    default:
      assertUnreachable(action);
      return false;
  }
};

const canRedo = (action: HistoryEntry, canvas: fabric.CollaboardCanvas) => {
  switch (action.transformationType) {
    case HistoryEntryType.ADD: {
      // Can always redo ADD unless it's attaching to a parent
      if (action.objectType && attachmentTypes.includes(action.objectType)) {
        const attachments = action.addedObjects;
        const allUuids = attachments
          .map((attachment) =>
            isDefinedTileId(attachment.ParentId) ? attachment.ParentId : null
          )
          .filter(isDefined);
        const allObjects = canvas.getObjectsByUUIDs(allUuids);

        return areAllObjectsPresent(allObjects, allUuids);
      }

      return true;
    }
    case HistoryEntryType.REMOVE: {
      if (action.objectType === canvasObjectIds.connector) {
        const allUuids = action.removedConnections.map((c) => c.uuid);
        const allObjects = canvas.getObjectsByUUIDs(allUuids);

        return areAllObjectsPresent(allObjects, allUuids);
      }

      if (action.objectType && attachmentTypes.includes(action.objectType)) {
        const attachments = action.removedObjects;
        const parentIds = attachments
          .map((attachment) =>
            isDefinedTileId(attachment.ParentId) ? attachment.ParentId : null
          )
          .filter(isDefined);
        const allUuids = [...getTileIds(attachments), ...parentIds];
        const allObjects = canvas.getObjectsByUUIDs(allUuids);

        return areAllObjectsPresent(allObjects, allUuids);
      }

      const removedObjectsUuids = getTileIds(action.removedObjects);
      const removedChildrenUuids = getTileIds(action.removedChildren);
      const allUuids = [...removedObjectsUuids, ...removedChildrenUuids];

      const removedObjects = canvas.getObjectsByUUIDs(removedObjectsUuids);
      const removedChildren = canvas.getObjectsByUUIDs(removedChildrenUuids);
      const allObjects = [...removedObjects, ...removedChildren];

      if (removedObjects.some(isInGroup)) {
        /**
         * @TODO We should allow redo of removing a subselected item, but in practise we don't correctly
         * handle it yet in `redoRemoval` in historyMiddleware. We don't have a way to programmatically
         * remove subitems, we only support the user-activated removal when subselection is active.
         */
        return false;
        // const expectedParentMap = new Map(
        //   action.removedObjects.map((tile) => [tile.TileId, tile.ParentId])
        // );

        // return (
        //   areAllObjectsPresent(allObjects, allUuids) &&
        //   areAllObjectsInTheirParents(removedObjects, expectedParentMap)
        // );
      }

      return (
        areAllObjectsPresent(allObjects, allUuids) &&
        areAllObjectsTopLevel(removedObjects)
      );
    }
    case HistoryEntryType.CONNECT: {
      const allUuids = action.affectedUuids || [];
      const allObjects = canvas.getObjectsByUUIDs(allUuids);
      return areAllObjectsPresent(allObjects, allUuids);
    }
    case HistoryEntryType.GROUP: {
      const groupedChildrenTiles = Object.values(
        action.childrenByGroupBeforeGroup
      )
        .filter(isDefined)
        .flat();

      const topLevelObjectsUuids = getTileIds(
        action.topLevelObjectsBeforeGroup
      );
      const allUuids = [
        ...topLevelObjectsUuids,
        ...getTileIds(action.groupsBeforeGroup),
        ...getTileIds(groupedChildrenTiles),
      ];

      const topLevelObjects = canvas.getObjectsByUUIDs(topLevelObjectsUuids);
      const allObjects = canvas.getObjectsByUUIDs(allUuids);

      // If any group has been grouped it won't be present anymore so no need to check `areAllObjectsTopLevel` for them
      return (
        areAllObjectsPresent(allObjects, allUuids) &&
        areAllObjectsTopLevel(topLevelObjects)
      );
    }
    case HistoryEntryType.UNGROUP: {
      const groupObject = canvas.getObjectByUUID(
        action.groupBeforeUngroup.TileId
      );
      const allUuids = [
        ...getTileIds(action.removedChildren),
        action.groupBeforeUngroup.TileId,
        ...getTileIds(action.childrenBeforeUngroup),
      ];
      const allObjects = canvas.getObjectsByUUIDs(allUuids);

      return (
        areAllObjectsPresent(allObjects, allUuids) &&
        groupObject &&
        areAllObjectsTopLevel([groupObject])
      );
    }
    case HistoryEntryType.UNGROUP_ALL: {
      const allUuids = [
        ...getTileIds(action.groupsBeforeUngroup),
        ...getTileIds(action.childrenBeforeUngroup),
      ];
      const allObjects = canvas.getObjectsByUUIDs(allUuids);

      return areAllObjectsPresent(allObjects, allUuids);
    }
    case HistoryEntryType.STACK: {
      const allUuids = getTileIds(action.childrenAfterStack);
      const allObjects = canvas.getObjectsByUUIDs(allUuids);

      return (
        areAllObjectsPresent(allObjects, allUuids) &&
        areAllObjectsTopLevel(allObjects)
      );
    }
    case HistoryEntryType.UNSTACK: {
      const allUuids = [action.removedStack.TileId];
      const allObjects = canvas.getObjectsByUUIDs(allUuids);

      return areAllObjectsPresent(allObjects, allUuids);
    }
    case HistoryEntryType.LOCK: {
      const allUuids = action.affectedUuids;
      const allObjects = canvas.getObjectsByUUIDs(allUuids);

      return (
        areAllObjectsPresent(allObjects, allUuids) &&
        areAllObjectsTopLevel(allObjects) &&
        areAllObjectsUnreserved(allObjects)
      );
    }
    case HistoryEntryType.MODIFY:
    case HistoryEntryType.MOVE_LAYER: {
      const { affectedUuids, originalObjects } = action;
      const allUuids = affectedUuids || [];
      const allObjects = canvas.getObjectsByUUIDs(allUuids);

      if (action.skipTopLevelObjectsCheck) {
        const expectedParentMap = new Map(
          originalObjects.map((tile) => [tile.TileId, tile.ParentId])
        );
        // Ensure that all objects are still in their original parent
        return (
          areAllObjectsPresent(allObjects, allUuids) &&
          areAllObjectsInTheirParents(allObjects, expectedParentMap)
        );
      }

      return (
        areAllObjectsPresent(allObjects, allUuids) &&
        areAllObjectsTopLevel(allObjects)
      );
    }
    case HistoryEntryType.UPDATE:
    case HistoryEntryType.UPDATE_CONNECTION:
    case HistoryEntryType.STACK_ROTATE: {
      const allUuids = action.affectedUuids || [];
      const allObjects = canvas.getObjectsByUUIDs(allUuids);
      return (
        areAllObjectsPresent(allObjects, allUuids) &&
        (action.skipTopLevelObjectsCheck || areAllObjectsTopLevel(allObjects))
      );
    }
    case HistoryEntryType.DUPLICATE:
    case HistoryEntryType.UPLOAD:
    case HistoryEntryType.SELECTION_CHANGED:
      return false;

    case HistoryEntryType.COPY:
    case HistoryEntryType.CHANGE_BACKGROUND:
      return true;

    default:
      assertUnreachable(action);
      return false;
  }
};

/**
 * Remove items from the history stack that are no longer possible.
 *
 * This can happen if another user performs an action that makes something
 * impossible.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* cleanHistoryStackSaga() {
  const history = selectHistory(yield select());
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  const firstValidUndoIndex = history.undo
    .slice()
    .reverse()
    .findIndex((action) => {
      return canUndo(action, canvas);
    });

  if (firstValidUndoIndex === -1 && history.undo.length) {
    // No valid actions found - clear undo stack
    yield put(clearUndoStackAction());
  } else if (firstValidUndoIndex > 0) {
    // First valid action is not first - remove invalid actions
    yield put(
      pruneUndoStackAction({
        pruneToIndex: history.undo.length - firstValidUndoIndex - 1,
      })
    );
  }

  const firstValidRedoIndex = history.redo.findIndex((action) => {
    return canRedo(action, canvas);
  });

  if (firstValidRedoIndex === -1 && history.redo.length) {
    // No valid actions found - clear redo stack
    yield put(clearRedoStackAction());
  } else if (firstValidRedoIndex > 0) {
    // First valid action is not first - remove invalid actions
    yield put(pruneRedoStackAction({ pruneToIndex: firstValidRedoIndex }));
  }
}
