import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Bell(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M29,27.26a6.65,6.65,0,0,1-2.41-5.13V17.36A6.5,6.5,0,0,0,22,11.14a2.13,2.13,0,0,0,.26-1,2.15,2.15,0,1,0-4.29,0,2.13,2.13,0,0,0,.25,1,6.51,6.51,0,0,0-4.61,6.22v4.77a6.67,6.67,0,0,1-2.4,5.13,1.1,1.1,0,0,0-.35,1.25,1.13,1.13,0,0,0,1.07.74h6.16a3.61,3.61,0,0,0,7.08,0h3.14a1.12,1.12,0,0,0,1.06-.74A1.13,1.13,0,0,0,29,27.26ZM20.11,9.4a.73.73,0,1,1,0,1.46.74.74,0,0,1-.74-.74A.73.73,0,0,1,20.11,9.4Zm1.51,21.34a2.22,2.22,0,0,1-2.08-1.49H23.7A2.2,2.2,0,0,1,21.62,30.74Zm-9-2.9A8,8,0,0,0,15,22.13V17.36a5.09,5.09,0,1,1,10.17,0v4.77a8.06,8.06,0,0,0,2.37,5.71Z" />
      <path d="M31.38,18.3a.71.71,0,0,0-1,1,3.6,3.6,0,0,1,0,5.06.71.71,0,0,0,0,1,.77.77,0,0,0,1,0,5,5,0,0,0,1.46-3.53A5,5,0,0,0,31.38,18.3Z" />
      <path d="M28.36,20.32a.7.7,0,0,0,0,1,.75.75,0,0,1,.21.51.72.72,0,0,1-.21.51.7.7,0,0,0,0,1,.77.77,0,0,0,1,0,2.15,2.15,0,0,0,0-3A.69.69,0,0,0,28.36,20.32Z" />
      <path d="M8.79,21.84a3.56,3.56,0,0,1,1.05-2.53.71.71,0,1,0-1-1,4.94,4.94,0,0,0-1.45,3.53,5,5,0,0,0,1.45,3.53.77.77,0,0,0,1,0,.72.72,0,0,0,0-1A3.54,3.54,0,0,1,8.79,21.84Z" />
      <path d="M11.86,23.35a.71.71,0,0,0,0-1,.71.71,0,0,1-.21-.51.68.68,0,0,1,.21-.51.71.71,0,1,0-1-1,2.15,2.15,0,0,0,0,3A.76.76,0,0,0,11.86,23.35Z" />
    </Icon>
  );
}
export default Bell;
