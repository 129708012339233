import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Button, Text } from "theme-ui";

type Props = {
  onAvatarClick(): void;
  isAvatarEditMode: boolean;
};

function AvatarEditButton({
  onAvatarClick,
  isAvatarEditMode,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <Text>
      <Button type="button" variant="link" onClick={onAvatarClick}>
        {isAvatarEditMode
          ? t("form.button.cancel")
          : t("form.button.changePhoto")}
      </Button>
    </Text>
  );
}

export default AvatarEditButton;
