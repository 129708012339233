// max
export const maxURLLength = 2048;
export const maxCommentTextLength = 2000;
export const maxQuickLinkNameLength = 256;
export const maxProjectDescriptionLength = 100;
export const maxSpaceDescriptionLength = 100;
export const maxVotingSessionNameLength = 100;
export const maxUserNameLength = 40;
export const maxOtpCodeLength = 6;

// Password
export const minPasswordLength = 8;
export const maxPasswordLength = 64;
