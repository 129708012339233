import { combineReducers } from "redux";
import { BrushType, brushTypeConfig } from "../../../const";
import { DrawingAction, DrawingActionType } from "./drawing.actions";

type InkSizes = {
  [brushType in BrushType]?: number;
};

type DrawingInkState = {
  fillColor: string;
  inkSizes: InkSizes;
  strokeWidth: number;
  brushType: BrushType;
  previousBrushType: BrushType;
};

export type DrawingState = {
  inkState: DrawingInkState;
  openSubmenuId: string | null;
};

export const defaultBrushType = BrushType.nib;
export const defaultBrushSize = brushTypeConfig[defaultBrushType].default;

export const getInitialInkState = (): DrawingInkState => {
  return {
    brushType: defaultBrushType,
    previousBrushType: defaultBrushType,
    fillColor: "#000000",
    strokeWidth: brushTypeConfig[defaultBrushType].default,
    inkSizes: {},
  };
};
const getInitialDrawingState = (): DrawingState => {
  return {
    inkState: getInitialInkState(),

    openSubmenuId: null,
  };
};

function drawingInkStateReducer(
  state = getInitialDrawingState().inkState,
  action: DrawingAction
): DrawingInkState {
  switch (action.type) {
    case DrawingActionType.SET_DRAWING_BRUSH_TYPE: {
      const brushType = action.payload;

      return {
        ...state,
        brushType,
        previousBrushType:
          state.brushType === brushType || state.brushType === BrushType.rubber
            ? state.previousBrushType
            : state.brushType,
      };
    }
    case DrawingActionType.SET_DRAWING_COLOR: {
      const fillColor = action.payload;

      return {
        ...state,
        fillColor,
      };
    }
    case DrawingActionType.SET_DRAWING_STROKE_WIDTH: {
      const strokeWidth = action.payload;

      return {
        ...state,
        inkSizes: {
          [state.brushType]: strokeWidth,
        },
        strokeWidth,
      };
    }
    default:
      return state;
  }
}

function drawingSubmenuReducer(
  state = getInitialDrawingState().openSubmenuId,
  action: DrawingAction
): string | null {
  switch (action.type) {
    case DrawingActionType.SET_DRAWING_SUBMENU: {
      return action.payload;
    }
    default:
      return state;
  }
}

export const drawingReducer = combineReducers<DrawingState>({
  inkState: drawingInkStateReducer,
  openSubmenuId: drawingSubmenuReducer,
});

export const selectDrawingInkState = (
  state: ApplicationGlobalState
): DrawingInkState => {
  return state.canvas.drawing.inkState;
};

export const selectOpenDrawingSubmenu = (
  state: ApplicationGlobalState
): string | null => {
  return state.canvas.drawing.openSubmenuId;
};
