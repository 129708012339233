import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Help(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M16.73,17.58a2.37,2.37,0,0,1,.39-1.25,3,3,0,0,1,1.16-1,3.63,3.63,0,0,1,1.78-.42,3.79,3.79,0,0,1,1.66.35,2.68,2.68,0,0,1,1.12.94,2.36,2.36,0,0,1,.39,1.31,2,2,0,0,1-.22,1,3.05,3.05,0,0,1-.53.72q-.32.32-1.11,1a4,4,0,0,0-.36.36,1.47,1.47,0,0,0-.2.28,1.3,1.3,0,0,0-.1.25c0,.08-.06.23-.11.45a.72.72,0,0,1-.77.67.77.77,0,0,1-.57-.22.9.9,0,0,1-.23-.66,2.43,2.43,0,0,1,.17-.94,2.38,2.38,0,0,1,.45-.71,9.13,9.13,0,0,1,.75-.71c.28-.25.48-.43.6-.55a2.12,2.12,0,0,0,.32-.42,1,1,0,0,0,.12-.49,1.14,1.14,0,0,0-.38-.88,1.65,1.65,0,0,0-2.06,0,3,3,0,0,0-.57,1.07c-.15.49-.43.73-.84.73a.81.81,0,0,1-.61-.25A.8.8,0,0,1,16.73,17.58Zm3.17,7.13a1,1,0,0,1-.69-.26.89.89,0,0,1-.3-.72A.94.94,0,0,1,19.2,23a1,1,0,0,1,1.39,0,.94.94,0,0,1,.28.69.92.92,0,0,1-.29.72A1,1,0,0,1,19.9,24.71Z" />
      <path d="M20,30.21A10.16,10.16,0,1,1,30.16,20.05,10.17,10.17,0,0,1,20,30.21Zm0-19.12a9,9,0,1,0,9,9A9,9,0,0,0,20,11.09Z" />
    </Icon>
  );
}
export default Help;
