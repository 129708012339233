import { ActionCreator, AnyAction } from "redux";
import { EventChannel } from "redux-saga";
import {
  call,
  cancelled,
  Effect,
  getContext,
  put,
  select,
  take,
} from "redux-saga/effects";
import {
  AllCopiedTiles,
  getAllPagesForCopiedTiles,
  getTilesInfo,
  requestBigInk,
} from "../../../../api";
import { SignalRClient } from "../../../../api/signalR";
import type {
  InkTileStatus,
  TileStatus,
} from "../../../../api/signalR/message.types";
import { NotifyMessageName } from "../../../../api/signalR/protobuf.codecs";
import { OnMessageType } from "../../../../api/signalR/SignalRProtobufClient";
import {
  blobStates,
  canvasObjectIds,
  CopyTilesTrigger,
} from "../../../../const";
import { runWithoutStashedGroups } from "../../../../studio/components/group/group.utils";
import { runWithoutAffectingSelection } from "../../../../studio/components/patches/extends-selection/selection.utils";
import {
  getObjectByProperty,
  isPDFDocument,
} from "../../../../studio/utils/fabricObjects";
import {
  deserializeRelation,
  isTileBatchActionGroupInfo,
  isTileBatchActionObjectInfo,
  tileBatchActionGroupInfoToTileStatus,
} from "../../../../studio/utils/objectConverter";
import { findUnknownUserInfo } from "../../../../tools/avatarGenerator";
import collaboard from "../../../../tools/collaboard";
import { convertByteDataToDataUrl } from "../../../../tools/files";
import { isDefined } from "../../../../tools/utils";
import { signalRNotifyChannel } from "../../../redux.utils";
import {
  addConnections,
  addObjects,
  changeBlobStatus,
  deleteObjects,
  moveGroupedObjects,
  ungroupObjects,
  updateGroupZIndexes,
  updateObject,
  updateObjectsReservation,
  updateTopLevelZIndexes,
} from "../../helpers/objects";
import { resetStatusAction } from "../../project/project.actions";
import {
  selectProjectId,
  selectProjectMyself,
  selectProjectUsers,
} from "../../project/project.reducer";
import { cleanHistoryStackAction } from "../history.actions";
import { copiedAction } from "../history.entry.actions";
import {
  HistoryNotifyAction,
  NotifyActionType,
  OnNotifyDeleteAction,
  onNotifyDeleteAction,
  OnNotifyLinkCreatedAction,
  onNotifyLinkCreatedAction,
  OnNotifyLinkDeletedAction,
  onNotifyLinkDeletedAction,
  OnNotifyLowResThumbnailAvailableAction,
  onNotifyLowResThumbnailAvailableAction,
  OnNotifyNewAction,
  onNotifyNewAction,
  OnNotifyNewBigInkAction,
  onNotifyNewBigInkAction,
  OnNotifyPinningActivityAction,
  onNotifyPinningActivityAction,
  OnNotifyResponsiveImagesAvailableAction,
  onNotifyResponsiveImagesAvailableAction,
  OnNotifyThumbnailAvailableAction,
  onNotifyThumbnailAvailableAction,
  OnNotifyThumbnailsCreatedAction,
  onNotifyThumbnailsCreatedAction,
  OnNotifyThumbnailsCreatingAction,
  onNotifyThumbnailsCreatingAction,
  OnNotifyThumbnailsFailedAction,
  onNotifyThumbnailsFailedAction,
  OnNotifyTileBatchAction,
  onNotifyTileBatchAction,
  OnNotifyTileIndexesChangeAction,
  onNotifyTileIndexesChangeAction,
  OnNotifyTilePropertiesChangedAction,
  onNotifyTilePropertiesChangedAction,
  OnNotifyTileRelationChangedAction,
  onNotifyTileRelationChangedAction,
  OnNotifyTileReorderAction,
  onNotifyTileReorderAction,
  OnNotifyTilesCopiedAction,
  onNotifyTilesCopiedAction,
  OnNotifyUploadedAction,
  onNotifyUploadedAction,
} from "./signalR-notify.actions";

export const addReservedObjects = async (
  projectUsers: ProjectOnlineUserInfo[],
  tiles: TileStatus[],
  canvas: fabric.CollaboardCanvas
): Promise<fabric.Object[]> => {
  const userName = tiles[0]?.LockedUser;
  const sortedObjects = await addObjects(canvas, tiles);

  if (userName) {
    const user = findUnknownUserInfo(projectUsers, userName);
    updateObjectsReservation(
      canvas,
      tiles.map((t) => t.TileId),
      user
    );
  }

  return sortedObjects;
};

const {
  deleted,
  thumbnailsAvailable,
  creatingThumbnails,
  thumbnailError,
} = blobStates;

/* eslint-disable require-yield */

/**
 * @NOTE In signalR notify handlers we typically use `canvas.sortAndReassignRootZIndexes({ skipSave: true });`
 * Otherwise, it can potentially result in infinite NotifyTileIndexesChange messages
 * if the zIndexable objects don't have the same zIndex of the sender. It would
 * trigger a new NotifyTileIndexesChange message and likewise in the other users
 * on cascade. We avoid this possibility by avoiding to propagate further changes
 * to the server, although this may mean that the synced tab has a wrong objects order.
 */

function* notifyDelete({ payload }: OnNotifyDeleteAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const { TileIds } = payload;

  yield call(runWithoutStashedGroups, canvas, async () => {
    await deleteObjects(canvas, TileIds);
  });

  canvas.requestRenderAll();
  yield put(cleanHistoryStackAction());
}

function* notifyLowResThumbnailAvailable({
  payload,
}: OnNotifyLowResThumbnailAvailableAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const { ImageData, ParentTileId } = payload;

  if (ImageData) {
    const object = canvas.getObjectByUUID<fabric.CollaboardDocument>(
      ParentTileId
    );
    if (object) {
      const dataUrl: string = yield call(convertByteDataToDataUrl, ImageData);
      dataUrl && object.onLowResPreviewAvailable(dataUrl);
    }
  }
}

function* notifyNew({ payload }: OnNotifyNewAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const users = selectProjectUsers(yield select());

  yield call(addReservedObjects, users, payload.Tiles, canvas);

  canvas.requestRenderAll();
  yield put(cleanHistoryStackAction());
}

function* notifyNewBigInk({ payload }: OnNotifyNewBigInkAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const { TileId } = payload;

  // check if tile exist, it means that tile was added by this user,
  const exist = canvas.getObjectByUUID(TileId);
  if (exist) {
    return Promise.resolve();
  }

  const projectId = selectProjectId(yield select());
  const { Tile }: { Tile: InkTileStatus } = yield call(
    requestBigInk,
    projectId,
    TileId
  );
  yield call(addObjects, canvas, [Tile]);
  canvas.requestRenderAll();

  return Promise.resolve();
}

function* notifyObjectLinkCreated({ payload }: OnNotifyLinkCreatedAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const { SourceTileId, TargetTileId, TargetUrl } = payload;

  const object = canvas.getObjectByUUID(SourceTileId);
  if (object) {
    object.objectLink = {
      LinkType: payload.LinkType,
      QuickLinkId: payload.QuickLinkId,
      SourceTileId,
      TargetTileId,
      TargetUrl,
    };
  }
}

function* notifyObjectLinkDeleted({ payload }: OnNotifyLinkDeletedAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  const object = canvas.getObjectByUUID(payload.SourceTileId);
  if (object) {
    delete object.objectLink;
  }
}

function* notifyPinningActivity({ payload }: OnNotifyPinningActivityAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const { PinnedTiles } = payload;

  const tileIdMap = PinnedTiles.reduce<Record<string, boolean>>(
    (result, item) => {
      return {
        ...result,
        [item.TileId]: item.IsPinned,
      };
    },
    {}
  );
  const tileIds = Object.keys(tileIdMap);

  const objects = canvas.getObjectsByUUIDs(tileIds);

  canvas.discardReadOnlyActiveObject(objects);
  objects.forEach((object) => {
    const isPinned = tileIdMap[object.uuid];
    object.setPinned(isPinned);
  });
  canvas.requestRenderAll();
}

function* notifyResponsiveImagesAvailable({
  payload,
}: OnNotifyResponsiveImagesAvailableAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const { ImageTileId, ImageContent } = payload;

  if (ImageContent) {
    const object = canvas.getObjectByUUID<fabric.CollaboardImage>(ImageTileId);
    object?.setResizedImages(ImageContent.ResizedImages.slice());
  }
}

function* notifyThumbnailAvailable({
  payload,
}: OnNotifyThumbnailAvailableAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const { ParentTileId, NumberOfThumbnails, ThumbnailId } = payload;

  const document = canvas.getObjectByUUID<fabric.CollaboardDocument>(
    ParentTileId
  );
  document?.onPreviewAvailable(NumberOfThumbnails, ThumbnailId);
}

function* notifyThumbnailsCreated({
  payload,
}: OnNotifyThumbnailsCreatedAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  changeBlobStatus(payload.ParentTileId, thumbnailsAvailable, canvas);
}

function* notifyUploaded({ payload }: OnNotifyUploadedAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  changeBlobStatus(payload.TileId, payload.AzureBlobStatus, canvas);
}

function* notifyThumbnailsCreating({
  payload,
}: OnNotifyThumbnailsCreatingAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  changeBlobStatus(payload.ParentTileId, creatingThumbnails, canvas);
}

function* notifyThumbnailsFailed({ payload }: OnNotifyThumbnailsFailedAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  const object = canvas.getObjectByUUID(payload.ParentTileId);
  if (object && isPDFDocument(object)) {
    /**
     * #7555 - Server PDF thumbnail creation is currently broken. Ignore these
     * messages for PDF documents.
     *
     * See https://ibvsolutions.visualstudio.com/CollaBoardWeb/_workitems/edit/7555
     */
    return;
  }

  changeBlobStatus(payload.ParentTileId, thumbnailError, canvas);
}

function* notifyTilesCopied({ payload }: OnNotifyTilesCopiedAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const { Token, CopyTrigger } = payload;
  const myself = selectProjectMyself(yield select());
  const users = selectProjectUsers(yield select());

  const {
    relations,
    tiles,
    originalUuids,
    userName,
    sourceProjectId,
  }: AllCopiedTiles = yield call(getAllPagesForCopiedTiles, Token);

  // TODO: temporary fix: #3338, until server team fix issue
  const newTiles = tiles.filter((t) => t.AzureBlobStatus !== deleted);

  const objects: fabric.Object[] = yield call(
    addReservedObjects,
    users,
    newTiles,
    canvas
  );
  const objectsAddedByMe = objects.length && userName === myself?.UserName;

  yield call(addConnections, canvas, relations);

  if (objectsAddedByMe) {
    canvas.sortAndReassignRootZIndexes();
    yield put(
      copiedAction({
        objects,
        originalUuids,
        sourceProjectId,
      })
    );
  }

  if (CopyTrigger === CopyTilesTrigger.ApplyProjectTemplate) {
    yield put(resetStatusAction());
    if (objectsAddedByMe) {
      canvas.panToObjects(objects);
    }
  }

  canvas.requestRenderAll();
}

function* notifyTileBatch({ payload }: OnNotifyTileBatchAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const {
    Added,
    Restored,
    Grouped,
    Ungrouped,
    Deleted,
    Locked,
    Tiles,
    User,
  } = payload;
  /**
   * @TODO #6665: Assume that we can create only group tiles. This will become true when we have
   * restoredIds for Undo Ungroup with deleted 2nd last item
   */
  const added = Added.map((index) => {
    return Tiles[index];
  })
    .filter(isDefined)
    .filter(isTileBatchActionGroupInfo);
  const grouped = Grouped.map((index) => {
    return Tiles[index];
  })
    .filter(isDefined)
    .filter(isTileBatchActionObjectInfo);
  const ungrouped = Ungrouped.map((index) => {
    return Tiles[index];
  })
    .filter(isDefined)
    .filter(isTileBatchActionObjectInfo);

  const restoredIds = Restored.map((index) => {
    return Tiles[index];
  })
    .filter(isDefined)
    .map((tile) => tile.Id)
    .filter(isDefined);

  const deletedIds = Deleted.map((index) => {
    return Tiles[index];
  })
    .filter(isDefined)
    .map((tile) => tile.Id)
    .filter(isDefined);
  const lockedIds = Locked.map((index) => {
    return Tiles[index];
  })
    .filter(isDefined)
    .map((tile) => tile.Id)
    .filter(isDefined);

  const addedTiles = added.map(tileBatchActionGroupInfoToTileStatus);

  // Objects whose changes may affect what the user has selected
  const selectionAffectingUuids = [
    ...grouped.map((tile) => tile.Id),
    ...ungrouped.map((tile) => tile.Id),
    ...deletedIds,
    ...lockedIds,
  ];

  const projectId = selectProjectId(yield select());
  const users = selectProjectUsers(yield select());

  // Restore selection before updating reservations as it can affect user's own selection,
  // in case of multiple tabs in the same project
  yield call(
    runWithoutAffectingSelection,
    canvas,
    async () => {
      await runWithoutStashedGroups(canvas, async () => {
        addedTiles.length &&
          (await addReservedObjects(users, addedTiles, canvas));

        if (restoredIds.length) {
          const { Tiles, Relations } = await getTilesInfo(
            projectId,
            restoredIds
          );

          Tiles.length && (await addReservedObjects(users, Tiles, canvas));
          Relations.length && (await addConnections(canvas, Relations));
        }

        grouped.length && moveGroupedObjects(canvas, grouped, "serverUpdate");
        ungrouped.length && ungroupObjects(canvas, ungrouped, "serverUpdate");
        deletedIds.length && (await deleteObjects(canvas, deletedIds));
      });
    },
    selectionAffectingUuids
  );

  // Even if there are no locked objects, it means we have to update reservation
  const user = findUnknownUserInfo(users, User);
  updateObjectsReservation(canvas, lockedIds, user);

  canvas.sortAndReassignRootZIndexes({ isSilent: true });
  canvas.requestRenderAll();
  yield put(cleanHistoryStackAction());
}

function* notifyTileIndexesChange({
  payload,
}: OnNotifyTileIndexesChangeAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  runWithoutStashedGroups(canvas, () => {
    updateTopLevelZIndexes(canvas, payload.TileIndexes, "serverUpdate");
  });
  canvas.requestRenderAll();
}

function* notifyTilePropertiesChanged({
  payload,
}: OnNotifyTilePropertiesChangedAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const updates: Array<{
    object: fabric.Object;
    Delta: string;
  }> = payload.Deltas.map(({ Delta, TileId }) => {
    const object = canvas.getObjectByUUID(TileId);
    return { object, Delta };
  }).filter(({ object }) => isDefined(object)) as Array<{
    object: fabric.Object;
    Delta: string;
  }>;

  const affectedUuids = updates.map(({ object }) => object.uuid);

  runWithoutAffectingSelection(
    canvas,
    () => {
      runWithoutStashedGroups(canvas, () => {
        updates.forEach(({ object, Delta }) => {
          updateObject(object, JSON.parse(Delta), "serverUpdate");
        });
      });
    },
    affectedUuids
  );

  canvas.requestRenderAll();
}

function* notifyTileReorder({ payload }: OnNotifyTileReorderAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  runWithoutStashedGroups(canvas, () => {
    updateGroupZIndexes(canvas, payload.GroupedTiles, "serverUpdate");
  });
  canvas.requestRenderAll();
}

function* notifyTileRelationChanged({
  payload,
}: OnNotifyTileRelationChangedAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const signalRClient: SignalRClient = yield getContext("signalRClient");

  const { Relation, RelationDeleted } = payload;

  if (!Relation?.SyncId || !Relation.Id) {
    return;
  }

  const connections = canvas.getObjects(canvasObjectIds.connector);
  const uuid = signalRClient.syncIdToRelationIdMap.get(Relation.SyncId);

  const connectionByUUID = getObjectByProperty<fabric.CollaboardConnector>(
    connections,
    "uuid",
    uuid
  );
  const connectionByRelationId = getObjectByProperty<fabric.CollaboardConnector>(
    connections,
    "relationId",
    Relation.Id
  );

  if (RelationDeleted) {
    if (connectionByRelationId) {
      canvas.remove(connectionByRelationId);
    }
  } else if (connectionByUUID) {
    // Connection has been added - assign the relationId from the server
    connectionByUUID.setRelationId(Relation.Id);
    signalRClient.syncIdToRelationIdMap.delete(Relation.SyncId);
  } else if (connectionByRelationId) {
    // Connection has been modified - update properties
    const props = deserializeRelation(Relation);
    connectionByRelationId.setContextProps(props);
  } else {
    // Connection has been created - add it to the canvas
    addConnections(canvas, [Relation]).then(() => canvas.requestRenderAll());
    return;
  }

  canvas.requestRenderAll();
  yield put(cleanHistoryStackAction());
}

const onNotifySagas: {
  [key in NotifyActionType]: (
    action: ActionInUnionWithType<HistoryNotifyAction, key>
  ) => Generator<Effect>;
} = {
  ON_NOTIFY_DELETE: notifyDelete,
  ON_NOTIFY_LOW_RES_THUMBNAIL_AVAILABLE: notifyLowResThumbnailAvailable,
  ON_NOTIFY_NEW_BIG_INK: notifyNewBigInk,
  ON_NOTIFY_NEW: notifyNew,
  ON_NOTIFY_OBJECT_LINK_CREATED: notifyObjectLinkCreated,
  ON_NOTIFY_OBJECT_LINK_DELETED: notifyObjectLinkDeleted,
  ON_NOTIFY_PINNING: notifyPinningActivity,
  ON_NOTIFY_RESPONSIVE_IMAGES_AVAILABLE: notifyResponsiveImagesAvailable,
  ON_NOTIFY_THUMBNAIL_AVAILABLE: notifyThumbnailAvailable,
  ON_NOTIFY_THUMBNAILS_CREATED: notifyThumbnailsCreated,
  ON_NOTIFY_THUMBNAILS_CREATING: notifyThumbnailsCreating,
  ON_NOTIFY_THUMBNAILS_FAILED: notifyThumbnailsFailed,
  ON_NOTIFY_TILE_BATCH: notifyTileBatch,
  ON_NOTIFY_TILE_INDEXES_CHANGE: notifyTileIndexesChange,
  ON_NOTIFY_TILE_PROPERTIES_CHANGED: notifyTilePropertiesChanged,
  ON_NOTIFY_TILE_RELATION_CHANGE: notifyTileRelationChanged,
  ON_NOTIFY_TILE_REORDER: notifyTileReorder,
  ON_NOTIFY_TILES_COPIED: notifyTilesCopied,
  ON_NOTIFY_UPLOADED: notifyUploaded,
};

const eventToAction: { [key in OnMessageType]?: ActionCreator<AnyAction> } = {
  [NotifyMessageName.DeleteTileMessage]: onNotifyDeleteAction,
  [NotifyMessageName.LinkCreatedMessage]: onNotifyLinkCreatedAction,
  [NotifyMessageName.LinkDeletedMessage]: onNotifyLinkDeletedAction,
  [NotifyMessageName.LinkUpdatedMessage]: onNotifyLinkCreatedAction,
  [NotifyMessageName.LowResThumbnailAvailableMessage]: onNotifyLowResThumbnailAvailableAction,
  [NotifyMessageName.NewMessage]: onNotifyNewAction,
  [NotifyMessageName.NewBigInkMessage]: onNotifyNewBigInkAction,
  [NotifyMessageName.PinMessage]: onNotifyPinningActivityAction,
  [NotifyMessageName.ResponsiveImagesAvailableMessage]: onNotifyResponsiveImagesAvailableAction,
  [NotifyMessageName.SingleThumbnailsMessage]: onNotifyThumbnailAvailableAction,
  [NotifyMessageName.ThumbnailsCreatedMessage]: onNotifyThumbnailsCreatedAction,
  [NotifyMessageName.ThumbnailsCreatingMessage]: onNotifyThumbnailsCreatingAction,
  [NotifyMessageName.ThumbnailCreationFailedMessage]: onNotifyThumbnailsFailedAction,
  [NotifyMessageName.TileBatchActionMessage]: onNotifyTileBatchAction,
  [NotifyMessageName.TileIndexesMessage]: onNotifyTileIndexesChangeAction,
  [NotifyMessageName.TilePropertyMessage]: onNotifyTilePropertiesChangedAction,
  [NotifyMessageName.TileRelationMessage]: onNotifyTileRelationChangedAction,
  [NotifyMessageName.TileGroupMessage]: onNotifyTileReorderAction,
  [NotifyMessageName.TilesCopiedMessage]: onNotifyTilesCopiedAction,
  [NotifyMessageName.UploadedMessage]: onNotifyUploadedAction,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* signalRHistoryNotifySaga() {
  const notifyChannel: EventChannel<HistoryNotifyAction> = yield call(
    signalRNotifyChannel,
    collaboard.signalRClient,
    eventToAction
  );

  try {
    while (true) {
      const action: HistoryNotifyAction = yield take(notifyChannel);

      // Always dispatch the action from the SignalR event as it may needed by other middlewares/reducers
      yield put(action);

      const handler = onNotifySagas[action.type] as (
        action: HistoryNotifyAction
      ) => Generator<Effect>;

      // If there's a handler, we run the handler saga sequentially. If new SignalR messages arrive meanwhile,
      // they will be buffered inside the `notifyChannel`
      if (handler) {
        yield call(handler, action);
      }
    }
  } finally {
    const isCancelled: boolean = yield cancelled(); // Cancelled by exiting the project

    if (isCancelled) {
      // Close the EventChannel so that the unsubscribe function is called and listeners removed
      notifyChannel.close();
    }
  }
}
