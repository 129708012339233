export enum EditingActionType {
  ENTER_EDITING_MODE = "ENTER_EDITING_MODE",
}

export const enterEditingModeAction = () =>
  ({
    type: EditingActionType.ENTER_EDITING_MODE,
    payload: undefined,
  } as const);

export type EditingAction = ReturnType<typeof enterEditingModeAction>;
