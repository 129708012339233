import React, { PropsWithChildren, ReactElement } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useParams } from "react-router-dom";
import { LogCategory, onErrorToLog } from "../../../tools/telemetry";

function AppErrorBoundary({
  children,
}: PropsWithChildren<unknown>): ReactElement {
  // The params may contain the ProjectId from the path
  const { ProjectId } = useParams<AnyRouteParams>();

  return (
    <ErrorBoundary
      FallbackComponent={() => null}
      onError={(error, { componentStack }) => {
        onErrorToLog(error, LogCategory.react, {
          project: ProjectId ? { ProjectId } : undefined,
          data: { componentStack },
        });
      }}
    >
      {children}
    </ErrorBoundary>
  );
}

export default AppErrorBoundary;
