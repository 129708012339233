import { InternalError } from "../errors/InternalError";
import { LogCategory, onErrorToLog } from "./telemetry";

export const assertNotUndefined = <T>(
  value: T | undefined,
  name: string
): T => {
  if (value === undefined) {
    throw new Error(`Unexpected undefined: ${name}`);
  }

  return value;
};

export const assertNotNull = <T>(value: T | null, name: string): T => {
  if (value === null) {
    throw new Error(`Unexpected null: ${name}`);
  }

  return value;
};

export const assertUnreachable = (value: never, errorMsg?: string): never => {
  const error = new InternalError(errorMsg || `Unexpected case value ${value}`);

  onErrorToLog(error, LogCategory.internal, { value });

  throw error;
};
