import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Image, Text } from "theme-ui";
import CreditCard from "../../../icons/CreditCard";

function PaymentMethod() {
  const { t } = useTranslation();
  return (
    <Box>
      <Text sx={{ fontSize: [5], mb: [2] }}>
        {t("dialog.payment.paymentMethod")}
      </Text>

      <Flex sx={containerStyle}>
        <Flex sx={{ alignItems: "center" }}>
          <CreditCard noShadow />
          <Text>{t("dialog.payment.creditCard")}</Text>
        </Flex>
        <Image src="/cards.jpg" sx={{ height: "30px" }} />
      </Flex>
    </Box>
  );
}

const containerStyle = {
  justifyContent: "space-between",
  flex: 1,
  height: "40px",
  alignItems: "center",
  pr: [4],
};

export default PaymentMethod;
