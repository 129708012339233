import React, { ReactElement } from "react";
import { Box, Flex } from "theme-ui";
import useProjectUrlParams from "../useProjectUrlParams";
import ProjectListResults from "./ProjectListResults";
import { searchModes } from "./searchModes";
import SearchProjectList from "./SearchProjectList";
import UserListResults from "./UserListResults";

function ProjectList(): ReactElement {
  const { params } = useProjectUrlParams();

  const tabs = {
    [searchModes.date]: <ProjectListResults />,
    [searchModes.user]: <UserListResults />,
    [searchModes.owned]: <ProjectListResults />,
  };

  return (
    <Box>
      <SearchProjectList />
      <Flex variant="layout.projectsList">{tabs[params.searchMode]}</Flex>
    </Box>
  );
}

export default ProjectList;
