import React from "react";
import { Text } from "theme-ui";
import { useTranslation } from "react-i18next";

import QRCode from "../../../../icons/QRCode";
import ProjectThumbnailLink from "./ProjectThumbnailLink";
import { isRunningInMSTeams } from "../../../../tools/msTeams";

function LoadFromQRCode({ onClick }) {
  const { t } = useTranslation();

  if (isRunningInMSTeams()) {
    /**
     * We need to suppress all QR codes when running in MS Teams, otherwise
     * the app will be rejected during the certification process.
     */
    return null;
  }

  return (
    <ProjectThumbnailLink
      index={98}
      onClick={onClick}
      variant="projectThumbnailLinkLoad"
    >
      <Text
        variant="links.projectThumbnailLinkLoad.title"
        className="project-thumbnail-link__title project-thumbnail-link__title--cta"
      >
        {t("form.button.loadWithQRCode")}
      </Text>
      <QRCode noShadow className="project-thumbnail-link__icon" />
    </ProjectThumbnailLink>
  );
}

export default LoadFromQRCode;
