import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex } from "theme-ui";
import Avatar from "../../../../users/Avatar";
import ExpandButton from "../buttons/ExpandButton";
import { byUserFlex } from "../gridFlex";

type Props = {
  User: ProjectApiUserInfo;
  onExpand: () => void;
  isExpanded: boolean;
};

function ProjectResultByUser({
  User,
  onExpand,
  isExpanded,
}: Props): ReactElement {
  const { t } = useTranslation();

  const {
    UserName,
    FirstName,
    LastName,
    AvatarUrl,
    ProjectColor,
    DateCreated,
    DateLastActivity,
  } = User;

  const registrationDate = t("app.fullDateTime", {
    date: new Date(DateCreated),
  });
  const lastActivityDate = t("app.fullDateTime", {
    date: new Date(DateLastActivity),
  });

  return (
    <Flex
      id={`row-user-id-${UserName}`}
      sx={{
        borderBottom: "2px solid",
        fontSize: 14,
        fontWeight: 300,
        cursor: "pointer",
        borderColor: isExpanded ? "accent" : "var(--modalLine)",
        backgroundColor: isExpanded ? "accent" : "transparent",
        div: {
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
        "> div": {
          px: [4],
          display: "flex",
          alignItems: "center",
          padding: [1, null, 3],
          fontWeight: isExpanded ? 600 : 400,
          textAlign: ["left", null, "center"],
        },
      }}
      onClick={onExpand}
      role="button"
    >
      <Flex sx={{ flex: byUserFlex.owner }}>
        <Avatar AvatarUrl={AvatarUrl} ProjectColor={ProjectColor} />
        <Box sx={{ ml: [4] }}>
          {FirstName} {LastName}
        </Box>
      </Flex>
      <Flex sx={{ flex: byUserFlex.email }} title={UserName}>
        {UserName}
      </Flex>
      <Flex sx={{ flex: byUserFlex.registration }} title={registrationDate}>
        {registrationDate}
      </Flex>
      <Flex sx={{ flex: byUserFlex.lastActivity }} title={lastActivityDate}>
        {lastActivityDate}
      </Flex>
      <Flex sx={{ flex: byUserFlex.actions, justifyContent: "flex-end" }}>
        <ExpandButton isExpanded={isExpanded} onExpand={onExpand} />
      </Flex>
    </Flex>
  );
}

export default ProjectResultByUser;
