import {
  AcceptedFile,
  RejectedFile,
} from "../../../../features/shared/drag-and-drop/useDropArea";

export enum DropActionType {
  DROP = "DROP",
}

export type DropFiles = {
  acceptedObjects: AcceptedFile[];
  rejectedObjects: RejectedFile[];
  pointer?: { x: number; y: number };
};

export const dropAction = (payload: DropFiles) =>
  ({
    type: DropActionType.DROP,
    payload,
  } as const);

export type DropAction = ReturnType<typeof dropAction>;
