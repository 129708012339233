import React, { ReactElement } from "react";
import { Redirect } from "react-router-dom";
import PlansPageTemplate from "./PlansPageTemplate";
import { usePlanDetails } from "../../licensing/usePlanDetails";
import { routes } from "../../../const";

function Plans(): ReactElement {
  const { hasAccessToPlansPage } = usePlanDetails();

  return hasAccessToPlansPage ? (
    <PlansPageTemplate />
  ) : (
    <Redirect to={routes.projects} />
  );
}
export default Plans;
