import { ScreenCoordinates } from "./presentation.reducer";

export const computeScaledViewport = (
  presenter: { viewport: ScreenCoordinates; zoomLevel: number },
  myCanvas: { width: number; height: number }
): {
  offsetX: number;
  offsetY: number;
  scaledZoomLevel: number;
  scaledWidth: number;
  scaledHeight: number;
} => {
  const { viewport: presenterViewport, zoomLevel: presenterZoomLevel } =
    presenter;
  const { width: myCanvasWidth, height: myCanvasHeight } = myCanvas;

  // Calculate scaling required to fit within my resolution
  const canvasScaleX = myCanvasWidth / presenterViewport.width;
  const canvasScaleY = myCanvasHeight / presenterViewport.height;
  const requiredCanvasScale = Math.min(canvasScaleX, canvasScaleY);

  const scaledWidth = presenterViewport.width * requiredCanvasScale;
  const scaledHeight = presenterViewport.height * requiredCanvasScale;

  // Calculate the zoom required to fit to my resolution
  const scaledZoomLevel = presenterZoomLevel * requiredCanvasScale;

  const offsetX = (myCanvasWidth - scaledWidth) / 2;
  const offsetY = (myCanvasHeight - scaledHeight) / 2;

  return {
    offsetX,
    offsetY,
    scaledZoomLevel,
    scaledWidth,
    scaledHeight,
  };
};
