import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function PasswordVisible(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M19.13,10.77A17.75,17.75,0,0,0,4,19.53,17.73,17.73,0,0,0,19.13,28.3a17.73,17.73,0,0,0,15.11-8.77A17.73,17.73,0,0,0,19.13,10.77Zm6.7,8.76a6.71,6.71,0,1,1-6.7-6.7A6.69,6.69,0,0,1,25.83,19.53Zm-19.39,0a17.2,17.2,0,0,1,5.31-4.74,8.79,8.79,0,0,0,0,9.49A18,18,0,0,1,6.44,19.53ZM26.5,24.28a8.74,8.74,0,0,0,0-9.49,18,18,0,0,1,5.32,4.74A18,18,0,0,1,26.5,24.28ZM16,19.53a3.1,3.1,0,1,1,3.1,3.1A3.11,3.11,0,0,1,16,19.53Z" />
    </Icon>
  );
}
export default PasswordVisible;
