import {
  all,
  call,
  Effect,
  getContext,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import {
  defaultConnectorSettingsStorage,
  defaultStickyNoteSettingsStorage,
  defaultTextSettingsStorage,
  previouslyAddedObjectStorage,
} from "../../../../tools/localStorageStores";
import {
  ObjectSettingsAction,
  ObjectsSettingsActionType,
  setConnectorAttributesAction,
  setStickyNoteAttributesAction,
  setTextAttributesAction,
} from "./settings.actions";
import { selectDefaultObjectSettings } from "./settings.reducer";

function* setObjectAttributes(action: ObjectSettingsAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const objectSettings = selectDefaultObjectSettings(yield select());

  switch (action.type) {
    case ObjectsSettingsActionType.SET_CONNECTOR_ATTRIBUTES:
      defaultConnectorSettingsStorage.set(objectSettings.connectors);
      canvas.connectorSettings = objectSettings.connectors;
      break;
    case ObjectsSettingsActionType.SET_STICKY_NOTE_ATTRIBUTES:
      defaultStickyNoteSettingsStorage.set(objectSettings.stickyNote);
      break;
    case ObjectsSettingsActionType.SET_TEXT_ATTRIBUTES:
      defaultTextSettingsStorage.set(objectSettings.text);
      break;
    default:
      break;
  }

  // Clear previously added storage so that double clicking will create an object with the new settings
  previouslyAddedObjectStorage.clear();
}

function* settingsInitializationSaga() {
  const connectorSettings: ContextProps = yield call({
    context: defaultConnectorSettingsStorage,
    fn: defaultConnectorSettingsStorage.get,
  });
  const stickyNoteSettings: ContextProps = yield call({
    context: defaultStickyNoteSettingsStorage,
    fn: defaultStickyNoteSettingsStorage.get,
  });
  const textSettings: ContextProps = yield call({
    context: defaultTextSettingsStorage,
    fn: defaultTextSettingsStorage.get,
  });

  if (connectorSettings) {
    yield put(setConnectorAttributesAction(connectorSettings));
  }

  if (stickyNoteSettings) {
    yield put(setStickyNoteAttributesAction(stickyNoteSettings));
  }

  if (textSettings) {
    yield put(setTextAttributesAction(textSettings));
  }
}

export function* objectSettingsSaga(): Generator<Effect> {
  yield all([
    settingsInitializationSaga(),
    takeEvery(
      [
        ObjectsSettingsActionType.SET_CONNECTOR_ATTRIBUTES,
        ObjectsSettingsActionType.SET_STICKY_NOTE_ATTRIBUTES,
        ObjectsSettingsActionType.SET_TEXT_ATTRIBUTES,
      ],
      setObjectAttributes
    ),
  ]);
}
