import { ActionCreator } from "redux";
import { EventChannel } from "redux-saga";
import { call, cancelled, put, select, take } from "redux-saga/effects";
import type {
  InitialDataForNewJoinToPresentationMessage,
  NudgeForAttentionMessage,
  ScreenPosZoomChangedMessage,
  StartPresentingMessage,
  StopPresentingMessage,
  ThumbnailPageChangedMessage,
  VideoPlayerStartStopCalledMessage,
} from "../../../api/signalR/message.types";
import { NotifyMessageName } from "../../../api/signalR/protobuf.codecs";
import { assertUnreachable } from "../../../tools/assertions";
import collaboard from "../../../tools/collaboard";
import { selectAssertedUserProfile } from "../../auth/auth.reducer";
import { SignalRAction, signalRNotifyChannel } from "../../redux.utils";
import { selectCanvasSettings } from "../settings/settings.reducer";
import {
  onDocumentControlAction,
  onJoinedPresentationAction,
  onMediaControlAction,
  onNudgeForAttentionAction,
  presentationStartedAction,
  presentationStoppedAction,
  presentationViewportChangeAction,
} from "./presentation.viewer.actions";

type NotifyPresentationMessageType =
  | NotifyMessageName.StartPresentingMessage
  | NotifyMessageName.StopPresentingMessage
  | NotifyMessageName.NudgeForAttentionMessage
  | NotifyMessageName.ScreenPosZoomChangedMessage
  | NotifyMessageName.InitialDataForNewJoinToPresentationMessage
  | NotifyMessageName.VideoPlayerStartStopCalledMessage
  | NotifyMessageName.ThumbnailPageChangedMessage;

type MarshalNotifyPresentation =
  | SignalRAction<
      NotifyMessageName.StartPresentingMessage,
      StartPresentingMessage
    >
  | SignalRAction<
      NotifyMessageName.StopPresentingMessage,
      StopPresentingMessage
    >
  | SignalRAction<
      NotifyMessageName.NudgeForAttentionMessage,
      NudgeForAttentionMessage
    >
  | SignalRAction<
      NotifyMessageName.ScreenPosZoomChangedMessage,
      ScreenPosZoomChangedMessage
    >
  | SignalRAction<
      NotifyMessageName.InitialDataForNewJoinToPresentationMessage,
      InitialDataForNewJoinToPresentationMessage
    >
  | SignalRAction<
      NotifyMessageName.VideoPlayerStartStopCalledMessage,
      VideoPlayerStartStopCalledMessage
    >
  | SignalRAction<
      NotifyMessageName.ThumbnailPageChangedMessage,
      ThumbnailPageChangedMessage
    >;

const marshalActionCreator = <T extends NotifyPresentationMessageType>(
  type: T
) => (
  payload: ActionInUnionWithType<MarshalNotifyPresentation, T>["payload"]
) => {
  return {
    type,
    payload,
  };
};

const eventToAction: Record<
  NotifyPresentationMessageType,
  ActionCreator<MarshalNotifyPresentation>
> = {
  [NotifyMessageName.StartPresentingMessage]: marshalActionCreator(
    NotifyMessageName.StartPresentingMessage
  ),
  [NotifyMessageName.StopPresentingMessage]: marshalActionCreator(
    NotifyMessageName.StopPresentingMessage
  ),
  [NotifyMessageName.NudgeForAttentionMessage]: marshalActionCreator(
    NotifyMessageName.NudgeForAttentionMessage
  ),
  [NotifyMessageName.ScreenPosZoomChangedMessage]: marshalActionCreator(
    NotifyMessageName.ScreenPosZoomChangedMessage
  ),
  [NotifyMessageName.InitialDataForNewJoinToPresentationMessage]: marshalActionCreator(
    NotifyMessageName.InitialDataForNewJoinToPresentationMessage
  ),
  [NotifyMessageName.VideoPlayerStartStopCalledMessage]: marshalActionCreator(
    NotifyMessageName.VideoPlayerStartStopCalledMessage
  ),
  [NotifyMessageName.ThumbnailPageChangedMessage]: marshalActionCreator(
    NotifyMessageName.ThumbnailPageChangedMessage
  ),
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* signalRPresentationSaga() {
  const notifyChannel: EventChannel<MarshalNotifyPresentation> = yield call(
    signalRNotifyChannel,
    collaboard.signalRClient,
    eventToAction
  );

  try {
    while (true) {
      const action: MarshalNotifyPresentation = yield take(notifyChannel);
      const userProfile = selectAssertedUserProfile(yield select());

      switch (action.type) {
        // TODO: An active presentation must also be reflected in the API response
        // so that users who connect after this signal is sent also enter presentation mode
        case NotifyMessageName.StartPresentingMessage: {
          const { payload } = action;

          yield put(
            presentationStartedAction({
              presentation: payload,
              isPresenter: payload.Presenter === userProfile.UserName,
            })
          );

          break;
        }
        case NotifyMessageName.StopPresentingMessage: {
          const { payload } = action;
          yield put(presentationStoppedAction({ presentation: payload }));
          break;
        }
        case NotifyMessageName.NudgeForAttentionMessage: {
          const { payload } = action;
          const canvasSettings = selectCanvasSettings(yield select());

          yield put(
            onNudgeForAttentionAction({
              presentation: payload,
              myCanvas: {
                width: canvasSettings.width,
                height: canvasSettings.height,
              },
            })
          );
          break;
        }
        // This handler is technically required only when the presentation is active, but we
        // need it to be active so we can immediately respond when a presentation
        // is started. Otherwise we miss the first viewport change event.
        case NotifyMessageName.ScreenPosZoomChangedMessage: {
          const { payload } = action;
          const canvasSettings = selectCanvasSettings(yield select());

          yield put(
            presentationViewportChangeAction({
              presentation: payload,
              myCanvas: {
                width: canvasSettings.width,
                height: canvasSettings.height,
              },
            })
          );
          break;
        }
        case NotifyMessageName.InitialDataForNewJoinToPresentationMessage: {
          const { payload } = action;
          const canvasSettings = selectCanvasSettings(yield select());

          yield put(
            onJoinedPresentationAction({
              presentation: payload,
              isPresenter: payload.Presenter === userProfile.UserName,
              myCanvas: {
                width: canvasSettings.width,
                height: canvasSettings.height,
              },
            })
          );
          break;
        }
        case NotifyMessageName.VideoPlayerStartStopCalledMessage: {
          const { payload } = action;
          yield put(onMediaControlAction(payload));
          break;
        }
        case NotifyMessageName.ThumbnailPageChangedMessage: {
          const { payload } = action;
          yield put(onDocumentControlAction(payload));
          break;
        }
        default: {
          assertUnreachable(action);
        }
      }
    }
  } finally {
    const isCancelled: boolean = yield cancelled(); // Cancelled by exiting the project

    if (isCancelled) {
      // Close the EventChannel so that the unsubscribe function is called and listeners removed
      notifyChannel.close();
    }
  }
}
