import { all, Effect, getContext, select, takeEvery } from "redux-saga/effects";
import { isStickyNote } from "../../../../studio/utils/fabricObjects";
import { selectIsViewOnlyMode } from "../../app/app.reducer";
import { EditingActionType } from "./editing.actions";

function* enterEditingMode() {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  if (selectIsViewOnlyMode(yield select())) {
    return;
  }

  const activeObj = canvas.getActiveObject();
  if (activeObj && isStickyNote(activeObj) && !activeObj.isEditingMode()) {
    activeObj.enterEditing();
  }
}

export function* editingSaga(): Generator<Effect> {
  yield all([
    takeEvery(EditingActionType.ENTER_EDITING_MODE, enterEditingMode),
  ]);
}
