import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserPermissionChange } from "../../../../api";
import {
  usePermissionUpdate,
  useProjectOwner,
} from "../../../../api/useRequest";
import { projectPermissions } from "../../../../const";
import { errorToast } from "../../../../tools/errorToast";
import { ApiPermission } from "../../../../types/enum";

const { ownerPermission, noPermission } = projectPermissions;

const messages = {
  [noPermission]: "dialog.removeAccount",
  [ownerPermission]: "dialog.changeOwner",
};

type UsePermissionChangeButtonsParams = {
  user: ProjectParticipantInfo;
  projectId: string;
  presenter?: string;
  onPermissionChange: (permission: UserPermissionChange) => void;
  onProjectOwnerChange: (response: { newOwner: string }) => void;
  noPromptRemoveUser?: boolean;
};

type UsePermissionChangeButtons = {
  isChangingPermission: boolean;
  isChangingProjectOwner: boolean;
  handlePermissionClick: (permission: ApiPermission) => void;
  confirmationMessage: string | null;
  confirmAction: (() => void) | null;
  permissionAwaitingConfirmation: ApiPermission | null;
};

export const usePermissionChangeButtons = ({
  user,
  projectId,
  presenter,
  onPermissionChange,
  onProjectOwnerChange,
  noPromptRemoveUser,
}: UsePermissionChangeButtonsParams): UsePermissionChangeButtons => {
  const { t } = useTranslation();

  const [confirmationMessage, setConfirmationMessage] = useState<string | null>(
    null
  );
  const [confirmAction, setConfirmAction] = useState<(() => void) | null>(null);
  const [
    permissionAwaitingConfirmation,
    setPermissionAwaitingConfirmation,
  ] = useState<ApiPermission | null>(null);

  const { changePermission, isChangingPermission } = usePermissionUpdate(
    onPermissionChange
  );
  const handleOwnerChange = useCallback(
    () => onProjectOwnerChange({ newOwner: user.UserName }),
    [onProjectOwnerChange, user]
  );
  const { changeProjectOwner, isChangingProjectOwner } = useProjectOwner(
    handleOwnerChange
  );

  const showConfirmation = (
    permission: ApiPermission,
    callback: () => void
  ) => {
    setConfirmationMessage(t(messages[permission]));
    setPermissionAwaitingConfirmation(permission);
    setConfirmAction(() => callback);
  };

  const hideConfirmation = () => {
    setPermissionAwaitingConfirmation(null);
    setConfirmationMessage(null);
    setConfirmAction(null);
  };

  const handlePermissionClick = (permission: ApiPermission) => {
    if (user.IsMyself) {
      errorToast(t("users.cannotUpdateYourPermissions"));
      return;
    }

    if (user.UserName === presenter) {
      errorToast(t("users.cannotChangePermissionOfPresenter"));
      return;
    }

    const defaultAction = () => {
      hideConfirmation();

      if (user.Permission !== permission) {
        changePermission({ projectId, user, permission });
      }
    };

    const action =
      {
        [noPermission]: noPromptRemoveUser
          ? defaultAction
          : () => {
              showConfirmation(permission, () => {
                changePermission({ projectId, user, permission });
              });
            },
        [ownerPermission]: () => {
          showConfirmation(permission, () => {
            changeProjectOwner({ projectId, newOwner: user.UserName });
          });
        },
      }[permission] ?? defaultAction;

    action();
  };

  return {
    isChangingPermission,
    isChangingProjectOwner,
    handlePermissionClick,
    confirmationMessage,
    confirmAction,
    permissionAwaitingConfirmation,
  };
};
