import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function EyeDropper(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M25.77,16.77l1.05-1,.45-.45a2.23,2.23,0,0,0-3.16-3.16l-.45.45-1.05,1-1.05-1-2.11,2.11,1.05,1.05-8.57,8.57s-2.26,3-1.65,4.52c1.5.6,4.81-1.36,4.81-1.36l8.57-8.57,1.06,1.05,2.1-2.1ZM14,26.4l-1.66.6.6-1.65,8.58-8.58,1.05,1.06Z" />
      <polygon points="12.66 27.4 11.4 27.88 11.88 26.62 13.72 24.79 15.17 24.88 12.66 27.4" />
    </Icon>
  );
}
export default EyeDropper;
