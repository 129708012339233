import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function RegisteredUser(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M26.43,21.67c-1.44-1-3.64-.47-5-2.29,0,0-.51-.58.64-2S23.3,13.75,23,12a3.7,3.7,0,0,0-7.22,0c-.29,1.72-.19,4,1,5.36s.64,2,.64,2c-1.38,1.82-3.58,1.27-5,2.29S11.29,25,11.29,25h2.87a5.28,5.28,0,0,0-.23,1.55,5.47,5.47,0,0,0,10.94,0A5.28,5.28,0,0,0,24.64,25h2.87S27.86,22.7,26.43,21.67Zm-7,9.38a4.47,4.47,0,1,1,4.47-4.47A4.47,4.47,0,0,1,19.4,31.05Z" />
      <polygon points="18.75 27.38 17.66 26.29 16.93 27.03 18.74 28.85 21.94 25.65 21.2 24.91 18.75 27.38" />
    </Icon>
  );
}
export default RegisteredUser;
