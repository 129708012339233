import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Button, Box, Text, ThemeUIStyleObject } from "theme-ui";
import Heart from "../../icons/Heart";
import Plus from "../../icons/Plus";
import Spaces from "../../icons/Spaces";
import { byFavourite } from "../../tools/sorters";
import { ApiResourcePermission, ApiResourceType } from "../../types/enum";
import { usePermissions } from "../permissions/usePermissions";
import ContainedSpinner from "../shared/spinners/ContainedSpinner";
import SpacesDropdown from "./SpacesDropdown";
import { useSpaces } from "./useSpaces";

type Props = {
  activeSpaceId: number;
  headSpaceId: number;
  isProjectsPage?: boolean;
  onSpaceChange: (spaceId: number) => void;
};

function SpacesNav({
  activeSpaceId,
  headSpaceId,
  isProjectsPage,
  onSpaceChange,
}: Props): ReactElement {
  const { t } = useTranslation();
  const { hasPermission } = usePermissions();
  const { spaces, isLoading } = useSpaces();
  const allowedSpaces = spaces.filter((space) =>
    hasPermission({
      type: ApiResourceType.Space,
      value: String(space.Space.SpaceId),
      permission: ApiResourcePermission.View,
    })
  );
  const sortedSpaces = [...allowedSpaces].sort(byFavourite);

  const visibleSpaces = sortedSpaces.slice(0, 4);
  const otherSpaces = sortedSpaces.slice(4);

  const canCreateSpace = hasPermission({
    type: ApiResourceType.Space,
    value: null,
    permission: ApiResourcePermission.Create,
  });

  return (
    <Flex sx={spacesNavStyle}>
      {isProjectsPage ? (
        <Button
          variant="vanilla"
          className={
            !isLoading && activeSpaceId === headSpaceId ? "active" : undefined
          }
          sx={{
            ...spaceButtonStyle,
            ...allButtonStyle,
          }}
          onClick={() => onSpaceChange(headSpaceId)}
        >
          <Spaces noShadow />
          <Box>{t("spaces.allSpaces")}</Box>
        </Button>
      ) : (
        canCreateSpace && (
          <Button
            variant="vanilla"
            className={activeSpaceId === headSpaceId ? "active" : undefined}
            sx={{
              ...spaceButtonStyle,
              ...createButtonStyle,
            }}
            onClick={() => onSpaceChange(headSpaceId)}
            title={t("spaces.createSpace")}
          >
            <Plus noShadow />
          </Button>
        )
      )}
      {isLoading ? (
        <ContainedSpinner />
      ) : (
        <>
          {visibleSpaces.length ? (
            visibleSpaces.map(
              ({ Space: { SpaceId, Name, Color }, IsFavorite }) => (
                <Button
                  variant="vanilla"
                  className={activeSpaceId === SpaceId ? "active" : undefined}
                  sx={{
                    ...spaceButtonStyle,
                    "&:hover::after, &:active::after, &.active::after": {
                      ...hoverLineStyle,
                      backgroundColor: Color,
                    },

                    "&:active, &.active": {
                      borderColor: Color,
                      backgroundColor: Color,
                      color: "white",
                    },

                    "&:active::before, &.active::before": {
                      backgroundColor: Color,
                    },
                  }}
                  onClick={() => onSpaceChange(SpaceId)}
                  title={Name}
                  key={SpaceId}
                >
                  {IsFavorite && (
                    <Box sx={favouriteStyle}>
                      <Heart noShadow scale={0.65} />
                    </Box>
                  )}
                  <Box sx={buttonLabelStyle}>{Name}</Box>
                </Button>
              )
            )
          ) : (
            <Text sx={noSpacesStyle}>
              {isProjectsPage ? t("spaces.noSpacesGoTo") : t("spaces.noSpaces")}
            </Text>
          )}
          {otherSpaces.length ? (
            <SpacesDropdown
              current={String(activeSpaceId)}
              spaceItems={otherSpaces.map(
                ({ Space: { SpaceId, Name, Color }, IsFavorite }) => ({
                  key: String(SpaceId),
                  label: (
                    <>
                      {IsFavorite && (
                        <Box sx={favouriteStyle}>
                          <Heart noShadow scale={0.65} />
                        </Box>
                      )}
                      <Box title={Name} sx={buttonLabelStyle}>
                        {Name}
                      </Box>
                    </>
                  ),
                  color: Color,
                  name: Name,
                })
              )}
              onSelect={(spaceId: string) => onSpaceChange(Number(spaceId))}
            />
          ) : null}
        </>
      )}
    </Flex>
  );
}

const spacesNavStyle: ThemeUIStyleObject = {
  bg: "delicate",
  height: ["3rem", null, null, "4rem"],
  mb: "3rem",
  position: "relative",
};

const hoverLineStyle: ThemeUIStyleObject = {
  content: '" "',
  bottom: "-1rem",
  height: "0.375em",
  left: 0,
  position: "absolute",
  width: "100%",
};

const spaceButtonStyle: ThemeUIStyleObject = {
  alignItems: "center",
  bg: "delicate",
  borderColor: "delicate",
  color: "black",
  cursor: "pointer",
  display: "flex",
  fontSize: [3, null, null, "inherit"],
  justifyContent: "center",
  padding: 0,
  position: "relative",
  px: [2, 4],
  width: "16.67%", // Align with ProjectsNav tiles

  "&::before": {
    content: '" "',
    bg: "secondary",
    height: "70%",
    position: "absolute",
    right: 0,
    top: "15%",
    width: "2px",
  },
};

const allButtonStyle: ThemeUIStyleObject = {
  backgroundColor: "black",
  borderColor: "black",
  color: "white",
  flexGrow: 0,
  fontWeight: 600,
  px: [0],

  // Disable and override styles from spaceButtonStyle
  "&:active, &.active": {},
  "&:hover::after, &:active::after, &.active::after": {
    ...hoverLineStyle,
    backgroundColor: "black",
  },
  "&::before": {},

  "> svg, > svg:hover": {
    fill: "white",
    width: "var(--gridTile)",
    height: "var(--gridTile)",
  },
};

const createButtonStyle: ThemeUIStyleObject = {
  flexGrow: 0,
  px: [0],
  width: ["3rem", null, null, "4rem"],

  // Disable and override styles from spaceButtonStyle
  "&:active, &.active": {},
  "&:active::after, &.active::after": {},
  "&:active::before, &.active::before": {},

  "&:hover, &:hover::before": {
    bg: "muted",
  },

  "&:hover::after": {
    ...hoverLineStyle,
    backgroundColor: "black",
  },

  "> svg, > svg:hover": {
    height: "calc(var(--gridTile) * 2)",
    width: "calc(var(--gridTile) * 2)",
  },
};

const favouriteStyle: ThemeUIStyleObject = {
  position: "absolute",
  right: 0,
  top: "-0.5rem",

  "> svg, > svg:hover": {
    fill: "currentColor",
  },
};

const buttonLabelStyle: ThemeUIStyleObject = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const noSpacesStyle: ThemeUIStyleObject = {
  alignItems: "center",
  color: "muted",
  display: "flex",
  px: [3],
};

export default SpacesNav;
