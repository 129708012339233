import { clamp } from "./utils";

type RGB = {
  r: number;
  g: number;
  b: number;
};

type HSL = {
  h: number;
  s: number;
  l: number;
};

type HSLDelta = {
  hDelta?: number;
  sDelta?: number;
  lDelta?: number;
};

export const isTransparent = (color: string): boolean => {
  return color === "transparent";
};

export const toDarkModeColor = (color: string): string => {
  if (isTransparent(color)) {
    return color;
  }

  const { l: lightness } = hexToHsl(color);
  const isDarkColor = lightness < 0.1;
  /** @TODO #7385 - Figure out how to use the same `isDarkColor` as background color uses */
  return isDarkColor
    ? updateHsl(color, {
        lDelta: 1 - lightness * 2,
      })
    : color;
};

/**
 * Convert short hex code (e.g. #ccc) or hex with alpha (e.g. #00ff0080) into
 * standard 6 digit hex code.
 */
export const toFullHex = (hex: string): string => {
  const withoutHash = hex.replace("#", "").toLowerCase().trim();

  if (!/^[0-9a-fA-F]+$/.test(withoutHash)) {
    return "#000000";
  }

  if (withoutHash.length >= 6) {
    return `#${withoutHash.substring(0, 6)}`;
  }

  const lastChar = withoutHash.substring(-1);
  const withoutAlpha = withoutHash.padEnd(6, lastChar).substring(0, 3);

  const fullHex = withoutAlpha
    .split("")
    .map((code) => code + code)
    .join("");

  return `#${fullHex}`;
};

/**
 *
 * @param hex
 * @returns
 */
export const splitHexToRGB = (hex: string): RGB => {
  if (isTransparent(hex)) {
    return {
      r: 0,
      g: 0,
      b: 0,
    };
  }
  const fullHex = toFullHex(hex);
  return {
    r: parseInt(fullHex.slice(1, 3), 16),
    g: parseInt(fullHex.slice(3, 5), 16),
    b: parseInt(fullHex.slice(5, 7), 16),
  };
};

// https://gist.github.com/xenozauros/f6e185c8de2a04cdfecf
export const hexToHsl = (hex: string): HSL => {
  let { r, g, b } = splitHexToRGB(hex);
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const l = (max + min) / 2;

  if (max === min) {
    return { h: 0, s: 0, l };
  }

  const d = max - min;
  const s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
  let h =
    max === r
      ? (g - b) / d + (g < b ? 6 : 0)
      : max === g
      ? (b - r) / d + 2
      : (r - g) / d + 4;

  h *= 60;
  return { h, s, l };
};

export const updateHsl = (
  hex: string,
  { hDelta, sDelta, lDelta }: HSLDelta
): string => {
  const { h, s, l } = hexToHsl(hex);
  const newH = Math.round(hDelta ? clamp(h + hDelta, 0, 255) : h);
  const newS = Math.round(100 * (sDelta ? clamp(s + sDelta, 0, 1) : s));
  const newL = Math.round(100 * (lDelta ? clamp(l + lDelta, 0, 1) : l));

  return hslToHex(newH, newS, newL);
};

export const hexToRGB = (hex: string, alpha: number): string => {
  const { r, g, b } = splitHexToRGB(hex);

  if (alpha || alpha === 0) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
};

// https://css-tricks.com/converting-color-spaces-in-javascript/
export const hslToHex = (h: number, s: number, l: number): string => {
  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2;
  let r = 0,
    g = 0,
    b = 0;
  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return rgbToHex(r, g, b);
};

export const rgbToHex = (r: number, g: number, b: number): string => {
  const value = (r << 16) | (g << 8) | b;
  if (!value) {
    return "#000000";
  }
  return `#${value.toString(16).padStart(6, "0")}`;
};

/**
 * Calculate the brightness of a color.
 *
 * See https://github.com/bgrins/TinyColor
 *
 * @param color Hex color, e.g. #ff00dd
 * @returns A number between 0 and 255. 0 = dark, 255 = light
 */
export const getBrightness = (color: string): number => {
  const { r, g, b } = splitHexToRGB(color);

  // As per http://www.w3.org/TR/AERT#color-contrast
  return (r * 299 + g * 587 + b * 114) / 1000;
};

/**
 * Is this a dark color?
 *
 * Used to determine if Dark Mode should be disabled.
 */
export const isDarkColor = (color: string): boolean => {
  return getBrightness(color) < 70; // Equivalent to #464646
};

/**
 * Is this a light color?
 *
 * Used to determine if Dark Mode should be enabled.
 */
export const isLightColor = (color: string): boolean => {
  return getBrightness(color) > 185; // Equivalent to #b9b9b9
};
