import React, { ReactElement, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateUserAndProfile } from "../../../../api";
import RemoveAvatar from "../../../../icons/RemoveAvatar";
import { updateUserProfileAction } from "../../../../reduxStore/auth/auth.actions";
import { LogCategory } from "../../../../tools/telemetry";
import { useErrorHandler } from "../../../shared/hooks/useErrorHandler";
import { Confirmation } from "../../../shared/modals";
import { TemplateProps } from "../../../shared/modals/Modal";
import ModalHeader from "../../../shared/modals/ModalHeader";

function RemoveAvatarModalTemplate({
  modalProps,
}: TemplateProps): ReactElement {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { fallbackErrorHandler } = useErrorHandler();

  const onConfirm = useCallback(async () => {
    const payload = { Photo: "" };
    try {
      const user = await updateUserAndProfile(payload);
      dispatch(updateUserProfileAction(user));
      toast(t("users.photoRemoved"));
    } catch (error) {
      fallbackErrorHandler(error, "Failed to remove avatar", LogCategory.api);
    }
  }, [dispatch, fallbackErrorHandler, t]);

  return (
    <Confirmation
      header={
        <ModalHeader title={t("dialog.removePhoto")} Icon={RemoveAvatar} />
      }
      confirmationLabel={t("dialog.remove")}
      variant="warning"
      onConfirm={onConfirm}
      {...modalProps}
    >
      {t("dialog.removePhotoPrompt")}
    </Confirmation>
  );
}

export default RemoveAvatarModalTemplate;
