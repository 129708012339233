export type Currency = { symbol: string; code: string };

export const useCurrencies = (): {
  currencies: Currency[];
  areCurrenciesLoading: boolean;
} => {
  return {
    currencies: [
      { symbol: "€", code: "EUR" },
      { symbol: "$", code: "USD" },
      { symbol: "CHF", code: "CHF" },
    ],
    areCurrenciesLoading: false,
  };
};
