import { fabric } from "fabric";

import { maxStickyFontSize, stickyNoteFontSizes } from "../../../const";
import { assertUnreachable } from "../../../tools/assertions";

fabric.util.object.extend(fabric.StickyNote.prototype, {
  requireMaxFontSizeCalculation: false,

  /*----------  Auto-font size overrides  ----------*/

  setMaxManualFontSize(this: fabric.StickyNote) {
    this.isAutoFontSize = false;
    this.maxFontSize = this._calculateMaxFontSize();
    this._reflowText();

    this.dirty = true;
    this.requireMaxFontSizeCalculation = false;
  },
  /**
   * Set the font size to the maximum that will fit.
   */
  setMaxAutoFontSize(this: fabric.StickyNote) {
    const maxAutoSize = this._calculateMaxFontSize();

    this.isAutoFontSize = false;
    const maxManualSize = this._calculateMaxFontSize();
    this.isAutoFontSize = true;

    this.maxFontSize = maxManualSize;
    this.fontSize = maxAutoSize;

    this._reflowText();

    this.dirty = true;
    this.requireMaxFontSizeCalculation = false;
  },

  _reflowText(this: fabric.StickyNote) {
    this._clearCache();
    this._splitText();
  },

  /**
   * Calculate if text is overflowing sticky note.
   *
   * @override - Modifies method from fabric.CustomEditing
   */
  isTextOverflow(this: fabric.StickyNote) {
    this._reflowText();

    return this._textLines.some(this._isLineTooLong.bind(this));
  },

  _isLineTooLong(_textLine: string[], i: number) {
    const heightOfLine = this.getHeightOfLine(i);
    const widthOfLine = this.getLineWidth(i);
    const maxHeight = heightOfLine / this.lineHeight;

    // Text is rendered with 'alphabetic' textBaseline so `heightOfLine`
    // doesn't need to be added
    let top = heightOfLine * i + maxHeight;
    top -= (heightOfLine * this._fontSizeFraction) / this.lineHeight;

    const finalWidth = Math.floor(widthOfLine + 2 * this.internalPadding);
    const finalHeight = Math.floor(top + 2 * this.internalPadding);

    return finalHeight > this.height || finalWidth > this.width;
  },

  /**
   * Calculate raw text area height
   * @private
   */
  _calcTextRawAreaHeight(this: fabric.StickyNote) {
    return Math.floor(this.maxHeight / this.lineHeight / this._fontSizeMult);
  },

  /**
   * Calculate the maximum font size that will fit in the sticky note.
   *
   * @private
   */
  _calculateMaxFontSize(this: fabric.StickyNote) {
    if (this.text === "") {
      return maxStickyFontSize;
    }

    const initialFontSize = this.fontSize;
    const maxFontSize = this._calculateMaxFontSizeWithBinarySearch();
    this.fontSize = initialFontSize;

    return maxFontSize;
  },

  /**
   * Calculate the max font size using binary search to ook in the range
   * between minFontSize and maxStickyFontSize
   *
   * @private
   */
  _calculateMaxFontSizeWithBinarySearch(this: fabric.StickyNote) {
    let start = 0;
    let end = stickyNoteFontSizes.length - 1;

    let current = stickyNoteFontSizes.findIndex(
      (size) => size === this.fontSize
    );

    if (current === -1) {
      current = Math.ceil((start + end) / 2);
    }

    // Exit when start = maxFontSize, end = overflow causing an infinite loop
    while (end - start > 0 && !(end - start === 1 && current === start)) {
      this.fontSize = stickyNoteFontSizes[current];

      if (this.isTextOverflow()) {
        // Too big, this is the new end of the range
        end = current;
        current = Math.floor((start + end) / 2);
      } else {
        // it fits, but maybe too small, this is the new start of the range
        start = current;
        current = Math.ceil((start + end) / 2);
      }
    }

    const result = stickyNoteFontSizes[start];

    return result;
  },

  /**
   * Wraps a line of text using the width of the Textbox and a context.
   *
   * Compared to the native implementation, this one is simpler and splits by
   * word if possible, even if `this.splitByGrapheme = true`
   *
   * @override
   */
  _wrapLine(
    this: fabric.StickyNote,
    text: string,
    lineIndex: number,
    desiredWidth: number
  ) {
    let currentLine = "";
    const lines = [];

    for (let i = 0; i < text.length; i += 1) {
      const char = text.charAt(i);
      const newLine = currentLine.concat(char);
      const box = this._getGraphemeBox(newLine, lineIndex, 0); // Expensive

      /**
       * If the line is too long, decide how to wrap the text.
       * - If there's possibility to wrap by word, do it instead of splitting words.
       *
       * - If font size is "Auto", split words if length > 16
       * - If font size is manual, split any word that would overflow, regardless of the length
       *   - If even splitting words results in text overflow, then change the font size to Auto
       *     (this is done in fabric.CustomEditing)
       */

      if (box.kernedWidth >= desiredWidth - 2 * this.internalPadding) {
        const index = currentLine.lastIndexOf(" ");

        if (index > 0) {
          // divide on white space
          const part = currentLine.slice(0, index + 1);
          lines.push(part.split(""));
          // second part add a current line in new line, it contains now wrapped word
          currentLine = currentLine.slice(index + 1).concat(char);
        } else if (this.isAutoFontSize) {
          if (newLine.length > 16) {
            lines.push(currentLine.split(""));
            currentLine = char;
          } else {
            currentLine = newLine;
          }
        } else {
          lines.push(currentLine.split(""));
          currentLine = char;
        }
      } else {
        currentLine = newLine;
      }
    }

    lines.push(currentLine.split(""));

    return lines;
  },

  /*----------  Text alignment and placement overrides  ----------*/

  /**
   * Override to support internal padding
   * @override
   */
  _getLeftOffset(this: fabric.StickyNote) {
    const additionalOffset = this.textAlign
      ? {
          left: this.internalPadding,
          right: -this.internalPadding,
          center: 0,
          none: 0,
        }[this.textAlign]
      : 0;

    return -this.width / 2 + additionalOffset;
  },

  /**
   * Override to support internal padding and vertical text placement
   * @override
   */
  _getTopOffset(this: fabric.StickyNote) {
    const lineHeights = this._textLines.reduce(
      (sum, textLine, i) => sum + this.getHeightOfLine(i),
      0
    );

    switch (this.textPlacement) {
      case "bottom": {
        return this.height / 2 - this.internalPadding - lineHeights;
      }
      case "center": {
        return -lineHeights / 2;
      }
      case "top":
      case "none":
      case undefined:
        return -this.height / 2 + this.internalPadding;
      default:
        return assertUnreachable(this.textPlacement);
    }
  },

  /**
   * Override to correctly take into account the initial left/top offset when determining the clicked
   * line index.
   *
   * @override
   */
  getSelectionStartFromPointer(this: fabric.StickyNote, e: MouseEvent) {
    const mouseOffset = this.getLocalPointer(e);
    let prevWidth = 0;
    // In fabric's original implementation, Width and height are initialized as just zero, because `this._getLeftOffset` and
    // `this._getTopOffset` return a negative offset (e.g. -this.width / 2) from the object center so that
    // the text start is at virtually at (0, 0). However, because we have overriden the default left and top offsets,
    // we need to correctly initialize the two values.
    let width = (this._getLeftOffset() + this.width / 2) * (this.scaleX || 1); // <= FIX
    let height = (this._getTopOffset() + this.height / 2) * (this.scaleY || 1); // <= FIX

    let charIndex = 0;
    let lineIndex = 0;

    for (let i = 0, len = this._textLines.length; i < len; i++) {
      if (height <= mouseOffset.y) {
        height += this.getHeightOfLine(i) * (this.scaleY || 1);
        lineIndex = i;
        if (i > 0) {
          charIndex +=
            this._textLines[i - 1].length + this.missingNewlineOffset(i - 1);
        }
      } else {
        break;
      }
    }

    // Add the line left offset (which depends on the text alignment)
    width = width + this._getLineLeftOffset(lineIndex) * (this.scaleX || 1); // <= FIX

    const line = this._textLines[lineIndex];
    const jlen = line.length;
    for (let j = 0; j < jlen; j++) {
      prevWidth = width;
      const kernedWidth = this.__charBounds?.[lineIndex][j].kernedWidth ?? 0;
      width += kernedWidth * (this.scaleX || 1);
      if (width <= mouseOffset.x) {
        charIndex++;
      } else {
        break;
      }
    }

    return this._getNewSelectionStartFromOffset(
      mouseOffset,
      prevWidth,
      width,
      charIndex,
      jlen
    );
  },
});
