import { readFileContents } from "./file";

/**
 * Ensure that the dimensions are defined for an SVG File.
 */
export const setSVGFileDimensions = async (file: File): Promise<File> => {
  const svg = await readFileContents(file);

  const modifiedSVG = setSVGDimensions(svg);

  const blob = new Blob([modifiedSVG], {
    type: file.type,
  });

  return new File([blob], file.name, {
    type: file.type,
  });
};

const parseSVG = (svg: string): Document => {
  const parser = new DOMParser();
  return parser.parseFromString(svg, "image/svg+xml");
};

const serializeSVG = (doc: Document): string => {
  const serializer = new XMLSerializer();
  return serializer.serializeToString(doc);
};

/**
 * Ensure an SVG file has a viewBox definition that contains the width / height.
 *
 * Without width and height values Firefox will use `0` for the dimensions,
 * meaning the SVG won't be visible.
 *
 * https://bugzilla.mozilla.org/show_bug.cgi?id=700533
 * https://github.com/whatwg/html/issues/3510
 *
 * @private
 */
const setSVGDimensions = (svg: string): string => {
  const doc = parseSVG(svg);
  const $svg = doc.querySelector("svg");

  if ($svg) {
    const viewBox = $svg.getAttribute("viewBox") || "";
    const width = $svg.getAttribute("width");
    const height = $svg.getAttribute("height");

    const [, , vWidth, vHeight] = viewBox.split(" ");

    $svg.setAttribute("width", width || vWidth);
    $svg.setAttribute("height", height || vHeight);

    return serializeSVG(doc);
  }

  return svg;
};

const svgToFile = (svg: string, fileName: string): File => {
  const blob = new Blob([svg], {
    type: "image/svg+xml",
  });
  const file = new File([blob], fileName, {
    type: "image/svg+xml",
  });

  return file;
};

export const sanitizeSVGFile = async (file: File): Promise<File> => {
  const svg = await readFileContents(file);
  const doc = parseSVG(svg);

  const foreignEl = doc.querySelector("foreignObject");

  if (!foreignEl) {
    return file;
  }

  foreignEl.parentNode?.removeChild(foreignEl);

  const sanitizedSVG = serializeSVG(doc);

  return svgToFile(sanitizedSVG, file.name);
};
