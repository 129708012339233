import React, { ReactElement } from "react";
import { Button } from "theme-ui";
import CollaboardTooltip from "../../../../shared/tooltip/CollaboardTooltip";
import ChevronDown from "../../../../../icons/ChevronDown";

type Props = {
  onExpand: () => void;
  isExpanded: boolean;
};

function ExpandButton({ onExpand, isExpanded }: Props): ReactElement {
  const onClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onExpand && onExpand();
  };

  return (
    <CollaboardTooltip
      header={
        isExpanded
          ? "tooltip.header.collapseProject"
          : "tooltip.header.expandProject"
      }
      description={
        isExpanded
          ? "tooltip.description.collapseProject"
          : "tooltip.description.expandProject"
      }
      placement="topLeft"
    >
      <Button
        onClick={onClick}
        variant={
          isExpanded ? "contentProjectListExpanded" : "contentProjectList"
        }
        sx={{
          mt: [isExpanded ? 5 : 0],
          transform: `rotate(${isExpanded ? 180 : 0}deg)`,
          margin: 0,
          height: "100%",
          svg: { fill: "text" },
        }}
        name="expand-project-details"
      >
        <ChevronDown noShadow />
      </Button>
    </CollaboardTooltip>
  );
}

export default ExpandButton;
