import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Currencies(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <g>
        <g id="Layer_1_6_">
          <g>
            <g>
              <path
                className="st2"
                d="M18.79,16.75c0.09-1.02,0.45-1.95,1.01-2.75c-0.45-2.06-2.29-3.6-4.48-3.6c-2.53,0-4.58,2.06-4.58,4.58
					c0,2.2,1.56,4.04,3.63,4.48C15.41,18.05,16.98,17.05,18.79,16.75z M13.49,16.08c0-0.17,0.13-0.3,0.3-0.3h0.94v-0.22l-0.13-0.22
					h-0.81c-0.17,0-0.3-0.13-0.3-0.3c0-0.17,0.13-0.3,0.3-0.3h0.46l-1-1.64c-0.05-0.09-0.11-0.2-0.11-0.31
					c0-0.42,0.39-0.55,0.65-0.55c0.32,0,0.46,0.25,0.52,0.36l1.02,1.93l1.01-1.93c0.05-0.11,0.2-0.36,0.51-0.36
					c0.27,0,0.65,0.14,0.65,0.55c0,0.11-0.05,0.22-0.11,0.31l-1,1.64h0.46c0.17,0,0.3,0.13,0.3,0.3c0,0.17-0.13,0.3-0.3,0.3h-0.81
					l-0.13,0.22v0.22h0.94c0.17,0,0.3,0.13,0.3,0.3c0,0.17-0.13,0.3-0.3,0.3h-0.94v0.75c0,0.36-0.22,0.61-0.6,0.61
					c-0.38,0-0.6-0.24-0.6-0.61v-0.75h-0.94C13.61,16.38,13.49,16.25,13.49,16.08z"
              />
            </g>
            <g>
              <path
                className="st2"
                d="M24.49,12.7c-2.31,0-4.23,1.73-4.54,3.96c0.87,0.01,1.71,0.18,2.48,0.48c0.05-0.12,0.16-0.19,0.32-0.19
					h0.16c-0.16-0.23-0.23-0.52-0.23-0.81c0-0.94,0.83-1.6,1.9-1.6c1.33,0,1.97,0.71,1.97,1.3c0,0.34-0.24,0.53-0.57,0.53
					c-0.67,0-0.26-0.92-1.28-0.92c-0.45,0-0.87,0.27-0.87,0.76c0,0.26,0.13,0.52,0.25,0.74h0.83c0.31,0,0.47,0.11,0.47,0.36
					s-0.16,0.36-0.47,0.36h-0.6c0.02,0.06,0.04,0.11,0.04,0.18c0,0.12-0.03,0.24-0.08,0.36c0.25,0.2,0.48,0.43,0.69,0.67
					c0.21,0.04,0.4,0.09,0.62,0.09c0.13,0,0.44-0.08,0.56-0.08c0.28,0,0.44,0.21,0.44,0.48c0,0.43-0.38,0.61-0.78,0.64
					c0.26,0.44,0.48,0.91,0.64,1.4c1.55-0.74,2.63-2.31,2.63-4.14C29.07,14.76,27.02,12.7,24.49,12.7z"
              />
            </g>
            <g>
              <path
                className="st2"
                d="M20.21,24.08v1.88c0.57-0.04,1.17-0.3,1.17-0.93C21.37,24.38,20.72,24.19,20.21,24.08z"
              />
            </g>
            <g>
              <path
                className="st2"
                d="M18.58,21.83c0,0.47,0.35,0.75,1.06,0.89v-1.7C18.99,21.05,18.58,21.42,18.58,21.83z"
              />
            </g>
            <g>
              <path
                className="st2"
                d="M19.9,17.8c-3.16,0-5.73,2.57-5.73,5.73s2.57,5.73,5.73,5.73s5.73-2.57,5.73-5.73S23.06,17.8,19.9,17.8z
					 M20.21,26.92v0.59c0,0.16-0.12,0.32-0.29,0.32s-0.28-0.16-0.28-0.32v-0.59c-1.6-0.04-2.4-0.99-2.4-1.74
					c0-0.38,0.23-0.6,0.59-0.6c1.06,0,0.24,1.31,1.81,1.37v-1.98c-1.4-0.26-2.25-0.87-2.25-1.92c0-1.29,1.07-1.95,2.25-1.99v-0.5
					c0-0.16,0.12-0.32,0.28-0.32s0.29,0.16,0.29,0.32v0.5c0.74,0.02,2.25,0.48,2.25,1.41c0,0.37-0.28,0.59-0.6,0.59
					c-0.62,0-0.61-1.01-1.66-1.03v1.8c1.25,0.27,2.36,0.63,2.36,2.09C22.57,26.19,21.62,26.83,20.21,26.92z"
              />
            </g>
          </g>
        </g>
      </g>
    </Icon>
  );
}
export default Currencies;
