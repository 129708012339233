import React, { ReactElement } from "react";
import { Flex, Text } from "theme-ui";
import { useTranslation } from "react-i18next";

import Avatar from "../../../../users/Avatar";
import { byUserFlex } from "../gridFlex";

type Props = {
  User: ProjectApiUserInfo;
};

function UserDetails({ User }: Props): ReactElement {
  const { t } = useTranslation();

  const {
    UserName,
    FirstName,
    LastName,
    DateCreated,
    DateLastActivity,
    AvatarUrl,
    ProjectColor,
  } = User;

  const registrationDate = t("app.fullDateTime", {
    date: new Date(DateCreated),
  });
  const lastActivityDate = t("app.fullDateTime", {
    date: new Date(DateLastActivity),
  });

  return (
    <Flex
      sx={{
        py: [4],
        backgroundColor: "var(--contextGradientColor2)",
        "> div": {
          alignItems: "center",
          fontSize: 14,
        },
      }}
    >
      <Flex
        sx={{
          flex: byUserFlex.owner,
          ml: [2],
          "> div": {
            fontSize: 18,
            fontWeight: 700,
            ml: [4],
            alignSelf: "center",
          },
        }}
      >
        <Avatar enlarge AvatarUrl={AvatarUrl} ProjectColor={ProjectColor} />
        <Text>
          {FirstName} {LastName}
        </Text>
      </Flex>

      <Flex sx={{ flex: byUserFlex.email }}>{UserName}</Flex>
      <Flex sx={{ flex: byUserFlex.registration }}>{registrationDate}</Flex>
      <Flex sx={{ flex: byUserFlex.lastActivity }}>{lastActivityDate}</Flex>
      <Flex sx={{ flex: byUserFlex.actions }} />
    </Flex>
  );
}
export default UserDetails;
