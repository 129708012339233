import { fabric } from "fabric";
import type { TileStatusWithOptionalZIndex } from "../api/signalR/message.types";
import { VotingState } from "../reduxStore/canvas/voting/voting.reducer";

export const byOnlineStatus = (
  u1: ProjectOnlineUserInfo,
  u2: ProjectOnlineUserInfo
): number => (u1.IsOnline === u2.IsOnline ? 0 : (u1.IsOnline && -1) || 1);

export const ascending = (a: number, b: number): number => a - b;

export const byImageWidth = (a: ResponsiveImage, b: ResponsiveImage): number =>
  a.Width - b.Width;

export const byFavourite = (a: Space, b: Space): number => {
  if (a.IsFavorite === b.IsFavorite) {
    return 0;
  }

  return a.IsFavorite ? -1 : 1;
};

export const byOnScreen = (a: fabric.Object, b: fabric.Object): number => {
  const aIsOnScreen = a.isOnScreen();
  const bIsOnScreen = b.isOnScreen();

  if (aIsOnScreen === bIsOnScreen) {
    return 0;
  }

  return aIsOnScreen ? -1 : 1;
};

export const byVotesCount = ({
  myVotesCastByTileId,
  totalVotesCastByTileId,
}: VotingState) => (a: fabric.Object, b: fabric.Object): number => {
  const totalA = totalVotesCastByTileId.get(a.uuid) ?? 0;
  const totalB = totalVotesCastByTileId.get(b.uuid) ?? 0;
  const votesA = myVotesCastByTileId.get(a.uuid) ?? 0;
  const votesB = myVotesCastByTileId.get(b.uuid) ?? 0;

  return totalA || totalB ? totalA - totalB : votesA - votesB;
};

export const byCreationDate = (a: ApiComment, b: ApiComment): number => {
  return a.Comment.CreationDate.localeCompare(b.Comment.CreationDate);
};

export const byEdgeOffset = (a: EdgeOffset, b: EdgeOffset): number => {
  return a.offset - b.offset;
};

/**
 * Sort objects by their zIndex value.
 *
 * @NOTE this needs to protect against objects that do not have a zIndex value,
 * e.g. alignmentLine, as this can affect the sorting.
 */
export const byZIndex = (a: fabric.Object, b: fabric.Object): number => {
  return (a.zIndex ?? 0) - (b.zIndex ?? 0);
};

/**
 * @NOTE this needs to protect against tiles that do not have a zIndex value,
 * e.g. chat, as this can affect the sorting.
 */
export const byTileZIndex = (
  a: TileStatusWithOptionalZIndex,
  b: TileStatusWithOptionalZIndex
): number => (a.ZIndex ?? 0) - (b.ZIndex ?? 0);
