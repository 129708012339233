import React, { ReactElement } from "react";
import { Redirect } from "react-router-dom";
import TeamPageTemplate from "./TeamPageTemplate";
import { usePlanDetails } from "../../licensing/usePlanDetails";
import { routes } from "../../../const";

function Team(): ReactElement {
  const { hasAccessToTeamPage } = usePlanDetails();

  return hasAccessToTeamPage ? (
    <TeamPageTemplate />
  ) : (
    <Redirect to={routes.projects} />
  );
}
export default Team;
