import PQueue from "p-queue";

export enum SyncStatus {
  starting = "starting",
  pending = "pending",
  finished = "finished",
}

export type QueueStatus = {
  syncStatus: SyncStatus;
  percentage: number;
};

export type OverloadCallback = (
  queueStatus: QueueStatus
) => Promise<void> | void;

type PQueueEvent = "active" | "idle" | "add" | "next";

export abstract class SignalRQueue {
  protected signalQueue: PQueue;
  protected _queueOverloadCallback?: OverloadCallback;

  constructor(queueOptions?: { concurrency: number }) {
    this.signalQueue = new PQueue(queueOptions);
  }

  // wrapper over PQueue interface
  add<T>(promise: () => Promise<T>): Promise<T> {
    return this.signalQueue.add<T>(promise);
  }

  on(eventName: PQueueEvent, callback: () => void): PQueue {
    return this.off(eventName).on(eventName, callback);
  }

  off(eventName: PQueueEvent): PQueue {
    return this.signalQueue.off(eventName);
  }

  /**
   * Start or resume the queue.
   */
  start(): PQueue {
    return this.signalQueue.start();
  }

  /**
   * Pause the queue.
   */
  pause(): void {
    return this.signalQueue.pause();
  }

  setQueueOverloadCallback = (callback?: OverloadCallback): void => {
    this._queueOverloadCallback = callback;
  };

  protected getQueueSize(): number {
    const { size, pending } = this.signalQueue;
    return size + pending;
  }
}
