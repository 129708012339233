import React, { useRef } from "react";
import { Button, Flex } from "theme-ui";
import ReactCountryFlag from "react-country-flag";

import allCountries from "../../../countries/allCountries";
import { useCountryTranslations } from "../../../countries/useCountryTranslations";

function CountriesDropdown({ handleSelect, country, theme }) {
  const countryList = useRef(null);
  const activeCountry = useRef(null);

  const { countries, loading, error } = useCountryTranslations();

  if (loading || error) {
    return null;
  }

  return (
    <Flex variant="layout.dropdown" ref={countryList}>
      {allCountries.map(
        ({ iso2, dialCode, isAreaCode }) =>
          !isAreaCode && (
            <Button
              key={`${iso2}${dialCode}`}
              onClick={handleSelect(iso2)}
              onKeyPress={handleSelect(iso2)}
              variant="dropdownItem"
              ref={iso2 === country.iso2 ? activeCountry : null}
              sx={{
                bg: iso2 === country.iso2 && theme.colors.accent,
              }}
            >
              <ReactCountryFlag countryCode={iso2} svg />
              {countries[(iso2 || "").toUpperCase()]}
            </Button>
          )
      )}
    </Flex>
  );
}

export default CountriesDropdown;
