import React from "react";
import { useTranslation } from "react-i18next";
import { Text } from "theme-ui";

type BatchImportResultInProgressProps = {
  progress: number;
};

function BatchImportResultInProgress({
  progress,
}: BatchImportResultInProgressProps): React.ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <Text sx={{ mb: [3] }}>
        {t("dialog.batchUserImport.result.importing")}
      </Text>
      <Text>
        {t("dialog.batchUserImport.result.importProgress", {
          progress: Math.round(progress * 100),
        })}
      </Text>
    </>
  );
}

export default BatchImportResultInProgress;
