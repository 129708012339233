import React, { ReactElement, useRef } from "react";
import { Flex, Box, Button, ThemeUIStyleObject } from "theme-ui";
import { useTranslation } from "react-i18next";
import DownArrow from "../../icons/DownArrow";
import useDropdown from "../shared/forms/dropdowns/useDropdown";
import OptionsListItem, {
  Option,
} from "../shared/forms/options/OptionsListItem";

type Props = {
  current?: string;
  onSelect: (key: string) => void;
  spaceItems: Array<Option & { name: string; color: string }>;
};

function SpacesDropdown({
  current,
  spaceItems,
  onSelect,
}: Props): ReactElement {
  const { t } = useTranslation();

  const selectedEl = useRef(null);
  const dropEl = useRef(null);
  const [dropOpen, toggleDropOpen] = useDropdown(selectedEl, dropEl);

  const currentOption = current
    ? spaceItems.find((o) => o.key === current)
    : undefined;

  return (
    <Box ref={selectedEl} sx={dropdownStyle} onClick={() => toggleDropOpen()}>
      <Flex sx={buttonContainerStyle}>
        <Button
          variant="vanilla"
          sx={currentButtonStyle}
          style={
            currentOption
              ? {
                  backgroundColor: currentOption.color,
                  color: "white",
                }
              : undefined
          }
        >
          <Box
            title={currentOption?.name ?? t("spaces.otherSpaces")}
            sx={selectionWrapStyle}
          >
            {currentOption?.label ?? t("spaces.otherSpaces")}
          </Box>
          <DownArrow noShadow />
        </Button>
      </Flex>
      <Box ref={dropEl} hidden={!dropOpen} sx={listStyle}>
        {spaceItems.map((option) => (
          <OptionsListItem
            key={`option-${option.key}`}
            active={current === option.key}
            option={option}
            sx={{
              fontSize: [4],
              backgroundColor:
                option.key === current ? option.color : "transparent",
              "&:active, &:active:hover": {
                backgroundColor: option.color,
                color: "white",
              },
            }}
            boxed
            onClick={() => {
              onSelect(option.key);
              toggleDropOpen(false);
            }}
          />
        ))}
      </Box>
    </Box>
  );
}

const dropdownStyle: ThemeUIStyleObject = {
  alignItems: "center",
  border: 0,
  flexGrow: 1,
  flexBasis: "16.67%",
  padding: 0,
  position: "relative",
};

const buttonContainerStyle: ThemeUIStyleObject = {
  height: "100%",
};

const currentButtonStyle: ThemeUIStyleObject = {
  alignItems: "center",
  appearance: "none",
  backgroundColor: "transparent",
  border: "none",
  color: "var(--textColor)",
  cursor: "pointer",
  display: "flex",
  fontSize: [3, null, null, "inherit"],
  justifyContent: "space-between",
  lineHeight: "var(--gridTile)",
  outline: "none",
  py: [2, null, null, 3],
  width: "100%",

  "> svg": {
    fill: "currentColor",
  },
};

const selectionWrapStyle: ThemeUIStyleObject = {
  padding: "0 0 0 calc(var(--gridTile) / 4)",
  textAlign: "left",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const listStyle: ThemeUIStyleObject = {
  backgroundColor: "var(--contextBgColor)",
  boxShadow: "var(--controlShadow)",
  flex: 1,
  height: "auto",
  left: 0,
  margin: 0,
  maxHeight: "calc(var(--gridTile) * 6)",
  overflowX: "hidden",
  overflowY: "auto",
  padding: 0,
  position: "absolute",
  top: "auto",
  width: "100%",
  zIndex: 2,
};

export default SpacesDropdown;
