import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { createOrder, createSubscription } from "../../../api";
import { selectSubscriptionDetails } from "../../../reduxStore/subscriptionDetails/subscriptionDetails.reducer";
import { errorToast } from "../../../tools/errorToast";
import { goToStripeCheckout } from "../../../tools/stripe";
import { paymentUrls } from "../../licensing/payment/enums";

type OrderDetails = {
  productId: number;
  billingType: number;
  numberOfDevices: number;
  numberOfUsers: number;
  currencyCode: string;
  promoCode: string;
};

type SubscriptionCompare = Omit<OrderDetails, "promoCode" | "currencyCode"> & {
  subscription: ApiSubscription | null;
};

const isSameSubscription = ({
  subscription,
  productId,
  billingType,
  numberOfUsers,
  numberOfDevices,
}: SubscriptionCompare) =>
  subscription?.ProductId === productId &&
  subscription.BillingTypeId === billingType &&
  subscription.NumberOfUsers === numberOfUsers &&
  subscription.NumberOfDevices === numberOfDevices;

export const useOrderCreation = ({
  productId,
  billingType,
  numberOfDevices,
  numberOfUsers,
  currencyCode,
  promoCode,
}: OrderDetails): { goToCheckout: () => Promise<undefined | void> } => {
  const { t } = useTranslation();
  const { subscription } = useSelector(selectSubscriptionDetails);

  const getNewSubscriptionId = async () => {
    const result = await createSubscription({ productId, billingType });

    return result?.ID ?? null;
  };

  const goToCheckout = async () => {
    const subscriptionId = isSameSubscription({
      subscription,
      productId,
      billingType,
      numberOfUsers,
      numberOfDevices,
    })
      ? subscription?.ID
      : await getNewSubscriptionId();

    if (!subscriptionId) {
      errorToast(t("clientError.subscriptionCreationFailed"));
      return undefined;
    }

    const { PaymentReference } = await createOrder({
      subscriptionId,
      currency: currencyCode,
      promoCode,
      successUrl: paymentUrls.successUrl,
      cancelUrl: paymentUrls.cancelUrl,
      numberOfDevices: Number(numberOfDevices),
      numberOfUsers: Number(numberOfUsers),
    });

    return goToStripeCheckout(PaymentReference);
  };

  return { goToCheckout };
};
