import { isError } from "react-query";
import {
  all,
  call,
  cancelled,
  Effect,
  getContext,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { SignalRClient } from "../../../api/signalR";
import { InternalError } from "../../../errors/InternalError";
import { defaultProjectColor } from "../../../features/users/users.utils";
import { isRemoteObject } from "../../../studio/utils/fabricObjects";
import { hexToRGB } from "../../../tools/colors";
import { isExternalResourceError, stringifyError } from "../../../tools/errors";
import {
  presentingStorage,
  previouslyAddedObjectStorage,
  userPresenceStorage,
  viewportTransformStorage,
} from "../../../tools/localStorageStores";
import { onErrorToLog } from "../../../tools/telemetry";
import { parseQueryUrl } from "../../../tools/url";
import { ApiPermission, UIProjectUnavailableReason } from "../../../types/enum";
import { selectUserProfile } from "../../auth/auth.reducer";
import { monitorSaga } from "../../redux.utils";
import { selectSignalRConnected } from "../../signalR/signalR.reducer";
import { resetCanvasModeAction } from "../app/app.actions";
import { openCanvasChatAction } from "../chat/chat.actions";
import {
  IncrementalCompleteProjectAction,
  IncrementalUpdateProjectAction,
  onProjectResetAction,
  ProjectAction,
  ProjectActionType,
  setStatusAction,
  SetupProjectAction,
} from "./project.actions";
import {
  ProjectStatus,
  selectProjectMyself,
  selectProjectUsers,
} from "./project.reducer";
import { addTiles, completeProject } from "./project.saga.utils";
import {
  NotifyProjectActionType,
  SetUserOfflineAction,
  UpdatePermissionAction,
} from "./signalR-project/signalR-project.actions";
import { signalRProjectSaga } from "./signalR-project/signalR-project.saga";

function* incrementalCompleteProject({
  payload,
}: IncrementalCompleteProjectAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const { relations, tiles, config } = payload;

  try {
    yield call(completeProject, canvas, tiles, relations);
    config.onAllTilesAdded?.();

    // wait for all thumbnails download
    yield Promise.all(
      canvas
        .getObjects()
        .filter(isRemoteObject)
        .map((object) => object.onPreviewLoaded)
    );
  } catch (e) {
    if (isExternalResourceError(e)) {
      // Do nothing if some images failed to load. The error is already logged by img.onerror
      return;
    } else {
      onErrorToLog(
        isError(e)
          ? e
          : new InternalError(
              `Failed to complete project load: ${stringifyError(e)}`
            )
      );
    }
  } finally {
    config.onPreviewLoaded?.();
  }
}

function* incrementalUpdateProject({
  payload,
}: IncrementalUpdateProjectAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const { tiles, config } = payload;

  yield call(addTiles, canvas, tiles);

  config.onTilesAdded?.();
}

function* resetProject() {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const users = selectProjectUsers(yield select());

  canvas.reinitialize();
  canvas.initObjectsReservation(users); // TODO #5927: single source of truth
  canvas.requestRenderAll();
}

function* setupProject({ payload }: SetupProjectAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const {
    project: { Project },
    viewportTransform,
  } = payload;

  canvas.projectId = Project.ProjectId;
  canvas.discardActiveObject(); // needed when auto-reloading project
  viewportTransform && canvas.setViewportTransform(viewportTransform); // TODO #5927: single source of truth
}

function* setParticipants() {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const myself = selectProjectMyself(yield select());

  if (!myself) {
    return;
  }

  canvas.enableMultiSelectionFlag();
  canvas.updateSkipTargetFind();

  canvas.requestRenderAll();
}

function* setProjectStatus() {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const state: ApplicationGlobalState = yield select();
  const {
    canvas: { project },
  } = state;

  if (project.status.status === ProjectStatus.READY) {
    // runs when both users and tiles are available
    const users = selectProjectUsers(state);
    const { search } = window.location;
    const { mention: uuid, commentId } = parseQueryUrl<MentionUrlQuery>(search);
    const mentionedInChat = uuid && canvas.getObjectByUUID<fabric.Chat>(uuid);

    canvas.initObjectsReservation(users); // TODO #5927: single source of truth
    canvas.requestRenderAll();

    if (mentionedInChat) {
      canvas.panToObjects([mentionedInChat]);
      yield put(openCanvasChatAction(mentionedInChat.getChatStatus(commentId)));
    }
  }

  // TODO #5927: single source of truth
  canvas.isUserInactive = project.status.status === ProjectStatus.INACTIVE;
  canvas.signalRConnected = selectSignalRConnected(state);
  canvas.enableMultiSelectionFlag();
  canvas.updateSkipTargetFind();

  !canvas.signalRConnected && canvas.discardActiveObject({ isSilent: true });
}

function* setUserOffline({ payload }: SetUserOfflineAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const state: ApplicationGlobalState = yield select();

  const { UserName } = payload;
  const userStates = selectProjectUsers(state);
  const isMyself = userStates.find(
    (user) => user.UserName === UserName && user.IsMyself
  );

  !isMyself && UserName && canvas.clearReservation(UserName);

  canvas.requestRenderAll();
}

function* updatePermission({ payload }: UpdatePermissionAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const signalRClient: SignalRClient = yield getContext("signalRClient");
  const state: ApplicationGlobalState = yield select();

  // Avoid using `selectProjectMyself` as the user might not be in the project users list anymore
  const userProfile = selectUserProfile(state);
  const { ParticipantUserName, Permission } = payload;
  const myPermissionsChanged = ParticipantUserName === userProfile?.UserName;

  if (myPermissionsChanged && Permission === ApiPermission.noPermission) {
    yield put(
      setStatusAction({
        status: ProjectStatus.UNAVAILABLE,
        reason: UIProjectUnavailableReason.kickedOut,
      })
    );
    return;
  } else {
    // Now it's safe to use `selectProjectMyself`
    const myself = selectProjectMyself(state);
    const isPermissionReadOnly = Permission === ApiPermission.readPermission;
    const { __subselectedGroupRef: subselectedGroup } = canvas;
    const reservedObjects =
      canvas.reservedObjects[ParticipantUserName]?.objects;

    if (
      Permission < ApiPermission.readWritePermission &&
      reservedObjects?.length
    ) {
      /**
       * When changing somebody's permissions to `Read`,
       * only owner is authorized to send PostUnlock signal in order to release one's objects.
       *
       * @TODO to be done automatically server side: #6220, #5827, #6523
       * then, leave just if (perm < write && !isMyself) clearReservation(user)
       */
      myself?.IsProjectOwner &&
        signalRClient.postTileBatchAction("Lock", { lockedTiles: [] });

      canvas.clearReservation(ParticipantUserName);
      canvas.requestRenderAll();
    }

    if (isPermissionReadOnly) {
      if (subselectedGroup) {
        subselectedGroup.deactivateSubselectionMode();
        canvas.setActiveObject(subselectedGroup, { isSilent: true });
      }

      canvas.discardActiveObject({ isSilent: true });
      canvas.trigger("custom:drawing-mode:deactivate");

      yield put(resetCanvasModeAction());
    }

    canvas.updateSkipTargetFind();
  }
}

function* syncCanvasMyself(myself: ProjectOnlineUserInfo | undefined) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  canvas.isPermissionReadOnly = myself
    ? myself.Permission === ApiPermission.readPermission
    : true;
  canvas.projectColor = myself
    ? hexToRGB(myself.ProjectColor, 0.2)
    : defaultProjectColor;
}

/*----------  PROJECT INITIALIZATION SAGAS  ----------*/

function* waitForUsersAndTiles() {
  // Wait for both SET_PARTICIPANTS and INCREMENTAL_COMPLETE_PROJECT
  yield all([
    take(ProjectActionType.SET_PARTICIPANTS),
    take(ProjectActionType.INCREMENTAL_COMPLETE_PROJECT),
  ]);

  yield put(setStatusAction({ status: ProjectStatus.READY }));
}

function* projectLoadSaga() {
  try {
    yield all([
      waitForUsersAndTiles(),

      takeEvery(
        ProjectActionType.INCREMENTAL_COMPLETE_PROJECT,
        incrementalCompleteProject
      ),
      takeEvery(
        ProjectActionType.INCREMENTAL_UPDATE_PROJECT,
        incrementalUpdateProject
      ),
    ]);
  } finally {
    const isCancelled: boolean = yield cancelled();

    if (isCancelled) {
      /**
       * Reset the canvas if the project is restarted during the load.
       *
       * @TODO #7117 #7486: technically we should try also to abort any pending GetTilesByProjectIdPaged API
       * request by using something like an AbortController, otherwise it can be an issue in big projects
       * and slow networks.
       */
      yield put(onProjectResetAction());
    }
  }
}

function initializeProjectStorages(projectId: string) {
  viewportTransformStorage.setProjectNamespace(projectId);
  previouslyAddedObjectStorage.setProjectNamespace(projectId);
  presentingStorage.setProjectNamespace(projectId);
  userPresenceStorage.setProjectNamespace(projectId);
}

function* projectInitializationSaga(projectId: string) {
  yield call(initializeProjectStorages, projectId);
}

const isProjectInitAction = (action: ProjectAction) => {
  return (
    action.type === ProjectActionType.SET_PROJECT_STATUS &&
    action.payload.status === ProjectStatus.LOADING &&
    action.payload.loadingPercentage === 0
  );
};

export function* projectSaga(projectId: string): Generator<Effect> {
  yield all([
    projectInitializationSaga(projectId),
    signalRProjectSaga(),
    // Spawn a new saga each time the project is loaded (first load or resync), but cancel the
    // previous one if still active. This avoids INCREMENTAL_UPDATE_PROJECT and INCREMENTAL_COMPLETE_PROJECT
    // from the previous saga to be dispatched after it was cancelled by a new resync.
    // TODO #7486: write tests for this once everything is in sagas.
    takeLatest(isProjectInitAction, projectLoadSaga),

    monitorSaga(selectProjectMyself, syncCanvasMyself),

    takeEvery(ProjectActionType.PROJECT_RESET, resetProject),
    takeEvery(ProjectActionType.SETUP_PROJECT, setupProject),
    takeEvery(ProjectActionType.SET_PARTICIPANTS, setParticipants),
    takeEvery(ProjectActionType.SET_PROJECT_STATUS, setProjectStatus),
    takeEvery(NotifyProjectActionType.SET_USER_OFFLINE, setUserOffline),
    takeEvery(NotifyProjectActionType.UPDATE_PERMISSION, updatePermission),
  ]);
}
