import React, { ReactElement, useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { leaveProject } from "../../../../../api";
import Leave from "../../../../../icons/Leave";
import { useLoggedInStatus } from "../../../../authentication/useLoggedInStatus";
import { Confirmation } from "../../../../shared/modals";
import { TemplateProps } from "../../../../shared/modals/Modal";
import ModalHeader from "../../../../shared/modals/ModalHeader";
import useToast from "../../../../shared/toasts/useToast";
import { ProjectsContext } from "../../Projects";

type Props = {
  projectId: string;
};

function LeaveProjectModalTemplate({
  projectId,
  modalProps,
}: TemplateProps<Props>): ReactElement {
  const { t } = useTranslation();
  const { userProfile } = useLoggedInStatus();
  const projectLeftToast = useToast("project.leaveSuccessful");
  const { removeProjectFromList } = useContext(ProjectsContext).current;

  const onConfirm = useCallback(
    () =>
      userProfile &&
      leaveProject(projectId, userProfile).then(() => {
        projectLeftToast.show();
        removeProjectFromList(projectId);
      }),
    [projectId, projectLeftToast, removeProjectFromList, userProfile]
  );

  useEffect(() => {
    projectLeftToast.dismiss();
  }, [projectLeftToast]);

  return (
    <Confirmation
      header={<ModalHeader title={t("dialog.leaveProject")} Icon={Leave} />}
      confirmationLabel={t("dialog.leave")}
      variant="warning"
      onConfirm={onConfirm}
      {...modalProps}
    >
      {t("dialog.leaveProjectPrompt")}
    </Confirmation>
  );
}

export default LeaveProjectModalTemplate;
