import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { Link, Flex, Text, ThemeUIStyleObject, Grid, Button } from "theme-ui";
import { fixInvalidToken } from "../../../api/helpers";
import { routes } from "../../../const";
import {
  isStaticFeatureActive,
  staticFeatureFlags,
} from "../../../tools/flags";
import { useLoggedInStatus } from "../../authentication/useLoggedInStatus";
import { useAuthenticatingStatus } from "../../authentication/useAuthenticatingStatus";
import FullPageSpinner from "../../shared/spinners/FullPageSpinner";
import {
  acceptSubscriptionUserInvitation,
  getInvitedSubscriptionUser,
} from "../../../api";
import { toast } from "react-toastify";
import { isAPIError } from "../../../tools/errors";
import { errorCodeIds } from "../../../api/errorCodes";
import { useErrorHandler } from "../../shared/hooks/useErrorHandler";
import { LogCategory } from "../../../tools/telemetry";
import Page from "../layout/pages/Page";

const isRegistrationDisabled = isStaticFeatureActive(
  staticFeatureFlags.REGISTRATION_DISABLED
);

function AcceptTeamInvitation(): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { isInitialized, isLoggingIn } = useAuthenticatingStatus();
  const {
    isLoggedInAsUser,
    isLoggedOut,
    logout,
    userProfile,
  } = useLoggedInStatus();
  const {
    fallbackErrorHandler,
    handleApiError,
    redirectToErrorPage,
  } = useErrorHandler();

  const [isLoading, setLoading] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [isInvalidUser, setInvalidUser] = useState(false);
  const [invitationData, setInvitationData] = useState<UserProfile | undefined>(
    undefined
  );
  const [logoutTriggered, setLogoutTriggered] = useState(false);

  const queryParams = useMemo(() => {
    const params = new URLSearchParams(search);
    const token = params.get("token") ?? undefined;
    token && params.set("token", fixInvalidToken(token));
    return params;
  }, [search]);

  const token = queryParams.get("token");
  const tenant = queryParams.get("tenant") ?? undefined;
  const autoLoginFromInvite = queryParams.get("autoLoginFromInvite") ?? false;

  const loginRoute = `${routes.authenticate}/${tenant ?? ""}`;
  const registerRoute = `${routes.register}/${tenant ?? ""}`;

  const redirectQueryString = `?redirect=${encodeURIComponent(
    pathname + "?" + queryParams.toString()
  )}`;

  const loginLink = `${loginRoute}${redirectQueryString}`;

  /**
   * Registration handles acceptance of invitation without redirecting back.
   */
  const goToRegister = useCallback(() => {
    const state: RegisterLocationState = {
      invitationData,
      token: queryParams.get("token") ?? undefined,
    };
    history.replace(registerRoute, state);
  }, [history, invitationData, queryParams, registerRoute]);

  useEffect(() => {
    const getInviteData = async (token: string) => {
      setLoading(true);
      try {
        const response = await getInvitedSubscriptionUser(token);
        setInvitationData(response);
      } catch (error) {
        redirectToErrorPage(error);
      }
      setLoading(false);
    };
    if (token) {
      getInviteData(token);
    }
  }, [fallbackErrorHandler, redirectToErrorPage, token]);

  useEffect(() => {
    const acceptInvite = async (token: string, UserName: string) => {
      if (accepted) {
        return;
      }

      setAccepted(true);
      setLoading(true);

      try {
        await getInvitedSubscriptionUser(token, UserName);
        await acceptSubscriptionUserInvitation(token);
        toast(t("team.invite.accepted"));
        history.replace(routes.projects);
      } catch (error: unknown) {
        if (isAPIError(error)) {
          const { details } = error;
          const { errorCode } = details;

          if (
            errorCode === errorCodeIds.SubscriptionUserInvitationIncorrectUser
          ) {
            setInvalidUser(true);
            setLoading(false);
            return;
          }
        }

        fallbackErrorHandler(
          error,
          "Failed to accept team invite",
          LogCategory.auth
        );

        history.replace(routes.authenticate);
      }
    };

    if (token) {
      if (isLoggedInAsUser && userProfile) {
        acceptInvite(token, userProfile.UserName);
      } else if (isLoggedOut && tenant && autoLoginFromInvite) {
        history.push(loginLink);
      }
    }
  }, [
    accepted,
    autoLoginFromInvite,
    handleApiError,
    fallbackErrorHandler,
    history,
    isLoggedInAsUser,
    isLoggedOut,
    loginLink,
    t,
    tenant,
    token,
    userProfile,
  ]);

  useEffect(() => {
    if (logoutTriggered && isLoggedOut) {
      history.replace(loginLink);
    }
  }, [history, logoutTriggered, isLoggedOut, loginLink]);

  /**
   * This route is not an AuthRoute or PrivateRoute so we have to handle
   * the redirect manually. This means we need to wait for the state to update
   * BEFORE redirecting otherwise unnecessary requests will be triggered.
   */
  const onClickLogout = useCallback(() => {
    logout();
    setLogoutTriggered(true);
  }, [logout]);

  const onClickProjects = useCallback(() => {
    history.replace(routes.projects);
  }, [history]);

  if (!token) {
    return <Redirect to={routes.authenticate} />;
  }

  if (isLoading || !isInitialized || isLoggingIn) {
    return <FullPageSpinner />;
  }

  if (isInvalidUser) {
    return (
      <Page>
        <Text sx={textStyle}>{t("team.invite.unauthorisedUser")}</Text>

        <Flex sx={flexStyle}>
          <Grid columns={2} sx={gridStyle}>
            <Button onClick={onClickLogout}>{t("form.button.logout")}</Button>

            <Button onClick={onClickProjects}>
              {t("form.button.backToProjects")}
            </Button>
          </Grid>
        </Flex>
      </Page>
    );
  }

  return (
    <Page>
      <Text sx={textStyle}>
        <span>{t("team.invite.notLoggedIn")}</span>
        {!isRegistrationDisabled && (
          <span>{t("team.invite.notLoggedInSecondLine")}</span>
        )}
      </Text>

      <Flex sx={flexStyle}>
        <Link href={loginLink} variant="buttons.primary" sx={buttonStyle}>
          {t("team.invite.logInAndAccept")}
        </Link>
        {!isRegistrationDisabled && (
          <Button
            onClick={goToRegister}
            variant="buttons.primary"
            sx={buttonStyle}
          >
            {t("team.invite.registerAndAccept")}
          </Button>
        )}
      </Flex>
    </Page>
  );
}

export default AcceptTeamInvitation;

const gridStyle: ThemeUIStyleObject = { width: "100%" };

const flexStyle: ThemeUIStyleObject = {
  justifyContent: "center",
  my: [5],
  width: "100%",
};

const textStyle: ThemeUIStyleObject = {
  mt: [8],
  mx: [2],
  textAlign: "justify",
  span: { mr: [1] },
};

const buttonStyle: ThemeUIStyleObject = {
  mx: [2],
};
