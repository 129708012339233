import { useEffect, useMemo, useRef, useState } from "react";
import { colorThemes } from "../../../const";
import { isCanvasTainted } from "../../../tools/canvas";
import collaboard from "../../../tools/collaboard";
import { rgbToHex } from "../../../tools/colors";
import { isTaintedCanvasError } from "../../../tools/errors";
import useHotkeys from "../../shared/dom/useHotkeys";

export type CursorProps = {
  color: string;
  escapedColor: string;
  x: number;
  y: number;
  theme: colorThemes;
  hoveredObject: fabric.Object | undefined;
};

export type FullCursorProps = CursorProps & {
  isValidPosition: boolean;
};

type Props = {
  isCursorModeActive: boolean;
  withColorData?: boolean;
  exitCursorMode: () => void;
  isPositionValid?: (props: CursorProps) => boolean;
  toCursor: (props: FullCursorProps) => string;
  onPointerUp: (props: FullCursorProps) => void;
  onTaintedError?: () => void;
};

const useCanvasCursor = ({
  isCursorModeActive,
  withColorData,
  exitCursorMode,
  isPositionValid,
  onPointerUp,
  toCursor,
  onTaintedError,
}: Props): { isCanvasTainted: boolean } => {
  const [isTainted, setIsTainted] = useState(false);
  const contextRef = useRef<CanvasRenderingContext2D | null>(null);
  const { canvas } = collaboard;

  useHotkeys("esc", () => exitCursorMode(), [exitCursorMode]);

  useEffect(() => {
    if (canvas?.contextContainer) {
      setIsTainted(isCanvasTainted(canvas.contextContainer));
    }
  }, [canvas]);

  useEffect(() => {
    const { upperCanvasEl, lowerCanvasEl } = canvas || {};

    if (!canvas || !isCursorModeActive || !upperCanvasEl || !lowerCanvasEl) {
      return undefined;
    }

    const getColorUnderCursor = (ev?: MouseEvent): string => {
      const ctx = contextRef.current;
      const defaultColor = "#000000";

      if (!ctx || !withColorData || !ev) {
        return defaultColor;
      }

      try {
        const { clientX: x, clientY: y } = ev;
        const pxRatio = window.devicePixelRatio;
        const px = ctx.getImageData(x * pxRatio, y * pxRatio, 1, 1).data;
        const isTransparentColor = px[3] === 0;
        const hex = isTransparentColor
          ? "transparent"
          : rgbToHex(px[0], px[1], px[2]);
        return hex;
      } catch (e) {
        if (isTaintedCanvasError(e)) {
          // getImageData will fail if the canvas is tainted.
          onTaintedError && onTaintedError();
          setIsTainted(true);
          return defaultColor;
        }

        throw e;
      }
    };

    const getCursorProps = (ev?: MouseEvent): FullCursorProps => {
      const color = getColorUnderCursor(ev);
      const props: CursorProps = {
        color,
        escapedColor: color.replace("#", "%23"),
        x: ev?.clientX ?? 0,
        y: ev?.clientY ?? 0,
        theme: canvas.theme,
        hoveredObject: canvas._hoveredObject,
      };

      return {
        ...props,
        isValidPosition: isPositionValid ? isPositionValid(props) : true,
      };
    };

    const onMouseMove = (ev: MouseEvent): void => {
      if (!contextRef.current) {
        return;
      }

      setCursor(toCursor(getCursorProps(ev)));
    };

    const onMouseUp = (ev: MouseEvent): void => {
      if (!contextRef.current || !isCursorModeActive) {
        return;
      }

      const props = getCursorProps(ev);

      if (props.isValidPosition) {
        exitCursorMode();
        onPointerUp(props);
      }
    };

    const setCursor = (cursor: string, forceSet?: boolean) => {
      upperCanvasEl.style.cursor = cursor;
      if (forceSet) {
        // force update cursor, without user mouse movement:
        upperCanvasEl.setAttribute("hidden", "true");
        upperCanvasEl.removeAttribute("hidden");
      }
    };

    contextRef.current = lowerCanvasEl.getContext("2d");
    setCursor(toCursor(getCursorProps()), true);

    upperCanvasEl.addEventListener("mousemove", onMouseMove);
    upperCanvasEl.addEventListener("mouseup", onMouseUp);

    return () => {
      exitCursorMode();
      upperCanvasEl.removeEventListener("mousemove", onMouseMove);
      upperCanvasEl.removeEventListener("mouseup", onMouseUp);
      setCursor("default", true);
    };
  }, [
    canvas,
    isCursorModeActive,
    withColorData,
    exitCursorMode,
    toCursor,
    isPositionValid,
    onPointerUp,
    onTaintedError,
  ]);

  return useMemo(
    () => ({
      isCanvasTainted: isTainted,
    }),
    [isTainted]
  );
};

export default useCanvasCursor;
