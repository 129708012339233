import { AnyAction } from "redux";
import { reduxSingleton } from "./redux.singleton";

/**
 * Indirectly dispatch a Redux action without importing the reduxStore.
 *
 * @NOTE You should still find a better alternative way if possible, especially in a React component
 * or a saga.
 */
export const reduxDispatch = (action: AnyAction): void => {
  reduxSingleton.getStore().dispatch(action);
};
