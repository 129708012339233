import { AnySchema } from "yup";

export const mergeSchemas = <T = AnySchema>(
  ...schemas: AnySchema[]
): AnySchema<T> => {
  const [first, ...rest] = schemas;

  const merged = rest.reduce(
    (mergedSchemas, schema) => mergedSchemas.concat(schema),
    first
  );

  return merged;
};
