import type {
  CommentCreatedMessage,
  CommentDeletedMessage,
} from "../../../api/signalR/message.types";
import { ActiveChat } from "./chat.reducer";

export enum ChatActionType {
  OPEN_CHAT = "OPEN_CHAT",
  CLOSE_CHAT = "CLOSE_CHAT",
  SET_UNREAD_CHAT_MESSAGE_COUNT = "SET_UNREAD_CHAT_MESSAGE_COUNT",
  UPDATE_UNREAD_CHAT_MESSAGE_COUNT = "UPDATE_UNREAD_CHAT_MESSAGE_COUNT",
  INCREMENT_UNREAD_CHAT_MESSAGE_COUNT = "INCREMENT_UNREAD_CHAT_MESSAGE_COUNT",
  DECREMENT_UNREAD_CHAT_MESSAGE_COUNT = "DECREMENT_UNREAD_CHAT_MESSAGE_COUNT",
}

export const openCanvasChatAction = (payload: ActiveChat) =>
  ({
    type: ChatActionType.OPEN_CHAT,
    payload,
  } as const);

export const closeCanvasChatAction = (payload: { uuid: string }) =>
  ({
    type: ChatActionType.CLOSE_CHAT,
    payload,
  } as const);

type ChatCommentStatus = {
  TileId: string;
  LastViewedCommentId: number | null;
  UnreadCommentsCount: number;
};

type SetUnreadChatMessageCountPayload = {
  chatStatuses: ChatCommentStatus[];
};

export const setUnreadChatMessageCountAction = (
  payload: SetUnreadChatMessageCountPayload
) =>
  ({
    type: ChatActionType.SET_UNREAD_CHAT_MESSAGE_COUNT,
    payload,
  } as const);

type UpdateChatCommentStatus = {
  chatUuid: string;
  lastReadCommentId: number;
  unreadCommentsCount: number;
};

export const updateUnreadChatMessageCountAction = (payload: {
  chatStatus: UpdateChatCommentStatus;
}) =>
  ({
    type: ChatActionType.UPDATE_UNREAD_CHAT_MESSAGE_COUNT,
    payload,
  } as const);

export const incrementUnreadMessagesCountAction = (payload: {
  chatComment: CommentCreatedMessage;
}) =>
  ({
    type: ChatActionType.INCREMENT_UNREAD_CHAT_MESSAGE_COUNT,
    payload,
  } as const);

export const decrementUnreadMessagesCountAction = (payload: {
  chatComment: CommentDeletedMessage;
}) =>
  ({
    type: ChatActionType.DECREMENT_UNREAD_CHAT_MESSAGE_COUNT,
    payload,
  } as const);

export type OpenCanvasChatAction = ReturnType<typeof openCanvasChatAction>;
export type CloseCanvasChatAction = ReturnType<typeof closeCanvasChatAction>;
export type SetUnreadChatMessageCountAction = ReturnType<
  typeof setUnreadChatMessageCountAction
>;
export type UpdateUnreadChatMessageCountAction = ReturnType<
  typeof updateUnreadChatMessageCountAction
>;
export type IncrementUnreadMessagesCountAction = ReturnType<
  typeof incrementUnreadMessagesCountAction
>;
export type DecrementUnreadMessagesCountAction = ReturnType<
  typeof decrementUnreadMessagesCountAction
>;

export type ChatAction =
  | OpenCanvasChatAction
  | CloseCanvasChatAction
  | SetUnreadChatMessageCountAction
  | UpdateUnreadChatMessageCountAction
  | IncrementUnreadMessagesCountAction
  | DecrementUnreadMessagesCountAction;
