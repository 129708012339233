import SendToken from "../../features/authentication/passwordReset/SendTokenTWR";
import LoginPage from "../twr/Login";
import Tagline from "./Tagline";
import Logo from "./Logo";
import BannerLogo from "./BannerLogo";

// NOTE: other than logo / Tagline and colors, it's the same as TWR

const components: Partial<CustomerComponents> = {
  Logo,
  BannerLogo,
  Tagline,
  LoginPage,
  SendToken,
};

export default components;
