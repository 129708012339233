import React, { PropsWithChildren, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Container, Flex, Heading, Text } from "theme-ui";
import { selectAssertedUserProfileInfo } from "../../../../reduxStore/auth/auth.reducer";
import { getAppName } from "../../../../tools/customer";
import Logo from "../logo/Logo";
import MainNav from "../MainNav";
import UserMenu from "../NavBar/UserMenu";
import TagLine from "../TagLine";

function PageAuthorized({
  children,
}: PropsWithChildren<unknown>): ReactElement {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const userProfile = useSelector(selectAssertedUserProfileInfo);

  return (
    <>
      <Container as="header" variant="header">
        <Flex as="nav" variant="layout.navbar">
          <Logo withDisclaimer>
            <TagLine />
          </Logo>
          <Flex sx={{ alignItems: "center" }}>
            <Flex
              sx={{ flexDirection: "column", alignItems: "flex-end" }}
              pr={1}
            >
              <Text sx={{ color: "primary", fontSize: [3], fontWeight: 400 }}>
                {t("hello")}
              </Text>
              <Text sx={{ color: "text", fontSize: [4], fontWeight: 500 }}>
                {userProfile.FirstName}
              </Text>
            </Flex>
            <UserMenu {...userProfile} />
          </Flex>
        </Flex>
      </Container>
      <Flex as="main" variant="layout.pageWrap">
        <Container as="section">
          <Heading
            as="h1"
            pb={1}
            sx={{
              fontSize: [8],
              width: ["100%"],
            }}
          >
            {t(`headings.${pathname}.heading`, { appName: getAppName() })}
          </Heading>
          <Text as="header" sx={{ fontSize: [5], fontWeight: 300 }}>
            {t(`headings.${pathname}.tagline`)}
          </Text>
          <MainNav />
          {children}
        </Container>
      </Flex>
    </>
  );
}
export default PageAuthorized;
