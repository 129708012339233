import { Formik, FormikConfig } from "formik";
import React, { ReactElement, useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast, ToastId } from "react-toastify";
import { Box, Button, ThemeUIStyleObject } from "theme-ui";
import { object, string } from "yup";
import { updateProject } from "../../../../../api";
import { projectPermissions } from "../../../../../const";
import ApplyEdit from "../../../../../icons/ApplyEdit";
import Edit from "../../../../../icons/Edit";
import { errorToast } from "../../../../../tools/errorToast";
import {
  ApiResourcePermission,
  ApiResourceType,
} from "../../../../../types/enum";
import { maxProjectDescriptionLength } from "../../../../../validationSchemas/fieldRestrictions";
import { usePermissions } from "../../../../permissions/usePermissions";
import { useSpacePermission } from "../../../../spaces/useSpaces";
import InputField from "./InputField";

type Props = PropsWithRequiredChildren<{
  project: ApiProject;
  isEditMode: boolean;
  isExpanded: boolean;
  onProjectEdited: (newProject: Project) => void;
  activateEditMode: (projectId: string | null) => void;
}>;

function EditProjectDescriptionField({
  project,
  isEditMode,
  isExpanded,
  children,
  onProjectEdited,
  activateEditMode,
}: Props): ReactElement {
  const { IsLicensed, Project, Permission } = project;
  const { Description, ProjectId, SpaceId } = Project;
  const { hasPermission } = usePermissions();
  const spacePermission = useSpacePermission(SpaceId);
  const canEdit = hasPermission({
    type: SpaceId ? ApiResourceType.SpaceProjects : ApiResourceType.Project,
    value: String(ProjectId),
    permission: ApiResourcePermission.Update,
    spacePermission,
    projectPermission: Permission,
    defaultPermission: Permission >= projectPermissions.readWritePermission,
  });
  const { t } = useTranslation();
  const toastId = useRef<ToastId | null>(0);

  const formikProps: FormikConfig<Project> = {
    initialValues: Project,
    onReset: () => activateEditMode(null),
    onSubmit: async (newProject) => {
      if (newProject.Description === Description) {
        return activateEditMode(null);
      }

      await updateProject(newProject);
      return onProjectEdited(newProject);
    },
    validationSchema: object().shape({
      Description: string().max(
        maxProjectDescriptionLength,
        t("project.nameTooLong", { charsLimit: maxProjectDescriptionLength })
      ),
    }),
  };

  const EditIcon = isEditMode ? ApplyEdit : Edit;

  return (
    <Formik {...formikProps}>
      {({ handleReset, submitForm, isValid, isSubmitting, errors }) => {
        if (errors.Description) {
          toastId.current = errorToast(errors.Description);
        } else if (toastId.current) {
          toast.dismiss(toastId.current);
          toastId.current = 0;
        }

        const hoverIconStyle = isValid
          ? { fill: isExpanded ? "black" : "accent" }
          : undefined;
        return (
          <Box
            sx={{
              width: "100%",
              border: "2px solid transparent",
              borderColor: isEditMode ? "accent" : undefined,
              "&:hover": IsLicensed
                ? {
                    borderColor: "accent",
                    svg: hoverIconStyle,
                  }
                : undefined,
            }}
          >
            {isEditMode ? (
              <InputField
                name="Description"
                disabled={isSubmitting}
                // onBlur={() => !dirty && handleReset()} // TODO: clash with submit button
                onComplete={(isCancelled) => {
                  isCancelled ? handleReset() : isValid && submitForm();
                }}
              />
            ) : (
              children
            )}
            {IsLicensed && canEdit && (
              <Button
                id="btn-edit-name"
                type="submit"
                sx={{
                  ...buttonStyle,

                  "&:hover:enabled": {
                    backgroundColor: "transparent",
                    svg: hoverIconStyle,
                  },
                }}
                disabled={!isValid}
                onClick={() =>
                  isEditMode ? submitForm() : activateEditMode(ProjectId)
                }
              >
                <EditIcon noShadow />
              </Button>
            )}
          </Box>
        );
      }}
    </Formik>
  );
}

const buttonStyle: ThemeUIStyleObject = {
  position: "absolute",
  right: 0,
  top: "50%",
  transform: "translateY(-50%)",
  m: [0],
  py: [0],
  px: [1],
  cursor: "pointer",
  backgroundColor: "transparent",
  border: "none",

  svg: {
    fill: "black",
    width: "var(--gridTile)",
    height: "var(--gridTile)",
  },
};

export default EditProjectDescriptionField;
