import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Box, Heading, Link, Text, ThemeUIStyleObject } from "theme-ui";

type Props = {
  license: OtherPlan;
};

function OtherLicense({
  license: { Icon, nameKey, infoKey, getLink },
}: Props): ReactElement {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Box key={nameKey} sx={containerStyle}>
        <Icon style={{ height: "60px", width: "60px" }} />
        <Heading sx={headingStyle}>{t(nameKey)}</Heading>
        <Text sx={{ fontSize: "0.85rem" }}>{t(infoKey)}</Text>
      </Box>

      <Link
        href={getLink(i18n.resolvedLanguage)}
        target="_blank"
        rel="nofollow noreferrer"
        variant="buttons.primary"
        sx={linkStyle}
      >
        {t("plans.contactUs")}
      </Link>
    </>
  );
}

const containerStyle: ThemeUIStyleObject = {
  backgroundColor: "black",
  color: "white",
  overflow: "hidden",
  padding: "50px 10px 60px",
  textAlign: "center",
  svg: {
    fill: "primary",
  },
};

const headingStyle: ThemeUIStyleObject = {
  color: "white",
  display: "block",
  fontSize: "3rem",
  fontWeight: 500,
  margin: "20px 0 10px 0",
};

const linkStyle: ThemeUIStyleObject = {
  fontSize: "18px",
  fontWeight: "bold",
  padding: [3],
  textDecoration: "none",
};

export default OtherLicense;
