import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Flex, Heading, ThemeUIStyleObject } from "theme-ui";
import LoginFooter from "../../features/authentication/login/LoginFooter";
import LoginOptions from "../../features/authentication/login/LoginOptions";
import { useCustomerComponents } from "../../features/licensing/useCustomerComponents";
import Logo from "../../features/routes/layout/logo/Logo";
import TWRBanner from "./TWRBanner";

function LoginTWR(): ReactElement {
  const { t } = useTranslation();
  const { Tagline: TaglineContent } = useCustomerComponents();

  return (
    <>
      <TWRBanner />
      <Flex sx={containerStyle}>
        <Heading sx={headingStyle}>{t("form.header.loginTWR")}</Heading>
        <Flex sx={bodyStyle}>
          <Flex sx={innerBodyStyle}>
            <Logo size="140px" sx={logoStyle}>
              <TaglineContent height="90px" />
            </Logo>
          </Flex>
        </Flex>
      </Flex>
      <LoginOptions />
      <LoginFooter />
    </>
  );
}

const containerStyle: ThemeUIStyleObject = {
  width: "100%",
  mt: "10rem",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
};

const headingStyle: ThemeUIStyleObject = { fontSize: [8], mr: [6] };

const bodyStyle: ThemeUIStyleObject = {
  flexDirection: "column",
  maxWidth: "400px",
};

const innerBodyStyle: ThemeUIStyleObject = {
  justifyContent: "flex-start",
  alignItems: "center",
};

const logoStyle: ThemeUIStyleObject = { mr: [4] };

export default LoginTWR;
