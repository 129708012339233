import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Input,
  Text,
  ThemeUIStyleObject,
} from "theme-ui";
import { deleteSpace, updateSpace, updateSpaceParticipant } from "../../../api";
import { useSpaceUsers } from "../../../api/useRequest";
import Delete from "../../../icons/Delete";
import Envelope from "../../../icons/Envelope";
import Leave from "../../../icons/Leave";
import Spaces from "../../../icons/Spaces";
import debounce from "../../../tools/debounce";
import {
  ApiPermission,
  ApiResourcePermission,
  ApiResourceType,
} from "../../../types/enum";
import { useLoggedInStatus } from "../../authentication/useLoggedInStatus";
import { usePermissions } from "../../permissions/usePermissions";
import ColorSquare from "../../shared/color/ColorSquare";
import { BasicDropdownItem } from "../../shared/forms/dropdowns/BasicDropdown/BasicDropdown";
import { Confirmation, useConfirmModal } from "../../shared/modals";
import ModalHeader from "../../shared/modals/ModalHeader";
import { useSpaces } from "../useSpaces";
import { isSpaceAdmin } from "../utils";
import InviteToSpaceModalTemplate from "./InviteToSpaceModalTemplate";
import SpaceFavourite from "./SpaceFavourite";
import SpaceInfo, { SpaceInfoValues } from "./SpaceInfo";
import { SpaceUsers } from "./SpaceUsers";

type Props = {
  space: Space;
  onExit: () => void;
};

export default function Space({ space, onExit }: Props): ReactElement {
  const [isExpanded, setIsExpanded] = useState(false);
  const [query, setQuery] = useState("");

  const { t } = useTranslation();
  const { Space: spaceInfo, IsFavorite, SpacePermission } = space;
  const { SpaceId } = spaceInfo;
  const usersQueryResult = useSpaceUsers(SpaceId, query);
  const { users, refetch: refetchUsers } = usersQueryResult;
  const { refetch: refetchSpaces } = useSpaces();
  const { userProfile } = useLoggedInStatus();

  const { hasPermission } = usePermissions();
  const canEditSpace = hasPermission({
    type: ApiResourceType.Space,
    value: String(SpaceId),
    permission: ApiResourcePermission.Update,
    spacePermission: SpacePermission,
    defaultPermission: isSpaceAdmin(space.SpacePermission),
  });
  const canDeleteSpace = hasPermission({
    type: ApiResourceType.Space,
    value: String(SpaceId),
    permission: ApiResourcePermission.Delete,
    spacePermission: SpacePermission,
    defaultPermission: isSpaceAdmin(space.SpacePermission),
  });
  const canViewSpaceParticipants = hasPermission({
    type: ApiResourceType.SpaceParticipants,
    value: String(SpaceId),
    permission: ApiResourcePermission.View,
  });
  const canAddSpaceParticipants = hasPermission({
    type: ApiResourceType.SpaceParticipants,
    value: String(SpaceId),
    permission: ApiResourcePermission.Create,
    spacePermission: SpacePermission,
    defaultPermission: isSpaceAdmin(space.SpacePermission),
  });
  const canUpdateSpaceParticipants = hasPermission({
    type: ApiResourceType.SpaceParticipants,
    value: String(SpaceId),
    permission: ApiResourcePermission.Update,
    spacePermission: SpacePermission,
    defaultPermission: isSpaceAdmin(space.SpacePermission),
  });
  const canRemoveSpaceParticipants = hasPermission({
    type: ApiResourceType.SpaceParticipants,
    value: String(SpaceId),
    permission: ApiResourcePermission.Delete,
    spacePermission: SpacePermission,
    defaultPermission: isSpaceAdmin(space.SpacePermission),
  });

  const deleteSpaceModal = useConfirmModal();
  const leaveSpaceModal = useConfirmModal();

  const invitesInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!isExpanded && invitesInputRef.current) {
      invitesInputRef.current.blur();
    }
  }, [isExpanded]);

  const showUpdateToast = () => {
    toast.dismiss();
    toast(t("spaces.updateSuccessful"));
  };

  const onInfoChange = ({ Name, Color }: SpaceInfoValues) => {
    updateSpace(SpaceId, Name, Color).then(() => {
      refetchSpaces();
      showUpdateToast();
    });
  };

  const onFavouriteChange = () => {
    updateSpaceParticipant({
      SpaceId,
      User: userProfile?.UserName || "",
      ProjectPermission: space.ProjectPermission,
      SpacePermission: space.SpacePermission,
      IsFavorite: !IsFavorite,
    }).then(() => {
      refetchSpaces();
      showUpdateToast();
    });
  };

  const onQueryChange = useMemo(
    () =>
      debounce((value: string) => {
        setQuery(value.trim());
      }, 250),
    []
  );

  useEffect(() => {
    return () => onQueryChange.cancel();
  }, [onQueryChange]);

  const onPermissionChange = (
    user: SpaceUser,
    permission: BasicDropdownItem<ApiPermission>
  ) => {
    updateSpaceParticipant({
      SpaceId,
      User: user.User.UserName,
      ProjectPermission: permission.value,
      SpacePermission: user.SpacePermission,
      IsFavorite: user.IsFavorite,
    }).then(() => {
      refetchUsers();
      showUpdateToast();
    });
  };

  const onAdminToggle = (user: SpaceUser, isAdmin: boolean) => {
    updateSpaceParticipant({
      SpaceId,
      User: user.User.UserName,
      ProjectPermission: user.ProjectPermission,
      SpacePermission: isAdmin
        ? ApiPermission.readWritePermission
        : ApiPermission.readPermission,
      IsFavorite: user.IsFavorite,
    }).then(() => {
      refetchSpaces(); // User can remove himself from Admins so we refetch space permissions
      refetchUsers();
      showUpdateToast();
    });
  };

  const onUserDelete = (user: SpaceUser) => {
    return updateSpaceParticipant({
      SpaceId,
      User: user.User.UserName,
      ProjectPermission: ApiPermission.noPermission,
      SpacePermission: ApiPermission.noPermission,
      IsFavorite: user.IsFavorite,
    }).then(() => {
      const isLeaving = user.User.UserName === userProfile?.UserName;
      if (isLeaving) {
        return;
      }

      refetchUsers();
      showUpdateToast();
    });
  };

  const onInvitesSent = (isPartial: boolean) => {
    refetchUsers();

    if (!isPartial) {
      setIsExpanded(false);
      showUpdateToast();
    }
  };

  const onSpaceDelete = () => {
    deleteSpaceModal.open({
      confirmCallback: async () => {
        await deleteSpace(SpaceId);
        refetchSpaces();
        onExit();
      },
    });
  };

  const onSpaceLeave = () => {
    leaveSpaceModal.open({
      confirmCallback: async () => {
        const myself = users.find(
          (user) => user.User.UserName === userProfile?.UserName
        );

        myself && (await onUserDelete(myself));
        refetchSpaces();
        onExit();
      },
    });
  };

  return (
    <Box>
      <Heading as="h4" variant="text.h4" sx={{ mt: [7] }}>
        <Spaces noShadow margin="0 0 0 -.75rem" scale={1.25} />
        {t("spaces.spaceInfo")}
      </Heading>

      {canEditSpace ? (
        <SpaceInfo
          space={space}
          onInfoChange={onInfoChange}
          onFavouriteChange={onFavouriteChange}
        />
      ) : (
        <Grid
          columns="repeat(auto-fit, minmax(10rem, 1fr))"
          gap={[5]}
          sx={{ alignItems: "center" }}
        >
          <Flex>
            <Text>{t("spaces.spaceName")}: </Text>
            <Text sx={{ fontWeight: 600, ml: [2] }}>{spaceInfo.Name}</Text>
          </Flex>

          <Flex sx={{ alignItems: "center" }}>
            <Text>{t("spaces.spaceColor")}: </Text>
            <Box sx={{ ml: [2] }}>
              <ColorSquare hex={spaceInfo.Color} />
            </Box>
          </Flex>

          <SpaceFavourite
            isFavourite={IsFavorite}
            onFavouriteChange={onFavouriteChange}
          />
        </Grid>
      )}

      {canViewSpaceParticipants && (
        <SpaceUsers
          adminVisible={canUpdateSpaceParticipants}
          removeVisible={canRemoveSpaceParticipants}
          usersQueryResult={usersQueryResult}
          onQueryChange={onQueryChange}
          onPermissionChange={onPermissionChange}
          onAdminToggle={onAdminToggle}
          onDelete={onUserDelete}
        />
      )}

      {canAddSpaceParticipants && (
        <Box>
          <Heading as="h4" variant="text.h4" sx={{ mt: [7] }}>
            <Envelope noShadow margin="0 0 0 -.75rem" />
            {t("spaces.addUsers")}
          </Heading>
          <Text sx={{ mb: [4] }}>{t("spaces.inviteDescription")}</Text>
          <Input
            data-cy="space-add-user"
            ref={invitesInputRef}
            variant="inputMulti"
            onFocus={(e) => setIsExpanded(true)}
            placeholder={t("spaces.emailsPlaceholder")}
            sx={invitesInputStyle}
          />
        </Box>
      )}

      <Grid columns="1fr 1fr" sx={buttonsBarStyle}>
        <Flex>
          {canDeleteSpace ? (
            <Button
              data-cy="space-delete"
              variant="warning"
              onClick={onSpaceDelete}
              sx={buttonStyle}
            >
              {t("spaces.deleteSpace")}
            </Button>
          ) : (
            <Button variant="warning" onClick={onSpaceLeave} sx={buttonStyle}>
              {t("spaces.leaveSpace")}
            </Button>
          )}
        </Flex>
      </Grid>

      {isExpanded && (
        <InviteToSpaceModalTemplate
          spaceId={SpaceId}
          setIsExpanded={setIsExpanded}
          onClose={() => setIsExpanded(false)}
          onInvitesSent={onInvitesSent}
        />
      )}

      <Confirmation
        {...deleteSpaceModal.props}
        variant="warning"
        header={<ModalHeader title={t("spaces.deleteSpace")} Icon={Delete} />}
        confirmationLabel={t("dialog.delete")}
      >
        <Trans i18nKey="dialog.deleteSpacePrompt" />
      </Confirmation>

      <Confirmation
        {...leaveSpaceModal.props}
        variant="warning"
        header={<ModalHeader title={t("spaces.leaveSpace")} Icon={Leave} />}
        confirmationLabel={t("dialog.leave")}
      >
        {t("dialog.leaveSpacePrompt")}
      </Confirmation>
    </Box>
  );
}

const buttonsBarStyle: ThemeUIStyleObject = {
  mt: [7],
};

const buttonStyle: ThemeUIStyleObject = {
  flex: "unset",
  minWidth: [0, "13.75rem"],
};

const invitesInputStyle: ThemeUIStyleObject = {
  border: "1px solid",
  outlineColor: "primary",
  transition: "all 0.15s ease-in-out",
  py: [2],
  px: [2],
  width: "100%",
  cursor: "text",
  maxHeight: "300px",
  overflowY: "auto",
  fontSize: [4],
};
