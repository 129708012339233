import theme from "../default";

const configuration: CustomerConfiguration = {
  appName: "Collaboard",
  faviconName: "favicon-main.ico",
  loginTroubleHref: "mailto:support@collaboard.app",
  theme,
};

export default configuration;
