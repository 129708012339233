import { merge } from "theme-ui";

import { runtimeConfig } from "../tools/runtimeConfig";
import defaultTheme, { DefaultTheme } from "./default";

// TODO: #6214 - Remove this and move the configuration to server

const prowiseTheme = merge(defaultTheme, {
  name: "prowise",
  colors: {
    accent: "#0057a5",
    active: "#0057a5",
    danger: "#e84e1b",
    primary: "#4576b8",
    disabled: "#d3d3d3",
    disabledDark: "#666666",
    accentAlternate: "#014786",
    accentSecondary: "#4786bd",
    accentSecondaryAlternate: "#2772b4",
    accentSecondaryDimmed: "#aacce9",
    disclaimerBg: "#0057a5",
    ...runtimeConfig.styles?.colors,
  },
  layout: {
    // removes floating (fixed) logos
    formsWrap: {
      "&:before": undefined,
      "&:after": undefined,
    },
  },
  fonts: {
    body: "system-ui, sans-serif",
  },
  links: {
    projectThumbnailLinkNew: {
      ".project-thumbnail-link__icon": {
        fill: "#ffffff",
      },
    },
  },
}) as DefaultTheme;

export default prowiseTheme;
