import React, { ReactElement } from "react";
import { ThemeUIStyleObject } from "theme-ui";
import { useField } from "formik";
import BasicDropdown, {
  BasicDropdownItem,
} from "../dropdowns/BasicDropdown/BasicDropdown";

type Props<T extends string | number> = {
  name: string;
  items: BasicDropdownItem<T>[];
  placeholder?: string;
  onChange?: (item: BasicDropdownItem<T>) => void; // onChange is made optional
  disabled?: boolean;
  sx?: ThemeUIStyleObject;
  selectSx?: ThemeUIStyleObject;
  optionsSx?: ThemeUIStyleObject;
  optionSx?: ThemeUIStyleObject;
  testId?: string;
};

function BasicDropdownField<T extends string | number>({
  testId,
  name,
  onChange,
  ...dropdownProps
}: Props<T>): ReactElement {
  const [field, , helpers] = useField(name);

  const onItemClick = (item: BasicDropdownItem<T>) => {
    helpers.setValue(item.value);
    onChange && onChange(item);
  };

  return (
    <BasicDropdown
      data-cy={testId}
      {...dropdownProps}
      value={field.value}
      ariaLabel={name}
      onChange={onItemClick}
    />
  );
}

export default BasicDropdownField;
