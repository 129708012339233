import { Formik, FormikConfig } from "formik";
import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { boolean, object, string } from "yup";
import Accordion from "../../../../../icons/Accordion";
import Modal from "../../../../shared/modals";
import ConfirmationButtons from "../../../../shared/modals/ConfirmationButtons";
import { TemplateProps } from "../../../../shared/modals/Modal";
import TypeSelection from "./TypeSelection";
import { useUISettings } from "../../../../../reduxStore/uiSettings";
import { dynamicFeatureFlags } from "../../../../../tools/flags";
import {
  useCreateProjectModalTemplate,
  CreateProjectValues,
} from "./useCreateProjectModalTemplate";
import { maxProjectDescriptionLength } from "../../../../../validationSchemas/fieldRestrictions";
import { withModalTemplate } from "../../../../shared/modals/withModalTemplate";

type Props = {
  activeSpaceId: number | null;
};

function CreateProjectModalTemplate({
  modalProps,
  activeSpaceId,
}: TemplateProps<Props>): ReactElement {
  const { t } = useTranslation();
  const { isDynamicFeatureActive } = useUISettings();

  const {
    runCreateBlankProject,
    type,
    types,
    setType,
  } = useCreateProjectModalTemplate();

  const mustAcceptToS = isDynamicFeatureActive(
    dynamicFeatureFlags.CREATE_PROJECT_TOS
  );

  const formikProps: FormikConfig<CreateProjectValues> = {
    initialValues: {
      Description: "",
      SpaceId: activeSpaceId,
      CreateProjectToS: !mustAcceptToS,
    },
    onSubmit: (values) => {
      runCreateBlankProject(values);
    },
    validationSchema: object().shape({
      Description: string().max(
        maxProjectDescriptionLength,
        t("clientError.tooLongMax", { max: maxProjectDescriptionLength })
      ),
      CreateProjectToS: boolean().oneOf([true], t("clientError.required")),
    }),
    validateOnBlur: false,
    isInitialValid: !mustAcceptToS,
  };

  useEffect(() => {
    toast.dismiss();
  }, []);

  return (
    <Formik {...formikProps}>
      {({ submitForm, isValid }) => (
        <Modal
          header={
            <>
              <Accordion /> <span>{t("dialog.createNewProject")}</span>
            </>
          }
          actionButtons={
            <ConfirmationButtons
              onConfirm={submitForm}
              onClose={() => modalProps.onClose()}
              confirmationLabel={t("dialog.createProject")}
              closeOnConfirm={true}
              confirmationDisabled={!isValid}
            />
          }
          {...modalProps}
        >
          <TypeSelection type={type} setType={setType} types={types} />
        </Modal>
      )}
    </Formik>
  );
}

export default withModalTemplate(CreateProjectModalTemplate);
