import { Form, Formik, FormikConfig } from "formik";
import React, { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Flex, Heading } from "theme-ui";
import { updateUserAndProfile } from "../../../api";
import { companyIndustries, companySizes } from "../../../const";
import { changeLanguage } from "../../../i18n";
import { updateUserProfileAction } from "../../../reduxStore/auth/auth.actions";
import { useUISettings } from "../../../reduxStore/uiSettings";
import { dynamicFeatureConfig } from "../../../tools/flags";
import { LogCategory } from "../../../tools/telemetry";
import { normalizeNullValues } from "../../../tools/validationTools";
import { userProfileSchema } from "../../../validationSchemas/userProfile";
import { useLoggedInStatus } from "../../authentication/useLoggedInStatus";
import { useCountryTranslations } from "../../shared/countries/useCountryTranslations";
import InputDropdownField, {
  InputDropdownOption,
} from "../../shared/forms/fields/InputDropdownField";
import InputField from "../../shared/forms/fields/InputField";
import PhoneInputField from "../../shared/forms/fields/PhoneInputField";
import { useErrorHandler } from "../../shared/hooks/useErrorHandler";
import useToast from "../../shared/toasts/useToast";

function UserProfile(): ReactElement {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { countries, error } = useCountryTranslations();
  const { getLanguages, getDynamicFeatureConfig } = useUISettings();
  const { appLanguages } = getLanguages();
  const profileInfoSavedToast = useToast("users.profileInfoSaved");
  const { fallbackErrorHandler } = useErrorHandler();
  const { userProfile } = useLoggedInStatus();

  const disableEditableName = getDynamicFeatureConfig<boolean>(
    dynamicFeatureConfig.DISABLE_REGISTRATION_NAME
  );

  const countriesOptions = useMemo(
    () => Object.entries(countries).map(([value, label]) => ({ value, label })),
    [countries]
  );

  const companySizeOptions: InputDropdownOption<number>[] = useMemo(() => {
    return Object.entries(companySizes).map(([key, value]) => ({
      value,
      label: t(`CompanySize.${key}`),
    }));
  }, [t]);

  const companyIndustryOptions: InputDropdownOption<number>[] = useMemo(() => {
    return Object.entries(companyIndustries).map(([key, value]) => ({
      value,
      label: t(`CompanyIndustry.${key}`),
    }));
  }, [t]);

  const userData = useMemo(() => {
    return userProfile
      ? {
          Language: userProfile.Language || i18n.resolvedLanguage,
          ...userProfile,
        }
      : { Language: i18n.resolvedLanguage };
  }, [userProfile, i18n.resolvedLanguage]);

  const initialValues = useMemo(() => {
    const normalized = normalizeNullValues(userData as EditUserData);

    return {
      ...normalized,
      // Ensure these values are in the form for InputDropDownField to correctly
      // detect the number type.
      // TODO: refactor InputDropdownField to accept only string values and be consistent
      // with shared/DropDown.
      CompanyIndustry: normalized.CompanyIndustry || 0,
      CompanySize: normalized.CompanySize || 0,
    };
  }, [userData]);

  const formikProps: FormikConfig<Partial<EditUserData>> = {
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      // Reset values added for correct type conversion by InputDropDownField
      const normalizedValues = Object.fromEntries(
        Object.entries(values).map(([key, value]) => {
          if (value === 0) {
            return [key, ""];
          }
          return [key, value];
        })
      );

      try {
        const user = await updateUserAndProfile(normalizedValues);
        dispatch(updateUserProfileAction(user));
        profileInfoSavedToast.show();
      } catch (error) {
        actions.setSubmitting(false);
        fallbackErrorHandler(
          error,
          "Failed to save user profile",
          LogCategory.api
        );
      }
    },
    validationSchema: userProfileSchema(t),
  };

  return (
    <Formik {...formikProps}>
      {({ isSubmitting, isValid, setFieldValue, values }) => (
        <Form id="user-info-personal">
          <Heading as="h3" variant="text.h3">
            {t("app.personalInformation")}
          </Heading>
          <InputField
            required
            name="FirstName"
            variant="profile"
            disabled={disableEditableName}
          />
          <InputField
            required
            name="LastName"
            variant="profile"
            disabled={disableEditableName}
          />
          <InputField name="CompanyName" variant="profile" />
          <InputDropdownField
            name="CompanyIndustry"
            variant="profile"
            options={companyIndustryOptions}
          />
          <InputField name="CompanyRole" variant="profile" />
          <InputDropdownField
            name="CompanySize"
            variant="profile"
            options={companySizeOptions}
          />
          <PhoneInputField
            name="PhoneNumber"
            variant="profile"
            defaultCountry={values.Country}
          />
          {countries && !error && (
            <InputDropdownField
              name="Country"
              variant="profile"
              options={countriesOptions}
            />
          )}
          <InputDropdownField
            name="Language"
            options={appLanguages}
            onHandleChange={(value) => {
              changeLanguage(value);
              setFieldValue("Language", value);
            }}
            variant="profile"
          />
          <Flex variant="layout.profileButtonContainer">
            <Button
              type="submit"
              variant="profile"
              disabled={!isValid || isSubmitting}
            >
              {t("form.button.save")}
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  );
}

export default UserProfile;
