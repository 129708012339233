import React, { ReactElement, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Image, ThemeUIStyleObject } from "theme-ui";
import { updateUserAndProfile } from "../../../../api";
import {
  acceptedImageTypes,
  colorThemes,
  maxAvatarSizeKB,
} from "../../../../const";
import ThinPlus from "../../../../icons/ThinPlus";
import { updateUserProfileAction } from "../../../../reduxStore/auth/auth.actions";
import { selectAssertedUserProfileInfo } from "../../../../reduxStore/auth/auth.reducer";
import { errorToast } from "../../../../tools/errorToast";
import { readAndCompressImage } from "../../../../tools/files";
import { LogCategory } from "../../../../tools/telemetry";
import { useErrorHandler } from "../../../shared/hooks/useErrorHandler";

type Props = {
  isAvatarEditMode: boolean;
  onEditSuccess(): void;
};

function AvatarEditor({
  isAvatarEditMode,
  onEditSuccess,
}: Props): ReactElement {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { logUnknownError } = useErrorHandler();
  const userProfile = useSelector(selectAssertedUserProfileInfo);

  const { AvatarUrl, FirstName } = userProfile ?? {};
  const avatar = AvatarUrl && AvatarUrl[colorThemes.light];

  const onDropAccepted = useCallback(
    async (files: File[]) => {
      try {
        const photo = await readAndCompressImage(files[0], maxAvatarSizeKB);

        const payload = { Photo: photo };

        const user = await updateUserAndProfile(payload);
        dispatch(updateUserProfileAction(user));

        onEditSuccess();
      } catch (error) {
        errorToast(t("clientError.cannotLoadImage"));
        logUnknownError(error, "Failed to drop avatar", LogCategory.api);
      }
    },
    [dispatch, logUnknownError, onEditSuccess, t]
  );

  const onDropRejected = useCallback(() => {
    errorToast(t("clientError.cannotLoadImage"));
  }, [t]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedImageTypes,
    multiple: false,
    onDropAccepted,
    onDropRejected,
  });

  return isAvatarEditMode ? (
    <Flex
      variant="layout.dropzone"
      {...getRootProps()}
      sx={{
        ...editDropzoneStyle,
        background: `url(${avatar})`,
      }}
    >
      <input {...getInputProps()} id="tbx-edit-avatar" />
      <Image alt={FirstName} src={avatar} variant="profile" sx={avatarStyle} />
      <ThinPlus />
    </Flex>
  ) : (
    <Flex variant="layout.dropzone" sx={nonEditDropzoneStyle}>
      <Image alt={FirstName} src={avatar} variant="profile" />
    </Flex>
  );
}

const editDropzoneStyle: ThemeUIStyleObject = {
  backgroundSize: "cover",
  "::before": {
    content: "''",
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 1,
    background: "rgba(255, 255, 255, 0.7)",
  },
};

const nonEditDropzoneStyle: ThemeUIStyleObject = {
  cursor: "default",
};

const avatarStyle: ThemeUIStyleObject = {
  clipPath: "circle(50% at center)",
  position: "relative",
  zIndex: 2,
};

export default AvatarEditor;
