import { difference } from "ramda";
import type {
  TileRelation,
  TileStatus,
} from "../../../api/signalR/message.types";
import { canvasObjectIds } from "../../../const";
import { flattenObjectTree } from "../../../studio/utils/fabricObjects";
import { splitBy } from "../../../tools/utils";
import { addConnections, addObjects, updateObject } from "../helpers/objects";

const incrementalUpdateObjects = (
  existingTiles: TileStatus[],
  canvas: fabric.CollaboardCanvas
) => {
  // Overwrite properties received from server in case they have changed during a re-sync
  existingTiles.forEach((tile) => {
    const object = canvas.getObjectByUUID(tile.TileId);
    if (object) {
      updateObject(object, tile, "serverUpdate");
    }
  });
};

export const addTiles = (
  canvas: fabric.CollaboardCanvas,
  tiles: TileStatus[]
): Promise<void> => {
  const tileCount = tiles.length;

  if (!tileCount) {
    // this is case when there is more pages with relations than with tiles
    return Promise.resolve(undefined);
  }

  const [existingTiles, newTiles] = splitBy(tiles, (tile) =>
    canvas.hasObjectWithUUID(tile.TileId)
  );

  /**
   * @NOTE - `noAnimation: true`
   * Don't use animation when loading a project because it does a lot of
   * unnecessary work and triggers multiple renders during the critical loading
   * phase.
   *
   * @NOTE - `nestInGroups: false`
   * Nest in groups is done once all objects have been loaded, otherwise
   * children don't have the correct positions because their coordinates are
   * relative to their parent's center. The API doesn't guarantee the order
   * that objects are loaded.
   */
  return addObjects(canvas, newTiles, {
    noAnimation: true,
    nestInGroups: false,
  })
    .then((objects) => {
      // The canvas.getContext() returns null if a user left the project
      // before it was fully initialized. This happens because we call
      // canvas.dispose() method when leaving the project to clear canvas
      // and remove listeners. Therefore we need to cancel further operations
      // on the canvas in this point to avoid fabric errors related to the
      // clearRect method. (#2757)
      if (!canvas || !canvas.getContext || !canvas.getContext()) {
        return Promise.reject({ canvasDestroyed: true });
      }

      // hide objects which are children
      // because before grouping they are rendered using they local coords and then
      // on after grouping they jump to final position.
      objects.forEach((object) => {
        if (object.parentId) {
          object.visible = false;
        }
      });

      return Promise.resolve(undefined);
    })
    .then(() => incrementalUpdateObjects(existingTiles, canvas))
    .then(() =>
      canvas.forEachObject(
        (object) => object.isPinned && object.setPinned(true)
      )
    )
    .then(() => Promise.resolve(undefined))
    .catch((e) => {
      if (e?.canvasDestroyed) {
        return undefined;
      }
      return Promise.reject(e);
    });
};

/**
 * this method finds difference set of objects between what comes from server and what is on canvas
 * that difference means that something was removed during disconnection
 * those objects musst be deleted from canvas.
 */
const incrementalRemoveObjects = (
  allTilesUuids: string[],
  canvas: fabric.CollaboardCanvas
) => {
  const uuidsTarget = canvas
    .getObjects()
    // AllTiles does not contain connectors, so we need to exclude them
    .filter((o) => o.type !== canvasObjectIds.connector)
    .map((o) => o.uuid);

  const uuids2Remove = difference(uuidsTarget, allTilesUuids);

  if (uuids2Remove.length) {
    const objs2Remove = canvas.getObjectsByUUIDs(uuids2Remove);
    canvas.remove(...objs2Remove);
  }
};

export const completeProject = (
  canvas: fabric.CollaboardCanvas,
  tiles: TileStatus[],
  relations: TileRelation[]
): Promise<void> => {
  // Remove any objects which were deleted while we were disconnected
  incrementalRemoveObjects(
    tiles.map((t) => t.TileId),
    canvas
  );

  canvas.nestInGroups(canvas.getObjects());
  canvas.sortAndReassignRootZIndexes();

  // Ensure all objects are visible
  const allObjects = flattenObjectTree(canvas.getObjects());
  allObjects.forEach((o) => (o.visible = true));

  // add connections
  return (
    addConnections(canvas, relations)
      .then(() => {
        canvas.requestRenderAll();
      })
      // eslint-disable-next-line no-console
      .catch((e) => console.warn(e))
  );
};
