import React from "react";
import { toast, ToastId, ToastOptions } from "react-toastify";
import i18n from "i18next";

import { isStaticFeatureActive, staticFeatureFlags } from "./flags";
import { isRunningInMSTeams } from "./msTeams";
import { isError } from "./errors";

const allToasts: { [key: string]: ToastId } = {};

export const getTextFromError = (error: string | Error): string => {
  const key = error instanceof Error ? error.message : error;

  if (key && i18n.exists(key)) {
    return i18n.t(key);
  }

  return key || i18n.t("clientError.uncaught");
};

export const errorToast = (
  error: string | Error, // TODO: Only accept Errors so we get the stack trace
  options: ToastOptions = {},
  header = i18n.t("error")
): ToastId | null => {
  /**
   * @NOTE Use this flag if you need to suppress error toast messages in
   * MS Teams during the certification process.
   *
   * This should only be necessary if we have known bugs (JS exceptions),
   * otherwise this flag should always be disabled otherwise the user won't get
   * helpful toast messages like "Username / password is incorrect".
   */
  if (
    isError(error) &&
    isStaticFeatureActive(staticFeatureFlags.SUPPRESS_ERRORS_IN_MS_TEAMS) &&
    isRunningInMSTeams()
  ) {
    return null;
  }

  const text = getTextFromError(error);

  const currentToast = allToasts[text];

  if (currentToast) {
    return currentToast;
  }

  const { onClose, ...toastConfig } = options;

  const toastId = toast.error(
    <>
      {header && <header data-testid="toast-header">{header}</header>}
      <div className="message" data-testid="toast-message">
        {text}
      </div>
    </>,
    {
      className: "error-toast",
      closeButton: !toastConfig.type || toastConfig.type === "error",
      onClose: () => {
        delete allToasts[text];
        onClose?.();
      },
      ...toastConfig,
    }
  );

  allToasts[text] = toastId;

  return toastId;
};
