import i18next from "i18next";
import React, { ReactElement, useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { acceptSubscriptionUserInvitation } from "../../../api";
import { routes } from "../../../const";
import {
  isStaticFeatureActive,
  staticFeatureFlags,
} from "../../../tools/flags";
import { LogCategory } from "../../../tools/telemetry";
import { ApiLanguage } from "../../../types/enum";
import { useErrorHandler } from "../../shared/hooks/useErrorHandler";
import EmailVerificationModalTemplate from "../login/EmailVerificationModalTemplate";
import {
  RegistrationFormValues,
  useAuthentication,
} from "../useAuthentication";
import RegistrationForm from "./RegistrationForm";

type Props = {
  tenantFromConfig?: string | undefined;
};

const isToSDisabled = isStaticFeatureActive(
  staticFeatureFlags.DISABLE_REGISTRATION_TOS
);

function EmailRegistration({ tenantFromConfig }: Props): ReactElement {
  const history = useHistory();
  const { pathname } = useLocation();
  const { state } = useLocation<RegisterLocationState | undefined>();
  const { fallbackErrorHandler } = useErrorHandler();

  const [UserName, setUserName] = useState<string | undefined>(undefined);
  const [Password, setPassword] = useState<string | undefined>(undefined);

  const { register, autoLogin, emailVerificationModal } = useAuthentication();

  const hasInvitationData = !!state?.invitationData;
  const invitationToken = state?.token;

  const initialValues: RegistrationFormValues = {
    UserName: state?.invitationData?.UserName ?? "",
    Password: "",
    FirstName: state?.invitationData?.FirstName ?? "",
    LastName: state?.invitationData?.LastName ?? "",
    CompanyName: state?.invitationData?.CompanyName ?? "",
    Country: "",
    Language:
      state?.invitationData?.Language ??
      (i18next.resolvedLanguage as ApiLanguage),
    PhoneNumber: state?.invitationData?.PhoneNumber ?? "",
    ReceiveNews: false,
    /**
     * The server runs validation on the payload so we need to set this to true
     * even when the server is configured to auto-accept ToS.
     */
    TermsOfServiceAccepted: isToSDisabled || false,
  };

  const onSubmit = useCallback(
    async (values: RegistrationFormValues): Promise<void> => {
      try {
        setUserName(values.UserName);
        setPassword(values.Password);
        const { AuthorizationToken } = await register({
          values,
          bypassEmailVerification: hasInvitationData,
        });

        if (hasInvitationData && invitationToken) {
          await acceptSubscriptionUserInvitation(
            invitationToken,
            AuthorizationToken
          );
          await autoLogin({
            UserName: values.UserName,
            Password: values.Password,
            Tenant: tenantFromConfig,
          });
        }
      } catch (error) {
        fallbackErrorHandler(
          error,
          "Failed to register via email",
          LogCategory.auth
        );
        return;
      }
    },
    [
      register,
      hasInvitationData,
      invitationToken,
      autoLogin,
      tenantFromConfig,
      fallbackErrorHandler,
    ]
  );

  const onEmailVerifySuccess = useCallback(async () => {
    try {
      if (UserName && Password) {
        await autoLogin({ UserName, Password });
      } else {
        history.replace(routes.authenticate);
      }
    } catch (error) {
      fallbackErrorHandler(
        error,
        "Failed to auto-login via email",
        LogCategory.auth
      );
      history.replace(routes.authenticate);
    }

    emailVerificationModal.closeOnNextTick();
  }, [
    UserName,
    Password,
    emailVerificationModal,
    autoLogin,
    fallbackErrorHandler,
    history,
  ]);

  const onEmailVerifyError = useCallback(
    (error: unknown) =>
      fallbackErrorHandler(error, "Failed to verify email", LogCategory.auth),
    [fallbackErrorHandler]
  );

  const onClearInviteData = useCallback(() => {
    // Also clears the ?redirect from the querystring so it won't try to accept
    // the invite once this has been clicked
    history.replace(pathname);
  }, [history, pathname]);

  return (
    <>
      <RegistrationForm<RegistrationFormValues>
        initialValues={initialValues}
        onSubmit={onSubmit}
        hasInvitationData={hasInvitationData}
        onClearInviteData={onClearInviteData}
        isCompleteRegistration={false}
      />
      {UserName && (
        <EmailVerificationModalTemplate
          UserName={UserName}
          modalProps={emailVerificationModal}
          onSuccess={onEmailVerifySuccess}
          onError={onEmailVerifyError}
        />
      )}
    </>
  );
}

export default EmailRegistration;
