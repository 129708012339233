import React, { PropsWithChildren, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Flex, ThemeUIStyleObject } from "theme-ui";
import Button from "../../canvas/Button";

type Props = {
  i18nKey: string;
  i18nValues?: Record<string, string>;
  hideOnClick?: boolean;
  onDismiss?: () => void;
};

function HelpNotice({
  i18nKey,
  i18nValues,
  hideOnClick,
  onDismiss,
}: PropsWithChildren<Props>): ReactElement | null {
  const { t } = useTranslation();

  return (
    <Flex sx={containerStyle}>
      {t(i18nKey, i18nValues)}
      {hideOnClick && (
        <Button variant="optionButton" sx={buttonStyle} onClick={onDismiss}>
          {t("dialog.dismiss")}
        </Button>
      )}
    </Flex>
  );
}

const containerStyle: ThemeUIStyleObject = {
  alignItems: "center",
  fontSize: "var(--dynamicFontSize)",
  justifyContent: "space-between",
  px: [5],
  py: [3],
};

const buttonStyle: ThemeUIStyleObject = {
  border: "1px solid var(--invertedTextColor)",
  color: "var(--invertedTextColor)",
  cursor: "pointer",
  ml: [4],
  p: [3],

  ":hover": {
    background: "var(--invertedTextColor)",
    color: "var(--textColor)",
  },
};

export default HelpNotice;
