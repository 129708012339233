import i18n from "i18next";
import { ApiLanguage } from "../../types/enum";
import { isString } from "../../tools/text";

export const getTranslationValue = (
  key: string,
  dictionary: TranslationsByLang,
  language: ApiLanguage = i18n.resolvedLanguage as ApiLanguage
): string => {
  const keys = key.split(".");
  const label = keys.reduce<TranslationValue>((acc, k) => {
    return !acc || isString(acc) ? acc : acc[k];
  }, dictionary[language] ?? {});

  return isString(label) ? label : key;
};
