import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Admin(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M29.18,26.56c-1.55-1.1-3.93-.51-5.42-2.48,0,0-.55-.62.69-2.14a6.41,6.41,0,0,0,1.18-4.16H17.55a6.47,6.47,0,0,0,1.18,4.16c1.24,1.52.69,2.14.69,2.14-1.48,2-3.86,1.38-5.41,2.48s-1.18,3.62-1.18,3.62H30.35S30.73,27.67,29.18,26.56Z" />
      <path d="M16.41,14.61A1.6,1.6,0,1,1,18,13a1.58,1.58,0,0,1-1.55,1.6Zm2.9-2.69a3.1,3.1,0,0,0-2.9-2.1,3.2,3.2,0,0,0,0,6.39,3.11,3.11,0,0,0,3-2.7h4.86v1.42h1.54v-3Z" />
      <rect x="21.44" y="13.35" width="1.55" height="1.08" />
    </Icon>
  );
}
export default Admin;
