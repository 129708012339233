import { useRef } from "react";
import { InternalError } from "../../../errors/InternalError";

type DeferredPromise = {
  resolver: (value?: unknown) => void;
  promise: Promise<unknown>;
};

export function useDeferredPromise(): DeferredPromise {
  const deferredPromise = useRef<DeferredPromise>(createDeferredPromise());

  return deferredPromise.current;
}

const createDeferredPromise = (): DeferredPromise => {
  let resolver: ((value?: unknown) => void) | undefined = undefined;
  const promise = new Promise((resolve) => {
    resolver = resolve;
  });

  if (!resolver) {
    throw new InternalError("Unable to create resolver");
  }

  return {
    promise,
    resolver,
  };
};
