import { Store } from "redux";

class ReduxSingleton {
  store: Store<ApplicationGlobalState> | null = null;

  /**
   * Return a reference of the global ReduxStore
   * @NOTE Be sure to call this method only after the reduxStore is initialized, i.e. not in statically
   * initialized variables.
   */
  getStore(): Store<ApplicationGlobalState> {
    if (!this.store) {
      // We can't use any import of telemetry in this module to avoid circular dependencies so we
      // just use console.error, which logs to AI in PROD.
      // eslint-disable-next-line no-console
      console.error("Retrieving uninitialized Redux Store");
    }

    return this.store as Store<ApplicationGlobalState>;
  }

  setStore(store: Store<ApplicationGlobalState>) {
    this.store = store;
  }
}

export const reduxSingleton = new ReduxSingleton();
