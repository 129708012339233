import React, { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Text, ThemeUIStyleObject } from "theme-ui";
import { Currency, useCurrencies } from "../useCurrencies";
import CreditCard from "../../../../icons/CreditCard";
import { getAppName } from "../../../../tools/customer";
import { formatNumber } from "../../../../tools/numbers";
import { noop } from "../../../../tools/utils";
import { getPlanInfoKeyById } from "../../../licensing/payment/helpers";
import ConfirmationButtons from "../../../shared/modals/ConfirmationButtons";
import Modal, { TemplateProps } from "../../../shared/modals/Modal";
import ModalHeader from "../../../shared/modals/ModalHeader";
import { withModalTemplate } from "../../../shared/modals/withModalTemplate";

const getCurrencySymbolByCode = (
  currencyCode: string,
  currencies: Currency[]
): string => currencies.find(({ code }) => code === currencyCode)?.symbol || "";

export type PaymentSuccessModalProps = {
  amount: number;
  currency: string;
  billingTypeId: number;
  productId: number;
  nextBillingDate: string;
};

function PaymentSuccessModalTemplate({
  amount,
  currency,
  billingTypeId,
  productId,
  nextBillingDate,
  modalProps,
}: TemplateProps<PaymentSuccessModalProps>): ReactElement {
  const { t } = useTranslation();
  const { currencies, areCurrenciesLoading } = useCurrencies();
  const currencySymbol = useMemo(
    () =>
      areCurrenciesLoading ? "" : getCurrencySymbolByCode(currency, currencies),
    [currencies, areCurrenciesLoading, currency]
  );

  const licenseTranslationKey = getPlanInfoKeyById(productId);

  return (
    <Modal
      header={
        <ModalHeader
          title={t("dialog.payment.paymentSuccess.header")}
          Icon={CreditCard}
        />
      }
      actionButtons={
        <ConfirmationButtons
          onClose={modalProps.onClose}
          confirmationLabel={t("dialog.payment.paymentSuccess.confirm")}
          onConfirm={noop}
          hideCancelButton
        />
      }
      {...modalProps}
    >
      <Text variant="default" sx={{ mb: [5] }}>
        {t("dialog.payment.paymentSuccess.text", { appName: getAppName() })}
      </Text>

      <Flex sx={containerStyle}>
        <Flex sx={colStyle}>
          {licenseTranslationKey && (
            <Text sx={{ mb: [1], fontSize: "24px" }} aria-label="plan-name">
              {t(licenseTranslationKey)}
            </Text>
          )}

          <Text aria-label="billing-type">
            {t(`dialog.payment.paymentSuccess.billed${billingTypeId}`)}
          </Text>
        </Flex>

        <Flex sx={colStyle}>
          <Text sx={{ mb: [1], fontSize: "24px" }}>
            {t("dialog.payment.total")}
          </Text>
          <Box>
            <Text as="span" sx={{ fontSize: "20px" }} aria-label="currency">
              {currencySymbol}
            </Text>

            <Text
              as="span"
              sx={{ fontWeight: "bold", fontSize: "30px" }}
              aria-label="amount"
            >
              {formatNumber(amount.toFixed(2))}
            </Text>
          </Box>
        </Flex>

        <Flex sx={colStyle}>
          <Text sx={{ mb: [1], fontSize: "24px" }}>
            {t("dialog.payment.paymentSuccess.nextBillingDate")}
          </Text>

          <Text aria-label="next-billing-date">
            {t("date", { date: new Date(nextBillingDate) })}
          </Text>
        </Flex>
      </Flex>
    </Modal>
  );
}

const containerStyle: ThemeUIStyleObject = {
  flexDirection: "row",
  justifyContent: "space-between",
  bg: "background",
  px: [4],
  py: [5],
  border: "1px solid",
  borderColor: "muted",
};

const colStyle: ThemeUIStyleObject = {
  flexDirection: "column",
  justifyContent: "space-between",
  flex: 1,
  alignSelf: "stretch",
  borderRight: "1px solid",
  borderColor: "muted",
  marginRight: "10px",
  "&:last-child": { borderRight: 0, marginRight: 0 },
};

export default withModalTemplate(PaymentSuccessModalTemplate);
