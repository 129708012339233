import React, { ReactElement, useEffect } from "react";
import { Box, Flex, Grid, Text } from "theme-ui";
import { Formik, FormikConfig } from "formik";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { object, string } from "yup";
import Spaces from "../../icons/Spaces";
import Modal from "../shared/modals";
import ConfirmationButtons from "../shared/modals/ConfirmationButtons";
import { TemplateProps } from "../shared/modals/Modal";
import InputField from "../shared/forms/fields/InputField";
import { createSpace } from "../../api";
import { useTrackEventToLog } from "../shared/tracking/useTrackEventToLog";
import { useSpaces } from "./useSpaces";
import { maxSpaceDescriptionLength } from "../../validationSchemas/fieldRestrictions";
import ColorHex from "../shared/color/ColorHex";
import ColorPicker from "../shared/color/ColorPicker";
import { colorThemes } from "../../const";
import SelectColor from "../../icons/SelectColor";
import { LogCategory } from "../../tools/telemetry";
import { withModalTemplate } from "../shared/modals/withModalTemplate";

type CreateSpaceValues = {
  Name: string;
  Color: string;
};

type Props = {
  onCreated: (spaceId: number) => void;
};

function CreateSpaceModalTemplate({
  modalProps,
  onCreated,
}: TemplateProps<Props>): ReactElement {
  const { t } = useTranslation();
  const { trackEvent } = useTrackEventToLog();
  const { refetch } = useSpaces();

  const formikProps: FormikConfig<CreateSpaceValues> = {
    initialValues: {
      Name: "",
      Color: "#000000",
    },
    onSubmit: async ({ Name, Color }) => {
      const { SpaceId } = await createSpace(Name, Color);
      trackEvent(LogCategory.space, {
        subcategory: "space-create",
        spaceId: SpaceId,
        name: Name,
        color: Color,
      });

      await refetch();
      onCreated(SpaceId);
      toast(t("spaces.createSuccessful"));
    },
    validationSchema: object().shape({
      Name: string()
        .trim()
        .max(
          maxSpaceDescriptionLength,
          t("clientError.tooLongMax", { max: maxSpaceDescriptionLength })
        )
        .required(t("clientError.required")),
      Color: string(), // Cannot have invalid hex values
    }),
  };

  useEffect(() => {
    toast.dismiss();
  }, []);

  return (
    <Formik {...formikProps}>
      {({ values, submitForm, isValid, setFieldValue }) => {
        const onColorChange = (color: string) => setFieldValue("Color", color);

        return (
          <Modal
            header={
              <>
                <Spaces /> <span>{t("dialog.createNewSpace")}</span>
              </>
            }
            actionButtons={
              <ConfirmationButtons
                onConfirm={submitForm}
                onClose={modalProps.onClose}
                confirmationLabel={t("dialog.createSpace")}
                confirmationDisabled={!isValid}
              />
            }
            {...modalProps}
          >
            <InputField
              name="Name"
              testId="space-name"
              required
              maxLength={maxSpaceDescriptionLength}
              placeholder={t("dialog.spaceName")}
              processOnEnterPressed
            />

            <Flex
              sx={{
                alignItems: "center",
              }}
            >
              <SelectColor noShadow />
              <Text>{t("sidebars.shape.selectColor")}</Text>
            </Flex>

            <Grid
              columns={[
                "repeat(auto-fit, min-max(24rem, 1fr))",
                null,
                "minmax(24rem, 1fr) 16rem",
              ]}
              gap={[4]}
            >
              <Box>
                <ColorPicker
                  color={values.Color}
                  theme={colorThemes.light}
                  isStaticPalette
                  onColorChange={onColorChange}
                />
              </Box>
              <Box>
                <ColorHex
                  color={values.Color}
                  hideEyeDropper
                  vertical
                  testId="space-color-hex"
                  onColorChange={onColorChange}
                />
                <Flex sx={{ justifyContent: "center", mt: [3] }}>
                  <Text sx={{ color: "muted" }}>
                    {t("sidebars.shape.insertHex")}
                  </Text>
                </Flex>
              </Box>
            </Grid>
          </Modal>
        );
      }}
    </Formik>
  );
}

export default withModalTemplate(CreateSpaceModalTemplate);
