import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Eye(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M19.7,14A12.48,12.48,0,0,0,9.07,20.18,12.47,12.47,0,0,0,19.7,26.35a12.48,12.48,0,0,0,10.64-6.17A12.47,12.47,0,0,0,19.7,14Zm4.72,6.17a4.72,4.72,0,1,1-4.72-4.72A4.71,4.71,0,0,1,24.42,20.18Zm-13.64,0a12.2,12.2,0,0,1,3.74-3.34,6.18,6.18,0,0,0,0,6.68A12.72,12.72,0,0,1,10.78,20.18Zm14.11,3.34a6.18,6.18,0,0,0,0-6.68,12.72,12.72,0,0,1,3.74,3.34A12.72,12.72,0,0,1,24.89,23.52Zm-7.36-3.34a2.18,2.18,0,1,1,2.17,2.18A2.18,2.18,0,0,1,17.53,20.18Z" />
    </Icon>
  );
}
export default Eye;
