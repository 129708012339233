import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Confirmation } from "../../shared/modals";
import ModalHeader from "../../shared/modals/ModalHeader";
import FullPageSpinner from "../../shared/spinners/FullPageSpinner";
import {
  RedemptionModalProps,
  useRedemptionDetailsModalTemplate,
} from "./useRedemptionDetailsModalTemplate";
import SubscriptionDetailsBox from "./SubscriptionDetailsBox";
import Redeem from "../../../icons/Redeem";
import { TemplateProps } from "../../shared/modals/Modal";

function RedemptionDetailsModalTemplate(
  props: TemplateProps<RedemptionModalProps>
): ReactElement {
  const { onClose, modalProps } = props;
  const { t } = useTranslation();
  const {
    handleConfirm,
    shouldUpgrade,
    current,
    newSubscriptionDetails,
    isLoading,
    isError,
  } = useRedemptionDetailsModalTemplate(props);

  useEffect(() => {
    if (isError) {
      onClose();
    }
  }, [isError, onClose]);

  if (isLoading || isError || !newSubscriptionDetails) {
    return <FullPageSpinner />;
  }

  return (
    // todo: provide types for modal-related stuff
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Confirmation
      header={
        <ModalHeader
          title={t("dialog.codeRedemptionDetails.title")}
          Icon={Redeem}
        />
      }
      confirmationLabel={t("form.button.redeem")}
      onConfirm={handleConfirm}
      closeOnConfirm={false}
      closeOnDimmerClick={false}
      autoFocusConfirmButton
      {...modalProps}
    >
      {current && shouldUpgrade && (
        <SubscriptionDetailsBox
          data={current.Subscription}
          header={t("dialog.codeRedemptionDetails.currentSubscription")}
          sx={{ mb: [5] }}
        />
      )}

      <SubscriptionDetailsBox
        data={newSubscriptionDetails}
        header={t("dialog.codeRedemptionDetails.newSubscription")}
      />
    </Confirmation>
  );
}

export default RedemptionDetailsModalTemplate;
