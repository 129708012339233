import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { BoxProps, Flex } from "theme-ui";

type Props = BoxProps;

function EmptyListPlaceholder({ sx, ...otherProps }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <Flex
      sx={{
        justifyContent: "center",
        alignItems: "center",
        ...sx,
      }}
      {...otherProps}
    >
      {t("team.emptyList")}
    </Flex>
  );
}

export default EmptyListPlaceholder;
