import { AnyAction } from "redux";
import { eventChannel, EventChannel, Task } from "redux-saga";
import {
  all,
  call,
  cancel,
  cancelled,
  Effect,
  fork,
  put,
  take,
} from "redux-saga/effects";
import { SignalRClient } from "../../api/signalR";
import { SyncStatus } from "../../api/SignalRQueue";
import { SignalRError } from "../../errors/signalRError";
import { CollaboardToast } from "../../features/shared/toasts/useToast";
import collaboard from "../../tools/collaboard";
import { isError, stringifyError } from "../../tools/errors";
import { LogCategory, onErrorToLog } from "../../tools/telemetry";
import { WebRTCManager } from "../../webrtc";
import { AuthActionType } from "../auth/auth.actions";
import { CanvasActionType, CanvasEnterAction } from "../canvas/canvas.actions";
import {
  resetStatusAction,
  setStatusAction,
} from "../canvas/project/project.actions";
import { ProjectStatus } from "../canvas/project/project.reducer";
import {
  setSignalRConnectedAction,
  setSignalRDisconnectedAction,
  setSignalRFailedAction,
  setSignalRStatusAction,
} from "./signalR.actions";
import { SignalRStatus } from "./signalR.reducer";

const autoSyncToast = new CollaboardToast("clientError.autoSynced");
const connectionToast = new CollaboardToast("", {
  autoClose: false,
  closeOnClick: false,
});
const errorToast = new CollaboardToast("");

function createSignalRChannel(
  signalRClient: SignalRClient,
  webRTCManager?: WebRTCManager
) {
  return eventChannel<AnyAction>((emitter) => {
    signalRClient.onClose((error) => {
      if (error) {
        onErrorToLog(error, LogCategory.signalR);
      }

      emitter(setSignalRDisconnectedAction());
    });

    signalRClient.onReconnecting(() => {
      emitter(setSignalRStatusAction({ status: SignalRStatus.Reconnecting }));
      connectionToast.update("clientError.websocketReconnecting");
    });

    signalRClient.onReconnected(async (connectionId: string) => {
      emitter(setSignalRConnectedAction({ connectionId }));
      webRTCManager?.setMyConnectionId(connectionId);

      connectionToast.dismiss();

      if (signalRClient.isConnectedToProject()) {
        signalRClient.postLoginProject();

        /**
         * @TODO #7322 - Do this properly - requires #7486 really.
         */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await (window as any).closeProject?.();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await (window as any).startProject?.();
      }
    });

    signalRClient.onReconnectionFailed(() => {
      emitter(
        setSignalRFailedAction({
          // Use the untranslated key so that the ErrorPage can detect if it is a network error
          error: new SignalRError("clientError.websocketFailed"),
        })
      );

      autoSyncToast.dismiss();
      errorToast.dismiss();
      connectionToast.dismiss();
    });

    signalRClient.onServiceError(async () => {
      if (!signalRClient.isConnectedToProject()) {
        // Ignore messages that arrive after user has left the project
        return;
      }

      errorToast.update("clientError.failedSignalRMessage");

      /**
       * @TODO #7322 - Do this properly - requires #7486 really.
       */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await (window as any).closeProject?.();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await (window as any).startProject?.();

      autoSyncToast.show();
    });

    signalRClient.onServiceErrorsLimit(async () => {
      emitter(
        setSignalRFailedAction({
          // Use the untranslated key so that the ErrorPage can detect if it is a network error
          error: new SignalRError("clientError.websocketErrorsLimitReached"),
        })
      );

      autoSyncToast.dismiss();
      errorToast.dismiss();
      connectionToast.dismiss();

      if (signalRClient.isConnectedToProject()) {
        /**
         * @TODO #7322 - Do this properly - requires #7486 really.
         */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await (window as any).closeProject?.();
      }
    });

    signalRClient.onSignalQueueActive(() => {
      emitter(setSignalRStatusAction({ isQueueBusy: true }));
    });

    signalRClient.onSignalQueueIdle(() => {
      emitter(setSignalRStatusAction({ isQueueBusy: false }));
    });

    signalRClient.onSignalQueueOverloaded(({ percentage, syncStatus }) => {
      if (syncStatus === SyncStatus.starting) {
        emitter(setStatusAction({ status: ProjectStatus.SYNCING }));
      }

      if (
        syncStatus === SyncStatus.starting ||
        syncStatus === SyncStatus.pending
      ) {
        connectionToast.update("syncing", { percentage });
      }

      if (syncStatus === SyncStatus.finished) {
        emitter(resetStatusAction());
        connectionToast.dismiss();
      }
    });

    const onOnlineHandler = async () => {
      try {
        const connectionId = await signalRClient.start();

        emitter(setSignalRConnectedAction({ connectionId }));
        webRTCManager?.setMyConnectionId(connectionId);

        /**
         * @TODO #7322 - Do this properly - requires #7486 really.
         */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await (window as any).closeProject?.();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        await (window as any).startProject?.();
      } catch (error) {
        logSignalRStartError(error);

        emitter(
          setSignalRFailedAction({
            // Use the untranslated key so that the ErrorPage can detect if it is a network error
            error: new SignalRError("clientError.websocketFailed"),
          })
        );
      } finally {
        autoSyncToast.dismiss();
        errorToast.dismiss();
        connectionToast.dismiss();

        if (signalRClient.isConnectedToProject()) {
          await signalRClient.postLoginProject();
        }
      }
    };

    const onOfflineHandler = async () => {
      await signalRClient.stop();

      emitter(setSignalRStatusAction({ status: SignalRStatus.Offline }));

      autoSyncToast.dismiss();
      errorToast.dismiss();
      connectionToast.dismiss();
    };

    window.addEventListener("online", onOnlineHandler);
    window.addEventListener("offline", onOfflineHandler);

    return () => {
      signalRClient.removeStatusCallbacks();
      window.removeEventListener("online", onOnlineHandler);
      window.removeEventListener("offline", onOfflineHandler);
    };
  });
}

function* signalRStatusListenersSaga(
  signalRClient: SignalRClient,
  webRTCManager?: WebRTCManager
) {
  const signalRChannel: EventChannel<AnyAction> = yield call(
    createSignalRChannel,
    signalRClient,
    webRTCManager
  );

  try {
    while (true) {
      const action: AnyAction = yield take(signalRChannel);
      yield put(action);
    }
  } finally {
    const isCancelled: boolean = yield cancelled(); // Cancelled when user logs out

    if (isCancelled) {
      // Close the EventChannel so that the unsubscribe function is called and listeners removed
      signalRChannel.close();
    }
  }
}

function* signalRProjectLoginSaga(signalRClient: SignalRClient) {
  while (true) {
    const action: CanvasEnterAction = yield take(
      CanvasActionType.ON_CANVAS_ENTER
    );

    signalRClient.setProjectId(action.payload.projectId);

    yield call({
      context: signalRClient,
      fn: signalRClient.postLoginProject,
    });

    yield take(CanvasActionType.ON_CANVAS_EXIT);

    yield call({
      context: signalRClient,
      fn: signalRClient.postLogoutProject,
    });

    signalRClient.clearProjectId();
  }
}

function* spawnSagasOnProjectOpen(
  signalRClient: SignalRClient,
  webRTCManager?: WebRTCManager
): Generator<Effect> {
  yield all([
    signalRStatusListenersSaga(signalRClient, webRTCManager),
    signalRProjectLoginSaga(signalRClient),
  ]);
}

function logSignalRStartError(error: unknown) {
  onErrorToLog(
    isError(error)
      ? error
      : new SignalRError(
          `Failed to start SignalR connection: ${stringifyError(error)}`
        ),
    LogCategory.signalR
  );
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* signalRSaga() {
  /** @NOTE - Optional operator after `collaboard` is required for tests. */
  if (!collaboard?.signalRClient) {
    return;
  }

  while (true) {
    yield take(AuthActionType.LOGGED_IN);
    const signalRProjectSagaTask = (yield fork(
      spawnSagasOnProjectOpen,
      collaboard.signalRClient,
      collaboard.webRTCManager
    )) as Task;

    try {
      const connectionId: string = yield call({
        context: collaboard.signalRClient,
        fn: collaboard.signalRClient.start,
      });

      yield put(setSignalRConnectedAction({ connectionId }));

      /**
       * Change the connection ID in the WebRTCManager before sending PostLogin
       * to ensure that the new ID is in place before the subsequent
       * NotifyLoginProject message arrives. Otherwise the WebRTCManager will
       * think the message refers to a different user, not _this_ user, and
       * will attempt to establish an RTC connection. See #7128.
       */
      collaboard.webRTCManager?.setMyConnectionId(connectionId);

      yield take(AuthActionType.LOGGED_OUT);

      // Stop SignalR connection
      yield cancel(signalRProjectSagaTask);
      yield call({
        context: collaboard.signalRClient,
        fn: collaboard.signalRClient.stop,
      });

      yield put(setSignalRDisconnectedAction());
    } catch (error) {
      yield cancel(signalRProjectSagaTask);

      logSignalRStartError(error);

      // This action will trigger <SignalRStatus /> to redirect to the network error page
      yield put(
        setSignalRFailedAction({
          // Use the untranslated key so that the ErrorPage can detect if it is a network error
          error: new SignalRError("clientError.websocketFailed"),
        })
      );
    }
  }
}
