import { ThemeUIStyleObject } from "theme-ui";

const contextToolsHeader: ThemeUIStyleObject = {
  color: "inherit",
  fontSize: "10px",
  fontWeight: "700",
  lineHeight: "var(--gridTile)",
  minHeight: "var(--gridTile)",
  minWidth: "calc(var(--gridTile) * 2)",
  overflow: "hidden",
  paddingX: "0.4rem",
  position: "relative",
  textAlign: "left",
  textOverflow: "ellipsis",
  userSelect: "none",
  whiteSpace: "nowrap",
} as const;

const forms: Record<string, ThemeUIStyleObject> = {
  formGroup: {
    flex: "1 0 100%",
    position: "relative",
  },
  input: {
    border: "1px solid",
    borderColor: "var(--inputBorder)",
    outline: "1px solid",
    outlineColor: "transparent",
    outlineOffset: "-2px",
    borderRadius: 0,
    flex: "1 0 100%",
    fontSize: [4, 5],
    padding: "12px 8px",
    lineHeight: "initial",
    color: "var(--textColor)",
    "&[disabled]": { bg: "secondary" },
    "&::placeholder": {
      fontWeight: "100",
      color: "var(--inputPlaceholder)",
      opacity: 1,
    },
    "&:focus": {
      borderColor: "primary",
      outlineColor: "primary",
    },
    "+ select": {
      appearance: "none",
      opacity: 0,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      option: {
        backgroundColor: "background",
      },
    },
  },
  textarea: {
    borderRadius: 0,
    border: "1px solid",
    borderColor: "var(--inputBorder)",
    transition: "all 0.15s ease-in-out",
    outline: "1px solid",
    outlineColor: "transparent",
    outlineOffset: "-2px",
    p: [4],
    color: "var(--textColor)",
    "&:focus": {
      borderColor: "primary",
      outlineColor: "primary",
    },
    "&::placeholder": {
      fontWeight: "100",
      color: "var(--inputPlaceholder)",
      opacity: 1,
    },
  },
  inputPrepend: {
    alignItems: "center",
    borderColor: "var(--inputBorder)",
    borderRadius: 0,
    borderStyle: "solid",
    borderWidth: "1px",
    borderRightWidth: 0,
    cursor: "pointer",
    paddingX: [1],
    "> img": {
      boxShadow: "var(--controlShadow)",
      backgroundPosition: "center center",
      position: "relative",
      width: "1em !important",
      height: "0.75em !important",
      fontSize: ["var(--gridTile)", "40px"],
    },
    "> svg": {
      position: "relative",
    },
    "+ input": {
      // borderLeftWidth: 0,
      flex: "1 1 0%",
    },
  },
  inputMulti: {
    variant: "forms.textarea",
    border: "none",
    maxWidth: "100%",
    height: "38px",
    transition: "all 0.15s linear",
    outline: "none",
    color: "var(--textColor)",
  },
  profile3: {
    alignItems: "center",
    flex: ["1 0 100%", "1 0 33%"],
    mb: [3],
    mt: [2],
  },
  profile: {
    flex: ["1 0 100%", "0 50%"],
    alignItems: "flex-start",
    flexWrap: "wrap",
    mb: [2],
    position: "relative",
    "> div": {
      flex: "1 0 100%",
    },
    "&:nth-of-type(even)": {
      pl: [0, 1],
    },
    "&:nth-of-type(odd)": {
      pr: [0, 1],
    },
    required: {
      flex: ["1 0 100%", "0 50%"],
      alignItems: "flex-start",
      flexWrap: "wrap",
      mb: [2],
      position: "relative",
      "&:nth-of-type(even)": {
        pl: [0, 1],
      },
      "&:nth-of-type(odd)": {
        pr: [0, 1],
      },
    },
  },
  modalDropdown: {
    variant: "forms.profile",
    minHeight: "60px",
    input: {
      borderColor: "var(--textColor)",
    },
  },
  label: {
    alignItems: "center",
    flexWrap: "wrap",
    mb: [4],
    position: "relative",
    "> div > svg": {
      borderRadius: 0,
      color: "primary",
    },
    required: {
      alignItems: "center",
      flexWrap: "wrap",
      mb: [4],
      position: "relative",
      "::after": {
        alignItems: "center",
        color: "var(--textColor)",
        content: '"*"',
        display: "flex",
        height: "2em",
        justifyContent: "center",
        position: "absolute",
        right: 0,
        textAlign: "center",
        top: 0,
        width: "2em",
        zIndex: -1,
      },
    },
    canvasTool: {
      color: "inherit",
      cursor: "inherit",
      fontSize: "var(--dynamicFontSize)",
      fontWeight: "300",
      left: "100%",
      lineHeight: "var(--gridTile)",
      minHeight: "var(--gridTile)",
      overflow: "hidden",
      paddingX: "0.4rem",
      textAlign: "left",
      textOverflow: "ellipsis",
      userSelect: "none",
      whiteSpace: "nowrap",
    },
    canvasContextTool: {
      alignItems: "center",
      color: "inherit",
      fontSize: "var(--dynamicFontSize)",
      fontWeight: "300",
      height: "var(--gridTile)",
      overflow: "hidden",
      paddingX: "0.4rem",
      textAlign: "left",
      textOverflow: "ellipsis",
      userSelect: "none",
    },
    toolsHeader: {
      color: "inherit",
      fontSize: "10px",
      fontWeight: "300",
      lineHeight: "var(--gridTile)",
      minHeight: "var(--gridTile)",
      minWidth: "calc(var(--gridTile) * 2)",
      overflow: "hidden",
      paddingX: "0.4rem",
      position: "relative",
      textAlign: "left",
      textOverflow: "ellipsis",
      userSelect: "none",
      whiteSpace: "nowrap",
    },
    contextToolsHeader: {
      ...contextToolsHeader,
      "&:after": {
        content: '""',
        position: "absolute",
        bottom: 0,
        height: "1px",
        left: "0.4rem",
        right: "0.4rem",
        bg: "var(--separator)",
      },
    },
    contextToolsHeaderInverted: {
      ...contextToolsHeader,
      "&:after": {
        content: '""',
        position: "absolute",
        bottom: 0,
        height: "1px",
        left: "0.4rem",
        right: "0.4rem",
        bg: "var(--separator__inverted)",
      },
    },
  },
  checkbox: {
    fill: "var(--accentAlternate) !important", // `!important` is to override modal style
  },
  checkboxLabel: {
    alignItems: "flex-start",
    flexWrap: "nowrap",
    mb: [4],
    position: "relative",
    required: {
      alignItems: "flex-start",
      flexWrap: "nowrap",
      mb: [4],
      position: "relative",
      "::after": {
        alignItems: "center",
        color: "var(--textColor)",
        content: '"*"',
        display: "flex",
        height: "2em",
        justifyContent: "center",
        position: "absolute",
        right: 0,
        textAlign: "center",
        top: 0,
        width: "2em",
      },
    },
  },
  small: {
    variant: "forms.label",
    input: {
      height: "2.2rem",
      fontSize: [4],
    },
    svg: { height: "24px", width: "24px" },
    "& > div": {
      height: "2.2rem",
      mr: [2],
    },
  },
  toggle: {
    cursor: "pointer",
    WebkitTapHighlightColor: "transparent",
    WebkitAppearance: "none",
    MozAppearance: "none",
    appearance: "none",
    height: "18px",
    width: "38px",
    borderRadius: "9px",
    display: "inline-block",
    position: "relative",
    margin: "0 !important",
    border: "none",
    background: "var(--toggleBackground)",
    transition: "all .2s ease",
    boxShadow: "inset 1px 1px 2px 0px rgba(0, 0, 0, 0.5)",
    "&[disabled]": {
      cursor: "default",
    },
    "&:focus": {
      outline: 0,
    },

    "&:after": {
      content: '""',
      position: "absolute",
      top: "2px",
      left: "2px",
      width: "14px",
      height: "14px",
      background: "white",
      transition: "all .2s cubic-bezier(0.7, 0.12, 0.65, 1.36)",
      borderRadius: "50%",
      boxShadow:
        "0 1px 2px rgba(44,44,44,.2), 0px 1px 1px 0px rgb(100, 100, 100)",
    },
    "&:checked": {
      backgroundColor: "primary",
      "&:after": {
        transform: "translateX(20px)",
      },
    },
  },
  select: {
    borderColor: "primary",
    borderRadius: 0,
    fontSize: [4],
    padding: "14px 8px",
    "&:focus": {
      borderColor: "accent",
      outline: "none",
    },
    "> option:checked": {
      bg: "primary",
    },
  },
};

export default forms;
