import { SignalRQueue, SyncStatus } from "./SignalRQueue";

const { starting, pending, finished } = SyncStatus;

type SilentSyncConfig = {
  onSlowProgress(): void;
};

export class SignalRQueueWithBasicMonitoring extends SignalRQueue {
  private _syncing = false;
  private _maxQueueSize = 10; // syncing starts when queue size exceeds this number
  private _releaseQueueSize = 2; // syncing stops when queue size becomes lower than this number
  _onSilentIteration?: () => void; // ONLY USED BY TESTS

  add<T>(promise: () => Promise<T>): Promise<T> {
    const { signalQueue, _maxQueueSize, _syncing } = this;
    const result = super.add(promise);
    const isQueueSizeExceeded = this.getQueueSize() > _maxQueueSize;

    if (isQueueSizeExceeded && !_syncing) {
      this._syncing = true;

      this._syncSilently({
        onSlowProgress: () => this._syncLoudly(),
      });

      signalQueue.onIdle().then(() => {
        this._syncing = false;
      });
    }

    return result;
  }

  private _syncSilently(config: SilentSyncConfig): void {
    const { onSlowProgress } = config;
    let lastQueueSize = this.getQueueSize();
    let iteration = 0;

    const worker = setInterval(() => {
      iteration++;
      const queueSize = this.getQueueSize();
      const isQueueEmpty = queueSize <= this._releaseQueueSize;
      const progress = (lastQueueSize - queueSize) / lastQueueSize;
      const isSlowProgress = iteration >= 2 && progress < 0.5;
      lastQueueSize = queueSize;

      isSlowProgress && onSlowProgress();
      (isQueueEmpty || isSlowProgress) && clearInterval(worker);
      this._onSilentIteration?.();
    }, 1000);
  }

  private _syncLoudly(): void {
    const { signalQueue, _releaseQueueSize, _queueOverloadCallback } = this;
    const initialQueueSize = this.getQueueSize();

    _queueOverloadCallback?.({
      syncStatus: starting,
      percentage: 0,
    });

    signalQueue.on("next", () => {
      const currentQueueSize = this.getQueueSize();
      const maxQueueSize = Math.max(currentQueueSize, initialQueueSize);
      const signalsResolved = maxQueueSize - currentQueueSize;
      const percentage = Math.ceil((signalsResolved / maxQueueSize) * 100);
      const isFinished = currentQueueSize <= _releaseQueueSize;

      // Delay this callback a little not to execute "starting" and "pending" callbacks too quickly one after another
      // This is to prevent an occasional bug, where 2 toasts are displaying, and only 1 of them is updated / dismissed
      setTimeout(() =>
        _queueOverloadCallback?.({
          syncStatus: isFinished ? finished : pending,
          percentage,
        })
      );

      isFinished && signalQueue.off("next");
    });
  }
}
