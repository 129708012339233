import Logo from "./Logo";
import NoopComponent from "../shared/NoopComponent";

// NOTE: other than logo, it's the same as default theme

const components: Partial<CustomerComponents> = {
  Logo,
  Tagline: NoopComponent,
};

export default components;
