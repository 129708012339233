import { MouseEvent, useCallback } from "react";
import { useParams } from "react-router-dom";
import { LogCategory, trackEventToLog } from "../../../tools/telemetry";
import { PropertiesToTrack } from "../../../tools/telemetry/applicationInsights";
import { useLoggedInStatus } from "../../authentication/useLoggedInStatus";

const findTrackId = (target: HTMLElement | null): string => {
  if (!target) {
    return "";
  }

  let trackId = target.dataset["trackId"];

  if (trackId === undefined) {
    trackId =
      target.getAttribute("aria-label") ||
      target.id ||
      findTrackId(target.parentElement);

    target.dataset["trackId"] = trackId;
  }

  return trackId;
};

interface UseTrackEventToLog {
  trackEvent: (category: LogCategory, properties?: PropertiesToTrack) => void;
  trackClick: (e: MouseEvent) => void;
}

/**
 * @NOTE Must be used within a route because of `useParams()`.
 */
export const useTrackEventToLog = (): UseTrackEventToLog => {
  // The params may contain the ProjectId from the path
  const { ProjectId } = useParams<AnyRouteParams>();
  const { userProfile } = useLoggedInStatus();

  const trackEvent = useCallback(
    (category: LogCategory, properties?: PropertiesToTrack) =>
      trackEventToLog(category, {
        projectId: ProjectId,
        user: userProfile,
        ...properties,
      }),
    [ProjectId, userProfile]
  );

  const trackClick = useCallback(
    (e: MouseEvent) => {
      const key = findTrackId(e.target as HTMLElement);

      if (key) {
        trackEvent(LogCategory.interaction, {
          subcategory: `click-${key}`,
        });
      }
    },
    [trackEvent]
  );

  return { trackEvent, trackClick };
};
