import type { CountryCode } from "libphonenumber-js";
import countries, { LocalizedCountryNames } from "i18n-iso-countries";

import allCountries, { Country } from "./allCountries";

export const findCountryBy = (
  identifyer: keyof Country,
  item: string
): Country | undefined =>
  allCountries.find(
    (country) =>
      country[identifyer] === item ||
      (country[identifyer] ?? "")
        .toString()
        .toLowerCase()
        .startsWith(item.toLowerCase())
  );

export const getCountry = (
  phoneNumber: string,
  defaultCountry: string
): Country | undefined => {
  const defaultCountryIso2 = defaultCountry?.toLocaleLowerCase();
  const tel = phoneNumber.startsWith("+")
    ? phoneNumber.slice(1, 6)
    : phoneNumber.slice(0, 5);

  const byDialCode = (country: Country) => {
    const number = `+${tel.replace(" ", "")}`;
    return country.dialCode.length && number.startsWith(country.dialCode);
  };

  const byIso2 = (c: Country) => c.iso2 === defaultCountryIso2;

  const byDefaultDialCodeStatus = (c: Country) => c.isDefaultByDialCode;

  const matchingCountries = allCountries.filter(byDialCode);
  return (
    matchingCountries.find(byIso2) ||
    matchingCountries.find(byDefaultDialCodeStatus) ||
    matchingCountries[0]
  );
};

export const formatPhoneNumber = async (
  number: string,
  iso2?: string
): Promise<string> => {
  let formattedPhoneNumber = number.replace(/\+/g, "");

  formattedPhoneNumber = `+${formattedPhoneNumber}`;

  if (formattedPhoneNumber.startsWith("+00")) {
    formattedPhoneNumber = formattedPhoneNumber.replace("00", "");
  }

  const { parsePhoneNumberFromString } = await import("libphonenumber-js");

  const parsedPhoneNumber = parsePhoneNumberFromString(
    formattedPhoneNumber,
    iso2?.toUpperCase() as CountryCode
  );

  try {
    formattedPhoneNumber =
      parsedPhoneNumber?.format("INTERNATIONAL") ??
      formattedPhoneNumber.replace(/\(+-()\)/g, "");
  } catch (e) {
    formattedPhoneNumber = formattedPhoneNumber.replace(/\(+-()\)/g, "");
  }

  return formattedPhoneNumber;
};

export const getCountriesTranslationByLanguage = async (
  lang: string
): Promise<LocalizedCountryNames> => {
  const splitLocaleAt = lang.indexOf("-");
  const languageISOCode = lang.substr(
    0,
    splitLocaleAt === -1 ? lang.length : splitLocaleAt
  );

  try {
    const data = await import(
      `i18n-iso-countries/langs/${languageISOCode}.json`
    );

    countries.registerLocale(data);
    return countries.getNames(data.locale);
  } catch (e) {
    // Default to English rather than failing
    // TODO: Use logger for production
    const data = await import("i18n-iso-countries/langs/en.json");

    countries.registerLocale(data);
    return countries.getNames("en");
  }
};
