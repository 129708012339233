import React, { ReactElement, useContext } from "react";
import { Box, Grid, ThemeUIStyleObject } from "theme-ui";
import { ApiResourcePermission, ApiResourceType } from "../../../../types/enum";
import { usePlanDetails } from "../../../licensing/usePlanDetails";
import { usePermissions } from "../../../permissions/usePermissions";
import { useSimpleModal } from "../../../shared/modals/useSimpleModal";
import ContainedSpinner from "../../../shared/spinners/ContainedSpinner";
import { useSpaces } from "../../../spaces/useSpaces";
import { findSpacePermission, isSpaceAdmin } from "../../../spaces/utils";
import { ProjectsContext } from "../Projects";
import CreateNewProject from "./CreateNewProject";
import CreateProjectModalTemplate from "./CreateProjectModalTemplate";
import FreeProjectsLimitModalTemplate from "./FreeProjectsLimitModalTemplate";
import LoadFromQRCode from "./LoadFromQRCode";
import ProjectThumbnail from "./ProjectThumbnail";
import QRScannerModalTemplate from "./QRScannerModalTemplate";

type Props = {
  activeSpaceId: number | null;
};

function ProjectsNav({ activeSpaceId }: Props): ReactElement {
  const { spaces } = useSpaces();
  const createProjectModalProps = useSimpleModal();
  const freeProjectsLimitModalProps = useSimpleModal();
  const qrScannerModalProps = useSimpleModal();

  const {
    isAllowedToCreateProject: createProjectAllowedInLicence,
    showCreateNewProject,
  } = usePlanDetails();

  const { Projects, isProjectListLoading } = useContext(ProjectsContext).all;
  const { hasPermission } = usePermissions();

  const activeSpace = spaces.find(
    (space) => space.Space.SpaceId === activeSpaceId
  );

  const allowedProjects = Projects.filter((project) => {
    const spaceId = project.Project.SpaceId;
    return hasPermission({
      type: spaceId ? ApiResourceType.SpaceProjects : ApiResourceType.Project,
      value: String(project.Project.ProjectId),
      permission: ApiResourcePermission.View,
      spacePermission: spaceId
        ? findSpacePermission(spaces, spaceId)
        : undefined,
      projectPermission: project.Permission,
    });
  });

  const canCreateProject = activeSpaceId
    ? hasPermission({
        type: ApiResourceType.SpaceProjects,
        value: String(activeSpaceId),
        permission: ApiResourcePermission.Create,
        spacePermission: activeSpace ? activeSpace.SpacePermission : undefined,
        defaultPermission:
          activeSpace && isSpaceAdmin(activeSpace.SpacePermission),
      })
    : hasPermission({
        type: ApiResourceType.Project,
        value: null,
        permission: ApiResourcePermission.Create,
      });

  return (
    <Box>
      <Box sx={projectsContainerStyle}>
        {isProjectListLoading && <ContainedSpinner />}
        <Grid as="nav" sx={navStyle}>
          {allowedProjects.slice(0, 7).map((project, index) => (
            <ProjectThumbnail
              index={index}
              key={project.Project.ProjectId}
              project={project}
            />
          ))}
          {showCreateNewProject && (
            <>
              {canCreateProject && (
                <CreateNewProject
                  onClick={
                    createProjectAllowedInLicence
                      ? createProjectModalProps.open
                      : freeProjectsLimitModalProps.open
                  }
                />
              )}
              <LoadFromQRCode onClick={qrScannerModalProps.open} />
            </>
          )}
        </Grid>
      </Box>
      <CreateProjectModalTemplate
        modalProps={createProjectModalProps}
        activeSpaceId={activeSpaceId}
      />
      <FreeProjectsLimitModalTemplate
        modalProps={freeProjectsLimitModalProps}
      />
      <QRScannerModalTemplate modalProps={qrScannerModalProps} />
    </Box>
  );
}

const projectsContainerStyle: ThemeUIStyleObject = { position: "relative" };

const navStyle: ThemeUIStyleObject = {
  gridAutoFlow: ["row", null, "column"],
  gridTemplateColumns: "repeat(6, 1fr)",
  gap: 0,
  mb: [7],

  ".project-thumbnail": {
    gridColumn: ["span 3", "span 2"],
    gridRow: ["span 3", "span 2"],
  },

  "& .project-thumbnail:nth-of-type(3) ~ .project-thumbnail": {
    gridColumn: [null, null, "span 1"],
    gridRow: [null, null, "span 1"],
  },
  // If there are more than three items and less then seven everything after the second item must be small
  "& .project-thumbnail:nth-of-type(2):nth-last-of-type(3) ~ .project-thumbnail": {
    gridColumn: [null, null, "span 1"],
    gridRow: [null, null, "span 1"],
  },
  "& .project-thumbnail:nth-of-type(2):nth-last-of-type(4) ~ .project-thumbnail": {
    gridColumn: [null, null, "span 1"],
    gridRow: [null, null, "span 1"],
  },
  "& .project-thumbnail:nth-of-type(2):nth-last-of-type(5) ~ .project-thumbnail": {
    gridColumn: [null, null, "span 1"],
    gridRow: [null, null, "span 1"],
  },
  // If there are more than six items everything after the first item must be small
  "& .project-thumbnail:nth-of-type(1):nth-last-of-type(7) ~ .project-thumbnail": {
    gridColumn: [null, null, "span 1"],
    gridRow: [null, null, "span 1"],
  },
  "& .project-thumbnail:nth-of-type(1):nth-last-of-type(8) ~ .project-thumbnail": {
    gridColumn: [null, null, "span 1"],
    gridRow: [null, null, "span 1"],
  },
  "& .project-thumbnail:nth-of-type(1):nth-last-of-type(9) ~ .project-thumbnail": {
    gridColumn: [null, null, "span 1"],
    gridRow: [null, null, "span 1"],
  },
};

export default ProjectsNav;
