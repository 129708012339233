import React, { ReactElement, useCallback, useEffect } from "react";
import { useLoggedInStatus } from "../authentication/useLoggedInStatus";
import { welcomeVideoClosedStorage } from "../../tools/localStorageStores";
import { useSimpleModal } from "../shared/modals/useSimpleModal";
import WelcomeVideoModalTemplate from "./WelcomeVideoModalTemplate";

function WelcomeVideo(): ReactElement | null {
  const { userProfile } = useLoggedInStatus();

  const firstName = userProfile?.FirstName;

  const onClose = useCallback(() => welcomeVideoClosedStorage.set(true), []);
  const welcomeVideoModalProps = useSimpleModal({ onClose });
  const { open: openWelcomeVideoModal } = welcomeVideoModalProps;

  useEffect(() => {
    openWelcomeVideoModal();
  }, [openWelcomeVideoModal]);

  return (
    <WelcomeVideoModalTemplate
      modalProps={welcomeVideoModalProps}
      firstName={firstName}
    />
  );
}

export default WelcomeVideo;
