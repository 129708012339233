import React, { ReactElement } from "react";

import CodeRedemptionLanding from "./CodeRedemptionLanding";

function WacomLanding(): ReactElement {
  return (
    <CodeRedemptionLanding label="Wacom" name="wacom" logoSx={{ mb: [4] }} />
  );
}

export default WacomLanding;
