import { flattenObjectTree } from "../../studio/utils/fabricObjects";

import CollaboardObjectConnections from "../components/connections/collaboardObjectCustom.connections";

/**
 * Search the object list to determine which connections are connected.
 * @param {Array<fabric.Object>} objects List of objects
 * @returns {Array<fabric.Connector>} List of connectors
 */
export const getObjectConnections = (objects) => {
  const flattenedObjects = flattenObjectTree(objects);
  const connectableObjects = flattenedObjects.filter((object) => {
    return object.connections instanceof CollaboardObjectConnections;
  });
  return _getUniqueConnections(connectableObjects);
};

/**
 * Return a list of unique connections given a list of objects
 * @private
 * @param {Array<fabric.Object>} objects List of objects
 * @returns {Array<fabric.Connector>} List of connectors
 */
const _getUniqueConnections = (objects) => {
  return objects.reduce((result, object) => {
    object.connections &&
      object.connections.getConnections().forEach((connection) => {
        if (!result.includes(connection)) {
          result.push(connection);
        }
      });
    return result;
  }, []);
};

/**
 * Check if two connection coordinates are equal
 */
export const areCoordsEqual = (coordsA, coordsB) => {
  return (
    coordsA.x1 === coordsB.x1 &&
    coordsA.y1 === coordsB.y1 &&
    coordsA.x2 === coordsB.x2 &&
    coordsA.y2 === coordsB.y2
  );
};

/**
 * Check if two points have been moved with the same translation
 */
export const isSameTranslation = (coordsA, coordsB) => {
  const x1Diff = Math.round(coordsA.x1 - coordsB.x1);
  const y1Diff = Math.round(coordsA.y1 - coordsB.y1);
  const x2Diff = Math.round(coordsA.x2 - coordsB.x2);
  const y2Diff = Math.round(coordsA.y2 - coordsB.y2);

  return x1Diff === x2Diff && y1Diff === y2Diff;
};
