import { reduxSingleton } from "../redux.singleton";
import { getTranslationValue } from "./translation";

export { useUISettings } from "./uiSettings.hook";

/**
 * Check if a feature flag is active.
 *
 * This value may change at runtime.
 *
 * @param flag Feature flag
 * @returns {boolean}
 */
export const isDynamicFeatureActive = (
  flag: keyof RuntimeConfigDynamicFeatureFlags
): boolean => {
  // TODO: Move flags to separate key
  return !!(reduxSingleton.getStore().getState().uiSettings
    .dynamicFeatures as RuntimeConfigDynamicFeatureFlags)[flag];
};

/**
 * Get feature config.
 *
 * This value may change at runtime.
 *
 * @param flag Feature flag
 * @returns
 */
export const getFeatureConfig = (
  flag: keyof RuntimeConfigDynamicFeatureConfig
): unknown | void => {
  // TODO: Move flags to separate key
  return (reduxSingleton.getStore().getState().uiSettings
    .dynamicFeatures as RuntimeConfigDynamicFeatureConfig)[flag];
};

/**
 * Get a custom translation value, if it exists.
 *
 * @param key Translation key
 * @returns {string}
 */
export const getTranslation = (key: string): string => {
  return getTranslationValue(
    key,
    reduxSingleton.getStore().getState().uiSettings.labels
  );
};
