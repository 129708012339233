import React, { ReactElement } from "react";
import { Heading, Box, ThemeUIStyleObject } from "theme-ui";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../../../../icons/LoadingSpinner";
import EmptyListPlaceholder from "../../../../shared/layout/EmptyListPlaceholder";
import RequestErrorPlaceholder from "../RequestErrorPlaceholder";
import PendingRequest from "./PendingRequest";
import { UseInfiniteQueryResult } from "../../../../../api/useRequest";
import InfiniteScroll from "react-infinite-scroller";
import Centered from "../../../../shared/layout/Centered";
import { rowHeight } from "../const";

type Props = {
  onRemoveUser(user: ApiSubscriptionUser, isActive: boolean): void;
  query: UseInfiniteQueryResult & {
    data: ApiSubscriptionUser[];
  };
};

function PendingUsers({ onRemoveUser, query }: Props): ReactElement {
  const { t } = useTranslation();
  const {
    canFetchMore,
    fetchMore,
    data,
    isDataInitialized,
    error,
    isFetching,
    isFetchingMore,
  } = query;

  return (
    <>
      <Heading variant="layout.darkBar" sx={{ mt: [5] }}>
        <Box>{t("team.pendingRequests")}</Box>
      </Heading>

      {!isDataInitialized && !error && <LoadingSpinner />}
      {isDataInitialized && data.length === 0 && (
        <EmptyListPlaceholder sx={{ height: rowHeight }} />
      )}
      {error && <RequestErrorPlaceholder />}

      {!!data.length && (
        <Box sx={scrollWrapperStyle}>
          <InfiniteScroll
            pageStart={1}
            useWindow={false}
            loadMore={fetchMore}
            hasMore={canFetchMore && !isFetching && !isFetchingMore}
            loader={
              <Centered key="subscription-pending-users-infinite-scroll">
                <LoadingSpinner />
              </Centered>
            }
          >
            {data.map((user) => (
              <PendingRequest
                key={user.Username}
                request={user}
                onRemove={() => onRemoveUser(user, false)}
              />
            ))}
          </InfiniteScroll>
        </Box>
      )}
    </>
  );
}

const scrollWrapperStyle: ThemeUIStyleObject = {
  height: "300px",
  overflowY: "auto",
};

export default PendingUsers;
