import React, { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Input, Flex } from "theme-ui";
import ModalHeader from "../shared/modals/ModalHeader";
import { TemplateProps } from "../shared/modals/Modal";
import Modal from "../shared/modals";
import Cool from "../../icons/Cool";
import { hideWelcomeVideoStorage } from "../../tools/localStorageStores";
import { withModalTemplate } from "../shared/modals/withModalTemplate";

type Props = {
  firstName?: string;
};

function WelcomeVideoModalTemplate({
  firstName,
  modalProps,
}: TemplateProps<Props>): ReactElement {
  const { t } = useTranslation();

  return (
    <Modal
      {...modalProps}
      header={<ModalHeader Icon={Cool} title={t("dialog.welcome.welcome")} />}
    >
      <Trans i18nKey="dialog.welcome.welcomeText" values={{ firstName }}>
        <br />
      </Trans>
      <Flex sx={{ my: 5 }}>
        <video
          controls
          style={{ width: "100%", height: "100%" }}
          poster="/welcome_video_poster.png"
        >
          <source src="/welcomeVideo.mp4" type="video/mp4" />
          <track src="captions_en.vtt" kind="captions"></track>
        </video>
      </Flex>
      <Flex>
        <Input
          variant="toggle"
          type="checkbox"
          defaultChecked
          onChange={(e) => hideWelcomeVideoStorage.set(!e.target.checked)}
        />
        <Flex sx={{ ml: [2] }}>{t("dialog.welcome.showOnStartup")}</Flex>
      </Flex>
    </Modal>
  );
}

export default withModalTemplate(WelcomeVideoModalTemplate);
