import { AnyAction } from "redux";
import { ApiAuthenticationMode } from "../../types/enum";

export enum AuthUserType {
  Guest = "Guest",
  User = "User",
}

export enum AuthActionType {
  INIT_AUTH = "INIT_AUTH",

  SET_AUTHENTICATING = "SET_AUTHENTICATING",
  CLEAR_AUTHENTICATING = "CLEAR_AUTHENTICATING",

  SET_AUTHENTICATED = "SET_AUTHENTICATED",

  ASSERT_USER = "ASSERT_USER",

  UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE",
  SET_AUTHENTICATION_MODE = "SET_AUTHENTICATION_MODE",

  LOGOUT = "LOGOUT",

  LOGGED_IN = "LOGGED_IN",
  LOGGED_OUT = "LOGGED_OUT",

  SYNC_LOGGED_IN = "SYNC_LOGGED_IN",
  SYNC_LOGGED_OUT = "SYNC_LOGGED_OUT",

  CLEAR_REDIRECT_AFTER_LOGIN = "CLEAR_REDIRECT_AFTER_LOGIN",
}

export const isAuthAction = (action: AnyAction): action is AuthAction => {
  return Object.values(AuthActionType).includes(action.type);
};

/**
 * INIT AUTH
 */
export const initAuthAction = () =>
  ({
    type: AuthActionType.INIT_AUTH,
  } as const);

/**
 * SET AUTHENTICATING
 *
 * These values need to be persisted to storage so that they are still available
 * after the roundtrip authentication to an external authentication provider.
 */

export type SetAuthenticatingPayload = {
  authProvider: string;
  isRegistration: boolean;
  redirect?: string;
  tenant?: string;
};

export const setAuthenticatingAction = (payload: SetAuthenticatingPayload) =>
  ({
    type: AuthActionType.SET_AUTHENTICATING,
    payload,
  } as const);

export const clearAuthenticatingAction = () =>
  ({
    type: AuthActionType.CLEAR_AUTHENTICATING,
  } as const);

/**
 * SET AUTHENTICATED
 */

export type SetAuthenticatedPayload = SetAuthenticatingPayload & {
  token: {
    AuthorizationToken: string;
    RefreshToken: string;
  };
  userType: AuthUserType;
};

export const setAuthenticatedAction = (payload: SetAuthenticatedPayload) =>
  ({
    type: AuthActionType.SET_AUTHENTICATED,
    payload,
  } as const);

/**
 * ASSERT USER
 */

export const assertUserAction = () =>
  ({
    type: AuthActionType.ASSERT_USER,
  } as const);

/**
 * UPDATE USER PROFILE
 */

export const updateUserProfileAction = (payload: UserProfile) =>
  ({
    type: AuthActionType.UPDATE_USER_PROFILE,
    payload,
  } as const);

/**
 * @TODO #7118 - Update the UPDATE_USER_PROFILE action so it can accept
 * a partial of the UserState, instead of ApiUser. Then we'd be able to reuse
 * that instead of this separate action.
 */
export const setAuthenticateModeAction = (payload: ApiAuthenticationMode) =>
  ({
    type: AuthActionType.SET_AUTHENTICATION_MODE,
    payload,
  } as const);

/**
 * LOGOUT
 */

export const logoutAction = () =>
  ({
    type: AuthActionType.LOGOUT,
  } as const);

/**
 * LOGGED IN / LOGGED OUT
 */

export const loggedInAction = (payload: UserProfile) =>
  ({
    type: AuthActionType.LOGGED_IN,
    payload,
  } as const);

export const loggedOutAction = () =>
  ({
    type: AuthActionType.LOGGED_OUT,
  } as const);

/**
 * SYNC ACTIONS (propagate to other tabs)
 */

export type SyncPayload = {
  uniqueTabId: string;
};

export const syncLoggedInAction = (payload: SyncPayload) =>
  ({
    type: AuthActionType.SYNC_LOGGED_IN,
    payload,
  } as const);

export const syncLoggedOutAction = (payload: SyncPayload) =>
  ({
    type: AuthActionType.SYNC_LOGGED_OUT,
    payload,
  } as const);

/**
 * REDIRECT
 */
export const clearRedirectAfterLoginAction = () =>
  ({
    type: AuthActionType.CLEAR_REDIRECT_AFTER_LOGIN,
  } as const);

export type InitAuthAction = ReturnType<typeof initAuthAction>;
export type SetAuthenticatingAction = ReturnType<
  typeof setAuthenticatingAction
>;
export type ClearAuthenticatingAction = ReturnType<
  typeof clearAuthenticatingAction
>;
export type SetAuthenticatedAction = ReturnType<typeof setAuthenticatedAction>;
export type AssertUserAction = ReturnType<typeof assertUserAction>;
export type UpdateUserProfileAction = ReturnType<
  typeof updateUserProfileAction
>;
export type SetAuthenticateModeAction = ReturnType<
  typeof setAuthenticateModeAction
>;
export type LogoutAction = ReturnType<typeof logoutAction>;
export type LoggedInAction = ReturnType<typeof loggedInAction>;
export type LoggedOutAction = ReturnType<typeof loggedOutAction>;
export type SyncLoggedInAction = ReturnType<typeof syncLoggedInAction>;
export type SyncLoggedOutAction = ReturnType<typeof syncLoggedOutAction>;
export type ClearRedirectAfterLoginAction = ReturnType<
  typeof clearRedirectAfterLoginAction
>;

export type AuthAction =
  | InitAuthAction
  | SetAuthenticatingAction
  | ClearAuthenticatingAction
  | SetAuthenticatedAction
  | AssertUserAction
  | UpdateUserProfileAction
  | SetAuthenticateModeAction
  | LogoutAction
  | LoggedInAction
  | LoggedOutAction
  | SyncLoggedInAction
  | SyncLoggedOutAction
  | ClearRedirectAfterLoginAction;
