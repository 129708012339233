import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function OrderDown(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M26.25,28.75a.67.67,0,0,0,.21.1Z" />
      <path d="M30.5,24.2a1,1,0,0,0-1.36,0l-1.41,1.41V17.66a1,1,0,0,0-1.93,0v8L24.39,24.2A1,1,0,0,0,23,25.56l3,3,0,.05.07.06h0l.1.07.21.1a.45.45,0,0,0,.11,0l.19,0a1,1,0,0,0,.29,0,1.06,1.06,0,0,0,.33-.18h0l.07-.06,0,0,3-3A1,1,0,0,0,30.5,24.2Z" />
      <path d="M24.67,12.88a1,1,0,0,0-1-1h-12a1,1,0,1,0,0,1.93H23.71A1,1,0,0,0,24.67,12.88Z" />
      <path d="M22.26,17.66a1,1,0,0,0-1-1H11.66a1,1,0,1,0,0,1.93H21.3A1,1,0,0,0,22.26,17.66Z" />
      <path d="M19.85,22.45a1,1,0,0,0-1-1H11.66a1,1,0,1,0,0,1.93h7.23A1,1,0,0,0,19.85,22.45Z" />
      <path d="M17.44,27.23a1,1,0,0,0-1-1H11.66a1,1,0,1,0,0,1.93h4.82A1,1,0,0,0,17.44,27.23Z" />
    </Icon>
  );
}
export default OrderDown;
