import type {
  DeleteProjectMessage,
  ProjectUnavailableMessage,
  TileRelation,
  TileStatus,
} from "../../../api/signalR/message.types";
import type { ProjectStatusState } from "./project.reducer";

export enum ProjectActionType {
  INCREMENTAL_COMPLETE_PROJECT = "INCREMENTAL_COMPLETE_PROJECT",
  INCREMENTAL_UPDATE_PROJECT = "INCREMENTAL_UPDATE_PROJECT",
  PROJECT_DELETED = "PROJECT_DELETED",
  PROJECT_RESET = "PROJECT_RESET",
  PROJECT_UNAVAILABLE = "PROJECT_UNAVAILABLE",
  RESET_PROJECT_STATUS = "RESET_PROJECT_STATUS",
  SETUP_PROJECT = "SETUP_PROJECT",
  SET_PARTICIPANTS = "SET_PARTICIPANTS",
  SET_PROJECT_STATUS = "SET_PROJECT_STATUS",
}

export type TilesLoadCallbacks = {
  onTilesAdded?(): void;
  onAllTilesAdded?(): void;
  onPreviewLoaded?(): void;
};

export type UpdateProjectPayload = {
  tiles: TileStatus[];
  relations: TileRelation[];
  config: TilesLoadCallbacks;
  percentage: number;
};

export const incrementalUpdateProjectAction = (payload: UpdateProjectPayload) =>
  ({
    type: ProjectActionType.INCREMENTAL_UPDATE_PROJECT,
    payload,
  } as const);

export const incrementalCompleteProjectAction = (
  payload: UpdateProjectPayload
) =>
  ({
    type: ProjectActionType.INCREMENTAL_COMPLETE_PROJECT,
    payload,
  } as const);

export const onProjectDeletedAction = (payload: DeleteProjectMessage) =>
  ({
    type: ProjectActionType.PROJECT_DELETED,
    payload,
  } as const);

export const onProjectResetAction = () =>
  ({
    type: ProjectActionType.PROJECT_RESET,
    payload: undefined,
  } as const);

export const onProjectUnavailableAction = (
  payload: ProjectUnavailableMessage
) =>
  ({
    type: ProjectActionType.PROJECT_UNAVAILABLE,
    payload,
  } as const);

export const resetStatusAction = () =>
  ({
    type: ProjectActionType.RESET_PROJECT_STATUS,
  } as const);

type SetupProjectPayload = {
  project: ApiGetProject;
  viewportTransform?: TransformMatrix;
};

export const setupProjectAction = (payload: SetupProjectPayload) =>
  ({
    type: ProjectActionType.SETUP_PROJECT,
    payload,
  } as const);

type SetParticipantsPayload = {
  users: ProjectParticipant[];
  onlineUsers: ApiOnlineUser[];
};

export const setParticipantsAction = (payload: SetParticipantsPayload) =>
  ({
    type: ProjectActionType.SET_PARTICIPANTS,
    payload,
  } as const);

export const setStatusAction = (payload: ProjectStatusState) =>
  ({
    type: ProjectActionType.SET_PROJECT_STATUS,
    payload,
  } as const);

export type IncrementalCompleteProjectAction = ReturnType<
  typeof incrementalCompleteProjectAction
>;
export type IncrementalUpdateProjectAction = ReturnType<
  typeof incrementalUpdateProjectAction
>;
export type OnProjectDeletedAction = ReturnType<typeof onProjectDeletedAction>;
export type OnProjectResetAction = ReturnType<typeof onProjectResetAction>;
export type OnProjectUnavailableAction = ReturnType<
  typeof onProjectUnavailableAction
>;
export type ResetStatusAction = ReturnType<typeof resetStatusAction>;
export type SetupProjectAction = ReturnType<typeof setupProjectAction>;
export type SetParticipantsAction = ReturnType<typeof setParticipantsAction>;
export type SetStatusAction = ReturnType<typeof setStatusAction>;

export type ProjectAction =
  | IncrementalCompleteProjectAction
  | IncrementalUpdateProjectAction
  | OnProjectDeletedAction
  | OnProjectResetAction
  | OnProjectUnavailableAction
  | ResetStatusAction
  | SetupProjectAction
  | SetParticipantsAction
  | SetStatusAction;
