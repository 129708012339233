import theme from "../twr";

const configuration: CustomerConfiguration = {
  appName: "Teamwork Revolution",
  faviconName: "favicon-teamwork.ico",
  loginTroubleHref: "mailto:support@hds19.com",
  theme,
};

export default configuration;
