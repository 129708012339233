import { all, Effect, getContext, put, select, take } from "redux-saga/effects";
import { HighlightHoveredObjectMode } from "../../../types/enum";
import {
  CanvasAppActionType,
  SetCanvasModeAction,
  setLeftSidePanelAction,
  setRightSidePanelAction,
} from "./app.actions";
import {
  CanvasMode,
  selectCanvasMode,
  selectIsViewOnlyMode,
} from "./app.reducer";

function* canvasMode() {
  while (true) {
    const previousCanvasMode = selectCanvasMode(yield select());
    const action: SetCanvasModeAction = yield take(
      CanvasAppActionType.SET_CANVAS_MODE
    );
    const newCanvasMode = selectCanvasMode(yield select());

    const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

    if (
      previousCanvasMode === CanvasMode.DRAW &&
      newCanvasMode !== CanvasMode.DRAW
    ) {
      canvas.trigger("custom:drawing-mode:deactivate");
    }

    if (
      previousCanvasMode !== CanvasMode.SELECT_OBJECT &&
      newCanvasMode === CanvasMode.SELECT_OBJECT &&
      action.payload.canvasMode === CanvasMode.SELECT_OBJECT // TS prompt
    ) {
      // Start SELECT_OBJECT mode
      canvas.enterObjectSelectionMode(action.payload.sourceTileId);
    } else if (
      previousCanvasMode === CanvasMode.SELECT_OBJECT &&
      newCanvasMode !== CanvasMode.SELECT_OBJECT
    ) {
      // Stop SELECT_OBJECT mode
      canvas.exitObjectSelectionMode();
    }

    if (
      previousCanvasMode !== CanvasMode.ADD_CHAT &&
      newCanvasMode === CanvasMode.ADD_CHAT
    ) {
      // Start ADD_CHAT mode
      const currentStashedGroup = canvas.__subselectedGroupRef;
      currentStashedGroup && currentStashedGroup.deactivateSubselectionMode();
      canvas.stashGroups(canvas.getStashableGroups());
      canvas.setHighlightMode(HighlightHoveredObjectMode.attachmentMode);
    } else if (
      previousCanvasMode === CanvasMode.ADD_CHAT &&
      newCanvasMode !== CanvasMode.ADD_CHAT
    ) {
      // Stop ADD_CHAT mode
      canvas.recreateGroups();
      canvas.setHighlightMode(undefined);
    }

    if (
      previousCanvasMode !== CanvasMode.VOTING &&
      newCanvasMode === CanvasMode.VOTING
    ) {
      // Start VOTING mode
      yield put(setLeftSidePanelAction({ sidePanel: undefined }));
      yield put(setRightSidePanelAction({ sidePanel: undefined }));
    }

    canvas.mode = newCanvasMode;
    canvas.isViewOnlyMode = selectIsViewOnlyMode(yield select());
    canvas.isEyeDropperActive = newCanvasMode === CanvasMode.PICK_COLOR;
    canvas.enableMultiSelectionFlag(); // TODO - this should only be used in some cases
    canvas.updateSkipTargetFind();
  }
}

export function* appSaga(): Generator<Effect> {
  yield all([canvasMode()]);
}
