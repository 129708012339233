import { prop, sortBy } from "ramda";
import React from "react";
import { useTranslation } from "react-i18next";

import Checkmark from "../../../icons/Checkmark";

export const useComparisonTableColumn = ({ plan, labels }) => {
  const { t } = useTranslation();

  const values = labels.map(({ labelKey }) => {
    const features = sortBy(prop("order"), [...plan.all, ...plan.values]);

    const feature = features.find((feature) => feature.labelKey === labelKey);

    const isValueless = !feature?.value;
    const isValueFalse = feature?.value?.[0] === false;
    const isValueNull = feature?.value?.[0] === null;

    if (!feature || isValueFalse) {
      return {
        labelKey,
        value: null,
      };
    }

    if (isValueless) {
      return {
        labelKey,
        value: <Checkmark noShadow />,
      };
    }

    if (isValueNull) {
      return {
        labelKey,
        value: t("plans.features.unlimited"),
      };
    }

    const isLabelTableSpecific = Boolean(feature.value[1]);

    return {
      labelKey,
      value: isLabelTableSpecific
        ? t(`plans.features.${feature.labelKey}Table`, {
            number: feature.value[0],
          })
        : feature.value[0],
    };
  });

  return { values };
};
