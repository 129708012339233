export type Country = {
  name: string;
  regions: string[];
  iso2: string;
  dialCode: string;
  isAreaCode?: boolean;
  isDefaultByDialCode?: boolean; // #5569 - some countries share dialCode - this flag marks 'primary' ones
  hasAreaCodes: boolean;
};

const allCountries: Country[] = [
  {
    name: "International",
    regions: [],
    iso2: "intl",
    dialCode: "",
    isAreaCode: true,
    hasAreaCodes: false,
  },
  {
    name: "Afghanistan (‫افغانستان‬‎)",
    regions: ["asia"],
    iso2: "af",
    dialCode: "+93",
    hasAreaCodes: false,
  },
  {
    name: "Albania (Shqipëri)",
    regions: ["europe"],
    iso2: "al",
    dialCode: "+355",
    hasAreaCodes: false,
  },
  {
    name: "Algeria (‫الجزائر‬‎)",
    regions: ["africa", "north-africa"],
    iso2: "dz",
    dialCode: "+213",
    hasAreaCodes: false,
  },
  {
    name: "American Samoa",
    regions: ["oceania"],
    iso2: "as",
    dialCode: "+1684",
    hasAreaCodes: false,
  },
  {
    name: "Andorra",
    regions: ["europe"],
    iso2: "ad",
    dialCode: "+376",
    hasAreaCodes: false,
  },
  {
    name: "Angola",
    regions: ["africa"],
    iso2: "ao",
    dialCode: "+244",
    hasAreaCodes: false,
  },
  {
    name: "Anguilla",
    regions: ["america", "carribean"],
    iso2: "ai",
    dialCode: "+1264",
    hasAreaCodes: false,
  },
  {
    name: "Antigua and Barbuda",
    regions: ["america", "carribean"],
    iso2: "ag",
    dialCode: "+1268",
    hasAreaCodes: false,
  },
  {
    name: "Argentina",
    regions: ["america", "south-america"],
    iso2: "ar",
    dialCode: "+54",
    hasAreaCodes: false,
  },
  {
    name: "Armenia (Հայաստան)",
    regions: ["asia", "ex-ussr"],
    iso2: "am",
    dialCode: "+374",
    hasAreaCodes: false,
  },
  {
    name: "Aruba",
    regions: ["america", "carribean"],
    iso2: "aw",
    dialCode: "+297",
    hasAreaCodes: false,
  },
  {
    name: "Australia",
    regions: ["oceania"],
    iso2: "au",
    dialCode: "+61",
    hasAreaCodes: false,
  },
  {
    name: "Austria (Österreich)",
    regions: ["europe", "european-union"],
    iso2: "at",
    dialCode: "+43",
    hasAreaCodes: false,
  },
  {
    name: "Azerbaijan (Azərbaycan)",
    regions: ["asia", "ex-ussr"],
    iso2: "az",
    dialCode: "+994",
    hasAreaCodes: false,
  },
  {
    name: "Bahamas",
    regions: ["america", "carribean"],
    iso2: "bs",
    dialCode: "+1242",
    hasAreaCodes: false,
  },
  {
    name: "Bahrain (‫البحرين‬‎)",
    regions: ["middle-east"],
    iso2: "bh",
    dialCode: "+973",
    hasAreaCodes: false,
  },
  {
    name: "Bangladesh (বাংলাদেশ)",
    regions: ["asia"],
    iso2: "bd",
    dialCode: "+880",
    hasAreaCodes: false,
  },
  {
    name: "Barbados",
    regions: ["america", "carribean"],
    iso2: "bb",
    dialCode: "+1246",
    hasAreaCodes: false,
  },
  {
    name: "Belarus (Беларусь)",
    regions: ["europe", "ex-ussr"],
    iso2: "by",
    dialCode: "+375",
    hasAreaCodes: false,
  },
  {
    name: "Belgium (België)",
    regions: ["europe", "european-union"],
    iso2: "be",
    dialCode: "+32",
    hasAreaCodes: false,
  },
  {
    name: "Belize",
    regions: ["america", "central-america"],
    iso2: "bz",
    dialCode: "+501",
    hasAreaCodes: false,
  },
  {
    name: "Benin (Bénin)",
    regions: ["africa"],
    iso2: "bj",
    dialCode: "+229",
    hasAreaCodes: false,
  },
  {
    name: "Bermuda",
    regions: ["america", "north-america"],
    iso2: "bm",
    dialCode: "+1441",
    hasAreaCodes: false,
  },
  {
    name: "Bhutan (འབྲུག)",
    regions: ["asia"],
    iso2: "bt",
    dialCode: "+975",
    hasAreaCodes: false,
  },
  {
    name: "Bolivia",
    regions: ["america", "south-america"],
    iso2: "bo",
    dialCode: "+591",
    hasAreaCodes: false,
  },
  {
    name: "Bosnia and Herzegovina (Босна и Херцеговина)",
    regions: ["europe"],
    iso2: "ba",
    dialCode: "+387",
    hasAreaCodes: false,
  },
  {
    name: "Botswana",
    regions: ["africa"],
    iso2: "bw",
    dialCode: "+267",
    hasAreaCodes: false,
  },
  {
    name: "Brazil (Brasil)",
    regions: ["america", "south-america"],
    iso2: "br",
    dialCode: "+55",
    hasAreaCodes: false,
  },
  {
    name: "British Indian Ocean Territory",
    regions: ["asia"],
    iso2: "io",
    dialCode: "+246",
    hasAreaCodes: false,
  },
  {
    name: "British Virgin Islands",
    regions: ["america", "carribean"],
    iso2: "vg",
    dialCode: "+1284",
    hasAreaCodes: false,
  },
  {
    name: "Brunei",
    regions: ["asia"],
    iso2: "bn",
    dialCode: "+673",
    hasAreaCodes: false,
  },
  {
    name: "Bulgaria (България)",
    regions: ["europe", "european-union"],
    iso2: "bg",
    dialCode: "+359",
    hasAreaCodes: false,
  },
  {
    name: "Burkina Faso",
    regions: ["africa"],
    iso2: "bf",
    dialCode: "+226",
    hasAreaCodes: false,
  },
  {
    name: "Burundi (Uburundi)",
    regions: ["africa"],
    iso2: "bi",
    dialCode: "+257",
    hasAreaCodes: false,
  },
  {
    name: "Cambodia (កម្ពុជា)",
    regions: ["asia"],
    iso2: "kh",
    dialCode: "+855",
    hasAreaCodes: false,
  },
  {
    name: "Cameroon (Cameroun)",
    regions: ["africa"],
    iso2: "cm",
    dialCode: "+237",
    hasAreaCodes: false,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1",
    hasAreaCodes: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1204",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1236",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1249",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1250",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1289",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1306",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1343",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1365",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1387",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1403",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1416",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1418",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1431",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1437",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1438",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1450",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1506",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1514",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1519",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1548",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1579",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1581",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1587",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1604",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1613",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1639",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1647",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1672",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1705",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1709",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1742",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1778",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1780",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1782",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1807",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1819",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1825",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1867",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1873",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1902",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Canada",
    regions: ["america", "north-america"],
    iso2: "ca",
    dialCode: "+1905",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Cape Verde (Kabu Verdi)",
    regions: ["africa"],
    iso2: "cv",
    dialCode: "+238",
    hasAreaCodes: false,
  },
  {
    name: "Caribbean Netherlands",
    regions: ["america", "carribean"],
    iso2: "bq",
    dialCode: "+599",
    hasAreaCodes: false,
  },
  {
    name: "Cayman Islands",
    regions: ["america", "carribean"],
    iso2: "ky",
    dialCode: "+1345",
    hasAreaCodes: false,
  },
  {
    name: "Central African Republic (République centrafricaine)",
    regions: ["africa"],
    iso2: "cf",
    dialCode: "+236",
    hasAreaCodes: false,
  },
  {
    name: "Chad (Tchad)",
    regions: ["africa"],
    iso2: "td",
    dialCode: "+235",
    hasAreaCodes: false,
  },
  {
    name: "Chile",
    regions: ["america", "south-america"],
    iso2: "cl",
    dialCode: "+56",
    hasAreaCodes: false,
  },
  {
    name: "China (中国)",
    regions: ["asia"],
    iso2: "cn",
    dialCode: "+86",
    hasAreaCodes: false,
  },
  {
    name: "Colombia",
    regions: ["america", "south-america"],
    iso2: "co",
    dialCode: "+57",
    hasAreaCodes: false,
  },
  {
    name: "Comoros (‫جزر القمر‬‎)",
    regions: ["africa"],
    iso2: "km",
    dialCode: "+269",
    hasAreaCodes: false,
  },
  {
    name: "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)",
    regions: ["africa"],
    iso2: "cd",
    dialCode: "+243",
    hasAreaCodes: false,
  },
  {
    name: "Congo (Republic) (Congo-Brazzaville)",
    regions: ["africa"],
    iso2: "cg",
    dialCode: "+242",
    hasAreaCodes: false,
  },
  {
    name: "Cook Islands",
    regions: ["oceania"],
    iso2: "ck",
    dialCode: "+682",
    hasAreaCodes: false,
  },
  {
    name: "Costa Rica",
    regions: ["america", "central-america"],
    iso2: "cr",
    dialCode: "+506",
    hasAreaCodes: false,
  },
  {
    name: "Côte d’Ivoire",
    regions: ["africa"],
    iso2: "ci",
    dialCode: "+225",
    hasAreaCodes: false,
  },
  {
    name: "Croatia (Hrvatska)",
    regions: ["europe", "european-union"],
    iso2: "hr",
    dialCode: "+385",
    hasAreaCodes: false,
  },
  {
    name: "Cuba",
    regions: ["america", "carribean"],
    iso2: "cu",
    dialCode: "+53",
    hasAreaCodes: false,
  },
  {
    name: "Curaçao",
    regions: ["america", "carribean"],
    iso2: "cw",
    dialCode: "+599",
    hasAreaCodes: false,
    isDefaultByDialCode: true,
  },
  {
    name: "Cyprus (Κύπρος)",
    regions: ["europe", "european-union"],
    iso2: "cy",
    dialCode: "+357",
    hasAreaCodes: false,
  },
  {
    name: "Czech Republic (Česká republika)",
    regions: ["europe", "european-union"],
    iso2: "cz",
    dialCode: "+420",
    hasAreaCodes: false,
  },
  {
    name: "Denmark (Danmark)",
    regions: ["europe", "european-union"],
    iso2: "dk",
    dialCode: "+45",
    hasAreaCodes: false,
  },
  {
    name: "Djibouti",
    regions: ["africa"],
    iso2: "dj",
    dialCode: "+253",
    hasAreaCodes: false,
  },
  {
    name: "Dominica",
    regions: ["america", "carribean"],
    iso2: "dm",
    dialCode: "+1767",
    hasAreaCodes: false,
  },
  {
    name: "Dominican Republic (República Dominicana)",
    regions: ["america", "carribean"],
    iso2: "do",
    dialCode: "+1",
    hasAreaCodes: true,
  },
  {
    name: "Dominican Republic (República Dominicana)",
    regions: ["america", "carribean"],
    iso2: "do",
    dialCode: "+1809",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Dominican Republic (República Dominicana)",
    regions: ["america", "carribean"],
    iso2: "do",
    dialCode: "+1829",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Dominican Republic (República Dominicana)",
    regions: ["america", "carribean"],
    iso2: "do",
    dialCode: "+1849",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Ecuador",
    regions: ["america", "south-america"],
    iso2: "ec",
    dialCode: "+593",
    hasAreaCodes: false,
  },
  {
    name: "Egypt (‫مصر‬‎)",
    regions: ["africa", "north-africa"],
    iso2: "eg",
    dialCode: "+20",
    hasAreaCodes: false,
  },
  {
    name: "El Salvador",
    regions: ["america", "central-america"],
    iso2: "sv",
    dialCode: "+503",
    hasAreaCodes: false,
  },
  {
    name: "Equatorial Guinea (Guinea Ecuatorial)",
    regions: ["africa"],
    iso2: "gq",
    dialCode: "+240",
    hasAreaCodes: false,
  },
  {
    name: "Eritrea",
    regions: ["africa"],
    iso2: "er",
    dialCode: "+291",
    hasAreaCodes: false,
  },
  {
    name: "Estonia (Eesti)",
    regions: ["europe", "european-union", "ex-ussr"],
    iso2: "ee",
    dialCode: "+372",
    hasAreaCodes: false,
  },
  {
    name: "Ethiopia",
    regions: ["africa"],
    iso2: "et",
    dialCode: "+251",
    hasAreaCodes: false,
  },
  {
    name: "Falkland Islands (Islas Malvinas)",
    regions: ["america", "south-america"],
    iso2: "fk",
    dialCode: "+500",
    hasAreaCodes: false,
  },
  {
    name: "Faroe Islands (Føroyar)",
    regions: ["europe"],
    iso2: "fo",
    dialCode: "+298",
    hasAreaCodes: false,
  },
  {
    name: "Fiji",
    regions: ["oceania"],
    iso2: "fj",
    dialCode: "+679",
    hasAreaCodes: false,
  },
  {
    name: "Finland (Suomi)",
    regions: ["europe", "european-union"],
    iso2: "fi",
    dialCode: "+358",
    hasAreaCodes: false,
  },
  {
    name: "France",
    regions: ["europe", "european-union"],
    iso2: "fr",
    dialCode: "+33",
    hasAreaCodes: false,
  },
  {
    name: "French Guiana (Guyane française)",
    regions: ["america", "south-america"],
    iso2: "gf",
    dialCode: "+594",
    hasAreaCodes: false,
  },
  {
    name: "French Polynesia (Polynésie française)",
    regions: ["oceania"],
    iso2: "pf",
    dialCode: "+689",
    hasAreaCodes: false,
  },
  {
    name: "Gabon",
    regions: ["africa"],
    iso2: "ga",
    dialCode: "+241",
    hasAreaCodes: false,
  },
  {
    name: "Gambia",
    regions: ["africa"],
    iso2: "gm",
    dialCode: "+220",
    hasAreaCodes: false,
  },
  {
    name: "Georgia (საქართველო)",
    regions: ["asia", "ex-ussr"],
    iso2: "ge",
    dialCode: "+995",
    hasAreaCodes: false,
  },
  {
    name: "Germany (Deutschland)",
    regions: ["europe", "european-union"],
    iso2: "de",
    dialCode: "+49",
    hasAreaCodes: false,
  },
  {
    name: "Ghana (Gaana)",
    regions: ["africa"],
    iso2: "gh",
    dialCode: "+233",
    hasAreaCodes: false,
  },
  {
    name: "Gibraltar",
    regions: ["europe"],
    iso2: "gi",
    dialCode: "+350",
    hasAreaCodes: false,
  },
  {
    name: "Greece (Ελλάδα)",
    regions: ["europe", "european-union"],
    iso2: "gr",
    dialCode: "+30",
    hasAreaCodes: false,
  },
  {
    name: "Greenland (Kalaallit Nunaat)",
    regions: ["america"],
    iso2: "gl",
    dialCode: "+299",
    hasAreaCodes: false,
  },
  {
    name: "Grenada",
    regions: ["america", "carribean"],
    iso2: "gd",
    dialCode: "+1473",
    hasAreaCodes: false,
  },
  {
    name: "Guadeloupe",
    regions: ["america", "carribean"],
    iso2: "gp",
    dialCode: "+590",
    hasAreaCodes: false,
    isDefaultByDialCode: true,
  },
  {
    name: "Guam",
    regions: ["oceania"],
    iso2: "gu",
    dialCode: "+1671",
    hasAreaCodes: false,
  },
  {
    name: "Guatemala",
    regions: ["america", "central-america"],
    iso2: "gt",
    dialCode: "+502",
    hasAreaCodes: false,
  },
  {
    name: "Guinea (Guinée)",
    regions: ["africa"],
    iso2: "gn",
    dialCode: "+224",
    hasAreaCodes: false,
  },
  {
    name: "Guinea-Bissau (Guiné Bissau)",
    regions: ["africa"],
    iso2: "gw",
    dialCode: "+245",
    hasAreaCodes: false,
  },
  {
    name: "Guyana",
    regions: ["america", "south-america"],
    iso2: "gy",
    dialCode: "+592",
    hasAreaCodes: false,
  },
  {
    name: "Haiti",
    regions: ["america", "carribean"],
    iso2: "ht",
    dialCode: "+509",
    hasAreaCodes: false,
  },
  {
    name: "Honduras",
    regions: ["america", "central-america"],
    iso2: "hn",
    dialCode: "+504",
    hasAreaCodes: false,
  },
  {
    name: "Hong Kong (香港)",
    regions: ["asia"],
    iso2: "hk",
    dialCode: "+852",
    hasAreaCodes: false,
  },
  {
    name: "Hungary (Magyarország)",
    regions: ["europe", "european-union"],
    iso2: "hu",
    dialCode: "+36",
    hasAreaCodes: false,
  },
  {
    name: "Iceland (Ísland)",
    regions: ["europe"],
    iso2: "is",
    dialCode: "+354",
    hasAreaCodes: false,
  },
  {
    name: "India (भारत)",
    regions: ["asia"],
    iso2: "in",
    dialCode: "+91",
    hasAreaCodes: false,
  },
  {
    name: "Indonesia",
    regions: ["asia"],
    iso2: "id",
    dialCode: "+62",
    hasAreaCodes: false,
  },
  {
    name: "Iran (‫ایران‬‎)",
    regions: ["middle-east"],
    iso2: "ir",
    dialCode: "+98",
    hasAreaCodes: false,
  },
  {
    name: "Iraq (‫العراق‬‎)",
    regions: ["middle-east"],
    iso2: "iq",
    dialCode: "+964",
    hasAreaCodes: false,
  },
  {
    name: "Ireland",
    regions: ["europe", "european-union"],
    iso2: "ie",
    dialCode: "+353",
    hasAreaCodes: false,
  },
  {
    name: "Israel (‫ישראל‬‎)",
    regions: ["middle-east"],
    iso2: "il",
    dialCode: "+972",
    hasAreaCodes: false,
  },
  {
    name: "Italy (Italia)",
    regions: ["europe", "european-union"],
    iso2: "it",
    dialCode: "+39",
    hasAreaCodes: false,
    isDefaultByDialCode: true,
  },
  {
    name: "Jamaica",
    regions: ["america", "carribean"],
    iso2: "jm",
    dialCode: "+1876",
    hasAreaCodes: false,
  },
  {
    name: "Japan (日本)",
    regions: ["asia"],
    iso2: "jp",
    dialCode: "+81",
    hasAreaCodes: false,
  },
  {
    name: "Jordan (‫الأردن‬‎)",
    regions: ["middle-east"],
    iso2: "jo",
    dialCode: "+962",
    hasAreaCodes: false,
  },
  {
    name: "Kazakhstan (Казахстан)",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    dialCode: "+7",
    hasAreaCodes: true,
  },
  {
    name: "Kazakhstan (Казахстан)",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    dialCode: "+7313",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Kazakhstan (Казахстан)",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    dialCode: "+7327",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Kazakhstan (Казахстан)",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    dialCode: "+77172",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Kazakhstan (Казахстан)",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    dialCode: "+7312",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Kazakhstan (Казахстан)",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    dialCode: "+773622",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Kazakhstan (Казахстан)",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    dialCode: "+7321",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Kazakhstan (Казахстан)",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    dialCode: "+7324",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Kazakhstan (Казахстан)",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    dialCode: "+7336",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Kazakhstan (Казахстан)",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    dialCode: "+7318",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Kazakhstan (Казахстан)",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    dialCode: "+7315",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Kazakhstan (Казахстан)",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    dialCode: "+7325",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Kazakhstan (Казахстан)",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    dialCode: "+7311",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Kazakhstan (Казахстан)",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    dialCode: "+7326",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Kazakhstan (Казахстан)",
    regions: ["asia", "ex-ussr"],
    iso2: "kz",
    dialCode: "+7310",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Kenya",
    regions: ["africa"],
    iso2: "ke",
    dialCode: "+254",
    hasAreaCodes: false,
  },
  {
    name: "Kiribati",
    regions: ["oceania"],
    iso2: "ki",
    dialCode: "+686",
    hasAreaCodes: false,
  },
  {
    name: "Kuwait (‫الكويت‬‎)",
    regions: ["middle-east"],
    iso2: "kw",
    dialCode: "+965",
    hasAreaCodes: false,
  },
  {
    name: "Kyrgyzstan (Кыргызстан)",
    regions: ["asia", "ex-ussr"],
    iso2: "kg",
    dialCode: "+996",
    hasAreaCodes: false,
  },
  {
    name: "Laos (ລາວ)",
    regions: ["asia"],
    iso2: "la",
    dialCode: "+856",
    hasAreaCodes: false,
  },
  {
    name: "Latvia (Latvija)",
    regions: ["europe", "european-union", "ex-ussr"],
    iso2: "lv",
    dialCode: "+371",
    hasAreaCodes: false,
  },
  {
    name: "Lebanon (‫لبنان‬‎)",
    regions: ["middle-east"],
    iso2: "lb",
    dialCode: "+961",
    hasAreaCodes: false,
  },
  {
    name: "Lesotho",
    regions: ["africa"],
    iso2: "ls",
    dialCode: "+266",
    hasAreaCodes: false,
  },
  {
    name: "Liberia",
    regions: ["africa"],
    iso2: "lr",
    dialCode: "+231",
    hasAreaCodes: false,
  },
  {
    name: "Libya (‫ليبيا‬‎)",
    regions: ["africa", "north-africa"],
    iso2: "ly",
    dialCode: "+218",
    hasAreaCodes: false,
  },
  {
    name: "Liechtenstein",
    regions: ["europe"],
    iso2: "li",
    dialCode: "+423",
    hasAreaCodes: false,
  },
  {
    name: "Lithuania (Lietuva)",
    regions: ["europe", "european-union", "ex-ussr"],
    iso2: "lt",
    dialCode: "+370",
    hasAreaCodes: false,
  },
  {
    name: "Luxembourg",
    regions: ["europe", "european-union"],
    iso2: "lu",
    dialCode: "+352",
    hasAreaCodes: false,
  },
  {
    name: "Macau (澳門)",
    regions: ["asia"],
    iso2: "mo",
    dialCode: "+853",
    hasAreaCodes: false,
  },
  {
    name: "Macedonia (FYROM) (Македонија)",
    regions: ["europe"],
    iso2: "mk",
    dialCode: "+389",

    hasAreaCodes: false,
  },
  {
    name: "Madagascar (Madagasikara)",
    regions: ["africa"],
    iso2: "mg",
    dialCode: "+261",
    hasAreaCodes: false,
  },
  {
    name: "Malawi",
    regions: ["africa"],
    iso2: "mw",
    dialCode: "+265",
    hasAreaCodes: false,
  },
  {
    name: "Malaysia",
    regions: ["asia"],
    iso2: "my",
    dialCode: "+60",
    hasAreaCodes: false,
  },
  {
    name: "Maldives",
    regions: ["asia"],
    iso2: "mv",
    dialCode: "+960",
    hasAreaCodes: false,
  },
  {
    name: "Mali",
    regions: ["africa"],
    iso2: "ml",
    dialCode: "+223",
    hasAreaCodes: false,
  },
  {
    name: "Malta",
    regions: ["europe", "european-union"],
    iso2: "mt",
    dialCode: "+356",
    hasAreaCodes: false,
  },
  {
    name: "Marshall Islands",
    regions: ["oceania"],
    iso2: "mh",
    dialCode: "+692",
    hasAreaCodes: false,
  },
  {
    name: "Martinique",
    regions: ["america", "carribean"],
    iso2: "mq",
    dialCode: "+596",
    hasAreaCodes: false,
  },
  {
    name: "Mauritania (‫موريتانيا‬‎)",
    regions: ["africa"],
    iso2: "mr",
    dialCode: "+222",
    hasAreaCodes: false,
  },
  {
    name: "Mauritius (Moris)",
    regions: ["africa"],
    iso2: "mu",
    dialCode: "+230",
    hasAreaCodes: false,
  },
  {
    name: "Mexico (México)",
    regions: ["america", "central-america"],
    iso2: "mx",
    dialCode: "+52",
    hasAreaCodes: false,
  },
  {
    name: "Micronesia",
    regions: ["oceania"],
    iso2: "fm",
    dialCode: "+691",
    hasAreaCodes: false,
  },
  {
    name: "Moldova (Republica Moldova)",
    regions: ["europe"],
    iso2: "md",
    dialCode: "+373",
    hasAreaCodes: false,
  },
  {
    name: "Monaco",
    regions: ["europe"],
    iso2: "mc",
    dialCode: "+377",
    hasAreaCodes: false,
  },
  {
    name: "Mongolia (Монгол)",
    regions: ["asia"],
    iso2: "mn",
    dialCode: "+976",
    hasAreaCodes: false,
  },
  {
    name: "Montenegro (Crna Gora)",
    regions: ["europe"],
    iso2: "me",
    dialCode: "+382",
    hasAreaCodes: false,
  },
  {
    name: "Montserrat",
    regions: ["america", "carribean"],
    iso2: "ms",
    dialCode: "+1664",
    hasAreaCodes: false,
  },
  {
    name: "Morocco (‫المغرب‬‎)",
    regions: ["africa", "north-africa"],
    iso2: "ma",
    dialCode: "+212",
    hasAreaCodes: false,
  },
  {
    name: "Mozambique (Moçambique)",
    regions: ["africa"],
    iso2: "mz",
    dialCode: "+258",
    hasAreaCodes: false,
  },
  {
    name: "Myanmar (Burma) (မြန်မာ)",
    regions: ["asia"],
    iso2: "mm",
    dialCode: "+95",
    hasAreaCodes: false,
  },
  {
    name: "Namibia (Namibië)",
    regions: ["africa"],
    iso2: "na",
    dialCode: "+264",
    hasAreaCodes: false,
  },
  {
    name: "Nauru",
    regions: ["africa"],
    iso2: "nr",
    dialCode: "+674",
    hasAreaCodes: false,
  },
  {
    name: "Nepal (नेपाल)",
    regions: ["asia"],
    iso2: "np",
    dialCode: "+977",
    hasAreaCodes: false,
  },
  {
    name: "Netherlands (Nederland)",
    regions: ["europe", "european-union"],
    iso2: "nl",
    dialCode: "+31",
    hasAreaCodes: false,
  },
  {
    name: "New Caledonia (Nouvelle-Calédonie)",
    regions: ["oceania"],
    iso2: "nc",
    dialCode: "+687",
    hasAreaCodes: false,
  },
  {
    name: "New Zealand",
    regions: ["oceania"],
    iso2: "nz",
    dialCode: "+64",
    hasAreaCodes: false,
  },
  {
    name: "Nicaragua",
    regions: ["america", "central-america"],
    iso2: "ni",
    dialCode: "+505",
    hasAreaCodes: false,
  },
  {
    name: "Niger (Nijar)",
    regions: ["africa"],
    iso2: "ne",
    dialCode: "+227",
    hasAreaCodes: false,
  },
  {
    name: "Nigeria",
    regions: ["africa"],
    iso2: "ng",
    dialCode: "+234",
    hasAreaCodes: false,
  },
  {
    name: "Niue",
    regions: ["asia"],
    iso2: "nu",
    dialCode: "+683",
    hasAreaCodes: false,
  },
  {
    name: "Norfolk Island",
    regions: ["oceania"],
    iso2: "nf",
    dialCode: "+672",
    hasAreaCodes: false,
  },
  {
    name: "North Korea (조선 민주주의 인민 공화국)",
    regions: ["asia"],
    iso2: "kp",
    dialCode: "+850",
    hasAreaCodes: false,
  },
  {
    name: "Northern Mariana Islands",
    regions: ["oceania"],
    iso2: "mp",
    dialCode: "+1670",
    hasAreaCodes: false,
  },
  {
    name: "Norway (Norge)",
    regions: ["europe"],
    iso2: "no",
    dialCode: "+47",
    hasAreaCodes: false,
  },
  {
    name: "Oman (‫عُمان‬‎)",
    regions: ["middle-east"],
    iso2: "om",
    dialCode: "+968",
    hasAreaCodes: false,
  },
  {
    name: "Pakistan (‫پاکستان‬‎)",
    regions: ["asia"],
    iso2: "pk",
    dialCode: "+92",
    hasAreaCodes: false,
  },
  {
    name: "Palau",
    regions: ["oceania"],
    iso2: "pw",
    dialCode: "+680",
    hasAreaCodes: false,
  },
  {
    name: "Palestine (‫فلسطين‬‎)",
    regions: ["middle-east"],
    iso2: "ps",
    dialCode: "+970",
    hasAreaCodes: false,
  },
  {
    name: "Panama (Panamá)",
    regions: ["america", "central-america"],
    iso2: "pa",
    dialCode: "+507",
    hasAreaCodes: false,
  },
  {
    name: "Papua New Guinea",
    regions: ["oceania"],
    iso2: "pg",
    dialCode: "+675",

    hasAreaCodes: false,
  },
  {
    name: "Paraguay",
    regions: ["america", "south-america"],
    iso2: "py",
    dialCode: "+595",
    hasAreaCodes: false,
  },
  {
    name: "Peru (Perú)",
    regions: ["america", "south-america"],
    iso2: "pe",
    dialCode: "+51",
    hasAreaCodes: false,
  },
  {
    name: "Philippines",
    regions: ["asia"],
    iso2: "ph",
    dialCode: "+63",
    hasAreaCodes: false,
  },
  {
    name: "Poland (Polska)",
    regions: ["europe", "european-union"],
    iso2: "pl",
    dialCode: "+48",
    hasAreaCodes: false,
  },
  {
    name: "Portugal",
    regions: ["europe", "european-union"],
    iso2: "pt",
    dialCode: "+351",
    hasAreaCodes: false,
  },
  {
    name: "Puerto Rico",
    regions: ["america", "carribean"],
    iso2: "pr",
    dialCode: "+1",
    hasAreaCodes: true,
  },
  {
    name: "Puerto Rico",
    regions: ["america", "carribean"],
    iso2: "pr",
    dialCode: "+1787",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Puerto Rico",
    regions: ["america", "carribean"],
    iso2: "pr",
    dialCode: "+1939",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Qatar (‫قطر‬‎)",
    regions: ["middle-east"],
    iso2: "qa",
    dialCode: "+974",
    hasAreaCodes: false,
  },
  {
    name: "Réunion (La Réunion)",
    regions: ["africa"],
    iso2: "re",
    dialCode: "+262",
    hasAreaCodes: false,
  },
  {
    name: "Romania (România)",
    regions: ["europe", "european-union"],
    iso2: "ro",
    dialCode: "+40",
    hasAreaCodes: false,
  },
  {
    name: "Russia (Россия)",
    regions: ["europe", "asia", "ex-ussr"],
    iso2: "ru",
    dialCode: "+7",
    hasAreaCodes: false,
    isDefaultByDialCode: true,
  },
  {
    name: "Rwanda",
    regions: ["africa"],
    iso2: "rw",
    dialCode: "+250",
    hasAreaCodes: false,
  },
  {
    name: "Saint Barthélemy (Saint-Barthélemy)",
    regions: ["america", "carribean"],
    iso2: "bl",
    dialCode: "+590",
    hasAreaCodes: false,
  },
  {
    name: "Saint Helena",
    regions: ["africa"],
    iso2: "sh",
    dialCode: "+290",
    hasAreaCodes: false,
  },
  {
    name: "Saint Kitts and Nevis",
    regions: ["america", "carribean"],
    iso2: "kn",
    dialCode: "+1869",
    hasAreaCodes: false,
  },
  {
    name: "Saint Lucia",
    regions: ["america", "carribean"],
    iso2: "lc",
    dialCode: "+1758",
    hasAreaCodes: false,
  },
  {
    name: "Saint Martin (Saint-Martin (partie française))",
    regions: ["america", "carribean"],
    iso2: "mf",
    dialCode: "+590",
    hasAreaCodes: false,
  },
  {
    name: "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)",
    regions: ["america", "north-america"],
    iso2: "pm",
    dialCode: "+508",
    hasAreaCodes: false,
  },
  {
    name: "Saint Vincent and the Grenadines",
    regions: ["america", "carribean"],
    iso2: "vc",
    dialCode: "+1784",
    hasAreaCodes: false,
  },
  {
    name: "Samoa",
    regions: ["oceania"],
    iso2: "ws",
    dialCode: "+685",
    hasAreaCodes: false,
  },
  {
    name: "San Marino",
    regions: ["europe"],
    iso2: "sm",
    dialCode: "+378",
    hasAreaCodes: false,
  },
  {
    name: "São Tomé and Príncipe (São Tomé e Príncipe)",
    regions: ["africa"],
    iso2: "st",
    dialCode: "+239",
    hasAreaCodes: false,
  },
  {
    name: "Saudi Arabia (‫المملكة العربية السعودية‬‎)",
    regions: ["middle-east"],
    iso2: "sa",
    dialCode: "+966",
    hasAreaCodes: false,
  },
  {
    name: "Senegal (Sénégal)",
    regions: ["africa"],
    iso2: "sn",
    dialCode: "+221",
    hasAreaCodes: false,
  },
  {
    name: "Serbia (Србија)",
    regions: ["europe"],
    iso2: "rs",
    dialCode: "+381",
    hasAreaCodes: false,
  },
  {
    name: "Seychelles",
    regions: ["africa"],
    iso2: "sc",
    dialCode: "+248",
    hasAreaCodes: false,
  },
  {
    name: "Sierra Leone",
    regions: ["africa"],
    iso2: "sl",
    dialCode: "+232",
    hasAreaCodes: false,
  },
  {
    name: "Singapore",
    regions: ["asia"],
    iso2: "sg",
    dialCode: "+65",
    hasAreaCodes: false,
  },
  {
    name: "Sint Maarten",
    regions: ["america", "carribean"],
    iso2: "sx",
    dialCode: "+1721",
    hasAreaCodes: false,
  },
  {
    name: "Slovakia (Slovensko)",
    regions: ["europe", "european-union"],
    iso2: "sk",
    dialCode: "+421",
    hasAreaCodes: false,
  },
  {
    name: "Slovenia (Slovenija)",
    regions: ["europe", "european-union"],
    iso2: "si",
    dialCode: "+386",
    hasAreaCodes: false,
  },
  {
    name: "Solomon Islands",
    regions: ["oceania"],
    iso2: "sb",
    dialCode: "+677",
    hasAreaCodes: false,
  },
  {
    name: "Somalia (Soomaaliya)",
    regions: ["africa"],
    iso2: "so",
    dialCode: "+252",
    hasAreaCodes: false,
  },
  {
    name: "South Africa",
    regions: ["africa"],
    iso2: "za",
    dialCode: "+27",
    hasAreaCodes: false,
  },
  {
    name: "South Korea (대한민국)",
    regions: ["asia"],
    iso2: "kr",
    dialCode: "+82",
    hasAreaCodes: false,
  },
  {
    name: "South Sudan (‫جنوب السودان‬‎)",
    regions: ["africa", "north-africa"],
    iso2: "ss",
    dialCode: "+211",
    hasAreaCodes: false,
  },
  {
    name: "Spain (España)",
    regions: ["europe", "european-union"],
    iso2: "es",
    dialCode: "+34",
    hasAreaCodes: false,
  },
  {
    name: "Sri Lanka (ශ්‍රී ලංකාව)",
    regions: ["asia"],
    iso2: "lk",
    dialCode: "+94",
    hasAreaCodes: false,
  },
  {
    name: "Sudan (‫السودان‬‎)",
    regions: ["africa"],
    iso2: "sd",
    dialCode: "+249",
    hasAreaCodes: false,
  },
  {
    name: "Suriname",
    regions: ["america", "south-america"],
    iso2: "sr",
    dialCode: "+597",
    hasAreaCodes: false,
  },
  {
    name: "Swaziland",
    regions: ["africa"],
    iso2: "sz",
    dialCode: "+268",
    hasAreaCodes: false,
  },
  {
    name: "Sweden (Sverige)",
    regions: ["europe", "european-union"],
    iso2: "se",
    dialCode: "+46",
    hasAreaCodes: false,
  },
  {
    name: "Switzerland (Schweiz)",
    regions: ["europe"],
    iso2: "ch",
    dialCode: "+41",
    hasAreaCodes: false,
  },
  {
    name: "Syria (‫سوريا‬‎)",
    regions: ["middle-east"],
    iso2: "sy",
    dialCode: "+963",
    hasAreaCodes: false,
  },
  {
    name: "Taiwan (台灣)",
    regions: ["asia"],
    iso2: "tw",
    dialCode: "+886",
    hasAreaCodes: false,
  },
  {
    name: "Tajikistan",
    regions: ["asia", "ex-ussr"],
    iso2: "tj",
    dialCode: "+992",
    hasAreaCodes: false,
  },
  {
    name: "Tanzania",
    regions: ["africa"],
    iso2: "tz",
    dialCode: "+255",
    hasAreaCodes: false,
  },
  {
    name: "Thailand (ไทย)",
    regions: ["asia"],
    iso2: "th",
    dialCode: "+66",
    hasAreaCodes: false,
  },
  {
    name: "Timor-Leste",
    regions: ["asia"],
    iso2: "tl",
    dialCode: "+670",
    hasAreaCodes: false,
  },
  {
    name: "Togo",
    regions: ["africa"],
    iso2: "tg",
    dialCode: "+228",
    hasAreaCodes: false,
  },
  {
    name: "Tokelau",
    regions: ["oceania"],
    iso2: "tk",
    dialCode: "+690",
    hasAreaCodes: false,
  },
  {
    name: "Tonga",
    regions: ["oceania"],
    iso2: "to",
    dialCode: "+676",
    hasAreaCodes: false,
  },
  {
    name: "Trinidad and Tobago",
    regions: ["america", "carribean"],
    iso2: "tt",
    dialCode: "+1868",
    hasAreaCodes: false,
  },
  {
    name: "Tunisia (‫تونس‬‎)",
    regions: ["africa", "north-africa"],
    iso2: "tn",
    dialCode: "+216",
    hasAreaCodes: false,
  },
  {
    name: "Turkey (Türkiye)",
    regions: ["europe"],
    iso2: "tr",
    dialCode: "+90",
    hasAreaCodes: false,
  },
  {
    name: "Turkmenistan",
    regions: ["asia", "ex-ussr"],
    iso2: "tm",
    dialCode: "+993",
    hasAreaCodes: false,
  },
  {
    name: "Turks and Caicos Islands",
    regions: ["america", "carribean"],
    iso2: "tc",
    dialCode: "+1649",
    hasAreaCodes: false,
  },
  {
    name: "Tuvalu",
    regions: ["asia"],
    iso2: "tv",
    dialCode: "+688",
    hasAreaCodes: false,
  },
  {
    name: "U.S. Virgin Islands",
    regions: ["america", "carribean"],
    iso2: "vi",
    dialCode: "+1340",
    hasAreaCodes: false,
  },
  {
    name: "Uganda",
    regions: ["africa"],
    iso2: "ug",
    dialCode: "+256",
    hasAreaCodes: false,
  },
  {
    name: "Ukraine",
    regions: ["europe", "ex-ussr"],
    iso2: "ua",
    dialCode: "+380",
    hasAreaCodes: false,
  },
  {
    name: "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
    regions: ["middle-east"],
    iso2: "ae",
    dialCode: "+971",
    hasAreaCodes: false,
  },
  {
    name: "United Kingdom",
    regions: ["europe", "european-union"],
    iso2: "gb",
    dialCode: "+44",
    hasAreaCodes: false,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1",
    hasAreaCodes: true,
    isDefaultByDialCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1907",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1205",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1251",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1256",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1334",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1479",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1501",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1870",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1480",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1520",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1602",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1623",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1928",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1209",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1213",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1310",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1323",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1408",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1415",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1510",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1530",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1559",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1562",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1619",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1626",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1650",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1661",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1707",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1714",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1760",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1805",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1818",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1831",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1858",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1909",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1916",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1925",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1949",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1951",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1303",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1719",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1970",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1203",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1860",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1202",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1302",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1239",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1305",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1321",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1352",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1386",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1407",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1561",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1727",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1772",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1813",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1850",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1863",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1904",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1941",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1954",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1229",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1404",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1478",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1706",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1770",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1912",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1808",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1319",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1515",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1563",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1641",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1712",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1208",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1217",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1309",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1312",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1618",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1630",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1708",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1773",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1815",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1847",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1219",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1260",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1317",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1574",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1765",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1812",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1316",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1620",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1785",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1913",

    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1270",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1502",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1606",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1859",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1225",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1318",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1337",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1504",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1985",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1413",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1508",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1617",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1781",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1978",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1301",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1410",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1207",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1231",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1248",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1269",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1313",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1517",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1586",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1616",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1734",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1810",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1906",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1989",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1218",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1320",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1507",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1612",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1651",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1763",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1952",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1314",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1417",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1573",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1636",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1660",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1816",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1228",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1601",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1662",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1406",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1252",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1336",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1704",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1828",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1910",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1919",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1701",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1308",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1402",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1603",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1201",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1609",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1732",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1856",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1908",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1973",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1505",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1575",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1702",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1775",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1212",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1315",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1516",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1518",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1585",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1607",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1631",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1716",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1718",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1845",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1914",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1216",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1330",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1419",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1440",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1513",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1614",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1740",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1937",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1405",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1580",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1918",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1503",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1541",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1215",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1412",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1570",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1610",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1717",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1724",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1814",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1401",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1803",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1843",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1864",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1605",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1423",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1615",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1731",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1865",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1901",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1931",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1210",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1214",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1254",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1281",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1325",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1361",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1409",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1432",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1512",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1713",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1806",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1817",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1830",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1903",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1915",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1936",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1940",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1956",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1972",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1979",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1435",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1801",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1276",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1434",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1540",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1703",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1757",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1804",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1802",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1206",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1253",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1360",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1425",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1509",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1262",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1414",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1608",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1715",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1920",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1304",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "United States",
    regions: ["america", "north-america"],
    iso2: "us",
    dialCode: "+1307",
    hasAreaCodes: true,
    isAreaCode: true,
  },
  {
    name: "Uruguay",
    regions: ["america", "south-america"],
    iso2: "uy",
    dialCode: "+598",
    hasAreaCodes: false,
  },
  {
    name: "Uzbekistan (Oʻzbekiston)",
    regions: ["asia", "ex-ussr"],
    iso2: "uz",
    dialCode: "+998",
    hasAreaCodes: false,
  },
  {
    name: "Vanuatu",
    regions: ["oceania"],
    iso2: "vu",
    dialCode: "+678",
    hasAreaCodes: false,
  },
  {
    name: "Vatican City (Città del Vaticano)",
    regions: ["europe"],
    iso2: "va",
    dialCode: "+39",
    hasAreaCodes: false,
  },
  {
    name: "Venezuela",
    regions: ["america", "south-america"],
    iso2: "ve",
    dialCode: "+58",
    hasAreaCodes: false,
  },
  {
    name: "Vietnam (Việt Nam)",
    regions: ["asia"],
    iso2: "vn",
    dialCode: "+84",
    hasAreaCodes: false,
  },
  {
    name: "Wallis and Futuna",
    regions: ["oceania"],
    iso2: "wf",
    dialCode: "+681",
    hasAreaCodes: false,
  },
  {
    name: "Yemen (‫اليمن‬‎)",
    regions: ["middle-east"],
    iso2: "ye",
    dialCode: "+967",
    hasAreaCodes: false,
  },
  {
    name: "Zambia",
    regions: ["africa"],
    iso2: "zm",
    dialCode: "+260",
    hasAreaCodes: false,
  },
  {
    name: "Zimbabwe",
    regions: ["africa"],
    iso2: "zw",
    dialCode: "+263",
    hasAreaCodes: false,
  },
];

export default allCountries;
