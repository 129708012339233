export enum ApiCompanyIndustry {
  accounting = 1,
  administration = 2,
  advertising = 3,
  banking = 4,
  callCentre = 5,
  communityServices = 6,
  construction = 7,
  consulting = 8,
  architecture = 9,
  education = 10,
  engineering = 11,
  farming = 12,
  government = 13,
  healthcare = 14,
  tourism = 15,
  hr = 16,
  it = 17,
  insurance = 18,
  legal = 19,
  logistics = 20,
  marketing = 21,
  energy = 22,
  realEstate = 23,
  retail = 24,
  sales = 25,
  science = 26,
  sport = 27,
  trade = 28,
}

export enum ApiCompanySize {
  micro = 1,
  xsmall = 2,
  small = 3,
  medium = 4,
  large = 5,
  xlarge = 6,
  corporation = 7,
  conglomerate = 8,
}

export enum ApiLanguage {
  arabic = "ar",
  english = "en",
  french = "fr",
  german = "de",
  italian = "it",
  japanese = "ja",
  spanish = "es",
}

export enum ApiUseCase {
  Swot = 1,
  BusinessModelCanvas = 2,
  CostBenefit = 3,
  Brainstorming = 4,
  DesignThinking = 5,
  ProductDesign = 6,
  VisualPresentation = 7,
  UserStorymap = 8,
  CreativityWorkshop = 9,
  Drawing = 10,
  HEVL = 11,
  FlippedClass = 12,
  AgileSprint = 13,
  Events = 14,
  SharedToDo = 15,
  Sitemap = 16,
  MindMap = 17,
  Flowchart = 18,
  Workflow = 19,
  SharedNotes = 20,
  Personas = 21,
  CustomerJourney = 22,
  Blueprint = 23,
  Wireframe = 24,
}

export enum MediaAction {
  start = 1,
  stop = 2,
  pause = 3,
  rewind = 4,
  fullScreenOn = 10,
  fullScreenOff = 11,
}

export enum ApiPermission {
  noPermission = 0,
  readPermission = 1, // Viewer
  readWritePermission = 2, // Editor
  facilitatorPermission = 20, // Facilitator
  ownerPermission = 255, // Owner
}

export enum ApiProjectUnavailableReason {
  needsUpgrading = 1,
  exporting = 2,
}

export enum UIProjectUnavailableReason {
  deleted = -1,
  kickedOut = -2,
  generic = -99,
}

export type ProjectUnavailableReason =
  | ApiProjectUnavailableReason
  | UIProjectUnavailableReason;

export enum Direction {
  left = "left",
  right = "right",
  down = "down",
  up = "up",
}

export enum Anchor {
  left = "left",
  right = "right",
  top = "top",
  bottom = "bottom",
  auto = "auto",
}

export enum AnchorAlgorithm {
  useDefaults = 0,
  /**
   * Linear algorithm processes one pixel at a time.
   * EXPENSIVE but reliable.
   */
  linear = 1,
  /**
   * Binary search is efficient but unreliable. It doesn't work properly
   * for objects with internal transparency.
   */
  binarySearch = 2,
  /**
   * Pre-computed anchor positions negate the need for runtime calculations
   * but they aren't possible for unique shapes such as ink paths and text.
   */
  preComputed = 3,
}

export enum ApiProjectsOrderParameter {
  name = "ProjectName",
  owner = "ProjectOwner",
  lastAccess = "ProjectLastAccessDate",
}

export enum HostingCountry {
  Germany = "Germany",
  Europe = "Europe",
  Sweden = "Sweden",
  Switzerland = "Switzerland",
  UK = "UK",
  USA = "USA",
}

export enum InvitationState {
  error = -1,
  notLoggedIn = 1,
  mismatchedUser = 2,
  rejected = 3,
}

export enum Roles {
  CollaboardManager = "CollaboardManager",
  LicensingManager = "LicensingManager",
  IBVManager = "IBVManager",
  UserManager = "UserManager",
  SysAdmin = "SysAdmin",
}

export enum UISizes {
  Default = "default",
  Medium = "medium",
  Large = "large",
}

export enum RelationChangeAction {
  Add = "add",
  Delete = "delete",
  Modify = "modify",
}

export enum ShapeVersion {
  Shape = "Shape",
  ShapeUnscaledText = "ShapeUnscaledText",
}

export enum FreeFormTextVersion {
  FreeFormText = "FreeFormText",
  FreeFormUnscaledText = "FreeFormUnscaledText",
}

export enum TileContentType {
  TextContent = "TextContent",
  UnscaledTextContent = "UnscaledTextContent",
  ShapeContent = "ShapeContent",
  ImageContent = "ImageContent",
  InkContent = "InkContent",
  YouTubeContent = "YouTubeContent",
  EmbedContent = "EmbedContent",
  UploadedFileContent = "UploadedFileContent",
  ThumbnailContent = "ThumbnailContent",
  ChatContent = "ChatContent",
}

export enum BatchTileType {
  Identity = 0,
  Object = 1,
  Group = 2,
  Stack = 3,
}

export enum ExportType {
  Selection = "selection",
  Project = "project",
}

export enum CanvasExportFormat {
  Image = "image",
  PDF = "pdf",
  Text = "text",
}

export enum ApiObjectLinkType {
  tile = 10,
  url = 20,
  quickLink = 30,
}

export enum CustomCorner {
  rotate = "rotate",
  resize = "resize",

  connectMt = "connect:mt",
  connectMr = "connect:mr",
  connectMb = "connect:mb",
  connectMl = "connect:ml",

  setLinkDestination = "setLinkDestination",
  generateLink = "generateLink",

  voteDown = "voteDown",
  voteUp = "voteUp",
  resetRate = "resetRate",
  rate = "rate",

  enterPlayerMode = "enterPlayerMode",
  videoPosition = "videoPosition",
  mute = "mute",
  fullScreen = "fullScreen",
  pause = "pause",
}

export enum WheelInputAction {
  scaleWithWheel = "scaleWithWheel",
  scaleWithTouchpad = "scaleWithTouchpad",
  pan = "pan",
}

export enum WheelInputMode {
  mouse = "mouse",
  touchpad = "touchpad",
}

export enum HighlightHoveredObjectMode {
  attachmentMode = "attachmentMode",
}

export enum ApiSubscriptionUserPermission {
  none = 0,
  viewer = 1,
  manager = 2,
  owner = 255,
}

export enum ApiAuthenticationMode {
  PASSWORD = 1, // Email + password
  OTP_CODE = 2, // Email + OTP
  TFA = 3, // Email + password + OTP
  GUEST = 4,
}

/**
 * The list of ResourceType is hard-coded in the front-end client as it needs business logic, but
 * in the BackOffice it comes from API to allow the server to set more permissions without a new front-end
 * deployment. In those cases, the user will just receive an Unauthorized error instead of a proper UI
 * disabling the action.
 */
export enum ApiResourceType {
  Space = "Collaboard.Space",
  SpaceParticipants = "Collaboard.Space.Participants",
  SpaceProjects = "Collaboard.Space.Projects",
  Project = "Collaboard.Project",
  ProjectParticipants = "Collaboard.Project.Participants",
}

export enum ApiResourcePermission {
  View = 1,
  Create = 2,
  Update = 3,
  Delete = 4,
  Copy = 5,
  Participate = 6,
}

export enum ApiResourcePermissionMode {
  Allowed = 1,
  Denied = 2,
}

export enum ApiResourcePermissionScope {
  ProjectOwner = "project.owner",
  ProjectParticipant = "project.participant",
  SpaceManager = "space.manager",
  SpaceParticipant = "space.participant",
}
