import { useMemo, ReactElement } from "react";
import { toast, ToastOptions, ToastId } from "react-toastify";
import i18n, { StringMap, TOptions } from "i18next";
import { isString } from "../../../tools/text";

export const staticToastConfig: ToastOptions = {
  autoClose: false,
  closeOnClick: false,
};

export class CollaboardToast {
  private toastId: ToastId = -1;

  /**
   *@NOTE If providing a `toastConfig` value ensure that it is memoized,
   * otherwise you will be unable to dismiss your toast.
   *
   * You can do this by defining it outside of your component for example.
   */
  constructor(
    private messageKey: ReactElement | string,
    private toastConfig?: ToastOptions
  ) {}

  isActive(): boolean {
    return toast.isActive(this.toastId);
  }

  show<T extends StringMap>(translationParams?: TOptions<T> | string): void {
    this.update(this.messageKey, translationParams);
  }

  update<T extends StringMap>(
    newMessageKey: ReactElement | string,
    translationParams?: TOptions<T> | string
  ): void {
    this.messageKey = newMessageKey;
    if (this.isActive()) {
      toast.update(this.toastId, {
        render: isString(newMessageKey)
          ? i18n.t(newMessageKey, translationParams)
          : newMessageKey,
      });
    } else {
      this.toastId = toast(
        isString(newMessageKey)
          ? i18n.t(newMessageKey, translationParams)
          : newMessageKey,
        this.toastConfig
      );
    }
  }

  dismiss(): void {
    this.isActive() && toast.dismiss(this.toastId);
  }
}

export default (
  messageKey: ReactElement | string,
  toastConfig?: ToastOptions
): CollaboardToast => {
  return useMemo(() => new CollaboardToast(messageKey, toastConfig), [
    messageKey,
    toastConfig,
  ]);
};
