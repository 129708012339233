import React, { useState } from "react";
import PropTypes from "prop-types";
import { Flex, Text, Button, Box } from "theme-ui";
import { useTranslation } from "react-i18next";

import Currencies from "../../../icons/Currencies";
import DropdownArrow from "../../../icons/DropdownArrow";

function CurrencySwitcher({ currencies, selected, onChange }) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const close = () => {
    setIsOpen(false);
  };
  const open = () => {
    setIsOpen(true);
  };

  const handleChange = (code) => {
    onChange(code);
    close();
  };

  return (
    <Flex sx={{ alignItems: "center", svg: { height: "40px", width: "40px" } }}>
      <Currencies noShadow />
      <Text>{t("plans.currency")}</Text>
      <Box sx={{ position: "relative", alignSelf: "stretch", width: "65px" }}>
        <Flex
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "40px",
            alignItems: "center",
          }}
        >
          {!isOpen && (
            <>
              <Button
                onClick={open}
                variant="content"
                sx={{ px: [2], py: [1] }}
                name="show-options-current-code"
              >
                {selected.code}
              </Button>
              <Button
                variant="buttons.content"
                onClick={open}
                sx={{ svg: { height: "20px", width: "20px", mt: [1] } }}
                name="show-options-arrow"
              >
                <DropdownArrow noShadow />
              </Button>
            </>
          )}

          {isOpen && (
            <>
              <Flex
                sx={{ flexDirection: "column", outline: "2px solid white" }}
              >
                {currencies.map(({ code }) => (
                  <Button
                    variant="content"
                    key={code}
                    sx={{
                      px: [2],
                      py: [1],
                      bg:
                        selected.code === code ? "accentAlternate" : "primary",
                      color: "background",
                      alignSelf: "stretch",
                      borderRadius: 0,
                      "&:hover": {
                        bg:
                          selected.code === code ? "accentAlternate" : "accent",
                      },
                    }}
                    onClick={() => {
                      handleChange(code);
                    }}
                  >
                    {code}
                  </Button>
                ))}
              </Flex>
              <Button
                variant="buttons.content"
                onClick={close}
                sx={{
                  svg: { height: "20px", width: "20px", mt: [1] },
                }}
              >
                <DropdownArrow noShadow />
              </Button>
            </>
          )}
        </Flex>
      </Box>
    </Flex>
  );
}

CurrencySwitcher.propTypes = {
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      symbol: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    code: PropTypes.string.isRequired,
    symbol: PropTypes.string,
  }).isRequired,
};
CurrencySwitcher.defaultProps = {};

export default CurrencySwitcher;
