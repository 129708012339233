import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function LimitReached(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M18.91,13.1H9.37V23.43h1.09V14h8.16C18.7,13.71,18.8,13.4,18.91,13.1Z" />
      <path d="M18.42,15.73q0-.36,0-.72h-7V26.15h1.09V15.94h5.89C18.43,15.87,18.42,15.8,18.42,15.73Z" />
      <path d="M25.81,23.12a7.4,7.4,0,0,1-7.29-6.19h-5V28.87H28.19V22.72A7.52,7.52,0,0,1,25.81,23.12Z" />
      <path d="M29.77,11.77a5.6,5.6,0,1,0,0,7.92A5.59,5.59,0,0,0,29.77,11.77ZM23,12.9a4,4,0,0,1,5-.51l-5.54,5.53A4,4,0,0,1,23,12.9Zm5.64,5.65a4,4,0,0,1-5,.51l5.54-5.52A4,4,0,0,1,28.63,18.55Z" />
    </Icon>
  );
}
export default LimitReached;
