import { useCallback, useEffect, useMemo } from "react";
import {
  IsFetchingMoreValue,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "react-query";
import { useSelector } from "react-redux";
import {
  changeProjectOwner,
  ChangeProjectOwner,
  changeUserPermission,
  ChangeUserPermission,
  generateZoomSignature,
  getCoParticipatingProjects,
  getCoParticipatingUsers,
  getParticipatingProjects,
  getParticipatingSpaces,
  getQuickLinks,
  getSubscriptionDevices,
  getSubscriptionUsers,
  getUsersByProject,
  getUsersBySpace,
  getVotesPerUser,
  getVotingArchive,
  getVotingSessionDetails,
  ProjectParticipantsConfig,
  sendTogglePauseTimedSession,
  SendTogglePauseTimedSession,
  UserPermissionChange,
} from ".";
import { SubscriptionUserStatuses } from "../const";
import { useProjectListMemo } from "../features/routes/projects/useProjectsMemo";
import {
  combineParticipantInfo,
  combineUserInfo,
} from "../features/users/users.utils";
import { selectAssertedUserProfile } from "../reduxStore/auth/auth.reducer";
import { BingImage, SearchImages, searchImages } from "./Bing";
import { combineResultsOfInfiniteQuery, getFetchMore } from "./helpers";
import { searchVideos, YouTubeVideoData } from "./YouTube";

const useCombinedResults = <T>(
  data: PagedResponse<T>[] | undefined | null
): T[] => useMemo(() => combineResultsOfInfiniteQuery(data), [data]);

// Default mutation error handler used only for infering the Error type
const onError = (_: Error) => {
  // Noop
};

export const useImageSearch = (): {
  images: BingImage[];
  isIdle: boolean;
  isImageSearchRequestPending: boolean;
  imageSearchError: Error | null;
  searchImages: (args: SearchImages) => void;
} => {
  const [
    mutate,
    {
      data: images = [],
      isIdle,
      isLoading: isImageSearchRequestPending,
      error: imageSearchError,
    },
  ] = useMutation(searchImages, { onError });

  return {
    images,
    isIdle,
    isImageSearchRequestPending,
    imageSearchError,
    searchImages: mutate,
  };
};

export const useVideoSearch = (): {
  videos: YouTubeVideoData[];
  isIdle: boolean;
  isVideoSearchRequestPending: boolean;
  videoSearchError: Error | null;
  searchVideos: (arg0: string) => void;
} => {
  const [
    mutate,
    {
      data: videos = [],
      isIdle,
      isLoading: isVideoSearchRequestPending,
      error: videoSearchError,
    },
  ] = useMutation(searchVideos, { onError });

  return {
    videos,
    isIdle,
    isVideoSearchRequestPending,
    videoSearchError,
    searchVideos: mutate,
  };
};

type UseFilteredParticipatingProjectsParam = {
  orderQuery: OrderQuery;
  filterQuery: FilterQuery[];
  permission?: number;
  spaceId: number | null;
  enabled: boolean;
};

export type UseInfiniteQueryResult = {
  isLoading: boolean;
  isDataInitialized?: boolean;
  error: Error | string | null;
  fetchMore: () => void;
  canFetchMore?: boolean;
  isFetching: boolean;
  isFetchingMore?: IsFetchingMoreValue;
  refetch: () => void;
};

export type UseParticipatingProjects = UseInfiniteQueryResult & {
  queryKey: string;
  results: ApiProject[];
  removeFromList: (projectId: string) => void;
  updateList: (newProject: Project) => void;
  updateSpace: (projectId: string, spaceId: number | null) => void;
  updateTags: (projectId: string, newTags: string[]) => void;
};

export const useFilteredParticipatingProjects = ({
  filterQuery,
  orderQuery,
  permission,
  spaceId,
  enabled,
}: UseFilteredParticipatingProjectsParam): UseParticipatingProjects => {
  const fetchFilteredProjects = (key: unknown, pageNumber: number) =>
    getParticipatingProjects({
      pageNumber,
      orderQuery,
      filterQuery,
      permission,
      spaceId,
    });
  const filterKey = filterQuery.map(({ key, value }) => `${key}${value}`);
  const { property, ascending } = orderQuery;
  const queryKey = `q=${filterKey},perm=${
    permission || ""
  },prop=${property},asc=${ascending},spaceId=${spaceId}`;

  const {
    status,
    data,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
    error,
    refetch,
  } = useInfiniteQuery<PagedResponse<ApiProject>, Error>({
    queryKey: `ParticipatingProjects(${queryKey})`,
    queryFn: fetchFilteredProjects,
    config: {
      getFetchMore,
      enabled,
      keepPreviousData: true,
    },
  });

  const {
    projects,
    updateList,
    updateSpace,
    updateTags,
    removeFromList,
  } = useProjectListMemo(data, refetch);

  return {
    results: projects,
    isLoading: status === "loading",
    queryKey,
    error,
    fetchMore,
    canFetchMore,
    isFetching,
    isFetchingMore,
    refetch,
    updateList,
    updateSpace,
    updateTags,
    removeFromList,
  };
};

export const useParticipatingProjects = ({
  enabled,
  spaceId,
  orderQuery,
}: {
  enabled: boolean;
  spaceId: number | null;
  orderQuery?: OrderQuery;
}): UseParticipatingProjects => {
  const fetchProjects = (key: unknown, pageNumber: number) =>
    getParticipatingProjects({
      pageNumber,
      pageSize: undefined,
      orderQuery,
      filterQuery: undefined,
      permission: undefined,
      spaceId,
    });
  const { property = "", ascending = false } = orderQuery || {};
  const queryKey = `prop=${property},asc=${ascending},spaceId=${spaceId}`;

  const {
    status,
    data,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
    error,
    clear,
    refetch,
  } = useInfiniteQuery<PagedResponse<ApiProject>, Error>({
    queryKey: `ParticipatingProjects(${queryKey})`,
    queryFn: fetchProjects,
    config: {
      getFetchMore,
      keepPreviousData: true,
    },
    enabled,
  });

  const {
    projects,
    updateList,
    updateSpace,
    updateTags,
    removeFromList,
  } = useProjectListMemo(data, refetch);

  useEffect(() => {
    return clear; // #5227: clear query cache when changing route
  }, [clear]);

  return {
    results: projects,
    isLoading: status === "loading",
    queryKey,
    error,
    fetchMore,
    canFetchMore,
    isFetching,
    isFetchingMore,
    refetch,
    updateList,
    updateSpace,
    updateTags,
    removeFromList,
  };
};

export const useCoParticipatingProjects = (): {
  Projects: ApiProject[];
  TotalRows: number;
  isProjectListLoading: boolean;
  getCoParticipatingProjects: (UserName: string) => void;
} => {
  const [
    mutate,
    {
      data: { Results = [], TotalRows = 0 } = {},
      isLoading: isProjectListLoading,
    },
  ] = useMutation(getCoParticipatingProjects);

  return {
    Projects: Results ?? [],
    TotalRows,
    isProjectListLoading,
    getCoParticipatingProjects: mutate,
  };
};

export const useCoParticipatingUsers = (): UseInfiniteQueryResult & {
  users: Array<ProjectApiUserInfo>;
} => {
  const userProfile = useSelector(selectAssertedUserProfile);

  const fetchUsers = (key: unknown, page: number) =>
    getCoParticipatingUsers(page);

  const {
    status,
    data,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
    error,
    refetch,
  } = useInfiniteQuery<PagedResponse<ProjectParticipant>, Error>(
    "CoParticipatingUsers",
    fetchUsers,
    {
      getFetchMore,
      // refetch the data on component mount only if at least 15s passed since last fetch
      staleTime: 1000 * 15,
    }
  );

  const allParticipants = useCombinedResults(data);
  const users = useMemo(() => {
    return allParticipants.map((participant, index) =>
      combineUserInfo({
        user: participant.User,
        userProfile,
        index,
      })
    );
  }, [allParticipants, userProfile]);

  return {
    users,
    isLoading: status === "loading",
    error,
    fetchMore,
    canFetchMore,
    isFetching,
    isFetchingMore,
    refetch,
  };
};

export const useParticipatingSpaces = (
  enabled = true
): {
  spaces: Space[];
  isLoading: boolean;
  error: Error | null;
  refetch: () => Promise<PagedResponse<Space> | undefined>;
} => {
  const { status, data, error, refetch } = useQuery<
    PagedResponse<Space>,
    Error
  >("ParticipatingSpaces", getParticipatingSpaces, {
    // refetch the data on component mount only if at least 15s passed since last fetch
    staleTime: 1000 * 15,
    // If enabled is false then a manual fetch call is needed
    enabled,
  });

  return {
    spaces: data?.Results ?? [],
    isLoading: status === "loading",
    error,
    refetch,
  };
};

export const useSpaceUsers = (
  spaceId: number,
  searchTerm?: string
): UseInfiniteQueryResult & {
  users: SpaceUser[];
} => {
  const fetchUsers = (key: unknown, page: number) =>
    getUsersBySpace(spaceId, page, searchTerm);

  const {
    status,
    data,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
    error,
    refetch,
  } = useInfiniteQuery<PagedResponse<SpaceUser>, Error>(
    `GetUsersBySpace(spaceId=${spaceId},searchTerm=${searchTerm})`,
    fetchUsers,
    {
      getFetchMore,
    }
  );

  return {
    users: useCombinedResults(data),
    isLoading: status === "loading",
    error,
    fetchMore,
    canFetchMore,
    isFetching,
    isFetchingMore,
    refetch,
  };
};

export const useQuickLinks = (
  projectId: string,
  searchTerm: string
): UseInfiniteQueryResult & {
  quickLinks: QuickLink[];
} => {
  const fetchQuickLinks = (key: unknown, pageNumber = 1) =>
    getQuickLinks(projectId, pageNumber, searchTerm);

  const {
    status,
    data,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
    error,
    refetch,
    isFetched,
  } = useInfiniteQuery<PagedResponse<QuickLink>, Error>(
    `QuickLinks-${searchTerm}-${projectId}`,
    fetchQuickLinks,
    {
      retry: false,
      getFetchMore,
    }
  );

  return {
    quickLinks: useCombinedResults(data),
    isLoading: status === "loading",
    error,
    fetchMore,
    canFetchMore,
    isFetching,
    isFetchingMore,
    refetch,
    isDataInitialized: isFetched,
  };
};

export const useProjectParticipants = (
  projectId: string,
  config: ProjectParticipantsConfig = {}
): {
  users: ProjectApiParticipantInfo[];
  participantsLoaded: boolean;
  participantsError: Error | null;
  getProjectParticipants: () => void;
} => {
  const userProfile = useSelector(selectAssertedUserProfile);

  const request = useCallback(() => getUsersByProject(projectId, config), [
    config,
    projectId,
  ]);
  const [run, { isSuccess, error, data }] = useMutation(request, { onError });

  const users = useMemo(() => {
    return (data?.Results || []).map((participant, index) =>
      combineParticipantInfo({
        user: participant.User,
        projectPermission: {
          Permission: participant.Permission,
          ParticipationType: participant.ParticipationType,
        },
        userProfile,
        index,
      })
    );
  }, [data, userProfile]);

  return {
    users,
    participantsLoaded: isSuccess,
    participantsError: error,
    getProjectParticipants: run,
  };
};

export const usePermissionUpdate = (
  onSuccess: (permission: UserPermissionChange) => void,
  onError?: (error: Error) => void
): {
  isChangingPermission: boolean;
  changePermission: (args: ChangeUserPermission) => void;
} => {
  const [
    changePermission,
    { isLoading: isChangingPermission },
  ] = useMutation(changeUserPermission, { onSuccess, onError });

  return { isChangingPermission, changePermission };
};

export const useProjectOwner = (
  onSuccess: () => void,
  onError?: (error: Error) => void
): {
  isChangingProjectOwner: boolean;
  changeProjectOwner: (args: ChangeProjectOwner) => void;
} => {
  const [
    mutate,
    { isLoading: isChangingProjectOwner },
  ] = useMutation(changeProjectOwner, { onSuccess, onError });

  return { isChangingProjectOwner, changeProjectOwner: mutate };
};

export const usePauseTimedSession = (): {
  isPauseRequestPending: boolean;
  togglePauseTimedSession: (args: SendTogglePauseTimedSession) => void;
} => {
  const [mutate, { isLoading }] = useMutation(sendTogglePauseTimedSession);
  return { isPauseRequestPending: isLoading, togglePauseTimedSession: mutate };
};

export const useZoomMeeting = (): {
  apiKey?: string;
  token?: string;
  isLoading: boolean;
} => {
  const [mutate, { data, isLoading }] = useMutation(generateZoomSignature);

  useEffect(() => {
    mutate();
  }, [mutate]);

  return {
    apiKey: data?.ApiKey,
    token: data?.Token,
    isLoading,
  };
};

export const useVotingArchive = (
  projectId: string
): UseInfiniteQueryResult & {
  votingArchive: ApiVotingOverview[];
} => {
  const fetchVotingArchive = (key: unknown, pageNumber = 1) =>
    getVotingArchive(projectId, pageNumber);

  const {
    status,
    data,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
    error,
    refetch,
    isFetched,
  } = useInfiniteQuery<PagedResponse<ApiVotingOverview>, Error>(
    `VotingArchive-${projectId}`,
    fetchVotingArchive,
    {
      retry: false,
      getFetchMore,
    }
  );

  return {
    votingArchive: useCombinedResults(data),
    isLoading: status === "loading",
    error,
    fetchMore,
    canFetchMore,
    isFetching,
    isFetchingMore,
    refetch,
    isDataInitialized: isFetched,
  };
};

export const useVotingSessionDetails = (
  projectId: string,
  sessionId: number
): UseInfiniteQueryResult & {
  votingSessionDetails: ApiGetVotingSessionSummary | undefined;
  votingSessionObjects: ApiUserVotesSummary[];
} => {
  const fetchVotingDetails = (key: unknown, pageNumber = 1) =>
    getVotingSessionDetails(projectId, sessionId, pageNumber);

  const {
    status,
    data,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
    error,
    refetch,
    isFetched,
  } = useInfiniteQuery<ApiGetVotingSessionSummary, Error>(
    `VotingDetails-${sessionId}`,
    fetchVotingDetails,
    {
      retry: false,
      getFetchMore,
    }
  );

  return {
    votingSessionDetails: data?.[0],
    votingSessionObjects: useCombinedResults(data),
    isLoading: status === "loading",
    error,
    fetchMore,
    canFetchMore,
    isFetching,
    isFetchingMore,
    refetch,
    isDataInitialized: isFetched,
  };
};

export const useVotesPerUser = (
  projectId: string,
  sessionId: number
): UseInfiniteQueryResult & {
  votesPerUser: VotesPerUser[];
} => {
  const fetchVotesPerUser = (key: unknown, pageNumber = 1) =>
    getVotesPerUser(projectId, sessionId, pageNumber);

  const {
    status,
    data,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
    error,
    refetch,
    isFetched,
  } = useInfiniteQuery<PagedResponse<VotesPerUser>, Error>(
    `VotesPerUser-${sessionId}`,
    fetchVotesPerUser,
    {
      retry: false,
      getFetchMore,
    }
  );

  return {
    votesPerUser: useCombinedResults(data),
    isLoading: status === "loading",
    error,
    fetchMore,
    canFetchMore,
    isFetching,
    isFetchingMore,
    refetch,
    isDataInitialized: isFetched,
  };
};

export const useSubscriptionUsers = (
  userStatus: SubscriptionUserStatuses,
  subscriptionID: number,
  searchTerm?: string
): UseInfiniteQueryResult & {
  data: ApiSubscriptionUser[];
} => {
  const fetchUsers = (key: unknown, pageNumber = 1) =>
    getSubscriptionUsers({
      subscriptionID,
      pageNumber,
      status: userStatus,
      searchTerm,
    });

  const {
    status,
    data,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
    error,
    refetch,
    isFetched,
  } = useInfiniteQuery<PagedResponse<ApiSubscriptionUser>, Error>(
    `SubscriptionUsers-${subscriptionID}-${userStatus}-${searchTerm}`,
    fetchUsers,
    {
      retry: false,
      getFetchMore,
    }
  );

  return {
    data: useCombinedResults(data),
    isLoading: status === "loading",
    error,
    fetchMore,
    canFetchMore,
    isFetching,
    isFetchingMore,
    refetch,
    isDataInitialized: isFetched,
  };
};

export const useSubscriptionDevices = (
  isActive: boolean,
  subscriptionID: number
): UseInfiniteQueryResult & {
  data: ApiSubscriptionDevice[];
} => {
  const fetchDevices = (key: unknown, pageNumber = 1) =>
    getSubscriptionDevices({
      subscriptionID,
      pageNumber,
      isActive,
    });

  const {
    status,
    data,
    isFetching,
    isFetchingMore,
    fetchMore,
    canFetchMore,
    error,
    refetch,
    isFetched,
  } = useInfiniteQuery<PagedResponse<ApiSubscriptionDevice>, Error>(
    `SubscriptionDevices-${subscriptionID}-${isActive}`,
    fetchDevices,
    {
      retry: false,
      getFetchMore,
    }
  );

  return {
    data: useCombinedResults(data),
    isLoading: status === "loading",
    error,
    fetchMore,
    canFetchMore,
    isFetching,
    isFetchingMore,
    refetch,
    isDataInitialized: isFetched,
  };
};
