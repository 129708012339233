import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  createSubscriptionFromVoucher,
  upgradeSubscriptionFromVoucher,
} from "../../../api";
import { errorCodeIds } from "../../../api/errorCodes";
import { RedemptionModalProps } from "./useRedemptionDetailsModalTemplate";
import { ModalProps, useSimpleModal } from "../../shared/modals/useSimpleModal";
import { routes } from "../../../const";
import { useSubscription } from "../../../reduxStore/subscriptionDetails/subscriptionDetails.hook";
import { getAppName } from "../../../tools/customer";
import { errorToast } from "../../../tools/errorToast";
import { isApiErrorWithCode } from "../../../tools/errors";

type DisplayRedemptionDetailsModalParam = { Code: string };

type UseRedemptionDetailsResult = {
  redemptionDetailsModalProps: ModalProps;
  redemptionDetailsData: RedemptionModalProps | null;
  displayRedemptionDetailsModal: (
    param: DisplayRedemptionDetailsModalParam
  ) => Promise<unknown>;
};

export const useRedemptionDetails = (
  label: string
): UseRedemptionDetailsResult => {
  const { t } = useTranslation();
  const [
    redemptionDetailsData,
    setRedemptionDetailsData,
  ] = useState<RedemptionModalProps | null>(null);
  const onClose = useCallback(() => setRedemptionDetailsData(null), []);
  const redemptionDetailsModalProps = useSimpleModal({ onClose });

  const { getSubscription } = useSubscription();
  const history = useHistory<void>();

  const handleRedemptionErrors = (err: {
    message?: string;
    ErrorCode: number;
  }) => {
    if (err.message === `existingSubscription${label}`) {
      errorToast(
        t(`form.error.existingSubscription${label}`, { appName: getAppName() })
      );
    }

    if (err?.ErrorCode === errorCodeIds.InvalidPromoCode) {
      errorToast(
        t(`clientError.invalid${label}Code`, { appName: getAppName() })
      );
    }
  };

  const redeem = async (Code: string, SubscriptionId: number | null = null) => {
    try {
      const action = SubscriptionId
        ? upgradeSubscriptionFromVoucher
        : createSubscriptionFromVoucher;

      await action({ PromoCode: Code, SubscriptionId });
      getSubscription();

      toast(t("plans.enjoySubscription", { appName: getAppName() }));
      history.push(routes.projects);
    } catch (err) {
      handleRedemptionErrors(
        isApiErrorWithCode(err) ? err : { ErrorCode: errorCodeIds.GenericError }
      );
    }
  };

  const displayRedemptionDetailsModal = ({
    Code,
  }: DisplayRedemptionDetailsModalParam) =>
    new Promise<void>((res) => {
      setRedemptionDetailsData({
        onConfirm: () => {
          setRedemptionDetailsData(null);
          res();
        },
        onClose: () => {
          setRedemptionDetailsData(null);
          res();
          redemptionDetailsModalProps.onClose();
        },
        code: Code,
        redeem,
      });
      redemptionDetailsModalProps.open();
    });

  return {
    redemptionDetailsModalProps,
    redemptionDetailsData,
    displayRedemptionDetailsModal,
  };
};
