import {
  calcTransformState,
  isInActiveSelection,
} from "../../../utils/fabricObjects";

export type SerializedObject = {
  type: string;
  uuid: string;
  originUuid: string;
  top: number;
  left: number;
  width: number;
  height: number;
  angle?: number;
  scaleX?: number;
  scaleY?: number;

  /**
   * Add a unique property so that fabric.Object is not assignable to SerializedObject since the
   * former is an object with prototype, while the latter is just a plain object. Operations like spreading
   * are not safe with fabric.Objects, whereas they are for SerializedObject.
   */
  __serialized: true;
};

export const toCopyPlaceholder = (
  object: SerializedObject
): CopyPlaceholder => {
  const {
    width,
    height,
    angle = 0,
    scaleX = 1,
    scaleY = 1,
    top = 0,
    left = 0,
  } = object;

  return {
    x: left,
    y: top,
    width: width * scaleX,
    height: height * scaleY,
    angle,
  };
};

export const toSerializedObject = (object: fabric.Object): SerializedObject => {
  const transform = calcTransformState(object);
  return {
    type: object.type,
    uuid: object.uuid,
    originUuid: object.originUuid,
    // save position relative to active selection
    top: transform.top,
    left: transform.left,
    width: object.width,
    height: object.height,
    angle: object.angle,
    scaleX: object.scaleX,
    scaleY: object.scaleY,

    __serialized: true,
  };
};

export const toSerializedCopyObject = (
  object: fabric.Object
): SerializedObject => {
  const isMultiselection = isInActiveSelection(object);

  return {
    ...toSerializedObject(object),

    // save position relative to active selection
    top: isMultiselection ? object.top : 0,
    left: isMultiselection ? object.left : 0,
  };
};
