import { activatePenOnTouchDefault } from "../../../const";
import {
  activatePenOnTouchStorage,
  clickableUrlsStorage,
  embedTransparencyStorage,
  floatingToolbarStorage,
  minimapOpenStorage,
  objectGradientsStorage,
  uiSizeStorage,
  wheelInputAsTouchpadStorage,
  windowBorderOverlayStorage,
} from "../../../tools/localStorageStores";
import { UISizes } from "../../../types/enum";
import {
  SelectableUserSetting,
  ToggleableUserSetting,
} from "./userSettings.actions";

export const activatePenOnTouchSetting: ToggleableUserSetting = {
  key: "activatePenOnTouch",
  storage: activatePenOnTouchStorage,
  defaultValue: activatePenOnTouchDefault,
};

export const clickableUrlsSetting: ToggleableUserSetting = {
  key: "clickableUrls",
  storage: clickableUrlsStorage,
  defaultValue: false,
};

export const embedTransparencySetting: ToggleableUserSetting = {
  key: "embedTransparency",
  storage: embedTransparencyStorage,
  defaultValue: true,
};

export const floatingToolbarSetting: ToggleableUserSetting = {
  key: "floatingToolbar",
  storage: floatingToolbarStorage,
  defaultValue: false,
};

export const minimapOpenSetting: ToggleableUserSetting = {
  key: "minimapOpen",
  storage: minimapOpenStorage,
  defaultValue: true,
};

export const objectGradientsSetting: ToggleableUserSetting = {
  key: "objectGradients",
  storage: objectGradientsStorage,
  defaultValue: false,
};

export const uiSizeSetting: SelectableUserSetting = {
  key: "uiSize",
  storage: uiSizeStorage,
  defaultValue: UISizes.Default,
};

export const wheelInputAsTouchpadSetting: ToggleableUserSetting = {
  key: "wheelInputAsTouchpad",
  storage: wheelInputAsTouchpadStorage,
  defaultValue: false,
};

export const windowBorderOverlaySetting: ToggleableUserSetting = {
  key: "windowBorderOverlay",
  storage: windowBorderOverlayStorage,
  defaultValue: true,
};
