import { SetStateAction, useState, Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { createProject } from "../../../../../api";
import { routes } from "../../../../../const";
import { useTrackEventToLog } from "../../../../shared/tracking/useTrackEventToLog";
import { LogCategory } from "../../../../../tools/telemetry";

export type CreateProjectValues = {
  Description: string;
  CreateProjectToS: boolean;
  SpaceId: number | null;
};

type UseCreateProjectModalTemplate = {
  runCreateBlankProject: (values: CreateProjectValues) => Promise<string>;
  type: ValuesOf<typeof types>;
  types: typeof types;
  setType: Dispatch<SetStateAction<number>>;
};

const types = { blank: 0, template: 1 };

export const useCreateProjectModalTemplate = (): UseCreateProjectModalTemplate => {
  const { t } = useTranslation();
  const history = useHistory();
  const [type, setType] = useState(types.blank);
  const { trackEvent } = useTrackEventToLog();

  const runCreateBlankProject = ({
    Description,
    SpaceId,
  }: CreateProjectValues) =>
    createProject(Description, SpaceId).then(({ ProjectId }) => {
      trackEvent(LogCategory.project, {
        subcategory: "project-create",
        projectId: ProjectId,
        description: Description,
        type: type === types.template ? "template" : "blank",
      });

      const projectRoute = `${routes.collaboard}/${ProjectId}`;
      const projectState: ViewBoardState = {
        activateTemplateDialog: type === types.template,
      };

      //TODO: pass bool that template was desired and open templates dialog accordingly
      history.push(projectRoute, projectState);

      toast(t("project.createSuccessful"));

      return ProjectId;
    });

  return {
    runCreateBlankProject,
    type,
    types,
    setType,
  };
};
