import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Flex, SxStyleProp, Text } from "theme-ui";
import useProjectUrlParams from "../../useProjectUrlParams";

type Props = {
  project: ApiProject;
  asField?: boolean;
  sx?: SxStyleProp;
  onDoubleClick?: React.MouseEventHandler<HTMLDivElement>;
};

function ProjectTagList({
  project,
  asField,
  sx,
  onDoubleClick,
}: Props): ReactElement {
  const { Tags } = project;
  const { t } = useTranslation();

  const {
    params: { tag: filteredTag },
    filterByTag,
  } = useProjectUrlParams();
  const activeTag = filteredTag.toLowerCase();

  return (
    <Flex
      onDoubleClick={onDoubleClick}
      sx={
        asField
          ? {
              border: "1px solid",
              borderColor: "muted",
              p: [3],
              minHeight: "calc(var(--gridTile) * 4)",
              flexWrap: "wrap",
              alignContent: "flex-start",
            }
          : {
              "> div": {
                display: "table",
              },
            }
      }
    >
      {!Tags.length && (
        <Text sx={{ color: "muted", pointerEvents: "none" }}>
          {t(asField ? "project.noTagsField" : "project.noTags")}
        </Text>
      )}
      {Tags.map((tag, index) => (
        <Text
          key={tag}
          sx={{
            ...sx,
            cursor: "pointer",
            fontWeight: tag.toLowerCase() === activeTag ? "bold" : "normal",
            height: "min-content",
            mr: [1],
            "&:hover": {
              textDecoration: "underline",
            },
          }}
          onClick={() => filterByTag(tag)}
        >
          {tag}
          {index !== Tags.length - 1 && ","}
        </Text>
      ))}
    </Flex>
  );
}

export default ProjectTagList;
