import { Action, AnyAction } from "redux";
import { Task } from "redux-saga";
import { all, cancel, fork, getContext, take } from "redux-saga/effects";
import { SignalRActionType } from "../signalR/signalR.actions";
import { CanvasAppActionType } from "./app/app.actions";
import { appSaga } from "./app/app.saga";
import { CanvasActionType, CanvasEnterAction } from "./canvas.actions";
import { chatSaga } from "./chat/chat.saga";
import { drawingSaga } from "./drawing/drawing.saga";
import { historySaga } from "./history/history.saga";
import { objectsSaga } from "./objects/objects.saga";
import { presentationSaga } from "./presentation/presentation.saga";
import { projectSaga } from "./project/project.saga";
import { NotifyProjectActionType } from "./project/signalR-project/signalR-project.actions";
import { selectionSaga } from "./selection/selection.saga";
import { canvasSettingsSaga } from "./settings/settings.saga";
import { timedSessionSaga } from "./timedSession/timedSession.saga";
import { userPresenceSaga } from "./userPresence/userPresence.saga";
import { userSettingsSaga } from "./userSettings/userSettings.saga";
import { votingSaga } from "./voting/voting.saga";

const actionsToIgnore = [
  SignalRActionType.SET_SIGNALR_STATUS,
  NotifyProjectActionType.ON_NOTIFY_PINGBACK,
  ...Object.values(CanvasAppActionType), // Frequent but harmless for canvas objects
];

function* validationSaga() {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  let lastActions: AnyAction[] = [];

  while (true) {
    const action: AnyAction = yield take(
      (action: Action) => !actionsToIgnore.includes(action.type)
    );

    lastActions.push(action);
    lastActions.length > 10 && lastActions.shift();

    const isValid = canvas.isCanvasStateValid(lastActions);

    // Reset the last actions array if there was some reported inconsistency
    !isValid && (lastActions = []);
  }
}

function* spawnSagasOnCanvasEnter(projectId: string) {
  yield all([
    appSaga(),
    chatSaga(),
    drawingSaga(),
    historySaga(),
    projectSaga(projectId),
    objectsSaga(),
    selectionSaga(),
    canvasSettingsSaga(),
    presentationSaga(),
    timedSessionSaga(),
    userPresenceSaga(),
    userSettingsSaga(),
    votingSaga(),

    validationSaga(),
  ]);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* canvasSaga() {
  while (true) {
    const action: CanvasEnterAction = yield take(
      CanvasActionType.ON_CANVAS_ENTER
    );
    // Spawn canvas sagas in the background when entering
    const canvasSagasTask = (yield fork(
      spawnSagasOnCanvasEnter,
      action.payload.projectId
    )) as Task;

    // Cancel them when exiting
    yield take(CanvasActionType.ON_CANVAS_EXIT);
    yield cancel(canvasSagasTask);
  }
}
