import React, { ReactElement } from "react";
import { Text, Flex, Button, Box } from "theme-ui";
import QRCodeRenderer from "qrcode.react";
import { useTranslation } from "react-i18next";

import OK from "../../../../icons/OK";
import ModalFooter from "../../../shared/modals/ModalFooter";

type Props = {
  value: string;
  onBackClick: () => void;
};

function ShareQRCode({ value, onBackClick }: Props): ReactElement {
  const { t } = useTranslation();
  return (
    <Box sx={{ flex: 1 }}>
      <Flex sx={{ flexDirection: "column", alignItems: "center", mb: [6] }}>
        <Flex sx={{ alignItems: "center", svg: { fill: "primary" } }}>
          <OK noShadow />
          <Text sx={{ fontSize: [5], fontWeight: 500 }}>
            {t("dialog.share.qrCodeGenerated")}
          </Text>
        </Flex>
        <Text sx={{ mb: [4] }}>{t("dialog.share.qrCodeGeneratedInfo")}</Text>
        <QRCodeRenderer value={value} includeMargin size={200} />
      </Flex>

      <ModalFooter sx={{ p: 0 }}>
        <Button variant="buttons.secondary" onClick={onBackClick}>
          {t("dialog.share.back")}
        </Button>
      </ModalFooter>
    </Box>
  );
}

export default ShareQRCode;
