import React, { ReactElement, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, Text } from "theme-ui";
import { useLoggedInStatus } from "../../../authentication/useLoggedInStatus";
import { useSimpleModal } from "../../../shared/modals/useSimpleModal";
import RemoveAvatarModalTemplate from "./RemoveAvatarModalTemplate";

type Props = {
  onRemoveAvatar(): void;
};

function AvatarRemoveButton({ onRemoveAvatar }: Props): ReactElement | null {
  const { t } = useTranslation();
  const { userProfile } = useLoggedInStatus();

  const removeAvatarModalProps = useSimpleModal();

  const onRemoveAvatarClick = useCallback(() => {
    onRemoveAvatar();
    removeAvatarModalProps.open();
  }, [onRemoveAvatar, removeAvatarModalProps]);

  if (!userProfile?.PhotoUrl) {
    return null;
  }

  return (
    <Text>
      <Button type="button" variant="link" onClick={onRemoveAvatarClick}>
        {t("form.button.removePhoto")}
      </Button>
      <RemoveAvatarModalTemplate modalProps={removeAvatarModalProps} />
    </Text>
  );
}

export default AvatarRemoveButton;
