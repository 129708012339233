import { fabric } from "fabric";
import {
  canvasObjectIds,
  fontFamilies,
  fontSizes,
  maxFontSize,
  minObjectHeight,
} from "../../../const";

import { isString } from "../../../tools/text";
import { FreeFormTextVersion } from "../../../types/enum";
import { getDimensionPropsToSet } from "../../utils/dimensionsProps";
import { calcTransformState } from "../../utils/fabricObjects";
import { LiveColor } from "../color/liveColor";
import "../object/collaboardObjectCustom.clickable_urls_mixin";
import "../object/collaboardObjectCustom.editing_mixin";

(function () {
  fabric.FreeFormText = fabric.util.createClass(
    /**
     * We may change it to fabric.Textbox in future but in that case note that
     * it doesn't change the width when fontSize is changed.
     * See fabric.Textbox.prototype.initDimensions
     */
    fabric.IText,
    fabric.CustomEditing,
    fabric.CollaboardClickableURLs, // TODO: #6554 (LINKS_V2) remove
    {
      type: canvasObjectIds.text,
      version: FreeFormTextVersion.FreeFormUnscaledText,

      fontFamily: fontFamilies[0],
      fontSize: fontSizes[6],
      maxFontSize,
      hasRotatingPoint: false,

      scaleX: 1,
      scaleY: 1,
      // don't change originX/originY
      // https://ibvsolutions.visualstudio.com/CollaBoardWeb/_wiki/wikis/CollaBoardWeb.wiki/364/Coordinate-system
      originX: "left",
      originY: "top",
      objectCaching: false,
      lockUniScaling: true,
      textAlign: "left", // "left", "center", "right", "justify", "justify-left", "justify-center" or "justify-right"
      fontWeight: "normal",
      fontStyle: "normal",

      /**
       * Properties from fabric.TextBox and needed to correctly render on the cache
       */
      noScaleCache: false,
      lockScalingFlip: true,

      padding: 15,

      // splitByGrapheme: true, // Enable it if we want to allow resizing the text

      /**
       * Solid geometric objects should use `false`, while intricate objects with
       * lots of transparency (such as Text) should use `true`.
       */
      perPixelTargetFind: true,

      initialize(
        this: fabric.FreeFormText,
        text: string,
        options: FreeFormConfig = {}
      ) {
        this.callSuper("initialize", text, {
          ...options,
          _textBeforeEdit: options.text || text,
        });

        this.initializeCollaboardObject();

        this.initializeClickableURLs && this.initializeClickableURLs(); // TODO: #6554 (LINKS_V2) remove

        this.updateMinSize(-1, minObjectHeight);

        /**
         * The event must be "scaled" and not "modified" so that the later "modified"
         * diff will catch the new fontSize, width etc. and send it to signalR
         *
         * @NOTE "scaled" is triggered also when the parent fabric.Group is scaled
         */
        this.on("scaled", () => {
          if (this.__cb_isAnimating) {
            return;
          }

          this.setTextSize();
        });
      },

      setTextSize(this: fabric.FreeFormText) {
        const { scaleX } = calcTransformState(this);
        const { fontSize, width } = this;

        const updatedFontSize = Math.max(
          Math.round(fontSize * scaleX),
          fontSizes[0]
        );

        const updatedWidth = width * scaleX; // Check with this.getMinWidth if fabric.Textbox is used

        /**
         * If fabric.Textbox is used, we'll have to set the fontSize before the
         * other properties to update the dynamicMinWidth. But this can apparently
         * result in a flash of large bounding box depending on how soon the next
         * render will be.
         */
        this.set({
          fontSize: updatedFontSize,
          scaleX: 1,
          scaleY: 1,
          width: updatedWidth,
        });
      },

      setContextProps(
        this: fabric.FreeFormText,
        {
          textColor,
          // _dimensionAffectingProps
          fontFamily,
          fontSize,
          fontStyle,
          fontWeight,
          textAlign,
          width,
          ...rest
        }: ContextProps,
        config?: SetContextPropsConfig
      ) {
        if (!this.canvas) {
          return;
        }

        const eventProps: ClientModifiedEvent = {
          transform: { action: "ctxPropsChange" }, // text change updates object's BB
        };

        if (textColor) {
          this.fill = textColor ? new LiveColor(textColor) : this.fill;
          this.dirty = true;
        }

        const dimensionPropsToSet = getDimensionPropsToSet(
          this,
          {
            fontFamily,
            fontSize,
            fontStyle,
            fontWeight,
            textAlign,
            width,
          },
          config
        );

        /**
         * Group changes into a single `set()` call because it does additional
         * work when changing properties that affect dimensions.
         */
        const propsToSet = {
          ...dimensionPropsToSet,
          ...rest,
        };

        this.set(propsToSet);

        // TODO: trigger automatically when BB of any object changes
        this.trigger("modified", eventProps);
      },

      getContextProps(this: fabric.FreeFormText): ContextProps {
        return {
          textColor: isString(this.fill) ? this.fill : this.fill?.base,
          fontFamily: this.fontFamily,
          fontSize: this.fontSize,
          fontWeight: this.fontWeight as FontWeight,
          fontStyle: this.fontStyle as FontStyle,
          underline: this.underline,
          textAlign: this.textAlign,
          maxFontSize: this.maxFontSize,
          isAutoFontSize: false,
        };
      },

      supportsDarkMode() {
        return true;
      },

      hasEditableText() {
        return true;
      },

      hasEditableStroke() {
        // TODO: support stroke in text object (return true)
        return false;
      },
    }
  );
})();
