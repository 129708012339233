import React, { lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Button, Text } from "theme-ui";

import { useQRScanner } from "./useQRScanner";

function QRScanner() {
  const { t } = useTranslation();
  const {
    onScan,
    onError,
    errorMessage,
    cameraAccess,
    cameraCount,
    cameraStream,
    facingMode,
    setFacingMode,
  } = useQRScanner();

  if (cameraAccess === null) {
    return <Text>{t("dialog.cameraAccessRequested")}</Text>;
  }

  if (cameraAccess === false) {
    return <Text variant="error">{t("dialog.cameraAccessDenied")}</Text>;
  }

  if (cameraAccess === true && cameraCount === 0) {
    return <Text variant="error">{t("dialog.noCamerasDetected")}</Text>;
  }

  const renderCameraSwitcher = () => {
    return (
      <div style={{ display: "flex", marginTop: "1em" }}>
        <Button
          onClick={() => setFacingMode("front")}
          variant={facingMode === "front" ? "radioButtonActive" : "radioButton"}
        >
          {t("dialog.front")}
        </Button>
        <Button
          onClick={() => setFacingMode("rear")}
          variant={facingMode === "rear" ? "radioButtonActive" : "radioButton"}
        >
          {t("dialog.rear")}
        </Button>
      </div>
    );
  };

  const loadingScanner = <Text>{t("dialog.QRScannerLoading")}</Text>;
  const QRReader = lazy(() => import("react-qr-scanner"));

  return (
    <>
      <Text sx={{ mb: [4] }}>{t("dialog.scanQRCodeInstructions")}</Text>
      <Suspense fallback={loadingScanner}>
        <QRReader
          delay={400}
          className="qr-scanner"
          onError={onError}
          onScan={onScan}
          facingMode={facingMode}
          initialStream={cameraStream}
        />
      </Suspense>
      {cameraCount > 1 && renderCameraSwitcher()}
      {errorMessage && <Text variant="error">{errorMessage}</Text>}
    </>
  );
}

export default QRScanner;
