import { userColors } from "../../const";
import {
  generateAvatars,
  getUserFullName,
  toCanvasAvatars,
} from "../../tools/avatarGenerator";
import { hexToRGB } from "../../tools/colors";
import { ApiPermission } from "../../types/enum";

export const defaultProjectColor = hexToRGB(userColors[0], 0.2);

export const combineUserInfo = <T extends ProjectUserIdentity>({
  user,
  userProfile,
  index,
}: {
  user: T;
  userProfile: ApiUser;
  index: number;
}): ProjectUserInfo<T> => {
  const AvatarUrl = generateAvatars(user);
  const IsMyself = user.UserId === userProfile.UserId;

  return {
    ...user,
    AvatarUrl,
    Avatar: toCanvasAvatars(AvatarUrl),
    FullName: getUserFullName(user),
    IsGuest: user.IsGuest ?? false,
    IsMyself,
    ProjectColor: userColors[index % userColors.length],
  };
};

export const combineParticipantInfo = <T extends ProjectUserIdentity>({
  user,
  userProfile,
  projectPermission,
  index,
}: {
  user: T;
  userProfile: ApiUser;
  projectPermission: ProjectUserPermission;
  index: number;
}): ProjectParticipantInfo<T> => {
  return {
    ...combineUserInfo({ user, userProfile, index }),
    IsProjectOwner:
      projectPermission.Permission === ApiPermission.ownerPermission,
    Permission: projectPermission.Permission,
    ParticipationType: projectPermission.ParticipationType,
  };
};

/**
 * Fix the user basic identity information so that all the fields are available.
 *
 * @NOTE Avoid using this function if possible, be sure that the added properties
 * won't be used otherwise prefer explicitly adding more suitable values, depending
 * on the case.
 */
export const patchUserIdentity = <T extends Partial<ProjectUserIdentity>>(
  user: T
): T & ProjectUserIdentity => {
  return {
    ...user,
    FirstName: user.FirstName ?? "",
    IsGuest: user.IsGuest ?? false,
    LastName: user.LastName ?? "",
    PhotoUrl: user.PhotoUrl ?? null,
    UserId: user.UserId ?? 0,
    UserName: user.UserName ?? "",
  };
};
