import { parseQueryUrl } from "./url";
import { MSTeamsStorage } from "./authStorageStores";
import { routes } from "../const";
import { LogCategory, onErrorToLog } from "./telemetry";
import { isError } from "./errors";
import { InternalError } from "../errors/InternalError";

/**
 * Initialize the MS Teams SDK when running inside MS Teams.
 *
 * Note, this should only be used on first initialization because the
 * `?context=msteams` value is only available on the entry page. After that
 * we must check the storage value to determine if the app is within MS Teams.
 *
 * MS Teams also allows users to refresh the page so we also check the storage.
 */
export const detectAndInitializeMSTeams = (): void => {
  if (isRunningInMSTeams() || detectInitializedInMSTeams()) {
    initializeForMSTeams();
  }
};

/**
 * Check if the app has determined that it is running inside MS Teams.
 */
export const isRunningInMSTeams = (): boolean => {
  return !!MSTeamsStorage.get();
};

const msTeamsCookieName = "msteams_auth";

/**
 * Store MS Teams auth code.
 *
 * @NOTE - The MSTeams JS library has a bug which means that sometimes
 * authentication successes are reported as failures. 🙄
 *
 * The suggested workaround is to store the code in a session cookie.
 *
 * https://github.com/OfficeDev/microsoft-teams-library-js/issues/629#issuecomment-975926875
 */
export const storeMSTeamsAuthCode = (code: string): void => {
  document.cookie = `${msTeamsCookieName}=${encodeURIComponent(
    code
  )}; SameSite=strict; path=/;`;
};

export const clearMSTeamsAuthCode = (): void => storeMSTeamsAuthCode("");

export const getMSTeamsAuthCode = (): string | undefined => {
  const cookies = document.cookie;
  const cookieValue = cookies
    .split("; ") // Trailing space is important
    .find((row) => row.startsWith(`${msTeamsCookieName}=`));

  if (!cookieValue) {
    return undefined;
  }

  const [, value] = cookieValue.split("=");

  return value ? decodeURIComponent(value) : undefined;
};

/**
 * Detect if the app has been initialized inside MS Teams.
 *
 * The MS Teams SDK does not provide a method to check whether the app instance
 * is running inside MS Teams, but also we don't want to download the SDK
 * unnecessarily just to check if we are inside MS Teams.
 *
 * So instead we check for `?context=msteams` which is appended to the entry
 * URL in the MS Team's app manifest.json.
 *
 * @private
 */
const detectInitializedInMSTeams = () => {
  const { context } = parseQueryUrl(window.location.search);
  const isOnTeamsAuthPage = ([
    routes.MSTeamsAuthEnd,
    routes.MSTeamsAuth,
  ] as string[]).includes(window.location.pathname);

  return context?.toString().toLowerCase() === "msteams" || isOnTeamsAuthPage;
};

/**
 * Prepare app for MS Teams.
 *
 * @private
 */
const initializeForMSTeams = async () => {
  MSTeamsStorage.set(true);
  try {
    const microsoftTeams = await import("@microsoft/teams-js");
    microsoftTeams.initialize();
  } catch (error) {
    onErrorToLog(
      isError(error)
        ? error
        : new InternalError(`Teams initialization failed: ${error}`),
      LogCategory.internal
    );
  }
};
