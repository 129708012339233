import { Task } from "redux-saga";
import {
  all,
  call,
  cancel,
  Effect,
  fork,
  getContext,
  select,
  take,
  takeEvery,
} from "redux-saga/effects";
import { sendControlMedia } from "../../../api";
import { SignalRClient } from "../../../api/signalR";
import { presentingStorage } from "../../../tools/localStorageStores";
import { selectAssertedUserProfile } from "../../auth/auth.reducer";
import { selectProjectId } from "../project/project.reducer";
import {
  NotifyProjectActionType,
  SetUserOnlineAction,
} from "../project/signalR-project/signalR-project.actions";
import { CanvasSettingsActionType } from "../settings/settings.actions";
import { TimedSessionActionType } from "../timedSession/timedSession.action";
import {
  ControlDocumentAction,
  ControlMediaAction,
  PresentationPresenterActionType,
} from "./presentation.presenter.actions";
import { ScreenCoordinates } from "./presentation.reducer";

/** @TODO #7322 - Slightly different from the API shape. Unify them */
export const getScreenCoords = (
  canvas: fabric.CollaboardCanvas
): ScreenCoordinates => {
  const [, , , , x, y] = canvas.viewportTransform;
  const width = window.innerWidth;
  const height = window.innerHeight;

  return { x, y, width, height };
};

function* viewportChange() {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const signalRClient: SignalRClient = yield getContext("signalRClient");

  const screenCoords = getScreenCoords(canvas);
  const [zoomLevel] = canvas.viewportTransform;

  signalRClient.postScreenPosZoomChanged(zoomLevel, {
    X: screenCoords.x,
    Y: screenCoords.y,
    Width: screenCoords.width,
    Height: screenCoords.height,
  });
}

function* onStartPresenting() {
  viewportChange();
  yield call({ context: presentingStorage, fn: presentingStorage.set }, true);
}

function* onStopPresenting() {
  yield call({ context: presentingStorage, fn: presentingStorage.clear });
}

function* controlMedia({ payload }: ControlMediaAction) {
  const projectId = selectProjectId(yield select());
  const { object, action, currentTime } = payload;

  yield call(sendControlMedia, projectId, object.uuid, action, currentTime);
}

function* controlDocument({ payload }: ControlDocumentAction) {
  const signalRClient: SignalRClient = yield getContext("signalRClient");

  const { object, page } = payload;

  yield signalRClient.postThumbnailPageChanged(object.uuid, page);
}

function* notifyUserJoined({ payload }: SetUserOnlineAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const signalRClient: SignalRClient = yield getContext("signalRClient");

  const { AuthUser } = payload;

  const userProfile = selectAssertedUserProfile(yield select());

  const ScreenCoordinates = getScreenCoords(canvas);
  const [ZoomLevel] = canvas.viewportTransform;

  const connectionId = AuthUser?.ConnectionId;

  if (connectionId) {
    yield signalRClient.postUpdateNewJoinedForPresentation({
      ZoomLevel,
      ScreenCoordinates: {
        X: ScreenCoordinates.x,
        Y: ScreenCoordinates.y,
        Width: ScreenCoordinates.width,
        Height: ScreenCoordinates.height,
      },
      NewJoinerConnectionId: connectionId,
      Presenter: userProfile.UserName,
    });
  }
}

function* spawnPresenterSagas() {
  yield all([
    takeEvery(CanvasSettingsActionType.SET_VIEWPORT_TRANSFORM, viewportChange),
    takeEvery(CanvasSettingsActionType.WINDOW_RESIZE_DEBOUNCED, viewportChange),
    takeEvery(
      TimedSessionActionType.TIMED_SESSION_PAUSE_TOGGLED,
      viewportChange
    ),
    takeEvery(
      PresentationPresenterActionType.CONTROL_DOCUMENT,
      controlDocument
    ),
    takeEvery(PresentationPresenterActionType.CONTROL_MEDIA, controlMedia),
    takeEvery(NotifyProjectActionType.SET_USER_ONLINE, notifyUserJoined),
  ]);
}

function* presentationStartedSaga() {
  while (true) {
    // Spawn the presenter sagas only when presenting in the current tab
    yield take([
      PresentationPresenterActionType.START_PRESENTING,
      PresentationPresenterActionType.RESUME_PRESENTING,
    ]);

    // Spawn sagas in the background when starting the presentation
    const spawnedSagasTask = (yield fork(spawnPresenterSagas)) as Task;

    // Cancel sagas when the presentation is stopped
    yield take(PresentationPresenterActionType.STOP_PRESENTING);
    yield cancel(spawnedSagasTask);
  }
}

export function* presentationPresenterSaga(): Generator<Effect> {
  yield all([
    presentationStartedSaga(),
    takeEvery(
      PresentationPresenterActionType.START_PRESENTING,
      onStartPresenting
    ),
    takeEvery(
      PresentationPresenterActionType.RESUME_PRESENTING,
      viewportChange
    ),
    takeEvery(
      PresentationPresenterActionType.STOP_PRESENTING,
      onStopPresenting
    ),
  ]);
}
