import React, { ReactElement, useEffect } from "react";
import { Flex } from "theme-ui";
import { useTranslation } from "react-i18next";

import { useCoParticipatingProjects } from "../../../../../api/useRequest";
import LoadingSpinner from "../../../../../icons/LoadingSpinner";
import LineSeparator from "../LineSeparator";
import UserProjectsList from "./UserProjectsList";
import { usePermissions } from "../../../../permissions/usePermissions";
import {
  ApiResourcePermission,
  ApiResourceType,
} from "../../../../../types/enum";
import { useSpaces } from "../../../../spaces/useSpaces";
import { findSpacePermission } from "../../../../spaces/utils";

type Props = {
  User: ProjectApiUserInfo;
  isExpanded: boolean;
};

function UserProjects({ User, isExpanded }: Props): ReactElement {
  const { t } = useTranslation();

  const {
    Projects,
    isProjectListLoading,
    getCoParticipatingProjects,
  } = useCoParticipatingProjects();
  const { spaces } = useSpaces();
  const { hasPermission } = usePermissions();

  useEffect(() => {
    getCoParticipatingProjects(User.UserName);
  }, [User, getCoParticipatingProjects]);

  const allowedProjects = Projects.filter((project) => {
    const spaceId = project.Project.SpaceId;
    return hasPermission({
      type: project.Project.SpaceId
        ? ApiResourceType.SpaceProjects
        : ApiResourceType.Project,
      value: String(project.Project.ProjectId),
      spacePermission: spaceId
        ? findSpacePermission(spaces, spaceId)
        : undefined,
      projectPermission: project.Permission,
      permission: ApiResourcePermission.View,
    });
  });

  return (
    <Flex sx={{ my: 2, flex: 1, flexDirection: "column" }}>
      <LineSeparator
        text={t("users.projects", { count: Projects.length })}
        isLineVisible={false}
        textSx={{ fontWeight: 300, mb: [2] }}
      />

      <Flex sx={{ flexWrap: "wrap", justifyContent: "space-between" }}>
        {isProjectListLoading ? (
          <LoadingSpinner />
        ) : (
          <UserProjectsList
            isExpanded={isExpanded}
            projects={allowedProjects}
          />
        )}
      </Flex>
    </Flex>
  );
}

export default UserProjects;
