import theme from "../prowise";

const configuration: CustomerConfiguration = {
  appName: "digital workspace",
  faviconName: "favicon-digital-workspace.ico",
  loginTroubleHref: "mailto:support@collaboard.app",
  theme,
};

export default configuration;
