import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Button, Flex, Text } from "theme-ui";
import Close from "../../../../../icons/Close";
import OrderDown from "../../../../../icons/OrderDown";
import OrderUp from "../../../../../icons/OrderUp";
import { ApiProjectsOrderParameter } from "../../../../../types/enum";
import useProjectUrlParams from "../../useProjectUrlParams";
import { byDateFlex } from "../gridFlex";

type OrderHeaderProps = PropsWithRequiredChildren<{
  orderProperty?: ApiProjectsOrderParameter;
  flex: number | (number | null)[];
}>;

type CloseButtonProps = {
  onClick(): void;
};

function CloseButton({ onClick }: CloseButtonProps): ReactElement {
  return (
    <Button variant="linkWithIcon" onClick={onClick}>
      <Close fill="white" scale={0.6} />
    </Button>
  );
}

function ProjectListHeader(): ReactElement {
  const { t } = useTranslation();
  const {
    params,
    orderProjectsBy,
    resetProjectsOrder,
    clearTag,
  } = useProjectUrlParams();
  const { orderBy, ascending, tag } = params;
  const { name, owner } = ApiProjectsOrderParameter;

  function Header({
    flex,
    orderProperty,
    children,
  }: OrderHeaderProps): ReactElement | null {
    const isOrdered = orderBy === orderProperty;
    const onClick = orderProperty
      ? () => orderProjectsBy(orderProperty)
      : undefined;

    const headerContent = !isOrdered ? (
      <Text
        sx={{ cursor: orderProperty ? "pointer" : undefined }}
        onClick={onClick}
      >
        {children}
      </Text>
    ) : (
      <>
        <Text>{children}</Text>
        <Flex>
          <Button variant="linkWithIcon" onClick={onClick} sx={{ pr: [3] }}>
            {ascending ? <OrderUp fill="white" /> : <OrderDown fill="white" />}
          </Button>

          <CloseButton onClick={resetProjectsOrder} />
        </Flex>
      </>
    );

    return (
      <Flex
        sx={{
          flex,
          borderBottom: "4px solid",
          borderColor: isOrdered ? "accent" : "black",
        }}
      >
        {headerContent}
      </Flex>
    );
  }

  return (
    <Flex
      sx={{
        borderTop: "5px solid",
        borderColor: "white",
        backgroundColor: "black",
        flexWrap: "wrap",
        "> div": {
          textAlign: ["left", null, "center"],
          px: [4],
          py: [3],
          lineHeight: "var(--gridTile)",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          alignItems: "center",
          justifyContent: "space-between",
          fontSize: 14,
          fontWeight: 600,
          "> div": {
            color: "white",
          },
        },
      }}
    >
      <Header flex={byDateFlex.description} orderProperty={name}>
        {t("project.name")}
      </Header>

      <Header flex={byDateFlex.owner} orderProperty={owner}>
        {t("project.owner")}
      </Header>

      <Header flex={byDateFlex.tags}>
        <Flex>
          {t("project.tags")}
          {tag && <CloseButton onClick={clearTag} />}
        </Flex>
      </Header>
      <Header flex={byDateFlex.actions}>{}</Header>
    </Flex>
  );
}

export default ProjectListHeader;
