import { SignalRStatus } from "./signalR.reducer";

export enum SignalRActionType {
  SET_SIGNALR_STATUS = "SET_SIGNALR_STATUS",
  SET_SIGNALR_CONNECTED = "SET_SIGNALR_CONNECTED",
  SET_SIGNALR_FAILED = "SET_SIGNALR_FAILED",
}

export type SetSignalRStatusPayload = {
  status?: Exclude<
    SignalRStatus,
    SignalRStatus.Connected | SignalRStatus.Failed
  >;
  isQueueBusy?: boolean;
};

export const setSignalRStatusAction = (payload: SetSignalRStatusPayload) =>
  ({
    type: SignalRActionType.SET_SIGNALR_STATUS,
    payload,
  } as const);

type SetSignalRStatusAction = ReturnType<typeof setSignalRStatusAction>;

export const setSignalRConnectedAction = (payload: { connectionId: string }) =>
  ({
    type: SignalRActionType.SET_SIGNALR_CONNECTED,
    payload: {
      status: SignalRStatus.Connected,
      connectionId: payload.connectionId,
    },
  } as const);

export const setSignalRDisconnectedAction = () =>
  ({
    type: SignalRActionType.SET_SIGNALR_CONNECTED,
    payload: {
      status: SignalRStatus.Disconnected,
      connectionId: undefined,
    },
  } as const);

export const setSignalRFailedAction = (payload: { error: Error }) =>
  ({
    type: SignalRActionType.SET_SIGNALR_FAILED,
    payload,
  } as const);

type SetSignalRConnectedAction = ReturnType<typeof setSignalRConnectedAction>;
type SetSignalRDisconnectedAction = ReturnType<
  typeof setSignalRDisconnectedAction
>;
type SetSignalRFailedAction = ReturnType<typeof setSignalRFailedAction>;

export type SignalRAction =
  | SetSignalRStatusAction
  | SetSignalRConnectedAction
  | SetSignalRDisconnectedAction
  | SetSignalRFailedAction;
