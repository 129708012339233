import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Grid, Text } from "theme-ui";
import Thumbnail from "../Thumbnail";

type Props = {
  projects: ApiProject[];
  isExpanded: boolean;
};

function UserProjectsList({ projects }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <Grid
      sx={{
        gridTemplateColumns: "40% 20% 20% 20%",
        width: "100%",
        gap: 0,
      }}
    >
      {projects.map((ProjectData) => {
        const { NumberOfParticipants, Permission } = ProjectData;
        const {
          Thumbnail: thumbnail,
          ProjectId: projectId,
          Description,
          CreationDate,
          LastUpdate,
        } = ProjectData.Project;

        const creationDate = t("app.creationDate", {
          date: new Date(CreationDate),
        });
        const lastUpdated = t("app.lastUpdate", {
          date: new Date(LastUpdate),
        });

        return (
          <React.Fragment key={projectId}>
            <Flex
              sx={{
                flex: 1,
                backgroundColor: "var(--contextGradientColor2)",
                marginBottom: 2,
                padding: 2,
              }}
            >
              <Thumbnail
                projectId={projectId}
                thumbnail={thumbnail}
                customStyles={{ height: "100px", maxWidth: "150px" }}
                boxCustomStyes={{ mb: [0] }}
              />
              <Flex
                sx={{
                  flexDirection: "column",
                  flex: 1,
                  ml: [2],
                  fontSize: 12,
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Text
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      mb: [2],
                      "a:hover &": { textDecoration: "underline" },
                    }}
                  >
                    {Description}
                  </Text>
                </Box>
                <Box>
                  <Text sx={{ mb: [1] }}>{creationDate}</Text>
                  <Text sx={{ mb: [1] }}>{lastUpdated}</Text>
                </Box>
              </Flex>
            </Flex>
            <Flex
              sx={{
                backgroundColor: "var(--contextGradientColor2)",
                marginBottom: 2,
                padding: 2,
                alignItems: "center",
              }}
            >
              <Text>{t("users.collaborators")}</Text>:
              <Text sx={{ pl: [1], fontWeight: 600 }}>
                {NumberOfParticipants}
              </Text>
            </Flex>
            <Flex
              sx={{
                backgroundColor: "var(--contextGradientColor2)",
                marginBottom: 2,
                padding: 2,
                alignItems: "center",
              }}
            >
              <Text>{t(`permissions.${Permission}`)}</Text>
            </Flex>
            <Flex
              sx={{
                backgroundColor: "var(--contextGradientColor2)",
                marginBottom: 2,
                padding: 2,
              }}
            ></Flex>
          </React.Fragment>
        );
      })}
    </Grid>
  );
}

export default UserProjectsList;
