import { useField } from "formik";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Input, ThemeUIStyleObject } from "theme-ui";
import { useFieldError } from "../../../../shared/forms/fields/useFieldError";

type Props = {
  name: string;
  disabled?: boolean;
  onBlur?: () => void;
  onComplete?: (cancelled: boolean) => void;
};

function InputField({
  name,
  disabled,
  onBlur,
  onComplete,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [field, meta] = useField({ name });
  const { errorInputSx } = useFieldError({ meta, name });

  const onKeyUp = (e: React.KeyboardEvent): void => {
    const key = e.key.toLowerCase();
    const isCancelled = key === "escape";

    if (key === "enter" || isCancelled) {
      onComplete && onComplete(isCancelled);
    }
  };

  return (
    <Input
      placeholder={t("dialog.newProjectName")}
      onKeyUp={onKeyUp}
      disabled={disabled}
      {...field}
      {...meta}
      onBlur={onBlur}
      autoFocus
      sx={{
        ...inputStyle,
        ...errorInputSx,
      }}
    />
  );
}

const inputStyle: ThemeUIStyleObject = {
  fontSize: "14px!important",
  padding: 0,
  pr: "var(--gridTile) !important",
  border: "none",
  outline: "none",
  background: "white",
  "&:disabled": {
    opacity: 0.6,
  },
};

export default InputField;
