import { RefObject, useRef, useEffect } from "react";
import { useFormikContext } from "formik";

type Config = {
  name: string;
  fieldRef: RefObject<HTMLElement>;
};

export const useFocusOnError = ({ fieldRef, name }: Config): void => {
  const formik = useFormikContext();
  const prevSubmitCountRef = useRef(formik.submitCount);

  const firstErrorKey = Object.keys(formik.errors)[0];

  useEffect(() => {
    if (
      fieldRef.current &&
      prevSubmitCountRef.current !== formik.submitCount &&
      !formik.isValid &&
      firstErrorKey === name
    ) {
      fieldRef.current.focus();

      const y =
        fieldRef.current.getBoundingClientRect().top + window.pageYOffset - 100;

      window.scrollTo({ top: y, behavior: "smooth" });
    }

    if (!formik.isSubmitting) {
      prevSubmitCountRef.current = formik.submitCount;
    }

    // formik.isSubmitting should not be added to dependency list as it messes up the prevSubmitCountRef
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.submitCount, formik.isValid, firstErrorKey, fieldRef, name]);
};
