export const featuresIndividual: Array<Feature> = [
  {
    labelKey: "projectExport",
    inCard: true,
    order: 300,
  },
  {
    labelKey: "youtube",
    inCard: true,
    order: 301,
  },
];
