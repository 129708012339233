import React, { PropsWithChildren, useRef } from "react";
import {
  Alert,
  Flex,
  Input,
  Label,
  useThemeUI,
  ThemeUICSSObject,
} from "theme-ui";
import { ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import { isIOS, isMobile } from "react-device-detect";
import ReactCountryFlag from "react-country-flag";

import Web from "../../../../../icons/Web";
import CountriesDropdown from "./CountriesDropdown";
import CountriesSelectMobile from "./CountriesSelectMobile";
import { usePhoneInputField } from "./usePhoneInputField";

type Props = PropsWithChildren<{
  name: string;
  defaultCountry?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  tabIndex?: number;
  variant?: string;
  sx?: ThemeUICSSObject;
}>;

export default function PhoneInputField({
  children: componentChildren,
  defaultCountry,
  disabled,
  placeholder,
  required,
  sx,
  tabIndex,
  variant = "label",
  ...props
}: Props): React.ReactElement {
  const { theme } = useThemeUI();
  const { t } = useTranslation();

  const inputLabel = useRef(null);
  const phoneInput = useRef(null);

  const {
    name,
    country,
    phoneNumber,
    countriesList,
    onFieldChange,
    handleSelect,
    handleToggleList,
  } = usePhoneInputField({
    defaultCountry,
    props,
    disabled,
    inputLabel,
    phoneInput,
  });

  return (
    <Label
      variant={required ? `${variant}.required` : `${variant}`}
      ref={inputLabel}
      sx={sx}
    >
      <Flex variant="forms.formGroup">
        <Flex onClick={handleToggleList} variant="forms.inputPrepend">
          {country.iso2 === "intl" ? (
            <Web />
          ) : (
            <ReactCountryFlag countryCode={country.iso2} svg />
          )}
        </Flex>

        <Input
          maxLength={21}
          name={name}
          autoComplete="tel"
          onChange={onFieldChange}
          placeholder={placeholder || t(`form.placeholder.${name}`)}
          ref={phoneInput}
          type="tel"
          sx={{ zIndex: 2 }}
          value={phoneNumber}
          tabIndex={tabIndex}
        />

        {!isMobile && !isIOS && countriesList && (
          <CountriesDropdown
            country={country}
            handleSelect={handleSelect}
            theme={theme}
          />
        )}

        {(isMobile || isIOS) && (
          <CountriesSelectMobile
            theme={theme}
            handleSelect={handleSelect}
            country={country}
          />
        )}
      </Flex>
      <ErrorMessage
        name={name}
        component={({ children }) => (
          <Alert variant="primary">{children}</Alert>
        )}
      />
      {componentChildren}
    </Label>
  );
}
