import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { SendResetPasswordEmail } from "../../../api";
import PasswordReset from "../../authentication/passwordReset/PasswordReset";
import { useCustomerComponents } from "../../licensing/useCustomerComponents";
import Page from "../layout/pages/Page";

function ResetPassword(): ReactElement {
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const hasToken = queryParams.has("token");
  const [User, setUser] = useState<string | undefined>(undefined);

  const { SendToken } = useCustomerComponents();

  const onSendToken = useCallback((values: SendResetPasswordEmail) => {
    setUser(values.User);
  }, []);

  return (
    <Page>
      {hasToken || User ? (
        <PasswordReset User={User} />
      ) : (
        <SendToken onSendToken={onSendToken} />
      )}
    </Page>
  );
}
export default ResetPassword;
