import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function TemplateFull(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <path d="M27.92,24H26.63V11.7a.65.65,0,0,0-.65-.65H22.74V9.76H17.55v1.29H14.3a.66.66,0,0,0-.65.65V24H12.36v1.3h1.29V26a.66.66,0,0,0,.65.65h3L14.49,29.4l.92.92,3.7-3.7h.38v3.89h1.3V26.62h.38l3.7,3.7.92-.92L23,26.62h3a.65.65,0,0,0,.65-.65v-.65h1.29ZM15,12.35H25.33V24H15Z" />
      <path d="M21.44,14.94a.65.65,0,0,0-.65-.64H16.9a.65.65,0,0,0-.65.64v3.9a.65.65,0,0,0,.65.64h.65V15.59h3.89Z" />
      <path d="M21.44,16.89A2.6,2.6,0,1,0,24,19.48,2.6,2.6,0,0,0,21.44,16.89Zm0,3.89a1.3,1.3,0,1,1,1.3-1.3A1.3,1.3,0,0,1,21.44,20.78Z" />
    </Icon>
  );
}
export default TemplateFull;
