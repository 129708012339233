import React, { PropsWithChildren, ReactElement } from "react";
import { Box, ThemeUIStyleObject } from "theme-ui";

type Props = PropsWithChildren<{
  isDragActive: boolean;
}>;

function PulseContainer({ isDragActive, children }: Props): ReactElement {
  return (
    <Box sx={isDragActive ? pulseContainerStyle : idleContainerStyle}>
      {children}
    </Box>
  );
}

const iconStyle: ThemeUIStyleObject = {
  flex: 1,
  alignSelf: "center",
  top: 0,
  fill: "black",
  width: "calc(var(--staticGridTile) * 3)",
  height: "calc(var(--staticGridTile) * 3)",
};

const idleContainerStyle: ThemeUIStyleObject = {
  animation: "none",
  "& > svg": iconStyle,
};

const pulseContainerStyle: ThemeUIStyleObject = {
  animation: "pulse-animation .5s ease-in-out infinite both",
  "& > svg": {
    ...iconStyle,
    fill: "var(--accent)",
  },
};

export default PulseContainer;
