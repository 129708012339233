import { fabric } from "fabric";

export const lineIntersectionType = {
  coincident: 0,
  parallel: 1,
  nonIntersecting: 2,
  intersecting: 3,
};

export class LineIntersection {
  constructor(
    public a: fabric.CollaboardPoint,
    public b: fabric.CollaboardPoint
  ) {}

  intersectsLine(line: LineIntersection): fabric.CollaboardPoint | null {
    const denom =
      (line.b.y - line.a.y) * (this.b.x - this.a.x) -
      (line.b.x - line.a.x) * (this.b.y - this.a.y);
    const na =
      (line.b.x - line.a.x) * (this.a.y - line.a.y) -
      (line.b.y - line.a.y) * (this.a.x - line.a.x);
    const nb =
      (this.b.x - this.a.x) * (this.a.y - line.a.y) -
      (this.b.y - this.a.y) * (this.a.x - line.a.x);
    if (denom !== 0) {
      const ua = na / denom;
      const ub = nb / denom;
      if (ua >= 0.0 && ua <= 1.0 && ub >= 0.0 && ub <= 1.0) {
        return this.a.interpolate(this.b, ua);
      } else {
        return this.a.interpolate(this.b, ua);
      }
    } else {
      return null;
    }
  }
}
