import { isTransparent } from "../../../tools/colors";

/**
 * Parse the settings object and determine the correct values to use.
 *
 * Shared by reviver and fabric.CollaboardShape.
 */
export const parseShapeSettings = ({
  fillColor = "transparent",
  strokeColor = "transparent",
  strokeWidth = 1,
}: ContextProps = {}): ShapeSettings => {
  return {
    fillColor,
    strokeColor,
    strokeWidth,
  };
};

/**
 * Set the initial options on a new shape element.
 *
 * Called when a new shape element is created or duplicated.
 *
 * @returns {Function} Reviver callback
 */
export const shapeReviver = (
  contextProps: ContextProps
): ((path: SVGElement, object: fabric.Object) => void) => {
  const { fillColor, strokeColor, strokeWidth } = parseShapeSettings(
    contextProps
  );

  return (_path: SVGElement, object: fabric.Object) => {
    object.set({
      fill: fillColor,
      stroke: strokeColor,
      strokeWidth,
      strokeUniform: true,
    });
  };
};

/**
 * Ensure new fill and stroke will not make the object entirely invisible.
 *
 * TODO: This might be useful for other objects.
 */
export const preventFullTransparency = (
  newFill: string,
  newStroke: string,
  currentFill: string,
  currentStroke: string
): { fill: string; stroke: string } => {
  const isNewFillTransparent = isTransparent(newFill);
  const isNewStrokeTransparent = isTransparent(newStroke);

  const willBecomeTransparent = isNewFillTransparent && isNewStrokeTransparent;

  if (!willBecomeTransparent) {
    return {
      fill: newFill,
      stroke: newStroke,
    };
  }

  const isCurrentFillTransparent = isTransparent(currentFill);

  return {
    fill: isCurrentFillTransparent ? currentStroke : newFill,
    stroke: !isCurrentFillTransparent ? currentFill : newStroke,
  };
};
