import type {
  ResumedObject,
  StorageAccessCredentials,
} from "@collaboard/storage-client";
import {
  CollaboardStorageClient,
  StorageProvider,
} from "@collaboard/storage-client";
import { fabric } from "fabric";
import { getStorageAccessSignature } from "../api";
import { SignalRClient } from "../api/signalR";
import { deviceUuid } from "../const";
import { noLimitMaximumFileSize } from "../features/licensing/useActivePlan";
import { reduxStore } from "../reduxStore";
import { getOrRefreshAuthToken } from "../reduxStore/auth/auth.storage";
import { selectProjectId } from "../reduxStore/canvas/project/project.reducer";
import { isRecognizedTileType } from "../studio/utils/fabricObjects";
import { objectTypes } from "../studio/utils/objectConverter";
import { WebRTCManager } from "../webrtc/webRTCManager";
import {
  developmentFlags,
  isDevFlagActive,
  isDevModeEnabled,
  isStaticFeatureActive,
  staticFeatureFlags,
} from "./flags";
import { runtimeConfig } from "./runtimeConfig";
import { LogCategory, trackEventToLog } from "./telemetry";
import { getAppVersion } from "./versionInfo";

const {
  storageProvider,
  storageDownloadChunkSize,
  storageUploadChunkSize,
  onPremiseMftApiUrl,
} = runtimeConfig;

export type Collaboard = {
  canvas: fabric.CollaboardCanvas | null;
  signalRClient: SignalRClient;
  storageClient: CollaboardStorageClient;
  webRTCManager?: WebRTCManager;
};

export type CollaboardWithCanvas = Collaboard & {
  canvas: fabric.CollaboardCanvas;
};

const mftUploadAsByteArray = isStaticFeatureActive(
  staticFeatureFlags.MFT_UPLOAD_AS_BYTE_ARRAY
);
const disableTimeouts = isDevFlagActive(developmentFlags.DISABLE_TIMEOUTS);

const getStorageAccessCredentialsProvider = async (
  projectId: string | number
): Promise<StorageAccessCredentials> => {
  const {
    AuthorizationToken,
    AuthorizationTokenExpiry,
  } = await getOrRefreshAuthToken();
  const { Signature, ExpiresAt } = await getStorageAccessSignature(projectId);
  const expiryBuffer = 10_000;
  return {
    // TODO - add 'host' from API response once it is available. This will be
    // required for S3 support.
    signature: Signature,
    // Use a buffer to prevent signature expiring just before it is used
    signatureExpiry: new Date(ExpiresAt).getTime() - expiryBuffer,
    token: AuthorizationToken,
    // The token expiry timestamp already includes a buffer
    tokenExpiry: AuthorizationTokenExpiry,
  };
};

const storageClient = new CollaboardStorageClient({
  provider: storageProvider as StorageProvider,
  getStorageAccessCredentialsProvider,
  downloadChunkSize: storageDownloadChunkSize,
  uploadChunkSize: storageUploadChunkSize,
  enableResumableUploads: true,
  mft: {
    appVer: getAppVersion(),
    deviceId: deviceUuid,
    maximumFileSize: noLimitMaximumFileSize,
    origin: onPremiseMftApiUrl,
    // MFT library doesn't allow to disable the timeout entirely, so use a large value instead
    pollingRetryMaximumDuration: disableTimeouts
      ? Number.MAX_SAFE_INTEGER
      : undefined,
    uploadChunkAsByteArray: mftUploadAsByteArray,
  },
  onResumeUploadChunk: (object: ResumedObject) => {
    // Single chunk of a file has been uploaded
    const { uuid } = object;
    window.dispatchEvent(new Event(`pending:chunk:done:${uuid}`));
  },
  onResumeUploadComplete: async (object: ResumedObject) => {
    // Entire file has been uploaded
    const { uuid, projectId, type, fileName } = object;
    const currentProjectId = selectProjectId(reduxStore.getState());

    try {
      if (!isRecognizedTileType(type)) {
        return;
      }
      const tileType = objectTypes[type];
      if (!tileType) {
        return;
      }

      await collaboard.signalRClient.postLoginProject(Number(projectId));
      await collaboard.signalRClient.postUploaded(uuid, tileType, fileName);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      await collaboard.signalRClient.postLoginProject(Number(currentProjectId));

      window.dispatchEvent(new Event(`pending:upload:done:${uuid}`));
    }
  },
});

storageClient.initStore().catch(() => {
  trackEventToLog(LogCategory.storage, {
    subcategory: "localforage.indexeddb-not-available",
  });
});

const collaboard: Collaboard = {
  canvas: null,
  signalRClient: new SignalRClient(),
  storageClient,
  webRTCManager: undefined,
};

if (isDevModeEnabled()) {
  // Expose SignalRClient in DEV to allows back-end people to
  // test new changes with their local SignalR server

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).SignalRClient = SignalRClient;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).signalRClient = collaboard.signalRClient;
}

export const maxSelectionLimit = collaboard.signalRClient.calculateMaxSelectionLimit();

export default collaboard;
