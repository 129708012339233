import { bootstrap } from "@theme-ui/presets";
import { Theme } from "theme-ui";
import { toolPanelsZindex, draggableWindowZIndex } from "../const";
import { runtimeConfig } from "../tools/runtimeConfig";
import buttons from "./default/buttons";
import forms from "./default/forms";
import styles from "./default/styles";

const makeTheme = <T extends Theme>(t: T) => t;

const defaultTheme = makeTheme({
  ...bootstrap,
  name: "default",
  colors: {
    accent: "#c7d306",
    active: "#c7d306",
    accentSecondary: "#e8e783",
    background: "#ffffff",
    danger: "#ff561e",
    muted: "#c6c6c5",
    primary: "#d0d600",
    secondary: "#ececec",
    text: "#1d1d1b",
    disabled: "#d9d9d9",
    disabledDark: "#9c9c9b",
    disabledTextDark: "#6f6f6f",
    delicate: "#f5f5f5", // TODO: rename
    accentAlternate: "#9BB200",
    accentDimmed: "#e9eec4",
    accentSecondaryAlternate: "#c2cc61",
    accentSecondaryDimmed: "#f9f9e1",
    warning: "#e84e1b",
    warningAlternate: "#b54e1b",
    disclaimerBg: "#c7d306",
    disclaimerText: "#000000",
    pageCover: "rgba(255, 255, 255, 0.8)",
    pageCoverDark: "rgba(0, 0, 0, 0.4)",
    ...runtimeConfig.styles?.colors,
  },
  initialColorMode: "default",
  useBodyStyles: true,
  background: "green",
  breakpoints: [...(bootstrap.breakpoints || []), "1600px"],
  space: [
    "0",
    "0.25rem",
    "0.4375rem",
    "0.5625rem",
    "1rem",
    "1.5rem",
    "3rem",
    "3.75rem",
    "6rem",
    "9rem",
  ],
  fontSizes: [
    "0.5rem", // "8px"
    "0.625rem", // "10px"
    "0.75rem", // "12px"
    "0.875rem", // "14px"
    "1rem", // "16px"
    "1.375rem", // "22px"
    "1.75rem", // "28px"
    "2.75rem", // "44px"
    "3.25rem", // "52px"
    "4rem", // "64px"
  ],
  sizes: {
    container: "76rem",
  },
  buttons,
  fonts: {
    body:
      '"Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    heading: "'Segoe-ui - Semibold', system-ui, sans-serif",
    monospace: "Menlo, monospace",
  },
  fontWeights: {
    body: 400,
    bold: 700,
    heading: 400,
  },
  forms,
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  text: {
    default: {
      color: "var(--textColor)",
    },
    secondary: {
      fontSize: [3],
      color: "var(--modalSubTitle)",
    },
    dropdownText: {
      m: [1],
    },
    ellipsis: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    error: {
      color: "danger",
      fontWeight: 500,
    },
    heading: {
      color: "primary",
      display: "flex",
      alignItems: "center",
    },
    icon: {
      display: "flex",
      alignItems: "center",
    },
    h2: {
      color: "primary",
      fontSize: [7],
      fontWeight: 300,
    },
    h3: {
      variant: "text.heading",
      color: "text",
      flex: "1 0 100%",
      fontSize: [5],
      fontWeight: 400,
      pt: [5, 6],
      pb: [4, 5],
    },
    h4: {
      variant: "text.heading",
      color: "text",
      fontSize: [5],
      fontWeight: 300,
      py: [3],
    },
    label: {
      fontSize: [1],
      fontWeight: "bold",
      lineHeight: "heading",
      p: "0.5rem 1rem 0",
      userSelect: "none",
      flex: "1 0 100%",
      color: "var(--iconColor)",
    },
  },
  styles,
  alerts: {
    primary: {
      bg: "transparent",
      fontWeight: 200,
      borderRadius: 0,
      color: "warning",
      flex: "1 0 100%",
      my: [1],
      px: [0],
      py: [0],
    },
  },

  layout: {
    fullLogo: {
      alignItems: "center",
    },
    contextToolboxWrapper: {
      backgroundColor: "var(--contextBgColor)",
      position: "absolute",
      boxShadow: "var(--overlayShadow)",
    },
    submitButtonContainer: {
      flex: "1 0 100%",
      justifyContent: "flex-end",
      py: [4, 5],
      "> button": {
        flex: ["1 0 100%", "0 50%"],
      },
    },
    profileButtonContainer: {
      flex: ["1 0 100%", "0 50%"],
      justifyContent: "flex-end",
      pl: [0, 1],
    },
    dropdown: {
      bg: "background",
      borderColor: "primary",
      borderStyle: "solid",
      borderWidth: "1px",
      boxShadow: "var(--controlShadow)",
      borderTopWidth: "0",
      flexWrap: "wrap",
      maxHeight: "calc(var(--gridTile) * 7)",
      overflowY: "auto",
      position: "absolute",
      top: "100%",
      width: "100%",
      zIndex: 3,
      "> button": {
        flex: "1 0 100%",
      },
    },
    dropdownAboveField: {
      variant: "layout.dropdown",
      top: "auto",
      bottom: "100%",
      boxShadow: "var(--controlShadowTop)",
      borderTopWidth: "1px",
      borderBottomWidth: "0",
    },
    flexWrap: {
      flexWrap: "wrap",
      mb: [2],
    },
    passwordValidationParamsColumn: {
      width: ["100%", "50%", "50%", "100%", "50%"],
      justifyContent: "flex-start",
    },
    formsWrap: {
      alignItems: "center",
      display: "flex",
      flexWrap: "wrap",
      maxWidth: "48em",
      mb: [5, 7, 8],
      position: "relative",
      px: [2, 3],
      width: ["100%", "80%", "60%", "40%"],
      "&:before": {
        backgroundImage: [null, null, null, "url('/cb-logo.svg')"],
        backgroundPosition: "-75px center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "300px",
        content: '""',
        display: "block",
        height: "100%",
        left: "0",
        position: "fixed",
        top: "0",
        width: "100%",
        zIndex: "-1",
      },
      "&:after": {
        backgroundImage: ["url('/cb-logo-filled.svg')"],
        backgroundRepeat: "no-repeat",
        content: '""',
        display: "block",
        height: "40px",
        margin: [2, 3],
        position: "absolute",
        right: 0,
        top: "100%",
        width: "40px",
      },
    },
    authenticate: {
      variant: "layout.formsWrap",
      maxWidth: "60em",
      // those strange looking values make sure the fixed-size Collaboard logo fits next to the login form
      width: ["100%", "80%", "90%", "50%", "60%"],
    },
    container: {
      px: [2, 3],
    },
    userData: {
      flexWrap: "wrap",
      mb: [4],
    },

    projectsList: {
      flexDirection: "column",
      borderTopColor: "primary",
      borderTopStyle: "solid",
      borderTopWidth: "0.375em",
      mt: [2],
    },

    sidebar: {
      icon: {
        "> svg": {
          fill: "var(--textColor)",
          cursor: "pointer",
        },
      },
    },

    dropzone: {
      alignItems: "center",
      cursor: "pointer",
      display: "flex",
      height: [
        "calc(var(--gridTile) * 9)",
        null,
        null,
        null,
        "calc(var(--gridTile) * 7)",
      ],
      justifyContent: "center",
      position: "relative",
      mr: [4],
      width: [
        "calc(var(--gridTile) * 9)",
        null,
        null,
        null,
        "calc(var(--gridTile) * 7)",
      ],
      "> svg": {
        fill: "white",
        height: "calc(var(--gridTile) * 5)",
        position: "absolute",
        width: "calc(var(--gridTile) * 5)",
        zIndex: 3,
      },
    },
    icon: {
      backgroundColor: "transparent",
      display: "inline-block",
      flex: "none",
      height: ["24px", "32px", "40px"],
      outline: "none",
      pointerEvents: "auto",
      position: "relative",
      stroke: "none",
      width: ["24px", "32px", "40px"],
      "&:focus": { outline: "none" },
      "&:hover": { fill: "primary" },
      toolbar: {
        height: ["32px", "40px"],
        width: ["32px", "40px"],
      },
      context: {
        height: "var(--gridTile)",
        width: "var(--gridTile)",
      },
    },
    header: {
      px: [0],
    },
    headerBar: {
      backgroundColor: "primary",
      color: "background",
      padding: "1.4rem",
      fontWeight: 200,
      fontSize: "2rem",
      fontFamily: "body",
    },
    darkBar: {
      backgroundColor: "text",
      color: "delicate",
      padding: "0 1rem",
      height: "70px",
      fontSize: "1.6rem",
      display: "flex",
      alignItems: "center",
      margin: 0,
      justifyContent: "space-between",
      "> *": {
        alignItems: "center",
      },
    },
    listRow: {
      borderBottom: "2px solid",
      borderColor: "primary",
      fontWeight: 300,
      minHeight: "var(--gridTile)",
    },
    listRowCell: {
      flex: 1,
      padding: "0.5rem 1rem",
      textAlign: "left",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
    },
    canvasNavbar: {
      alignItems: "center",
      mt: [4],
      pointerEvents: "none",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      transition: "all 225ms cubic-bezier(0.7, 0, 0.3, 1)",
      transitionProperty: "left right",
      zIndex: toolPanelsZindex,
    },
    navbar: {
      alignItems: "center",
      height: ["6em", "8em", "10em", "15em"],
      justifyContent: "space-between",
      pointerEvents: "none",
      position: "relative",
      top: 0,
      width: "100%",
      zIndex: toolPanelsZindex,
    },
    pageWrap: {
      bg: "background",
      mb: [5, 7, 8],
      width: "100%",
    },
    userProfile: {
      flexDirection: "column",
    },
    componentHeader: {
      alignItems: "center",
      fontSize: "1.5em",
      fontWeight: "bold",
      span: {
        marginLeft: "calc(var(--gridTile) / 2)",
        marginBottom: "calc(var(--gridTile) / 4)",
      },
    },
    tabSwitcher: {
      cursor: "pointer",
      width: "auto",
      margin: 0,
      px: [4],
      py: [3],
      flex: 1,
      fontSize: [4, null, null, 5],
      fontWeight: 200,
      alignItems: "center",
      justifyContent: "center",
      bg: "background",
      "input[type='radio']:checked + &": {
        bg: "accent",
        color: "background",
        fontWeight: 500,
      },
    },
    radioSwitcher: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      fontWeight: 200,
      bg: "background",
      "input[type='radio']:checked + &": {
        fontWeight: 500,
      },
    },
    draggableWindow: {
      width: 512,
      height: 512,
      padding: "5px",
      backgroundColor: "rgba(255, 255, 255, 0.4)",
      border: "1px solid var(--iconColor)",
      position: "fixed",
      zIndex: draggableWindowZIndex,
      cursor: "move",
    },
    projectThumbnail: {
      display: "block",
      alignSelf: "flex-start",
      background:
        "linear-gradient(45deg, rgb(255,255,255) 0%, rgb(245,245,245) 100%)",
      borderRadius: 0,
      position: "relative",
      textAlign: "left",
      p: [0],
      m: [0],
      zIndex: 1,

      wrapper: {
        position: "relative",
        paddingTop: "100%",
      },
      content: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        padding: 4,
        flexDirection: "column",
        lineHeight: 1.4,
      },
      title: {
        variant: "text.ellipsis",
        color: "text",
        fontSize: 14,
        paddingRight: 10, // Allow a little space for the context menu
      },
      meta: {
        variant: "text.ellipsis",
        color: "var(--inputPlaceholder)",
        fontSize: 12,
      },
      context: {
        position: "absolute",
        top: 0,
        right: 0,
        height: "auto",
        width: "auto",
        display: "none",
      },
      overlay: {
        position: "absolute",
        zIndex: 10,
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        p: [5],
        lineHeight: "110%",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(255,255,255,0.9)",
        color: "disabledTextDark",
        svg: {
          fill: "disabled",
        },
      },
      overlayHover: {
        variant: "layout.projectThumbnail.overlay",
        background: "rgba(240,240,240,0.95)",
        svg: {
          fill: "disabledTextDark",
        },
      },
      updateLink: {
        cursor: "pointer",
        display: "inline",
        textDecoration: "underline",
      },
      overlayLabel: {
        p: [3],
        background: "white",
        textAlign: "center",
        fontSize: 1,
      },
    },
    projectThumbnailLocked: {
      variant: "layout.projectThumbnail",
      cursor: "not-allowed",
    },
  },

  links: {
    projectThumbnailLink: {
      variant: "layout.projectThumbnail",
      cursor: "pointer",
      "&:hover": {
        borderColor: "primary",
        borderWidth: "2px",
        borderStyle: "solid",
        transform: "scale(1.05)",
        transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        boxShadow: "var(--controlShadow)",
        zIndex: 2,
        ".project-thumbnail-link__context": {
          display: "block",
        },
      },
    },
    projectThumbnailLinkReadOnly: {
      variant: "links.projectThumbnailLink",
      background: "var(--red)",
    },
    projectThumbnailLinkNew: {
      variant: "links.projectThumbnailLink",
      background: "none",
      bg: "primary",
      textAlign: "center",
      wrapper: {
        variant: "links.projectThumbnailLink.wrapper",
      },
      content: {
        variant: "links.projectThumbnailLink.content",
      },
      title: {
        color: "background",
        justifySelf: "flex-start",
      },
      ".project-thumbnail-link__icon": {
        flex: 1,
        fill: "background",
        height: "50%",
        width: "50%",
        alignSelf: "center",
      },
    },
    projectThumbnailLinkLoad: {
      variant: "links.projectThumbnailLinkNew",
      bg: "text",
      "&:hover": {
        variant: "links.projectThumbnailLink.&:hover",
        borderColor: "text",
      },
      wrapper: {
        variant: "links.projectThumbnailLink.wrapper",
      },
      content: {
        variant: "links.projectThumbnailLink.content",
      },
      title: {
        color: "background",
        justifySelf: "flex-start",
      },
      ".project-thumbnail-link__icon": {
        // TODO: Figure out how to reuse styles from projectThumbnailLinkNew
        flex: 1,
        fill: "primary",
        height: "50%",
        width: "50%",
        alignSelf: "center",
      },
    },
  },
  images: {
    avatar: {
      borderRadius: "50%",
      borderStyle: "solid",
      borderWidth: "3px",
      boxShadow: "var(--controlShadow)",
      height: "var(--gridTile)",
      width: "var(--gridTile)",
      minWidth: "auto",
      maxWidth: "var(--gridTile)",
      maxHeight: "var(--gridTile)",
      // For better browser support the shadow must be applied to the containing button
      "button &": {
        boxShadow: "none",
      },
      enlarge: {
        borderRadius: "50%",
        borderStyle: "solid",
        borderWidth: "3px",
        boxShadow: "var(--controlShadow)",
        minWidth: "auto",
        height: "calc(var(--gridTile) * 1.4)",
        width: "calc(var(--gridTile) * 1.4)",
        "button &": {
          boxShadow: "none",
        },
      },
    },
    profile: {
      backgroundColor: "black",
      height: "100%",
      width: "100%",
    },
  },

  licenseItem: {
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    padding: "calc(1.4rem - 10px) 1.4rem",
    "> * ": {
      alignItems: "center",
    },
  },
  licenseBox: {
    borderWidth: "1px",
    borderColor: "primary",
    borderStyle: "solid",
    height: "80px",
    padding: "10px 0",
    "> * ": {
      borderRightWidth: "1px",
      borderRightStyle: "solid",
      borderRightColor: "primary",
      "&:last-child": {
        borderRight: "none",
      },
    },
  },
  tabs: {
    bold: {
      "> button": {
        fontSize: [4],
        fontWeight: "bold",
      },
    },
    regular: {
      "> button": {
        fontSize: [4],
        fontWeight: "normal",
      },
    },
    light: {
      "> button": {
        fontSize: [1, 1, 1, 2],
        fontWeight: "bold",
      },
    },
  },
});

export type DefaultTheme = typeof defaultTheme;

export default defaultTheme;
