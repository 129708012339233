export enum ObjectsSettingsActionType {
  SET_CONNECTOR_ATTRIBUTES = "SET_CONNECTOR_ATTRIBUTES",
  SET_STICKY_NOTE_ATTRIBUTES = "SET_STICKY_NOTE_ATTRIBUTES",
  SET_TEXT_ATTRIBUTES = "SET_TEXT_ATTRIBUTES",
}

export const setConnectorAttributesAction = (payload: ContextProps) =>
  ({
    type: ObjectsSettingsActionType.SET_CONNECTOR_ATTRIBUTES,
    payload,
  } as const);

export const setStickyNoteAttributesAction = (payload: ContextProps) =>
  ({
    type: ObjectsSettingsActionType.SET_STICKY_NOTE_ATTRIBUTES,
    payload,
  } as const);

export const setTextAttributesAction = (payload: ContextProps) =>
  ({
    type: ObjectsSettingsActionType.SET_TEXT_ATTRIBUTES,
    payload,
  } as const);

export type ObjectSettingsAction =
  | ReturnType<typeof setConnectorAttributesAction>
  | ReturnType<typeof setStickyNoteAttributesAction>
  | ReturnType<typeof setTextAttributesAction>;
