import {
  activatePenOnTouchSetting,
  clickableUrlsSetting,
  embedTransparencySetting,
  floatingToolbarSetting,
  minimapOpenSetting,
  objectGradientsSetting,
  uiSizeSetting,
  wheelInputAsTouchpadSetting,
  windowBorderOverlaySetting,
} from ".";
import { UISizes } from "../../../types/enum";
import {
  UISettingsAction,
  UISettingsActionType,
} from "../../uiSettings/uiSettings.actions";
import {
  UserSettingOrigin,
  UserSettingsActions,
  UserSettingsActionType,
} from "./userSettings.actions";

export type SelectableUserSettings = {
  uiSize: UISizes;
};

export type ToggleableUserSettings = {
  activatePenOnTouch: boolean;
  clickableUrls: boolean;
  embedTransparency: boolean;
  floatingToolbar: boolean;
  minimapOpen: boolean;
  objectGradients: boolean;
  windowBorderOverlay: boolean;
  wheelInputAsTouchpad: boolean;
};

type UserSettingsConfig = SelectableUserSettings & ToggleableUserSettings;

type UserSettingsStatus<T> = {
  value: T;
  origin: UserSettingOrigin;
};

export type UserSettingsState = {
  [key in keyof UserSettingsConfig]: UserSettingsStatus<
    UserSettingsConfig[key]
  >;
};

const getUserSettingsInitialState = (): UserSettingsState => {
  return {
    activatePenOnTouch: {
      value: activatePenOnTouchSetting.defaultValue,
      origin: UserSettingOrigin.defaultValue,
    },
    clickableUrls: {
      value: clickableUrlsSetting.defaultValue,
      origin: UserSettingOrigin.defaultValue,
    },
    embedTransparency: {
      value: embedTransparencySetting.defaultValue,
      origin: UserSettingOrigin.defaultValue,
    },
    floatingToolbar: {
      value: floatingToolbarSetting.defaultValue,
      origin: UserSettingOrigin.defaultValue,
    },
    minimapOpen: {
      value: minimapOpenSetting.defaultValue,
      origin: UserSettingOrigin.defaultValue,
    },
    objectGradients: {
      value: objectGradientsSetting.defaultValue,
      origin: UserSettingOrigin.defaultValue,
    },
    uiSize: {
      value: uiSizeSetting.defaultValue,
      origin: UserSettingOrigin.defaultValue,
    },
    windowBorderOverlay: {
      value: windowBorderOverlaySetting.defaultValue,
      origin: UserSettingOrigin.defaultValue,
    },
    wheelInputAsTouchpad: {
      value: wheelInputAsTouchpadSetting.defaultValue,
      origin: UserSettingOrigin.defaultValue,
    },
  };
};

export function userSettingsReducer(
  state = getUserSettingsInitialState(),
  action: UserSettingsActions | UISettingsAction
): UserSettingsState {
  switch (action.type) {
    case UserSettingsActionType.TOGGLE_USER_SETTING: {
      const { payload } = action;
      const { setting, origin } = payload;
      return {
        ...state,
        [setting.key]: {
          value: !state[setting.key].value,
          origin,
        },
      };
    }
    case UserSettingsActionType.SET_USER_SETTINGS: {
      const { payload } = action;
      return payload.reduce<UserSettingsState>(
        (result, { setting, value, origin }) => {
          return {
            ...result,
            [setting.key]: {
              value,
              origin,
            },
          };
        },
        state
      );
    }
    case UISettingsActionType.RESET_UI_SETTINGS:
    case UserSettingsActionType.RESET_USER_SETTINGS: {
      return getUserSettingsInitialState();
    }
    default:
      return state;
  }
}

const selectUserSetting = <K extends keyof UserSettingsState>(setting: K) => (
  state: ApplicationGlobalState
): UserSettingsState[K]["value"] => {
  return state.canvas.userSettings[setting].value;
};

export const selectIsMinimapOpen = selectUserSetting("minimapOpen");
export const selectClickableUrls = selectUserSetting("clickableUrls");
export const selectActivatePenOnTouch = selectUserSetting("activatePenOnTouch");
export const selectEmbedTransparency = selectUserSetting("embedTransparency");
export const selectObjectGradients = selectUserSetting("objectGradients");
export const selectFloatingToolbar = selectUserSetting("floatingToolbar");
export const selectWheelInputAsTouchpad = selectUserSetting(
  "wheelInputAsTouchpad"
);
export const selectWindowBorderOverlay = selectUserSetting(
  "windowBorderOverlay"
);
export const selectUiSize = selectUserSetting("uiSize");
