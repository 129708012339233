import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button } from "theme-ui";
import { routes } from "../../../../const";
import Modal, { TemplateProps } from "../../../shared/modals/Modal";
import ModalHeader from "../../../shared/modals/ModalHeader";
import { withModalTemplate } from "../../../shared/modals/withModalTemplate";
import BatchImportResultComplete from "./BatchImportResultComplete";
import BatchImportResultInProgress from "./BatchImportResultInProgress";

export type BatchImportResultModalProps = {
  importStatus: ImportStatus;
};

function BatchImportResultModalTemplate({
  importStatus,
  modalProps,
}: TemplateProps<BatchImportResultModalProps>): React.ReactElement {
  const { t } = useTranslation();
  const history = useHistory<void>();

  const {
    importing,
    totalRequests,
    requestCount,
    complete,
    addedCount,
    removedCount,
    invalidCount,
    existingCount,
    maxUsersReached,
  } = importStatus;

  const actionButton = (
    <Button
      onClick={() => {
        history.push(routes.team);
      }}
    >
      {t("form.button.ok")}
    </Button>
  );

  const onClose = () => {
    if (complete && addedCount > 0) {
      history.push(routes.team);
    } else {
      modalProps.onClose();
    }
  };

  return (
    <Modal
      header={<ModalHeader title={t("dialog.batchUserImport.result.header")} />}
      actionButtons={actionButton}
      {...modalProps}
      onClose={onClose}
    >
      <>
        {importing && (
          <BatchImportResultInProgress
            progress={requestCount / totalRequests}
          />
        )}
        {complete && (
          <BatchImportResultComplete
            added={addedCount}
            removed={removedCount}
            invalid={invalidCount}
            existing={existingCount}
            maxUsersReached={maxUsersReached}
          />
        )}
      </>
    </Modal>
  );
}

export default withModalTemplate(BatchImportResultModalTemplate);
