export enum UISettingsActionType {
  LOAD_UI_SETTINGS_DONE = "LOAD_UI_SETTINGS_DONE",
  RESET_UI_SETTINGS = "RESET_UI_SETTINGS",
}

export const loadUISettingsDoneAction = (payload: ApiUISettings) =>
  ({
    type: UISettingsActionType.LOAD_UI_SETTINGS_DONE,
    payload,
  } as const);

export const resetUISettingsAction = () =>
  ({
    type: UISettingsActionType.RESET_UI_SETTINGS,
    payload: undefined,
  } as const);

export type UISettingsAction =
  | ReturnType<typeof loadUISettingsDoneAction>
  | ReturnType<typeof resetUISettingsAction>;
