import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Grid } from "theme-ui";
import {
  colorThemes,
  projectListNoAccessPermissions,
  projectListPermissions,
  projectPermissions,
  permissionsDropdownZIndex,
} from "../../../../../const";
import { ApiPermission } from "../../../../../types/enum";
import Avatar from "../../../../users/Avatar";
import CroppedText from "../../../../shared/text/CroppedText";
import { usePermissionChangeButtons } from "../../../../canvas/project/permission/usePermissionChangeButtons";
import Dropdown, { DropdownItem } from "../DropDown";
import { Confirmation, useConfirmModal } from "../../../../shared/modals";
import ModalHeader from "../../../../shared/modals/ModalHeader";
import useToast from "../../../../shared/toasts/useToast";
import { useProjectOwner } from "../../../../../api/useRequest";
import Delete from "../../../../../icons/Delete";

export type PermissionChangeProps = {
  permission: ApiPermission;
  user: ProjectUserIdentity;
};

type Props = {
  user: ProjectParticipantInfo;
  myself: ProjectParticipantInfo | null;
  theme?: colorThemes | undefined;
  project: ApiProject;
  onPermissionChange: (props: PermissionChangeProps) => void;
  onProjectOwnerChange: () => void;
};

const { noPermission, ownerPermission } = projectPermissions;

function UserCard({
  user,
  myself,
  theme = colorThemes.light,
  project,
  onPermissionChange,
  onProjectOwnerChange,
}: Props): ReactElement {
  const { ProjectId, Presenter } = project.Project;
  const projectId = `${ProjectId}`;
  const { t } = useTranslation();

  const changeOwnershipModal = useConfirmModal();
  const userRemoveModal = useConfirmModal();

  const changeOwnershipToast = useToast("project.changeOwnerSuccessful");

  const onProjectOwnerChangeCompleted = () => {
    onProjectOwnerChange();
    changeOwnershipToast.show();
  };

  const { changeProjectOwner } = useProjectOwner(
    onProjectOwnerChangeCompleted,
    onProjectOwnerChange
  );

  const isProjectOwner = myself?.Permission === ownerPermission;

  const { handlePermissionClick } = usePermissionChangeButtons({
    user,
    projectId,
    presenter: Presenter ? Presenter : undefined,
    onPermissionChange,
    onProjectOwnerChange,
    noPromptRemoveUser: true,
  });

  const onBeforeChange = ({
    value,
  }: DropdownItem<number>): Promise<boolean> => {
    const confirmationModals = {
      [ownerPermission]: changeOwnershipModal,
      [noPermission]: userRemoveModal,
    };

    return !confirmationModals[value]
      ? Promise.resolve(true)
      : new Promise((resolve) => {
          changeOwnershipToast.dismiss();
          confirmationModals[value].open({
            confirmCallback: () => resolve(true),
            closeCallback: () => resolve(false),
          });
        });
  };

  const onChange = ({ value }: DropdownItem<number>) => {
    if (value === ownerPermission) {
      changeProjectOwner({ projectId, newOwner: user.UserName });
    } else {
      handlePermissionClick(value);
    }
  };

  const selectedPermission = projectListPermissions.find(
    (item) => item.value === user.Permission
  );

  const confirmationModalProps = changeOwnershipModal.props.isOpen
    ? {
        ...changeOwnershipModal.props,
        header: (
          <ModalHeader title={t("dialog.changeOwnerHeader")} Icon={Delete} />
        ),
        children: <>{t("dialog.changeOwnerPrompt")}</>,
      }
    : {
        ...userRemoveModal.props,
        header: <ModalHeader title={t("team.removeUser")} Icon={Delete} />,
        children: (
          <>
            {t("team.removeUserDescription", {
              name: user.FullName,
            })}
          </>
        ),
      };

  return (
    <Box
      key={user.UserName}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        bg: "#eee",
        width: "100%",
        fontSize: [10, null, null, 11],
      }}
    >
      <Confirmation
        {...confirmationModalProps}
        variant="warning"
        confirmationLabel={t("dialog.confirm")}
      />
      <Grid sx={{ gridTemplateColumns: "2fr 2fr 1.5fr 0.5fr", width: "100%" }}>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Avatar {...user} enlarge={false} theme={theme} />
          <Box sx={{ fontWeight: "700", marginLeft: 3 }}>
            <span>
              {user.FirstName} {user.LastName} {user.IsMyself && t("users.you")}
            </span>
          </Box>
        </Box>
        {!user.IsGuest && (
          <Box
            sx={{
              color: "grey",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <CroppedText>{user.UserName}</CroppedText>
          </Box>
        )}
        <Box>
          {/* TODO opened dropdown has incorrect width */}
          {isProjectOwner && (
            <Dropdown
              disabled={isProjectOwner && user.UserName === myself?.UserName}
              alreadySelected={selectedPermission}
              items={projectListPermissions}
              onChange={onChange}
              onBeforeChange={onBeforeChange}
              sx={{ height: 40, fontWeight: 700 }}
              labelSx={{
                fontSize: 14,
                alignItems: "center",
                justifyContent: "space-between",
              }}
              optionsSx={{
                minWidth: "unset",
                zIndex: permissionsDropdownZIndex,
              }}
            />
          )}
        </Box>
        <Box sx={{ alignSelf: "center", margin: "auto" }}>
          {isProjectOwner && (
            <Button
              variant="contentProjectList"
              id="project-delete"
              onClick={async () => {
                const noPermission = projectListNoAccessPermissions;
                const deleteConfirmed = await onBeforeChange(noPermission);
                deleteConfirmed && onChange(noPermission);
              }}
              disabled={isProjectOwner && user.UserName === myself?.UserName}
            >
              <Delete noShadow />
            </Button>
          )}
        </Box>
      </Grid>
    </Box>
  );
}

export default UserCard;
