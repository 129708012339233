import { ErrorMessage, Field, FieldProps } from "formik";
import React, { PropsWithChildren, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Flex,
  Input,
  Label,
  SxStyleProp,
  Text,
  ThemeUIStyleObject,
} from "theme-ui";
import { useFieldError } from "./useFieldError";

type Props = PropsWithChildren<{
  name: string;
  testId?: string;
  type?: string;
  autoComplete?: string;
  placeholder?: string;
  required?: boolean;
  sx?: SxStyleProp;
  variant?: string;
  label?: string;
  processOnEnterPressed?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  readOnly?: boolean;
  disabled?: boolean;
  maxLength?: number;
  hideErrorMessage?: boolean;
}>;

type InputProps = Props & FieldProps;

function InputField({
  form,
  meta,
  field,
  placeholder,
  required,
  variant = "label",
  sx,
  label,
  processOnEnterPressed,
  name,
  testId,
  type,
  onChange,
  onBlur,
  disabled,
  autoComplete,
  maxLength,
  readOnly,
  hideErrorMessage,
  children,
}: InputProps): ReactElement {
  const { t } = useTranslation();
  const { errorInputSx, fieldRef } = useFieldError({ meta, name });

  const onKeyUp: React.KeyboardEventHandler = (e) => {
    if (e.key.toLowerCase() === "enter" && processOnEnterPressed) {
      const processingButton = document.getElementById("processing-button");
      // TODO: refactor modals / forms around ConfirmatinButtons
      // state of confirmation button must reflect form state
      // currently both states are independent
      processingButton ? processingButton.click() : form.submitForm();
    }
  };

  return (
    <Label
      variant={required ? `${variant}.required` : `${variant}`}
      sx={sx}
      aria-label={name}
      data-cy={testId}
    >
      {label && (
        <Text
          variant="default"
          sx={{ fontWeight: 600, fontSize: [4], mb: [1] }}
        >
          {label} {required ? `(${t("form.required")})` : ""}
        </Text>
      )}
      <Box sx={inputWrapperStyle}>
        <Input
          style={children ? { paddingRight: "var(--gridTile)" } : undefined}
          placeholder={placeholder || t(`form.placeholder.${name}`)}
          onKeyUp={onKeyUp}
          sx={errorInputSx}
          ref={fieldRef}
          autoComplete={autoComplete}
          type={type}
          disabled={disabled}
          maxLength={maxLength}
          readOnly={readOnly}
          {...field}
          {...meta}
          onChange={onChange ?? field.onChange}
          onBlur={onBlur ?? field.onBlur}
        />
        {children && <Flex sx={childrenStyle}>{children}</Flex>}
      </Box>

      {!hideErrorMessage && (
        <ErrorMessage
          name={name}
          component={({ children: kids }) => (
            <Alert variant="primary">{kids}</Alert>
          )}
        />
      )}
    </Label>
  );
}

const inputWrapperStyle: ThemeUIStyleObject = {
  position: "relative",
  width: "100%",
};

const childrenStyle: ThemeUIStyleObject = {
  position: "absolute",
  right: 2,
  top: "50%",
  transform: "translateY(-50%)",
  pl: [2],
};

function FormInputField(props: Props): ReactElement {
  return (
    <Field name={props.name}>
      {(fieldProps: FieldProps) => <InputField {...props} {...fieldProps} />}
    </Field>
  );
}

export default FormInputField;
