import React, { ReactElement } from "react";
import { Box, Flex } from "theme-ui";
import { useTranslation } from "react-i18next";
import Touchpad from "../../../../icons/Touchpad";

type Props = {
  labelKey: string;
  linkKey?: string;
};

function TouchpadToast({ labelKey, linkKey }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <Flex
      sx={{
        alignItems: "center",
        fontSize: 3,
        pr: [3],
        whiteSpace: "nowrap",
      }}
    >
      <Touchpad fill="var(--invertedTextColor)" />
      {t(labelKey)}
      {linkKey ? (
        <Box
          sx={{
            fontWeight: "bold",
            textDecoration: "underline",
            ml: [1],
          }}
        >
          {t(linkKey)}
        </Box>
      ) : null}
    </Flex>
  );
}

export default TouchpadToast;
