import {
  ChangeEventHandler,
  ClipboardEventHandler,
  KeyboardEvent,
  KeyboardEventHandler,
  MouseEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { FieldHookConfig, useField } from "formik";
import { without } from "ramda";
import { string } from "yup";
import { detectedOS } from "../../../../../tools/detectOS";
import { useUISettings } from "../../../../../reduxStore/uiSettings";
import { dynamicFeatureFlags } from "../../../../../tools/flags";

const itemSchemas = {
  email: string().email(),
  string: string(),
};

export type MultiInputType = keyof typeof itemSchemas;

export type MultiInputFieldItem<T> = {
  value: string;
  metadata?: T;
  isValid: boolean;
};

type UseMultiInputFieldProps<T> = {
  itemType: MultiInputType;
  props: FieldHookConfig<MultiInputFieldItem<T>[]>;
  setIsExpanded(flag: boolean): void;
  isExpanded: boolean;
  onAdded?(
    newItems: MultiInputFieldItem<T>[],
    allItems: MultiInputFieldItem<T>[]
  ): void;
  onRemoved?(
    removedItems: MultiInputFieldItem<T>[],
    allItems: MultiInputFieldItem<T>[]
  ): void;
  validateEntries?(entries: string[]): Promise<MultiInputFieldItem<T>[]>;
};

type UseMultiInputField<T> = {
  items: MultiInputFieldItem<T>[];
  isAllSelected: boolean;
  inputFieldRef: MutableRefObject<HTMLInputElement | null>;
  inputFieldValue: string;
  onRemoveItem(
    itemToRemove: MultiInputFieldItem<T>,
    e: MouseEvent<HTMLButtonElement>
  ): void;
  onInputChange: ChangeEventHandler<HTMLInputElement>;
  onInputKeyDown: KeyboardEventHandler<HTMLInputElement>;
  onFieldBlur(): void;
  onPaste: ClipboardEventHandler<HTMLInputElement>;
};

const getSchema = (itemType: MultiInputType) => itemSchemas[itemType];

const getEntriesFromText = (
  pastedText: string | undefined,
  itemType: MultiInputType
) => {
  if (!pastedText) {
    return {
      validEntries: [],
      restString: "",
    };
  }

  const schema = getSchema(itemType);
  const formattedText = pastedText.trim().replace(/(,|;|\s)+/g, " ");
  const entries = formattedText.split(" ");

  type TextEntries = {
    valid: string[];
    invalid: string[];
  };

  const { valid, invalid } = entries.reduce<TextEntries>(
    (accu, entry) =>
      entry.length && schema.isValidSync(entry)
        ? {
            valid: accu.valid.concat(entry),
            invalid: accu.invalid,
          }
        : {
            valid: accu.valid,
            invalid: accu.invalid.concat(entry),
          },
    { valid: [], invalid: [] }
  );

  return {
    validEntries: valid,
    restString: invalid.join(" "),
  };
};

const { mac: isMac } = detectedOS;

const isDeleteKey = (e: KeyboardEvent<HTMLInputElement>) => {
  const deleteKeys = ["backspace", "delete"];
  return deleteKeys.includes(e.key.toLowerCase());
};

const isBackspaceKey = (e: KeyboardEvent<HTMLInputElement>) => {
  return e.key.toLowerCase() === "backspace";
};

const isConfirmKey = (e: KeyboardEvent<HTMLInputElement>) => {
  const confirmKeys = [" ", "tab", "enter", ",", ";"];
  return confirmKeys.includes(e.key.toLowerCase());
};

const isSelectAllKeys = (e: KeyboardEvent<HTMLInputElement>) => {
  const isA = e.key.toLowerCase() === "a";
  return isA && ((isMac && e.metaKey) || (!isMac && e.ctrlKey));
};

const isExitKey = (e: KeyboardEvent<HTMLInputElement>) => {
  return e.key.toLowerCase() === "escape";
};

const defaultEntriesValidator = async <T>(
  entries: string[]
): Promise<MultiInputFieldItem<T>[]> =>
  entries.map((e) => ({ value: e, isValid: true }));

export const useMultiInputField = <T extends unknown>({
  itemType,
  props,
  setIsExpanded,
  isExpanded,
  onAdded,
  onRemoved,
  validateEntries = defaultEntriesValidator,
}: UseMultiInputFieldProps<T>): UseMultiInputField<T> => {
  const inputFieldRef = useRef<HTMLInputElement>(null);
  const [inputFieldValue, setInputFieldValue] = useState("");
  const [isAllSelected, setAllSelected] = useState(false);
  const [field, , helpers] = useField<MultiInputFieldItem<T>[]>(props);
  const { value: items = [] } = field;
  const { isDynamicFeatureActive } = useUISettings();

  const isUserSearchFeatureActive = isDynamicFeatureActive(
    dynamicFeatureFlags.USER_SEARCH
  );

  useEffect(() => {
    if (inputFieldValue?.length || items.length) {
      setIsExpanded(true);
    }
  }, [inputFieldValue, items, setIsExpanded]);

  useEffect(() => {
    if (!isExpanded) {
      setInputFieldValue("");
    } else {
      inputFieldRef.current?.focus();
    }
  }, [isExpanded]);

  const removeItem = (itemToRemove: MultiInputFieldItem<T>) => {
    const newItems = items.filter((item) => item !== itemToRemove);
    helpers.setValue(newItems);
    onRemoved?.([itemToRemove], newItems);
  };

  const addItems = (newItems: MultiInputFieldItem<T>[]) => {
    const itemsIgnoreCase = items.map((i) => i.value.toLowerCase());
    const newItemsIgnoreCase = newItems.map((i) => i.value.toLowerCase());
    const itemsToAddIgnoreCase = without(itemsIgnoreCase, newItemsIgnoreCase);
    const itemsToAdd = newItems.filter((i) =>
      itemsToAddIgnoreCase.includes(i.value.toLowerCase())
    );

    if (itemsToAdd.length) {
      const newValue = [...items, ...itemsToAdd];
      helpers.setValue(newValue);
      onAdded?.(itemsToAdd, newValue);
    }

    setInputFieldValue("");
  };

  const highlightInvalid = () => {
    if (!inputFieldRef.current) {
      return;
    }

    const { style } = inputFieldRef.current;
    style.color = "#e84e1b";

    setTimeout(() => {
      if (inputFieldRef.current) {
        inputFieldRef.current.style.color = "";
      }
    }, 250);
  };

  const handleConfirmation = async (value?: string) => {
    const { validEntries, restString } = getEntriesFromText(value, itemType);
    const validItems = await validateEntries(validEntries);

    addItems(validItems);

    if (restString.length) {
      setInputFieldValue(restString);
      highlightInvalid();
    }
  };

  const removeAllItems = () => {
    onRemoved?.(items, []);
    helpers.setValue([]);
    setInputFieldValue("");
    setAllSelected(false);
    inputFieldRef.current?.focus();
  };

  const removeLastItem = () => {
    const lastItem = items[items.length - 1];
    if (lastItem) {
      setInputFieldValue(lastItem.value);
      inputFieldRef.current?.focus();
      removeItem(lastItem);
    }
  };

  const onRemoveItem = (
    itemToRemove: MultiInputFieldItem<T>,
    e: MouseEvent<HTMLButtonElement>
  ) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }

    removeItem(itemToRemove);
  };

  const onInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputFieldValue(e.target.value);
  };

  const onInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    const isInputEmpty = !inputFieldValue?.length;

    const isBackspace = isBackspaceKey(e);
    const isDelete = isDeleteKey(e);
    const isConfirming = isConfirmKey(e);
    const isSelectingAll = isSelectAllKeys(e);
    const isExiting = isExitKey(e);

    const isRemoving = isBackspace && isInputEmpty;
    const isRemovingAll = isAllSelected && isDelete;

    if (isConfirming || isRemoving || isRemovingAll) {
      e.preventDefault();
    }

    if (isConfirming) {
      handleConfirmation(inputFieldValue);
    } else if (isSelectingAll && isInputEmpty) {
      setAllSelected(true);
    } else if (isRemovingAll) {
      removeAllItems();
    } else if (isRemoving) {
      removeLastItem();
    } else if (isExiting) {
      setIsExpanded(false);
    } else {
      setAllSelected(false);
    }
  };

  const onPaste: ClipboardEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();

    const pastedText = e.clipboardData.getData("text").trim();
    if (!pastedText) {
      return;
    }

    handleConfirmation(pastedText);
  };

  const onFieldBlur = () => {
    isUserSearchFeatureActive
      ? setInputFieldValue("")
      : handleConfirmation(inputFieldValue);
  };

  return {
    items,
    isAllSelected,
    inputFieldRef,
    inputFieldValue,
    onRemoveItem,
    onInputChange,
    onInputKeyDown,
    onFieldBlur,
    onPaste,
  };
};
