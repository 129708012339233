import { combineReducers } from "redux";
import { objectLinksReducer, ObjectLinksState } from "./links/links.reducer";
import {
  objectSettingsReducer,
  ObjectSettingsState,
} from "./settings/settings.reducer";

export type CanvasObjectsState = {
  links: ObjectLinksState;
  settings: ObjectSettingsState;
};

export const canvasObjectsReducer = combineReducers<CanvasObjectsState>({
  links: objectLinksReducer,
  settings: objectSettingsReducer,
});
