import React, { ComponentProps, forwardRef, PropsWithChildren } from "react";
import { Box, ThemeUIStyleObject } from "theme-ui";

type Props = PropsWithChildren<
  {
    hidden?: boolean;
    inverted?: boolean;
    boxed?: boolean;
  } & ComponentProps<"div">
>;

const OptionsList = forwardRef<HTMLDivElement, Props>(function OptionsList(
  { children, hidden, inverted, boxed, ...rest }: Props,
  ref
) {
  return (
    <Box
      hidden={hidden}
      ref={ref}
      sx={{
        ...listStyle,
        border: boxed ? "1px solid var(--buttonToggleColor__off)" : "auto",
      }}
      {...rest}
    >
      {children}
    </Box>
  );
});

const listStyle: ThemeUIStyleObject = {
  backgroundColor: "transparent",
  boxShadow: "none",
  left: 0,
  height: "auto",
  margin: 0,
  flex: 1,
  overflowY: "auto",
  overflowX: "hidden",
  padding: 0,
  position: "relative",
  top: "auto",
  width: "100%",
  maxHeight: "calc(var(--gridTile) * 6)",
  zIndex: 2,
};

export default OptionsList;
