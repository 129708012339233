import React, { ReactElement, SyntheticEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, Image, ThemeUIStyleObject } from "theme-ui";
import { getCustomerConfiguration } from "../../../tools/customer";

const defaultIcon = "/cb-logo-filled.svg";

const {
  loginProviderCustomNames,
  loginProviderCustomIcons,
  displayProviderNamesOnly,
} = getCustomerConfiguration();

type Props = {
  name: string;
  displayName?: string;
  isRegistration: boolean;
  onClick: (name: string) => void;
};

function ExternalAuthButton({
  name,
  displayName,
  isRegistration,
  onClick,
}: Props): ReactElement {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    onClick(name);
  }, [name, onClick]);

  const onImageError = useCallback(
    ({ currentTarget }: SyntheticEvent<HTMLImageElement, Event>) => {
      currentTarget.src = defaultIcon;
    },
    []
  );

  const label = loginProviderCustomNames?.[name] || displayName || name;

  return (
    <Button variant="social" onClick={handleClick}>
      <Image
        src={
          loginProviderCustomIcons?.[name] || `/btn-${displayName || name}.svg`
        }
        onError={onImageError}
        mr={2}
        sx={imageStyle}
      />
      {displayProviderNamesOnly
        ? ""
        : isRegistration
        ? t("form.button.registerWith")
        : t("form.button.loginWith")}{" "}
      {label}
    </Button>
  );
}

export default ExternalAuthButton;

const imageStyle: ThemeUIStyleObject = { height: "1.25rem" };
