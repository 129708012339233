import React, { ReactElement } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button, Text } from "theme-ui";
import { useHistory } from "react-router-dom";

import Modal from "../../../../../shared/modals";
import ModalHeader from "../../../../../shared/modals/ModalHeader";
import UploadDocument from "../../../../../../icons/UploadDocument";
import { useSpreadsheetUpload } from "./useSpreadsheetUpload";
import { routes } from "../../../../../../const";
import ContainedSpinner from "../../../../../shared/spinners/ContainedSpinner";
import { TemplateProps } from "../../../../../shared/modals/Modal";
import { withModalTemplate } from "../../../../../shared/modals/withModalTemplate";
import DropZone from "../../../../../shared/drag-and-drop/DropZone";

const formatFileName = (name: string) => {
  const nameSplit = name.split(".");
  const withoutExtension = nameSplit.slice(0, -1).join(".");
  const extension = nameSplit.slice().pop()?.slice(0, 4) ?? "";

  return withoutExtension.length > 30
    ? `${withoutExtension.slice(0, 27)}(...).${extension}`
    : name;
};

function BatchImportModalTemplate({ modalProps }: TemplateProps): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    inputProps,
    rootProps,
    spreadsheetData,
    fileName,
    isDragActive,
    isProcessing,
  } = useSpreadsheetUpload();

  const importPreviewState: BatchImportHistoryLocationState = {
    spreadsheetData,
  };

  const actionButton = (
    <Button
      disabled={!spreadsheetData?.length || isProcessing}
      onClick={() => {
        history.push(routes.importPreview, importPreviewState);
      }}
    >
      {t("continue")}
    </Button>
  );

  return (
    <Modal
      header={
        <ModalHeader title={t("team.uploadFile")} Icon={UploadDocument} />
      }
      smallHeader
      actionButtons={actionButton}
      {...modalProps}
    >
      <>
        {isProcessing && <ContainedSpinner />}

        <p>{t("team.batchImport.modalIntro")}</p>

        <Trans i18nKey="team.batchImport.exampleFile">
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <a href="batch_user_import_example.xlsx" download />
        </Trans>

        <DropZone
          inputProps={inputProps}
          rootProps={rootProps}
          isDragActive={isDragActive}
          acceptedNames={["xls, xlsx, ods, csv, txt"]}
          icon={UploadDocument}
          isBoardUpload={false}
          sx={{ marginTop: [4] }}
        />

        {fileName && Boolean(spreadsheetData?.length) && (
          <Text sx={{ mt: [4], textAlign: "center" }}>
            <Trans
              i18nKey="team.batchImport.fileName"
              values={{ fileName: formatFileName(fileName) }}
            >
              <strong />
            </Trans>
          </Text>
        )}

        {fileName && !spreadsheetData?.length && (
          <Text sx={{ mt: [4], textAlign: "center" }}>
            <Trans
              i18nKey="team.batchImport.fileEmpty"
              values={{ fileName: formatFileName(fileName) }}
            >
              <strong />
            </Trans>
          </Text>
        )}
      </>
    </Modal>
  );
}

export default withModalTemplate(BatchImportModalTemplate);
