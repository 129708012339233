import {
  all,
  call,
  Effect,
  getContext,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import { backgroundColors, colorThemes } from "../../../const";
import {
  CanvasToast,
  CanvasToastContent,
} from "../../../features/shared/toasts/useCanvasToast";
import { setDirty } from "../../../studio/utils/fabricObjects";
import { isDarkColor, isLightColor } from "../../../tools/colors";
import { viewportTransformStorage } from "../../../tools/localStorageStores";
import { nextZoomStep, previousZoomStep } from "../../../tools/zoom";
import {
  CanvasSettingsActionType,
  SetBackgroundColorAction,
  SetThemeAction,
  setThemeAction,
  SetViewportTransformAction,
  SetZoomLevelAction,
  setZoomLevelAction,
  ZoomInAction,
  ZoomOutAction,
} from "./settings.actions";
import {
  selectCanvasGrid,
  selectCanvasTheme,
  selectCanvasZoomLevel,
} from "./settings.reducer";

function* setBackgroundColor(action: SetBackgroundColorAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  const theme = selectCanvasTheme(yield select());
  const { BackgroundColor } = action.payload;

  const isInDarkTheme = backgroundColors[colorThemes.dark].includes(
    BackgroundColor
  );
  const isInLightTheme = backgroundColors[colorThemes.light].includes(
    BackgroundColor
  );

  const enableDarkMode = isInDarkTheme || isDarkColor(BackgroundColor);
  const enableLightMode = isInLightTheme || isLightColor(BackgroundColor);

  if (enableDarkMode && theme !== colorThemes.dark) {
    yield put(
      setThemeAction({
        theme: colorThemes.dark,
        setByBackgroundChange: true,
      })
    );
  } else if (enableLightMode && theme !== colorThemes.light) {
    yield put(
      setThemeAction({
        theme: colorThemes.light,
        setByBackgroundChange: true,
      })
    );
  }

  canvas.backgroundColor = BackgroundColor;
  setDirty(canvas);
  canvas.requestRenderAll();
}

function* setTheme(action: SetThemeAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  const { theme, setByBackgroundChange } = action.payload;
  canvas.theme = theme;
  canvas.updateColorScheme();
  canvas.setThemeCursors(theme);
  setDirty(canvas);
  canvas.requestRenderAll();

  if (setByBackgroundChange) {
    canvas.trigger("custom:canvas:toast:update", {
      toast: CanvasToast.onThemeChangedByBackground,
      content:
        theme === colorThemes.dark
          ? CanvasToastContent.darkThemeEnabled
          : CanvasToastContent.lightThemeEnabled,
    });
  }
}

function* setViewportTransform(action: SetViewportTransformAction) {
  yield call(
    { context: viewportTransformStorage, fn: viewportTransformStorage.set },
    action.payload
  );
}

function* toggleGrid() {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  // TODO #5927: single source of truth
  const grid = selectCanvasGrid(yield select());
  canvas.isGridEnabled = grid;
  canvas.requestRenderAll();
}

let zoomToCenterInFlight = false;

function* zoomCenter() {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  if (!zoomToCenterInFlight) {
    const bb = canvas.calcBBForAllObjects({
      absolute: true,
    });

    zoomToCenterInFlight = true;

    yield canvas.scaleToBB({ bb });

    zoomToCenterInFlight = false;

    canvas.trigger("custom:canvas:viewport:transform", {
      viewportTransform: canvas.viewportTransform,
      zoomLevel: canvas.getZoom(),
    });
  }
}

let lastZoomStepRequest: number;

function* setZoomLevelToNextStep(action: ZoomInAction | ZoomOutAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");
  const zoomLevel = selectCanvasZoomLevel(yield select());

  const zoomTo =
    action.type === CanvasSettingsActionType.ZOOM_OUT_TO_NEXT_STEP
      ? previousZoomStep(zoomLevel)
      : nextZoomStep(zoomLevel);

  if (zoomTo !== lastZoomStepRequest) {
    lastZoomStepRequest = zoomTo;
    yield put(
      setZoomLevelAction({
        zoomLevel: zoomTo,
        animate: true,
        x: canvas.width / 2,
        y: canvas.height / 2,
      })
    );
  }
}

function* zoomToActiveObject() {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  const activeObject = canvas.getActiveObject();
  yield activeObject ? canvas.panToObjects([activeObject]) : Promise.resolve();
}

function* setZoomLevel({ payload }: SetZoomLevelAction) {
  const canvas: fabric.CollaboardCanvas = yield getContext("canvas");

  const { zoomLevel, animate, x, y } = payload;
  const zoomTo = {
    x: x || canvas.width / 2,
    y: y || canvas.height / 2,
    zoomLevel,
  };
  if (animate) {
    yield canvas.fxZoomToPoint(zoomTo);
    canvas.trigger("custom:canvas:viewport:transform", {
      viewportTransform: canvas.viewportTransform,
      zoomLevel,
    });
  } else {
    canvas.trigger("custom:canvas:zoom:set", {
      ...payload,
      atomic: true,
    });
  }
}

export function* canvasSettingsSaga(): Generator<Effect> {
  yield all([
    takeEvery(
      CanvasSettingsActionType.SET_BACKGROUND_COLOR,
      setBackgroundColor
    ),
    takeEvery(CanvasSettingsActionType.SET_THEME, setTheme),
    takeEvery(CanvasSettingsActionType.TOGGLE_GRID, toggleGrid),

    takeEvery(
      CanvasSettingsActionType.SET_VIEWPORT_TRANSFORM,
      setViewportTransform
    ),

    takeEvery(CanvasSettingsActionType.SET_ZOOM_LEVEL, setZoomLevel),
    takeEvery(CanvasSettingsActionType.ZOOM_CENTER, zoomCenter),
    takeEvery(
      [
        CanvasSettingsActionType.ZOOM_IN_TO_NEXT_STEP,
        CanvasSettingsActionType.ZOOM_OUT_TO_NEXT_STEP,
      ],
      setZoomLevelToNextStep
    ),
    takeEvery(
      CanvasSettingsActionType.ZOOM_TO_ACTIVE_OBJECT,
      zoomToActiveObject
    ),
  ]);
}
