import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Flex, Grid, Text, ThemeUIStyleObject } from "theme-ui";
import OK from "../../../../icons/OK";
import { getAppName } from "../../../../tools/customer";

type Props = {
  emails: string[];
  onReset: () => void;
};

function InvitesSentConfirmation({ emails, onReset }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <Box sx={containerStyle}>
        <Flex sx={{ mb: [2], alignItems: "center", justifyContent: "center" }}>
          <OK noShadow />
          <Text sx={textStyle}>{t("dialog.share.invitesSent")}</Text>
        </Flex>
        <Text variant="secondary">
          {t("dialog.share.invitesSentInfo", { appName: getAppName() })}
        </Text>
      </Box>

      <Grid sx={gridStyle}>
        {emails.map((email) => (
          <Flex key={email} sx={emailStyle}>
            {email}
          </Flex>
        ))}
      </Grid>

      <Box as="footer" sx={{ py: [5] }}>
        <Button onClick={onReset} sx={{ ml: "auto" }}>
          {t("dialog.share.inviteMore")}
        </Button>
      </Box>
    </>
  );
}

const containerStyle: ThemeUIStyleObject = {
  pb: [5],
  textAlign: "center",
  svg: {
    fill: "primary",
    height: "36px",
    width: "36px",
  },
};

const textStyle: ThemeUIStyleObject = {
  fontWeight: 700,
  fontSize: [5],
  color: "var(--textColor)",
};

const gridStyle: ThemeUIStyleObject = {
  gridTemplateColumns: "1fr 1fr",
  overflowY: "auto",
  maxHeight: "300px",
};

const emailStyle: ThemeUIStyleObject = {
  backgroundColor: "var(--multiInputItemBackground)",
  color: "var(--textColor)",
  py: [3],
  px: [4],
};

export default InvitesSentConfirmation;
