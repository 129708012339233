import { useEffect, useState } from "react";
import { queryCache } from "react-query";
import { useTranslation } from "react-i18next";

import errorCodes from "../../../../api/errorCodes";
import { billingTypes } from "../../../licensing/payment/enums";
import { useTotalPriceQuery } from "../usePrices";
import { useOrderCreation } from "../useOrderCreation";
import { useProducts } from "./useProducts";

const GET_TOTAL_QUERY_KEY = "GetPriceTeam";

export const useTeamLicenseOrder = (currencyCode, productTypeID) => {
  const { t } = useTranslation();
  const { productDetails } = useProducts(productTypeID);
  const { MinNumberOfUsers } = productDetails;

  const [promoCode, setPromoCode] = useState("");
  const [promoCodeDisabled, setPromoCodeDisabled] = useState(false);
  const [billingType, setBilling] = useState(billingTypes.annually);
  const [userCount, setUserCount] = useState(`${MinNumberOfUsers}`);

  const [userCountError, setUserCountError] = useState("");

  // display error when userCount is lower than minimum
  useEffect(() => {
    setUserCountError(
      Number(userCount) < MinNumberOfUsers
        ? t("plans.minimumUserCountNotReached", { count: MinNumberOfUsers })
        : ""
    );
  }, [userCount, t, MinNumberOfUsers]);

  useEffect(() => {
    if (MinNumberOfUsers) {
      setUserCount(`${MinNumberOfUsers}`);
    }
  }, [MinNumberOfUsers]);

  const onUserCountChange = (e) => {
    const enteredValue = e.target.value.trim();
    Number(enteredValue) < 1
      ? setUserCount("")
      : setUserCount(e.target.value.trim().replace(/\D/g, ""));
  };

  const onPromoCodeChange = (e) => {
    setPromoCode(e.target.value.trim());
  };

  const onBillingChange = (value) => {
    setBilling(value);
  };

  const { goToCheckout } = useOrderCreation({
    productId: productTypeID,
    billingType,
    currencyCode,
    promoCode,
    numberOfDevices: 0,
    numberOfUsers: Number(userCount),
  });

  const {
    total,
    numberOfFreeMonths,
    totalError,
    isSuccess,
    isError,
  } = useTotalPriceQuery({
    billingType,
    currencyCode,
    userCount,
    promoCodeDisabled,
    promoCode,
    queryKey: GET_TOTAL_QUERY_KEY,
    productId: productTypeID,
    MinNumberOfUsers,
  });

  const clearPromoCode = async () => {
    await queryCache.invalidateQueries(GET_TOTAL_QUERY_KEY);
    setPromoCodeDisabled(false);
    setPromoCode("");
  };

  const getTotalError = () =>
    userCountError ||
    (totalError?.ErrorCode &&
      t(`apiError.${errorCodes[totalError?.ErrorCode]}`));

  return {
    total,
    numberOfFreeMonths,
    totalError: getTotalError(),
    promoCode,
    promoCodeDisabled,
    billingType,
    arePricesLoaded: isSuccess || isError,
    userCount,
    onUserCountChange,
    onBillingChange,
    onPromoCodeChange,
    clearPromoCode,
    goToCheckout,
    updateTotal: async () => {
      await queryCache.invalidateQueries(GET_TOTAL_QUERY_KEY);
      setPromoCodeDisabled(true);
    },
  };
};
