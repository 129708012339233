import React, { ReactElement } from "react";
import { Icon } from "./Icon";

function Accordion(props: IconProps): ReactElement {
  return (
    <Icon {...props}>
      <polygon points="11.2 13.11 11.2 12.12 12.19 12.12 22.97 12.12 22.97 11.19 10.11 11.19 10.11 21.52 11.2 21.52 11.2 13.11" />
      <polygon points="13.28 14.03 14.27 14.03 25.87 14.03 25.87 13.11 12.19 13.11 12.19 24.24 13.28 24.24 13.28 15.02 13.28 14.03" />
      <path d="M28.94,15H14.27V27H28.94Z" />
    </Icon>
  );
}
export default Accordion;
