import PropTypes from "prop-types";
import { isNil } from "ramda";
import React from "react";
import { useTranslation } from "react-i18next";
import { Flex, Text } from "theme-ui";
import Checkmark from "../../../icons/Checkmark";
import WrappingBox from "./WrappingBox";

function FreeMonths({ count }) {
  const { t } = useTranslation();

  return isNil(count) ? null : (
    <WrappingBox>
      <Flex sx={containerStyle} id="promo-code-free-months">
        <Checkmark noShadow />
        <Text>{t("plans.freeMonthsInfo", { count })}</Text>
      </Flex>
    </WrappingBox>
  );
}

const containerStyle = {
  alignItems: "center",
  svg: {
    fill: "primary",
    mr: [2],
  },
};

FreeMonths.propTypes = {
  count: PropTypes.oneOfType([PropTypes.number]),
};
FreeMonths.defaultProps = {
  count: null,
};
export default FreeMonths;
